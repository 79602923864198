
















import { Component, Prop, Emit } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class VisitInfo extends CarePartBase {
  @Prop({ default: false }) protected readonly service_visit!: boolean;

  @Emit("update:service_visit") protected updateServiceVisit(
    newValue: boolean
  ) {
    return newValue;
  }

  protected get ServiceVisit() {
    return this.service_visit;
  }

  protected set ServiceVisit(newValue: boolean) {
    this.updateServiceVisit(newValue);
  }
}
