
































import { Component, Mixins, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import { Choice } from "@/types";
import EditMixin from "@/mixins/editMixin";

interface PatientGroup {
  id: number;
  patient_id: number;
  group_id: number;
  sort_no: number;
  is_icon_display: number;
}

@Component({
  components: {
    PatientHeader
  }
})
export default class GroupSettings extends Mixins(AxiosMixin, EditMixin) {
  private patientId = 0;
  private groupItems: Choice[] = [];
  private patientGroups: PatientGroup[] = [];

  created(): void {
    this.patientId = Number(this.$route.params.id);
    this.fetchGroups();
  }

  private fetchGroups(): void {
    // グループリスト取得
    this.postJsonCheck(
      window.base_url + "/api/patient/group/get",
      { patient_id: this.patientId },
      res => {
        this.groupItems = res.data.groups;
        this.patientGroups = res.data.patient_groups;
        this.setLoaded();
      }
    );
  }

  private clickSave(): void {
    this.setParameter();
    // グループ保存
    this.postJsonCheck(
      window.base_url + "/api/patient/group/save",
      {
        patient_groups: this.patientGroups,
        patient_id: this.patientId
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.setNoEditMode();
        this.$router.push({
          name: "PatientCalendar",
          params: { id: String(this.patientId) }
        });
      }
    );
  }

  private setParameter() {
    this.patientGroups.forEach(patientGroup => {
      if (patientGroup.group_id < 1) {
        patientGroup.is_icon_display = 0;
      }
    });
  }

  //編集モード設定
  @Watch("patientGroups", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
