import Base from "../base";

//公費自己負担
export interface PublicMoneySelfBurden extends Base {
  id: number; //ID
  public_money_id: number; //'公費ID',
  target_yearmonth: number; //'対象年月',
  other_medical_amount: string; //'他医療機関',
  self_burden_amount: string; //'自己負担',
  target_yearmonth_disp: string;
}

export const DefaultPublicMoneySelfBurden = (): PublicMoneySelfBurden => ({
  id: 0,
  public_money_id: 0,
  target_yearmonth: 0,
  other_medical_amount: "",
  self_burden_amount: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  target_yearmonth_disp: ""
});
