











import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ScheduleSearchCondition } from "@/components/schedule/common";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";

@Component({
  components: {
    OfficeSelect
  }
})
export default class ScheduleManageOfficeSelect extends Vue {
  /** 検索条件 */
  @Prop({ required: true }) searchCondition!: ScheduleSearchCondition;

  /** 選択事業所変更時 */
  @Emit("change") private change() {
    return;
  }

  /** 選択事業所を変更する */
  private changeOfficeId() {
    // 初期化
    this.searchCondition.patient_ids = [];
    this.searchCondition.group_ids = [];
    this.searchCondition.group_operator = 1;
    this.searchCondition.staff_ids = [];
    this.searchCondition.shifts = [];

    //既に検索条件が設定済みの場合は設定する。
    this.searchCondition.data.forEach(cond => {
      if (cond.office_id !== this.searchCondition.office_id) {
        return;
      }
      this.searchCondition.patient_ids = cond.patient_ids;
      this.searchCondition.group_ids = cond.group_ids;
      this.searchCondition.group_operator = cond.group_operator;
      this.searchCondition.staff_ids = cond.staff_ids;
      this.searchCondition.shifts = cond.shifts;
    });

    this.change();
  }
}
