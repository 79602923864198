








































import AxiosMixin from "@/mixins/axiosMixin";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AppChatSystemIcon from "./AppChatSystemIcon.vue";

@Component({ components: { AppChatSystemIcon } })
export default class AppChatList extends AxiosMixin {
  @Prop() roomName!: string;
  @Prop() latestMessage!: string;
  @Prop() unreadCount!: number;
  @Prop({ default: null }) roomIconPath!: string | null;
  @Prop({ default: false }) isSystemRoom!: boolean;
  @Prop({ default: 0 }) fileType!: number;

  private signImagePath = "";

  created() {
    if (this.roomIconPath !== null) {
      this.postJsonBackground(
        window.auth_backend_url + "/api/sign-image-path/get",
        {
          bucket_path: this.roomIconPath
        },
        res => {
          this.signImagePath = res.data.image_path;
        }
      );
    }
  }

  @Emit()
  private selectRoom() {
    return;
  }
}
