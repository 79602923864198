




































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { OcrTemplateChoice } from "@/types";
import {
  OcrAttr,
  IndicateNormalAttrs,
  MediInsGeneralAttrs,
  TargetKind,
  IndicateMentalAttrs,
  IndicateSpecialAttrs,
  IndicateMentalSpecialAttrs,
  MediInsNationalAttrs,
  CareInsGeneralAttrs,
  CareInsPaymentRateAttrs,
  MediInsOldAgeAttrs,
  MediInsHighExpenseAttrs,
  MediInsOldOldAttrs
} from "@/const/ocr";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";

const Ocr = namespace(ocr.name);

@Component
export default class OcrExecuteDialog extends Mixins(AxiosMixin) {
  @Prop({ default: 0 }) documentType!: number;
  @Prop({
    default: () => {
      return [];
    }
  })
  ocrTemplates!: OcrTemplateChoice[];

  @Ocr.Mutation(ocr.SET_OCR_RESULT) setOcrResult!: Function;
  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Mutation(ocr.SET_DOCUMENT_INFO) setDocumentInfo!: Function;

  private isOpen = false;
  private imagePath = "";
  private fileId = 0;
  private templateId = 0;
  private preTemplateId = 0;
  private ocrMap: { [key: string]: unknown } = {};
  private smartOcrId = 0;
  private patientId = 0;

  private get ocrKeys(): string[] {
    let arr: string[] = [];
    const template = this.ocrTemplates.find(template => {
      return template.value === this.preTemplateId;
    });
    if (!template) {
      return arr;
    }

    const attrs = this.getOcrAttrs(template.target_kind);
    arr = attrs.map(o => {
      return o.key;
    });

    return arr;
  }

  private get createDocumentButtonTitle() {
    const template = this.ocrTemplates.find(template => {
      return template.value === this.preTemplateId;
    });

    if (!template) {
      return "作成";
    }

    switch (template.target_kind) {
      case TargetKind.mediInsGeneral:
      case TargetKind.mediInsNational:
      case TargetKind.mediInsOldOld:
        return "医療保険を作成";
      case TargetKind.mediInsOldAge:
        return "高齢受給者証を作成";
      case TargetKind.mediInsHighExpense:
        return "高額療養費設定を作成";
      case TargetKind.careInsGeneral:
        return "介護保険を作成";
      case TargetKind.careInsPaymentRate:
        return "介護保険負担割合証を作成";
      case TargetKind.indicateNormal:
      case TargetKind.indicateMental:
        return "指示書を作成";
      case TargetKind.indicateSpecial:
      case TargetKind.indicateMentalSpecial:
        return "特別指示書を作成";
      default:
        return "作成";
    }
  }

  public open(imagePath: string, fileId: number) {
    // 初期化
    this.ocrMap = {};
    this.smartOcrId = 0;
    this.templateId = 0;

    this.imagePath = imagePath;
    this.fileId = fileId;
    this.isOpen = true;

    this.postJsonCheck(
      window.base_url + "/api/ocrstore/metainfo/get",
      {
        document_type: this.documentType,
        document_id: this.fileId
      },
      res => {
        this.ocrMap = JSON.parse(res.data.ocr_meta.ocr_map.map);
        this.templateId = res.data.ocr_meta.ocr_map.ocr_template_id;
        this.smartOcrId = res.data.ocr_meta.ocr_status.smart_ocr_id;
        this.preTemplateId = this.templateId;
        this.patientId = res.data.patient_file.patient_id;
      },
      () => {
        this.close();
      }
    );
  }

  private refetchOcrMap() {
    this.postJsonCheck(
      window.base_url + "/api/ocrstore/mapping/update",
      {
        document_type: this.documentType,
        document_id: this.fileId,
        ocr_template_id: this.templateId
      },
      res => {
        this.ocrMap = JSON.parse(res.data.ocr_map.map);
        this.templateId = res.data.ocr_map.ocr_template_id;
        this.preTemplateId = this.templateId;
      }
    );
  }

  private createDocument() {
    const template = this.ocrTemplates.find(template => {
      return template.value === this.preTemplateId;
    });

    if (!template) {
      this.$openAlert(
        "予期せぬエラーが発生しました。\n画面を再読み込みしてお試しください。"
      );
      return;
    }

    this.clearOcrState();

    this.setDocumentInfo({ type: this.documentType, id: this.fileId });
    this.setOcrResult({ targetKind: template.target_kind, map: this.ocrMap });

    switch (template.target_kind) {
      //医療保険
      case TargetKind.mediInsGeneral:
      case TargetKind.mediInsNational:
      case TargetKind.mediInsOldOld:
        this.$router.push({
          name: "InsuranceInfoMed",
          params: { id: String(this.patientId), insmedid: "0" },
          query: { from: "ocr" }
        });
        break;
      //高齢受給者証
      case TargetKind.mediInsOldAge:
        this.$router.push({
          name: "InsuranceInfoEldCert",
          params: { id: String(this.patientId), inseldcertid: "0" },
          query: { from: "ocr" }
        });
        break;
      //高額療養費
      case TargetKind.mediInsHighExpense:
        this.$router.push({
          name: "InsuranceInfoHighCost",
          params: { id: String(this.patientId), inshighcostid: "0" },
          query: { from: "ocr" }
        });
        break;
      //介護保険
      case TargetKind.careInsGeneral:
        this.$router.push({
          name: "InsuranceInfoCare",
          params: { id: String(this.patientId), inscareid: "0" },
          query: { from: "ocr" }
        });
        break;
      //介護保険負担割合証
      case TargetKind.careInsPaymentRate:
        this.$router.push({
          name: "InsuranceInfoCareRateCert",
          params: { id: String(this.patientId), inscareratecertid: "0" },
          query: { from: "ocr" }
        });
        break;
      //指示書
      case TargetKind.indicateNormal:
      case TargetKind.indicateMental:
        this.$router.push({
          name: "PatientIndicateEdit",
          params: { id: String(this.patientId), indicateid: "0" },
          query: { from: "ocr" }
        });
        break;
      //特別指示書
      case TargetKind.indicateSpecial:
      case TargetKind.indicateMentalSpecial:
        this.$router.push({
          name: "PatientSpecialIndicateEdit",
          params: { id: String(this.patientId), specialindicateid: "0" },
          query: { from: "ocr" }
        });
        break;
    }
  }

  private updateOcrMap() {
    this.postJsonCheck(
      window.base_url + "/api/ocrstore/mapping/save",
      {
        document_type: this.documentType,
        document_id: this.fileId,
        map: JSON.stringify(this.ocrMap)
      },
      res => {
        this.ocrMap = JSON.parse(res.data.ocr_map.map);
      }
    );
  }

  private close() {
    this.isOpen = false;
  }

  private isTextArea(key: string): boolean {
    const template = this.ocrTemplates.find(template => {
      return template.value === this.preTemplateId;
    });
    if (!template) {
      return false;
    }

    const attrs = this.getOcrAttrs(template.target_kind);
    const arr = attrs.filter(o => {
      return o.key === key;
    });

    if (arr.length === 1) {
      return arr[0].type === 2;
    }

    return false;
  }

  private getOcrAttrs(targetKind: number): OcrAttr[] {
    let attrs: OcrAttr[] = [];
    switch (targetKind) {
      //医療保険
      case TargetKind.mediInsGeneral: //（一般）
        attrs = MediInsGeneralAttrs;
        break;
      case TargetKind.mediInsNational: //（国保）
        attrs = MediInsNationalAttrs;
        break;
      case TargetKind.mediInsOldOld: //（後期高齢）
        attrs = MediInsOldOldAttrs;
        break;
      //高齢受給者証
      case TargetKind.mediInsOldAge:
        attrs = MediInsOldAgeAttrs;
        break;
      //高齢受給者証
      case TargetKind.mediInsHighExpense:
        attrs = MediInsHighExpenseAttrs;
        break;
      //介護保険
      case TargetKind.careInsGeneral:
        attrs = CareInsGeneralAttrs;
        break;
      //介護保険負担割合証
      case TargetKind.careInsPaymentRate:
        attrs = CareInsPaymentRateAttrs;
        break;
      //指示書
      case TargetKind.indicateNormal:
        attrs = IndicateNormalAttrs;
        break;
      case TargetKind.indicateMental:
        attrs = IndicateMentalAttrs;
        break;
      //特別指示書
      case TargetKind.indicateSpecial:
        attrs = IndicateSpecialAttrs;
        break;
      case TargetKind.indicateMentalSpecial:
        attrs = IndicateMentalSpecialAttrs;
        break;
    }
    return attrs;
  }
}
