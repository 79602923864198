


















































































import { Component, Watch } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class OneMonthAdd extends MedPartBase {
  created(): void {
    this.setHour24Div();
    this.setMedicalSpecialDiv();
  }

  //24時間体制
  protected setHour24Div() {
    if (this.receiptVisitRecord.hour24_div == 1) {
      this.hour24_div1 = true;
    }
    if (this.receiptVisitRecord.hour24_div == 2) {
      this.hour24_div2 = true;
    }
  }

  //特別管理加算
  protected setMedicalSpecialDiv() {
    if (this.receiptVisitRecord.medical_special_div == 1) {
      this.medical_special_div1 = true;
      this.medical_special_div2 = false;
    }
    if (this.receiptVisitRecord.medical_special_div == 2) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = true;
    }
  }

  /** 24時間対応体制 */
  protected hour24_div1 = false;

  /** 24時間連絡体制 */
  protected hour24_div2 = false;

  /** 特別管理加算 */
  protected medical_special_div1 = false;

  /** 重症度等の高い患者 */
  protected medical_special_div2 = false;

  //24時間対応体制
  @Watch("hour24_div1") protected handleHour24Div1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.hour24_div1 == true) {
      this.receiptVisitRecord.hour24_div = 1;
    }
    if (this.hour24_div1 == false) {
      this.receiptVisitRecord.hour24_div = 0;
    }
  }

  //24時間連絡体制
  @Watch("hour24_div2") protected handleHour24Div2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.hour24_div2 == true) {
      this.receiptVisitRecord.hour24_div = 2;
    }
    if (this.hour24_div2 == false) {
      this.receiptVisitRecord.hour24_div = 0;
    }
  }

  //特別管理加算
  @Watch("medical_special_div1") protected handleMedicalSpecialDiv1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.medical_special_div1 == true) {
      this.receiptVisitRecord.medical_special_div = 1;
      this.medical_special_div2 = false;
    }
    if (
      this.medical_special_div1 == false &&
      this.medical_special_div2 == false
    ) {
      this.receiptVisitRecord.medical_special_div = 0;
    }
  }

  //重症度等の高い患者
  @Watch("medical_special_div2") protected handleMedicalSpecialDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.medical_special_div2 == true) {
      this.receiptVisitRecord.medical_special_div = 2;
      this.medical_special_div1 = false;
    }
    if (
      this.medical_special_div1 == false &&
      this.medical_special_div2 == false
    ) {
      this.receiptVisitRecord.medical_special_div = 0;
    }
  }

  //ターミナルケア
  protected disableCheckMedicalTerminalDiv(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_terminal_care_add == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //特別管理指導
  protected disableCheckSpecialInstFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_tokubetsu_kanri_add != 1 ||
      this.receiptVisitRecord.medical_leave_inst_flg != 1 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //24時間体制
  protected disableCheckMedicalHour24Add(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //特別管理加算
  protected disableCheckMedicalSpecialDiv1(): boolean {
    let result = false;
    if (
      (this.event.indicate.temporary_covid19_div == 0 &&
        this.officeHistory.medical_tokubetsu_kanri_add == 0) ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //特別管理加算(重症度等の高いもの)
  protected disableCheckMedicalSpecialDiv2(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_tokubetsu_kanri_add == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //在宅患者連携指導
  protected disableCheckHomeInstFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //精神科重症患者支援管理連携加算
  protected disableCheckMentalSupportDiv(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_seishin_renkei_add != 1 ||
      this.event.visit_record.mental_div == 1 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //情報提供療養費
  protected disableCheckInfoExpensesFlg(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.stay_outside_flg == 1 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //ターミナルケア
    if (this.disableCheckMedicalTerminalDiv()) {
      this.receiptVisitRecord.medical_terminal_div = 0;
    }
    //特別管理指導
    if (this.disableCheckSpecialInstFlg()) {
      this.receiptVisitRecord.special_inst_flg = 0;
    }
    //24時間体制
    if (this.disableCheckMedicalHour24Add()) {
      this.hour24_div1 = false;
      this.hour24_div2 = false;
      this.receiptVisitRecord.hour24_div = 0;
    }
    //特別管理加算
    if (
      this.disableCheckMedicalSpecialDiv1() &&
      this.disableCheckMedicalSpecialDiv2()
    ) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = false;
      this.receiptVisitRecord.medical_special_div = 0;
    }
    //在宅患者連携指導
    if (this.disableCheckHomeInstFlg()) {
      this.receiptVisitRecord.home_inst_flg = 0;
    }
    //精神科重症患者支援管理連携加算
    if (this.disableCheckMentalSupportDiv()) {
      this.receiptVisitRecord.mental_support_div = 0;
    }
    //情報提供療養費
    if (this.disableCheckInfoExpensesFlg()) {
      this.receiptVisitRecord.info_expenses_flg1 = 0;
    }
  }
}
