














































import { Component, Mixins } from "vue-property-decorator";
import EditPrimaryContentDialog from "@/components/master/adlitem/EditPrimaryContentDialog.vue";
import EditSecondaryContentDialog from "@/components/master/stdevalitem/EditSecondaryContentDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  StdEvalItemPrimaryContent,
  StdEvalItemSecondaryContent
} from "./types";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import { DataTableHeader } from "vuetify";

@Component({
  components: {
    CommonMasterList,
    EditPrimaryContentDialog,
    EditSecondaryContentDialog
  }
})
export default class StdEvalItemList extends Mixins(AxiosMixin) {
  private isShownEditSecondaryContentDialog = false;
  private selectedSecondaryContent?: StdEvalItemSecondaryContent = undefined;
  private primaryContents: StdEvalItemPrimaryContent[] = [];

  private hint =
    "評価項目基準は、月次報告書の[(別添)評価]で表示される項目です。ここでは[(別添)評価]の[評価参考表]に表示される評価に対する説明が編集できます。なお、月次報告書の[(別添)評価]は、月次報告書の作成者にPT/OT/STを選択した時に表示されます。";
  private headers: DataTableHeader[] = [
    {
      text: "評価項目基準",
      value: "name"
    },
    {
      text: "",
      value: "select_items",
      width: "1px",
      sortable: false
    }
  ];

  private subHeaders: DataTableHeader[] = [
    {
      text: "選択肢",
      value: "name"
    }
  ];

  public created(): void {
    this.fetchStdEvalItems();
  }

  private fetchStdEvalItems() {
    this.postJsonCheck(
      window.base_url + "/api/master/std_eval_entry/get",
      {},
      res => {
        this.primaryContents = res.data.std_eval_items;
      }
    );
  }

  private async save(content: StdEvalItemPrimaryContent) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/master/std_eval_entry/save",
      {
        std_eval_item: content
      },
      () => {
        this.fetchStdEvalItems();
        this.clickCloseEditSecondaryContentDialogButton();
      }
    );
  }

  private saveSecondaryContent(content: StdEvalItemSecondaryContent): void {
    const index = this.primaryContents.findIndex(
      p => p.id == content.std_eval_item_id
    );
    if (index > -1) {
      if (content.id) {
        const updItemIndex = this.primaryContents[index].select_items.findIndex(
          item => item.id == content.id
        );
        if (updItemIndex > -1) {
          this.primaryContents[index].select_items[updItemIndex].description =
            content.description;
        }
      }
      this.save(this.primaryContents[index]);
    }
  }

  private clickEditSecondaryButton(content: StdEvalItemSecondaryContent) {
    this.selectedSecondaryContent = content;
    this.isShownEditSecondaryContentDialog = true;
  }

  private clickCloseEditSecondaryContentDialogButton() {
    this.isShownEditSecondaryContentDialog = false;
  }
}
