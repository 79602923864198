import Base from "./base";
import {
  ApplyStaff,
  DefaultApplyStaff,
  ApplyAdlEvaluation,
  DefaultApplyAdlEvaluation
} from "./common";

//ADL項目
export interface AdlItem extends Base {
  id: number;
  adl_id: number; //'ADLID',
  sort_no: number; //'並び順',
  is_refer: number; //'心身の状態反映フラグ\n0:反映しない\n1:反映する',
  adl_evaluation_item_id: number; //'ADL評価項目ID',
  adl_evaluation_select_item_id: number; //'ADL評価項目選択肢ID',
  adl_evaluation: ApplyAdlEvaluation; //ADL評価項目
}
export const DefaultAdlItem = (): AdlItem => ({
  id: 0,
  adl_id: 0,
  sort_no: 0,
  is_refer: 1,
  adl_evaluation_item_id: 0,
  adl_evaluation_select_item_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  adl_evaluation: DefaultApplyAdlEvaluation()
});

//ADL
export interface Adl extends Base {
  id: number; //ID
  patient_id: number; //利用者ID
  status: number; //'状態\n1:一時保存\n2:提出',
  evaluation_date: string; //評価日
  evaluation_staff_id: number; //評価職員ID
  is_comment_to_state: number; //コメント心身の状態反映フラグ
  comment: string; //コメント
  mind_and_body_state: string; //心身の状態
  evaluation_date_wareki: string; //評価日(和暦)
  items: AdlItem[]; //ADL項目
  evaluation_staff: ApplyStaff; //評価職員
}
export const DefaultAdl = (): Adl => ({
  id: 0,
  patient_id: 0,
  status: 0,
  evaluation_date: "",
  evaluation_staff_id: 0,
  is_comment_to_state: 0,
  comment: "",
  mind_and_body_state: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  evaluation_date_wareki: "",
  items: [DefaultAdlItem()],
  evaluation_staff: DefaultApplyStaff()
});
