





































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class NavigationLayerStaffScheduleToggle extends Vue {
  /** チェック状態 */
  @Prop() value!: unknown;

  /** 表示状態 */
  @Prop() display!: boolean;

  /** チェック状態更新時 */
  @Emit() input(value: unknown) {
    return value;
  }

  /** 全ての職員の予定を表示する */
  @Emit() clickCalendarEvent() {
    return;
  }

  /** 全ての職員の訪問を表示する */
  @Emit() clickVisitEvent() {
    return;
  }

  /** 全ての職員のシフトを表示する */
  @Emit() clickShiftEvent() {
    return;
  }

  /** チェック状態変更時 */
  @Emit() change() {
    return;
  }

  /** チェック状態 */
  private get Value(): unknown {
    return this.value;
  }

  /** チェック状態 */
  private set Value(newVal: unknown) {
    this.input(newVal);
  }
}
