import Base from "../base";
import {
  ApplyStaff,
  DefaultApplyStaff,
  ApplyPatient,
  DefaultApplyPatient,
  ApplyDoctor,
  ApplyPersonnel,
  DefaultApplyPersonnel,
  DefaultApplyDoctor
} from "../common";

// 引き当て利用者
export interface PdfLinkApplyPatient extends ApplyPatient {
  age: number; // 年齢
}
export const DefaultPdfLinkApplyPatient = (): PdfLinkApplyPatient => ({
  ...DefaultApplyPatient(),
  age: 0
});

// PDFリンク情報
export interface PdfLink extends Base {
  id: number;
  agreement_id: number;
  patient_id: number; // 利用者ID
  editor_staff_id: number; // 編集した職員ID
  send_datetime: string; // 送信日時
  status: number; // ステータス 1:下書き 2:送信済み
  resend: number; // 再送信であるか 0:いいえ 1:はい
  comment: string; // メール本文中、定型文とは別の追加コメント
  expired_date: string; // 有効期限日
  confirm_pdf_link: number; // メールアドレス確認画面表示フラグ 0:非表示 1:表示
  is_expired: number; // PDFの閲覧期限は切れているか
  send_datetime_wareki: string; // メール送信リクエスト日時（和暦）
  expired_date_wareki: string; // 有効期限日（和暦）
  updated_at_wareki: string; // 更新日時（和暦）
  mails: PdfLinkMail[]; // PDFリンクメール送信先
  reports: PdfLinkReport[]; // PDFリンクメール帳票patient
  patient: PdfLinkApplyPatient; // 引き当て利用者
  editor_staff: ApplyStaff; // 引き当て編集職員
}
export const DefaultPdfLink = (): PdfLink => ({
  id: 0,
  agreement_id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  send_datetime: "",
  status: 0,
  resend: 0,
  comment: "",
  expired_date: "",
  confirm_pdf_link: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  is_expired: 0,
  send_datetime_wareki: "",
  expired_date_wareki: "",
  updated_at_wareki: "",
  mails: [],
  reports: [],
  patient: DefaultPdfLinkApplyPatient(),
  editor_staff: DefaultApplyStaff()
});

// PDFリンクメール送信先
export interface PdfLinkMail extends Base {
  id: number;
  agreement_id: number;
  pdf_link_id: number; // PDFリンク情報ID
  mail_address: string; // 送信先メールアドレス
  mail_key: string; // 送信先ごとに発行したランダム文字列
  institution_div: number; // 送付先担当者の所属機関区分 1:関連機関 2:医療機関
  personnel_id: number; // 送信先担当者ID
  read_datetime: string; // メール受取人の既読日時
  is_read: number; // メール受取人が送信済みPDF一覧を見たか
  read_datetime_wareki: string; // メール受取人の既読日時（和暦）
  institution_name: string; // 送信先機関名
  personnel_name: string; // 送信先担当者名
  send_status: number; // メール送信リクエストの成否
  personnel: ApplyPersonnel; // 引き当て担当者情報
  doctor: ApplyDoctor; // 引き当て医師情報
}
export const DefaultPdfLinkMail = (): PdfLinkMail => ({
  id: 0,
  agreement_id: 0,
  pdf_link_id: 0,
  mail_address: "",
  mail_key: "",
  institution_div: 0,
  personnel_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  read_datetime: "",
  is_read: 0,
  read_datetime_wareki: "",
  institution_name: "",
  personnel_name: "",
  send_status: 0,
  personnel: DefaultApplyPersonnel(),
  doctor: DefaultApplyDoctor()
});

// PDFリンクメールで送ったリンクに紐づく帳票
export interface PdfLinkReport extends Base {
  id: number;
  agreement_id: number;
  pdf_link_id: number; // PDFリンク情報ID
  report_type: number; // 帳票種類 1:記録書2 2:褥瘡対策看護計画 3:看護サマリ 4:会議録 5:月次報告書 6:ヒヤリハット報告書 7:情報提供書 8:サービス確認書 9:看護計画書 10:看護記録1 11:情報提供書(口腔)
  report_type_name: string; // 帳票種類名
  report_id: number; // 帳票ID
  pdf_path: string; // PDFファイルパス
  report_staff_name: string; // 担当者
  write_date: string; // 帳票の記載日、種類によっては訪問日や褥瘡計画作成日
  submit_staff_name: string; // 提出者
  submit_datetime: string; // 提出日時
  description: string; // 帳票の説明
  write_date_wareki: string; // 帳票の記載日（和暦）
  submit_date_wareki: string; // 帳票の提出日（和暦）
  personnel_pdfs: PdfLinkPersonnelPdf[]; // PDFリンク帳票のPDF
}
export const DefaultPdfLinkReport = (): PdfLinkReport => ({
  id: 0,
  agreement_id: 0,
  pdf_link_id: 0,
  report_type: 0,
  report_type_name: "",
  report_id: 0,
  pdf_path: "",
  report_staff_name: "",
  write_date: "",
  submit_staff_name: "",
  submit_datetime: "",
  description: "",
  personnel_pdfs: [],
  created_at: "",
  updated_at: "",
  deleted_at: "",
  write_date_wareki: "",
  submit_date_wareki: ""
});

// 送信先宛PDF
export interface PdfLinkPersonnelPdf extends Base {
  id: number;
  pdf_link_report_id: number; // PDFリンク帳票ID
  mail_key: string; // 送信先ごとに発行したランダム文字列
  pdf_path: string; // PDFファイルパス
}
export const DefaultPdfLinkPersonnelPdf = (): PdfLinkPersonnelPdf => ({
  id: 0,
  pdf_link_report_id: 0,
  mail_key: "",
  pdf_path: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
