

































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { BulletinBoard, DefaultBulletinBoard } from "#/model/bulletinboard";
import { DefaultApplyStaff } from "#/model/common";
import { VForm } from "@/types";

interface InfoItems {
  id: number;
  date: string;
  staff_name: string;
  comment: string;
  button: boolean;
}

@Component({
  components: {}
})
export default class BbsDetail extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("test_bbs_comment") private readonly form!: VForm;

  private bbsId = 0;
  private createDialog = false;

  private bulletin_board: BulletinBoard = DefaultBulletinBoard(); //掲示板トピック
  private authDiv = 0; //職員権限区分\n0:一般職員\n1:一般職員以外（一般職員以外は、新規トピックの作成・自分以外のトピックの削除・自分以外のコメントの削除が可能）
  private staffId = 0; //自身のeditor_staff_id
  private title = ""; //掲示板タイトル
  private authorName = ""; //掲示板作成者
  private createdDate = ""; //掲示板作成日時
  private content = ""; //掲示板本文
  private commentItems: InfoItems[] = []; //掲示板コメント
  private comment = ""; //入力されたコメント

  private created() {
    this.bbsId = Number(this.$route.params.bbsid);
    this.getBbs();
  }

  //コメント未入力時ボタンdisabled
  private get btnStatus(): boolean {
    if (this.comment.match(/.*\S+.*/)) {
      return false;
    }
    return true;
  }

  //入力コメント追加
  private pushComment() {
    this.bulletin_board.bulletin_board_comments.push({
      id: 0,
      bulletin_board_id: 0,
      editor_staff_id: this.staffId,
      comment: this.comment,
      created_at: "",
      updated_at: "",
      deleted_at: "",
      updated_at_wareki: "",
      editor_staff: DefaultApplyStaff()
    });
  }

  //コメント入力
  private async create() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/bbscomment/create",
      {
        bulletin_board_comment: {
          id: 0,
          bulletin_board_id: 0,
          editor_staff_id: this.staffId,
          comment: this.comment,
          created_at: "",
          updated_at: "",
          deleted_at: "",
          updated_at_wareki: "",
          editor_staff: DefaultApplyStaff()
        }
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.comment = "";
        this.getBbs();
      }
    );
  }

  //トピック削除
  private async deleteBbs() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.postJsonCheck(
        window.base_url + "/api/bbs/delete",
        {
          bulletin_board: this.bulletin_board
        },
        () => {
          this.$router.go(-1);
        }
      );
    }
  }

  //コメント削除
  private async deleteComment(idx: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.postJsonCheck(
        window.base_url + "/api/bbscomment/delete",
        {
          bulletin_board_comment: this.bulletin_board.bulletin_board_comments[
            idx
          ]
        },
        () => {
          this.getBbs();
        }
      );
    }
  }

  //掲示板取得
  private getBbs(): void {
    this.postJsonCheck(
      window.base_url + "/api/bbs/get",
      {
        bbs_id: this.bbsId
      },
      res => {
        this.authDiv = res.data.auth_div;
        this.staffId = res.data.staff_id;
        this.title = res.data.title;
        this.authorName = res.data.author_name;
        this.createdDate = res.data.created_date;
        this.content = res.data.content;
        this.commentItems = res.data.comment_items;
        this.bulletin_board = res.data.bulletin_board;
      }
    );
  }
}
