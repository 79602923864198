






































import { Component } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class TwoMonthAdd extends MedPartBase {
  //退院時共同指導
  protected disableCheckMedicalLeaveInstFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //特別管理指導
  protected disableCheckSpecialInstFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_tokubetsu_kanri_add != 1 ||
      this.receiptVisitRecord.medical_leave_inst_flg != 1 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //在宅患者緊急時カンファレンス
  protected disableCheckHomeUrgentConfFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //退院時共同指導
    if (this.disableCheckMedicalLeaveInstFlg()) {
      this.receiptVisitRecord.medical_leave_inst_flg = 0;
    }
    //特別管理指導
    if (this.disableCheckSpecialInstFlg()) {
      this.receiptVisitRecord.special_inst_flg = 0;
    }
    //在宅患者緊急時カンファレンス
    if (this.disableCheckHomeUrgentConfFlg()) {
      this.receiptVisitRecord.home_urgent_conf_flg = 0;
    }
  }
}
