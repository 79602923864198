






































import { Component } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { DataTableHeader } from "vuetify/types/index";
import { BreadItem } from "@/store/modules/patient/types";
import { TableDataDetail } from "@/components/officeAggregate/unitCost/types";

@Component
export default class UnitCostDetail extends Base {
  private officeId = 0; //事業所ID
  private officeName = ""; //事業所名
  private yearmonth = ""; //年月
  private tableDatas: TableDataDetail[] = []; //テーブルデータ

  //画面上部情報
  private get date() {
    const [year, month] = this.yearmonth.split("-");
    return `年月：${year}年${month}月`;
  }

  // パンくずリスト
  private breadItems: BreadItem[] = [];

  //データテーブルヘッダー
  private headers: DataTableHeader[] = [
    {
      value: "staff_name",
      text: "職員名",
      width: "110px"
    },
    {
      value: "starttime_archive",
      text: "勤務開始",
      width: "150px"
    },
    {
      value: "endtime_archive",
      text: "勤務終了",
      width: "150px"
    },
    {
      value: "rest_time_archive",
      text: "休憩時間（分）",
      width: "100px"
    },
    {
      value: "office_hours",
      text: "実勤務時間（分）",
      width: "100px"
    }
  ];

  public async created() {
    this.officeId = Number(this.$route.query.id);
    this.officeName = String(this.$route.query.office);
    this.yearmonth = String(this.$route.query.yearmonth);

    this.breadItems = [
      {
        text: "勤務売上情報",
        disabled: false,
        to: "/office_aggregate/unit_cost",
        exact: true
      },
      {
        text: "勤務時間詳細",
        disabled: true,
        to: ""
      }
    ];
    await this.getDetail();
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // Excelダウンロード
  private downloadExcel() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/officeAggregate/unitCost/detail/downloadexcel",
      {
        table_datas: this.tableDatas,
        office_name: this.officeName,
        yearmonth: this.yearmonth
      },
      res => {
        const fileName = "unitCostDetail.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 詳細データ取得
  //-----------------------------------------------------
  private async getDetail() {
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/unitCost/getdetail",
        {
          office_id: this.officeId,
          yearmonth: this.yearmonth
        },
        res => {
          this.tableDatas = res.data.table_datas;
          resolve(0);
        }
      );
    });
  }
}
