

























































































































import { Component, Ref } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import BiOfficeSelect from "@/components/officeAggregate/BiOfficeSelect.vue";
import {
  ChartData,
  TableData,
  SearchCondition
} from "@/components/officeAggregate/receiptSales/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {
    BiOfficeSelect
  }
})
export default class ReceiptSales extends Base {
  @Ref("receipt_sales") private readonly form!: VForm;

  private chart!: am4charts.XYChart; //アムチャート
  private chartDatas: ChartData[] = []; //アムチャートデータ
  private tableDatas: TableData[] = []; //テーブルデータ
  private officeId = 0; //検索条件：事業所ID
  private start = ""; //検索条件：請求開始月
  private end = ""; //検索条件：請求終了月

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      text: "年月",
      value: "date",
      align: "start",
      width: ""
    },
    {
      text: "医療",
      value: "medical",
      align: "start",
      width: ""
    },
    {
      text: "介護",
      value: "care",
      align: "start",
      width: ""
    },
    {
      text: "予防",
      value: "prevent_care",
      align: "start",
      width: ""
    },
    {
      text: "自費",
      value: "own_expence",
      align: "start",
      width: ""
    },
    {
      text: "",
      value: "actions",
      align: "center",
      width: "75px",
      sortable: false
    }
  ];

  public async mounted() {
    this.collection = "office-aggregate-receipt-sales";
    this.documentId = String(this.loginUser.id);
    const fbDoc = await this.documentGet();

    //親画面で保存した検索条件
    const parentFbDoc = await this.getParentSearchCon();

    //デフォルトの検索条件
    this.officeId = this.offices();
    this.start = this.lastmonth();
    this.end = this.todaymonth();

    if (parentFbDoc !== undefined && typeof parentFbDoc !== "boolean") {
      const searchCondition = parentFbDoc;
      this.start = searchCondition?.start;
      this.end = searchCondition?.end;
    }
    if (fbDoc !== undefined && typeof fbDoc !== "boolean") {
      const searchCondition = fbDoc.searchCondition as SearchCondition;
      this.officeId = searchCondition?.office_id;
    }

    this.disposeChart();
    // 初期メッセージ
    this.chart = this.xyChartMessage();
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.XYChart) {
    // 拡大スクロール
    const scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;

    //日付ラベル
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.dateFormatter.language = new am4core.Language();
    dateAxis.dateFormatter.language.locale = am4lang_ja_JP;
    dateAxis.language.locale["_date_day"] = "MMM";
    dateAxis.language.locale["_date_year"] = "yyyy年";
    dateAxis.baseInterval = {
      timeUnit: "month",
      count: 1
    };

    // y軸メモリ
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) {
      valueAxis.tooltip.disabled = true;
    }
    let max = 0;
    this.tableDatas.forEach((item: TableData) => {
      const total =
        item.medical + item.care + item.prevent_care + item.own_expence;
      if (total > max) {
        max = total;
      }
    });
    if (max < 10000) {
      valueAxis.min = 0;
      valueAxis.max = 10000;
    }

    valueAxis.title.text = "売上金額(円)";

    //グラフ
    const medicalSeries = chart.series.push(new am4charts.LineSeries());
    this.series(medicalSeries, "医療", "medical");

    const careSeries = chart.series.push(new am4charts.LineSeries());
    this.series(careSeries, "介護", "care");

    const preventCareSeries = chart.series.push(new am4charts.LineSeries());
    this.series(preventCareSeries, "予防", "prevent_care");

    const ownExpenceSeries = chart.series.push(new am4charts.LineSeries());
    this.series(ownExpenceSeries, "自費", "own_expence");

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    return chart;
  }

  //グラフ
  private series(series: am4charts.LineSeries, name: string, valueY: string) {
    series.name = name;
    series.dataFields.dateX = "date";
    series.dataFields.valueY = valueY;
    series.tooltipText = "[#000]{valueY.value}[/]";
    if (series.tooltip) {
      series.tooltip.background.fill = am4core.color("#FFF");
      series.tooltip.getStrokeFromObject = true;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.strokeWidth = 3;
    }
    series.fillOpacity = 0.6;
    series.strokeWidth = 2;
    series.stacked = true;
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      alert("入力内容に不備があります");
      return;
    }
    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  // 詳細ボタン
  private clickDetail(item: TableData) {
    const officeName = this.getOfficeName(item.office_id);
    const url = "/office_aggregate/receipt_sales/detail";
    this.$router.push(
      url +
        `?id=${item.office_id}` +
        `&office=${officeName}` +
        `&yearmonth=${item.date}`
    );
  }

  // Excelダウンロード
  private downloadExcel() {
    const officeName = this.getOfficeName(this.officeId);
    this.postJsonBlobResCheck(
      window.base_url + "/api/officeAggregate/receiptSales/downloadexcel",
      {
        table_datas: this.tableDatas,
        start: this.start,
        end: this.end,
        office_name: officeName
      },
      res => {
        const fileName = "receiptSales.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    const searchCondition = {
      office_id: this.officeId,
      start: this.start,
      end: this.end
    };
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/receiptSales/search",
        searchCondition,
        res => {
          this.chartDatas = res.data.chart_datas;
          this.chartDatas.reverse();
          this.tableDatas = res.data.table_datas;
          this.documentSave({
            searchCondition: searchCondition
          });
          resolve(0);
        }
      );
    });
  }
}
