var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"care-time-div"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.receiptVisitRecord.staff_div == 1 ||
        _vm.receiptVisitRecord.staff_div == 2 ||
        _vm.receiptVisitRecord.staff_div == 3
    ),expression:"\n      receiptVisitRecord.staff_div == 1 ||\n        receiptVisitRecord.staff_div == 2 ||\n        receiptVisitRecord.staff_div == 3\n    "}]},[_c('AppSubTitle',{staticClass:"mb-2",attrs:{"label":"時間区分"}}),_c('AppSelect',{attrs:{"id":(_vm.prefix_id + "-nursing-time-div"),"items":_vm.masters.nursing_time_div,"clearable":false,"rules":[_vm.chackNursingTimeDiv()]},model:{value:(_vm.receiptVisitRecord.nursing_time_div),callback:function ($$v) {_vm.$set(_vm.receiptVisitRecord, "nursing_time_div", $$v)},expression:"receiptVisitRecord.nursing_time_div"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.receiptVisitRecord.staff_div == 4 ||
        _vm.receiptVisitRecord.staff_div == 5 ||
        _vm.receiptVisitRecord.staff_div == 6
    ),expression:"\n      receiptVisitRecord.staff_div == 4 ||\n        receiptVisitRecord.staff_div == 5 ||\n        receiptVisitRecord.staff_div == 6\n    "}]},[_c('AppSubTitle',{staticClass:"mb-2",attrs:{"label":"提供時間"}}),_c('div',[_vm._v(_vm._s(_vm.dispNursingTimeDiv))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }