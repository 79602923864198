import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import * as types from "./types";

export const getters: GetterTree<types.BbsState, RootState> = {
  iBowHeadlineIds: (state: types.BbsState): number[] => {
    return state.ibowHeadlines.map(a => {
      return a.id;
    });
  },
  ewellHeadlineIds: (state: types.BbsState): number[] => {
    return state.ewellHeadlines.map(a => {
      return a.id;
    });
  },
  supportHeadlineIds: (state: types.BbsState): number[] => {
    return state.supportHeadlines.map(a => {
      return a.id;
    });
  },
  unreadCountiBow: (state: types.BbsState): number => {
    let count = 0;
    state.ibowHeadlines.forEach(h => {
      if (h.icon === "未読") count++;
    });

    return count;
  },
  unreadCountEwell: (state: types.BbsState): number => {
    let count = 0;
    state.ewellHeadlines.forEach(h => {
      if (h.icon === "未読") count++;
    });

    return count;
  },
  unreadCountSupport: (state: types.BbsState): number => {
    let count = 0;
    state.supportHeadlines.forEach(h => {
      if (h.icon === "未読") count++;
    });

    return count;
  }
};
