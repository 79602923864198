





































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { CalendarEvent, DefaultCalendarEvent } from "@/types";

@Component
export default class AbsenseDialog extends Mixins(AxiosMixin, UtilMixin) {
  /** 不在イベント */
  @Prop({ default: () => DefaultCalendarEvent() })
  private readonly adsenceEvent!: CalendarEvent;

  /** 利用者ID */
  @Prop() private readonly patientId!: number;

  /** データアップデート */
  @Emit() private update() {
    return;
  }

  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** 不在情報削除チェックボックスの値 */
  private checkDelete = 0;

  /** 不在イベント */

  public open() {
    this.isOpen = true;
  }

  private cancel() {
    this.checkDelete = 0;
    this.isOpen = false;
  }

  /** 不在情報削除チェックボックスをクリック */
  private async checkDeleteAdsence() {
    if (this.checkDelete == 0) {
      return;
    }
    if (
      await this.$openConfirm(
        "ここにチェックを入れて削除すると完全に削除されます。復旧することはできません。本当に、チェックを入れてよろしいですか？"
      )
    ) {
      this.checkDelete = 1;
      return;
    }
    this.$nextTick(() => {
      this.checkDelete = 0;
    });
  }

  /** 削除ボタンをクリック */
  private async clickDelete() {
    if (this.checkDelete == 0) {
      await this.$openAlert("削除のチェックがない為、削除ができません");
      return;
    }

    if (
      await this.$openConfirm(
        this.adsenceEvent.visitor_name +
          "さんが登録した不在情報を削除しますか？" +
          "\n" +
          "＊復旧はできません"
      )
    ) {
      this.postJsonCheck(
        window.base_url + "/api/patient/calendar/absence/delete",
        {
          patient_id: this.patientId,
          id: this.adsenceEvent.visit_record_id
        },
        () => {
          this.isOpen = false;
          this.update();
        }
      );
    }
  }
}
