// 共通

/** 記事ステータス：保存 */
export const STATUS_SAVE = 1;
/** 記事ステータス：提出 */
export const STATUS_SUBMIT = 2;
/** 記事ステータス：差し戻し */
export const STATUS_REMAND = 3;

/** 記事ステータス */
export const REPORT_STATUS = {
  /** 記事ステータス：保存 */
  SAVE: 1,
  /** 記事ステータス：提出 */
  SUBMIT: 2,
  /** 記事ステータス：差し戻し */
  REMAND: 3
};
