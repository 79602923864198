var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-master-list"},[_c('AppDataTable',{staticClass:"elevation-2 mt-2",attrs:{"hide-default-footer":"","no-data-text":"データがありませんでした。","single-expand":"","isNoFooter":"true","item-class":_vm.recordClass,"headers":_vm.ActionHeaders,"items":_vm.items,"disabled":_vm.isSortMode,"search":_vm.searchtext,"is-no-filter-button":true,"show-expand":_vm.showExpand,"custom-filter":_vm.filterNestItem,"expanded":_vm.expandedItem},on:{"update:search":function($event){_vm.searchtext=$event},"update:expanded":function($event){_vm.expandedItem=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-4 pb-0",attrs:{"no-gutters":""}},[_c('div',{staticClass:"flex-grow-1 d-flex pb-4"},[_c('AppTextField',{staticClass:"mr-4",style:({ maxWidth: _vm.searchWidth }),attrs:{"label":"検索","disabled":_vm.isSortMode},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('AppIcon',{attrs:{"icon":"search"}})]},proxy:true}]),model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}}),_vm._t("search-append")],2),_c('v-spacer'),_vm._t("action",null,{"isSortMode":_vm.isSortMode}),(_vm.isNewItem)?_c('AppButton',{attrs:{"outlined":"","disabled":_vm.isSortMode,"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.newItem.apply(null, arguments)}}},[_c('AppIcon',{attrs:{"icon":"add-2","fill":_vm.isSortMode
                ? _vm.$vuetify.theme.themes.light.stroke
                : _vm.$vuetify.theme.themes.light.primary}}),_vm._v(" 新しく追加する")],1):_vm._e(),(!_vm.isSortMode && _vm.isOrder)?_c('AppButton',{attrs:{"color":"primary ml-2"},on:{"click":function($event){return _vm.startSort()}}},[_vm._v("並び替え")]):_vm._e(),(_vm.isSortMode && _vm.isOrder)?[_c('AppButton',{staticClass:"ml-2",on:{"click":function($event){return _vm.cancelSort()}}},[_vm._v("キャンセル")]),_c('AppButton',{staticClass:"ml-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.endSort()}}},[_vm._v("完了")])]:_vm._e()],2)]},proxy:true},{key:("item." + _vm.subItemKey),fn:function(){return undefined},proxy:true},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [(_vm.isEditFunc(item))?[(_vm.isCopy)?_c('AppButton',{staticClass:"ma-2",attrs:{"color":"primary","outlined":"","disabled":_vm.isSortMode,"width":"75px"},on:{"click":function($event){$event.stopPropagation();return _vm.copyItem(item)}}},[_vm._v("コピー")]):_vm._e(),_c('AppToolTip',{attrs:{"left":"","top":false,"label":"編集する"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"mr-1 px-0",attrs:{"text":"","disabled":_vm.isSortMode,"height":"40px","width":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"edit","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)})]:_vm._e(),(_vm.getHelpMessage(item) !== '')?_c('AppToolTip',{attrs:{"left":"","top":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('AppIcon',_vm._g(_vm._b({staticClass:"mr-3 pa-0",staticStyle:{"vertical-align":"middle"},attrs:{"icon":"help","fill":_vm.$vuetify.theme.themes.light.greyLight}},'AppIcon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getHelpMessage(item)))])]):_vm._e()]}},{key:"item.icon_path",fn:function(ref){
                var item = ref.item;
return [_c('v-img',{attrs:{"max-height":"36","width":"66px","contain":"","src":item.icon_path}})]}},{key:"item.custom",fn:function(preload){return [_vm._t("item.custom",null,null,preload)]}},{key:"item.offices",fn:function(preload){return [_vm._t("item.offices",function(){return _vm._l((preload.item.offices),function(office){return _c('div',{key:office.id},[_vm._v(" "+_vm._s(office.office.name)+" ")])})},null,preload)]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.ActionSubHeaders,"items":item[_vm.subItemKey],"search":_vm.searchtext,"disable-pagination":"","hide-default-footer":"","no-data-text":"データがありませんでした。"},on:{"click:row":_vm.clickSubRow},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [(_vm.isEditFunc(item))?[(_vm.isDeleteSubFunc(item))?_c('AppButton',{staticClass:"ma-2",attrs:{"color":"alert white--text","outlined":"","width":"75px","disabled":_vm.isSortMode},on:{"click":function($event){$event.stopPropagation();return _vm.clickDeleteSub(item)}}},[_vm._v(" 削除 ")]):_vm._e(),_c('AppToolTip',{attrs:{"left":"","top":false,"label":"編集する"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"mr-1 px-0",attrs:{"text":"","disabled":_vm.isSortMode,"height":"40px","width":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.editSubItem(item)}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"edit","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)})]:_vm._e()]}}],null,true)})],1)])]}},{key:"item.data-table-expand",fn:function(ref){
                var item = ref.item;
                var expand = ref.expand;
                var isExpanded = ref.isExpanded;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item[_vm.subItemKey].length > 0),expression:"item[subItemKey].length > 0"}],attrs:{"height":"initial","width":"initial","icon":"","link":""},on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(isExpanded),expression:"isExpanded"}]},[_vm._v("mdi-chevron-up")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!isExpanded),expression:"!isExpanded"}]},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }