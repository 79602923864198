import { FileIF as BaseFileIF } from "@/views/master/excel/types";
import { Staff } from "#/model/staff";
import { DataTableHeader } from "vuetify";

/** 拡張ファイルインタフェース */
export interface FileIF extends BaseFileIF {
  /** ファイル名 */
  name: string;
}

/** 拡張ファイルインタフェースデフォルト値 */
export const DEFAULT_FILEIF: FileIF = {
  id: 0,
  path: "",
  name: ""
};

/** 職員一括連携 入力チェック結果情報 */
export interface BatchStaffLinkCheckResponse {
  /** 取込成否 */
  is_success: boolean;

  /** ヘッダー情報(データテーブルに渡す情報) */
  headers: DataTableHeader[];

  /** バリデーションエラーメッセージ */
  error_messages: string[];

  /** 取込情報 */
  data: RowItem[];

  /** 連携情報を更新する職員の存在 */
  has_updated_data: boolean;
}

/** 職員一括連携 取込情報(1行分) */
export interface RowItem {
  /** 行取込の結果ステータス 0:連携先更新なし 1:連携先更新あり 2:エラーあり */
  record_state: number;

  /** 連携後の情報に置き換えられた職員Proto構造体 */
  proto_staff: Staff;

  /** (入力)行番号 */
  row_number: string;

  /** (入力)職員システムID */
  staff_unique_id: string;

  /** (入力)職員名 */
  staff_name: string;

  /** (入力)ステータス */
  status: string;

  /** (入力)アカウント情報:iBowID */
  ibow_id: string;

  /** (入力)アカウント情報:メールアドレス */
  mail_address: string;

  /** (入力)アカウント情報:電話番号 */
  tel_no: string;

  /** (入力)連携先アカウント名 */
  account_name: string;

  /** 変更後連携先アカウント名 */
  change_after_account_name: string;
}

/** 職員一括連携 入力チェック結果情報デフォルト値 */
export const DEFAULT_BATCH_STAFF_LINK_CHECK_RESPONSE: BatchStaffLinkCheckResponse = {
  is_success: false,
  headers: [],
  error_messages: [],
  data: [],
  has_updated_data: false
};
