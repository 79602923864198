









































import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import {
  CalendarDispEvent,
  EditRange,
  EditRangeDiv
} from "#/components/calendar/common";

@Component
export default class EditButton extends Vue {
  /** 選択中のイベント */
  @Prop() event!: CalendarDispEvent;

  /** ボタン名称 */
  @Prop({ default: "" }) label!: string;

  /** 色 */
  @Prop({ default: "primary" }) color!: string;

  /** アウトライン */
  @Prop({ default: false }) outlined!: boolean;

  /** アイコンの有無 */
  @Prop({ default: false }) icon!: boolean;

  /** 編集 */
  @Emit("click") click(
    editRange: EditRangeDiv = EditRangeDiv.This
  ): EditRangeDiv {
    return editRange;
  }

  /** 編集範囲 */
  private readonly editRange = EditRange;
}
