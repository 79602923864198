





















import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class Template extends Mixins(AxiosMixin, UtilMixin) {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 選択ひな形番号 */
  private specimen = 0;

  /**
   * 指示書PDFファイルの取得と出力
   */
  private async requestIndicatePdf(): Promise<void> {
    if (this.specimen === 0) {
      await this.$openAlert("指示書の種類を選んでください");
      return;
    }

    this.makePdf(window.base_url + "/api/patient/indicate/outputpdf", {
      patient_id: this.patientId,
      indicate_type: this.specimen
    });
  }

  /**
   * 指示書Excelファイルの取得
   */
  private async requestIndicateExcel(): Promise<void> {
    const requestedType = this.specimen;
    if (requestedType === 0) {
      await this.$openAlert("指示書の種類を選んでください");
      return;
    }

    this.postJsonBlobResCheck(
      window.base_url + "/api/patient/indicate/outputexcel",
      {
        patient_id: this.patientId,
        indicate_type: requestedType
      },
      res => {
        let fileName = "Indicate.xlsx";
        switch (requestedType) {
          case 1:
            fileName = "IndicateGeneral.xlsx";
            break;
          case 2:
            fileName = "IndicateR3chglaw.xlsx";
            break;
          case 3:
            fileName = "IndicateR4chglaw.xlsx";
            break;
          case 4:
            fileName = "IndicateRouken.xlsx";
            break;
          case 5:
            fileName = "IndicateMental.xlsx";
            break;
          case 6:
            fileName = "IndicateSpecial.xlsx";
            break;
          case 7:
            fileName = "IndicateMentalSpecial.xlsx";
            break;
          case 8:
            fileName = "IndicateR6chglaw.xlsx";
            break;
          case 9:
            fileName = "IndicateMentalR6chglaw.xlsx";
            break;
          default:
            return;
        }
        this.downloadIndicateExcel(res.data, fileName);
      }
    );
  }

  /**
   * 指示書Excelファイルのダウンロード
   */
  private downloadIndicateExcel(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }
}
