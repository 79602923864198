








































import { Vue, Prop, Component } from "vue-property-decorator";
import {
  OfficeHistory,
  DefaultOfficeHistory
} from "#/model/agreement/officeHistory";

@Component
export default class NoticeChatTarget extends Vue {
  @Prop({ default: DefaultOfficeHistory }) officeHistory!: OfficeHistory;

  /** チェックボックス非活性フラグ */
  @Prop({ default: false }) disabled!: boolean;
}
