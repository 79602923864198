export const ChatType = {
  text: 0,
  stamp: 1,
  image: 2,
  systemText: 10
} as const;

export const RoomType = {
  normal: 0,
  system: 10
} as const;

export const FileType = {
  occurReport: 1,
  patientFile: 2,
  aiReport: 3
} as const;
