













































































































































































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";
import FileUpload from "#/components/FileUpload.vue";
import AppSelectTable from "#/components/AppSelectTable.vue";
import {
  FileIF,
  DefaultFileIF,
  PatientInvoiceData
} from "@/components/master/patientInvoice/types";
import { DataTableHeader } from "vuetify/types/index";

@Component({
  components: {
    OfficeSelect,
    FileUpload,
    AppSelectTable
  }
})
export default class PatientInvoice extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("patient_invoice") private readonly form!: VForm;

  private mode = 0; //Excelモード\n0:Excel出力\n1:Excel入力
  private officeId = 0; //事業所
  private patientInvoiceDatas: PatientInvoiceData[] = []; //利用者請求情報
  private selectPatientInvoiceDatas: PatientInvoiceData[] = []; //保存対象の利用者請求情報
  private decisionDatas: PatientInvoiceData[] = []; //確定された利用者請求情報
  private errMessage = ""; //エラーメッセージ
  private pushExcelInput = false; //Excel出力押下ステータス
  private pushDecision = false; //確定押下ステータス

  private excelFile: FileIF = DefaultFileIF(); //Excelファイル
  // ファイルアップロードAPIパス
  private uploadApiUrl = window.base_url + "/api/upload";
  // ファイルパス作成
  private get imagePath(): string {
    // patientinvoice/
    return `patientinvoice/`;
  }

  //データテーブル（Excel入力時）
  private headers: DataTableHeader[] = [
    {
      text: "行番号",
      value: "row_no",
      align: "start",
      sortable: false,
      width: "68px"
    },
    {
      text: "利用者システムID",
      value: "patient_id",
      align: "start",
      sortable: false,
      width: "126px"
    },
    {
      text: "ユーザーID",
      value: "display_id.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "氏名(姓)",
      value: "last_name.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "氏名(名)",
      value: "first_name.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "ふりがな(姓)",
      value: "last_name_kana.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "ふりがな(名)",
      value: "first_name_kana.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "性別",
      value: "sex.value",
      align: "start",
      sortable: false,
      width: "60px"
    },
    {
      text: "支払方法",
      value: "pay_kbn.value",
      align: "start",
      sortable: false,
      width: "80px",
      cellClass: "px-0"
    },
    {
      text: "引落開始設定",
      value: "withdrawal_st_kbn.value",
      align: "start",
      sortable: false,
      width: "104px",
      cellClass: "px-0"
    },
    {
      text: "銀行選択",
      value: "financial_institution_kbn.value",
      align: "start",
      sortable: false,
      width: "145px",
      cellClass: "px-0"
    },
    {
      text: "支店コード(通帳記号)(ゆうちょ)",
      value: "yucho_branch_no.value",
      align: "start",
      sortable: false,
      width: "205px",
      cellClass: "px-0"
    },
    {
      text: "口座番号(通帳番号)(ゆうちょ)",
      value: "yucho_account_no.value",
      align: "start",
      sortable: false,
      width: "193px",
      cellClass: "px-0"
    },
    {
      text: "預金者名(ゆうちょ)",
      value: "yucho_depositor.value",
      align: "start",
      sortable: false,
      width: "240px",
      cellClass: "px-0"
    },
    {
      text: "振替金融機関コード(ゆうちょ銀行以外の金融機関)",
      value: "institution_no.value",
      align: "start",
      sortable: false,
      width: "305px",
      cellClass: "px-0"
    },
    {
      text: "振替金融機関名(ゆうちょ銀行以外の金融機関)",
      value: "institution_name.value",
      align: "start",
      sortable: false,
      width: "281px",
      cellClass: "px-0"
    },
    {
      text: "支店コード(通帳記号)(ゆうちょ銀行以外の金融機関)",
      value: "branch_no.value",
      align: "start",
      sortable: false,
      width: "313px",
      cellClass: "px-0"
    },
    {
      text: "支店名(ゆうちょ銀行以外の金融機関)",
      value: "branch_name.value",
      align: "start",
      sortable: false,
      width: "233px",
      cellClass: "px-0"
    },
    {
      text: "預金種目(ゆうちょ銀行以外の金融機関)",
      value: "deposit_kbn.value",
      align: "start",
      sortable: false,
      width: "245px",
      cellClass: "px-0"
    },
    {
      text: "口座番号(通帳番号)(ゆうちょ銀行以外の金融機関)",
      value: "account_no.value",
      align: "start",
      sortable: false,
      width: "301px",
      cellClass: "px-0"
    },
    {
      text: "預金者名(ゆうちょ銀行以外の金融機関)",
      value: "depositor.value",
      align: "start",
      sortable: false,
      width: "240px",
      cellClass: "px-0"
    }
  ];
  //データテーブル（確定後）
  private decisionHeaders: DataTableHeader[] = [
    {
      text: "行番号",
      value: "row_no",
      align: "start",
      sortable: false,
      width: "68px"
    },
    {
      text: "利用者システムID",
      value: "patient_id",
      align: "start",
      sortable: false,
      width: "126px"
    },
    {
      text: "ユーザーID",
      value: "display_id.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "氏名(姓)",
      value: "last_name.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "氏名(名)",
      value: "first_name.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "ふりがな(姓)",
      value: "last_name_kana.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "ふりがな(名)",
      value: "first_name_kana.value",
      align: "start",
      sortable: false,
      width: "120px"
    },
    {
      text: "性別",
      value: "sex.value",
      align: "start",
      sortable: false,
      width: "60px"
    },
    {
      text: "支払方法",
      value: "pay_kbn.value",
      align: "start",
      sortable: false,
      width: "80px"
    },
    {
      text: "引落開始設定",
      value: "withdrawal_st_kbn.value",
      align: "start",
      sortable: false,
      width: "104px"
    },
    {
      text: "銀行選択",
      value: "financial_institution_kbn.value",
      align: "start",
      sortable: false,
      width: "145px"
    },
    {
      text: "支店コード(通帳記号)(ゆうちょ)",
      value: "yucho_branch_no.value",
      align: "start",
      sortable: false,
      width: "205px"
    },
    {
      text: "口座番号(通帳番号)(ゆうちょ)",
      value: "yucho_account_no.value",
      align: "start",
      sortable: false,
      width: "193px"
    },
    {
      text: "預金者名(ゆうちょ)",
      value: "yucho_depositor.value",
      align: "start",
      sortable: false,
      width: "240px"
    },
    {
      text: "振替金融機関コード(ゆうちょ銀行以外の金融機関)",
      value: "institution_no.value",
      align: "start",
      sortable: false,
      width: "305px"
    },
    {
      text: "振替金融機関名(ゆうちょ銀行以外の金融機関)",
      value: "institution_name.value",
      align: "start",
      sortable: false,
      width: "281px"
    },
    {
      text: "支店コード(通帳記号)(ゆうちょ銀行以外の金融機関)",
      value: "branch_no.value",
      align: "start",
      sortable: false,
      width: "313px"
    },
    {
      text: "支店名(ゆうちょ銀行以外の金融機関)",
      value: "branch_name.value",
      align: "start",
      sortable: false,
      width: "233px"
    },
    {
      text: "預金種目(ゆうちょ銀行以外の金融機関)",
      value: "deposit_kbn.value",
      align: "start",
      sortable: false,
      width: "245px"
    },
    {
      text: "口座番号(通帳番号)(ゆうちょ銀行以外の金融機関)",
      value: "account_no.value",
      align: "start",
      sortable: false,
      width: "301px"
    },
    {
      text: "預金者名(ゆうちょ銀行以外の金融機関)",
      value: "depositor.value",
      align: "start",
      sortable: false,
      width: "240px"
    },
    {
      text: "成否",
      value: "success_or_failure",
      align: "start",
      sortable: false,
      width: "60px"
    }
  ];

  created() {
    if (this.loginUser.office_id) {
      this.officeId = this.loginUser.office_id;
    } else {
      this.officeId = this.HeadOffice;
    }
  }

  //性別
  private getSex(value: string): string {
    let sex = "";
    if (value == "1") {
      sex = "男性";
    }
    if (value == "2") {
      sex = "女性";
    }
    return sex;
  }
  //支払方法
  private getPayKbn(value: string): string {
    let payKbn = "";
    if (value == "1") {
      payKbn = "集金";
    }
    if (value == "2") {
      payKbn = "振込";
    }
    if (value == "3") {
      payKbn = "引落";
    }
    return payKbn;
  }
  //引落開始設定
  private getWithdrawalStKbn(value: string): string {
    let withdrawalStKbn = "";
    if (value == "1") {
      withdrawalStKbn = "開始する";
    }
    if (value == "2") {
      withdrawalStKbn = "開始しない";
    }
    return withdrawalStKbn;
  }
  //銀行選択
  private getFinancialInstitutionKbn(value: string): string {
    let financialInstitutionKbn = "";
    if (value == "1") {
      financialInstitutionKbn = "ゆうちょ銀行";
    }
    if (value == "2") {
      financialInstitutionKbn = "ゆうちょ銀行以外";
    }
    return financialInstitutionKbn;
  }
  //預金種目
  private getDepositKbn(value: string): string {
    let depositKbn = "";
    if (value == "1") {
      depositKbn = "普通";
    }
    if (value == "2") {
      depositKbn = "当座";
    }
    return depositKbn;
  }
  //成否
  private getSuccessOrFailure(value: boolean): string {
    let successOrFailure = "";
    if (value == true) {
      successOrFailure = "成功";
    }
    if (value == false) {
      successOrFailure = "失敗";
    }
    return successOrFailure;
  }
  //行の色：既存行、新規行、エラー行
  private itemRowBackground(item: PatientInvoiceData) {
    let result = "";
    //既存行
    if (item.patient_state == 1) {
      result = "";
    }
    //新規行
    if (item.patient_state == 2) {
      result = "yellow lighten-4";
    }
    //エラー行
    if (item.patient_state == 3) {
      result = "red lighten-4";
    }
    return result;
  }
  /** 行を選択できる条件 */
  private isRowSelectable(item: PatientInvoiceData) {
    // 支払方法未設定または差分項目あり
    return item.patient_state === 2 || item.patient_state === 4;
  }
  //行の色：確定後（失敗の場合）
  private decisionItemRowBackground(item: PatientInvoiceData) {
    let result = "";
    //成功
    if (item.success_or_failure == true) {
      result = "";
    }
    //失敗
    if (item.success_or_failure == false) {
      result = "red lighten-4";
    }
    return result;
  }
  //項目の色：既存項目、差分あり項目、エラー項目
  private itemBackground(state: number) {
    let result = "";
    //既存項目
    if (state == 1) {
      result = "padding-left: 16px;";
    }
    //差分あり項目
    if (state == 2) {
      result = "background-color: #cef;height: 100%; padding-left: 16px;";
    }
    //エラー項目
    if (state == 3) {
      result = "background-color: #f99;height: 100%; padding-left: 16px;";
    }
    return result;
  }

  //Excel出力
  private async excelOutput() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    this.postJsonBlobResCheck(
      window.base_url + "/api/master/patientinvoice/outputexcel",
      {
        office_id: this.officeId
      },
      res => {
        const fileName = "patientInvoice.xlsx";
        this.downloadPatientInvoiceExcel(res.data, fileName);
      }
    );
  }
  //利用者請求情報Excelファイルのダウンロード
  private downloadPatientInvoiceExcel(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  //Excel入力
  private async excelInput() {
    if (this.excelFile.path == "") {
      await this.$openAlert("Excelファイルを指定してください。");
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/patientinvoice/inputexcel",
      {
        path: this.excelFile.path
      },
      res => {
        this.patientInvoiceDatas = res.data.patient_invoice_datas;
        // 初期状態で全ての入力可能な項目を選択する
        this.checkProcess();
        this.errMessage = res.data.err_message;
        this.pushExcelInput = true;
      }
    );
  }

  //エラーメッセージ出力
  private errMessageOutput() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/master/patientinvoice/outputerrmessage",
      {
        err_message: this.errMessage
      },
      res => {
        const fileName = "errorMessage.txt";
        this.downloadErrMessageTxt(res.data, fileName);
      }
    );
  }
  //エラーメッセージテキストのダウンロード
  private downloadErrMessageTxt(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  //確定
  private async decision() {
    //チェックなし確認
    if (this.selectPatientInvoiceDatas.length == 0) {
      if (
        !(await this.$openConfirm(
          "チェックがついていません。表示されている全てを対象に処理を行いますか？"
        ))
      ) {
        return;
      }
      this.checkProcess();
    }
    //チェックが1つもない場合
    if (this.selectPatientInvoiceDatas.length == 0) {
      await this.$openAlert("1件以上にチェックを付けてください。");
      return;
    }
    //確認
    if (!(await this.$openConfirm("登録してもよろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/patientinvoice/decision",
      {
        select_patient_invoice_datas: this.selectPatientInvoiceDatas
      },
      res => {
        this.decisionDatas = res.data.decision_datas;
        this.pushDecision = true;
        this.selectPatientInvoiceDatas = [];
      }
    );
  }

  //支払方法未設定もしくは差分項目ありデータ全てにチェック処理
  private checkProcess() {
    this.selectPatientInvoiceDatas = this.patientInvoiceDatas.filter(
      this.isRowSelectable
    );
  }

  //Excel入力に戻る
  private backExcelInput() {
    this.mode = 1;
    this.excelFile = DefaultFileIF();
    this.pushExcelInput = false;
    this.pushDecision = false;
    this.selectPatientInvoiceDatas = [];
  }
}
