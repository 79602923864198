var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppSelect',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-requester-type"),"label":"依頼元","items":_vm.masters.requester_types,"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.requester_type),callback:function ($$v) {_vm.$set(_vm.patient.info, "requester_type", $$v)},expression:"patient.info.requester_type"}}),_c('v-expand-transition',[(_vm.patient.info.requester_type == _vm.PRIREQUESTER_TYPE.RELATED)?_c('PatientRelationInsSelect',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-personnel-id"),"prefix_id":(_vm.prefixId + "-personnel"),"label":"*依頼元：関連機関名","rules":[
        _vm.patient.info.requester_type != _vm.PRIREQUESTER_TYPE.RELATED ||
          _vm.minNumber(_vm.patient.info.requester_personnel_id, 1, '必須です')
      ],"not_select":true,"disabled":_vm.isReadOnly},on:{"change":_vm.changeRelation},model:{value:(_vm.patient.info.requester_personnel_id),callback:function ($$v) {_vm.$set(_vm.patient.info, "requester_personnel_id", $$v)},expression:"patient.info.requester_personnel_id"}}):_vm._e()],1),_c('v-expand-transition',[(_vm.patient.info.requester_type == _vm.PRIREQUESTER_TYPE.MEDICAL)?_c('PatientDoctorSelect',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-doctor-id"),"prefix_id":(_vm.prefixId + "-doctor"),"label":"*依頼元：医療機関名","not_empty":false,"rules":[
        _vm.patient.info.requester_type != _vm.PRIREQUESTER_TYPE.MEDICAL ||
          _vm.minNumber(_vm.patient.info.requester_doctor_id, 1, '必須です')
      ],"not_select":true,"disabled":_vm.isReadOnly},on:{"change":_vm.changeMedical},model:{value:(_vm.patient.info.requester_doctor_id),callback:function ($$v) {_vm.$set(_vm.patient.info, "requester_doctor_id", $$v)},expression:"patient.info.requester_doctor_id"}}):_vm._e()],1),_c('v-expand-transition',[(_vm.patient.info.requester_type >= _vm.PRIREQUESTER_TYPE.FAMILY)?_c('AppTextField',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-requester-fullname"),"label":"*依頼元：氏名","maxlength":"30","counterNum":30,"rules":[
        _vm.patient.info.requester_type < _vm.PRIREQUESTER_TYPE.FAMILY ||
          _vm.required(_vm.patient.info.requester_fullname, '必須です')
      ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.requester_fullname),callback:function ($$v) {_vm.$set(_vm.patient.info, "requester_fullname", $$v)},expression:"patient.info.requester_fullname"}}):_vm._e()],1),_c('v-expand-transition',[(_vm.patient.info.requester_type == _vm.PRIREQUESTER_TYPE.FAMILY)?_c('FamilyRelationShipSelect',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-family-relationship-id"),"label":"依頼元：続柄","disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.requester_family_relationship_id),callback:function ($$v) {_vm.$set(_vm.patient.info, "requester_family_relationship_id", $$v)},expression:"patient.info.requester_family_relationship_id"}}):_vm._e()],1),_c('v-expand-transition',[(
        _vm.patient.info.requester_type != _vm.PRIREQUESTER_TYPE.NOT &&
          _vm.patient.info.requester_type != _vm.PRIREQUESTER_TYPE.SELF
      )?_c('AppPhoneArea',{staticClass:"mb-6",attrs:{"prefix_id":(_vm.prefixId + "-requester-tel"),"label":"依頼元連絡先","area":_vm.patient.info.requester_tel_area,"city":_vm.patient.info.requester_tel_city,"num":_vm.patient.info.requester_tel_num,"disabled":_vm.isReadOnly},on:{"update:area":function($event){return _vm.$set(_vm.patient.info, "requester_tel_area", $event)},"update:city":function($event){return _vm.$set(_vm.patient.info, "requester_tel_city", $event)},"update:num":function($event){return _vm.$set(_vm.patient.info, "requester_tel_num", $event)}}}):_vm._e()],1),_c('AppTextArea',{attrs:{"id":(_vm.prefixId + "-contact-info"),"label":"連絡事項","showCounter":true,"simpleCounter":true,"limitPerLine":800,"maxLines":1,"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.requester_contact_info),callback:function ($$v) {_vm.$set(_vm.patient.info, "requester_contact_info", $$v)},expression:"patient.info.requester_contact_info"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }