import AppChatStampList from "./AppChatStampList.vue";

export default {
  title: "Example/AppChatStampList",
  component: AppChatStampList,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatStampList },
  template: '<AppChatStampList v-bind="$props" />'
});

export const ChatStampList = Template.bind({});

ChatStampList.args = {
  stampGroups: [
    {
      id: 1,
      icon:
        "https://1.bp.blogspot.com/-Fn-oz-1cGTI/X4aVjdBGL0I/AAAAAAABbwY/bG-apG9Dk9AtYWlGuiqSc9N8A-l5Z7XAwCNcBGAsYHQ/s745/hamster_sleeping_golden.png",
      stamps: [
        {
          id: 1,
          path:
            "https://1.bp.blogspot.com/-H61djj8LaRk/X68akpuOknI/AAAAAAABcSA/6h-CmsvWsw0eum4hgZ6jje0f4ctNxZG9wCNcBGAsYHQ/s675/cthulhu_deep_ones.png"
        },
        {
          id: 2,
          path:
            "https://1.bp.blogspot.com/-QNMuJ-k-bYU/X68bIi2gjjI/AAAAAAABcUs/39kmQwXcZPM3w4s8O1vrcIswGqd2r6EWgCNcBGAsYHQ/s798/fantasy_unicorn_rainbow.png"
        },
        {
          id: 3,
          path:
            "https://1.bp.blogspot.com/-AEOr9LcsSYE/XzXk61xOTOI/AAAAAAABan4/h3RtZyngmYYHwt5cupf-RYSIGNSbCz7XwCNcBGAsYHQ/s1600/youkai_nurikabe.png"
        },
        {
          id: 4,
          path:
            "https://blogger.googleusercontent.com/img/a/AVvXsEgi8IFwR7VoUtQIBHY5U5UueeRusSawWoAD3njzwc7gR-gFyFlzPafCOHmZ7jrBxAVn2AVHWmPL8sxlm0cnxeVZbtr6zJzrEZPGLSbY4ceSaPw15CalNXNx9tk3hBtV3KFGe8CNloo0Kd-M-WI1KjJjfVGDvo3nPH-UJj6n3_9g7OhJZha8uKL6H2kpPg=s911"
        },
        {
          id: 5,
          path:
            "https://1.bp.blogspot.com/-Z0m06wTs37M/X9GYAqMwrwI/AAAAAAABcsE/Al62TNZO1ZUzTipA5tQUxIdzYTqbNu34wCNcBGAsYHQ/s783/dorne_fpv.png"
        },
        {
          id: 6,
          path:
            "https://1.bp.blogspot.com/-yZikeaYdLVA/XxU0ms-W-CI/AAAAAAABaNs/jiciuxpZgSoQ-LoAv5ajgnTev07f_GtGQCNcBGAsYHQ/s1600/pinata_halloween_bat_girl.png"
        },
        {
          id: 7,
          path:
            "https://1.bp.blogspot.com/-qcVpJKkKQEY/Xsdr5bKB1AI/AAAAAAABZEE/VT9INtKysVkFeik6IbKwwZnZz6lnQL_mgCNcBGAsYHQ/s1600/toy_fukiage_pipe_boy.png"
        },
        {
          id: 8,
          path:
            "https://1.bp.blogspot.com/-Nb82XHbwI4Y/Xhwq1if_7fI/AAAAAAABXFA/T_u3eMw8ue8q8UbxFGvsulnk5-hgCKFWwCNcBGAsYHQ/s1600/toy_kibori_kuma.png"
        },
        {
          id: 9,
          path:
            "https://1.bp.blogspot.com/-iKEaEooBXmI/XVKf_bCJFFI/AAAAAAABUGM/REiIJhKhF8wAPYz7DpbG2FjnN6tWay9CACLcBGAs/s1600/jiko_takoage_denchu.png"
        },
        {
          id: 10,
          path:
            "https://1.bp.blogspot.com/-CPRg7W2C3RA/Vrc7I0Z6-WI/AAAAAAAA32Y/ZRu9I3V_odQ/s800/nidone_man.png"
        },
        {
          id: 11,
          path:
            "https://3.bp.blogspot.com/-ARwj-H2OUIU/UylAOpArMJI/AAAAAAAAeQQ/8TPr9AYpM6Y/s800/onigiri_seachicken.png"
        },
        {
          id: 12,
          path:
            "https://3.bp.blogspot.com/-woTFzblMDj0/UsZs5o49aOI/AAAAAAAActs/lk4Lmj4uMVs/s800/sports_american_football.png"
        }
      ]
    },
    {
      id: 2,
      icon:
        "https://4.bp.blogspot.com/-a7WEvuIz_-4/W1vhClOop5I/AAAAAAABNtY/FjTJQ-3P41AFPX8QSCZXLU05YgKc8xntACLcBGAs/s800/character_earth_chikyu.png",
      stamps: [
        {
          id: 13,
          path:
            "https://3.bp.blogspot.com/-wMWCHzKYgWw/V9aG_0uOgUI/AAAAAAAA9mI/Vhox-guAiSQvhmKSRhUzeZczeZdN9OWgQCLcB/s800/kaigo_kurumaisu_man.png"
        },
        {
          id: 14,
          path:
            "https://4.bp.blogspot.com/-CYF76iDAydk/WBsARPj3VCI/AAAAAAAA_TQ/RiqBN4Mje8090H2BGZu0bkIFNxFhUBmCACLcB/s800/family_teisyukanpaku_kowai.png"
        },
        {
          id: 15,
          path:
            "https://4.bp.blogspot.com/-u2PJcGUSIN0/W-VEcnRvyII/AAAAAAABQFk/7miGx-JsspIuKCrD7UcZ5ymEOQmDn7yywCLcBGAs/s800/drink_petbottle_coffee.png"
        },
        {
          id: 16,
          path:
            "https://3.bp.blogspot.com/-Rt8xJ-CoIds/WCqdoKpOiLI/AAAAAAAA_ks/TD5WTCuu5HEXRh1e3mHLzFOSeCMC2jb-ACLcB/s800/body_tainai_dokei1.png"
        },
        {
          id: 17,
          path:
            "https://4.bp.blogspot.com/-sTilfcU4-rM/VHPgBvN8FnI/AAAAAAAApN0/I-DwLCX4Rew/s800/jidori_selfy_stick.png"
        },
        {
          id: 18,
          path:
            "https://3.bp.blogspot.com/-F0xebhraSb4/WKFi5W2YgFI/AAAAAAABBpQ/zDai8yFu1dUdvKDh3eTA3W0_haXfMOY-QCLcB/s800/animal_caracal.png"
        }
      ]
    }
  ]
};
