


























































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  PatientRecord,
  DataParam,
  HeaderParam
} from "@/views/master/choju/types";
import AppSelectTable from "#/components/AppSelectTable.vue";
import { isInputSelectable } from "@/components/master/csv/utility";

@Component({
  components: {
    AppSelectTable
  }
})
export default class CsvResult extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** テーブルデータ */
  @Prop({ default: null }) datas!: PatientRecord[];
  @Prop({ default: null }) header!: HeaderParam[];
  @Prop({ default: null }) errMessage!: string;
  @Prop({ default: () => [] }) selected!: PatientRecord[];
  @Prop({ default: false }) success!: boolean;

  // created() {
  //   console.log("hoge-------", this.datas);
  //   console.log("hoge-------", this.errMessage);
  // }

  //行の色：既存行、新規行、エラー行
  private rowBackground(item: DataParam) {
    let result = "";

    switch (item.state) {
      case 4: // 成否：成功行
        result = "white lighten-4";
        break;
      case 3: //エラー行
        result = "red lighten-4";
        break;
      case 2: //新規行
        result = "yellow lighten-4";
        break;
      case 1: // 既存行（差分なし）
        result = "";
        break;
      case 0: // 既存行（差分あり）
        result = "";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  /** 行を選択できる条件 */
  private isRowSelectable(item: DataParam) {
    // 既存行（差分あり）または新規行
    return isInputSelectable(item);
  }

  //項目の色：既存項目、差分あり項目、エラー項目
  private tableDataState(state: number, rowState: number) {
    let result = "";
    switch (state) {
      case 0: // 通常
        result = "default";
        break;
      case 1: // 差分あり
        result = "diff";
        break;
      case 2: // エラー
        result = "err";
        break;
      default:
        // 通常
        result = "default";
        break;
    }

    // 新規行のエラー行場合セルのカラーはエラー項目のみ有効
    switch (rowState) {
      case 4: // 成否：成功行
      case 3: // エラー行
      case 2: // 新規行
        if (state != 2) {
          result = "default";
        }
        break;
      default:
        break;
    }

    return result;
  }

  //エラーメッセージ出力
  private errMessageOutput() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/master/patientinvoice/outputerrmessage",
      {
        err_message: this.errMessage
      },
      res => {
        const fileName = "errorMessage.txt";
        this.downloadErrMessageTxt(res.data, fileName);
      }
    );
  }

  @Emit("update:selected")
  private selectRecord(value: PatientRecord[]) {
    return value;
  }

  private get SelectRecord() {
    return this.selected;
  }
  private set SelectRecord(value: PatientRecord[]) {
    this.selectRecord(value);
  }

  //エラーメッセージテキストのダウンロード
  private downloadErrMessageTxt(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  //確定
  @Emit()
  private confirm() {
    return;
  }

  @Emit()
  private back() {
    return;
  }
}
