



















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class OtherPageOpen extends Vue {
  @Prop({ default: "" }) private readonly message!: string;

  /** ページを閉じる */
  private clickClose() {
    window.close();
  }
}
