




























































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import ShiftTypeEntryDialog from "@/components/master/shift/StaffShiftTypeEntry.vue";
import { Facility } from "@/components/master/office/common";
import AppPlaces from "#/components/AppPlaces.vue";
import OfficeItem from "@/components/master/office/OfficeItem.vue";
import SateliteItem from "@/components/master/office/SateliteItem.vue";
import MasmenMixin from "@/mixins/masmenMixin";
import EditMixin from "@/mixins/editMixin";
import { BreadItem } from "@/store/modules/masmen/types";
import {
  Office,
  OfficeHistory,
  SateliteHistory,
  setOffice,
  setOfficeHistory,
  setSateliteHistory
} from "#/model/agreement";
import UtilMixin from "@/mixins/utilMixin";
import { Location } from "vue-router";
import { OFFICE_STAFF } from "#/const";
import TherapistVisitCount from "@/components/master/office/TherapistVisitCount.vue";

interface FacilityItem {
  text: string;
  value: number;
}

@Component({
  components: {
    ShiftTypeEntryDialog,
    AppPlaces,
    OfficeItem,
    SateliteItem
  }
})
export default class OfficeEdit extends Mixins(
  MasmenMixin,
  EditMixin,
  UtilMixin
) {
  /** システム管理者権限 */
  private STAFF_ADMIN = OFFICE_STAFF.AUTH.ADMIN;
  /** 本社職員権限 */
  private STAFF_HOME = OFFICE_STAFF.AUTH.HOME;
  /** 本社職員権限 */
  private STAFF_CHIEF = OFFICE_STAFF.AUTH.CHIEF;

  @Ref("sateliteItem") private readonly sateliteItem!: SateliteItem;

  @Ref("officeItem") private readonly officeItem!: OfficeItem;

  private isSatelite = false;

  //新規作成かどうか
  private isNewData = false;

  private office: OfficeHistory = setOfficeHistory();

  private satelite: SateliteHistory = setSateliteHistory();

  private cloneOffice: Office = setOffice();

  /** 変更前事業所情報 */
  private officeBefore: OfficeHistory = setOfficeHistory();

  private breadItems: BreadItem[] = [
    {
      text: "事業所・サテライト一覧",
      disabled: false,
      to: "/master/office/list",
      exact: true
    },
    {
      text: "事業所・サテライト情報変更",
      disabled: true,
      to: ""
    }
  ];
  private facilityItems: FacilityItem[] = []; //同一建物マスタ

  private facilityRateItems = [
    { text: "10%", value: 1 },
    { text: "15%", value: 2 }
  ];

  private is_honobono = 0;

  // 同一建物データ
  private facilities: Facility[] = [];

  // 理学療法士超過減算
  private therapistVisitCount: TherapistVisitCount[] = [];

  // 事業所データ
  private offices: Office[] = [];

  created() {
    if (this.$route.query.satelite) {
      this.isSatelite = true;
    }
    this.getOffice();
    this.checkIsNewData();
    if (this.isNewData) {
      this.breadItems = [
        {
          text: "事業所・サテライト一覧",
          disabled: false,
          to: "/master/office/list",
          exact: true
        },
        {
          text: "事業所・サテライト情報追加",
          disabled: true,
          to: ""
        }
      ];
    }
  }
  private checkIsNewData() {
    if (!this.$route.params.id) {
      this.isNewData = true;
    }
  }

  private historyEdit() {
    const url: Location = {
      name: "MastermaintenanceOfficeHistory",
      params: { id: this.$route.params.id + "" }
    };
    if (this.isSatelite) {
      url.query = {};
      url.query.satelite = "true";
    }
    this.$router.push(url);
  }

  private getOffice() {
    this.postJsonCheck(
      window.base_url + "/api/master/station/get_by_id",
      {
        office_id: Number(this.$route.params.id)
      },
      res => {
        this.cloneOffice = { ...res.data.office };
        this.office = res.data.office.office_info;
        this.satelite = { ...res.data.office.satelite_info };
        this.is_honobono = res.data.is_honobono;
        this.facilities = res.data.same_buildings;
        this.therapistVisitCount =
          res.data.office.office_info.over_therapist_visit_count;
        this.offices = res.data.parent_offices;
        this.setLoaded();

        // 事業所情報の差分チェック&メール送信用に変更前の情報を保持しておく
        this.officeBefore = this.deepCopy(this.office);
      }
    );
  }

  private async saveOffice() {
    this.cloneOffice.office_info = this.office;
    this.postJsonCheck(
      window.base_url + "/api/master/station/save",
      {
        office: this.cloneOffice
      },
      () => {
        this.callSaveApiAfter();
      }
    );
  }

  private saveOfficeHistory() {
    if (this.office.use_edi_flg && this.office.edi_code.length < 10) {
      this.office.edi_code = this.office.edi_code.padEnd(10, "0");
    }
    this.postJsonCheck(
      window.base_url + "/api/master/station/save_info",
      {
        office_history: this.office,
        office_history_before: this.officeBefore,
        kst_no: this.cloneOffice.kst_no
      },
      () => {
        this.callSaveApiAfter();
      }
    );
  }

  private saveSateliteHistory() {
    this.postJsonCheck(
      window.base_url + "/api/master/station/save_satelite_info",
      {
        satelite_history: this.satelite
      },
      () => {
        this.callSaveApiAfter();
      }
    );
  }

  private saveSatelite() {
    this.postJsonCheck(
      window.base_url + "/api/master/station/save_satelite",
      {
        office: {
          id: 0,
          satelite_info: this.satelite
        }
      },
      () => {
        // サテライトを新規作成した場合、トークンをリフレッシュしないと一覧に表示されない
        this.refTokenOnly(() => {
          this.callSaveApiAfter();
        });
      }
    );
  }

  private callSaveApiAfter() {
    this.setNoEditMode();
    // マスタ情報を再取得
    this.GetMaster().then(() => {
      this.$router.push({ name: "MastermaintenanceOffice" });
    });
  }

  private async deleteOffice() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }
    if (this.cloneOffice.id > 0) {
      this.postJsonCheck(
        window.base_url + "/api/master/station/delete",
        {
          office: this.cloneOffice
        },
        () => {
          // サテライトを削除した場合、トークンをリフレッシュしてグループ事業所IDを更新
          this.refTokenOnly(() => {
            this.setNoEditMode();
            this.$router.push({ name: "MastermaintenanceOffice" });
          });
        }
      );
    }
  }

  private async save() {
    if (this.isSatelite) {
      if (!this.sateliteItem.form.validate()) {
        await this.$openAlert("入力内容に不備があります");
        return;
      }
      if (this.satelite.office_id == 0) {
        this.saveSatelite();
      } else {
        this.saveSateliteHistory();
      }
    } else {
      if (!this.officeItem.form.validate()) {
        await this.$openAlert("入力内容に不備があります");
        return;
      }
      if (
        this.office.office_code.slice(-7) !==
        this.office.medical_institution_code
      ) {
        if (
          !(await this.$openConfirm(
            "事業所番号の末尾7文字が、医療機関コードと異なります。よろしいですか？"
          ))
        ) {
          return;
        }
      }
      // 24時間体制連絡加算ONが隠れたまま対応加算をONにした時、対応だけをONにして保存する
      if (
        this.office.medical_hour24_taiou_add &&
        this.office.medical_hour24_renraku_add
      ) {
        this.office.medical_hour24_renraku_add = 0;
      }

      if (this.cloneOffice.id == 0) {
        this.saveOffice();
      } else {
        this.saveOfficeHistory();
      }
    }
  }

  /** 事業所コード発行 */
  private async publishOfficeSecurityCode() {
    if (this.loginUserAuthId == this.STAFF_ADMIN) {
      await this.publishOfficeSecurityCodeAdmin();
    } else {
      await this.publishOfficeSecurityCodeNormal();
    }
  }

  /** 事業所コード発行（本社・所長） */
  private async publishOfficeSecurityCodeNormal() {
    if (
      !(await this.$openConfirm(
        this.loginUser.ibow_nickname +
          "さんの電話番号またはメールアドレスに事業所セキュリティコードを発行します。よろしいですか。"
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/master/station/publish_office_security_code",
      {
        office_id: Number(this.$route.params.id)
      },
      async () => {
        await this.$openAlert("事業所セキュリティコードを発行しました。");
      }
    );
    return;
  }

  /** 事業所コード発行（管理者） */
  private async publishOfficeSecurityCodeAdmin() {
    if (
      !(await this.$openConfirm(
        "画面にセキュリティコードを発行します。よろしいですか。"
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/master/station/publish_office_security_code",
      {
        office_id: Number(this.$route.params.id),
        is_display: 1
      },
      async res => {
        await this.$openAlert(
          "事業所セキュリティコードは以下です。\n" + res.data.security_code
        );
      }
    );
  }

  //編集モード設定
  @Watch("office", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  @Watch("satelite", { deep: true })
  private watchSatelite() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  /** 事業所セキュリティコードが表示できるかどうか */
  private get VisibleSecurityCodeButton(): boolean {
    return (
      !this.isSatelite &&
      (this.loginUserAuthId == this.STAFF_ADMIN ||
        this.loginUserAuthId == this.STAFF_HOME ||
        this.loginUserAuthId == this.STAFF_CHIEF)
    );
  }
}
