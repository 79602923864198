
























import { Component } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class Other extends MedPartBase {
  //同一日3人以上
  protected disableCheckThreePersonFlg(): boolean {
    let result = false;
    if (
      this.baseDisableCheck() ||
      (this.event.visit_record.mental_div == 1 &&
        this.receiptVisitRecord.remedy_div != 2) ||
      (this.event.visit_record.mental_div == 2 &&
        this.receiptVisitRecord.remedy_div != 3)
    ) {
      result = true;
    }
    return result;
  }

  //加算のみ
  protected disableCheckAddOnlyFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //同一日3人以上
    if (this.disableCheckThreePersonFlg()) {
      this.receiptVisitRecord.three_person_flg = 0;
    }
    //加算のみ
    if (this.disableCheckAddOnlyFlg()) {
      this.receiptVisitRecord.add_only_flg = 0;
    }
  }
}
