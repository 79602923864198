





























































































































































































































import { Component, Watch } from "vue-property-decorator";
import OneMonthAddV4 from "./OneMonthAddV4.vue";

@Component
export default class OneMonthAddV5 extends OneMonthAddV4 {
  created(): void {
    this.setHour24Div();
    this.setMedicalSpecialDivV5();
    this.setMedicalTerminalDiv();
    this.setAccompaniedStaffCnt();
  }

  //特別管理加算
  protected setMedicalSpecialDivV5() {
    if (this.receiptVisitRecord.medical_special_div == 1) {
      this.medical_special_div1 = true;
      this.medical_special_div2 = false;
      this.medical_special_div3 = false;
    }
    if (this.receiptVisitRecord.medical_special_div == 2) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = true;
      this.medical_special_div3 = false;
    }
    if (this.receiptVisitRecord.medical_special_div == 4) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = false;
      this.medical_special_div3 = true;
    }
    if (this.receiptVisitRecord.medical_special_div == 5) {
      this.medical_special_div1 = true;
      this.medical_special_div2 = false;
      this.medical_special_div3 = true;
    }
    if (this.receiptVisitRecord.medical_special_div == 6) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = true;
      this.medical_special_div3 = true;
    }
  }

  /** コロナ臨時 */
  protected medical_special_div3 = false;

  //特別管理加算
  @Watch("medical_special_div1") protected handleMedicalSpecialDiv1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.medical_special_div1 == true) {
      if (this.medical_special_div3 == true) {
        this.receiptVisitRecord.medical_special_div = 5;
      }
      if (this.medical_special_div3 == false) {
        this.receiptVisitRecord.medical_special_div = 1;
      }
      this.medical_special_div2 = false;
    }
    if (
      this.medical_special_div1 == false &&
      this.medical_special_div2 == false
    ) {
      if (this.medical_special_div3 == true) {
        this.receiptVisitRecord.medical_special_div = 4;
      }
      if (this.medical_special_div3 == false) {
        this.receiptVisitRecord.medical_special_div = 0;
      }
    }
  }

  //重症度等の高い患者
  @Watch("medical_special_div2") protected handleMedicalSpecialDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.medical_special_div2 == true) {
      if (this.medical_special_div3 == true) {
        this.receiptVisitRecord.medical_special_div = 6;
      }
      if (this.medical_special_div3 == false) {
        this.receiptVisitRecord.medical_special_div = 2;
      }
      this.medical_special_div1 = false;
    }
    if (
      this.medical_special_div1 == false &&
      this.medical_special_div2 == false
    ) {
      if (this.medical_special_div3 == true) {
        this.receiptVisitRecord.medical_special_div = 4;
      }
      if (this.medical_special_div3 == false) {
        this.receiptVisitRecord.medical_special_div = 0;
      }
    }
  }

  //コロナ臨時
  @Watch("medical_special_div3") protected handleMedicalSpecialDiv3() {
    if (!this.isLoaded) {
      return;
    }
    if (this.medical_special_div3 == true) {
      if (
        this.medical_special_div1 == true &&
        this.medical_special_div2 == false
      ) {
        this.receiptVisitRecord.medical_special_div = 5;
      }
      if (
        this.medical_special_div1 == false &&
        this.medical_special_div2 == true
      ) {
        this.receiptVisitRecord.medical_special_div = 6;
      }
      if (
        this.medical_special_div1 == false &&
        this.medical_special_div2 == false
      ) {
        this.receiptVisitRecord.medical_special_div = 4;
      }
    }
    if (this.medical_special_div3 == false) {
      if (
        this.medical_special_div1 == true &&
        this.medical_special_div2 == false
      ) {
        this.receiptVisitRecord.medical_special_div = 1;
      }
      if (
        this.medical_special_div1 == false &&
        this.medical_special_div2 == true
      ) {
        this.receiptVisitRecord.medical_special_div = 2;
      }
      if (
        this.medical_special_div1 == false &&
        this.medical_special_div2 == false
      ) {
        this.receiptVisitRecord.medical_special_div = 0;
      }
    }
  }

  //特別管理加算
  protected disableCheckMedicalSpecialDiv1(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_tokubetsu_kanri_add == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //特別管理加算（コロナ臨時）
  protected disableCheckMedicalSpecialDiv3(): boolean {
    let result = false;
    if (
      this.event.indicate.temporary_covid19_div == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV5() {
    //ターミナルケアと遠隔死亡診断補助
    if (this.disableCheckMedicalTerminalDivV4()) {
      this.medical_terminal_div1 = false;
      this.medical_terminal_div2 = false;
      this.receiptVisitRecord.medical_terminal_div = 0;
      this.receiptVisitRecord.remote_death_assist = 0;
    }
    //特別管理指導
    if (this.disableCheckSpecialInstFlg()) {
      this.receiptVisitRecord.special_inst_flg = 0;
    }
    //24時間体制
    if (this.disableCheckMedicalHour24Add()) {
      this.hour24_div1 = false;
      this.hour24_div2 = false;
      this.receiptVisitRecord.hour24_div = 0;
    }
    //特別管理加算
    if (
      this.disableCheckMedicalSpecialDiv1() &&
      this.disableCheckMedicalSpecialDiv2() &&
      this.disableCheckMedicalSpecialDiv3()
    ) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = false;
      this.medical_special_div3 = false;
      this.receiptVisitRecord.medical_special_div = 0;
    }
    //在宅患者連携指導
    if (this.disableCheckHomeInstFlg()) {
      this.receiptVisitRecord.home_inst_flg = 0;
    }
    //精神科重症患者支援管理連携加算
    if (this.disableCheckMentalSupportDivV2()) {
      this.mental_support_div1 = false;
      this.mental_support_div2 = false;
      this.receiptVisitRecord.mental_support_div = 0;
    }
    //情報提供療養費
    if (this.disableCheckInfoExpensesFlgV2()) {
      this.receiptVisitRecord.info_expenses_flg1 = 0;
      this.receiptVisitRecord.info_expenses_flg2 = 0;
      this.receiptVisitRecord.info_expenses_flg3 = 0;
    }
    //情報提供療養費（II）回数
    if (this.disableCheckInfoExpenses2Count()) {
      this.receiptVisitRecord.info_expenses2_count = 0;
    }
    //看護・介護職員連携
    if (this.disableCheckNursingCareFlg()) {
      this.receiptVisitRecord.nursing_care_flg = 0;
    }
    //看護・介護職員連携日
    if (this.disableCheckNursingCareDate()) {
      this.receiptVisitRecord.nursing_care_date = "";
    }
    //専門管理加算
    if (this.disableCheckSpecialManagementAddV4()) {
      this.receiptVisitRecord.special_management_add = 0;
    }
  }
}
