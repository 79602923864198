
























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AppTabs from "../AppTabs.vue";
import AppTab from "../AppTab.vue";

export interface StampGroup {
  id: number;
  name: string;
  icon: string;
  stamps: Stamp[];
}

export interface Stamp {
  id: number;
  name: string;
  path: string;
  type: number;
}

@Component({
  components: { AppTabs, AppTab }
})
export default class AppChatStampList extends Vue {
  @Prop({
    default: () => {
      return [];
    }
  })
  stampGroups!: StampGroup[];
  @Prop() selectedStamp!: Stamp | null;

  private currentTab = 1;

  @Emit()
  private sendStamp(stamp: Stamp) {
    return stamp;
  }

  @Emit()
  private updateStamp(stamp: Stamp | null) {
    return stamp;
  }

  private get Stamps() {
    const stampGroup = this.stampGroups.find(stampGroup => {
      return stampGroup.id === this.currentTab;
    });

    if (!stampGroup) return [];
    return stampGroup.stamps;
  }

  private get SelectedStampId() {
    if (this.selectedStamp) {
      return this.selectedStamp.id;
    }

    return -1;
  }

  private get remainderOfStampCounts() {
    // スタンプを5個並びにするので余りをスペーサで埋める
    if (this.Stamps.length % 5 === 0) {
      return 0;
    }

    return 5 - (this.Stamps.length % 5);
  }

  private changeGroup(groupId: number) {
    this.currentTab = groupId;
  }

  private selectStamp(stamp: Stamp) {
    // スタンプ選択中にもう一度選択で送信
    if (this.selectedStamp && this.selectedStamp.id === stamp.id) {
      this.sendStamp(this.selectedStamp);
      this.updateStamp(null);
      return;
    }

    this.updateStamp(stamp);
  }
}
