


















import { Component, Mixins, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import { OccurReport } from "#/model/occurreport";
import { COMMON } from "#/const";

@Component({
  components: {
    PatientHeader,
    BaseReportList
  }
})
export default class Occur extends Mixins(AxiosMixin) {
  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  private patientId = 0;

  private items: OccurReport[] = []; //事故ヒヤリハット

  private headers: DataTableHeader[] = [
    {
      text: "発生日",
      value: "o_datetm_wareki",
      align: "start",
      width: "152px",
      sort: SortWarekiDate
    }
  ];

  private commentHeader = {
    text: "コメント",
    value: "remand.comment"
  };

  public created(): void {
    this.patientId = Number(this.$route.params.id);
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchOccurReport({ tab: 0, page: 0 });
    }
  }

  private clickNew(): void {
    this.$router.push({
      name: "PatientOccurEdit",
      params: { id: String(this.patientId), occurid: "0" }
    });
  }

  private clickEdit(item: OccurReport): void {
    this.$router.push({
      name: "PatientOccurEdit",
      params: { id: String(this.patientId), occurid: String(item.id) }
    });
  }

  //事故ヒヤリハット情報一覧取得
  private fetchOccurReport(obj: { tab: number; page: number }): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/occurreports/get",
      {
        patient_id: this.patientId,
        tab: obj.tab,
        page: obj.page
      },
      res => {
        if (res.data.items != null) {
          this.reportList?.finishLoaded(res.data.items);
          if (obj.page != 0) {
            this.items = this.items.concat(res.data.items);
          } else {
            this.items = res.data.items;
          }
        }
      }
    );
  }

  //差し戻し
  private setRemand(recordItem: { item: OccurReport; comment: string }): void {
    recordItem.item.status = this.REPORT_REMAND;
    recordItem.item.remand.comment = recordItem.comment;
    this.postJsonCheck(
      window.base_url + "/api/patient/occurreport/saveRemand",
      {
        occur_report: recordItem.item
      },
      () => {
        this.items = this.items.filter(v => v.id !== recordItem.item.id);
      }
    );
  }

  //PDF
  private pdf(item: OccurReport): void {
    this.makePdf(window.base_url + "/api/patient/occurreport/preview/ids", {
      patient_id: this.patientId,
      occur_report_ids: [item.id],
      occur_reports: [item]
    });
  }
}
