



































































































































































































import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import Sortable from "sortablejs";
import EditMixin from "@/mixins/editMixin";

interface Iitem {
  [key: string]: number | object[];
  id: number;
}

@Component
export default class CommonMasterList extends Mixins(EditMixin) {
  @Prop() private headers!: DataTableHeader[]; //ヘッダー

  @Prop({ default: () => [] }) private subHeaders!: DataTableHeader[]; //サブヘッダー

  @Prop() private subItemKey!: string; //サブアイテムキー

  @Prop({ default: "削除しますか？" }) private subItemDeleteMessage!: string; //サブアイテムdeleteメッセージ

  @Prop() private items!: Iitem[]; //アイテム

  @Prop({ default: false }) private showExpand!: boolean; //拡張フラグ

  @Prop({ default: false }) private isCopy!: boolean; //コピーフラグ

  @Prop({ default: true }) private isOrder!: boolean; //並び替えフラグ

  @Prop({ type: Function, default: () => true }) private isEditFunc!: Function;

  @Prop({ type: Function, default: () => true })
  private isDeleteFunc!: Function;

  @Prop({ type: Function, default: () => true })
  private isDeleteSubFunc!: Function;

  @Prop({ default: false }) private isNoFilterButton!: boolean; // 絞り込みボタン

  @Prop({ default: true }) private isNewItem!: boolean; // 新しく追加する表示非表示

  @Prop({ type: Function, default: () => "draggable" })
  private recordClass!: Function;

  @Prop({ type: Function, default: () => "" })
  private getHelpMessage!: Function;

  private searchWidth = "370px"; //検索横幅

  private searchtext = ""; //検索キーワード

  private isSortMode = false; //並び替え中

  private sortable?: Sortable = undefined; //SortableObject

  private subSortable?: Sortable = undefined; //SubSortableObject

  private expandedItem: Iitem[] = []; //拡張アイテム

  //アクションヘッダー
  private get ActionHeaders() {
    const width = "168px";
    return [
      ...this.headers,
      {
        text: "",
        value: "edit",
        align: "end",
        width: width,
        filterable: false,
        sortable: false
      }
    ];
  }

  //アクションサブヘッダー
  private get ActionSubHeaders() {
    return [
      ...this.subHeaders,
      {
        text: "操作",
        value: "edit",
        width: "168px",
        filterable: false,
        sortable: false
      }
    ];
  }

  mounted() {
    const table = document.querySelector(
      ".common-master-list .v-data-table tbody"
    ) as HTMLElement;
    if (table) {
      //eslint-disable-next-line
      const _self = this; 
      this.sortable = new Sortable(table, {
        animation: 100,
        ghostClass: "ghost",
        draggable: ".draggable",
        onStart() {
          _self.expandedItem = [];
        },
        onEnd(event) {
          if (event.oldIndex !== undefined && event.newIndex !== undefined) {
            const rowSelected = _self.items.splice(event.oldIndex, 1)[0];
            _self.items.splice(event.newIndex, 0, rowSelected);
          }
        }
      });
    }

    this.sortable?.option("disabled", true);
  }

  @Watch("expandedItem")
  watchExpandedItem() {
    this.$nextTick(() => {
      if (this.expandedItem.length < 1) {
        this.subSortable = undefined;
        return;
      }
      const subTable = document.querySelector(
        ".common-master-list .v-data-table tbody .v-data-table tbody"
      ) as HTMLElement;
      //eslint-disable-next-line
      const _self = this;
      this.subSortable = new Sortable(subTable, {
        animation: 100,
        ghostClass: "ghost",
        onEnd(event) {
          if (event.oldIndex !== undefined && event.newIndex !== undefined) {
            for (let index = 0; index < _self.items.length; index++) {
              if (_self.items[index].id === _self.expandedItem[0].id) {
                const rowSelected = (_self.items[index][
                  _self.subItemKey
                ] as object[]).splice(event.oldIndex, 1)[0];
                (_self.items[index][_self.subItemKey] as object[]).splice(
                  event.newIndex,
                  0,
                  rowSelected
                );
              }
            }
          }
        }
      });
      this.subSortable.option("disabled", !this.isSortMode);
    });
  }

  //並び順変更開始
  private startSort() {
    this.searchtext = "";
    this.isSortMode = true;
    this.sortable?.option("disabled", false);
    this.subSortable?.option("disabled", false);
    this.setEditMode();
  }

  //並び順変更終了
  private async endSort() {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.isSortMode = false;
    this.sortable?.option("disabled", true);
    this.subSortable?.option("disabled", true);
    this.saveOrder();
    this.setNoEditMode();
  }

  //並び順キャンセル処理
  private async cancelSort() {
    if (!(await this.$openConfirm("キャンセルしますか？"))) {
      return;
    }
    this.isSortMode = false;
    this.sortable?.option("disabled", true);
    this.subSortable?.option("disabled", true);
    this.cancelOrder();
    this.setNoEditMode();
  }

  //並び順保存処理へ
  @Emit()
  private saveOrder() {
    return;
  }

  //並び順キャンセル処理へ
  @Emit()
  private cancelOrder() {
    return;
  }

  //アイテム編集
  @Emit()
  private editItem(item: unknown) {
    return item;
  }

  //アイテム削除確認
  private async clickDelete(item: unknown) {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.deleteItem(item);
  }

  //アイテム削除
  @Emit()
  private deleteItem(item: unknown) {
    return item;
  }

  //アイテムコピー
  @Emit()
  private copyItem(item: unknown) {
    return item;
  }

  //追加
  @Emit()
  private newItem(newItem: unknown) {
    return newItem;
  }

  //サブアイテム編集
  @Emit()
  private editSubItem(subItem: unknown) {
    return subItem;
  }

  //サブアイテム削除確認
  private async clickDeleteSub(subItem: unknown) {
    if (!(await this.$openConfirm(this.subItemDeleteMessage))) {
      return;
    }
    this.deleteSubItem(subItem);
  }

  //サブアイテム削除
  @Emit()
  private deleteSubItem(subItem: unknown) {
    return subItem;
  }

  //サブアイテム絞り込み
  private filterNestItem(value: unknown, search: string) {
    if (value == null) {
      return false;
    }

    if (search == null) {
      return false;
    }

    if (typeof value === "string") {
      return value.toString().indexOf(search) !== -1;
    }

    if (value instanceof Array) {
      for (const obj of value) {
        for (const head of this.subHeaders) {
          if (typeof obj[head.value] === "string") {
            if (obj[head.value].toString().indexOf(search) !== -1) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  // private clickRow(item: Iitem) {
  //   if (this.isSortMode) {
  //     return;
  //   }
  //   this.editItem(item);
  // }

  private clickSubRow(item: Iitem) {
    if (this.isSortMode) {
      return;
    }
    if (!this.isEditFunc(item)) {
      return;
    }
    this.editSubItem(item);
  }
}
