import Base from "./base";
import { Pdf, DefaultPdf, Remand, DefaultRemand } from "./report";
import {
  ApplyPersonnel,
  DefaultApplyPersonnel,
  ApplyStaff,
  DefaultApplyStaff,
  ApplyDoctor,
  DefaultApplyDoctor,
  ApplyOffice,
  DefaultApplyOffice
} from "./common";

//情報提供書-ADL
export interface ProvisionReportAdl extends Base {
  id: number; //ID
  provision_report_id: number; //'情報提供書ID',
  provision_report_adl_item_id: number; //'情報提供書_ADL項目ID',
  provision_report_adl_select_item_id: number; //'情報提供書_ADL項目選択肢ID',
  item_name: string; //'ADL項目名',
  select_item_name: string; //'情報提供書_ADL項目選択肢名',
}
export const DefaultProvisionReportAdl = (): ProvisionReportAdl => ({
  id: 0,
  provision_report_id: 0,
  provision_report_adl_item_id: 0,
  provision_report_adl_select_item_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  item_name: "",
  select_item_name: ""
});

// 基礎疾患
export interface ProvisionReportUnderlyingCondition extends Base {
  id: number; //ID
  provision_report_id: number; //'情報提供書ID',
  provision_report_underlying_condition_item_id: number; //'情報提供書_基礎疾患項目ID',
  item_name: string; //'基礎疾患項目名',
}
export const DefaultProvisionReportUnderlyingCondition = (): ProvisionReportUnderlyingCondition => ({
  id: 0,
  provision_report_id: 0,
  provision_report_underlying_condition_item_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  item_name: "",
});

//情報提供書-算定
export interface ProvisionReportCalculate extends Base {
  id: number; //ID
  provision_report_id: number; //'情報提供書ID',
  order_no: number; //'登録順\n並び順で使用。算定年月、取得理由が同じ場合にこの項目で並び替える。',
  target_yearmonth: string; //'算定年月',
  reason: number; //'取得理由\n0:未選択\n1:入園\n2:入学\n3:転園\n4:転学\n5:医療的ケアの変更',
  personnel_id: number; //'提供先ID\n記録書Iで登録された提供先（学校）の名称が入る',
  provider: string; //'提供先\n記録書Iで登録された提供先（学校）の名称が入る',
  target_yearmonth_wareki: string; //算定年月（和暦）
  personnel: ApplyPersonnel; //提供先
}
export const DefaultProvisionReportCalculate = (): ProvisionReportCalculate => ({
  id: 0,
  provision_report_id: 0,
  order_no: 0,
  target_yearmonth: "",
  reason: 0,
  personnel_id: 0,
  provider: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  target_yearmonth_wareki: "",
  personnel: DefaultApplyPersonnel(),
});

/** 情報提供書差戻し */
export interface ProvisionReportRemand extends Remand {
  provision_report_id: number; //情報提供書ID
}
export const DefaultProvisionReportRemand = (): ProvisionReportRemand => ({
  ...DefaultRemand(),
  provision_report_id: 0 //情報提供書ID
});

//情報提供書
export interface ProvisionReport extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  report_type: number; //書類タイプ 1:情報提供書（訪問看護情報提供療養費Ⅰ～Ⅲ） 2:情報提供書（口腔連携強化加算）
  report_type_str: string; //書類タイプ（表示用） 1:情報提供書（訪問看護情報提供療養費Ⅰ～Ⅲ） 2:情報提供書（口腔連携強化加算）
  yearmonth: string; //'報告月',
  info_office_id: number; //'情報提供事業所ID',
  doctor_id: number; //'医師ID',
  nstate: string; //'病状・障害等の状態',
  person_info: string; //'家族等及び主な介護者に係る情報',
  ncnt: string; //'看護内容',
  nserv: string; //'必要福祉サービス',
  mention: string; //'そのほか特筆すべき事項',
  disease_passage: string; //'傷病の経過（学校宛の場合のみ）',
  medical_care_attention: string; //'医療的ケア等の実施方法及び留意事項（学校宛の場合のみ）',
  admission_medical_name: string; //'入院又は入所先医療機関',
  introduce_div: number; // 紹介先区分\n1:保険医療機関\n2:介護老人保健施設\n3:介護医療院
  prevhistory: string; //'既往症（医療機関宛の場合のみ）',
  other_service: string; //'その他に利用しているサービス等（医療機関宛の場合のみ）',
  nursing_problem: string; //'看護上の問題点（医療機関宛の場合のみ）',
  care_message: string; //'ケアにおける留意点、継続すべき看護等（医療機関宛の場合のみ）',
  aspiration_pneumonia_onset_flg: number; //(口腔連携) 誤嚥性肺炎の発症・既往の有無 0:無 1:有
  aspiration_pneumonia_onset_yearmonth: string; //(口腔連携) 誤嚥性肺炎発症年月
  paralysis_flg: number; //(口腔連携) 麻痺の有無 0:無 1:有
  paralysis_hand_flg: number; //(口腔連携) 手の麻痺の有無 0:無 1:有
  paralysis_face_flg: number; //(口腔連携) 顔の麻痺の有無 0:無 1:有
  paralysis_other_flg: number; //(口腔連携) その他の麻痺の有無 0:無 1:有
  eating_method_div: number; //(口腔連携) 摂食方法 0:未選択 1:経口のみ 2:一部経口 3:経管栄養 4:静脈栄養
  family_doctor_flg: number; //(口腔連携) かかりつけ医の有無 0:無 1:有
  dental_checkup_flg: number; //(口腔連携) 直近一年の歯科受診の有無 0:無 1:有
  last_dental_checkup_yearmonth: string; //(口腔連携) 最終歯科受診年月
  artificial_tooth_div: number; //(口腔連携) 義歯の使用の有無 0:無 1:部分 2:全部
  clean_mouth_independence_div: number; //(口腔連携) 口腔清掃の自立度 0:未選択 1:自立 2:部分介助 3:全介助
  clean_mouth_support: string; //(口腔連携) 口腔清掃の介助方法
  prescribe_flg: number; //(口腔連携) 処方薬の有無 0:無 1:有
  prescribed_medicine: string; //(口腔連携) 処方薬の薬剤名
  open_mouth_flg: number; //(口腔連携) 開口 0:未選択 1:できる 2:できない
  tooth_dirt_flg: number; //(口腔連携) 歯の汚れ 0:未選択 1:あり 2:なし
  tongue_dirt_flg: number; //(口腔連携) 舌の汚れ 0:未選択 1:あり 2:なし
  blood_flg: number; //(口腔連携) 歯肉の腫れ、出血 0:未選択 1:あり 2:なし
  bite_flg: number; //(口腔連携) 噛み合わせ 0:未選択 1:できる 2:できない
  muse_flg: number; //(口腔連携) むせ 0:未選択 1:あり 2:なし
  bukubuku_flg: number; //(口腔連携) ぶくぶくうがい 0:未選択 1:できる 2:できない
  food_residue_flg: number; //(口腔連携) 食物のためこみ 0:未選択 1:あり 2:なし
  mouth_problem: string; //(口腔連携) その他の口腔健康状態の問題点
  need_dentist_check_flg: number; //(口腔連携) 歯科医師等による口腔内等の確認の必要性 1:低い 2:高い
  dental_message: string; //(口腔連携) 歯科医療機関への連絡事項
  nursing_support_message: string; //(口腔連携) 介護支援専門員への連絡事項
  writer_staff1_id: number; //作成者1
  writer_staff2_id: number; //作成者2
  write_date: string; //'記載日',
  submit_datetime_wareki: string; //提出日時（和暦）
  write_date_wareki: string; //記載日（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  adls: ProvisionReportAdl[]; //ADL
  underlying_conditions: ProvisionReportUnderlyingCondition[]
  calculates: ProvisionReportCalculate[]; //計算
  pdf: Pdf; //PDF
  remand: ProvisionReportRemand; //差し戻し
  editor_staff: ApplyStaff; //編集者
  info_office: ApplyOffice; //情報提供事業所
  doctor: ApplyDoctor; //医師
}
export const DefaultProvisionReport = (): ProvisionReport => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  report_type: 0,
  report_type_str: "",
  submit_datetime: "",
  yearmonth: "",
  info_office_id: 0,
  doctor_id: 0,
  nstate: "",
  person_info: "",
  ncnt: "",
  nserv: "",
  mention: "",
  disease_passage: "",
  medical_care_attention: "",
  admission_medical_name: "",
  introduce_div: 0,
  prevhistory: "",
  other_service: "",
  nursing_problem: "",
  care_message: "",
  write_date: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  submit_datetime_wareki: "",
  aspiration_pneumonia_onset_flg: 0,
  aspiration_pneumonia_onset_yearmonth: "",
  paralysis_flg: 0,
  paralysis_hand_flg: 0,
  paralysis_face_flg: 0,
  paralysis_other_flg: 0,
  eating_method_div: 0,
  family_doctor_flg: 0,
  dental_checkup_flg: 0,
  last_dental_checkup_yearmonth: "",
  artificial_tooth_div: 0, 
  clean_mouth_independence_div: 0,
  clean_mouth_support: "",
  prescribe_flg: 0,
  prescribed_medicine: "",
  open_mouth_flg: 0,
  tooth_dirt_flg: 0,
  tongue_dirt_flg: 0,
  blood_flg: 0,
  bite_flg: 0,
  muse_flg: 0,
  bukubuku_flg: 0,
  food_residue_flg: 0,
  mouth_problem: "",
  need_dentist_check_flg: 0,
  dental_message: "",
  nursing_support_message: "",
  writer_staff1_id: 0,
  writer_staff2_id: 0,
  write_date_wareki: "",
  updated_at_wareki: "",
  adls: [],
  underlying_conditions: [],
  calculates: [],
  pdf: DefaultPdf(),
  remand: DefaultProvisionReportRemand(),
  editor_staff: DefaultApplyStaff(),
  info_office: DefaultApplyOffice(),
  doctor: DefaultApplyDoctor()
});
