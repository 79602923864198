







































































































import {
  Component,
  Prop,
  Watch,
  Mixins,
  Ref,
  Emit
} from "vue-property-decorator";
import { uuid } from "vue-uuid";
import { VForm } from "@/types";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import {
  OfficeSharedFile,
  OfficeSharedFileTag,
  DefaultOfficeSharedFile,
  DefaultOfficeShareFileTag
} from "#/model/agreement";
import RulesMixin from "#/mixins/rulesMixin";
import ShareFileMixin from "./sharefileMixins";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    OfficeSelect
  }
})
export default class FileUpload extends Mixins(
  RulesMixin,
  UtilMixin,
  ShareFileMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop() dialog!: boolean; // ダイアログ表示状態
  @Prop({ default: DefaultOfficeSharedFile() }) model!: OfficeSharedFile;
  @Prop({ default: "*/*" }) accept!: string;
  @Prop({ default: false }) isTimestamp!: boolean;

  @Emit()
  public close(isUpdate: boolean) {
    return isUpdate;
  }

  private openFlg = true; // ダイアログ展開時バリデーション回避フラグ

  // コンポーネント内変数
  private localImage: File | [] = [];
  private isModify = false;
  private tagText = "";

  // 初期化処理
  @Watch("dialog")
  private init() {
    this.localImage = [];
    this.isModify = false;
    this.tagText = "";
    this.openFlg = true;

    this.$nextTick(() => {
      this.form.resetValidation();
    });
  }

  // ファイルディレクトリパス
  private get imageFilePath() {
    // [co_code]/[kst_no]/share/[事業所ID]/[ファイル名(uuid)]
    const kstNo = this.getKstnoByOfficeId(this.model.office_id);
    return `${this.siteName}/${kstNo}/share/${this.model.office_id}/`;
  }

  // ファイル名取得
  private getFileName(path: string): string {
    const strs = path.split("/").reverse();
    if (strs.length == 0) return "";
    return strs[0];
  }

  // 登録イベント
  private async clickRegist() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      this.requiredFileExt();
      return;
    }

    if (this.isModify && this.localImage instanceof File) {
      const formData = new FormData();
      formData.append("upload", this.localImage);
      let path = this.model.file_path;
      if (!path) {
        // パスが存在しない（新規に登録）場合はパスを作成
        path = this.imageFilePath;
        if (path.slice(-1) !== "/") {
          path += "/";
        }
        path += uuid.v4();
      }
      formData.append("path", path);
      formData.append("is_temp", "0");
      this.postJsonCheck(window.base_url + "/api/upload", formData, res => {
        this.model.file_path = res.data.path;
        this.saveShareFile();
      });
    } else {
      this.saveShareFile();
    }
  }

  private saveShareFile() {
    // 保存処理
    this.postJsonCheck(
      window.base_url + "/api/share/save",
      {
        office_share_file: this.model,
        is_file_update: this.isModify,
        is_timestamp: this.isTimestamp
      },
      () => {
        this.close(true);
      }
    );
  }

  // キャンセルイベント
  private clickCancel() {
    this.close(false);
  }

  // 削除イベント
  private async clickDelete() {
    if (
      !(await this.$openConfirm(
        "削除します。一度削除するとデータは復旧できません。よろしいですか？"
      ))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/share/delete",
      { office_share_file: this.model },
      () => {
        this.close(true);
      }
    );
  }

  //ファイル取り消しイベント
  private clearForm() {
    this.localImage = [];
    this.model.file_name = "";
    this.isModify = true;
  }

  // ファイル変更イベント
  private changeFileInput() {
    if (this.localImage instanceof File) {
      const fileName = this.localImage.name;
      // ファイル名リネームチェック
      if (fileName.length > 100) {
        const splitName = fileName.split(".");
        const sliceText = splitName[0].slice(0, 90);
        this.model.file_name = sliceText + "." + splitName[1];
      } else {
        this.model.file_name = this.localImage.name;
      }
      this.isModify = true;
    }
  }

  // ファイル必須チェック
  private requiredFileExt(): boolean | string {
    if (this.model.file_path) {
      return true;
    } else if (this.localImage instanceof File) {
      return this.localImage.size > 0 || "必須です";
    } else if (!this.localImage || this.localImage.length == 0) {
      return "必須です";
    }
    this.openFlg = false;
    return true;
  }

  // タグ削除
  private removeTag(idx: number) {
    this.model.tags.splice(idx, 1);
  }

  // タグ追加
  private addTag() {
    if (!this.tagText) {
      return;
    }
    const tag: OfficeSharedFileTag = DefaultOfficeShareFileTag();
    tag.tag = this.tagText;
    this.model.tags.push(tag);
    this.tagText = "";
  }
}
