

















































































































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { Choice, RequesterChoice, VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import EditMixin from "@/mixins/editMixin";
import { MedicineTaking, DefaultMedicineTaking } from "#/model/medicinetaking";

@Component
export default class MedicineTakingEdit extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  PatientMixin,
  EditMixin
) {
  /** Form */
  @Ref("patient-header") private readonly form!: VForm;

  /** 利用者ID */
  private patientId = 0;

  /** 服薬管理 */
  private medicine_taking: MedicineTaking = DefaultMedicineTaking();

  /** 薬品名リスト */
  private medicine_names: Choice[] = [];

  /** 病院リスト */
  private get LocalHospitals(): RequesterChoice[] {
    return this.MedicalInsOnly.map(v => {
      return {
        id: v.id,
        value: v.value,
        text: v.med_name ?? "",
        tel_area: v.tel_area,
        tel_city: v.tel_city,
        tel_no: v.tel_no,
        med_id: v.med_id,
        med_name: v.med_name
      };
    });
  }

  /** 医師リスト */
  private get LocalDoctors(): RequesterChoice[] {
    return this.Doctors.map(v => {
      return {
        id: v.id,
        value: v.value,
        text: v.doc_name ?? "",
        tel_area: v.tel_area,
        tel_city: v.tel_city,
        tel_no: v.tel_no,
        med_id: v.med_id,
        med_name: v.med_name
      };
    });
  }

  /** 絞り込み医師リスト */
  private get FilterDoctors(): RequesterChoice[] {
    //服薬管理データが0の場合何もしない
    if (this.medicine_taking.m_name == "") {
      return this.LocalDoctors;
    }

    const doctors: RequesterChoice[] = [];
    let hospitalId: string | number;
    hospitalId = 0;
    //入力されている医療機関の医療機関IDを取り出す
    for (const hospital of this.LocalHospitals) {
      if (hospital.text == this.medicine_taking.m_name) {
        hospitalId = hospital.med_id ?? "";
      }
    }
    //その医療機関IDが0でない場合、医療機関は選択されたことになる
    if (hospitalId != 0) {
      const doctors: RequesterChoice[] = [];
      //医師名リストを医療機関IDで絞る（医師名otherの中に所属医療機関ID持っている）
      for (const doctor of this.LocalDoctors) {
        if (hospitalId == doctor.med_id) {
          doctors.push(doctor);
        }
      }
      return doctors;
    }
    //選択されていない場合（手動入力の場合）医師名は全件表示
    return doctors;
  }

  /** 入力した薬品名がマスタ登録されているかどうか */
  private get IsNotRegisterdName(): boolean {
    if (!this.medicine_taking) {
      return false;
    }
    for (const medicine_name of this.medicine_names) {
      if (medicine_name.text == this.medicine_taking.medicine_name) {
        return false;
      }
    }
    return true;
  }

  @Watch("medicine_taking", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  created() {
    this.patientId = Number(this.$route.params.id);
    this.fetchMedicineTaking();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/medicinetaking`
      },
      {
        text: "服薬管理",
        disabled: true,
        to: ""
      }
    ]);
  }

  //服薬管理一覧取得
  private fetchMedicineTaking(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/medicinetakings/get",
      {
        patient_id: this.patientId,
        include_stop: true,
        medicine_id: Number(this.$route.params.medicine_id)
      },
      res => {
        this.medicine_names = res.data.medicine_names;
        if (
          this.$route.params.medicine_id != "0" &&
          this.$route.params.medicine_id != ""
        ) {
          this.medicine_taking = res.data.medicine_takings[0];
          if (typeof this.$route.query.copy === "string") {
            this.medicine_taking.id = 0;
          }
        } else {
          this.medicine_taking = DefaultMedicineTaking();
        }
        this.setLoaded();
      }
    );
  }

  /** 保存ボタンを押した時 */
  private async clickSave() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.save();
  }

  /** 保存 */
  private async save() {
    //薬品名のマスタ登録をするかの判定
    let saveMasterFlg = 0;
    const medicine_master = {
      id: 0,
      name: ""
    };
    //入力した薬品名がマスタ登録されていない場合
    if (this.IsNotRegisterdName && this.medicine_taking.medicine_name) {
      if (await this.$openConfirm("薬品名をマスタ登録しますか？")) {
        saveMasterFlg = 1;
        medicine_master.name = this.medicine_taking.medicine_name;
      }
    }

    this.medicine_taking.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/medicinetaking/save",
      {
        patient_id: this.patientId,
        medicine_taking: this.medicine_taking,
        save_master_flg: saveMasterFlg,
        medicine_master: medicine_master
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  /** キャンセル */
  private cancel() {
    if (this.$route.name === "VisitMedicinetakingEdit") {
      window.close();
    } else {
      this.$router.go(-1);
    }
  }

  /** 削除 */
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.postJsonCheck(
        window.base_url + "/api/patient/medicinetaking/delete",
        {
          medicine_taking: this.medicine_taking
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  /** 医師名選択時、その医師所属の医療機関名自動選択 */
  private selectDName() {
    //選択した医師名から、その医師名に持っている医療機関IDを取り出してくる
    if (!this.medicine_taking.d_name) {
      return;
    }
    //既に医療機関名が入力されていた場合、その機関内の医師名を選んだので機関名は変更しない
    if (this.medicine_taking.m_name) {
      return;
    }

    for (const doctor of this.LocalDoctors) {
      if (doctor.text == this.medicine_taking.d_name) {
        this.medicine_taking.m_name = doctor.med_name ?? "";
      }
    }
  }
}
