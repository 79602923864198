
















































import { Component, Mixins } from "vue-property-decorator";
import { Menu } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class Master extends Mixins(AxiosMixin) {
  private master_menus: Menu[] = [];

  created() {
    this.postJsonCheck(window.base_url + "/api/master/base/get", {}, res => {
      this.master_menus = res.data.menus;
    });
  }

  private linkto(menu: Menu) {
    this.$router.push(`/master/${menu.url}`);
  }
}
