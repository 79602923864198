






































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import FileUpload from "#/components/FileUpload.vue";
import {
  Inquiry,
  DEFAULT_INQUIRY,
  DEFAULT_INQUIRY_FILE
} from "#/model/inquiry";

@Component({
  components: {
    FileUpload
  }
})
export default class InquiryFileUploader extends Mixins(UtilMixin, AxiosMixin) {
  @Prop({ default: () => DEFAULT_INQUIRY }) inquiry!: Inquiry;

  /** アップロード可能なファイルタイプ */
  private accepts = ["application/pdf", "image/*"];
  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";
  /** 添付可能数 */
  private FILE_LIMIT = 5;
  /** 保存先 */
  private get imagePath(): string {
    return `admin/inquiry/${this.inquiry.id}/`;
  }

  @Watch("inquiry", { immediate: true })
  onChangeInquiry() {
    this.inquiry.inquiry_files.forEach(file => {
      file.path = file.file_path;
    });
    this.relocationFile();
  }

  /** 添付枠を追加 */
  private add(): void {
    const filesCount = this.inquiry.inquiry_files.length;
    if (filesCount < this.FILE_LIMIT) {
      const inquiryFile = this.deepCopy(DEFAULT_INQUIRY_FILE);
      inquiryFile.id = Date.now(); // 仮のID、送信時に0にする
      inquiryFile.inquiry_id = this.inquiry.id;
      this.inquiry.inquiry_files.push(inquiryFile);
    }
  }

  /** ファイルを追加した時 */
  private onAddFile() {
    this.add();
  }

  /** ファイルをダイアログ操作で削除した時 */
  private onDeleteFile() {
    this.$nextTick(() => {
      this.relocationFile();
    });
  }

  /** 一覧の内、ファイルパスのないものを取り除く */
  private relocationFile() {
    // 何番目を削除するか決めてから、リアクティブに消す
    const delIndexes: number[] = [];
    this.inquiry.inquiry_files.forEach((file, idx) => {
      if (!file.path) delIndexes.push(idx);
    });
    delIndexes.reverse().forEach(delIndex => {
      this.inquiry.inquiry_files.splice(delIndex, 1);
    });
    this.add();
  }

  /** 添付ファイルを消してアイテム行を取り除く */
  private async remove(itemKey: number): Promise<void> {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }

    const uploader = (this.$refs[`fileUpload_${itemKey}`] as FileUpload[])?.[0];
    uploader?.FileReset?.();
    this.$nextTick(() => {
      this.relocationFile();
    });
  }
}
