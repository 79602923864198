/** その他の情報 */
export interface OtherInfo {
  main_disease1: string; // 主傷病1
  main_disease2: string; // 主傷病2
  main_disease3: string; // 主傷病3
  other_disease: string; // その他の傷病
  beppyo7: string; // 基準告示第２の１に規定する疾病等
  beppyo7_disease: string[]; // 別表７疾病
  beppyo8: string; // 基準告示第２の１に規定する状態
  beppyo8_states: string[]; // 【 選択済別表8状態 】
  special_manage: string; // 特別管理
  hour24: string; // 24時間体制
  hospital: string; // 在宅療養支援診療所又は在宅療養支援病院
  emergency: string; // 緊急時訪問
  long: string[]; // 長時間訪問看護加算条件
}

/** その他の情報初期値 */
export const OtherInfoDefaultValues = (): OtherInfo => ({
  main_disease1: "",
  main_disease2: "",
  main_disease3: "",
  other_disease: "",
  beppyo7: "",
  beppyo7_disease: [],
  beppyo8: "",
  beppyo8_states: [],
  special_manage: "",
  hour24: "",
  hospital: "",
  emergency: "",
  long: []
});
