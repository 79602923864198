

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { Choice, ChoiceAddOther, VForm } from "@/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import TextDialogItem from "@/components/patient/vnplan/TextDialogItem.vue";
import SolutionDialogItem from "@/components/patient/vnplan/SolutionDialogItem.vue";
import RemandInfo from "@/components/patient/common/remand/RemandInfo.vue";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import UtilMixin from "@/mixins/utilMixin";
import EditMixin from "@/mixins/editMixin";
import {
  VnPlan,
  DefaultVnPlan,
  VnPlanMark,
  DefaultVnPlanMark,
  VnPlanProblem,
  DefaultVnPlanRemand,
  DefaultVnPlanProblem,
  DefaultVnPlanSend,
  VnPlanProblemCareContent,
  DefaultVnPlanProblemCareContent
} from "#/model/vnplan";
import {
  Term,
  DefaultTerm,
  TermLong,
  TermShort,
  TermProblem,
  TermSolution,
  CareProblemSolution,
  NursingDetailMaster,
  CareMediumContent,
  DefaultCareMediumContent,
  CareLargeContent
} from "#/model/master";
import PatientRelationInsSelect from "@/components/patient/common_ibow/PatientRelationInsSelect.vue";
import PatientMixin from "@/mixins/patientMixin";
import ExpantionActions from "@/components/patient/ExpantionActions.vue";
import AiRecommendDiseaseButton from "@/components/common_ibow/AiRecommendDiseaseButton.vue";

@Component({
  components: {
    PatientHeader,
    PatientSaveArea,
    TextDialogItem,
    SolutionDialogItem,
    PatientRelationInsSelect,
    RemandInfo,
    ExpantionActions,
    AiRecommendDiseaseButton
  }
})
export default class PatientPlan extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private vnplanId = 0;
  private patientId = 0;
  private aiDialog = false;
  private searchProblemDialog = false;
  private emptyTermFlg = false; //目標内容空フラグ
  private emptyProblemFlg = false; //問題点空フラグ
  private pushSubmissionFlg = false; //提出押下フラグ

  private vn_plan: VnPlan = DefaultVnPlan(); //看護計画書
  private gender = ""; //性別
  private age = ""; //年齢
  private main_disease1 = ""; //主傷病名1
  private main_disease2 = ""; //主傷病名2
  private main_disease3 = ""; //主傷病名3
  private dwenv = ""; //住居環境
  private careContent: NursingDetailMaster = { care_large_contents: [] }; //看護内容マスタ
  private term_main_disease1: Term = DefaultTerm(); //主傷病1AIお勧め検索リスト
  private term_main_disease2: Term = DefaultTerm(); //主傷病2AIお勧め検索リスト
  private term_main_disease3: Term = DefaultTerm(); //主傷病3AIお勧め検索リスト
  private long_marks: VnPlanMark[] = []; //長期目標
  private short_marks: VnPlanMark[] = []; //短期目標
  private problems: VnPlanProblem[] = []; //問題点・解決策・評価
  private problem_solutions: CareProblemSolution[] = []; //問題点・解決策検索一覧
  private keywords = ""; //キーワード（問題点・解決策検索）
  private relatedDiseaseName = ""; //関連病名（問題点・解決策検索）
  private writerStaffs: ChoiceAddOther[] = []; //作成者
  private staffs: Choice[] = []; //担当者
  private validCareFlg = 0; //居宅サービスが登録されている有効な介護保険フラグ
  private corpItems: Choice[] = []; //担当ケアマネ（居宅介護支援事業所）
  private informationProviders: Choice[] = []; //情報提供先
  private requestors: Choice[] = []; //地域包括支援センター
  private others: Choice[] = []; //その他
  private patient_show_flag = false; //利用者同意欄に利用者名を記載する

  //-----------------------------------------------------
  // AIお勧め検索
  private includeTermLongDisease1 = false; //長期目標Disease1にチェックがあるかどうか
  private includeTermLongDisease2 = false; //長期目標Disease2にチェックがあるかどうか
  private includeTermLongDisease3 = false; //長期目標Disease3にチェックがあるかどうか
  private includeTermShortDisease1 = false; //短期目標Disease1にチェックがあるかどうか
  private includeTermShortDisease2 = false; //短期目標Disease2にチェックがあるかどうか
  private includeTermShortDisease3 = false; //短期目標Disease3にチェックがあるかどうか
  private includeTermProblem = false; //問題点
  //-----------------------------------------------------

  //-----------------------------------------------------
  // ダイアログ
  @Ref("long_mark") private readonly longMark!: TextDialogItem;
  @Ref("short_mark") private readonly shortMark!: TextDialogItem;

  @Ref("problem") private readonly problem!: TextDialogItem;
  @Ref("solution") private readonly solution!: SolutionDialogItem;
  @Ref("evaluation") private readonly evaluation!: TextDialogItem;
  //-----------------------------------------------------

  //-----------------------------------------------------
  // ダイアログ用値保持
  private long_idx = 0; //長期目標データの番数
  private long_mark = ""; //長期目標
  private short_idx = 0; //短期目標データの番数
  private short_mark = ""; //短期目標

  private problem_idx = 0; //問題点・解決策・評価データの番数
  private problem_point = ""; //問題点
  private care_content_items: VnPlanProblemCareContent[] = []; //看護内容
  private solution_item = ""; //解決策
  private points_of_concern_item = ""; //留意事項
  private evaluation_point = ""; //評価
  //-----------------------------------------------------

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  private get StorageKey(): string {
    return `vn_plan-${this.loginUser.id}-${this.patientId}-${this.vnplanId}`;
  }

  /** 差し戻し情報があるか */
  private get HasRemandInfo() {
    return this.vn_plan.remand.id && this.vn_plan.remand.editor_staff_id;
  }

  //今日の年月日を返す
  private today(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const today = date.toISOString().substr(0, 10);
    return today;
  }

  //計画月と記載日を空欄にする
  private defaultDate() {
    this.vn_plan.yearmonth = "";
    this.vn_plan.write_date = "";
  }

  //編集モード設定
  @Watch("vn_plan", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      vn_plan: this.vn_plan,
      long_marks: this.long_marks,
      short_marks: this.short_marks,
      problems: this.problems,
      displayPreview: this.displayPreview,
      patient_show_flag: this.patient_show_flag
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  /** 利用者同意欄 */
  @Watch("patient_show_flag") private watchPatientShowFlag() {
    this.watchData();
  }

  /** 長期目標 */
  @Watch("long_marks", { deep: true }) private watchLongMarks() {
    this.watchData();
  }

  /** 短期目標 */
  @Watch("short_marks", { deep: true }) private watchShortMarks() {
    this.watchData();
  }

  /** 問題点 */
  @Watch("problems", { deep: true }) private watchProblems() {
    this.watchData();
  }

  //計画月：変更時getYearmonthChange走らせる
  @Watch("vn_plan.yearmonth")
  private handleYearmonth() {
    if (!this.IsLoaded) {
      return;
    }
    this.getYearmonthChange();
  }

  //衛生材料等が必要な処置の有無
  @Watch("vn_plan.sanitation_flg")
  private handleSanitationFlg() {
    if (!this.IsLoaded) {
      return;
    }
    if (this.vn_plan.sanitation_flg == 0) {
      this.vn_plan.treatment = "なし";
      this.vn_plan.sanitation_type_size = "なし";
      this.vn_plan.sanitation_amount = "なし";
    }
  }

  //利用者同意欄に利用者名を記載する
  @Watch("patient_show_flag")
  private handlePatientShowFlag() {
    if (this.patient_show_flag) {
      this.vn_plan.patient_show_flag = 1;
    } else {
      this.vn_plan.patient_show_flag = 0;
    }
  }

  created() {
    this.vnplanId = Number(this.$route.params.vnplanid);
    this.patientId = Number(this.$route.params.id);
    this.fetchVnPlan();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/plan`
      },
      {
        text: "看護計画書",
        disabled: true,
        to: ""
      }
    ]);
  }

  //キャンセル
  private cancel() {
    this.vn_plan.id = this.vnplanId;
    this.vn_plan.patient_id = this.patientId;
    // オペログ書き込み用
    this.postJsonBackground(
      window.base_url + "/api/patient/vnplan/cancel",
      {
        vn_plan: this.vn_plan
      },
      () => {
        // Do Nothing
      }
    );
    this.$router.go(-1);
  }

  //保存
  private save(isMove: boolean) {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/vnplan/save", false, isMove);
    });
  }

  //提出
  private async submission(isPreview = false) {
    //目標内容と問題点が空かどうかチェック
    this.pushSubmissionFlg = true;
    this.emptyTermFlg = false;
    this.emptyProblemFlg = false;
    this.checkMark();
    this.checkProblem();
    if (
      !this.patientHeader.validate() ||
      this.emptyTermFlg ||
      this.emptyProblemFlg
    ) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (this.long_marks.length == 0 && this.short_marks.length == 0) {
      await this.$openAlert("長期・短期目標のどちらか必須です");
      return;
    }

    this.saveAction(2, "/api/patient/vnplan/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.vn_plan.patient_id = this.patientId;
    this.vn_plan.status = status;

    //目標を一つにまとめる（看護計画書に入れる）
    this.vn_plan.marks = [];
    for (const long_mark of this.long_marks) {
      this.vn_plan.marks.push(long_mark);
    }
    for (const short_mark of this.short_marks) {
      this.vn_plan.marks.push(short_mark);
    }

    //問題点を看護計画書に入れる
    this.vn_plan.problems = [];
    for (const problem of this.problems) {
      this.vn_plan.problems.push(problem);
    }

    //宛名が未選択の場合は保存しない
    this.editSends();

    this.makePdf(
      window.base_url + url,
      {
        vn_plan: this.vn_plan,
        is_preview: isPreview
      },
      res => {
        this.setNoEditMode();
        if (isMove) {
          this.cancel();
        } else {
          this.vnplanId = res.data.id;
          this.vn_plan.id = res.data.id;
          this.vn_plan.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { vnplanid: String(this.vnplanId) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //宛名が未選択の場合は保存しない
  private editSends() {
    const sends = this.vn_plan.sends.concat();
    this.vn_plan.sends = [];
    for (const send of sends) {
      if (send.support_office_div != 0) {
        this.vn_plan.sends.push(send);
      }
    }
  }

  //削除
  private clickDelete() {
    this.vn_plan.id = this.vnplanId;
    this.vn_plan.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/vnplan/delete",
      {
        vn_plan: this.vn_plan
      },
      () => {
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //プレビュー
  private preview() {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.vn_plan.patient_id = this.patientId;
      this.vn_plan.marks = [...this.long_marks, ...this.short_marks]; //目標を一つにまとめる（看護計画書に入れる）
      this.vn_plan.problems = this.problems; //問題点を看護計画書に入れる
      this.makePdf(window.base_url + "/api/patient/vnplan/preview", {
        vn_plan: this.vn_plan
      });
    });

    return;
  }

  //計画月変更した時：有効な介護保険フラグと担当ケアマネID
  private getYearmonthChange(isInit = false): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/vnplan/yearmonthchange/get",
      {
        patient_id: this.patientId,
        yearmonth: this.vn_plan.yearmonth
      },
      res => {
        this.validCareFlg = res.data.valid_care_flg;
        if (this.validCareFlg == 1) {
          this.vn_plan.care_personnel_id = res.data.plan_writer_personnel_id;
        }
        this.main_disease1 = res.data.main_disease1;
        this.main_disease2 = res.data.main_disease2;
        this.main_disease3 = res.data.main_disease3;

        if (isInit) {
          this.execAfterFetch(this.StorageKey, data => {
            this.vn_plan = data.vn_plan;
            this.long_marks = data.long_marks;
            this.short_marks = data.short_marks;
            this.problems = data.problems;
            this.displayPreview = data.displayPreview;
            this.patient_show_flag = data.patient_show_flag;
          });
        }
      }
    );
  }

  //看護計画書情報取得
  private fetchVnPlan(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/vnplan/get",
      {
        patient_id: this.patientId,
        vnplan_id: this.vnplanId
      },
      res => {
        this.gender = res.data.gender;
        this.age = res.data.age;
        this.main_disease1 = res.data.main_disease1;
        this.main_disease2 = res.data.main_disease2;
        this.main_disease3 = res.data.main_disease3;
        this.dwenv = res.data.dwenv;
        this.careContent = res.data.care_content;
        this.writerStaffs = res.data.writer_staffs;
        this.staffs = res.data.staffs;
        this.corpItems = res.data.corp_items;
        this.informationProviders = res.data.information_providers;
        this.requestors = res.data.requestors;
        this.others = res.data.others;
        this.vn_plan = res.data.vn_plan;

        //利用者同意欄に利用者名を記載する
        if (this.vn_plan.patient_show_flag == 1) {
          this.patient_show_flag = true;
        } else {
          this.patient_show_flag = false;
        }
        this.long_marks = res.data.long_marks;
        this.short_marks = res.data.short_marks;
        this.problems = res.data.problems;
        this.sortMark(this.long_marks);
        this.sortMark(this.short_marks);
        this.sortProblem(this.problems);

        // 看護内容の中項目枠が存在しない(null)場合は、空配列で初期化
        this.careContent.care_large_contents = this.careContent.care_large_contents.map(
          (largeCareContent: CareLargeContent) => {
            if (!largeCareContent.care_medium_contents) {
              largeCareContent.care_medium_contents = [] as CareMediumContent[];
            }
            return largeCareContent;
          }
        );

        if (this.vnplanId === 0) {
          this.defaultDate();
          this.vn_plan.remand = DefaultVnPlanRemand();
        }

        this.getYearmonthChange(true);
      }
    );
  }

  //-----------------------------------------------------
  // AIお勧め検索
  //-----------------------------------------------------
  //AIお勧め検索
  private searchAI() {
    this.postJsonCheck(
      window.base_url + "/api/patient/vnplan/ai/get",
      {
        main_disease1: this.main_disease1,
        main_disease2: this.main_disease2,
        main_disease3: this.main_disease3
      },
      res => {
        this.term_main_disease1 = res.data.term_main_disease1;
        this.term_main_disease2 = res.data.term_main_disease2;
        this.term_main_disease3 = res.data.term_main_disease3;
        if (!this.term_main_disease1.term_longs) {
          this.term_main_disease1.term_longs = [];
          this.term_main_disease1.term_shorts = [];
          this.term_main_disease1.term_problems = [];
        }
        if (!this.term_main_disease2.term_longs) {
          this.term_main_disease2.term_longs = [];
          this.term_main_disease2.term_shorts = [];
          this.term_main_disease2.term_problems = [];
        }
        if (!this.term_main_disease3.term_longs) {
          this.term_main_disease3.term_longs = [];
          this.term_main_disease3.term_shorts = [];
          this.term_main_disease3.term_problems = [];
        }

        const lists = this.cutList(
          this.term_main_disease1.term_longs,
          this.term_main_disease2.term_longs,
          this.term_main_disease3.term_longs,
          5
        );
        this.term_main_disease1.term_longs = lists[0] as TermLong[];
        this.term_main_disease2.term_longs = lists[1] as TermLong[];
        this.term_main_disease3.term_longs = lists[2] as TermLong[];
        this.aiDialog = true;
      }
    );
  }

  private cutList(
    list1: unknown[],
    list2: unknown[],
    list3: unknown[],
    limit: number
  ) {
    const subList1 = [...list1];
    const subList2 = [...list2];
    const subList3 = [...list3];
    let diseaseLength = subList1.length + subList2.length + subList3.length;
    while (diseaseLength > limit) {
      if (
        subList1.length > subList2.length &&
        subList1.length > subList3.length
      ) {
        subList1.splice(subList1.length - 1, 1);
      } else if (subList2.length > subList3.length) {
        subList2.splice(subList2.length - 1, 1);
      } else {
        subList3.splice(subList3.length - 1, 1);
      }
      diseaseLength = subList1.length + subList2.length + subList3.length;
    }
    return [subList1, subList2, subList3];
  }

  private get TermShort1() {
    return this.getTermShort()[0];
  }

  private get TermShort2() {
    return this.getTermShort()[1];
  }

  private get TermShort3() {
    return this.getTermShort()[2];
  }

  private getTermShort() {
    let termshot1: unknown[] = [];
    if (this.includeTermLongDisease1) {
      termshot1 = this.term_main_disease1.term_shorts;
    }
    let termshot2: unknown[] = [];
    if (this.includeTermLongDisease2) {
      termshot2 = this.term_main_disease2.term_shorts;
    }
    let termshot3: unknown[] = [];
    if (this.includeTermLongDisease3) {
      termshot3 = this.term_main_disease3.term_shorts;
    }
    return this.cutList(termshot1, termshot2, termshot3, 10);
  }

  private get TermSolution1() {
    return this.getTermSolution()[0];
  }

  private get TermSolution2() {
    return this.getTermSolution()[1];
  }

  private get TermSolution3() {
    return this.getTermSolution()[2];
  }

  private getTermSolution() {
    let termsol1: unknown[] = [];
    if (this.includeTermShortDisease1) {
      termsol1 = this.term_main_disease1.term_problems;
    }
    let termsol2: unknown[] = [];
    if (this.includeTermShortDisease2) {
      termsol2 = this.term_main_disease2.term_problems;
    }
    let termsol3: unknown[] = [];
    if (this.includeTermShortDisease3) {
      termsol3 = this.term_main_disease3.term_problems;
    }
    return this.cutList(termsol1, termsol2, termsol3, 15);
  }

  //長期目標チェックDisease1
  private termLongDisease1(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease1.term_longs[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease1.term_longs[idx].check_flg = false;
    }
    this.includeTermLongDisease1 = this.includeLongCheck(
      this.term_main_disease1,
      1
    );
    this.scrollToId("term_short");
  }
  //長期目標チェックDisease2
  private termLongDisease2(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease2.term_longs[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease2.term_longs[idx].check_flg = false;
    }
    this.includeTermLongDisease2 = this.includeLongCheck(
      this.term_main_disease2,
      2
    );
    this.scrollToId("term_short");
  }
  //長期目標チェックDisease3
  private termLongDisease3(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease3.term_longs[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease3.term_longs[idx].check_flg = false;
    }
    this.includeTermLongDisease3 = this.includeLongCheck(
      this.term_main_disease3,
      3
    );
    this.scrollToId("term_short");
  }
  private scrollToId(id: string) {
    this.$nextTick(() => {
      document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    });
  }
  //長期目標チェックが一つでもあった時
  private includeLongCheck(term_main: Term, num: number): boolean {
    let includeTermLong = false;
    for (const termLong of term_main.term_longs) {
      if (termLong.check_flg) {
        includeTermLong = true;
      }
    }
    //ひとつもない時
    if (!includeTermLong) {
      if (num == 1) {
        this.includeTermShortDisease1 = false;
      }
      if (num == 2) {
        this.includeTermShortDisease2 = false;
      }
      if (num == 3) {
        this.includeTermShortDisease3 = false;
      }
      this.includeTermProblem = false;
      this.deleteShortCheck(term_main);
      this.deleteProblemCheck(term_main);
    }
    return includeTermLong;
  }
  //長期目標チェックを消す
  private deleteLongCheck(term_main: Term) {
    for (const termLong of term_main.term_longs) {
      termLong.check_flg = false;
    }
  }

  //短期目標チェックDisease1
  private termShortDisease1(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease1.term_shorts[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease1.term_shorts[idx].check_flg = false;
    }
    this.includeTermShortDisease1 = this.includeShortCheck(
      this.term_main_disease1
    );
    this.scrollToId("term_problem");
  }
  //短期目標チェックDisease2
  private termShortDisease2(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease2.term_shorts[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease2.term_shorts[idx].check_flg = false;
    }
    this.includeTermShortDisease2 = this.includeShortCheck(
      this.term_main_disease2
    );
    this.scrollToId("term_problem");
  }
  //短期目標チェックDisease3
  private termShortDisease3(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease3.term_shorts[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease3.term_shorts[idx].check_flg = false;
    }
    this.includeTermShortDisease3 = this.includeShortCheck(
      this.term_main_disease3
    );
    this.scrollToId("term_problem");
  }
  //短期目標チェックが一つでもあった時
  private includeShortCheck(term_main: Term): boolean {
    let includeTermShort = false;
    for (const termShort of term_main.term_shorts) {
      if (termShort.check_flg) {
        includeTermShort = true;
      }
    }
    //ひとつもない時
    if (!includeTermShort) {
      this.includeTermProblem = false;
      this.deleteProblemCheck(term_main);
    }
    return includeTermShort;
  }
  //短期目標チェックを消す
  private deleteShortCheck(term_main: Term) {
    for (const termShort of term_main.term_shorts) {
      termShort.check_flg = false;
    }
  }

  //看護内容の表示（改善策）
  private solutionDisp(solutions: TermSolution[]): string {
    if (!solutions) return "";
    return solutions.map(solution => [solution.solution]).join("、");
  }

  //問題点チェックDisease1
  private termProblemDisease1(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease1.term_problems[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease1.term_problems[idx].check_flg = false;
    }
    this.includeProblemCheck(this.term_main_disease1);
  }
  //問題点チェックDisease2
  private termProblemDisease2(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease2.term_problems[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease2.term_problems[idx].check_flg = false;
    }
    this.includeProblemCheck(this.term_main_disease2);
  }
  //問題点チェックDisease3
  private termProblemDisease3(idx: number, checkFlg: boolean) {
    if (checkFlg) {
      this.term_main_disease3.term_problems[idx].check_flg = true;
    }
    if (!checkFlg) {
      this.term_main_disease3.term_problems[idx].check_flg = false;
    }
    this.includeProblemCheck(this.term_main_disease3);
  }
  //問題点チェックが一つでもあった時
  private includeProblemCheck(term_main: Term) {
    this.includeTermProblem = false;
    for (const termProblem of term_main.term_problems) {
      if (termProblem.check_flg) {
        this.includeTermProblem = true;
      }
    }
  }
  //問題点チェックを消す
  private deleteProblemCheck(term_main: Term) {
    for (const termProblem of term_main.term_problems) {
      termProblem.check_flg = false;
    }
  }

  //AIお勧め検索の登録
  private saveAi() {
    this.longMarkPush(this.term_main_disease1.term_longs); //長期目標Disease1
    this.longMarkPush(this.term_main_disease2.term_longs); //長期目標Disease2
    this.longMarkPush(this.term_main_disease3.term_longs); //長期目標Disease3
    this.shortMarkPush(this.term_main_disease1.term_shorts); //短期目標Disease1
    this.shortMarkPush(this.term_main_disease2.term_shorts); //短期目標Disease2
    this.shortMarkPush(this.term_main_disease3.term_shorts); //短期目標Disease3
    this.problemPush(this.term_main_disease1.term_problems); //問題点Disease1
    this.problemPush(this.term_main_disease2.term_problems); //問題点Disease2
    this.problemPush(this.term_main_disease3.term_problems); //問題点Disease3
    this.close();
  }
  //長期目標push
  private longMarkPush(termLongs: TermLong[]) {
    for (const termLong of termLongs) {
      if (termLong.check_flg) {
        const longMark: VnPlanMark = DefaultVnPlanMark();
        longMark.sort_no = this.long_marks.length + 1;
        longMark.time_type = 1;
        longMark.mark_type = 1;
        longMark.mark = termLong.mark;
        this.long_marks.push(longMark);
        this.sortMark(this.long_marks);
      }
    }
  }
  //短期目標push
  private shortMarkPush(termShorts: TermShort[]) {
    for (const termShort of termShorts) {
      if (termShort.check_flg) {
        const shortMark: VnPlanMark = DefaultVnPlanMark();
        shortMark.sort_no = this.short_marks.length + 1;
        shortMark.time_type = 2;
        shortMark.mark_type = 1;
        shortMark.mark = termShort.mark;
        this.short_marks.push(shortMark);
        this.sortMark(this.short_marks);
      }
    }
  }
  //問題点push
  private problemPush(termProblems: TermProblem[]) {
    for (const termProblem of termProblems) {
      if (termProblem.check_flg) {
        const problem: VnPlanProblem = DefaultVnPlanProblem();
        problem.sort_no = this.problems.length + 1;
        problem.target_date = this.today();
        problem.problem = termProblem.problem;

        //解決策の看護内容に反映（AI用に追加もしくは選択される）
        const masterCareMediumContents: CareMediumContent[] = [];
        if (termProblem.term_solutions != undefined) {
          for (const termSolution of termProblem.term_solutions) {
            //AI用マスタ
            const careContent: VnPlanProblemCareContent = DefaultVnPlanProblemCareContent();
            for (const careLargeContent of this.careContent
              .care_large_contents) {
              if (careLargeContent.is_ai == 2) {
                for (const careMediumContent of careLargeContent.care_medium_contents) {
                  //AI用マスタに存在した場合（既にあるマスタの情報で保存）
                  if (termSolution.solution == careMediumContent.name) {
                    careContent.care_large_content_id = careLargeContent.id;
                    careContent.care_medium_content_id = careMediumContent.id;
                    careContent.care_text = careMediumContent.name;
                    problem.care_contents.push(careContent);
                    break;
                  }
                }

                //AI用マスタになかった場合（新しく中項目IDを0で作成する。またマスタにその項目も追加する）
                if (careContent.care_large_content_id == 0) {
                  careContent.care_large_content_id = careLargeContent.id;
                  careContent.care_text = termSolution.solution;
                  problem.care_contents.push(careContent);

                  //マスタ追加用作成
                  const masterCareMediumContent: CareMediumContent = DefaultCareMediumContent();
                  masterCareMediumContent.name = termSolution.solution;
                  masterCareMediumContents.push(masterCareMediumContent);
                  break;
                }
              }
            }
          }

          //AI用マスタに追加
          for (const careLargeContent of this.careContent.care_large_contents) {
            if (careLargeContent.is_ai == 2) {
              careLargeContent.care_medium_contents.push(
                ...masterCareMediumContents
              );
            }
          }
        }
        this.problems.push(problem);
        this.sortProblem(this.problems);
      }
    }
  }

  //AI用中項目のmaxIDの抽出
  private findAiMaxId(careMediumContents: CareMediumContent[]): number {
    let id = 0;
    let idx = 0;

    for (const careMediumContent of careMediumContents) {
      idx++;
      if (idx == 1) {
        id = careMediumContent.id;
      } else {
        if (id < careMediumContent.id) {
          id = careMediumContent.id;
        }
      }
    }

    return id;
  }

  //-----------------------------------------------------
  // 長期目標・短期目標
  //-----------------------------------------------------
  //目標：ソート
  private sortMark(items: VnPlanMark[]) {
    items.sort(function(a, b) {
      return a.sort_no - b.sort_no;
    });
  }

  //長期目標：追加
  private addLong() {
    const longMark: VnPlanMark = DefaultVnPlanMark();
    longMark.sort_no = this.long_marks.length + 1;
    longMark.time_type = 1;
    longMark.mark_type = 1;
    this.long_marks.push(longMark);
    this.sortMark(this.long_marks);
  }
  //短期目標：追加
  private addShort() {
    const shortMark: VnPlanMark = DefaultVnPlanMark();
    shortMark.sort_no = this.short_marks.length + 1;
    shortMark.time_type = 2;
    shortMark.mark_type = 1;
    this.short_marks.push(shortMark);
    this.sortMark(this.short_marks);
  }

  //長期目標：登録
  private saveLongMark() {
    this.close();
  }
  //短期目標：登録
  private saveShortMark() {
    this.close();
  }

  //長期目標：上移動
  private upLong(idx: number) {
    //一番上じゃなかったら
    if (idx != 0) {
      this.long_marks[idx].sort_no -= 1;
      this.long_marks[idx - 1].sort_no += 1;
    }
    this.sortMark(this.long_marks);
  }
  //長期目標：下移動
  private downLong(idx: number) {
    //一番下じゃなかったら
    if (idx != this.long_marks.length - 1) {
      this.long_marks[idx].sort_no += 1;
      this.long_marks[idx + 1].sort_no -= 1;
    }
    this.sortMark(this.long_marks);
  }
  //短期目標：上移動
  private upShort(idx: number) {
    //一番上じゃなかったら
    if (idx != 0) {
      this.short_marks[idx].sort_no -= 1;
      this.short_marks[idx - 1].sort_no += 1;
    }
    this.sortMark(this.short_marks);
  }
  //短期目標：下移動
  private downShort(idx: number) {
    //一番下じゃなかったら
    if (idx != this.short_marks.length - 1) {
      this.short_marks[idx].sort_no += 1;
      this.short_marks[idx + 1].sort_no -= 1;
    }
    this.sortMark(this.short_marks);
  }

  //長期目標：削除
  private longMarkDelete(idx: number) {
    this.long_marks.splice(idx, 1);
    //ソート番号の振り直し
    let sortNo = 0;
    for (const long_mark of this.long_marks) {
      sortNo += 1;
      long_mark.sort_no = sortNo;
    }
  }
  //短期目標：削除
  private shortMarkDelete(idx: number) {
    this.short_marks.splice(idx, 1);
    //ソート番号の振り直し
    let sortNo = 0;
    for (const short_mark of this.short_marks) {
      sortNo += 1;
      short_mark.sort_no = sortNo;
    }
  }

  //内容空チェック
  private checkMark() {
    for (const long_mark of this.long_marks) {
      if (!long_mark.mark.replace(/\n/g, "")) {
        this.emptyTermFlg = true;
      }
    }
    for (const short_mark of this.short_marks) {
      if (!short_mark.mark.replace(/\n/g, "")) {
        this.emptyTermFlg = true;
      }
    }
  }
  //内容必須切り替え
  private classMark(item: VnPlanMark): string {
    if (!item.mark.replace(/\n/g, "") && this.pushSubmissionFlg) {
      return "error";
    }
    return "nomal hover-area";
  }

  //-----------------------------------------------------
  // 問題点・解決策・評価
  //-----------------------------------------------------
  //問題点・解決策・評価：ソート
  private sortProblem(items: VnPlanProblem[]) {
    items.sort(function(a, b) {
      return a.sort_no - b.sort_no;
    });
  }

  //No.作成
  private no(no: number): string {
    return "#" + String(no);
  }

  //解決策作成
  private problemSolutionDisp(item: VnPlanProblem): string {
    let solution = "";
    //看護内容が無ければ通らない（書かないとエラーになる）
    if (item.care_contents != null) {
      //看護内容テキスト
      for (const careMediumContent of item.care_contents) {
        solution += careMediumContent.care_text + "\n";
      }
    }
    //解決策テキスト
    if (item.solution != "") {
      solution += item.solution + "\n";
    }
    //留意事項
    if (item.consideration != "") {
      solution += "【留意事項】" + "\n" + item.consideration;
    }
    return solution;
  }

  //問題点・解決策・評価：追加
  private addProblem() {
    const problem: VnPlanProblem = DefaultVnPlanProblem();
    problem.sort_no = this.problems.length + 1;
    this.problems.push(problem);
    this.sortProblem(this.problems);
  }

  //問題点：登録
  private saveProblem() {
    this.close();
  }
  //解決策：登録
  private saveSolution() {
    this.close();
  }
  //評価：登録
  private saveEvaluation() {
    this.close();
  }

  //問題点・解決策・評価：上移動
  private upProblem(idx: number) {
    //一番上じゃなかったら
    if (idx != 0) {
      this.problems[idx].sort_no -= 1;
      this.problems[idx - 1].sort_no += 1;
    }
    this.sortProblem(this.problems);
  }
  //問題点・解決策・評価：下移動
  private downProblem(idx: number) {
    //一番下じゃなかったら
    if (idx != this.problems.length - 1) {
      this.problems[idx].sort_no += 1;
      this.problems[idx + 1].sort_no -= 1;
    }
    this.sortProblem(this.problems);
  }

  //問題点・解決策・評価：削除
  private problemDelete(idx: number) {
    this.problems.splice(idx, 1);
    //ソート番号の振り直し
    let sortNo = 0;
    for (const problem of this.problems) {
      sortNo += 1;
      problem.sort_no = sortNo;
    }
  }

  //問題点空チェック
  private checkProblem() {
    for (const problem of this.problems) {
      if (!problem.problem.replace(/\n/g, "")) {
        this.emptyProblemFlg = true;
      }
    }
  }
  //問題点必須切り替え
  private classProblem(item: VnPlanProblem): string {
    if (!item.problem.replace(/\n/g, "") && this.pushSubmissionFlg) {
      return "error px-1";
    }
    return "nomal hover-area px-1";
  }

  //解決策必須切り替え
  private classSolution(item: VnPlanProblem): string {
    if (!this.problemSolutionDisp(item) && this.pushSubmissionFlg) {
      return "error px-1";
    }
    return "nomal hover-area px-1";
  }

  //-----------------------------------------------------
  // 問題点・解決策検索
  //-----------------------------------------------------
  //問題点・解決策検索
  private searchProblem() {
    this.keywords = "";
    this.relatedDiseaseName = "";
    this.problem_solutions = [];
    this.searchProblemDialog = true;
  }

  //検索
  private search() {
    this.postJsonCheck(
      window.base_url + "/api/patient/vnplan/problem/get",
      {
        keywords: this.keywords,
        related_disease_name: this.relatedDiseaseName
      },
      res => {
        this.problem_solutions = res.data.problem_solutions;
      }
    );
  }

  //選択
  private choice(problemSolution: CareProblemSolution) {
    const problem: VnPlanProblem = DefaultVnPlanProblem();
    problem.sort_no = this.problems.length + 1;
    problem.problem = problemSolution.problem;
    problem.solution = problemSolution.solution;
    this.problems.push(problem);
    this.sortProblem(this.problems);
    this.close();
  }
  //-----------------------------------------------------
  // ダイアログ
  //-----------------------------------------------------
  //長期目標ダイアログ
  private longMarkOpen(idx: number, long_mark: string) {
    this.long_idx = idx;
    this.long_mark = long_mark;
    this.longMark.open();
  }
  //短期目標ダイアログ
  private shortMarkOpen(idx: number, short_mark: string) {
    this.short_idx = idx;
    this.short_mark = short_mark;
    this.shortMark.open();
  }

  //問題点ダイアログ
  private problemOpen(idx: number, problem: string) {
    this.problem_idx = idx;
    this.problem_point = problem;
    this.problem.open();
  }
  //解決策ダイアログ
  private solutionOpen(
    idx: number,
    careContents: VnPlanProblemCareContent[],
    solution: string,
    consideration: string
  ) {
    this.problem_idx = idx;
    this.solution_item = solution;
    this.points_of_concern_item = consideration;
    this.care_content_items = careContents || [];
    this.solution.open();
  }
  //評価ダイアログ
  private evaluationOpen(idx: number, evaluation: string) {
    this.problem_idx = idx;
    this.evaluation_point = evaluation;
    this.evaluation.open();
  }

  //ダイアログを閉じる
  private close() {
    this.longMark.close();
    this.shortMark.close();
    this.problem.close();
    this.solution.close();
    this.evaluation.close();
    this.aiDialog = false;
    this.searchProblemDialog = false;
    this.includeTermLongDisease1 = false;
    this.includeTermLongDisease2 = false;
    this.includeTermLongDisease3 = false;
    this.includeTermShortDisease1 = false;
    this.includeTermShortDisease2 = false;
    this.includeTermShortDisease3 = false;
    this.includeTermProblem = false;
    this.deleteLongCheck(this.term_main_disease1);
    this.deleteLongCheck(this.term_main_disease2);
    this.deleteLongCheck(this.term_main_disease3);
    this.deleteShortCheck(this.term_main_disease1);
    this.deleteShortCheck(this.term_main_disease2);
    this.deleteShortCheck(this.term_main_disease3);
    this.deleteProblemCheck(this.term_main_disease1);
    this.deleteProblemCheck(this.term_main_disease2);
    this.deleteProblemCheck(this.term_main_disease3);
  }

  //-----------------------------------------------------
  // 宛名関連
  //-----------------------------------------------------
  //宛名追加
  private addAddress() {
    this.vn_plan.sends.push(DefaultVnPlanSend());
  }
  //宛名削除
  private async deleteAddress(idx: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.vn_plan.sends.splice(idx, 1);
    }
  }
  //宛名変えた時
  private changeAddress(send: { personnel_id: number }) {
    send.personnel_id = 0;
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //担当者1：担当者が被っている場合
  private checkStaff1(): boolean | string {
    if (!this.vn_plan.staff1_id) {
      return true;
    }
    if (
      this.vn_plan.staff1_id !=
      (this.vn_plan.staff2_id ||
        this.vn_plan.staff3_id ||
        this.vn_plan.staff4_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //担当者2：担当者が被っている場合
  private checkStaff2(): boolean | string {
    if (!this.vn_plan.staff2_id) {
      return true;
    }
    if (
      this.vn_plan.staff2_id !=
      (this.vn_plan.staff1_id ||
        this.vn_plan.staff3_id ||
        this.vn_plan.staff4_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //担当者3：担当者が被っている場合
  private checkStaff3(): boolean | string {
    if (!this.vn_plan.staff3_id) {
      return true;
    }
    if (
      this.vn_plan.staff3_id !=
      (this.vn_plan.staff1_id ||
        this.vn_plan.staff2_id ||
        this.vn_plan.staff4_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //担当者4：担当者が被っている場合
  private checkStaff4(): boolean | string {
    if (!this.vn_plan.staff4_id) {
      return true;
    }
    if (
      this.vn_plan.staff4_id !=
      (this.vn_plan.staff1_id ||
        this.vn_plan.staff2_id ||
        this.vn_plan.staff3_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }

  //作成者：作成者が被っている場合
  private checkWriterStaff(): boolean | string {
    if (!this.vn_plan.write_staff1_id) {
      return true;
    }
    if (!this.vn_plan.write_staff2_id) {
      return true;
    }
    if (this.vn_plan.write_staff1_id != this.vn_plan.write_staff2_id) {
      return true;
    } else {
      return "作成者が重複しています";
    }
  }
  //作成者：作成者に「保健師」「看護師」「専門看護師」のいずれもいない場合
  private checkWriterStaffDiv(): boolean | string {
    let staff1Div = 0;
    let staff2Div = 0;
    for (const writerStaff of this.writerStaffs) {
      if (this.vn_plan.write_staff1_id == writerStaff.value) {
        staff1Div = writerStaff.other;
      }
      if (this.vn_plan.write_staff2_id == writerStaff.value) {
        staff2Div = writerStaff.other;
      }
    }
    if (
      staff1Div == 10 ||
      staff1Div == 1 ||
      staff1Div == 3 ||
      staff2Div == 10 ||
      staff2Div == 1 ||
      staff2Div == 3
    ) {
      return true;
    }
    return "作成者①②どちらかで保健師、看護師、専門看護師の職員を選択してください。";
  }

  //宛名：機関名が被っている場合
  private checkSendPerson(personnel_id: number): boolean | string {
    let counter = 0;
    for (const send of this.vn_plan.sends) {
      if (personnel_id == send.personnel_id) {
        counter += 1;
      }
      if (counter == 2) {
        return "宛名が重複してます";
      }
    }
    return true;
  }

  //2020年4月法改正（訪問予定職種の表示・非表示）
  private visitPlanDispFlg(): boolean {
    let flg = false;
    if (this.vn_plan.write_date >= "2020-04-01") {
      flg = true;
    }
    return flg;
  }
}
