import { GetterTree } from "vuex";
import { PatientState } from "./types";
import { RootState } from "@/store/types";
import { RequesterChoice } from "@/types";

export const getters: GetterTree<PatientState, RootState> = {
  Doctors: (state: PatientState) => {
    const items: RequesterChoice[] = [];
    for (const ins of state.master.medical_institutions) {
      for (const doctor of ins.doctors) {
        if (doctor.is_empty == 0) {
          continue;
        }

        let text = ins.name;
        if (doctor.full_name !== "") {
          text += "/" + doctor.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: doctor.id,
          value: doctor.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num,
          med_id: ins.id,
          med_name: ins.name,
          doc_name: doctor.full_name
        });
      }
    }
    return items;
  },
  MedicalInsOnly: (state: PatientState) => {
    const items: RequesterChoice[] = [];
    for (const ins of state.master.medical_institutions) {
      for (const doctor of ins.doctors) {
        if (doctor.is_empty == 1) {
          continue;
        }

        let text = ins.name;
        if (doctor.full_name !== "") {
          text += "/" + doctor.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: doctor.id,
          value: doctor.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num,
          med_id: ins.id,
          med_name: ins.name
        });
      }
    }
    return items;
  },
  MedicalInses: (state: PatientState) => {
    const items: RequesterChoice[] = [];
    for (const ins of state.master.medical_institutions) {
      for (const doctor of ins.doctors) {
        let text = ins.name;
        if (doctor.full_name !== "") {
          text += "/" + doctor.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: doctor.id,
          value: doctor.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num
        });
      }
    }
    return items;
  },
  Personnels: (state: PatientState) => {
    const items: RequesterChoice[] = [];
    for (const ins of state.master.relation_institutions) {
      for (const personnel of ins.personnels) {
        let text = ins.name;
        if (personnel.full_name !== "") {
          text += "/" + personnel.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: personnel.id,
          value: personnel.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num
        });
      }
    }
    return items;
  },
  RelationInses: (state: PatientState) => {
    const items: RequesterChoice[] = [];
    for (const ins of state.master.relation_institutions) {
      for (const personnel of ins.personnels) {
        let text = ins.name;
        if (personnel.full_name !== "") {
          text += "/" + personnel.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: personnel.id,
          value: personnel.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num
        });
      }
    }
    return items;
  }
};

export default getters;
