



































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  CategoryChoice,
  CategoryMenuList,
  CategoryMenuSelect
} from "@/views/contact/types";

@Component
export default class CategoryMenu extends Mixins(UtilMixin, AxiosMixin) {
  /** 選択している項目のID */
  @Prop() current?: CategoryChoice;

  /** カテゴリ */
  private categoryList: CategoryMenuList[] = [];

  /** 選択用のカテゴリリスト */
  private get CategorySelects() {
    const selects: CategoryMenuSelect[] = [];

    // 3階層あるカテゴリを平滑化する
    for (const catChild1 of this.categoryList) {
      const childSelect: CategoryMenuSelect = {
        category_name: catChild1.category_name,
        selects: []
      };
      childSelect.selects.push({
        text: catChild1.category_name,
        value: catChild1.category_id,
        other_string: catChild1.icon,
        depth: 0
      });

      for (const catChild2 of catChild1.childs ?? []) {
        childSelect.selects.push({
          text: catChild2.category_name,
          value: catChild2.category_id,
          depth: 1
        });
        for (const catChild3 of catChild2.childs ?? []) {
          childSelect.selects.push({
            text: catChild3.category_name,
            value: catChild3.category_id,
            depth: 2
          });
        }
      }

      selects.push(childSelect);
    }

    return selects;
  }

  created() {
    this.fetchCategoryLink();
  }

  /** ヘルプカテゴリ一覧取得 */
  private fetchCategoryLink() {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/categorys/get",
      {},
      async res => {
        if (!res.data) {
          await this.$openAlert("カテゴリ一覧を取得できませんでした。");
          return;
        }
        this.sendCategoryList(res.data.category_list);
        this.categoryList = res.data.category_list;
      }
    );
  }

  /** カテゴリ選択肢クリック時 */
  private onClickCategoryLink(selectCatId: number) {
    // 選択肢の元となったカテゴリ項目を探して、返す
    for (const catChild1 of this.categoryList) {
      if (catChild1.category_id === selectCatId) {
        this.sendClickedCategoryLink(catChild1);
        return;
      }
      for (const catChild2 of catChild1.childs ?? []) {
        if (catChild2.category_id === selectCatId) {
          this.sendClickedCategoryLink(catChild2);
          return;
        }
        for (const catChild3 of catChild2.childs ?? []) {
          if (catChild3.category_id === selectCatId) {
            this.sendClickedCategoryLink(catChild3);
            return;
          }
        }
      }
    }
  }

  /** カテゴリ選択を解除 */
  private unselectCategory() {
    this.sendClickedCategoryLink({
      category_name: "",
      category_id: 0,
      childs: []
    });
  }

  /** 取得したカテゴリ一覧を返す */
  @Emit("get:category")
  sendCategoryList(categoryList: CategoryMenuList[]) {
    return categoryList;
  }

  /** カテゴリリンククリック時 */
  @Emit("click:category")
  private sendClickedCategoryLink(category: CategoryMenuList) {
    return category;
  }
}
