import { Choice } from "@/types";
import { Remand, DefaultRemand } from "#/model/report";

export interface BedsorePlan {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  valuation: string; //'危険因子の評価',
  plan_bed: string; //'看護計画：圧迫・ずれ力の排除（ベッド上）',
  plan_chair: string; //'看護計画：圧迫・ずれ力の排除（イス上）',
  plan_skin_care: string; //'看護計画：スキンケア',
  plan_nourishment: string; //'看護計画：栄養状態改善',
  plan_rehabilitation: string; //'看護計画：リハビリテーション',
  write_staff_id: string; //'作成者ID',
  plan_date: string; //'計画作成日',
  plan_end_date: string; //'計画終了日',
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
  submit_datetime_wareki: string; //提出日時（和暦）
  plan_date_wareki: string; //計画作成日（和暦）
  plan_end_date_wareki: string; //計画終了日（和暦）
  parts: BedsorePlanPart[]; //部位
  risk_factor: BedsorePlanRiskFactor; //危険因子
  pdfs: BedsorePlanPdf[]; //PDF
  pdf: BedsorePlanPdf; //PDF
  remands: BedsorePlanRemand[]; //差し戻し
  remand: BedsorePlanRemand; //差し戻し
}

export interface BedsorePlanPart {
  id: number; //ID
  bedsore_plan_id: number; //'褥瘡対策看護計画ID',
  sort_no: number; //'並び順',
  time_div: number; //'時間区分\n1:現在\n2:過去',
  bedsore_part_id: number; //'褥瘡部位\n1:仙骨部\n2:坐骨部\n3:尾骨部\n4:腸骨部\n5:大転子部\n6:踵部\n7:その他',
  is_exist: number; //'褥瘡有無\n0:無\n1:有',
  exist_date: string; //'褥瘡発生日',
  other_bedsore_part: string; //'その他部位名',
  depth: number; //'深さ\n0:なし\n1:持続する発赤\n2:真皮までの損傷\n3:皮下組織までの損傷\n4:皮下組織をこえる損傷\n5:関節腔、体腔にいたる損傷\nU:深さ判定が不能な場合',
  exudate: number; //'滲出液\n0:なし\n1:少量：毎日の交換を要しない\n3:中等量：1日1回の交換\n6:多量：1日2回以上の交換',
  size: number; //'大きさ\n0:皮膚損傷なし\n3:4未満\n6:4以上16未満\n8:16以上36未満\n9:36以上64未満\n12:64以上100未満\n15:100以上',
  infection: number; //'炎症・感染\n0:局所の炎症徴候なし\n1:局所の炎症徴候あり\n3:局所の明らかな感染徴候あり\n9:全身的な影響あり',
  granulation_tissue: number; //'肉芽形成\n0:創閉鎖又は創が浅い為評価不能\n1:創面の90％以上を占める\n3:創面の50％以上90％未満を占める\n4:創面の10%以上50％未満を占める\n5:創面の10％未満を占める\n6:全く形成されていない',
  necrotic_tissue: number; //'壊死組織\n0:なし\n3:柔らかい壊死組織あり\n6:硬く厚い密着した壊死組織あり',
  pocket: number; //'ポケット\n0:なし\n6:4未満\n9:4以上16未満\n12:16以上36未満\n24:36以上',
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
  exist_date_wareki: string; //褥瘡発生日（和暦）
}

export interface BedsorePlanRiskFactor {
  id: number; //ID
  bedsore_plan_id: number; //'褥瘡対策看護計画ID',
  daily_life_rate: number; //'日常生活自立度\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2',
  basic_action1: number; //'基本動作能力（ベッド上 自立体位変換)\n1:できる\n2:できない',
  basic_action2: number; //'基本動作能力 (イス上 座位姿勢の保持、除圧)\n1:できる\n2:できない',
  bone: number; //'病的骨突出\n1:なし\n2:あり',
  joint: number; //'関節拘縮\n1:なし\n2:あり',
  nutrition: number; //'栄養状態低下\n1:なし\n2:あり',
  skin: number; //'皮膚湿潤(多汗、尿失禁、便失禁)\n1:なし\n2:あり',
  edema: number; //'浮腫(局所以外の部位)\n1:なし\n2:あり',
  skin_tear: number; //'皮膚の脆弱性(スキン―テアの保有、既往)\n1:なし\n2:あり',
  step: string; //'対処',
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
}
export interface BedsorePlanPdf {
  id: number; //ID
  bedsore_plan_id: number; //'褥瘡対策看護計画ID',
  pdf_path: string; //''PDFファイルパス',
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
}

export interface BedsorePlanRemand extends Remand {
  bedsore_plan_id: number; //利用者ID
}
export const BedsorePlanRemandDefaultValues = (): BedsorePlanRemand => ({
  ...DefaultRemand(),
  bedsore_plan_id: 0 //利用者ID
});

export const BedsorePlanDefaultValues = (): BedsorePlan => ({
  id: 0, //ID
  patient_id: 0, //'利用者ID',
  editor_staff_id: 0, //'編集者ID',
  status: 0, //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: "", //'提出日時',
  valuation: "", //'危険因子の評価',
  plan_bed: "", //'看護計画：圧迫・ずれ力の排除（ベッド上）',
  plan_chair: "", //'看護計画：圧迫・ずれ力の排除（イス上）',
  plan_skin_care: "", //'看護計画：スキンケア',
  plan_nourishment: "", //'看護計画：栄養状態改善',
  plan_rehabilitation: "", //'看護計画：リハビリテーション',
  write_staff_id: "", //'作成者ID',
  plan_date: "", //'計画作成日',
  plan_end_date: "", //'計画終了日',
  created_at: "", //作成日時
  updated_at: "", //更新日時
  deleted_at: "", //削除日時
  submit_datetime_wareki: "", //提出日時（和暦）
  plan_date_wareki: "", //計画作成日（和暦）
  plan_end_date_wareki: "", //計画終了日（和暦）
  parts: [], //部位
  risk_factor: {
    id: 0, //ID
    bedsore_plan_id: 0, //'褥瘡対策看護計画ID',
    daily_life_rate: 0, //'日常生活自立度\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2',
    basic_action1: 1, //'基本動作能力（ベッド上 自立体位変換)\n1:できる\n2:できない',
    basic_action2: 1, //'基本動作能力 (イス上 座位姿勢の保持、除圧)\n1:できる\n2:できない',
    bone: 1, //'病的骨突出\n1:なし\n2:あり',
    joint: 1, //'関節拘縮\n1:なし\n2:あり',
    nutrition: 1, //'栄養状態低下\n1:なし\n2:あり',
    skin: 1, //'皮膚湿潤(多汗、尿失禁、便失禁)\n1:なし\n2:あり',
    edema: 1, //'浮腫(局所以外の部位)\n1:なし\n2:あり',
    skin_tear: 1, //'皮膚の脆弱性(スキン―テアの保有、既往)\n1:なし\n2:あり',
    step: "", //'対処',
    created_at: "", //作成日時
    updated_at: "", //更新日時
    deleted_at: "" //削除日時
  }, //危険因子
  pdfs: [], //PDF
  pdf: {
    id: 0,
    bedsore_plan_id: 0,
    pdf_path: "",
    created_at: "",
    updated_at: "",
    deleted_at: ""
  },
  remands: [], //差し戻し
  remand: BedsorePlanRemandDefaultValues() //差し戻し
});

export interface BedsorePartName {
  bedsore_part_id: number; // 部位ID
  name: string; // 部位名
}

export interface BedsorePartItem {
  depths: Choice[]; // 深さ
  exudates: Choice[]; // 滲出液
  sizes: Choice[]; // 大きさ
  infections: Choice[]; // 炎症・感染
  granulations: Choice[]; // 肉芽形成
  necrotics: Choice[]; // 壊死組織
  pockets: Choice[]; // ポケット(cm2)
}

export interface BedsoreRiskFactorItem {
  daily_life_rate: Choice[]; // 日常生活自立度
  basic_action1: Choice[]; // 基本動作能力（ベッド上 自立体位変換)
  basic_action2: Choice[]; // 基本動作能力 (イス上 座位姿勢の保持、除圧)
  bone: Choice[]; // 病的骨突出
  joint: Choice[]; // 関節拘縮
  nutrition: Choice[]; // 栄養状態低下
  skin: Choice[]; // 皮膚湿潤(多汗、尿失禁、便失禁)
  edema: Choice[]; // 浮腫(局所以外の部位)
  skin_tear: Choice[]; // 皮膚の脆弱性(スキン―テアの保有、既往)
}

export const BedsoreRiskFactorItemDefaultValues = (): BedsoreRiskFactorItem => ({
  daily_life_rate: [], // 日常生活自立度
  basic_action1: [], // 基本動作能力（ベッド上 自立体位変換)
  basic_action2: [], // 基本動作能力 (イス上 座位姿勢の保持、除圧)
  bone: [], // 病的骨突出
  joint: [], // 関節拘縮
  nutrition: [], // 栄養状態低下
  skin: [], // 皮膚湿潤(多汗、尿失禁、便失禁)
  edema: [], // 浮腫(局所以外の部位)
  skin_tear: [] // 皮膚の脆弱性(スキン―テアの保有、既往)
});

export const BedsorePartItemDefaultValues = (): BedsorePartItem => ({
  depths: [], // 深さ
  exudates: [], // 滲出液
  sizes: [], // 大きさ
  infections: [], // 炎症・感染
  granulations: [], // 肉芽形成
  necrotics: [], // 壊死組織
  pockets: [] // ポケット(cm2)
});

export const BedsorePlanPartDefaultValues = (
  time_div: number,
  sort_no: number
): BedsorePlanPart => ({
  id: 0, //ID
  bedsore_plan_id: 0, //'褥瘡対策看護計画ID',
  sort_no: sort_no, //'並び順',
  time_div: time_div, //'時間区分\n1:現在\n2:過去',
  bedsore_part_id: 0, //'褥瘡部位\n1:仙骨部\n2:坐骨部\n3:尾骨部\n4:腸骨部\n5:大転子部\n6:踵部\n7:その他',
  is_exist: 0, //'褥瘡有無\n0:無\n1:有',
  exist_date: "", //'褥瘡発生日',
  other_bedsore_part: "", //'その他部位名',
  depth: 0, //'深さ\n0:なし\n1:持続する発赤\n2:真皮までの損傷\n3:皮下組織までの損傷\n4:皮下組織をこえる損傷\n5:関節腔、体腔にいたる損傷\nU:深さ判定が不能な場合',
  exudate: 0, //'滲出液\n0:なし\n1:少量：毎日の交換を要しない\n3:中等量：1日1回の交換\n6:多量：1日2回以上の交換',
  size: 0, //'大きさ\n0:皮膚損傷なし\n3:4未満\n6:4以上16未満\n8:16以上36未満\n9:36以上64未満\n12:64以上100未満\n15:100以上',
  infection: 0, //'炎症・感染\n0:局所の炎症徴候なし\n1:局所の炎症徴候あり\n3:局所の明らかな感染徴候あり\n9:全身的な影響あり',
  granulation_tissue: 0, //'肉芽形成\n0:創閉鎖又は創が浅い為評価不能\n1:創面の90％以上を占める\n3:創面の50％以上90％未満を占める\n4:創面の10%以上50％未満を占める\n5:創面の10％未満を占める\n6:全く形成されていない',
  necrotic_tissue: 0, //'壊死組織\n0:なし\n3:柔らかい壊死組織あり\n6:硬く厚い密着した壊死組織あり',
  pocket: 0, //'ポケット\n0:なし\n6:4未満\n9:4以上16未満\n12:16以上36未満\n24:36以上',
  created_at: "", //作成日時
  updated_at: "", //更新日時
  deleted_at: "", //削除日時
  exist_date_wareki: "" //褥瘡発生日（和暦）
});

export const TIME_DIV_NOW = 1;
export const TIME_DIV_OLD = 2;
