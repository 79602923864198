import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:高額療養費情報 */
export const headerHighExpense: StatisticHeader = {
  id: STATISTIC_COL_ID.HIGH_EXPENSE,
  text: "高額療養費情報",
  is_checked: false,
  value: [
    {
      text: "開始日（高額療養費）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.HIGH_EXPENSE}.start_date`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（高額療養費）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.HIGH_EXPENSE}.end_date`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "特記欄（高額療養費）",
      select_name: "特記欄",
      value: `${STATISTIC_COL_ID.HIGH_EXPENSE}.disp_special_note_name`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "区分（高額療養費）",
      select_name: "区分",
      value: `${STATISTIC_COL_ID.HIGH_EXPENSE}.div_text`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    }
  ]
};
