




























































import { Component, Vue, Prop } from "vue-property-decorator";
import { ScheduleSearchCondition } from "@/components/schedule/common";

@Component
export default class ScheduleManageSidebar extends Vue {
  /** 検索条件 */
  @Prop({ required: true })
  private readonly searchCondition!: ScheduleSearchCondition;

  /** ナビゲーション開閉状態 */
  private openNav = false;

  mounted() {
    if (!this.$vuetify.breakpoint.xs) {
      this.openNav = true;
    }
    window.addEventListener("resize", this.setNavDisp);
  }

  /** ウィンドウサイズによって、ナビゲーションの開閉状態を切り替える */
  private setNavDisp() {
    if (this.$vuetify.breakpoint.xs) {
      this.openNav = false;
    }
  }
}
