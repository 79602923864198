


























































import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import sanitizeHTML from "sanitize-html";
import { getVisitMemo } from "@/components/schedule/common";
import { VisitInfo } from "./types";

@Component
export default class VisitMemoDialog extends Vue {
  @Prop({ default: "" }) private prefix_id!: string;
  /** 訪問メモ情報 */
  @Prop({ default: {} }) visitInfo!: VisitInfo;
  /** 利用者名 */
  @Prop({ default: "" }) patientName!: string;
  /** 訪問メモダイアログを訪問終了時にも表示するかどうか */
  @Prop({ default: 1 }) isDispMemoEnd!: number;
  /** 移動中:0 訪問中:1  */
  @Prop({ default: 0 }) mode!: number;

  @Emit("update:isDispMemoEnd")
  updateDispMemoEnd(newValue: number) {
    return newValue;
  }
  private dialog = false;
  /** サニタイズ */
  private sanitize = sanitizeHTML;

  /** 訪問メモURL対応後 */
  private get Memo(): string {
    return getVisitMemo(this.visitInfo.memo);
  }

  private get IsDispMemoEnd(): number {
    return this.isDispMemoEnd;
  }

  private set IsDispMemoEnd(newValue: number) {
    this.updateDispMemoEnd(newValue);
  }
  /** 訪問メモダイアログ表示 */
  /** ダイアログopen時、Promiseのresolveを保持する */
  public async openMemoDialog(): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._resolve = () => {};
    // Promise生成
    return await new Promise<boolean>(resolve => {
      this.dialog = true;
      this._resolve = resolve;
    });
  }
  /** resolveの保持 */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _resolve: (ans: boolean | PromiseLike<boolean>) => void = () => {};
  private async closeMemoDialog() {
    this._resolve(false);
    this.dialog = false;
  }
  private async okMemoDialog() {
    this._resolve(true);
    this.dialog = false;
  }
}
