























































import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import { LinkagePreview } from "../careplan";

const InputHosokuStatus = {
  ng: 0,
  ok: 1
} as const;

const InputStatus = {
  bothNg: 0,
  // 最初（第1表 or 第6表がない）
  firstNg: 1,
  // 2つ目（第6表 or 第7表がない）
  secondNg: 2,
  ok: 3
} as const;

@Component
export default class CareplanInputResultDialog extends Vue {
  @Prop({ default: () => [] }) linkagePreviews!: LinkagePreview[];
  @Prop({ default: false }) isOpenDialog!: boolean;
  @Prop({ default: 1 }) inputType!: number;

  private isNGData(hosokuStatus: number, status: number) {
    return hosokuStatus === InputHosokuStatus.ng || status !== InputStatus.ok;
  }

  private createResultMessage(hosokuStatus: number, status: number) {
    let message = "";

    if (hosokuStatus === InputHosokuStatus.ng) {
      message += "利用者補足情報のファイルに保険者番号と被保険者番号が一致するデータが見つかりません";
    }

    if (this.inputType === 1) {
      switch (status) {
        // 両方なし
        case InputStatus.bothNg:
          message = this.addMessage(message, "居宅サービス計画書1表のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          message = this.addMessage(message, "居宅サービス計画書2表のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          break;

        // 第2表なし
        case InputStatus.firstNg:
          message = this.addMessage(message, "居宅サービス計画書2表のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          break;

        // 第3表なし
        case InputStatus.secondNg:
          message = this.addMessage(message, "居宅サービス計画書1表のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          break;

        // 両方あり
        default:
          break;
      }
    } else if (this.inputType === 2) {
      switch (status) {
        // 両方なし
        case InputStatus.bothNg:
          message = this.addMessage(message, "サービス利用票のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          message = this.addMessage(message, "サービス利用票別表のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          break;

        // 第7表なし
        case InputStatus.firstNg:
          message = this.addMessage(message, "サービス利用票別表のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          break;

        // 第6表なし
        case InputStatus.secondNg:
          message = this.addMessage(message, "サービス利用票のファイルに保険者番号と被保険者番号が一致するデータが見つかりません");
          break;

        // 両方あり
        default:
          break;
      }
    }

    if (message === "") {
      return "◯";
    }

    return message;
  }

  private addMessage(message: string, add: string) {
    if (message === "") {
      return add;
    }

    return message + "\n" + add;
  }

  @Emit()
  private cancel() {
    return;
  }

  @Emit()
  private save() {
    return;
  }
}
