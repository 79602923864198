
























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { CalendarEvent } from "@/types";

@Component
export default class VisitDialog extends Mixins(AxiosMixin, UtilMixin) {
  /** 訪問イベント */
  @Prop() private readonly event!: CalendarEvent;

  /** PDF出力 */
  @Emit() private outputPdf() {
    return this.event;
  }

  /** 差し戻し */
  @Emit() private remand() {
    return { event: this.event, comment: this.comment };
  }

  /** 差し戻しコメント */
  private comment = "";

  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** ダイアログ開く */
  public open() {
    this.isOpen = true;
  }

  /** ダイアログ閉じる */
  private cancel() {
    this.isOpen = false;
  }

  /** PDF出力ボタンクリック */
  private clickOutputPdf() {
    this.outputPdf();
    this.cancel();
  }

  /** 差し戻しボタンクリック */
  private clickRemand() {
    this.remand();
    this.cancel();
  }
}
