



























import { Component, Prop, Mixins, Ref, Emit } from "vue-property-decorator";
import { VForm } from "@/types";
import { Staff, DefaultStaff } from "#/model/staff";
import RulesMixin from "#/mixins/rulesMixin";
import LinkStaffMixin from "@/mixins/linkStaffMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class ChangeLink extends Mixins(
  RulesMixin,
  LinkStaffMixin,
  UtilMixin
) {
  @Ref("form") private readonly form!: VForm;

  /** ダイアログ表示状態 */
  @Prop() dialog!: boolean;

  /** 連携対象職員情報 */
  @Prop({ default: DefaultStaff() }) staff!: Staff;

  /** 連携後の編集画面の再取得 */
  @Emit()
  public update() {
    return;
  }

  /** ダイアログを閉じる */
  @Emit()
  public close() {
    return;
  }

  /** 変更先のアカウント紐付け情報 */
  private ibowSendInfo = "";

  /** 連携可能か確認 */
  private async linkConfirm() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    /** 連携先アカウント存在チェック＆連携先アカウントに既に紐づいているアカウントがあるかチェック */
    this.postJsonCheck(
      window.base_url + "/api/master/staff/check-link",
      {
        staff: this.staff,
        ibow_send_info: this.ibowSendInfo,
        ibow_send_type: this.inviteType
      },
      async res => {
        if (!res.data.message || (await this.$openConfirm(res.data.message))) {
          /** 変更対象がログインユーザーなら警告 */
          if (this.staff.id == this.loginUser.id) {
            if (
              await this.$openConfirm(
                "ログイン中のアカウントを変更するため保存後マイページに飛びます、よろしいですか？"
              )
            ) {
              this.regist(true);
            }
          } else {
            this.regist(false);
          }
        }
      }
    );
  }

  /** 登録イベント */
  private regist(toMyPage: boolean) {
    this.postJsonCheck(
      window.base_url + "/api/master/staff/change-link",
      {
        staff: this.staff,
        ibow_send_info: this.ibowSendInfo,
        ibow_send_type: this.inviteType,
        check_type: 0
      },
      () => {
        if (toMyPage) {
          /** マイページへ */
          this.postJsonCheck(
            window.auth_backend_url + "/api/auth/logout",
            {},
            () => {
              location.href = window.auth_frontend_url;
            }
          );
        } else {
          /** 編集画面へ */
          this.closeDialog();
          this.update();
        }
      }
    );
  }

  /** 入力内容とバリデーションをリセットしてから閉じる */
  private closeDialog() {
    this.ibowSendInfo = "";
    this.form.resetValidation();
    this.close();
  }
}
