


























































































import {
  Component,
  Prop,
  Mixins,
  Ref,
  Emit,
  Watch
} from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import ShiftMixin from "@/components/shift/shiftMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";
import { ShiftTemplate } from "#/model/schedule";
import { CalendarEvent } from "vuetify";

@Component
export default class StaffShiftEditDialog extends Mixins(
  AxiosMixin,
  ShiftMixin,
  UtilMixin
) {
  /** フォームrefオブジェクト */
  @Ref("dayShiftForm") private readonly dayShiftForm!: VForm;

  /** シフトテンプレート */
  @Prop({ default: () => [] }) private shift_templates!: ShiftTemplate[];

  /** シフトイベント */
  @Prop({ default: () => [] }) private events!: CalendarEvent[];

  /** 職員ID */
  @Prop({ default: 0 }) private staffId!: number;

  /** データ更新時 */
  @Emit() update() {
    return;
  }

  /** 選択シフト変更時 */
  @Watch("selectedShiftNames") private watchSelectedShiftNames() {
    this.dayShiftForm?.validate();
  }

  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** 日付 */
  private openDate = "";

  /** 変更前のシフト */
  private bkSelectedShiftNames: string[] = [];

  /** 変更後のシフト */
  private selectedShiftNames: string[] = [];

  /** 登録アラート表示フラグ */
  private isShowEntryAlert = false;

  /** 表示用日付 */
  private get DispDate(): string {
    return this.openDate.replaceAll("-", "/");
  }

  /** イベント登録用テンプレート */
  private get Templates(): ShiftTemplate[] {
    const templateNames = this.shift_templates.map(
      template => template.shift_name
    );
    const privateShift = this.events
      .filter(
        event =>
          event.start == this.openDate && !templateNames.includes(event.name)
      )
      .map(event => {
        return event.shift;
      });
    return [...this.shift_templates, ...privateShift];
  }

  /** ダイアログを開く */
  public open(openDate: string) {
    this.openDate = openDate;
    this.selectedShiftNames = this.events
      .filter(event => event.start == this.openDate)
      .map(event => event.name);
    this.bkSelectedShiftNames = this.deepCopy(this.selectedShiftNames);
    if (this.selectedShiftNames.length > 0 || this.shift_templates.length > 0) {
      this.isOpen = true;
    }
  }

  /** イベントを登録する */
  private async entry(entryContinue = false) {
    if (!this.dayShiftForm.validate()) {
      if (!entryContinue) {
        await this.$openAlert("入力内容に不備があります。");
      } else {
        await this.$openAlert(
          "入力内容に不備があるため、登録せずに日付を変更します。"
        );
      }
      return;
    }

    // 変更された場合のみ保存
    if (
      JSON.stringify(this.bkSelectedShiftNames) !==
      JSON.stringify(this.selectedShiftNames)
    ) {
      const saveShifts: unknown[] = this.Templates.filter(template =>
        this.selectedShiftNames.includes(template.shift_name)
      ).map(template => {
        const shift = this.deepCopy(template);
        shift.id = 0;
        shift.target_date = this.openDate;
        return shift;
      });

      this.postJsonCheck(
        window.base_url + "/api/shift/staff/save",
        {
          staff_id: this.staffId,
          target_date: this.openDate,
          shifts: saveShifts
        },
        () => {
          this.showEntryAlert();
          this.update();
        }
      );
    } else {
      this.update();
    }

    // 前日翌月遷移しない場合
    if (!entryContinue) {
      this.isOpen = false;
    }
  }

  /** 前日に遷移する */
  private async toLeft() {
    // 現在の日付のシフトを登録して遷移
    await this.entry(true);

    // 前日取得
    const prevDate = this.strToDate(this.openDate);
    prevDate.setDate(prevDate.getDate() - 1);

    this.openDate = this.dateToStr(prevDate, "yyyy-MM-dd");
    this.update();
    this.prevNext();
  }

  /** 翌日に遷移する */
  private async toRight() {
    // 現在の日付のシフトを登録して遷移
    await this.entry(true);

    // 翌日取得
    const nextDate = this.strToDate(this.openDate);
    nextDate.setDate(nextDate.getDate() + 1);

    this.openDate = this.dateToStr(nextDate, "yyyy-MM-dd");
    this.update();
    this.prevNext();
  }

  /** シフト時間の重複を検証する */
  private validateEvent(msg: string): boolean | string {
    const selects = this.Templates.filter(template =>
      this.selectedShiftNames.includes(template.shift_name)
    );
    // シフト時間重複チェック
    for (let i = 0; i < selects.length; i++) {
      const select = selects[i];
      for (let j = 0; j < selects.length; j++) {
        if (i === j) continue;
        const diff = selects[j];

        const orgStart =
          select.shift_start_time_hour * 60 + select.shift_start_time_minute;
        let orgEnd =
          select.shift_end_time_hour * 60 + select.shift_end_time_minute;
        if (orgStart > orgEnd) orgEnd += 1440; // 日跨ぎなら終了時間には24時間分プラス

        const diffStart =
          diff.shift_start_time_hour * 60 + diff.shift_start_time_minute;
        let diffEnd =
          diff.shift_end_time_hour * 60 + diff.shift_end_time_minute;
        if (diffStart > diffEnd) diffEnd += 1440; // 日跨ぎなら終了時間には24時間分プラス

        if (
          (orgStart <= diffEnd && orgEnd >= diffEnd) ||
          (orgStart <= diffStart && orgEnd >= diffStart)
        ) {
          return msg;
        }
      }
    }
    return true;
  }

  /** 登録アラートを表示する */
  private showEntryAlert() {
    this.isShowEntryAlert = true;
    setTimeout(() => {
      this.isShowEntryAlert = false;
    }, 2000);
  }

  /** 翌日や前日に遷移する */
  private prevNext() {
    this.selectedShiftNames = this.events
      .filter(event => event.start == this.openDate)
      .map(event => event.name);

    // 変更有無を把握するため、退避
    this.bkSelectedShiftNames = this.deepCopy(this.selectedShiftNames);

    if (this.selectedShiftNames.length > 0 || this.shift_templates.length > 0) {
      this.isOpen = true;
    }
  }
}
