import { MasterState } from "./types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const state: MasterState = {
  error: false
};

const namespaced = true;

export const master: Module<MasterState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
