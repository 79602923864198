














import { Component, Mixins } from "vue-property-decorator";
import PatientInfo from "@/components/patient/PatientInfo.vue";
import PatientMixin from "@/mixins/patientMixin";
import { Detail, PatientDispInfo } from "@/components/patient/types";

@Component({
  components: {
    PatientInfo
  }
})
export default class PatientInfoDialog extends Mixins(PatientMixin) {
  private dialog = false;

  public open() {
    this.dialog = true;
  }

  private get detail(): Detail {
    return this.patientDetail; // 利用者詳細
  }

  private get info(): PatientDispInfo {
    return this.patientInfo; // 利用者詳細
  }
}
