













import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";
import { CareItems } from "./type";

@Component({
  components: {
    ItemTable
  }
})
export default class Care extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 医療保険 */
  @Prop({ default: () => [] }) private readonly items!: CareItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 介護保険ヘッダー */
  private careHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    { text: "保険者番号", value: "insurer_no", width: "100px" },
    { text: "被保険者番号", value: "insured_no", width: "140px" },
    { text: "要介護度", value: "care_level", width: "80px" },
    {
      text: "開始日",
      value: "start_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "居宅サービス計画作成者",
      value: "plan_creator"
    }
  ];

  /** 新規作成 */
  private careCreate() {
    this.$router.push({
      name: "InsuranceInfoCare",
      params: { id: String(this.patientId), inscareid: "0" }
    });
  }

  /** 修正 */
  private careRepair(item: CareItems) {
    this.$router.push({
      name: "InsuranceInfoCare",
      params: { id: String(this.patientId), inscareid: String(item.id) }
    });
  }

  /** コピー */
  private careCopy(item: CareItems) {
    this.$router.push({
      name: "InsuranceInfoCare",
      params: { id: String(this.patientId), inscareid: String(item.id) },
      query: { mode: "copy" }
    });
  }

  //現在有効な介護保険に色を付ける
  private careItemsRowBackground(item: CareItems) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
