





































































































































































import {
  Component,
  Emit,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import UtilMixin from "@/mixins/utilMixin";
import FocusingMixin from "@/mixins/focusingMixin";
import InfiniteLoading from "vue-infinite-loading";
import { OFFICE_STAFF, COMMON } from "#/const";

@Component({
  components: {
    InfiniteLoading
  }
})
export default class BaseReportList extends Mixins(UtilMixin, FocusingMixin) {
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;
  /** 保存 */
  private REPORT_SAVE = COMMON.REPORT_STATUS.SAVE;
  /** 提出 */
  private REPORT_SUBMIT = COMMON.REPORT_STATUS.SUBMIT;
  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  /** 無限ローディング */
  @Ref("infiniteLoading") private readonly infinite!: InfiniteLoading;

  /** 検索キーワード */
  @Prop({ default: "" }) keyword!: string;

  /** タイトルや新規作成ボタンを表示するか */
  @Prop({ default: true }) isShowSubHeader!: boolean;

  /** タブを離して表示するか */
  @Prop({ default: false }) isOffsetTab!: boolean;

  /** ヘッダー */
  @Prop() headers!: DataTableHeader[];

  /** コメント付きヘッダー */
  @Prop() commentHeader!: DataTableHeader; //コメントヘッダー

  /** 保存中タブのみ表示するヘッダー */
  @Prop({ default: () => [] }) saveHeaders!: DataTableHeader[];

  /** 提出済みタブのみ表示するヘッダー */
  @Prop({ default: () => [] }) submitHeaders!: DataTableHeader[];

  /** データ */
  @Prop() items!: { status: number }[];

  /** コメント領域 */
  @Prop({ default: false }) isCommentArea!: boolean;

  /** 差し戻し呼び出し判定メソッド */
  @Prop({ type: Function, default: () => true })
  remandBeforeFunc!: () => boolean;

  /** 編集者エリア表示フラグ */
  @Prop({ default: true }) isEditorArea!: boolean;

  /** タイトル */
  @Prop() title!: string;

  /** タイトルに付随するページ説明 */
  @Prop({ default: "" }) hint!: string;

  @Prop({ default: "204px" }) submitEditAreaWidth!: string;

  @Emit("update:items") updateItems(newValue: unknown) {
    return newValue;
  }

  /** タブ */
  private tab = -1;

  /** 差し戻しダイアログ表示フラグ */
  private isRemandDialogOpen = false;

  /** 差し戻し対象アイテム */
  private targetItem: unknown = null;

  /** 差し戻しコメント */
  private comment = "";

  /** ページ数 */
  private page = 0;

  /** 一回で取得するデータ量 */
  private limit = 20;

  /** キーワード反映 */
  private get Keyword(): string {
    return this.keyword;
  }
  private set Keyword(keyword: string) {
    this.updateKeyword(keyword);
  }

  /** 操作列を加えたヘッダー */
  private get EditHeaders(): DataTableHeader[] {
    // ADL画面の提出タブだけソート不可にする
    const isSortableStatusColumn = !(
      this.$route.name === "PatientAdlList" && this.tab === 1
    );
    let commonHeaders: DataTableHeader[] = [
      {
        text: "状態",
        value: "status",
        width: "94px",
        align: "center",
        sortable: isSortableStatusColumn
      }
    ];
    const editorHeaders: DataTableHeader[] = [
      {
        text: "更新日",
        value: "updated_at",
        width: "152px",
        sort: SortWarekiDate
      },
      {
        text: "更新者",
        value: "editor_staff.ibow_nickname",
        width: "120px"
      }
    ];
    if (this.isEditorArea) {
      commonHeaders = commonHeaders.concat(editorHeaders);
    }
    // 看護記録画面の未提出タブだけはsortableにする
    const isSortableEditColumn =
      this.$route.name === "SubmitList" && this.tab === 0;
    let edit: DataTableHeader[] = [
      {
        text: "編集",
        value: "edit",
        width: this.tab === 1 ? this.submitEditAreaWidth : "60px",
        align: "center",
        sortable: isSortableEditColumn
      }
    ];
    edit = edit.concat(commonHeaders, this.headers);
    // 保存タブのみ表示
    if (this.tab === 0 && this.saveHeaders && this.saveHeaders.length > 0) {
      edit = edit.concat(this.saveHeaders);
    }
    if (this.tab === 0 && this.commentHeader) {
      edit = edit.concat(this.commentHeader);
    }
    // 提出済みタブのみ表示
    if (this.tab === 1 && this.submitHeaders && this.submitHeaders.length > 0) {
      edit = edit.concat(this.submitHeaders);
    }
    return edit;
  }

  /** タブによる表示アイテム制御 */
  private get tabItems(): unknown[] {
    if (this.tab == 0) {
      return this.items.filter(item => item.status != this.REPORT_SUBMIT);
    } else if (this.tab == 1) {
      return this.items.filter(item => item.status == this.REPORT_SUBMIT);
    }
    return [];
  }

  /** タブの値変更 */
  @Watch("tab")
  private watchTab(newValue: number, oldValue: number) {
    if (oldValue === -1) return;
    this.resetLoaded();
    if (this.tab == 0) {
      this.updateData();
    }
    this.setUrlParams();
  }

  /** タブが切り替わった */
  @Emit()
  updateData() {
    return { tab: this.tab, page: this.page };
  }

  /** タブが切り替わった */
  @Emit()
  updateKeyword(newKeyword: string) {
    return newKeyword;
  }

  /** 新規作成 */
  @Emit()
  clickNew() {
    return;
  }

  /** 編集 */
  @Emit()
  clickEdit(item: unknown) {
    return item;
  }

  /** PDF表示 */
  @Emit()
  clickPdf(item: unknown) {
    return item;
  }

  /** 差し戻し */
  @Emit()
  clickRemand() {
    this.isRemandDialogOpen = false;
    return { item: this.targetItem, comment: this.comment };
  }

  /** 検索パネルを開く */
  @Emit()
  private openFilter() {
    return;
  }

  /** 行クリック */
  @Emit()
  private clickRow(item: { status: number }) {
    if (item.status == this.REPORT_SAVE || item.status == this.REPORT_REMAND) {
      this.clickEdit(item);
    }
    if (item.status == this.REPORT_SUBMIT) {
      this.clickPdf(item);
    }
  }

  @Watch("$route") private watchRoute() {
    this.changeLinkQuery();
  }

  mounted() {
    this.changeLinkQuery();
  }

  //URLの変更で動作変更
  private changeLinkQuery() {
    let reporttab = 0;
    if (typeof this.$route.query.reporttab === "string") {
      reporttab = Number(this.$route.query.reporttab);
    }
    this.tab = reporttab;
  }

  /** 未提出・提出済みをクエリパラメータに設定する */
  private setUrlParams() {
    this.$router.push({
      query: {
        reporttab: String(this.tab)
      }
    });
  }

  /** 差し戻しボタン表示 */
  public clickRemandBtn(item: unknown) {
    if (this.remandBeforeFunc()) {
      this.isRemandDialogOpen = true;
      this.targetItem = item;
    }
  }

  /** 読み込み完了 */
  public finishLoaded(items: unknown[]) {
    if (this.infinite) {
      this.infinite.stateChanger.loaded();
      if (items.length < this.limit) {
        this.infinite.stateChanger.complete();
      }
    }
  }

  /** 無限ローディングリセット */
  public resetLoaded() {
    if (this.infinite) {
      this.infinite.stateChanger.reset();
    }
    this.page = 0;
    this.updateItems([]);
  }

  /** 無限ローディングのローディング処理 */
  private infiniteHandler() {
    this.updateData();
    this.page++;
  }
}
