




























































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import {
  VisitSchdule,
  DefaultVsistSchedule,
  Rule,
  DefaultRule
} from "#/model/schedule";
// common
import { RepeatDiv } from "#/components/calendar/common";

// 繰り返しなし
@Component({})
export default class RepeatNone extends Mixins(UtilMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: () => DefaultVsistSchedule() }) visitSchedule!: VisitSchdule;
  @Prop({ default: () => DefaultRule() }) rule!: Rule;

  // watch
  /**
   *  終日変更時
   */
  @Watch("visitSchedule.all_day_flg")
  private onChangeAllDay() {
    if (this.visitSchedule.all_day_flg !== 1) {
      return;
    }
    this.visitSchedule.shift_start_time_hour =
      String(this.visitSchedule.shift_start_time_hour) === ""
        ? 0
        : this.visitSchedule.shift_start_time_hour;
    this.visitSchedule.shift_start_time_minute =
      String(this.visitSchedule.shift_start_time_minute) === ""
        ? 0
        : this.visitSchedule.shift_start_time_minute;
    this.visitSchedule.shift_end_time_hour =
      String(this.visitSchedule.shift_end_time_hour) === ""
        ? 0
        : this.visitSchedule.shift_end_time_hour;
    this.visitSchedule.shift_end_time_minute =
      String(this.visitSchedule.shift_end_time_minute) === ""
        ? 0
        : this.visitSchedule.shift_end_time_minute;
  }

  // イベント 訪問開始日変更
  private changeVisitStartDate() {
    // 訪問終了日 < 訪問開始日の場合
    if (
      this.visitSchedule.visit_end_date &&
      this.visitSchedule.visit_end_date < this.visitSchedule.visit_start_date
    ) {
      // 訪問終了日に訪問開始日をセット
      this.visitSchedule.visit_end_date = this.visitSchedule.visit_start_date;
    }
  }

  // バリデーション 訪問日
  private validateVisitDate(): string | boolean {
    if (this.rule.repeat_state !== RepeatDiv.None) {
      return true;
    }
    if (
      this.visitSchedule.visit_start_date > this.visitSchedule.visit_end_date
    ) {
      return "終了日は開始日以降に設定してください";
    }
    return true;
  }

  // バリデーション 訪問時刻
  private validateVisitTime(): string | boolean {
    if (
      this.rule.repeat_state !== RepeatDiv.None ||
      this.visitSchedule.all_day_flg === 1
    ) {
      return true;
    }
    if (
      this.visitSchedule.visit_start_date === this.visitSchedule.visit_end_date
    ) {
      if (
        this.visitSchedule.shift_start_time_hour <
        this.visitSchedule.shift_end_time_hour
      ) {
        return true;
      } else if (
        this.visitSchedule.shift_start_time_hour ===
          this.visitSchedule.shift_end_time_hour &&
        this.visitSchedule.shift_start_time_minute <=
          this.visitSchedule.shift_end_time_minute
      ) {
        return true;
      }
      return "終了時間は開始時間以降に設定してください";
    }

    return true;
  }
}
