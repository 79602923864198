






























import { Component, Prop, Vue } from "vue-property-decorator";
import { Attend, TimeSchedule } from "@/components/home/types";
import StampAndNotice from "@/components/home/stampAndNotice.vue";
import ScheduleToday from "@/components/home/scheduleToday.vue";

@Component({
  components: {
    StampAndNotice,
    ScheduleToday
  }
})
export default class extends Vue {
  /** 勤務情報 */
  @Prop() private readonly attend!: Attend;

  /** 本日の予定 */
  @Prop() private readonly schedules!: TimeSchedule[];

  /** 本日の予定の件数 */
  @Prop() private readonly todayScheduleCount!: number;

  /** 本日の予定のローディング */
  @Prop() private readonly todayScheduleLoading!: boolean;

  /** 未読の管理者お知らせ件数 */
  @Prop() private readonly countUnreadNotice!: number;

  /** 書類の未提出件数 */
  @Prop() private readonly countUnsubmit!: number;

  /** 緊急対応件数 */
  @Prop() private readonly countUrgency!: number;

  /** 事業所ID */
  @Prop() private readonly officeId!: number;

  /** 件数メッセージがあるか */
  private get HasNoticeMsg(): boolean {
    return (
      (this.InfoList && this.InfoList.length > 0) ||
      (this.CautionList && this.CautionList.length > 0)
    );
  }

  /** 通常メッセージ */
  private get InfoList(): string[] {
    const list: string[] = [];
    this.schedules.length &&
      list.push(
        `本日は<b>${this.todayScheduleCount}件</b>の予定が入っています`
      );
    this.countUnreadNotice &&
      list.push(`新しいお知らせが<b>${this.countUnreadNotice}件</b>あります`);
    return list.map(item =>
      item.replaceAll("<b>", "<b class='primary--text'>")
    );
  }

  /** 注意書きメッセージ */
  private get CautionList(): string[] {
    const list: string[] = [];
    this.countUrgency &&
      list.push(`緊急対応が<b>${this.countUrgency}件</b>あります`);
    this.countUnsubmit &&
      list.push(`未提出の書類が<b>${this.countUnsubmit}枚</b>あります`);
    return list.map(item => item.replaceAll("<b>", "<b class='error--text'>"));
  }
}
