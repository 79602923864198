

























import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import * as bbs from "@/store/modules/bbs/types";
import { InfoItems } from "./types";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import NoticePanel from "./noticeArea/NoticePanel.vue";

const BBS = namespace(bbs.name);

@Component({
  components: {
    NoticePanel
  }
})
export default class extends Mixins(UtilMixin, AxiosMixin) {
  @BBS.State ibowHeadlines!: bbs.AdminHeadline[];
  @BBS.State ewellHeadlines!: bbs.AdminHeadline[];
  @BBS.State supportHeadlines!: bbs.AdminHeadline[];
  @BBS.Mutation(bbs.SET_IBOW_HEADLINES) setiBowHeadLines!: Function;
  @BBS.Mutation(bbs.SET_EWELL_HEADLINES) setEwellHeadLines!: Function;
  @BBS.Mutation(bbs.SET_SUPPORT_HEADLINES) setSupportHeadLines!: Function;
  @BBS.Getter unreadCountiBow!: number;
  @BBS.Getter unreadCountEwell!: number;
  @BBS.Getter unreadCountSupport!: number;

  /** お知らせを別タブで開くかどうか */
  @Prop() private readonly isOpenNewTab = false;

  created() {
    this.getNotice();
  }

  /** お知らせ一覧を取得する */
  private getNotice(): void {
    this.postJsonCheck(window.base_url + "/api/bbsnoticelist/get", {}, res => {
      this.setiBowHeadLines(res.data.ibow_items);
      this.setEwellHeadLines(res.data.ewell_items);
      this.setSupportHeadLines(res.data.support_items);
    });
  }

  /** お知らせページへ遷移する */
  private moveNoticePage(item: InfoItems): void {
    // iOS 除外
    if (this.isOpenNewTab && !this.IsiOS) {
      window.open(`/bbs/notice-sep/${item.id}`, "_blank");
    } else {
      this.$router.push({ path: `/bbs/notice/${item.id}` });
    }
  }
}
