














































































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import { MedicineTaking } from "#/model/medicinetaking";

@Component
export default class MedicineTakings extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  PatientMixin
) {
  /** 利用者ID */
  private patientId = 0;

  /** 服薬管理一覧 */
  private medicine_takings: MedicineTaking[] = [];

  /** 中止・終了したものを含むフラグ */
  private includeStop = false;

  @Watch("includeStop")
  private watchIncludeStop() {
    this.fetchMedicineTakings();
  }

  created() {
    this.patientId = Number(this.$route.params.id);
    this.fetchMedicineTakings();
  }

  mounted() {
    this.setDefaultHeader();
  }

  /** 新規作成 */
  private addMedicine() {
    if (this.$route.name == "VisitMedicinetaking") {
      this.$router.push(`/visit/${this.patientId}/medicinetaking/edit/0`);
    } else {
      this.$router.push(`/patient/${this.patientId}/medicinetaking/edit/0`);
    }
  }

  /** 編集 */
  private edit(idx: number) {
    const medicineTaking = this.medicine_takings[idx];
    if (this.$route.name == "VisitMedicinetaking") {
      this.$router.push(
        `/visit/${this.patientId}/medicinetaking/edit/${medicineTaking.id}`
      );
    } else {
      this.$router.push(
        `/patient/${this.patientId}/medicinetaking/edit/${medicineTaking.id}`
      );
    }
  }

  /** コピー */
  private copy(idx: number) {
    const medicineTaking = this.medicine_takings[idx];
    this.postJsonBackground(
      window.base_url + "/api/patient/medicinetaking/copy",
      {
        medicine_taking: medicineTaking
      },
      () => {
        // Do Nothing
      }
    );
    if (this.$route.name == "VisitMedicinetaking") {
      this.$router.push(
        `/visit/${this.patientId}/medicinetaking/edit/${medicineTaking.id}?copy=1`
      );
    } else {
      this.$router.push(
        `/patient/${this.patientId}/medicinetaking/edit/${medicineTaking.id}?copy=1`
      );
    }
  }

  /** 閉じる */
  private clickClose() {
    window.close();
  }

  /** 服薬管理一覧取得 */
  private fetchMedicineTakings(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/medicinetakings/get",
      {
        patient_id: this.patientId,
        include_stop: this.includeStop
      },
      res => {
        this.medicine_takings = res.data.medicine_takings;
      }
    );
  }
}
