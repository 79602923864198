import { PatientFile } from "#/model/patient";

export interface PatientFileIF extends PatientFile {
  file_name: string; // ファイル名
  tag_ids: number[]; // タグIDリスト
}

export const DefaultPatientFileIF = (): PatientFileIF => ({
  id: 0,
  patient_id: 0,
  file_tags: [],
  file_path: "",
  file_name: "",
  tag_ids: [],
  created_at: "",
  updated_at: "",
  deleted_at: "",
  updated_at_wareki: ""
});
