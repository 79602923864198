import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#009199",
        primaryLight: "#f3f7f9",
        secondary: "#ef5b5b",
        secondaryLight: "#fdf1f0",
        white: "#fff",
        background: "#fbfeff",
        divider: "#e0e6ea",
        dividerBlue: "#81c8cb",
        stroke: "#c5ccd2",
        blue: "#96b7ea",
        lightBlue: "#f2f6fc",
        orange: "#ed856b",
        lightOrange: "#fff2ef",
        grey: "#2e4052",
        greyLight: "#5f667a",
        success: "#007dff",
        error: "#e0483d",

        append: "#1064b7",
        alert: "#e0483d",
        cancel: "#595757",
        footer: "#f2f2f2"
      }
    }
  }
});
