



























import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

@Component
export default class SymptomReport extends Mixins(UtilMixin, RulesMixin) {
  @Prop({ default: "prefix_id" }) private prefix_id!: string;
  @Prop() private condition_div!: number;
  @Prop() private condition!: string;

  @Emit("update:condition_div")
  private updateConditionDiv(newValue: number) {
    return newValue;
  }

  @Emit("update:condition")
  private updateCondition(newValue: string) {
    return newValue;
  }

  private get ConditionDiv(): number {
    return this.condition_div;
  }

  private set ConditionDiv(newValue: number) {
    this.updateConditionDiv(newValue);
  }

  private get Condition(): string {
    return this.condition;
  }

  private set Condition(newValue: string) {
    this.updateCondition(newValue);
  }

  private validateCondition(): boolean | string {
    if (this.ConditionDiv != 2) {
      return true;
    }
    return this.required(this.Condition, "必須です");
  }
}
