

































































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { Choice } from "@/types";
import { COLLECTION_SHARE_FILE_SETTING } from "@/const/envFireStore";
import {
  ShareFireInfo,
  SearchCondition,
  DefaultSearchCondition,
  FileSortTypeSelect
} from "@/components/sharefile/type";
import { OfficeSharedFile, DefaultOfficeSharedFile } from "#/model/agreement";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";
import FireStoreMixin from "@/mixins/firestoreMixin";
import FileUpload from "@/components/sharefile/FileUpload.vue";
import FileListCard from "@/components/sharefile/FileListCard.vue";
import FileListTable from "@/components/sharefile/FileListTable.vue";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import StaffSelect from "@/components/common_ibow/StaffSelect.vue";
import TagEdit from "@/components/sharefile/TagEdit.vue";
import FileHeader from "@/components/sharefile/FlieHeader.vue";

@Component({
  components: {
    FileUpload,
    FileListCard,
    FileListTable,
    OfficeSelect,
    StaffSelect,
    TagEdit,
    FileHeader
  }
})
export default class ShareFile extends Mixins(ShareFileMixin, FireStoreMixin) {
  private dialog = false; // ダイアログ展開状態
  private tagDialog = false; // タグ一括編集ダイアログ展開状態
  private shareFiles: ShareFireInfo[] = []; // 共有ファイル一覧
  private shareFile: OfficeSharedFile = DefaultOfficeSharedFile(); // 共有ファイル
  private isTimestamp = false; //署名付きタイムスタンプフラグ
  private accept = "";
  private pageNo = 1;

  // 検索用タグ
  private top_tags: string[] = [];
  // 更新者
  private staffs: Choice[] = [];
  // チェック状態 （テーブル表示のみ）
  private checked: number[] = [];
  // 編集権限フラグ
  private file_edit_flag = false;

  // 検索条件
  protected searchCond: SearchCondition = DefaultSearchCondition();
  protected setDefaultSearch() {
    // 表示切り替えボタン・並び順の選択状態は保持
    const defaultSearchCond = DefaultSearchCondition();
    this.searchCond = {
      ...this.searchCond,
      office_id: defaultSearchCond.office_id,
      text_field: defaultSearchCond.text_field,
      update_user_id: defaultSearchCond.update_user_id
    };
  }

  // 検索サイドバーを開いているか
  private searchPanel = false;

  @Watch("searchCond", { deep: true })
  private changeDocument() {
    this.documentSave(this.searchCond);
  }

  @Watch("searchCond.toggle_list")
  private changeDisplay() {
    if (!this.searchCond.toggle_list) {
      this.checked = [];
    }
    this.changeDocument;
  }
  public async created() {
    this.collection = COLLECTION_SHARE_FILE_SETTING;
    this.documentId = String(this.loginUser.id);
    const data = await this.documentGet();
    if (data) {
      // 保存済み検索条件を反映、保存データにはない追加されたプロパティなら、デフォルト値が使われる
      this.searchCond = {
        ...DefaultSearchCondition(),
        ...(data as SearchCondition)
      };
      // 並び順選択のラベルに反映
      this.sortSearchCondToSelect(this.searchCond);
    } else if (data === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
    this.fetchFiles();
  }

  // ファイル一覧取得;
  private fetchFiles() {
    this.postJsonCheck(
      window.base_url + "/api/shares/get",
      this.searchCond,
      res => {
        this.shareFiles = res.data.office_share_files;
        this.staffs = res.data.staffs;
        this.top_tags = res.data.top_hash_tag;
        this.file_edit_flag = res.data.file_edit_flag;
        // レスポンスのファイル並び順は検索条件によらず一定であるため、画面側で並び替える
        this.changeSortOrder(this.fileSortBy);
      }
    );
  }

  private closeDialog(isUpdate: boolean) {
    if (isUpdate) {
      this.fetchFiles();
    }
    this.dialog = false;
  }

  private closeTagDialog(isUpdate: boolean) {
    if (isUpdate) {
      this.fetchFiles();
    }
    this.tagDialog = false;
  }

  // 新規作成イベント
  private clickNew(isTimestamp = false) {
    this.shareFile = DefaultOfficeSharedFile();
    this.isTimestamp = isTimestamp;
    if (this.isTimestamp) {
      this.accept = "application/pdf";
    } else {
      this.accept = "";
    }
    this.dialog = true;
  }

  //タグ一括編集イベント
  private openTagDialog() {
    this.tagDialog = true;
  }

  // ファイルアップロードダイアログ openイベント
  private fileUploadOpen(fileId: number) {
    this.fetchFile(fileId, () => {
      this.dialog = true;
    });
  }

  /** URLコピー */
  private copyFileUrl(fileId: number) {
    this.copyUrlClipboard(
      window.auth_middleend_url + "/appform/share/file/view/",
      fileId,
      "url-copy-area"
    );
  }

  private fetchFile(fileId: number, callback: Function) {
    this.postJsonCheck(window.base_url + "/api/share/get", { fileId }, res => {
      this.shareFile = res.data.office_share_file;
      callback();
    });
  }

  /** 検索条件を並び順選択に反映 */
  private sortSearchCondToSelect(searchCond: SearchCondition) {
    const matcSortBy = this.fileSortByList.find(
      sortBy =>
        sortBy.sort === searchCond.sort &&
        sortBy.sortType === searchCond.sort_type
    );
    if (matcSortBy) {
      this.fileSortBy = matcSortBy;
    }
  }

  //ソート
  private changeSortOrder(rule: FileSortTypeSelect) {
    this.fileSortBy = rule;
    this.searchCond.sort = rule.sort;
    this.searchCond.sort_type = rule.sortType;

    this.shareFiles = this.shareFiles.sort((first, second) => {
      let f, s: string;
      if (this.searchCond.sort === 0) {
        // ファイル名
        f = first.file_name;
        s = second.file_name;
      } else {
        // 更新日時
        f = first.updated_at;
        s = second.updated_at;
      }
      const result = this.sortOrdar(f, s, this.searchCond.sort_type);
      if (result !== 0) {
        return result;
      }
      return this.sortOrdar(first.id, second.id, this.searchCond.sort_type);
    });
  }

  //テキストフィールドに上位タグを追加
  private addTag(tag: string) {
    if (!this.searchCond.text_field) {
      this.searchCond.text_field = tag;
    } else {
      this.searchCond.text_field += " " + tag;
    }
    this.fetchFiles();
  }

  /** 閉じる */
  private clickClose() {
    window.close();
  }

  /** 署名付きの判定 */
  private get CheckTimestamp() {
    return this.file_edit_flag && window.mode == "dev";
  }

  //** 絞り込みしているかどうか */
  private get IsFiltered(): boolean {
    return !(
      this.searchCond.office_id === 0 && this.searchCond.update_user_id === 0
    );
  }

  private openSearchPanel() {
    this.searchPanel = true;
  }

  /** ページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }
}
