import { MutationTree } from "vuex";
import * as types from "./types";
import { TargetKind } from "@/const/ocr";

export const mutations: MutationTree<types.OcrState> = {
  [types.SET_DOCUMENT_INFO](state, documentInfo: { type: number; id: number }) {
    state.documentType = documentInfo.type;
    state.documentId = documentInfo.id;
  },
  [types.SET_OCR_RESULT](
    state,
    input: { targetKind: number; map: { [key: string]: unknown } }
  ) {
    state.targetKind = input.targetKind;
    switch (input.targetKind) {
      //医療保険
      case TargetKind.mediInsGeneral: //（一般）
        types.SetMediInsGeneral(state, input.map);
        break;
      case TargetKind.mediInsNational: //（国保）
        types.SetMediInsNational(state, input.map);
        break;
      case TargetKind.mediInsOldOld: //（後期高齢）
        types.SetMediInsOldOld(state, input.map);
        break;
      //高齢受給者証
      case TargetKind.mediInsOldAge:
        types.SetMediInsOldAge(state, input.map);
        break;
      //高額療養費
      case TargetKind.mediInsHighExpense:
        types.SetMediInsHighExpense(state, input.map);
        break;
      //介護保険
      case TargetKind.careInsGeneral:
        types.SetCareInsGeneral(state, input.map);
        break;
      //介護保険負担割合証
      case TargetKind.careInsPaymentRate:
        types.SetCareInsPaymentRate(state, input.map);
        break;
      //指示書
      case TargetKind.indicateNormal:
        types.SetIndicateNormal(state, input.map);
        break;
      case TargetKind.indicateMental:
        types.SetIndicateMental(state, input.map);
        break;
      case TargetKind.indicateSpecial:
      case TargetKind.indicateMentalSpecial:
        types.SetIndicateSpecial(state, input.map);
        break;
    }
  },
  [types.CLEAR_STATE](state) {
    state.documentType = 0;
    state.documentId = 0;
    state.targetKind = 0;
    state.mediIns = types.DefaultOcrMediInsCondition();
    state.mediInsOldAge = types.DefaultOcrMediInsOldAgeCondition();
    state.mediInsHighExpense = types.DefaultOcrMediInsHighExpenseCondition();
    state.careIns = types.DefaultOcrCareInsCondition();
    state.careInsPaymentRate = types.DefaultOcrCareInsPaymentRateCondition();
    state.indicate = types.DefaultOcrIndicateCondition();
    state.indicateSpecial = types.DefaultOcrSpecialIndicateCondition();
  }
};
