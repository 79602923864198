































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Patient, RegularVisit, DefaultRegularVisit } from "#/model/patient";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {}
})

/** 利用者基礎情報 定期巡回フォーム */
export default class FormRegularVisit extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 利用者情報 */
  @Prop() patient!: Patient;

  /** 読み取り専用フラグ(履歴編集画面用) */
  @Prop({ default: false }) isReadOnly!: boolean;

  /** 関数 */

  /** 適用中の定期巡回か判定 */
  private checkIsRegularVisitNow(regularVisit: RegularVisit): boolean {
    return (
      regularVisit.id > 0 &&
      this.patient.info.regular_visit_term_from == regularVisit.start_date &&
      this.patient.info.use_regular_visit == 1
    );
  }

  /** 定期巡回追加 */
  private async addRegularVisit() {
    if (!this.patient.regular_visits) {
      this.patient.regular_visits = [];
    }

    // 登録済み
    // 現在有効
    // 終了日が空
    // な定期巡回がある場合は、追加できない（終了日を入れてもらう）

    for (const rv of this.patient.regular_visits) {
      if (
        rv.id > 0 &&
        rv.id == this.patient.regular_visit_now.id &&
        !rv.end_date
      ) {
        await this.$openAlert(
          "定期巡回を新しく追加するためには、現在適用中の定期巡回終了日を入力して下さい。"
        );
        return;
      }
    }

    const newRegularVisit = DefaultRegularVisit();
    this.patient.regular_visits.unshift(newRegularVisit);
  }

  /** 定期巡回削除 */
  private async removeRegularVisit(index: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.patient.regular_visits.splice(index, 1);
    }
  }

  /**
   * サービスに関するチェック
   * 同年同月で異なるサービスを選択している場合はNG
   */
  private checkStartEndService(
    start: string,
    end: string,
    startService: number,
    endService: number
  ): boolean | string {
    if (!start || !end) {
      return true;
    }

    // 同年同月に異なるサービスを選択していた場合はエラー
    if (
      start.substring(0, 7) == end.substring(0, 7) &&
      startService != endService
    ) {
      return "同年同月の場合は同じサービスを選択してください";
    }
    return true;
  }

  /**
   * サービス期間に関するチェック
   * 開始・終了が逆転している場合はNG
   */
  private checkStartEnd(start: string, end: string): boolean | string {
    if (start && end) {
      if (start > end) {
        return "期間が不正です";
      }
    }
    return true;
  }

  /**
   * サービス期間に関するチェック
   * 期間が重複している場合はNG
   */
  private checkPeriod(
    index: number,
    start: string,
    end: string
  ): boolean | string {
    if (!start && !end) {
      return true;
    }

    let isOk = true;

    this.patient.regular_visits.forEach((rv, i) => {
      // 自身との比較は行わない
      if (i === index) {
        return;
      }

      // 開始日も終了日も空の場合はチェック対象外
      if (!rv.start_date && !rv.end_date) {
        return;
      }

      const rvStart = rv.start_date || "1970-01-01";
      const rvEnd = rv.end_date || "9999-12-31";

      const tStart = start || "1970-01-01";
      const tEnd = end || "9999-12-31";

      if (rvEnd >= tStart && rvStart <= tEnd) {
        isOk = false;
      }
    });

    if (!isOk) {
      return "定期巡回期間が重複しています。";
    }

    return true;
  }
}
