






























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CalendarTitle extends Vue {
  /** 色情報取得フラグ */
  @Prop({ default: false }) private readonly resGetColor!: boolean;

  /** ドキュメント情報取得フラグ */
  @Prop({ default: false }) private readonly resGetDoc!: boolean;

  /** 情報取得失敗フラグ */
  private get FailGetData(): boolean {
    return !this.resGetColor || !this.resGetDoc;
  }
}
