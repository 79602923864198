





















































































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import {
  SelectStaff,
  SetColor,
  SetColorItem
} from "@/components/calendar/common";
import { Staff } from "#/model/staff";
import UtilMixin from "@/mixins/utilMixin";
import { COLLECTION_STAFF_CALENDAR_SET_COLORS } from "@/const/envFireStore";
import FireStoreMixin from "@/mixins/firestoreMixin";
import NavigationLayerStaffScheduleToggle from "@/components/calendar/NavigationLayerStaffScheduleToggle.vue";
import { CalendarDispEvent } from "#/components/calendar/common";

@Component({
  components: {
    NavigationLayerStaffScheduleToggle
  }
})
export default class NavigationStaff extends Mixins(UtilMixin, FireStoreMixin) {
  /** 表示職員 */
  @Prop() item!: SelectStaff;

  /** 職員一覧 */
  @Prop() staffs!: Staff[];

  /** 訪問予定とシフトレイヤー */
  @Prop({ default: () => [] }) events!: CalendarDispEvent[];

  /** 職員色情報のFirestoreオブジェクト */
  @Prop() docSetColor!: SetColor;

  /** 対象年月日 */
  @Prop() focus!: string;

  /** チェック状態更新時 */
  @Emit() changeDocument() {
    return;
  }

  /** 予定表示職員を削除する（単体） */
  @Emit() delCalendarStaff() {
    return this.item.id;
  }

  /** 選択可能な色配列 */
  private swatches = [
    ["#ef9a9a", "#f48fb1", "#ce93d8", "#b39ddb", "#9fa8da"],
    ["#90caf9", "#81d4fa", "#80deea", "#80cbc4", "#a5d6a7"],
    ["#c5e1a5", "#e6ee9c", "#fff59d", "#ffe082", "#ffcc80"],
    ["#bcaaa4", "#b0bec5", "#eeeeee"]
  ];

  /** 職員色オブジェクト */
  private get ColorObject(): SetColorItem | undefined {
    return this.docSetColor.setting.find(item => item.id === this.item.id);
  }

  /** 職員色 */
  private get Color(): string {
    if (this.ColorObject && typeof this.ColorObject.color === "string") {
      return this.ColorObject.color;
    }
    return "#ffffff"; // ここを空白にするとrgbaの形になるので注意
  }

  /** 職員名 */
  private get StaffName(): string {
    const staff = this.staffs.find(staff => staff.id === this.item.id);
    if (staff) {
      return staff.info.ibow_nickname;
    }
    return "";
  }

  /** 職員表示状態を変更する */
  private changeStaffDisplay() {
    this.item.display = !this.item.display;
    this.changeDocument();
  }

  /** 職員色を変更する */
  private async changeStaffColor(color: string) {
    const beforeColor = this.Color;

    if (this.ColorObject) {
      this.ColorObject.color = color;
    } else {
      this.docSetColor.setting.push({
        id: this.item.id,
        color: color
      });
    }

    let res = false;
    if (this.loginUser.ewell_user_system_id) {
      this.collection = COLLECTION_STAFF_CALENDAR_SET_COLORS;
      this.documentId = String(this.loginUser.ewell_user_system_id);
      res = await this.documentSave(this.docSetColor);
    }
    if (!res && this.ColorObject) {
      this.ColorObject.color = beforeColor;
      return;
    }

    //色変更
    this.events.forEach(event => {
      if (event.staff_id === this.item.id) {
        event.color = color;
        const styleSplit = event.style.split(";");
        event.style = styleSplit[0] + ";" + "border-color: " + color;
      }
    });
  }

  /** 印刷するボタンクリック */
  private clickPrint() {
    const name = "StaffCalendarPrint";
    if (!this.focus) {
      this.focus = this.dateToStr(new Date(), "yyyy-MM-dd");
    }
    const printRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.item.id) },
      query: { target: String(this.focus) }
    });
    window.open(printRoute.href, "_blank");
  }

  /** 予定表示職員を削除する（単体） */
  private async clickDelCalendarStaff() {
    if (
      !(await this.$openConfirm(
        "職員をカレンダーから削除します。よろしいですか。"
      ))
    ) {
      return;
    }
    this.delCalendarStaff();
  }
}
