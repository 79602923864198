



























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AppChatBubble, { Icon } from "./AppChatBubble.vue";
import { dateToStr, diffOfDay, getDayOfWeek } from "../../utility/appDate";

export interface Chat {
  id: string;
  text: string;
  name: string;
  isOwn: boolean;
  chatType: number;
  date: Date;
  accountId: number;
  stampPath: string;
  imagePath: string;
  fileType: number;
  deleteFlg: boolean;
}

export interface Account {
  id: number;
  lastReadAt: Date;
}

@Component({
  components: {
    AppChatBubble
  }
})
export default class AppChatList extends Vue {
  @Prop() selfEwellUserId!: number;
  @Prop({
    default: () => {
      return [];
    }
  })
  chats!: Chat[];
  @Prop({
    default: () => {
      return [];
    }
  })
  accounts!: Account[];
  @Prop() dropChatModeFlg!: boolean;

  private cachedIcons: Icon[] = [];

  beforeDestroy() {
    this.dropChatModeOff();
  }

  private getReadCount(postDate: Date) {
    return this.accounts.filter(account => {
      // 自分はカウントの対象から除く
      return (
        account.lastReadAt >= postDate && account.id !== this.selfEwellUserId
      );
    }).length;
  }

  /// 日付の区切りラベルを取得
  private getDateTimeLabel(index: number, chat: Chat) {
    let date: Date;

    if (index === 0) {
      date = chat.date;
    } else {
      const prev = this.chats[index - 1];
      const dDiffPrev = diffOfDay(prev.date, chat.date);

      if (dDiffPrev === 0) {
        // 前のチャットと同じ日付なら何も表示しない
        return undefined;
      } else {
        date = chat.date;
      }
    }

    const dDiffToday = diffOfDay(date, new Date());

    if (dDiffToday === 0) {
      return "今日";
    } else if (dDiffToday === -1) {
      return "昨日";
    } else {
      return dateToStr(chat.date, "M/d") + ` (${getDayOfWeek(chat.date)})`;
    }
  }

  private updateIcon(icon: Icon) {
    const index = this.cachedIcons.findIndex(cached => {
      return cached.id === icon.id;
    });

    if (index === -1) {
      this.cachedIcons.push(icon);
    } else {
      this.cachedIcons.splice(index, 1, icon);
    }
  }

  @Emit("drop:chat")
  private dropChat(id: string) {
    return id;
  }

  @Emit("dropChatMode:off")
  private dropChatModeOff() {
    return false;
  }
}
