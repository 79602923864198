// データ
export interface Data {
  receipt_sales: number; // 種類別売上
  p_receipt_sales: number; // 種類別売上（前月）
  r_receipt_sales: string; // 種類別売上（前月比）
  visit_record_number: number; // 訪問記録
  p_visit_record_number: number; // 訪問記録（前月）
  r_visit_record_number: string; // 訪問記録（前月比）
  patient_number: number; // 利用者数
  p_patient_number: number; // 利用者数（前月）
  r_patient_number: string; // 利用者数（前月比）
  patient_continuity_rate: string; // 利用者継続
  p_patient_continuity_rate: string; // 利用者継続（前月）
  office_hours: number; // 職員勤務時間
  p_office_hours: number; // 職員勤務時間（前月）
  r_office_hours: string; // 職員勤務時間（前月比）
  indicate_number: number; // 利用者指示書
  p_indicate_number: number; // 利用者指示書（前月）
  r_indicate_number: string; // 利用者指示書（前月比）
}
export const DefaultData = (): Data => ({
  receipt_sales: 0,
  p_receipt_sales: 0,
  r_receipt_sales: "-",
  visit_record_number: 0,
  p_visit_record_number: 0,
  r_visit_record_number: "-",
  patient_number: 0,
  p_patient_number: 0,
  r_patient_number: "-",
  patient_continuity_rate: "なし",
  p_patient_continuity_rate: "なし",
  office_hours: 0,
  p_office_hours: 0,
  r_office_hours: "-",
  indicate_number: 0,
  p_indicate_number: 0,
  r_indicate_number: "-"
});
