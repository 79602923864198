





















import { Component } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class StaffDiv extends MedPartBase {}
