




































































import { Component } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { DataTableHeader } from "vuetify/types/index";
import { BreadItem } from "@/store/modules/patient/types";
import { TableDataDetailPatient } from "@/components/officeAggregate/receiptSales/types";

@Component
export default class ReceiptSalesPatient extends Base {
  private officeId = 0; //事業所ID
  private officeName = ""; //事業所名
  private yearmonth = ""; //年月
  private tableDatas: TableDataDetailPatient[] = []; //テーブルデータ

  //画面上部情報
  private get receiptDate() {
    const [year, month] = this.yearmonth.split("-");
    return `年月：${year}年${month}月`;
  }

  // パンくずリスト
  private breadItems: BreadItem[] = [];

  //データテーブルヘッダー
  private headers: DataTableHeader[] = [
    {
      value: "ibow_id",
      text: "利用者ID",
      width: "110px"
    },
    {
      value: "name",
      text: "氏名",
      width: "150px"
    },
    {
      value: "furigana",
      text: "ﾌﾘｶﾞﾅ",
      width: "150px"
    },
    {
      value: "payment_method",
      text: "支払方法",
      width: "100px"
    },
    {
      value: "visit_counts",
      text: "訪問回数",
      width: "100px"
    },
    {
      value: "total",
      text: "合計",
      width: "90px"
    },
    {
      value: "medical_insurance",
      text: "医療/国保連",
      width: "120px"
    },
    {
      value: "medical_paymentfund",
      text: "医療/支払基金",
      width: "140px"
    },
    {
      value: "medical_ownexpense",
      text: "医療/自己負担",
      width: "140px"
    },
    {
      value: "adjust_amount1",
      text: "医療/調整",
      width: "120px"
    },
    {
      value: "care_insurance",
      text: "介護/保険",
      width: "120px"
    },
    {
      value: "care_publicexpenditure",
      text: "介護/公費",
      width: "120px"
    },
    {
      value: "care_ownexpense",
      text: "介護/自己負担",
      width: "140px"
    },
    {
      value: "adjust_amount2",
      text: "介護/調整",
      width: "120px"
    },
    {
      value: "ownexpense",
      text: "自費",
      width: "90px"
    }
  ];

  public async created() {
    this.officeId = Number(this.$route.query.id);
    this.officeName = String(this.$route.query.office);
    this.yearmonth = String(this.$route.query.yearmonth);

    this.breadItems = [
      {
        text: "レセプト売上情報",
        disabled: false,
        to: "/office_aggregate/receipt_sales",
        exact: true
      },
      {
        text: "レセプト売上情報詳細",
        disabled: false,
        to: `/office_aggregate/receipt_sales/detail?id=${this.officeId}&office=${this.officeName}&yearmonth=${this.yearmonth}`,
        exact: true
      },
      {
        text: "利用者詳細",
        disabled: true,
        to: ""
      }
    ];
    await this.getDetailPatient();
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // Excelダウンロード
  private downloadExcel() {
    this.postJsonBlobResCheck(
      window.base_url +
        "/api/officeAggregate/receiptSales/patientDetail/downloadexcel",
      {
        table_datas: this.tableDatas,
        office_name: this.officeName,
        yearmonth: this.yearmonth
      },
      res => {
        const fileName = "receiptSalesPatientDetail.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 詳細データ取得
  //-----------------------------------------------------
  private async getDetailPatient() {
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/receiptSales/getpatientdetail",
        {
          office_id: this.officeId,
          yearmonth: this.yearmonth
        },
        res => {
          this.tableDatas = res.data.table_datas;
          resolve(0);
        }
      );
    });
  }
}
