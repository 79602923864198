













import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";
import { MedItems } from "./type";

@Component({
  components: {
    ItemTable
  }
})
export default class Med extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 医療保険 */
  @Prop({ default: () => [] }) private readonly items!: MedItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 医療保険ヘッダー */
  private medHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    { text: "保険種類", value: "insurance_type_div", width: "80px" },
    { text: "保険者番号", value: "insurer_no", width: "100px" },
    { text: "記号・番号", value: "insured_no", width: "140px" },
    { text: "枝番", value: "insured_branch", width: "80px" },
    {
      text: "資格取得年月日",
      value: "first_issue_date",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "開始日",
      value: "start_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    { text: "給付割合", value: "payment_rate", width: "80px" },
    { text: "兼高齢", value: "is_old_age_certificate" }
  ];

  /** 新規作成 */
  private medCreate() {
    this.$router.push({
      name: "InsuranceInfoMed",
      params: { id: String(this.patientId), insmedid: "0" }
    });
  }

  /** 修正 */
  private medRepair(item: MedItems) {
    this.$router.push({
      name: "InsuranceInfoMed",
      params: { id: String(this.patientId), insmedid: String(item.id) }
    });
  }

  /** コピー */
  private medCopy(item: MedItems) {
    this.$router.push({
      name: "InsuranceInfoMed",
      params: { id: String(this.patientId), insmedid: String(item.id) },
      query: { mode: "copy" }
    });
  }

  //現在有効な医療保険に色を付ける
  private medItemsRowBackground(item: MedItems) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
