import firebase from "firebase/app";

/** ドキュメントIDの設定 */
export const SET_DOCUMENT_ID = "SET_DOCUMENT_ID";

/** オブザーバーの設定 */
export const SET_OBSERVER = "SET_OBSERVER";

/** ドキュメントの保存 */
export const SAVE_DOCUMENT = "SAVE_DOCUMENT";

/** ドキュメントの更新 */
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";

/** ドキュメントの削除 */
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

/** ドキュメントの取得 */
export const GET_DOCUMENT = "GET_DOCUMENT";

/** ドキュメントデータの設定 */
export const SET_DOCUMENT_DATA = "SET_DOCUMENT_DATA";

export interface FirestoreState {
  /** コレクションID（固定） */
  collection: string;
  /** ドキュメントID（ログイン職員ID） */
  documentId: string;
  /** Firebaseデータ */
  firebaseData?: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >;
  /** Firebaseから取得したデータ */
  data?: firebase.firestore.DocumentData;
  /** リッスン解除メソッド */
  unsubscribe?: () => void;
  /** データ変更時に呼ばれる処理 */
  observers: Observer[];
  /** エラー時 */
  error: boolean;
}

export interface Observer {
  /** 一意の名前 */
  observer_name: string;
  /** データ変更時に呼ばれる処理 */
  next: (
    snapshot: firebase.firestore.DocumentSnapshot<
      firebase.firestore.DocumentData
    >
  ) => void;
  /** エラー時に呼ばれる処理 */
  error?: (error: firebase.firestore.FirestoreError) => void;
  /** 完了時に呼ばれる処理 */
  complete?: () => void;
}

/** オブザーバーの登録処理 */
export function setSnapShot(state: FirestoreState) {
  state.unsubscribe = state.firebaseData?.onSnapshot(
    doc => {
      for (const o of state.observers) {
        o.next(doc);
      }
    },
    error => {
      for (const o of state.observers) {
        if (o.error != undefined) {
          o.error(error);
        }
      }
    },
    () => {
      for (const o of state.observers) {
        if (o.complete != undefined) {
          o.complete();
        }
      }
    }
  );
}
