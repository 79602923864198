






































































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { PREFECTURES } from "@/const";
import { AgreementHistory } from "@/components/master/agreeinq/types";
import { Office } from "#/model/agreement/office";

@Component
export default class AgreeInq extends Mixins(AxiosMixin) {
  private header = [
    { title: "会社名", value: "" },
    { title: "企業コード", value: "" },
    { title: "郵便番号", value: "" },
    { title: "住所", value: "" },
    { title: "電話番号", value: "" },
    { title: "FAX", value: "" }
  ];

  private co_code = "";

  private prefecture: unknown = "";

  //契約情報
  private agreement: AgreementHistory = {
    id: 0,
    agreement_id: 0,
    confflag: 0,
    co_name: "",
    co_code: "",
    post_code: "",
    pref: 0,
    city: "",
    area: "",
    building: "",
    tel_city: "",
    tel_area: "",
    tel_num: "",
    fax_area: "",
    fax_city: "",
    fax_num: "",
    remarks: "",
    receipt_agency_div: 0,
    u_start_dt: "",
    u_end_dt: "",
    u_start_dt_wareki: "",
    u_end_dt_wareki: ""
  };

  //事業所情報
  private offices: Office[] = [];

  public created(): void {
    this.fetchAgreements();
  }

  private fetchAgreements(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/agreement/get",
      {},
      res => {
        if (res.data.agreement) {
          this.agreement = res.data.agreement;
          this.co_code = res.data.co_code;
          this.offices = res.data.offices;
          this.prefecture = PREFECTURES.find(pref => {
            return pref.value === Number(res.data.agreement.pref);
          })?.text;
        }
      }
    );
  }
}
