































































































































import AxiosMixin from "@/mixins/axiosMixin";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { Prop, Emit, Ref } from "vue-property-decorator";
import sanitizeHTML from "sanitize-html";
import { dateToStr } from "../../utility/appDate";
import { Regex } from "../../utility/regex";
import { FETCH_ACCOUNT_IMAGE_PATH } from "#/store/types";
import { ChatType } from "./const";
import AppChatSystemIcon from "./AppChatSystemIcon.vue";

export interface Icon {
  id: number;
  path: string;
  expired: Date;
}

function replaceAngleBrackets(str: string): string {
  return str.replaceAll("<", "＜").replaceAll(">", "＞");
}

@Component({ components: { AppChatSystemIcon } })
export default class AppChatBubble extends AxiosMixin {
  @Prop() id!: string;
  @Prop() text!: string;
  @Prop() name!: string;
  @Prop() deleteFlg!: boolean;
  @Prop() isOwn!: boolean;
  @Prop() chatType!: number;
  @Prop() fileType!: number;
  @Prop() date!: Date;
  @Prop() readCount!: number;
  @Prop() accountId!: number;
  @Prop() stampPath?: string;
  @Prop() imagePath?: string;
  @Prop() sectionHeader?: string;
  @Prop({
    default: () => {
      return [];
    }
  })
  cachedIcons!: Icon[];
  @Prop() dropChatModeFlg!: boolean;

  @Ref("AppChatBubble")
  private readonly appChatBubble!: HTMLDivElement;

  @Getter("AccountImagePath", { namespace: "chat" })
  accountImagePath!: (accountId: number) => string;

  @Action(FETCH_ACCOUNT_IMAGE_PATH, { namespace: "chat" })
  fetchAccountImagePath!: Function;

  private sanitize = sanitizeHTML;
  private signImagePath = "";
  private signUserIconPath = "";

  private get time(): string {
    return dateToStr(this.date, "H:mm");
  }

  private get chatText(): string {
    if (this.chatType === ChatType.systemText) {
      // システム側で作成したチャットはいじらない
      return this.text;
    }

    // URL が含まれていた場合は a タグに置き換える
    return Regex.replaceURLToLink(replaceAngleBrackets(this.text));
  }

  private get isSystemChat(): boolean {
    return this.chatType === ChatType.systemText;
  }

  private get isTextChat(): boolean {
    return (
      this.chatType === ChatType.text || this.chatType === ChatType.systemText
    );
  }

  private get isStampChat(): boolean {
    return this.chatType === ChatType.stamp;
  }

  private get isImageChat(): boolean {
    return this.chatType === ChatType.image;
  }

  created() {
    this.sanitize.defaults.allowedAttributes = {
      a: ["href", "class", "target", "id"]
    };

    if (this.imagePath) {
      this.postJsonBackground(
        window.auth_backend_url + "/api/sign-image-path/get",
        {
          bucket_path: this.imagePath
        },
        res => {
          this.signImagePath = res.data.image_path;
        }
      );
    }

    // 自分のアイコンは表示しない。システムチャットのアイコンは固定
    if (!this.isOwn && this.chatType !== ChatType.systemText) {
      this.fetchAccountImagePath(this.accountId);
    }
  }

  private openImageTab(path: string) {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = path;
    a.click();
  }

  mounted() {
    // リンクをクリックした時の挙動をタグの種類によって変えたい
    const atgs = this.appChatBubble.querySelectorAll("a");

    atgs.forEach(a => {
      const href = a.getAttribute("href");
      const id = a.getAttribute("id");

      if (href !== null && id !== null) {
        a.addEventListener("click", event => {
          // iBowの内部リンクはSPA遷移させたい
          const isiBowLink = Regex.isiBowPageLink(id);
          if (isiBowLink) {
            event.preventDefault();

            // 同じ path に遷移しようとするとエラーが発生する
            if (href !== this.$route.fullPath) {
              this.$router.push(href);
            }

            return;
          }

          // file は別タブにて開きたい（Authサーバ向き）
          const isiBowFileLink = Regex.isiBowFileLink(id);
          if (isiBowFileLink) {
            event.preventDefault();

            window.open(href, "_blank");
          }
        });
      }
    });
  }

  @Emit()
  private updateIcon(icon: Icon) {
    return icon;
  }

  @Emit("drop:chat")
  private dropChat() {
    return this.id;
  }
}
