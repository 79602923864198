var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"ibow2-header px-1",staticStyle:{"z-index":"100"},attrs:{"app":"","flat":"","clipped-left":"","height":"86px","color":_vm.$vuetify.breakpoint.lgAndUp ? 'white' : 'primary'}},[(!_vm.$vuetify.breakpoint.lgAndUp && !_vm.is_visit)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleSideOpen()}}},[_c('AppIcon',{attrs:{"icon":"drawer-1","fill":_vm.$vuetify.theme.themes.light.white}})],1):_vm._e(),_c('v-btn',{staticClass:"ibow2-header-logo ml-1",attrs:{"fab":"","text":"","to":"/","disabled":_vm.is_visit}},[_c('v-img',{attrs:{"src":("/images/" + (_vm.$vuetify.breakpoint.lgAndUp
            ? 'logo_ibow.svg'
            : 'logo_ibow_white.svg')),"alt":"アイボウのロゴ","max-width":"32px"}})],1),_c('v-spacer'),_c('div',{staticClass:"ibow2-header-contents"},[(_vm.officeName)?_c('p',{staticClass:"ma-0 pt-2 office-name",class:_vm.$vuetify.breakpoint.lgAndUp ? 'primary--text' : 'white--text',attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.officeName)+" ")]):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-spacer'),(_vm.isBirthday)?_c('v-row',{staticClass:"mr-4",class:_vm.$vuetify.breakpoint.lgAndUp ? 'primary--text' : 'white--text',attrs:{"no-gutters":"","justify":"end","align":"center"}},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.$vuetify.breakpoint.lgAndUp ? 'primary' : 'white'}},[_vm._v("mdi-party-popper")]):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('span',[_vm._v("お誕生日おめでとうございます！")]):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"ibow2-header-item-wrap",attrs:{"no-gutters":"","align":"center"}},[_c('v-spacer'),(_vm.isBirthday && !_vm.$vuetify.breakpoint.lgAndUp)?_c('AppToolTip',{attrs:{"left":"","top":false,"label":"お誕生日おめでとうございます！"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":_vm.$vuetify.breakpoint.lgAndUp ? 'primary' : 'white'}},'v-icon',attrs,false),on),[_vm._v("mdi-party-popper")])]}}],null,false,773001628)}):_vm._e(),(!_vm.is_visit)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","content-class":"ibow2-header-submenu-wrapper","close-on-click":_vm.closeSearchMenuOnClickOutside,"close-on-content-click":_vm.closeSearchMenuOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({on:{"click":_vm.openSearchMenu}},on),[_c('AppTextField',{staticClass:"ibow2-header-search ibow2-input-field-noborder mr-2 rounded-lg",attrs:{"label":"利用者を検索","clearable":"","height":"40px","width":"100%","minWidth":"120px"},on:{"focus":function($event){return _vm.initGetPatients()},"input":function($event){return _vm.initGetPatients()}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('AppIcon',{attrs:{"icon":"search","fill":_vm.$vuetify.breakpoint.lgAndUp
                        ? _vm.$vuetify.theme.themes.light.primary
                        : _vm.$vuetify.theme.themes.light.white}})]},proxy:true}],null,true),model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)]}}],null,false,1469538221)},[_c('div',{staticClass:"ibow2-header-submenu",style:({ width: '100%' })},[_c('v-row',{staticClass:"ibow2-header-submenu-header justify-space-between pa-4 pb-2",attrs:{"no-gutters":""}},[_c('span',[_vm._v("利用者一覧")]),_c('AppToolTip',{attrs:{"bottom":"","addContentClass":"z-index--high"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('AppIcon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"help","fill":_vm.$vuetify.theme.themes.light.greyLight}},'AppIcon',attrs,false),on))]}}],null,false,1415742556)},[_c('span',[_vm._v("所属する主たる事業所及びサテライト(未所属の本社職員は全事業所)の現在有効な利用者がふりがな の 50音順で表示されます。"),_c('br'),_vm._v("利用者一覧画面の絞り込みをリセットして、利用者名順-昇順（ふりがな優先）にした時と同じです。")])]),_c('v-spacer'),_c('AppIcon',{attrs:{"icon":"close"},on:{"click":_vm.closeSearchAndFavoriteMenu}})],1),(_vm.needEmptyMessage)?_c('p',{staticClass:"mb-0 px-2 pt-2"},[_vm._v(" 利用者が見つかりませんでした。 ")]):_vm._e(),_c('v-list',{staticClass:"py-1"},_vm._l((_vm.favoriteAttachedPatients),function(patientInfo){return _c('v-list-item',{key:("p_" + (patientInfo.patient_id)),staticClass:"ibow2-header-submenu-item ma-0 py-3",on:{"click":function($event){return _vm.redirectPatientTop(patientInfo.patient_id)}}},[_c('v-list-item-action',{staticClass:"ma-0"},[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(patientInfo.patient_name)+" ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.onClickStar(patientInfo)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(patientInfo.is_favorited ? "mdi-star" : "mdi-star-outline")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(patientInfo.is_favorited ? "お気に入り解除" : "お気に入り登録"))])])],1)}),1),_c('InfiniteLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.page),expression:"page"}],ref:"infiniteLoading",attrs:{"spinner":"spiral"},on:{"infinite":_vm.fetchFavoritedPatients}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"})])],1)]):_vm._e(),(!_vm.is_visit)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-click":_vm.closeFavoriteMenuOnClickOutside,"close-on-content-click":_vm.closeFavoriteMenuOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""},on:{"click":_vm.fetchFavoritePatients}},on),[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',{attrs:{"size":"28","color":_vm.$vuetify.breakpoint.lgAndUp ? 'primary' : 'white'}},[_vm._v(" mdi-star ")])],1)],1)]}}],null,false,1053626328)},[_c('div',{staticClass:"ibow2-header-submenu",style:({ width: '250px' })},[_c('v-row',{staticClass:"ibow2-header-submenu-header justify-space-between pa-4 pb-2",attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v("お気に入り利用者")]),_c('AppIcon',{attrs:{"icon":"close"},on:{"click":_vm.closeSearchAndFavoriteMenu}})],1),(_vm.needEmptyMessage)?_c('p',{staticClass:"mb-0 px-2 pt-2"},[_vm._v(" お気に入り利用者が見つかりませんでした。 ")]):_vm._e(),_c('v-list',{staticClass:"py-1"},_vm._l((_vm.favoritedPatients),function(favoritePatient){return _c('v-list-item',{key:("favp_" + (favoritePatient.patient_id)),staticClass:"ibow2-header-submenu-item ma-0 py-3",on:{"click":function($event){return _vm.redirectPatientTop(favoritePatient.patient_id)}}},[_c('v-list-item-action',{staticClass:"ma-0"},[_c('div',{staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(favoritePatient.patient_name)+" ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.onClickStar(favoritePatient)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-star ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(favoritePatient.is_favorited ? "お気に入り解除" : "お気に入り登録"))])])],1)}),1)],1)]):_vm._e(),(_vm.isShownChatButton)?_c('div',{staticClass:"ibow2-header-chat"},[(_vm.isShownChatBadge)?_c('div',{staticClass:"ibow2-header-chat-notice-badge rounded-circle ma-0"}):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleChatOpen()}}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.breakpoint.lgAndUp ? 'primary' : 'white'}},[_vm._v("mdi-chat-processing")])],1)],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-icon',{attrs:{"size":"32","color":_vm.$vuetify.breakpoint.lgAndUp ? 'primary' : 'white'}},[_vm._v(" mdi-account ")])],1)],1)]}}])},[_c('v-list',{staticClass:"ibow2-header-submenu py-1"},[(_vm.CanMypage)?_c('v-list-item',{staticClass:"ibow2-header-submenu-item ma-0 py-3",on:{"click":function($event){return _vm.toMypage()}}},[_c('v-list-item-action',{staticClass:"ma-0"},[_vm._v(" マイページ ")])],1):_vm._e(),_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [(_vm.officeStaffs.length > 1)?_c('v-list-item',_vm._g({staticClass:"ibow2-header-submenu-item ma-0 py-3"},on),[_c('v-list-item-action',{staticClass:"ma-0"},[_vm._v(" 事業所・権限変更 ")])],1):_vm._e()]}}])},[_c('v-card',{staticClass:"ibow2-header-submenu"},[_c('v-list-item-content',{staticClass:"py-1"},_vm._l((_vm.officeStaffs),function(staff){return _c('v-list-item',{key:staff.id,staticClass:"ibow2-header-submenu-item ma-0 py-3",class:_vm.selectStaff(staff.id) ? 'select-active' : '',on:{"click":function($event){return _vm.clickSelect(staff.id)}}},[_c('v-list-item-action',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(staff.text)+" ")])],1)}),1)],1)],1),_c('v-list-item',{staticClass:"ibow2-header-submenu-item ma-0 py-3",on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-action',{staticClass:"ma-0"},[_vm._v(" ログアウト ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }