import { MutationTree } from "vuex";
import {
  ChatState,
  AccountImage,
  SET_ACCOUNT_IMAGE_PATH,
  SET_FETCHING_ACOUNT_ID,
  DELETE_FETCHING_ACCOUNT_ID,
  SET_AGREEMENTS,
  Agreement
} from "#/store/types";

export const mutations: MutationTree<ChatState> = {
  [SET_ACCOUNT_IMAGE_PATH](state, accountImage: AccountImage) {
    const index = state.accountImages.findIndex(image => {
      image.accountId === accountImage.accountId;
    });

    if (index >= 0) {
      state.accountImages.splice(index, 1, accountImage);
    } else {
      state.accountImages.push(accountImage);
    }
  },
  [SET_FETCHING_ACOUNT_ID](state, accountId: number) {
    const isIncluded = state.fetchingAccountIds.includes(accountId);

    if (!isIncluded) {
      state.fetchingAccountIds.push(accountId);
    }
  },
  [DELETE_FETCHING_ACCOUNT_ID](state, accountId: number) {
    const index = state.fetchingAccountIds.findIndex(id => {
      return id === accountId;
    });

    if (index >= 0) {
      state.fetchingAccountIds.splice(index, 1);
    }
  },
  [SET_AGREEMENTS](state, agreements: Agreement[]) {
    state.enabledAgreements = agreements;
  }
};
