import {
  OwnExpenseTemplate,
  DefaultOwnExpenseTemplate
} from "./ownExpenseTemplate";

// 自費
export interface OwnExpense extends OwnExpenseTemplate {
  visit_record_id: number; //'訪問記録ID',
  yearmonth: string; //'請求月',
  visit_date: string; //'訪問日',
  VisitDateWareki: string; //訪問日(和暦)
  is_edited?: number; //編集済みフラグ
}

export const DefaultOwnExpense = (): OwnExpense => ({
  ...DefaultOwnExpenseTemplate(),
  visit_record_id: 0,
  yearmonth: "",
  visit_date: "",
  VisitDateWareki: ""
});

// 自費アイテムChoice
export interface OwnExpenseItemChoice {
  text: string;
  value: number | string;
  own_expense_category_id: number; //'自費カテゴリID',
  amount: number; //'単価',
  tax_div: number; //'課税区分\n1:内税(10%)\n2:外税(10%)\n3:非課税\n4:内税(8%)\n5:外税(8%)',
  is_medical_deducation: number; //'医療費控除フラグ',
  code: string; //'自費項目コード',
  remarks: string; //'備考',
  sort_no: number; //'並び順',
}

export const DefaultOwnExpenseItemChoice = (): OwnExpenseItemChoice => ({
  text: "",
  value: 0,
  own_expense_category_id: 0,
  amount: 0,
  tax_div: 0,
  is_medical_deducation: 0,
  code: "",
  remarks: "",
  sort_no: 0
});
