







































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { PatientSituation, DefaultPatientSituation } from "#/model/patient";
import PatientDoctorSelect from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {
    PatientDoctorSelect
  }
})
export default class VisitPlanSituation extends Mixins(UtilMixin, RulesMixin) {
  @Prop({ default: () => DefaultPatientSituation() })
  patientSituation!: PatientSituation;
}
