














































































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Office } from "#/model/agreement";
import OfficeShiftTypeEntry from "@/components/shift/OfficeShiftTypeEntry.vue";
import SelectYearMonthDialog from "@/components/master/office/SelectYearMonthDialog.vue";
import CarelogoodConfigDialog from "@/components/master/office/CarelogoodConfigDialog.vue";

interface OfficeCarelog extends Office {
  is_carelog: number; // ケアログ事業所かどうか
}

@Component({
  components: {
    OfficeShiftTypeEntry,
    SelectYearMonthDialog,
    CarelogoodConfigDialog
  }
})
export default class PatientList extends Mixins(AxiosMixin) {
  /* 変数 */
  // 事業所シフト編集ダイアログ
  private dialog = false;
  // 勤怠シフト連携ダイアログ
  private linkShiftKintaiDialog = false;
  /** けあログっと設定ダイアログ */
  private carelogoodDialog = false;
  // 検索テキスト
  private search = "";
  // 選択事業所ID
  private selectedOfficeId = 0;
  // 選択事業所名
  private selectedOfficeName = "";
  // ケアログ事業所かどうか
  private isCarelog = 0;
  // テーブルヘッダ
  private headers = [
    {
      text: "事業所名",
      align: "start",
      sortable: false,
      value: "office_name"
    },
    {
      text: "サテライト",
      align: "start",
      sortable: false,
      value: "satelite_info"
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "end",
      width: "200px"
    }
  ];
  // 事業所一覧
  private offices: Office[] = [];

  /* 関数 */
  public created(): void {
    this.fetchOfficeList();
  }

  private fetchOfficeList() {
    this.postJsonCheck(window.base_url + "/api/master/station/get", {}, res => {
      this.offices = res.data.offices;
    });
  }

  private itemClass(item: { is_working: boolean }) {
    let itemClass = "";
    if (item.is_working == false) {
      itemClass = "grey lighten-2";
    }
    return itemClass;
  }

  /** 事業所シフト編集 */
  private editShiftTemplate({ id }: Office) {
    this.selectedOfficeId = id;
    this.dialog = true;
  }

  /** 勤怠シフト一括取込 */
  private linkShiftKintai({ id }: Office) {
    this.selectedOfficeId = id;
    this.linkShiftKintaiDialog = true;
  }

  /** けあログっと設定 */
  private carelogoodConfig({ id, office_name, is_carelog }: OfficeCarelog) {
    this.selectedOfficeId = id;
    this.selectedOfficeName = office_name;
    this.isCarelog = is_carelog;
    this.carelogoodDialog = true;
  }

  /** けあログっとボタン表示設定 */
  private isDisplayCarelog(item: Office) {
    if (
      !item.satelite_info &&
      (window.display_carelogood_ageement_id == 0 ||
        item.agreement_id == window.display_carelogood_ageement_id)
    ) {
      return true;
    }
    return false;
  }

  // 編集ボタン
  private clickEdit(office: Office) {
    const route = {
      name: "MastermaintenanceOfficeEdit",
      params: {
        id: String(office.id)
      },
      query: { satelite: "" }
    };
    if (office.satelite_info) {
      route.query.satelite = "true";
    }
    this.$router.push(route);
  }
}
