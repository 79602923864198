

































































































































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { Choice, ChoiceAddOther, VForm } from "@/types";
import { IsurerMaster } from "@/components/patient/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import DateInput from "#/components/DateInput.vue";
import { MedicalInsurance, DefaultMedicalInsurance } from "#/model/patient";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";
import { TargetKind } from "@/const/ocr";
import { PATIENT } from "#/const";

const Ocr = namespace(ocr.name);

@Component({
  components: {
    PatientHeader,
    DateInput
  }
})
export default class InsuranceInfoMed extends Mixins(
  AxiosMixin,
  RulesMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Getter("OcrState") ocrState!: ocr.OcrState;

  private readonly PATIENT = PATIENT;

  private insMedId = 0;
  private patientId = 0;
  private mode = "";
  private underSixYears = false; //法的年齢が6歳未満\n6歳未満の場合:true\6歳以上の場合:false
  private isLoaded = false;

  private insurance: MedicalInsurance = DefaultMedicalInsurance(); //医療保険
  private searchInsurer = 0; // 保険者検索v-model
  private isurerMaster: IsurerMaster[] = []; // 保険者マスター
  /** 分離前の旧「記号・番号」値が存在するかどうか */
  private existsDeprecatedInsuredNo = false;

  created() {
    this.insMedId = Number(this.$route.params.insmedid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchInsurerMed();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=Med`
      },
      {
        text: "医療保険",
        disabled: true,
        to: ""
      }
    ]);
  }

  destroy() {
    this.clearOcrState();
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //登録
  private async save() {
    //被保険者記号・番号合計桁数バリデーション
    if (this.InsuerdSignNumber.length > 19) {
      await this.$openAlert(
        "記号・番号の桁数は合わせて19桁以下で入力してください。"
      );
      return;
    }
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    //保険種類が社保・国保・退職者で、枝番を入力していない場合
    if (
      [1, 2, 5].includes(this.insurance.insurance_type) &&
      this.insurance.insured_branch === ""
    ) {
      if (
        !(await this.$openConfirm(
          "枝番がある場合は必ず入力してください。令和6年の医療伝送の開始以降、社保・国保・退職者では必須となる可能性があります。\n\nこのまま登録してもよろしいですか？"
        ))
      ) {
        return;
      }
    }
    if (this.IsWarnNotHighExpense) {
      const msg = "高額療養費を登録できない内容です。よろしいですか？";
      if (!(await this.$openConfirm(msg))) {
        return;
      }
    }
    if (
      this.IsOver70Age &&
      !this.IsOver75Age &&
      (this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_SOCIAL ||
        this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_NATIONAL) &&
      !this.insurance.is_old_age_certificate
    ) {
      // 70歳以上75歳未満で、国保または社保、かつ兼高齢OFFの場合は、その内容で合っているか確認する
      const msg =
        "高齢受給者証の登録を引き続き行ってください。もし高齢受給者証一体型の保険証の場合は、「兼高齢受給者証」スイッチをONにしてください。";
      if (!(await this.$openConfirm(msg))) {
        return;
      }
    }

    if (this.mode) {
      this.insurance.id = 0;
    }

    // 後期の場合は記号、枝番をクリア
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE) {
      this.insurance.insured_sign = "";
      this.insurance.insured_branch = "";
    }

    this.insurance.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/med/save",
      {
        insurance: this.insurance
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        // OCR 結果から作成成功した場合は OCR ステータスを「作成済み」にする
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.applyOcr();
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  private applyOcr() {
    if (this.ocrState.documentType === 0 || this.ocrState.documentId === 0) {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/ocrstore/mapping/apply",
      {
        document_type: this.ocrState.documentType,
        document_id: this.ocrState.documentId
      },
      () => {
        // do nothing
      }
    );
  }

  //変更
  private update() {
    this.insurance.id = this.insMedId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.insurance.id = this.insMedId;
      this.insurance.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/med/delete",
        {
          insurance: this.insurance
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  /** 終了日時点で65歳以上か */
  private get IsOver65Age() {
    return this.isOverAge(this.insurance.end_date, 65);
  }
  /** 終了日時点で70歳以上か */
  private get IsOver70Age() {
    return this.isOverAge(this.insurance.end_date, 70);
  }
  /** 終了日時点で75歳以上か */
  private get IsOver75Age() {
    return this.isOverAge(this.insurance.end_date, 75);
  }

  /** 高額療養費を作成できない内容というアラートを表示するか */
  private get IsWarnNotHighExpense() {
    if (!this.IsOver70Age || this.IsOver75Age) {
      // 70歳未満と75歳以上は対象外
      return false;
    }
    // 医療保険の内容次第でメッセージを表示する
    return (
      (this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_SOCIAL ||
        this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_NATIONAL) &&
      this.insurance.is_old_age_certificate &&
      this.insurance.payment_rate === 90
    );
  }

  // 保険者一覧作成
  private get IsurerItems() {
    return this.isurerMaster.map(item => ({
      value: item.id,
      text: `${item.number}/${item.name} (${item.type})`
    }));
  }

  // 保険者検索イベント
  public async changeSearchInsurer(id: number) {
    const info = this.IsurerItems.filter(item => item.value == id)[0];
    if (this.insurance.insurer_no == "" && this.insurance.insurer_name == "") {
      this.inputSearchInsurer(id);
    } else if (
      await this.$openConfirm(`保険者情報を"${info.text}"で置き換えますか？`)
    ) {
      this.inputSearchInsurer(id);
    }
  }

  //保険者検索入れ込み
  private inputSearchInsurer(id: number) {
    const master = this.isurerMaster.filter(item => item.id == id)[0];
    // 自動フィルイン
    this.insurance.insurer_no = master.number; // 保険者番号
    this.insurance.insurer_name = master.name; // 保険者名
  }

  //医療保険情報取得
  private fetchInsurerMed(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/med/get",
      {
        patient_id: this.patientId,
        ins_med_id: this.insMedId
      },
      res => {
        this.isurerMaster = res.data.insurer;
        //医療保険情報
        if (this.insMedId != 0) {
          const insurance: MedicalInsurance = res.data.medical_insurance;
          if (this.mode) {
            insurance.start_date = "";
            insurance.end_date = "";
            insurance.insured_no = "";
            //特例1割は廃止された制度なので、コピー元がONでもOFFにする
            insurance.is_exception10 = 0;
          }
          insurance.honobono_link_id = 0;
          this.insurance = insurance;
        }
        this.underSixYears = res.data.under_six_years;

        // OCR 結果から作成の場合、初期値設定
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.setOcrParams();
        }
        //高額療養費自動作成のON・OFF制御
        this.highExpenseOnOff();
        //記号・番号が未入力の場合は、記号・番号の分割登録がされたとする（分割前の情報がある場合は、表示する）
        this.existsDeprecatedInsuredNo =
          this.insurance.insured_no != null && this.insurance.insured_no != "";

        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  private setOcrParams() {
    // 一般
    if (this.ocrState.targetKind === TargetKind.mediInsGeneral) {
      this.insurance.insured_sign = this.ocrState.mediIns.insuredSign;
      this.insurance.insured_number = this.ocrState.mediIns.insuredNumber;
      this.insurance.insurer_no = this.ocrState.mediIns.insurerNumber;
      this.insurance.insurer_name = this.ocrState.mediIns.insurerName;
      this.insurance.first_issue_date = this.ocrState.mediIns.firstIssueDate;
    }
    // 国保
    if (this.ocrState.targetKind === TargetKind.mediInsNational) {
      this.insurance.insurance_type = PATIENT.INSURANCE_TYPE_NATIONAL; //国保
      this.insurance.insured_sign = this.ocrState.mediIns.insuredSign;
      this.insurance.insured_number = this.ocrState.mediIns.insuredNumber;
      this.insurance.insured_branch = this.ocrState.mediIns.insuredBranch;
      this.insurance.insurer_no = this.ocrState.mediIns.insurerNumber;
      this.insurance.insurer_name = this.ocrState.mediIns.insurerName;
      this.insurance.first_issue_date = this.ocrState.mediIns.firstIssueDate;
      this.insurance.start_date = this.ocrState.mediIns.startDate;
      this.insurance.end_date = this.ocrState.mediIns.endDate;
      this.insurance.payment_rate = this.ocrState.mediIns.paymentRate;
    }
    // 後期高齢
    if (this.ocrState.targetKind === TargetKind.mediInsOldOld) {
      this.insurance.insurance_type = PATIENT.INSURANCE_TYPE_NATIONAL; //後期高齢
      this.insurance.insured_number = this.ocrState.mediIns.insuredNumber;
      this.insurance.insurer_no = this.ocrState.mediIns.insurerNumber;
      this.insurance.insurer_name = this.ocrState.mediIns.insurerName;
      this.insurance.first_issue_date = this.ocrState.mediIns.firstIssueDate;
      this.insurance.start_date = this.ocrState.mediIns.startDate;
      this.insurance.end_date = this.ocrState.mediIns.endDate;
      this.insurance.payment_rate = this.ocrState.mediIns.paymentRate;
      switch (this.insurance.payment_rate) {
        case 70:
          this.insurance.insured_div = PATIENT.INSURED_DIV_OLDSEVEN; //高齢7
          break;
        case 80:
        case 90:
          this.insurance.insured_div = PATIENT.INSURED_DIV_OLD; //高齢一
          break;
      }
    }
  }

  //-----------------------------------------------------
  // Watch表示切り替え
  //-----------------------------------------------------
  //編集モード設定
  @Watch("insurance", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  //保険種類：被保険者区分切り替え（”公費単独””後期”以外切り替えなし）
  @Watch("insurance.insurance_type")
  private handleInsuranceType() {
    if (!this.isLoaded) {
      return;
    }
    for (const insTypes of this.masters.insurance_types_switching_insured_div) {
      if (this.insurance.insurance_type == insTypes.value) {
        if (insTypes.other == -1) {
          break;
        }
        this.insurance.insured_div = insTypes.other;
      }
    }
    //公費単独を選択した時、給付割合を10割にする
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_PUBLIC) {
      this.insurance.payment_rate = 100;
    }
    //後期高齢の場合、兼高齢の選択は無効なのでOFFにする
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE) {
      this.insurance.is_old_age_certificate = 0;
    }

    //高額療養費自動作成のON・OFFとdisable制御
    this.highExpenseOnOff();
    //被保険者区分アラートや給付率自動選択をする
    this.handleInsuranceDiv();
  }

  //被保険者区分：年齢アラート・給付割合切り替え
  @Watch("insurance.insured_div")
  private async handleInsuranceDiv() {
    if (!this.isLoaded) {
      return;
    }

    //公費単独の場合アラートなし
    if (
      this.insurance.insurance_type !== PATIENT.INSURANCE_TYPE_PUBLIC &&
      this.insurance.insured_div !== 0
    ) {
      const isLate =
        this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_LATE;
      if (
        this.underSixYears &&
        this.insurance.insured_div != PATIENT.INSURED_DIV_SIX
      ) {
        //法的年齢が6歳未満で「6歳」以外を選択している場合
        await this.$openAlert(
          "利用者の年齢と本人/家族の選択値が合っておりませんのでご確認ください"
        );
      } else if (
        !this.underSixYears &&
        this.insurance.insured_div == PATIENT.INSURED_DIV_SIX
      ) {
        //法的年齢が6歳以上で「6歳」を選択している場合（「6歳」の保険は法的年齢が6歳未満に適応される）
        await this.$openAlert(
          "利用者の年齢と本人/家族の選択値が合っておりませんのでご確認ください"
        );
      } else if (
        this.IsOver70Age &&
        this.insurance.insured_div != PATIENT.INSURED_DIV_OLD &&
        this.insurance.insured_div != PATIENT.INSURED_DIV_OLDSEVEN
      ) {
        //70歳以上で「高齢一」「高齢7」以外を選択している場合
        await this.$openAlert(
          "利用者の年齢と本人/家族の選択値が合っておりませんのでご確認ください"
        );
      } else if (
        (isLate ? !this.IsOver65Age : !this.IsOver70Age) &&
        (this.insurance.insured_div == PATIENT.INSURED_DIV_OLD ||
          this.insurance.insured_div == PATIENT.INSURED_DIV_OLDSEVEN)
      ) {
        //70歳未満(後期は65歳未満)で「高齢一」「高齢7」を選択している場合
        await this.$openAlert(
          "利用者の年齢と本人/家族の選択値が合っておりませんのでご確認ください"
        );
      }
    }
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_PUBLIC) {
      this.insurance.payment_rate = 100;
    } else if (this.insurance.is_exception10 == 1) {
      this.insurance.payment_rate = 80;
    } else {
      for (const insDivs of this.masters.insured_divs_switching_rate) {
        if (this.insurance.insured_div == insDivs.value) {
          this.insurance.payment_rate = insDivs.other;
        }
      }
    }

    //高額療養費自動作成のON・OFFとdisable制御
    this.highExpenseOnOff();
  }

  //兼高齢受給者証：onにした時に年齢チェック offにした時に未選択に戻す
  @Watch("insurance.is_old_age_certificate")
  private async handleIsOldAgeCertificate() {
    if (!this.isLoaded) {
      return;
    }
    if (this.insurance.is_old_age_certificate) {
      if (!this.IsOver70Age || this.IsOver75Age) {
        if (
          await this.$openConfirm(
            "兼高齢受給者証の年齢を満たしていませんが、よろしいですか？"
          )
        ) {
          return;
        }
        this.$nextTick(() => {
          this.insurance.is_old_age_certificate = 0;
        });
      }
    } else {
      this.insurance.payment_rate = 0;
    }
  }

  //減免:offにした時に未選択に戻す
  @Watch("insurance.is_exempt")
  private async handleIsExempt() {
    if (!this.isLoaded) {
      return;
    }
    if (!this.insurance.is_exempt) {
      this.insurance.payment_rate = 0;
    }
  }

  //特例1割：給付割合切り替え
  @Watch("insurance.is_exception10")
  private handleIsException10() {
    if (!this.isLoaded) {
      return;
    }
    if (this.insurance.is_exception10 == 1) {
      this.insurance.payment_rate = 80;
      return;
    }
  }

  //給付割合：特例1割の場合、給付割合8割以外選択時アラート
  @Watch("insurance.payment_rate")
  private async handlePaymentRate() {
    if (!this.isLoaded) {
      return;
    }
    if (
      this.insurance.is_exception10 == 1 &&
      this.insurance.payment_rate != 80
    ) {
      await this.$openAlert("特例1割が選択されています");
    }

    //高額療養費自動作成のON・OFFとdisable制御
    this.highExpenseOnOff();
  }

  //高額療養費自動作成のON・OFFとdisable制御
  private highExpenseOnOff() {
    //2024年2月21日リリースより前に作られた医療保険は、自動作成の対象外
    if (
      this.insurance.id > 0 &&
      !this.mode &&
      this.IS_MAKE_CHAIN_HIGHEXPENSE_FROM > this.insurance.created_at
    ) {
      this.insurance.is_make_high_expense = 0;
      return;
    }
    //後期高齢以外で、かつ兼高齢OFFの場合、高額療養費は作らない
    if (
      this.insurance.insurance_type !== PATIENT.INSURANCE_TYPE_LATE &&
      !this.insurance.is_old_age_certificate
    ) {
      this.insurance.is_make_high_expense = 0;
      return;
    }

    let on = false; //自動作成のON・OFF

    //高齢7 && 7割
    const isAgedSeven7 =
      this.insurance.insured_div == PATIENT.INSURED_DIV_OLDSEVEN &&
      this.insurance.payment_rate == 70;
    //高齢一 && 8割
    const isAgedOne8 =
      this.insurance.insured_div == PATIENT.INSURED_DIV_OLD &&
      this.insurance.payment_rate == 80;
    //高齢一 && 9割
    const isAgedOne9 =
      this.insurance.insured_div == PATIENT.INSURED_DIV_OLD &&
      this.insurance.payment_rate == 90;

    //国保・社保 && 高齢7 && 7割
    //国保・社保 && 高齢一 && 8割
    if (
      this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_SOCIAL ||
      this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_NATIONAL
    ) {
      on = isAgedSeven7 || isAgedOne8;
    }

    //後期 && 高齢7 && 7割
    //後期 && 高齢一 && 8割
    //後期 && 高齢一 && 9割
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE) {
      on = isAgedSeven7 || isAgedOne8 || isAgedOne9;
    }

    //高額療養費を作成するか制御
    this.insurance.is_make_high_expense = +on;
  }

  //-----------------------------------------------------
  // プルダウン項目表示切り替え
  //-----------------------------------------------------
  //被保険者区分プルダウン：保険種類によって切り替え
  private get InsuranceDivsItems(): ChoiceAddOther[] {
    //公費単独
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_PUBLIC) {
      return this.masters.insured_divs_public_alone;
    }
    //後期
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE) {
      return this.masters.insured_divs_latter_period;
    }
    //上記以外
    return this.masters.insured_divs_switching_rate;
  }

  //給付割合プルダウン：被保険者区分と減免によって切り替え
  private get PaymentRatesItems(): Choice[] {
    //減免にチェックありの場合は、7割・8割・9割・10割を表示
    if (this.insurance.is_exempt == 1) {
      return this.masters.payment_rates;
    }

    //本人 && 減免にチェックなし
    if (
      this.insurance.insured_div == PATIENT.INSURED_DIV_SELF &&
      this.insurance.is_exempt == 0
    ) {
      if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_PUBLIC) {
        return this.masters.payment_rates;
      }
      return this.masters.payment_rates_self_and_non_check;
    }
    //家族 && 減免にチェックなし
    if (
      this.insurance.insured_div == PATIENT.INSURED_DIV_FAMILY &&
      this.insurance.is_exempt == 0
    ) {
      return this.masters.payment_rates_family_and_non_check;
    }
    //6歳 && 減免にチェックなし
    if (
      this.insurance.insured_div == PATIENT.INSURED_DIV_SIX &&
      this.insurance.is_exempt == 0
    ) {
      return this.masters.payment_rates_age6_and_non_check;
    }
    //高齢一 && 減免にチェックなし
    if (
      this.insurance.insured_div == PATIENT.INSURED_DIV_OLD &&
      this.insurance.is_exempt == 0
    ) {
      return this.masters.payment_rates_old_age1_and_non_check;
    }
    //高齢7 && 減免にチェックなし
    if (
      this.insurance.insured_div == PATIENT.INSURED_DIV_OLDSEVEN &&
      this.insurance.is_exempt == 0
    ) {
      return this.masters.payment_rates_old_age7_and_non_check;
    }
    //上記以外
    return this.masters.payment_rates;
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //保険者番号：必須切り替え
  private requiredInsurerNo(): boolean | string {
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_PUBLIC) {
      return true;
    }
    return this.required(this.insurance.insurer_no, "必須です");
  }
  //保険者番号：先頭2桁の番号チェック
  private checkInsurerNoAcronym(): boolean | string {
    //社保
    if (
      this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_SOCIAL &&
      this.insurance.insurer_no.length > 0
    ) {
      const checkNo = [
        "01",
        "02",
        "03",
        "04",
        "06",
        "07",
        "31",
        "32",
        "33",
        "34",
        "63",
        "72",
        "73",
        "74",
        "75"
      ];
      const found = checkNo.find(
        elem => elem == this.insurance.insurer_no.slice(0, 2)
      );
      if (found == undefined) {
        return "社保の場合は先頭2桁が[01,02,03,04,06,07,31,32,33,34,63,72,73,74,75]のいずれかである必要があります";
      }
    }
    //後期
    if (
      this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE &&
      this.insurance.insurer_no.length > 0
    ) {
      const checkNo = ["39"];
      const found = checkNo.find(
        elem => elem == this.insurance.insurer_no.slice(0, 2)
      );
      if (found == undefined) {
        return "後期の場合は先頭2桁が[39]である必要があります";
      }
    }
    //退職者
    if (
      this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_RETIRE &&
      this.insurance.insurer_no.length > 0
    ) {
      const checkNo = ["67"];
      const found = checkNo.find(
        elem => elem == this.insurance.insurer_no.slice(0, 2)
      );
      if (found == undefined) {
        return "退職者の場合は先頭2桁が[67]である必要があります";
      }
    }
    return true;
  }

  //保険者名：必須切り替え
  private requiredInsurerName(): boolean | string {
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_PUBLIC) {
      return true;
    }
    return this.required(this.insurance.insurer_name, "必須です");
  }

  //記号・番号：必須切り替え
  private requiredInsuredSign(): boolean | string {
    return true; //記号・番号の分割で必須ではない。
  }
  private requiredInsuredNumber(): boolean | string {
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_PUBLIC) {
      return true;
    }
    return this.required(this.insurance.insured_number, "必須です");
  }
  //被保険者番号ラベル
  private get InsuredNumberLabel(): string {
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE) {
      return "被保険者番号";
    } else {
      return "番号";
    }
  }
  //すこやかサン被保険者番号ラベル
  private get SukoyakaInsuredSignNumberLabel(): string {
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE) {
      return "すこやかサンCSV出力の被保険者番号";
    } else {
      return "すこやかサンCSV出力の記号・番号";
    }
  }
  //被保険者番号有効桁数
  private get InsuredNumberDigit(): number {
    if (this.insurance.insurance_type == PATIENT.INSURANCE_TYPE_LATE) {
      return 16;
    } else {
      return 19;
    }
  }
  //被保険者番号（記号・番号の合成）
  private get InsuerdSignNumber(): string {
    if (!this.insurance.insured_sign) {
      return `${this.insurance.insured_number}`;
    }
    if (!this.insurance.insured_number) {
      return `${this.insurance.insured_sign}`;
    }
    return `${this.insurance.insured_sign}・${this.insurance.insured_number}`;
  }

  //-----------------------------------------------------
  // 誕生日設定
  //-----------------------------------------------------
  /** 開始日を75歳誕生日に設定する */
  private setBirthday75() {
    const birthday = this.newDate(this.patientInfo.bday);
    birthday.setFullYear(birthday.getFullYear() + 75);
    this.insurance.start_date = this.dateToStr(birthday, "yyyy-MM-dd");
  }
  /** 終了日を誕生日前日に設定する */
  private setBeforeBirthday() {
    this.insurance.end_date = this.calcBeforeBirthday(
      this.insurance.start_date,
      this.patientInfo.bday
    );
  }

  //有効期限（開始日）：必須切り替え
  private requiredStartDate() {
    return (
      this.insurance.is_old_age_certificate === 1 ||
      this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_SOCIAL ||
      this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_NATIONAL ||
      this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_LATE
    );
  }

  //有効期限（終了日）：必須切り替え
  private requiredEndDate() {
    if (this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_SOCIAL) {
      return false; // 社保なら兼高齢に関係なく任意
    }
    return (
      this.insurance.is_old_age_certificate === 1 ||
      this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_NATIONAL ||
      this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_LATE ||
      this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_RETIRE
    );
  }

  /** 資格取得年月日チェック */
  private checkFirstIssueDate(): boolean | string {
    if (this.insurance.start_date !== "") {
      if (this.insurance.first_issue_date > this.insurance.start_date) {
        return "資格取得年月日は有効期限(開始日)以前を入力してください";
      }
    } else if (this.insurance.end_date !== "") {
      if (this.insurance.first_issue_date > this.insurance.end_date) {
        return "資格取得年月日は有効期限(終了日)以前を入力してください";
      }
    }
    return true;
  }

  /** 開始日年齢チェック */
  private checkOldAgeStart(): boolean | string {
    if (this.insurance.is_old_age_certificate && this.insurance.start_date) {
      return this.checkOldAge(
        this.insurance.start_date,
        "開始日は70歳以上から75歳未満になる日を入力してください"
      );
    }
    return true;
  }

  /** 終了日年齢チェック */
  private checkOldAgeEnd(): boolean | string {
    if (this.insurance.is_old_age_certificate && this.insurance.end_date) {
      return this.checkOldAge(
        this.insurance.end_date,
        "終了日は70歳以上から75歳未満になる日を入力してください"
      );
    }
    return true;
  }

  /** 検索条件バリデーション：期間の開始日 */
  private checkStartDate(): boolean | string {
    const startDate = this.newDate(this.insurance.start_date);
    const endDate = this.newDate(this.insurance.end_date);
    //高額療養費を作成する場合
    if (this.insurance.is_make_high_expense) {
      const target = this.newDate("2018-08-01");
      if (target > startDate) {
        return "高額療養費への登録は有効期限（開始日）が平成30年8月1日(2018年8月1日)以降の場合に可能です";
      }
    }
    //開始日が終了日よりも後である場合
    if (startDate > endDate) {
      return "開始日は終了日よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了日 */
  private checkEndDate(): boolean | string {
    const startDate = this.newDate(this.insurance.start_date);
    const endDate = this.newDate(this.insurance.end_date);
    //終了日が開始日よりも前である場合
    if (startDate > endDate) {
      return "終了日は開始日よりも後に設定してください";
    }
    return true;
  }

  //後期高齢2割負担に対する配慮措置の有効判定
  private rulePayPer20(): boolean | string {
    if (this.insurance.is_pay_per20 !== 1) {
      return true;
    }
    // ONの場合のみ判定する。
    if (
      this.insurance.insurance_type === PATIENT.INSURANCE_TYPE_LATE && // 後期高齢
      this.insurance.insured_div === PATIENT.INSURED_DIV_OLD && // 高齢一
      this.insurance.payment_rate === 80 // 負担割合２割
    ) {
      return true;
    }
    return "配慮措置の条件に合わないため登録できません。";
  }
}
