











import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { InquiryFile } from "#/model/inquiry";

@Component({
  components: {}
})
export default class InquiryFileViewer extends Mixins(UtilMixin, AxiosMixin) {
  @Prop({ default: () => [] as InquiryFile[] }) inquiryFiles!: InquiryFile[];
}
