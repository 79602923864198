











































import { Component, Ref, Watch } from "vue-property-decorator";
import {
  CalendarDispEventMoving as CalendarDispEvent,
  DefaultEvent
} from "#/components/calendar/common";
import ScheduleAssistantVisitDates from "./ScheduleAssistantVisitDates";
import * as appDate from "#/utility/appDate";
import AppCalendarV2 from "#/components/AppCalendarV2.vue";

@Component({
  components: {
    AppCalendarV2
  }
})
export default class ScheduleAssistant extends ScheduleAssistantVisitDates {
  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: AppCalendarV2;

  /** 訪問開始時間変更検知 */
  @Watch("start_time") private changeStartTime() {
    this.scrollTime();
  }

  /** 訪問日変更 */
  @Watch("VisitDates") private watchVisitDates() {
    this.setDate();
    this.scrollTime();
    this.fetch();
  }

  /** 訪問者のシフト */
  private shiftEvents: CalendarDispEvent[] = [];
  /** 訪問者の訪問予定 */
  private visitEvents: CalendarDispEvent[] = [];
  /** 訪問者の職員予定 */
  private staffEvents: CalendarDispEvent[] = [];
  /** 利用者の訪問予定+個別予定 */
  private patientVisitEvents: CalendarDispEvent[] = [];
  /** 祝日 */
  private holidayEvents: CalendarDispEvent[] = [];
  /** 対象日付 */
  private focus = new Date();
  /** 有効日付一覧 */
  private dates: string[] = [];
  /** 有効日付番号 */
  private idxDate = 0;

  /** カレンダータイトル */
  private get CalendarTitle(): string {
    if (!(this.focus instanceof Date)) {
      this.focus = appDate.strToDate(this.focus);
    }
    return appDate.dateToStr(this.focus, "yyyy年MM月dd日");
  }

  /** カレンダーイベント */
  private get Events(): CalendarDispEvent[] {
    let events: CalendarDispEvent[] = [];
    if (this.VisitDates) {
      for (const visitDate of this.VisitDates) {
        if (visitDate.start_day && visitDate.end_day) {
          let start = visitDate.start_day;
          let end = visitDate.end_day;
          let name = "";
          if (!this.IsAll) {
            start += " " + this.StartTime;
            end += " " + this.EndTime;
            name = `${this.StartTime}〜${this.EndTime} `;
          } else {
            name = "終日 ";
          }
          name += "訪問（予定）";

          const dispEvent: CalendarDispEvent = {
            ...DefaultEvent(),
            type: 2,
            status: 0,
            class: "visit-set-color-event",
            style: "background-color: #ffffffCC; border-color: red",
            color: "red",
            name: name,
            name_toolchip: name,
            start: start,
            end: end,
            timed: !this.IsAll,
            is_repeat: false,
            category: "訪問予定",
            tmp_timed: !this.IsAll,
            is_not_moving: true
          };

          events.push(dispEvent);
        }
      }
    }
    if (this.shiftEvents && this.shiftEvents.length > 0) {
      events = events.concat(this.shiftEvents);
    }
    if (this.visitEvents && this.visitEvents.length > 0) {
      events = events.concat(this.visitEvents);
    }
    if (this.staffEvents && this.staffEvents.length > 0) {
      events = events.concat(this.staffEvents);
    }
    if (this.patientVisitEvents && this.patientVisitEvents.length > 0) {
      events = events.concat(this.patientVisitEvents);
    }
    if (this.holidayEvents && this.holidayEvents.length > 0) {
      events = events.concat(this.holidayEvents);
    }

    for (const event of events) {
      event.is_not_moving = true;
    }

    return events;
  }

  /** カレンダーカテゴリリスト */
  private get Categories(): string[] {
    const dispCategories: string[] = [];
    dispCategories.push("訪問予定");
    for (const staffId of this.Visitors) {
      dispCategories.push(String(staffId));
    }
    return dispCategories;
  }

  mounted() {
    this.scrollTime();
  }

  /** スクロール位置を設定する */
  private scrollTime() {
    const time = this.ScrollTime || "08:00";
    this.$nextTick(() => {
      if (this.calendar && this.calendar.scrollToTime) {
        this.calendar.scrollToTime(time);
      }
    });
  }

  /** 対象日付を設定する */
  private setDate() {
    this.idxDate = 0;
    this.dates = [];
    if (this.VisitDates && this.VisitDates.length > 0) {
      for (const visitDate of this.VisitDates) {
        if (visitDate.start_day && visitDate.end_day) {
          if (!this.dates.includes(visitDate.start_day)) {
            this.dates.push(visitDate.start_day);
          }
          if (!this.dates.includes(visitDate.end_day)) {
            this.dates.push(visitDate.end_day);
          }
        }
      }
      if (this.VisitDates[0].start_day) {
        this.focus = appDate.strToDate(this.VisitDates[0].start_day);
      }
    } else {
      this.focus = new Date();
    }
  }

  /** データを取得する */
  private fetch() {
    if (!this.visitSchedule.patient_id) {
      this.shiftEvents = [];
      this.visitEvents = [];
      this.staffEvents = [];
      this.patientVisitEvents = [];
      this.holidayEvents = [];
      return;
    }
    const targetDate = appDate.dateToStr(this.focus, "yyyy-MM-dd");
    this.postJsonCheck(
      window.base_url + "/api/schedule/staffevents/get",
      {
        target_date: targetDate,
        staff_ids: this.Visitors,
        patient_id: this.visitSchedule.patient_id,
        visit_schedule_id: this.visitSchedule.id,
        rule_id: this.visitSchedule.rule_id
      },
      res => {
        if (res.data) {
          this.shiftEvents = res.data.shift_events;
          this.visitEvents = res.data.visit_events;
          this.staffEvents = res.data.staff_calendar;
          this.patientVisitEvents = res.data.patient_visit_events;
          this.holidayEvents = res.data.holiday_events;
        }
      }
    );
  }

  /** 対象日付を前の日付にする */
  private prev() {
    if (this.dates[this.idxDate - 1]) {
      this.idxDate--;
      this.focus = appDate.strToDate(this.dates[this.idxDate]);
      this.scrollTime();
      this.fetch();
    }
  }

  /** 対象日付を次の日付にする */
  private next() {
    if (this.dates[this.idxDate + 1]) {
      this.idxDate++;
      this.focus = appDate.strToDate(this.dates[this.idxDate]);
      this.scrollTime();
      this.fetch();
    }
  }
}
