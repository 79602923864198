var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppDataTable',_vm._g(_vm._b({attrs:{"show-select":true,"item-key":"id","headers":_vm.headers,"items":_vm.patientShareFiles,"single-select":false,"isNoTop":true,"disable-pagination":_vm.disablePagination,"hide-default-footer":_vm.disablePagination,"backTotal":_vm.backTotal,"backPageItems":_vm.backPageItems},on:{"input":_vm.checked},scopedSlots:_vm._u([{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('AppToolTip',{attrs:{"right":"","top":false,"label":("“" + (item.patient_file.file_name) + "”を開く")},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"height":"35px","width":"50px","color":"primary","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.previewFile(item.patient_file.file_path)}}},'AppButton',attrs,false),on),[_vm._v(" 見る ")])]}}],null,true)})]}},{key:"item.ocr",fn:function(ref){
var item = ref.item;
return [(_vm.isOcrShown(item.ocr_meta))?_c('AppToolTip',{attrs:{"left":"","top":false,"label":"OCR画面を開く"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"mr-1",class:_vm.ocrStatusColor(item.ocr_meta.ocr_status.status),attrs:{"height":"35px","width":"88px","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.ocrAction(item)}}},'AppButton',attrs,false),on),[_vm._v(" "+_vm._s(_vm.ocrStatusText(item.ocr_meta.ocr_status.status))+" ")])]}}],null,true)}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('AppToolTip',{attrs:{"left":"","top":false,"label":("“" + (item.patient_file.file_name) + "”を編集する")},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"40px","width":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.editPatientShareFile(item)}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"edit","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)}),_c('AppToolTip',{attrs:{"left":"","top":false,"label":"有効期限のないリンクをクリップボードにコピーする"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"40px","width":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.copyPatientShareFile(item)}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"duplicate","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)})]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"d-flex justify-end",attrs:{"size":"30","color":_vm.getFileInfo(item.patient_file.file_name).iconColor}},[_vm._v(" "+_vm._s(_vm.getFileInfo(item.patient_file.file_name).icon)+" ")])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-n1 patient-file-chip"},_vm._l((item.tags),function(tag){return _c('v-chip',{key:tag.value,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(_vm._s(tag.text))])}),1)]}},{key:"item.office_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getText(item.office_id, _vm.masters.group_offices))+" ")]}},{key:"item.patient_name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.stopPropagation();return _vm.onClickPatient(item.patient_id)}}},[_vm._v(_vm._s(item.patient_name))])]}},{key:"item.patient_file.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient_file.updated_at.replace(/-/g, "/").slice(0, 16))+" ")]}},{key:"item.office_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"220px"}},[_vm._v(_vm._s(item.office_name))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'AppDataTable',_vm.$attrs,false),_vm.$listeners)),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }