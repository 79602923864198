























import { Component, Vue } from "vue-property-decorator";

interface History {
  id: number;
  start_date: string;
  end_date: string;
}

@Component
export default class StaffHistory extends Vue {
  // 履歴ヘッダ
  private headers = [
    {
      text: "適用開始日",
      value: "start_date",
      align: "start",
      sortable: false
    },
    {
      text: "適用終了日",
      value: "end_date",
      align: "start",
      sortable: false
    },
    {
      text: "",
      value: "actions",
      align: "end",
      sortable: false
    }
  ];

  private histories: History[] = [
    {
      id: 3,
      start_date: "R3年1月1日",
      end_date: "適用中"
    },
    {
      id: 2,
      start_date: "R2年1月1日",
      end_date: "R2年12月31日"
    },
    {
      id: 1,
      start_date: "R1年1月1日",
      end_date: "R1年12月31日"
    }
  ];

  private edit(item: History) {
    console.log(item);
    this.$router.push({ name: "StaffEdit" });
  }
}
