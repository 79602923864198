



















import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { Prop, Emit } from "vue-property-decorator";
import { FETCH_ACCOUNT_IMAGE_PATH } from "#/store/types";

@Component
export default class AppChatStaffListItem extends Vue {
  @Prop() staffName!: string;
  @Prop() accountId!: number;
  @Prop() officeName!: string;

  @Getter("AccountImagePath", { namespace: "chat" })
  accountImagePath!: (accountId: number) => string;

  @Action(FETCH_ACCOUNT_IMAGE_PATH, { namespace: "chat" })
  fetchAccountImagePath!: Function;

  @Emit()
  private select() {
    return;
  }

  created() {
    this.fetchAccountImagePath(this.accountId);
  }
}
