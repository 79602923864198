












import { Component, Mixins, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import { namespace } from "vuex-class";
import * as patient from "@/store/modules/patient/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";

const Patient = namespace(patient.name);

@Component({
  components: {
    PatientHeader
  }
})
export default class PatientBase extends Mixins(
  FireStoreMixin,
  UtilMixin,
  PatientMixin,
  AxiosMixin
) {
  @Patient.Mutation(patient.SET_PATIENT_MASTER) setPatientMaster!: Function;

  @Patient.Mutation(patient.SET_PATIENT_BASE) setPatientBase!: Function;

  /** 利用者ID */
  private patientId = "0";

  /** マスタデータ読み取り完了フラグ */
  private isLoadedMaster = true;

  /** 利用者ヘッダー */
  private pheader: Vue | Element | (Vue | Element)[] | undefined = [];

  private get NavLists() {
    const menus = this.patientMaster.patient_menus;
    for (const menu of menus) {
      if (menu.id === 6) {
        menu.count = this.reportCount.visit_record_not_count;
      }
      if (menu.id === 14) {
        menu.count = this.reportCount.vn_record_not_count;
      }
      if (menu.id === 15) {
        menu.count = this.reportCount.month_report_not_count;
      }
      if (menu.id === 16) {
        menu.count = this.reportCount.provision_report_not_count;
      }
      if (menu.id === 17) {
        menu.count = this.reportCount.vn_plan_not_count;
      }
      if (menu.id === 19) {
        menu.count = this.reportCount.service_report_not_count;
      }
      if (menu.id === 20) {
        menu.count = this.reportCount.bedsore_plan_not_count;
      }
      if (menu.id === 21) {
        menu.count = this.reportCount.care_summary_not_count;
      }
      if (menu.id === 22) {
        menu.count = this.reportCount.occur_report_not_count;
      }
      if (menu.id === 23) {
        menu.count = this.reportCount.conference_report_not_count;
      }
      if (menu.id === 9) {
        menu.count = this.reportCount.fulltime_not_read_count;
      }
    }
    return menus;
  }

  @Watch("$route") private watchRoute() {
    if (this.patientId != this.$route.params.id) {
      this.isLoadedMaster = false;
      this.patientId = this.$route.params.id;
      this.updateMaster();
    }
    this.updateBaseInfo();
  }

  created() {
    this.isLoadedMaster = false;
    this.patientId = this.$route.params.id;
    this.updateMaster();
    this.updateBaseInfo();
  }

  mounted() {
    this.pheader = this.$refs.pheader;
  }

  /** 利用者情報取得 */
  private updateBaseInfo() {
    this.postJsonBackground(
      window.base_url + "/api/patient/base/get",
      { patient_id: Number(this.patientId) },
      res => {
        if (res.data) {
          this.setPatientBase({ master: res.data });
          this.isLoadedMaster = true;
        }
      }
    );
  }

  /** 利用者マスタ情報取得 */
  private updateMaster() {
    this.postJsonBackground(
      window.base_url + "/api/patient/master/get",
      { patient_id: Number(this.patientId) },
      res => {
        if (res.data) {
          this.setPatientMaster({ master: res.data });
        }
      }
    );
  }
}
