import { MasmenState } from "./types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const state: MasmenState = {
  menus: [],
  is_agent: 0,
  is_nursing_category: 0,
  error: false
};

const namespaced = true;

export const masmen: Module<MasmenState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
