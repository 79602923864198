




import { Component, Mixins } from "vue-property-decorator";
import PatientMixin from "@/mixins/patientMixin";
import ReportsSearch from "@/components/report_list/ReportList.vue";

@Component({
  components: {
    ReportsSearch
  }
})
export default class PatientDocumentList extends Mixins(PatientMixin) {
  mounted() {
    this.setDefaultHeader();
  }
}
