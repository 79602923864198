











































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  RelationInstitution,
  DefaultRelationInstitution
} from "#/model/uniquemaster";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import InfoCoordInput from "@/components/master/info_coord/InfoCoordInput.vue";
import InfoCoordOutput from "@/components/master/info_coord/InfoCoordOutput.vue";
import { Choice } from "@/types";
import { DispRelationInstitutionInfoCoordUpload } from "@/views/master/info_coord/types";

@Component({
  components: {
    OfficeSelect,
    InfoCoordInput,
    InfoCoordOutput
  }
})
export default class InfoCoordIO extends Mixins(AxiosMixin, UtilMixin) {
  /** 入出力種類 0:入力 1:出力 */
  private ioType = 0;

  /** 関連機関情報 */
  private rInst: RelationInstitution = DefaultRelationInstitution();

  /** 主たる関連機関ID */
  private mainRInstId = 0;

  /** 主たる関連機関事業所番号 */
  private mainRInstOfficeCode = "";

  /** 出力用事業所選択肢情報 */
  private officeSelects: Choice[] = [];

  /** 入力用事業所選択肢情報 */
  private inputOfficeSelects: Choice[] = [];

  /** 取込ファイル情報 */
  private rInstInfoCoordUploads: DispRelationInstitutionInfoCoordUpload[] = [];

  /** 情報連携の標準仕様csv入力設定 */
  private use_info_coord_input = 0;

  /** 情報連携の標準仕様csv出力設定 */
  private use_info_coord_output = 0;

  /** 表示名 */
  private get title(): string {
    return `${this.rInst.name} (関連機関事業所番号/${this.mainRInstOfficeCode})`;
  }

  created() {
    this.fetchRelationInstitution();
  }

  /** 関連機関情報取得 */
  private fetchRelationInstitution() {
    const id = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/master/dataio/info_coord/get",
      { relation_institution_id: id },
      res => {
        if (res.data.relation_institution) {
          this.rInst = res.data.relation_institution;
          this.mainRInstId = res.data.main_rinst_id;
          this.mainRInstOfficeCode = res.data.main_rinst_office_code;
          this.officeSelects = res.data.office_selects;
          this.inputOfficeSelects = res.data.input_office_selects;
          this.rInstInfoCoordUploads =
            res.data.relation_institution_info_coord_uploads;
          this.use_info_coord_input = res.data.use_info_coord_input;
          this.use_info_coord_output = res.data.use_info_coord_output;
        }
      }
    );
  }

  /** 取込処理 */
  private inputCsv(obj: {
    office_id: number;
    file_name: string;
    file_path: string;
  }) {
    this.postJsonCheck(
      window.base_url + "/api/master/dataio/info_coord/input",
      {
        relation_institution_id: this.mainRInstId,
        office_id: obj.office_id,
        file_name: obj.file_name,
        file_path: obj.file_path
      },
      () => {
        this.fetchRelationInstitution();
      }
    );
  }

  /** 出力処理 */
  private outputCsv(obj: { selectedOffice: Choice; targetYearmonth: string }) {
    const now = new Date();
    const nowStr = this.dateToStr(now, "yyyyMMddHHmmss");
    const targetYearmonthStr = obj.targetYearmonth.replaceAll("-", "");
    const fileName = `UPJSK_${targetYearmonthStr}_${obj.selectedOffice.other_string}_${this.mainRInstOfficeCode}_${nowStr}.csv`;

    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/master/dataio/info_coord/output",
      {
        relation_institution: this.rInst,
        relation_institution_id: this.rInst.id,
        office_id: obj.selectedOffice.value,
        target_yearmonth: obj.targetYearmonth
      },
      res => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }

  /** 削除処理 */
  private removeCsv(item: DispRelationInstitutionInfoCoordUpload) {
    this.postJsonCheck(
      window.base_url + "/api/master/dataio/info_coord/delete",
      {
        relation_institution_info_coord_upload: item
      },
      () => {
        this.fetchRelationInstitution();
      }
    );
  }

  /** ファイルダウンロード */
  private preview(item: DispRelationInstitutionInfoCoordUpload) {
    this.postJsonBlobResCheck(
      window.base_url + "/api/master/dataio/info_coord/download",
      { file_path: item.file_path },
      res => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = item.file_name;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }
}
