import { Mixins, Component } from "vue-property-decorator";
import { State, Getter, namespace } from "vuex-class";
import * as appDate from "#/utility/appDate";
import * as report from "@/store/modules/patient/types";
import { ReportCount, RequesterChoice } from "@/types";
import { Detail, PatientDispInfo } from "@/components/patient/types";
import { Vital } from "#/model/report";
import UtilMixin from "@/mixins/utilMixin";

const Patient = namespace(report.name);
@Component
export default class PatientMixin extends Mixins(UtilMixin) {
  @State(report.name) patientState!: report.PatientState;

  @Getter("Doctors", { namespace: "patient" })
  Doctors!: RequesterChoice[];
  @Getter("MedicalInses", { namespace: "patient" })
  MedicalInses!: RequesterChoice[];
  @Getter("MedicalInsOnly", { namespace: "patient" })
  MedicalInsOnly!: RequesterChoice[];
  @Getter("Personnels", { namespace: "patient" })
  Personnels!: RequesterChoice[];
  @Getter("RelationInses", { namespace: "patient" })
  RelationInses!: RequesterChoice[];

  @Patient.Mutation(report.SET_BREAD_ITEMS) setBreadItems!: Function;

  /** 高額療養費の自動作成をするのは何日以降に作ったものからか */
  protected readonly IS_MAKE_CHAIN_HIGHEXPENSE_FROM = "2024-03-21 20:00:00";

  public get reportCount(): ReportCount {
    return this.patientState.report_count;
  }

  public get patientInfo(): PatientDispInfo {
    return this.patientState.info;
  }

  public get patientDetail(): Detail {
    return this.patientState.detail;
  }

  public get patientMaster(): report.PatientMaster {
    return this.patientState.master;
  }

  public get BreadItems(): report.BreadItem[] {
    return this.patientState.bread_items;
  }

  public setDefaultHeader() {
    //パンくずリスト
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: true,
        to: ""
      }
    ]);
  }

  /** タブアイテム（「訪問する」とレセプトは除く） */
  public get TabItems() {
    return this.patientMaster.patient_menus.filter(
      item => item.id !== 5 && item.id !== 7 && item.report_group == 0
    );
  }

  /** 書類グループアイテム */
  public get ReportGroupItems() {
    const groupItems = this.patientMaster.patient_menus.filter(
      item => item.report_group == 1
    );
    return groupItems.map(item => {
      return {
        text: item.title,
        key: item.link,
        count: item.count
      };
    });
  }

  /** 訪問するボタンを活性化するかどうか */
  public get IsVisitBtn(): boolean {
    const isVisit = this.patientMaster.patient_menus.findIndex(
      (menu: report.PatientList) => menu.id === 5
    );

    return isVisit !== -1 && !this.patientInfo.is_not_click_to_visit;
  }

  /** レセプトボタンを表示するかどうか */
  public get IsReceiptBtn(): boolean {
    const isReceipt = this.patientMaster.patient_menus.findIndex(
      (menu: report.PatientList) => menu.id === 7
    );
    return isReceipt !== -1;
  }

  /** 所属事業所ID */
  public get PatientOfficeId(): number {
    return this.patientState.office_id;
  }

  /**
   * 変更先の利用者IDを取得する
   * @param isNext true:次へ false:前へ
   * @param id 現在の利用者ID
   * @param ids 利用者IDs
   * @return 変更先の利用者ID
   */
  protected getChangePatientId(
    isNext: boolean,
    id: number,
    ids: number[]
  ): number {
    let nextId = 0;
    const index = ids.indexOf(id);
    if (isNext) {
      if (index < ids.length - 1) {
        nextId = ids[index + 1];
      } else {
        nextId = ids[0];
      }
    } else {
      if (index > 0) {
        nextId = ids[index - 1];
      } else {
        nextId = ids[ids.length - 1];
      }
    }
    return nextId;
  }

  /**
   * 指定日時点で、ある年齢以上か
   * @param targetDate 誕生日を求める基準日(yyyy-MM-dd形式)
   * @param minAge 年齢の下限
   */
  protected isOverAge(targetDate: string, minAge: number) {
    if (targetDate === "") {
      targetDate = this.dateToStr(new Date(), "yyyy-MM-dd");
    }

    const birthday = this.strToDate(this.patientInfo.bday);
    const bMonth = birthday.getMonth();
    const patientMinAgeDay = birthday;
    patientMinAgeDay.setFullYear(patientMinAgeDay.getFullYear() + minAge);
    // 閏年の2月29日から、minAge年後の3月1日になってしまった場合、みなし誕生日の2月28日に補正する
    if (patientMinAgeDay.getMonth() !== bMonth) {
      patientMinAgeDay.setDate(0);
    }

    return targetDate >= this.dateToStr(patientMinAgeDay, "yyyy-MM-dd");
  }

  /**
   * バイタルの設定
   * @param vitals バイタル情報
   */
  protected setVital(vitals: Vital | Vital[]) {
    if (Array.isArray(vitals)) {
      for (const vital of vitals) {
        this.setBodyTemp(vital);
      }
    } else {
      this.setBodyTemp(vitals);
    }
  }
  /** 体温の設定 */
  private setBodyTemp(vital: Vital) {
    if (vital.body_temprature_int === undefined) this.setBodyTempInt(vital);
    if (vital.body_temprature_dec === undefined) this.setBodyTempDec(vital);
  }
  /** 体温（整数部）の設定 */
  private setBodyTempInt(vital: Vital) {
    if (!vital.body_temprature) {
      vital.body_temprature_int = 99;
    } else {
      vital.body_temprature_int = Number(
        String(vital.body_temprature).split(".")[0]
      );
    }
  }
  /** 体温（小数部）の設定 */
  private setBodyTempDec(vital: Vital) {
    if (
      !vital.body_temprature ||
      String(vital.body_temprature).indexOf(".") == -1
    ) {
      vital.body_temprature_dec = 99;
    } else {
      vital.body_temprature_dec = Number(
        String(vital.body_temprature).split(".")[1]
      );
    }
  }

  /** ある日を基準に、次の誕生日の前日を取得する */
  protected calcBeforeBirthday(targetDateStr: string, birthdayStr: string) {
    // 開始日より後の最初の誕生日の、前日を終了日にする
    // ただし開始日と誕生日が同月なら、翌年の誕生日前日を終了日にする
    const targetDate = this.newDate(targetDateStr);
    const birthday = this.newDate(birthdayStr);

    /** 次の誕生日の前日、ただ一旦次の誕生日にする */
    let beforBirthday = new Date(
      targetDate.getFullYear(),
      birthday.getMonth(),
      birthday.getDate()
    );
    if (
      appDate.diffOfDay(beforBirthday, targetDate) <= 0 ||
      targetDate.getMonth() === birthday.getMonth()
    ) {
      // 次の誕生日が翌年か、基準日と誕生日が同じ月なら、翌年に設定する
      beforBirthday = new Date(
        targetDate.getFullYear() + 1,
        birthday.getMonth(),
        birthday.getDate()
      );
    }
    beforBirthday.setDate(beforBirthday.getDate() - 1);

    return this.dateToStr(beforBirthday, "yyyy-MM-dd");
  }

  /** 高齢受給者証・医療保険兼高齢の年齢条件に合致するかチェック */
  protected checkOldAge(target: string, message: string): string | boolean {
    // 誕生日が1日以外の場合は、翌月1日を誕生日として扱う
    let fixedBirthday: Date;
    //誕生日が1日の場合
    if (this.patientInfo.bday.slice(8, 10) == "01") {
      fixedBirthday = this.newDate(this.patientInfo.bday);
      fixedBirthday.setMonth(fixedBirthday.getMonth());
    } else {
      //誕生日が1日以外の場合
      fixedBirthday = this.newDate(this.patientInfo.bday.slice(0, 8) + "01");
      fixedBirthday.setMonth(fixedBirthday.getMonth() + 1);
    }

    const birthStr = this.dateToStr(fixedBirthday, "yyyyMMdd");
    const age = Math.floor((+target.replace(/-/g, "") - +birthStr) / 10000);
    if (age < 70 || age >= 75) {
      return message;
    }
    return true;
  }
}
