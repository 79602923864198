









































































































































































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import ContactUtility from "@/views/contact/utility";
import { DispTemplate, DEFAULT_DISP_TEMPLATE } from "@/views/contact/types";
import FAQTemplateFileViewer from "@/components/contact/FAQTemplateFileViewer.vue";
import { Location } from "vue-router";

interface DisplayAnserContent {
  /** 内容の一部 */
  content: string;
  /** ハイライト表示すべき文字か */
  isHighlight: boolean;
}

@Component({
  components: {
    FAQTemplateFileViewer
  }
})
export default class FAQContentDetail extends Mixins(ContactUtility) {
  /** FAQテンプレート情報 */
  @Prop({ default: { ...DEFAULT_DISP_TEMPLATE } }) template!: DispTemplate;
  /** 回答内容を展開表示するか */
  @Prop({ default: true }) isOpen!: boolean;
  /** テンプレートの詳細画面であるか */
  @Prop({ default: false }) isDetail!: boolean;
  /** 強調して表示すべき文字 */
  @Prop({ default: () => [] }) highlightWords!: string[];

  /** 詳細画面へのルート情報 */
  private get DetailRoute(): Location {
    return {
      name: "FAQDetail",
      params: { id: String(this.template.id) },
      query: this.$route.query
    };
  }

  /** 表示用回答文 */
  private get FormattedContent() {
    // 2重までのHTML形式になっている文字列の、先頭の「A.」は取り除く
    return this.template.answer_content.replace(
      /^(<[^>]+?>)?(<[^>]+?>)?(?:A\.)?/,
      "$1$2"
    );
  }

  /** ハイライトする部分の文字とそれ以外を分けた回答内容 */
  private get HighlightedContent() {
    // isCutBefore: 検索ワードと一致した文字の、前の部分を省略するか
    return (content: string, isCutBefore: boolean) => {
      const contents: DisplayAnserContent[] = [];
      const originAnswer = this.removeHTMLTag(content);

      // フリーワード検索をしていない場合は、ハイライト表示及び先頭省略をしない
      if (this.highlightWords.length === 0) {
        contents.push({
          content: originAnswer,
          isHighlight: false
        });
        return contents;
      }

      // ["会", "飲み"] → (会|飲み)
      const contentReg = new RegExp("(" + this.highlightWords.join("|") + ")");
      // "協会の元会長と飲み会" → 検索ワードで区切る → ["協", "会", "の元", "会", "長と", "飲み", 会]
      const matchParts = originAnswer.split(contentReg);

      matchParts.forEach(part => {
        contents.push({
          content: part,
          isHighlight: this.highlightWords.includes(part)
        });
      });
      // アコーディオンを閉じている時、回答文のうちキーワードよりも前の部分は、最後の15文字(スマホ幅では5文字)以外を省略する
      // 2行目に差し掛かる部分はCSSによって省略するので、ここではそのまま
      if (isCutBefore && contents.length >= 2) {
        const beforeLen = this.$vuetify.breakpoint.xs ? 5 : 15;
        if (
          !contents[0].isHighlight &&
          contents[0].content.length >= beforeLen
        ) {
          contents[0].content = "..." + contents[0].content.slice(-beforeLen);
        }
      }

      return contents;
    };
  }

  /** カテゴリのクリック時 */
  private goCategoryList(selectedCatId: number) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.clickCategory(selectedCatId);
    this.$router.push({
      name: "FAQ",
      query: this.getFAQUrlQuery({ category_id: String(selectedCatId) })
    });
  }

  /** キーワードのクリック時 */
  private goKeywordList(keyword: string) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.clickKeyword(keyword);
    this.$router.push({
      name: "FAQ",
      query: this.getFAQUrlQuery({ hashtag: keyword })
    });
  }

  /** カテゴリリンクをクリックした */
  @Emit("click:category")
  clickCategory(categoryId: number) {
    return categoryId;
  }

  /** キーワードリンクをクリックした */
  @Emit("click:keyword")
  clickKeyword(keyword: string) {
    return keyword;
  }
}
