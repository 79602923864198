










































































































































































































































































































































import { Component, Emit, Mixins, Prop, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import FirestoreMixin from "@/mixins/firestoreMixin";
import VisitTmpDataMixin from "@/mixins/firestore/visitTmpDataMixin";
import { COLLECTION_FAVORITED_PATIENT_IDS } from "@/const/envFireStore";
import { cantMoving } from "@/main";
import { Staff } from "@/types";
import { TokenObj } from "@/components/app/types";
import { FavoritedPatient } from "@/components/main/types";
import InfiniteLoading from "vue-infinite-loading";

@Component({
  components: {
    InfiniteLoading
  }
})
export default class AppHeader extends Mixins(
  UtilMixin,
  AxiosMixin,
  VisitTmpDataMixin,
  FirestoreMixin
) {
  /** 訪問中フラグ */
  @Prop({ default: false }) is_visit!: boolean;

  // チャット
  @Prop({ default: false }) isShownChatButton!: boolean;
  @Prop({ default: false }) isShownChatBadge!: boolean;

  /** 無限ローディング */
  @Ref("infiniteLoading") private readonly infiniteLoading?: InfiniteLoading;

  /** ログイン中の職員に対するお気に入り利用者ID */
  private favoritedPatientIds: number[] = [];

  /** 一回で取得するデータ量 */
  private limit = 10; //一回で取得するデータ量

  /** 読み込みページ数 */
  private page = 0;

  /** お気に入り情報含めた利用者情報 */
  private favoriteAttachedPatients: FavoritedPatient[] = [];

  /** お気に入り利用者情報 */
  private favoritedPatients: FavoritedPatient[] = [];

  /** 利用者検索欄キーワード */
  private keyword = "";

  /** 利用者の検索回数 */
  private wordSearchCount = 0;

  /** 該当利用者が見つからなかったというメッセージを表示するかフラグ */
  private needEmptyMessage = false;

  /** 利用者一覧取得リクエストの実行待ち */
  private timeoutFetchPatients = 0;

  /** 契約内の職員リスト */
  private officeStaffs: Staff[] = [];

  /** 利用者検索メニューを範囲外のクリックで閉じるかどうか */
  private closeSearchMenuOnClickOutside = true;

  /** 利用者検索メニューをコンテンツのクリックで閉じるかどうか */
  private closeSearchMenuOnContentClick = false;

  /** お気に入りメニューを範囲外のクリックで閉じるかどうか */
  private closeFavoriteMenuOnClickOutside = true;

  /** お気に入りメニューをコンテンツのクリックで閉じるかどうか */
  private closeFavoriteMenuOnContentClick = false;

  /** 事業所名 */
  private get officeName() {
    return this.masters.offices.find(
      office => office.value === this.loginUser.office_id
    )?.text;
  }

  // 誕生日か
  private get isBirthday() {
    if (this.loginUser.ewell_user_id == 0) {
      return false;
    }
    const birthdayArray = this.loginUser.birth_day.split("-");
    const dateBirthday = new Date(
      Number(birthdayArray[0]),
      Number(birthdayArray[1]) - 1,
      Number(birthdayArray[2])
    );
    const today = new Date();
    return (
      dateBirthday.getMonth() == today.getMonth() &&
      dateBirthday.getDate() == today.getDate()
    );
  }

  /** マイページ閲覧権限があるかどうか */
  private get CanMypage() {
    return this.loginUser.ewell_user_id > 0;
  }

  async created() {
    // firebaseからお気に入り利用者ID情報を取得
    this.collection = COLLECTION_FAVORITED_PATIENT_IDS;
    this.documentId = String(this.loginUser.id);
    const data = await this.documentGet();
    if (typeof data !== "boolean" && data) {
      this.favoritedPatientIds = data.favorited_patient_ids;
    } else if (data === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
  }

  //事業所一覧取得
  mounted() {
    this.postJsonCheck(window.base_url + "/api/getofficestaffs", {}, res => {
      if (res.data.office_staffs) {
        this.officeStaffs = res.data.office_staffs;
      }
    });
  }

  //事業所選択
  clickSelect(staffId: number) {
    const staffs = this.officeStaffs.filter(item => item.id == staffId);
    if (staffs.length === 0) {
      return;
    }
    this.postJsonCheck(
      window.auth_backend_url + "/api/auth/token",
      {
        office_staff_id: staffs[0].id
      },
      res => {
        this.changeStaff(res.data.token, res.data.ref_token);
      }
    );
  }

  //職員切替
  @Emit()
  private changeStaff(token: string, ref_token: string): TokenObj {
    return { token, ref_token };
  }

  //ログアウト
  private async logout() {
    if (!(await this.$openConfirm("ログアウトしますか？"))) {
      return;
    }
    this.toLogout();
  }

  //ログアウト
  @Emit()
  private toLogout() {
    return;
  }

  @Emit()
  private toggleSideOpen() {
    return;
  }

  @Emit()
  private toggleChatOpen() {
    return;
  }

  //マイページへ
  private async toMypage() {
    // 編集を破棄したくない場合は処理しない
    if (await cantMoving()) {
      return;
    }

    // セッション削除
    this.postJsonCheck(window.auth_backend_url + "/api/auth/logout", {}, () => {
      location.href = window.auth_frontend_url;
    });
  }

  /** 利用者画面へ遷移する */
  private redirectPatientTop(patientId: number): void {
    if (!patientId) return;
    this.closeSearchMenuOnContentClick = true;
    this.closeFavoriteMenuOnContentClick = true;
    this.$router.push({
      name: "PatientCalendar",
      params: { id: String(patientId) }
    });
  }

  /** 利用者検索エリアを開く */
  private openSearchMenu() {
    this.closeSearchMenuOnClickOutside = true;
    this.closeSearchMenuOnContentClick = false;
  }

  private closeSearchAndFavoriteMenu() {
    console.log("closeSearchAndFavoriteMenu");
    this.closeSearchMenuOnContentClick = true;
    this.closeFavoriteMenuOnContentClick = true;
  }

  /** 利用者検索。取得済み一覧があれば破棄する */
  private initGetPatients() {
    this.resetLoaded();
    // 入力後、400ミリ秒間入力がなければ検索する
    clearTimeout(this.timeoutFetchPatients);
    this.timeoutFetchPatients = setTimeout(() => {
      this.fetchFavoritedPatients();
    }, 400);
  }

  /** お気に入り情報含めた利用者情報取得 */
  private fetchFavoritedPatients() {
    this.wordSearchCount++;
    const wordSearchCount = this.wordSearchCount;
    this.needEmptyMessage = false;

    this.postJsonBackground(
      window.base_url + "/api/patient/favorites/get",
      {
        keyword: this.keyword,
        favorited_patient_ids: this.favoritedPatientIds,
        limit: this.limit,
        page: this.page
      },
      res => {
        if (!res.data.favorited_patients) {
          return;
        }
        if (wordSearchCount !== this.wordSearchCount) {
          return;
        }
        const resPatients: FavoritedPatient[] = res.data.favorited_patients;

        if (!this.page) {
          this.favoriteAttachedPatients = resPatients ?? [];
        } else {
          this.favoriteAttachedPatients = this.favoriteAttachedPatients.concat(
            resPatients ?? []
          );
        }
        this.finishLoaded(resPatients);

        if (this.favoriteAttachedPatients.length == 0) {
          this.needEmptyMessage = true;
        }

        this.forceInfiniteLoad();
      }
    );
  }

  private forceInfiniteLoad() {
    const scroller: HTMLElement | null = document.querySelector(
      ".ibow2-header-submenu-wrapper"
    );
    if (scroller && scroller.scrollHeight === scroller.clientHeight) {
      const menu: HTMLElement | null = scroller.querySelector(
        ".ibow2-header-submenu"
      );
      if (menu) {
        // 要素があれば処理を続行する
        const scrollerOverflowY = scroller.style.overflowY;
        const menuMarginBottom = menu.style.marginBottom;
        // 3pxだけスクロールできる状態にして、自動で3pxスクロール
        // スクロールが末尾に達したことを検知して、無限ローディングが行われる
        scroller.style.overflowY = "scroll";
        menu.style.marginBottom = "-3px";
        scroller.scrollTo({ top: 3 });
        // 変更したスタイルを戻す
        menu.style.marginBottom = menuMarginBottom;
        scroller.style.overflowY = scrollerOverflowY;
      }
    }
  }

  /** お気に入り登録済み利用者情報取得 */
  private fetchFavoritePatients() {
    this.needEmptyMessage = false;
    this.closeFavoriteMenuOnClickOutside = true;
    this.closeFavoriteMenuOnContentClick = false;

    // お気に入りが0件の場合はAPIを呼ばない
    if (this.favoritedPatientIds.length == 0) {
      this.favoritedPatients = [];
      this.needEmptyMessage = true;
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/favorites/get",
      {
        keyword: "",
        favorited_patient_ids: this.favoritedPatientIds
      },
      res => {
        if (res.data.favorited_patients) {
          this.favoritedPatients = res.data.favorited_patients;
          if (this.favoritedPatients.length == 0) {
            this.needEmptyMessage = true;
          }
        }
      }
    );
  }

  /** お気に入り情報（星マーク）クリック時 */
  private onClickStar(favoritePatient: FavoritedPatient) {
    favoritePatient.is_favorited
      ? this.unfollowPatient(favoritePatient)
      : this.followPatient(favoritePatient);
  }

  /** 利用者お気に入り登録 */
  private followPatient(patientInfo: FavoritedPatient) {
    // お気に入り利用者IDに追加
    this.favoritedPatientIds = [
      patientInfo.patient_id,
      ...this.favoritedPatientIds
    ];

    // お気に入りリストに追加
    this.favoritedPatients = [patientInfo, ...this.favoritedPatients];

    // お気に入り情報含めた利用者情報中の対象利用者のお気に入り登録情報を反転(キーワードあり)・追加(キーワードなし)
    this.favoriteAttachedPatients = this.favoriteAttachedPatients.map(
      (data: FavoritedPatient) => {
        if (data.patient_id == patientInfo.patient_id) {
          data.is_favorited = !data.is_favorited;
        }
        return data;
      }
    );

    // firebase更新
    this.documentSave({ favorited_patient_ids: this.favoritedPatientIds });
  }

  /** 利用者お気に入り解除 */
  private async unfollowPatient(patientInfo: FavoritedPatient) {
    this.closeSearchMenuOnClickOutside = false;
    this.closeFavoriteMenuOnClickOutside = false;
    if (!(await this.$openConfirm("解除しますか？"))) {
      this.closeSearchMenuOnClickOutside = true;
      this.closeFavoriteMenuOnClickOutside = true;
      return;
    }
    this.closeSearchMenuOnClickOutside = true;
    this.closeFavoriteMenuOnClickOutside = true;
    // お気に入り利用者IDから削除
    this.favoritedPatientIds = this.favoritedPatientIds.filter(
      (favoritedPatientId: number) =>
        favoritedPatientId != patientInfo.patient_id
    );

    // お気に入りリストから削除
    this.favoritedPatients = this.favoritedPatients.filter(
      data => data.patient_id != patientInfo.patient_id
    );

    // お気に入り情報含めた利用者情報中の対象利用者のお気に入り登録情報を反転(キーワードあり)・削除(キーワードなし)
    this.favoriteAttachedPatients = this.favoriteAttachedPatients.map(
      (data: FavoritedPatient) => {
        if (data.patient_id == patientInfo.patient_id) {
          data.is_favorited = !data.is_favorited;
        }
        return data;
      }
    );

    // firebase更新
    this.documentSave({ favorited_patient_ids: this.favoritedPatientIds });
  }

  /** 読み込み終了処理 */
  private finishLoaded(items: unknown[]) {
    this.infiniteLoading?.stateChanger.loaded();
    if (items?.length < this.limit) {
      this.infiniteLoading?.stateChanger.complete();
    }
    this.page++;
  }

  /** 読み込みリセット処理 */
  private resetLoaded() {
    this.infiniteLoading?.stateChanger.reset();
    this.page = 0;
    this.favoriteAttachedPatients = [];
  }

  /* ログイン中の職員 */
  private selectStaff(staffId: number) {
    if (this.loginUser.id === staffId) {
      return true;
    }
  }
}
