

































import { Component } from "vue-property-decorator";
import Other from "./Other.vue";

@Component
export default class OtherV3 extends Other {
  //訪問看護感染症対策実施加算
  protected disableCheckCovid19Div(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.remedy_div == 4 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //同一日3人以上
    if (this.disableCheckThreePersonFlg()) {
      this.receiptVisitRecord.three_person_flg = 0;
    }
    //加算のみ
    if (this.disableCheckAddOnlyFlg()) {
      this.receiptVisitRecord.add_only_flg = 0;
    }
    //訪問看護感染症対策実施加算
    if (this.disableCheckCovid19Div()) {
      this.receiptVisitRecord.covid19_div = 0;
    }
  }
}
