import { COLLECTION_VISIT_TEMP } from "@/const/envFireStore";
import { FirestoreState } from "../firestore/types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { actions } from "../firestore/actions";
import { mutations } from "../firestore/mutations";
import { getters } from "../firestore/getters";

export const name = "visitTmpData";

export const state: FirestoreState = {
  collection: COLLECTION_VISIT_TEMP,
  documentId: "",
  firebaseData: undefined,
  data: undefined,
  unsubscribe: undefined,
  observers: [],
  error: false
};

const namespaced = true;

export const visitTmpData: Module<FirestoreState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
