var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppSectionTitle',{attrs:{"text":"お問合せ","linkTo":_vm.$route.name !== 'ContactHistory' ? 'ContactHistory' : '',"linkText":"お問合せ一覧をみる"}}),_c('AppDataTable',{staticClass:"mt-4",attrs:{"headers":_vm.contactListHeaders,"items":_vm.filteredInquiry,"fixed-header":true,"isNoTop":true,"pageNo":_vm.pageNo,"frontOnlyPageCount":_vm.itemPerPage,"isNoFooter":_vm.isPartly || _vm.filteredInquiry.length <= _vm.itemPerPage,"noDataText":"お問合せがありません。"},on:{"click:row":_vm.show,"update:pageNo":_vm.changePage},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","align":"center"}},[_c('AppChip',{attrs:{"color":_vm.idToText(item.status, _vm.INQUIRY_STATUS_COLOR)}},[_c('span',{staticClass:"text-no-wrap white--text"},[_vm._v(_vm._s(_vm.idToText(item.status, _vm.INQUIRY_STATUS)))])]),(item.has_not_reading_inquiry)?_c('AppChip',{staticClass:"ml-2",attrs:{"color":"deep-orange darken-1"}},[_c('span',{staticClass:"text-no-wrap white--text"},[_vm._v("未読あり")])]):_vm._e()],1)]}},{key:"item.normalized_inquiry_content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",class:item.inquiry.inquiry_answers.length ? 'font-weight-bold' : '',style:({ width: '200px' })},[_vm._v(" "+_vm._s(item.normalized_inquiry_content)+" ")])]}},{key:"item.inquiry.inquiry_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inquiry.inquiry_datetime.replaceAll("-", "/"))+" ")]}},{key:"item.last_answered_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_answered_datetime.replaceAll("-", "/"))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }