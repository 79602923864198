






































import { Component, Prop, Vue } from "vue-property-decorator";
import { VisitModel } from "@/components/patient/record2/types";

@Component
export default class BasicInfo extends Vue {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop() private readonly model!: VisitModel;
}
