import {
  Inquiry,
  DEFAULT_INQUIRY,
  Template,
  TemplateCategory,
  DEFAULT_TEMPLATE
} from "#/model/inquiry";
import { Choice } from "@/types";

/** 一覧表示用問合せ情報 */
export interface ListInquiry {
  /** 問合せ情報 */
  inquiry: Inquiry;
  /** HTMLタグを除去した問合せ内容 */
  normalized_inquiry_content: string;
  /** 最終回答日時 */
  last_answered_datetime: string;
  /** チケット番号 */
  ticket_no: string;
  /** 未読の問合せがあるか */
  has_not_reading_inquiry: boolean;
}

/** デフォルト値 */
export const DEFAULT_LIST_INQUIRY: ListInquiry = {
  inquiry: { ...DEFAULT_INQUIRY },
  normalized_inquiry_content: "",
  last_answered_datetime: "",
  ticket_no: "",
  has_not_reading_inquiry: false
};

export interface CategoryMenuList {
  /** リストに表示するカテゴリ名 */
  category_name: string;
  /** リストに表示するアイコン */
  icon?: string;
  /** カテゴリID */
  category_id: number;
  /** 子カテゴリ */
  childs: CategoryMenuList[];
}

export interface CategoryMenuSelect {
  /** リストに表示するカテゴリ名 */
  category_name: string;
  /** 大カテゴリ内の選択肢 */
  selects: CategoryMenuSelectItem[];
}

export interface CategoryMenuSelectItem extends Choice {
  /** FAQカテゴリの第何階層か */
  depth: number;
}

/** カテゴリ選択肢情報 */
export interface CategoryChoice extends Choice {
  /** カテゴリ階層の深さ */
  depth: number;
}

export interface DispTemplate extends Template {
  flat_categories: TemplateCategory[];
  formatted_answer_content: string;
}

export const DEFAULT_DISP_TEMPLATE: DispTemplate = {
  ...DEFAULT_TEMPLATE,
  flat_categories: [],
  formatted_answer_content: ""
};

/** FAQテンプレート検索条件 */
export interface SearchCondFAQ {
  /** 問い合わせ・回答内容 */
  keyword: string;
  /** キーワードのAND/OR検索 1:AND 2:OR */
  keyword_operator: number;
  /** カテゴリID */
  category_id: number;
  /** キーワード */
  hashtag: string;
}

export const DEFAULT_SEARCH_COND_FAQ: SearchCondFAQ = {
  keyword: "",
  keyword_operator: 1,
  category_id: 0,
  hashtag: ""
};

/** FAQテンプレート検索画面のURLクエリ */
export interface SearchQueryFAQ {
  keyword: string;
  andor: string;
  category_id: string;
  hashtag: string;
}
