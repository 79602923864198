











import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import ItemTable from "#/components/ItemTable.vue";

interface SelfburdenTemplate {
  id: number; // ID
  regist: number; // 編集フラグ
  category_name: string; // カテゴリ名
  item_name: string; // アイテム名
  unit_price: number; // 単価
  quantity: number; // 数量
  amount: number; // 金額
  remarks: string; // 備考
}

@Component({
  components: {
    ItemTable
  }
})
export default class SelfBurdenTemplate extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 自費テンプレート */
  @Prop({ default: () => [] }) private readonly items!: SelfburdenTemplate[];

  /** 自費テンプレートヘッダー */
  private selfburdenTemplateHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    {
      text: "カテゴリ名",
      value: "category_name",
      width: "150px"
    },
    {
      text: "アイテム名",
      value: "item_name",
      width: "150px"
    },
    {
      text: "単価",
      value: "unit_price",
      width: "70px"
    },
    {
      text: "数量",
      value: "quantity",
      width: "70px"
    },
    {
      text: "金額",
      value: "amount",
      width: "70px"
    },
    {
      text: "課税区分",
      value: "tax_div",
      width: "90px"
    },
    {
      text: "医療費控除",
      value: "medical_deducation",
      width: "90px"
    },
    {
      text: "備考",
      value: "remarks",
      width: "200px"
    }
  ];

  /** 新規作成 */
  private selfburdenTemplateCreate() {
    this.$router.push({
      name: "PatientSelfburdenTemplateEdit",
      params: { id: String(this.patientId), selfburdentemplateid: "0" }
    });
  }

  /** 修正 */
  private selfburdenTemplateEdit(item: SelfburdenTemplate) {
    this.$router.push({
      name: "PatientSelfburdenTemplateEdit",
      params: {
        id: String(this.patientId),
        selfburdentemplateid: String(item.id)
      }
    });
  }

  /** コピー */
  private selfburdenTemplateCopy(item: SelfburdenTemplate) {
    this.$router.push({
      name: "PatientSelfburdenTemplateEdit",
      params: {
        id: String(this.patientId),
        selfburdentemplateid: String(item.id)
      },
      query: { mode: "copy" }
    });
  }
}
