









































import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { OtherInfo } from "@/components/patient/info/types";
import OtherInfoViewr from "@/components/patient/info/OtherInfoViewer.vue";
import { Patient } from "#/model/patient";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {
    OtherInfoViewr
  }
})

/** 利用者基礎情報 状態フォーム */
export default class FormCondition extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 利用者情報 */
  @Prop() patient!: Patient;

  /** 基礎情報その他情報 */
  @Prop() otherInfo!: OtherInfo;

  /** 読み取り専用フラグ(履歴編集画面用) */
  @Prop({ default: false }) isReadOnly!: boolean;

  /** 履歴編集画面フラグ */
  @Prop({ default: false }) isHistoryEditMode!: boolean;

  /** プレフィックスID(入力項目にIDを振る対応) */
  @Prop({ default: "patient-info" }) private prefixId!: string;

  /** 関数 */

  /** ADLから情報反映 */
  @Emit("click:adl")
  private clickFetchAdl() {
    return;
  }
}
