












import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class OfficeSelects extends Mixins(UtilMixin) {
  @Prop({ default: "事業所" }) label!: string;

  @Prop() value!: number[];

  /** サテライト事業所を選択できるか */
  @Prop({ default: true }) needSatelite!: boolean;

  /** 未選択を選択できるか */
  @Prop({ default: false }) needUnselected!: boolean;

  private get Value(): number[] {
    return this.value.slice();
  }

  private set Value(newValue: number[]) {
    this.input(newValue);
  }

  /** 選択可能な事業所一覧 */
  private get selectableItems() {
    // propsによってサテライトを含めるかどうか切り替える
    const copiedGroupOffices = this.masters.group_offices.slice();
    const copiedOnlyOffices = this.masters.group_only_offices.slice();
    if (!this.needUnselected) {
      copiedGroupOffices.splice(0, 1);
      copiedOnlyOffices.splice(0, 1);
    }
    return this.needSatelite ? copiedGroupOffices : copiedOnlyOffices;
  }

  @Emit()
  private input(newValue: number[]) {
    return newValue;
  }
}
