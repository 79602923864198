import { Patient, DefaultPatient } from "#/model/patient";
import {
  OtherInfo,
  OtherInfoDefaultValues
} from "@/components/patient/info/types";
import { Office, DefaultOffice } from "#/model/agreement";

/** 基礎情報・履歴編集画面用利用者 */
export interface ExPatient {
  /** 利用者情報 */
  patient: Patient;
  /** 指示書情報 */
  other_info: OtherInfo;
  /** 請求情報表示フラグ */
  disp_billing: boolean;
  /** 事業所情報(ほのぼの事業所表示用) */
  office: Office;
  /** 主治医ID */
  indicate_doctor_id: number;
  /** 主治医強調表示フラグ */
  hightlight_doctor: boolean;
  /** 変更予約された利用者情報フラグ */
  is_reserved_history: boolean;
  /** 「履歴を残して基礎情報を保存」可能フラグ */
  can_leave_history: boolean;
}

export const DefaultExPatient = (): ExPatient => ({
  patient: DefaultPatient(),
  other_info: OtherInfoDefaultValues(),
  disp_billing: false,
  office: DefaultOffice(),
  indicate_doctor_id: 0,
  hightlight_doctor: false,
  is_reserved_history: false,
  can_leave_history: false
});
