



















































































































































import { Component, Watch } from "vue-property-decorator";
import OneMonthAddV2 from "./OneMonthAddV2.vue";

@Component
export default class OneMonthAddV3 extends OneMonthAddV2 {
  created(): void {
    this.setHour24Div();
    this.setMedicalSpecialDivV3();
    this.setMedicalTerminalDiv();
    this.setAccompaniedStaffCnt();
  }

  //特別管理加算（オーバーライド）
  @Watch("medical_special_div1") protected handleMedicalSpecialDiv1() {
    if (!this.isLoaded) {
      return;
    }
    if (
      this.medical_special_div1 == false &&
      this.medical_special_div2 == false
    ) {
      this.receiptVisitRecord.medical_special_div = 0;
      return;
    }
    //コロナ臨時対応の場合同時選択可能（同時選択の場合3になる）
    if (this.event.indicate.temporary_covid19_div == 1) {
      if (
        this.medical_special_div1 == true &&
        this.medical_special_div2 == true
      ) {
        this.receiptVisitRecord.medical_special_div = 3;
        return;
      }
      //両方とも選択されている状態から、自分をfalseにした場合
      if (this.medical_special_div1 == false) {
        if (this.medical_special_div2 == true) {
          this.receiptVisitRecord.medical_special_div = 2;
        }
      }
    }
    if (this.medical_special_div1 == true) {
      this.receiptVisitRecord.medical_special_div = 1;
      this.medical_special_div2 = false;
    }
  }

  //重症度等の高い患者
  @Watch("medical_special_div2") protected handleMedicalSpecialDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (
      this.medical_special_div1 == false &&
      this.medical_special_div2 == false
    ) {
      this.receiptVisitRecord.medical_special_div = 0;
      return;
    }
    //コロナ臨時対応の場合同時選択可能（同時選択の場合3になる）
    if (this.event.indicate.temporary_covid19_div == 1) {
      if (
        this.medical_special_div1 == true &&
        this.medical_special_div2 == true
      ) {
        this.receiptVisitRecord.medical_special_div = 3;
        return;
      }
      //両方とも選択されている状態から、自分をfalseにした場合
      if (this.medical_special_div2 == false) {
        if (this.medical_special_div1 == true) {
          this.receiptVisitRecord.medical_special_div = 1;
        }
      }
    }
    if (this.medical_special_div2 == true) {
      this.receiptVisitRecord.medical_special_div = 2;
      this.medical_special_div1 = false;
    }
  }

  //特別管理加算
  protected setMedicalSpecialDivV3() {
    if (this.receiptVisitRecord.medical_special_div == 1) {
      this.medical_special_div1 = true;
      this.medical_special_div2 = false;
    }
    if (this.receiptVisitRecord.medical_special_div == 2) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = true;
    }
    if (this.receiptVisitRecord.medical_special_div == 3) {
      this.medical_special_div1 = true;
      this.medical_special_div2 = true;
    }
  }

  //特別管理加算回数
  protected disableCheckMedicalSpecialDivCount(): boolean {
    let result = false;
    if (!this.medical_special_div1 || this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //特別管理加算回数（コロナ対応時のみ）
  protected chackMedicalSpecialDivCount(): boolean | string {
    //1か2しか入力できない
    if (
      this.medical_special_div1 &&
      this.receiptVisitRecord.medical_special_div_count != 1 &&
      this.receiptVisitRecord.medical_special_div_count != 2 &&
      !this.disableCheckMedicalSpecialDivCount()
    ) {
      return "特別管理加算の回数は2までを入力してください。";
    }
    return true;
  }

  //情報提供療養費（II）回数
  protected disableCheckInfoExpenses2Count(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.info_expenses_flg2 == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.stay_outside_flg == 1 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }

  //情報提供療養費（II）回数
  protected chackInfoExpenses2Count(): boolean | string {
    //1以上の数字入力
    if (
      this.receiptVisitRecord.info_expenses_flg2 == 1 &&
      this.receiptVisitRecord.info_expenses2_count < 1 &&
      !this.disableCheckInfoExpenses2Count()
    ) {
      return "情報提供療養費（II）の回数には1以上の数字を入れてください。";
    }
    return true;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //ターミナルケア
    if (this.disableCheckMedicalTerminalDivV2()) {
      this.medical_terminal_div1 = false;
      this.medical_terminal_div2 = false;
      this.receiptVisitRecord.medical_terminal_div = 0;
    }
    //特別管理指導
    if (this.disableCheckSpecialInstFlg()) {
      this.receiptVisitRecord.special_inst_flg = 0;
    }
    //24時間体制
    if (this.disableCheckMedicalHour24Add()) {
      this.hour24_div1 = false;
      this.hour24_div2 = false;
      this.receiptVisitRecord.hour24_div = 0;
    }
    //特別管理加算
    if (
      this.disableCheckMedicalSpecialDiv1() &&
      this.disableCheckMedicalSpecialDiv2()
    ) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = false;
      this.receiptVisitRecord.medical_special_div = 0;
    }
    //特別管理加算回数
    if (this.disableCheckMedicalSpecialDivCount()) {
      this.receiptVisitRecord.medical_special_div_count = 0;
    }
    //在宅患者連携指導
    if (this.disableCheckHomeInstFlg()) {
      this.receiptVisitRecord.home_inst_flg = 0;
    }
    //精神科重症患者支援管理連携加算
    if (this.disableCheckMentalSupportDivV2()) {
      this.mental_support_div1 = false;
      this.mental_support_div2 = false;
      this.receiptVisitRecord.mental_support_div = 0;
    }
    //情報提供療養費
    if (this.disableCheckInfoExpensesFlgV2()) {
      this.receiptVisitRecord.info_expenses_flg1 = 0;
      this.receiptVisitRecord.info_expenses_flg2 = 0;
      this.receiptVisitRecord.info_expenses_flg3 = 0;
    }
    //情報提供療養費（II）回数
    if (this.disableCheckInfoExpenses2Count()) {
      this.receiptVisitRecord.info_expenses2_count = 0;
    }
    //看護・介護職員連携
    if (this.disableCheckNursingCareFlg()) {
      this.receiptVisitRecord.nursing_care_flg = 0;
    }
    //看護・介護職員連携日
    if (this.disableCheckNursingCareDate()) {
      this.receiptVisitRecord.nursing_care_date = "";
    }
  }
}
