


















































import { Component, Ref } from "vue-property-decorator";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import { Choice } from "@/types";
import Base from "@/components/patient/info/Base";
import { ExPatient, DefaultExPatient } from "@/views/patient/info/types";
import PatientInfoItem from "@/components/patient/info/PatientInfoItem.vue";

@Component({
  components: {
    PatientExpantionAreas,
    PatientInfoItem
  }
})
export default class PatientInfo extends Base {
  /** Ref */

  @Ref("form") private readonly form!: PatientInfoItem;

  /** 変数 */

  /** 編集対象の利用者情報 */
  private targetPatient: ExPatient = DefaultExPatient();

  /** 変更予約された利用者情報 */
  private reservedPatient: ExPatient = DefaultExPatient();

  /** 利用者ID */
  private patientId = "";

  /** 保存前引き落とし区分 */
  private beforeSavetWithdrawalStKbn = 0;

  /** グループマスタ */
  private groupItems: Choice[] = [];

  /** 変更予約された基礎情報の確認用ダイアログ制御 */
  private reservedPatientDialog = false;

  /** ロード完了フラグ */
  private isLoaded = false;

  /** 関数 */

  /** 変更予約された基礎情報確認ダイアログを開く */
  private openReservedPatientDialog() {
    this.reservedPatientDialog = true;
  }

  created() {
    this.useType = 1;
    this.patientId = this.$route.params.id;
    this.postJsonCheck(
      window.base_url + "/api/patient/info/get",
      {
        patient_id: Number(this.patientId)
      },
      res => {
        if (res.data) {
          this.targetPatient = {
            patient: res.data.patient,
            other_info: res.data.other_info,
            disp_billing: res.data.disp_billing,
            office: res.data.office,
            indicate_doctor_id: res.data.indicate_doctor_id,
            hightlight_doctor: res.data.hightlight_doctor,
            is_reserved_history: res.data.is_reserved_history,
            can_leave_history: res.data.can_leave_history
          };

          this.reservedPatient = res.data.reserved_patient;

          // base.tsで管理している変数へ格納
          this.patient = { ...this.targetPatient.patient };
          this.office = this.targetPatient.office;

          this.beforeSavetWithdrawalStKbn = this.patient.info.withdrawal_st_kbn;

          this.preNursingMonths = this.makeCheckboxForPreNursingMonth(
            this.patient.info.prevent_gensan_month,
            this.patient.info.patient_pre_nursing_months
          );

          this.setLoaded();
          this.$nextTick(() => {
            this.isLoaded = true;
          });
        }
      }
    );

    // グループリスト取得
    this.postJsonCheck(
      window.base_url + "/api/patient/group/get",
      { patient_id: Number(this.patientId) },
      res => {
        this.groupItems = res.data.groups;
      }
    );
  }

  mounted() {
    this.setDefaultHeader();
  }

  /** 初回訪問日ボタンクリック */
  private clickFirstDay() {
    this.$set(
      this.patient.info,
      "care_service_start_date",
      this.patient.info.first_visit_date
    );
  }

  /** 訪問看護終了日ボタンクリック */
  private clickVisitEndDay() {
    this.$set(
      this.patient.info,
      "care_service_end_date",
      this.patient.info.visit_end_date
    );
  }

  /** ADLから情報反映 */
  private clickFetchAdl() {
    this.postJsonCheck(
      window.base_url + "/api/patient/info/adl/get",
      { patient_id: Number(this.patientId) },
      res => {
        this.patient.info.mind_and_body_state = res.data.mind_and_body_state;
      }
    );
  }

  /** 利用者削除 */
  private async clickPatientDelete() {
    const message =
      "完全に利用者リストから削除します。本当に削除してよろしいですか？";
    if (!(await this.$openConfirm(message))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/patient/delete",
      { patient: this.patient },
      async () => {
        await this.$openAlert("削除しました。");
        this.setNoEditMode();
        this.$router.push({ name: "PatientList" });
      }
    );
  }

  /** 保存ボタンクリック */
  private async clickSave(leaveHistory: boolean) {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (this.patient.info.sex === 3) {
      if (
        !(await this.$openConfirm(
          "レセプトを作成する場合、男・女のどちらかを選択していただく必要があります。このまま登録しますか？"
        ))
      ) {
        return;
      }
    }
    if (
      this.patient.info.withdrawal_st_kbn == 1 &&
      this.beforeSavetWithdrawalStKbn === 2
    ) {
      if (
        !(await this.$openConfirm(
          "引落開始設定が「開始する」になっていますが、このまま登録しますか？"
        ))
      ) {
        return;
      }
    }
    if (this.patient.info.first_visit_date === "") {
      if (
        !(await this.$openConfirm(
          "初回訪問日は、医療のレセプトを作成する場合、必須の情報になります。入力されていませんがこのまま登録しますか？"
        ))
      ) {
        return;
      }
    }
    if (this.patient.info.mind_and_body_state === "") {
      if (
        !(await this.$openConfirm(
          "心身の状態は、医療のレセプトを作成する場合、必須の情報になります。入力されていませんがこのまま登録しますか？"
        ))
      ) {
        return;
      }
    }

    if (this.reservedPatient.patient.info.id > 0) {
      if (
        !(await this.$openConfirm(
          "変更予約された基礎情報が作成されています。現在の変更が上書きされる可能性がありますが、このまま保存しますか？"
        ))
      ) {
        return;
      }
    }

    this.setGroupParameter();

    // 施設コードのリセット
    this.resetFacilityCode();

    // 減算起算開始月の設定
    this.patient.info.patient_pre_nursing_months = this.preNursingMonths
      .filter(v => v.is_checked == 1)
      .map(v => {
        return {
          id: v.id,
          patient_id: 0,
          exec_yearmonth: v.exec_yearmonth,
          is_auto: v.is_auto,
          created_at: v.created_at ?? "",
          updated_at: v.updated_at ?? "",
          deleted_at: v.deleted_at ?? ""
        };
      });

    // 履歴保存のあり・なしでAPI呼び先を変更
    const apiUrl = leaveHistory
      ? "/api/patient/info/saveWithHistory"
      : "/api/patient/info/save";

    this.postJsonCheck(
      window.base_url + apiUrl,
      {
        patient: this.patient
      },
      async res => {
        if (res.data.errors && res.data.errors.length > 0) {
          await this.$openAlert(res.data.errors.join("\n"));
          return;
        }
        this.setNoEditMode();
        this.$router.push({
          name: "PatientCalendar",
          params: { id: this.patientId }
        });
      }
    );
  }

  // キャンセルボタンクリック
  private async clickCancel() {
    if (await this.$openConfirm("編集をキャンセルしてよろしいですか？")) {
      this.$router.go(-1);
    }
  }
}
