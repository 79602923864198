/** PDFリンクメールステータス */
export const PDF_LINK_STATUS = {
  SAVE: 1, // PDFリンクメールステータス：下書き保存済み
  SEND: 2 // PDFリンクメールステータス：送信済み
};

export interface SearchCond {
  send_start: string; // 送信日検索開始
  send_end: string; // 送信日検索終了
  limit: number; // 取得件数
  page: number; // ページ
}
export const DefaultSearchCond = (): SearchCond => ({
  send_start: "",
  send_end: "",
  limit: 0,
  page: 0
});
