







































import { Component, Mixins } from "vue-property-decorator";
import EditObserveItemCategoryDialog from "@/components/master/observeitem/EditObserveItemCategoryDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify/types/index";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import {
  ObserveItemCategory,
  Office,
  CARE_TYPES,
  initObserveItemCategory
} from "@/components/master/observeitem/types";
import Sortable from "sortablejs";

@Component({
  components: {
    EditObserveItemCategoryDialog,
    CommonMasterList
  }
})
export default class ObserveItemCategoryList extends Mixins(AxiosMixin) {
  private isShownEditDialog = false;

  private selectedObserveItemCategory = initObserveItemCategory();

  private headers: DataTableHeader[] = [
    {
      text: "カテゴリ名",
      value: "name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    {
      text: "訪問種別",
      value: "custom",
      width: "300px",
      sortable: false
    }
  ];

  private careTypes = CARE_TYPES;

  private observeItemCategories: ObserveItemCategory[] = [];

  private offices: Office[] = [];

  public created(): void {
    this.fetchObservationCategoryItems();
  }

  //データ更新
  private fetchObservationCategoryItems() {
    this.postJsonCheck(
      window.base_url + "/api/master/observation_category_item/get",
      {},
      res => {
        this.observeItemCategories = res.data.observation_category_items;
      }
    );
  }

  //並び替え
  private sort() {
    this.postJsonCheck(
      window.base_url + "/api/master/observation_category_item/save_order",
      {
        observation_category_items: this.observeItemCategories
      },
      () => {
        this.fetchObservationCategoryItems();
      }
    );
  }

  //新規作成
  private create() {
    this.selectedObserveItemCategory = initObserveItemCategory();
    this.isShownEditDialog = true;
  }

  //編集
  private edit(content: ObserveItemCategory) {
    this.selectedObserveItemCategory = content;
    this.isShownEditDialog = true;
  }

  //ダイアログ閉じる
  private close() {
    this.isShownEditDialog = false;
  }

  //保存
  private save(item: unknown) {
    this.postJsonCheck(
      window.base_url + "/api/master/observation_category_item/save",
      {
        observation_category_item: item
      },
      () => {
        this.fetchObservationCategoryItems();
        this.close();
      }
    );
  }

  //削除
  private deleteCategoryItem(item: unknown) {
    this.postJsonCheck(
      window.base_url + "/api/master/observation_category_item/delete",
      {
        observation_category_item: item
      },
      () => {
        this.fetchObservationCategoryItems();
        this.close();
      }
    );
  }

  //編集条件
  private deleteFunc(item: ObserveItemCategory) {
    return item.is_editable === 1;
  }
}
