















































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import AppPlaces from "#/components/AppPlaces.vue";
import { SameBuilding, DefaultSameBuilding } from "#/model/uniquemaster";
import EditMixin from "@/mixins/editMixin";

@Component({
  components: {
    AppPlaces
  }
})
export default class FacilityEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin,
  EditMixin
) {
  @Ref("form") private readonly form!: VForm;

  public facility: SameBuilding = DefaultSameBuilding();

  // public created(): void {
  //   if (this.$route.params.id && Number(this.$route.params.id) > 0)
  //     this.getBuilding(Number(this.$route.params.id));
  // }

  created() {
    if (this.$route.params.id && Number(this.$route.params.id) > 0) {
      this.getBuilding(Number(this.$route.params.id));
    } else {
      this.setLoaded();
    }
  }

  private getBuilding(id: number): void {
    this.postJsonCheck(
      window.base_url + "/api/master/same_building/get",
      {
        same_building_id: id
      },
      res => {
        this.facility = res.data.same_building;
        this.setLoaded();
      }
    );
  }

  public async save(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/same_building/save",
      {
        same_building: this.facility
      },
      () => {
        this.setNoEditMode();
        this.$router.push({ name: "FacilityList" });
      }
    );
  }

  public async deleteSameBuilding(): Promise<void> {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/same_building/delete",
      {
        same_building: this.facility
      },
      () => {
        this.setNoEditMode();
        this.$router.push({ name: "FacilityList" });
      }
    );
  }

  private requiredBuildingKind(): boolean | string {
    if (this.facility.building_kind > 0) return true;
    return "必須です";
  }

  private requiredName(): boolean | string {
    if (this.facility.name.length > 0) return true;

    return this.required(this.facility.name, "必須です");
  }

  private requiredDisplayName(): boolean | string {
    if (this.facility.display_name.length > 0) return true;

    return this.required(this.facility.display_name, "必須です");
  }

  //編集モード設定
  @Watch("facility", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
