var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"header",staticClass:"ibow2-visit-header header ma-0 pa-0"},[_c('v-row',{staticClass:"ibow2-visit-header-menu pb-2 mb-4",attrs:{"no-gutters":"","align":"center"}},[(!_vm.isNeedTitleNextLine)?_c('AppTitle',{staticClass:"ibow2-visit-header-title text-no-wrap pr-4",style:({ maxWidth: _vm.maxWidthTitle })},[_c('span',{staticClass:"d-inline-block text-truncate"},[_vm._v(_vm._s(_vm.Title))])]):_vm._e(),(_vm.isShowIconArea)?_c('v-row',{staticClass:"ibow2-visit-header-icon flex-grow-0 px-2 rounded-pill",attrs:{"no-gutters":"","align":"center","justify":"center"}},[(_vm.info.is_med)?_c('v-chip',{staticClass:"ibow2-visit-header-icon-item px-2 mx-1 rounded-pill font-weight-bold",attrs:{"color":"orange","label":"","dark":""}},[_vm._v(" 医 ")]):_vm._e(),(_vm.info.is_emargency)?_c('v-chip',{staticClass:"ibow2-visit-header-icon-item px-2 mx-1 rounded-pill font-weight-bold",attrs:{"color":"blue","label":"","dark":""}},[_vm._v(" 急 ")]):_vm._e(),(_vm.isHonobonoAgreement && !_vm.info.is_honobono)?_c('v-chip',{staticClass:"ibow2-visit-header-icon-item px-2 mx-1 rounded-pill font-weight-bold",attrs:{"color":"green darken-1","label":"","dark":""}},[_vm._v(" 未連携 ")]):_vm._e(),_vm._l((_vm.info.group_icons),function(icon,idx){return _c('AppToolTip',{key:("group-icon-" + idx),attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"px-2 py-1"},'span',attrs,false),on),[(icon.path)?_c('v-img',{attrs:{"max-height":"20","max-width":"20","src":icon.path}}):_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(icon.name))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(icon.name))])])}),(
            _vm.info.covid19_vaccine_status_icon.path &&
              _vm.agreementUserSetting.covid19_vaccine_status_display > 0
          )?_c('AppToolTip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mx-1"},'span',attrs,false),on),[_c('v-img',{attrs:{"max-height":"30","max-width":"30","src":_vm.info.covid19_vaccine_status_icon.path}})],1)]}}],null,false,942321108)},[_c('span',[_vm._v(_vm._s(_vm.info.covid19_vaccine_status_icon.name))])]):_vm._e()],2):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"ibow2-visit-header-menu-item pl-2 rounded-lg"},[_c('AppButton',{attrs:{"id":"patient-info-dialog-open","color":"stroke","outlined":"","height":"40px","width":"40px"},on:{"click":function($event){return _vm.patientInfoDialog.open()}}},[_c('AppIcon',{attrs:{"icon":"detail","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)],1),_c('div',{staticClass:"ibow2-visit-header-menu-item pl-2 rounded-lg"},[_c('AppButton',{attrs:{"id":"patient-memo-dialog-open","color":"stroke","outlined":"","height":"40px","width":"40px"},on:{"click":function($event){return _vm.patientMemoDialog.open()}}},[_c('AppIcon',{attrs:{"icon":"chat-1","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)],1),_vm._l((_vm.linkShowReports),function(link,linkNo){return [_c('div',{key:linkNo,staticClass:"ibow2-visit-header-menu-hidable pl-2 rounded-lg"},[_c('AppButton',{staticClass:"px-3",attrs:{"disabled":!link.isEnable,"id":("access-" + linkNo),"color":"stroke","outlined":"","height":"40px"},on:{"click":link.access}},[(link.prependIcon)?_c('AppIcon',{staticClass:"ml-n1 mr-2",attrs:{"icon":link.prependIcon,"fill":_vm.$vuetify.theme.themes.light.greyLight,"height":"22","width":"22"}}):_vm._e(),_c('span',{staticClass:"font-weight-bold grey-light--text"},[_vm._v(_vm._s(link.label))])],1)],1)]}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',{staticClass:"ibow2-visit-header-menu-expander pl-2 rounded-lg"},[_c('AppButton',_vm._g(_vm._b({attrs:{"id":"attrs","color":"stroke","outlined":"","height":"40px","width":"40px"}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"more-horiz","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)],1)]}}])},[_c('v-list',_vm._l((_vm.linkSecondShowReports),function(menuItem,j){return _c('v-list-item',{key:j,attrs:{"disabled":!menuItem.isEnable},on:{"click":menuItem.access}},[_c('v-list-item-title',[_vm._v(_vm._s(menuItem.label))])],1)}),1)],1)],2),(_vm.isNeedTitleNextLine)?_c('AppTitle',{staticClass:"text-no-wrap mb-6 pr-4"},[_c('span',{staticClass:"d-inline-block text-truncate"},[_vm._v(_vm._s(_vm.Title))])]):_vm._e(),_c('PatientInfoDialog',{ref:"patientInfoDialog",attrs:{"prefix_id":"patient-info-dialog"}}),_c('PatientMemoDialog',{ref:"patientMemoDialog",attrs:{"prefix_id":"patient-memo-dialog","patientId":_vm.patientId}}),_c('VisitMemoDialog',{ref:"visitMemoDialog",attrs:{"prefix_id":"visit-memo-dialog","visitInfo":_vm.visitInfo,"patientName":_vm.info.name,"mode":_vm.mode,"isDispMemoEnd":_vm.isDispMemoEnd},on:{"update:isDispMemoEnd":function($event){_vm.isDispMemoEnd=$event},"update:is-disp-memo-end":function($event){_vm.isDispMemoEnd=$event}}})],1),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }