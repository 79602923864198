














































import { Component, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import AxiosMixin from "@/mixins/axiosMixin";

interface InfoItems {
  id: number;
  date: string;
  title: string;
  content?: string;
  icon: string;
  staff_name?: string;
}

@Component({})
export default class ContactNoticeList extends Mixins(AxiosMixin) {
  // テーブルヘッダー
  private headers: DataTableHeader[] = [
    {
      text: "概要",
      value: "title",
      align: "start"
    },
    { text: "アイコン", value: "icon", align: "end" }
  ];
  // サポートからのお知らせ情報
  private supportItems: InfoItems[] = [];

  // 未読数
  private unread = 0;

  private created() {
    this.getNotice();
  }

  // 各お知らせクリック
  private show(item: InfoItems): void {
    this.$router.push({ path: `/bbs/notice/${item.id}` });
  }

  // お知らせ一覧取得
  private getNotice(): void {
    this.postJsonCheck(window.base_url + "/api/bbsnoticelist/get", {}, res => {
      this.supportItems = res.data.support_items;
      this.supportItems.forEach(item => {
        if (item.icon === "未読") this.unread++;
      });
    });
  }
}
