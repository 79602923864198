import Base from "./base";
import { Pdf, Remand, DefaultRemand } from "./report";
import {
  ApplyStaff,
  DefaultApplyStaff,
  ApplyCareContent,
  DefaultApplyCareContent
} from "./common";

//看護計画書目標
export interface VnPlanMark extends Base {
  id: number; //ID
  vn_plan_id: number; //'看護計画書ID',
  sort_no: number; //'並び順',
  time_type: number; //'期間種別\n1:長期目標\n2:短期目標',
  mark_type: number; //'目標種別\n1:看護\n2:リハビリ\n3:看護・リハビリ',
  mark: string; //'目標',
}
export const DefaultVnPlanMark = (): VnPlanMark => ({
  id: 0,
  vn_plan_id: 0,
  sort_no: 0,
  time_type: 0,
  mark_type: 0,
  mark: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//看護計画書問題点・解決策・評価 看護内容
export interface VnPlanProblemCareContent extends Base {
  id: number; //ID
  vn_plan_problem_id: number; //'看護計画書問題点ID',
  sort_no: number; //'並び順',
  care_large_content_id: number; //'看護内容大項目ID',
  care_medium_content_id: number; //'看護内容中項目ID',
  care_text: string; //'看護内容テキスト'
  care_content: ApplyCareContent; //看護内容項目
}
export const DefaultVnPlanProblemCareContent = (): VnPlanProblemCareContent => ({
  id: 0,
  vn_plan_problem_id: 0,
  sort_no: 0,
  care_large_content_id: 0,
  care_medium_content_id: 0,
  care_text: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  care_content: DefaultApplyCareContent()
});

//看護計画書問題点・解決策・評価
export interface VnPlanProblem extends Base {
  id: number; //ID
  vn_plan_id: number; //看護計画書ID
  sort_no: number; //並び順
  target_date: string; //対象日
  problem: string; //問題点
  solution: string; //解決策
  consideration: string; //留意事項
  evaluation: string; //評価
  target_date_wareki: string; //対象日（和暦）
  care_contents: VnPlanProblemCareContent[]; //看護内容
}
export const DefaultVnPlanProblem = (): VnPlanProblem => ({
  id: 0,
  vn_plan_id: 0,
  sort_no: 0,
  target_date: "",
  problem: "",
  solution: "",
  consideration: "",
  evaluation: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  target_date_wareki: "",
  care_contents: []
});

/** 看護計画書差戻し */
export interface VnPlanRemand extends Remand {
  vn_plan_id: number; //看護計画書ID
}
export const DefaultVnPlanRemand = (): VnPlanRemand => ({
  ...DefaultRemand(),
  vn_plan_id: 0
});

//看護計画書宛名
export interface VnPlanSend extends Base {
  id: number; //ID
  vn_plan_id: number; //看護計画書ID
  sort_no: number; //並び順
  support_office_div: number; //支援事業所区分\n1:居宅介護支援事業所\n2:情報提供先\n3:地域包括支援センター\n4:その他
  personnel_id: number; //担当者ID
  personnel: ApplyStaff; //担当者
}
export const DefaultVnPlanSend = (): VnPlanSend => ({
  id: 0,
  vn_plan_id: 0,
  sort_no: 0,
  support_office_div: 0,
  personnel_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  personnel: DefaultApplyStaff()
});

//看護計画書
export interface VnPlan extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  yearmonth: string; //'計画月',
  mark: string; //'看護／リハビリ目標',
  sanitation_flg: number; //'衛生材料等が必要な処置の有無',
  treatment: string; //'処置の内容',
  sanitation_type_size: string; //'衛生材料(種類・サイズ)等',
  sanitation_amount: string; //'必要量',
  notes: string; //'備考',
  write_date: string; //'記載日',
  write_staff1_id: number; //'記載者1',
  write_staff2_id: number; //'記載者2',
  visit_plan: string; //'訪問予定職種',
  staff1_id: number; //'担当者1',
  staff2_id: number; //'担当者2',
  staff3_id: number; //'担当者3',
  staff4_id: number; //'担当者4',
  care_personnel_id: number; //ケアマネ担当者ID
  patient_show_flag: number; //'利用者同意欄に利用者名を記載する',
  submit_datetime_wareki: string; //提出日時（和暦）
  yearmonth_wareki: string; //計画月（和暦）
  write_date_wareki: string; //記載日（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  marks: VnPlanMark[]; //目標
  pdf: Pdf[]; //PDF
  problems: VnPlanProblem[]; //問題
  remand: VnPlanRemand; //差し戻し
  sends: VnPlanSend[]; //宛名
  editor_staff: ApplyStaff; //編集者
  write_staff1: ApplyStaff; //記載者1
  write_staff2: ApplyStaff; //記載者2
  staff1: ApplyStaff; //担当者1
  staff2: ApplyStaff; //担当者2
  staff3: ApplyStaff; //担当者3
  staff4: ApplyStaff; //担当者4
}
export const DefaultVnPlan = (): VnPlan => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  submit_datetime: "",
  yearmonth: "",
  mark: "",
  sanitation_flg: -1,
  treatment: "",
  sanitation_type_size: "",
  sanitation_amount: "",
  notes: "",
  write_date: "",
  write_staff1_id: 0,
  write_staff2_id: 0,
  visit_plan: "",
  staff1_id: 0,
  staff2_id: 0,
  staff3_id: 0,
  staff4_id: 0,
  care_personnel_id: 0,
  patient_show_flag: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  submit_datetime_wareki: "",
  yearmonth_wareki: "",
  write_date_wareki: "",
  updated_at_wareki: "",
  marks: [],
  pdf: [],
  problems: [],
  remand: DefaultVnPlanRemand(),
  sends: [DefaultVnPlanSend()],
  editor_staff: DefaultApplyStaff(),
  write_staff1: DefaultApplyStaff(),
  write_staff2: DefaultApplyStaff(),
  staff1: DefaultApplyStaff(),
  staff2: DefaultApplyStaff(),
  staff3: DefaultApplyStaff(),
  staff4: DefaultApplyStaff()
});
