
































import { Component } from "vue-property-decorator";
import ContactNoticeList from "@/components/contact/ContactNoticeList.vue";
import ContactList from "@/components/contact/ContactList.vue";
import FAQList from "@/components/contact/FAQList.vue";
import { ListInquiry } from "@/views/contact/types";
import ContactUtility from "@/views/contact/utility";
import { BreadItem } from "@/store/modules/patient/types";

@Component({
  components: { ContactNoticeList, ContactList, FAQList }
})
export default class ContactHistory extends ContactUtility {
  /** パンくずリスト */
  private breadItems: BreadItem[] = [
    {
      text: "サポート",
      disabled: false,
      to: "/contact",
      exact: true
    },
    {
      text: "お問合せ",
      disabled: true,
      to: ""
    }
  ];

  /** 問合せ情報 */
  private inquiries: ListInquiry[] = [];

  created(): void {
    this.fetchInquiries();
  }

  /** 問合せ取得 */
  private fetchInquiries() {
    this.postJsonCheck(window.base_url + "/api/inquiries/get", {}, res => {
      const resData: ListInquiry[] = res.data.inquiries;

      // メタデータ削除
      this.inquiries = resData.map(inquiry => {
        inquiry.normalized_inquiry_content = this.trimMetaData(
          inquiry.normalized_inquiry_content
        );

        return inquiry;
      });
    });
  }

  /** FAQトップへ戻るボタンクリック時 */
  private goFAQTop() {
    this.$router.push({ path: "/contact" });
  }
}
