import { render, staticRenderFns } from "./PasswordRemind.vue?vue&type=template&id=4f8d2f6a&scoped=true&"
import script from "./PasswordRemind.vue?vue&type=script&lang=ts&"
export * from "./PasswordRemind.vue?vue&type=script&lang=ts&"
import style0 from "./PasswordRemind.vue?vue&type=style&index=0&id=4f8d2f6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8d2f6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VForm,VImg,VRow})
