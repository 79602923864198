import { Component, Mixins } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { SearchCondFAQ, SearchQueryFAQ } from "@/views/contact/types";

@Component
export default class ContactUtility extends Mixins(AxiosMixin, UtilMixin) {
  public trimMetaData(content: string): string {
    const prefix = "[問合せ内容]";
    const targetIndex = content.indexOf(prefix);
    if (targetIndex >= 0) {
      // 対象文字列が見つかった場合、その文字列以降を問合せ本文とする。なければ、そのまま
      content = content.substring(targetIndex + prefix.length + 1);
    }
    return content;
  }

  /** FAQ記事検索画面のURLパラメータを取得する */
  public getFAQUrlQuery(
    customQuery: Partial<SearchQueryFAQ>
  ): Dictionary<string | (string | null)[] | null | undefined> {
    const defaultQuery: SearchQueryFAQ = {
      keyword: "",
      andor: "1",
      category_id: "0",
      hashtag: ""
    };
    return { ...defaultQuery, ...customQuery };
  }

  /** 検索条件をURLパラメータに変換する */
  public searchCondToQuery(searchCond: SearchCondFAQ): SearchQueryFAQ {
    return {
      keyword: searchCond.keyword,
      andor: String(searchCond.keyword_operator),
      category_id: String(searchCond.category_id),
      hashtag: searchCond.hashtag
    };
  }
}
