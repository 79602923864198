





























import { Component, Prop, Watch, Emit, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class OfficeSelectInput extends Mixins(UtilMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: () => [] }) selectedOffice!: number[]; //選択済みの事業所

  private allOffices = true; //全てのステーション

  @Watch("allOffices")
  private watchAllOffices() {
    if (this.allOffices) {
      this.SelectedOffice = [];
    }
  }

  private get SelectedOffice() {
    this.allOffices = this.selectedOffice.length == 0;
    return this.selectedOffice;
  }

  private set SelectedOffice(office: number[]) {
    this.allOffices = office.length == 0;
    this.setSelectedOffice(office);
  }

  @Emit("update:selectedOffice")
  private setSelectedOffice(office: number[]) {
    return office;
  }
}
