

















































import { Component, Mixins } from "vue-property-decorator";
import {
  OutputCsvItem,
  OUTPUT_CSV_ITEMS,
  OutputCsvSearchObj
} from "@/views/master/normal_csv/types";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {}
})
export default class NormalCsvOutput extends Mixins(AxiosMixin, UtilMixin) {
  /** CSV出力項目情報 */
  private OUTPUT_CSV_ITEMS = OUTPUT_CSV_ITEMS;

  /** 選択中項目番号 */
  private selectedItem = 1;

  /** 検索条件 */
  private searchObj: OutputCsvSearchObj = {
    target_date: this.dateToStr(new Date(), "yyyy-MM-dd"),
    target_yearmonth: this.dateToStr(new Date(), "yyyy-MM")
  };

  /** 選択中項目情報 */
  private get selectedOutputCsvItemInfo(): OutputCsvItem {
    return this.OUTPUT_CSV_ITEMS.filter(
      (item: OutputCsvItem) => item.value == this.selectedItem
    )[0];
  }

  /** 出力処理 */
  private output(isTemplate: boolean) {
    const fileName = this.getFileName();

    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/master/dataio/csv/output",
      {
        item_type: this.selectedItem,
        is_template: isTemplate,
        ...this.searchObj
      },
      res => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }

  /** ファイル名取得 */
  private getFileName(): string {
    const prefix = this.selectedOutputCsvItemInfo.file_name;
    const suffix = this.dateToStr(new Date(), "yyyyMMddHHmmss");
    const fileName = `${prefix}_${suffix}.csv`;

    return fileName;
  }

  /** 選択項目変更時はリセットをかける */
  private reset() {
    this.searchObj = {
      target_date: this.dateToStr(new Date(), "yyyy-MM-dd"),
      target_yearmonth: this.dateToStr(new Date(), "yyyy-MM")
    };
  }
}
