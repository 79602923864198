import Base from "../base";

//公費
export interface PublicMoneyKind extends Base {
  id: number;
  agreement_id: number;
  insurance_div: number;
  no: number;
  name: string;
  payment_rate: number;
  priority: number;
  is_daily: number;
  is_receipt_link: number;
  is_add_user: number;
  is_end_date_requeired: number;
  is_change_payment_rate: number;
  honobono_add_flag: number;
}

export const DefaultPublicMoneyKind = (): PublicMoneyKind => ({
  id: 0,
  agreement_id: 0,
  insurance_div: 0,
  no: 0,
  name: "",
  payment_rate: 0,
  priority: 0,
  is_daily: 0,
  is_receipt_link: 1,
  is_add_user: 1,
  is_end_date_requeired: 0,
  is_change_payment_rate: 0,
  honobono_add_flag: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
