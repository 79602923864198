






















































































import { Component, Mixins } from "vue-property-decorator";
import EditPrimaryContentDialog from "@/components/master/adlcontent/EditPrimaryContentDialog.vue";
import EditSecondaryContentDialog from "@/components/master/adlcontent/EditSecondaryContentDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  ObservationItem,
  ObservationSelectItem,
  Office
} from "@/views/master/adlcontent/types";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import { DataTableHeader } from "vuetify";
import MasmenMixin from "@/mixins/masmenMixin";

/** 観察項目カテゴリ */
interface ObservationCategory {
  id: number;
  is_editable: number;
  observation_items: ObservationItem[];
}

@Component({
  components: {
    EditPrimaryContentDialog,
    EditSecondaryContentDialog,
    CommonMasterList
  }
})
export default class AdlContentList extends Mixins(AxiosMixin, MasmenMixin) {
  private isShownEditPrimaryContentDialog = false;
  private isShownEditSecondaryContentDialog = false;
  private selectedPrimaryContent?: ObservationItem = undefined;
  private selectedSecondaryContent?: ObservationSelectItem = undefined;
  private primaryContents: ObservationItem[] = [];
  private observeItemCategories: ObservationCategory[] = [];

  private headers: DataTableHeader[] = [
    {
      text: "観察項目",
      value: "name"
    },
    {
      text: "観察項目カテゴリ",
      value: "category_name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    {
      text: "",
      value: "observation_select_items",
      width: "1px",
      sortable: false
    }
  ];

  private subHeaders: DataTableHeader[] = [
    {
      text: "選択肢",
      value: "name"
    }
  ];

  // 観察項目カテゴリの内、観察項目を追加可能なもの
  private get observationCategoryAddable() {
    return this.observeItemCategories.filter(category => {
      if (this.IsNursingCategory) {
        // ログインユーザーの所属事業所設定が「登録数制限を解除する」であれば、数の制限はないが編集可能フラグの影響を受ける
        return category.is_editable;
      } else {
        // 「観察項目最大21項目」であれば、21項目未満なら編集可能フラグに関わらず追加可能
        return category.observation_items.length < 21;
      }
    });
  }

  // 観察項目カテゴリの内、編集可能なもの
  private get observationCategoryEditable() {
    if (this.IsNursingCategory) {
      return this.observeItemCategories.filter(item => item.is_editable);
    }
    return this.observeItemCategories;
  }

  // 観察項目が属するカテゴリ
  private get matchObservationCategory() {
    return (item: ObservationItem) => {
      return this.observeItemCategories.find(cat => {
        return cat.id === item.observation_category_item_id;
      });
    };
  }

  // 観察項目の内、編集可能なもの
  private get primariesEditable() {
    return this.primaryContents.filter(this.itemEditable);
  }
  // 観察項目の内、編集不可なもの
  private get primariesNotEditable() {
    return this.primaryContents.filter(item => !this.itemEditable(item));
  }

  // 観察項目を編集できるか
  private itemEditable(item: ObservationItem | ObservationSelectItem) {
    let primaryItem: ObservationItem | undefined;
    if ("observation_item_id" in item) {
      // セカンダリ(サブ)メニューの場合
      primaryItem = this.primaryContents.find(primary => {
        return primary.id === item.observation_item_id;
      });
    } else {
      // プライマリメニューの場合
      primaryItem = item;
    }
    if (primaryItem == null) {
      // 観察項目を取得できなければ、編集可能か判断し得ない
      return false;
    }
    const matchCategory = this.matchObservationCategory(primaryItem);
    if (matchCategory == null) {
      // 観察項目カテゴリを取得できなければ、編集可能か判断し得ない
      return false;
    }
    // ログインユーザーの所属事業所設定が「観察項目最大21項目」であれば、どれも編集できる
    if (!this.IsNursingCategory) {
      return primaryItem;
    }
    // 編集可能フラグが立っていれば、編集できる
    if (matchCategory.is_editable) {
      return primaryItem;
    } else {
      return false;
    }
  }

  public created(): void {
    this.fetchObservationItems();
    this.fetchObservationCategoryItems();
  }

  //観察項目取得
  private fetchObservationItems() {
    this.postJsonCheck(
      window.base_url + "/api/master/observation_item/get",
      {},
      res => {
        this.primaryContents = res.data.observation_items;
      }
    );
  }

  //観察項目カテゴリ取得
  private fetchObservationCategoryItems(): Promise<void> {
    return new Promise(resolve => {
      // computedを更新させるために一旦空にする
      this.observeItemCategories.splice(0);
      this.postJsonCheck(
        window.base_url + "/api/master/observation_category_item/get",
        {},
        res => {
          this.observeItemCategories = res.data.observation_category_items;
          resolve();
        }
      );
    });
  }

  //観察項目保存
  private async save(
    item: ObservationItem,
    isSaveSelectItem = false,
    selectItem: ObservationSelectItem
  ) {
    // 新規作成/コピー時(id===0)、観察項目カテゴリが選択されていなければ、アラートを出す
    const primaryIdInAddableCat = this.observationCategoryAddable.find(
      // 既存観察項目からのコピーであっても、そのカテゴリにもう追加できない場合は空欄になる
      cat => cat.id === item.observation_category_item_id
    );
    if (!item.id && primaryIdInAddableCat === undefined) {
      await this.$openAlert("観察項目カテゴリを選択してください");
      return;
    }
    // 新規作成/コピー時(id===0)、同一カテゴリ内に同じ名前の観察項目があれば、アラートを出す
    const primaryDupName = this.primaryContents.find(
      p =>
        p.observation_category_item_id === item.observation_category_item_id &&
        p.name === item.name &&
        p.id !== item.id
    );
    if (primaryDupName !== undefined) {
      await this.$openAlert(
        "観察項目カテゴリと観察項目の組み合わせが重複しています。"
      );
      return;
    }

    // 保存対象が項目か選択肢かでAPIの呼び先を変える
    const apiUrl =
      isSaveSelectItem === true
        ? "/api/master/observation_select_item/save"
        : "/api/master/observation_item/save";

    this.postJsonCheck(
      window.base_url + apiUrl,
      {
        observation_item: item,
        observation_select_item: selectItem
      },
      () => {
        this.fetchObservationCategoryItems().then(() => {
          this.fetchObservationItems();
        });
        this.clickCloseEditPrimaryContentDialogButton();
        this.clickCloseEditSecondaryContentDialogButton();
      }
    );
  }

  //選択肢保存
  private async saveSecondaryContent(content: ObservationSelectItem) {
    const primary = this.primaryContents.find(
      p => p.id === content.observation_item_id
    );
    if (primary === undefined) {
      await this.$openAlert("観察項目を選択してください");
      return;
    }
    // 新規作成/コピー時(id===0)、同一観察項目内に同じ名前の選択肢があれば、アラートを出す
    const dupNameChoice = primary.observation_select_items.find(
      secondary =>
        secondary.name === content.name && secondary.id !== content.id
    );
    if (dupNameChoice !== undefined) {
      await this.$openAlert(
        "観察項目と観察項目選択内容の組み合わせが重複しています。"
      );
      return;
    }

    if (content.id) {
      const targetChoice = primary.observation_select_items.find(
        item => item.id === content.id
      );
      if (targetChoice !== undefined) {
        targetChoice.name = content.name;
      }
    } else {
      if (!primary.observation_select_items) {
        primary.observation_select_items = [];
      }
      primary.observation_select_items.push(content);
    }
    this.save(primary, true, content);
  }

  //観察項目削除
  private deletePrimaryContent(content: unknown): void {
    this.postJsonCheck(
      window.base_url + "/api/master/observation_item/delete",
      {
        observation_item: content
      },
      () => {
        this.fetchObservationCategoryItems().then(() => {
          this.fetchObservationItems();
        });
        this.clickCloseEditPrimaryContentDialogButton();
      }
    );
  }

  //選択肢削除
  private deleteSecondaryContent(content: ObservationSelectItem): void {
    const index = this.primaryContents.findIndex(
      item => item.id === content.observation_item_id
    );
    if (index > -1) {
      this.primaryContents[
        index
      ].observation_select_items = this.primaryContents[
        index
      ].observation_select_items.filter(item => item.id != content.id);
      this.postJsonCheck(
        window.base_url + "/api/master/observation_select_item/delete",
        {
          observation_item: this.primaryContents[index],
          observation_select_item: content
        },
        () => {
          this.fetchObservationCategoryItems().then(() => {
            this.fetchObservationItems();
          });
          this.clickCloseEditSecondaryContentDialogButton();
        }
      );
    }
  }

  //並び順保存
  private clickToSortModeButton() {
    this.postJsonCheck(
      window.base_url + "/api/master/observation_item/save_order",
      {
        observation_items: this.primaryContents
      },
      () => {
        this.fetchObservationItems();
      }
    );
  }

  //コピー
  private clickCopyPrimaryContentButton(content: ObservationItem) {
    const offices: Office[] = [];
    const selectItems: ObservationSelectItem[] = [];
    const copyContent: ObservationItem = {
      id: 0,
      category_name: content.category_name,
      name: content.name,
      observation_category_item_id: content.observation_category_item_id,
      observation_item_offices: offices,
      observation_select_items: selectItems,
      sort_no: 0
    };

    if (content.observation_item_offices) {
      content.observation_item_offices.forEach(office => {
        copyContent.observation_item_offices.push({
          office_id: office.office_id
        });
      });
    }

    if (content.observation_select_items) {
      content.observation_select_items.forEach(item => {
        const copyItem = { ...item };
        copyItem.id = 0;
        copyContent.observation_select_items.push(copyItem);
      });
    }

    this.selectedPrimaryContent = copyContent;
    this.isShownEditPrimaryContentDialog = true;
  }

  //大項目を新規作成
  private clickCreateNewPrimaryItemButton() {
    this.selectedPrimaryContent = {
      id: 0,
      name: "",
      category_name: "",
      sort_no: 0,
      observation_category_item_id: 0,
      observation_select_items: [],
      observation_item_offices: []
    };
    if (!this.IsNursingCategory) {
      this.selectedPrimaryContent.observation_category_item_id = this.observationCategoryAddable[0].id;
    }
    this.isShownEditPrimaryContentDialog = true;
  }

  //中項目を新規作成
  private clickCreateNewSecondaryItemButton() {
    this.selectedSecondaryContent = undefined;
    this.isShownEditSecondaryContentDialog = true;
  }

  //大項目を編集
  private clickEditPrimaryContentButton(content: ObservationItem) {
    this.selectedPrimaryContent = content;
    this.isShownEditPrimaryContentDialog = true;
  }

  //中項目を編集
  private clickEditSecondaryButton(content: ObservationSelectItem) {
    this.selectedSecondaryContent = content;
    this.isShownEditSecondaryContentDialog = true;
  }

  //大項目ダイアログ閉じる
  private clickCloseEditPrimaryContentDialogButton() {
    this.isShownEditPrimaryContentDialog = false;
  }

  //中項目ダイアログ閉じる
  private clickCloseEditSecondaryContentDialogButton() {
    this.isShownEditSecondaryContentDialog = false;
  }
}
