


































































































import { Mixins, Component, Prop, Ref, Watch } from "vue-property-decorator";
import { VForm, SortTypeSelect } from "@/types";
import { Link } from "#/types";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import EditMixin from "@/mixins/editMixin";
import PdfLinkMixin from "@/mixins/pdfLinkMixin";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import PdfLinkItem from "@/components/patient/pdflink/PdfLinkItem.vue";
import {
  PDF_LINK_STATUS,
  DefaultSearchCond
} from "@/components/patient/pdflink/list";
import { PdfLink } from "#/model/patient/pdfLink";

@Component({
  components: {
    PdfLinkItem
  }
})
export default class PatientPdfLinkMailList extends Mixins(
  UtilMixin,
  PatientMixin,
  AxiosMixin,
  EditMixin,
  PdfLinkMixin
) {
  /** 利用者ヘッダー */
  @Prop() readonly pheader!: PatientHeader;

  @Ref("formSearch") private readonly formSearch!: VForm;

  /** PDFリンクメールステータス */
  private readonly PDF_LINK_STATUS = PDF_LINK_STATUS;

  private patientId = 0;

  /** 左サイドメニューアイテム */
  private links = [
    { text: "下書き", key: "Save" },
    { text: "送信済み", key: "Send" }
  ];

  /** 現在有効なメニュー */
  private link = this.links[0];

  /** PDFリンク情報一覧 */
  private pdfLinks: PdfLink[] = [];

  /** 検索条件 */
  private searchCond = DefaultSearchCond();
  /** 検索条件をリセットする */
  private resetSearchCond() {
    this.searchCond = DefaultSearchCond();
  }

  /** 検索条件：PDFリンクステータス */
  private get SearchCondStatus() {
    switch (this.link.key) {
      default:
        // 下書きの場合
        return PDF_LINK_STATUS.SAVE;
      case "Send":
        // 送信済みの場合
        return PDF_LINK_STATUS.SEND;
    }
  }

  /** 絞り込み検索しているか */
  private get IsFiltered() {
    const defaultCond = DefaultSearchCond();
    return (
      this.searchCond.send_start !== defaultCond.send_start ||
      this.searchCond.send_end !== defaultCond.send_end
    );
  }

  /** ソート方法リスト：保存済み */
  private sortRuleListSave: SortTypeSelect[] = [
    { text: "編集日 - 新しい順", sort: 0, sortType: 0, select: "編集日順" },
    { text: "編集日 - 古い順", sort: 0, sortType: 1, select: "編集日順" }
  ];
  /** ソート方法リスト：送信済み */
  private sortRuleListSend: SortTypeSelect[] = [
    { text: "送信日 - 新しい順", sort: 1, sortType: 0, select: "送信日順" },
    { text: "送信日 - 古い順", sort: 1, sortType: 1, select: "送信日順" }
  ];
  /** 選択したソート方法：保存済み */
  private sortBySave = this.sortRuleListSave[0];
  /** 選択したソート方法：送信済み */
  private sortBySend = this.sortRuleListSend[0];
  /** ソート方法リスト */
  private get SortRuleList() {
    switch (this.SearchCondStatus) {
      case PDF_LINK_STATUS.SAVE:
        // 下書きの場合
        return this.sortRuleListSave;
      default:
        // 送信済みの場合
        return this.sortRuleListSend;
    }
  }

  /** 検索条件：ソート方法 */
  private get SortBy() {
    switch (this.SearchCondStatus) {
      case PDF_LINK_STATUS.SAVE:
        // 下書きの場合
        return this.sortBySave;
      default:
        // 送信済みの場合
        return this.sortBySend;
    }
  }
  /** ソート方法を変更 */
  private set SortBy(sortRule: SortTypeSelect) {
    switch (this.SearchCondStatus) {
      case PDF_LINK_STATUS.SAVE:
        // 下書きの場合
        this.sortBySave = sortRule;
        break;
      default:
        // 送信済みの場合
        this.sortBySend = sortRule;
        break;
    }
  }
  /** ソート方法を変更した時 */
  private changeSort(sortRule: SortTypeSelect) {
    this.SortBy = sortRule;
    this.onSubmitSearch();
  }

  /** 検索パネルを開いているか */
  private isOpenSearchPanel = false;
  /** 検索パネルを開く */
  private openSearchPanel() {
    this.isOpenSearchPanel = true;
  }

  created() {
    this.patientId = Number(this.$route.params.id);
    this.resetSearchCond();
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.key || this.$route.query.key === this.links[0].key) {
      this.fetchPdfLinks();
    }
  }

  mounted() {
    // 有効なリンクの切り替え
    this.changeLinkQuery();
    this.setDefaultHeader();
  }

  @Watch("link")
  watchLink() {
    this.onSubmitSearch();
  }

  //URLの変更で動作変更
  private changeLinkQuery() {
    if (typeof this.$route.query.key === "string") {
      const key = this.$route.query.key;
      for (const v of this.links) {
        if (v.key == key) {
          this.link = v;
        }
      }
    }
  }

  private setUrlParams({ link }: { link: Link }) {
    this.$router.push({
      query: { key: link.key }
    });
  }

  /** 検索リクエスト送信前にバリデーション：PDF一覧 */
  private async onSubmitSearch() {
    if (!this.formSearch.validate()) {
      await this.$openAlert("入力内容に不備があります");
      // 検索条件に不備があれば、パネルを開く
      this.openSearchPanel();
      return;
    }
    this.fetchPdfLinks();
  }

  /** PDFリンク情報一覧取得 */
  private fetchPdfLinks() {
    this.pdfLinks = [];
    this.postJsonCheck(
      window.base_url + "/api/patient/pdflinks/get",
      {
        patient_ids: [this.patientId],
        statuses: [this.SearchCondStatus],
        ...this.searchCond,
        sort: this.SortBy.sort,
        sort_type: this.SortBy.sortType
      },
      res => {
        if (res.data.pdf_links == null) {
          return;
        }
        this.pdfLinks = res.data.pdf_links;
      }
    );
  }

  /** 新規作成画面へ遷移 */
  private clickNew() {
    this.$router.push({
      name: "PatientPdfLinkEdit",
      params: { id: String(this.patientId), pdf_link_id: "0" }
    });
  }

  /** 編集画面へ遷移 */
  private routeEditPdfLink(pdfLinkId: number) {
    this.$router.push({
      name: "PatientPdfLinkEdit",
      params: { id: String(this.patientId), pdf_link_id: String(pdfLinkId) }
    });
  }

  /** 詳細画面へ遷移 */
  private routeDetailPdfLink(pdfLinkId: number) {
    this.$router.push({
      name: "PatientPdfLinkDetail",
      params: { id: String(this.patientId), pdf_link_id: String(pdfLinkId) }
    });
  }
}
