import Base from "./base";
import {
  ApplyStaff,
  DefaultApplyStaff,
  ApplyObservation,
  DefaultApplyObservation,
  ApplyCareContent,
  DefaultApplyCareContent,
  ApplyPatient,
  DefaultApplyPatient
} from "./common";
import { OwnExpense } from "./patient";
import { ReceiptVisitRecord } from "./receipt";
import { Remand, DefaultRemand } from "./report";

//訪問記録バイタル
export interface VisitRecordVital extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  vital_count: number; //'測定回数',
  body_temprature: number; //'体温',
  body_temprature_int: number; //'体温（整数部）',
  body_temprature_dec: number; //'体温（小数部）',
  breath: number; //'呼吸回数/分',
  breath_div: number; //'呼吸区分\n0:平静\n1:努力様',
  pulse: number; //'脈拍回数/分',
  pulse_div: number; //'脈拍区分\n0:平静\n1:努力様',
  bp_high_right: number; //'最高血圧（右腕）',
  bp_low_right: number; //'最低血圧（右腕）',
  bp_high_left: number; //'最高血圧（左腕）',
  bp_low_left: number; //'最低血圧（左腕）',
  spo2: number; //'SpO2（%）',
  spo2_amount: number; //'呼吸器酸素流量',
  loc_div: number; //'意識レベル\n1:良好\n2:Ⅰ－１\n3:Ⅰ－２\n4:Ⅰ－３\n5:Ⅱ－１０\n6:Ⅱ－２０\n7:Ⅱ－３０\n8:Ⅲ－１００\n9:Ⅲ－２００\n10:Ⅲ－１００',
  blood_sugar: number; //'血糖値',
  blood_sugar_timing: number; //'血糖値測定時間\n1:食前\n2:食後1時間未満\n3:食後2時間未満\n4:食後3時間未満\n5:食後4時間未満\n6:食後5時間未満\n7:食後6時間未満\n8:食後7時間未満\n9:食後8時間未満\n99:不明',
  weight: number; //'体重',
  abd_girth_navel: number; //'臍上腹囲',
  abd_girth: number; //'最大腹囲',
  tan_div: number; //'痰\n0:なし\n1:あり',
  lung_noise_div: number; //'肺副雑音\n0:なし\n1:あり',
  daily_life_rate_div: number; //'日常生活自立度\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2',
}
export const DefaultVisitRecordVital = (): VisitRecordVital => ({
  id: 0,
  visit_record_id: 0,
  vital_count: 0,
  body_temprature: 0,
  body_temprature_int: 99,
  body_temprature_dec: 99,
  breath: 0,
  breath_div: 0,
  pulse: 0,
  pulse_div: 0,
  bp_high_right: 0,
  bp_low_right: 0,
  bp_high_left: 0,
  bp_low_left: 0,
  spo2: 0,
  spo2_amount: 0,
  loc_div: 0,
  blood_sugar: 0,
  blood_sugar_timing: 0,
  weight: 0,
  abd_girth_navel: 0,
  abd_girth: 0,
  tan_div: 0,
  lung_noise_div: 0,
  daily_life_rate_div: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//訪問記録危険因子
export interface VisitRecordRiskFactor extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  basic_action1: number; //'基本動作能力（ベッド上 自立体位変換)\n0:未選択\n1:できる\n2:できない',
  basic_action2: number; //'基本動作能力（イス上 座位姿勢の保持、除圧)\n0:未選択\n1:できる\n2:できない',
  bone: number; //'病的骨突出\n0:未選択\n1:なし\n2:あり',
  joint: number; //'関節拘縮\n0:未選択\n1:なし\n2:あり',
  nutrition: number; //'栄養状態低下\n0:未選択\n1:なし\n2:あり',
  skin: number; //'皮膚湿潤(多汗、尿失禁、便失禁)\n0:未選択\n1:なし\n2:あり',
  edema: number; //'浮腫(局所以外の部位)\n0:未選択\n1:なし\n2:あり',
  skin_tear: number; //'皮膚の脆弱性(スキン―テアの保有、既往)\n0:未選択\n1:なし\n2:あり',
  jokuso_valuation: string; //'褥瘡の状態：危険因子の評価',
  copy_recent_evaluation_risk_factor: number; //危険因子の評価 前回参照フラグ\n1:参照する\n0:参照しない
}
export const DefaultVisitRecordRiskFactor = (): VisitRecordRiskFactor => ({
  id: 0,
  visit_record_id: 0,
  basic_action1: 0,
  basic_action2: 0,
  bone: 0,
  joint: 0,
  nutrition: 0,
  skin: 0,
  edema: 0,
  skin_tear: 0,
  jokuso_valuation: "",
  copy_recent_evaluation_risk_factor: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//訪問記録差し戻し
export interface VisitRecordRemand extends Remand {
  visit_record_id: number; //訪問記録ID
  upd_flg: number; // 編集して再送信フラグ
}
export const DefaultVisitRecordRemand = (): VisitRecordRemand => ({
  ...DefaultRemand(),
  visit_record_id: 0,
  upd_flg: 0
});

//訪問記録PDF
export interface VisitRecordPdf extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  pdf_path: string; //'PDFファイルパス',
}
export const DefaultVisitRecordPdf = (): VisitRecordPdf => ({
  id: 0,
  visit_record_id: 0,
  pdf_path: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//訪問記録観察項目
export interface VisitRecordObservation extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  sort_no: number; //'並び順',
  observation_category_item_id: number; //'観察項目カテゴリID',
  observation_item_id: number; //'観察項目ID',
  observation_select_item_id: number; //'観察項目選択肢ID',
  observation_comment: string; //'観察項目コメント',
  observation: ApplyObservation; //観察項目
}
export const DefaultVisitRecordObservation = (): VisitRecordObservation => ({
  id: 0,
  visit_record_id: 0,
  sort_no: 0,
  observation_category_item_id: 0,
  observation_item_id: 0,
  observation_select_item_id: 0,
  observation_comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  observation: DefaultApplyObservation()
});

//訪問記録褥瘡
export interface VisitRecordJokuso extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  jokuso_div: number; //'褥瘡区分\n1:仙骨部\n2:坐骨部\n3:尾骨部\n4:腸骨部\n5:大転子部\n6:踵部\n7:その他',
  jokuso_name: string;
  is_not_evaluate: number; //'評価しないフラグ',
  depth: number; //'深さ\n0:なし\n1:持続する発赤\n2:真皮までの損傷\n3:皮下組織までの損傷\n4:皮下組織をこえる損傷\n5:関節腔、体腔にいたる損傷\n99:深さ判定が不能な場合(U)',
  exudate: number; //'滲出液\n0:なし\n1:少量：毎日の交換を要しない\n3:中等量：1日1回の交換\n6:多量：1日2回以上の交換',
  size: number; //'大きさ\n0:皮膚損傷なし\n3:4未満\n6:4以上16未満\n8:16以上36未満\n9:36以上64未満\n12:64以上100未満\n15:100以上',
  infection: number; //'炎症・感染\n0:局所の炎症徴候なし\n1:局所の炎症徴候あり\n3:局所の明らかな感染徴候あり\n9:全身的な影響あり',
  granulation_tissue: number; //'肉芽形成\n0:創閉鎖又は創が浅い為評価不能\n1:創面の90％以上を占める\n3:創面の50％以上90％未満を占める\n4:創面の10%以上50％未満を占める\n5:創面の10％未満を占める\n6:全く形成されていない\n',
  necrotic_tissue: number; //'壊死組織\n0:なし\n3:柔らかい壊死組織あり\n6:硬く厚い密着した壊死組織あり\n',
  pocket: number; //'ポケット\n0:なし\n6:4未満\n9:4以上16未満\n12:16以上36未満\n24:36以上',
  copy_recent_evaluation_part: number; //前回参照フラグ\n1:参照する\n0:参照しない
}
export const DefaultVisitRecordJokuso = (): VisitRecordJokuso => ({
  id: 0,
  visit_record_id: 0,
  jokuso_div: 0,
  jokuso_name: "",
  is_not_evaluate: 0,
  depth: 0,
  exudate: 0,
  size: 0,
  infection: 0,
  granulation_tissue: 0,
  necrotic_tissue: 0,
  pocket: 0,
  copy_recent_evaluation_part: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//訪問記録GAF
export interface VisitRecordGaf extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  gaf_score: number; //'GAF評価点',
  is_family_visit: number; //'家族への訪問フラグ\n0:なし\n1:あり',
  gaf_text: string; //GAFテキスト
}
export const DefaultVisitRecordGaf = (): VisitRecordGaf => ({
  id: 0,
  visit_record_id: 0,
  gaf_score: 0,
  is_family_visit: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  gaf_text: ""
});

//訪問記録ファイル
export interface VisitRecordFile extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  file_type: number; //'写真種別\n1:訪問へ行く\n2:記録書Ⅱをスキャンする',
  path: string; //'画像パス',
  comment: string; //'コメント',
}
export const DefaultVisitRecordFile = (): VisitRecordFile => ({
  id: 0,
  visit_record_id: 0,
  file_type: 0,
  path: "",
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//訪問記録看護内容
export interface VisitRecordCareContent extends Base {
  id: number; //ID
  visit_record_id: number; //'訪問記録ID',
  sort_no: number; //'並び順',
  care_large_content_id: number; //'看護内容大項目ID',
  care_medium_content_id: number; //'看護内容中項目ID',
  care_content: ApplyCareContent; //看護内容項目
}
export const DefaultVisitRecordCareContent = (): VisitRecordCareContent => ({
  id: 0,
  visit_record_id: 0,
  sort_no: 0,
  care_large_content_id: 0,
  care_medium_content_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  care_content: DefaultApplyCareContent()
});

//訪問記録サービス時間
export interface VisitRecordServiceTime extends Base {
  id: number; //訪問記録_サービス提供時間ID
  agreement_id: number; //契約ID
  visit_record_id: number; //訪問記録ID
  sub_no: number; //連番
  service_start_datetime: string; //サービス開始日時
  service_end_datetime: string; //サービス終了日時
  service_start_datetime_wareki: string; //サービス開始日時（和暦）
  service_end_datetime_wareki: string; //サービス終了日時（和暦）
}
export const DefaultVisitRecordServiceTime = (): VisitRecordServiceTime => ({
  id: 0,
  agreement_id: 0,
  visit_record_id: 0,
  sub_no: 0,
  service_start_datetime: "",
  service_end_datetime: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  service_start_datetime_wareki: "",
  service_end_datetime_wareki: ""
});

//訪問記録
export interface VisitRecord extends Base {
  id: number; //ID
  agreement_id: number; //'契約ID',
  office_id: number; //'事業所ID',
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'記事編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  first_submit_datetime: string; //'初回提出日時',
  recent_submit_datetime: string; //'最新提出日時',
  departure_datetime: string; //'出発日時',
  invoice_yearmonth: string; //'請求対象年月',
  invoice_datetime: string; //'請求チェック日時',
  insurance_div: number; //'適用状態\n1:医療保険訪問\n2:介護保険訪問\n3:自費訪問',
  regular_visit_div: number; //'定期巡回訪問タイプ（定期巡回は介護保険のみ）\n0:定期巡回以外\n1:定期巡回\n2:随時巡回',
  mental_div: number; //'一般・精神区分\n1:訪問看護\n2:精神訪問看護（医療保険のみ）',
  care_div: number; //'介護・予防区分\n1:介護\n2:予防',
  visit_content_div: number; //'訪問内容\n1:計画\n2:緊急',
  visit_start_datetime: string; //'訪問開始日時',
  visit_end_datetime: string; //'訪問終了日時',
  service_start_datetime: string; //'サービス開始日時',
  service_end_datetime: string; //'サービス終了日時',
  staff_id: number; //'訪問者ID',
  service_time_div: number; //'提供時間区分\n介護保険/看護師・准看護師の場合\n1:訪看I1(20分未満)\n2:訪看I2(40分未満)\n3:訪看I3(30分以上60分未満)\n4:訪看I4(60分以上90分未満)\n5:訪看I4・長(90分以上)\n介護保険/OT・PT・ST（他ステーション訪問なし）の場合\n6:訪看I5(20分)\n7:訪看I5・2超(40分)\n8:訪看I5・2超(60分)\n9:訪看I5・2超(80分)\n10:訪看I5・2超(100分)\n11:訪看I5・2超(120分)\n介護保険/OT・PT・ST（他ステーション訪問あり）の場合\n12:訪看I5・2超(20分)\n13:訪看I5・2超(40分)\n医療保険の場合\n14:医療保険訪問',
  nursing_type: number; //訪問タイプ 1:サービス訪問（自費） 2:入院中の外泊訪問 3:加算のみ 4:専門看護師として同行を行う
  senmon_care_div: number; //'専門看護師訪問区分\n1:緩和ケア\n2:褥瘡ケア\n3:人工肛門／膀胱ケア',
  online_visit_flg: number; //'オンライン訪問フラグ',
  is_leave_joint: number; //'退院時共同指導加算',
  leave_joint_date: string; //'退院時共同指導日',
  time_type: number; //訪問時間区分 1:夜間訪問看護 2:早朝訪問看護 3:深夜訪問看護
  is_long_care: number; //'長時間訪問看護（医療のみ）',
  is_emergency_care: number; //'緊急訪問看護（医療のみ）',
  same_p2_flg: number; //'加算項目：同一日同一建物 2人',
  same_p3_flg: number; //'加算項目：同一日同一建物 3人以上',
  is_multi_care: number; //'複数名訪問看護加算',
  accompany1_id: number; //'同行者1ID',
  accompany1_other: string; //'同行者1その他テキスト',
  accompany2_id: number; //'同行者2ID',
  accompany2_other: string; //'同行者2その他テキスト',
  accompany3_id: number; //'同行者3ID',
  accompany3_other: string; //'同行者3その他テキスト',
  condition_div: number; //'症状\n1:著しい変化はなく安定\n2:特記すべき症状変化がある',
  condition: string; //'症状報告内容',
  care_content: string; //'看護内容記載',
  contact: string; //'連絡・特記事項',
  insurance_confirmed: number; //'有効保険証確認フラグ',
  next_visit_start_datetime: string; //'次回訪問日時',
  copy_recent_evaluation_adl: number; //観察項目 前回参照フラグ\n1:参照する\n0:参照しない
  first_submit_date_wareki: string; //初回提出日（和暦）
  first_submit_datetime_wareki: string; //初回提出日時（和暦）
  recent_submit_date_wareki: string; //最新提出日（和暦）
  recent_submit_datetime_wareki: string; //最新提出日時（和暦）
  departure_datetime_wareki: string; //出発日時（和暦）
  invoice_datetime_wareki: string; //請求チェック日時（和暦）
  visit_date_wareki: string; //訪問日（和暦）
  visit_start_datetime_wareki: string; //訪問開始日時（和暦）
  visit_end_datetime_wareki: string; //訪問終了日時（和暦）
  leave_joint_date_wareki: string; //退院時共同指導日（和暦）
  next_visit_start_datetime_wareki: string; //次回訪問日時（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  service_start_datetime_wareki: string; //サービス開始日時（和暦）
  service_end_datetime_wareki: string; //サービス終了日時（和暦）
  trach_tube_replace_flg: number; //特定行為 気管カニューレの交換の有無
  gast_jej_catheter_button_replace_flg: number; //特定行為 胃ろうカテーテル若しくは腸ろうカテーテル又は胃ろうボタンの交換の有無
  bladder_catheter_replace_flg : number; //特定行為 膀胱ろうカテーテルの交換の有無
  necrotic_tissue_remove_flg : number; //特定行為 褥瘡又は慢性創傷の治療における血流のない壊死組織の除去の有無
  negative_pressure_wound_therapy_flg : number; //特定行為 創傷に対する陰圧閉鎖療法の有無
  high_cal_infusion_adjust_flg : number; //特定行為 持続点滴中の高カロリー輸液の投与量の調整の有無
  fluid_dehydration_correction_flg : number; //特定行為 脱水症状に対する輸液による補正の有無
  care_contents: VisitRecordCareContent[]; //看護記録
  files: VisitRecordFile[]; //ファイル
  gaf: VisitRecordGaf; //GAF
  jokusos: VisitRecordJokuso[]; //褥瘡
  observations: VisitRecordObservation[]; //観察項目
  pdf: VisitRecordPdf; //PDF
  remand: VisitRecordRemand; //差し戻し
  risk_factor: VisitRecordRiskFactor; //危険因子
  vitals: VisitRecordVital[]; //バイタル
  own_expenses: OwnExpense[]; //自費
  editor_staff: ApplyStaff; //編集者
  staff: ApplyStaff; //訪問者
  accompany1: ApplyStaff; //同行者1
  accompany2: ApplyStaff; //同行者2
  accompany3: ApplyStaff; //同行者3
  patient: ApplyPatient; //利用者（引き当てはAPI側）
  service_times: VisitRecordServiceTime[]; //訪問記録サービス時間
  receipt_visit_records: ReceiptVisitRecord[]; //レセプト-訪問記録
  create_staff_id: number; //作成者ID
  create_staff: ApplyStaff; //作成者
  is_editor_staff_write: number; //編集者に履歴を残すフラグ
}
export const DefaultVisitRecord = (): VisitRecord => ({
  id: 0,
  agreement_id: 0,
  office_id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  first_submit_datetime: "",
  recent_submit_datetime: "",
  departure_datetime: "",
  invoice_yearmonth: "",
  invoice_datetime: "",
  insurance_div: 0,
  regular_visit_div: 0,
  mental_div: 0,
  care_div: 0,
  visit_content_div: 0,
  visit_start_datetime: "",
  visit_end_datetime: "",
  service_start_datetime: "",
  service_end_datetime: "",
  staff_id: 0,
  service_time_div: 0,
  nursing_type: 0,
  senmon_care_div: 0,
  online_visit_flg: 0,
  is_leave_joint: 0,
  leave_joint_date: "",
  time_type: 0,
  is_long_care: 0,
  is_emergency_care: 0,
  same_p2_flg: 0,
  same_p3_flg: 0,
  is_multi_care: 0,
  accompany1_id: 0,
  accompany1_other: "",
  accompany2_id: 0,
  accompany2_other: "",
  accompany3_id: 0,
  accompany3_other: "",
  condition_div: 0,
  condition: "",
  care_content: "",
  contact: "",
  insurance_confirmed: 0,
  next_visit_start_datetime: "",
  copy_recent_evaluation_adl: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  first_submit_date_wareki: "",
  first_submit_datetime_wareki: "",
  recent_submit_date_wareki: "",
  recent_submit_datetime_wareki: "",
  departure_datetime_wareki: "",
  invoice_datetime_wareki: "",
  visit_date_wareki: "",
  visit_start_datetime_wareki: "",
  visit_end_datetime_wareki: "",
  leave_joint_date_wareki: "",
  next_visit_start_datetime_wareki: "",
  updated_at_wareki: "",
  service_start_datetime_wareki: "",
  service_end_datetime_wareki: "",
  trach_tube_replace_flg: 0,
  gast_jej_catheter_button_replace_flg: 0,
  bladder_catheter_replace_flg: 0,
  necrotic_tissue_remove_flg: 0,
  negative_pressure_wound_therapy_flg: 0,
  high_cal_infusion_adjust_flg: 0,
  fluid_dehydration_correction_flg: 0,
  care_contents: [],
  files: [],
  gaf: DefaultVisitRecordGaf(),
  jokusos: [],
  observations: [],
  pdf: DefaultVisitRecordPdf(),
  remand: DefaultVisitRecordRemand(),
  risk_factor: DefaultVisitRecordRiskFactor(),
  vitals: [],
  own_expenses: [],
  editor_staff: DefaultApplyStaff(),
  staff: DefaultApplyStaff(),
  accompany1: DefaultApplyStaff(),
  accompany2: DefaultApplyStaff(),
  accompany3: DefaultApplyStaff(),
  patient: DefaultApplyPatient(),
  service_times: [],
  receipt_visit_records: [],
  create_staff_id: 0,
  create_staff: DefaultApplyStaff(),
  is_editor_staff_write: 0
});

export const getRemandName = (visit: {
  staff: ApplyStaff;
  create_staff: ApplyStaff;
  is_editor_staff_write: number;
}): string => {
  let remandName = visit.create_staff.ibow_nickname;
  if (visit.is_editor_staff_write === 0) {
    remandName = visit.staff.ibow_nickname;
  }
  return remandName;
};
