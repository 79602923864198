import Base from "./base";
import { Pdf, Remand, DefaultRemand } from "./report";
import {
  ApplyPersonnel,
  DefaultApplyPersonnel,
  ApplyStaff,
  DefaultApplyStaff
} from "./common";

//月次報告書 - 送付先
export interface MonthReportSend extends Base {
  id: number; //ID
  month_report_id: number; //'月次報告書ID',
  sort_no: number; //'並び順',
  support_office_div: number; //'支援事業所区分\n1:居宅介護支援事業所\n2:情報提供先\n3:地域包括支援センター\n4:その他',
  personnel_id: number; //'担当者ID',
}
export const DefaultMonthReportSend = (): MonthReportSend => ({
  id: 0,
  month_report_id: 0,
  sort_no: 0,
  support_office_div: 0,
  personnel_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//月次報告書 - 評価基準
export interface MonthReportEval extends Base {
  id: number;
  std_eval_item_id: number;
  std_eval_select_item_id: number;
  name: string;
  remarks: string;
  items: {
    id: number;
    point: number;
    value: number;
    description: string;
    name: string;
  }[];
}
export const DefaultMonthReportEval = (): MonthReportEval => ({
  id: 0,
  std_eval_item_id: 0,
  std_eval_select_item_id: 0,
  name: "",
  remarks: "",
  items: [
    {
      id: 0,
      point: 0,
      value: 0,
      description: "",
      name: ""
    }
  ],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//月次報告書
export interface MonthReport extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  yearmonth: string; //'報告月',
  staff1_id: number; //'職員ID',
  staff2_id: number; //'職員ID',
  staff3_id: number; //'職員ID',
  staff4_id: number; //'職員ID',
  care_personnel_id: number; //'ケアマネ担当者ID',
  condrep: string; //'病状経過',
  n_contsel: string; //'看護内容(選択)',
  ncont: string; //'看護内容',
  relation: string; //'家族等との関係',
  sanitation_name: string; //'衛生材料等の名称',
  sanitation_chg: string; //'使用及び交換頻度',
  sanitation_amount: string; //'使用量',
  sanitation_chg_flg: number; //'衛生材料等(種類・サイズ・必要量等)の変更の必要性\n0:なし\n1:あり',
  sanitation_chg_content: string; //'変更内容',
  mention: string; //'特記事項',
  writer_staff1_id: number; //'作成者①',
  writer_staff2_id: number; //'作成者②',
  write_date: string; //'記載日',
  submit_datetime_wareki: string; //提出日時（和暦）
  write_date_wareki: string; //記載日（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  yearmonth_wareki: string; //報告月（和暦）
  pdf: Pdf[]; //PDF
  remand: Remand; //差し戻し
  sends: MonthReportSend[]; //送付先
  editor_staff: ApplyStaff; //編集者
  staff1: ApplyStaff; //職員1
  staff2: ApplyStaff; //職員2
  staff3: ApplyStaff; //職員3
  staff4: ApplyStaff; //職員4
  care_personnel: ApplyPersonnel; //ケアマネ担当者
  writer_staff1: ApplyStaff; //作成者①
  writer_staff2: ApplyStaff; //作成者②
  daily_life_rate_div: number;
  dementia_daily_life_rate_div: number;
  contents: string;
  contents_font_size: number;
  communication: string;
  communication_font_size: number;
  role_in_the_home: string;
  role_in_the_home_font_size: number;
  leisure_activities: string;
  leisure_activities_font_size: number;
  volunteer_activities: string;
  volunteer_activities_font_size: number;
  activities_after: string;
  activities_after_font_size: number;
  patient_evaluation: string;
  patient_evaluation_font_size: number;
  notice: string;
  notice_font_size: number;
  evals: MonthReportEval[];
}

export const DefaultMonthReport = (): MonthReport => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  submit_datetime: "",
  yearmonth: "",
  staff1_id: 0,
  staff2_id: 0,
  staff3_id: 0,
  staff4_id: 0,
  care_personnel_id: 0,
  condrep: "",
  n_contsel: "",
  ncont: "",
  relation: "",
  sanitation_name: "",
  sanitation_chg: "",
  sanitation_amount: "",
  sanitation_chg_flg: 0,
  sanitation_chg_content: "",
  mention: "",
  writer_staff1_id: 0,
  writer_staff2_id: 0,
  write_date: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  submit_datetime_wareki: "",
  write_date_wareki: "",
  updated_at_wareki: "",
  yearmonth_wareki: "",
  pdf: [],
  remand: DefaultRemand(),
  sends: [],
  editor_staff: DefaultApplyStaff(),
  staff1: DefaultApplyStaff(),
  staff2: DefaultApplyStaff(),
  staff3: DefaultApplyStaff(),
  staff4: DefaultApplyStaff(),
  care_personnel: DefaultApplyPersonnel(),
  writer_staff1: DefaultApplyStaff(),
  writer_staff2: DefaultApplyStaff(),
  daily_life_rate_div: 0,
  dementia_daily_life_rate_div: 0,
  contents: "",
  contents_font_size: 0,
  communication: "",
  communication_font_size: 0,
  role_in_the_home: "",
  role_in_the_home_font_size: 0,
  leisure_activities: "",
  leisure_activities_font_size: 0,
  volunteer_activities: "",
  volunteer_activities_font_size: 0,
  activities_after: "",
  activities_after_font_size: 0,
  patient_evaluation: "",
  patient_evaluation_font_size: 0,
  notice: "",
  notice_font_size: 0,
  evals: []
});
