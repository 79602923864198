export const name = "bbs";

export const SET_IBOW_HEADLINES = "SET_IBOW_HEADLINES";
export const SET_EWELL_HEADLINES = "SET_EWELL_HEADLINES";
export const SET_SUPPORT_HEADLINES = "SET_SUPPORT_HEADLINES";

export const BoardDiv = {
  iBow: 1,
  ewell: 2,
  support: 3
} as const;

export interface BbsState {
  ibowHeadlines: AdminHeadline[];
  ewellHeadlines: AdminHeadline[];
  supportHeadlines: AdminHeadline[];
}

// 公式からのお知らせ 見出し
export interface AdminHeadline {
  id: number;
  date: string;
  title: string;
  icon: string;
  board_div: number;
}
