



































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import ItemTable from "#/components/ItemTable.vue";
import NumberInput from "#/components/NumberInput.vue";
import ZeroDispNumberInput from "#/components/ZeroDispNumberInput.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import { VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { OwnExpenseTemplate, DefaultOwnExpenseTemplate } from "#/model/patient";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import OwnExpenseMixin from "@/mixins/ownExpenseMixin";

@Component({
  components: {
    ItemTable,
    NumberInput,
    PatientHeader,
    ZeroDispNumberInput
  }
})
export default class PatientSelfburdenTemplateEdit extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  EditMixin,
  PatientMixin,
  OwnExpenseMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  /** 自費テンプレートID */
  private selfburdenTemplateId = 0;

  /** 利用者ID */
  private patientId = 0;

  /** 編集/コピー */
  private mode = "";

  /** 自費テンプレート */
  private own_expense_template: OwnExpenseTemplate = DefaultOwnExpenseTemplate();

  /** 単価と数量の初期値0を空欄として表示するかフラグ */
  private get IsNeedDispEmptyFirst() {
    return this.own_expense_template.id == 0 && !this.mode;
  }

  /** 自費アイテムマスタの絞り込み（カテゴリ選択時それに付随するアイテム表示）*/
  private get RefinementOwnExpenseItems() {
    return this.refinementOwnExpenseItems(
      this.own_expense_template.category_name
    );
  }

  /** 課税率のルール */
  private get RuleTaxRate() {
    return this.ruleTaxRate(this.own_expense_template);
  }

  /** データ変更監視 */
  @Watch("own_expense_template", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  created() {
    this.selfburdenTemplateId = Number(this.$route.params.selfburdentemplateid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchSelfburdenTemplate();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/selfburden?key=SelfBurdenTemplate`,
        exact: true
      },
      {
        text: "自費テンプレートを作成する",
        disabled: true,
        to: ""
      }
    ]);
  }

  /** キャンセル */
  private cancel() {
    this.$router.go(-1);
  }

  /** 登録 */
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    if (this.mode) {
      this.own_expense_template.id = 0;
    }
    this.own_expense_template.patient_id = this.patientId;
    this.calculation(this.own_expense_template);
    this.postJsonCheck(
      window.base_url + "/api/patient/selfburdentemplate/save",
      {
        own_expense_template: this.own_expense_template
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  /** 更新 */
  private update() {
    this.own_expense_template.id = this.selfburdenTemplateId;
    this.save();
  }

  /** 削除 */
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.own_expense_template.id = this.selfburdenTemplateId;
      this.own_expense_template.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/selfburdentemplate/delete",
        {
          own_expense_template: this.own_expense_template
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  /** 自費テンプレート取得 */
  fetchSelfburdenTemplate(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/selfburdentemplate/get",
      {
        patient_id: this.patientId,
        selfburden_template_id: this.selfburdenTemplateId
      },
      res => {
        this.ownExpenseCategorys = res.data.own_expense_categorys;
        this.ownExpenseItems = res.data.own_expense_items;
        //自費情報
        if (this.selfburdenTemplateId != 0) {
          this.own_expense_template = res.data.own_expense_template;
        }
        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  /** カテゴリ名・アイテム名変更時 */
  private changeItem() {
    if (!this.isLoaded) {
      return;
    }
    const item = this.selectedItem(
      this.own_expense_template.category_name,
      this.own_expense_template.item_name
    );
    if (!item) {
      return;
    }
    // 項目セット
    this.setSelectedOwnExpense(this.own_expense_template, item);
  }
}
