import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:定期巡回情報 */
export const headerRegularVisit: StatisticHeader = {
  id: STATISTIC_COL_ID.REGULAR_VISIT,
  text: "定期巡回情報",
  is_checked: false,
  value: [
    {
      text: "サービス内容（定期巡回）",
      select_name: "サービス内容",
      value: `${STATISTIC_COL_ID.REGULAR_VISIT}.use_regular_visit`,
      sortable: true,
      align: "start",
      width: "220px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST
    },
    {
      text: "開始日（定期巡回）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.REGULAR_VISIT}.regular_visit_term_from`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "開始月サービス（定期巡回）",
      select_name: "開始月サービス",
      value: `${STATISTIC_COL_ID.REGULAR_VISIT}.str_regular_visit_calc_type_from`,
      sortable: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST
    },
    {
      text: "終了日（定期巡回）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.REGULAR_VISIT}.regular_visit_term_to`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了月サービス（定期巡回）",
      select_name: "終了月サービス",
      value: `${STATISTIC_COL_ID.REGULAR_VISIT}.str_regular_visit_calc_type_to`,
      sortable: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST
    }
  ]
};
