import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:指示書情報 */
export const headerIndicate: StatisticHeader = {
  id: STATISTIC_COL_ID.INDICATE,
  text: "指示書情報",
  is_checked: false,
  value: [
    {
      text: "指示書到着状況",
      value: `${STATISTIC_COL_ID.INDICATE}.str_is_not_document_arrive`,
      sortable: true,
      align: "start",
      width: "150px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "種類（指示書）",
      select_name: "種類",
      value: `${STATISTIC_COL_ID.INDICATE}.str_indicate_div`,
      sortable: true,
      align: "start",
      width: "160px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.start_date`,
      sortable: true,
      align: "start",
      width: "168px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.end_date`,
      sortable: true,
      align: "start",
      width: "168px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "病院名（指示書）",
      select_name: "病院名",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.medical_institution_name`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "ふりがな（指示書）",
      select_name: "病院名ふりがな",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.medical_institution_name_kana`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "主治医名（指示書）",
      select_name: "主治医名",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.name`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "ふりがな（指示書）",
      select_name: "主治医名ふりがな",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.furigana`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "郵便番号（指示書・医療機関）",
      select_name: "郵便番号",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.post_code`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "住所（指示書・医療機関）",
      select_name: "住所",
      value: `${STATISTIC_COL_ID.INDICATE}.medical_institution_address`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "電話番号（指示書・医療機関）",
      select_name: "電話番号",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.medical_institution_tel_no`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "FAX（指示書・医療機関）",
      select_name: "FAX",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.medical_institution_fax_no`,
      sortable: true,
      align: "start",
      width: "220px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "連絡先1（指示書・主治医）",
      select_name: "連絡先1",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.contact1`,
      sortable: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "連絡先2（指示書・主治医）",
      select_name: "連絡先2",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.contact2`,
      sortable: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "連絡先3（指示書・主治医）",
      select_name: "連絡先3",
      value: `${STATISTIC_COL_ID.INDICATE}.doctor.contact3`,
      sortable: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "主傷病名1（指示書）",
      select_name: "主傷病名1",
      value: `${STATISTIC_COL_ID.INDICATE}.main_disease1`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "主傷病名２（指示書）",
      select_name: "主傷病名２",
      value: `${STATISTIC_COL_ID.INDICATE}.main_disease2`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "主傷病名３（指示書）",
      select_name: "主傷病名３",
      value: `${STATISTIC_COL_ID.INDICATE}.main_disease3`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "別表７ 病名（指示書）",
      select_name: "別表７ 病名",
      value: `${STATISTIC_COL_ID.INDICATE}.appendix7_diseases`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "開始日（指示書・別表７ 病名）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.appendix7_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・別表７ 病名）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.appendix7_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "別表８ 状態（指示書）",
      select_name: "別表８ 状態",
      value: `${STATISTIC_COL_ID.INDICATE}.appendix8_status`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書・別表８ 状態）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.appendix8_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・別表８ 状態）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.appendix8_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "特別管理Ⅰ（指示書）",
      select_name: "特別管理Ⅰ",
      value: `${STATISTIC_COL_ID.INDICATE}.str_special_add1`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書・特別管理Ⅰ）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.special_add1_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・特別管理Ⅰ）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.special_add1_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "特別管理Ⅱ（指示書）",
      select_name: "特別管理Ⅱ",
      value: `${STATISTIC_COL_ID.INDICATE}.str_special_add2`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書・特別管理Ⅱ）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.special_add2_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・特別管理Ⅱ）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.special_add2_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "開始日（指示書・点滴注射）",
      select_name: "点滴注射開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.injection_start_date`,
      sortable: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・点滴注射）",
      select_name: "点滴注射終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.injection_end_date`,
      sortable: true,
      align: "start",
      width: "240px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "24時間体制（指示書）",
      select_name: "24時間体制",
      value: `${STATISTIC_COL_ID.INDICATE}.str_hour24_div`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書・24時間体制）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.hour24_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・24時間体制）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.hour24_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "緊急時訪問（指示書）",
      select_name: "緊急時訪問",
      value: `${STATISTIC_COL_ID.INDICATE}.str_emergency_div`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書・緊急時訪問）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.emergency_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・緊急時訪問）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.emergency_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "長時間訪問看護加算（指示書）",
      select_name: "長時間訪問看護加算",
      value: `${STATISTIC_COL_ID.INDICATE}.str_longtime_child_div`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "超重症児対象（指示書）",
      select_name: "超重症児対象",
      value: `${STATISTIC_COL_ID.INDICATE}.str_smid_flg`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書・超重症児対象）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.smid_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・超重症児対象）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.smid_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "準超重症児対象（指示書）",
      select_name: "準超重症児対象",
      value: `${STATISTIC_COL_ID.INDICATE}.str_semi_smid_flg`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（指示書・準超重症児対象）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.INDICATE}.semi_smid_start_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（指示書・準超重症児対象）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.INDICATE}.semi_smid_end_date`,
      sortable: true,
      is_branch: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    }
  ]
};
