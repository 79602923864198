export interface NursingPrimaryContent {
  id?: number;
  is_ai: number;
  content_name: string;
  care_medium_contents: NursingSecondaryContent[];
  care_large_content_offices: Office[];
}
export const DefaultNursingPrimaryContent = (): NursingPrimaryContent => ({
  id: 0,
  is_ai: 0,
  content_name: "",
  care_medium_contents: [],
  care_large_content_offices: []
});

export interface NursingSecondaryContent {
  id?: number;
  care_large_content_id: number;
  content_name: string;
}
export const DefaultNursingSecondaryContent = (): NursingSecondaryContent => ({
  id: 0,
  care_large_content_id: 0,
  content_name: ""
});

export interface Office {
  office_id: number;
}
