















































import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  RegistrationMessage,
  ResultsConfirmedMessage,
  ResetMessage
} from "#/model/receipt";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class ConfirmationMessage extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** メッセージ項目 */
  @Prop({ default: () => ({}) }) messageItem!: {
    /** 登録時メッセージ */
    registrationMessages: RegistrationMessage[];
    /** 確定時メッセージ */
    resultsConfirmedMessage: ResultsConfirmedMessage;
    /** リセット時メッセージ */
    resetMessage: ResetMessage;
    /** 実績確定解除時メッセージ */
    releaseConfirmedResultsMessage: string;
    /** 登録メッセージ */
    registrationMessage: string;
  };
}
