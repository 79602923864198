import Base from "./base";
import { ApplyStaff, DefaultApplyStaff } from "./common";

//管理者掲示板 - 事業所
export interface BulletinBoardAdminOffice extends Base {
  id: number; //ID
  bulletin_board_admin_id: number; //'管理者掲示板ID',
  agreement_id: number; //'契約ID\n0:全契約',
  office_id: number; //'事業所ID\n0:全事業所',
}
export const DefaultBulletinBoardAdminOffice = (): BulletinBoardAdminOffice => ({
  id: 0,
  bulletin_board_admin_id: 0,
  agreement_id: 0,
  office_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//管理者掲示板
export interface BulletinBoardAdmin extends Base {
  id: number; //ID
  editor_admin_id: number; //'編集管理者ID',
  board_div: number; //'掲示板区分\n1:iBowに関するお知らせ\n2:eWeLLからのお知らせ\n3:サポートからのお知らせ',
  start_date: string; //'掲載開始期間',
  end_date: string; //'掲載終了期間',
  title: string; //'件名',
  message: string; //'掲示内容',
  status: number; //'状態\n1:通常\n2:早期終了',
  start_date_wareki: string; //掲載開始期間（和暦）
  end_date_wareki: string; //掲載終了期間（和暦）
  bulletin_board_admin_offices: BulletinBoardAdminOffice[]; //管理者掲示板 - 事業所
}
export const DefaultBulletinBoardAdmin = (): BulletinBoardAdmin => ({
  id: 0,
  editor_admin_id: 0,
  board_div: 0,
  start_date: "",
  end_date: "",
  title: "",
  message: "",
  status: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: "",
  bulletin_board_admin_offices: []
});

//iBow掲示板コメント
export interface BulletinBoardComment extends Base {
  id: number; //ID
  bulletin_board_id: number; //'掲示板ID',
  editor_staff_id: number; //'編集者ID',
  comment: string; //'コメント',
  updated_at_wareki: string; //更新日時（和暦）
  editor_staff: ApplyStaff; //編集者
}
export const DefaultBulletinBoardComment = (): BulletinBoardComment => ({
  id: 0,
  bulletin_board_id: 0,
  editor_staff_id: 0,
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  updated_at_wareki: "",
  editor_staff: DefaultApplyStaff()
});

//ibow掲示板 - 事業所
export interface BulletinBoardOffice extends Base {
  id: number; //ID
  bulletin_board_id: number; //'掲示板ID',
  office_id: number; //'事業所ID',
}
export const DefaultBulletinBoardOffice = (): BulletinBoardOffice => ({
  id: 0,
  bulletin_board_id: 0,
  office_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//iBow掲示板
export interface BulletinBoard extends Base {
  id: number; //ID
  agreement_id: number; //'契約ID',
  editor_staff_id: number; //'編集者ID',
  title: string; //'タイトル',
  content: string; //'内容',
  bulletin_board_comments: BulletinBoardComment[]; //コメント
  bulletin_board_offices: BulletinBoardOffice[]; //事業所
  updated_at_wareki: string; //更新日時（和暦）
  editor_staff: ApplyStaff; //編集者
}
export const DefaultBulletinBoard = (): BulletinBoard => ({
  id: 0,
  agreement_id: 0,
  editor_staff_id: 0,
  title: "",
  content: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  bulletin_board_comments: [],
  bulletin_board_offices: [],
  updated_at_wareki: "",
  editor_staff: DefaultApplyStaff()
});
