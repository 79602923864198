










































































































import { Component, Emit, Ref, Mixins, Prop } from "vue-property-decorator";
import { InfoItems, defaultInfoItems } from "../types";
import { VForm } from "@/types";
import sanitizeHTML from "sanitize-html";
import AxiosMixin from "@/mixins/axiosMixin";
import { DefaultApplyStaff } from "#/model/common";

@Component
export default class extends Mixins(AxiosMixin) {
  /** 掲示板のコメント登録エリア */
  @Ref("commentForm") private readonly commentForm!: VForm;

  /** コメント投稿後自動スクロールするために使用 */
  @Ref("scrollTarget") private readonly scrollTarget!: HTMLElement;

  /** 事業所掲示板アイテムリスト */
  @Prop() private readonly topicItems!: InfoItems[];

  /** ログイン中の職員ID */
  @Prop() private readonly staffId!: number;

  /** 検索時 */
  @Emit("search") private search(): void {
    return;
  }

  /** サニタイズ */
  private sanitize = sanitizeHTML;

  /** サイドバーの開閉状態 */
  private isOpenTopicArea = false;

  /** 編集中のトピック */
  private editTopic = defaultInfoItems();

  /** 編集中のトピックindex */
  private editTopicIdx = 0;

  /** 確認ダイアログが開いているかどうか */
  private isOpenAlertConfirm = false;

  /** 編集中のコメント */
  private comment = "";

  /** サイドバーを開く */
  public open(topic: InfoItems, idx: number): void {
    this.editTopic = topic;
    this.editTopicIdx = idx;
    this.comment = "";
    this.isOpenTopicArea = true;
  }

  /** 掲示板トピック1件を取得する */
  private getBbs(): void {
    this.postJsonCheck(
      window.base_url + "/api/bbs/get",
      {
        bbs_id: this.editTopic.id
      },
      res => {
        this.topicItems.splice(this.editTopicIdx, 1, res.data.bbs);
        this.editTopic = res.data.bbs;
        this.$nextTick(() => {
          this.scrollToLatestComment();
        });
      }
    );
  }

  /** トピックを削除する */
  private async deleteBbs() {
    // 確認ダイアログの表示中にドロワーが閉じないようにする
    this.isOpenAlertConfirm = true;
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      this.isOpenAlertConfirm = false;
      return;
    }
    this.isOpenAlertConfirm = false;

    this.postJsonCheck(
      window.base_url + "/api/bbs/delete",
      {
        bulletin_board: this.editTopic.bulletin_board
      },
      () => {
        this.search();
        this.isOpenTopicArea = false;
      }
    );
  }

  /** コメントを登録する */
  private createComment() {
    if (!this.commentForm.validate()) {
      return;
    }

    if (!this.editTopic.bulletin_board.bulletin_board_comments) {
      this.editTopic.bulletin_board.bulletin_board_comments = [];
    }
    const comment_bullet = {
      id: 0,
      bulletin_board_id: this.editTopic.id,
      editor_staff_id: this.staffId,
      comment: this.comment,
      created_at: "",
      updated_at: "",
      deleted_at: "",
      updated_at_wareki: "",
      editor_staff: DefaultApplyStaff()
    };
    this.postJsonCheck(
      window.base_url + "/api/bbscomment/create",
      {
        bulletin_board_comment: comment_bullet
      },
      () => {
        this.comment = "";
        this.commentForm.resetValidation();
        this.getBbs();
      }
    );
  }

  /** コメントを削除する */
  private async deleteComment(idx: number) {
    // 確認ダイアログの表示中にドロワーが閉じないようにする
    this.isOpenAlertConfirm = true;
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      this.isOpenAlertConfirm = false;
      return;
    }
    this.isOpenAlertConfirm = false;

    this.postJsonCheck(
      window.base_url + "/api/bbscomment/delete",
      {
        bulletin_board_comment: this.editTopic.bulletin_board
          .bulletin_board_comments[idx]
      },
      () => {
        this.getBbs();
      }
    );
  }

  /** コメント送信時に、送信したて一番下のコメントまでスクロールする */
  private scrollToLatestComment() {
    const scrollArea = this.scrollTarget.querySelector(
      ".bbs_topic-area_topic-side-bar .v-navigation-drawer__content .v-card__text"
    );
    if (!scrollArea) {
      return;
    }
    // 詳細エリアのヘッダーと最新のコメントが被らないように調整
    scrollArea.scrollTo({
      top: scrollArea.scrollHeight,
      behavior: "smooth"
    });
  }

  /** コメントの入力チェック */
  private requiredComment(comment: string): boolean | string {
    if (!comment.match(/.*\S+.*/)) {
      return "必須です";
    }
    return true;
  }
}
