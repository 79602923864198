



























































































































































































































































































































































































































































































































import { Component, Mixins, Watch, Ref, Prop } from "vue-property-decorator";
import { Choice, ChoiceAddOther } from "@/types";
import { Link } from "#/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import UtilMixin from "@/mixins/utilMixin";
import {
  MonthReport,
  MonthReportEval,
  DefaultMonthReport,
  DefaultMonthReportSend,
  DefaultMonthReportEval
} from "#/model/monthreport";
import RemandExpand from "@/components/patient/common/remand/RemandExpand.vue";
import PatientRelationInsSelect from "@/components/patient/common_ibow/PatientRelationInsSelect.vue";
import EditMixin from "@/mixins/editMixin";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import { COMMON } from "#/const";
import PatientMixin from "@/mixins/patientMixin";

@Component({
  components: {
    PatientHeader,
    PatientSaveArea,
    RemandExpand,
    PatientRelationInsSelect,
    PatientExpantionAreas
  }
})
export default class Report extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  @Prop() readonly pheader!: PatientHeader;

  @Ref("patient-header") private readonly patientHeader!: PatientExpantionAreas;

  /** フォントサイズの設定キー */
  private readonly FONT_SIZE_KEY_L = 1;
  private readonly FONT_SIZE_KEY_M = 2;
  private readonly FONT_SIZE_KEY_S = 3;

  private reportId = 0;
  private patientId = 0;

  private month_report: MonthReport = DefaultMonthReport(); //月次報告者
  private hasRecentMonthReport = false; //前回提出の月次報告書があるか
  private staffs: Choice[] = []; //担当者
  private validCareFlg = 0; //居宅サービスが登録されている有効な介護保険フラグ
  private corpItems: Choice[] = []; //担当ケアマネ（居宅介護支援事業所）
  private informationProviders: Choice[] = []; //情報提供先
  private requestors: Choice[] = []; //地域包括支援センター
  private others: Choice[] = []; //その他
  private writerStaffs: ChoiceAddOther[] = []; //作成者
  private informationProvider1 = ""; //情報提供先1
  private informationProvider2 = ""; //情報提供先2
  private informationProvider3 = ""; //情報提供先3
  private is_open_evals = false; //評価参考表
  private clone_evals: MonthReportEval[] = []; //別添評価_複製

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  //報告月をリアルタイムの今月にする
  private today() {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const today = date.toISOString().substr(0, 7);
    this.month_report.yearmonth = today;
  }

  //報告月を空欄にする
  private defaultYearmonth() {
    this.month_report.yearmonth = "";
  }

  /** アコーディオンメニュー */
  private localLinks: Link[] = [];

  private get RemandLink(): Link[] {
    if (
      this.month_report.remand.id &&
      this.month_report.remand.editor_staff_id
    ) {
      return [
        {
          text: "差戻し情報",
          key: "remand",
          add_info: this.month_report.remand.comment
        }
      ];
    }
    return [];
  }

  private prefixLink = [
    {
      text: "報告月",
      key: "provision_month"
    },
    {
      text: "担当者",
      key: "person_in_charge"
    },
    {
      text: "病状の経過",
      key: "medical_condition"
    },
    {
      text: "看護の内容",
      key: "care_content"
    },
    {
      text: "家族等(家庭での)状況",
      key: "family_situation"
    },
    {
      text: "衛生材料等の状況",
      key: "sanitary_status"
    },
    {
      text: "連絡事項",
      key: "information"
    }
  ];

  private bettenLink = [
    {
      text: "（別添）自立度",
      key: "independence"
    },
    {
      text: "（別添）訪問看護、家族等への指導、リスク管理等の内容",
      key: "nursing_detail"
    },
    {
      text: "（別添）評価",
      key: "evaluation"
    },
    {
      text: "（別添）特記すべき事項",
      key: "sp_comment"
    }
  ];

  private suffixLink = [
    {
      text: "作成者",
      key: "created_staff"
    },
    {
      text: "記載日",
      key: "created_at"
    },
    {
      text: "宛名追加",
      key: "address"
    }
  ];

  private font_sizes = [
    { text: "大", value: this.FONT_SIZE_KEY_L },
    { text: "中", value: this.FONT_SIZE_KEY_M },
    { text: "小", value: this.FONT_SIZE_KEY_S }
  ];

  /** フォントサイズにより変わる設定値 */
  private get byFZ() {
    return (
      fzSetting: string | number,
      sizeL: number,
      sizeM: number,
      sizeS: number
    ) => {
      switch (Number(fzSetting)) {
        case this.FONT_SIZE_KEY_L:
          return sizeL;
        case this.FONT_SIZE_KEY_M:
          return sizeM;
        case this.FONT_SIZE_KEY_S:
          return sizeS;
      }
    };
  }

  private get TotalPoint() {
    let total = 0;
    for (const evalItem of this.month_report.evals) {
      if (evalItem.std_eval_select_item_id == 0) {
        continue;
      }

      for (const item of evalItem.items) {
        if (item.id == evalItem.std_eval_select_item_id) {
          total += item.point;
        }
      }
    }
    return total;
  }

  private get StorageKey() {
    return `month_report-${this.loginUser.id}-${this.patientId}-${this.reportId}`;
  }

  //編集モード設定
  @Watch("month_report", { deep: true }) private watchData() {
    this.saveLocalStorage({
      month_report: this.month_report,
      displayPreview: this.displayPreview
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  //報告月：変更時getYearmonthChange走らせる
  @Watch("month_report.yearmonth")
  private handleYearmonth() {
    if (!this.IsLoaded) {
      return;
    }
    this.getYearmonthChange();
  }

  created() {
    this.reportId = Number(this.$route.params.reportid);
    this.patientId = Number(this.$route.params.id);
    this.fetchMonthReport();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/report`
      },
      {
        text: "月次報告書",
        disabled: true,
        to: ""
      }
    ]);
  }

  //評価参考表開く
  private openEval() {
    this.is_open_evals = true;
    this.clone_evals = [];
    for (const evalItem of this.month_report.evals) {
      this.clone_evals.push({ ...evalItem });
    }
  }

  //評価参考表保存
  private saveEval() {
    this.is_open_evals = false;
    this.month_report.evals = this.clone_evals;
  }

  //別添に値が入力されている場合、作成者にPTOTSTがいるべきなので確認(PDF別添のため)
  private checkBettenOnlyPtotst() {
    // 作成者1か2がPTOTSTなら問題なし
    if (this.isPtotst) {
      return true;
    }
    const edited = this.month_report;
    const defaultReport = DefaultMonthReport();
    const defaultEval = DefaultMonthReportEval();

    // evalのどれかのstd_eval_select_item_idがデフォルトでなければ、引っかかる
    for (const ev of edited.evals) {
      if (ev.std_eval_select_item_id !== defaultEval.std_eval_select_item_id) {
        return false;
      }
    }

    // eval以外でも別添がデフォルトでなければ引っかかる、ただしフォントサイズは考慮しない
    // prettier-ignore
    if (
      edited.daily_life_rate_div !== defaultReport.daily_life_rate_div ||
      edited.dementia_daily_life_rate_div !== defaultReport.dementia_daily_life_rate_div ||
      edited.contents !== defaultReport.contents ||
      edited.communication !== defaultReport.communication ||
      edited.role_in_the_home !== defaultReport.role_in_the_home ||
      edited.leisure_activities !== defaultReport.leisure_activities ||
      edited.volunteer_activities !== defaultReport.volunteer_activities ||
      edited.activities_after !== defaultReport.activities_after ||
      edited.patient_evaluation !== defaultReport.patient_evaluation ||
      edited.notice !== defaultReport.notice
    ) {
      return false;
    }
    return true;
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //保存
  private save(isMove: boolean) {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/report/save", false, isMove);
    });
  }

  //提出
  private async submission(isPreview = false) {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    // 別添に記入がある、かつ作成者にPT/OT/STがいなければ、意図せず別添が出力されないのを避けたい
    // その利用者の提出済み月次報告書がない場合を除き、確認を挟む
    if (!this.checkBettenOnlyPtotst() && this.hasRecentMonthReport) {
      const bettenMsg =
        "別添の項目に登録があります。このまま提出すると別添は作成されませんので登録内容は初期化されますが宜しいですか？";
      if (!(await this.$openConfirm(bettenMsg))) {
        return;
      }
    }
    this.saveAction(2, "/api/patient/report/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.month_report.patient_id = this.patientId;
    this.month_report.status = status;
    //宛名が未選択の場合は保存しない
    this.editSends();
    this.makePdf(
      window.base_url + url,
      {
        month_report: this.month_report,
        is_preview: isPreview
      },
      async res => {
        this.setNoEditMode();
        if (isMove) {
          this.cancel();
        } else {
          this.reportId = res.data.id;
          this.month_report.id = res.data.id;
          this.month_report.updated_at = res.data.updated_at;
          this.$router.replace(
            `/patient/${this.patientId}/report/edit/${this.reportId}`
          );
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //宛名が未選択の場合は保存しない
  private editSends() {
    const sends = this.month_report.sends.concat();
    this.month_report.sends = [];
    for (const send of sends) {
      if (send.support_office_div != 0) {
        this.month_report.sends.push(send);
      }
    }
  }

  //削除
  private clickDelete() {
    this.month_report.id = this.reportId;
    this.month_report.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/report/delete",
      {
        month_report: this.month_report
      },
      () => {
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //プレビュー
  private preview() {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.month_report.patient_id = this.patientId;
      this.month_report.status = 0;
      this.makePdf(window.base_url + "/api/patient/report/preview", {
        month_report: this.month_report
      });
    });
  }

  private previewVisitRecords() {
    this.month_report.patient_id = this.patientId;
    this.month_report.status = 0;
    this.makePdf(window.base_url + "/api/patient/visitrecords/preview", {
      patient_id: this.patientId,
      visit_yearmonth: this.month_report.yearmonth
    });
  }

  //報告月変更した時：有効な介護保険フラグと担当ケアマネID、看護内容、情報提供先取得
  private getYearmonthChange(isInit = false): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/report/yearmonthchange/get",
      {
        patient_id: this.patientId,
        report_id: this.reportId,
        yearmonth: this.month_report.yearmonth
      },
      res => {
        this.validCareFlg = res.data.valid_care_flg;
        if (this.validCareFlg == 1) {
          this.month_report.care_personnel_id =
            res.data.plan_writer_personnel_id;
        }
        this.month_report.n_contsel = res.data.care_content;
        this.informationProvider1 = res.data.information_provider1;
        this.informationProvider2 = res.data.information_provider2;
        this.informationProvider3 = res.data.information_provider3;

        if (isInit) {
          this.execAfterFetch(this.StorageKey, data => {
            this.month_report = data.month_report;
            this.displayPreview = data.displayPreview;
          });
        }
      }
    );
  }

  //月次報告書情報取得
  private fetchMonthReport(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/report/get",
      {
        patient_id: this.patientId,
        report_id: this.reportId
      },
      res => {
        this.staffs = res.data.staffs;
        this.corpItems = res.data.corp_items;
        this.informationProviders = res.data.information_providers;
        this.requestors = res.data.requestors;
        this.others = res.data.others;
        this.writerStaffs = res.data.writer_staffs;
        this.month_report = res.data.month_report;
        this.hasRecentMonthReport = res.data.has_recent_month_report;
        if (this.reportId == 0) {
          this.defaultYearmonth();
        }
        this.getYearmonthChange(true);
      }
    );
  }

  //-----------------------------------------------------
  // 別添表示関連
  //-----------------------------------------------------
  private get attachmentWriters(): (number | string)[] {
    // 別添表示条件を満たす職員IDの配列を比較用に作成
    const writers = this.writerStaffs.filter(
      ws => ws.other == 4 || ws.other == 5 || ws.other == 6
    );

    return writers.map(w => w.value);
  }

  private get isPtotst() {
    const writer1Id = this.month_report.writer_staff1_id;
    const writer2Id = this.month_report.writer_staff2_id;
    return (
      this.attachmentWriters.indexOf(writer1Id) !== -1 ||
      this.attachmentWriters.indexOf(writer2Id) !== -1
    );
  }

  private get links(): Link[] {
    if (this.isPtotst) {
      this.localLinks = [
        ...this.RemandLink,
        ...this.prefixLink,
        ...this.bettenLink,
        ...this.suffixLink
      ];
    } else {
      this.localLinks = [
        ...this.RemandLink,
        ...this.prefixLink,
        ...this.suffixLink
      ];
    }

    return this.localLinks;
  }

  private set links(val: Link[]) {
    this.localLinks = val;
  }

  //-----------------------------------------------------
  // 宛名関連
  //-----------------------------------------------------
  //宛名追加
  private addAddress() {
    this.month_report.sends.push(DefaultMonthReportSend());
  }
  //宛名削除
  private async deleteAddress(idx: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.month_report.sends.splice(idx, 1);
    }
  }
  //宛名変えた時
  private changeAddress(send: { personnel_id: number }) {
    send.personnel_id = 0;
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //担当者1：担当者が被っている場合
  private checkStaff1(): boolean | string {
    if (!this.month_report.staff1_id) {
      return true;
    }
    if (
      this.month_report.staff1_id !=
      (this.month_report.staff2_id ||
        this.month_report.staff3_id ||
        this.month_report.staff4_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //担当者2：担当者が被っている場合
  private checkStaff2(): boolean | string {
    if (!this.month_report.staff2_id) {
      return true;
    }
    if (
      this.month_report.staff2_id !=
      (this.month_report.staff1_id ||
        this.month_report.staff3_id ||
        this.month_report.staff4_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //担当者3：担当者が被っている場合
  private checkStaff3(): boolean | string {
    if (!this.month_report.staff3_id) {
      return true;
    }
    if (
      this.month_report.staff3_id !=
      (this.month_report.staff1_id ||
        this.month_report.staff2_id ||
        this.month_report.staff4_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //担当者4：担当者が被っている場合
  private checkStaff4(): boolean | string {
    if (!this.month_report.staff4_id) {
      return true;
    }
    if (
      this.month_report.staff4_id !=
      (this.month_report.staff1_id ||
        this.month_report.staff2_id ||
        this.month_report.staff3_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }

  //作成者：作成者が被っている場合
  private checkWriterStaff(): boolean | string {
    if (!this.month_report.writer_staff1_id) {
      return true;
    }
    if (!this.month_report.writer_staff2_id) {
      return true;
    }
    if (
      this.month_report.writer_staff1_id != this.month_report.writer_staff2_id
    ) {
      return true;
    } else {
      return "作成者が重複しています";
    }
  }

  //作成者：作成者に「保健師」「看護師」「専門看護師」のいずれもいない場合
  private checkWriterStaffDiv(): boolean | string {
    let staff1Div = 0;
    let staff2Div = 0;
    for (const writerStaff of this.writerStaffs) {
      if (this.month_report.writer_staff1_id == writerStaff.value) {
        staff1Div = writerStaff.other;
      }
      if (this.month_report.writer_staff2_id == writerStaff.value) {
        staff2Div = writerStaff.other;
      }
    }
    if (
      staff1Div == 10 ||
      staff1Div == 1 ||
      staff1Div == 3 ||
      staff2Div == 10 ||
      staff2Div == 1 ||
      staff2Div == 3
    ) {
      return true;
    }
    return "作成者1、2どちらかで保健師、看護師、専門看護師の職員を選択してください。";
  }

  //宛名：機関名が被っている場合
  private checkSendPerson(personnel_id: number): boolean | string {
    let counter = 0;
    for (const send of this.month_report.sends) {
      if (personnel_id == send.personnel_id) {
        counter += 1;
      }
      if (counter == 2) {
        return "宛名が重複してます";
      }
    }
    return true;
  }
}
