
























import { Component, Vue } from "vue-property-decorator";
import PatientSearch from "@/components/patientsearch/PatientSearch.vue";
import { COLLECTION_SEARCH_COND_PATIENT_LIST } from "@/const/envFireStore";

@Component({
  components: {
    PatientSearch
  }
})
export default class PatientList extends Vue {
  // fire store 保存先コレクション
  private collection_name = COLLECTION_SEARCH_COND_PATIENT_LIST;

  // 利用者選択
  private clickSelect(patientId: number) {
    this.$router.push(`/patient/${patientId}/calendar`);
  }

  // 新規利用者受付
  private clickAccept() {
    // 利用者登録
    this.$router.push(`/patient/accept`);
  }
}
