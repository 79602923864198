
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AppChatRoomListItem from "./AppChatRoomListItem.vue";

interface ChatRoom {
  id: string;
  title: string;
  imagePath?: string;
  latestMessage: string;
  unreadCount: number;
  isSystemRoom: boolean;
  fileType: number;
}

@Component({
  components: { AppChatRoomListItem }
})
export default class AppChatList extends Vue {
  @Prop({
    default: () => {
      return [];
    }
  })
  chatRooms!: ChatRoom[];

  @Emit()
  private selectRoom(roomId: string) {
    return roomId;
  }
}
