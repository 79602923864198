
























































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { Sheet, PROCCESS_TYPES } from "@/views/master/excel/types";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import { VForm } from "@/types";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: { OfficeSelect }
})
export default class ExcelInputSelect extends Mixins(UtilMixin, RulesMixin) {
  @Ref("inputForm") private readonly form!: VForm;

  /** 処理中のシート情報 */
  @Prop() sheet!: Sheet;

  /** 最終シートフラグ */
  @Prop() isLastSheet!: boolean;

  /** 事業所選択の必要フラグ */
  @Prop() needOfficeSelect!: number;

  /** 処理種類選択肢 */
  private processTypes = PROCCESS_TYPES;

  /** 選択中の処理種類 */
  private processType = 1;

  /** 選択中の重複チェック用項目 */
  private keyType = 1;

  /** 選択事業所ID */
  private selectedOfficeId = 0;

  /** 取り込みボタンを押した時 */
  private async onSubmit() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.inputData();
  }

  @Emit("input:data")
  inputData() {
    return {
      processType: this.processType,
      keyType: this.keyType,
      officeId: this.selectedOfficeId
    };
  }

  @Emit("skip")
  skip() {
    return;
  }

  @Emit("reset")
  reset() {
    return;
  }
}
