



















































































































































import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import {
  Office,
  SateliteHistory,
  DefaultSateliteHistorySameBuilding
} from "#/model/agreement";
import { Facility } from "@/components/master/office/common";
import SameBuildings from "@/components/master/office/SameBuildings.vue";

@Component({
  components: {
    AppPlaces,
    SameBuildings
  }
})
export default class SateliteEdit extends Mixins(UtilMixin, RulesMixin) {
  @Ref("form") public readonly form!: VForm;

  @Prop({ type: Object, default: () => ({}) }) satelite!: SateliteHistory;

  @Prop({ type: Array, default: () => [] }) mainOffices!: Office;

  @Prop({ default: false }) isReadOnly!: boolean;

  @Prop({ default: true }) showDatePicker!: boolean; //履歴日付変更フラグ

  @Prop({ default: () => [] }) facilities!: Facility[]; //同一建物情報

  @Prop({ default: false }) is_init_data!: boolean; //初期データかどうか

  @Prop({ default: false }) is_history!: boolean; //履歴編集かどうか

  //適用開始月ルール
  private checkStart(date: string): boolean | string {
    if (date.slice(0, 7) < "1970-01") {
      return "1970年以降を指定してください";
    }
    return this.required(date, "必須です");
  }

  private requiredParentOfficeId(): boolean | string {
    if (this.satelite.parent_office_id > 0) {
      return true;
    }
    return "必須です";
  }

  private requiredName(): boolean | string {
    if (this.satelite.name) {
      return true;
    }
    return "必須です";
  }

  private requireSpecialAreaSmallArea(): boolean | string {
    if (
      this.satelite.care_special_area_div &&
      this.satelite.care_small_office_scale_add
    ) {
      return "特別地域加算と中山間地域等小規模事業所加算は同時に算定できません。";
    }
    return true;
  }

  private addFacilityItem() {
    if (!this.satelite.satelite_history_same_buildings) {
      this.satelite.satelite_history_same_buildings = [];
    }
    this.satelite.satelite_history_same_buildings.push(
      DefaultSateliteHistorySameBuilding()
    );
  }

  private deleteFacilityItem(item: unknown) {
    if (!this.satelite.satelite_history_same_buildings) {
      this.satelite.satelite_history_same_buildings = [];
    }
    this.satelite.satelite_history_same_buildings = this.satelite.satelite_history_same_buildings.filter(
      facility => facility != item
    );
  }
}
