














import { Component } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class FirstFlg extends CarePartBase {
  //初回加算
  protected chackFirstFlg(): boolean | string {
    //初回加算と退院時共同指導は同時に加算できない
    if (this.receiptVisitRecord.first_flg == 1 && this.nursing_leave_inst_flg) {
      return "初回訪問加算と退院時共同指導加算は併せて算定できません";
    }
    return true;
  }

  //初回訪問加算
  protected disableCheckFirstFlg(): boolean {
    let result = false;
    if (this.receiptVisitRecord.online_visit_flg == 1) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //サービス提供体制
    if (this.disableCheckFirstFlg()) {
      this.receiptVisitRecord.first_flg = 0;
    }
  }
}
