import Base from "../base";

// 自費テンプレート
export interface OwnExpenseTemplate extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  category_name: string; //'カテゴリ名',
  item_name: string; //'アイテム名',
  unit_price: number; //'単価',
  quantity: number; //'数量',
  amount: number; //'金額',
  remarks: string; //'備考',
  tax_div: number; //'課税区分\n0:非課税\n1:内税\n2:外税',
  tax_rate: number; //'課税率（％）',
  medical_deducation: number; //'医療費控除\n0:なし\n1:あり',
}

export const DefaultOwnExpenseTemplate = (): OwnExpenseTemplate => ({
  id: 0,
  patient_id: 0,
  category_name: "",
  item_name: "",
  unit_price: 0,
  quantity: 0,
  amount: 0,
  remarks: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  tax_div: 0,
  tax_rate: 0,
  medical_deducation: 0
});
