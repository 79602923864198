





























































import {
  CalendarDispEventMoving as CalendarDispEvent,
  EditRangeDiv
} from "#/components/calendar/common";
import { Component, Ref, Prop, Emit, Mixins } from "vue-property-decorator";
import * as appDate from "#/utility/appDate";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { CalendarEvent, Choice } from "@/types";
import { OfficeStaffChoice } from "#/model/staff";
import AppCalendarV2 from "#/components/AppCalendarV2.vue";
import VisitPlanShowEventDialog from "./VisitPlanShowEventDialog.vue";
import VisitPlanEditEventDialog from "./VisitPlanEditEventDialog.vue";
import StaffScheduleShowEventDialog from "./StaffScheduleShowEventDialog.vue";
import StaffScheduleEditEventDialog from "./StaffScheduleEditEventDialog.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    AppCalendarV2,
    VisitPlanShowEventDialog,
    VisitPlanEditEventDialog,
    StaffScheduleShowEventDialog,
    StaffScheduleEditEventDialog
  }
})
export default class IbowCalendar extends Mixins(UtilMixin, AxiosMixin) {
  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: AppCalendarV2;

  /** 訪問予定表示ダイアログ */
  @Ref("visit-plan-show-event-dialog")
  private readonly visitPlanShowEventDialog!: VisitPlanShowEventDialog;

  /** 訪問予定編集ダイアログ */
  @Ref("visit-plan-edit-event-dialog")
  public readonly visitPlanEditEventDialog!: VisitPlanEditEventDialog;

  /** 職員予定表示ダイアログ */
  @Ref("staff-schedule-show-event-dialog")
  private readonly staffScheduleShowEventDialog!: StaffScheduleShowEventDialog;

  /** 職員予定編集ダイアログ */
  @Ref("staff-schedule-edit-event-dialog")
  public readonly staffScheduleEditEventDialog!: StaffScheduleEditEventDialog;

  /** 表示基準日 */
  @Prop({ default: "" }) value!: string;

  /** カレンダータイプ 日・週・月 */
  @Prop({ default: "month" }) type!: string;

  /** カレンダーイベント */
  @Prop({ default: () => [] }) events!: CalendarDispEvent[];

  /** カレンダーの高さ */
  @Prop({ default: "auto" }) height!: string;

  /** カレンダークラス */
  @Prop({ default: "" }) calendarClass!: string;

  /** 誕生日 */
  @Prop() birthday!: string;

  /** 時間毎の間隔 */
  @Prop({ default: 80 }) intervalHeight!: number;

  /** 表示時間数 */
  @Prop({ default: 24 }) intervalCount!: number;

  /** 一番上の表示時間 */
  @Prop({ default: 0 }) firstInterval!: number;

  /** クリックできるイベントタイプ */
  @Prop({ default: () => [] }) clickTypes!: number[];

  /** 移動できるイベントタイプ */
  @Prop({ default: () => [] }) moveTypes!: number[];

  /** 利用者一覧 */
  @Prop({ default: () => [] }) patients!: Choice[];

  /** 職員一覧 */
  @Prop({ default: () => [] }) staffs!: OfficeStaffChoice[];

  /** ログイン職員ID */
  @Prop({ default: 0 }) loginStaffLayerId!: number;

  /** 訪問予定の編集が可能であるかどうか */
  @Prop({ default: true }) isVisitPlanEditable!: boolean;

  /** カレンダーイベントのマージン */
  @Prop({ default: 1 }) eventMarginBottom!: number;

  /** 利用者個別予定の編集を行うか */
  @Prop({ default: false }) editableIndividual!: boolean;

  /** その他カレンダーイベントをクリックした時の処理 */
  @Emit()
  private otherHandleEvent(event: CalendarDispEvent) {
    return event;
  }

  /** その他カレンダーイベントを移動した時の処理 */
  @Emit()
  private otherMoveEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent | null;
  }) {
    return e;
  }

  /** 表示基準日の変更 */
  @Emit() input(newValue: string) {
    return newValue;
  }

  /** カレンダータイプの変更 */
  @Emit("update:type") updateType(newValue: string) {
    return newValue;
  }

  /** カレンダーをクリックする */
  @Emit() clickCalendar(e: CalendarEvent) {
    return e;
  }

  /** データ更新 */
  @Emit() update() {
    return;
  }

  /** イベント */
  private get Events(): CalendarDispEvent[] {
    for (const event of this.events) {
      if (this.moveTypes.indexOf(event.type) === -1) {
        event.is_not_moving = true;
        continue;
      }
      if (event.status === 1) {
        event.is_not_moving = true;
        continue;
      }
      if (event.type === EventType.StaffSchedule && event.button_type !== 1) {
        event.is_not_moving = true;
        continue;
      }
    }
    return this.events;
  }

  /** 表示基準日 */
  private get Value(): string {
    return this.value;
  }
  private set Value(newValue: string) {
    this.input(newValue);
  }

  /** カレンダータイプ 日・週・月 */
  private get Type(): string {
    return this.type;
  }
  private set Type(newType: string) {
    this.updateType(newType);
  }

  /** カレンダーのタイトル */
  public get title() {
    return this.calendar.title;
  }

  /** 前へ */
  public prev() {
    return this.calendar.prev();
  }

  /** 次へ */
  public next() {
    return this.calendar.next();
  }

  /** スクロール位置を戻す */
  public scrollToTime(time: string) {
    this.calendar.scrollToTime(time);
  }

  /** イベントをクリックした時 */
  private clickEvent(event: CalendarDispEvent) {
    if (this.clickTypes.indexOf(event.type) === -1) {
      return;
    }

    switch (event.type) {
      case EventType.StaffSchedule: //職員予定
        this.clickStaffEvent(event);
        break;
      case EventType.VisitSchedule: //訪問予定
      case EventType.VisitScheduleNoStaff: //訪問予定（未割り当て）
      case EventType.IndivisualSchedule: //利用者個別予定
        this.clickVisitPlanEvent(event);
        break;
      default:
        this.otherHandleEvent(event);
        break;
    }
  }

  /** イベントを移動した時 */
  private moveEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent;
  }) {
    if (this.moveTypes.indexOf(e.event.type) === -1) {
      return;
    }

    switch (e.event.type) {
      case EventType.StaffSchedule: //職員予定
        this.moveStaffEvent(e);
        break;
      case EventType.VisitSchedule: //訪問予定
      case EventType.VisitScheduleNoStaff: //訪問予定（未割り当て）
      case EventType.IndivisualSchedule: //利用者個別予定
        this.moveVisitPlanEvent(e);
        break;
      default:
        this.otherMoveEvent(e);
        break;
    }
  }

  /** 職員予定をクリックした時 */
  private clickStaffEvent(event: CalendarDispEvent) {
    if (event.button_type > 0) {
      this.staffScheduleShowEventDialog.open(event);
    }
  }

  /** 訪問予定をクリックした時 */
  private clickVisitPlanEvent(event: CalendarDispEvent) {
    this.visitPlanShowEventDialog.open(event);
  }

  /** 職員予定を移動した時 */
  private moveStaffEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent;
  }) {
    this.postJsonCheck(
      window.base_url + "/api/calendar/event/simplesave",
      {
        event_id: e.event.event_id,
        start: e.event.start,
        end: e.event.end,
        edit_start: e.orgEvent.start
      },
      () => {
        this.update();
      },
      () => {
        e.event.start = e.orgEvent.start;
        e.event.end = e.orgEvent.end;
      }
    );
    return;
  }

  /** 訪問予定を移動した時 */
  private moveVisitPlanEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent;
  }) {
    this.postJsonCheck(
      window.base_url + "/api/schedule/visitschedule/simplesave",
      {
        event: e.event
      },
      async res => {
        const overlapSchedule = res.data.overlap_schedule_texts;
        if (overlapSchedule) {
          await this.alertOverlapSchedule(overlapSchedule);
        } else {
          e.event.is_repeat = false;
        }
        this.update();
      },
      () => {
        e.event.start = e.orgEvent.start;
        e.event.end = e.orgEvent.end;
      }
    );
    return;
  }

  /** 職員予定を編集する */
  private editStaffScheduleEvent(req: {
    event: CalendarDispEvent;
    editRange: EditRangeDiv;
    isCopy: boolean;
  }) {
    const date = appDate.strToDate(req.event.start);
    const cancelStaff: number[] = [];
    if (req.event.status === 1) {
      cancelStaff.push(req.event.staff_id);
    }
    this.staffScheduleEditEventDialog.open(
      req.event.event_id,
      appDate.dateToStr(date, "yyyy-MM-dd"),
      req.editRange,
      cancelStaff,
      0,
      0,
      req.isCopy
    );
  }

  /** 訪問予定を編集する */
  private editVisitPlanEvent(req: {
    event: CalendarDispEvent;
    editRange: EditRangeDiv;
    isCopy: boolean;
  }) {
    this.visitPlanEditEventDialog.open(
      req.event.event_id,
      this.dateToStr(appDate.strToDate(req.event.start), "yyyy-MM-dd"),
      0,
      0,
      0,
      req.editRange,
      0,
      req.isCopy
    );
  }

  /** 重複した訪問予定がある場合はアラート */
  private async alertOverlapSchedule(schedules: string[]) {
    let text = "";
    if (schedules.length === 0) {
      return;
    } else if (schedules.length === 1) {
      text = "同じ時間帯に【";
      text += schedules[0];
      text += "】の予定が登録されているため、登録できません";
    } else {
      text = "同じ時間帯に複数の予定が登録されているため、登録できません\n\n";
      schedules.forEach(schedule => {
        text += schedule + "\n\n";
      });
    }

    await this.$openAlert(text);
  }
}
