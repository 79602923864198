






























import { Component, Vue, Prop, Emit, Ref } from "vue-property-decorator";
import {
  AdlEvalItemPrimaryContent,
  DefaultAdlEvalItemPrimaryContent
} from "@/views/master/adlitem/types";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";
import { VForm } from "@/types";

@Component({
  components: { OfficeSelectInput }
})
export default class EditNursingPrimaryContentDialog extends Vue {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {
        id: null,
        name: "",
        items: []
      };
    }
  })
  content!: AdlEvalItemPrimaryContent;

  private cloneContent: AdlEvalItemPrimaryContent = DefaultAdlEvalItemPrimaryContent();

  // 選択済みの適応事業所
  public selectedOffice: number[] = [];

  private get IsDelete() {
    return this.cloneContent.id;
  }

  created() {
    this.cloneContent = { ...this.content };
  }

  private mounted(): void {
    if (this.cloneContent.offices) {
      this.cloneContent.offices.forEach(office => {
        if (office.office_id) {
          this.selectedOffice.push(office.office_id);
        }
      });
    } else {
      this.selectedOffice = [];
    }
  }

  //保存
  private save() {
    if (!this.form.validate()) {
      return;
    }
    this.cloneContent.offices = [];
    if (this.selectedOffice.length > 0) {
      this.selectedOffice.forEach(officeId => {
        this.cloneContent.offices.push({
          office_id: officeId
        });
      });
    }
    this.saveDialog(this.cloneContent);
  }

  //削除
  private async remove() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.removeDialog(this.cloneContent);
  }

  //キャンセル
  private cancel() {
    this.closeDialog();
  }

  @Emit("close")
  private closeDialog() {
    return;
  }

  @Emit("remove")
  private removeDialog(content: unknown) {
    return content;
  }

  @Emit("save")
  private saveDialog(content: unknown) {
    return content;
  }
}
