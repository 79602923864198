













import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { InquiryAnswerFile } from "#/model/inquiry";

@Component({
  components: {}
})
export default class InquiryAnswerFileViewer extends Mixins(
  UtilMixin,
  AxiosMixin
) {
  @Prop({ default: () => [] as InquiryAnswerFile[] })
  inquiryAnswerFiles!: InquiryAnswerFile[];
}
