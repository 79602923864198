

















































































































import { Component, Ref } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import BiOfficeSelect from "@/components/officeAggregate/BiOfficeSelect.vue";
import {
  ChartData,
  TableData,
  SearchCondition
} from "@/components/officeAggregate/unitCost/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {
    BiOfficeSelect
  }
})
export default class UnitCost extends Base {
  @Ref("unit_cost") private readonly form!: VForm;

  private chart!: am4charts.XYChart; //アムチャート
  private chartDatas: ChartData[] = []; //アムチャートデータ
  private tableDatas: TableData[] = []; //テーブルデータ
  private officeId = 0; //検索条件：事業所ID
  private start = ""; //検索条件：請求開始月
  private end = ""; //検索条件：請求終了月

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      text: "年月",
      value: "date",
      align: "start",
      width: ""
    },
    {
      text: "単価（1時間あたり）",
      value: "unit_cost",
      align: "start",
      width: ""
    },
    {
      text: "総金額",
      value: "total_amount",
      align: "start",
      width: ""
    },
    {
      text: "総勤務時間（時間）",
      value: "total_office_hours",
      align: "start",
      width: ""
    },
    {
      text: "総職員数",
      value: "total_staff",
      align: "start",
      width: ""
    },
    {
      text: "",
      value: "actions",
      align: "center",
      width: "75px",
      sortable: false
    }
  ];

  public async mounted() {
    this.collection = "office-aggregate-unit-cost";
    this.documentId = String(this.loginUser.id);
    const fbDoc = await this.documentGet();

    //親画面で保存した検索条件
    const parentFbDoc = await this.getParentSearchCon();

    //デフォルトの検索条件
    this.officeId = this.offices(); //まず、選択肢の1番目で検索する
    this.start = this.lastmonth();
    this.end = this.todaymonth();

    if (parentFbDoc !== undefined && typeof parentFbDoc !== "boolean") {
      const searchCondition = parentFbDoc;
      this.start = searchCondition?.start;
      this.end = searchCondition?.end;
    }
    if (fbDoc !== undefined && typeof fbDoc !== "boolean") {
      const searchCondition = fbDoc.searchCondition as SearchCondition;
      this.officeId = searchCondition?.office_id;
    }

    this.disposeChart();
    // 初期メッセージ
    this.chart = this.xyChartMessage();
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.XYChart) {
    // 拡大スクロール
    const scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;

    // Create axes
    //日付ラベル
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.dateFormatter.language = new am4core.Language();
    dateAxis.dateFormatter.language.locale = am4lang_ja_JP;
    dateAxis.language.locale["_date_day"] = "MMM";
    dateAxis.language.locale["_date_year"] = "yyyy年";
    dateAxis.baseInterval = {
      timeUnit: "month",
      count: 1
    };

    //売上金額ラベル
    const totalAmountAxis = chart.yAxes.push(new am4charts.ValueAxis());
    totalAmountAxis.title.text = "売上金額";
    let taMax = 0;
    this.tableDatas.forEach((item: TableData) => {
      if (item.total_amount > taMax) {
        taMax = item.total_amount;
      }
    });
    if (taMax < 10) {
      totalAmountAxis.min = 0;
      totalAmountAxis.max = 10;
    }

    //単価ラベル
    const unitCostAxis = chart.yAxes.push(new am4charts.ValueAxis());
    unitCostAxis.title.text = "勤務時間単価";
    unitCostAxis.renderer.opposite = true;
    let ucMax = 0;
    this.tableDatas.forEach((item: TableData) => {
      if (item.unit_cost > ucMax) {
        ucMax = item.unit_cost;
      }
    });
    if (ucMax < 10) {
      unitCostAxis.min = 0;
      unitCostAxis.max = 10;
    }

    // 売上金額グラフ
    const totalAmountSeries = chart.series.push(new am4charts.ColumnSeries());
    totalAmountSeries.dataFields.valueY = "total_amount";
    totalAmountSeries.dataFields.dateX = "date";
    totalAmountSeries.yAxis = totalAmountAxis;
    totalAmountSeries.tooltipText = "{valueY} 円";
    totalAmountSeries.name = "売上金額";
    totalAmountSeries.columns.template.fillOpacity = 0.7;
    totalAmountSeries.columns.template.propertyFields.strokeDasharray =
      "dash_length";
    totalAmountSeries.columns.template.propertyFields.fillOpacity = "alpha";
    totalAmountSeries.showOnInit = true;

    const dtotalAmountState = totalAmountSeries.columns.template.states.create(
      "hover"
    );
    dtotalAmountState.properties.fillOpacity = 0.9;

    // 単価グラフ
    const unitCostSeries = chart.series.push(new am4charts.LineSeries());
    unitCostSeries.dataFields.valueY = "unit_cost";
    unitCostSeries.dataFields.dateX = "date";
    unitCostSeries.yAxis = unitCostAxis;
    unitCostSeries.name = "単価";
    unitCostSeries.strokeWidth = 2;
    unitCostSeries.propertyFields.strokeDasharray = "dash_length";
    unitCostSeries.tooltipText = "{valueY} 円";
    unitCostSeries.showOnInit = true;

    const bullet = unitCostSeries.bullets.push(new am4charts.Bullet());
    const rectangle = bullet.createChild(am4core.Rectangle);
    bullet.horizontalCenter = "middle";
    bullet.verticalCenter = "middle";
    bullet.width = 7;
    bullet.height = 7;
    rectangle.width = 7;
    rectangle.height = 7;

    const state = bullet.states.create("hover");
    state.properties.scale = 1.2;

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color("#000");
    chart.cursor.lineX.fillOpacity = 0.1;

    return chart;
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      alert("入力内容に不備があります");
      return;
    }
    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  // 詳細ボタン
  private clickDetail(item: TableData) {
    const url = "/office_aggregate/unit_cost/detail";
    this.$router.push(
      url +
        `?id=${item.office_id}` +
        `&office=${item.office}` +
        `&yearmonth=${item.date}`
    );
  }

  // Excelダウンロード
  private downloadExcel() {
    const officeName = this.getOfficeName(this.officeId);
    this.postJsonBlobResCheck(
      window.base_url + "/api/officeAggregate/unitCost/downloadexcel",
      {
        table_datas: this.tableDatas,
        start: this.start,
        end: this.end,
        office_name: officeName
      },
      res => {
        const fileName = "unitCost.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    const searchCondition = {
      office_id: this.officeId,
      start: this.start,
      end: this.end
    };
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/unitCost/search",
        searchCondition,
        res => {
          this.chartDatas = res.data.chart_datas;
          this.chartDatas.reverse();
          this.tableDatas = res.data.table_datas;
          this.documentSave({
            searchCondition: searchCondition
          });
          resolve(0);
        }
      );
    });
  }
}
