import { Vue, Component } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import * as report from "@/store/modules/firestore/types";
import { name } from "@/store/modules/visitTmpData";
import { VisitModelExt } from "@/components/visit/types";
import { networkErrorMessage, firestoreUpdateErrorMessage } from "../const";

const VisitTmpData = namespace(name);

@Component
export default class VisitTmpDataMixin extends Vue {
  @State(name) visitTmpDataState!: report.FirestoreState;

  @VisitTmpData.Action(report.SAVE_DOCUMENT) saveVisitStore!: Function;

  @VisitTmpData.Action(report.UPDATE_DOCUMENT) updateVisitStore!: Function;

  @VisitTmpData.Action(report.DELETE_DOCUMENT) deleteVisitStore!: Function;

  @VisitTmpData.Action(report.GET_DOCUMENT) getVisitStore!: Function;

  /**
   * 訪問中データ保存
   */
  protected async saveVisitTmpData(model: VisitModelExt): Promise<string> {
    let errorMessage = "";
    try {
      await this.saveVisitStore(model);
    } catch {
      await this.$openAlert(networkErrorMessage);
      errorMessage = networkErrorMessage;
    }
    return errorMessage;
  }

  /**
   * 訪問中データ更新 データがない場合はエラー
   */
  protected async updateVisitTmpData(model: VisitModelExt): Promise<string> {
    let errorMessage = "";
    try {
      await this.updateVisitStore(model);
    } catch (error) {
      errorMessage = networkErrorMessage;

      const errorObject = error as Error;

      // 更新失敗(=既に訪問終了している)なら専用のエラーメッセージを表示
      if (errorObject.message.indexOf("No document to update") !== -1) {
        errorMessage = firestoreUpdateErrorMessage;
      }
      await this.$openAlert(errorMessage);
    }
    return errorMessage;
  }

  /**
   * 訪問中データ削除
   */
  protected async deleteVisitTmpData(): Promise<string> {
    let errorMessage = "";
    try {
      await this.deleteVisitStore();
    } catch {
      await this.$openAlert(networkErrorMessage);
      errorMessage = networkErrorMessage;
    }
    return errorMessage;
  }

  /**
   * 訪問中データ取得
   */
  protected async getVisitTmpData(staffId: string): Promise<string> {
    let errorMessage = "";
    try {
      await this.getVisitStore(staffId);
    } catch {
      await this.$openAlert(networkErrorMessage);
      errorMessage = networkErrorMessage;
    }
    return errorMessage;
  }
}
