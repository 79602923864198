import Base from "./base";
import { Pdf, DefaultPdf, Remand, DefaultRemand } from "./report";
import {
  ApplyPersonnel,
  DefaultApplyPersonnel,
  ApplyStaff,
  DefaultApplyStaff,
  ApplyDoctor,
  DefaultApplyDoctor
} from "./common";

/** 会議録差戻し */
export interface ConferenceReportRemand extends Remand {
  conference_report_id: number; //会議録ID
}
export const DefaultConferenceReportRemand = (): ConferenceReportRemand => ({
  ...DefaultRemand(),
  conference_report_id: 0
});

//会議録_出席者
export interface ConferenceReportStaff extends Base {
  id: number; //ID
  conference_report_id: number; //'会議録ID',
  attendees_div: number; //'出席者区分\n1:担当者\n2:副担当者\n3:出席者',
  staff_id: number; //'担当者ID',
  staff_office_name: string; //'事業所名',
  institution_div: number; //'出席者施設区分\n0:未選択\n1:医療機関\n2:居宅介護支援事業\n3:その他',
  doctor_id: number; //'医療機関出席者ID',
  personnel_id: number; //'居宅介護支援事業出席者ID',
  other_name: string; //'その他名称',
  other_div: number; //'その他敬称区分\n1:御中\n2:様\n3:敬称なし',
  staff: ApplyStaff; //（副）担当者
  doctor: ApplyDoctor; //医師
  personnel: ApplyPersonnel; //担当者
}
export const DefaultConferenceReportStaff = (
  div = 0
): ConferenceReportStaff => ({
  id: 0,
  conference_report_id: 0,
  attendees_div: div,
  staff_id: 0,
  staff_office_name: "",
  institution_div: 0,
  doctor_id: 0,
  personnel_id: 0,
  other_name: "",
  other_div: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  staff: DefaultApplyStaff(),
  doctor: DefaultApplyDoctor(),
  personnel: DefaultApplyPersonnel()
});

//会議録
export interface ConferenceReport extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  kind: number; //'会議種類\n1:退院支援指導カンファレンス\n2:退院共同指導カンファレンス\n3:サービス担当者会議\n4:その他会議',
  title: string; //'会議録タイトル',
  conference_date: string; //'会議日',
  discharge_date: string; //'退院日',
  place: string; //'会議場所',
  content: string; //'会議内容',
  agreement_flg: number; //'利用者同意欄表示フラグ\n0:表示しない\n1:表示する'',
  write_date: string; //'記載日',
  submit_datetime_wareki: string; //提出日時（和暦）
  conference_date_wareki: string; //会議日（和暦）
  discharge_date_wareki: string; //退院日（和暦）
  write_date_wareki: string; //記載日（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  pdf: Pdf; //PDF
  remand: Remand; //差し戻し
  staffs: ConferenceReportStaff[]; //出席者
  editor_staff: ApplyStaff; //編集者
}
export const DefaultConferenceReport = (): ConferenceReport => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  submit_datetime: "",
  kind: 0,
  title: "",
  conference_date: "",
  discharge_date: "",
  place: "",
  content: "",
  agreement_flg: 0,
  write_date: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  submit_datetime_wareki: "",
  conference_date_wareki: "",
  discharge_date_wareki: "",
  write_date_wareki: "",
  updated_at_wareki: "",
  pdf: DefaultPdf(),
  remand: DefaultRemand(),
  staffs: [
    DefaultConferenceReportStaff(1),
    DefaultConferenceReportStaff(2),
    DefaultConferenceReportStaff(2),
    DefaultConferenceReportStaff(3)
  ],
  editor_staff: DefaultApplyStaff()
});
