











import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import PatientMixin from "@/mixins/patientMixin";
import { RequesterChoice } from "@/types";

@Component
export default class PatientRelationInsOnlySelect extends Mixins(PatientMixin) {
  @Prop({ default: "関連機関のみ" }) label!: string;
  @Prop() value!: number;
  @Prop({ default: () => [] }) support_office_divs!: number[];
  @Prop({ default: false }) not_select!: boolean;
  @Prop({ default: () => [] }) document_forms!: number[];

  private get Items() {
    const items: RequesterChoice[] = [];

    if (this.not_select) {
      items.push({
        id: 0,
        value: 0,
        text: "未選択",
        tel_area: "",
        tel_city: "",
        tel_no: ""
      });
    }

    for (const ins of this.patientMaster.relation_institutions) {
      if (this.support_office_divs.length > 0) {
        const isOK = this.support_office_divs.some(div => {
          return div == ins.support_office_div;
        });
        if (!isOK) {
          continue;
        }
      }

      if (this.document_forms.length > 0) {
        const isOK = this.document_forms.some(form => {
          return form == ins.document_form;
        });
        if (!isOK) {
          continue;
        }
      }

      for (const personnel of ins.personnels) {
        if (personnel.is_empty == 1) {
          continue;
        }

        let text = ins.name;
        if (personnel.full_name !== "") {
          text += "/" + personnel.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: personnel.id,
          value: personnel.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num
        });
      }
    }
    return items;
  }

  private get Value(): number {
    return this.value;
  }

  private set Value(newValue: number) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: number) {
    return newValue;
  }
}
