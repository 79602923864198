














































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import { Choice } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Adl, AdlItem } from "#/model/adl";

interface AdlEvaluationItem {
  text: string;
  value: number;
  adl_evaluation_select_items: Choice[];
}

@Component
export default class AdlSelectItem extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Prop({ default: null }) item!: AdlItem;
  @Prop({ default: null }) adlEvaluationItems!: AdlEvaluationItem[];
  @Prop({ default: null }) adl!: Adl;
  @Prop() checkAdl!: (num: number) => string | boolean;

  @Emit()
  deleteAdl() {
    return;
  }

  //ADL項目変えた時
  private changeAdl() {
    this.item.adl_evaluation_select_item_id = 0;
  }

  //ADL評価項目表示作成
  private get adlEvaluationSelectItems(): Choice[] {
    for (const item of this.adlEvaluationItems) {
      if (item.value == this.item.adl_evaluation_item_id) {
        return item.adl_evaluation_select_items;
      }
    }
    return [];
  }
}
