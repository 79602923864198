






































































































































import { Component, Emit, Prop, Mixins, Ref } from "vue-property-decorator";
import { Staff } from "#/model/staff";
import AppColorPicker from "#/components/AppColorPicker.vue";
import { Document, SetColor } from "@/components/calendar/common";
import UtilMixin from "@/mixins/utilMixin";
import { COLLECTION_HOME_ITEM_LIST } from "@/const/envFireStore";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { ButtonItems } from "@/components/home/types";
import SearchStaffDialog from "@/components/calendar/SearchStaffDialog.vue";
import NavigationLayerCheckbox from "@/components/calendar/NavigationLayerCheckbox.vue";
import NavigationLayerStaffScheduleToggle from "@/components/calendar/NavigationLayerStaffScheduleToggle.vue";
import NavigationStaff from "@/components/calendar/NavigationStaff.vue";
import { Layer, CalendarDispEvent } from "#/components/calendar/common";

@Component({
  components: {
    AppColorPicker,
    SearchStaffDialog,
    NavigationLayerCheckbox,
    NavigationLayerStaffScheduleToggle,
    NavigationStaff
  }
})
export default class Navigation extends Mixins(UtilMixin, FireStoreMixin) {
  /** 職員検索コンポーネント（追加） */
  @Ref("addSearchStaff") private readonly addSearchStaff!: SearchStaffDialog;

  /** 職員検索コンポーネント（削除） */
  @Ref("subSearchStaff") private readonly subSearchStaff!: SearchStaffDialog;

  /** 選択職員情報のFirestoreオブジェクト */
  @Prop() document!: Document;

  /** 職員色情報のFirestoreオブジェクト */
  @Prop() docSetColor!: SetColor;

  /** 職員一覧 */
  @Prop() staffs!: Staff[];

  /** 対象年月日 */
  @Prop() focus!: string;

  /** その他レイヤー */
  @Prop() other_layers!: Layer;

  /** イベント */
  @Prop() events!: CalendarDispEvent[];

  /** 表示設定を切り替えた時 */
  @Emit() changeDocument() {
    return;
  }

  /** 職員の追加時（色情報の更新が必要） */
  @Emit() addStaffs() {
    return;
  }

  /** ナビゲーション開閉状態 */
  private openNav = false;

  /** 全体フォントサイズ */
  private selectFontSize = 0;

  /** 画面横幅 */
  private currentWidth = window.innerWidth;

  /** まだ追加していない職員 */
  private get UnAddStaffs(): Staff[] {
    const showStaffIds = this.document.showStaffs.map(staff => staff.id);
    return this.staffs.filter(staff => {
      return !showStaffIds.includes(staff.id);
    });
  }

  /** 追加済みの職員 */
  private get AddedStaffs(): Staff[] {
    const showStaffIds = this.document.showStaffs.map(staff => staff.id);
    return this.staffs.filter(staff => {
      //ログイン職員は除く
      if (staff.id === this.loginUser.id) {
        return false;
      }
      return showStaffIds.includes(staff.id);
    });
  }

  /** 職員リストをスクロールさせるかどうか */
  private get StaffListIsScroll(): boolean {
    return this.document.showStaffs.length > 6;
  }

  /** 職員リストの高さ */
  private get StaffListHeight(): number {
    if (this.document.showStaffs.length > 10) {
      return 750;
    } else {
      return 500;
    }
  }

  /** 職員リストのアイテムの高さ */
  private get StaffListItemHeight(): number {
    switch (this.selectFontSize) {
      case 0:
        return 80;
      case 1:
        return 90;
      case 2:
        return 100;
      default:
        return 80;
    }
  }

  async mounted() {
    // xs以上の場合はサイドメニュー開く
    if (!this.$vuetify.breakpoint.xs) {
      this.openNav = true;
    }
    if (this.loginUser.id) {
      this.collection = COLLECTION_HOME_ITEM_LIST;
      this.documentId = String(this.loginUser.id);
      const documentButtonItem = (await this.documentGet()) as ButtonItems;
      if (documentButtonItem) {
        this.selectFontSize = documentButtonItem.selectFontSize;
      } else if (documentButtonItem === false) {
        // firestore取得失敗
        this.$openAlert(
          "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
        );
        return;
      }
    }
    window.addEventListener("resize", this.setNavDisp);
  }

  /** レイヤーIDに対応するドキュメントを取得する */
  private getDocumentByLayerId(
    layerId: number
  ): {
    layerId: number;
    checked: boolean;
  } {
    const s = this.document.selectLayers.find(
      layer => layer.layerId === layerId
    );
    if (s) {
      return s;
    }
    this.document.selectLayers.push({
      layerId: layerId,
      checked: true
    });
    return this.document.selectLayers[this.document.selectLayers.length - 1];
  }

  /** ナビゲーションの開閉 */
  private switchNav() {
    this.openNav = !this.openNav;
  }

  /** ナビゲーションの開閉設定 */
  private setNavDisp() {
    if (this.currentWidth == window.innerWidth) {
      //横幅が変わっていないので処理中断
      return;
    }
    this.currentWidth = window.innerWidth;
    if (this.$vuetify.breakpoint.xs) {
      this.openNav = false;
    }
  }

  /** 全職員カレンダーボタンクリック */
  private clickAllStaffCalendarEvent() {
    this.setIndividualStaff(0);
  }

  /** 全職員訪問予定ボタンクリック */
  private clickAllStaffVisitEvent() {
    this.setIndividualStaff(1);
  }

  /** 全職員シフトボタンクリック */
  private clickAllStaffShiftEvent() {
    this.setIndividualStaff(2);
  }

  /** 予定表示職員を削除する（単体） */
  private async delCalendarStaff(staffId: number) {
    this.document.showStaffs = this.document.showStaffs.filter(
      showStaff => showStaff.id !== staffId
    );
    this.changeDocument();
  }

  /** 予定表示職員を追加する */
  private async addCalendarStaffs(staffIds: number[]) {
    staffIds.forEach(staffId => {
      const index = this.document.showStaffs.findIndex(
        staff => staff.id === staffId
      );
      if (index === -1) {
        this.document.showStaffs.push({
          id: staffId,
          checked: [0, 1, 2],
          color: "",
          display: true
        });
      }
    });
    this.addStaffs();
  }

  /** 予定表示職員を削除する */
  private async delCalendarStaffs(staffIds: number[]) {
    staffIds.forEach(staffId => {
      const index = this.document.showStaffs.findIndex(
        staff => staff.id === staffId
      );
      if (index > -1) {
        this.document.showStaffs.splice(index, 1);
      }
    });
    this.changeDocument();
  }

  /** 各職員に設定する */
  private setIndividualStaff(type: number) {
    if (this.document.allDisp.includes(type)) {
      this.document.showStaffs.forEach(staff => {
        staff.checked = staff.checked.filter(item => item !== type);
      });
    } else {
      this.document.showStaffs.forEach(staff => {
        if (!staff.checked.includes(type)) {
          staff.checked.push(type);
        }
      });
    }
    this.changeDocument();
  }
}
