// 選択職員インターフェース
export interface SelectStaff {
  id: number; // 職員ID
  checked: number[]; // チェック状態
  color: string;
  display: boolean;
}

export interface SetColor {
  setting: SetColorItem[];
}
export interface SetColorItem {
  id: number; // 職員ID
  color: string;
}
export const DefaultDocumentSettingColor = (): SetColor => ({
  setting: []
});

// カレンダー表示状態
export interface Document {
  selectLayers: { layerId: number; checked: boolean }[]; // 選択中のレイヤー
  openAcrodion: number[]; // アコーディオンオープン状態
  type: string; // カレンダータイプ
  showStaffs: SelectStaff[]; // 表示職員
  allDisp: number[]; //全職員用表示設定
}
export const DefaultDocument = (): Document => ({
  selectLayers: [],
  openAcrodion: [0, 1, 2],
  type: "month",
  showStaffs: [],
  allDisp: [0, 1, 2]
});

/** ドキュメントに保存しているイベントのタイプ */
export const DOCUMENT_EVENT_TYPE = {
  /** 職員予定 */ STAFF: 0,
  /** 訪問予定 */ VISIT: 1,
  /** シフト */ SHIFT: 2
};
