




























































































import {
  Component,
  Mixins,
  Watch,
  Prop,
  Emit,
  Ref
} from "vue-property-decorator";
import { AxiosError } from "axios";
import LoginTitle from "@/components/app/login/LoginTitle.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

@Component({
  components: { LoginTitle }
})
export default class LoginLink extends Mixins(RulesMixin) {
  @Ref("form") private readonly form!: VForm;

  /** メールアドレス/電話番号/iBowID */
  private ibow_send_info = "";

  /** パスワード */
  private password = "";

  /** ローディング */
  private loading = false;

  /** エラーメッセージ */
  private errorMessages = "";

  /** iBowアカウント作成画面 */
  private accountMakeUrl =
    window.auth_middleend_url + "/accountform/prov/entry";

  /** パスワード表示非表示 */
  private isShow = false;

  /** 連携情報 */
  @Prop({ default: () => ({}) }) linkInfoPayload!: {
    token: string;
    ref_token: string;
    user_name: string;
    user_id: number;
  };

  @Watch("ibow_send_info")
  private watchUserId() {
    if (this.ibow_send_info != "") {
      this.errorMessages = "";
    }
  }

  //ログインボタンクリック
  private clickLogin() {
    if (!this.form.validate()) {
      return;
    }

    this.loading = true;
    window.axios
      .post(
        window.base_url + "/api/auth/ibowid-connect",
        {
          ibow_send_info: this.ibow_send_info,
          password: this.password
        },
        {
          headers: {
            Authorization: "Bearer " + this.linkInfoPayload.token
          }
        }
      )
      .then(async () => {
        this.loading = false;
        await this.$openAlert(
          "連携が成功しました。\n新しいiBowのログイン画面に移動します。\n今入力したiBowアカウントでログインいただけます。"
        );
        location.href = "/";
      })
      .catch(async (error: AxiosError) => {
        this.loading = false;
        if (error.response) {
          this.errorMessages = error.response.data.message;
        } else {
          await this.$openAlert(
            "サーバでエラーが発生しました。\n(" + error + ")"
          );
          console.log(error);
          console.log(error.response);
        }
      });
  }

  /** iBowアカウント作成画面へ */
  private clickMakeIbowAccount() {
    window.open(this.accountMakeUrl, "_blank");
    window.addEventListener("message", e => {
      if (e.origin !== window.auth_middleend_url) {
        return;
      }
      this.ibow_send_info = e.data;
    });
  }

  /** 戻るボタン */
  @Emit()
  private returnLinkView() {
    return this.linkInfoPayload;
  }
}
