
















































































































import { Component, Ref } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import BiOfficeSelect from "@/components/officeAggregate/BiOfficeSelect.vue";
import {
  ChartData,
  TableData,
  SearchCondition
} from "@/components/officeAggregate/officeinvoice/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {
    BiOfficeSelect
  }
})
export default class OfficeInvoice extends Base {
  @Ref("office_invoice") private readonly form!: VForm;

  private chart!: am4charts.XYChart; //アムチャート
  private chartDatas: ChartData[] = []; //アムチャートデータ
  private tableDatas: TableData[] = []; //テーブルデータ
  private officeIds: number[] = []; //検索条件：事業所IDs
  private start = ""; //検索条件：請求開始月
  private end = ""; //検索条件：請求終了月

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      text: "年月",
      value: "date",
      align: "start",
      width: ""
    },
    {
      text: "事業所",
      value: "office",
      align: "start"
    },
    {
      text: "看護記録書Ⅱ提出枚数",
      value: "submissions",
      align: "start",
      width: ""
    },
    {
      text: "提出人数",
      value: "submitters",
      align: "start",
      width: ""
    },
    {
      text: "",
      value: "actions",
      align: "center",
      width: "75px",
      sortable: false
    }
  ];

  public async mounted() {
    this.collection = "office-aggregate-visitrecord-number";
    this.documentId = String(this.loginUser.id);
    const fbDoc = await this.documentGet();

    //親画面で保存した検索条件
    const parentFbDoc = await this.getParentSearchCon();

    //デフォルトの検索条件
    this.start = this.lastymonth();
    this.end = this.todaymonth();

    if (parentFbDoc !== undefined && typeof parentFbDoc !== "boolean") {
      const searchCondition = parentFbDoc;
      this.start = searchCondition?.start;
      this.end = searchCondition?.end;
    }
    if (fbDoc !== undefined && typeof fbDoc !== "boolean") {
      const searchCondition = fbDoc.searchCondition as SearchCondition;
      this.officeIds = searchCondition?.office_ids;
    }

    this.disposeChart();
    // 初期メッセージ
    this.chart = this.xyChartMessage();
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.XYChart) {
    // 拡大スクロール
    const scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;

    //看護記録書枚数ラベル
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "看護記録書Ⅱ枚数";
    let max = 0;
    this.tableDatas.forEach((item: TableData) => {
      if (item.submissions > max) {
        max = item.submissions;
      }
    });
    if (max < 10) {
      valueAxis.min = 0;
      valueAxis.max = 10;
    }

    // Create axes
    //日付ラベル
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.minGridDistance = 20;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.dateFormatter.language = new am4core.Language();
    dateAxis.dateFormatter.language.locale = am4lang_ja_JP;
    dateAxis.language.locale["_date_day"] = "MMM";
    dateAxis.language.locale["_date_year"] = "yyyy年";
    dateAxis.baseInterval = {
      timeUnit: "month",
      count: 1
    };

    //グラフ
    for (const chartData of this.chartDatas) {
      const series = chart.series.push(new am4charts.LineSeries());
      this.series(series, chartData, valueAxis);
    }

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color("#000");
    chart.cursor.lineX.fillOpacity = 0.1;

    return chart;
  }

  //グラフ
  private series(
    series: am4charts.LineSeries,
    chartData: ChartData,
    valueAxis: am4charts.ValueAxis<am4charts.AxisRenderer>
  ) {
    series.data = chartData.submissions;
    series.dataFields.valueY = "submission";
    series.dataFields.dateX = "date";
    series.yAxis = valueAxis;
    series.name = chartData.office;
    series.strokeWidth = 2;
    series.propertyFields.strokeDasharray = "dash_length";
    series.tooltipText = "{valueY} 枚";
    series.showOnInit = true;

    const bullet = series.bullets.push(new am4charts.Bullet());
    const rectangle = bullet.createChild(am4core.Rectangle);
    bullet.horizontalCenter = "middle";
    bullet.verticalCenter = "middle";
    bullet.width = 7;
    bullet.height = 7;
    rectangle.width = 7;
    rectangle.height = 7;

    const state = bullet.states.create("hover");
    state.properties.scale = 1.2;
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  // Excelダウンロード
  private downloadExcel() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/officeAggregate/officeinvoice/downloadexcel",
      {
        table_datas: this.tableDatas,
        start: this.start,
        end: this.end
      },
      res => {
        const fileName = "officeInvoice.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  // 詳細ボタン
  private clickDetail(item: TableData) {
    const url = "/office_aggregate/officeinvoice/detail";
    this.$router.push(
      url +
        `?id=${item.office_id}` +
        `&office=${item.office}` +
        `&yearmonth=${item.date}`
    );
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    //todo エラーの場合、resolveされない。。
    const searchCondition = {
      office_ids: this.officeIds,
      start: this.start,
      end: this.end
    };
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/officeinvoice/search",
        searchCondition,
        res => {
          this.chartDatas = res.data.chart_datas;
          for (const chartData of this.chartDatas) {
            chartData.submissions.reverse();
          }
          this.tableDatas = res.data.table_datas;
          this.documentSave({
            searchCondition: searchCondition
          });
          resolve(0);
        }
      );
    });
  }
}
