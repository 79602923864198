
































import { Component, Mixins } from "vue-property-decorator";
import { COLLECTION_BBS_SETTING } from "@/const/envFireStore";
import { BbsSetting } from "@/components/bbs/types";
import UtilMixin from "@/mixins/utilMixin";
import FirestoreMixin from "@/mixins/firestoreMixin";
import StateButton from "@/components/common_ibow/StateButton.vue";
import NoticeArea from "@/components/bbs/NoticeArea.vue";
import TopicArea from "@/components/bbs/TopicArea.vue";

@Component({
  components: {
    StateButton,
    NoticeArea,
    TopicArea
  }
})
export default class extends Mixins(UtilMixin, FirestoreMixin) {
  /** お知らせを別タブで開くかどうか */
  private isOpenNewTab = false;

  created() {
    this.collection = COLLECTION_BBS_SETTING;
    this.documentId = String(this.loginUser.id);
    this.fetchSetting();
  }

  /** お知らせ取得設定を更新する */
  private async fetchSetting() {
    const bbsSetting = await this.documentGet();
    if (bbsSetting) {
      this.isOpenNewTab = (bbsSetting as BbsSetting).opens_new_tab;
    }
  }

  /** お知らせ取得設定を変更する */
  private toggleOpensNewTab() {
    this.isOpenNewTab = !this.isOpenNewTab;
    const setting: BbsSetting = { opens_new_tab: this.isOpenNewTab };
    this.documentSave(setting);
  }
}
