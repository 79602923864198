


























































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { BaseRecord } from "@/views/master/excel/types";
import {
  BatchStaffLinkCheckResponse,
  RowItem
} from "@/views/master/batch_staff_link/types";

@Component({})
export default class BatchStaffLinkCheckResult extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** テーブルデータ */
  @Prop() checkResponse!: BatchStaffLinkCheckResponse;

  /** 更新なしデータを非表示にするかどうか */
  private showOnlyUpdatedData = false;

  /** 連携できるかどうか */
  private get CanLink(): boolean {
    // エラーが1つもなく、連携先を更新する職員が1人は存在
    return (
      this.checkResponse.error_messages.length == 0 &&
      this.checkResponse.has_updated_data
    );
  }

  /** 表示データ */
  private get ResponseData(): RowItem[] {
    // チェックが入っていれば、更新・エラー行のみを表示
    return this.showOnlyUpdatedData
      ? this.checkResponse.data.filter(
          (rowItem: RowItem) => rowItem.record_state > 0
        )
      : this.checkResponse.data;
  }

  /** 各行の色定義 */
  private rowBackground(item: BaseRecord) {
    let color = "";

    switch (item.record_state) {
      case 2:
        // エラーあり
        color = "red lighten-4";
        break;
      case 1:
        // 連携先更新あり
        color = "yellow lighten-4";
        break;
      case 0:
      default:
        // 連携先更新なし、該当なし
        color = "";
        break;
    }
    return color;
  }

  /** 各項目の色定義 */
  private tableDataState(state: number, rowState: number) {
    let color = "";
    switch (state) {
      case 1:
        // エラー
        color = "err";
        break;
      case 0:
      default:
        // 通常
        color = "default";
        break;
    }

    // 連携先更新ありのエラー行場合、セルのカラーはエラー項目のみ有効
    switch (rowState) {
      case 2:
      case 1:
        // 連携先更新あり、エラー
        if (state != 1) {
          color = "default";
        }
        break;
      default:
        break;
    }

    return color;
  }

  /** 連携ボタンクリック時 */
  @Emit("save")
  private save() {
    return;
  }

  /** リセットボタンクリック時 */
  @Emit("reset")
  private reset() {
    return;
  }
}
