















































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import ItemTable from "#/components/ItemTable.vue";
import NumberInput from "#/components/NumberInput.vue";
import ZeroDispNumberInput from "#/components/ZeroDispNumberInput.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import { VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { OwnExpense, DefaultOwnExpense } from "#/model/patient";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import OwnExpenseMixin from "@/mixins/ownExpenseMixin";

@Component({
  components: {
    ItemTable,
    NumberInput,
    PatientHeader,
    ZeroDispNumberInput
  }
})
export default class PatientSelfburdenEdit extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  EditMixin,
  PatientMixin,
  OwnExpenseMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  /** 自費ID */
  private selfburdenId = 0;

  /** 利用者ID */
  private patientId = 0;

  /** 編集/コピー */
  private mode = "";

  /** 自費 */
  private own_expense: OwnExpense = DefaultOwnExpense();

  /** 単価と数量の初期値0を空欄として表示するかフラグ */
  private get IsNeedDispEmptyFirst() {
    return this.own_expense.id == 0 && !this.mode;
  }

  /** 自費アイテムマスタの絞り込み（カテゴリ選択時それに付随するアイテム表示）*/
  private get RefinementOwnExpenseItems() {
    return this.refinementOwnExpenseItems(this.own_expense.category_name);
  }

  /** 課税率のルール */
  private get RuleTaxRate() {
    return this.ruleTaxRate(this.own_expense);
  }

  /** データ変更監視 */
  @Watch("own_expense", { deep: true })
  private watchOwnExpense() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  created() {
    this.selfburdenId = Number(this.$route.params.selfburdenid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchSelfburden();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/selfburden`,
        exact: true
      },
      {
        text: "自費を作成する",
        disabled: true,
        to: ""
      }
    ]);
  }

  /** キャンセル */
  private cancel() {
    this.$router.go(-1);
  }

  /** 登録 */
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    if (this.mode) {
      this.own_expense.id = 0;
    }
    this.own_expense.patient_id = this.patientId;
    this.calculation(this.own_expense);
    this.postJsonCheck(
      window.base_url + "/api/patient/selfburden/save",
      {
        own_expense: this.own_expense
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  /** 更新 */
  private update() {
    this.own_expense.id = this.selfburdenId;
    this.save();
  }

  /** 削除 */
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.own_expense.id = this.selfburdenId;
      this.own_expense.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/selfburden/delete",
        {
          own_expense: this.own_expense
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  /** 自費取得 */
  fetchSelfburden(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/selfburden/get",
      {
        patient_id: this.patientId,
        selfburden_id: this.selfburdenId
      },
      res => {
        this.ownExpenseCategorys = res.data.own_expense_categorys;
        this.ownExpenseItems = res.data.own_expense_items;
        //自費情報
        if (this.selfburdenId != 0) {
          this.own_expense = res.data.own_expense;
        }

        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  /** カテゴリ名・アイテム名変更時 */
  private changeItem() {
    if (!this.isLoaded) {
      return;
    }
    const item = this.selectedItem(
      this.own_expense.category_name,
      this.own_expense.item_name
    );
    if (!item) {
      return;
    }
    // 項目セット
    this.setSelectedOwnExpense(this.own_expense, item);
  }
}
