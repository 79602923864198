




















































































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import { ShareFireInfo } from "@/components/sharefile/type";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";
import { Choice } from "@/types";
import { FONT_SIZE } from "@/types";

@Component
export default class FileListTable extends Mixins(ShareFileMixin) {
  @Prop() shareFiles!: ShareFireInfo[]; // 共有ファイル一覧
  @Prop() staffs!: Choice[]; // 職員一覧
  @Prop() fileEditFlag!: boolean; // ファイル編集フラグ
  @Prop({ default: 1 }) pageNo!: number; //ページ番号
  @Prop({ default: 25 }) frontOnlyPageCount!: number; //フロントだけで制御する場合の1ページあたりの表示件数（これを有効にした場合、フロントのみのページングになる）

  @Emit("update:checked")
  private checked(): number[] {
    return this.selected.map(select => select.id);
  }

  @Emit("update:pageNo")
  private updatePageNo(newValue: number) {
    this.changePage(newValue);
    return newValue;
  }

  @Emit()
  private changePage(page: number) {
    return page;
  }

  private get PageNo() {
    return this.pageNo;
  }

  private set PageNo(newValue: number) {
    this.updatePageNo(newValue);
  }

  // テーブルチェックデータ
  private selected: ShareFireInfo[] = [];

  // テーブルヘッダー
  private get Headers() {
    let updateDateWidth = "112px";
    switch (window.font_size) {
      case FONT_SIZE.DEFAULT:
        updateDateWidth = "112px";
        break;
      case FONT_SIZE.SIZE_L:
        updateDateWidth = "124px";
        break;
      case FONT_SIZE.SIZE_LL:
        updateDateWidth = "140px";
        break;
    }

    return [
      {
        text: "",
        value: "preview",
        sortable: false,
        align: "start",
        width: "64px",
        class: "px-0",
        cellClass: "px-0"
      },
      {
        text: "ファイル名",
        value: "file_name",
        align: "start",
        width: "220px",
        class: "pl-0",
        cellClass: "pl-0 word-break-all"
      },
      {
        text: "タグ",
        value: "hash_tags",
        align: "start",
        width: "200px",
        class: "pl-0",
        cellClass: "pl-0"
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        align: "start",
        width: "84px",
        class: "px-0",
        cellClass: "px-0"
      },
      {
        text: "更新日時",
        value: "updated_at",
        align: "start",
        width: updateDateWidth
      },
      {
        text: "備考",
        value: "remarks",
        align: "start",
        width: "138px"
      },
      {
        text: "形式",
        value: "icon",
        sortable: false,
        align: "center",
        width: "72px"
      },
      {
        text: "サイズ",
        value: "size",
        align: "start",
        width: "128px"
      },
      {
        text: "更新者",
        value: "editor_staff_id",
        align: "start",
        width: "180px"
      },
      {
        text: "事業所",
        value: "office_id",
        align: "start"
      }
    ];
  }
}
