














































import { Component, Prop, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { BulletinBoardAdmin } from "#/model/bulletinboard";
import UtilMixin from "@/mixins/utilMixin";
import LoadingArea from "@/components/home/loadingArea.vue";
// import { BOARD_DIV, BOARD_DIV_COLOR } from "@/const/bulletinBoard";

@Component({
  components: {
    LoadingArea
  }
})
export default class BulletinBoardBulletinBoard extends Mixins(UtilMixin) {
  /** 管理者お知らせ */
  @Prop() private readonly infoItems!: BulletinBoardAdmin[];

  /** 管理者お知らせ件数 */
  @Prop() private readonly infoCount!: number;

  /** ローディング中かどうか */
  @Prop() private readonly loading!: boolean;

  // private BOARD_DIV = BOARD_DIV;
  // private BOARD_DIV_COLOR = BOARD_DIV_COLOR;

  /** 管理者お知らせヘッダー */
  private readonly infoHeaders: DataTableHeader[] = [
    // {
    //   text: "",
    //   value: "time_stamp"
    // },
    {
      text: "",
      value: "start_date",
      width: "120px",
      align: "start"
    },
    // {
    //   text: "",
    //   value: "board_div"
    // },
    {
      text: "",
      value: "title",
      align: "start"
    }
  ];

  /** 最新3件を表示 */
  private get LimitInfoItems() {
    return this.infoItems.slice(0, 3);
  }

  /** 管理者お知らせをクリックした時、詳細画面に遷移する */
  private clickRow(item: BulletinBoardAdmin) {
    this.$router.push({ name: "BbsNotice", params: { bbsid: item.id + "" } });
  }
}
