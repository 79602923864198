// import { Choice } from "@/types";
export interface IndicateInfo {
  id: number;
  indicate_period: string;
}
// firestore
export interface CalendarDisplay {
  selectLayers: number[];
}
export const DefaultCalendarDisplay = (): CalendarDisplay => ({
  selectLayers: []
});

/** カレンダー状態 */
export const CalendarType = {
  Month: "month", // 月表示
  Week: "week", // 週表示
  Day: "day" // 表示
} as const;
export type CalendarType = typeof CalendarType[keyof typeof CalendarType];

/** 検索条件 */
export interface FetchParameter {
  patient_id: number;
  start_date: string;
  end_date: string;
  calendar_div: number;
  is_reciept: boolean;
}

/** 指示書情報 */
export interface IndicateInfoTexts {
  text: string;
  color: string;
}
