import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:公費種類情報 */
export const headerPublicMoney: StatisticHeader = {
  id: STATISTIC_COL_ID.PUBLIC_MONEY,
  text: "公費種類情報",
  is_checked: false,
  value: [
    {
      text: "種類（公費）",
      select_name: "種類",
      value: `${STATISTIC_COL_ID.PUBLIC_MONEY}.name`,
      sortable: true,
      align: "start",
      width: "168px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（公費）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.PUBLIC_MONEY}.start_date`,
      sortable: true,
      align: "start",
      width: "150px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（公費）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.PUBLIC_MONEY}.end_date`,
      sortable: true,
      align: "start",
      width: "150px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "負担者番号（公費）",
      select_name: "負担者番号",
      value: `${STATISTIC_COL_ID.PUBLIC_MONEY}.insurer_no`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "受給者番号（公費）",
      select_name: "受給者番号",
      value: `${STATISTIC_COL_ID.PUBLIC_MONEY}.insured_no`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      //100 - 給付割合
      text: "負担率（公費）",
      select_name: "負担率",
      value: `${STATISTIC_COL_ID.PUBLIC_MONEY}.str_burden_rate`,
      sortable: true,
      align: "start",
      width: "150px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.NUMBER
    },
    {
      text: "自己負担上限額（公費）",
      select_name: "自己負担上限額",
      value: `${STATISTIC_COL_ID.PUBLIC_MONEY}.self_bear_limit_amount`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.NUMBER
    }
  ]
};
