
































































import { Component, Mixins, Emit, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";

@Component
export default class PasswordRemind extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("form")
  private readonly form!: { validate: () => boolean };

  private type = 1; //1:iBowID 2:メールアドレス 3:電話番号
  private ibowid = "";
  private mail = "";
  private phone = "";
  private typeList = [
    {
      text: "iBowID",
      value: 1
    },
    {
      text: "メールアドレス",
      value: 2
    },
    {
      text: "電話番号",
      value: 3
    }
  ];

  private clickPost() {
    if (!this.form.validate()) {
      return;
    }
    if (this.type == 1) {
      this.mail = "";
      this.phone = "";
    }
    if (this.type == 2) {
      this.ibowid = "";
      this.phone = "";
    }
    if (this.type == 3) {
      this.ibowid = "";
      this.mail = "";
    }
    this.postJsonCheck(
      window.auth_backend_url + "/password-reset/remind",
      {
        ibowid: this.ibowid,
        mail: this.mail,
        phone: this.phone
      },
      res => {
        this.$router.push({ query: { check_token: res.data.token } });
        this.checkSecurity();
      }
    );
  }

  @Emit()
  private checkSecurity() {
    return;
  }

  @Emit()
  private cancel() {
    return;
  }
}
