export const name = "masmen";

export const SET_MASMEN = "SET_MASMEN";

export interface MasmenState {
  menus: MasterMenu[]; //職員基本情報
  is_agent: number; //レセプト代行フラグ
  is_nursing_category: number; //観察項目カテゴリ
  error: boolean; //エラー情報
}

export interface SetMasmen {
  menus: MasterMenu[];
  is_agent: number;
  is_nursing_category: number;
}

export interface MasterSubMenu {
  title: string; // タイトル
  link: string; // 遷移先route名
  path: string; // 遷移先path
  children?: string[];
}

export interface MasterMenu {
  id: number; // メニューID(タブの出し分けに必要)
  title: string; // タイトル
  hint: string; // 説明
  icon: string; // アイコン
  link: string; // 遷移先route名
  lists: MasterSubMenu[]; // マスタメニューアイコンリスト
}

export interface BreadItem {
  text: string;
  disabled: boolean;
  to: string;
  exact?: boolean;
}
