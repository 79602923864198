

















































































































































































































































































































































































































































import { Mixins, Component, Ref, Watch, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import { DefaultRemand } from "#/model/report";
import Adl from "@/components/patient/vnrecord/Adl.vue";
import FileUpload from "#/components/FileUpload.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import { Choice, VForm } from "@/types";
import { Link } from "#/types";
import {
  AdlMasterItem,
  Vnrecord,
  Person,
  FileByType,
  VnrecordDefaultValues,
  PersonDefaultValues,
  FileType,
  SameBuilding
} from "@/components/patient/vnrecord/types";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import RemandExpand from "@/components/patient/common/remand/RemandExpand.vue";
import UtilMixin from "@/mixins/utilMixin";
import FamilyRelationShipSelect from "@/components/common_ibow/FamilyRelationShipSelect.vue";
import PatientRelationInsSelect from "@/components/patient/common_ibow/PatientRelationInsSelect.vue";
import PatientDoctorSelect from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import EditMixin from "@/mixins/editMixin";
import DateInput from "#/components/DateInput.vue";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import PatientMixin from "@/mixins/patientMixin";

@Component({
  components: {
    Adl,
    FileUpload,
    PatientHeader,
    PatientSaveArea,
    RemandExpand,
    FamilyRelationShipSelect,
    PatientRelationInsSelect,
    PatientDoctorSelect,
    DateInput,
    PatientExpantionAreas
  }
})
export default class PatientVnrecord extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  private patientId = 0;
  private vnrecordId = 0;
  private vnrecord: Vnrecord = VnrecordDefaultValues();
  private emergencyContactItems: Choice[] = []; // 緊急時の連絡方法items
  private requiredTimeItems: Choice[] = []; // 訪問看護ステーションからの所要時間items
  private dwEnvItems: Choice[] = []; // 住居環境items
  private sameBuildingItemsMaster: SameBuilding[] = []; // 施設名バックアップ
  private contactItems: Choice[] = []; // 連絡先items
  private liveKindKeyPersonItems: Choice[] = []; // キーパーソン用同居/別居items
  private liveKindItems: Choice[] = []; // 同居/別居items
  private occupationItems: Choice[] = []; // 職業items
  private familyRelationshipItems: Choice[] = []; // 続柄items
  private adlItems: AdlMasterItem[] = []; // 看護記録書ⅠADL項目items
  private medicalItems: Choice[] = []; // 医療機関items
  private allRelationItems: Choice[] = []; // 関連機関items
  private cityRelationItems: Choice[] = []; // 関連機関（自治体）items
  private schoolRelationItems: Choice[] = []; // 関連機関（学校等）items
  private a4quarterFiles: FileByType[] = []; // A4 4分の1サイズのファイル
  private a4Files: FileByType[] = []; // A4サイズのファイル
  private isSchoolSubmitEnable = false;
  private show_support_center = true // 地域包括支援センターの表示フラグ（居宅介護支援事業所の選択肢）
  // ファイルアップロードAPIパス
  private uploadApiUrl = window.base_url + "/api/upload";

  /** アップロード可能ファイル */
  private acceptFilePaths = [".jpg", ".jpeg", ".png"];

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  /** ADL>心身の状態プレビューの文字数制約 */
  private previewLimitPerLine = 250;

  @Prop() readonly pheader!: PatientHeader;

  //フォーム
  @Ref("patient-header") private readonly patientHeader!: PatientExpantionAreas;

  //patientHeaderに属さないフォームのバリデーション
  @Ref("footer-form") private readonly footerForm!: VForm;

  private get Links() {
    const links: Link[] = [];
    if (this.vnrecord.remand.id && this.vnrecord.remand.editor_staff_id) {
      links.push({
        text: "差戻し情報",
        key: "remand",
        add_info: this.vnrecord.remand.comment
      });
    }
    links.push(
      {
        text: "既往歴・病状・病識等の記載",
        key: "medical_history"
      },
      {
        text: "お住まいの状況に関する項目",
        key: "housing_status"
      },
      {
        text: "家族構成",
        key: "family_info"
      },
      {
        text: "依頼目的",
        key: "purpose",
        attention: "この項目はサービス内容確認書に反映されます"
      },
      {
        text: "ADL/日常生活自立度",
        key: "adl"
      },
      {
        text: "関連機関（医療、法人、行政、歯科医療機関、居宅介護支援事業所）",
        key: "relation",
        mini_text: "関連機関"
      },
      {
        text: "写真（A4 4分の1サイズ）",
        key: "photo_a4quarter",
        mini_text: "写真(A4 1/4枚)"
      },
      {
        text: "写真（A4 1枚サイズ）",
        key: "photo_a4",
        mini_text: "写真(A4 1枚)"
      }
    );
    return links;
  }

  // ファイルパス作成
  private get imagePath(): string {
    // [co_code]/[kst_no]/vnrecord/[利用者ID]/[看護記録ⅠID]/[ファイル名(uuid)]
    const kstNo = this.getKstnoByOfficeId(this.PatientOfficeId);
    return `${this.siteName}/${kstNo}/vnrecord/${this.patientId}/${this.vnrecordId}/`;
  }

  private get SameBuildingMaster(): Choice[] {
    let items: Choice[] = [];
    for (const obj of this.sameBuildingItemsMaster) {
      if (obj.building_kind == this.vnrecord.dwenv) {
        items = obj.building_names;
      }
    }
    return items;
  }

  private get StorageKey(): string {
    return `vnrecord-${this.loginUser.id}-${this.patientId}-${this.vnrecordId}`;
  }

  /** 編集時に保存する */
  @Watch("vnrecord", { deep: true }) private watchData() {
    this.saveLocalStorage({
      vnrecord: this.vnrecord,
      a4Files: this.a4Files,
      a4quarterFiles: this.a4quarterFiles,
      displayPreview: this.displayPreview
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  /** ファイル */
  @Watch("a4quarterFiles", { deep: true }) private watchA4quarterFiles() {
    this.watchData();
  }

  /** ファイル */
  @Watch("a4Files", { deep: true }) private watchA4Files() {
    this.watchData();
  }

  created(): void {
    this.patientId = Number(this.$route.params.id);
    this.vnrecordId = Number(this.$route.params.vnrecord_id);
    this.fetchVnrecord();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/vnrecord`
      },
      {
        text: "看護記録Ⅰ",
        disabled: true,
        to: ""
      }
    ]);
  }

  private fetchVnrecord(): void {
    // 看護記録書Ⅰ取得
    this.postJsonCheck(
      window.base_url + "/api/patient/vnrecord/detail/get",
      { vnrecord_id: this.vnrecordId, patient_id: this.patientId },
      res => {
        this.vnrecord = res.data.vnrecord;
        this.emergencyContactItems = res.data.emergency_contacts;
        this.requiredTimeItems = res.data.required_times;
        this.dwEnvItems = res.data.dw_envs;
        this.sameBuildingItemsMaster = res.data.same_buildings;
        this.contactItems = res.data.contacts;
        this.liveKindKeyPersonItems = res.data.key_person_live_kinds;
        this.liveKindItems = res.data.live_kinds;
        this.occupationItems = res.data.occupations;
        this.familyRelationshipItems = res.data.family_relationships;
        this.adlItems = res.data.vnrecord_adl_items;
        this.medicalItems = res.data.medicals;
        this.allRelationItems = res.data.relations;
        this.cityRelationItems = res.data.city_relations;
        this.schoolRelationItems = res.data.school_relations;
        this.isSchoolSubmitEnable = res.data.is_school_submit_enable;
        this.show_support_center = res.data.show_support_center
        if (this.vnrecord.files) {
          this.a4quarterFiles = this.vnrecord.files.filter(
            (file: FileByType) => file.file_type == FileType.A4QUARTER_SIZE
          );
          this.a4Files = this.vnrecord.files.filter(
            (file: FileByType) => file.file_type == FileType.A4_SIZE
          );
        }
        if (this.vnrecordId === 0) {
          this.vnrecord.remand = DefaultRemand();
        }
        this.execAfterFetch(this.StorageKey, data => {
          this.vnrecord = data.vnrecord;
          this.a4Files = data.a4Files;
          this.a4quarterFiles = data.a4quarterFiles;
          this.displayPreview = data.displayPreview;
        });
      }
    );
  }

  private addPerson(): void {
    const num = this.vnrecord.persons.length + 1;
    this.vnrecord.persons.push(PersonDefaultValues(this.vnrecordId, num));
  }

  private async removePerson(item: Person): Promise<void> {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }
    this.vnrecord.persons = this.vnrecord.persons.filter(
      (person: Person) => person != item
    );
    // 間の家族を削除された時のために並び順の降り直しをする
    this.vnrecord.persons.forEach((person, index) => {
      person.sort_no = index + 1;
    });
  }

  private changeDwEnv(): void {
    this.$nextTick(() => {
      this.vnrecord.same_building_id = 0;
    });
  }

  private saveVnrecord(isMove: boolean): void {
    this.setIsSave(true);
    this.validateAfterExec(() => {
      this.saveAction(1, "/api/patient/vnrecord/detail/save", false, isMove);
    });
  }

  private deleteVnrecord(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/vnrecord/detail/delete",
      { vnrecord: this.vnrecord },
      () => {
        this.setNoEditMode();
        this.$router.push({
          name: "PatientVnrecordList",
          params: { id: String(this.patientId) }
        });
      }
    );
  }

  /** ADL/日常生活自立度において、「不問」がチェックされていれば、選択肢を「未選択」にする */
  private resetAdlIfCheck(): void {
    this.vnrecord.adls.forEach(adl => {
      if (adl.check) {
        // 「未選択」の選択肢valueが0であること前提
        adl.support = 0;
        adl.assistance = 0;
      }
    });
  }

  private async submitVnrecord(isPreview = false): Promise<void> {
    this.validateAfterExec(() => {
      this.saveAction(2, "/api/patient/vnrecord/detail/submission", isPreview);
    });
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ): void {
    this.is_finish = true;
    this.vnrecord.patient_id = this.patientId;
    this.vnrecord.status = status;
    this.vnrecord.files = this.a4quarterFiles.concat(this.a4Files);
    // 「不問」がチェックされていれば、選択肢を「未選択」にする
    this.resetAdlIfCheck();
    this.vnrecord.mind_and_body_state = this.vnrecord.mind_and_body_state.slice(
      0,
      this.previewLimitPerLine
    );
    this.makePdf(
      window.base_url + url,
      { vnrecord: this.vnrecord, is_preview: isPreview },
      res => {
        this.setNoEditMode();
        if (isMove) {
          this.$router.push({
            name: "PatientVnrecordList",
            params: { id: String(this.patientId) }
          });
        } else {
          this.vnrecordId = res.data.id;
          this.vnrecord.id = res.data.id;
          this.vnrecord.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { vnrecord_id: String(this.vnrecordId) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  private previewVnrecord(): void {
    this.setIsSave(true);
    this.validateAfterExec(() => {
      this.vnrecord.patient_id = this.patientId;
      this.makePdf(window.base_url + "/api/patient/vnrecord/detail/preview", {
        vnrecord: this.vnrecord
      });
    });
  }

  private async warningProvSubmitPlaceFlg2(): Promise<void> {
    // 訪問看護情報提供療養費（医療）（Ⅱ）のワーニング表示
    if (this.vnrecord.is_school_submit == 0) {
      return;
    }
    if (this.isSchoolSubmitEnable == true) {
      return;
    }
    if (
      !(await this.$openConfirm(
        "利用者が１８歳未満(※)または指示書の（別表８・別表７・超重症児・準超重症児）いずれかに登録が必要です。(※2022年3月以前は１５歳未満)"
      ))
    ) {
      this.$nextTick(() => {
        this.vnrecord.is_school_submit = 0;
      });
      return;
    }
  }

  // バリデーション
  private validateAfterExec(exec: Function) {
    // ADL>心身の状態プレビューはチェック対象外のため、一時的に文字数制限を解放
    this.previewLimitPerLine = 1000000000;
    this.$nextTick(async () => {
      const ok = this.patientHeader.validate();
      this.previewLimitPerLine = 250;
      if (!ok) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      exec();
    });
  }
}
