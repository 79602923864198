









































import { Component, Prop, Vue } from "vue-property-decorator";
import { MedicalInstitution } from "#/model/uniquemaster";
interface MedicalInstitutionItem extends MedicalInstitution {
  office_names: string;
}
@Component
export default class MedicalList extends Vue {
  private itemPerPage = 25;

  private pageNo = 1;

  private headers = [
    {
      text: "医療機関",
      align: "start",
      value: "name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    { text: "", value: "actions", sortable: false, align: "end" }
  ];

  @Prop({ default: "" })
  search!: string;

  @Prop({
    type: Array,
    default: () => {
      return [];
    }
  })
  medicalList!: MedicalInstitution[];

  private clickEdit(med: MedicalInstitution) {
    this.$router.push({
      name: "MedicalEdit",
      params: {
        id: String(med.id)
      }
    });
  }

  //絞り込み
  private filterItems(
    value: unknown,
    search: string,
    item: MedicalInstitutionItem
  ) {
    if (search == null) {
      return false;
    }

    if (item == null) {
      return false;
    }
    if (
      item.name.match(search) ||
      item.furigana.match(search) ||
      item.office_names.match(search)
    ) {
      return true;
    }

    return false;
  }

  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }
}
