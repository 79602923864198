










































































































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import FamilyRelationShipSelect from "@/components/common_ibow/FamilyRelationShipSelect.vue";
import PatientRelationInsSelect from "@/components/patient/common_ibow/PatientRelationInsSelect.vue";
import PatientDoctorSelect from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import { Patient } from "#/model/patient";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { PRIREQUESTER_TYPE } from "./const";

@Component({
  components: {
    PatientRelationInsSelect,
    PatientDoctorSelect,
    FamilyRelationShipSelect
  }
})

/** 利用者基礎情報 受付情報フォーム */
export default class FormAcceptInfo extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 利用者情報(編集対象) */
  @Prop() patient!: Patient;

  /** 読み取り専用フラグ(履歴編集画面用) */
  @Prop({ default: false }) isReadOnly!: boolean;

  /** プレフィックスID(入力項目にIDを振る対応) */
  @Prop({ default: "patient-info" }) private prefixId!: string;

  /* 定数 */

  /** 依頼元種類 */
  private PRIREQUESTER_TYPE = PRIREQUESTER_TYPE;

  /* Emit */

  /** 関連機関変更時 */
  @Emit("change:relationInstitution")
  private changeRelation() {
    return;
  }

  /** 医療機関変更時 */
  @Emit("change:medicalInstitution")
  private changeMedical() {
    return;
  }
}
