








































import { Component, Vue, Prop, Emit, Ref } from "vue-property-decorator";
import {
  NursingSecondaryContent,
  DefaultNursingSecondaryContent
} from "@/views/master/nursing_content/types";
import { VForm } from "@/types";

@Component
export default class EditNursingSecondaryContentDialog extends Vue {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    default: () => {
      return {
        id: 0,
        care_large_content_id: 0,
        content_name: ""
      };
    }
  })
  content!: NursingSecondaryContent;

  @Prop({ default: () => [] }) primaryContents!: NursingSecondaryContent[];

  private cloneContent: NursingSecondaryContent = DefaultNursingSecondaryContent();

  private get IsDelete() {
    return this.cloneContent.id !== 0;
  }

  created() {
    this.cloneContent = { ...this.content };
  }

  //保存
  private didClickSaveButton(): void {
    if (this.form.validate()) {
      this.$emit("saveSecondaryContent", this.cloneContent);
    }
  }

  //キャンセル
  private didClickCancelButton() {
    this.closeDialog();
  }

  //削除
  private async didClickRemoveButton() {
    if (
      !(await this.$openConfirm(
        "既に帳票等で使用している場合、削除を行うと元に戻せなくなります。それでも削除しますか？"
      ))
    ) {
      return;
    }
    this.$emit("deleteSecondaryContent", this.cloneContent);
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
