























import { Mixins, Component, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import ProgressItems from "@/components/patient/progress/ProgressItems.vue";

@Component({
  components: {
    ProgressItems
  }
})
export default class PatientProgress extends Mixins(
  UtilMixin,
  AxiosMixin,
  PatientMixin
) {
  @Ref("progress") private readonly progress!: ProgressItems;
  private patientId = 0;

  public created(): void {
    this.patientId = Number(this.$route.params.id);
  }

  public mounted(): void {
    this.setDefaultHeader();
  }

  // イベント閉じる
  private clickClose() {
    window.close();
  }

  // 印刷するボタンクリック
  private print() {
    const name = "PrintProgress";
    const type = this.progress.ChartType;
    const date = this.progress.targetDate;
    const patientName = this.patientInfo.name;
    const printRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.patientId) },
      query: {
        type: String(type),
        date: String(date),
        name: patientName,
        office: this.patientInfo.office_name
      }
    });
    window.open(printRoute.href, "_blank");
  }
}
