import { GetterTree } from "vuex";
import { MasterState } from "./types";
import { RootState } from "@/store/types";

export const getters: GetterTree<MasterState, RootState> = {
  contactTypes: (state: MasterState) => {
    if (state && state.master) {
      return state.master.contact_types;
    }
  }
};

export default getters;
