












































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Choice } from "@/types";

@Component
export default class AppPagination extends Vue {
  @Prop({ default: "" }) label!: string; //検索エリアのラベル

  @Prop({ default: 1 }) pageNo!: number; //ページ番号
  @Prop({ default: 1 }) pageCount!: number; //ページ数

  /** フロントのみのページングの場合は下記必須 */
  @Prop() items!: []; // データ一覧
  @Prop({ default: -1 }) frontOnlyPageCount!: number; //フロントだけで制御する場合の1ページあたりの表示件数（これを有効にした場合、フロントのみのページングになる）

  @Emit("update:pageNo")
  private updatePageNo(newValue: number) {
    this.changePage(newValue);
    return newValue;
  }

  @Emit()
  private changePage(page: number) {
    return page;
  }

  private get PageNo() {
    return this.pageNo;
  }

  private set PageNo(newValue: number) {
    this.updatePageNo(newValue);
  }

  private get PageCount() {
    if (this.frontOnlyPageCount !== -1 && this.items != undefined) {
      return Math.ceil(this.items.length / this.frontOnlyPageCount);
    }
    return this.pageCount;
  }

  private get selectItems(): Choice[] {
    const choices: Choice[] = [];
    for (let i = 0; i < this.PageCount; i++) {
      const text = String(i + 1) + "/" + String(this.PageCount);
      const choice: Choice = {
        text: text,
        value: i + 1
      };
      choices.push(choice);
    }
    return choices;
  }

  private clickLeft() {
    if (this.PageNo != 1) {
      this.PageNo--;
    }
  }

  private clickRight() {
    if (this.PageNo != this.PageCount) {
      this.PageNo++;
    }
  }
}
