
































import CareplanList from "@/components/careplan/CareplanList.vue";
import CareplanInOut from "@/components/careplan/inout/CareplanInOut.vue";
import CareplanHistory from "@/components/careplan/CareplanHistory.vue";
import { Component, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { CareplanOffice } from "@/components/careplan/careplan";
import { Office } from "#/model/agreement/office";
import { STAFF_AUTH_COMMON } from "#/const/officestaff";

@Component({
  components: {
    CareplanList,
    CareplanInOut,
    CareplanHistory
  }
})
export default class Careplan extends Mixins(UtilMixin, AxiosMixin) {
  private currentTab = 0;
  private isLoading = false;
  private offices: CareplanOffice[] = [];

  private get tabs() {
    const items = [
      {
        value: 0,
        text: "取込済みケアプラン一覧"
      }
    ];

    // 取込/出力、履歴は一般職員以外利用可能
    if (this.loginUserAuthId !== STAFF_AUTH_COMMON) {
      items.push(
        {
          value: 1,
          text: "取込/出力"
        },
        {
          value: 2,
          text: "取込ファイルの履歴"
        }
      );
    }

    return items;
  }

  private created() {
    this.isLoading = true;
    this.postJsonCheck(
      window.base_url + "/api/master/station/get",
      {},
      res => {
        this.offices = res.data.offices
          .filter((o: Office) => {
            return o.office_info.is_careplan_used;
          })
          .map((o: Office) => {
            return {
              id: o.id,
              name: o.office_name,
              code: o.office_info.office_code,
              isSatelite: o.office_div === 2
            } as CareplanOffice;
          });
        this.isLoading = false;

        // ケアプランが有効で、自身が参照できる事業所がない場合、Homeに遷移させる
        if (this.offices.length === 0) {
          this.$router.push({ name: "Home" });
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
