











































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { VISIT_RECORD } from "#/const";
import { VisitModelExt } from "@/components/visit/types";

@Component
export default class VisitingBar extends Mixins(UtilMixin) {
  /** 訪問状況 1:移動中 2:訪問中 */
  @Prop() status?: number;

  /** 訪問中の一時データ */
  @Prop() model?: VisitModelExt;

  /** 強制終了 */
  @Emit("forceDelete") forceDelete() {
    return;
  }

  /** 訪問状況定数 */
  private visitMode = VISIT_RECORD;

  /** 訪問経過時間のカウンタを保持 */
  private visitTimer = 0;

  /** 経過時間 */
  private visitStartTime = new Date();

  /** 経過時間（表示用） */
  private timeDist = "00:00:00";

  mounted(): void {
    this.visitTimer = setInterval(this.calcVisitTime, 1000);
  }

  beforeDestroy(): void {
    clearInterval(this.visitTimer);
  }

  /** 経過時間計算 */
  private calcVisitTime(): void {
    if (this.$route.name === "VisitMove") {
      // 移動中は経過時間に含まないため、移動開始時間は仮にも現在時刻とする
      this.visitStartTime = new Date();
    } else if (this.model?.visit_start_datetime) {
      // 訪問中にリロードした場合はmodelから基準日時を取る
      this.visitStartTime = this.strToDate(this.model.visit_start_datetime);
    }

    const now = new Date(); // 現在日時
    const diff = now.getTime() - this.visitStartTime.getTime();
    const hour = Math.floor(diff / (60 * 60 * 1000));
    const minute = Math.floor(diff / (60 * 1000)) % 60;
    const second = Math.floor(diff / 1000) % 60;
    this.timeDist =
      ("00" + hour).slice(-2) +
      ":" +
      ("00" + minute).slice(-2) +
      ":" +
      ("00" + second).slice(-2);
  }

  /** 強制終了ボタンクリック */
  private async clickForceDelete() {
    if (
      await this.$openConfirm(
        "訪問状態を強制終了しますか？\n訪問状態は保存されません。\n訪問状態が通常の手段で終了できない場合にのみ使用してください。"
      )
    ) {
      this.forceDelete();
    }
  }
}
