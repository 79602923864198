












































































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { Choice, VForm } from "@/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  ConferenceReport,
  DefaultConferenceReport,
  DefaultConferenceReportRemand,
  DefaultConferenceReportStaff
} from "#/model/conferencereport";
import PatientDoctorSelect from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import PatientRelationInsSelect from "@/components/patient/common_ibow/PatientRelationInsSelect.vue";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import RemandInfo from "@/components/patient/common/remand/RemandInfo.vue";
import ExpantionActions from "@/components/patient/ExpantionActions.vue";

@Component({
  components: {
    PatientHeader,
    PatientSaveArea,
    PatientDoctorSelect,
    PatientRelationInsSelect,
    RemandInfo,
    ExpantionActions
  }
})
export default class ConferenceCreate extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private conferenceId = 0;
  private patientId = 0;

  private conference_report: ConferenceReport = DefaultConferenceReport(); //会議録
  private staffs: Choice[] = []; // 担当者
  private corpItems: Choice[] = []; // 関連機関
  private medicalItems: Choice[] = []; // 医療機関

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  private get StorageKey(): string {
    return `conference_report-${this.loginUser.id}-${this.patientId}-${this.conferenceId}`;
  }

  /** 差し戻し情報があるか */
  private get HasRemandInfo() {
    return (
      this.conference_report.remand.id &&
      this.conference_report.remand.editor_staff_id
    );
  }

  //編集モード設定
  @Watch("conference_report", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      conference_report: this.conference_report,
      displayPreview: this.displayPreview
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  //会議出席者>[副]担当者のIDを設定すると、事業所名もセット
  @Watch("conference_report.staffs", { deep: true })
  private setStaffOfficeName() {
    this.conference_report.staffs.forEach(confStaff => {
      if (!confStaff?.staff_id) return;
      //担当者が設定されていれば、設定されたIDをもとに担当者リストから該当職員を取得
      const staff = this.staffs.find(staffObj => {
        return staffObj.value === confStaff.staff_id;
      });
      //事業所名が取得できなければ何もしない
      if (!staff?.other_string) return;
      confStaff.staff_office_name = staff.other_string;
    });
  }

  //会議種類：会議録タイトルと連動
  @Watch("conference_report.kind")
  private handleKind() {
    if (!this.IsLoaded) {
      return;
    }
    if (this.conference_report.kind == 1) {
      this.conference_report.title = "退院支援指導カンファレンス　会議録";
    }
    if (this.conference_report.kind == 2) {
      this.conference_report.title = "退院共同指導カンファレンス　会議録";
    }
    if (this.conference_report.kind == 3) {
      this.conference_report.title = "サービス担当者会議　会議録";
    }
  }

  created() {
    this.conferenceId = Number(this.$route.params.conferenceid);
    this.patientId = Number(this.$route.params.id);
    this.fetchConferenceReport();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/conference`
      },
      {
        text: "会議録",
        disabled: true,
        to: ""
      }
    ]);
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //保存
  private save(isMove: boolean) {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/conferencereport/save", false, isMove);
    });
  }

  //提出
  private async submission(isPreview = false) {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.saveAction(2, "/api/patient/conferencereport/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.conference_report.patient_id = this.patientId;
    this.conference_report.status = status;
    this.makePdf(
      window.base_url + url,
      {
        conference_report: this.conference_report,
        is_preview: isPreview
      },
      res => {
        this.setNoEditMode();
        if (isMove) {
          this.cancel();
        } else {
          this.conferenceId = res.data.id;
          this.conference_report.id = res.data.id;
          this.conference_report.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { conferenceid: String(this.conferenceId) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //削除
  private clickDelete() {
    this.conference_report.id = this.conferenceId;
    this.conference_report.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/conferencereport/delete",
      {
        conference_report: this.conference_report
      },
      () => {
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //プレビュー
  private preview(): void {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.conference_report.patient_id = this.patientId;
      this.makePdf(window.base_url + "/api/patient/conferencereport/preview", {
        conference_report: this.conference_report
      });
    });
  }

  //会議録情報取得
  private fetchConferenceReport(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/conferencereport/get",
      {
        patient_id: this.patientId,
        conference_id: this.conferenceId
      },
      res => {
        this.staffs = res.data.staffs;
        this.corpItems = res.data.corp_items;
        this.medicalItems = res.data.medical_items;
        if (this.conferenceId != 0) {
          this.conference_report = res.data.conference_report;
        } else {
          this.conference_report.remand = DefaultConferenceReportRemand();
        }
        this.execAfterFetch(this.StorageKey, data => {
          this.conference_report = data.conference_report;
          this.displayPreview = data.displayPreview;
        });
      }
    );
  }

  //-----------------------------------------------------
  // 出席者関連
  //-----------------------------------------------------
  //出席者追加
  private addAttendees() {
    this.conference_report.staffs.push(DefaultConferenceReportStaff(3));
  }
  //出席者削除
  private async deleteAttendees(idx: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.conference_report.staffs.splice(idx, 1);
    }
  }
  //区分変えた時
  private changeDivs(item: { personnel_id: number; doctor_id: number }) {
    item.personnel_id = 0;
    item.doctor_id = 0;
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //担当者：被っている場合
  private checkStaff(): boolean | string {
    if (!this.conference_report.staffs[0].staff_id) {
      return true;
    }
    if (
      this.conference_report.staffs[0].staff_id !=
      (this.conference_report.staffs[1].staff_id ||
        this.conference_report.staffs[2].staff_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //副担当者1：被っている場合
  private checkDeputyStaff1(): boolean | string {
    if (!this.conference_report.staffs[1].staff_id) {
      return true;
    }
    if (
      this.conference_report.staffs[1].staff_id !=
      (this.conference_report.staffs[0].staff_id ||
        this.conference_report.staffs[2].staff_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }
  //副担当者2：被っている場合
  private checkDeputyStaff2(): boolean | string {
    if (!this.conference_report.staffs[2].staff_id) {
      return true;
    }
    if (
      this.conference_report.staffs[2].staff_id !=
      (this.conference_report.staffs[0].staff_id ||
        this.conference_report.staffs[1].staff_id)
    ) {
      return true;
    } else {
      return "担当者が重複しています";
    }
  }

  //医療機関：出席者名が被っている場合
  private checkDoctorId(doctor_id: number): boolean | string {
    let counter = 0;
    for (const send of this.conference_report.staffs) {
      if (doctor_id == send.doctor_id) {
        counter += 1;
      }
      if (counter == 2) {
        return "出席者が重複しています";
      }
    }
    return true;
  }
  //居宅介護支援事業所：出席者名が被っている場合
  private checkPersonnelId(personnel_id: number): boolean | string {
    let counter = 0;
    for (const send of this.conference_report.staffs) {
      if (personnel_id == send.personnel_id) {
        counter += 1;
      }
      if (counter == 2) {
        return "出席者が重複しています";
      }
    }
    return true;
  }
}
