export const name = "ocr";

export const SET_DOCUMENT_INFO = "SET_DOCUMENT_INFO";
export const SET_OCR_RESULT = "SET_OCR_RESULT";
export const CLEAR_STATE = "CLEAR_STATE";

/** OCR情報 */
export interface OcrState {
  documentType: number;
  documentId: number;
  targetKind: number;
  mediIns: OcrMediIns;
  mediInsOldAge: OcrMediInsOldAge;
  mediInsHighExpense: OcrMediInsHighExpense;
  careIns: OcrCareIns;
  careInsPaymentRate: OcrCareInsPaymentRate;
  indicate: OcrIndicate;
  indicateSpecial: OcrSpecialIndicate;
}

/** 医療保険 */
export interface OcrMediIns {
  insuredSign: string; //記号
  insuredNumber: string; //番号
  insuredBranch: string; //枝番
  insurerName: string; //保険者
  insurerNumber: string; //保険者番号
  firstIssueDate: string; //取得年月日
  startDate: string;
  endDate: string;
  paymentRate: number;
  patientName: string;
  householder: string; //世帯主
}

export const DefaultOcrMediInsCondition = (): OcrMediIns => ({
  insuredSign: "",
  insuredNumber: "",
  insuredBranch: "",
  insurerName: "",
  insurerNumber: "",
  firstIssueDate: "",
  startDate: "",
  endDate: "",
  paymentRate: 0,
  patientName: "",
  householder: ""
});

/** 高齢受給者証 */
export interface OcrMediInsOldAge {
  insuredNo: string; //'記号・番号',
  paymentRate: number; //'給付割合（％）',
  startDate: string; //'開始日',
  endDate: string; //'終了日',
}

export const DefaultOcrMediInsOldAgeCondition = (): OcrMediInsOldAge => ({
  insuredNo: "",
  paymentRate: 0,
  startDate: "",
  endDate: ""
});

/** 高額療養費 */
export interface OcrMediInsHighExpense {
  startDate: string; //'開始日',
  endDate: string; //'終了日',
  highExpenseTermId: number; //'医療保険_高額療養費期間ID',
  highExpenseDivId: number; //'医療保険_高額療養費区分ID',
}

export const DefaultOcrMediInsHighExpenseCondition = (): OcrMediInsHighExpense => ({
  startDate: "",
  endDate: "",
  highExpenseTermId: 4,
  highExpenseDivId: 0
});

/** 介護保険 */
export interface OcrCareIns {
  insuredNo: string; //'被保険者番号',
  insurerNo: string; //'保険者番号',
  startDate: string; //'開始日（認定の有効期間）',
  endDate: string; //'終了日（認定の有効期間）',
  needCareLevel: number; //'要介護度\n0:未選択\n1:非該当\n2:要支援1\n3:要支援2\n4:経過的要介護（未使用）\n5:要介護1\n6:要介護2\n7:要介護3\n8:要介護4\n9:要介護5',
  stationName: string; //居宅介護支援事業所名
}

export const DefaultOcrCareInsCondition = (): OcrCareIns => ({
  insuredNo: "",
  insurerNo: "",
  startDate: "",
  endDate: "",
  needCareLevel: 0,
  stationName: ""
});

/** 介護保険負担割合証 */
export interface OcrCareInsPaymentRate {
  rate: number; //'負担割合',
  startDate: string; //'開始日（認定の有効期間）',
  endDate: string; //'終了日（認定の有効期間）',
}

export const DefaultOcrCareInsPaymentRateCondition = (): OcrCareInsPaymentRate => ({
  rate: 0,
  startDate: "",
  endDate: ""
});

/** 指示書 */
export interface OcrIndicate {
  startDate: string; //開始日
  endDate: string; //終了日
  injectionStartDate: string; //注射開始日
  injectionEndDate: string; //注射終了日
  mainDisease1: string; //主傷病名1
  mainDisease2: string; //主傷病名2
  mainDisease3: string; //主傷病名3
  otherDisease: string; //その他傷病名
  doctorIndicate: string; //医師からの指示内容
  hospitalName: string; //医療機関名
  doctorName: string; //医師名
}

export const DefaultOcrIndicateCondition = (): OcrIndicate => ({
  startDate: "",
  endDate: "",
  injectionStartDate: "",
  injectionEndDate: "",
  mainDisease1: "",
  mainDisease2: "",
  mainDisease3: "",
  otherDisease: "",
  doctorIndicate: "",
  hospitalName: "",
  doctorName: ""
});

/** 特別指示書 */
export interface OcrSpecialIndicate {
  startDate: string; //開始日
  endDate: string; //終了日
  injectionStartDate: string; //注射開始日
  injectionEndDate: string; //注射終了日
  injectionIndicate: string; //点滴注射指示内容
  injectionRemarks: string; //留意事項及び指示事項
  doctorIndicate: string; //医師からの指示内容
  hospitalName: string; //医療機関名
  doctorName: string; //医師名
}

export const DefaultOcrSpecialIndicateCondition = (): OcrSpecialIndicate => ({
  startDate: "",
  endDate: "",
  injectionStartDate: "",
  injectionEndDate: "",
  injectionIndicate: "",
  injectionRemarks: "",
  doctorIndicate: "",
  hospitalName: "",
  doctorName: ""
});

/** パーサー */
const parsePaymentRate = (v: string): number => {
  // 数字を半角数字に変更
  v = v.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  if (v.includes("1割")) {
    return 90;
  }
  if (v.includes("2割")) {
    return 80;
  }
  if (v.includes("3割")) {
    return 70;
  }
  return 0;
};

const parseNeedCareLevel = (v: string): number => {
  // 数字を半角数字に変更
  v = v.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  //'要介護度\n0:未選択\n1:非該当\n2:要支援1\n3:要支援2\n4:経過的要介護（未使用）\n5:要介護1\n6:要介護2\n7:要介護3\n8:要介護4\n9:要介護5',
  if (v.includes("非該当")) {
    return 1;
  }
  if (v.includes("要支援1")) {
    return 2;
  }
  if (v.includes("要支援2")) {
    return 3;
  }
  if (v.includes("要介護1")) {
    return 5;
  }
  if (v.includes("要介護2")) {
    return 6;
  }
  if (v.includes("要介護3")) {
    return 7;
  }
  if (v.includes("要介護4")) {
    return 8;
  }
  if (v.includes("要介護5")) {
    return 9;
  }
  return 0;
};

const parseDate = (v: string): string => {
  // 数字を半角数字に変更
  v = v.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  const dt = new Date(v);
  if (isNaN(dt.getDate())) {
    return "";
  }
  const yyyy = dt.getFullYear();
  const MM = ("00" + (dt.getMonth() + 1)).slice(-2);
  const dd = ("00" + dt.getDate()).slice(-2);
  return `${yyyy}-${MM}-${dd}`;
};

/** 医療保険 */
export const SetMediInsGeneral = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["記号"];
  if (v) {
    state.mediIns.insuredSign = v + "";
  }
  v = map["番号"];
  if (v) {
    state.mediIns.insuredNumber = v + "";
  }
  v = map["保険者名称"];
  if (v) {
    state.mediIns.insurerName = v + "";
  }
  v = map["保険者番号"];
  if (v) {
    state.mediIns.insurerNumber = v + "";
  }
  v = map["取得年月日"];
  if (v) {
    state.mediIns.firstIssueDate = parseDate(v + "");
  }
};

export const SetMediInsNational = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["記号"];
  if (v) {
    state.mediIns.insuredSign = v + "";
  }
  v = map["番号"];
  if (v) {
    state.mediIns.insuredNumber = v + "";
  }
  v = map["枝番"];
  if (v) {
    state.mediIns.insuredBranch = v + "";
  }
  v = map["保険者名称"];
  if (v) {
    state.mediIns.insurerName = v + "";
  }
  v = map["保険者番号"];
  if (v) {
    state.mediIns.insurerNumber = v + "";
  }
  v = map["資格取得年月日"];
  if (v) {
    state.mediIns.firstIssueDate = parseDate(v + "");
  }
  v = map["負担割合"];
  if (v) {
    state.mediIns.paymentRate = parsePaymentRate(v + "");
  }
  v = map["有効期限（開始日）"];
  if (v) {
    state.mediIns.startDate = parseDate(v + "");
  }
  v = map["有効期限（終了日）"];
  if (v) {
    state.mediIns.endDate = parseDate(v + "");
  }
  v = map["氏名"];
  if (v) {
    state.mediIns.patientName = v + "";
  }
};

export const SetMediInsOldOld = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["保険者番号"];
  if (v) {
    state.mediIns.insurerNumber = v + "";
  }
  v = map["保険者名称"];
  if (v) {
    state.mediIns.insurerName = v + "";
  }
  v = map["被保険者番号"];
  if (v) {
    state.mediIns.insuredNumber = v + "";
  }
  v = map["取得年月日"];
  if (v) {
    state.mediIns.firstIssueDate = parseDate(v + "");
  }
  v = map["発行期日(開始日)"];
  if (v) {
    state.mediIns.startDate = parseDate(v + "");
  }
  v = map["有効期限(終了日)"];
  if (v) {
    state.mediIns.endDate = parseDate(v + "");
  }
  v = map["負担割合"];
  if (v) {
    state.mediIns.paymentRate = parsePaymentRate(v + "");
  }
  v = map["氏名"];
  if (v) {
    state.mediIns.patientName = v + "";
  }
};

/** 高齢受給者証 */
export const SetMediInsOldAge = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  let p: unknown = "";
  let w = "";
  v = map["記号"];
  p = map["番号"];
  if (v) {
    w = v + "";
  }
  if (p) {
    w += "・" + p;
  }
  if (w !== "") {
    state.mediInsOldAge.insuredNo = w;
  }
  v = map["負担割合"];
  if (v) {
    state.mediInsOldAge.paymentRate = parsePaymentRate(v + "");
  }
  v = map["有効期限（開始日）"];
  if (v) {
    state.mediInsOldAge.startDate = parseDate(v + "");
  }
  v = map["有効期限（終了日）"];
  if (v) {
    state.mediInsOldAge.endDate = parseDate(v + "");
  }
};

/** 高額療養費 */
export const SetMediInsHighExpense = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["開始日"];
  if (v) {
    state.mediInsHighExpense.startDate = parseDate(v + "");
  }
  v = map["終了日"];
  if (v) {
    state.mediInsHighExpense.endDate = parseDate(v + "");
  }
};

/** 介護保険 */
export const SetCareInsGeneral = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["被保険者番号"];
  if (v) {
    state.careIns.insuredNo = v + "";
  }
  v = map["保険者番号"];
  if (v) {
    state.careIns.insurerNo = v + "";
  }
  v = map["有効期間開始"];
  if (v) {
    state.careIns.startDate = parseDate(v + "");
  }
  v = map["有効期間終了"];
  if (v) {
    state.careIns.endDate = parseDate(v + "");
  }
  v = map["要介護度"];
  if (v) {
    state.careIns.needCareLevel = parseNeedCareLevel(v + "");
  }
  v = map["居宅介護支援事業者"];
  if (v) {
    state.careIns.stationName = v + "";
  }
};

interface PaymentRate {
  rate: number;
  start: string;
  end: string;
}

/** 介護保険負担割合証 */
export const SetCareInsPaymentRate = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  const arr: PaymentRate[] = [
    {
      rate: 1,
      start: parseDate(map["負担1割開始日"] + ""),
      end: parseDate(map["負担1割終了日"] + "")
    },
    {
      rate: 2,
      start: parseDate(map["負担2割開始日"] + ""),
      end: parseDate(map["負担2割終了日"] + "")
    },
    {
      rate: 3,
      start: parseDate(map["負担3割開始日"] + ""),
      end: parseDate(map["負担3割終了日"] + "")
    }
  ];

  //開始日、終了日の登録ありを最優先
  let obj = arr.find(o => {
    return o.start != "" && o.end != "";
  });
  if (!obj) {
    //開始日のみ
    obj = arr.find(o => {
      return o.start != "";
    });
  }
  if (!obj) {
    //終了日のみ
    obj = arr.find(o => {
      return o.end != "";
    });
  }
  if (obj) {
    state.careInsPaymentRate.rate = obj.rate;
    state.careInsPaymentRate.startDate = obj.start;
    state.careInsPaymentRate.endDate = obj.end;
  }
};

/** 指示書 */
export const SetIndicateNormal = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["訪問看護指示期間開始"];
  if (v) {
    state.indicate.startDate = parseDate(v + "");
  }
  v = map["訪問看護指示期間終了"];
  if (v) {
    state.indicate.endDate = parseDate(v + "");
  }
  v = map["点滴注射指示期間開始"];
  if (v) {
    state.indicate.injectionStartDate = parseDate(v + "");
  }
  v = map["点滴注射指示期間終了"];
  if (v) {
    state.indicate.injectionEndDate = parseDate(v + "");
  }
  v = map["主傷病名１"];
  if (v) {
    state.indicate.mainDisease1 = v + "";
  }
  v = map["主傷病名２"];
  if (v) {
    state.indicate.mainDisease2 = v + "";
  }
  v = map["主傷病名３"];
  if (v) {
    state.indicate.mainDisease3 = v + "";
  }
  v = map["主傷病名その他"];
  if (v) {
    state.indicate.otherDisease = v + "";
  }
  const keys: string[] = [
    "病状・治療状態",
    "療養生活指導上の留意事項",
    "緊急時の連絡先",
    "特記すべき留意事項"
  ];
  const vals: string[] = [];
  for (const k of keys) {
    v = map[k];
    if (v) {
      vals.push(v + "");
    }
  }
  state.indicate.doctorIndicate = vals.join("\n-----------------\n");
  v = map["医療機関名"];
  if (v) {
    state.indicate.hospitalName = v + "";
  }
  v = map["医師氏名"];
  if (v) {
    state.indicate.doctorName = v + "";
  }
};

export const SetIndicateMental = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["精神指示期間開始"];
  if (v) {
    state.indicate.startDate = parseDate(v + "");
  }
  v = map["精神指示期間終了"];
  if (v) {
    state.indicate.endDate = parseDate(v + "");
  }
  v = map["主傷病名１"];
  if (v) {
    state.indicate.mainDisease1 = v + "";
  }
  v = map["主傷病名２"];
  if (v) {
    state.indicate.mainDisease2 = v + "";
  }
  v = map["主傷病名３"];
  if (v) {
    state.indicate.mainDisease3 = v + "";
  }
  v = map["主傷病名その他"];
  if (v) {
    state.indicate.otherDisease = v + "";
  }
  const keys: string[] = [
    "病状・治療状況",
    "投与中の薬剤の用量・用法",
    "緊急時の連絡先",
    "特記すべき留意事項",
    "留意事項及び指示事項"
  ];
  const vals: string[] = [];
  for (const k of keys) {
    v = map[k];
    if (v) {
      vals.push(v + "");
    }
  }
  state.indicate.doctorIndicate = vals.join("\n-----------------\n");
  v = map["医療機関名"];
  if (v) {
    state.indicate.hospitalName = v + "";
  }
  v = map["医師氏名"];
  if (v) {
    state.indicate.doctorName = v + "";
  }
};

/** 特別指示書 */
export const SetIndicateSpecial = (
  state: OcrState,
  map: { [key: string]: unknown }
): void => {
  let v: unknown = "";
  v = map["特別看護指示期間開始"];
  if (v) {
    state.indicateSpecial.startDate = parseDate(v + "");
  }
  v = map["特別看護指示期間終了"];
  if (v) {
    state.indicateSpecial.endDate = parseDate(v + "");
  }
  v = map["点滴注射指示期間開始"];
  if (v) {
    state.indicateSpecial.injectionStartDate = parseDate(v + "");
  }
  v = map["点滴注射指示期間終了"];
  if (v) {
    state.indicateSpecial.injectionEndDate = parseDate(v + "");
  }
  v = map["点滴注射指示内容"];
  if (v) {
    state.indicateSpecial.injectionIndicate = v + "";
  }
  v = map["留意事項及び指示事項"];
  if (v) {
    state.indicateSpecial.injectionRemarks = v + "";
  }
  const keys: string[] = [
    "病状・主訴",
    "一時的に訪問看護が頻回に必要な理由",
    "特に観察を要する項目",
    "緊急時の連絡先等"
  ];
  const vals: string[] = [];
  for (const k of keys) {
    v = map[k];
    if (v) {
      vals.push(v + "");
    }
  }
  state.indicateSpecial.doctorIndicate = vals.join("\n-----------------\n");
  v = map["医療機関名"];
  if (v) {
    state.indicateSpecial.hospitalName = v + "";
  }
  v = map["医師氏名"];
  if (v) {
    state.indicateSpecial.doctorName = v + "";
  }
};
