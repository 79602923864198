import Base from "./base";
import { Pdf, DefaultPdf, Remand, DefaultRemand } from "./report";
import {
  ApplyDoctor,
  DefaultApplyDoctor,
  ApplyStaff,
  DefaultApplyStaff
} from "./common";

/** 差戻し */
export interface OccurReportRemand extends Remand {
  occur_report_id: number; //ヒヤリハット報告書ID
}
export const DefaultOccurReportRemand = (): OccurReportRemand => ({
  ...DefaultRemand(),
  occur_report_id: 0
});

//ヒヤリハット報告書
export interface OccurReport extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  report_date: string; //'報告日',
  report_staff_id: number; //'報告職員ID',
  o_place: string; //'発生場所',
  o_datetm: string; //'発生日時',
  o_status: string; //'発生状況',
  support_id: number; //'対応状況\n1:その場で処置/様子を見る\n2:救急搬送/一般外来',
  descript: string; //'利用者/家族への説明',
  medical_institution_id: number; //'搬送先医療機関ID',
  med_support: string; //'医療機関での対応',
  possible: string; //'原因と対策',
  prevent: string; //'予防策',
  submit_datetime_wareki: string; //提出日時（和暦）
  report_date_wareki: string; //報告日（和暦）
  o_datetm_wareki: string; //発生日時（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  pdf: Pdf; //PDF
  remand: OccurReportRemand; //差し戻し
  editor_staff: ApplyStaff; //編集者
  report_staff: ApplyStaff; //報告職員
  medical_institution: ApplyDoctor; //搬送先医療機関
}
export const DefaultOccurReport = (): OccurReport => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  submit_datetime: "",
  report_date: "",
  report_staff_id: 0,
  o_place: "",
  o_datetm: "",
  o_status: "",
  support_id: 0,
  descript: "",
  medical_institution_id: 0,
  med_support: "",
  possible: "",
  prevent: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  submit_datetime_wareki: "",
  report_date_wareki: "",
  o_datetm_wareki: "",
  updated_at_wareki: "",
  pdf: DefaultPdf(), //PDF
  remand: DefaultOccurReportRemand(), //差し戻し
  editor_staff: DefaultApplyStaff(), //編集者
  report_staff: DefaultApplyStaff(), //報告職員
  medical_institution: DefaultApplyDoctor() //搬送先医療機関
});
