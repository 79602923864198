





































































import { Component, Mixins } from "vue-property-decorator";
import EditPrimaryContentDialog from "@/components/master/nursing_content/EditNursingPrimaryContentDialog.vue";
import EditSecondaryContentDialog from "@/components/master/nursing_content/EditNursingSecondaryContentDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  NursingPrimaryContent,
  NursingSecondaryContent,
  Office
} from "./types";
import { DataTableHeader } from "vuetify/types/index";
import CommonMasterList from "@/components/master/CommonMasterList.vue";

@Component({
  components: {
    EditPrimaryContentDialog,
    EditSecondaryContentDialog,
    CommonMasterList
  }
})
export default class NusringContentList extends Mixins(AxiosMixin) {
  private isShownEditPrimaryContentDialog = false;
  private isShownEditSecondaryContentDialog = false;
  private selectedPrimaryContent?: NursingPrimaryContent = undefined;
  private selectedSecondaryContent?: NursingSecondaryContent = undefined;
  private primaryContents: NursingPrimaryContent[] = [];

  private headers: DataTableHeader[] = [
    {
      text: "大項目",
      value: "content_name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    {
      text: "",
      value: "care_medium_contents",
      width: "1px",
      sortable: false
    }
  ];

  private subHeaders: DataTableHeader[] = [
    {
      text: "中項目",
      value: "content_name"
    }
  ];

  private offices: Office[] = [];

  private get NotAiPrimaryContents() {
    return this.primaryContents.filter(value => value.is_ai != 2);
  }

  public created(): void {
    this.fetchCareLargeContents();
  }

  fetchCareLargeContents(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/carelarge_content/get",
      {},
      res => {
        this.primaryContents = res.data.carelargecontents;
      }
    );
  }

  private didClickToSortModeButton() {
    this.postJsonCheck(
      window.base_url + "/api/master/carelarge_content/save_order",
      {
        carelargecontents: this.primaryContents
      },
      () => {
        this.fetchCareLargeContents();
      }
    );
  }

  //大項目新規作成
  private didClickCreateNewPrimaryItemButton() {
    this.selectedPrimaryContent = undefined;
    this.isShownEditPrimaryContentDialog = true;
  }

  //中項目新規作成
  private didClickCreateNewSecondaryItemButton() {
    this.selectedSecondaryContent = undefined;
    this.isShownEditSecondaryContentDialog = true;
  }

  //大項目編集
  private didClickEditPrimaryContentButton(content: NursingPrimaryContent) {
    this.selectedPrimaryContent = content;
    this.isShownEditPrimaryContentDialog = true;
  }

  //中項目編集
  private didClickEditSecondaryButton(content: NursingSecondaryContent) {
    this.selectedSecondaryContent = content;
    this.isShownEditSecondaryContentDialog = true;
  }

  //大項目ダイアログ閉じる
  private didClickCloseEditPrimaryContentDialogButton() {
    this.isShownEditPrimaryContentDialog = false;
  }

  //中項目ダイアログ閉じる
  private didClickCloseEditSecondaryContentDialogButton() {
    this.isShownEditSecondaryContentDialog = false;
  }

  //大項目保存
  private async save(content: unknown) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/master/carelarge_content/save",
      {
        carelargecontent: content
      },
      () => {
        this.fetchCareLargeContents();
        this.didClickCloseEditPrimaryContentDialogButton();
      }
    );
  }

  //中項目保存
  private async saveSecondaryContent(
    content: NursingSecondaryContent
  ): Promise<void> {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    const index = this.primaryContents.findIndex(
      p => p.id == content.care_large_content_id
    );
    if (index > -1) {
      if (content.id) {
        const updItemIndex = this.primaryContents[
          index
        ].care_medium_contents.findIndex(item => item.id == content.id);
        if (updItemIndex > -1) {
          this.primaryContents[index].care_medium_contents[
            updItemIndex
          ].content_name = content.content_name;
        }
      } else {
        if (!this.primaryContents[index].care_medium_contents) {
          this.primaryContents[index].care_medium_contents = [];
        }
        this.primaryContents[index].care_medium_contents.push(content);
      }
      this.postJsonCheck(
        window.base_url + "/api/master/caremedium_content/save",
        {
          carelargecontent: this.primaryContents[index],
          care_medium_content: content
        },
        () => {
          this.fetchCareLargeContents();
          this.didClickCloseEditSecondaryContentDialogButton();
        }
      );
    }
  }

  //大項目削除
  private deletePrimaryContent(content: unknown): void {
    this.postJsonCheck(
      window.base_url + "/api/master/carelarge_content/delete",
      {
        carelargecontent: content
      },
      () => {
        this.fetchCareLargeContents();
        this.didClickCloseEditPrimaryContentDialogButton();
      }
    );
  }

  //中項目削除
  private deleteSecondaryContent(content: NursingSecondaryContent): void {
    const index = this.primaryContents.findIndex(
      item => item.id == content.care_large_content_id
    );
    if (index > -1) {
      this.primaryContents[index].care_medium_contents = this.primaryContents[
        index
      ].care_medium_contents.filter(item => item.id !== content.id);
      this.postJsonCheck(
        window.base_url + "/api/master/caremedium_content/delete",
        {
          carelargecontent: this.primaryContents[index],
          care_medium_content: content
        },
        () => {
          this.fetchCareLargeContents();
          this.didClickCloseEditSecondaryContentDialogButton();
        }
      );
    }
  }

  //大項目コピー
  private clickCopyPrimaryContentButton(content: NursingPrimaryContent) {
    const copyContent: NursingPrimaryContent = {
      id: 0,
      content_name: content.content_name,
      is_ai: content.is_ai,
      care_medium_contents: [],
      care_large_content_offices: []
    };

    if (content.care_medium_contents) {
      content.care_medium_contents.forEach(medium => {
        const copyItem = { ...medium };
        copyItem.id = 0;
        copyContent.care_medium_contents.push(copyItem);
      });
    }

    if (content.care_large_content_offices) {
      content.care_large_content_offices.forEach(office => {
        copyContent.care_large_content_offices.push(office);
      });
    }
    this.selectedPrimaryContent = copyContent;
    this.isShownEditPrimaryContentDialog = true;
  }

  private editFunc(item: NursingPrimaryContent) {
    return item.is_ai !== 2;
  }
}
