
























import { Component, Watch } from "vue-property-decorator";
import RecuperationDiv from "./RecuperationDiv.vue";

@Component
export default class RecuperationDivV2 extends RecuperationDiv {
  //療養区分（同一建物内:指示書 && 基本IIで入力可 / 精神指示書 && 基本Ⅲで入力可）左記以外は空にする
  @Watch("receiptVisitRecord.remedy_div") protected handleRemedyDiv() {
    if (!this.isLoaded) {
      return;
    }
    if (
      (this.event.visit_record.mental_div == 1 &&
        this.receiptVisitRecord.remedy_div != 2) ||
      (this.event.visit_record.mental_div == 2 &&
        this.receiptVisitRecord.remedy_div != 3) ||
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.stay_outside_flg == 1 ||
      this.receiptVisitRecord.remedy_div == 4
    ) {
      this.receiptVisitRecord.times_threeperson_div = 0;
      this.receiptVisitRecord.accompanied_staff_threeperson_div = 0;
    }
  }
}
