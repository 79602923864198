import Base from "../base";
import { PublicMoneySelfBurden } from "./publicMoneySelfBurden";

//公費
export interface PublicMoney extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  public_money_kind_id: number; //'公費種類ID',
  insurer_no: string; //'負担者番号',
  insured_no: string; //'受給者番号',
  daily_self_bear_limit_amount: number; //1日あたりの自己負担上限額
  self_bear_limit_amount: string; //'自己負担上限額',
  use_other_medical_amount: number; //'他医療機関（自己負担の累積額）入力フラグ',
  payment_rate: number; //給付割合
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
  insurance_div: number; //保険区分\n0:医療\n1:介護
  no: number; //方別番号
  name: string; //公費名
  priority: number; //優先順位
  is_daily: number; //日毎公費フラグ 0:無効 1:有効
  is_receipt_link: number; //レセプト連携フラグ\n0:無効\n1:有効
  is_add_user: number; //ユーザー追加フラグ\n0:システム追加\n1:ユーザー追加
  self_burdens: PublicMoneySelfBurden[]; //公費自己負担
  honobono_link_id: number; // ほのぼの連携ID
}
export const DefaultPublicMoney = (): PublicMoney => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  public_money_kind_id: 0,
  insurer_no: "",
  insured_no: "",
  daily_self_bear_limit_amount: 0,
  self_bear_limit_amount: "",
  use_other_medical_amount: 0,
  payment_rate: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: "",
  insurance_div: 0,
  no: 0,
  name: "",
  priority: 0,
  is_daily: 0,
  is_receipt_link: 0,
  is_add_user: 0,
  self_burdens: [],
  honobono_link_id: 0
});
