



















import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class ExcelInputSaveResult extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** 最終シートフラグ */
  @Prop() isLastSheet!: boolean;

  /** 終了ボタンクリック時 */
  @Emit("completed")
  private completed() {
    return;
  }

  /** 次へボタンクリック時 */
  @Emit("next")
  private next() {
    return;
  }

  /** リセットボタンクリック時 */
  @Emit("reset")
  private reset() {
    return;
  }
}
