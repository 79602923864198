


















































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { PDF_LINK_STATUS } from "@/components/patient/pdflink/list";
import { PdfLink } from "#/model/patient/pdfLink";

@Component
export default class PdfLinkItem extends Mixins(AxiosMixin, UtilMixin) {
  @Prop() private readonly pdfLink!: PdfLink; // PDFリンクメールの項目

  /** PDFリンクメールステータス */
  private readonly STATUS = PDF_LINK_STATUS;

  /** アイテムクリック時 */
  private clickItem() {
    switch (this.pdfLink.status) {
      case this.STATUS.SAVE:
        this.clickEdit(this.pdfLink.id);
        break;
      case this.STATUS.SEND:
        this.clickDetail(this.pdfLink.id);
        break;
    }
  }

  /** PDFリンクメール編集画面へ遷移(下書きのみ) */
  @Emit("edit")
  clickEdit(pdfLinkId: number) {
    return pdfLinkId;
  }

  /** PDFリンクメール詳細画面へ遷移 */
  @Emit("detail")
  clickDetail(pdfLinkId: number) {
    return pdfLinkId;
  }
}
