





































































import { Component, Ref } from "vue-property-decorator";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import BaseRecord2List, { ListItems } from "@/components/list/baseRecord2List";
import { SortWarekiDate } from "#/utility/appSort";

@Component({
  components: {
    PatientHeader,
    BaseReportList
  }
})
export default class Record2List extends BaseRecord2List {
  @Ref("reportList") private readonly reportList?: BaseReportList;

  private patientId = "";

  private headers = [
    {
      text: "訪問者",
      value: "visit_staff_name",
      width: "150px"
    },
    {
      text: "訪問日時",
      value: "visit_date_time",
      width: "230px",
      sort: SortWarekiDate
    },
    {
      text: "記入者",
      value: "create_staff_name",
      width: "150px"
    }
  ];

  private commentHeader = {
    text: "コメント",
    value: "remand_comment"
  };

  private submitHeaders = [
    {
      text: "提出日",
      value: "submit_date_time",
      width: "160px",
      sort: SortWarekiDate
    }
  ];

  /** 最後に検索したタブ */
  private searchedTab = 0;

  created(): void {
    this.patientId = this.$route.params.id;
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchRecord2({ tab: 0, page: 0 });
    }
  }

  mounted() {
    this.setDefaultHeader();
  }

  /** タブが切り替わった時 */
  private updateTab(obj: { tab: number; page: number }) {
    this.searchedTab = obj.tab;
    this.fetchRecord2(obj);
  }

  // 一覧取得
  private fetchRecord2(obj: { tab: number; page: number }) {
    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecords/get",
      {
        patient_id: Number(this.patientId),
        tab: obj.tab,
        page: obj.page
      },
      res => {
        if (res.data.records != null) {
          this.reportList?.finishLoaded(res.data.records);
          if (obj.page != 0) {
            this.records = this.records.concat(res.data.records);
          } else {
            this.records = res.data.records;
          }
        } else {
          this.reportList?.finishLoaded([]);
        }
      }
    );
  }

  // 新規作成
  private clickNew() {
    this.$router.push({
      name: "PatientRecord2Edit",
      params: { id: String(this.patientId), record2_id: "0" }
    });
  }

  // 差し戻し
  private async clickRemand(item: ListItems) {
    if (await this.remand(item)) {
      this.reportList?.clickRemandBtn(item);
    }
  }
}
