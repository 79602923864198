




































































































































































import { Component, Ref, Prop, Emit } from "vue-property-decorator";
import Base from "@/components/patient/info/Base";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import { RelationInstitution, MedicalInstitution } from "#/model/uniquemaster";
import MedicalForm from "@/components/master/medical/MedicalForm.vue";
import CorpForm from "@/components/master/corp/CorpForm.vue";

interface SearchItem {
  corp_med_name: string;
  tel_area: string;
  name: string;
}
const DefaultSearchItem = (): SearchItem => ({
  corp_med_name: "",
  tel_area: "",
  name: ""
});
interface SearchResList {
  id: number;
  corp_med_name: string;
}
interface AddItem {
  corpMedId: number;
  last_name: string;
  last_name_kana: string;
  first_name: string;
  first_name_kana: string;
}
const DefaultAddItem = (): AddItem => ({
  corpMedId: 0,
  last_name: "",
  last_name_kana: "",
  first_name: "",
  first_name_kana: ""
});

interface Choice {
  text: string;
  value: number;
}
interface Texts {
  d_title: string;
  s_text: string;
  s_input1: string;
  s_input2: string;
  s_input3: string;
  a_input1: string;
  a_input2: string;
  a_input3: string;
  a_input4: string;
  a_input5: string;
  a_input1_err: string;
  a_ins: string;
  save_vaid_err: string;
}
const DefaultTexts = (): Texts => ({
  d_title: "",
  s_text: "",
  s_input1: "",
  s_input2: "",
  s_input3: "",
  a_input1: "",
  a_input2: "",
  a_input3: "",
  a_input4: "",
  a_input5: "",
  a_input1_err: "",
  a_ins: "",
  save_vaid_err: ""
});

@Component({ components: { MedicalForm, CorpForm } })
export default class RequesterType extends Base {
  // 依頼元
  @Prop({ default: 0 }) private type!: number;
  // 追加用情報
  @Prop({ default: [] }) private relationItems!: RelationInstitution[];
  @Prop({ default: [] }) private medItems!: MedicalInstitution[];
  @Ref("searchForm") private readonly searchForm!: VForm;
  @Ref("addForm") private readonly addForm!: VForm;
  @Ref("medForm") private readonly medForm!: MedicalForm;
  @Ref("corpForm") private readonly corpForm!: CorpForm;

  @Emit()
  private add() {
    return;
  }

  @Emit()
  private select(id: number) {
    this.closeDialog();
    return id;
  }
  // ダイアログ開閉状態
  private dialog = false;

  /** 担当者検索 */
  // 入力値
  private searchItem: SearchItem = DefaultSearchItem();
  //formvaid
  private searchValid = true;
  private searchResList: SearchResList[] = [];
  // 検索結果ヘッダー
  private headers: DataTableHeader[] = [
    {
      text: "関連機関名",
      value: "corp_med_name"
    },
    {
      text: "選択",
      value: "action"
    }
  ];
  //検索結果一覧

  /** 担当者登録 */
  // 入力値
  private addItem: AddItem = DefaultAddItem();
  //formvaid
  private valid = true;

  private insDialog = false;

  // 各テキスト
  private get Texts(): Texts {
    if (this.type === this.PRIREQUESTER_TYPE.RELATED) {
      //関連機関
      return {
        d_title: "関連機関検索/登録",
        s_text:
          "関連機関名と電話番号で検索して、該当の関連機関を選択すると入力画面に反映されます。",
        s_input1: "関連機関名",
        s_input2: "電話番号",
        s_input3: "担当者名",
        a_input1: "*事業所名",
        a_input2: "*担当者姓",
        a_input3: "*担当者名",
        a_input4: "*姓ふりがな",
        a_input5: "*名ふりがな",
        a_input1_err: "事業所を選択してください。",
        a_ins: "関連機関登録",
        save_vaid_err:
          "指定した事業所情報が取得できませんでした。再度お試しください。"
      } as Texts;
    } else if (this.type === this.PRIREQUESTER_TYPE.MEDICAL) {
      //関連機関
      return {
        d_title: "医療機関検索/登録",
        s_text:
          "医療機関名と電話番号で検索して、該当の医療機関を選択すると入力画面に反映されます。",
        s_input1: "医療機関名",
        s_input2: "電話番号",
        s_input3: "医師名",
        a_input1: "*医療機関名",
        a_input2: "*医師姓",
        a_input3: "*医師名",
        a_input4: "*姓ふりがな",
        a_input5: "*名ふりがな",
        a_input1_err: "医療機関を選択してください。",
        a_ins: "医療機関登録",
        save_vaid_err:
          "指定した医療機関情報が取得できませんでした。再度お試しください。"
      };
    }
    return DefaultTexts();
  }
  public openDialog() {
    this.dialog = true;
  }
  public closeDialog() {
    this.searchItem = DefaultSearchItem();
    this.searchForm.resetValidation();
    this.searchResList = [];
    this.addItem = DefaultAddItem();
    this.addForm.resetValidation();
    this.dialog = false;
  }

  // 担当者登録
  private async save() {
    if (!this.addForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (this.type === this.PRIREQUESTER_TYPE.RELATED) {
      this.relationSave();
    } else if (this.type === this.PRIREQUESTER_TYPE.MEDICAL) {
      this.medSave();
    }
  }

  private async relationSave() {
    const selectContent = this.relationItems.find(
      item => item.id === this.addItem.corpMedId
    );
    if (!selectContent) {
      await this.$openAlert(this.Texts.save_vaid_err);
      return;
    }
    if (this.addItem)
      selectContent.personnels.push({
        id: 0,
        relation_institution_id: 0,
        is_empty: 1,
        full_name: "",
        furigana: "",
        last_name: this.addItem.last_name,
        last_name_kana: this.addItem.last_name_kana,
        first_name: this.addItem.first_name,
        first_name_kana: this.addItem.first_name_kana,
        section_name: "",
        status: 1,
        remarks: "",
        relation_institution_name: selectContent.name,
        personnel_contacts: [],
        created_at: "",
        updated_at: "",
        deleted_at: ""
      });

    this.postJsonCheck(
      window.base_url + "/api/master/relationinstitution/save",
      {
        relation_institution: selectContent
      },
      () => {
        // 追加した担当者を選択肢に反映させるため、更新
        this.add();
        this.closeDialog();
      }
    );
  }

  private async medSave() {
    const selectContent = this.medItems.find(
      item => item.id === this.addItem.corpMedId
    );
    if (!selectContent) {
      await this.$openAlert(this.Texts.save_vaid_err);
      return;
    }
    if (this.addItem)
      selectContent.doctors.push({
        id: 0,
        medical_institution_id: 0,
        is_empty: 1,
        full_name: "",
        furigana: "",
        last_name: this.addItem.last_name,
        last_name_kana: this.addItem.last_name_kana,
        first_name: this.addItem.first_name,
        first_name_kana: this.addItem.first_name_kana,
        section_name: "",
        status: 1,
        remarks: "",
        honobono_hosp_dr_id: 0,
        doctor_contacts: [],
        created_at: "",
        updated_at: "",
        deleted_at: ""
      });

    this.postJsonCheck(
      window.base_url + "/api/master/medicalinstitution/save",
      {
        medical_institution: selectContent
      },
      () => {
        this.add();
        this.closeDialog();
      }
    );
  }

  // 選択肢
  private get RequestItems() {
    const items: Choice[] = [];
    if (this.type === this.PRIREQUESTER_TYPE.RELATED) {
      for (const ins of this.relationItems) {
        items.push({
          text: ins.name,
          value: ins.id
        });
      }
    } else if (this.type === this.PRIREQUESTER_TYPE.MEDICAL) {
      for (const ins of this.medItems) {
        items.push({
          text: ins.name,
          value: ins.id
        });
      }
    }
    return items;
  }

  private async search() {
    // 初期化
    this.searchResList = [];
    if (!this.searchForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (
      this.searchItem.corp_med_name === "" &&
      this.searchItem.tel_area === "" &&
      this.searchItem.name === ""
    ) {
      this.searchResList = [];
      return;
    }
    // 絞り込み
    if (this.type === this.PRIREQUESTER_TYPE.RELATED) {
      this.searchResList = this.searchRelations();
    } else if (this.type === this.PRIREQUESTER_TYPE.MEDICAL) {
      this.searchResList = this.searchMeds();
    }
  }

  //関連機関検索
  private searchRelations(): SearchResList[] {
    const resItems: SearchResList[] = [];

    const searchInst = this.searchItem.corp_med_name.replace(/\s/g, "");
    const searchName = this.searchItem.name.replace(/\s/g, "");

    const filteredItems = this.relationItems
      .filter(item => {
        // 関連機関名
        if (searchInst === "") {
          return true;
        }
        const name = item.name.replace(/\s/g, "");
        const nameKana = item.furigana.replace(/\s/g, "");
        if (name.match(searchInst) || nameKana.match(searchInst)) {
          return true;
        }
        return false;
      })
      .filter(item => {
        // 電話番号
        if (this.searchItem.tel_area === "") {
          return true;
        }
        const tel = "" + item.tel_area + item.tel_city + item.tel_num;
        if (tel.match(this.searchItem.tel_area)) {
          return true;
        }
        return false;
      });

    // 担当者を取得
    filteredItems.forEach(item => {
      for (const personnel of item.personnels) {
        // 担当者名絞り込み（ふりがなも含める）
        const name = personnel.full_name.replace(/\s/g, "");
        const nameKana = personnel.furigana.replace(/\s/g, "");
        if (
          searchName !== "" &&
          !name.match(searchName) &&
          !nameKana.match(searchName)
        ) {
          continue;
        }

        let text = item.name;
        if (personnel.full_name !== "") {
          text += "/" + personnel.full_name;
        }
        if (item.tel_area && item.tel_city && item.tel_num) {
          text +=
            "/" + item.tel_area + "-" + item.tel_city + "-" + item.tel_num;
        }
        resItems.push({
          id: personnel.id,
          corp_med_name: text
        });
      }
    });

    return resItems;
  }

  //医療機関 検索
  private searchMeds(): SearchResList[] {
    const resItems: SearchResList[] = [];

    const searchInst = this.searchItem.corp_med_name.replace(/\s/g, "");
    const searchName = this.searchItem.name.replace(/\s/g, "");

    const filteredItems = this.medItems
      .filter(item => {
        // 医療機関名
        if (searchInst === "") {
          return true;
        }
        const name = item.name.replace(/\s/g, "");
        const nameKana = item.furigana.replace(/\s/g, "");
        if (name.match(searchInst) || nameKana.match(searchInst)) {
          return true;
        }
        return false;
      })
      .filter(item => {
        // 電話番号
        if (this.searchItem.tel_area === "") {
          return true;
        }
        const tel = "" + item.tel_area + item.tel_city + item.tel_num;
        if (tel.match(this.searchItem.tel_area)) {
          return true;
        }
        return false;
      });

    // 医師を取得
    filteredItems.forEach(item => {
      for (const doctor of item.doctors) {
        // 医師名絞り込み（ふりがなも含める）
        // 医師登録がないものは飛ばす
        const name = doctor.full_name.replace(/\s/g, "");
        const nameKana = doctor.furigana.replace(/\s/g, "");
        if (
          searchName !== "" &&
          !name.match(searchName) &&
          !nameKana.match(searchName)
        ) {
          continue;
        }
        let text = item.name;
        if (doctor.full_name !== "") {
          text += "/" + doctor.full_name;
        }
        if (item.tel_area && item.tel_city && item.tel_num) {
          text +=
            "/" + item.tel_area + "-" + item.tel_city + "-" + item.tel_num;
        }
        resItems.push({
          id: doctor.id,
          corp_med_name: text
        });
      }
    });

    return resItems;
  }

  //機関の登録
  private async saveIns() {
    if (this.type === this.PRIREQUESTER_TYPE.RELATED) {
      if (await this.corpForm.saveRelationInstitution()) {
        this.clearIns();
        this.add();
      }
    } else if (this.type === this.PRIREQUESTER_TYPE.MEDICAL) {
      if (await this.medForm.saveMedicalInstitution()) {
        this.clearIns();
        this.add();
      }
    }
  }

  // 機関追加のキャンセル
  private clearIns() {
    if (this.type === this.PRIREQUESTER_TYPE.RELATED) {
      this.corpForm.clear();
    } else if (this.type === this.PRIREQUESTER_TYPE.MEDICAL) {
      this.medForm.clear();
    }
    this.insDialog = false;
  }
}
