





























import { Component, Mixins, Ref } from "vue-property-decorator";
import draggable from "vuedraggable";
import GroupEdit from "@/components/master/group/GroupEdit.vue";
import * as groupCommon from "@/components/master/group/common";
import AxiosMixin from "@/mixins/axiosMixin";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import { DataTableHeader } from "vuetify/types/index";
import { Choice } from "@/types";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    draggable,
    GroupEdit,
    CommonMasterList
  }
})
export default class GroupList extends Mixins(AxiosMixin, UtilMixin) {
  @Ref("dialog") private readonly dialog!: GroupEdit;

  /** コロナワクチン接種状況選択肢 */
  private covid19VaccineStatuses: Choice[] = [
    { text: "表示しない", value: 0 },
    { text: "表示する", value: 1 }
  ];

  //グループヘッダー
  private headers: DataTableHeader[] = [
    {
      text: "",
      value: "icon_path",
      width: "20px",
      cellClass: "icon-cell",
      sortable: false
    },
    {
      text: "グループ名",
      value: "name",
      width: "200px"
    },
    {
      text: "",
      value: "description",
      width: "150px",
      sortable: false
    }
  ];

  private groups: groupCommon.Group[] = []; // グループ情報

  public created(): void {
    this.fetchGroups();
  }

  // グループ情報取得
  private fetchGroups(): void {
    this.postJsonCheck(window.base_url + "/api/master/groups/get", {}, res => {
      this.groups = res.data.groups;
    });
  }

  // ソート番号更新処理
  private saveOrder() {
    this.postJsonCheck(
      window.base_url + "/api/master/groups/order/save",
      {
        groups: this.groups
      },
      () => {
        this.fetchGroups();
      }
    );
  }

  //グループ削除
  private deleteItem(group: groupCommon.Group): void {
    this.postJsonCheck(
      window.base_url + "/api/master/group/delete",
      { group: group },
      () => {
        this.fetchGroups();
      }
    );
  }

  // グループ新規作成
  private clickInsert(): void {
    this.dialog.openNew();
  }

  // グループ編集
  private editItem(group: groupCommon.Group): void {
    this.dialog.openEdit(group.id);
  }

  /** コロナワクチン接種状況表示設定保存 */
  private saveAgreementUserSetting() {
    this.postJsonCheck(
      window.base_url + "/api/master/group/covid19setting/save",
      { agreement_user_setting: this.agreementUserSetting },
      () => {
        //
      }
    );
  }
}
