import Base from "../base";

// シフトテンプレート
export interface ShiftTemplate extends Base {
  id: number; //'シフトテンプレートID',
  office_id: number; //'事業所ID',
  staff_id: number; //'職員ID\n0:事業所のシフトテンプレート',
  shift_name: string; //'勤務時間帯名',
  shift_start_time_hour: number; //'シフト開始時',
  shift_start_time_minute: number; //'シフト開始分',
  shift_end_time_hour: number; //'シフト終了時',
  shift_end_time_minute: number; //'シフト終了分',
  color_id: number; //'色ID',
  target_date: string;
}
export const DefaultShiftTemplate = (): ShiftTemplate => ({
  id: 0, //'シフトテンプレートID',
  office_id: 0, //'事業所ID',
  staff_id: 0, //'職員ID\n0:事業所のシフトテンプレート',
  shift_name: "", //'勤務時間帯名',
  shift_start_time_hour: 0, //'シフト開始時',
  shift_start_time_minute: 0, //'シフト開始分',
  shift_end_time_hour: 0, //'シフト終了時',
  shift_end_time_minute: 0, //'シフト終了分',
  color_id: 0, //'色ID',
  created_at: "",
  updated_at: "",
  deleted_at: "",
  target_date: ""
});

// 職員シフト
export interface StaffShift extends Base {
  id: number; //'シフトテンプレートID',
  staff_id: number; //'職員ID',
  target_date: string; // 対象日
  shift_name: string; //'勤務時間帯名',
  shift_start_time_hour: number; //'シフト開始時',
  shift_start_time_minute: number; //'シフト開始分',
  shift_end_time_hour: number; //'シフト終了時',
  shift_end_time_minute: number; //'シフト終了分',
  color_id: number; //'色ID',
}

// 訪問予定職員
export interface VisitScheduleStaffs extends Base {
  id: number; //'訪問予定職員ID',
  visit_schedule_id: number; //'訪問予定ID',
  staff_id: number; //'訪問予定職員ID',
  staff_div: number; //'職員区分\n1:主訪問者\n2:副訪問者1\n3:副訪問者2',
}
export const DefaultVisitScheduleStaffs = (): VisitScheduleStaffs[] => {
  const staffs: VisitScheduleStaffs[] = [];
  for (let i = 1; i <= 3; i++) {
    staffs.push({
      id: 0,
      visit_schedule_id: 0,
      staff_id: 0,
      staff_div: i,
      created_at: "",
      updated_at: "",
      deleted_at: ""
    });
  }
  return staffs;
};

// 訪問予定
export interface VisitSchdule extends Base {
  id: number; // 訪問予定ID
  patient_id: number; //利用者ID
  rule_id: number; //シフトルールID
  visit_start_date: string; //訪問開始日
  visit_end_date: string; //訪問終了日
  shift_start_time_hour: number; //訪問開始時間（時）
  shift_start_time_minute: number; //訪問開始時間（分）
  shift_end_time_hour: number; //訪問終了時間（時）
  shift_end_time_minute: number; //訪問終了時間（分）
  all_day_flg: number; // 終日フラグ
  rule?: Rule; // ルール
  visit_schedule_staffs: VisitScheduleStaffs[];
  event_type: number; // イベント種類（0:訪問予定 1:利用者個別予定）
  event_title?: string; // 1:利用者個別予定のみタイトル必要
  content?: string; // 1:利用者個別予定のみ内容必要
  visit_memo: string; // 訪問メモ
  memo_display: number; // 訪問メモ
  is_cancel: number; //キャンセルフラグ
}
export const DefaultVsistSchedule = (): VisitSchdule => ({
  id: 0,
  patient_id: 0,
  rule_id: 0,
  visit_start_date: "",
  visit_end_date: "",
  shift_start_time_hour: 0,
  shift_start_time_minute: 0,
  shift_end_time_hour: 0,
  shift_end_time_minute: 0,
  all_day_flg: 0,
  visit_schedule_staffs: DefaultVisitScheduleStaffs(),
  created_at: "",
  updated_at: "",
  deleted_at: "",
  event_type: 0,
  visit_memo: "", // 訪問メモ
  memo_display: 0,
  is_cancel: 0
});

// ルール
export interface Rule extends Base {
  id: number; //ルールID
  patient_id: number; //利用者ID
  repeat_state: number; //'繰り返し状態',
  visit_date: string; //'日にち',
  visit_day: number; //'日付',
  visit_week_weeks: string; //'週（X週目）',
  visit_week_times: string; //'週（X回目）',
  visit_week: string; //'曜日',
  shift_start_time_hour: number; //'開始時間（時）',
  shift_start_time_minute: number; //'開始時間（分）',
  shift_end_time_hour: number; //'終了時間（時）',
  shift_end_time_minute: number; //'終了時間（分）',
  all_day_flg: number; //'終日フラグ',
  visit_interval_week: number; //'間隔（週）',
  visit_interval_month: number; //'間隔（月）',
  visit_limit: number; //'期限',
  visit_limit_start_date: string; //'期限開始日',
  visit_limit_end_date: string; //'期限終了日',
  visit_limit_count: number; //'期限回数',
  main_staff_id: number; //'主訪問者ID',
  sub_staff1_id: number; //'副訪問者ID1',
  sub_staff2_id: number; //'副訪問者ID2',
}
export const DefaultRule = (): Rule => ({
  id: 0,
  patient_id: 0,
  repeat_state: 1, //'繰り返し状態',
  visit_date: "", //'日付',
  visit_day: 0, //'回数',
  visit_week_weeks: "000000", //'週（X週目）',
  visit_week_times: "00000", //'週（X回目）',
  visit_week: "0000000", //'曜日',
  shift_start_time_hour: 0, //'開始時間（時）',
  shift_start_time_minute: 0, //'開始時間（分）',
  shift_end_time_hour: 0, //'終了時間（時）',
  shift_end_time_minute: 0, //'終了時間（分）',
  all_day_flg: 0, //'終日フラグ',
  visit_interval_week: 0, //'間隔（週）',
  visit_interval_month: 0, //'間隔（月）',
  visit_limit: 0, //'期限',
  visit_limit_start_date: "", //'期限開始日',
  visit_limit_end_date: "", //'期限終了日',
  visit_limit_count: 0, //'期限回数',
  main_staff_id: 0, //'主訪問者ID',
  sub_staff1_id: 0, //'副訪問者ID1',
  sub_staff2_id: 0, //'副訪問者ID2',
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
