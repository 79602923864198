// 契約

/** 外部システム連携設定 利用者情報 */
export const SETTING_ID_PATIENT = 1;
/** 外部システム連携設定 職員情報 */
export const SETTING_ID_STAFF = 2;
/** 外部システム連携設定 医療機関情報 */
export const SETTING_ID_MEDICAL_INSTITUTION = 3;
/** 外部システム連携設定 医師情報 */
export const SETTING_ID_DOCTOR = 4;
/** 外部システム連携設定 関連機関情報 */
export const SETTING_ID_RELATION_INSTITUTION = 5;
/** 外部システム連携設定 関連機関担当者情報 */
export const SETTING_ID_PERSONEL = 6;

/** 処理区分 新規登録*/
export const FUNCTION_DIV_INSERT = 1;
/** 処理区分 削除*/
export const FUNCTION_DIV_DELETE = 2;

/** 機能 非表示 */
export const DISP_NON = 0;
/** 機能 表示 */
export const DISP_SHOW = 1;
