






import { Component, Vue } from "vue-property-decorator";
import MedicalForm from "@/components/master/medical/MedicalForm.vue";
@Component({
  components: { MedicalForm }
})
export default class MedicalEdit extends Vue {
  private medicalId = -1;

  created() {
    if (this.$route.params.id) {
      this.medicalId = Number(this.$route.params.id);
    }
  }
}
