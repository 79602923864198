import AppChatStaffListItem from "./AppChatStaffListItem.vue";

export default {
  title: "Example/AppChatStaffListItem",
  component: AppChatStaffListItem,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatStaffListItem },
  template: '<AppChatStaffListItem v-bind="$props" />'
});

export const HasIcon = Template.bind({});
export const NoIcon = Template.bind({});

NoIcon.args = {
  staffName: "てすとたろう",
  iBowName: "上田 次郎"
};
HasIcon.args = {
  staffName: "てすとたろう",
  iBowName: "上田 次郎",
  iconPath:
    "https://4.bp.blogspot.com/-7DLdBODmEqc/VCIitQRzAWI/AAAAAAAAmeY/g1fjm8NqyaI/s800/animal_arupaka.png"
};
