





















































import { Component, Mixins } from "vue-property-decorator";
import AppSelect from "#/components/AppSelect.vue";
import AppTextField from "#/components/AppTextField.vue";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Staff } from "#/model/staff";
import { Patient } from "#/model/patient";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";

interface SearchUser {
  id: number;
  name: string;
  birthday: string;
  disp_id: string;
  deleted_at: string;
  patient: Patient;
  staff: Staff;
}

@Component({
  components: { AppSelect, AppTextField, TextDayChangePicker, OfficeSelect }
})
export default class RestoreMasterPatient extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  private user = "利用者"; //利用者/職員
  private officeId = 0; //事業所
  // private from = ""; //期間from
  // private to = ""; //期間to
  private text = ""; //名前、ID検索テキスト

  //検索ユーザー
  private searchUsers: SearchUser[] = [];

  //検索ユーザーヘッダー
  private searchUserHeaders: DataTableHeader[] = [
    {
      text: "名前",
      value: "name",
      width: "170px"
    },
    {
      text: "生年月日",
      value: "birthday",
      width: "150px",
      sort: SortWarekiDate
    },
    {
      text: "ID",
      value: "disp_id",
      width: "100px"
    },
    {
      text: "削除日",
      value: "deleted_at",
      width: "140px"
    },
    {
      text: "削除職員",
      value: "delete_staff_name",
      width: "140px"
    },
    {
      text: "復元",
      value: "restore",
      align: "center",
      width: "80px",
      sortable: false
    }
  ];

  private created() {
    this.officeId = this.loginUser.office_id;
  }

  //検索
  private search() {
    if (this.user == "利用者") {
      this.postJsonCheck(
        window.base_url + "/api/master/restore/patient/search",
        {
          office_id: this.officeId,
          text: this.text
        },
        res => {
          this.searchUsers = res.data.search_users;
        }
      );
    }
    if (this.user == "職員") {
      this.postJsonCheck(
        window.base_url + "/api/master/restore/officestaff/search",
        {
          office_id: this.officeId,
          text: this.text
        },
        res => {
          this.searchUsers = res.data.search_users;
        }
      );
    }
  }

  //復元
  private async restore(searchUser: SearchUser) {
    if (
      await this.$openConfirm(searchUser.name + "様を復元しますが宜しいですか?")
    ) {
      if (this.user == "利用者") {
        this.postJsonCheck(
          window.base_url + "/api/master/restore/patient/restore",
          {
            search_user: searchUser
          },
          () => {
            this.search();
          }
        );
      }
      if (this.user == "職員") {
        this.postJsonCheck(
          window.base_url + "/api/master/restore/officestaff/restore",
          {
            search_user: searchUser
          },
          () => {
            this.search();
          }
        );
      }
    }
  }
}
