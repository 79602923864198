
























































































//===========================================================================================
// オンライン資格確認取込
//===========================================================================================
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { DataTableHeader } from "vuetify/types/index";
import { OnlineCapDisplayData } from "@/views/patient/onlinecap/types";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import TableHeader from "@/components/patient/onlinecap/TableHeader.vue";
import InsuranceDialog from "@/components/patient/onlinecap/InsuranceDialog.vue";

@Component({
  components: {
    OfficeSelect,
    TableHeader,
    InsuranceDialog,
  },
})
export default class OnlineCapabilities extends Mixins(AxiosMixin, UtilMixin) {
  // 保険情報ダイアログ
  @Ref("insurance-dialog") private readonly insuranceDialog!: InsuranceDialog;
  // Table Header
  private get headers(): DataTableHeader[] {
    let dateText: string;
    let dateValue: string;
    // 取り込み前・取り込み済みで表示する日時データを切り替え
    if (this.imported) {
      // 取り込み済み
      dateText = "取込日時";
      dateValue = "import_date";
    } else {
      // 取り込み前
      dateText = "表示日時";
      dateValue = "create_date";
    }

    return [
      { text: dateText, value: dateValue, width: "150px" },
      {
        text: "iBow利用者",
        value: "patient_ibow",
        width: "100px",
        align: "center",
      },
      { text: "被保険者名", value: "insured_name", width: "180px" },
      { text: "生年月日", value: "birth", width: "150px" },
      { text: "保険種類", value: "insure_kind", width: "100px" },
      { text: "保険者番号", value: "insurer_number", width: "120px" },
      { text: "被保険者番号", value: "insured_number", width: "158px" },
      { text: "開始日", value: "start_date", width: "150px" },
      { text: "終了日", value: "end_date", width: "150px" },
      { text: "高齢受給者証", value: "elderly_info", width: "100px" },
      { text: "高額療養費", value: "lmit_info", width: "100px" },
      { text: "公費", value: "has_public_money", width: "100px" },
    ];
  }
  // 画面表示データ
  private items: OnlineCapDisplayData[] = [];
  //選択データ
  private selectItems: OnlineCapDisplayData[] = [];
  //検索keyword
  private keyword = "";
  //取込済み
  private imported = 0;
  /** 1ページ当たりの表示件数 */
  private itemsPerPage = 25;
  /** ページ番号 */
  private reportsPageNo = 1;
  /** 選択事業所(本社職員・事業所未所属の場合)  */
  private selectOfficeId = 0;
  /** テーブルフッター */
  private tablePagerOption = {
    "items-per-page-text": "1ページあたりの件数:",
    "page-text": "{0} - {1} 件 / 計 {2} 件",
    "items-per-page-all-text": "全て",
  };
  /** 並び替えに使用する項目 */
  private sortBy: string[] = [];
  /** 並び替え昇順/降順 */
  private sortDesc: boolean[] = [];

  // 画面表示データが更新された場合、並び替え情報を初期化する
  @Watch("items")
  private watchItems() {
    // 並び替え初期値（取込日時or表示日時の降順）
    this.sortBy = [this.imported ? "import_date" : "create_date"];
    this.sortDesc = [true];
  }

  mounted() {
    if (this.masters.group_only_offices.length == 2) {
      this.selectOfficeId = this.masters.group_only_offices[1].value as number;
    }
    this.getListData();
  }

  /** 事業所選択変更 */
  private changeOffice() {
    this.getListData();
  }

  /** 作成前・作成後タブ切り替え */
  private changeImported() {
    this.getListData();
  }

  /** ページング */
  private changePage(newPage: number) {
    this.reportsPageNo = newPage;
  }

  /** オンライン資格確認リストデータ取得 */
  private getListData() {
    if (this.selectOfficeId == 0) {
      //事業所未選択状態はなにもしない
      this.items = [];
      this.selectItems = [];
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/patient/onlinecap/list",
      {
        office_id: this.selectOfficeId,
        imported: this.imported,
      },
      (res) => {
        this.items = res.data.online_caps;
        this.selectItems = [];
      }
    );
  }

  /** 保険情報ダイアログの表示 */
  private openInsuranceDialog(patientId: number) {
    this.insuranceDialog.open(patientId);
  }
}
