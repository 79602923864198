



























import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class extends Mixins(UtilMixin) {
  /** ダイアログの開閉状態 */
  @Prop({ type: Boolean, default: false }) private value!: boolean;

  /** 取り込み結果 */
  @Prop({ type: String, default: "" }) private impResult!: string;

  /** 取り込み日時 */
  @Prop({ type: String, default: "" }) private impDate!: string;

  /** ダイアログの開閉状態の変更通知 */
  @Emit() private input(value: boolean) {
    return value;
  }

  /** ダイアログの開閉状態 */
  private get Value() {
    return this.value;
  }
  private set Value(value: boolean) {
    this.input(value);
  }

  /** 結果ダウンロードボタンクリック */
  private downloadResult() {
    const blob = new Blob([this.impResult]);
    const a = document.createElement("a");
    const dt = this.dateToStr(new Date(), "yyyyMMddHHmmss");
    a.download = `保険情報取込結果_${dt}.txt`;
    a.href = URL.createObjectURL(blob);
    a.click();
  }
}
