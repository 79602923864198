
















































































import { Component, Watch } from "vue-property-decorator";
import OneMonthAdd from "./OneMonthAdd.vue";

@Component
export default class OneMonthAddV2 extends OneMonthAdd {
  created(): void {
    this.setNursingSpecialDiv();
    this.setCareKyokaFlg();
  }

  //看護体制強化加算
  protected setCareKyokaFlg() {
    if (this.receiptVisitRecord.care_kyoka_flg == 1) {
      this.care_kyoka_flg1 = true;
    }
    if (this.receiptVisitRecord.care_kyoka_flg == 2) {
      this.care_kyoka_flg2 = true;
    }
  }

  /** 看護体制強化加算Ⅰ */
  protected care_kyoka_flg1 = false;

  /** 看護体制強化加算Ⅱ */
  protected care_kyoka_flg2 = false;

  //看護体制強化加算Ⅰ
  @Watch("care_kyoka_flg1")
  protected handleCareKyokaFlg1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.care_kyoka_flg1 == true) {
      this.receiptVisitRecord.care_kyoka_flg = 1;
    }
    if (this.care_kyoka_flg1 == false) {
      this.receiptVisitRecord.care_kyoka_flg = 0;
    }
  }
  //看護体制強化加算Ⅱ
  @Watch("care_kyoka_flg2")
  protected handleCareKyokaFlg2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.care_kyoka_flg2 == true) {
      this.receiptVisitRecord.care_kyoka_flg = 2;
    }
    if (this.care_kyoka_flg2 == false) {
      this.receiptVisitRecord.care_kyoka_flg = 0;
    }
  }

  //看護体制強化加算I
  protected disableCheckCareKyokaFlg1(): boolean {
    let result = false;
    if (
      this.officeHistory.care_kyoka_add == 0 ||
      this.officeHistory.care_kyoka_add == 1 ||
      this.officeHistory.care_kyoka_add == 2 ||
      this.officeHistory.care_kyoka_add == 3 ||
      (this.officeHistory.care_kyoka_add == 4 &&
        this.event.visit_record.care_div == 2) ||
      (this.officeHistory.care_kyoka_add == 5 &&
        this.event.visit_record.care_div == 2) ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //看護体制強化加算II
  protected disableCheckCareKyokaFlg2(): boolean {
    let result = false;
    if (
      this.officeHistory.care_kyoka_add == 0 ||
      (this.officeHistory.care_kyoka_add == 2 &&
        this.event.visit_record.care_div == 1) ||
      (this.officeHistory.care_kyoka_add == 3 &&
        this.event.visit_record.care_div == 2) ||
      this.officeHistory.care_kyoka_add == 4 ||
      (this.officeHistory.care_kyoka_add == 5 &&
        this.event.visit_record.care_div == 1) ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV2() {
    //緊急時訪問看護
    if (this.disableCheckNursingUrgentFlg()) {
      this.receiptVisitRecord.nursing_urgent_flg = 0;
    }
    //特別管理
    if (this.disableCheckNursingSpecialDiv()) {
      this.nursing_special_div1 = false;
      this.nursing_special_div2 = false;
      this.receiptVisitRecord.nursing_special_div = 0;
    }
    //ターミナルケア
    if (this.disableCheckNursingTerminalFlg()) {
      this.receiptVisitRecord.nursing_terminal_flg = 0;
    }
    //中山間部
    if (this.disableCheckMountainFlg()) {
      this.receiptVisitRecord.mountain_flg = 0;
    }
    //連携強化
    if (this.disableCheckCoopStrengtheningFlg()) {
      this.receiptVisitRecord.coop_strengthening_flg = 0;
    }
    //連携強化日
    if (this.disableCheckCoopStrengtheningDate()) {
      this.receiptVisitRecord.coop_strengthening_date = "";
    }
    //看護体制強化加算I
    if (this.disableCheckCareKyokaFlg1() && !this.care_kyoka_flg2) {
      this.care_kyoka_flg1 = false;
      this.receiptVisitRecord.care_kyoka_flg = 0;
    }
    //看護体制強化加算II
    if (this.disableCheckCareKyokaFlg2() && !this.care_kyoka_flg1) {
      this.care_kyoka_flg2 = false;
      this.receiptVisitRecord.care_kyoka_flg = 0;
    }
  }
}
