



































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import { FileIF, DEFAULT_FILEIF } from "@/views/master/batch_staff_link/types";

@Component({
  components: { FileUpload }
})
/**
 * 職員一括連携 初期画面
 */
export default class BatchStaffLinkStart extends Mixins(UtilMixin) {
  /** 選択中ファイル情報 */
  @Prop({ default: { ...DEFAULT_FILEIF } }) inputFile!: FileIF;

  /** ファイルアップロード用API */
  private uploadApiUrl = `${window.base_url}/api/upload`;

  /** アップロードファイル保存先パス */
  private get ImagePath(): string {
    return "staff/link";
  }

  /** 取込ボタンクリック時 */
  private async onClickStart() {
    if (this.inputFile.path == "") {
      await this.$openAlert("Excelファイルを指定してください。");
      return;
    }

    this.start();
  }

  /** 取込ボタンクリックイベント通知 */
  @Emit("click:start")
  start() {
    return;
  }

  /** 職員情報出力イベント通知 */
  @Emit("click:output")
  output() {
    return;
  }
}
