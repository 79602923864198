







































































import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import { Choice } from "@/types";
import FileUpload from "#/components/FileUpload.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { DispRelationInstitutionInfoCoordUpload } from "@/views/master/info_coord/types";
import { DataTableHeader } from "vuetify";

interface FileIF {
  id: number;
  path: string;
  name: string;
  size: number;
}

@Component({
  components: { OfficeSelect, FileUpload }
})
export default class InfoCoordInput extends Mixins(UtilMixin, RulesMixin) {
  /** 事業所選択肢情報 */
  @Prop({ default: () => [] }) officeSelects!: Choice[];

  /** 取込ファイル情報 */
  @Prop({ default: () => [] })
  rInstInfoCoordUploads!: DispRelationInstitutionInfoCoordUpload[];

  /** 選択中ファイル情報 */
  private inputFile: FileIF = { id: 0, path: "", name: "", size: 0 };

  /** ファイルアップロード用APIパス */
  private uploadApiUrl = `${window.base_url}/api/upload`;

  /** ファイルパス作成 */
  private get imagePath(): string {
    // [site_name]/[kst_no]/outpdf/info_coord/[事業所ID]/[ファイル名)]
    return `${this.siteName}/kst_no/outpdf/info_coord/0/`;
  }

  /** ヘッダー情報 */
  private headers: DataTableHeader[] = [
    {
      text: "取込日時",
      value: "uploaded_at_wareki",
      width: "180px",
      sortable: false
    },
    {
      text: "取込ファイル種類",
      value: "file_type_name",
      width: "200px",
      sortable: false
    },
    {
      text: "送信元機関",
      value: "from_rinst_name",
      width: "300px",
      sortable: false
    },
    {
      text: "送信先事業所",
      value: "to_office_name",
      width: "300px",
      sortable: false
    },
    {
      text: "ファイル名",
      value: "file_name",
      width: "150px",
      sortable: false
    },
    {
      text: "",
      value: "remove",
      width: "25px",
      sortable: false
    }
  ];

  /** 選択事業所 */
  private selectedOffice: Choice = { text: "", value: 0 };

  /** 取り込みボタンを押した時 */
  private async onSubmit() {
    if (this.inputFile.path == "") {
      await this.$openAlert("ファイルを選択してください。");
      return;
    }
    this.inputCsv();
  }

  /** 削除ボタンを押した時 */
  private async onRemove(item: DispRelationInstitutionInfoCoordUpload) {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }
    this.removeCsv(item);
  }

  @Emit("remove:csv")
  private removeCsv(item: DispRelationInstitutionInfoCoordUpload) {
    return item;
  }

  @Emit("input:csv")
  private inputCsv() {
    return {
      office_id: this.selectedOffice.value,
      file_name: this.inputFile.name,
      file_path: this.inputFile.path
    };
  }

  @Emit("preview:csv")
  private preview(item: DispRelationInstitutionInfoCoordUpload) {
    return item;
  }

  @Watch("officeSelects", { immediate: true })
  private getHeadOffice() {
    if (this.loginUser.office_id > 0) {
      this.selectedOffice = this.officeSelects.filter(
        (officeSelect: Choice) => officeSelect.value == this.loginUser.office_id
      )[0];
      return;
    }

    if (this.officeSelects.length > 0) {
      this.selectedOffice = this.officeSelects[0];
      return;
    }

    this.selectedOffice = { text: "", value: 0, other_string: "" };
  }
}
