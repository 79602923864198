import AppChatRoomList from "./AppChatRoomList.vue";

export default {
  title: "Example/AppChatRoomList",
  component: AppChatRoomList,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatRoomList },
  template: '<AppChatRoomList v-bind="$props" />'
});

export const ChatRoomList = Template.bind({});

ChatRoomList.args = {
  chatRooms: [
    {
      id: "1",
      title: "なかよしぐるーぷ",
      unread_count: 2,
      image_path:
        "https://4.bp.blogspot.com/-7DLdBODmEqc/VCIitQRzAWI/AAAAAAAAmeY/g1fjm8NqyaI/s800/animal_arupaka.png"
    },
    {
      id: "2",
      title: "なかよしぐるーぷ2",
      unread_count: 0
    },
    {
      id: "3",
      title: "なかよしぐるーぷ3",
      unread_count: 0
    },
    {
      id: "4",
      title: "なかよしぐるーぷ4",
      unread_count: 99
    },
    {
      id: "5",
      title: "なかよしぐるーぷ6",
      unread_count: 100
    }
  ]
};
