
































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class AppFileDragAndDrop extends Vue {
  @Prop({ default: false }) isShown!: boolean;

  @Emit()
  private dropFile(event: DragEvent) {
    return event;
  }

  @Emit()
  private close() {
    return;
  }
}
