import { Vue, Component } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { RootState, SET_IS_EDIT } from "@/store/types";
import * as appDate from "#/utility/appDate";
import { cantMoving } from "@/main";

Component.registerHooks(["beforeRouteLeave"]);

@Component
export default class EditMixin extends Vue {
  @State rootState!: RootState;

  @Mutation(SET_IS_EDIT) setIsEdit!: Function;

  /** データ読み込み未完了フラグ */
  private not_loading = true;

  /** 前回のインターバルID */
  private beforeIntervalId = 0;

  /** ストレージのキー */
  private storageKey = "";

  /** 終了フラグ */
  protected is_finish = false;

  /** データ読み込みが完了しているどうか */
  get IsLoaded() {
    return !this.not_loading;
  }

  /** データ読み込みを完了にする */
  setLoaded() {
    this.$nextTick(() => {
      this.not_loading = false;
    });
  }

  setNotLoaded() {
    this.$nextTick(() => {
      this.not_loading = true;
    });
  }

  /** 編集モードにする */
  setEditMode() {
    this.setIsEdit(true);
  }

  /** 編集モードをやめる */
  setNoEditMode() {
    this.setIsEdit(false);
  }

  /**
   * データ読み込み後の処理
   * 編集モードにして、ストレージキーを設定し、復元するかどうかを確認する
   * @param key ストレージキー
   * @param restoreFunc ストレージから取得したデータを復元する関数
   * */
  execAfterFetch(key: string, restoreFunc: (storageData: any) => void) {
    this.setLoaded();
    this.setStorageKey(key);
    this.getLocalStorage(restoreFunc);
  }

  /** ストレージキーを設定する */
  setStorageKey(key: string) {
    this.storageKey = key;
  }

  /** ストレージをリセットする */
  resetStorage(key: string) {
    localStorage.removeItem(this.storageKey);
    this.storageKey = key;
    this.not_loading = true;
    this.setLoaded();
  }

  /** localstorageへの保存 */
  saveLocalStorage(value: object) {
    if (this.IsLoaded && !this.is_finish) {
      this.setEditMode();
      clearTimeout(this.beforeIntervalId);
      this.beforeIntervalId = setTimeout(() => {
        const date = appDate.dayjsDate().format("YYYY-MM-DD HH:mm");
        localStorage.setItem(
          this.storageKey,
          JSON.stringify({
            ...value,
            date: date
          })
        );
      }, 1000);
    }
  }

  /** localstorageからの取得 */
  async getLocalStorage(restoreFunc: (storageData: any) => void) {
    const data = localStorage.getItem(this.storageKey);
    if (data) {
      const storageData = JSON.parse(data);
      if (
        await this.$openConfirm(
          `${storageData.date}に
自動で保存された変更内容があります。
復元して編集を続けますか？

（「破棄する」と自動で保存された変更内容を破棄してから編集します。）`,
          "復元して編集する",
          "破棄する"
        )
      ) {
        restoreFunc(storageData);
      } else {
        localStorage.removeItem(this.storageKey);
      }
    }
  }

  /** コンポーネントを離れるときの処理 */
  async beforeRouteLeave(
    to: unknown,
    from: unknown,
    next: (arg0?: boolean) => void
  ) {
    if (await cantMoving()) {
      next(false);
    } else {
      localStorage.removeItem(this.storageKey);
      next();
    }
  }
}
