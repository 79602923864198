

































































































import { Component, Emit, Vue, Watch, Prop } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import LoginTitle from "@/components/app/login/LoginTitle.vue";

@Component({
  components: { LoginTitle }
})
export default class LoginLink extends Vue {
  /** 個人ID */
  private user_id = "";

  /** パスワード */
  private password = "";

  /** パスワード表示/非表示 */
  private isShow = false;

  /** ローディング */
  private loading = false;

  /** エラーメッセージ */
  private errorMessages = "";

  /** 契約名 */
  private name = "";

  /** 契約ID */
  private agreement_id = 0;

  /** 企業コード */
  private co_code = "";

  /** iBowアカウント作成 */
  private accountMakeUrl =
    window.auth_middleend_url + "/accountform/prov/entry";

  /** 連携情報 */
  @Prop({ default: () => ({}) }) linkInfoPayload!: {
    input_user_id: string;
    password: string;
  };

  @Watch("user_id")
  private watchUserId() {
    if (this.user_id != "") {
      this.errorMessages = "";
    }
  }

  @Watch("password")
  private watchPassword() {
    if (this.password != "") {
      this.errorMessages = "";
    }
  }

  @Watch("linkInfoPayload.input_user_id")
  private watchLinkInfo() {
    this.user_id = this.linkInfoPayload.input_user_id;
    this.password = this.linkInfoPayload.password;
  }

  created() {
    const reg = location.pathname.match(/\/login-link\/(.+)/);
    if (reg) {
      this.co_code = reg[1];
    }
    if (!this.co_code) {
      location.href = "/";
    }
    this.loading = true;
    window.axios
      .post(window.base_url + "/get-login-info", {
        co_code: this.co_code
      })
      .then((res: AxiosResponse) => {
        this.loading = false;
        this.name = res.data.agreement_name;
        this.agreement_id = res.data.agreement_id;
      })
      .catch((error: AxiosError) => {
        this.loading = false;
        console.log(error);
        location.href = "/";
      });
  }

  /** 認証ボタンクリック */
  private clickAuth() {
    this.loading = true;
    window.axios
      .post(window.auth_backend_url + "/auth/ibow-login", {
        agreement_id: this.agreement_id,
        user_id: this.user_id,
        password: this.password
      })
      .then((res: AxiosResponse) => {
        this.loading = false;
        this.toConnectView({
          ...res.data,
          input_user_id: this.user_id,
          password: this.password
        });
      })
      .catch(async (error: AxiosError) => {
        this.loading = false;
        if (error.response && error.response.status == 401) {
          this.password = "";
          this.errorMessages = error.response.data.message;
        } else {
          await this.$openAlert(
            "サーバでエラーが発生しました。\n(" + error + ")"
          );
          console.log(error);
          console.log(error.response);
        }
      });
  }

  /** マニュアルを開く */
  private openManual() {
    window.open(
      "https://ewellibow.jp/manual/faq/section/account-renkei.pdf",
      "_blank"
    );
  }

  /** iBowアカウント作成を開く */
  private openCreateIbowAccount() {
    window.open(this.accountMakeUrl, "_blank");
  }

  @Emit()
  private toConnectView(payload: unknown) {
    return payload;
  }
}
