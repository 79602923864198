import Base from "../base";

// 利用者状況
export interface PatientSituation extends Base {
  id: number; //利用者状況ID
  patient_id: number; //利用者ID
  situation: number; //タイトル
  content: string; //メモ
  target_date: string; //対象日
  target_time_hour: string; //対象時刻（時）
  target_time_minute: string; //対象時刻(分)
  service_situation: number; //サービス状況
  end_reason: number; //終了理由
  end_reason_other_comment: string; //終了理由 その他
  after_destination: string; //終了理由 利用後行先
  stay_doctor_id: number; //入院先医師ID
  place: number; //場所
  place_facility_div: number; //施設コード
  place_other: string; //場所 その他
  place_ditail: string; //場所 詳細
  docter_id: number; //医師ID
  display_date_time: string; //表示用日時
}

export const DefaultPatientSituation = (): PatientSituation => ({
  id: 0,
  patient_id: 0,
  situation: 0,
  content: "",
  target_date: "",
  target_time_hour: "",
  target_time_minute: "",
  service_situation: 0,
  end_reason: 0,
  end_reason_other_comment: "",
  after_destination: "",
  stay_doctor_id: 0,
  place: 0,
  place_facility_div: 0,
  place_other: "",
  place_ditail: "",
  docter_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  display_date_time: ""
});
