
































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

import CorpList from "@/components/master/corp/CorpList.vue";
import UtilMixin from "@/mixins/utilMixin";
import * as constant from "#/const";

@Component({
  components: {
    CorpList
  }
})
export default class CorpEntry extends Mixins(AxiosMixin, UtilMixin) {
  private search = "";

  // 新規登録ボタン表示フラグ
  private get showNew(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_RELATION_INSTITUTION,
      constant.AGREEMENT.FUNCTION_DIV_INSERT
    );
  }

  public mounted(): void {
    this.fetchCorpList();
  }

  private relationInstitutionsList = [];

  private fetchCorpList(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/relationinstitutions/get",
      {},
      res => {
        this.relationInstitutionsList = res.data.relation_institutions;
      }
    );
  }
}
