import {
  CareContent,
  Observation,
  Bedsore,
  Pdf,
  Remand,
  Vital,
  RiskFactor,
  DefaultPdf,
  DefaultRemand
} from "#/model/report";

//看護サマリ一覧
export interface CareSummaryListItem {
  id: number; //看護サマリID
  editor_staff_name: string; //編集者名
  status: number; //記事状態
  updated_at_wareki: string; //更新日時
  visit_date_wareki: string; //訪問日
}

//担当者
export interface CaresummaryStaff {
  id: number; //ID
  care_summary_id: number; //'看護サマリID',
  staff_id: number; //'職員ID',
}

/** 差戻し */
export interface CaresummaryRemand extends Remand {
  care_summary_id: number; //看護サマリID
}
export const DefaultCaresummaryRemand = (): CaresummaryRemand => ({
  ...DefaultRemand(),
  care_summary_id: 0
});

//看護サマリ
export interface CareSummaryModel extends CareSummary {
  visit_start_date: string;
  visit_start_time_hh: number;
  visit_start_time_mm: number;
  visit_end_date: string;
  visit_end_time_hh: number;
  visit_end_time_mm: number;
}

//引き当て用傷病・既往歴・病状
export interface DiseaseStatus {
  disease1: string;
  disease2: string;
  disease3: string;
  prevhistory: string;
  treat: string;
}

//引き当て用訪問看護経過
export interface NurseProgress {
  condrep: string;
  relation: string;
}

export const DefaultCareSummaryModel = (): CareSummaryModel => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  submit_datetime: "",
  disease1: "",
  disease2: "",
  disease3: "",
  prevhistory: "",
  treat: "",
  condrep: "",
  relation: "",
  visit_date: "",
  visit_start_datetime: "",
  visit_end_datetime: "",
  condition_div: 0,
  condition: "",
  care_content: "",
  kango_mondai_etc: "",
  destination: 0,
  doctor_id: 0,
  personnel_id: 0,
  other_destination: "",
  staff_title: 0,
  updated_at: "",
  bedsores: [],
  care_contents: [],
  observations: [],
  pdf: DefaultPdf(),
  remand: DefaultCaresummaryRemand(),
  risk_factor: {
    id: 0,
    basic_action1: 0,
    basic_action2: 0,
    bone: 0,
    joint: 0,
    nutrition: 0,
    skin: 0,
    edema: 0,
    skin_tear: 0,
    jokuso_valuation: "",
    copy_recent_evaluation_risk_factor: 0
  },
  staffs: [],
  vital: {
    id: 0,
    vital_count: 1,
    body_temprature: 0,
    body_temprature_int: 99,
    body_temprature_dec: 99,
    breath: "",
    breath_div: 0,
    pulse: "",
    pulse_div: 0,
    bp_high_right: "",
    bp_low_right: "",
    bp_high_left: "",
    bp_low_left: "",
    spo2: "",
    spo2_amount: "",
    loc_div: 0,
    blood_sugar: "",
    blood_sugar_timing: 0,
    weight: "",
    abd_girth_navel: "",
    abd_girth: "",
    tan_div: 0,
    lung_noise_div: 0,
    daily_life_rate_div: 0
  },
  visit_start_date: "",
  visit_start_time_hh: 0,
  visit_start_time_mm: 0,
  visit_end_date: "",
  visit_end_time_hh: 0,
  visit_end_time_mm: 0,
  write_date: ""
});

export const DefaultVital = (): Vital => ({
  id: 0,
  vital_count: 1,
  body_temprature: 0,
  body_temprature_int: 99,
  body_temprature_dec: 99,
  breath: "",
  breath_div: 0,
  pulse: "",
  pulse_div: 0,
  bp_high_right: "",
  bp_low_right: "",
  bp_high_left: "",
  bp_low_left: "",
  spo2: "",
  spo2_amount: "",
  loc_div: 0,
  blood_sugar: "",
  blood_sugar_timing: 0,
  weight: "",
  abd_girth_navel: "",
  abd_girth: "",
  tan_div: 0,
  lung_noise_div: 0,
  daily_life_rate_div: 0
});

export const DefaultDiseaseStatus = (): DiseaseStatus => ({
  disease1: "",
  disease2: "",
  disease3: "",
  prevhistory: "",
  treat: ""
});

export const DefaultNurseProgress = (): NurseProgress => ({
  condrep: "",
  relation: ""
});

//看護サマリ(純粋)
export interface CareSummary {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  disease1: string; //'主傷病名1',
  disease2: string; //'主傷病名2',
  disease3: string; //'主傷病名3',
  prevhistory: string; //'既往歴及び現業歴',
  treat: string; //'病状・治療状態',
  condrep: string; //'症状',
  relation: string; //'家族等との関係',
  visit_date: string; //'訪問日',
  visit_start_datetime: string; //'訪問開始日時',
  visit_end_datetime: string; //'訪問終了日時',
  condition_div: number; //'症状\n1:著しい変化はなく安定\n2:特記すべき症状変化がある',
  condition: string; //'症状報告内容',
  care_content: string; //'看護内容記載',
  kango_mondai_etc: string; //'看護上の問題点、継続看護内容など',
  destination: number; //'宛先区分\n1:医療機関\n2:居宅介護支援事業\n3:その他\n4:なし',
  doctor_id: number; //'宛先:医師ID',
  personnel_id: number; //'宛先:担当者ID',
  other_destination: string; //'宛先:その他宛先',
  staff_title: number; //'宛先:その他宛先:敬称区分\n1:御中\n2:様\n3:敬称なし',
  updated_at: string; //更新日時
  bedsores: Bedsore[]; //褥瘡
  care_contents: CareContent[]; //看護内容
  observations: Observation[]; //観察項目
  pdf: Pdf; //PDF
  remand: CaresummaryRemand; //差し戻し
  risk_factor: RiskFactor; //危険因子
  staffs: CaresummaryStaff[]; //職員
  vital: Vital; //バイタル
  write_date: string; //記載日
}
