




import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class NavigationLayerCheckbox extends Vue {
  /** レイヤー情報 */
  @Prop() layer!: { id: number; name: string; color: string; checked: boolean };

  /** チェック状態 */
  @Prop() value!: boolean;

  @Emit() input(value: boolean) {
    return value;
  }

  @Emit() change() {
    return;
  }

  private get Value(): boolean {
    return this.value;
  }

  private set Value(newVal: boolean) {
    this.input(newVal);
    this.change();
  }
}
