



































































import { Component, Mixins } from "vue-property-decorator";
import EditPrimaryContentDialog from "@/components/master/adlitem/EditPrimaryContentDialog.vue";
import EditSecondaryContentDialog from "@/components/master/adlitem/EditSecondaryContentDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  AdlEvalItemPrimaryContent,
  AdlEvalItemSecondaryContent
} from "./types";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import { DataTableHeader } from "vuetify";

@Component({
  components: {
    CommonMasterList,
    EditPrimaryContentDialog,
    EditSecondaryContentDialog
  }
})
export default class AdlItemList extends Mixins(AxiosMixin) {
  private isShownEditPrimaryContentDialog = false;
  private isShownEditSecondaryContentDialog = false;
  private selectedPrimaryContent?: AdlEvalItemPrimaryContent = undefined;
  private selectedSecondaryContent?: AdlEvalItemSecondaryContent = undefined;
  private primaryContents: AdlEvalItemPrimaryContent[] = [];

  private headers: DataTableHeader[] = [
    {
      text: "ADL評価項目",
      value: "name",
      class: "edl-entry-cell"
    },
    {
      text: "利用事業所",
      value: "office_names",
      class: "edl-entry-cell",
      width: "50%"
    },
    {
      text: "",
      value: "selects",
      width: "1px",
      sortable: false
    }
  ];

  private subHeaders: DataTableHeader[] = [
    {
      text: "選択肢",
      value: "name"
    }
  ];

  public created(): void {
    this.fetchAdlEvalItems();
  }

  private fetchAdlEvalItems() {
    this.postJsonCheck(
      window.base_url + "/api/master/adl_eval_entry/get",
      {},
      res => {
        this.primaryContents = res.data.adl_evaluation_items;
      }
    );
  }

  private clickToSortModeButton() {
    this.postJsonCheck(
      window.base_url + "/api/master/adl_eval_entry/save_order",
      {
        adl_evaluation_items: this.primaryContents
      },
      () => {
        this.fetchAdlEvalItems();
      }
    );
  }

  /** ADL評価項目保存 */
  private async save(content: AdlEvalItemPrimaryContent) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/master/adl_eval_entry/save",
      {
        adl_evaluation_item: content
      },
      () => {
        this.fetchAdlEvalItems();
        this.clickCloseEditPrimaryContentDialogButton();
        this.clickCloseEditSecondaryContentDialogButton();
      }
    );
  }

  /** ADL評価項目選択肢保存 */
  private async saveSecondaryContent(
    content: AdlEvalItemSecondaryContent
  ): Promise<void> {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    const index = this.primaryContents.findIndex(
      p => p.id == content.adl_evaluation_item_id
    );
    if (index > -1) {
      if (content.id) {
        const updItemIndex = this.primaryContents[index].selects.findIndex(
          item => item.id == content.id
        );
        if (updItemIndex > -1) {
          this.primaryContents[index].selects[updItemIndex].name = content.name;
        }
      } else {
        if (!this.primaryContents[index].selects) {
          this.primaryContents[index].selects = [];
        }
        this.primaryContents[index].selects.push(content);
      }
      this.postJsonCheck(
        window.base_url + "/api/master/adl_eval_select/save",
        {
          adl_evaluation_item: this.primaryContents[index],
          adl_evaluation_select_item: content
        },
        () => {
          this.fetchAdlEvalItems();
          this.clickCloseEditPrimaryContentDialogButton();
          this.clickCloseEditSecondaryContentDialogButton();
        }
      );
    }
  }

  /** ADL評価項目削除 */
  private deletePrimaryContent(content: AdlEvalItemPrimaryContent): void {
    this.postJsonCheck(
      window.base_url + "/api/master/adl_eval_entry/delete",
      {
        adl_evaluation_item: content
      },
      () => {
        this.fetchAdlEvalItems();
        this.clickCloseEditPrimaryContentDialogButton();
      }
    );
  }

  /** ADL評価項目選択肢削除 */
  private deleteSecondaryContent(content: AdlEvalItemSecondaryContent): void {
    const index = this.primaryContents.findIndex(
      item => item.id == content.adl_evaluation_item_id
    );
    if (index > -1) {
      this.primaryContents[index].selects = this.primaryContents[
        index
      ].selects.filter(item => item.id != content.id);
      this.postJsonCheck(
        window.base_url + "/api/master/adl_eval_select/delete",
        {
          adl_evaluation_item: this.primaryContents[index],
          adl_evaluation_select_item: content
        },
        () => {
          this.fetchAdlEvalItems();
          this.clickCloseEditSecondaryContentDialogButton();
        }
      );
    }
  }

  private clickCopyPrimaryContentButton(content: AdlEvalItemPrimaryContent) {
    const copyContent: AdlEvalItemPrimaryContent = {
      id: 0,
      sort_no: 0,
      name: content.name,
      selects: [],
      offices: []
    };

    if (content.offices) {
      content.offices.forEach(office => {
        copyContent.offices.push({
          office_id: office.office_id
        });
      });
    }

    if (content.selects) {
      content.selects.forEach(item => {
        const copyItem = { ...item };
        copyItem.id = 0;
        copyContent.selects.push(copyItem);
      });
    }

    this.selectedPrimaryContent = copyContent;
    this.isShownEditPrimaryContentDialog = true;
  }

  private clickCreateNewPrimaryItemButton() {
    this.selectedPrimaryContent = undefined;
    this.isShownEditPrimaryContentDialog = true;
  }

  private clickCreateNewSecondaryItemButton() {
    this.selectedSecondaryContent = undefined;
    this.isShownEditSecondaryContentDialog = true;
  }

  private clickEditPrimaryContentButton(content: AdlEvalItemPrimaryContent) {
    this.selectedPrimaryContent = content;
    this.isShownEditPrimaryContentDialog = true;
  }

  private clickEditSecondaryButton(content: AdlEvalItemSecondaryContent) {
    this.selectedSecondaryContent = content;
    this.isShownEditSecondaryContentDialog = true;
  }

  private clickCloseEditPrimaryContentDialogButton() {
    this.isShownEditPrimaryContentDialog = false;
  }

  private clickCloseEditSecondaryContentDialogButton() {
    this.isShownEditSecondaryContentDialog = false;
  }
}
