

























































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import DateInput from "#/components/DateInput.vue";
import {
  MedicalInsuranceOldAge,
  DefaultMedicalInsuranceOldAge
} from "#/model/patient";
import { VForm } from "@/types";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";
import { TargetKind } from "@/const/ocr";

const Ocr = namespace(ocr.name);

@Component({
  components: {
    PatientHeader,
    DateInput
  }
})
export default class InsuranceInfoEldCert extends Mixins(
  AxiosMixin,
  RulesMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Getter("OcrState") ocrState!: ocr.OcrState;

  private insEldCertId = 0;
  private patientId = 0;
  private mode = "";
  private isLoaded = false;
  private birthday = "";

  /** 登録する高齢受給者証 */
  private eldCert: MedicalInsuranceOldAge = DefaultMedicalInsuranceOldAge();

  /** 高額療養費自動作成のON・OFF制御 */
  private highExpenseOnOff() {
    let on = 1;
    //2024年2月21日リリースより前に作られた医療保険は、自動作成の対象外
    if (
      this.eldCert.id > 0 &&
      !this.mode &&
      this.IS_MAKE_CHAIN_HIGHEXPENSE_FROM > this.eldCert.created_at
    ) {
      on = 0;
    }
    this.eldCert.is_make_high_expense = on;
  }

  created() {
    this.insEldCertId = Number(this.$route.params.inseldcertid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchInsurerEldCert();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=EldCert`
      },
      {
        text: "高齢受給者証",
        disabled: true,
        to: ""
      }
    ]);
  }

  destroy() {
    this.clearOcrState();
  }

  /** 本人/家族：負担割合切り替え */
  private handleInsuredDiv() {
    if (!this.isLoaded) {
      return;
    }
    //高齢一
    if (this.eldCert.insured_div == 1) {
      this.eldCert.payment_rate = 80;
    }
    //高齢7
    if (this.eldCert.insured_div == 2) {
      this.eldCert.payment_rate = 70;
    }
  }

  /** 特例1割：負担割合切り替え */
  private handleIsException10() {
    if (!this.isLoaded) {
      return;
    }
    if (this.eldCert.is_exception10 == 1) {
      this.eldCert.payment_rate = 80;
    }
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //登録
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (this.eldCert.payment_rate === 90) {
      const msg = "高額療養費を登録できない内容です。よろしいですか？";
      if (!(await this.$openConfirm(msg))) {
        return;
      }
    }

    //高額療養費設定を作成する場合
    this.highExpenseOnOff();
    if (this.mode) {
      this.eldCert.id = 0;
    }
    this.eldCert.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/eldcert/save",
      {
        eld_cert: this.eldCert
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        // OCR 結果から作成成功した場合は OCR ステータスを「作成済み」にする
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.applyOcr();
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  private applyOcr() {
    if (this.ocrState.documentType === 0 || this.ocrState.documentId === 0) {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/ocrstore/mapping/apply",
      {
        document_type: this.ocrState.documentType,
        document_id: this.ocrState.documentId
      },
      () => {
        // do nothing
      }
    );
  }

  //変更
  private update() {
    this.eldCert.id = this.insEldCertId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.eldCert.id = this.insEldCertId;
      this.eldCert.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/eldcert/delete",
        {
          eld_cert: this.eldCert
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  //高齢受給者証情報取得
  private fetchInsurerEldCert(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/eldcert/get",
      {
        patient_id: this.patientId,
        ins_eld_cert_id: this.insEldCertId
      },
      res => {
        this.birthday = res.data.birthday;
        //高齢受給者証情報
        if (this.insEldCertId != 0) {
          const eldCert: MedicalInsuranceOldAge = res.data.eld_cert;
          if (this.mode) {
            eldCert.start_date = "";
            eldCert.end_date = "";
            //特例1割は廃止された制度なので、コピー元がONでもOFFにする
            eldCert.is_exception10 = 0;
          }
          this.eldCert = eldCert;
        }
        // OCR 結果から作成の場合、初期値設定
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.setOcrParams();
        }
        //高額療養費自動作成のON・OFF制御
        this.highExpenseOnOff();

        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  private setOcrParams() {
    // 一般
    if (this.ocrState.targetKind === TargetKind.mediInsOldAge) {
      this.eldCert.insured_no = this.ocrState.mediInsOldAge.insuredNo;
      this.eldCert.start_date = this.ocrState.mediInsOldAge.startDate;
      this.eldCert.end_date = this.ocrState.mediInsOldAge.endDate;
      const paymentRate = this.ocrState.mediInsOldAge.paymentRate;
      this.eldCert.payment_rate = paymentRate;
      switch (paymentRate) {
        case 70:
          this.eldCert.insured_div = 2; //高齢7
          break;
        case 80:
        case 90:
          this.eldCert.insured_div = 1; //高齢一
          break;
      }
    }
  }

  //-----------------------------------------------------
  // 誕生日前日
  //-----------------------------------------------------
  /** 終了日を誕生日前日に設定する */
  private setBeforeBirthday() {
    this.eldCert.end_date = this.calcBeforeBirthday(
      this.eldCert.start_date,
      this.birthday
    );
  }

  //-----------------------------------------------------
  // Watch表示切り替え
  //-----------------------------------------------------
  //編集モード設定
  @Watch("eldCert", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  //負担割合：特例1割選択状況下での動き
  @Watch("eldCert.payment_rate")
  private async handlePaymentRate() {
    if (!this.isLoaded) {
      return;
    }
    if (this.eldCert.is_exception10 == 1 && this.eldCert.payment_rate != 80) {
      await this.$openAlert("特例1割が選択されています");
    }
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //開始日年齢チェック
  private checkAgeStart(): boolean | string {
    if (this.eldCert.start_date) {
      return this.checkOldAge(
        this.eldCert.start_date,
        "開始日は70歳以上から75歳未満になる日を入力してください"
      );
    }
    return true;
  }
  //終了日年齢チェック
  private checkAgeEnd(): boolean | string {
    if (this.eldCert.end_date) {
      return this.checkOldAge(
        this.eldCert.end_date,
        "終了日は70歳以上から75歳未満になる日を入力してください"
      );
    }
    return true;
  }

  /** 検索条件バリデーション：期間の開始日 */
  private checkStartDate(): boolean | string {
    const startDate = this.newDate(this.eldCert.start_date);
    const endDate = this.newDate(this.eldCert.end_date);
    //高額療養費を作成する場合
    if (this.eldCert.is_make_high_expense) {
      const target = this.newDate("2018-08-01");
      if (target > startDate) {
        return "高額療養費への登録は有効期限（開始日）が平成30年8月1日(2018年8月1日)以降の場合に可能です";
      }
    }
    //開始日が終了日よりも後である場合
    if (startDate > endDate) {
      return "開始日は終了日よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了日 */
  private checkEndDate(): boolean | string {
    const startDate = this.newDate(this.eldCert.start_date);
    const endDate = this.newDate(this.eldCert.end_date);
    //終了日が開始日よりも前である場合
    if (startDate > endDate) {
      return "終了日は開始日よりも後に設定してください";
    }
    return true;
  }
}
