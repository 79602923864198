







































































































import { Component, Watch } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class EveryTime extends CarePartBase {
  created(): void {
    this.setNursingLeaveInstFlg();
  }

  //退院時共同指導日
  protected setNursingLeaveInstFlg() {
    if (
      this.receiptVisitRecord.nursing_leave_inst_date != "" ||
      this.receiptVisitRecord.nursing_leave_inst_date2 != ""
    ) {
      this.nursing_leave_inst_flg = true;
    }
  }

  //2人訪問:30分未満
  protected chackHalfHourFlg(): boolean | string {
    //30分未満選択該当
    const start = new Date(
      this.event.visit_record.service_start_datetime.replace(/-/g, "/")
    );
    const end = new Date(
      this.event.visit_record.service_end_datetime.replace(/-/g, "/")
    );
    const diff = Math.abs((end.getTime() - start.getTime()) / (60 * 1000));

    if (
      this.receiptVisitRecord.two_person_flg >= 1 &&
      !(this.receiptVisitRecord.staff_div >= 4 && diff >= 40) &&
      !(
        this.receiptVisitRecord.nursing_time_div == 3 ||
        this.receiptVisitRecord.nursing_time_div == 4 ||
        this.receiptVisitRecord.nursing_time_div == 5
      ) &&
      this.receiptVisitRecord.half_hour_flg == 0
    ) {
      return "30分未満を選択してください";
    }
    return true;
  }

  //退院時共同指導日1（必須入力）
  protected isNeedNursingLeaveInstDate1(): boolean {
    if (this.nursing_leave_inst_flg) {
      return true;
    }
    return false;
  }

  //退院時共同指導日1（日にち被り不可）
  protected chackNursingLeaveInstDate1(): boolean | string {
    //日にち被り不可
    if (
      this.receiptVisitRecord.nursing_leave_inst_date != "" &&
      this.receiptVisitRecord.nursing_leave_inst_date ==
        this.receiptVisitRecord.nursing_leave_inst_date2 &&
      !this.disableCheckNursingLeaveInstDate()
    ) {
      return "退院時共同指導日に同日付が入力されています";
    }
    return true;
  }

  //退院時共同指導日2（日にち被り不可）
  protected chackNursingLeaveInstDate2(): boolean | string {
    //日にち被り不可
    if (
      this.receiptVisitRecord.nursing_leave_inst_date != "" &&
      this.receiptVisitRecord.nursing_leave_inst_date ==
        this.receiptVisitRecord.nursing_leave_inst_date2 &&
      !this.disableCheckNursingLeaveInstDate()
    ) {
      return "退院時共同指導日に同日付が入力されています";
    }
    return true;
  }

  //2人訪問
  @Watch("receiptVisitRecord.two_person_flg")
  protected handleTwoPersonFlg() {
    if (!this.isLoaded) {
      return;
    }
    this.handleHalfHourFlg();
  }

  //サービス提供体制
  protected disableCheckServiceFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.care_service_supply_add == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //同一建物
  protected disableCheckSameBuildingFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.care_riyousya_gensan == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //提供時間
  protected disableCheckNursingTimeZoneDiv(): boolean {
    let result = false;
    if (this.receiptVisitRecord.online_visit_flg == 1) {
      result = true;
    }
    return result;
  }

  //2人訪問
  protected disableCheckTwoPerson(): boolean {
    let result = false;
    if (this.receiptVisitRecord.online_visit_flg == 1) {
      result = true;
    }
    return result;
  }

  //退院時共同指導日
  protected disableCheckNursingLeaveInstFlg(): boolean {
    let result = false;
    if (this.receiptVisitRecord.online_visit_flg == 1) {
      result = true;
    }
    return result;
  }

  //退院時共同指導日
  protected disableCheckNursingLeaveInstDate(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      !this.nursing_leave_inst_flg
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //サービス提供体制
    if (this.disableCheckServiceFlg()) {
      this.receiptVisitRecord.service_flg = 0;
    }
    //同一建物
    if (this.disableCheckSameBuildingFlg()) {
      this.receiptVisitRecord.same_building_flg = 0;
    }
    //提供時間
    if (this.disableCheckNursingTimeZoneDiv()) {
      this.receiptVisitRecord.nursing_time_zone_div = 0;
    }
    //2人訪問
    if (this.disableCheckTwoPerson()) {
      this.receiptVisitRecord.two_person_flg = 0;
      this.receiptVisitRecord.half_hour_flg = 0;
    }
    //退院時共同指導日
    if (this.disableCheckNursingLeaveInstFlg()) {
      this.nursing_leave_inst_flg = false;
    }
    //退院時共同指導日
    if (this.disableCheckNursingLeaveInstDate()) {
      this.receiptVisitRecord.nursing_leave_inst_date = "";
      this.receiptVisitRecord.nursing_leave_inst_date2 = "";
    }
  }
}
