


















import { Mixins, Component, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import { Vnrecord } from "@/components/patient/vnrecord/types";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import { COMMON } from "#/const";

@Component({
  components: {
    PatientHeader,
    BaseReportList
  }
})
export default class PatientVnrecordList extends Mixins(AxiosMixin) {
  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  private patientId = 0;
  private headers: DataTableHeader[] = [
    {
      text: "記載日",
      value: "write_date_wareki",
      width: "152px",
      sort: SortWarekiDate
    }
  ];
  private commentHeader = {
    text: "コメント",
    value: "remand.comment"
  };
  private listItems: Vnrecord[] = [];

  created(): void {
    this.patientId = Number(this.$route.params.id);
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchVnrecords({ tab: 0, page: 0 });
    }
  }

  private fetchVnrecords(obj: { tab: number; page: number }): void {
    // 看護記録書Ⅰ一覧取得
    this.postJsonCheck(
      window.base_url + "/api/patient/vnrecord/get",
      {
        patient_id: this.patientId,
        tab: obj.tab,
        page: obj.page
      },
      res => {
        if (res.data.vnrecords != null) {
          this.reportList?.finishLoaded(res.data.vnrecords);
          if (obj.page != 0) {
            this.listItems = this.listItems.concat(res.data.vnrecords);
          } else {
            this.listItems = res.data.vnrecords;
          }
        }
      }
    );
  }

  private toEdit(vnrecordId: number): void {
    this.$router.push({
      name: "PatientVnrecordEdit",
      params: { id: String(this.patientId), vnrecord_id: String(vnrecordId) }
    });
  }

  private getPdf(vnrecord: Vnrecord): void {
    this.makePdf(window.base_url + "/api/patient/vnrecords/pdf/get", {
      patient_id: this.patientId,
      pdf_path: vnrecord.pdf.pdf_path,
      vnrecord: vnrecord
    });
  }

  //差し戻し
  private setRemand(recordItem: { item: Vnrecord; comment: string }): void {
    recordItem.item.status = this.REPORT_REMAND;
    recordItem.item.remand.comment = recordItem.comment;
    this.postJsonCheck(
      window.base_url + "/api/patient/vnrecord/detail/saveRemand",
      { vnrecord: recordItem.item },
      () => {
        this.listItems = this.listItems.filter(
          v => v.id !== recordItem.item.id
        );
      }
    );
  }
}
