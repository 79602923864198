






































import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { MenuButton } from "./types";

@Component
export default class SecondaryButton extends Vue {
  @Prop() button!: MenuButton;

  @Emit() clickMenuButton(item: MenuButton) {
    return item;
  }
}
