

































































































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { EVENT_DISP_TYPE } from "#/model/schedule/calendarLayer";
import * as appDate from "#/utility/appDate";
import { Staff } from "#/model/staff";
import AxiosMixin from "@/mixins/axiosMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  CalendarDispEvent,
  DefaultEvent,
  EditRangeDiv
} from "#/components/calendar/common";
import EditButton from "@/components/common_ibow/calendar/visit_plan/EditButton.vue";

@Component({
  components: {
    EditButton
  }
})
export default class ShowEventDialog extends Mixins(
  AxiosMixin,
  FireStoreMixin,
  UtilMixin
) {
  /** 職員一覧 */
  @Prop({ default: () => [] }) staffs!: Staff[];

  @Emit() private edit(
    editRange: EditRangeDiv,
    isCopy: boolean
  ): {
    event: CalendarDispEvent;
    editRange: EditRangeDiv;
    isCopy: boolean;
  } {
    this.close();
    if (!this.event.is_repeat) {
      editRange = EditRangeDiv.New;
    }
    return { event: this.event, editRange: editRange, isCopy: isCopy };
  }

  @Emit() private update() {
    this.close();
  }

  /** ダイアログ展開状態 */
  private dialog = false;

  /** イベント */
  private event: CalendarDispEvent = DefaultEvent();

  /** 場所URLフラグ */
  private get IsLocateUrl(): boolean {
    return (
      this.event.staff_schedule.locate.indexOf("http://") === 0 ||
      this.event.staff_schedule.locate.indexOf("https://") === 0
    );
  }

  /** キャンセル済予定の削除ボタンラベル */
  private get DeletePlanText() {
    if (this.event.is_repeat) {
      return "一連の予定を削除";
    } else {
      return "この予定を削除";
    }
  }
  /** ダイアログを開く */
  public open(event: CalendarDispEvent) {
    this.event = event;
    this.dialog = true;
  }

  /** ダイアログを閉じる */
  public close() {
    this.dialog = false;
  }

  /** 参加者ステータスを更新する */
  private saveStatus(status: number, edit_range: number) {
    const participant = this.event.staff_schedule.own_participant;
    if (participant) {
      participant.status = status;
      const date = appDate.strToDate(this.event.start);
      this.updateStatus({
        participant_status: participant,
        date: appDate.dateToStr(date, "yyyy-MM-dd"),
        edit_range: edit_range
      });
    }
  }

  /** キャンセルされた予定を削除する（カレンダー上、非表示になる） */
  private async deletePlan() {
    if (
      !(await this.$openConfirm(
        "この予定をカレンダーから削除してもよろしいですか？"
      ))
    ) {
      return;
    }
    this.saveStatus(EVENT_DISP_TYPE.DELETED, EditRangeDiv.All);
  }

  /** 参加者ステータスを更新する */
  private updateStatus(updateObj: object) {
    this.postJsonCheck(
      window.base_url + "/api/calendar/participant/status/save",
      updateObj,
      () => {
        this.update();
      }
    );
  }
}
