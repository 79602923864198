











import { Component, Emit, Prop } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import UtilMixin from "@/mixins/utilMixin";
import { Office } from "#/model/agreement/office";
import { Choice } from "@/types";

export interface FileItem {
  id: number;
  file_name: string;
  file_size: string;
  updated_at: string;
}

@Component
export default class BiOfficeSelect extends Base {
  @Prop({ default: "事業所" }) label!: string;

  @Prop() value!:  number | number[];

  /** サテライト事業所を選択できるか */
  @Prop({ default: true }) needSatelite!: boolean;

  /** 未選択を選択できるか */
  @Prop({ default: true }) needUnselected!: boolean;

  private officeItems: Choice[] = []; //データ


  private get Value() {
    return this.value;
  }

  private set Value(newValue:  number | number[]) {
    this.input(newValue);
  }

  mounted() {
    this.getEnabledBiOfficeSelectItems()
  }

  /**
  *  BI機能有効な事業所（サテライト除外）取得
  */
  private getEnabledBiOfficeSelectItems() {
    this.postJsonCheck(
      window.base_url + "/api/master/station/get",
      {},
      res => {
        this.officeItems = res.data.offices
          .filter((o: Office) => o.billing_info.is_bi_enabled && o.office_div === 1)
          .map((o: Office) => {
            return {
              text: o.office_name,
              value: o.id,
            } as Choice
          })

          if (!this.$attrs.multiple && this.officeItems.length) {
          // 複数選択ではない場合１番目のデータ選択状態
            this.Value = Number(this.officeItems[0].value)
          } else if (this.$attrs.multiple && Array.isArray(this.Value)) {
          // 複数選択の場合、選択肢以外のチェックを省く
            this.Value = this.Value.filter(v => this.officeItems.some(i => i.value === v))
          }
      }
    );
  }

  @Emit()
  private input(newValue:  number | number[]) {
    return newValue;
  }
}
