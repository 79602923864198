export const RegexPettern = {
  containUrl: /https?:\/\/[!-_.~*()'a-zA-Z0-9;:#%,$&=?@/]+/g,
  iBowPageLink: /^ibow:page:link$/,
  iBowFileLink: /^ibow:file:link$/
} as const;

export const Regex = {
  isContainURL: (target: string): boolean => {
    const result = RegexPettern.containUrl.test(target);
    // 参考: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test#using_test_on_a_regex_with_the_global_flag
    RegexPettern.containUrl.lastIndex = 0;
    return result;
  },
  isiBowPageLink: (target: string): boolean => {
    const result = RegexPettern.iBowPageLink.test(target);
    return result;
  },
  isiBowFileLink: (target: string): boolean => {
    const result = RegexPettern.iBowFileLink.test(target);
    return result;
  },
  replaceURLToLink: (target: string): string => {
    return target.replace(
      RegexPettern.containUrl,
      '<a class="replaced-link" href="$&" target="_blank">$&</a>'
    );
  }
};
