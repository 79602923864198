













import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";
import { CareRateCertItems } from "./type";

@Component({
  components: {
    ItemTable
  }
})
export default class CareRateCert extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 医療保険 */
  @Prop({ default: () => [] }) private readonly items!: CareRateCertItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 介護保険負担割合証ヘッダー */
  private careRateCertHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    { text: "負担割合/給付率", value: "rate" },
    {
      text: "開始日",
      value: "start_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    }
  ];

  /** 新規作成 */
  private careRateCertCreate() {
    this.$router.push({
      name: "InsuranceInfoCareRateCert",
      params: { id: String(this.patientId), inscareratecertid: "0" }
    });
  }

  /** 修正 */
  private careRateCertRepair(item: CareRateCertItems) {
    this.$router.push({
      name: "InsuranceInfoCareRateCert",
      params: { id: String(this.patientId), inscareratecertid: String(item.id) }
    });
  }

  /** コピー */
  private careRateCertCopy(item: CareRateCertItems) {
    this.$router.push({
      name: "InsuranceInfoCareRateCert",
      params: {
        id: String(this.patientId),
        inscareratecertid: String(item.id)
      },
      query: { mode: "copy" }
    });
  }

  //現在有効な介護保険負担割合証に色を付ける
  private careItemsRowBackground(item: CareRateCertItems) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
