











import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class SexSelect extends Mixins(UtilMixin) {
  @Prop({ default: "性別" }) label!: string;

  @Prop() value!: number;

  private get Value() {
    return this.value;
  }

  private set Value(newValue: number) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: number) {
    return newValue;
  }
}
