import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ParentSearchCondition } from "./types";

@Component
export default class Base extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  FireStoreMixin
) {
  //-----------------------------------------------------
  // 年月
  //-----------------------------------------------------
  //今月
  protected todaymonth(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const todaymonth = date.toISOString().substr(0, 7);
    return todaymonth;
  }
  //先月
  protected lastmonth(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setMonth(date.getMonth() - 1);
    const lastmonth = date.toISOString().substr(0, 7);
    return lastmonth;
  }

  //去年
  protected lastymonth(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setMonth(date.getMonth() - 11);
    date.setDate(date.getDate());
    const todaymonth = date.toISOString().substr(0, 7);
    return todaymonth;
  }
  //6ヶ月前
  protected sixmonthago(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setMonth(date.getMonth() - 5);
    date.setDate(date.getDate());
    const todaymonth = date.toISOString().substr(0, 7);
    return todaymonth;
  }

  //データテーブル年月表示
  protected dateFormatted(date: string): string {
    if (!date) return "";
    const [year, month] = date.split("-");
    return `${year}年${month}月`;
  }

  //-----------------------------------------------------
  // 事業所
  //-----------------------------------------------------
  /** 選択可能な事業所一覧から先頭の事業所を返す */
  protected offices(): number {
    const copiedOnlyOffices = this.masters.group_only_offices.slice();
    copiedOnlyOffices.splice(0, 1);
    let officeId = 0;
    for (const coo of copiedOnlyOffices) {
      officeId = Number(coo.value);
      break;
    }
    return officeId;
  }

  /** 事業所名の取得 */
  protected getOfficeName(officeId: number): string {
    const copiedOnlyOffices = this.masters.group_only_offices.slice();
    let officeName = "";
    for (const coo of copiedOnlyOffices) {
      if (officeId == Number(coo.value)) {
        officeName = coo.text;
        break;
      }
    }
    return officeName;
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  // 初期メッセージ
  protected xyChartMessage(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    const message = chart.createChild(am4core.Label);
    this.customMessage(message);

    return chart;
  }

  // 初期メッセージ
  protected pieChartMessage(): am4charts.PieChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.PieChart
    );

    const message = chart.createChild(am4core.Label);
    this.customMessage(message);

    return chart;
  }

  // メッセージカスタマイズ
  private customMessage(message: am4core.Label) {
    message.text =
      "[bold #808080 font-size:19px]事業所を選択し開始月と終了月を選んで「検索」を押すとグラフを表示します。\n\n最新OSにバージョンアップデートできる端末でご利用ください。\n\n端末が古い場合、画面が正しく表示できない場合があります。[/]";
    message.marginBottom = 250;
    message.align = "center";
  }

  // ダウンロードメニュー
  protected menu(): am4core.IExportMenuItem[] {
    return [
      {
        label: "...",
        menu: [
          {
            label: "Image",
            menu: [
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "svg", label: "SVG" },
              { type: "pdf", label: "PDF" }
            ]
          }
        ]
      }
    ];
  }

  //-----------------------------------------------------
  // Excel
  //-----------------------------------------------------
  //Excelファイルのダウンロード
  protected downloadFile(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  //-----------------------------------------------------
  // Chack
  //-----------------------------------------------------
  //開始日:日付チェック
  protected chackStart(start: string, end: string): boolean | string {
    //開始日が終了日より後
    if (end != "" && start > end) {
      return "開始日が終了日より後になっています";
    }
    return true;
  }
  //終了日:日付チェック
  protected chackEnd(start: string, end: string): boolean | string {
    //終了日が開始日より前
    if (start != "" && start > end) {
      return "終了日が開始日より前になっています";
    }
    return true;
  }

  //事業所選択
  protected checkOfficeSelect(officeIds: number[]): boolean | string {
    //1つも選択していない場合
    if (!officeIds || officeIds.length == 0) {
      return "1事業所は選択してください";
    }
    //5つ以上選択している場合
    if (!officeIds || officeIds.length > 5) {
      return "最大5事業所まで選択可能です";
    }
    return true;
  }

  //-----------------------------------------------------
  // Chack（レセプト売上情報で使用）
  //-----------------------------------------------------
  //開始日:日付チェック
  protected chackStartRS(start: string, end: string): boolean | string {
    //開始日が終了日より後
    if (end != "" && start > end) {
      return "開始日が終了日より後になっています";
    } else {
      return this.chackRange(start, end);
    }
  }
  //終了日:日付チェック
  protected chackEndRS(start: string, end: string): boolean | string {
    //終了日が開始日より前
    if (start != "" && start > end) {
      return "終了日が開始日より前になっています";
    } else {
      return this.chackRange(start, end);
    }
  }
  //開始日・終了日の期間チェック（2ヶ月以上）
  private chackRange(s: string, e: string): boolean | string {
    //1年を超える検索の場合
    const start = new Date(s.replace(/-/g, "/") + "/01");
    const end = new Date(e.replace(/-/g, "/") + "/01");
    const diffYear = end.getFullYear() - start.getFullYear();
    const diffMonth = end.getMonth() - start.getMonth();
    if (diffYear == 0 && diffMonth == 0) {
      return "検索期間2ヶ月以上から検索できます";
    }
    return true;
  }

  //-----------------------------------------------------
  // Chack（利用者数情報で使用）
  //-----------------------------------------------------
  //事業所選択
  protected checkOfficeSelectPN(officeIds: number[]): boolean | string {
    //1つも選択していない場合
    if (!officeIds || officeIds.length == 0) {
      return "1事業所は選択してください";
    }
    //5つ以上選択している場合
    if (!officeIds || officeIds.length > 4) {
      return "最大4事業所まで選択可能です";
    }
    return true;
  }

  //-----------------------------------------------------
  // 親画面の検索条件(月)保存する
  //-----------------------------------------------------
  //親画面の検索条件を保存
  protected async saveParentSearchCon(
    parentSearchCondition: ParentSearchCondition
  ) {
    //元のcollectionは保存
    const collection: string = this.collection;
    const documentId: string = this.documentId;

    this.collection = "office-aggregate-parent";
    this.documentId = String(this.loginUser.id);
    this.documentSave({ parentSearchCondition: parentSearchCondition });

    this.collection = collection;
    this.documentId = documentId;
  }
  //親画面の検索条件を取得
  protected async getParentSearchCon() {
    //元のcollectionは保存
    const collection: string = this.collection;
    const documentId: string = this.documentId;

    this.collection = "office-aggregate-parent";
    this.documentId = String(this.loginUser.id);
    const fbDoc = await this.documentGet();

    this.collection = collection;
    this.documentId = documentId;
    if (fbDoc !== undefined && typeof fbDoc !== "boolean") {
      return fbDoc.parentSearchCondition as ParentSearchCondition;
    } else {
      return false;
    }
  }
}
