



















































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { RiskFactor as RiskFactorModel } from "#/model/report";
import { RiskFactorMaster } from "#/model/master";
import UtilMixin from "@/mixins/utilMixin";
import * as appDate from "#/utility/appDate";

@Component
export default class RiskFactor extends Mixins(UtilMixin) {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop() private riskFactor!: RiskFactorModel; //危険因子の評価
  @Prop() private readonly master!: RiskFactorMaster; //危険因子の評価マスタ
  @Prop() private before!: RiskFactorModel | null; //前回の危険因子の評価
  @Prop({ default: "" }) private readonly before_date!: string; // 前回評価日

  /*
   * computed
   */

  // 前回評価日
  private get beforeDate(): string {
    if (!this.before_date) {
      return "";
    }
    const d = appDate.strToDate(this.before_date);
    return appDate.dateToStr(d, "前回評価：yyyy年MM月dd日");
  }

  /*
   * method
   */

  //前回評価を入力クリック
  private clickBeforeEvaluate(value: boolean) {
    if (!value) {
      return;
    }
    if (!this.before) {
      return;
    }
    this.riskFactor.basic_action1 = this.before.basic_action1;
    this.riskFactor.basic_action2 = this.before.basic_action2;
    this.riskFactor.bone = this.before.bone;
    this.riskFactor.joint = this.before.joint;
    this.riskFactor.nutrition = this.before.nutrition;
    this.riskFactor.skin = this.before.skin;
    this.riskFactor.edema = this.before.edema;
    this.riskFactor.skin_tear = this.before.skin_tear;
    this.riskFactor.jokuso_valuation = this.before.jokuso_valuation;
    this.riskFactor.copy_recent_evaluation_risk_factor = this.before.copy_recent_evaluation_risk_factor;
  }
}
