



















































import { Component, Vue, Prop } from "vue-property-decorator";
import { Choice } from "@/types";
import { OfficeStaffChoice } from "#/model/staff";
import { ScheduleSearchCondition } from "@/components/schedule/common";

@Component
export default class ScheduleManageDisplaySearch extends Vue {
  /** 利用者リスト */
  @Prop({ default: () => [] }) private readonly patients!: Choice[];
  /** グループリスト */
  @Prop({ default: () => [] }) private readonly groups!: Choice[];
  /** 職員リスト */
  @Prop({ default: () => [] }) private readonly staffs!: Choice[];
  /** シフトリスト */
  @Prop({ default: () => [] }) private readonly shifts!: Choice[];
  /** 検索条件（表示用） */
  @Prop({ required: true })
  private readonly dispSearch!: ScheduleSearchCondition;

  /** 職員名を取得する */
  private getStaffName(staffId: number): string {
    const staff = this.staffs.find(
      staff => staff.value == staffId
    ) as OfficeStaffChoice;
    if (staff?.nick_name) {
      return staff.nick_name;
    }
    return "";
  }

  /** 利用者名を取得する */
  private getPatientName(patietId: number): string {
    const patient = this.patients.find(
      patient => patient.value == patietId
    ) as Choice;
    if (patient?.text) {
      return patient.text;
    }
    return "";
  }

  /** グループ名を取得する */
  private getGroupName(groupId: number): string {
    const group = this.groups.find(group => group.value == groupId) as Choice;
    if (group?.text) {
      return group.text;
    }
    return "";
  }
}
