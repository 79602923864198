







































import { Component, Prop, Vue } from "vue-property-decorator";
import { ObservationItemMaster } from "#/model/master";
import { Observation, DefaultObservation } from "#/model/report";

@Component
export default class Items extends Vue {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop() private observations!: Observation[];
  @Prop() private readonly item!: ObservationItemMaster;
  @Prop() private categoryId!: number;
  @Prop({ default: 0 }) private readonly adlSetting!: number; // 観察項目の登録数制限フラグ(0:最大21項目 1:制限を解除)

  private get observation(): Observation {
    const obs = this.findObservation(this.item.id);
    if (obs) {
      return obs;
    }
    const newObservation = DefaultObservation(this.categoryId, this.item.id);
    this.observations.push(newObservation);
    return newObservation;
  }

  /** 観察項目のコメント */
  private get commentLimit() {
    // 事業所設定によって項目数制限が無くなれば、文字数制限も無くす
    if (this.adlSetting === 1) {
      return 0;
    }
    return 15;
  }

  // 観察項目検索
  private findObservation(itemId: number): Observation | undefined {
    if (!this.observations) {
      return undefined;
    }
    return this.observations.find(ob => ob.observation_item_id == itemId);
  }
}
