











import { Component, Vue } from "vue-property-decorator";
import PatientSearch from "@/components/patientsearch/PatientSearch.vue";
import { COLLECTION_SEARCH_COND_HOUR24_LIST } from "@/const/envFireStore";

@Component({
  components: {
    PatientSearch
  }
})
export default class Hour24List extends Vue {
  // fire store 保存先コレクション
  private collection_name = COLLECTION_SEARCH_COND_HOUR24_LIST;

  // 利用者選択
  private clickSelect(patientId: number) {
    this.$router.push(`/patient/${patientId}/hour24/regist/0`);
  }
}
