





















































































































import { Component, Emit } from "vue-property-decorator";
import ShiftTypeEntryBase from "@/components/shift/ShiftTypeEntryBase";

@Component
export default class StaffShiftTypeEntry extends ShiftTypeEntryBase {
  /** 閉じた時 */
  @Emit() private close() {
    this.dialog = false;
  }

  /** ダイアログ開閉状態 */
  private dialog = false;

  public created(): void {
    this.fetch();
  }
}
