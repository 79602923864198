




























































































































































import { Component, Ref } from "vue-property-decorator";
import ReceiptEditAddMedBase from "./ReceiptEditAddMedBase";
import VisitMessage from "./common/VisitMessage.vue";
import VisitTitle from "./common/VisitTitle.vue";
import VisitInfoV3 from "./med/VisitInfoV3.vue";
import RecuperationDivV2 from "./med/RecuperationDivV2.vue";
import StaffDiv from "./med/StaffDiv.vue";
import SenmonCareDiv from "./med/SenmonCareDiv.vue";
import TimeDiv from "./med/TimeDiv.vue";
import OneMonthAddV4 from "./med/OneMonthAddV4.vue";
import TwoMonthAdd from "./med/TwoMonthAdd.vue";
import OneOrThreeWeekAddV4 from "./med/OneOrThreeWeekAddV4.vue";
import OneDayAddV4 from "./med/OneDayAddV4.vue";
import EveryTimeV4 from "./med/EveryTimeV4.vue";
import OtherV3 from "./med/OtherV3.vue";
import ReceiptEditAddBase from "./ReceiptEditAddBase";

@Component({
  components: {
    VisitMessage,
    VisitTitle,
    VisitInfoV3,
    RecuperationDivV2,
    StaffDiv,
    SenmonCareDiv,
    TimeDiv,
    OneMonthAddV4,
    TwoMonthAdd,
    OneOrThreeWeekAddV4,
    OneDayAddV4,
    EveryTimeV4,
    OtherV3
  }
})
export default class ReceiptEditAddMedV4 extends ReceiptEditAddMedBase
  implements ReceiptEditAddBase {
  @Ref("oneMonthAddV4") private readonly oneMonthAddV4!: OneMonthAddV4;
  @Ref("twoMonthAdd") private readonly twoMonthAdd!: TwoMonthAdd;
  @Ref("oneOrThreeWeekAddV4")
  private readonly oneOrThreeWeekAddV4!: OneOrThreeWeekAddV4;
  @Ref("oneDayAddV4") private readonly oneDayAddV4!: OneDayAddV4;
  @Ref("everyTimeV4") private readonly everyTimeV4!: EveryTimeV4;
  @Ref("otherV3") private readonly otherV3!: OtherV3;

  private long_time_flg = false;

  private long_time_flg_covid19_urgent_div = false;

  private long_time_flg_covid19_plan_div = false;

  //-----------------------------------------------------
  // ボタン群
  //-----------------------------------------------------
  //登録
  async registration(onSuccess?: Function) {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    //請求履歴確定済
    if (this.isBilledMedical == 1) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }

    //サービス訪問（自費）チェック時
    if (this.service_visit) {
      if (
        !(await this.$openConfirm(
          "請求対象外となるため画面から消えますが宜しいですか？"
        ))
      ) {
        return;
      }
    }

    //対象月で職員区分「専門」での訪問が2回目以降の場合
    if (this.receiptVisitRecord.staff_div == 3) {
      //登録対象より前の訪問で職員区分「専門」の訪問がある場合
      if (this.event.specialty_hide_flg) {
        if (
          !(await this.$openConfirm(
            "請求対象外となるため画面から消えますが宜しいですか？"
          ))
        ) {
          return;
        }
      }
    }

    //外泊訪問（登録済の訪問で見る）
    if (this.receiptVisitRecord.stay_outside_flg == 1) {
      let sleepoverFlg = false;
      //別表のある指示書の場合3件目以降から
      if (
        this.event.indicate.diseases.length > 0 ||
        this.event.indicate.states.length > 0
      ) {
        let count = 0;
        if (this.receiptVisitRecords) {
          this.receiptVisitRecords.forEach(rvr => {
            if (rvr.stay_outside_flg == 1) {
              count += 1;
            }
          });
        }
        if (count >= 2) {
          sleepoverFlg = true;
        }
      } else {
        //別表のない指示書の場合2件目以降から
        if (this.receiptVisitRecords) {
          this.receiptVisitRecords.forEach(rvr => {
            if (rvr.stay_outside_flg == 1) {
              sleepoverFlg = true;
            }
          });
        }
      }
      //上記判定に当てはまる場合
      if (sleepoverFlg) {
        if (
          !(await this.$openConfirm(
            "既に外泊登録されていますが、登録を続行しますか？"
          ))
        ) {
          return;
        }
      }
    }

    //登録対象が在宅がんで同じ期間に介護訪問がある場合
    if (this.homeCancerCareFlg) {
      if (
        !(await this.$openConfirm(
          "在宅がん医療総合診療料の期間に介護保険の実績が含まれています" +
            this.homeCancerCareDay +
            "日。本当に登録しますか？（ケアマネジャーへご確認ください。）"
        ))
      ) {
        return;
      }
      //登録対象が在宅がんの場合
    } else if (this.event.home_cancer_flg == 1) {
      if (
        !(await this.$openConfirm(
          "在宅がん医療総合診療料期間の実績は請求対象外となるため保険請求には反映されませんが宜しいですか？"
        ))
      ) {
        return;
      }
    }
    //情報提供療養費を加算しようとするが情報提供先が選択されていない
    if (this.receiptVisitRecord.info_expenses_flg1 && !this.hasInfoProvider1) {
      if (
        !(await this.$openConfirm(
          "情報提供療養費１を算定しているため、情報提供書（市町村等宛）を提出してください。\n情報提供先（１市(区)町村等）を設定する手順は、「月1回加算」の右にある（？）マークで確認できます。"
        ))
      ) {
        return;
      }
    }
    if (this.receiptVisitRecord.info_expenses_flg2 && !this.hasInfoProvider2) {
      if (
        !(await this.$openConfirm(
          "情報提供療養費２を算定しているため、情報提供書（学校等宛）を提出してください。\n情報提供先（２学校等）を設定する手順は、「月1回加算」の右にある（？）マークで確認できます。"
        ))
      ) {
        return;
      }
    }
    if (this.receiptVisitRecord.info_expenses_flg3 && !this.hasInfoProvider3) {
      if (
        !(await this.$openConfirm(
          "情報提供療養費３を算定しているため、情報提供書（保健所長等宛）を提出してください。\n情報提供先（３保険医療機関等）を設定する手順は、「月1回加算」の右にある（？）マークで確認できます。"
        ))
      ) {
        return;
      }
    }

    //clearDisableItem（disableの項目は0で保存しに行く）
    this.oneMonthAddV4.clearDisableItemV4();
    this.twoMonthAdd.clearDisableItem();
    this.oneOrThreeWeekAddV4.clearDisableItemV4();
    this.oneDayAddV4.clearDisableItemV4();
    this.everyTimeV4.clearDisableItemV4();
    this.otherV3.clearDisableItemV3();

    //バックエンド側で登録時チェックする
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/registration",
      {
        patient_id: this.patientId,
        service_visit: this.service_visit,
        yearmonth: this.yearmonth,
        event: this.event,
        events: this.events,
        receipt_visit_record: this.receiptVisitRecord
      },
      async res => {
        this.registrationMessage = res.data.registration_message;
        this.message("登録しました");
        this.update();
        onSuccess?.();
      },
      err => {
        if (err.response?.data.registration_message) {
          this.registrationMessage = err.response?.data.registration_message;
        }
      }
    );
  }

  //実績解除
  async releaseResult(onSuccess?: Function) {
    //請求履歴確定済
    if (this.isBilledMedical == 1) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }

    //確認
    if (!(await this.$openConfirm("実績解除します。よろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/releaseResult",
      {
        patient_id: this.patientId,
        receipt_visit_record: this.receiptVisitRecord,
        yearmonth: this.yearmonth,
        office_id: this.officeHistory.office_id
      },
      () => {
        this.message("登録実績を解除しました");
        this.update();
        onSuccess?.();
      }
    );
  }

  //リセット
  async reset(onSuccess?: Function) {
    //請求履歴確定済
    if (this.isBilledMedical == 1) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }

    //確認
    if (
      !(await this.$openConfirm(
        "記録書IIを再提出します。加算情報等、初期化されますが宜しいですか？"
      ))
    ) {
      return;
    }

    //介護保険に変更（医療訪問なのに介護保険があるかつ、指示書が医療扱いではない）
    if (this.judgeCare()) {
      //リセットメッセージ
      const resetColumns = this.resetColumns();

      if (resetColumns != "") {
        if (
          !(await this.$openConfirm(
            "適用保険が介護保険に変更されているため、" +
              resetColumns +
              "の選択が解除されますがよろしいですか？"
          ))
        ) {
          return;
        }
      }
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/reset",
      {
        patient_id: this.patientId,
        yearmonth: this.yearmonth,
        event: this.event
      },
      async res => {
        this.resetMessage = res.data.reset_message;
        this.message("リセットしました");
        this.update();
        onSuccess?.();
      },
      err => {
        if (err.response?.data.reset_message) {
          this.resetMessage = err.response?.data.reset_message;
        }
      }
    );
  }
}
