
















import { Component, Vue } from "vue-property-decorator";
@Component
export default class OutputExcel extends Vue {
  private offices = [
    {
      text: "訪問看護ステーション相棒",
      value: 1
    },
    {
      text: "大阪ステーション",
      value: 2
    },
    {
      text: "石川ステーション",
      value: 3
    }
  ];
  private sheetItems = [
    {
      id: 1,
      text: "契約情報"
    },
    {
      id: 2,
      text: "事業所情報"
    },
    {
      id: 3,
      text: "職員情報"
    },
    {
      id: 4,
      text: "利用者情報"
    },
    {
      id: 5,
      text: "保険情報"
    },
    {
      id: 6,
      text: "指示書情報"
    },
    {
      id: 7,
      text: "看護計画書"
    },
    {
      id: 8,
      text: "看護記録書Ⅰ"
    },
    {
      id: 9,
      text: "看護記録書Ⅱ"
    },
    {
      id: 10,
      text: "月次報告書"
    },
    {
      id: 11,
      text: "情報提供書"
    },
    {
      id: 12,
      text: "褥瘡対策看護計画書"
    },
    {
      id: 13,
      text: "事故/ヒヤリハット報告書"
    },
    {
      id: 14,
      text: "看護サマリー"
    },
    {
      id: 15,
      text: "服薬管理"
    },
    {
      id: 16,
      text: "業務日報"
    },
    {
      id: 17,
      text: "会議録"
    },
    {
      id: 18,
      text: "サービス提供票"
    },
    {
      id: 19,
      text: "サービス内容確認書"
    }
  ];
}
