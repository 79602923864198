import { Component, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class CalendarMixin extends Mixins(UtilMixin) {
  /** 引数の月を加算する */
  protected addMonthDate(targetDate: string, addMonth: number): string {
    if (targetDate == "") {
      return "";
    }
    const date = this.strToDate(targetDate);
    const addDate = new Date(date.getFullYear(), date.getMonth() + addMonth, 1);
    return this.dateToStr(addDate, "yyyy-MM-dd");
  }

  /** 引数の週を加算する */
  protected addWeekDate(targetDate: string, add: number): string {
    if (targetDate == "") {
      return "";
    }
    const date = this.strToDate(targetDate);
    const week = date.getDay();
    const addDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - week + add
    );
    return this.dateToStr(addDate, "yyyy-MM-dd");
  }
}
