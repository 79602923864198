















import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class MailPreviewDialog extends Vue {
  @Prop() private value!: boolean; // ダイアログを開いているか
  @Prop() private subject!: string; // メール件名

  private get Value() {
    return this.value;
  }
  private set Value(newValue: boolean) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: boolean) {
    return newValue;
  }
}
