

































import { Component, Mixins } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import ExcelInputStart from "@/components/master/excel/ExcelInputStart.vue";
import ExcelInputContainer from "@/components/master/excel/ExcelInputContainer.vue";
import {
  ExcelInputResult,
  ResultData,
  FileIF,
  Sheet,
  SHEET_DATA,
  ExChoice
} from "@/views/master/excel/types";

/** 拡張ファイルインタフェース */
interface ExFileIF extends FileIF {
  /** ファイル名 */
  name: string;
}

@Component({
  components: {
    OfficeSelect,
    FileUpload,
    ExcelInputStart,
    ExcelInputContainer
  }
})
export default class MedTransmissionExcelInput extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** 選択中のファイル情報 */
  private inputFile: ExFileIF = { id: 0, path: "", name: "" };

  /** 選択中のシートID */
  private sheetIds: number[] = [];

  /** 現在処理中のシートID */
  private currentSheetId = 0;

  /** 処理済みシート数 */
  private completedSheetCount = 0;

  /** 状態(1:初期 2:処理選択 3:取り込み結果 4:保存結果) */
  private state = 1;

  /** 事業所選択の必要フラグ */
  private needOfficeSelect = 0;

  /** 処理中のシート情報 */
  private get sheet(): Sheet {
    return SHEET_DATA.filter(
      (sheet: Sheet) => sheet.sheet_id == this.currentSheetId
    )[0];
  }

  /** 取り込み結果情報 */
  private result: ExcelInputResult = {
    is_success: true,
    result: {} as ResultData,
    proto_data: [],
    api_path: ""
  };

  /** 処理開始時 */
  private start(selectedSheetIds: ExChoice[]) {
    this.$nextTick(() => {
      // 選択中シートIDを昇順ソート
      this.sheetIds = selectedSheetIds
        .sort((a: ExChoice, b: ExChoice) => {
          return a.sort_no - b.sort_no;
        })
        .map((data: ExChoice) => data.value as number);

      this.completedSheetCount = 0;
      this.currentSheetId = this.sheetIds[this.completedSheetCount];
      this.state = 2;
    });
  }

  /** 取り込み時 */
  private inputData() {
    this.postJsonCheck(
      window.base_heavy_url + "/api/master/dataio/med_transmission_excel/input",
      {
        file_path: this.inputFile.path,
        file_name: this.inputFile.name,
        process_type: 2,
        key_type: 1,
        sheet_kind: this.currentSheetId,
        is_med_transmission: true
      },
      res => {
        if (!res.data.result_data) {
          return;
        }
        // 取り込み完了後
        this.state = 3;
        this.result = res.data.result_data;
      }
    );
  }

  /** 保存時 */
  private async save() {
    if (!(await this.$openConfirm("保存します。よろしいですか？"))) {
      return;
    }

    this.postJsonCheck(
      window.base_heavy_url + this.result.api_path,
      {
        proto_data: this.result.proto_data
      },
      () => {
        // 保存完了後
        this.state = 4;
      }
    );
  }

  /** 処理スキップ時 */
  private skip() {
    this.completedSheetCount++;
    this.currentSheetId = this.sheetIds[this.completedSheetCount];
    this.state = 2;
    this.result = {
      is_success: true,
      result: {} as ResultData,
      proto_data: [],
      api_path: ""
    };
  }

  /** 処理リセット時 */
  private reset() {
    this.completedSheetCount = 0;
    this.currentSheetId = 0;
    this.sheetIds = [];
    this.state = 1;
    this.result = {
      is_success: true,
      result: {} as ResultData,
      proto_data: [],
      api_path: ""
    };
  }
}
