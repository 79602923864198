
































































































































































































































































































import { Mixins, Component, Watch, Ref, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import BedsoreExist from "@/components/patient/bedsore/BedsoreExist.vue";
import { Choice } from "@/types";
import { Link } from "#/types";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import {
  BedsorePlan,
  BedsorePlanDefaultValues,
  BedsorePlanRemandDefaultValues,
  BedsorePlanPartDefaultValues,
  BedsorePartName,
  BedsorePartItem,
  BedsorePartItemDefaultValues,
  BedsoreRiskFactorItem,
  BedsoreRiskFactorItemDefaultValues,
  BedsorePlanPart,
  TIME_DIV_NOW,
  TIME_DIV_OLD
} from "@/components/patient/bedsore/types";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import RemandExpand from "@/components/patient/common/remand/RemandExpand.vue";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";

@Component({
  components: {
    BedsoreExist,
    PatientSaveArea,
    PatientExpantionAreas,
    RemandExpand
  }
})
export default class PatientBedsore extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  EditMixin,
  PatientMixin
) {
  private submitCheck = false;
  private patientId = 0;
  private bedsorePlanId = 0;
  private nowBedsoreParts: BedsorePlanPart[] = [];
  private oldBedsoreParts: BedsorePlanPart[] = [];
  private bedsorePlan: BedsorePlan = BedsorePlanDefaultValues();
  private bedsorePartNames: BedsorePartName[] = [];
  private bedsorePartItem: BedsorePartItem = BedsorePartItemDefaultValues();
  private bedsoreRiskFactorItem: BedsoreRiskFactorItem = BedsoreRiskFactorItemDefaultValues();
  private createPlanStaffItems: Choice[] = [];
  private existBedsorePartItems: Choice[] = [];
  private links: Link[] = [];

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  private get StorageKey(): string {
    return `bedsorePlan-${this.loginUser.id}-${this.patientId}-${this.bedsorePlanId}`;
  }

  @Prop() readonly pheader!: PatientHeader;

  @Ref("patient-header") private readonly patientHeader!: PatientExpantionAreas;

  //編集モード設定
  @Watch("bedsorePlan", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      bedsorePlan: this.bedsorePlan,
      displayPreview: this.displayPreview,
      nowBedsoreParts: this.nowBedsoreParts,
      oldBedsoreParts: this.oldBedsoreParts
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  @Watch("nowBedsoreParts", { deep: true }) watchNewBedsoreParts(
    newValue: BedsorePlanPart[]
  ) {
    this.links = this.getLinks(newValue);
    this.watchData();
  }

  @Watch("oldBedsoreParts", { deep: true }) watchOldBedsoreParts() {
    this.watchData();
  }

  created(): void {
    this.patientId = Number(this.$route.params.id);
    this.bedsorePlanId = Number(this.$route.params.bedsore_plan_id);
    this.fetchBedsorePlans();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/bedsore`
      },
      {
        text: "褥瘡対策",
        disabled: true,
        to: ""
      }
    ]);
  }

  private fetchBedsorePlans(): void {
    // 褥瘡対策計画一覧取得
    this.postJsonCheck(
      window.base_url + "/api/patient/bedsoreplan/get",
      { bedsore_plan_id: this.bedsorePlanId, patient_id: this.patientId },
      res => {
        this.bedsorePlan = res.data.bedsore_plan;
        this.nowBedsoreParts = this.BedsoreParts.filter(
          part => part.time_div == TIME_DIV_NOW
        );
        this.oldBedsoreParts = this.BedsoreParts.filter(
          part => part.time_div == TIME_DIV_OLD
        );
        this.existBedsorePartItems = res.data.bedsore_plan_parts;
        this.bedsorePartNames = res.data.bedsore_part_names;
        this.bedsorePartItem = res.data.bedsore_part_item;
        this.bedsoreRiskFactorItem = res.data.bedsore_risk_factor_item;
        this.createPlanStaffItems = res.data.create_plan_staffs;
        if (this.bedsorePlanId === 0) {
          this.bedsorePlan.remand = BedsorePlanRemandDefaultValues();
        }
        this.execAfterFetch(this.StorageKey, data => {
          this.bedsorePlan = data.bedsorePlan;
          this.displayPreview = data.displayPreview;
          this.nowBedsoreParts = data.nowBedsoreParts;
          this.oldBedsoreParts = data.oldBedsoreParts;
        });
      }
    );
  }

  private getLinks(nowBedsoreParts: BedsorePlanPart[]): Link[] {
    const links: Link[] = [];

    if (this.bedsorePlan.remand.id && this.bedsorePlan.remand.editor_staff_id) {
      links.push({
        text: "差戻し情報",
        key: "remand",
        add_info: this.bedsorePlan.remand.comment
      });
    }

    links.push(
      {
        text: "褥瘡の有無",
        key: "bedsore"
      },
      {
        text: "危険因子の評価",
        key: "risk_factor"
      },
      {
        text: "看護計画",
        key: "care_plan"
      }
    );

    // 現在褥瘡のある部位の絞り込み
    const existParts = nowBedsoreParts.filter(
      part => part.bedsore_part_id !== 0
    );
    if (existParts.length == 0) {
      return links;
    }
    const existPartLinks: Link[] = existParts.map(part => {
      const partItemIndex = this.bedsorePartNames.findIndex(
        item => item.bedsore_part_id == part.bedsore_part_id
      );
      const jokusouName = this.bedsorePartName(
        this.bedsorePartNames[partItemIndex],
        part.sort_no
      );

      return {
        text: `褥瘡の状態（${jokusouName}）`,
        key: part.sort_no.toString()
      };
    });
    Array.prototype.push.apply(links, existPartLinks);

    return links;
  }

  private get BedsoreParts(): BedsorePlanPart[] {
    const parts: BedsorePlanPart[] = [];
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < 3; j++) {
        const time_div = i + 1;
        const sort_no = j + 1;

        const existParts = this.bedsorePlan.parts.filter(
          part => part.time_div == time_div && part.sort_no == sort_no
        );
        // データがない場合初期化
        if (existParts.length == 0) {
          parts.push(BedsorePlanPartDefaultValues(time_div, sort_no));
        } else {
          parts.push(existParts[0]);
        }
      }
    }
    return parts;
  }

  private get ExistNowBedsoreParts(): BedsorePlanPart[] {
    return this.nowBedsoreParts.filter(
      part =>
        this.links.findIndex(link => link.key == part.sort_no.toString()) != -1
    );
  }

  private get IsExistNowBedsore(): boolean {
    return (
      this.nowBedsoreParts.filter(
        part => part.time_div == TIME_DIV_NOW && part.bedsore_part_id != 0
      ).length != 0
    );
  }

  private get ExistBedsoreParts(): BedsorePlanPart[] {
    const parts: BedsorePlanPart[] = [];
    Array.prototype.push.apply(parts, this.nowBedsoreParts);
    Array.prototype.push.apply(parts, this.oldBedsoreParts);
    return parts.filter(part => part.bedsore_part_id != 0);
  }

  private bedsorePartName(item: BedsorePartName, sort_no: number): string {
    if (item.bedsore_part_id > 6) {
      // その他部位名取得
      const index = this.nowBedsoreParts.findIndex(
        part => part.time_div == TIME_DIV_NOW && part.sort_no == sort_no
      );
      if (index == -1) {
        return "";
      }
      return this.nowBedsoreParts[index].other_bedsore_part;
    }
    return item.name;
  }

  private isDuplicateExistParts(): boolean {
    const isNowDuplicate = this.nowBedsoreParts.some(
      (part, idx, self) =>
        part.bedsore_part_id != 0 &&
        part.bedsore_part_id != 7 &&
        self.some(
          (selfItem, selfIdx) =>
            idx != selfIdx && selfItem.bedsore_part_id == part.bedsore_part_id
        )
    );
    const isOldDuplicate = this.oldBedsoreParts.some(
      (part, idx, self) =>
        part.bedsore_part_id != 0 &&
        part.bedsore_part_id != 7 &&
        self.some(
          (selfItem, selfIdx) =>
            idx != selfIdx && selfItem.bedsore_part_id == part.bedsore_part_id
        )
    );
    return isNowDuplicate == true || isOldDuplicate == true;
  }

  //褥瘡保存
  private async saveBedsorePlan(isMove: boolean): Promise<void> {
    // 重複チェック
    if (this.isDuplicateExistParts() == true) {
      await this.$openAlert("褥瘡の有無で同じ部位が選択されています。");
      return;
    }

    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/bedsoreplan/save", false, isMove);
    });
  }

  //褥瘡提出
  private async submitBedsorePlan(isPreview = false): Promise<void> {
    // 重複チェック
    if (this.isDuplicateExistParts() == true) {
      await this.$openAlert("褥瘡の有無で同じ部位が選択されています。");
      return;
    }
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.saveAction(2, "/api/patient/bedsoreplan/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.bedsorePlan.status = status;

    // 褥瘡のある部位のみ保存
    this.bedsorePlan.parts = this.ExistBedsoreParts;
    this.bedsorePlan.patient_id = this.patientId;
    this.makePdf(
      window.base_url + url,
      { bedsore_plan: this.bedsorePlan, is_preview: isPreview },
      res => {
        this.setNoEditMode();
        if (isMove) {
          this.$router.go(-1);
        } else {
          this.bedsorePlanId = res.data.id;
          this.bedsorePlan.id = res.data.id;
          this.bedsorePlan.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { bedsore_plan_id: String(this.bedsorePlanId) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //褥瘡削除
  private deleteBedsorePlan(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/bedsoreplan/delete",
      { bedsore_plan: this.bedsorePlan },
      () => {
        this.setNoEditMode();
        this.$router.go(-1);
      }
    );
  }

  //プレビュー
  private preview(): void {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.bedsorePlan.patient_id = this.patientId;
      // プレビュー時に褥瘡のある部位を表示するため褥瘡部位をセット
      this.bedsorePlan.parts = this.ExistBedsoreParts;
      this.makePdf(
        window.base_url + "/api/patient/bedsoreplan/preview",
        { bedsore_plan: this.bedsorePlan },
        () => {
          this.setNoEditMode();
        }
      );
    });
  }
}
