






































import { Component, Prop, Emit, Ref, Mixins } from "vue-property-decorator";
import {
  ObservationItem,
  DefaultObservationItem
} from "@/views/master/adlcontent/types";
import { VForm } from "@/types";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";
import MasmenMixin from "@/mixins/masmenMixin";

@Component({
  components: { OfficeSelectInput }
})
export default class EditPrimaryContentDialog extends Mixins(MasmenMixin) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {
        id: null,
        name: "",
        observation_category_item_id: null,
        observation_select_items: [],
        observation_item_offices: []
      };
    }
  })
  content!: ObservationItem;

  @Prop({ default: () => [] }) observeItemCategories!: [];

  private cloneContent: ObservationItem = DefaultObservationItem();

  private get IsDelete() {
    return this.cloneContent.id;
  }

  created() {
    this.cloneContent = { ...this.content };
  }

  //選択済みの適応事業所
  public selectedOffice: number[] = [];

  private mounted(): void {
    if (this.cloneContent.observation_item_offices.length > 0) {
      this.cloneContent.observation_item_offices.forEach(office => {
        if (office.office_id) {
          this.selectedOffice.push(office.office_id);
        }
      });
    } else {
      this.selectedOffice = [];
    }
  }

  //保存
  private async save() {
    if (!this.form.validate()) {
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.cloneContent.observation_item_offices = [];
    if (this.selectedOffice.length > 0) {
      this.selectedOffice.forEach(officeId => {
        this.cloneContent.observation_item_offices.push({
          office_id: officeId
        });
      });
    }
    this.saveDialog(this.cloneContent);
  }

  //削除
  private async remove() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.removeDialog(this.cloneContent);
  }

  //キャンセル
  private cancel() {
    this.closeDialog();
  }

  @Emit("save")
  private saveDialog(content: unknown) {
    return content;
  }

  @Emit("remove")
  private removeDialog(content: unknown) {
    return content;
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
