import * as types from "./types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const state: types.BbsState = {
  ibowHeadlines: [],
  ewellHeadlines: [],
  supportHeadlines: []
};

const namespaced = true;

export const bbs: Module<types.BbsState, RootState> = {
  namespaced,
  state,
  mutations,
  getters
};
