






























































































































































import { Mixins, Component, Prop, Watch, Ref } from "vue-property-decorator";
import store from "@/store";
import { VForm } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import EditMixin from "@/mixins/editMixin";
import PdfLinkMixin from "@/mixins/pdfLinkMixin";
import { PdfLink, PdfLinkMail, PdfLinkReport } from "#/model/patient/pdfLink";
import { PDF_LINK_STATUS } from "@/components/patient/pdflink/list";
import {
  Headers as HeadersPdf,
  Item as ItemPdf,
  DefaultSearchCond as DefaultSearchCondPdf
} from "@/components/patient/pdf/list";
import {
  Headers as HeadersPersonnel,
  Item as ItemPersonnel,
  DefaultItem as DefaultItemPersonnel,
  INSTITUTION_NUMBER,
  INSTITUTION_STRING
} from "@/components/master/doctorpersonnel/list";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import MailPreviewDialog from "@/components/patient/pdflink/MailPreviewDialog.vue";
import SelectPersonnel from "@/components/master/doctorpersonnel/SelectPersonnel.vue";
import SelectPdf from "@/components/patient/pdf/Select.vue";
import * as patient from "@/store/modules/patient/types";

@Component({
  components: {
    PatientExpantionAreas,
    PatientSaveArea,
    MailPreviewDialog,
    SelectPersonnel,
    SelectPdf
  }
})
export default class PatientPdfLinkMailEdit extends Mixins(
  UtilMixin,
  PatientMixin,
  EditMixin,
  PdfLinkMixin
) {
  /** 利用者ヘッダー */
  @Prop() readonly pheader!: PatientHeader;

  @Ref("formLinkMail") private readonly formLinkMail!: VForm;
  @Ref("formPdfSearch") private readonly formPdfSearch!: VForm;

  private HeadersPersonnel = HeadersPersonnel;
  private HeadersPdf = HeadersPdf;

  private patientId = 0;
  private pdfLinkId = 0;
  private editMode = "";

  /** 検索条件：PDF一覧 */
  private searchCondPdf = DefaultSearchCondPdf();
  /** 検索条件をリセットする：PDF一覧 */
  private resetSearchCondPdf() {
    this.searchCondPdf = DefaultSearchCondPdf();
  }

  /** 検索パネルを開いているか：PDF一覧 */
  private isOpenPdfSearchPanel = false;
  /** 検索パネルを開く：PDF一覧 */
  private openPdfSearchPanel() {
    this.isOpenPdfSearchPanel = true;
  }

  /** コピー作成モードであるか */
  private get IsCopyMode() {
    return this.editMode === "copy";
  }

  /** 利用者ヘッダーの下タイトル */
  private get Title() {
    if (this.pdfLink.id > 0 && !this.IsCopyMode) {
      return "PDFリンクメールを編集する";
    } else {
      return "PDFリンクメールを作成する";
    }
  }

  /** 絞り込み検索しているか */
  private get IsSearchFiltered() {
    const defaultCond = DefaultSearchCondPdf();
    return (
      this.searchCondPdf.writeStart !== defaultCond.writeStart ||
      this.searchCondPdf.writeEnd !== defaultCond.writeEnd
    );
  }

  /** 担当者一覧 */
  private get PatientPersonnels() {
    return this.EnumerablePersonnel(
      this.patientMaster.relation_institutions,
      this.patientMaster.medical_institutions
    );
  }

  /** 一覧に表示できる担当者一覧 */
  protected get EnumerablePersonnel() {
    return (
      relatInsts: patient.RelationInstitutionPatient[],
      medicalInsts: patient.MedicalInstitutionPatient[]
    ) => {
      const personnels: ItemPersonnel[] = [];
      // 関連機関担当者から
      for (const relation of relatInsts) {
        for (const personnel of relation.personnels) {
          const itemPersonnel = this.convertPersonnelToItem(personnel);
          if (itemPersonnel) {
            personnels.push(itemPersonnel);
          }
        }
      }
      // 医療機関医師から
      for (const medical of medicalInsts) {
        for (const doctor of medical.doctors) {
          const itemPersonnel = this.convertDoctorToItem(doctor);
          if (itemPersonnel) {
            personnels.push(itemPersonnel);
          }
        }
      }
      return personnels;
    };
  }

  //編集モード設定
  @Watch("pdfLink", { deep: true }) private watchPdfLink() {
    this.setEdit();
  }
  private setEdit() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  // 画面再読み込み後、PDFリンクより後に利用者マスタ機関を取得した場合でも、選択一覧を更新する
  @Watch("patientMaster.relation_institutions") private watchMasterRelation() {
    this.onChangePatientMaster();
  }
  @Watch("patientMaster.medical_institutions") private watchMasterMedical() {
    this.onChangePatientMaster();
  }
  private isLockMasterChangeEvent = false;
  private onChangePatientMaster() {
    // 医療機関も関連機関もあれば両方同時に呼ばれるので、2回目は何もしない
    if (!this.isLockMasterChangeEvent && this.pdfLink.mails.length > 0) {
      this.fromSelectedPersonnels(this.pdfLink.mails);
      this.isLockMasterChangeEvent = true;
    }
    this.$nextTick(() => (this.isLockMasterChangeEvent = false));
  }

  created() {
    this.patientId = Number(this.$route.params.id);
    this.pdfLinkId = Number(this.$route.params.pdf_link_id);
    this.editMode = this.$route.query.mode as string;
    this.searchCondPdf = DefaultSearchCondPdf();
    if (this.pdfLinkId > 0) {
      // 編集時は選んでいたPDFが確実に選択されるように、検索期間を広げる
      this.searchCondPdf.writeStart = "";
      this.searchCondPdf.writeEnd = "";
    } else {
      this.pdfLink.patient_id = this.patientId;
      this.pdfLink.editor_staff_id = this.loginUser.id;
      // デフォルトの有効期限は翌月末
      const now = new Date();
      const expDate = new Date(now.getFullYear(), now.getMonth() + 2, 0);
      this.pdfLink.expired_date = this.dateToStr(expDate, "yyyy-MM-dd");
    }

    Promise.allSettled([
      this.fetchPdfs(this.patientId, this.searchCondPdf),
      this.fetchPdfLink(this.pdfLinkId)
    ]).then(() => {
      if (this.pdfLinkId > 0) {
        this.fromSelectedPersonnels(this.pdfLink.mails);
        this.fromSelectedPdfs(this.pdfLink.reports);
        setTimeout(() => {
          this.setLoaded();
          if (this.IsCopyMode) {
            // 特に期限切れPDFリンクのコピー時にエラーを表示したい
            this.formLinkMail?.validate();
          }
        }, 200);
      } else {
        this.setLoaded();
      }
    });
  }

  mounted() {
    this.setDefaultHeader();
  }

  /** 前回の保存時に選択していた担当者を、選択状態にする */
  private fromSelectedPersonnels(mails: PdfLinkMail[]) {
    if (mails == null) {
      return; // 取得件数が0件の時nullになる
    }
    this.selectedPersonnels = this.PatientPersonnels.filter(personnel => {
      // 保存していた選択情報のうち、一覧に存在するもののみ選択状態にする
      return mails.find(
        mail =>
          personnel.personnel_id === mail.personnel_id &&
          personnel.institution_div === mail.institution_div
      );
    });
  }

  /** 前回の保存時に選択していた帳票を、選択状態にする */
  private fromSelectedPdfs(reports: PdfLinkReport[]) {
    if (reports == null) {
      return; // 取得件数が0件の時nullになる
    }
    this.selectedPdfs = this.pdfs.filter(pdf => {
      // 保存していた選択情報のうち、一覧に存在するもののみ選択状態にする
      return reports.find(
        report =>
          report.report_id === pdf.report_id &&
          report.report_type === pdf.report_type
      );
    });
  }

  /** 変換：利用者マスタ担当者→表項目 */
  private convertPersonnelToItem(
    personnel: patient.PersonnelPatient
  ): ItemPersonnel | undefined {
    if (personnel.status !== 1) {
      return; // 在職のみが対象
    }
    const mailContact = this.findMailContact(personnel.personnel_contacts);
    if (!mailContact) {
      return; // メールアドレスが登録されていない場合
    }

    return {
      ...DefaultItemPersonnel(),
      list_id: `${INSTITUTION_NUMBER.RELATION}_${personnel.id}`,
      mail_address: mailContact.contact_info,
      personnel_id: personnel.id,
      personnel_name: personnel.full_name,
      section_name: personnel.section_name,
      institution_div: INSTITUTION_NUMBER.RELATION,
      institution_div_name: INSTITUTION_STRING.RELATION,
      institution_id: personnel.relation_institution_id,
      institution_name: personnel.relation_institution_name
    };
  }

  /** 変換：利用者マスタ医師→表項目 */
  private convertDoctorToItem(
    doctor: patient.DoctorPatient
  ): ItemPersonnel | undefined {
    if (doctor.status !== 1) {
      return; // 在職のみが対象
    }
    const mailContact = this.findMailContact(doctor.doctor_contacts);
    if (!mailContact) {
      return; // メールアドレスが登録されていない場合
    }

    return {
      ...DefaultItemPersonnel(),
      list_id: `${INSTITUTION_NUMBER.MEDICAL}_${doctor.id}`,
      mail_address: mailContact.contact_info,
      personnel_id: doctor.id,
      personnel_name: doctor.full_name,
      section_name: doctor.section_name,
      institution_div: INSTITUTION_NUMBER.MEDICAL,
      institution_div_name: INSTITUTION_STRING.MEDICAL,
      institution_id: doctor.medical_institution_id,
      institution_name: doctor.medical_institution_name ?? ""
    };
  }

  /** 送信先担当者や帳票を絞った、送信用PDFリンクメール */
  private toSendablePdfLink(pdfLink: PdfLink, isCopy: boolean) {
    this.pdfLink.mails = this.selectedPersonnels;
    this.pdfLink.reports = this.selectedPdfs;
    const reqPdfLink = this.deepCopy(pdfLink);

    if (isCopy) {
      // IDを初期化することで、コピー情報として保存できるようにする
      reqPdfLink.mails.forEach(mail => (mail.id = 0));
      reqPdfLink.reports.forEach(report => (report.id = 0));
    }

    return reqPdfLink;
  }

  /** 検索リクエスト送信前にバリデーション：PDF一覧 */
  private async onSubmitPdfSearch() {
    if (!this.formPdfSearch.validate()) {
      await this.$openAlert("入力内容に不備があります");
      // 検索条件に不備があれば、パネルを開く
      this.openPdfSearchPanel();
      return;
    }
    // 未編集で検索した場合は、編集中状態にならないように
    const editing = store.state.is_edit;
    this.fetchPdfs(this.patientId, this.searchCondPdf).then(() => {
      if (!editing) {
        setTimeout(this.setNoEditMode, 400);
      }
    });
  }

  /** 選択したPDFを表示 */
  private async openPdfs(selects: ItemPdf[]) {
    if (!selects.length) {
      await this.$openAlert("PDFを選択してください");
      return;
    }
    const pdfPaths = selects.map(item => item.pdf_path);
    this.postJsonCheck(
      window.base_url + "/api/reportlist/pdf/join",
      { str_pdfs: pdfPaths },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        window.open(res.data.PdfPath, "_blank");
      }
    );
  }

  /** メール内容をプレビュー */
  private previewMailContent() {
    const reqPdfLink = this.toSendablePdfLink(this.pdfLink, false);
    reqPdfLink.resend = this.IsCopyMode ? 1 : 0;
    this.postJsonCheck(
      window.base_url + "/api/patient/pdflink/preview",
      { pdf_link: reqPdfLink },
      res => {
        this.previewMailSubject = res.data.subject;
        this.previewMailBody = res.data.body;
        this.isOpenPreviewDialog = true;
      }
    );
  }

  /** PDFリンクメールを保存する */
  private save(status: number, callback?: Function) {
    const reqPdfLink = this.toSendablePdfLink(this.pdfLink, true);
    reqPdfLink.resend = this.IsCopyMode ? 1 : 0;

    let reqUrl = window.base_url + "/api/patient/pdflink/save";
    if (status === PDF_LINK_STATUS.SEND) {
      reqUrl = window.base_url + "/api/patient/pdflink/savesend";
      if (this.IsCopyMode) {
        reqUrl = window.base_url + "/api/patient/pdflink/editsend";
      }
    }

    this.postJsonCheck(reqUrl, { pdf_link: reqPdfLink }, () => {
      this.setNoEditMode();
      callback?.();
    });
  }

  /** ページを離れる */
  private back() {
    this.$router.back();
  }

  /** PDFリンクの下書き一覧画面へ遷移する */
  private routePdfLinkSaves() {
    this.$router.push({
      name: "PatientPdfLinkList",
      params: { id: String(this.patientId) }
    });
  }

  /** PDFリンクの送信済み一覧画面へ遷移する */
  private routePdfLinkSends() {
    this.$router.push({
      name: "PatientPdfLinkList",
      params: { id: String(this.patientId) },
      query: { key: "Send" }
    });
  }

  /** 削除ボタンをクリック */
  private async clickDelete() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }

    const reqPdfLink = this.toSendablePdfLink(this.pdfLink, false);
    this.postJsonCheck(
      window.base_url + "/api/patient/pdflink/delete",
      { pdf_link: reqPdfLink },
      () => {
        this.setNoEditMode();
        this.back();
      }
    );
  }

  /** 保存ボタンをクリック */
  private clickSave() {
    // 保存
    this.save(PDF_LINK_STATUS.SAVE, this.routePdfLinkSaves);
  }

  /** 送信ボタンをクリック */
  private async clickSubmit() {
    if (!this.formLinkMail.validate()) {
      await this.$openAlert(
        "入力内容に不備があります\n" + this.NoticeLinkMailEdit
      );
      return;
    }
    if (!(await this.$openConfirm("メールを送信します。よろしいですか？"))) {
      return;
    }
    // 保存して送信
    this.save(PDF_LINK_STATUS.SEND, this.routePdfLinkSends);
  }
}
