













































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify/types/index";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import { Adl, DefaultAdl, AdlItem } from "#/model/adl";
import PatientMixin from "@/mixins/patientMixin";
import {
  Vnrecord,
  VnrecordDefaultValues,
  AdlItem as VnrecordAdlItem
} from "@/components/patient/vnrecord/types";

export interface DispAdl {
  evaluationDateWareki: string; //評価日・記載日
  evaluationStaffName: string; //評価者・編集者
  adlItems: DispItem[]; //ADL項目
  inpendenceItems: DispItem[]; //日常生活自立度
  isCommentToState: number; //コメント心身の状態反映フラグ
  comment: string; //コメント
}

export interface DispItem {
  name: string; //項目名
  selectName: string; //選択肢名
  isRefer: number; //心身の状態反映フラグ
  check: number; //不問
}

@Component({
  components: {
    PatientHeader
  }
})
export default class AdlConfirmation extends Mixins(AxiosMixin, PatientMixin) {
  private adlId = 0;
  private patientId = 0;
  private vnrecordId = 0;

  private dispAdl: DispAdl = {
    evaluationDateWareki: "",
    evaluationStaffName: "",
    adlItems: [],
    inpendenceItems: [],
    isCommentToState: 0,
    comment: ""
  };

  created(): void {
    this.adlId = Number(this.$route.params.adlid);
    this.patientId = Number(this.$route.params.id);
    this.vnrecordId = Number(this.$route.params.vnrecordid);

    if (this.vnrecordId > 0) {
      this.fetchVnrecordAdl();
    } else {
      this.fetchAdl();
    }
  }

  mounted(): void {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/adl`,
        exact: true
      },
      {
        text: "ADL評価を確認する",
        disabled: true,
        to: ""
      }
    ]);
  }

  private adl: Adl = DefaultAdl(); //ADL

  //Adl取得
  private fetchAdl(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/adl/get",
      {
        patient_id: this.patientId,
        adl_id: this.adlId
      },
      res => {
        this.adl = res.data.adl;

        const adlItems = this.adl.items.map((item: AdlItem) => {
          return {
            name: item.adl_evaluation.name,
            selectName: item.adl_evaluation.select_name,
            isRefer: item.is_refer,
            check: 0
          };
        });

        this.dispAdl = {
          evaluationDateWareki: this.adl.evaluation_date_wareki,
          evaluationStaffName: this.adl.evaluation_staff.ibow_nickname,
          adlItems: adlItems,
          inpendenceItems: [],
          isCommentToState: this.adl.is_comment_to_state,
          comment: this.adl.comment
        };
      }
    );
  }

  private vnrecord: Vnrecord = VnrecordDefaultValues(); //看護記録I

  //看護記録I取得
  private fetchVnrecordAdl(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/vnrecord/detail/get",
      {
        patient_id: this.patientId,
        vnrecord_id: this.vnrecordId
      },
      res => {
        this.vnrecord = res.data.vnrecord;

        const adlItems: DispItem[] = [];
        const inpendenceItems: DispItem[] = [];
        this.vnrecord.adls.forEach((item: VnrecordAdlItem) => {
          if (
            item.vn_record_adl_item_id == 11 ||
            item.vn_record_adl_item_id == 12
          ) {
            // 日常生活自立度
            if (item.support != 0 || item.check == 1) {
              inpendenceItems.push({
                name: item.name,
                selectName: item.support_name,
                isRefer: 0,
                check: item.check
              });
            }
          } else {
            // ADL項目
            if (item.assistance != 0 || item.check == 1) {
              adlItems.push({
                name: item.name,
                selectName: item.assistance_name,
                isRefer: 0,
                check: item.check
              });
            }
          }
        });

        this.dispAdl = {
          evaluationDateWareki: this.vnrecord.write_date_wareki,
          evaluationStaffName: this.vnrecord.editor_staff.ibow_nickname,
          adlItems: adlItems,
          inpendenceItems: inpendenceItems,
          isCommentToState: this.vnrecord.is_comment_to_state,
          comment: this.vnrecord.comment
        };
      }
    );
  }

  private headers: DataTableHeader[] = [
    {
      text: "心身の状態",
      value: "status",
      width: "100px",
      align: "center",
      class: "pa-1",
      sortable: false
    },
    {
      text: "ADL項目",
      value: "adl_item",
      width: "180px",
      align: "start",
      sortable: false
    },
    {
      text: "評価",
      value: "assesment",
      width: "180px",
      align: "start",
      sortable: false
    }
  ];
}
