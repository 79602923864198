






























































import { Component, Emit, Mixins, Ref, Prop } from "vue-property-decorator";
import { VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import { Choice } from "@/types";
import { BulletinBoard, DefaultBulletinBoard } from "#/model/bulletinboard";
import RulesMixin from "#/mixins/rulesMixin";

@Component
export default class extends Mixins(AxiosMixin, RulesMixin) {
  /** 掲示板トピック編集フォーム */
  @Ref("createTopicForm") private readonly createTopicForm!: VForm;

  /** 選択できる事業所一覧 */
  @Prop({ default: () => [] })
  private readonly stations!: Choice[];

  /** ログイン職員の事業所ID */
  @Prop() private readonly officeId!: number;

  /** 0:一般職員 1:一般職員以外（一般職員以外は、新規トピックの作成・自分以外のトピックの削除・自分以外のコメントの削除が可能） */
  @Prop() private readonly authDiv!: number;

  /** データ検索時 */
  @Emit("search") private search(): void {
    return;
  }

  /** 掲示板トピック作成ダイアログ開閉状態 */
  private isOpenCreateDialog = false;

  /** 全事業所選択 */
  private allOffices = 0;

  /** 選択された事業所 */
  private selectStations: number[] = [];

  /** 編集中の掲示板トピック */
  private bulletinBoard: BulletinBoard = DefaultBulletinBoard();

  public open() {
    this.isOpenCreateDialog = true;
    this.bulletinBoard = DefaultBulletinBoard();
    this.allOffices = 0;
    this.selectStations = [];
    if (this.officeId != 0) {
      this.selectStations = [this.officeId];
    }
  }

  //新規トピック作成
  private async create() {
    if (!this.createTopicForm.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    for (const selectStation of this.selectStations) {
      this.bulletinBoard.bulletin_board_offices.push({
        id: 0,
        bulletin_board_id: 0,
        office_id: selectStation,
        created_at: "",
        updated_at: "",
        deleted_at: ""
      });
    }
    this.postJsonCheck(
      window.base_url + "/api/bbs/create",
      {
        bulletin_board: this.bulletinBoard
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.search();
        this.isOpenCreateDialog = false;
      }
    );
  }

  //全事業所選択
  private allStationSelect() {
    this.allOffices = 1;
    this.selectStations = [0];
  }

  //全事業所選択解除
  private allStationRelease() {
    this.allOffices = 0;
    this.selectStations = [];
  }
}
