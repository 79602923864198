import { Component, Mixins, Prop } from "vue-property-decorator";
import { ReceiptVisitRecord } from "#/model/receipt";
import { CalendarEvent } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import { OfficeHistory } from "#/model/agreement";

@Component
export default class CarePartBase extends Mixins(UtilMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  @Prop({ default: () => ({}) }) event!: CalendarEvent;

  @Prop({ default: () => ({}) }) receiptVisitRecord!: ReceiptVisitRecord;

  @Prop({ default: () => ({}) }) officeHistory!: OfficeHistory;

  @Prop({ default: false }) isLoaded!: boolean;

  @Prop({ default: "" }) yearmonth!: string;

  @Prop({ default: false }) preventGensanDivFlg!: boolean;

  @Prop({ default: () => ({}) }) events!: CalendarEvent[];

  //30分未満制御
  protected handleHalfHourFlg() {
    const start = new Date(
      this.event.visit_record.service_start_datetime.replace(/-/g, "/")
    );
    const end = new Date(
      this.event.visit_record.service_end_datetime.replace(/-/g, "/")
    );
    const diff = Math.abs((end.getTime() - start.getTime()) / (60 * 1000));

    if (this.receiptVisitRecord.two_person_flg == 0) {
      this.receiptVisitRecord.half_hour_flg = 0;
    }
    if (
      this.receiptVisitRecord.two_person_flg != 0 &&
      (this.receiptVisitRecord.nursing_time_div == 1 ||
        this.receiptVisitRecord.nursing_time_div == 2 ||
        (this.receiptVisitRecord.staff_div >= 4 && diff == 20))
    ) {
      this.receiptVisitRecord.half_hour_flg = 1;
    } else {
      this.receiptVisitRecord.half_hour_flg = 0;
    }
  }

  /** 退院時共同指導日フラグ */
  protected nursing_leave_inst_flg = false;
}
