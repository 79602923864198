import { render, staticRenderFns } from "./AppDiseaseDialog.vue?vue&type=template&id=2f0a03c7&scoped=true&"
import script from "./AppDiseaseDialog.vue?vue&type=script&lang=ts&"
export * from "./AppDiseaseDialog.vue?vue&type=script&lang=ts&"
import style0 from "./AppDiseaseDialog.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AppDiseaseDialog.vue?vue&type=style&index=1&id=2f0a03c7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0a03c7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VCard,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VProgressCircular,VRow,VSpacer,VVirtualScroll})
