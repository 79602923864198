


































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

import InsurerList from "@/components/master/insurer/InsurerList.vue";

import { Insurer } from "./types";

@Component({
  components: {
    InsurerList
  }
})
export default class InsurerEntry extends Mixins(AxiosMixin) {
  private search = "";

  private insurers: Insurer[] = [];

  public created() {
    this.fetchInsurers();
  }

  private fetchInsurers(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/insurers/get",
      {},
      res => {
        this.insurers = res.data.insurers;
      }
    );
  }
}
