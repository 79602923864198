



























































































































import { Component, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import AxiosMixin from "@/mixins/axiosMixin";
import InviteChip from "@/components/master/staff/InviteChip.vue";
import CompleteChip from "@/components/master/staff/CompleteChip.vue";
import SearchPanel from "#/components/SearchPanel.vue";
import { Choice } from "@/types";
import { StaffItem } from "@/components/master/staff/types";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import UtilMixin from "@/mixins/utilMixin";
import * as constant from "#/const";

@Component({
  components: {
    InviteChip,
    CompleteChip,
    OfficeSelect,
    SearchPanel
  }
})
export default class StaffList extends Mixins(AxiosMixin, UtilMixin) {
  private headers: DataTableHeader[] = [
    {
      text: "iBowID",
      value: "ibow_id",
      width: "140px"
    },
    {
      text: "職員名",
      value: "ibow_nickname",
      width: "160px"
    },
    {
      text: "事業所",
      value: "office_name",
      width: "256px"
    },
    {
      text: "職種",
      value: "staff_div_name",
      width: "90px"
    },
    {
      text: "状態",
      value: "status_name",
      width: "80px"
    },
    {
      text: "権限",
      value: "auth_name",
      width: "130px"
    },
    {
      text: "招待状況",
      value: "condition",
      width: "100px"
    },
    {
      text: "",
      value: "button",
      width: "120px",
      align: "end",
      sortable: false
    }
  ];

  // 検索サイドバーを開いているか
  private searchPanel = false;

  private pageNo = 1; //ページ番号

  private itemsPerPage = 25; //1ページあたりの項目数

  private items: StaffItem[] = []; //職員データ

  private keyword = ""; //職員名(全角)・ID(半角)

  private orderby = 1; //並び替え

  private orderbyType = 1; //並び替え順序

  private officeId = 0; //事業所ID

  //並び替え選択リスト
  private orderItems: Choice[] = [
    {
      text: "氏名順",
      value: 1
    },
    {
      text: "登録順",
      value: 2
    },
    {
      text: "ID順",
      value: 3
    }
  ];

  //並び替え順序選択リスト
  private orderTypeItems: Choice[] = [
    {
      text: "↓",
      value: 1
    },
    {
      text: "↑",
      value: 2
    }
  ];

  //状態
  private status_zai = 1; //在職
  private status_kyu = 0; //休職
  private status_tai = 0; //退職

  private get GetSearchHeader(): string {
    let header = "検索";
    if (this.keyword) {
      header += "/" + this.keyword;
    }
    if (this.status_zai) {
      header += "/在職";
    }
    if (this.status_kyu) {
      header += "/休職";
    }
    if (this.status_tai) {
      header += "/退職";
    }
    switch (this.orderby) {
      case 1:
        header += "/氏名順";
        break;
      case 2:
        header += "/登録順";
        break;
      case 3:
        header += "/ID順";
        break;
    }
    switch (this.orderbyType) {
      case 1:
        header += "↓";
        break;
      case 2:
        header += "↑";
        break;
    }
    if (this.officeId) {
      const office = this.masters.group_offices.find(
        x => x.value === this.officeId
      );
      if (office) {
        header += "/" + office.text;
      }
    }
    return header;
  }

  // 新規登録ボタン表示フラグ
  private get showNew(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_STAFF,
      constant.AGREEMENT.FUNCTION_DIV_INSERT
    );
  }

  // 絞り込みしているかどうか
  private get IsFiltered(): boolean {
    return !(
      this.officeId === this.loginUser.office_id &&
      this.status_zai === 1 &&
      this.status_kyu === 0 &&
      this.status_tai === 0
    );
  }

  mounted() {
    if (typeof this.$route.query.keyword === "string") {
      this.keyword = this.$route.query.keyword;
    }
    this.setDefaultSearch();
    this.updateData();
  }

  // デフォルトの検索条件を設定、クエリパラエータが優先
  private setDefaultSearch() {
    this.officeId = this.loginUser.office_id;
    if (typeof this.$route.query.office_id === "string") {
      this.officeId = Number(this.$route.query.office_id);
    }
    this.status_zai = 1;
    if (typeof this.$route.query.status_zai === "string") {
      this.status_zai = Number(this.$route.query.status_zai);
    }
    this.status_kyu = 0;
    if (typeof this.$route.query.status_kyu === "string") {
      this.status_kyu = Number(this.$route.query.status_kyu);
    }
    this.status_tai = 0;
    if (typeof this.$route.query.status_tai === "string") {
      this.status_tai = Number(this.$route.query.status_tai);
    }
    this.orderby = 1;
    if (typeof this.$route.query.orderby === "string") {
      this.orderby = Number(this.$route.query.orderby);
    }
    this.orderbyType = 1;
    if (typeof this.$route.query.orderby_type === "string") {
      this.orderbyType = Number(this.$route.query.orderby_type);
    }
  }

  private updateData() {
    // 検索済みデータをリセット
    this.items = [];

    const statuses: number[] = [];
    // 在職
    let query_zai = "0";
    if (this.status_zai) {
      statuses.push(1);
      query_zai = "1";
    }
    // 休職
    let query_kyu = "0";
    if (this.status_kyu) {
      statuses.push(2);
      query_kyu = "1";
    }
    // 退職
    let query_tai = "0";
    if (this.status_tai) {
      statuses.push(3);
      query_tai = "1";
    }
    // どのステータスも指定されなかった
    if (statuses.length == 0) {
      statuses.push(0);
    }

    //クエリパラメータにいれる
    this.$router.replace({
      query: {
        keyword: this.keyword,
        status_zai: query_zai,
        status_kyu: query_kyu,
        status_tai: query_tai,
        orderby: String(this.orderby),
        orderby_type: String(this.orderbyType),
        office_id: String(this.officeId)
      }
    });

    this.postJsonCheck(
      window.base_url + "/api/master/staff-list/get",
      {
        statuses: statuses,
        keyword: this.keyword,
        orderby: this.orderby,
        orderby_type: this.orderbyType,
        office_id: this.officeId
      },
      res => {
        this.items = res.data.staffs;
      }
    );
  }

  private clickRow(row: StaffItem) {
    this.$router.push({ name: "StaffEdit", params: { id: row.id.toString() } });
  }

  // シフト登録
  private clickShift(row: StaffItem) {
    this.$router.push({
      name: "MasterStaffShift",
      params: {
        staff_id: String(row.id),
        office_id: String(row.office_id)
      }
    });
  }

  //絞り込み
  private filterItems(value: unknown, search: string, item: StaffItem) {
    if (search == null) {
      return false;
    }

    if (item == null) {
      return false;
    }
    if (
      item.ibow_nickname.match(search) ||
      item.ibow_nickname_furigana.match(search) ||
      item.ibow_id.match(search) ||
      item.auth_name.match(search) ||
      item.office_name.match(search) ||
      item.staff_div_name.match(search) ||
      item.status_name.match(search)
    ) {
      return true;
    }

    return false;
  }

  /** ページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }
}
