





































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class SelectYearMonthDialog extends Mixins(AxiosMixin) {
  @Prop({ default: 0 }) officeId!: number;
  @Prop({ default: false }) dialog!: number;
  @Emit()
  private close() {
    return;
  }

  created() {
    this.yearmonth = this.thisMonth();
  }
  private yearmonth = "";

  private thisMonth(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    const thisMonth = date.toISOString().substr(0, 7);
    return thisMonth;
  }

  private linkShift() {
    this.postJsonCheck(
      window.base_url + "/api/officeshift/kintai/update",
      { yearmonth: this.yearmonth, office_id: this.officeId },
      async () => {
        await this.$openAlert(" iBow KINTAIシフト一括取り込みが完了しました。");
        this.close();
      }
    );
    return;
  }
}
