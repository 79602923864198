























import {
  Component,
  Prop,
  Watch,
  Mixins,
  Ref,
  Emit
} from "vue-property-decorator";
import { VForm, Choice } from "@/types";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {}
})
export default class PatientShareFileBulkUpdateDialog extends Mixins(
  RulesMixin,
  UtilMixin
) {
  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** ダイアログ制御用 */
  @Prop() dialog!: boolean;

  /** 選択可能タグ情報 */
  @Prop() tags!: Choice[];

  /** 選択中のタグID */
  private tagIds: number[] = [];

  /** 初期化処理 */
  @Watch("dialog")
  private init() {
    this.tagIds = [];
    this.$nextTick(() => {
      this.form.resetValidation();
    });
  }

  /** キャンセルクリック時 */
  @Emit("click:close")
  public onCancel() {
    return;
  }

  /** 保存クリック時 */
  public async onSave() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.save();
  }

  @Emit("click:save")
  public save() {
    return this.tagIds;
  }
}
