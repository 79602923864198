















































import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { DispTemplate, DEFAULT_DISP_TEMPLATE } from "@/views/contact/types";
import FAQContentDetail from "@/components/contact/FAQContentDetail.vue";

@Component({
  components: {
    FAQContentDetail
  }
})
export default class RelatedFAQList extends Mixins(UtilMixin, AxiosMixin) {
  @Prop({ default: 0 }) templateId!: number;

  /** FAQテンプレートの表示件数 */
  private TEMPLATE_LIMIT = 5;

  /** FAQテンプレート情報 */
  private template: DispTemplate = { ...DEFAULT_DISP_TEMPLATE };

  /** 関連するFAQテンプレート一覧 */
  private relatedTemplates: DispTemplate[] = [];

  /** そのFAQの小カテゴリID、無ければ中大カテゴリID */
  private get childCategoryId() {
    const flatCategories = this.template.flat_categories;
    if (flatCategories.length === 0) {
      return 0; // FAQはカテゴリが必須で、lengthが0ならFAQカテゴリを調べられない
    }
    const childCategory = flatCategories[flatCategories.length - 1];
    return childCategory.category_id;
  }

  /** そのFAQのハッシュタグ */
  private get Keywords() {
    if (this.template.template_hashtags == null) return [];
    return this.template.template_hashtags.map(tag => tag.hashtag);
  }

  mounted() {
    this.fetchFAQ();
  }

  /** FAQテンプレート情報を取得 */
  private fetchFAQ() {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/templates/get",
      { ids: [this.templateId] },
      res => {
        if (!res.data.templates) return;
        this.template = res.data.templates[0];
        this.fetchFAQList();
      }
    );
  }

  /** 関連するFAQテンプレート一覧を取得 */
  private fetchFAQList() {
    this.relatedTemplates = [];
    // 表示しているFAQは一覧から除外するため、あらかじめ1つ多めに取っておく
    // ハッシュタグは1番目のみを検索対象とする
    this.postJsonCheck(
      window.base_url + "/api/inquiry/templates/get",
      {
        category_id: this.childCategoryId,
        hashtag: this.Keywords[0] ?? "",
        limit: this.TEMPLATE_LIMIT + 1
      },
      res => {
        if (!res.data.templates) return;
        const templates: DispTemplate[] = res.data.templates;
        // 表示しているFAQは一覧から除く
        this.relatedTemplates = templates
          .filter(faq => faq.id !== this.template.id)
          .slice(0, this.TEMPLATE_LIMIT);
      }
    );
  }
}
