import Base from "../base";

/* 事業所履歴-同一建物 */
export interface OfficeHistoryOverTherapistVisitCount extends Base {
  id: number; //ID
  office_history_id: number; //'事業所履歴ID',
  year: number;
  is_over: number;
}

export const DefaultOfficeHistoryOverTherapistVisitCount = (): OfficeHistoryOverTherapistVisitCount => ({
  id: 0,
  office_history_id: 0,
  year: 0,
  is_over: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
