



























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { TableItem } from "../types/table";
import AppDataTable from "./AppDataTable.vue";

/** 表の行要素に指定するクラス */
type ItemClass = (item: TableItem) => string;

/** 項目を選択できるかの判定 */
type JudgeSelectable = (item: TableItem) => boolean;

@Component({
  components: {
    AppDataTable
  }
})
export default class AppSelectTable extends Vue {
  /** 表項目 */
  @Prop({ default: () => [] }) items!: TableItem[];

  /** 選択可能な項目の内選択済み */
  @Prop({ default: () => [] }) value!: TableItem[];

  /** 行クラスの設定条件 */
  @Prop({ default: null }) itemClass!: ItemClass;

  /** 項目を選択できる条件 */
  @Prop({ default: () => () => true }) isSelectable!: JudgeSelectable;

  @Emit()
  private input(selects: TableItem[]) {
    return selects;
  }

  private get Value() {
    return this.value;
  }

  private set Value(value: TableItem[]) {
    const filteredItems = this.items.filter(this.isSelectable);
    let isNeedAllUnSelect = false;
    // 全選択した時に除外すべき項目があって
    if (this.items.length > filteredItems.length) {
      // 元々選択可能なものは全て選択していて、全選択操作をした時
      if (
        this.value.length === filteredItems.length &&
        value.length === this.items.length
      ) {
        // 全解除操作をする
        isNeedAllUnSelect = true;
      }
    }
    this.$nextTick(() => {
      if (isNeedAllUnSelect) {
        // AppDataTableのinputイベントは複数回走るので、終わるのを待つ
        setTimeout(() => {
          this.input([]);
        }, 60);
      } else {
        this.input(value.filter(this.isSelectable));
      }
    });
  }

  private get ScopeCols() {
    const slots: string[] = [];
    // 各行のチェックボックス以外は、使う側で記したslotをそのままAppDataTableに渡す
    for (const scope in this.$scopedSlots) {
      if (scope === "item.data-table-select") {
        continue;
      }
      slots.push(scope);
    }
    return slots;
  }

  private rowClass(item: TableItem) {
    // 使用箇所ごとの行クラス設定条件を優先
    if (this.itemClass) {
      return this.itemClass(item);
    }
    // 選択不可の列はグレーアウトする
    if (!this.isSelectable(item)) {
      return "blue-grey lighten-4";
    }
    return "";
  }
}
