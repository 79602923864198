






















import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    PatientHeader
  }
})
export default class TextDialogItem extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Prop() label!: string;
  @Prop() value!: string;
  @Prop() areaLabel!: string;

  @Emit()
  private save(): string {
    return this.innerValue;
  }

  private dialog = false;
  private innerValue = "";

  public open() {
    this.dialog = true;
    this.$nextTick(() => {
      this.innerValue = this.value;
    });
  }

  public checkDiff() {
    if (this.innerValue !== this.value) {
      this.$openConfirm("変更した内容が破棄されます。よろしいですか？").then(
        ok => {
          if (ok) {
            this.close();
          }
        }
      );
    } else {
      this.close();
    }
  }

  public close() {
    this.dialog = false;
  }
}
