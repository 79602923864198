




import { Component, Vue } from "vue-property-decorator";

@Component
export default class AiRecommendDiseaseButton extends Vue {
  /** 病名一覧を別画面で表示 */
  private open() {
    const route = this.$router.resolve({
      name: "AiRecommendDisease"
    });
    window.open(route.href, "_blank");
  }
}
