import Base from "../base";

//高額療養費
export interface MedicalInsuranceHighExpense extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  add_div: number; //'追加元の種類 0:新規作成 1:限度額適用認定証 2:医療保険(兼高齢) 3:高齢受給者証',
  add_id: number; //'追加元の保険/証明書ID',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  high_expense_term_id: number; //'医療保険_高額療養費期間ID',
  high_expense_div_id: number; //'医療保険_高額療養費区分ID',
  special_note_code: string; //'医療保険_特記事項コード',
  amount: number; //'その他金額',
  div_name: string; //高額療養費設定名
  div_text: string; //高額療養費表示文言
  age_div: number; //適用年齢区分\n1:70歳未満\n2:70歳以上
  fixed_amount: number; //固定利用者負担金額
  minus_amount: number; //医療費減算金額
  burden_ratio: number; //利用者負担割合（％）
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
  special_note_name: string; //'医療保険_特記事項略号',
}
export const DefaultMedicalInsuranceHighExpense = (): MedicalInsuranceHighExpense => ({
  id: 0,
  patient_id: 0,
  add_div: 0,
  add_id: 0,
  start_date: "",
  end_date: "",
  high_expense_term_id: 4,
  high_expense_div_id: 0,
  special_note_code: "",
  amount: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  div_name: "",
  div_text: "",
  age_div: 0,
  fixed_amount: 0,
  minus_amount: 0,
  burden_ratio: 0,
  start_date_wareki: "",
  end_date_wareki: "",
  special_note_name: ""
});
