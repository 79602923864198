























































import { Component, Mixins, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import UtilMixin from "@/mixins/utilMixin";
import { INQUIRY_STATUS, INQUIRY_STATUS_COLOR } from "@/const/contact";
import { ListInquiry } from "@/views/contact/types";

@Component
export default class ContactList extends Mixins(UtilMixin) {
  /** 問合せ情報 */
  @Prop({ default: () => [] }) inquirys!: ListInquiry[];

  /** 部分表示として利用するか（一覧か問合せメインページか） */
  @Prop({ default: true }) isPartly!: boolean;

  private INQUIRY_STATUS = INQUIRY_STATUS;
  private INQUIRY_STATUS_COLOR = INQUIRY_STATUS_COLOR;

  /** 自身による問合せのみを条件にするか */
  private isOnlyOwnInquiry = 0;

  /** 1ページあたりの表示問合せ件数 */
  private itemPerPage = 25;
  /** 表の現在のページ数 */
  private pageNo = 1;

  private contactListHeaders: DataTableHeader[] = [
    {
      text: "ステータス",
      value: "status",
      width: "120px"
    },
    {
      text: "お問合せ番号",
      value: "ticket_no"
    },
    {
      text: "お問合せ内容",
      value: "normalized_inquiry_content"
    },
    {
      text: "お問合せ日時",
      value: "inquiry.inquiry_datetime_short",
      width: "190px"
    },
    {
      text: "最終回答日",
      value: "last_answered_datetime"
    },
    {
      text: "回答数",
      value: "answer_count"
    }
  ];

  private get filteredInquiry() {
    if (this.isOnlyOwnInquiry) {
      // 自身のお問合せに限るオプション
      return this.inquirys.filter(
        item => item.inquiry.staff_id === this.loginUser.id
      );
    }
    return this.inquirys; // 絞り込まないのでそのまま
  }

  /** 表のページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** 詳細クリック時 */
  show(data: ListInquiry) {
    this.$router.push({
      name: "ContactDetail",
      params: { id: data.inquiry.id + "" }
    });
  }
}
