





























import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import { BulletinBoardAdmin } from "#/model/bulletinboard";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { BOARD_DIV, BOARD_DIV_COLOR } from "@/const/bulletinBoard";

@Component
export default class BulletinBoardAdminDialog extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  @Prop({ default: false }) isShown!: boolean; //表示フラグ

  @Prop() board!: BulletinBoardAdmin; //掲示板

  private BOARD_DIV = BOARD_DIV;
  private BOARD_DIV_COLOR = BOARD_DIV_COLOR;

  // 既読にするボタンクリック
  private save() {
    this.postJsonCheck(
      window.base_url + "/api/bbsnotice/mark",
      {
        bulletin_board_admin: this.board
      },
      () => {
        return;
      }
    );
    this.closeDialog();
  }

  // キャンセルボタンクリック
  private cancel() {
    this.closeDialog();
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
