





















































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ContentsDisplay } from "./types";

const FONT_SIZE = {
  DEFAULT: 0,
  SIZE_L: 1,
  SIZE_LL: 2
};

@Component
export default class EditButton extends Vue {
  /** 編集モード */
  @Prop({ default: false }) value!: boolean;

  /** ibowフォントサイズ選択値 */
  @Prop() selectfontsize!: number;

  /** コンテンツの表示設定 */
  @Prop() contentsDisplay!: ContentsDisplay;

  /** 編集モード変更時 */
  @Emit("input") input(value: boolean) {
    return value;
  }

  /** 編集モード開始時 */
  @Emit("edit:start") onStartEdit() {
    return;
  }

  /** 編集モード終了時 */
  @Emit("edit:end") onEndEdit() {
    return;
  }

  /** ibowフォントサイズ選択値変更時 */
  @Emit("update:selectfontsize") updateSelectFontSize(newVal: number) {
    return newVal;
  }

  /** コンテンツの表示設定変更時 */
  @Emit("update:contentsDisplay") updateContentsDisplay(
    newVal: ContentsDisplay
  ) {
    return newVal;
  }

  /** 編集モード */
  private get Value(): boolean {
    return this.value;
  }
  private set Value(value: boolean) {
    this.input(value);
  }

  /** 編集モードの切り替え */
  private toggleEditMode() {
    this.Value = !this.Value;
    if (this.Value) {
      this.onEndEdit();
    } else {
      this.onStartEdit();
    }
  }

  /** 文字サイズ変更 */
  private changeFontSize(select: number) {
    const htmlElem = document.getElementsByTagName("html");
    switch (select) {
      case FONT_SIZE.DEFAULT:
        htmlElem[0].style.cssText = "font-size:100% !important;";
        break;
      case FONT_SIZE.SIZE_L:
        htmlElem[0].style.cssText = "font-size:115% !important;";
        break;
      case FONT_SIZE.SIZE_LL:
        htmlElem[0].style.cssText = "font-size:130% !important;";
        break;
    }
    this.updateSelectFontSize(select);
  }
}
