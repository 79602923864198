import Base from "../base";
import {
  ApplyPersonnel,
  DefaultApplyPersonnel,
  ApplyDoctor,
  DefaultApplyDoctor
} from "../common";
import { IndicateBeppyo7Disease } from "./indicateBeppyo7Disease";
import { IndicateBeppyo8State } from "./indicateBeppyo8State";
import { DiseaseMeta, DefaultArrayDiseaseMeta } from "./indicateDisease";

//指示書
export interface Indicate extends Base {
  id: number; //ID
  patient_id: number; //利用者ID
  start_date: string; //開始日
  end_date: string; //終了日
  indicate_div: number; //指示書種類\n1:訪問看護指示書\n2:精神科訪問看護指示書\n3:特別訪問看護指示書\n4:精神科特別訪問看護指示書\n5:訪問看護点滴注射指示書
  is_mtsa: number; //医療観察訪問看護フラグ（0:医療観察訪問看護ではない 1:医療観察訪問看護である）
  doctor_id: number; //医師ID
  is_not_document_arrive: number; //書面未到着フラグ
  doctor_indicate: string; //医師からの指示内容
  is_injection: number; //点滴注射指示有効フラグ
  injection_start_date: string; //注射開始日
  injection_end_date: string; //注射終了日
  injection_indicate: string; //点滴注射指示内容
  injection_remarks: string; //留意事項及び指示事項
  main_disease1: string; //主傷病名1
  main_disease2: string; //主傷病名2
  main_disease3: string; //主傷病名3
  is_main_disease1_converted: number; //主傷病名1（医療伝送用コード変換 0:未変換 1:変換済み）
  is_main_disease2_converted: number; //主傷病名2（医療伝送用コード変換 0:未変換 1:変換済み）
  is_main_disease3_converted: number; //主傷病名3（医療伝送用コード変換 0:未変換 1:変換済み）
  medical_diseases: DiseaseMeta[]; //傷病名一覧
  other_disease: string; //その他傷病名
  is_dementia: number; //認知症フラグ
  beppyo8_zinko: number; //人工呼吸器使用の状態
  beppyo8_kikan: number; //気管カニューレ使用の状態
  beppyo8_jokuso: number; //真皮を超える褥瘡の状態
  special_add1: number; //特別管理加算1
  special_add2: number; //特別管理加算2
  temporary_covid19_div: number; //新型コロナウイルス感染症臨時対応区分
  hour24_div: number; //24時間対応体制加算
  home_care_div: number; //在宅療養支援診療所または在宅療養支援病院区分
  emergency_div: number; //緊急時訪問看護加算区分
  longtime_beppyo_child_div: number; //15歳未満の小児で別表8に掲げる者への訪問看護（長時間）
  longtime_severe_child_div: number; //15歳未満の超重症児または準超重症児への訪問看護（長時間）
  info_expense2_beppyo_child_div: number; //18歳未満の小児で別表8に掲げる者への訪問看護（情報提供療養費２）
  info_expense2_severe_child_div: number; //18歳未満の超重症児または準超重症児への訪問看護（情報提供療養費２）
  smid_flg: number; //重症児フラグ
  semi_smid_flg: number; //準重症児フラグ
  mental_multiple_times_flg: number; //精神科複数回訪問フラグ
  other1_relation_institution_id: number; //他の訪問看護ステーションへの指示1
  other2_relation_institution_id: number; //他の訪問看護ステーションへの指示2
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
  injection_start_date_wareki: string; //注射開始日(和暦)
  injection_end_date_wareki: string; //注射終了日(和暦)
  diseases: IndicateBeppyo7Disease[]; //指示書別表7病名
  states: IndicateBeppyo8State[]; //指示書別表8状態
  doctor: ApplyDoctor; //医師
  other1_relation_institution: ApplyPersonnel; //他①の訪問看護ステーション
  other2_relation_institution: ApplyPersonnel; //他②の訪問看護ステーション
  medical_condition: string; //病状・治療状態など
}
export const DefaultIndicate = (): Indicate => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  indicate_div: 1,
  is_mtsa: 0,
  doctor_id: 0,
  is_not_document_arrive: 0,
  doctor_indicate: "",
  is_injection: 0,
  injection_start_date: "",
  injection_end_date: "",
  injection_indicate: "",
  injection_remarks: "",
  main_disease1: "",
  main_disease2: "",
  main_disease3: "",
  is_main_disease1_converted: 0,
  is_main_disease2_converted: 0,
  is_main_disease3_converted: 0,
  medical_diseases: DefaultArrayDiseaseMeta(),
  other_disease: "",
  is_dementia: 0,
  beppyo8_zinko: 0,
  beppyo8_kikan: 0,
  beppyo8_jokuso: 0,
  special_add1: 0,
  special_add2: 0,
  temporary_covid19_div: 0,
  hour24_div: 0,
  home_care_div: 0,
  emergency_div: 0,
  longtime_beppyo_child_div: 0,
  longtime_severe_child_div: 0,
  info_expense2_beppyo_child_div: 0,
  info_expense2_severe_child_div: 0,
  smid_flg: 0,
  semi_smid_flg: 0,
  mental_multiple_times_flg: 0,
  other1_relation_institution_id: 0,
  other2_relation_institution_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: "",
  injection_start_date_wareki: "",
  injection_end_date_wareki: "",
  diseases: [],
  states: [],
  doctor: DefaultApplyDoctor(),
  other1_relation_institution: DefaultApplyPersonnel(),
  other2_relation_institution: DefaultApplyPersonnel(),
  medical_condition: ""
});
export const DefaultSpecialIndicate = (): Indicate => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  indicate_div: 3,
  is_mtsa: 0,
  doctor_id: 0,
  is_not_document_arrive: 0,
  doctor_indicate: "",
  is_injection: 0,
  injection_start_date: "",
  injection_end_date: "",
  injection_indicate: "",
  injection_remarks: "",
  main_disease1: "",
  main_disease2: "",
  main_disease3: "",
  is_main_disease1_converted: 0,
  is_main_disease2_converted: 0,
  is_main_disease3_converted: 0,
  medical_diseases: DefaultArrayDiseaseMeta(),
  other_disease: "",
  is_dementia: 0,
  beppyo8_zinko: 0,
  beppyo8_kikan: 0,
  beppyo8_jokuso: 0,
  special_add1: 0,
  special_add2: 0,
  temporary_covid19_div: 0,
  hour24_div: 0,
  home_care_div: 0,
  emergency_div: 0,
  longtime_beppyo_child_div: 0,
  longtime_severe_child_div: 0,
  info_expense2_beppyo_child_div: 0,
  info_expense2_severe_child_div: 0,
  smid_flg: 0,
  semi_smid_flg: 0,
  mental_multiple_times_flg: 0,
  other1_relation_institution_id: 0,
  other2_relation_institution_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: "",
  injection_start_date_wareki: "",
  injection_end_date_wareki: "",
  diseases: [],
  states: [],
  doctor: DefaultApplyDoctor(),
  other1_relation_institution: DefaultApplyPersonnel(),
  other2_relation_institution: DefaultApplyPersonnel(),
  medical_condition: ""
});
