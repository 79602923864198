













































































import { Component, Mixins } from "vue-property-decorator";
import draggable from "vuedraggable";
import EditServiceCategoryDialog from "@/components/master/own_expense_service/EditServiceCategoryDialog.vue";
import EditServiceItemDialog from "@/components/master/own_expense_service/EditServiceItemDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify/types/index";
import CommonMasterList from "@/components/master/CommonMasterList.vue";

export interface OwnExpenseServiceCategory {
  id?: number;
  name: string;
  code: string;
  sort_no?: number;
  items: OwnExpenseServiceItem[];
  offices: (Office | number)[];
}
export const DefaultOwnExpenseServiceCategory = (): OwnExpenseServiceCategory => ({
  id: 0,
  name: "",
  code: "",
  sort_no: 0,
  items: [],
  offices: []
});

export interface OwnExpenseServiceItem {
  id?: number;
  own_expense_category_id: number;
  name: string;
  amount: number;
  tax_div: number;
  is_medical_deducation: number;
  code: string;
  remarks: string;
  sort_no?: number;
}
export const DefaultOwnExpenseServiceItem = (): OwnExpenseServiceItem => ({
  id: 0,
  own_expense_category_id: 0,
  name: "",
  amount: 0,
  tax_div: 0,
  is_medical_deducation: 0,
  code: "",
  remarks: "",
  sort_no: 0
});

export interface Office {
  office_id: number;
}

@Component({
  components: {
    draggable,
    EditServiceCategoryDialog,
    EditServiceItemDialog,
    CommonMasterList
  }
})
export default class OwnExpenseServiceList extends Mixins(AxiosMixin) {
  private isShownEditCategoryDialog = false;
  private isShownEditItemDialog = false;
  private selectedCategory?: OwnExpenseServiceCategory = undefined;
  private selectedItem?: OwnExpenseServiceItem = undefined;

  //自費カテゴリヘッダー
  private headers: DataTableHeader[] = [
    {
      text: "自費カテゴリ",
      value: "name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    {
      text: "",
      value: "items",
      width: "1px",
      sortable: false
    }
  ];

  private categories: OwnExpenseServiceCategory[] = []; //自費カテゴリ

  //自費サブカテゴリヘッダー
  private subHeaders: DataTableHeader[] = [
    {
      text: "自費アイテム",
      value: "name"
    }
  ];

  created(): void {
    this.fetchCategories();
  }

  //自費カテゴリコピー
  private clickCopyPrimaryContentButton(category: OwnExpenseServiceCategory) {
    const copyContent: OwnExpenseServiceCategory = {
      id: 0,
      sort_no: 0,
      name: category.name,
      code: category.code,
      items: [],
      offices: []
    };

    if (category.offices) {
      category.offices.forEach(office => {
        const officeObj = office as Office;
        copyContent.offices.push(officeObj.office_id);
      });
    }

    if (category.items) {
      category.items.forEach(item => {
        const copyItem = { ...item };
        copyItem.id = 0;
        copyItem.own_expense_category_id = 0;
        copyContent.items.push(copyItem);
      });
    }

    this.selectedCategory = copyContent;
    this.isShownEditCategoryDialog = true;
  }

  //自費カテゴリ保存
  private saveServiceCategory(category: OwnExpenseServiceCategory) {
    this.postJsonCheck(
      window.base_url + "/api/master/own_expense_category/save",
      {
        own_expense_category: category
      },
      () => {
        this.fetchCategories();
      }
    );
  }

  //自費アイテム保存
  private saveServiceItem(item: OwnExpenseServiceItem) {
    const index = this.categories.findIndex(
      c => c.id == item.own_expense_category_id
    );
    if (index > -1) {
      if (item.id) {
        item.amount = Number(item.amount);
        const updItemIndex = this.categories[index].items.findIndex(
          i => i.id == item.id
        );
        if (updItemIndex > -1) {
          this.categories[index].items[updItemIndex] = { ...item };
        }
      } else {
        if (!this.categories[index].items) {
          this.categories[index].items = [];
        }
        item.amount = Number(item.amount);
        this.categories[index].items.push(item);
      }
      this.saveServiceCategory(this.categories[index]);
    }
  }

  //自費カテゴリ取得
  private fetchCategories() {
    this.postJsonCheck(
      window.base_url + "/api/master/own_expense_category/get",
      {},
      res => {
        this.categories = res.data.own_expense_categories;
      }
    );
  }

  //並び替え
  private didClickToSortModeButton() {
    this.postJsonCheck(
      window.base_url + "/api/master/own_expense_category/save_order",
      {
        own_expense_categories: this.categories
      },
      () => {
        this.fetchCategories();
      }
    );
  }

  //自費カテゴリ編集
  private didClickCreateNewCategoryButton() {
    this.selectedCategory = undefined;
    this.isShownEditCategoryDialog = true;
  }

  //自費アイテム新規作成
  private didClickCreateNewItemButton() {
    this.selectedItem = undefined;
    this.isShownEditItemDialog = true;
  }

  //自費カテゴリ編集
  private didClickEditServiceCategoryButton(
    category: OwnExpenseServiceCategory
  ) {
    this.selectedCategory = category;
    this.isShownEditCategoryDialog = true;
  }

  //自費アイテム編集
  private didClickEditServiceItemButton(item: OwnExpenseServiceItem) {
    this.selectedItem = item;
    this.isShownEditItemDialog = true;
  }

  //自費カテゴリ編集ダイアログ閉じる
  private didClickCloseEditCategoryDialogButton() {
    this.isShownEditCategoryDialog = false;
  }

  //自費アイテム編集ダイアログ閉じる
  private didClickCloseEditItemDialogButton() {
    this.isShownEditItemDialog = false;
  }

  //自費カテゴリ削除
  private deletePrimaryContent(category: OwnExpenseServiceCategory) {
    this.postJsonCheck(
      window.base_url + "/api/master/own_expense_category/delete",
      {
        own_expense_category: category
      },
      () => {
        this.fetchCategories();
      }
    );
  }

  //自費アイテム削除
  private deleteSecondaryContent(item: OwnExpenseServiceItem) {
    const index = this.categories.findIndex(
      i => i.id == item.own_expense_category_id
    );
    if (index > -1) {
      this.categories[index].items = this.categories[index].items.filter(
        x => x.id !== item.id
      );
      this.postJsonCheck(
        window.base_url + "/api/master/own_expense_category/save",
        {
          own_expense_category: this.categories[index]
        },
        () => {
          this.fetchCategories();
        }
      );
    }
  }
}
