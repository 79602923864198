






















































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import { Question, DEFAULT_QUESTION_RESULT } from "#/model/admin/question";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class QuestionDialog extends Mixins(AxiosMixin, UtilMixin) {
  /** 表示フラグ */
  private isShown = false;

  /** アンケート情報 */
  @Prop() question!: Question;

  /** 回答スキップ */
  private isSkip = 0;

  /** アンケート回答情報 */
  private get questionResult() {
    if (
      !this.question.question_results ||
      !this.question.question_results.length
    ) {
      this.question.question_results = [];
      this.question.question_results.push({ ...DEFAULT_QUESTION_RESULT });
    }

    return this.question.question_results[0];
  }

  /** ダイアログ内回答ボタンクリック時 */
  private save() {
    this.postJsonCheck(
      window.base_url + "/api/question/result/save",
      {
        question: this.question,
        question_result: this.questionResult,
        is_skip: this.isSkip
      },
      () => {
        return;
      }
    );
    this.closeDialog();
  }

  /** 今は回答しないクリック時 */
  private skip() {
    this.isSkip = 1;
    this.save();
  }

  public openDialog() {
    this.isShown = true;
  }

  @Emit("close")
  private closeDialog() {
    this.isShown = false;
    this.isSkip = 0;
    return;
  }
}
