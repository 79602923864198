






import { Component, Prop, Vue } from "vue-property-decorator";
import MedicalForm from "@/components/master/medical/MedicalForm.vue";
@Component({
  components: { MedicalForm }
})
export default class MedicalNew extends Vue {
  @Prop({ default: null })
  medicalId!: number;
}
