








































import { Component, Mixins, Watch } from "vue-property-decorator";
import { RawLocation, Route, Dictionary } from "vue-router/types/router";
import ContactUtility from "@/views/contact/utility";

import FAQList from "@/components/contact/FAQList.vue";
import FAQContentDetail from "@/components/contact/FAQContentDetail.vue";
import FAQSearch from "@/components/contact/FAQSearch.vue";
import CategoryMenu from "@/components/contact/CategoryMenu.vue";
import { BreadItem } from "@/store/modules/patient/types";
import {
  SearchCondFAQ,
  DispTemplate,
  DEFAULT_SEARCH_COND_FAQ,
  DEFAULT_DISP_TEMPLATE,
  CategoryChoice,
  CategoryMenuList
} from "@/views/contact/types";

@Component({
  components: {
    FAQList,
    FAQContentDetail,
    FAQSearch,
    CategoryMenu
  }
})
export default class FAQShow extends Mixins(ContactUtility) {
  /** FAQテンプレートの表示件数 */
  private TEMPLATE_LIMIT = 5;

  /** パンくずリスト */
  private breadItems: BreadItem[] = [];

  /** FAQテンプレート情報 */
  private template: DispTemplate = { ...DEFAULT_DISP_TEMPLATE };

  /** 関連するFAQテンプレート一覧 */
  private relatedTemplates: DispTemplate[] = [];

  /** 入力中の検索条件 */
  private searchObj: SearchCondFAQ = { ...DEFAULT_SEARCH_COND_FAQ };

  /** 検索した時の検索条件 */
  private savedSearchObj: SearchCondFAQ = { ...DEFAULT_SEARCH_COND_FAQ };

  /** カテゴリ選択用Choice */
  private categoryChoices: CategoryChoice[] = [];

  /** そのFAQの小カテゴリID、無ければ中大カテゴリID */
  private get ChildCategoryId() {
    const flatCategories = this.template.flat_categories;
    if (flatCategories.length === 0) {
      return 0; // FAQはカテゴリが必須で、lengthが0ならFAQカテゴリを調べられない
    }
    const childCategory = flatCategories[flatCategories.length - 1];
    return childCategory.category_id;
  }

  /** そのFAQのハッシュタグ */
  private get Keywords() {
    if (this.template.template_hashtags == null) return [];
    return this.template.template_hashtags.map(tag => tag.hashtag);
  }

  /** 検索結果画面から来たか */
  private get IsFromResults() {
    // 検索結果画面から遷移してきた場合、URLパラメータが付いている
    return Object.keys(this.$route.query).length > 0;
  }

  /** 戻るリンク名 */
  private get BackPageLabel() {
    if (!this.IsFromResults) {
      // サポートトップから遷移してきた場合
      return "サポートページへもどる";
    }
    if (
      this.savedSearchObj.keyword !== DEFAULT_SEARCH_COND_FAQ.keyword ||
      this.savedSearchObj.category_id !== DEFAULT_SEARCH_COND_FAQ.category_id ||
      this.savedSearchObj.hashtag !== DEFAULT_SEARCH_COND_FAQ.hashtag
    ) {
      return "検索結果へもどる";
    }
    return "記事一覧をみる";
  }

  /** 検索した時のURLクエリ */
  private get BeforeSearchPage() {
    const route: RawLocation = {
      name: "FAQ",
      query: this.getFAQUrlQuery(this.searchCondToQuery(this.savedSearchObj))
    };
    return route;
  }

  /** 現在検索しているカテゴリ */
  private get SearchCategory() {
    const catId = this.savedSearchObj.category_id;
    if (catId < 1) {
      return undefined;
    }
    return this.categoryChoices.find(cat => cat.value === catId);
  }

  /** 記事の詳細から別記事の詳細に移動した場合データ再取得 */
  @Watch("$route.params.id")
  changeFAQPage() {
    this.fetchFAQ();
  }

  created() {
    this.queryToSearchCond(this.$route.query);
    this.fetchFAQ();
    this.fetchCategory();
  }

  beforeRouteUpdate(to: Route, from: Route, next: Function) {
    // 関連するFAQに切り替える時は、上にスクロールする
    this.$vuetify.goTo(0, { easing: "easeInOutCubic" });
    next();
  }

  private setBreadItems() {
    const title = this.removeHTMLTag(this.template.inquiry_content);

    this.breadItems = [
      {
        text: "サポート",
        disabled: false,
        to: "/contact",
        exact: true
      },
      {
        text: "iBowヘルプ",
        disabled: false,
        to: "/contact/faq",
        exact: true
      },
      {
        text: title.length > 20 ? title.slice(0, 20) + "..." : title,
        disabled: true,
        to: ""
      }
    ];
  }

  /** クエリパラメータから検索条件を設定 */
  private queryToSearchCond(query: Dictionary<string | (string | null)[]>) {
    if (query.keyword != null) {
      this.searchObj.keyword = query.keyword as string;
    }
    if (query.andor != null) {
      this.searchObj.keyword_operator = Number(query.andor) || 1;
    }
    if (query.category_id != null) {
      this.searchObj.category_id = Number(query.category_id);
    }
    if (query.hashtag != null) {
      this.searchObj.hashtag = query.hashtag as string;
    }

    this.savedSearchObj = { ...this.searchObj };
  }

  /** FAQテンプレート情報を取得 */
  private fetchFAQ() {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/templates/get",
      { ids: [Number(this.$route.params.id)] },
      res => {
        if (!res.data.templates) return;
        this.template = res.data.templates[0];
        this.setBreadItems();
        this.fetchFAQList();
      }
    );
  }

  /** FAQカテゴリ情報取得 */
  private fetchCategory() {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/categorys/get",
      {},
      res => {
        this.categoryChoices = res.data.category_choice;
      }
    );
  }

  /** 関連するFAQテンプレート一覧を取得 */
  private fetchFAQList() {
    this.relatedTemplates = [];
    // 表示しているFAQは一覧から除外するため、あらかじめ1つ多めに取っておく
    // キーワードは1番目のみを検索対象とする
    this.postJsonCheck(
      window.base_url + "/api/inquiry/templates/get",
      {
        hashtag: this.Keywords[0] ?? "",
        category_id: this.ChildCategoryId,
        limit: this.TEMPLATE_LIMIT + 1
      },
      res => {
        if (!res.data.templates) return;
        const templates: DispTemplate[] = res.data.templates;
        // 表示しているFAQは一覧から除く
        this.relatedTemplates = templates
          .filter(faq => faq.id !== this.template.id)
          .slice(0, this.TEMPLATE_LIMIT);
      }
    );
  }

  /** カテゴリリストクリック時 */
  private onClickCategoryList(category: CategoryMenuList) {
    this.$router.push({
      name: "FAQ",
      query: this.getFAQUrlQuery({
        ...this.searchCondToQuery(this.searchObj),
        category_id: String(category.category_id)
      })
    });
  }

  /** 検索 */
  private search() {
    this.$router.push({
      name: "FAQ",
      query: this.getFAQUrlQuery(this.searchCondToQuery(this.searchObj))
    });
  }
}
