


















































import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { StaffPractitionerParentDiv } from "#/model/staff";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {}
})
export default class PractitionerTable extends Mixins(RulesMixin) {
  @Prop() value!: StaffPractitionerParentDiv[];
  @Prop({ default: false }) is_published!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  @Emit("input") input(practitionerParentDivs: StaffPractitionerParentDiv[]) {
    return practitionerParentDivs;
  }
  private get PractitionerParentDivs() {
    return this.value;
  }
  private set PractitionerParentDivs(
    practitionerParentDivs: StaffPractitionerParentDiv[]
  ) {
    this.input(practitionerParentDivs);
  }

  private async changePractitionerParentDiv(
    practitionerParentDiv: StaffPractitionerParentDiv
  ) {
    if (!practitionerParentDiv.is_check) return;
    if (
      !(await this.$openConfirm(
        "対応する特定行為を一緒にチェックします。\nよろしいですか。"
      ))
    )
      return;

    practitionerParentDiv.staff_practitioners.forEach(prac => {
      prac.is_check = 1;
    });
  }
}
