






























import { Component, Prop, Emit } from "vue-property-decorator";
import BaseNursingDetail from "./BaseNursingDetail";
import NursingDetailItem from "./NursingDetailItem.vue";

@Component({
  components: { NursingDetailItem }
})
export default class CaresummaryNursingDetail extends BaseNursingDetail {
  /** 看護内容（テキスト入力） */
  @Prop() private value!: string;

  /** 看護内容（テキスト入力） */
  @Emit() private input(newValue: string) {
    return newValue;
  }

  /** 看護内容（テキスト入力） */
  private get Value(): string {
    return this.value;
  }

  /** 看護内容（テキスト入力） */
  private set Value(newValue: string) {
    this.input(newValue);
  }
}
