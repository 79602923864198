import { MutationTree } from "vuex";
import {
  MasterState,
  SET_MASTER,
  SetMaster,
  SET_MASTER_BY_TOKEN,
  SetMasterByToken
} from "./types";

export const mutations: MutationTree<MasterState> = {
  [SET_MASTER](state, input: SetMaster) {
    state.master = input.master;
    state.login_user = input.login_user;
    state.can_auth_change = input.can_auth_change;
    state.button_items = input.button_items;
    state.co_code = input.co_code;
    state.kst_no = input.kst_no;
    state.site_name = input.site_name;
    state.is_honobono_agreement = input.is_honobono_agreement;
    state.is_sukoyaka_agreement = input.is_sukoyaka_agreement;
    state.receipt_link_settings = input.receipt_link_settings;
    state.agreement_user_setting = input.agreement_user_setting;
  },
  [SET_MASTER_BY_TOKEN](state, input: SetMasterByToken) {
    state.co_code = input.co_code;
    state.site_name = input.site_name;
    if (state.login_user) {
      state.login_user.agreement_id = input.agreement_id;
      state.login_user.auth_id = input.auth_id;
      state.login_user.ewell_user_id = input.ewell_id;
      state.login_user.office_id = input.office_id;
      state.login_user.id = input.office_staff_id;
    }
  }
};
