export interface AdlEvalItemPrimaryContent {
  id?: number;
  name: string;
  sort_no: number;
  selects: AdlEvalItemSecondaryContent[];
  offices: Office[];
}
export const DefaultAdlEvalItemPrimaryContent = (): AdlEvalItemPrimaryContent => ({
  id: 0,
  name: "",
  sort_no: 0,
  selects: [],
  offices: []
});

export interface AdlEvalItemSecondaryContent {
  adl_evaluation_item_id?: number;
  id?: number;
  name: string;
  sort_no: number;
}
export const DefaultAdlEvalItemSecondaryContent = (): AdlEvalItemSecondaryContent => ({
  adl_evaluation_item_id: 0,
  id: 0,
  name: "",
  sort_no: 0
});
export interface Office {
  office_id: number;
}
