




































import { Component } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { DataTableHeader } from "vuetify/types/index";
import { ReportData } from "@/components/officeAggregate/officeinvoice/types";
import { BreadItem } from "@/store/modules/patient/types";

@Component
export default class OfficeInvoiceStaff extends Base {
  private officeId = 0; //事業所ID
  private staffId = 0; //職員ID
  private officeName = ""; //事業所名
  private staffName = ""; //職員名
  private yearmonth = ""; //年月
  private submissions = 0; //看護記録書Ⅱ合計提出枚数
  private reportDatas: ReportData[] = []; //テーブル用アイテム

  // パンくずリスト
  private breadItems: BreadItem[] = [];

  //データテーブルヘッダー
  private invoiceStaffHeaders: DataTableHeader[] = [
    {
      text: "利用者名",
      value: "patient_name"
    },
    {
      text: "生年月日",
      value: "birthday"
    },
    {
      text: "提出日時",
      value: "submit_datetime"
    }
  ];

  private birthday(bday: string, age: number) {
    return `${this.warekiToShort(bday)} (${age}歳)`;
  }
  private formatDateStr(date: string) {
    return date.replaceAll("-", "/");
  }

  public async created() {
    this.officeId = Number(this.$route.query.id);
    this.officeName = String(this.$route.query.office);
    this.staffId = Number(this.$route.query.staff_id);
    this.staffName = String(this.$route.query.staff);
    this.yearmonth = String(this.$route.query.yearmonth);
    this.submissions = Number(this.$route.query.submissions);

    this.breadItems = [
      {
        text: "訪問記録情報",
        disabled: false,
        to: "/office_aggregate/officeinvoice",
        exact: true
      },
      {
        text: "看護記録書Ⅱ提出詳細",
        disabled: false,
        to: `/office_aggregate/officeinvoice/detail?id=${this.officeId}&office=${this.officeName}&yearmonth=${this.yearmonth}`,
        exact: true
      },
      {
        text: this.staffName,
        disabled: true,
        to: ""
      }
    ];
    await this.getDetail();
  }

  //-----------------------------------------------------
  // 詳細データ取得
  //-----------------------------------------------------
  private async getDetail() {
    //todo エラーの場合、resolveされない。。
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/officeinvoice/getstaff",
        {
          office_id: this.officeId,
          staff_id: this.staffId,
          yearmonth: this.yearmonth
        },
        res => {
          this.reportDatas = res.data.report_datas;
          resolve(0);
        }
      );
    });
  }
}
