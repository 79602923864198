





































































































import { Component, Ref } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import BiOfficeSelect from "@/components/officeAggregate/BiOfficeSelect.vue";
import {
  ChartData,
  TableData,
  SearchCondition
} from "@/components/officeAggregate/indicateNumber/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {
    BiOfficeSelect
  }
})
export default class IndicateNumber extends Base {
  @Ref("indicate_number") private readonly form!: VForm;

  private chart!: am4charts.XYChart; //アムチャート
  private chartDatas: ChartData[] = []; //アムチャートデータ
  private tableDatas: TableData[] = []; //テーブルデータ
  private officeId = 0; //検索条件：事業所ID
  private start = ""; //検索条件：請求開始月
  private end = ""; //検索条件：請求終了月
  private doctorNum = 30; //グラフに表示する医師人数

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      text: "医療機関名",
      value: "hospital_name",
      align: "start",
      width: 300
    },
    {
      text: "医師名",
      value: "doctor_name",
      align: "start",
      width: 200
    },
    {
      text: "普通1ヶ月（枚）",
      value: "nomal_number1",
      align: "start",
      width: ""
    },
    {
      text: "普通2ヶ月（枚）",
      value: "nomal_number2",
      align: "start",
      width: ""
    },
    {
      text: "普通3ヶ月（枚）",
      value: "nomal_number3",
      align: "start",
      width: ""
    },
    {
      text: "普通4ヶ月（枚）",
      value: "nomal_number4",
      align: "start",
      width: ""
    },
    {
      text: "普通5ヶ月（枚）",
      value: "nomal_number5",
      align: "start",
      width: ""
    },
    {
      text: "普通6ヶ月（枚）",
      value: "nomal_number6",
      align: "start",
      width: ""
    },
    {
      text: "特別（枚）",
      value: "special_number",
      align: "start",
      width: ""
    },
    {
      text: "利用者人数",
      value: "patient_number",
      align: "start",
      width: ""
    }
  ];

  public async mounted() {
    //小画面で保存している検索条件
    this.collection = "office-aggregate-indicate-number";
    this.documentId = String(this.loginUser.id);
    const fbDoc = await this.documentGet();
    //親画面で保存した検索条件
    const parentFbDoc = await this.getParentSearchCon();

    //デフォルトの検索条件
    this.officeId = this.offices();
    this.start = this.lastmonth();
    this.end = this.todaymonth();

    if (parentFbDoc !== undefined && typeof parentFbDoc !== "boolean") {
      const searchCondition = parentFbDoc;
      this.start = searchCondition?.start;
      this.end = searchCondition?.end;
    }
    if (fbDoc !== undefined && typeof fbDoc !== "boolean") {
      const searchCondition = fbDoc.searchCondition as SearchCondition;
      this.officeId = searchCondition?.office_id;
      this.doctorNum = searchCondition?.doctor_num;
    }

    this.disposeChart();
    // 初期メッセージ
    this.chart = this.xyChartMessage();
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.XYChart) {
    // 拡大スクロール
    const scrollbarY = new am4charts.XYChartScrollbar();
    chart.scrollbarY = scrollbarY;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "doctor_name";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;

    //create value axis for income and expenses
    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;
    let max = 0;
    this.tableDatas.forEach((item: TableData) => {
      const total =
        item.nomal_number1 +
        item.nomal_number2 +
        item.nomal_number3 +
        item.nomal_number4 +
        item.nomal_number5 +
        item.nomal_number6 +
        item.special_number;
      if (total > max) {
        max = total;
      }
    });
    if (max < 10) {
      valueAxis.min = 0;
      valueAxis.max = 10;
    }

    //create line
    const lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.categoryY = "doctor_name";
    lineSeries.dataFields.valueX = "patient_number";
    lineSeries.name = "利用者数";
    lineSeries.strokeWidth = 3;
    lineSeries.tooltipText = "{valueX.value}";
    lineSeries.zIndex = 10;

    //add bullets
    const circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.fill = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 2;
    const state = circleBullet.states.create("hover");
    state.properties.scale = 1.2;

    //add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomY";

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    return chart;
  }

  // Create series
  private createSeries(field: any, name: any) {
    const series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = field;
    series.dataFields.categoryY = "doctor_name";
    series.stacked = true;
    series.name = name;

    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.locationX = 0.5;
    // labelBullet.label.text = name + "({valueX})";
    labelBullet.label.fill = am4core.color("#fff");
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;

    this.createSeries("nomal_number1", "普通1ヶ月");
    this.createSeries("nomal_number2", "普通2ヶ月");
    this.createSeries("nomal_number3", "普通3ヶ月");
    this.createSeries("nomal_number4", "普通4ヶ月");
    this.createSeries("nomal_number5", "普通5ヶ月");
    this.createSeries("nomal_number6", "普通6ヶ月");
    this.createSeries("special_number", "特別指示書");
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      alert("入力内容に不備があります");
      return;
    }
    //グラフ表示件数アラート
    if (this.doctorNum > 50) {
      if (
        !(await this.$openConfirm(
          "表示件数が多い為グラフがうまく表示されない可能性があります。"
        ))
      ) {
        return;
      }
    }

    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  // Excelダウンロード
  private downloadExcel() {
    const officeName = this.getOfficeName(this.officeId);
    this.postJsonBlobResCheck(
      window.base_url + "/api/officeAggregate/indicateNumber/downloadexcel",
      {
        table_datas: this.tableDatas,
        start: this.start,
        end: this.end,
        office_name: officeName
      },
      res => {
        const fileName = "indicateNumber.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    const searchCondition = {
      office_id: this.officeId,
      start: this.start,
      end: this.end,
      doctor_num: this.doctorNum
    };
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/indicateNumber/search",
        searchCondition,
        res => {
          this.chartDatas = res.data.chart_datas;
          this.tableDatas = res.data.table_datas;
          this.documentSave({
            searchCondition: searchCondition
          });
          resolve(0);
        }
      );
    });
  }
}
