
































import { Component, Prop, Emit, Mixins, Ref } from "vue-property-decorator";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";
import {
  RelationInstitution,
  CareplanOffice,
  CarePlanChoice
} from "../careplan";

@Component
export default class CareplanOutput extends Mixins(RulesMixin, UtilMixin) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: () => [] }) institutions!: RelationInstitution[];
  @Prop({ default: () => [] }) offices!: CareplanOffice[];

  /** 選択中の関連機関ID */
  private institutionId = 0;
  /** 選択中の事業所ID */
  private officeId = 0;
  /** 対象年月 */
  private targetYearMonth = "";

  /** 関連機関選択肢 */
  private get institutionChoices(): CarePlanChoice[] {
    return this.institutions.map(institution => {
      return {
        value: institution.relation_institution_id,
        text: institution.name + " / " + institution.office_code,
        office_code: institution.office_code
      } as CarePlanChoice;
    });
  }

  /** 事業所選択肢 */
  private get officeChoices(): CarePlanChoice[] {
    return this.offices
      .filter(office => {
        return !office.isSatelite;
      })
      .map(office => {
        return {
          text: office.name,
          value: office.id,
          office_code: office.code
        } as CarePlanChoice;
      });
  }

  private created() {
    if (this.loginUser.parent_office_id > 0) {
      // 主たる事業所のみ表示するので初期値は主たる事業所にする
      this.officeId = this.loginUser.parent_office_id;
    } else {
      // 未所属は未選択
    }
  }

  private submit() {
    if (!this.form.validate()) {
      return;
    }

    this.output();
  }

  @Emit()
  private output() {
    // 選択中の事業所選択肢
    const office = this.officeChoices.find(
      (officeChoice: CarePlanChoice) => officeChoice.value == this.officeId
    );
    // 選択中の関連機関選択肢
    const institution = this.institutionChoices.find(
      (institutionId: CarePlanChoice) =>
        institutionId.value == this.institutionId
    );

    return {
      officeChoice: office,
      rInstChoice: institution,
      yearMonth: this.targetYearMonth
    };
  }
}
