








































import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import OneDayAdd from "./OneDayAdd.vue";

@Component
export default class OneOrThreeWeek extends OneDayAdd {
  @Prop({ default: false }) protected readonly long_time_flg!: boolean;

  @Prop({ default: false })
  protected readonly long_time_flg_covid19_urgent_div!: boolean;

  @Prop({ default: false })
  protected readonly long_time_flg_covid19_plan_div!: boolean;

  @Emit("update:long_time_flg") protected updateLongTimeFlg(newValue: boolean) {
    return newValue;
  }

  @Emit("update:long_time_flg_covid19_urgent_div")
  protected updateLongTimeFlgCovid19UrgentDiv(newValue: boolean) {
    return newValue;
  }

  @Emit("update:long_time_flg_covid19_plan_div")
  protected updateLongTimeFlgCovid19PlanDiv(newValue: boolean) {
    return newValue;
  }

  created(): void {
    this.initLongTimeFlgCovid19UrgentDiv();
    this.initLongTimeFlgCovid19PlanDiv();
  }

  protected get LongTimeFlgCovid19UrgentDiv() {
    return this.long_time_flg_covid19_urgent_div;
  }

  protected set LongTimeFlgCovid19UrgentDiv(newValue: boolean) {
    this.updateLongTimeFlgCovid19UrgentDiv(newValue);
  }

  protected get LongTimeFlgCovid19PlanDiv() {
    return this.long_time_flg_covid19_plan_div;
  }

  protected set LongTimeFlgCovid19PlanDiv(newValue: boolean) {
    this.updateLongTimeFlgCovid19PlanDiv(newValue);
  }

  //長時間（新型コロナウイルス臨時加算:緊急）
  protected initLongTimeFlgCovid19UrgentDiv() {
    if (this.receiptVisitRecord.long_time_flg == 1) {
      this.updateLongTimeFlgCovid19UrgentDiv(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 2) {
      this.updateLongTimeFlgCovid19UrgentDiv(true);
    }
    if (this.receiptVisitRecord.long_time_flg == 3) {
      this.updateLongTimeFlgCovid19UrgentDiv(true);
    }
    if (this.receiptVisitRecord.long_time_flg == 4) {
      this.updateLongTimeFlgCovid19UrgentDiv(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 5) {
      this.updateLongTimeFlgCovid19UrgentDiv(false);
    }
  }

  //長時間（新型コロナウイルス臨時加算:計画）
  protected initLongTimeFlgCovid19PlanDiv() {
    if (this.receiptVisitRecord.long_time_flg == 1) {
      this.updateLongTimeFlgCovid19PlanDiv(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 2) {
      this.updateLongTimeFlgCovid19PlanDiv(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 3) {
      this.updateLongTimeFlgCovid19PlanDiv(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 4) {
      this.updateLongTimeFlgCovid19PlanDiv(true);
    }
    if (this.receiptVisitRecord.long_time_flg == 5) {
      this.updateLongTimeFlgCovid19PlanDiv(true);
    }
  }

  //長時間（新型コロナウイルス臨時加算:緊急）
  @Watch("long_time_flg_covid19_urgent_div")
  protected handleLongTimeFlgCovid19UrgentDiv() {
    if (!this.isLoaded) {
      return;
    }
    if (this.long_time_flg_covid19_urgent_div) {
      this.updateLongTimeFlgCovid19PlanDiv(false);
      if (this.long_time_flg) {
        this.receiptVisitRecord.long_time_flg = 3;
      } else {
        this.receiptVisitRecord.long_time_flg = 2;
      }
    }
    if (
      this.long_time_flg &&
      !this.long_time_flg_covid19_urgent_div &&
      !this.long_time_flg_covid19_plan_div
    ) {
      this.receiptVisitRecord.long_time_flg = 1;
    }
    if (
      this.long_time_flg == false &&
      this.long_time_flg_covid19_urgent_div == false &&
      this.long_time_flg_covid19_plan_div == false
    ) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }

  //長時間（新型コロナウイルス臨時加算:計画）
  @Watch("long_time_flg_covid19_plan_div")
  protected handleLongTimeFlgCovid19PlanDiv() {
    if (!this.isLoaded) {
      return;
    }
    if (this.long_time_flg_covid19_plan_div) {
      this.updateLongTimeFlgCovid19UrgentDiv(false);
      if (this.long_time_flg) {
        this.receiptVisitRecord.long_time_flg = 5;
      } else {
        this.receiptVisitRecord.long_time_flg = 4;
      }
    }
    if (
      this.long_time_flg &&
      !this.long_time_flg_covid19_urgent_div &&
      !this.long_time_flg_covid19_plan_div
    ) {
      this.receiptVisitRecord.long_time_flg = 1;
    }
    if (
      this.long_time_flg == false &&
      this.long_time_flg_covid19_urgent_div == false &&
      this.long_time_flg_covid19_plan_div == false
    ) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }

  //長時間（新型コロナウイルス臨時加算)
  protected disableCheckLongTimeFlg(): boolean {
    const result = true;
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV4() {
    //緊急訪問看護
    if (this.disableCheckMedicalUrgentFlg()) {
      this.receiptVisitRecord.medical_urgent_flg = 0;
    }
    //乳幼児
    if (this.disableCheckInfantsFlg()) {
      this.receiptVisitRecord.infants_flg = 0;
    }
  }
}
