

















































import { Component, Watch } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class PractitionerDiv extends CarePartBase {
  // 専門区分変更時、特定行為を初期化する
  @Watch("receiptVisitRecord.senmon_care_div") protected handlePractitionerDivs() 
  {
    if (!this.isLoaded) {
      return;
    }
    if (this.receiptVisitRecord.senmon_care_div != 4) {
      this.receiptVisitRecord.trach_tube_replace_flg = 0;
      this.receiptVisitRecord.gast_jej_catheter_button_replace_flg = 0;
      this.receiptVisitRecord.bladder_catheter_replace_flg = 0;
      this.receiptVisitRecord.necrotic_tissue_remove_flg = 0;
      this.receiptVisitRecord.negative_pressure_wound_therapy_flg = 0;
      this.receiptVisitRecord.high_cal_infusion_adjust_flg = 0;
      this.receiptVisitRecord.fluid_dehydration_correction_flg = 0;
    }
  }
  
  // 特定行為未選択のチェック
  private checkPractitionerDiv(): boolean | string {
    // 専門看護師の訪問種類で「特定行為」を選択
    if (
      this.receiptVisitRecord.senmon_care_div === 4
    ) {
      // 特定行為未選択
      if (this.receiptVisitRecord.trach_tube_replace_flg === 0 &&
        this.receiptVisitRecord.gast_jej_catheter_button_replace_flg === 0 &&
        this.receiptVisitRecord.bladder_catheter_replace_flg === 0 &&
        this.receiptVisitRecord.necrotic_tissue_remove_flg === 0 &&
        this.receiptVisitRecord.negative_pressure_wound_therapy_flg === 0 &&
        this.receiptVisitRecord.high_cal_infusion_adjust_flg === 0 &&
        this.receiptVisitRecord.fluid_dehydration_correction_flg === 0
      ) {
        return "特定行為を選択してください";
      }
    }
    return true;
  }
}
