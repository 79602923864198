




















import { Component, Emit, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { TokenObj } from "./types";
import { AxiosError, AxiosResponse } from "axios";

interface Staff {
  id: number;
  agreement_id: number;
  office_id: number;
  auth_id: number;
}

@Component
export default class OfficeSelect extends Mixins(AxiosMixin) {
  // 契約内の事業所リスト
  private officeStaffs: Staff[] = [];

  // 選ばれた事業所ID
  private selectId = 0;

  //事業所一覧取得
  mounted() {
    this.postJsonCheck(window.base_url + "/api/getofficestaffs", {}, res => {
      this.officeStaffs = res.data.office_staffs;
    });
  }

  //事業所選択
  clickSelect() {
    const staff = this.officeStaffs.filter(item => item.id == this.selectId);
    if (staff.length == 0) {
      return;
    }
    this.loading++;
    window.axios
      .post(
        window.auth_backend_url + "/api/auth/token",
        {
          office_staff_id: staff[0].id
        },
        { headers: { Authorization: "Bearer " + window.token } }
      )
      .then((res: AxiosResponse) => {
        this.loading--;
        this.selectoffice(res.data.token, res.data.ref_token);
      })
      .catch(async (error: AxiosError) => {
        this.loading--;
        await this.$openAlert(
          "サーバでエラーが発生しました。\n(" + error + ")"
        );
        console.log(error);
        console.log(error.response);
      });
  }

  @Emit()
  selectoffice(token: string, ref_token: string): TokenObj {
    return { token, ref_token };
  }
}
