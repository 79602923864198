


























import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import ContactFormNew from "@/components/contact/ContactForm.vue";
import { Inquiry, DEFAULT_INQUIRY } from "#/model/inquiry";
import { BreadItem } from "@/store/modules/patient/types";

@Component({
  components: { ContactFormNew }
})
export default class ContactForm extends Mixins(AxiosMixin, UtilMixin) {
  private sendPopup = false;
  private breadItems: BreadItem[] = [
    {
      text: "サポート",
      disabled: false,
      to: "/contact",
      exact: true
    },
    {
      text: "お問合せ",
      disabled: true,
      to: ""
    }
  ];

  /** 問合せ情報 */
  private inquiry: Inquiry = { ...DEFAULT_INQUIRY };

  /** 初回問合せ情報 */
  private parentInquiry: Inquiry = { ...DEFAULT_INQUIRY };

  public created() {
    this.fetch();
  }

  /** フォーム画面に必要な情報を取得 */
  private fetch() {
    // 初回問合せID(新規作成時は0)
    const parentInquiryId = this.$route.params.id
      ? Number(this.$route.params.id)
      : 0;

    this.postJsonCheck(
      window.base_url + "/api/inquiry/form/get",
      { id: parentInquiryId },
      async res => {
        if (!res.data) {
          return;
        }

        // エラーがある場合は1つ前の画面に戻す
        if (res.data.error_message) {
          await this.$openAlert(res.data.error_message);
          this.$router.go(-1);
          return;
        }

        this.parentInquiry = res.data.parent_inquiry;
      }
    );
  }

  /** カウントダウンキャンセル用、タイマー参照数値 */
  private countDownTimer = 0;
  /** カウントダウンの残り秒数 */
  private remainSeconds = 0;
  /** 1秒毎にカウントダウンし、0になったらコールバック実行 */
  private countDown(callBack: Function, remainSeconds: number) {
    this.remainSeconds = remainSeconds;
    if (remainSeconds <= 0) {
      callBack();
      return;
    }
    this.countDownTimer = window.setTimeout(() => {
      this.countDown(callBack, remainSeconds - 1);
    }, 1000);
  }

  /** お問合せ送信後は一覧にリダイレクト */
  private redirect() {
    this.$router.push({ name: "ContactHistory" });
  }

  private cancelRedirect() {
    window.clearTimeout(this.countDownTimer);
    this.sendPopup = false;
  }

  /** お問合せキャンセル */
  private cancel() {
    this.$router.back();
  }

  /** お問合せ送信 */
  private submit(inquiry: Inquiry) {
    this.parentInquiry.id > 0
      ? this.sendSubInquiry(inquiry)
      : this.sendInquiry(inquiry);
  }

  /** 初回問合せ送信 */
  private sendInquiry(inquiry: Inquiry) {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/save",
      { inquiry: inquiry },
      () => {
        this.sendPopup = true;
        this.countDown(this.redirect, 3);
      }
    );
  }

  /** 追加問合せ送信 */
  private sendSubInquiry(inquiry: Inquiry) {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/sub/save",
      { inquiry: this.parentInquiry, sub_inquiry: inquiry },
      () => {
        this.sendPopup = true;
        this.countDown(this.redirect, 3);
      }
    );
  }
}
