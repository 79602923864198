




































































































































































































import { Component, Emit, Prop, Mixins, Ref } from "vue-property-decorator";
import PatientMixin from "@/mixins/patientMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { Personnel, DefaultPersonnel } from "#/model/uniquemaster";
import { RequesterChoice, Choice } from "@/types";
import { VForm } from "@/types";
import { namespace } from "vuex-class";
import * as patient from "@/store/modules/patient/types";
import { DataTableHeader } from "vuetify/types/index";
import CorpForm from "@/components/master/corp/CorpForm.vue";
import UtilMixin from "@/mixins/utilMixin";

export interface SearchItem {
  corp_name: string;
  tel_area: string;
  name: string;
}
const DefaultSearchItem = (): SearchItem => ({
  corp_name: "",
  tel_area: "",
  name: ""
});

interface SearchResList {
  id: number;
  corp_name: string;
}
interface AddItem {
  corpId: number;
  last_name: string;
  last_name_kana: string;
  first_name: string;
  first_name_kana: string;
}
const DefaultAddItem = (): AddItem => ({
  corpId: 0,
  last_name: "",
  last_name_kana: "",
  first_name: "",
  first_name_kana: ""
});

const Patient = namespace(patient.name);
@Component({ components: { CorpForm } })
export default class PatientRelationInsSelect extends Mixins(
  PatientMixin,
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: "関連機関" }) label!: string;
  @Prop() value!: number;
  @Prop({ default: () => [] }) support_office_divs!: number[];
  @Prop({ default: false }) is_own!: boolean;
  @Prop({ default: false }) not_select!: boolean;
  @Prop({ default: () => [] }) document_forms!: number[];
  @Prop({ default: false }) not_empty!: boolean; // 担当者が登録されていない機関は表示選択しない

  /** 無効化フラグ */
  @Prop({ default: false }) disabled!: boolean;

  @Ref("form") private readonly form!: VForm;
  @Ref("search") private readonly searchForm!: VForm;
  @Ref("corpForm") private readonly corpForm!: CorpForm;
  @Patient.Mutation(patient.SET_PATIENT_MASTER) setPatientMaster!: Function;

  @Emit()
  private input(newValue: number) {
    return newValue;
  }
  /** 担当者検索 */
  // 入力値
  private searchItem: SearchItem = DefaultSearchItem();
  //formvaid
  private searchValid = true;
  // 検索結果ヘッダー

  private headers: DataTableHeader[] = [
    {
      text: "関連機関名",
      value: "corp_name"
    },
    {
      text: "選択",
      value: "action"
    }
  ];
  //検索結果一覧
  private searchResList: SearchResList[] = [];
  /** 担当者登録 */
  //ダイアログ
  private dialog = false;
  //formvaid
  private valid = true;
  // 入力値
  private addItem: AddItem = DefaultAddItem();
  private corpFormDialog = false;
  private get Items() {
    const items: RequesterChoice[] = [];

    if (this.not_select) {
      items.push({
        id: 0,
        value: 0,
        text: "未選択",
        tel_area: "",
        tel_city: "",
        tel_no: ""
      });
    }

    if (this.is_own) {
      items.push({
        id: -1,
        value: -1,
        text: "ご本人様",
        tel_area: "",
        tel_city: "",
        tel_no: ""
      });
    }

    for (const ins of this.FilterRelationInstitutions) {
      for (const personnel of ins.personnels) {
        if (this.not_empty && personnel.is_empty == 0) {
          continue;
        }
        let text = ins.name;
        if (personnel.full_name !== "") {
          text += "/" + personnel.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: personnel.id,
          value: personnel.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num
        });
      }
    }
    return items;
  }

  // 関連機関選択肢
  private get RelationItems() {
    const items: Choice[] = [];

    for (const ins of this.FilterRelationInstitutions) {
      items.push({
        text: ins.name,
        value: ins.id
      });
    }
    return items;
  }

  private get Value(): number {
    return this.value;
  }

  private set Value(newValue: number) {
    this.input(newValue);
  }

  // 画面ごとに異なる項目一覧を取得
  private get FilterRelationInstitutions() {
    const resItem: patient.RelationInstitutionPatient[] = [];
    for (const ins of this.patientMaster.relation_institutions) {
      // 要介護度による選択肢絞り込み
      if (this.support_office_divs.length > 0) {
        const isOK = this.support_office_divs.some(div => {
          return div == ins.support_office_div;
        });
        if (!isOK) {
          continue;
        }
      }

      // 提供書様式による絞り込み
      if (this.document_forms.length > 0) {
        const isOK = this.document_forms.some(form => {
          return form == ins.document_form;
        });
        if (!isOK) {
          continue;
        }
      }
      resItem.push(ins);
    }
    return resItem;
  }

  // ダイアログを開く
  private openDialog() {
    this.dialog = true;
  }
  public openDialogWithCondition(cond: SearchItem) {
    this.searchItem.corp_name = cond.corp_name;
    this.searchItem.tel_area = cond.tel_area;
    this.searchItem.name = cond.name;
    this.openDialog();
  }
  // ダイアログを閉じる
  private closeDialog() {
    this.searchItem = DefaultSearchItem();
    this.searchForm.resetValidation();
    this.searchResList = [];
    this.addItem = DefaultAddItem();
    this.form.resetValidation();
    this.dialog = false;
  }

  private async search() {
    if (!this.searchForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (
      this.searchItem.corp_name === "" &&
      this.searchItem.tel_area === "" &&
      this.searchItem.name === ""
    ) {
      this.searchResList = [];
      return;
    }
    // 絞り込み
    this.searchResList = this.searchRelations();
  }

  private select(id: number) {
    this.Value = id;
    this.closeDialog();
  }

  // 担当者登録
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    const selectContent = this.patientMaster.relation_institutions.find(
      item => item.id === this.addItem.corpId
    );
    if (!selectContent) {
      await this.$openAlert(
        "指定した事業所情報が取得できませんでした。再度お試しください。"
      );
      return;
    }
    if (this.addItem) {
      const reqPersonnel: Personnel = {
        ...DefaultPersonnel(),
        relation_institution_id: selectContent.id,
        last_name: this.addItem.last_name,
        last_name_kana: this.addItem.last_name_kana,
        first_name: this.addItem.first_name,
        first_name_kana: this.addItem.first_name_kana,
        relation_institution_name: selectContent.name
      };
      this.postJsonCheck(
        window.base_url + "/api/master/personnel/save",
        { personnel: reqPersonnel },
        () => {
          // 追加した担当者を選択肢に反映させるため、更新
          this.updateMaster();
          this.closeDialog();
        }
      );
    }
  }

  //関連機関の登録
  private async saveCorp() {
    if (await this.corpForm.saveRelationInstitution()) {
      this.updateMaster();
      this.clearCorp();
    }
  }

  // 関連機関追加のキャンセル
  private clearCorp() {
    this.corpForm.clear();
    this.corpFormDialog = false;
  }

  // 利用者マスタ情報取得
  private updateMaster() {
    this.postJsonCheck(
      window.base_url + "/api/patient/master/get",
      { patient_id: Number(this.$route.params.id) },
      res => {
        if (res.data) {
          this.setPatientMaster({ master: res.data });
        }
      }
    );
  }

  //検索
  private searchRelations(): SearchResList[] {
    const resItems: SearchResList[] = [];

    const searchInst = this.searchItem.corp_name.replace(/\s/g, "");
    const searchName = this.searchItem.name.replace(/\s/g, "");

    const filteredItems = this.FilterRelationInstitutions.filter(item => {
      // 関連機関名
      if (searchInst === "") {
        return true;
      }
      const name = item.name.replace(/\s/g, "");
      const nameKana = item.furigana.replace(/\s/g, "");
      if (name.match(searchInst) || nameKana.match(searchInst)) {
        return true;
      }
      return false;
    }).filter(item => {
      // 電話番号
      if (this.searchItem.tel_area === "") {
        return true;
      }
      const tel = "" + item.tel_area + item.tel_city + item.tel_num;
      if (tel.match(this.searchItem.tel_area)) {
        return true;
      }
      return false;
    });

    // 担当者を取得
    filteredItems.forEach(item => {
      for (const personnel of item.personnels) {
        if (this.not_empty && personnel.is_empty == 0) {
          continue;
        }
        // 担当者名絞り込み（ふりがなも含める）
        const name = personnel.full_name.replace(/\s/g, "");
        const nameKana = personnel.furigana.replace(/\s/g, "");
        if (
          searchName !== "" &&
          !name.match(searchName) &&
          !nameKana.match(searchName)
        ) {
          continue;
        }

        let text = item.name;
        if (personnel.full_name !== "") {
          text += "/" + personnel.full_name;
        }
        if (item.tel_area && item.tel_city && item.tel_num) {
          text +=
            "/" + item.tel_area + "-" + item.tel_city + "-" + item.tel_num;
        }
        resItems.push({
          id: personnel.id,
          corp_name: text
        });
      }
    });

    return resItems;
  }
}
