var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppDataTable',{attrs:{"show-select":_vm.fileEditFlag,"no-data-text":"共有ファイルがありません。","item-key":"id","headers":_vm.Headers,"items":_vm.shareFiles,"single-select":false,"isNoTop":true,"pageNo":_vm.PageNo,"frontOnlyPageCount":_vm.frontOnlyPageCount},on:{"update:pageNo":function($event){_vm.PageNo=$event},"update:page-no":function($event){_vm.PageNo=$event},"input":_vm.checked},scopedSlots:_vm._u([{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('AppToolTip',{attrs:{"right":"","top":false,"label":("“" + (item.file_name) + "”を開く")},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"mr-1 px-0",attrs:{"height":"35px","width":"50px","color":"primary","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.previewFile(item.path)}}},'AppButton',attrs,false),on),[_vm._v(" 見る ")])]}}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.fileEditFlag)?_c('AppToolTip',{attrs:{"left":"","top":false,"label":("“" + (item.file_name) + "”を編集する")},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"40px","width":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item.id)}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"edit","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)}):_vm._e(),_c('AppToolTip',{attrs:{"left":"","top":false,"label":"有効期限のないリンクをクリップボードにコピーする"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"40px","width":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.id)}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"duplicate","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)})]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"d-flex justify-end",attrs:{"size":"30","color":_vm.getFileInfo(item.file_name).iconColor}},[_vm._v(" "+_vm._s(_vm.getFileInfo(item.file_name).icon)+" ")])]}},{key:"item.hash_tags",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-n1 patient-file-chip"},_vm._l((item.hash_tags),function(tag){return _c('v-chip',{key:tag.value,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(_vm._s(tag.text))])}),1)]}},{key:"item.editor_staff_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getText(item.editor_staff_id, _vm.staffs))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at.replace(/-/g, "/").slice(0, 16))+" ")]}},{key:"item.office_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"220px"}},[_vm._v(" "+_vm._s(_vm.getText(item.office_id, _vm.masters.group_offices))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }