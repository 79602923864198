































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import DateMixin from "#/mixins/dateMixin";
import StampDialog from "@/components/home/stampDialog.vue";
import { Choice } from "@/types";
import { Attend, DefaultAttend } from "@/components/home/types";

@Component({ components: { StampDialog } })
export default class Stamping extends Mixins(UtilMixin, AxiosMixin, DateMixin) {
  /** 勤務状況 */
  @Prop({ default: DefaultAttend() }) private readonly attend!: Attend;

  /** iBowKINTAI連携情報の更新 */
  @Emit("update-attend") private updateAttend() {
    return;
  }

  /** 事業所ID */
  @Prop({ default: 0 }) private readonly office_id!: number;

  /** ダイアログを開いているかどうか */
  private stampDialog = false;

  /** 事業所リスト */
  private officeList: Choice[] = [{ value: 0, text: "" }];

  /** 日付 */
  private date = "";
  /** 時間 */
  private minute = "";
  /** 表示時間 */
  private updateDateTimer = 0;

  private updateDateMinute(date: Date) {
    const hour = date
      .getHours()
      .toString()
      .padStart(2, "0");
    const minute = date
      .getMinutes()
      .toString()
      .padStart(2, "0");
    this.date = this.jpDate(date);
    this.minute = `${hour}:${minute}`;
  }

  mounted(): void {
    this.updateDateMinute(new Date());
    this.updateDateTimer = window.setInterval(() => {
      this.updateDateMinute(new Date());
    }, 500);
  }

  beforeDestroy(): void {
    window.clearInterval(this.updateDateTimer);
  }

  private get attendStatus(): string {
    if (this.attend.message != "") {
      return this.attend.message.replace("。", "");
    }
    switch (this.attend.status) {
      case 1:
        return "勤務中";
      case 2:
        return "時間外勤務中";
      case 3:
        return "中抜け中";
      default:
        break;
    }
    return "打刻する";
  }

  private async clickStamp() {
    if (this.office_id == 0) {
      this.openOfficeList();
      return;
    }

    // KINTAI職員が存在しない場合は、KINTAI職員を作成する
    if (this.attend.status == -2) {
      await this.saveKintaiStaff();
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/home/kintai/onetime",
      { office_id: this.office_id },
      res => {
        window.open(
          `${res.data.kintai_front_url}/?onetime=${res.data.one_time_token}`,
          "_brank",
          "noreferrer"
        );
      }
    );
  }

  /** 連携済み事業所リストを表示する */
  private openOfficeList() {
    this.postJsonCheck(
      window.base_url + "/api/home/kintai/officelist/get",
      {},
      async res => {
        this.officeList = res.data.kintai_link_office_list;

        // KINTAI職員が存在しない場合は、KINTAI職員を作成する
        if (this.officeList.length == 0) {
          await this.saveKintaiStaff();
          return;
        }

        this.stampDialog = true;
      }
    );
  }

  /** KINTAI職員を作成する */
  private async saveKintaiStaff() {
    if (
      await this.$openConfirm(
        "iBowKINTAI職員が見つかりません。\n作成しますか？"
      )
    ) {
      this.postJsonCheck(
        window.base_url + "/api/home/save-staff-for-kintai",
        {},
        () => {
          this.updateAttend();
        }
      );
    }
  }
}
