












import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";

interface SpecialIndicate {
  id: number;
  indicate_div: string;
  start_date: string;
  end_date: string;
  hospital: string;
  doctor: string;
  injection_start_date: string;
  injection_end_date: string;
  effective: boolean;
}

@Component({
  components: {
    ItemTable
  }
})
export default class Special extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 医療保険 */
  @Prop({ default: () => [] }) private readonly items!: SpecialIndicate[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 特別指示書ヘッダー */
  private specialIndicateHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    {
      text: "種類",
      value: "indicate_div",
      width: "8.8rem",
      class: "px-2",
      cellClass: "px-2"
    },
    {
      text: "開始日",
      value: "start_date",
      width: "5.2rem",
      class: "px-2",
      cellClass: "px-2 text-pre-wrap",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date",
      width: "5.2rem",
      class: "px-2",
      cellClass: "px-2 text-pre-wrap",
      sort: SortWarekiDate
    },
    {
      text: "病院",
      value: "hospital",
      width: "9.5rem",
      class: "px-2",
      cellClass: "px-2"
    },
    {
      text: "主治医",
      value: "doctor",
      width: "7rem",
      class: "px-2",
      cellClass: "px-2"
    },
    {
      text: "点滴注射\n開始日",
      value: "injection_start_date",
      width: "5.2rem",
      class: "px-2 text-pre-wrap",
      cellClass: "px-2 text-pre-wrap",
      sort: SortWarekiDate
    },
    {
      text: "点滴注射\n終了日",
      value: "injection_end_date",
      width: "5.2rem",
      class: "px-2 text-pre-wrap",
      cellClass: "px-2 text-pre-wrap",
      sort: SortWarekiDate
    }
  ];

  /** 新規作成 */
  private toSpecialEdit() {
    this.$router.push({
      name: "PatientSpecialIndicateEdit",
      params: { id: String(this.patientId), specialindicateid: "0" }
    });
  }

  /** 修正 */
  private toSpecialEditRepair(item: SpecialIndicate) {
    this.$router.push({
      name: "PatientSpecialIndicateEdit",
      params: { id: String(this.patientId), specialindicateid: String(item.id) }
    });
  }

  /** コピー */
  private toSpecialEditCopy(item: SpecialIndicate) {
    this.$router.push({
      name: "PatientSpecialIndicateEdit",
      params: {
        id: String(this.patientId),
        specialindicateid: String(item.id)
      },
      query: { mode: "copy" }
    });
  }

  //現在有効な特別指示書に色を付ける
  private specialIndicateRowBackground(item: SpecialIndicate) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
