


























import { Component, Emit, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { VForm } from "@/types";

interface Item {
  id: number;
  name: string;
}

@Component
export default class FiletagEditDialog extends Mixins(AxiosMixin) {
  @Ref("form") private readonly form!: VForm;

  private editingItem: Item = { id: 0, name: "" }; //編集中アイテム

  private dialog = false; //編集ダイアログ

  //削除フラグ
  private get IsDelete(): boolean {
    return this.editingItem.id !== 0;
  }

  //ダイアログ開く（新規作成）
  public openNew() {
    this.editingItem = { id: 0, name: "" };
    this.dialog = true;
    this.$nextTick(() => {
      this.form.resetValidation();
    });
  }

  //ダイアログ開く（編集）
  public openEdit(item: Item) {
    this.editingItem = { ...item };
    this.dialog = true;
    this.$nextTick(() => {
      this.form.resetValidation();
    });
  }

  //保存処理
  private async save() {
    if (!this.form.validate()) {
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/filetag/save",
      {
        file_tag: this.editingItem
      },
      () => {
        this.finishSave();
      }
    );
    this.dialog = false;
  }

  //削除処理
  private async remove() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/filetag/delete",
      {
        file_tag: this.editingItem
      },
      () => {
        this.finishSave();
      }
    );
    this.dialog = false;
  }

  //保存完了
  @Emit()
  private finishSave() {
    return;
  }
}
