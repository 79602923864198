













import { Component } from "vue-property-decorator";
import RegularPartBase from "./RegularPartBase";

@Component
export default class FirstFlg extends RegularPartBase {
  //初回加算
  protected chackFirstFlg(): boolean | string {
    //初回加算と退院時共同指導は同時に加算できない
    if (this.receiptVisitRecord.first_flg == 1 && this.nursing_leave_inst_flg) {
      return "初回訪問加算と退院時共同指導加算は併せて算定できません";
    }
    return true;
  }
}
