var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-y-hidden",class:{ 'mr-n9': !_vm.isDetail }},[_c('div',{staticClass:"d-flex align-sm-center flex-column flex-sm-row pb-4",class:{ 'mr-9': !_vm.isDetail }},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"faq-type-chip my-1 mr-2 orange white--text",class:{ 'align-self-start': _vm.isDetail }},[_vm._v("FAQ")]),_c('div',{staticClass:"align-self-center mr-4 font-weight-bold",class:{
          'text-wrap': _vm.isOpen,
          'word-break-all': _vm.isOpen,
          'text-h6': _vm.isDetail,
          'primary--text': !_vm.isDetail
        }},_vm._l((_vm.HighlightedContent(
            _vm.template.inquiry_content,
            false
          )),function(part,i){return _c('span',{key:i,class:{
            'orange--text': !_vm.isDetail && part.isHighlight,
            'font-weight-bold': !_vm.isDetail && part.isHighlight
          }},[_vm._v(_vm._s(part.content))])}),0)]),(!_vm.isDetail && !_vm.isOpen)?_c('router-link',{staticClass:"faq-detail-link ml-auto mr-1",attrs:{"to":_vm.DetailRoute}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("詳しく見る")]),_c('AppIcon',{attrs:{"icon":"arrow-right","fill":_vm.$vuetify.theme.themes.light.primary}})],1)]):_vm._e()],1),(_vm.isOpen)?_c('div',{staticClass:"d-flex mb-n1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 text-body-2"},[(_vm.isDetail)?_c('div',{staticClass:"faq-answer mb-4",domProps:{"innerHTML":_vm._s(_vm.FormattedContent)}}):_c('div',{staticClass:"flex-grow-1 mb-4"},[_c('span',{staticClass:"orange--text font-weight-bold"},[_vm._v("A. ")]),_vm._l((_vm.HighlightedContent(_vm.FormattedContent, false)),function(part,i){return _c('span',{key:i,class:{
            'orange--text': !_vm.isDetail && part.isHighlight,
            'font-weight-bold': !_vm.isDetail && part.isHighlight
          }},[_vm._v(_vm._s(part.content))])})],2),(_vm.isDetail)?_c('v-divider',{staticClass:"mb-4"}):_vm._e(),(_vm.isDetail)?_c('FAQTemplateFileViewer',{staticClass:"mb-2",attrs:{"templateFiles":_vm.template.template_files}}):_vm._e(),_c('v-row',{staticClass:"flex-grow-0",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-row',{staticClass:"flex-grow-0 mb-1",attrs:{"no-gutters":""}},[(_vm.template.flat_categories && _vm.template.flat_categories.length)?_c('div',{staticClass:"mr-4 mb-1"},[_c('v-icon',{staticClass:"faq-detail-category-icon"},[_vm._v("mdi-tag-outline")]),_c('span',[_vm._v("カテゴリ：")]),_vm._l((_vm.template.flat_categories),function(category,idx){return [(idx !== 0)?_c('span',{key:("catsplit" + idx),staticClass:"mr-1"},[_vm._v(",")]):_vm._e(),_c('a',{key:("category" + idx),staticClass:"font-weight-bold",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goCategoryList(category.category_id)}}},[_vm._v(_vm._s(category.category_name))])]})],2):_vm._e(),(
              _vm.template.template_hashtags && _vm.template.template_hashtags.length
            )?_c('div',{staticClass:"mr-4 mb-1"},[_c('AppIcon',{staticClass:"mr-1",attrs:{"icon":"keyword","fill":_vm.$vuetify.theme.themes.light.greyLight,"height":"12","width":"12"}}),_c('span',[_vm._v("キーワード：")]),_vm._l((_vm.template.template_hashtags),function(keyword,idx){return [(idx !== 0)?_c('span',{key:("keywordsplit" + idx),staticClass:"mr-1"},[_vm._v(",")]):_vm._e(),_c('a',{key:("keyword" + idx),staticClass:"font-weight-bold",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goKeywordList(keyword.hashtag)}}},[_vm._v(_vm._s(keyword.hashtag))])]})],2):_vm._e()]),_c('v-row',{staticClass:"flex-grow-0 mb-1 grey-light--text",attrs:{"no-gutters":"","justify":"start"}},[_c('div',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"faq-detail-category-icon"},[_vm._v("mdi-pencil")]),_c('span',[_vm._v("作成日："+_vm._s(_vm.template.created_at.slice(0, 10)))])],1),_c('div',[_c('v-icon',{staticClass:"faq-detail-category-icon"},[_vm._v("mdi-reload")]),_c('span',[_vm._v("更新日："+_vm._s(_vm.template.updated_at.slice(0, 10)))])],1)])],1)],1),(!_vm.isDetail)?_c('router-link',{staticClass:"faq-detail-link-narrow ml-3 mt-1 mb-2",attrs:{"to":_vm.DetailRoute}},[_c('span',[_vm._v("詳しく見る")]),_c('AppIcon',{attrs:{"icon":"arrow-right","fill":_vm.$vuetify.theme.themes.light.primary}})],1):_vm._e()],1):_c('div',{staticClass:"text-body-2 text-truncate"},[_c('span',{staticClass:"orange--text font-weight-bold"},[_vm._v("A. ")]),_vm._l((_vm.HighlightedContent(_vm.FormattedContent, true)),function(part,i){return _c('span',{key:i,class:{
        'orange--text': !_vm.isDetail && part.isHighlight,
        'font-weight-bold': !_vm.isDetail && part.isHighlight
      }},[_vm._v(_vm._s(part.content))])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }