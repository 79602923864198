/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CareContent } from "#/model/report";
import { NursingDetailMaster } from "#/model/master";
import { CareLargeContent } from "#/model/master";

@Component
export default class BaseNursingDetail extends Vue {
  /** 選択観察項目 */
  @Prop({ default: () => [] }) protected readonly care_contents!: CareContent[];

  /** 観察項目マスタ */
  @Prop() protected readonly master!: NursingDetailMaster;

  // 看護内容選択肢
  @Emit("update:care_contents")
  private updateCareCotens(newVal: CareContent[]) {
    return newVal;
  }

  protected get careContents(): CareContent[] {
    return this.care_contents;
  }

  protected set careContents(newVal: CareContent[]) {
    this.updateCareCotens(newVal);
  }

  //20項目を選択
  protected click20(checked: boolean, large: CareLargeContent) {
    const count =
      large.care_medium_contents.length > 20
        ? 20
        : large.care_medium_contents.length;
    for (let i = 0; i < count; i++) {
      const medium = large.care_medium_contents[i];
      // // チェック処理
      this.checkedContent(checked, large.id, medium.id, medium.name);
    }
  }

  // 選択値（この処理重そう）
  protected contentValue(
    largeId: number,
    mediumId: number,
    mediumName = "",
    isAi = 0
  ): boolean {
    return this.findCareContent(largeId, mediumId, mediumName, isAi)
      ? true
      : false;
  }

  // チェック処理
  protected checkedContent(
    checked: boolean,
    largeId: number,
    mediumId: number,
    mediumName = "",
    isAi = 0
  ) {
    if (checked) {
      const obj = this.findCareContent(largeId, mediumId, mediumName, isAi);
      if (!obj) {
        // 追加
        this.careContents.push({
          id: 0,
          care_large_content_id: largeId,
          care_medium_content_id: mediumId,
          care_text: mediumName,
          care_content: {
            large_id: 0,
            large_name: "",
            medium_id: 0,
            medium_name: ""
          }
        });
      }
    } else {
      // 削除
      const idx = this.findCareIndex(largeId, mediumId, mediumName, isAi);
      if (idx === -1) {
        return;
      }
      this.careContents.splice(idx, 1);
    }
  }

  // 看護内容検索
  protected findCareContent(
    largeId: number,
    mediumId: number,
    mediumName: string,
    isAi: number
  ): CareContent | undefined {
    if (!this.care_contents) return undefined;
    return this.careContents.find(ob => {
      return mediumId == 0
        ? ob.care_large_content_id == largeId && ob.care_text == mediumName
        : ob.care_large_content_id == largeId &&
            ob.care_medium_content_id == mediumId;
    });
  }

  // 看護内容インデックス検索
  protected findCareIndex(
    largeId: number,
    mediumId: number,
    mediumName: string,
    isAi: number
  ): number {
    if (!this.care_contents) return -1;
    return this.careContents.findIndex(ob => {
      return mediumId == 0
        ? ob.care_large_content_id == largeId && ob.care_text == mediumName
        : ob.care_large_content_id == largeId &&
            ob.care_medium_content_id == mediumId;
    });
  }
}
