
















import { Component } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class Other extends CarePartBase {
  //加算のみ
  protected disableCheckAddOnlyFlg(): boolean {
    let result = false;
    if (this.receiptVisitRecord.online_visit_flg == 1) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //加算のみ
    if (this.disableCheckAddOnlyFlg()) {
      this.receiptVisitRecord.add_only_flg = 0;
    }
  }
}
