















































import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import BaseNursingDetail from "./BaseNursingDetail";
import { VnPlanProblem, VnPlanProblemCareContent } from "#/model/vnplan";
import ReferenceVnplan from "@/components/patient/common/nursingdetail/ReferenceVnplan.vue";
import NursingDetailItem from "./NursingDetailItem.vue";
import {
  CareLargeContent,
  CareMediumContent,
  DefaultCareLargeContent,
  DefaultCareMediumContent
} from "#/model/master";
import { DefaultApplyCareContent } from "#/model/common";
import { CareContent } from "#/model/report";

@Component({
  components: {
    ReferenceVnplan,
    NursingDetailItem
  }
})
export default class NursingDetail extends Mixins(
  BaseNursingDetail,
  RulesMixin,
  RulesSaveMixin,
  AxiosMixin
) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  /** 計画書の看護内容 */
  @Prop({ default: () => [] }) vnplan_problems!: VnPlanProblem[];

  /** 看護内容（テキスト入力） */
  @Prop() private value!: string;

  /** 看護内容（テキスト入力） */
  @Emit() private input(newValue: string) {
    return newValue;
  }

  /** 看護内容（テキスト入力） */
  private get Value(): string {
    return this.value;
  }

  /** 看護内容（テキスト入力） */
  private set Value(newValue: string) {
    this.input(newValue);
  }

  // チェック数
  protected get totalAmount(): number {
    let counter = 0;
    this.careContents.forEach(() => {
      counter++;
    });
    return counter;
  }

  /** 看護内容の20項目上限ルール */
  private get AmountRules() {
    if (this.is_save) {
      return [];
    }
    return [
      this.minNumber(this.totalAmount, 1, "看護内容が選択されていません"),
      this.maxNumber(this.totalAmount, 20, "看護内容の選択数の上限は20個です")
    ];
  }

  /** 計画書から反映 */
  private applyVnplan(careContens: VnPlanProblemCareContent[]) {
    this.applyVnPlanCareContents(careContens);
  }

  /** 計画書参照で登録ボタン押下時、選択状態の反映+マスタ枠の反映を行う */
  protected applyVnPlanCareContents(
    vnPlanCareContens: VnPlanProblemCareContent[]
  ) {
    // 0. マスタ枠の情報初期化と現在の選択状態反映
    //
    // 1. vnPlanCareContentsを順番に見て、選択状態に追加するか+マスタ枠に追加するかチェックする
    // 大項目ID+中項目IDでマスタ枠の項目と一致するかどうかみる
    //    一致する：選択状態に重複なしで追加（ただし、名前はマスタ枠依存)
    //                中項目ID=0なら、名前まで見る
    //    一致しない：AI用の中を見て、名前が一致する項目があるかどうかみる
    //          存在する：選択状態に重複なしで追加（ただし、IDはマスタ枠依存）
    //          存在しない：選択状態に重複なしで追加（名前は計画書看護内容、IDはそのID）+マスタ枠に追加（名前は計画書看護内容、IDはそのID）、追加したマスタ枠にはフラグをもたせておく
    //
    //  枠追加をした場合は追加した枠であることを示すフラグを立てておく。さらに、選択状態に追加した場合は、枠に選択状態であることを示すフラグを持たせる
    //
    // 2. マスタ枠のAI用を順番に見て、削除するかどうかをチェックする
    // AI用かつ選択状態になく、さらに追加枠を削除
    //
    // 3. マスタ枠のフラグを順番に見て、filter()で枠を削除後、map()で選択状態を再作成
    //
    // ※※※※※ 項目がないことを考慮する ※※※※※

    /** 0. マスタ枠の初期化 */

    // 大項目がない場合を考慮
    if (!this.master.care_large_contents) {
      this.master.care_large_contents = [] as CareLargeContent[];
    }

    // AI用大項目がない場合、作る
    const obj = this.master.care_large_contents.find(
      (large: CareLargeContent) => large.is_ai == 2
    );
    if (obj == undefined) {
      const aiLargeCareContent = DefaultCareLargeContent();
      aiLargeCareContent.care_medium_contents = [] as CareMediumContent[];
      aiLargeCareContent.is_ai = 2;
      aiLargeCareContent.name = "AI用";
      aiLargeCareContent.sort_no = this.master.care_large_contents.length + 1;
      this.master.care_large_contents.push(aiLargeCareContent);
    }

    // 存在しなければ、中項目を作り、現在の選択状態を反映させる
    this.master.care_large_contents.forEach(
      (mLargeCareContent: CareLargeContent) => {
        if (!mLargeCareContent.care_medium_contents) {
          mLargeCareContent.care_medium_contents = [] as CareMediumContent[];
        }
        mLargeCareContent.care_medium_contents.forEach(
          (mMediumCareContent: CareMediumContent) => {
            mMediumCareContent.select_flag = 0;
            this.careContents.forEach((careContent: CareContent) => {
              // 新しく追加された枠はスキップ
              if (
                careContent.care_large_content_id == mLargeCareContent.id &&
                careContent.care_medium_content_id == mMediumCareContent.id &&
                careContent.care_text == mMediumCareContent.name &&
                mMediumCareContent.change_flag != 1
              ) {
                mMediumCareContent.select_flag = 1;
                return;
              }
            });
          }
        );
      }
    );

    /** 1. 選択状態と枠追加状態の更新 */
    vnPlanCareContens.forEach((vpCareContent: VnPlanProblemCareContent) => {
      // 計画書看護内容の大項目IDと中項目IDと中項目名
      const vpLargeId = vpCareContent.care_large_content_id;
      const vpMediumId = vpCareContent.care_medium_content_id;
      const vpMediumName = vpCareContent.care_text;

      // 対象看護内容を追加したかどうかフラグ
      let isAppend = false;

      this.master.care_large_contents.forEach(
        (mLargeCareContent: CareLargeContent) => {
          // 追加済みなら何もしない
          if (isAppend) {
            return;
          }

          mLargeCareContent.care_medium_contents.forEach(
            (mMediumCareContent: CareMediumContent) => {
              // マスタ枠の大項目IDと中項目IDと中項目名
              const mLargeId = mLargeCareContent.id;
              const mMediumId = mMediumCareContent.id;
              const mMediumName = mMediumCareContent.name;

              // 大項目IDと中項目IDで比較
              if (vpLargeId == mLargeId && vpMediumId == mMediumId) {
                if (vpMediumId == 0) {
                  // ID=0の場合は名前までチェックする
                  if (vpMediumName == mMediumName) {
                    isAppend = true;
                    mMediumCareContent.select_flag = 1;
                    return;
                  }
                } else {
                  isAppend = true;
                  mMediumCareContent.select_flag = 1;
                  return;
                }
              }
            }
          );
        }
      );

      // 一致していない場合、AI用項目を見にいく
      if (!isAppend) {
        this.master.care_large_contents.forEach(
          (mLargeCareContent: CareLargeContent) => {
            if (mLargeCareContent.is_ai != 2) {
              return;
            }

            mLargeCareContent.care_medium_contents.forEach(
              (mMediumCareContent: CareMediumContent) => {
                // 追加済みなら何もしない
                if (isAppend) {
                  return;
                }

                // マスタ枠の中項目名
                const mMediumName = mMediumCareContent.name;
                if (vpMediumName == mMediumName) {
                  isAppend = true;
                  mMediumCareContent.select_flag = 1;
                  return;
                }
              }
            );
          }
        );
      }

      // AI用項目までチェックして存在しなかった場合、選択状態と枠への追加
      if (!isAppend) {
        this.master.care_large_contents.forEach(
          (mLargeCareContent: CareLargeContent) => {
            if (mLargeCareContent.is_ai != 2) {
              return;
            }

            const newAIMediumCareContent = DefaultCareMediumContent();
            newAIMediumCareContent.id = 0;
            newAIMediumCareContent.name = vpMediumName;
            newAIMediumCareContent.select_flag = 1;
            newAIMediumCareContent.change_flag = 1;

            mLargeCareContent.care_medium_contents.push(newAIMediumCareContent);
          }
        );
      }
    });

    // 2. マスタ枠のAI用を順番に見て、削除するかどうかをチェックする
    // AI用かつ選択状態ではなく、さらに追加された枠に対して削除フラグを立てる
    this.master.care_large_contents.forEach(
      (mLargeCareContent: CareLargeContent) => {
        if (mLargeCareContent.is_ai != 2) {
          return;
        }

        mLargeCareContent.care_medium_contents.forEach(
          (mMediumCareContent: CareMediumContent) => {
            if (
              mMediumCareContent.select_flag != 1 &&
              mMediumCareContent.change_flag == 1
            ) {
              mMediumCareContent.change_flag = 2;
            }
          }
        );
      }
    );

    /** 3. マスタ枠のフラグを順番に見て、filter()で枠を削除後、選択状態を再作成 */
    this.master.care_large_contents = this.master.care_large_contents.map(
      (mLargeCareContent: CareLargeContent) => {
        mLargeCareContent.care_medium_contents = mLargeCareContent.care_medium_contents.filter(
          (mMediumCareContent: CareMediumContent) => {
            return mMediumCareContent.change_flag != 2;
          }
        );
        return mLargeCareContent;
      }
    );

    const newCareContents: CareContent[] = [];

    this.master.care_large_contents.forEach(
      (mLargeCareContent: CareLargeContent) => {
        mLargeCareContent.care_medium_contents.forEach(
          (mMediumCareContent: CareMediumContent) => {
            if (mMediumCareContent.select_flag == 1) {
              const newCareContent: CareContent = {
                id: 0,
                care_large_content_id: mLargeCareContent.id,
                care_medium_content_id: mMediumCareContent.id,
                care_text: mMediumCareContent.name,
                care_content: DefaultApplyCareContent()
              };
              newCareContents.push(newCareContent);
            }
          }
        );
      }
    );

    this.careContents = newCareContents.slice();
  }
}
