













import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import MasmenMixin from "@/mixins/masmenMixin";
import MasterHeader from "@/components/master/MasterHeader.vue";
import * as masmen from "@/store/modules/masmen/types";
import { BreadItem } from "@/store/modules/masmen/types";

@Component({
  components: {
    MasterHeader
  }
})
export default class MasterBase extends Mixins(AxiosMixin, MasmenMixin) {
  /** 見ているマスタメンテ画面のカテゴリに応じたタブメニューを取得 */
  private get menu(): masmen.MasterMenu {
    /** $route.matchedでルーティング定義の親階層も含めて探せる */
    const routeMatched = this.$route.matched.find(route => {
      return route.meta?.masterMenuId != null;
    });
    const masterMenuId = routeMatched?.meta.masterMenuId ?? 1;
    // masterMenuIdと一致するIDのメニューを返す
    const matchMenu = this.MasmenMenus.find(menu => {
      return menu.id === masterMenuId;
    });

    /** パンくずリスト設定 */
    if (this.breadItems[1] && matchMenu) {
      this.breadItems[1].text = matchMenu.title;
    }

    return matchMenu ?? this.MasmenMenus[0];
  }

  private breadItems: BreadItem[] = [
    {
      text: "マスタメンテメニュー",
      disabled: false,
      to: "/master/list",
      exact: true
    },
    {
      text: "",
      disabled: true,
      to: ""
    }
  ];

  created() {
    /** マスタメンテ情報を取得 */
    this.postJsonCheck(window.base_url + "/api/master/base/get", {}, res => {
      if (res.data) {
        this.setMasmen(res.data);
      }
    });
  }
}
