import { Vue, Component } from "vue-property-decorator";
import { Choice } from "@/types";
import { OwnExpenseTemplate, OwnExpenseItemChoice } from "#/model/patient";

@Component
export default class OwnExpenseMixin extends Vue {
  /** 読み込み中フラグ */
  protected isLoaded = false;

  /** 自費カテゴリマスタ */
  protected ownExpenseCategorys: Choice[] = [];

  /** 自費アイテムマスタ */
  protected ownExpenseItems: OwnExpenseItemChoice[] = [];

  /** 選択・入力している自費アイテム名 */
  protected selectedItemName = "";

  /** 課税率のルール */
  protected ruleTaxRate(template: OwnExpenseTemplate) {
    if (template.tax_div !== 0 && template.tax_rate === 0) {
      return "必須です";
    }
    return true;
  }

  /** 自費アイテムマスタの絞り込み（カテゴリ選択時それに付随するアイテム表示）*/
  protected refinementOwnExpenseItems(categoryName: string) {
    let ownExpenseCategoryId = 0;
    //カテゴリ選択時、選択カテゴリのID抽出
    if (categoryName != "") {
      for (const ownExpenseCategory of this.ownExpenseCategorys) {
        if (ownExpenseCategory.text == categoryName) {
          ownExpenseCategoryId = +ownExpenseCategory.value;
        }
      }
      const refinementOwnExpenseItems: OwnExpenseItemChoice[] = [];
      //アイテムをカテゴリに付随のものに絞り込み
      for (const ownExpenseItem of this.ownExpenseItems) {
        if (ownExpenseItem.own_expense_category_id == ownExpenseCategoryId) {
          refinementOwnExpenseItems.push(ownExpenseItem);
        }
      }
      return refinementOwnExpenseItems;
    }
    //選択されていない場合（手動入力の場合）アイテム全件表示
    return this.ownExpenseItems;
  }

  /** 課税区分変更イベント */
  protected changeTaskDiv(ownExpense: OwnExpenseTemplate) {
    if (ownExpense.tax_div === 0) {
      ownExpense.tax_rate = 0;
    } else if (ownExpense.tax_rate === 0) {
      ownExpense.tax_rate = 8;
    }
  }

  /** 合計金額の計算 */
  protected calculation(ownExpense: OwnExpenseTemplate) {
    ownExpense.amount = ownExpense.unit_price * ownExpense.quantity;
  }

  /** 入力したアイテム名にマッチする既存の項目名 */
  protected selectedItem(category: string, name: string) {
    return this.ownExpenseItems.find(item => {
      // カテゴリ名/アイテム名の形式で入力した時、両方と一致する自費アイテムを探す
      const itemCat = this.ownExpenseCategorys.find(cat => {
        return cat.value === item.own_expense_category_id;
      });
      return category === itemCat?.text && name === item.text;
    });
  }

  /** 選択した自費項目の情報で置き換え */
  protected setSelectedOwnExpense(
    ownExpense: OwnExpenseTemplate,
    item: OwnExpenseItemChoice
  ) {
    // 単価
    ownExpense.unit_price = item.amount;
    // 課税区分
    switch (item.tax_div) {
      case 0: // 未選択
        ownExpense.tax_div = 0;
        ownExpense.tax_rate = 0;
        break;
      case 1: // 内税(10%)
        ownExpense.tax_div = 1;
        ownExpense.tax_rate = 10;
        break;
      case 2: // 外税(10%)
        ownExpense.tax_div = 2;
        ownExpense.tax_rate = 10;
        break;
      case 3: // 非課税
        ownExpense.tax_div = 0;
        ownExpense.tax_rate = 0;
        break;
      case 4: // 内税(8%)
        ownExpense.tax_div = 1;
        ownExpense.tax_rate = 8;
        break;
      case 5: // 外税(8%)
        ownExpense.tax_div = 2;
        ownExpense.tax_rate = 8;
        break;
    }
    // 医療費控除
    ownExpense.medical_deducation = item.is_medical_deducation;
    // 備考
    ownExpense.remarks = item.remarks;
  }
}
