

























































































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { Choice, VForm } from "@/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import PublicMoneyKindEditDialog from "@/components/master/public_money_kind/PublicMoneyKindEditDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import DateInput from "#/components/DateInput.vue";
import {
  PublicMoney,
  PublicMoneySelfBurden,
  PublicMoneyKind,
  DefaultPublicMoney,
  DefaultPublicMoneyKind,
  DefaultPublicMoneySelfBurden
} from "#/model/patient";

interface Items {
  id: number;
  yearmonth: string;
  other_total?: string;
  burden: string;
}

interface PublicMoneyDivs extends Choice {
  id: number;
  insurance_div: number;
  no: number;
  name: string;
  payment_rate: number;
  priority: number;
  is_daily: number;
  is_receipt_link: number;
  is_add_user: number;
  is_end_date_required: number;
  is_change_payment_rate: number;
  is_dynamic_ratio: boolean;
}

@Component({
  components: {
    PatientHeader,
    PublicMoneyKindEditDialog,
    DateInput
  }
})
export default class InsuranceInfoPublic extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private insPublicId = 0;
  private patientId = 0;
  private mode = "";
  private range = 0;
  private isLoaded = false;
  private addEditDialog = false;
  private createNewDialog = false;
  private isBurdenRatio = true;

  //公費
  private public_money: PublicMoney = DefaultPublicMoney();
  private public_money_divs: PublicMoneyDivs[] = []; //公費種類
  private addUserPublicMoneyDivs: PublicMoneyDivs[] = []; //ユーザー追加の公費種類
  private selectedAddUserPublicMoney: PublicMoneyKind = DefaultPublicMoneyKind(); //選択されたユーザー追加の公費種類

  private headers: DataTableHeader[] = [];
  private changeHeader(other: number) {
    if (!other) {
      //他医療機関off
      this.headers = [
        {
          text: "年月",
          value: "target_yearmonth_disp",
          width: "120px",
          align: "center"
        },
        {
          text: "自己負担額",
          value: "self_burden_amount",
          sortable: false
        }
      ];
    } else {
      //他医療機関on
      this.headers = [
        {
          text: "年月",
          value: "target_yearmonth_disp",
          width: "120px",
          align: "center"
        },
        {
          text: "他医療機関\n（自己負担の累積額）",
          value: "other_medical_amount",
          sortable: false
        },
        {
          text: "自己負担額",
          value: "self_burden_amount",
          sortable: false
        }
      ];
    }
  }
  //公費種類一覧ヘッダー
  public publicKindHeaders = [
    {
      text: "編集",
      value: "actions",
      sortable: false,
      align: "center",
      width: "80px"
    },
    {
      text: "公費名",
      align: "start",
      value: "name"
    },
    {
      text: "法別番号",
      align: "end",
      value: "no"
    },
    {
      text: "給付率(％)",
      align: "end",
      value: "payment_rate"
    }
  ];

  /** 公費種類ダイアログの検索文字列 */
  private publicTypeDialogSearch = "";

  // 自己負担額 記憶用
  private selfBurdens: PublicMoneySelfBurden[] = [];

  created() {
    this.insPublicId = Number(this.$route.params.inspublicid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchInsurerPublic();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=Public`
      },
      {
        text: "公費",
        disabled: true,
        to: ""
      }
    ]);
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //登録
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    const publicMoney = this.public_money_divs.find(div => {
      return div.value === this.public_money.public_money_kind_id;
    });

    if (!publicMoney) {
      // failsafe
      return;
    }

    // 給付率が動的に変わる公費を選択した場合は、有効な医療保険の有無を確認する
    if (publicMoney.is_dynamic_ratio) {
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/med/period/get",
        {
          patient_id: this.patientId,
          start_date: this.public_money.start_date,
          end_date: this.public_money.end_date
        },
        res => {
          if (res.data.medical_insurance) {
            this.savePublicMoney();
          } else {
            // 公費期間で有効な医療保険がない場合は、確認ダイアログを表示
            this.$openConfirm(
              "有効期間中に医療保険の登録がありません。それでも登録しますか？"
            ).then(isOK => {
              if (isOK) {
                this.savePublicMoney();
              }
            });
          }
        }
      );
    } else {
      this.savePublicMoney();
    }
  }

  private savePublicMoney() {
    if (this.mode) {
      this.public_money.id = 0;
    }
    this.public_money.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/public/save",
      {
        public_money: this.public_money
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //変更
  private update() {
    this.public_money.id = this.insPublicId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.public_money.id = this.insPublicId;
      this.public_money.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/public/delete",
        {
          public_money: this.public_money
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  //公費情報取得
  private fetchInsurerPublic(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/public/get",
      {
        patient_id: this.patientId,
        ins_public_id: this.insPublicId
      },
      res => {
        this.public_money_divs = res.data.public_moeny_divs;
        this.createAddUserPublicMoneyDivs(); //ユーザー追加の公費種類一覧作成
        //公費情報
        if (this.insPublicId != 0) {
          this.public_money = res.data.public_money;
          if (this.mode) {
            this.public_money.start_date = "";
            this.public_money.end_date = "";
          }
          this.public_money.honobono_link_id = 0;
        }
        this.changeHeader(this.public_money.use_other_medical_amount);
        this.handleBurdens();
        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  //-----------------------------------------------------
  // 公費自己負担制御
  //-----------------------------------------------------
  private handleBurdens() {
    // 開始 ~ 終了の値が変わると、対象年月以外のデータが消えてしまうので、記憶しておく
    this.public_money.self_burdens.forEach(burden => {
      const index = this.selfBurdens.findIndex(tmp => {
        return burden.target_yearmonth === tmp.target_yearmonth;
      });

      // 年月重複の場合は入力値を優先
      if (index > -1) {
        this.selfBurdens[index] = burden;
      } else {
        this.selfBurdens.push(burden);
      }
    });

    const start = this.public_money.start_date.replaceAll("-", "");
    const end = this.public_money.end_date.replaceAll("-", "");
    const startYear = start.slice(0, 4);
    const endYear = end.slice(0, 4);
    const startMonth = start.slice(4, 6);
    const endMonth = end.slice(4, 6);
    const startDateTime = new Date(Number(startYear), Number(startMonth));

    //開始日のみの場合は2年分出す
    let periods = 24;
    //開始日も終了日も空欄の場合
    if (
      this.public_money.start_date == "" &&
      this.public_money.end_date == ""
    ) {
      periods = 0;
    }
    //終了日がある場合開始日からの期間分出す
    if (this.public_money.end_date != "") {
      let yearLen = Number(endYear) - Number(startYear);
      const monthLen = Number(endMonth) - Number(startMonth) + 1;
      if (yearLen != 0) {
        yearLen = yearLen * 12;
      }
      periods = yearLen + monthLen;
      if (start == "") {
        periods = 0;
      }
    }

    //対象年月を調べる
    const yearmonths = [];
    for (let i = 0; i < periods; i++) {
      if (i > 23) {
        break;
      }
      let month = startDateTime.getMonth();
      let year = startDateTime.getFullYear();
      const targetMonth = month + i;
      year = Math.floor(targetMonth / 12) + year;
      month = targetMonth % 12;
      if (month == 0) {
        year -= 1;
        month = 12 + month;
      }
      let yearmonth = Number(`${year}${month}`);
      if (month < 10) {
        yearmonth = Number(`${year}0${month}`);
      }
      yearmonths.push(yearmonth);
    }

    //既存のデータに対して、対象年月でデータを置き換える
    //対象年月内の既存データ そのまま
    //対象年月内の新規データ 追加
    for (const yearmonth of yearmonths) {
      let addflg = true;
      for (const burden of this.public_money.self_burdens) {
        if (yearmonth === burden.target_yearmonth) {
          addflg = false;
          burden.target_yearmonth_disp =
            `${yearmonth}`.substring(0, 4) +
            "年" +
            `${yearmonth}`.substring(4) +
            "月";
        }
      }
      if (addflg) {
        const newSelfBurden = DefaultPublicMoneySelfBurden();
        newSelfBurden.public_money_id = this.public_money.id;
        newSelfBurden.target_yearmonth = yearmonth;
        newSelfBurden.target_yearmonth_disp =
          `${yearmonth}`.substring(0, 4) +
          "年" +
          `${yearmonth}`.substring(4) +
          "月";
        newSelfBurden.public_money_id = this.public_money.id;

        this.public_money.self_burdens.push(newSelfBurden);
      }
    }

    //対象年月外の既存データ 削除
    for (let i = 0; i < this.public_money.self_burdens.length; i++) {
      const burden = this.public_money.self_burdens[i];
      let delflg = true;
      for (const yearmonth of yearmonths) {
        if (yearmonth === burden.target_yearmonth) {
          delflg = false;
        }
      }
      if (delflg) {
        this.public_money.self_burdens.splice(i, 1);
        i--;
      }
    }

    this.public_money.self_burdens = this.public_money.self_burdens.map(
      burden => {
        // 対象月の入力記録がある場合は反映する
        const tmpBurden = this.selfBurdens.find(tmp => {
          return tmp.target_yearmonth === burden.target_yearmonth;
        });

        if (tmpBurden) {
          return tmpBurden;
        }

        return burden;
      }
    );
  }

  //-----------------------------------------------------
  // ダイアログ関連
  //-----------------------------------------------------
  //新規公費追加/編集ダイアログを開く
  private addEditOpen() {
    this.addEditDialog = true;
  }
  //新規作成ダイアログを開く
  private createNewOpen(): void {
    this.selectedAddUserPublicMoney = DefaultPublicMoneyKind();
    this.createNewDialog = true;
  }
  //新規作成ダイアログを閉じる
  private createNewClose() {
    this.createNewDialog = false;
  }

  //ユーザー追加の公費種類一覧作成
  private createAddUserPublicMoneyDivs() {
    this.addUserPublicMoneyDivs = [];
    for (const public_money_div of this.public_money_divs) {
      if (public_money_div.is_add_user == 1) {
        this.addUserPublicMoneyDivs.push(public_money_div);
      }
    }
  }

  //編集
  private EditPublicMoneyDiv(item: PublicMoneyKind) {
    this.selectedAddUserPublicMoney = item;
    this.createNewDialog = true;
  }
  //削除
  private async deletePublicMoneyKind(item: PublicMoneyKind) {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/public_money_kind/delete",
      {
        public_money_kind: item
      },
      () => {
        this.updatePublicMoneyDiv();
      }
    );
  }
  //保存
  private async savePublicMoneyDiv(item: unknown) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/public_money_kind/save",
      {
        public_money_kind: item
      },
      () => {
        this.updatePublicMoneyDiv();
      }
    );
  }
  //更新
  private updatePublicMoneyDiv(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/public/get",
      {
        patient_id: this.patientId,
        ins_public_id: this.insPublicId
      },
      res => {
        this.public_money_divs = res.data.public_moeny_divs;
        this.createAddUserPublicMoneyDivs(); //ユーザー追加の公費種類一覧作成
        this.createNewClose();
      }
    );
  }

  //-----------------------------------------------------
  // Watch表示切り替え
  //-----------------------------------------------------
  //編集モード設定
  @Watch("public_money", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  // 終了期間
  @Watch("range")
  handleRange() {
    if (this.range == 0 || this.public_money.start_date == "") {
      return;
    }

    const date = new Date(this.public_money.start_date.replace(/-/g, "/"));
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);

    switch (this.range) {
      case 1:
        date.setMonth(date.getMonth() + 1);
        date.setDate(date.getDate() - 1);
        this.public_money.end_date = date.toISOString().substr(0, 10);
        break;
      case 2:
        date.setMonth(date.getMonth() + 3);
        date.setDate(date.getDate() - 1);
        this.public_money.end_date = date.toISOString().substr(0, 10);
        break;
      case 3:
        date.setMonth(date.getMonth() + 6);
        date.setDate(date.getDate() - 1);
        this.public_money.end_date = date.toISOString().substr(0, 10);
        break;
      case 4:
        date.setMonth(date.getMonth() + 12);
        date.setDate(date.getDate() - 1);
        this.public_money.end_date = date.toISOString().substr(0, 10);
        break;
    }
  }

  //開始日：公費自己負担制御
  @Watch("public_money.start_date")
  private handleStart() {
    this.handleBurdens();
  }

  //終了日：公費自己負担制御
  @Watch("public_money.end_date")
  private handleEnd() {
    this.handleBurdens();
  }

  // 公費種類：負担率 / 給付率切り替え
  @Watch("public_money.public_money_kind_id")
  private handlePublicMoneyKindId() {
    if (!this.isLoaded) {
      return;
    }
    for (const div of this.public_money_divs) {
      if (this.public_money.public_money_kind_id === div.value) {
        return (this.public_money.payment_rate = div.payment_rate);
      }
    }
    return 0;
  }

  //-----------------------------------------------------
  // 表示切り替え
  //-----------------------------------------------------
  // 公費種類：選ばれた公費種類が医療：0、介護：1
  private get SelectInsuranceDiv(): number {
    for (const div of this.public_money_divs) {
      if (this.public_money.public_money_kind_id === div.value) {
        return div.insurance_div;
      }
    }
    return 0;
  }

  //医療：負担率 / 介護：給付率
  private get BurdenRatioLabel(): string {
    if (this.SelectInsuranceDiv == 0 && this.SelectIsChangePaymentRate == 0) {
      return "負担率";
    }
    if (this.SelectInsuranceDiv == 1 && this.SelectIsChangePaymentRate == 0) {
      return "給付率";
    }
    if (this.SelectInsuranceDiv == 0 && this.SelectIsChangePaymentRate == 1) {
      return "*負担率";
    }
    if (this.SelectInsuranceDiv == 1 && this.SelectIsChangePaymentRate == 1) {
      return "*給付率";
    }
    return "";
  }

  //他医療機関入力時：自己負担額計算表示
  private changeOtherMedicalAmount(
    item: PublicMoneySelfBurden,
    newValue: string
  ) {
    item.other_medical_amount = newValue;
    item.self_burden_amount = String(
      Number(this.public_money.self_bear_limit_amount) - Number(newValue)
    );
  }

  private get IsBurdenRatioShown(): boolean {
    const publicMoney = this.public_money_divs.find(div => {
      return div.value === this.public_money.public_money_kind_id;
    });

    if (publicMoney) {
      // 給付率が動的に変わる場合は負担率を表示しない
      return !publicMoney.is_dynamic_ratio;
    }

    return true;
  }

  //公費種類が医療の場合、負担率で表示（給付率でくるので表示を逆にする）
  private get BurdenRatio(): number {
    if (this.SelectInsuranceDiv == 0) {
      return 100 - this.public_money.payment_rate;
    } else {
      return this.public_money.payment_rate;
    }
  }
  private set BurdenRatio(newValue: number) {
    if (this.SelectInsuranceDiv == 0) {
      this.public_money.payment_rate = 100 - newValue;
    } else {
      this.public_money.payment_rate = newValue;
    }
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //負担者番号：先頭2桁の番号チェック(公費種類の法別番号が先頭2桁でないといけない)
  //その他公費と地方公費（ユーザー追加）はチェック対象外
  private checkInsurerNo(): boolean | string {
    if (this.public_money.public_money_kind_id != 0) {
      let checkFlg = true;
      let checkNo = "";
      for (const public_money_div of this.public_money_divs) {
        if (this.public_money.public_money_kind_id == public_money_div.value) {
          checkNo = String(public_money_div.no);
          //その他公費と地方公費（ユーザー追加）はチェック対象外
          if (public_money_div.no == 0 || public_money_div.is_add_user == 1) {
            checkFlg = false;
          }
        }
      }
      if (checkFlg && checkNo != this.public_money.insurer_no.slice(0, 2)) {
        return "公費種類に表示されている法別番号（2桁）と負担者番号の先頭2桁が一致しません。";
      }
    }
    return true;
  }

  //1日あたりの自己負担額上限額：必須切り替え
  private requiredDailySelfBearLimitAmount(): boolean | string {
    if (this.SelectPublicMoneyDiv && this.SelectPublicMoneyDiv.is_daily == 0) {
      return true;
    }
    return this.minNumber(
      this.public_money.daily_self_bear_limit_amount,
      1,
      "必須です"
    );
  }

  //自己負担額上限額：必須切り替え
  private requiredSelfBearLimitAmount(): boolean | string {
    // 負の数は必ず許容しない
    if (this.public_money.use_other_medical_amount == 0) {
      return this.minNumber(
        Number(this.public_money.self_bear_limit_amount),
        0,
        "0以上で入力してください"
      );
    }
    return this.minNumber(
      Number(this.public_money.self_bear_limit_amount),
      1,
      "必須です"
    );
  }

  // 終了日必須フラグ
  private get SelectIsEndDateRequired(): number {
    for (const div of this.public_money_divs) {
      if (this.public_money.public_money_kind_id === div.value) {
        return div.is_end_date_required;
      }
    }
    return 0;
  }

  // 終了日：必須切り替え
  private requiredEndDate(): boolean {
    return this.SelectIsEndDateRequired == 1;
  }

  // 他医療機関金額チェック
  private checkOtherMedicalAmount(
    otherMedicalAmount: number
  ): boolean | string {
    if (Number(this.public_money.self_bear_limit_amount) < otherMedicalAmount) {
      return "自己負担額上限額を超えています";
    }
    return this.minNumber(otherMedicalAmount, 0, "0以上で入力してください");
  }

  // 給付率変更フラグ
  private get SelectIsChangePaymentRate(): number {
    for (const div of this.public_money_divs) {
      if (this.public_money.public_money_kind_id === div.value) {
        return div.is_change_payment_rate;
      }
    }
    return 0;
  }
  // 給付率：必須切り替え
  private requiredBurdenRatio(): boolean | string {
    if (this.SelectIsChangePaymentRate == 0) {
      this.isBurdenRatio = true;
      return true;
    }
    this.isBurdenRatio = false;
    if (this.public_money.payment_rate) {
      return this.maxNumber(
        this.public_money.payment_rate,
        100,
        "給付率は1～100で入力してください"
      );
    }
    return this.minNumber(this.public_money.payment_rate, 1, "必須です");
  }

  /** 負担者番号のルール */
  private get InsurerNoRules() {
    if (this.TypeIsNotReceiptLink) {
      return [this.checkInsurerNo()];
    }
    return [
      this.required(this.public_money.insurer_no, "必須です"),
      this.checkInsurerNo()
    ];
  }

  /** 受給者番号のルール */
  private get InsuredNoRules() {
    if (this.TypeIsNotReceiptLink) {
      return [];
    }
    return [this.required(this.public_money.insured_no, "必須です")];
  }

  /** 選択された公費種類 */
  private get SelectPublicMoneyDiv(): PublicMoneyDivs | undefined {
    for (const public_money_div of this.public_money_divs) {
      if (this.public_money.public_money_kind_id == public_money_div.value) {
        return public_money_div;
      }
    }
    return undefined;
  }

  /** レセプト連携しないが選ばれているかどうか */
  private get TypeIsNotReceiptLink() {
    return (
      this.SelectPublicMoneyDiv &&
      this.SelectPublicMoneyDiv.is_receipt_link == 0
    );
  }
}
