













import { Component, Mixins, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component({})
export default class BatchStaffLinkSaveResult extends Mixins(UtilMixin) {
  /** 終了ボタンクリック時 */
  @Emit("click:complete")
  private completed() {
    return;
  }
}
