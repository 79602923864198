



































































import AxiosMixin from "@/mixins/axiosMixin";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import AppFileInput from "../AppFileInput.vue";
import AppTextArea from "../AppTextArea.vue";
import AppChatStampList, { StampGroup, Stamp } from "./AppChatStampList.vue";

@Component({
  components: { AppFileInput, AppTextArea, AppChatStampList }
})
export default class AppChatList extends AxiosMixin {
  private selectedStamp: Stamp | null = null;

  private text = "";
  private isShownStampList = false;
  private stampGroups: StampGroup[] = [];

  created() {
    this.postJsonBackground(
      window.chat_backend_url + "/api/chat/stamp-groups/get",
      {},
      res => {
        this.stampGroups = res.data.stamp_groups;
      }
    );
  }

  @Emit()
  private sendImage(file: File) {
    return file;
  }

  @Emit()
  private sendMessage(message: string) {
    return message;
  }

  @Emit()
  private sendStamp(stamp: Stamp) {
    return stamp;
  }

  private get isSendable() {
    if (this.selectedStamp) {
      return true;
    }

    return this.text.replace(/\s+/g, "") !== "";
  }

  private send(e: KeyboardEvent) {
    if (this.selectedStamp) {
      this.sendStamp(this.selectedStamp);
      this.selectedStamp = null;
    }

    e.preventDefault();

    if (e.isComposing) {
      return;
    }

    // 空・空白、改行のみの場合は何もしない
    const r = this.text.replace(/\s+/g, "");
    if (!r) return;

    this.sendMessage(this.text);
    this.text = "";
  }

  private toggleStamp() {
    this.isShownStampList = !this.isShownStampList;
    // スタンプ画面を閉じたとき、スタンプの選択をクリア
    if (!this.isShownStampList) {
      this.selectedStamp = null;
    }
  }

  private upload(file?: File) {
    if (!file) {
      return;
    }

    if (file.size > 1e7) {
      this.$openAlert("ファイルサイズが10MBを超えています");
      return;
    }

    this.sendImage(file);
  }
}
