import Base from "../base";
import { PatientPreNursingMonth } from "./patientPreNursingMonth";
import {
  ApplyDoctor,
  DefaultApplyDoctor,
  ApplyPersonnel,
  DefaultApplyPersonnel
} from "./../common";

export interface PatientHistory extends Base {
  id: number; //'ID',
  office_id: number; //'事業所ID'
  patient_id: number; //'利用者ID',
  adl_id: number; //'ADLID（ADLから反映された場合）',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  display_id: string; //'表示用利用者ID',
  last_name: string; //'姓',
  last_name_kana: string; //'姓ふりがな',
  first_name: string; //'名',
  first_name_kana: string; //'名ふりがな',
  sex: number; //'性別\n1:男\n2:女',
  birthday: string; //'生年月日',
  visit_place_div: number; // 訪問場所区分（訪問した場所）\n1:自宅\n2:施設\n3:その他
  visit_place_facility_div: number; // 訪問場所施設区分\n1:01社会福祉施設及び身体障害者施設\n2:02小規模多機能型居宅介護\n3:03複合型サービス\n4:04認知症対応型グループホーム\n5:05特定施設\n6:06地域密着型介護老人福祉施設及び介護老人福祉施設
  post_code: string; //'郵便番号',
  pref: number; //'都道府県',
  city: string; //'市区町村',
  area: string; //'番地',
  building: string; //'建物',
  is_use_residence_cert_address: number; //'住民票登録先住所使用フラグ',
  residence_post_code: string; //'住民票登録先郵便番号',
  residence_pref: number; //'住民票登録先都道府県',
  residence_city: string; //'住民票登録先市町村',
  residence_area: string; //'住民票登録先番地',
  residence_building: string; //'住民票登録先建物',
  residence_tel_area: string; //'住民票登録先電話番号市外局番',
  residence_tel_city: string; //'住民票登録先電話番号市内局番',
  residence_tel_num: string; //'住民票登録先電話番号加入者番号',
  need_time: number; //'訪問に要する時間（分）',
  special_area_add: number; //'特別地域加算（医療）',
  tel_area: string; //'電話番号市外局番',
  tel_city: string; //'電話番号市内局番',
  tel_no: string; //'電話番号加入者番号',
  tel_contact: string; // '電話番号連絡先',
  mobile_area: string; //'携帯電話番号市外局番',
  mobile_city: string; //'携帯電話番号市内局番',
  mobile_no: string; //'携帯電話番号加入者番号',
  mobile_contact: string; // '携帯電話番号連絡先',
  job_type: number; //'職種\n0:未選択\n1:自営業\n2:会社員\n3:公務員\n4:主婦（主夫）\n5:学生\n6:無職\n7:その他',
  first_visit_date: string; //'初回訪問日',
  before_use_type: number; //'利用前所在\n0:未選択\n1:他訪問看護ステーション\n2:介護施設\n3:医療機関入院\n4:居宅\n5:施設',
  primary_doctor_id: number; //'かかりつけ医師ID',
  submit_info: string; //'提出した情報の概要',
  visit_end_date: string; //'訪問看護終了日',
  visit_end_time_hour: string; //'訪問看護終了時間',
  visit_end_time_minute: string; //'訪問看護終了時間',
  end_reason: number; //'終了理由\n0:未選択\n1:軽快\n2:死亡\n3:入院\n4:社会福祉施設入所\n5:他訪問看護ステーション\n6:その他',
  end_reason_other_comment: string; //'終了理由その他コメント',
  after_destination: string; //'利用後行先',
  stay_doctor_id: number; //'入院先医師ID',
  prevent_gensan_month: string; //予防訪問看護減算起算開始月
  medical_covid19_add_flg: number; //訪問看護感染症対策実施加算回数登録フラグ
  medical_covid19_add_cnt: number; //訪問看護感染症対策実施加算回数
  death_date: string; //'死亡日時',
  death_time_hour: string; // 死亡時間（時）
  death_time_minute: string; // 死亡時間（分）
  death_place: number; //'死亡場所',
  death_place_facility_div: number; // 死亡場所施設区分\n1:01社会福祉施設及び身体障害者施設\n2:02小規模多機能型居宅介護\n3:03複合型サービス\n4:04認知症対応型グループホーム\n5:05特定施設\n6:06地域密着型介護老人福祉施設及び介護老人福祉施設
  death_place_other: string; //'死亡場所その他',
  death_place_detail: string; //'死亡場所詳細',
  death_doctor_id: number; //'死亡医師ID',
  care_service_start_date: string; //'介護サービス開始日',
  care_service_end_date: string; //'介護サービス中止日',
  care_service_end_reason: number; //'介護サービス中止理由\n0:未選択\n1:非該当\n2:医療機関入院\n3:死亡\n4:その他\n5:介護老人福祉施設入所\n6:介護老人保健施設入所\n7:介護療養型医療施設入所\n8:介護医療院入所',
  use_regular_visit: number; //'定期巡回使用フラグ',
  regular_visit_service_type: number; //'定期巡回サービス内容\n1:定期・随時\n2:2:定期のみ\n3:随時のみ',
  regular_visit_term_from: string; //'定期巡回開始日',
  regular_visit_calc_type_from: number; //'定期巡回計算内容(開始)\n1:定期\n2:日割り',
  regular_visit_term_to: string; //'定期巡回終了日',
  regular_visit_calc_type_to: number; //'定期巡回計算内容(終了)\n1:定期\n2:日割り',
  remarks: string; //'備考',
  mind_and_body_state: string; //'心身の状態',
  mind_and_body_state_no_copy: number; //'心身の状態非反映フラグ\n月次報告書提出時に病状の経過の内容をコピーしないようにする\n1:自動反映しない\n0:自動反映する',
  is_birthday: number; //誕生日かどうか
  age: number; //年齢
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
  birthday_wareki: string; //誕生日(和暦)
  first_visit_date_wareki: string; //初回訪問日(和暦)
  visit_end_datetime_wareki: string; //訪問終了日時(和暦)
  death_datetime_wareki: string; //死亡日時(和暦)
  care_service_start_date_wareki: string; //介護サービス開始日(和暦)
  care_service_end_date_wareki: string; //介護サービス中止日(和暦)
  regular_visit_term_from_wareki: string; //定期巡回開始日(和暦)
  regular_visit_term_to_wareki: string; //定期巡回終了日(和暦)
  pay_kbn: number; //支払方法\n1:集金\n2:振込\n3:引落
  withdrawal_st_kbn: number; //引落開始設定\n1:開始する\n2:開始しない
  financial_institution_kbn: number; //銀行選択\n1:ゆうちょ\n2:ゆうちょ銀行以外の金融機関
  institution_no: string; //振替金融機関コード
  institution_name: string; //振替金融機関名
  branch_no: string; //支店コード
  branch_name: string; //支店名
  deposit_kbn: number; //預金種目\n1:普通\n2:当座
  account_no: string; //口座番号
  depositor: string; //預金者名
  use_invoice_address: number; //'請求書送付先住所使用フラグ',
  invoice_family_name: string; //'請求書送付先-氏',
  invoice_first_name: string; //'請求書送付先-名',
  invoice_family_name_kana: string; //請求書送付先-氏(ふりがな)',
  invoice_first_name_kana: string; //'請求書送付先-名(ふりがな)',
  invoice_zip_code: string; //'請求書送付先-郵便番号',
  invoice_pref: number; //'請求書送付先-都道府県',
  invoice_city: string; //'請求書送付先-市区町村・町域',
  invoice_area: string; //'請求書送付先-番地',
  invoice_build: string; //'請求書送付先-建物',
  invoice_tel_area: string; //'請求書送付先-電話番号-市外局番',
  invoice_tel_city: string; //'請求書送付先-電話番号-市内局番',
  invoice_tel_num: string; //'請求書送付先-電話番号-加入者番号'

  prevent_gensan_month_not_reset: number;

  requester_type: number; //依頼元種類
  requester_personnel_id: number; //担当者ID
  requester_doctor_id: number; //医師ID
  requester_tel_area: string; //電話番号市外局番
  requester_tel_city: string; //電話番号市内局番
  requester_tel_num: string; //電話番号加入者番号
  requester_fullname: string; //依頼元:氏名
  requester_family_relationship_id: number; //依頼元:続柄
  requester_contact_info: string; //連絡事項
  comment: string; //履歴コメント
  history_type: number; // 履歴種類 0:初期 1:手動作成 2:レセプト確定による自動生成

  patient_pre_nursing_months?: PatientPreNursingMonth[];

  primary_doctor: ApplyDoctor; //かかりつけ医師
  stay_doctor: ApplyDoctor; //入院先医師
  death_doctor: ApplyDoctor; //死亡医師
  personnel: ApplyPersonnel; //担当者
  doctor: ApplyDoctor; //医師
}

export const DefaultPatientHistory = (): PatientHistory => ({
  id: 0, //'ID',
  office_id: 0, //'事業所ID'
  patient_id: 0, //'利用者ID',
  adl_id: 0, //'ADLID（ADLから反映された場合）',
  start_date: "", //'開始日',
  end_date: "", //'終了日',
  display_id: "", //'表示用利用者ID',
  last_name: "", //'姓',
  last_name_kana: "", //'姓ふりがな',
  first_name: "", //'名',
  first_name_kana: "", //'名ふりがな',
  sex: 0, //'性別\n1:男\n2:女',
  birthday: "", //'生年月日',
  visit_place_div: 0, // //訪問場所区分（訪問した場所）\n1:自宅\n2:施設\n3:その他
  visit_place_facility_div: 0, // 訪問場所施設区分\n1:01社会福祉施設及び身体障害者施設\n2:02小規模多機能型居宅介護\n3:03複合型サービス\n4:04認知症対応型グループホーム\n5:05特定施設\n6:06地域密着型介護老人福祉施設及び介護老人福祉施設
  post_code: "", //'郵便番号',
  pref: 0, //'都道府県',
  city: "", //'市区町村',
  area: "", //'番地',
  building: "", //'建物',
  is_use_residence_cert_address: 0, //'住民票登録先住所使用フラグ',
  residence_post_code: "", //'住民票登録先郵便番号',
  residence_pref: 0, //'住民票登録先都道府県',
  residence_city: "", //'住民票登録先市町村',
  residence_area: "", //'住民票登録先番地',
  residence_building: "", //'住民票登録先建物',
  residence_tel_area: "", //'住民票登録先電話番号市外局番',
  residence_tel_city: "", //'住民票登録先電話番号市内局番',
  residence_tel_num: "", //'住民票登録先電話番号加入者番号',
  need_time: 0, //'訪問に要する時間（分）',
  special_area_add: 0, //'特別地域加算（医療）',
  tel_area: "", //'電話番号市外局番',
  tel_city: "", //'電話番号市内局番',
  tel_no: "", //'電話番号加入者番号',
  tel_contact: "", //'電話番号連絡先',
  mobile_area: "", //'携帯電話番号市外局番',
  mobile_city: "", //'携帯電話番号市内局番',
  mobile_no: "", //'携帯電話番号加入者番号',
  mobile_contact: "", //'携帯電話番号連絡先',
  job_type: 0, //'職種\n0:未選択\n1:自営業\n2:会社員\n3:公務員\n4:主婦（主夫）\n5:学生\n6:無職\n7:その他',
  first_visit_date: "", //'初回訪問日',
  before_use_type: 0, //'利用前所在\n0:未選択\n1:他訪問看護ステーション\n2:介護施設\n3:医療機関入院\n4:居宅\n5:施設',
  primary_doctor_id: 0, //'かかりつけ医師ID',
  submit_info: "", //'提出した情報の概要',
  visit_end_date: "", //'訪問看護終了日',
  visit_end_time_hour: "", // 訪問看護終了時間（時）
  visit_end_time_minute: "", // 訪問看護終了時間（分）
  end_reason: 0, //'終了理由\n0:未選択\n1:軽快\n2:死亡\n3:入院\n4:社会福祉施設入所\n5:他訪問看護ステーション\n6:その他',
  end_reason_other_comment: "", //'終了理由その他コメント',
  after_destination: "", //'利用後行先',
  stay_doctor_id: 0, //'入院先医師ID',
  prevent_gensan_month: "",
  medical_covid19_add_flg: 0,
  medical_covid19_add_cnt: 0,
  death_date: "", //'死亡日',
  death_time_hour: "", // 死亡時間（時）
  death_time_minute: "", // 死亡時間（分）
  death_place: 0, //'死亡場所',
  death_place_facility_div: 0, // 死亡場所施設区分\n1:01社会福祉施設及び身体障害者施設\n2:02小規模多機能型居宅介護\n3:03複合型サービス\n4:04認知症対応型グループホーム\n5:05特定施設\n6:06地域密着型介護老人福祉施設及び介護老人福祉施設
  death_place_other: "", //'死亡場所その他',
  death_place_detail: "", //'死亡場所詳細',
  death_doctor_id: 0, //'死亡医師ID',
  care_service_start_date: "", //'介護サービス開始日',
  care_service_end_date: "", //'介護サービス中止日',
  care_service_end_reason: 0, //'介護サービス中止理由\n0:未選択\n1:非該当\n2:医療機関入院\n3:死亡\n4:その他\n5:介護老人福祉施設入所\n6:介護老人保健施設入所\n7:介護療養型医療施設入所\n8:介護医療院入所',
  use_regular_visit: 0, //'定期巡回使用フラグ',
  regular_visit_service_type: 0, //'定期巡回サービス内容\n1:定期・随時\n2:2:定期のみ\n3:随時のみ',
  regular_visit_term_from: "", //'定期巡回開始日',
  regular_visit_calc_type_from: 0, //'定期巡回計算内容(開始)\n1:定期\n2:日割り',
  regular_visit_term_to: "", //'定期巡回終了日',
  regular_visit_calc_type_to: 0, //'定期巡回計算内容(終了)\n1:定期\n2:日割り',
  remarks: "", //'備考',
  mind_and_body_state: "", //'心身の状態',
  mind_and_body_state_no_copy: 0, //'心身の状態非反映フラグ\n月次報告書提出時に病状の経過の内容をコピーしないようにする\n1:自動反映しない\n0:自動反映する',
  is_birthday: 0, //誕生日かどうか
  age: 0, //年齢
  start_date_wareki: "", //開始日(和暦)
  end_date_wareki: "", //終了日(和暦)
  birthday_wareki: "", //誕生日(和暦)
  first_visit_date_wareki: "", //初回訪問日(和暦)
  visit_end_datetime_wareki: "", //訪問終了日時(和暦)
  death_datetime_wareki: "", //死亡日時(和暦)
  care_service_start_date_wareki: "", //介護サービス開始日(和暦)
  care_service_end_date_wareki: "", //介護サービス中止日(和暦)
  regular_visit_term_from_wareki: "", //定期巡回開始日(和暦)
  regular_visit_term_to_wareki: "", //定期巡回終了日(和暦)
  pay_kbn: 0, //支払方法\n1:集金\n2:振込\n3:引落
  withdrawal_st_kbn: 0, //引落開始設定\n1:開始する\n2:開始しない
  financial_institution_kbn: 0, //銀行選択\n1:ゆうちょ\n2:ゆうちょ銀行以外の金融機関
  institution_no: "", //振替金融機関コード
  institution_name: "", //振替金融機関名
  branch_no: "", //支店コード
  branch_name: "", //支店名
  deposit_kbn: 0, //預金種目\n1:普通\n2:当座
  account_no: "", //口座番号
  depositor: "", //預金者名
  use_invoice_address: 0, //'請求書送付先住所使用フラグ',
  invoice_family_name: "", //'請求書送付先-氏',
  invoice_first_name: "", //'請求書送付先-名',
  invoice_family_name_kana: "", //請求書送付先-氏(ふりがな)',
  invoice_first_name_kana: "", //'請求書送付先-名(ふりがな)',
  invoice_zip_code: "", //'請求書送付先-郵便番号',
  invoice_pref: 0, //'請求書送付先-都道府県',
  invoice_city: "", //'請求書送付先-市区町村・町域',
  invoice_area: "", //'請求書送付先-番地',
  invoice_build: "", //'請求書送付先-建物',
  invoice_tel_area: "", //'請求書送付先-電話番号-市外局番',
  invoice_tel_city: "", //'請求書送付先-電話番号-市内局番',
  invoice_tel_num: "", //'請求書送付先-電話番号-加入者番号'
  created_at: "",
  updated_at: "",
  deleted_at: "",
  prevent_gensan_month_not_reset: 0,
  requester_type: 0,
  requester_personnel_id: 0,
  requester_doctor_id: 0,
  requester_tel_area: "",
  requester_tel_city: "",
  requester_tel_num: "",
  requester_fullname: "",
  requester_family_relationship_id: 0,
  requester_contact_info: "",
  comment: "",
  history_type: 0,
  primary_doctor: DefaultApplyDoctor(),
  stay_doctor: DefaultApplyDoctor(),
  death_doctor: DefaultApplyDoctor(),
  personnel: DefaultApplyPersonnel(),
  doctor: DefaultApplyDoctor()
});