










































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { BreadItem } from "@/store/modules/patient/types";
import * as bbs from "@/store/modules/bbs/types";
import { Route } from "vue-router";
import FocusMixin from "@/mixins/focusingMixin";

Component.registerHooks(["beforeRouteUpdate"]);

@Component({})
export default class BbsManage extends Mixins(AxiosMixin, FocusMixin) {
  private bbsId = 0;
  private title = "";
  private date = "";
  private notice = "";
  private boradDiv = 0;
  private isFirstView = false;
  private bbsIds: number[] = [];
  private breadItems: BreadItem[] = [];

  private get CurrentPage() {
    const index = this.bbsIds.findIndex(b => {
      return b === this.bbsId;
    });

    if (index > -1) {
      return index + 1;
    }

    return 0;
  }

  private get AllPage() {
    return this.bbsIds.length;
  }

  private get HeaderTitle(): string {
    switch (this.boradDiv) {
      case bbs.BoardDiv.iBow:
        return "iBowに関するお知らせ";
      case bbs.BoardDiv.ewell:
        return "eWeLLからのお知らせ";
      case bbs.BoardDiv.support:
        return "サポートからのお知らせ";
    }

    return "";
  }

  private created() {
    this.bbsId = Number(this.$route.params.bbsid);
    this.setBreadItems();
    this.getBbsNotice();
  }

  // 参考: https://v3.router.vuejs.org/ja/guide/essentials/dynamic-matching.html#%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E5%A4%89%E6%9B%B4%E3%81%AE%E6%A4%9C%E7%9F%A5
  // ルートの行き先が現在のルートと同じで、かつパラメータのみが変更されている場合(例: /users/1 -> /users/2 のようにあるプロファイルから他へ)、変更(例: ユーザー情報の取得など)に反応するためにbeforeRouteUpdate を使用しなければなりません。
  beforeRouteUpdate(to: Route, from: Route, next: Function) {
    this.bbsId = Number(to.params.bbsid);
    this.setBreadItems();
    this.getBbsNotice();
    next();
  }

  //戻る
  private cancel() {
    this.$router.go(-1);
  }

  // パンくずリストを設定
  private setBreadItems() {
    this.breadItems = [
      {
        text: "掲示板",
        disabled: false,
        to: "/bbs",
        exact: true
      },
      {
        text: this.title,
        disabled: true,
        to: ""
      }
    ];
  }

  private changePage(page: number) {
    if (page > this.bbsIds.length || page <= 0) {
      return;
    }

    const id = this.bbsIds[page - 1];
    // 別タブ（メニューなし）もありうる
    if (this.IsFocusingMode) {
      this.$router.push({ path: `/bbs/notice-sep/${id}` });
    } else {
      this.$router.push({ path: `/bbs/notice/${id}` });
    }
  }

  //お知らせ取得
  private getBbsNotice(): void {
    this.postJsonCheck(
      window.base_url + "/api/bbsnotice/get",
      {
        bbs_id: this.bbsId
      },
      res => {
        this.title = res.data.title;
        this.date = res.data.date;
        this.notice = res.data.notice;
        this.boradDiv = res.data.board_div;
        this.isFirstView = res.data.is_first_view;
        this.bbsIds = res.data.all_notice_ids;
        this.setBreadItems();
      }
    );
  }

  private closeWindow() {
    window.close();
  }
}
