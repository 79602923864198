



























































import { Component, Mixins, Prop, Watch, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import Items from "@/components/patient/common/observationitem/items.vue";
import { Observation } from "#/model/report";
import { VisitPatient } from "@/components/visit/types";
import { ObservationCategoryMaster, ObservationCategory } from "#/model/master";
import * as constant from "#/const";
import * as appDate from "#/utility/appDate";

@Component({
  components: { Items }
})
export default class ObservationItem extends Mixins(UtilMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop() private observations!: Observation[]; //観察項目
  @Prop({ default: false }) private copy_recent_evaluation_adl!: number; //前回評価を参照フラグ
  @Prop() private readonly master!: ObservationCategoryMaster; //観察項目マスタ
  @Prop() private readonly before!: Observation[]; //前回観察項目
  @Prop({ default: "" }) private readonly before_date!: string; //前回評価日
  @Prop() private readonly patient!: VisitPatient; // 利用者
  @Prop({ default: 0 }) private readonly jobType!: number; // 訪問者 職種
  @Prop({ default: false }) private readonly dispAllCategoryCheck!: boolean; // 「全てのカテゴリを表示」の表示フラグ
  @Prop({ default: 0 }) private readonly adlSetting!: number; // 観察項目の登録数制限フラグ(0:最大21項目 1:制限を解除)

  /*
   * emit
   */

  @Emit("update:copy_recent_evaluation_adl")
  private updateCopyRecentEvaluationAdl(newValue: number) {
    return newValue;
  }

  /**
   * watch
   */

  @Watch("copy_recent_evaluation_adl")
  handleCopyRecentEvaluationAdl() {
    this.inner_copy_recent_evaluation_adl = this.copy_recent_evaluation_adl;
  }

  /*
   * data
   */

  // 前回評価を参照フラグ
  private inner_copy_recent_evaluation_adl = 0;

  // 「全てのカテゴリを表示」v-model
  private showAllCategories = false;

  /*
   * computed
   */

  private get observationCategories(): ObservationCategory[] {
    // 利用者情報が存在しない、または、「全てのカテゴリを表示」にチェックがある場合は絞り込みを行わない
    if (!this.patient || this.showAllCategories) {
      return this.master.observation_categories;
    }

    // 利用者の訪問区分、訪問者の職種によりカテゴリを絞り込む
    const categories = [] as ObservationCategory[];
    this.master.observation_categories.forEach(category => {
      // 介護保険適用
      if (this.patient.ins_div === constant.PATIENT.INSURANCE_DIV_CARE) {
        // 介護
        if (!this.isRehabilitation && category.use_care === 1) {
          categories.push(category);
          return;
        }
        // 介護 リハ
        if (this.isRehabilitation && category.use_care_rehabilitation === 1) {
          categories.push(category);
          return;
        }
      }

      // 医療保険適用（通常）
      if (
        this.patient.ins_div === constant.PATIENT.INSURANCE_DIV_MEDICAL &&
        this.patient.mental_div === constant.PATIENT.MENTAL_DIV_NORMAL
      ) {
        // 医療
        if (!this.isRehabilitation && category.use_medical === 1) {
          categories.push(category);
          return;
        }
        // 医療 リハ
        if (
          this.isRehabilitation &&
          category.use_medical_rehabilitation === 1
        ) {
          categories.push(category);
          return;
        }
      }

      // 医療保険適用（精神）
      if (
        this.patient.ins_div === constant.PATIENT.INSURANCE_DIV_MEDICAL &&
        this.patient.mental_div === constant.PATIENT.MENTAL_DIV_MENTAL
      ) {
        // 精神
        if (!this.isRehabilitation && category.use_medical_mental === 1) {
          categories.push(category);
          return;
        }
        // 精神 リハ
        if (
          this.isRehabilitation &&
          category.use_medical_mental_rehabilitation === 1
        ) {
          categories.push(category);
          return;
        }
      }
    });
    return categories;
  }

  // リハ判定
  private get isRehabilitation(): boolean {
    if (4 <= this.jobType && this.jobType <= 6) {
      // PT、OT、ST
      return true;
    }
    return false;
  }

  // 前回評価日
  private get beforeDate(): string {
    if (!this.before_date) {
      return "";
    }
    const d = appDate.strToDate(this.before_date);
    return appDate.dateToStr(d, "前回評価：yyyy年MM月dd日");
  }

  /*
   * method
   */

  // 前回評価参照フラグ切り替え
  private changeCopyRecent(event: boolean) {
    this.inner_copy_recent_evaluation_adl = +event;
    this.updateCopyRecentEvaluationAdl(this.inner_copy_recent_evaluation_adl);
    if (event) {
      for (const observation of this.observations) {
        const beforeObservation = this.before.find(
          b =>
            b.observation_category_item_id ==
              observation.observation_category_item_id &&
            b.observation_item_id == observation.observation_item_id
        );
        if (beforeObservation) {
          observation.observation_select_item_id =
            beforeObservation.observation_select_item_id;
          observation.observation_comment =
            beforeObservation.observation_comment;
        }
      }
    }
  }
}
