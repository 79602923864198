
























import { Component, Prop, Mixins } from "vue-property-decorator";
import { ShiftTemplate } from "#/model/schedule";
import ShiftMixin from "@/components/shift/shiftMixin";

@Component
export default class StaffShiftList extends Mixins(ShiftMixin) {
  /** シフトテンプレート */
  @Prop({ default: () => [] }) shift_templates!: ShiftTemplate[];
}
