/** 依頼元種類 */
export const PRIREQUESTER_TYPE = {
  /** 未選択 */
  NOT: 0,
  /** 関連機関 */
  RELATED: 1,
  /** 医療機関 */
  MEDICAL: 2,
  /** 利用者本人 */
  SELF: 3,
  /** 家族 */
  FAMILY: 4,
  /** 後見人 */
  GUARDIAN: 5,
  /** その他 */
  OTHER: 6
} as const;

export type PRIREQUESTER_TYPE = typeof PRIREQUESTER_TYPE[keyof typeof PRIREQUESTER_TYPE];
