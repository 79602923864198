var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppDialog',{attrs:{"prefix_id":(_vm.prefix_id + "-dialog"),"title":"自費登録","isDelete":_vm.model.is_edited},on:{"save":_vm.clickSave,"cancel":_vm.cancel,"remove":_vm.clickDelete},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form"},[_c('AppCombobox',{staticClass:"mb-4",attrs:{"id":(_vm.prefix_id + "-category-name"),"label":"カテゴリ","isNeed":true,"rules":[_vm.required(_vm.localModel.category_name, '必須です')],"items":_vm.ownExpenseCategorys,"maxlength":"15"},on:{"change":_vm.changeItem},model:{value:(_vm.localModel.category_name),callback:function ($$v) {_vm.$set(_vm.localModel, "category_name", $$v)},expression:"localModel.category_name"}}),_c('AppCombobox',{staticClass:"mb-4",attrs:{"id":(_vm.prefix_id + "-item-name"),"label":"アイテム名","isNeed":true,"rules":[_vm.required(_vm.localModel.item_name, '必須です')],"items":_vm.RefinementOwnExpenseItems,"disabled":_vm.localModel.category_name === '',"maxlength":"20"},on:{"change":_vm.changeItem},model:{value:(_vm.localModel.item_name),callback:function ($$v) {_vm.$set(_vm.localModel, "item_name", $$v)},expression:"localModel.item_name"}}),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('ZeroDispNumberInput',{staticClass:"mr-4",attrs:{"id":(_vm.prefix_id + "-unit-price"),"label":"単価","width":"calc(50% - 8px)","min-width":"100px","maxlength":"8","isNeed":true,"required":true,"isNeedDispEmptyFirst":_vm.IsNeedDispEmptyFirst,"rules":[
          _vm.minNumber(
            Number(_vm.localModel.unit_price),
            0,
            '0以上で入力してください'
          )
        ]},model:{value:(_vm.localModel.unit_price),callback:function ($$v) {_vm.$set(_vm.localModel, "unit_price", $$v)},expression:"localModel.unit_price"}}),_c('ZeroDispNumberInput',{attrs:{"id":(_vm.prefix_id + "-quantity"),"label":"数量","width":"calc(50% - 8px)","min-width":"100px","maxlength":"4","isNeed":true,"required":true,"isNeedDispEmptyFirst":_vm.IsNeedDispEmptyFirst,"rules":[
          _vm.minNumber(Number(_vm.localModel.quantity), 0, '0以上で入力してください')
        ]},model:{value:(_vm.localModel.quantity),callback:function ($$v) {_vm.$set(_vm.localModel, "quantity", $$v)},expression:"localModel.quantity"}})],1),_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('AppSelect',{staticClass:"mr-4",attrs:{"id":(_vm.prefix_id + "-tax-div"),"width":"calc(50% - 8px)","min-width":"100px","label":"課税区分","items":_vm.masters.tax_divs},on:{"change":function($event){return _vm.changeTaskDiv(_vm.localModel)}},model:{value:(_vm.localModel.tax_div),callback:function ($$v) {_vm.$set(_vm.localModel, "tax_div", $$v)},expression:"localModel.tax_div"}}),_c('AppSelect',{attrs:{"id":(_vm.prefix_id + "-tax-rate"),"width":"calc(50% - 8px)","min-width":"100px","label":"課税率（％）","disabled":_vm.localModel.tax_div == 0,"items":_vm.masters.tax_rates,"rules":[_vm.RuleTaxRate]},model:{value:(_vm.localModel.tax_rate),callback:function ($$v) {_vm.$set(_vm.localModel, "tax_rate", $$v)},expression:"localModel.tax_rate"}})],1),_c('AppSelect',{staticClass:"mb-4",attrs:{"id":(_vm.prefix_id + "-medical-deducation"),"label":"医療費控除","items":_vm.masters.yes_or_no},model:{value:(_vm.localModel.medical_deducation),callback:function ($$v) {_vm.$set(_vm.localModel, "medical_deducation", $$v)},expression:"localModel.medical_deducation"}}),_c('AppMonthPicker',{staticClass:"mb-4",attrs:{"prefix_id":(_vm.prefix_id + "-yearmonth"),"width":"100%","label":"請求月","clearable":false},model:{value:(_vm.localModel.yearmonth),callback:function ($$v) {_vm.$set(_vm.localModel, "yearmonth", $$v)},expression:"localModel.yearmonth"}}),_c('AppTextField',{attrs:{"id":(_vm.prefix_id + "-remarks"),"label":"備考","maxlength":"32","counterNum":32},model:{value:(_vm.localModel.remarks),callback:function ($$v) {_vm.$set(_vm.localModel, "remarks", $$v)},expression:"localModel.remarks"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }