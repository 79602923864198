import { Component, Prop, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import ShiftMixin from "@/components/shift/shiftMixin";
import { ShiftTemplate, DefaultShiftTemplate } from "#/model/schedule";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";
import RulesMixin from "#/mixins/rulesMixin";
import { SHIFT_COLORS } from "./types";

@Component
export default class OfficeShiftTypeEntry extends Mixins(
  AxiosMixin,
  ShiftMixin,
  UtilMixin,
  RulesMixin
) {
  /** 事業所ID */
  @Prop({ default: 0 }) officeId!: number;

  /** 職員ID */
  @Prop({ default: 0 }) staffId!: number;

  /** シフトテンプレート */
  protected shift_templates: ShiftTemplate[] = [];

  /** シフトテンプレート */
  private shiftTemplate: ShiftTemplate = DefaultShiftTemplate();

  /** シフト種類色 */
  private colors: string[] = SHIFT_COLORS;

  /** 入力フォーム */
  private get form(): VForm {
    return (this.$refs.form as unknown) as VForm;
  }

  /** シフトテンプレートを取得する */
  protected fetch(): void {
    this.postJsonCheck(
      window.base_url + "/api/shift/template/get",
      { staff_id: this.staffId, office_id: this.officeId },
      res => {
        if (res.data) {
          this.shift_templates = res.data.shift_templates;
        }
      }
    );
  }

  /** シフトテンプレートを保存する */
  private saveShift() {
    if (!this.form.validate()) {
      return;
    }
    this.shiftTemplate.staff_id = this.staffId;
    this.shiftTemplate.office_id = this.officeId;

    this.postJsonCheck(
      window.base_url + "/api/shift/template/save",
      { shift_template: this.shiftTemplate },
      () => {
        this.shiftTemplate = DefaultShiftTemplate();
        this.form.resetValidation();
        this.fetch();
      }
    );
  }

  /** シフトテンプレートを削除する */
  private async deleteShift(template: ShiftTemplate) {
    if (!(await this.$openConfirm("シフト種類を削除してよろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/shift/template/delete",
      { shift_template: template },
      () => {
        this.shiftTemplate = DefaultShiftTemplate();
        this.form.resetValidation();
        this.fetch();
      }
    );
  }

  /** シフト名の重複をチェックする */
  private duplicateShiftName(): boolean | string {
    if (!this.shift_templates) {
      return true;
    }
    const duplicate = this.shift_templates
      .map(template => template.shift_name)
      .includes(this.shiftTemplate.shift_name);
    return duplicate ? "登録済の勤務時間帯名が指定されています" : true;
  }

  /** 勤務時間をチェックする */
  private validateShiftTime(): boolean | string {
    const padding = "00";
    const orgStart =
      this.padStr(padding, this.shiftTemplate.shift_start_time_hour) +
      this.padStr(padding, this.shiftTemplate.shift_start_time_minute);
    const orgEnd =
      this.padStr(padding, this.shiftTemplate.shift_end_time_hour) +
      this.padStr(padding, this.shiftTemplate.shift_end_time_minute);
    return orgStart < orgEnd || "勤務時間が不正です";
  }
}
