import { StatisticHeader } from "@/components/dashboard/types";
import { headerPatient } from "@/components/dashboard/statisticSearchHeaderUnits/patient";
import { headerMedicalInsurance } from "@/components/dashboard/statisticSearchHeaderUnits/medicalInsurance";
import { headerOldAge } from "@/components/dashboard/statisticSearchHeaderUnits/oldAge";
import { headerCareInsurance } from "@/components/dashboard/statisticSearchHeaderUnits/careInsurance";
import { headerBurdenRatio } from "@/components/dashboard/statisticSearchHeaderUnits/burdenRatio";
import { headerHighExpense } from "@/components/dashboard/statisticSearchHeaderUnits/highExpense";
import { headerPublicMoney } from "@/components/dashboard/statisticSearchHeaderUnits/publicMoney";
import { headerIndicate } from "@/components/dashboard/statisticSearchHeaderUnits/indicate";
import { headerVnRecord } from "@/components/dashboard/statisticSearchHeaderUnits/vnRecord";
import { headerRegularVisit } from "@/components/dashboard/statisticSearchHeaderUnits/regularVisit";
import { headerVisitRecord } from "@/components/dashboard/statisticSearchHeaderUnits/visitRecord";

/** 保険・指示書・その他加算タブ用ヘッダー情報 */
const statisticHeader: StatisticHeader[] = [
  // 利用者基礎情報
  headerPatient,
  // 医療保険情報
  headerMedicalInsurance,
  // 高齢受給者証情報
  headerOldAge,
  // 介護保険情報
  headerCareInsurance,
  // 介護保険負担割合証情報
  headerBurdenRatio,
  // 高額療養費情報
  headerHighExpense,
  // 公費種類情報
  headerPublicMoney,
  // 指示書情報
  headerIndicate,
  // 情報提供療養費情報
  headerVnRecord,
  // 定期巡回情報
  headerRegularVisit
];

/** 看護記録・訪問予定タブ用ヘッダー情報 */
const statisticVisitHeader: StatisticHeader[] = [
  // 利用者基礎情報
  headerPatient,
  // 記録書Ⅱ
  headerVisitRecord,
  // 医療保険情報
  headerMedicalInsurance,
  // 高齢受給者証情報
  headerOldAge,
  // 介護保険情報
  headerCareInsurance,
  // 介護保険負担割合証情報
  headerBurdenRatio,
  // 高額療養費情報
  headerHighExpense,
  // 公費種類情報
  headerPublicMoney,
  // 指示書情報
  headerIndicate,
  // 定期巡回情報
  headerRegularVisit
];

export const GetStatisticHeader = (type: number) => {
  switch (type) {
    case 1:
      return statisticHeader;
    case 2:
      return statisticVisitHeader;
    default:
      return [];
  }
};
