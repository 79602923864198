






















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class InfoTextArea extends Vue {
  @Prop() label!: string;
  @Prop() text!: string;
  @Prop({ default: false }) long!: boolean;
}
