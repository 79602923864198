







































































































































import Component from "vue-class-component";
import { Mixins, Prop, Emit, Ref, Watch } from "vue-property-decorator";
import { uuid } from "vue-uuid";
import AppChatRoomList from "./AppChatRoomList.vue";
import AppChatRoom from "./AppChatRoom.vue";
import AppChatRoomEditor from "./AppChatRoomEditor.vue";
import { Stamp } from "./AppChatStampList.vue";
import firebase from "firebase";
import { db } from "#/mixins/firebase";
import AxiosMixin from "@/mixins/axiosMixin";
import { ChatType, RoomType, FileType } from "./const";

export interface Agreement {
  id: number;
  corp_name: string;
}

interface ChatRoom {
  title: string;
  agreement_id: number;
  author_id: number;
  company_name: string;
  image_path: string | null;
  accounts: Account[];
  account_ids: number[];
  last_send_at: firebase.firestore.Timestamp;
  is_private: boolean;
  room_type: number;
  file_type?: number;
}

interface Account {
  id: number;
  staff_id: number;
  staff_name: string;
  last_read_at: firebase.firestore.Timestamp;
}

interface Chat {
  text: string;
  timestamp: firebase.firestore.Timestamp;
  account_id: number;
  name: string;
  chat_type: number;
  stamp_path: string;
  image_path: string;
  delete_flg: boolean;
}

interface TalkItem {
  roomDocument: ChatRoomDocument;
  chatDocuments: ChatDocument[];
  accountDocuments: AccountDocument[];
}

interface UnsubscribeItem {
  room_id: string;
  unsubscribeChat?: () => void;
  unsubscribeAccount?: () => void;
  unsubscribeDeleteChat?: () => void;
}

export const converter = function<
  T
>(): firebase.firestore.FirestoreDataConverter<T> {
  return {
    toFirestore: (data: T) => {
      return data;
    },
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot<T>,
      options: firebase.firestore.SnapshotOptions
    ) => {
      const data = snapshot.data(options);
      return data;
    }
  };
};

type ChatRoomDocument = firebase.firestore.QueryDocumentSnapshot<ChatRoom>;
type ChatDocument = firebase.firestore.QueryDocumentSnapshot<Chat>;
type AccountDocument = firebase.firestore.QueryDocumentSnapshot<Account>;

@Component({
  components: { AppChatRoomList, AppChatRoom, AppChatRoomEditor }
})
export default class AppChatBase extends Mixins(AxiosMixin) {
  @Prop({
    default: () => {
      return [];
    }
  })
  agreements!: Agreement[];
  @Prop() isShownChatView!: boolean;
  @Prop() selfEwellUserId!: number;
  @Prop() selfNickname!: string;
  @Prop() selfStaffId!: number;
  @Prop() agreementId!: number;

  private ViewStatus = {
    roomList: 0,
    roomCreate: 1,
    chatRoom: 2,
    roomSetting: 3
  } as const;

  private isLoading = false;
  private viewStatus = 0;
  private talkList: TalkItem[] = [];
  private unsubscribeRoom = () => {
    return;
  };
  private unsubscribeItemList: UnsubscribeItem[] = [];
  private selectedTalk: TalkItem = {} as TalkItem;
  private chatFetchingState = "reset";
  private touchStartPoint: { x: number; y: number } = { x: 0, y: 0 };
  private drawer = false;
  private dropChatModeFlg = false;

  private CHAT_DELETE_LIMIT = 24 as const;

  @Ref("app-chat-room") private readonly appChatRoom?: AppChatRoom;

  mounted() {
    // Firebase Subscribe
    this.unsubscribeRoom = db
      .collection("chat-rooms")
      .where("is_active", "==", true)
      .where("account_ids", "array-contains", this.selfEwellUserId)
      // 現在チャットが有効な法人のみ
      .where(
        "agreement_id",
        "in",
        this.agreements.map(agreement => {
          return agreement.id;
        })
      )
      .withConverter(converter<ChatRoom>())
      .onSnapshot(ss => {
        ss.docChanges().forEach(changeRoom => {
          if (changeRoom.type === "removed") {
            const unsubscribeItem = this.unsubscribeItemList.find(item => {
              return item.room_id === changeRoom.doc.id;
            });

            if (unsubscribeItem) {
              if (unsubscribeItem.unsubscribeChat) {
                unsubscribeItem.unsubscribeChat();
              }
              if (unsubscribeItem.unsubscribeAccount) {
                unsubscribeItem.unsubscribeAccount();
              }
            }

            this.removeRoom(changeRoom.doc);
          } else if (changeRoom.type === "modified") {
            this.updateRoom(changeRoom.doc);
          } else if (changeRoom.type === "added") {
            this.talkList.push({
              roomDocument: changeRoom.doc,
              chatDocuments: [],
              accountDocuments: []
            });

            const index = this.unsubscribeItemList.findIndex(item => {
              return item.room_id === changeRoom.doc.id;
            });

            if (index < 0) {
              this.unsubscribeItemList.push({
                room_id: changeRoom.doc.id
              });
            }

            this.subscribeAccount(changeRoom.doc);
          }
        });
      });
  }

  beforeDestroy() {
    // Firestore Unsubscribe
    this.unsubscribeRoom();
    this.unsubscribeItemList.forEach(item => {
      if (item.unsubscribeChat) {
        item.unsubscribeChat();
      }
      if (item.unsubscribeAccount) {
        item.unsubscribeAccount();
      }
    });
  }

  @Watch("isShownChatView")
  private isShownChatViewDidChange() {
    // チャットのモーダルシートを開いた時に、チャット画面を開いている場合は最終閲読日時を更新する
    if (
      Object.keys(this.selectedTalk).length > 0 &&
      this.isShownChatView &&
      this.viewStatus === this.ViewStatus.chatRoom
    ) {
      this.updateLastReadAt();
    }
  }

  /** 基本カラー */
  private get MainColor() {
    return this.$vuetify.breakpoint.lgAndUp ? "white" : "primary";
  }

  /** 基本反転カラー */
  private get SubColor() {
    return this.$vuetify.breakpoint.lgAndUp ? "primary" : "white";
  }

  private get IsShownRoomCreatorIcon() {
    // チャットルームの作成ボタンは、ルーム一覧表示時かつ
    // ログインしている事業所の所属法人がチャット機能有効の場合のみ表示する
    const isOpenedRoomList = this.viewStatus === this.ViewStatus.roomList;
    const isChatEnabledAgreement = this.agreements.some(agreement => {
      return agreement.id === this.agreementId;
    });

    return isOpenedRoomList && isChatEnabledAgreement;
  }

  private get IsShownRoomEditorIcon() {
    // チャットルームの編集ボタンは、チャットルーム表示時かつ
    // 表示しているチャットルームがログインしている事業所の所属法人と同じだった場合のみ表示する

    const isOepnedChatRoom = this.viewStatus === this.ViewStatus.chatRoom;
    const isSameLoginedAgreement =
      Object.keys(this.selectedTalk).length > 0 &&
      this.selectedTalk.roomDocument.data().agreement_id === this.agreementId;

    return isOepnedChatRoom && isSameLoginedAgreement;
  }

  private get IsShownRoomDrawerIcon() {
    /** チャットルームのハンバーガーメニューは、チャットルーム表示時に表示する */
    return this.viewStatus === this.ViewStatus.chatRoom;
  }

  private get ChatRooms() {
    return this.talkList
      .map(talk => {
        const room = talk.roomDocument.data();
        const unreadCount = this.getUnreadCount(talk);

        // 最新のメッセージをプレビューとして表示する
        let latestMessage = "メッセージはありません";
        const latestChatDoc = Array.from(talk.chatDocuments)
          .sort((first, second) => {
            return first.data().timestamp.toDate() <
              second.data().timestamp.toDate()
              ? 1
              : -1;
          })
          .find(doc => {
            return doc !== undefined;
          });

        if (latestChatDoc) {
          if (latestChatDoc.data().delete_flg) {
            latestMessage = "取り消されたメッセージです";
          } else {
            // チャットのタイプに応じて、最新メッセージの表示を変える
            switch (latestChatDoc.data().chat_type) {
              case ChatType.text:
              case ChatType.systemText:
                latestMessage = latestChatDoc.data().text;
                break;
              case ChatType.stamp:
                if (latestChatDoc.data().account_id === this.selfEwellUserId) {
                  latestMessage = "スタンプを送信しました";
                } else {
                  latestMessage = `${
                    latestChatDoc.data().name
                  }がスタンプを送信しました`;
                }
                break;
              case ChatType.image:
                if (latestChatDoc.data().account_id === this.selfEwellUserId) {
                  latestMessage = "画像を送信しました";
                } else {
                  latestMessage = `${
                    latestChatDoc.data().name
                  }が画像を送信しました`;
                }
                break;
            }
          }
        }

        return {
          id: talk.roomDocument.id,
          title: room.title,
          imagePath: room.image_path,
          latestMessage,
          unreadCount,
          lastSendAt: room.last_send_at,
          isSystemRoom: room.room_type === RoomType.system,
          fileType: room.file_type ?? 0
        };
      })
      .sort((a, b) => {
        // 更新日時降順にソート
        return a.lastSendAt > b.lastSendAt ? -1 : 1;
      });
  }

  private get TotalUnreadCount() {
    const count = this.ChatRooms.reduce((sum, room) => {
      return sum + room.unreadCount;
    }, 0);

    this.unreadCountChanged(count);

    return count;
  }

  private get HeaderTitle() {
    switch (this.viewStatus) {
      case this.ViewStatus.roomList:
        return "トーク";
      case this.ViewStatus.roomCreate:
        return "トーク作成";
      case this.ViewStatus.chatRoom:
        return this.selectedTalk.roomDocument.data().title;
      case this.ViewStatus.roomSetting:
        return "トーク設定";
    }

    return "";
  }

  private get Chats() {
    if (Object.keys(this.selectedTalk).length > 0) {
      return Array.from(this.selectedTalk.chatDocuments).map(chatDocument => {
        const data = chatDocument.data();
        return {
          id: chatDocument.id,
          text: data.text,
          name: data.name,
          isOwn: data.account_id === this.selfEwellUserId,
          chatType: data.chat_type,
          date: data.timestamp.toDate(),
          accountId: data.account_id,
          stampPath: data.stamp_path,
          imagePath: data.image_path,
          fileType: this.selectedTalk.roomDocument.data().file_type ?? 0,
          deleteFlg: data.delete_flg ?? false
        };
      });
    } else {
      return [];
    }
  }

  private get Accounts() {
    if (Object.keys(this.selectedTalk).length > 0) {
      return Array.from(this.selectedTalk.accountDocuments).map(
        accountDocument => {
          const data = accountDocument.data();
          return {
            id: data.id,
            lastReadAt: data.last_read_at.toDate()
          };
        }
      );
    } else {
      return [];
    }
  }

  private get AgreementChoices() {
    return this.agreements.map(agreement => {
      return {
        text: agreement.corp_name,
        value: agreement.id
      };
    });
  }

  private get SelectedRoomTitle() {
    if (Object.keys(this.selectedTalk).length === 0) {
      return "";
    }

    return this.selectedTalk.roomDocument.data().title;
  }

  private get SelectedRoomType() {
    if (Object.keys(this.selectedTalk).length === 0) {
      return RoomType.normal;
    }

    return this.selectedTalk.roomDocument.data().room_type;
  }

  private get SelectedRoomFileType() {
    if (Object.keys(this.selectedTalk).length === 0) {
      return undefined;
    }

    return this.selectedTalk.roomDocument.data().file_type;
  }

  private get SelectedRoomAgreementId() {
    if (Object.keys(this.selectedTalk).length === 0) {
      // ログイン中の事業所が所属する法人のみ選択可とする
      return this.agreementId;
    }

    return this.selectedTalk.roomDocument.data().agreement_id;
  }

  private get SelectedRoomStaffIds() {
    if (Object.keys(this.selectedTalk).length === 0) {
      return [];
    }

    return this.selectedTalk.accountDocuments.map(doc => {
      return doc.data().staff_id;
    });
  }

  private get SelectedRoomImagePath() {
    if (Object.keys(this.selectedTalk).length === 0) {
      return undefined;
    }

    return this.selectedTalk.roomDocument.data().image_path;
  }

  private get RoomEditorType() {
    return Object.keys(this.selectedTalk).length === 0 ? 0 : 1;
  }

  private getUnreadCount(talk: TalkItem) {
    let unreadCount = 0;
    const account = talk.accountDocuments.find(accountDoc => {
      return accountDoc.data().id === this.selfEwellUserId;
    });

    if (account) {
      unreadCount = talk.chatDocuments.filter(chatDocument => {
        // 自分のチャットは未読カウントから除外
        return (
          chatDocument.data().timestamp > account.data().last_read_at &&
          chatDocument.data().account_id !== this.selfEwellUserId
        );
      }).length;
    }

    return unreadCount;
  }

  private chatBaseTouchedStart(e: TouchEvent) {
    if (e.touches.length === 0) {
      return;
    }

    this.touchStartPoint.x = e.touches[0].pageX;
    this.touchStartPoint.y = e.touches[0].pageY;
  }

  private chatBaseTouchedEnd(e: TouchEvent) {
    if (e.changedTouches.length === 0) {
      return;
    }

    // 縦方向への移動が大きい場合は何もしない
    const diffY = Math.abs(e.changedTouches[0].pageY - this.touchStartPoint.y);
    if (diffY > 100) {
      return;
    }

    // 右方向へ規定量以上動いた場合に、クローズのためにスワイプしたと判定する
    const diffX = e.changedTouches[0].pageX - this.touchStartPoint.x;
    if (diffX >= 100) {
      this.closeChatView();
    }
  }

  private backView() {
    switch (this.viewStatus) {
      case this.ViewStatus.roomCreate:
        this.viewStatus = this.ViewStatus.roomList;
        break;
      case this.ViewStatus.chatRoom:
        this.selectedTalk.chatDocuments = this.selectedTalk.chatDocuments.slice(
          -20
        );
        this.viewStatus = this.ViewStatus.roomList;
        this.selectedTalk = {} as TalkItem;
        this.chatFetchingState = "reset";
        break;
      case this.ViewStatus.roomSetting:
        this.viewStatus = this.ViewStatus.chatRoom;
        break;
      default:
        // do nothing
        break;
    }
  }
  private openRoomCreator() {
    this.viewStatus = this.ViewStatus.roomCreate;
  }

  private openRoomEditor() {
    this.viewStatus = this.ViewStatus.roomSetting;
    this.drawer = false;
  }

  private dropChatMode() {
    this.dropChatModeFlg = true;
    this.drawer = false;
  }

  private subscribeAccount(roomDoc: ChatRoomDocument) {
    const unsubscribeAccount = roomDoc.ref
      .collection("accounts")
      .withConverter(converter<Account>())
      .onSnapshot(ss => {
        ss.docChanges().forEach(accountChange => {
          if (accountChange.type === "added") {
            this.addAccount(roomDoc, accountChange.doc);
            if (accountChange.doc.data().id === this.selfEwellUserId) {
              this.subscribeChat(
                roomDoc,
                accountChange.doc.data().last_read_at
              );
            }
          } else if (accountChange.type === "modified") {
            this.updateAccount(roomDoc, accountChange.doc);
          } else if (accountChange.type === "removed") {
            this.removeAccount(roomDoc, accountChange.doc);
          }
        });
      });

    const index = this.unsubscribeItemList.findIndex(item => {
      return item.room_id === roomDoc.id;
    });

    if (index) {
      this.unsubscribeItemList[index].unsubscribeAccount = unsubscribeAccount;
    }
  }

  private subscribeChat(
    roomDoc: ChatRoomDocument,
    lastReadAt: firebase.firestore.Timestamp
  ) {
    const unsubscribeChat = roomDoc.ref
      .collection("chats")
      .orderBy("timestamp", "desc")
      // 未読チャット（上限100）を初期値として取得
      .where("timestamp", ">", lastReadAt)
      .limit(100)
      .withConverter(converter<Chat>())
      .onSnapshot(ss => {
        // SnapShot の変更が0件 → 初回の取得で、取得したチャットがないときのみ
        // ルーム一覧に表示する、最新のチャットプレビューのため1件だけ取得する
        if (ss.docChanges().length === 0) {
          const talk = this.talkList.find(talk => {
            return talk.roomDocument.id === roomDoc.id;
          });

          if (talk && talk.chatDocuments.length > 0) {
            // すでに取得済みのトークルームかつ、チャット取得済み（= 同じiBowアカウントが紐づいている別職員になった）はなにもしない
          } else {
            this.getOneChat(roomDoc);
          }
        }

        ss.docChanges().forEach(chatChange => {
          if (chatChange.type === "added") {
            this.addChat(roomDoc, chatChange.doc);

            // 新着チャット受信時に、対応する部屋を表示しており、チャット画面を開いている場合は最終閲読日時を更新する
            if (
              Object.keys(this.selectedTalk).length > 0 &&
              this.isShownChatView &&
              this.viewStatus === this.ViewStatus.chatRoom &&
              this.selectedTalk.roomDocument.id === roomDoc.id
            ) {
              this.updateLastReadAt();
            }
          } else {
            // do nothing
            // チャット自体の編集機能はなし
          }
        });
      });

    // チャット削除更新
    const today = new Date();
    today.setHours(today.getHours() - this.CHAT_DELETE_LIMIT);
    const timestamp = firebase.firestore.Timestamp.fromDate(today);
    const unsubscribeDeleteChat = roomDoc.ref
      .collection("chats")
      .orderBy("timestamp", "desc")
      .where("timestamp", ">", timestamp)
      .withConverter(converter<Chat>())
      .onSnapshot(ss => {
        ss.docChanges().forEach(chatChange => {
          if (chatChange.type === "modified") {
            this.modifiedChat(roomDoc, chatChange.doc);
          }
        });
      });

    const index = this.unsubscribeItemList.findIndex(item => {
      return item.room_id === roomDoc.id;
    });

    if (index) {
      this.unsubscribeItemList[index].unsubscribeChat = unsubscribeChat;
      this.unsubscribeItemList[
        index
      ].unsubscribeDeleteChat = unsubscribeDeleteChat;
    }
  }

  private submit(room: {
    isCreate: boolean;
    title: string;
    staffList: { id: number; name: string; ewellUserId: number }[];
    agreementId: number;
    image?: File;
    imagePath?: string;
  }) {
    // 部屋の作成・更新 + アカウント情報の作成・更新
    const upsert = (imagePath?: string) => {
      const data = {
        title: room.title,
        agreement_id: room.agreementId,
        is_active: true
      } as { [key: string]: unknown };

      const batch = db.batch();
      data.image_path = imagePath ?? null;

      if (room.isCreate) {
        // 将来、プライベートチャットと、企業内チャットをわけるための情報
        data.is_private = false;
        data.room_type = RoomType.normal;
        data.last_send_at = firebase.firestore.Timestamp.fromDate(new Date());
        data.author_id = this.selfEwellUserId;
        data.company_name = this.agreements.find(agreement => {
          return agreement.id === room.agreementId;
        })?.corp_name;
        // Firestore のデータ検索用
        data.account_ids = room.staffList.map(staff => {
          return staff.ewellUserId;
        });

        const roomDoc = db.collection("chat-rooms").doc();
        batch.set(roomDoc, data);

        room.staffList.forEach(staff => {
          const data = {
            id: staff.ewellUserId,
            staff_id: staff.id,
            staff_name: staff.name,
            last_read_at: firebase.firestore.Timestamp.fromDate(new Date())
          };
          batch.set(roomDoc.collection("accounts").doc(), data);
        });
      } else {
        // 部屋情報の更新では last_send_at の更新は行わない

        data.account_ids = room.staffList.map(staff => {
          return staff.ewellUserId;
        });

        batch.update(this.selectedTalk.roomDocument.ref, data);

        // 参加アカウントの更新
        this.SelectedRoomStaffIds.forEach(staffId => {
          const isExist = room.staffList.some(staff => {
            return staff.id === staffId;
          });

          if (isExist) {
            // do nothing
            // 変更のないスタッフの更新は必要ない
          } else {
            // 元々のトークメンバーだったが、今回のトークメンバーには存在しない場合、削除する
            const doc = this.selectedTalk.accountDocuments.find(doc => {
              return doc.data().staff_id === staffId;
            });

            if (doc) batch.delete(doc.ref);
          }
        });

        room.staffList.forEach(staff => {
          // 元々のトークメンバーに存在せず、今回のトークメンバーに存在する場合、追加する
          const isExist = this.SelectedRoomStaffIds.some(staffId => {
            return staffId === staff.id;
          });

          // some は対象が空配列の場合常に false を返す
          if (!isExist || this.SelectedRoomStaffIds.length === 0) {
            const account = {
              id: staff.ewellUserId,
              staff_id: staff.id,
              staff_name: staff.name,
              last_read_at: firebase.firestore.Timestamp.fromDate(new Date())
            };

            batch.set(
              this.selectedTalk.roomDocument.ref.collection("accounts").doc(),
              account
            );
          }
        });
      }

      batch
        .commit()
        .then(() => {
          if (room.isCreate) {
            this.viewStatus = this.ViewStatus.roomList;
          } else {
            this.viewStatus = this.ViewStatus.chatRoom;
          }
        })
        .catch(() => {
          this.$openAlert("通信に失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
        });
    };

    this.isLoading = true;

    if (room.image) {
      const form = new FormData();
      form.append("upload", room.image);
      form.append("path", `chat/room-thumbnail/${uuid.v4()}`);

      this.postJsonCheck(
        window.base_url + "/api/upload",
        form,
        res => {
          upsert(res.data.path);
        },
        () => {
          this.isLoading = false;
        }
      );
    } else {
      upsert(room.imagePath);
    }
  }

  private exit() {
    // システム側で作成したトークルームは最低1人は残す
    if (
      this.selectedTalk.roomDocument.data().room_type === RoomType.system &&
      this.SelectedRoomStaffIds.length === 1
    ) {
      this.$openAlert(
        "このトークは1人以上の職員の参加が必須なため、退出できません"
      );
      return;
    }

    // 退出
    const doc = this.selectedTalk.accountDocuments.find(doc => {
      return doc.data().id === this.selfEwellUserId;
    });

    if (doc) {
      this.isLoading = true;
      const data = {
        account_ids: this.selectedTalk.roomDocument
          .data()
          .account_ids.filter(id => {
            return id !== this.selfEwellUserId;
          })
      };
      const batch = db.batch();
      batch.delete(doc.ref);
      batch.update(this.selectedTalk.roomDocument.ref, data);

      batch
        .commit()
        .then(() => {
          this.viewStatus = this.ViewStatus.roomList;
          this.selectedTalk = {} as TalkItem;
        })
        .catch(() => {
          this.$openAlert("通信に失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  private selectRoom(roomId: string) {
    const talk = this.talkList.find(talk => {
      return talk.roomDocument.id === roomId;
    });

    if (talk) {
      this.selectedTalk = talk;
      this.viewStatus = this.ViewStatus.chatRoom;
      this.updateLastReadAt();
    }
  }

  private reachedTop(chatId?: string) {
    // 画面最上部へのスクロールで、過去のチャットを追加取得する
    let query = this.selectedTalk.roomDocument.ref
      .collection("chats")
      .orderBy("timestamp", "desc")
      .limit(20)
      .withConverter(converter<Chat>());

    // 取得済みチャットがある場合はそれを基準として、それ以前のチャットを取得しにいく
    if (chatId) {
      const oldest = this.selectedTalk.chatDocuments.find(chat => {
        return chat.id === chatId;
      });

      if (!oldest) {
        this.chatFetchingState = "complete";
        return;
      }
      query = query.startAfter(oldest);
    }

    this.chatFetchingState = "loading";
    query.get().then(ssChat => {
      if (!ssChat.docs.length) {
        this.chatFetchingState = "complete";
        return;
      }

      ssChat.docs.forEach(chat => {
        const index = this.talkList.findIndex(talk => {
          return talk.roomDocument.id === this.selectedTalk.roomDocument.id;
        });

        if (index > -1) {
          this.talkList[index].chatDocuments.push(chat);
          this.talkList[index].chatDocuments = this.talkList[
            index
          ].chatDocuments.sort((firstDoc, secondDoc) => {
            return firstDoc.data().timestamp < secondDoc.data().timestamp
              ? -1
              : 1;
          });
        }
      });

      this.chatFetchingState = "loaded";
    });
  }

  private updateLastReadAt() {
    const accountDoc = this.selectedTalk.accountDocuments.find(doc => {
      return doc.data().id === this.selfEwellUserId;
    });

    if (accountDoc) {
      accountDoc.ref.update({
        last_read_at: firebase.firestore.Timestamp.fromDate(new Date())
      });
    }
  }

  private sendMessage(message: string) {
    if (Object.keys(this.selectedTalk).length === 0) {
      return;
    }

    const chat = this.createNewChat(ChatType.text, message, "");
    this.send(chat);
  }

  private sendStamp(stamp: Stamp) {
    if (Object.keys(this.selectedTalk).length === 0) {
      return;
    }

    const chat = this.createNewChat(ChatType.stamp, "", stamp.path);
    this.send(chat);
  }

  private sendImage(file: File) {
    const form = new FormData();
    form.append("upload", file);
    form.append("path", `chat/image/${uuid.v4()}`);

    this.postJsonCheck(window.base_url + "/api/upload", form, res => {
      const chat = this.createNewChat(ChatType.image, "", res.data.path);
      this.send(chat);
    });
  }

  private send(chat: Chat) {
    this.selectedTalk.roomDocument.ref
      .update({
        last_send_at: firebase.firestore.Timestamp.fromDate(new Date())
      })
      .then(() => {
        return this.selectedTalk.roomDocument.ref.collection("chats").add(chat);
      })
      .then(() => {
        // 自信がチャットを送信したら、一番下までスクロールする
        this.appChatRoom?.scrollToBottom();
      })
      .catch(() => {
        this.$openAlert("送信に失敗しました");
      });
  }

  private async dropChat(id: string) {
    if (
      await this.$openConfirm(
        "送信後一定時間経過している場合や、受信者の利用状況によっては、受信者側にしばらくメッセージが残る場合があります。また、メッセージの復元はできません。" +
          "\n" +
          "送信を取り消しますか？",
        "送信取り消し"
      )
    ) {
      await this.selectedTalk.roomDocument.ref
        .collection("chats")
        .doc(id)
        .update({
          delete_flg: true
        })
        .catch(() => {
          this.$openAlert("送信に失敗しました");
          return;
        });

      // 24時間以上経過しているチャットも自身の画面から削除されるように対応
      const index = this.talkList.findIndex(talk => {
        return talk.roomDocument.id === this.selectedTalk.roomDocument.id;
      });

      if (index > -1) {
        const chatIndex = this.talkList[index].chatDocuments.findIndex(doc => {
          return doc.id === id;
        });

        if (chatIndex > -1) {
          this.selectedTalk.roomDocument.ref
            .collection("chats")
            .doc(id)
            .withConverter(converter<Chat>())
            .get()
            .then(data => {
              this.talkList[index].chatDocuments.splice(
                chatIndex,
                1,
                data as firebase.firestore.QueryDocumentSnapshot<Chat>
              );
            })
            .catch(() => {
              this.$openAlert("取得に失敗しました");
              return;
            });
        }
      }

      // 削除するとモード終了
      this.dropChatModeFlg = false;
    }
  }

  private createNewChat(type: number, text: string, path: string): Chat {
    return {
      text: text,
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      account_id: this.selfEwellUserId,
      name: this.selfNickname,
      chat_type: type,
      stamp_path: type === ChatType.stamp ? path : "",
      image_path: type === ChatType.image ? path : "",
      delete_flg: false
    };
  }

  private updateRoom(roomDoc: ChatRoomDocument) {
    const index = this.talkList.findIndex(talk => {
      return talk.roomDocument.id === roomDoc.id;
    });

    if (index > -1) {
      this.talkList[index].roomDocument = roomDoc;
    }
  }

  private removeRoom(roomDoc: ChatRoomDocument) {
    // 参照中のトークルームの閲覧権限がなくなった場合、ルーム一覧画面に遷移させる
    if (
      this.viewStatus === this.ViewStatus.chatRoom &&
      this.selectedTalk.roomDocument.id === roomDoc.id
    ) {
      this.selectedTalk = {} as TalkItem;
      this.viewStatus = this.ViewStatus.roomList;
    }

    const index = this.talkList.findIndex(talk => {
      return talk.roomDocument.id === roomDoc.id;
    });

    if (index > -1) {
      this.talkList.splice(index, 1);
    }
  }

  private addAccount(roomDoc: ChatRoomDocument, accountDoc: AccountDocument) {
    const index = this.talkList.findIndex(talk => {
      return talk.roomDocument.id === roomDoc.id;
    });

    if (index > -1) {
      this.talkList[index].accountDocuments.push(accountDoc);
    }
  }

  private updateAccount(
    roomDoc: ChatRoomDocument,
    accountDoc: AccountDocument
  ) {
    const index = this.talkList.findIndex(talk => {
      return talk.roomDocument.id === roomDoc.id;
    });

    if (index > -1) {
      const accountIndex = this.talkList[index].accountDocuments.findIndex(
        doc => {
          return doc.id === accountDoc.id;
        }
      );

      if (accountIndex > -1) {
        this.talkList[index].accountDocuments.splice(
          accountIndex,
          1,
          accountDoc
        );
      }
    }
  }

  private removeAccount(
    roomDoc: ChatRoomDocument,
    accountDoc: AccountDocument
  ) {
    const index = this.talkList.findIndex(talk => {
      return talk.roomDocument.id === roomDoc.id;
    });

    if (index > -1) {
      const accountIndex = this.talkList[index].accountDocuments.findIndex(
        doc => {
          return doc.id === accountDoc.id;
        }
      );

      if (accountIndex > -1) {
        this.talkList[index].accountDocuments.splice(accountIndex, 1);
      }
    }

    // 自身が削除された場合、チャットの購読をやめる
    if (accountDoc.data().id === this.selfEwellUserId) {
      const unsubscribeItem = this.unsubscribeItemList.find(item => {
        return item.room_id === roomDoc.id;
      });

      if (unsubscribeItem) {
        if (unsubscribeItem.unsubscribeChat) {
          unsubscribeItem.unsubscribeChat();
        }
      }
    }
  }

  private addChat(roomDoc: ChatRoomDocument, chatDoc: ChatDocument) {
    const index = this.talkList.findIndex(talk => {
      return talk.roomDocument.id === roomDoc.id;
    });

    if (index > -1) {
      this.talkList[index].chatDocuments.push(chatDoc);
      this.talkList[index].chatDocuments = this.talkList[
        index
      ].chatDocuments.sort((firstDoc, secondDoc) => {
        return firstDoc.data().timestamp < secondDoc.data().timestamp ? -1 : 1;
      });

      // 極力描画するDOMを減らすために、超過分のチャットを削除する
      let displayLength = 20;
      if (
        Object.keys(this.selectedTalk).length > 0 &&
        this.viewStatus === this.ViewStatus.chatRoom &&
        this.selectedTalk.roomDocument.id === roomDoc.id
      ) {
        // トークルーム選択状態
        if (!this.appChatRoom) {
          return;
        }

        // 画面最下部にいる場合にいる場合
        if (this.appChatRoom.isReachedBottom()) {
          const unreadCount = this.getUnreadCount(this.selectedTalk);
          if (unreadCount > 20) {
            // 未読数よりは少なくしない
            displayLength = unreadCount;
          }

          if (this.selectedTalk.chatDocuments.length > displayLength) {
            this.selectedTalk.chatDocuments = this.selectedTalk.chatDocuments.slice(
              -displayLength
            );
          }
        }
      } else {
        // トークルーム非選択状態
        const index = this.talkList.findIndex(talk => {
          return talk.roomDocument.id === roomDoc.id;
        });

        const unreadCount = this.getUnreadCount(this.talkList[index]);
        if (unreadCount > 20) {
          displayLength = unreadCount;
        }

        if (this.talkList[index].chatDocuments.length > displayLength) {
          this.talkList[index].chatDocuments = this.talkList[
            index
          ].chatDocuments.slice(-displayLength);
        }
      }
    }
  }

  private modifiedChat(roomDoc: ChatRoomDocument, chatDoc: ChatDocument) {
    const index = this.talkList.findIndex(talk => {
      return talk.roomDocument.id === roomDoc.id;
    });

    if (index > -1) {
      const chatIndex = this.talkList[index].chatDocuments.findIndex(doc => {
        return doc.id === chatDoc.id;
      });

      if (chatIndex > -1) {
        this.talkList[index].chatDocuments.splice(chatIndex, 1, chatDoc);
      }
    }
  }

  private getOneChat(roomDoc: ChatRoomDocument) {
    roomDoc.ref
      .collection("chats")
      .orderBy("timestamp", "desc")
      .limit(1)
      .withConverter(converter<Chat>())
      .get()
      .then(ss => {
        ss.docChanges().forEach(change => {
          this.addChat(roomDoc, change.doc);
        });
      });
  }

  private dropChatModeOff() {
    this.dropChatModeFlg = false;
  }

  @Emit()
  private unreadCountChanged(count: number) {
    return count;
  }

  @Emit()
  private closeChatView() {
    return;
  }
}
