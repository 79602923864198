



































import { Component, Vue, Prop, Emit, Ref } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import {
  ObservationSelectItem,
  DefaultObservationSelectItem
} from "@/views/master/adlcontent/types";
import { VForm } from "@/types";

@Component({
  components: { AppTextField }
})
export default class EditSecondaryContentDialog extends Vue {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {
        id: null,
        primary_content_id: null,
        name: ""
      };
    }
  })
  content!: ObservationSelectItem;

  @Prop({ default: () => [] }) primaryContents!: [];

  private cloneContent: ObservationSelectItem = DefaultObservationSelectItem();

  private get IsDelete() {
    return this.cloneContent.id;
  }

  created() {
    this.cloneContent = { ...this.content };
  }

  private save() {
    if (!this.form.validate()) {
      return;
    }
    this.saveDialog(this.cloneContent);
  }

  private async clickRemove() {
    if (
      !(await this.$openConfirm(
        "既に帳票等で使用している場合、削除を行うと元に戻せなくなります。それでも削除しますか？"
      ))
    ) {
      return;
    }
    this.remove(this.cloneContent);
  }

  private cancel() {
    this.closeDialog();
  }

  @Emit("save")
  private saveDialog(item: unknown) {
    return item;
  }

  @Emit()
  private remove(item: unknown) {
    return item;
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
