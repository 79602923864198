

































import { MaintenanceInfo } from "#/model/admin";
import UtilMixin from "@/mixins/utilMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class Maintenance extends Mixins(UtilMixin) {
  @Prop() maintenance_info!: MaintenanceInfo;
}
