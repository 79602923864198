























































import { Component } from "vue-property-decorator";
import { Location } from "vue-router";
import Base from "@/components/patient/info/Base";

/** リスト表示する利用者履歴情報 */
interface PatientHistoryRecord {
  /** 利用者履歴ID */
  id: number;
  /** 履歴種類 0:初期作成 1:手動作成 2:レセプト確定による自動生成 */
  history_type: number;
  /** 適用開始日 */
  start_date: string;
  /** 適用終了日 */
  end_date: string;
  /** コメント */
  comment: string;
  /** 今現在有効な利用者履歴フラグ */
  is_history_now: boolean;
}

@Component
export default class PatientHistoryList extends Base {
  /** 変数 */

  /** 履歴一覧テーブルヘッダー */
  public headers = [
    {
      text: "",
      value: "history_type",
      align: "start",
      sortable: false
    },
    {
      text: "適用開始日",
      value: "start_date",
      align: "center",
      sortable: true
    },
    {
      text: "適用終了日",
      value: "end_date",
      align: "center",
      sortable: true
    },
    {
      text: "コメント",
      value: "comment",
      align: "start",
      sortable: false
    },

    {
      text: "",
      value: "actions",
      align: "end",
      sortable: false
    }
  ];

  /** 利用者基礎情報履歴 */
  private histories: PatientHistoryRecord[] = [];

  /** 変更予約可能フラグ */
  private canReserve = false;

  /** ページネーション_1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** ページネーション_開始ページ番号 */
  private pageNo = 1;

  /** 関数 */

  /** 初回起動時 */
  public created() {
    this.fetchPatientHistories();
  }

  /** ヘッダーセット */
  mounted() {
    this.setDefaultHeader();
  }

  /** 利用者基礎情報履歴の取得 */
  private fetchPatientHistories() {
    if (this.$route.params.id) {
      this.postJsonCheck(
        window.base_url + "/api/patient/histories/get",
        {
          patient_id: Number(this.$route.params.id)
        },
        res => {
          this.histories = res.data.histories;
          this.canReserve = res.data.can_reserve;
        }
      );
    }
  }

  /** 履歴編集ボタンクリック時 */
  public edit(history: PatientHistoryRecord) {
    const route: Location = {
      name: "PatientHistoryEdit",
      params: {
        id: String(this.$route.params.id),
        historyId: String(history.id)
      }
    };
    this.$router.push(route);
  }

  /** 現在有効な利用者履歴に背景色を設定 */
  private historyItemsRowBackground(item: PatientHistoryRecord) {
    return item.is_history_now ? "green lighten-4" : "";
  }

  /** 変更予約ボタンクリック時 */
  private reserveHistory() {
    this.$router.push({
      name: "PatientHistoryEdit",
      params: { id: this.$route.params.id, historyId: "0" }
    });
  }

  /** ページネーション_ページ変更時 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }
}
