













import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class NursingDetailItem extends Vue {
  @Prop({ default: "" }) private prefix_id!: string;

  @Prop() private readonly label!: string;

  @Prop() private readonly inputValue!: boolean;

  @Prop() private readonly isLast!: boolean;

  @Emit() private change(isChecked: boolean) {
    return isChecked;
  }
}
