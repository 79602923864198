













































import { Component, Prop, Ref, Mixins, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import TableMixin from "#/mixins/tableMixin";
import PdfLinkMixin from "@/mixins/pdfLinkMixin";
import { Headers, Item } from "@/components/patient/pdf/list";
import SelectFilter from "#/components/selectFilter/SelectFilter.vue";
import { IsFilterMatch } from "#/components/selectFilter/filter";

@Component({
  components: {
    SelectFilter
  }
})
export default class PatientPdfSelect extends Mixins(
  UtilMixin,
  TableMixin,
  PdfLinkMixin
) {
  /** フィルター */
  @Ref("selectFilter") private readonly selectFilter!: SelectFilter;

  /** 提出済みのPDF */
  @Prop({ default: () => [] }) private items!: Item[];
  /** 選択したPDF */
  @Prop({ default: () => [] }) private value!: Item[];
  /** 絞り込み検索しているか */
  @Prop({ default: false }) isFiltered!: boolean;

  @Emit()
  input(newSelected: Item[]) {
    return newSelected;
  }

  /** PDFを開く */
  @Emit()
  openPdfs(selects: Item[]) {
    return selects;
  }

  @Emit()
  openSearchPanel() {
    return;
  }

  /** 表ヘッダー */
  private headers = Headers;
  /** 表の開いているページ */
  private pageNo = 1;
  /** 表の1ページあたり表示件数 */
  private itemPerPage = 25;
  /** 表内のデータ絞り込みキーワード */
  private keyword = "";

  /** SelectFilterの選択肢 */
  private get columnKeys() {
    return this.choiceColumnKey(this.Headers, this.items);
  }

  /** キーワードでクイックフィルタリンクする */
  private get FilterdPdfs() {
    return this.filterdItems<Item>(this.items, this.keyword);
  }

  /** 絞り込み条件も含めた表ヘッダー */
  private get Headers() {
    return this.headers.map(column => {
      column.filter = val => this.columnFilter(column.value, val);
      return column;
    });
  }

  /** 選択行の一覧を変える */
  private get Value() {
    return this.value;
  }
  private set Value(newSelected: Item[]) {
    this.input(newSelected);
  }

  /** ページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** キーワード変更 */
  private updateKeyword(keyword: string) {
    this.keyword = keyword;
  }

  /** カラムフィルター */
  private columnFilter(key: string, val: string | number | Date) {
    if (!this.selectFilter) {
      return true; // フィルターがない場合は、絞り込みをしない
    }
    return IsFilterMatch(this.selectFilter.filterClauses, key, val);
  }
}
