
























import { Component } from "vue-property-decorator";
import Other from "./Other.vue";

@Component
export default class OtherV3 extends Other {
  //12月超減算
  protected disableCheckPreventGensanDiv(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.preventGensanDivFlg == false ||
      (this.receiptVisitRecord.staff_div != 4 &&
        this.receiptVisitRecord.staff_div != 5 &&
        this.receiptVisitRecord.staff_div != 6)
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //加算のみ
    if (this.disableCheckAddOnlyFlg()) {
      this.receiptVisitRecord.add_only_flg = 0;
    }
    //12月超減算
    if (this.disableCheckPreventGensanDiv()) {
      this.receiptVisitRecord.prevent_gensan_div = 0;
    }
  }
}
