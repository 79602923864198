















import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Choice } from "@/types";
import { Attend } from "@/components/home/types";

@Component
export default class StampDialog extends Mixins(UtilMixin, AxiosMixin) {
  @Prop({ default: false }) dialog!: boolean; // ダイアログ
  @Prop({ default: { value: 0, text: "" } }) office_list!: Choice[]; // 事業所リスト
  @Prop() attend!: Attend; // 勤務状況

  @Emit()
  private close() {
    return;
  }

  private office: Choice = { value: 0, text: "" };

  @Watch("dialog")
  private changeDialog() {
    if (this.dialog == false) {
      return;
    }
    this.office = this.office_list[0];
  }

  private toKintai() {
    if (this.office.other_string) {
      this.attend.kst_no = this.office.other_string;
    }
    this.postJsonCheck(
      window.base_url + "/api/home/get-kintai-info",
      {
        attend: this.attend,
        office_id: this.office.value
      },
      async res => {
        if (res.data.attend.status == -2) {
          if (
            await this.$openConfirm(
              "iBowKINTAI職員が見つかりません。\n作成しますか？\n※こちらで作成しても、所属事業所でない場合、iBowKINTAIを利用することはできません。iBowKINTAIで就業状況を「未所属」から変更してください。"
            )
          ) {
            this.postJsonCheck(
              window.base_url + "/api/home/save-staff-for-kintai",
              {},
              () => {
                this.onetime();
              }
            );
          }
        } else {
          this.onetime();
        }
      }
    );
  }

  private onetime() {
    this.postJsonCheck(
      window.base_url + "/api/home/kintai/onetime",
      { office_id: this.office.value },
      res => {
        window.open(
          `${res.data.kintai_front_url}/?onetime=${res.data.one_time_token}`,
          "_brank",
          "noreferrer"
        );
      }
    );
    this.close();
  }
}
