
































































































import { Component, Emit, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import AppHeader from "@/components/main/AppHeader.vue";
import AppMenu from "@/components/main/AppMenu.vue";
import VisitingBar from "@/components/main/VisitingBar.vue";
import BulletinBoardAdminDialog from "@/components/main/BulletinBoardAdminDialog.vue";
import QuestionDialog from "@/components/main/QuestionDialog.vue";
import MaintenanceInfoDialog from "@/components/main/MaintenanceInfoDialog.vue";
import { Mutation, namespace, State, Getter } from "vuex-class";
import { TokenObj, SideMenu } from "@/components/app/types";
import * as master from "@/store/modules/master/types";
import * as root from "@/store/types";
import { BulletinBoardAdmin } from "#/model/bulletinboard";
import {
  MaintenanceInfo,
  DefaultMaintenanceInfo
} from "#/model/admin/maintenanceInfo";
import { Question, DEFAULT_QUESTION } from "#/model/admin/question";
import VisitTmpDataMixin from "@/mixins/firestore/visitTmpDataMixin";
import * as constant from "#/const";
import UtilMixin from "@/mixins/utilMixin";
import { OFFICE_STAFF } from "#/const";
import { Route } from "vue-router";
import FirestoreMixin from "@/mixins/firestoreMixin";
import { COLLECTION_HOME_ITEM_LIST } from "@/const/envFireStore";
import { ButtonItems } from "@/components/home/types";
import { VisitModel } from "@/components/main/types";
import * as lib from "#/store/types";
import { FONT_SIZE } from "@/types";

const Master = namespace(master.name);

@Component({
  components: {
    AppHeader,
    AppMenu,
    VisitingBar,
    BulletinBoardAdminDialog,
    MaintenanceInfoDialog,
    QuestionDialog
  }
})
export default class Main extends Mixins(
  AxiosMixin,
  VisitTmpDataMixin,
  UtilMixin,
  FirestoreMixin
) {
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;

  @Master.Mutation(master.SET_MASTER) setMaster!: Function;

  @Mutation(root.SET_IS_REGISTERD_AUTH_NAVIGATION)
  setIsRegisterdAuthNavigation!: Function;

  @State is_registerd_auth_navigation!: boolean;

  @Mutation(root.SET_IS_VISIT_NAVIGATION) setIsVisitNavigation!: Function;

  @State is_visit_navigation!: boolean;

  @State("chat") chatState!: lib.ChatState;

  @Mutation(lib.SET_AGREEMENTS, { namespace: "chat" })
  setAgreements!: Function;

  @Getter("HasChatEnabledAgreement", { namespace: "chat" })
  hasChatEnabledAgreement!: boolean;

  private isShownChatView = false;
  private unreadChatCount = 0;

  /** マスタデータ読み取り完了フラグ */
  private isLoadedMaster = false;

  /** 管理者掲示板(お知らせ) */
  private bulletin_board_admins: BulletinBoardAdmin[] = [];

  /** 掲示板ダイアログ表示フラグ */
  private isBulletinBoardDialogs: boolean[] = [];

  /** メンテナンス通知（お知らせ） */
  private maintenance_infos: MaintenanceInfo[] = [];

  /** メンテナンス情報 */
  private maintenance_info: MaintenanceInfo = DefaultMaintenanceInfo();

  /** メンテナンス通知ダイアログ表示フラグ */
  private isMaintenanceInfoDialogs: boolean[] = [];

  /** アンケート */
  private questions: Question[] = [];

  /** 表示対象アンケート */
  private showingQuestion: Question = { ...DEFAULT_QUESTION };

  /** サイドメニューの開閉状態 */
  private sideMenu: SideMenu = {
    isOpen: this.$vuetify.breakpoint.lgAndUp,
    isNarrow: false
  };

  /** デフォルトのフォントサイズ 0:デフォルト 1:大きく 2:もっと大きく */
  private selectFontSize = 0;

  /** 訪問データモデル */
  private get VisitModel(): VisitModel {
    return this.visitTmpDataState.data as VisitModel;
  }

  /** 訪問中かどうか */
  private get IsVisit() {
    if (this.VisitModel && this.VisitModel.mode) {
      return true;
    }
    return false;
  }

  private get VisitMode() {
    if (this.IsVisit) {
      return this.VisitModel.mode;
    }
    return 0;
  }

  /** 共通エリアを表示するかどうか */
  private get IsCommonArea() {
    return this.isLoadedMaster && !this.$route.meta?.isNoCommonArea;
  }

  /** アンケートダイアログ */
  @Ref("question_dialog") private readonly questionDialog!: QuestionDialog;

  created() {
    // 所属法人の取得
    this.postJsonBackground(
      window.chat_backend_url + "/api/chat/corps/get",
      {},
      res => {
        this.setAgreements(res.data.corps);
      }
    );
  }

  async mounted() {
    //起動時にログイン情報をチェック && マスタ取得
    this.postJsonCheck(
      window.base_url + "/api/master/get",
      {},
      async res => {
        if (res.data) {
          // マスター
          this.setMaster(res.data);

          // お知らせ
          this.bulletin_board_admins = res.data.bulletin_board_admins;

          // メンテナンス情報のお知らせ
          this.maintenance_infos = res.data.maintenance_infos;

          // アンケート
          this.questions = res.data.questions ? res.data.questions : [];

          if (this.loginUser.id) {
            this.collection = COLLECTION_HOME_ITEM_LIST;
            this.documentId = String(this.loginUser.id);
            const documentButtonItem = (await this.documentGet()) as ButtonItems;
            if (documentButtonItem) {
              this.selectFontSize = documentButtonItem.selectFontSize;
            } else if (documentButtonItem === false) {
              // firestore取得失敗
              this.$openAlert(
                "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
              );
              return;
            }
            // 文字サイズ変更
            this.setFontSize();
          }
          window.font_size = this.selectFontSize;

          // ホーム画面の時だけダイアログを表示
          if (location.pathname == "/") {
            for (let i = 0; i < this.bulletin_board_admins.length; i++) {
              this.isBulletinBoardDialogs.push(true);
            }

            for (let i = 0; i < this.maintenance_infos.length; i++) {
              this.isMaintenanceInfoDialogs.push(true);
            }

            this.reopenQuestionDialog();
          }

          // メンテナンス中
          this.maintenance_info = res.data.maintenance_info;
          if (this.maintenance_info && this.maintenance_info.id != 0) {
            this.toMainte();
          }

          // 訪問状態制御
          if (res.data.login_user) {
            //一時訪問データの取得
            await this.getVisitTmpData(String(res.data.login_user.id));
            this.isLoadedMaster = true;

            // 訪問を強制終了する隠し機能
            if (this.$route.query?.visiting === "forceend") {
              setTimeout(async () => {
                await this.deleteVisitTmpData();
                location.href = "/";
              }, 7000);
              return;
            }

            //訪問中の制御（画面起動時）
            if (this.VisitModel && !this.agreeVisitUrl(this.$route)) {
              switch (this.VisitMode) {
                case constant.VISIT_RECORD.MODE_MOVE:
                  location.href = `/visit/${this.VisitModel.patient_id}/move`;
                  break;
                case constant.VISIT_RECORD.MODE_STAY:
                  location.href = `/visit/${this.VisitModel.patient_id}/stay`;
                  break;
                default:
                  //おかしなMODEで訪問中であれば、一時訪問データは消してホーム画面に戻す
                  await this.deleteVisitTmpData();
                  location.href = "/";
                  break;
              }
              this.isLoadedMaster = false;
            }

            //訪問中の制御をルーティングに設定する
            if (!this.is_visit_navigation) {
              this.setIsVisitNavigation(true);
              this.$router.beforeEach(async (to, from, next) => {
                await this.getVisitTmpData(String(res.data.login_user.id));
                //訪問中の制御（画面遷移時）
                if (this.VisitModel) {
                  //訪問中の閲覧が許可されている画面は何もしない（訪問画面側で別途チェックをしている）
                  if (this.agreeVisitUrl(to)) {
                    next();
                    return;
                  }

                  //FireStoreの状態を見て、強制で遷移する
                  switch (this.VisitMode) {
                    case constant.VISIT_RECORD.MODE_MOVE:
                      next({
                        name: "VisitMove",
                        params: { id: String(this.VisitModel.patient_id) }
                      });
                      return;
                    case constant.VISIT_RECORD.MODE_STAY:
                      next({
                        name: "VisitStay",
                        params: { id: String(this.VisitModel.patient_id) }
                      });
                      return;
                    default:
                      //おかしなMODEで訪問中であれば、一時訪問データは消してホーム画面に戻す
                      await this.deleteVisitTmpData();
                      next({
                        name: "Home"
                      });
                      return;
                  }
                }
                next();
              });
            }
          } else {
            this.isLoadedMaster = true;
          }
        }

        //権限チェックの制御
        if (
          this.loginUserAuthId <= this.STAFF_COMMON &&
          this.$route.meta?.isNotNormal == 1
        ) {
          this.$router.replace("/");
        }

        //権限チェックの制御をルーティングに設定する
        if (!this.is_registerd_auth_navigation) {
          this.setIsRegisterdAuthNavigation(true);
          this.$router.beforeEach((to, from, next) => {
            if (this.loginUserAuthId > this.STAFF_COMMON) {
              next();
              return;
            }
            //ページごとの判定
            if (to.meta?.isNotNormal == 1) {
              next(false);
              return;
            }
            next();
          });
        }
      },
      () => {
        window.history.back();
      }
    );
  }

  // 訪問中もアクセス可能なURL判定
  private agreeVisitUrl(route: Route) {
    if (!route.name) return false;
    // 訪問中もアクセスを許可する画面
    const isPathVisiting =
      route.path.indexOf(`/visit/${this.VisitModel.patient_id}`) === 0;
    return isPathVisiting;
  }

  /** サイドメニュー表示非表示の切り替え */
  private toggleSideOpen() {
    this.sideMenu.isOpen = !this.sideMenu.isOpen;
  }

  /** サイドメニューの幅変更 */
  private toggleSideNarrow() {
    this.sideMenu.isNarrow = !this.sideMenu.isNarrow;
  }

  /** アンケートダイアログを表示 */
  private reopenQuestionDialog() {
    // アンケート情報が存在しない場合は何もしない
    if (this.questions.length == 0) {
      return;
    }

    // 全アンケート情報から1つ取り出してダイアログを表示
    this.showingQuestion = this.questions.slice()[0];
    this.questions.splice(0, 1);

    // 時間を置いてから再表示
    setTimeout(() => {
      this.questionDialog.openDialog();
    }, 300);
  }

  /** 訪問状態の強制終了 */
  private async deleteVisitStatus() {
    await this.deleteVisitTmpData();
    this.$router.replace("/");
  }

  /** 文字サイズ変更 */
  private setFontSize() {
    const htmlElem = document.getElementsByTagName("html");
    switch (this.selectFontSize) {
      case FONT_SIZE.DEFAULT:
        htmlElem[0].style.fontSize = "100%";
        break;
      case FONT_SIZE.SIZE_L:
        htmlElem[0].style.fontSize = "115%";
        break;
      case FONT_SIZE.SIZE_LL:
        htmlElem[0].style.fontSize = "130%";
        break;
    }
  }

  private unreadChatCountChanged(count: number) {
    this.unreadChatCount = count;
  }

  @Emit()
  private toMainte() {
    return this.maintenance_info;
  }

  @Emit()
  private changeStaff(selectOffice: TokenObj) {
    return selectOffice;
  }

  @Emit()
  private toLogout() {
    return;
  }
}
