

















































































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  CalendarDispEvent,
  DefaultEvent,
  EditRangeDiv
} from "#/components/calendar/common";
import sanitizeHTML from "sanitize-html";
import { getVisitMemo } from "@/components/schedule/common";
import EditButton from "@/components/common_ibow/calendar/visit_plan/EditButton.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    EditButton
  }
})
export default class VisitPlanShowEventDialog extends Mixins(
  UtilMixin,
  AxiosMixin
) {
  /** 訪問予定の編集が可能であるかどうか */
  @Prop({ default: true }) isVisitPlanEditable!: boolean;

  /** 編集 */
  @Emit() private edit(
    editRange: EditRangeDiv = EditRangeDiv.This,
    isCopy: boolean
  ) {
    this.close();
    if (isCopy) {
      editRange = EditRangeDiv.New;
    }
    return { event: this.event, editRange: editRange, isCopy: isCopy };
  }

  /** 更新 */
  @Emit() private update() {
    return;
  }

  /** ダイアログ開閉状態 */
  private dialog = false;

  /** サニタイズ機能 */
  private sanitize = sanitizeHTML;

  /** 表示中のイベント */
  private event: CalendarDispEvent = DefaultEvent();

  /** 訪問メモURL対応後 */
  private get Memo(): string {
    return getVisitMemo(this.event.visit_plan.memo);
  }

  /** キャンセル済予定の削除ボタンラベル */
  private get DeletePlanText() {
    if (this.event.is_repeat) {
      return "一連の予定を削除";
    } else {
      return "この予定を削除";
    }
  }

  /** ダイアログを閉じる */
  public close() {
    this.dialog = false;
  }

  /** ダイアログを開く */
  public open(event: CalendarDispEvent) {
    this.event = event;
    this.dialog = true;
  }

  /** 利用者カレンダーダイアログを開く */
  private openPatientDialog() {
    const name = "CalendarWebPrint";
    const target = String(this.event.start);
    const WebPrintRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.event.visit_plan.patient_id) },
      query: { target: target }
    });
    window.open(WebPrintRoute.href, "_blank");
  }

  /** キャンセルされた予定を削除する（カレンダー上、非表示になる） */
  private async deletePlan() {
    let message = "この訪問予定をカレンダーから削除してもよろしいですか？";
    if (this.event.type == EventType.IndivisualSchedule) {
      message =
        "この利用者個別の予定をカレンダーから削除してもよろしいですか？";
    }
    if (!(await this.$openConfirm(message))) {
      return;
    }

    if (this.event.is_repeat) {
      this.deleteRule();
    } else {
      this.deleteVisitSchedule();
    }
  }

  /** 通常の予定を削除する（カレンダー上、非表示） */
  private deleteVisitSchedule() {
    this.postJsonCheck(
      window.base_url + "/api/schedule/visitschedule/delete",
      {
        visit_schedule_id: this.event.event_id,
        mode: 1
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  /** 繰り返しの予定を削除する（カレンダー上、非表示） */
  private deleteRule() {
    this.postJsonCheck(
      window.base_url + "/api/schedule/rule/delete",
      {
        visit_schedule_id: this.event.event_id,
        edit_range: 2,
        mode: 1
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  /** 利用者画面へ遷移する */
  private redirectPatientPage() {
    this.$router.push({
      name: "PatientCalendar",
      params: { id: String(this.event.visit_plan.patient_id) }
    });
  }
}
