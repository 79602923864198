












































import { Component, Mixins } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import CsvResult from "@/components/master/csv/CsvResult.vue";
import { isInputSelectable } from "@/components/master/csv/utility";
import { BaseRecordData } from "@/views/master/choju/types";
import { InputRecords, BaseImportRequest } from "@/views/master/csv/types";

@Component({
  components: { OfficeSelect, FileUpload, CsvResult }
})
export default class CsvInput extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  private csvFile = { id: 0, path: "" };

  // ファイルアップロードAPIパス
  private uploadApiUrl = window.base_url + "/api/upload";
  // ファイルパス作成
  private get imagePath(): string {
    return `choju/`;
  }

  private request: BaseImportRequest = {
    kind: 1,
    path: ""
  };

  private inputRecords: InputRecords = {
    header: [],
    records: [],
    patients: [],
    error: ""
  };

  private kinds = [
    {
      text: "利用者情報",
      value: 1
    },
    {
      text: "介護保険",
      value: 2
    },
    {
      text: "公費",
      value: 3
    }
  ];

  // private header: HeaderParam[] = [];
  // private records: PatientRecord[] = [];
  // private patients: Patient[] = [];
  private err = "";

  private get Header() {
    return this.inputRecords.header;
  }
  private get Records() {
    return this.inputRecords.records;
  }

  private selectRecords: BaseRecordData[] = [];

  private showResult = false;
  private isSuccess = false;

  //Csv入力
  private async inputCsv() {
    if (this.csvFile.path == "") {
      await this.$openAlert("CSVファイルを指定してください。");
      return;
    }
    this.request.path = this.csvFile.path;
    this.postJsonCheck(
      window.base_url + "/api/master/dataio/choju/input",
      this.request,
      res => {
        this.inputRecords = res.data.CsvResult;
        this.selectRecords = (this.inputRecords
          .records as BaseRecordData[]).filter(isInputSelectable);
        this.showResult = true;
        this.err = this.inputRecords.error;
      }
    );
  }

  private back() {
    this.csvFile = { id: 0, path: "" };
    this.showResult = false;
    this.isSuccess = false;
  }

  private confirm() {
    let saveApiPath: string;
    // 選択かつ新規or更新行
    let requestData: unknown[] = [];
    const importData = this.inputRecords.patients;
    requestData = importData.filter(patient =>
      this.selectRecords.some(
        record =>
          Number(record.CoopId.text) === patient.coop_id &&
          isInputSelectable(record)
      )
    );

    switch (this.request.kind) {
      case 1:
        saveApiPath = "/api/master/dataio/patients/save";
        break;
      case 2:
        saveApiPath = "/api/master/dataio/careins/save";
        break;
      case 3:
        saveApiPath = "/api/master/dataio/publicmoney/save";
        break;
      default:
        requestData = [];
        saveApiPath = "";
    }
    this.save(saveApiPath, requestData);
  }

  private async save(saveApiPath: string, requestData: unknown[]) {
    if (!(await this.check(requestData))) return;

    this.postJsonCheck(
      window.base_url + saveApiPath,
      {
        patients: requestData
      },
      res => {
        // 選択していた行
        this.inputRecords.records = this.selectRecords
          .filter(isInputSelectable)
          .map(r => {
            r.is_success = {
              value: "is_success",
              text: "",
              label: "",
              state: 0
            };

            if (
              res.data.err_coop_ids.some(
                (coopId: number) => coopId === Number(r.CoopId.text)
              )
            ) {
              r.state = 3;
              r.is_success.text = "失敗";
            } else {
              r.state = 4;
              r.is_success.text = "成功";
            }
            return r;
          });
        // 成否カラム追加
        this.inputRecords.header.push({
          text: "成否",
          value: "is_success",
          align: "center",
          sortable: false,
          width: "128px"
        });
        this.isSuccess = true;
        this.selectRecords = [];
      }
    );
  }

  private async check(datas: unknown[]) {
    //チェックなし確認
    // if (datas.length == 0) {
    //   if (
    //     !await this.$openConfirm(
    //       "チェックがついていません。表示されている全てを対象に処理を行いますか？"
    //     )
    //   ) {
    //     return false;
    //   }
    // }
    //チェックが1つもない場合
    if (datas.length == 0) {
      await this.$openAlert("1件以上にチェックを付けてください。");
      return false;
    }
    //確認
    if (!(await this.$openConfirm("登録してもよろしいですか？"))) {
      return false;
    }
    return true;
  }
}
