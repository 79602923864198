


































import { Component, Vue, Prop, Emit, Ref } from "vue-property-decorator";
import {
  AdlEvalItemPrimaryContent,
  AdlEvalItemSecondaryContent,
  DefaultAdlEvalItemSecondaryContent
} from "@/views/master/adlitem/types";
import { VForm } from "@/types";

interface SelectItems {
  text: string | number | object;
  value: string | number | object;
}

@Component
export default class EditSecondaryContentDialog extends Vue {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {
        id: null,
        adl_evaluation_item_id: null,
        name: ""
      };
    }
  })
  content!: AdlEvalItemSecondaryContent;

  @Prop({ default: () => [] }) primaryContents!: AdlEvalItemPrimaryContent[];

  private cloneContent: AdlEvalItemSecondaryContent = DefaultAdlEvalItemSecondaryContent();

  private get IsDelete() {
    return this.cloneContent.id;
  }

  created() {
    this.cloneContent = { ...this.content };
  }

  private save() {
    if (!this.form.validate()) {
      return;
    }
    this.saveSecondaryContent(this.cloneContent);
  }

  private async remove() {
    if (
      !(await this.$openConfirm(
        "既に帳票等で使用している場合、削除を行うと元に戻せなくなります。それでも削除しますか？"
      ))
    ) {
      return;
    }
    this.removeDialog(this.cloneContent);
  }

  private cancel() {
    this.closeDialog();
  }

  @Emit("saveSecondaryContent")
  private saveSecondaryContent(content: unknown) {
    return content;
  }

  @Emit("remove")
  private removeDialog(content: unknown) {
    return content;
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
