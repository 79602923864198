









































































































































































































































































































































































































































































import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import Draggable, { MoveEvent } from "vuedraggable";
import AppTextField from "#/components/AppTextField.vue";
import AppDiseaseChip from "@/components/disease/AppDiseaseChip.vue";
import {
  DiseaseHistories,
  DiseaseHistory,
  SELECT_DISEASE_TYPE,
  HEADER_TITLE,
} from "@/components/disease/type";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { COLLECTION_DISEASE_HISTORY } from "@/const/envFireStore";
import {
  DiseaseWord,
  DiseaseMeta,
  SearchSet,
  UncodedDisease,
  DefaultSearchSet,
  DefaultDiseaseMeta,
  DefaultDiseaseWord,
} from "#/model/patient";

@Component({
  components: {
    Draggable,
    AppDiseaseChip,
  },
})
export default class AppDiseaseDialog extends Mixins(
  AxiosMixin,
  FireStoreMixin
) {
  @Ref("search-field") private readonly searchField!: AppTextField;

  /** ダイアログ */
  private isOpen = false;

  /** 検索キーワード */
  private searchWord = "";

  /** ID */
  private meta_id: number | string = 0;

  /* 一覧取得リクエストの実行待ちミリ秒 */
  private readonly FETCH_TIMEOUT = 300;

  /** 一覧取得リクエストの実行待ち */
  private timeoutFetch = 400;

  /** 傷病アイテムリスト */
  private diseaseWords: DiseaseWord[] = [];

  /** 検索傷病アイテムリスト */
  private searchSet: SearchSet = DefaultSearchSet();

  /** 検索タイプ（0:n-gram, 1:前方一致, 2:部分一致, 3:後方一致） */
  private matchType = 1;

  /** 提案傷病アイテムリスト */
  private suggestList: DiseaseMeta[] = [];

  /** 傷病検索結果保持（キャッシュ用） */
  private searchMap = new Map<string, SearchSet>();
  /** 提案検索結果保持（キャッシュ用） */
  private suggestMap = new Map<string, DiseaseMeta[]>();
  /** 未コード化ワード */
  private uncodedWord: DiseaseWord = UncodedDisease();

  /** 最大取得件数（定数） */
  private readonly FETCH_LIMIT = 300;

  /** テーブルの高さ半分（ヘッダー部） */
  private readonly HEADER_HALF_HIGHT = 30;

  /** テーブルの高さ（ヘッダー部） */
  private readonly HEADER_HIGHT = this.HEADER_HALF_HIGHT * 2;

  /** テーブルの高さ（スクロール部） */
  private readonly SCROLL_HIGHT = 300;

  /** 項目の高さ（スクロール部） */
  private readonly ITEM_HIGHT = 50;

  /** リクエストした件数 */
  private fetchLimit = this.FETCH_LIMIT;

  private panels = [0];

  private isOpenPanel = true;

  private beforConvDiseaseName = "";

  /** 選択中の傷病名 */
  private selectDisease: DiseaseWord = DefaultDiseaseWord();

  /** 傷病名の位置 */
  private diseaseNameIndex = 0;

  private diseaseHistories: DiseaseHistories = { histories: [] };

  // ダイアログを開いた時の傷病情報
  private beforeDiseaseWords: DiseaseWord[] = [];

  private matchTypeList = [
    { value: 1, text: "前方一致" },
    { value: 2, text: "部分一致" },
    { value: 3, text: "後方一致" },
  ];

  private selectDiseaseType = 0;

  private showUncodeDialog = false;

  /** コードで検索するフラグ */
  private isCodeSearch = 0;

  private selectedSuggest: DiseaseMeta = DefaultDiseaseMeta();

  /** 検索文字入力欄のラベル表示 */
  private get SearchFieldLabel() {
    if (this.isCodeSearch) {
      return "コードで傷病名を検索";
    }
    if (this.isStateUncoded()) {
      return `傷病名を入力`;
    }
    return `傷病名を検索`;
  }

  private get RuleSearch(): string | boolean {
    //コード検索以外では使用しない
    if (!this.isCodeSearch) {
      return true;
    }
    const matchStr = /^(-?[1-9])?[0-9]*$/g;
    if (this.searchWord.match(matchStr)) {
      return true;
    }
    return "傷病名コードを検索する場合は、半角数字で入力してください。";
  }

  /** 検索プレースホルダー */
  private get SearchPlaceholder() {
    if (this.isCodeSearch) {
      return "傷病名コードを入力して[検索する]を押してください";
    }
    return "漢字・かな・全角カナ・半角全角英数字で入力してください";
  }

  /** 接頭語カラムのヘッダ表示 */
  private get PrefixTitle() {
    return `接頭語(${this.searchSet.prefixs.length})`;
  }
  /** 傷病名カラムのヘッダ表示 */
  private get DiseaseTitle() {
    return `傷病名(${this.searchSet.diseases.length})`;
  }
  /** 接尾語カラムのヘッダ表示 */
  private get SuffixTitle() {
    return `接尾語(${this.searchSet.suffixs.length})`;
  }
  /** 傷病名候補カラムのヘッダ表示 */
  private get SuggestTitle() {
    return `${HEADER_TITLE.SUGGEST}(${this.suggestList.length})`;
  }
  /** 傷病名が選択済みかどうかの判定 */
  private get ExistsDisease(): boolean {
    for (let i = 0; i < this.diseaseWords.length; i++) {
      if (this.isDisease(this.diseaseWords[i])) {
        return true;
      }
    }
    return false;
  }

  private get Panels() {
    /** 傷病名候補が横並びになるサイズの場合、パネルを強制的に展開する */
    if (this.$vuetify.breakpoint.width >= 1368) {
      this.panels = [0];
    }

    return this.panels;
  }

  private set Panels(val: number[]) {
    this.panels = val;
    this.isOpenPanel = this.panels.length ? true : false;
  }

  /** 合成傷病名 */
  private concatName(): string {
    if (this.isStateUncoded()) {
      return this.searchWord;
    }
    let name = "";
    if (this.diseaseWords) {
      this.diseaseWords.forEach((v) => {
        name += v.name;
      });
    }
    return name;
  }

  /** 合成傷病名 */
  private concatNameKana(): string {
    if (this.isStateUncoded()) {
      return "";
    }
    let name = "";
    if (this.diseaseWords) {
      this.diseaseWords.forEach((v) => {
        name += v.name_kana;
      });
    }
    return name;
  }

  /** チップ表示名 */
  public getChipName(word: DiseaseWord) {
    if (this.isPrefix(word)) {
      return word.name;
    } else if (this.isSuffix(word)) {
      return word.name;
    }
    return word.name;
  }

  /** チップスタイル用修飾子 */
  public getChipClassModifier(word: DiseaseWord) {
    if (this.isPrefix(word)) {
      return "--prefix";
    } else if (this.isSuffix(word)) {
      return "--suffix";
    }
    return "";
  }

  /** 接頭語ワード判定 */
  private isPrefix(word: DiseaseWord): boolean {
    const code = word.code;
    if (code.length === 4) {
      if ("0000" <= code && code <= "7999") {
        return true;
      }
    }
    return false;
  }

  /** 接尾語ワード判定 */
  private isSuffix(word: DiseaseWord): boolean {
    const code = word.code;
    if (code.length === 4) {
      if ("8000" <= code && code <= "8999") {
        return true;
      }
    }
    return false;
  }

  /** 傷病名ワード判定 */
  private isDisease(word: DiseaseWord): boolean {
    const code = word.code;
    if (code.length === 7) {
      return true;
    }
    return false;
  }

  /** 未コード化ワード判定 */
  private isUncoded(word: DiseaseWord): boolean {
    if (word.code === this.uncodedWord.code) {
      return true;
    }
    return false;
  }

  /** 未コード化傷病名判定 */
  private isStateUncoded(): boolean {
    if (this.diseaseWords.length === 1) {
      const word = this.diseaseWords[0];
      if (this.isUncoded(word)) {
        return true;
      }
    }
    return false;
  }

  /** 未コード化傷病に変更 */
  private async changeStateUncoded() {
    // if (
    //   !(await this.$openConfirm(
    //     `「${this.searchWord}」をコードがない(未コード化)傷病名として入力しますか？`
    //   ))
    // ) {
    //   return;
    // }
    this.diseaseWords = [this.uncodedWord];
    this.save();
  }

  private uncodeDialogCancel() {
    this.showUncodeDialog = false;
  }

  /** 傷病名行選択イベント */
  private async onClickRowWord(word: DiseaseWord) {
    //候補から選択する状態の場合
    if (this.selectDiseaseType === SELECT_DISEASE_TYPE.SUGGEST) {
      if (
        !(await this.$openConfirm(
          `「${HEADER_TITLE.SUGGEST}」をやめて「${HEADER_TITLE.COMBO}」で傷病名を入力しますか？`
        ))
      ) {
        return;
      }

      this.diseaseWords = [];
      this.clearSuggestList();
    }

    //傷病名から組み合わせる状態
    this.selectDiseaseType = SELECT_DISEASE_TYPE.COMBO;

    if (this.overDiseaseWordCount()) {
      this.$openAlert("これ以上選択できません。");
      return;
    }

    if (this.isPrefix(word)) {
      //接頭語（接頭語以外の前に挿入）
      let pos = 0;
      let hit = false;
      for (let i = 0; i < this.diseaseWords.length; i++) {
        if (!this.isPrefix(this.diseaseWords[i])) {
          pos = i;
          hit = true;
          break;
        }
      }
      if (hit) {
        this.diseaseWords.splice(pos, 0, word);
      } else {
        this.diseaseWords.push(word);
      }
    } else if (this.isDisease(word)) {
      // 傷病名をクリア
      const idx = this.diseaseWords.findIndex((dw) => this.isDisease(dw));
      if (idx != -1) {
        this.diseaseWords.splice(idx, 1);
      }

      //傷病名（接尾語の前に挿入）
      let pos = 0;
      let hit = false;
      for (let i = 0; i < this.diseaseWords.length; i++) {
        if (this.isSuffix(this.diseaseWords[i])) {
          pos = i;
          hit = true;
          break;
        }
      }
      if (hit) {
        this.diseaseWords.splice(pos, 0, word);
      } else {
        this.diseaseWords.push(word);
      }
      this.selectDisease = word;
    } else {
      //接尾語
      this.diseaseWords.push(word);
    }

    this.searchWord = "";
    this.focusSearchField();
    this.setSelected(word);
  }

  /** 提案行選択イベント */
  private async onClickRowSuggest(row: DiseaseMeta) {
    this.selectedSuggest = row;

    //傷病名から組み合わせる状態の場合
    if (this.selectDiseaseType === SELECT_DISEASE_TYPE.COMBO) {
      if (
        !(await this.$openConfirm(
          `「${HEADER_TITLE.COMBO}」をやめて「${HEADER_TITLE.SUGGEST}」で傷病名を入力しますか？`
        ))
      ) {
        return;
      }

      //候補の選択状態クリア
      const ps = this.searchSet.prefixs.map((p) => {
        p.selected = false;
        return p;
      });
      this.searchSet.prefixs = [...ps];

      const ss = this.searchSet.suffixs.map((s) => {
        s.selected = false;
        return s;
      });
      this.searchSet.suffixs = [...ss];

      const ds = this.searchSet.diseases.map((d) => {
        d.selected = false;
        return d;
      });
      this.searchSet.diseases = [...ds];
    }

    //候補から選択する状態
    this.selectDiseaseType = SELECT_DISEASE_TYPE.SUGGEST;

    //候補一覧の選択データにフラグセット
    const suggestList = this.suggestList.map((suggest) => {
      suggest.selected = suggest.id === row.id;
      return suggest;
    });

    //一覧を更新
    this.suggestList = [...suggestList];

    this.diseaseWords = row.words.map((word) => {
      if (this.isDisease(word)) {
        this.selectDisease = word;
      }
      return { ...word };
    });
    this.searchWord = "";
    this.focusSearchField();
  }

  /** 一覧選択状態設定 */
  private setSelected(word: DiseaseWord, isDelete = false) {
    //チップ削除時同名複数存在する場合、選択解除処理を行わない
    if (
      isDelete &&
      this.diseaseWords.filter((w) => w.code === word.code).length > 0
    ) {
      return;
    }

    // //一覧の選択データにフラグセット
    if (this.isPrefix(word)) {
      //接頭語
      const ws = this.searchSet.prefixs.map((w) => {
        if (w.code === word.code) {
          w.selected = !isDelete;
        }
        return w;
      });
      this.searchSet.prefixs = [...ws];
    } else if (this.isSuffix(word)) {
      //接尾語
      const ws = this.searchSet.suffixs.map((w) => {
        if (w.code === word.code) {
          w.selected = !isDelete;
        }
        return w;
      });
      this.searchSet.suffixs = [...ws];
    } else if (this.isDisease(word)) {
      //傷病名
      const ws = this.searchSet.diseases.map((w) => {
        w.selected = !isDelete && w.code === word.code;
        return w;
      });
      this.searchSet.diseases = [...ws];
    }
  }

  /** 一覧選択状態設定 */
  private setDiseaseWordsSelected() {
    //一覧の選択データにフラグセット
    //接頭語
    const p = this.searchSet.prefixs.map((w) => {
      const idx = this.diseaseWords.findIndex((dw) => dw.code === w.code);
      w.selected = idx != -1;
      return w;
    });
    this.searchSet.prefixs = [...p];
    //接尾語
    const s = this.searchSet.suffixs.map((w) => {
      const idx = this.diseaseWords.findIndex((dw) => dw.code === w.code);
      w.selected = idx != -1;
      return w;
    });
    this.searchSet.suffixs = [...s];
    //傷病名
    const d = this.searchSet.diseases.map((w) => {
      const idx = this.diseaseWords.findIndex((dw) => dw.code === w.code);
      w.selected = idx != -1;
      return w;
    });
    this.searchSet.diseases = [...d];
  }

  /** 候補の選択状態クリア */
  private clearSuggestList() {
    const m = this.suggestList.map((sl) => {
      sl.selected = false;
      return sl;
    });
    this.selectedSuggest = DefaultDiseaseMeta();
    this.suggestList = [...m];
  }

  /** 傷病名検索欄にフォーカスをセットする */
  private focusSearchField() {
    this.$nextTick(() => {
      const input = this.searchField.$el.querySelector(
        "input:not([type=hidden]),textarea:not([type=hidden])"
      ) as HTMLElement;
      if (input) {
        setTimeout(() => {
          input.focus();
        }, 0);
      }
    });
  }

  /** 履歴一覧選択時イベント */
  private onSelectDiseaseHistoryClick(diseaseHistory: DiseaseHistory) {
    this.diseaseWords = diseaseHistory.words;
    this.selectDisease = {
      code: diseaseHistory.code,
      name: diseaseHistory.name,
      name_kana: diseaseHistory.kana,
    };
    // this.search();
  }

  /** 最大選択数上限超過 */
  private overDiseaseWordCount() {
    return this.diseaseWords.length > 20;
  }

  /** ドラッグ開始 */
  private onDragStart() {
    // 傷病名の位置取得
    this.diseaseNameIndex = this.diseaseWords.findIndex((word) =>
      this.isDisease(word)
    );
  }

  /** ドラッグ移動 */
  private onDragMove(e: MoveEvent<DiseaseWord>) {
    const word = e.draggedContext.element;
    if (this.isDisease(word)) {
      //傷病名は移動不可
      return false;
    } else if (this.isPrefix(word)) {
      //接頭語は傷病名より後に移動不可
      return this.diseaseNameIndex > e.draggedContext.futureIndex;
    } else if (this.isSuffix(word)) {
      //接尾語は傷病名より前に移動不可
      return this.diseaseNameIndex < e.draggedContext.futureIndex;
    }

    return false;
  }

  private async closeDiseaseChip(index: number, word: DiseaseWord) {
    if (this.isDisease(word)) {
      this.selectDisease = {
        code: "",
        name: "",
        name_kana: "",
      };
    }
    //選択状態が候補から選択かつ削除後にチップが存在する場合
    if (this.selectDiseaseType === SELECT_DISEASE_TYPE.SUGGEST) {
      if (this.diseaseWords.length > 1) {
        if (
          !(await this.$openConfirm(
            `「${HEADER_TITLE.SUGGEST}」をやめて「${HEADER_TITLE.COMBO}」で傷病名を入力しますか？`
          ))
        ) {
          return;
        }
        this.diseaseWords.forEach((dw) => {
          this.setSelected(dw);
        });
        this.selectDiseaseType = SELECT_DISEASE_TYPE.COMBO;
      }

      this.clearSuggestList();
    }

    this.diseaseWords.splice(index, 1);

    if (this.diseaseWords.length === 0) {
      //チップがなっくなった場合, 候補or組み合わせの選択状態をクリア
      this.selectDiseaseType = 0;
    }
    this.setSelected(word, true);
  }

  /** 結果セットがLIMIT件数と同数の場合は追加ロード可能 */
  private get canReload() {
    return (
      this.fetchLimit === this.FETCH_LIMIT &&
      (this.searchSet.prefixs.length === this.FETCH_LIMIT ||
        this.searchSet.diseases.length === this.FETCH_LIMIT ||
        this.searchSet.suffixs.length === this.FETCH_LIMIT ||
        this.suggestList.length === this.FETCH_LIMIT)
    );
  }

  /** 検索実行 */
  private async search(limit: number = this.FETCH_LIMIT, codeSearch = false) {
    //clearable時、nullが返却されるので空文字変換
    if (!this.searchWord) this.searchWord = ""
    //コード検索を行う場合、入力時に自動で検索を行わない
    if (this.isCodeSearch && !codeSearch) {
      return;
    }
    if (this.isStateUncoded()) {
      //未コード化状態は検索を実施しない。
      return;
    }
    // 入力後、指定ミリ秒間入力がなければ検索する
    clearTimeout(this.timeoutFetch);
    this.timeoutFetch = setTimeout(() => {
      this.clearList();
      if (!this.searchWord) {
        return; //検索文字が空の場合は抜ける
      }
      this.fetchLimit = limit;
      this.fetchDiseaseWords(this.searchWord, limit, this.matchType);
      this.fetchSuggestWord(this.searchWord, limit, this.matchType);
    }, this.FETCH_TIMEOUT);
  }

  /** 傷病名検索リクエスト */
  private async fetchDiseaseWords(
    searchWord: string,
    limit: number,
    matchType: number
  ) {
    //検索結果が存在する場合は、取得済みの値を使用する
    const keyword = `${matchType}-${searchWord}-${this.isCodeSearch}`;
    if (this.searchMap.has(keyword) && limit != 0) {
      const set = this.searchMap.get(keyword);
      if (set) {
        this.searchSet = set;
        this.setDiseaseWordsSelected();
        return;
      }
    }
    //リクエスト
    this.postJsonCheck(
      window.base_url + "/api/patient/indicate/disease/word",
      {
        search_word: searchWord,
        match_type: matchType,
        is_code_search: this.isCodeSearch,
        // limit: limit
      },
      (res) => {
        const searchSet = {
          prefixs: res.data.prefix_words ? res.data.prefix_words : [],
          diseases: res.data.disease_words ? res.data.disease_words : [],
          suffixs: res.data.suffix_words ? res.data.suffix_words : [],
        };
        //取得結果をMapに保持
        if (limit != 0) {
          const map = this.searchMap;
          if (map.size >= 5) {
            this.searchMap.delete(map.keys().next().value);
          }
          map.set(keyword, searchSet);
        }
        //検索結果と今の検索文字が異なる場合はスルー
        if (this.searchWord != res.data.search_word) {
          return;
        }
        //セット
        this.searchSet = searchSet;
      }
    );
  }

  /** 提案検索リクエスト */
  private async fetchSuggestWord(
    searchWord: string,
    limit: number,
    matchType: number
  ) {
    //検索結果が存在する場合は、取得済みの値を使用する
    const keyword = `${matchType}-${searchWord}-${this.isCodeSearch}`;
    if (this.suggestMap.has(keyword) && limit != 0) {
      const set = this.suggestMap.get(keyword);
      if (set) {
        this.suggestList = set.map((s) => {
          s.selected = this.selectedSuggest.id === s.id;
          return s;
        });
        return;
      }
    }
    //リクエスト
    this.postJsonCheck(
      window.base_url + "/api/patient/indicate/disease/suggest",
      {
        search_word: searchWord,
        match_type: matchType,
        is_code_search: this.isCodeSearch,
        // limit: limit
      },
      (res) => {
        const suggestList = res.data.suggests ? res.data.suggests : [];
        //取得結果をMapに保持
        if (limit != 0) {
          const map = this.suggestMap;
          if (map.size >= 5) {
            this.suggestMap.delete(map.keys().next().value);
          }
          map.set(keyword, suggestList);
        }
        //検索結果と今の検索文字が異なる場合はスルー
        if (this.searchWord != res.data.search_word) {
          return;
        }

        //セット
        this.suggestList = suggestList;
      }
    );
  }

  /** ダイアログ開く */
  public async open(
    historyId: number,
    obj: string | DiseaseMeta = DefaultDiseaseMeta(),
    matchType = 2,
    sword = ""
  ) {
    this.initialize();
    this.isOpen = true;
    this.matchType = matchType;
    if (typeof obj === "string") {
      this.meta_id = obj;
    } else {
      this.meta_id = obj.id;
      this.diseaseWords = obj.words;
      this.beforeDiseaseWords = [...obj.words];

      if (this.isStateUncoded()) {
        this.searchWord = obj.name;
      }
    }

    if (sword) {
      this.searchWord = sword;
      this.search(this.FETCH_LIMIT);
      this.beforConvDiseaseName = sword;
    }

    //検索履歴一覧取得
    // this.collection = COLLECTION_DISEASE_HISTORY;
    // this.documentId = String(historyId);
    // this.diseaseHistories = (await this.documentGet()) as DiseaseHistories;

    if (!this.diseaseHistories) {
      this.diseaseHistories = {
        histories: [],
      };
    }
  }

  /** ダイアログ閉じる */
  private close() {
    this.initialize();
    this.isOpen = false;
  }

  /** 初期化 */
  private initialize() {
    this.selectDiseaseType = 0;
    this.searchWord = "";
    this.beforConvDiseaseName = "";
    this.diseaseWords = [];
    this.searchMap.clear();
    this.suggestMap.clear();
    this.clearList();
  }

  /** リストデータクリア */
  private clearList() {
    //検索傷病アイテムリスト
    this.searchSet = DefaultSearchSet();
    //提案傷病アイテムリスト
    this.suggestList = [];
  }

  /** 登録処理 */
  private async save() {
    //入力値チェック
    const result = this.validateDiseaseWords();
    if (typeof result === "string") {
      await this.$openAlert(result);
      return;
    }

    const meta = DefaultDiseaseMeta();
    meta.id = this.meta_id;
    meta.name = this.concatName();
    meta.name_kana = this.concatNameKana();
    meta.words = this.diseaseWords;

    this.register(meta);

    // // 未コード化傷病名は履歴として登録しない
    // if (this.collection !== "" && !this.isStateUncoded()) {
    //   // 履歴に存在するかチェック
    //   const findDiseaseIndex = this.diseaseHistories.histories.findIndex(
    //     dh => dh.code === this.selectDisease.code
    //   );

    //   // 履歴に存在する場合、削除
    //   if (findDiseaseIndex != -1) {
    //     this.diseaseHistories.histories.splice(findDiseaseIndex, 1);
    //   }

    //   // 履歴の先頭に追加
    //   this.diseaseHistories.histories.unshift({
    //     code: this.selectDisease.code,
    //     name: this.selectDisease.name,
    //     kana: this.selectDisease.name_kana,
    //     words: this.diseaseWords
    //   });
    //   this.documentSave(this.diseaseHistories);
    // }

    this.close();
  }

  private async onCloseClick() {
    if (
      JSON.stringify(this.beforeDiseaseWords) !==
      JSON.stringify(this.diseaseWords)
    ) {
      if (
        !(await this.$openConfirm(
          "変更した内容が破棄されます。よろしいですか？"
        ))
      ) {
        return;
      }
    }
    this.close();
  }

  /** 入力値チェック */
  private validateDiseaseWords(): boolean | string {
    //未コード化チェック
    if (this.isStateUncoded()) {
      if (this.searchWord === "") {
        return "傷病名を入力してください。";
      }
      return true;
    }

    //未コード化以外
    const d_arr = this.diseaseWords.filter((w) => this.isDisease(w));
    if (d_arr.length === 0) {
      return "傷病名を１つ選択する必要があります。";
    }
    if (d_arr.length !== 1) {
      return "傷病名は複数選択できません。";
    }
    if (this.isUncoded(d_arr[0]) && this.diseaseWords.length >= 2) {
      return "未コード化傷病名は、接頭語、接尾語を含めることはできません。";
    }
    // 0:なし, 1:接頭語, 2:傷病名, 3:接頭語
    let step = 0;
    for (const w of this.diseaseWords) {
      if (this.isPrefix(w)) {
        if (step !== 0 && step !== 1) {
          return "接頭語は傷病名の前にある必要があります。";
        }
        step = 1;
      }
      if (this.isDisease(w)) {
        if (step !== 0 && step !== 1) {
          return "傷病名は接頭語と接尾語の間にある必要があります。\r\n（接頭語と接尾語は省略可）";
        }
        step = 2;
      }
      if (this.isSuffix(w)) {
        if (step !== 2 && step !== 3) {
          return "接尾語は傷病名の後ろにある必要があります。";
        }
        step = 3;
      }
    }
    return true;
  }

  /**
   * コードで検索で切り替え時、検索文字をクリアする
   */
  private changeCodeSearch() {
    this.searchWord = ""
  }

  /** 文字数によってフォントサイズ変更 */
  private fontSizeByLength(str: string): string {
    return String(16 - str.length * 0.1);
  }

  private wordsFontSizeLength(words: DiseaseWord[]): string {
    const len = words.reduce((sum, word) => {
      return sum + word.name.length;
    }, 0);

    return String(16 - len * 0.1);
  }

  /** 傷病名のスタイル返却 */
  private getListItemClass(item: DiseaseMeta) {
    return `c-disease__list ${
      item.selected ? "c-disease__list--selected" : ""
    }`;
  }

  /** 指定サイズより画面横サイズ以上場合 */
  private upperWidth(width: number): boolean {
    return this.$vuetify.breakpoint.width >= width;
  }

  private getScrollHeight() {
    return this.isOpenPanel ? this.SCROLL_HIGHT : this.SCROLL_HIGHT + 240;
  }

  /** 登録処理（親コンポーネントに値を渡す） */
  @Emit()
  private register(newValue: DiseaseMeta): DiseaseMeta {
    return newValue;
  }
}
