































































import { Component } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import {
  ChartDataDetail,
  TableDataDetail
} from "@/components/officeAggregate/officeinvoice/types";
import { BreadItem } from "@/store/modules/patient/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component
export default class OfficeInvoiceDetail extends Base {
  private breadItems: BreadItem[] = [
    {
      text: "訪問記録情報",
      disabled: false,
      to: "/office_aggregate/officeinvoice",
      exact: true
    },
    {
      text: "看護記録書Ⅱ提出詳細",
      disabled: true,
      to: ""
    }
  ];

  private officeId = 0; //事業所ID
  private officeName = ""; //事業所名
  private yearmonth = ""; //年月
  private submissions = 0; //看護記録書Ⅱ合計提出枚数
  private chart!: am4charts.XYChart; //アムチャート
  private chartDatas: ChartDataDetail[] = []; //チャートデータ
  private tableDatas: TableDataDetail[] = []; //テーブルデータ

  //データテーブルヘッダー
  private invoiceDetailHeaders: DataTableHeader[] = [
    {
      text: "訪問者名",
      value: "name"
    },
    {
      text: "看護記録書Ⅱ提出枚数",
      value: "submission",
      align: "end"
    },
    {
      text: "",
      value: "actions",
      align: "center",
      width: "75px",
      sortable: false
    }
  ];

  //画面上部情報
  private get invoiceDate() {
    const [year, month] = this.yearmonth.split("-");
    return `年月：${year}年${month}月`;
  }
  private get reportOutputTotal() {
    return this.submissions;
  }

  public async created() {
    this.officeId = Number(this.$route.query.id);
    this.officeName = String(this.$route.query.office);
    this.yearmonth = String(this.$route.query.yearmonth);

    await this.getDetail();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  mounted() {
    setTimeout(() => {
      // ページ末尾にスクロールされてしまうのを抑制
      window.scrollTo({ left: 0, top: 0 });
    }, 50);
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.XYChart) {
    chart.dateFormatter.language = new am4core.Language();
    chart.dateFormatter.language.locale = am4lang_ja_JP;
    chart.language.locale["_date_day"] = "MMMdd日";
    chart.language.locale["_date_year"] = "yyyy年";

    // 拡大スクロール
    const scrollbarY = new am4charts.XYChartScrollbar();
    chart.scrollbarY = scrollbarY;
    // チャートデータセット
    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 5; // x軸の幅
    valueAxis.title.text = "看護記録書Ⅱ枚数"; // x軸のタイトル
    valueAxis.fontSize = 12;
    let max = 0;
    valueAxis.min = 0;
    this.tableDatas.forEach((item: TableDataDetail) => {
      if (item.submission > max) {
        max = item.submission;
      }
    });
    if (max < 10) {
      valueAxis.min = 0;
      valueAxis.max = 10;
    }

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.dataFields.category = "name";

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "name"; // 日付毎
    series.dataFields.valueX = "submission"; // 値
    series.tooltipText = "{valueX.value}" + "枚"; // ツールチップ

    series.columns.template.adapter.add("stroke", function(_, target) {
      if (target.dataItem) {
        return chart.colors.getIndex(target.dataItem.index);
      }
      return chart.colors.next();
    });
    series.columns.template.adapter.add("fill", function(_, target) {
      if (target.dataItem) {
        return chart.colors.getIndex(target.dataItem.index);
      }
      return chart.colors.next();
    });

    chart.paddingRight = 20;

    // グラフカーソル
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomY";
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // Excelダウンロード
  private downloadExcel() {
    this.postJsonBlobResCheck(
      window.base_url +
        "/api/officeAggregate/officeinvoice/downloaddetailexcel",
      {
        office_name: this.officeName,
        yearmonth: this.yearmonth,
        submissions: this.submissions,
        submitters: this.tableDatas
      },
      res => {
        const fileName = "officeInvoiceDetail.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //詳細
  private clickDetail(item: TableDataDetail) {
    this.$router.push({
      name: "OfficeInvoiceStaff",
      query: {
        ...this.$route.query,
        staff_id: String(item.staff_id),
        staff: item.name
      }
    });
  }

  //-----------------------------------------------------
  // 詳細データ取得
  //-----------------------------------------------------
  private async getDetail() {
    //todo エラーの場合、resolveされない。。
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/officeinvoice/getdetail",
        {
          office_id: this.officeId,
          yearmonth: this.yearmonth
        },
        res => {
          this.tableDatas = res.data.table_datas;
          this.tableDatas.reverse();
          this.chartDatas = res.data.chart_datas;
          // 取得リストの提出枚数を足して合計を返す
          this.submissions = this.tableDatas
            .map(submit => submit.submission)
            .reduce((a, b) => a + b, 0);
          resolve(0);
        }
      );
    });
  }
}
