import { Choice } from "@/types";

// 0, 3は移行データ用。選択肢として表示するのは1 2 4の3つ
/** 希望連絡方法\n0:—\n1:どちらでも\n2:電話\n3:メール\n4:iBowシステム */
export const HOPE_CONTACT: Choice[] = [
  { text: "どちらでも", value: 1 },
  { text: "電話", value: 2 },
  { text: "iBowシステム", value: 4 }
];

export const HOPE_CONTACT_IBOW = 3;

export const INQUIRY_STATUS: Choice[] = [
  { text: "未対応", value: 1 },
  { text: "受付中", value: 2 },
  { text: "受付中", value: 3 },
  { text: "完了", value: 9 }
];

/** 登録状況の確認希望 0:未選択 1:希望しない 2:希望する */
export const ACCESS_CONFIRMS: Choice[] = [
  { text: "未選択", value: 0 },
  { text: "希望しない", value: 1 },
  { text: "希望する", value: 2 }
];

export const INQUIRY_STATUS_COMPLETED = 9;

export const INQUIRY_STATUS_COLOR: Choice[] = [
  { text: "orange darken-1", value: 1 },
  { text: "light-blue darken-1", value: 2 },
  { text: "light-blue darken-1", value: 3 },
  { text: "blue-grey lighten-2", value: 9 }
];

export const ANSWER_STATUS: Choice[] = [
  { text: "対応中", value: 1 },
  { text: "連絡待ち（外）", value: 2 },
  { text: "連絡待ち（内）", value: 3 },
  { text: "対応完了", value: 4 },
  { text: "対応完了（内）", value: 5 },
  { text: "対応完了（外）", value: 6 },
  { text: "対応完了（要望）", value: 7 },
  { text: "対応完了（未解決）", value: 8 },
  { text: "アサイン", value: 9 }
];

export const ANSWER_STATUS_COLOR: Choice[] = [
  { text: "orange darken-1", value: 1 },
  { text: "light-blue darken-1", value: 2 },
  { text: "light-blue darken-1", value: 3 },
  { text: "blue-grey lighten-2", value: 4 },
  { text: "blue-grey lighten-2", value: 5 },
  { text: "blue-grey lighten-2", value: 6 },
  { text: "blue-grey lighten-2", value: 7 },
  { text: "blue-grey lighten-2", value: 8 }
];
