





























import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { LastMonthItems } from "@/components/patient/insuranceinfo/type";
import All from "@/components/patient/insuranceinfo/All.vue";

@Component({
  components: {
    All
  }
})
export default class InsuranceDialog extends Mixins(AxiosMixin, UtilMixin) {
  /** ダイアログの開閉状態 */
  private value = false;
  /** 利用者ID */
  private patientId!: number;
  /** 前月時点で有効な保険情報 */
  private lastMonthItems: LastMonthItems = {
    med: [],
    eldCert: [],
    highCost: [],
    limitCert: [],
    care: [],
    careRateCert: [],
    public: []
  };

  open(patientId: number) {
    this.value = true;
    this.patientId = patientId;
    this.fetch();
  }

  close() {
    this.value = false;
  }

  // 保険情報取得
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurances/get",
      {
        patient_id: this.patientId
      },
      res => {
        this.lastMonthItems.med = res.data.last_month_items.med_items;
        this.lastMonthItems.eldCert = res.data.last_month_items.eld_cert_items;
        this.lastMonthItems.highCost =
          res.data.last_month_items.high_cost_items;
        this.lastMonthItems.limitCert =
          res.data.last_month_items.limit_cert_items;
        this.lastMonthItems.care = res.data.last_month_items.care_items;
        this.lastMonthItems.careRateCert =
          res.data.last_month_items.care_rate_cert_items;
        this.lastMonthItems.public = res.data.last_month_items.public_items;
      }
    );
  }
}
