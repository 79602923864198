

























































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { DispTemplate } from "@/views/contact/types";
import RelatedFAQList from "@/components/contact/RelatedFAQList.vue";
import FAQContentDetail from "@/components/contact/FAQContentDetail.vue";

@Component({
  components: {
    RelatedFAQList,
    FAQContentDetail
  }
})
export default class FAQList extends Mixins(UtilMixin, AxiosMixin) {
  /** 絞り込み検索であるか */
  @Prop({ default: "おすすめの記事" }) title!: string;
  /** 検索ワード */
  @Prop({ default: () => [] }) searchedKeywords!: string[];
  /** 項目がない時の代替テキスト */
  @Prop({ default: "関連する記事はありません" }) noDataText!: string;
  /** 表示項目を外から制御 */
  @Prop() customList?: DispTemplate[];

  @Prop({ default: false }) isRelatedFaq!: boolean;

  /** FAQテンプレート情報 */
  private templates: DispTemplate[] = [];

  private get Templates() {
    return this.customList ?? this.templates;
  }

  created() {
    this.fetchFAQList();
  }

  /** 関連するFAQテンプレート一覧 */
  private relatedTemplates: DispTemplate[] = [];

  /** FAQテンプレート一覧を取得 */
  private fetchFAQList() {
    // 項目を外側で制御する場合何もしない
    if (this.customList != null) return;

    this.templates = [];
    // おすすめとして設定している記事のみ取得
    this.postJsonCheck(
      window.base_url + "/api/inquiry/templates/get",
      { only_recommend: 1 },
      res => {
        if (res.data.templates) {
          this.templates = res.data.templates;
        }
      }
    );
  }

  /** カテゴリリンクをクリックした */
  @Emit("click:category")
  clickCategory(categoryId: number) {
    return categoryId;
  }

  /** キーワードリンクをクリックした */
  @Emit("click:keyword")
  clickKeyword(keyword: string) {
    return keyword;
  }
}
