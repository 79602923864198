



























































































































































































































































































import {
  Component,
  Mixins,
  Prop,
  Ref,
  Emit,
  Watch
} from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import DateMixin from "#/mixins/dateMixin";

import * as appDate from "#/utility/appDate";

import { Staff } from "#/model/staff";

import {
  CalendarEvent,
  DefaultCalendarEvent,
  DefaultCalendarParticipant,
  CalendarParticipantStatus,
  DefaultCalendarParticipantStatus
} from "#/model/schedule/calendarEvent";

import {
  RepeatDiv,
  EditRangeDiv,
  RepeatPeriodDiv,
  WeekDays,
  RepeatItems,
  EditRange,
  getEventWeekDays,
  getEventWeeks,
  getEventWeekTimes
} from "#/components/calendar/common";

import SearchStaffDialog from "@/components/calendar/SearchStaffDialog.vue";
import RepeateLimitArea from "#/components/calendar/RepeateLimitArea.vue";
import RepeatEveryWeek from "#/components/calendar/RepeatEveryWeek.vue";
import RepeatMonthlyDateAssign from "#/components/calendar/RepeatMonthlyDateAssign.vue";
import RepeatMonthlyWeekAssign from "#/components/calendar/RepeatMonthlyWeekAssign.vue";
import RepeatMonthlyWeekDayAssign from "#/components/calendar/RepeatMonthlyWeekDayAssign.vue";
import { VForm } from "@/types";

@Component({
  components: {
    SearchStaffDialog,
    RepeateLimitArea,
    RepeatEveryWeek,
    RepeatMonthlyDateAssign,
    RepeatMonthlyWeekAssign,
    RepeatMonthlyWeekDayAssign
  }
})
export default class EventEdit extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin,
  DateMixin
) {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: () => [] }) staffs!: Staff[]; // 職員一覧

  @Prop({ default: 0 }) layerId!: number; // レイヤーID

  /*
   * emit
   */
  @Emit()
  private update(): CalendarEvent {
    return this.event;
  }

  /*
   * ref
   */

  // フォーム
  @Ref("form") private readonly form!: VForm;
  // 職員検索コンポーネント
  @Ref("searchStaff") private readonly searchStaff!: SearchStaffDialog;

  /*
   * watch
   */

  // 繰り返し設定変更
  @Watch("event.repeat_div")
  private changeRepeatDiv() {
    // 繰り返し設定ありで期限開始日未設定の場合、編集日をセット
    if (
      this.event.repeat_div != RepeatDiv.None &&
      this.event.limit_start_date == ""
    ) {
      this.event.limit_start_date = this.editDate;
    }

    // 繰り返し設定ありで各設定値が未設定の場合初期値をセット
    const limitStart = appDate.strToDate(this.event.limit_start_date);
    switch (this.event.repeat_div) {
      case RepeatDiv.EveryDay: // 毎日
        break;
      case RepeatDiv.EveryWeek: // 毎週
        this.event.week_days = getEventWeekDays(
          this.event.week_days,
          limitStart
        );
        break;
      case RepeatDiv.EveryMonthSpecifiedDate: // 毎月（日付指定）
        if (!this.event.month_day) {
          this.event.month_day = limitStart.getDate();
        }
        break;
      case RepeatDiv.EveryMonthSpecifiedWeek: // 毎月（週指定）
        this.event.weeks = getEventWeeks(this.event.weeks, limitStart);
        this.event.week_days = getEventWeekDays(
          this.event.week_days,
          limitStart
        );
        break;
      case RepeatDiv.EveryMonthSpecifiedWeekDay: // 毎月（曜日指定）
        this.event.week_times = getEventWeekTimes(
          this.event.week_times,
          limitStart
        );
        this.event.week_days = getEventWeekDays(
          this.event.week_days,
          limitStart
        );
        break;
    }
  }

  /**
   *  終日変更時
   */
  @Watch("event.all_day")
  private onChangeAllDay() {
    if (this.event.all_day !== 1) {
      return;
    }
    this.event.start_hh =
      String(this.event.start_hh) === "" ? 0 : this.event.start_hh;
    this.event.start_mm =
      String(this.event.start_mm) === "" ? 0 : this.event.start_mm;
    this.event.end_hh =
      String(this.event.end_hh) === "" ? 0 : this.event.end_hh;
    this.event.end_mm =
      String(this.event.end_mm) === "" ? 0 : this.event.end_mm;
  }

  /*
   * data
   */

  // ダイアログ展開状態
  private dialog = false;
  // 編集範囲選択値
  private selectedEditRange = 0;
  // イベント
  private event = DefaultCalendarEvent();
  // 編集日付
  private editDate = "";
  // 繰り返しイベント削除用開始日
  private limitStart = "";
  // 職員追加モード（開催者変更：true OR ゲスト追加：false）
  private selectOrganizer = false;
  // 開催者変更前の職員ID
  private oldOrganizerId = 0;
  // 開催者変更後の職員ID
  private selectOrganizerId = 0;
  // 未参加の職員
  private cancelStaff: number[] = [];
  // 開催者ID
  private ownerStaffId = 0;

  private readonly RepeatDiv = RepeatDiv;
  private readonly EditRangeDiv = EditRangeDiv;
  private readonly RepeatPeriodDiv = RepeatPeriodDiv;
  private readonly RepeatItems = RepeatItems;
  private readonly EditRange = EditRange;

  /** コピーかどうか */
  private isCopy = false;

  /*
   * computed
   */

  // まだ追加していない職員
  private get unAddStaffs(): Staff[] {
    const showStaffIds = this.guests.map(guest => guest.staff_id);
    return this.staffs.filter(staff => {
      return !showStaffIds.includes(staff.id);
    });
  }
  // 開催者の職員以外
  private get unOrganizerStaffs(): Staff[] {
    // 開催者以外
    const organizer = this.event.participants[0];
    return this.staffs.filter(staff => {
      // この予定、単独新規の予定は不参加の人は表示しない
      if (
        this.selectedEditRange == EditRangeDiv.This ||
        this.selectedEditRange == EditRangeDiv.New
      ) {
        return (
          organizer.staff_id !== staff.id &&
          !this.cancelStaff.includes(staff.id)
        );
      } else {
        return organizer.staff_id !== staff.id;
      }
    });
  }

  // 編集モードであるか
  private get isEdit(): boolean {
    return this.event.id !== 0;
  }

  // イベント日時（表示用）
  private get dialogTitle(): string {
    let title = "予定を作成する";
    if (this.isEdit) title = "予定を編集する";

    let d = this.editDate;
    if (this.event.repeat_div === RepeatDiv.None) {
      d = this.event.start_date;
      // 開始日をリセットした時のNaN対策
      if (this.event.start_date === "") return title;
    }
    const date = appDate.strToDate(d);

    title += " - " + this.jpDate(date);
    return title;
  }

  // 繰り返し設定（表示用）
  private get repeatSetting(): string {
    // 繰り返し区分
    let repeatDiv = "";
    switch (this.event.repeat_div) {
      case RepeatDiv.None:
        repeatDiv = "なし";
        break;
      case RepeatDiv.EveryDay:
        repeatDiv = "毎日";
        break;
      case RepeatDiv.EveryWeek:
        repeatDiv = `毎週（${this.strWeekdays()}）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedDate:
        repeatDiv = `毎月（${this.event.month_day}日）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedWeek:
        repeatDiv = `毎月（${this.strWeekNumbers()} ${this.strWeekdays()}）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedWeekDay:
        repeatDiv = `毎月（${this.strWeekCounts()}  ${this.strWeekdays()}）`;
        break;
    }

    // 時間
    let time = "";
    if (this.event.all_day) {
      time = "終日";
    } else {
      time = `${this.zeroPadding(this.event.start_hh)}:
              ${this.zeroPadding(this.event.start_mm)}〜
              ${this.zeroPadding(this.event.end_hh)}:
              ${this.zeroPadding(this.event.end_mm)}
            `;
    }

    // 期限
    let limit = "";
    if (this.event.repeat_div !== RepeatDiv.None) {
      if (this.selectedEditRange === EditRangeDiv.After) {
        limit = this.editDate.replaceAll("-", "/") + "〜";
      } else {
        limit = this.event.limit_start_date.replaceAll("-", "/") + "〜";
      }
      switch (this.event.limit_div) {
        case RepeatPeriodDiv.Date:
          limit += this.event.limit_end_date.replaceAll("-", "/");
          break;
        case RepeatPeriodDiv.Count:
          limit += this.event.limit_count + "回";
          break;
      }
    }

    return `${repeatDiv} ${time} ${limit}`;
  }

  // イベントカラー取得
  private get color(): string {
    if (this.event.color) {
      return this.event.color;
    }
    return "";
  }
  // イベントカラーセット
  private set color(val: string) {
    this.event.color = val;
  }

  // 場所アイコン
  private get locateIcon(): string {
    if (
      this.event.locate.indexOf("http://") === 0 ||
      this.event.locate.indexOf("https://") === 0
    ) {
      return "mdi-map-marker";
    }
    return "";
  }

  // 参加者一覧（表示用）
  private get guests() {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any[] = [];
    if (!this.event.participants) {
      return result;
    }
    // 参加者単位でループ
    for (const participant of this.event.participants) {
      //この予定のみ
      if (this.selectedEditRange == EditRangeDiv.This) {
        let notParticipant = false;
        if (participant.statuses) {
          for (const status of participant.statuses) {
            if (status.date <= this.editDate)
              if (status.status == 1) {
                notParticipant = true;
              } else {
                notParticipant = false;
              }
          }
          if (notParticipant) {
            // 参加しない判定 表示しない
            continue;
          }
        }
      }

      const name = this.staffs.find(staff => staff.id == participant.staff_id)
        ?.info.ibow_nickname;
      result.push({
        staff_id: participant.staff_id,
        name: name
      });
    }
    return result;
  }

  // 職員選択ダイアログタイトル
  private get staffDialogTitle(): string {
    if (this.selectOrganizer) {
      return "開催者を変更する";
    }
    return "職員を追加する";
  }
  // 職員選択ダイアログ保存ラベル
  private get staffDialogSaveLabel(): string {
    if (this.selectOrganizer) {
      return "変更する";
    }
    return "追加する";
  }

  /*
   * method
   */

  // ダイアログ開く
  public open(
    eventId: number,
    editDate: string,
    editRange: EditRangeDiv,
    cancelStaff: number[],
    defaultHH = 0,
    defaultMM = 0,
    isCopy = false
  ): void {
    // 編集範囲初期化
    this.selectedEditRange = editRange;
    // イベント初期化
    this.event = DefaultCalendarEvent();
    // 編集日付セット
    this.editDate = editDate;
    // 職員選択モード初期化
    this.selectOrganizer = false;
    this.selectOrganizerId = 0;
    this.oldOrganizerId = 0;

    // キャンセル済みの職員を保持
    this.cancelStaff = cancelStaff;

    if (eventId > 0) {
      // コピーフラグ
      this.isCopy = isCopy;
      // 更新
      this.fetch(eventId);
    } else {
      // 新規
      const minute = defaultMM - (defaultMM % 30);
      this.event.start_date = editDate;
      this.event.start_hh = defaultHH;
      this.event.start_mm = minute;
      const endDate = appDate.strToDate(editDate);
      endDate.setHours(defaultHH);
      endDate.setMinutes(minute + 60);
      this.event.end_date = appDate.dateToStr(endDate, "yyyy-MM-dd");
      this.event.end_hh = endDate.getHours();
      this.event.end_mm = endDate.getMinutes();
      const participant = DefaultCalendarParticipant();
      participant.staff_id = this.loginUser.id;
      this.event.participants.push(participant);
      this.ownerStaffId = this.loginUser.id;
    }
    if (this.form) {
      // バリデーションリセット
      this.form.resetValidation();
    }
    this.dialog = true;
  }

  // ダイアログ閉じる
  public close(): void {
    this.selectOrganizer = false; // 職員選択モード初期化
    this.selectOrganizerId = 0;
    this.oldOrganizerId = 0;
    this.dialog = false;
  }

  // ゲストを追加する
  private addGuest() {
    this.selectOrganizer = false;
    this.searchStaff.open();
  }
  // 開催者を変更する
  private changeOrganizer() {
    this.selectOrganizer = true;
    this.searchStaff.open();
  }

  // 日本語表記の曜日を返す
  private strWeekdays(): string[] {
    const result: string[] = [];
    const weekdays = this.event.week_days.split("");
    weekdays.forEach((weekday, i) => {
      if (weekday === "1") {
        result.push(WeekDays[i]);
      }
    });
    return result;
  }

  // イベント 開始日変更
  private changeStartDate() {
    // 終了日 < 開始日の場合
    if (this.event.end_date && this.event.end_date < this.event.start_date) {
      // 終了日に開始日をセット
      this.event.end_date = this.event.start_date;
    }
  }

  // 日本語表記の週目を返す
  private strWeekNumbers(): string[] {
    const result: string[] = [];
    const weeks = this.event.weeks.split("");
    weeks.forEach((week, i) => {
      if (week === "1") {
        result.push(i + 1 + "週目");
      }
    });
    return result;
  }

  // 日本語表記の週回を返す
  private strWeekCounts(): string[] {
    const result: string[] = [];
    const weeks = this.event.week_times.split("");
    weeks.forEach((week, i) => {
      if (week === "1") {
        result.push(i + 1 + "回目");
      }
    });
    return result;
  }

  private zeroPadding(val: number): string {
    return String(val).padStart(2, "0");
  }

  // イベント詳細取得
  private fetch(eventId: number) {
    this.postJsonCheck(
      window.base_url + "/api/calendar/event/get",
      {
        event_id: eventId
      },
      res => {
        if (res.data.event) {
          this.event = res.data.event;
          this.ownerStaffId = res.data.owner_staff_id;
        }
        this.limitStart = this.event.limit_start_date; // 繰り返し予定削除のため
        if (
          this.event.repeat_div &&
          this.selectedEditRange === EditRangeDiv.This
        ) {
          this.event.start_date = this.editDate;
          this.event.end_date = this.editDate;
        }
        if (this.isCopy) {
          this.event.id = 0;
          this.event.repeat_div = RepeatDiv.None;
          this.event.start_date = this.editDate;
          this.event.end_date = this.editDate;
          this.event.weeks = "000000";
          this.event.week_days = "0000000";
          this.event.week_times = "00000";
          this.event.month_day = 0;
          this.event.interval = 0;
          this.event.limit_div = 0;
          this.event.limit_count = 0;
          this.event.limit_start_date = "";
          this.event.limit_end_date = "";
          this.limitStart = "";
        }
      }
    );
  }

  // イベント保存
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    // 開催者が変更されている場合、レイヤーIDを初期化
    if (this.selectOrganizerId) {
      this.event.layer_id = 0;
    } else if (!this.event.layer_id) {
      //レイヤーIDが設定されてない場合だけ、入れる
      this.event.layer_id = this.layerId;
    }
    this.postJsonCheck(
      window.base_url + "/api/calendar/event/save",
      {
        event: this.event,
        edit_range: this.selectedEditRange,
        edit_date: this.editDate,
        select_organizer_staff_id: this.selectOrganizerId,
        old_organizer_staff_id: this.oldOrganizerId
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  // 削除
  private async del() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }
    // 編集日1日前を繰り返し期限とするため、開始日を元々の日付に戻す
    this.event.limit_start_date = this.limitStart;
    this.postJsonCheck(
      window.base_url + "/api/calendar/event/delete",
      {
        event: this.event,
        edit_range: this.selectedEditRange,
        edit_date: this.editDate
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  // イベント 参加者追加
  private addParticipant(staffIds: number[]) {
    if (!this.event.participants) {
      this.event.participants = [];
    }

    if (this.selectOrganizer) {
      // 開催者変更
      // 変更前の開催者職員IDを保持
      this.oldOrganizerId = this.event.participants[0].staff_id;

      // 選択した職員が既にゲストの場合、重複するので配列から削除
      const staffIndex = this.event.participants.findIndex(
        staff => staff.staff_id === staffIds[0]
      );

      if (staffIndex > 0) {
        this.event.participants.splice(staffIndex, 1);
      }

      this.event.participants[0].staff_id = staffIds[0]; // 画面表示のため設定
      // 変更後の開催者職員IDを保持
      this.selectOrganizerId = staffIds[0];
    } else {
      // ゲスト追加
      staffIds.forEach(staffId => {
        if (
          !this.event.participants
            .map(participant => participant.staff_id)
            .includes(staffId)
        ) {
          // 参加者に未追加の場合
          const participant = DefaultCalendarParticipant();
          participant.staff_id = staffId;
          this.event.participants.push(participant);
        } else {
          // 参加者に追加済みの場合
          const idx = this.event.participants.findIndex(
            participant => participant.staff_id == staffId
          );
          if (
            this.selectedEditRange == EditRangeDiv.This ||
            this.selectedEditRange == EditRangeDiv.All
          ) {
            // この予定のみ編集の場合は編集日のデータに未定を設定する
            const idxStatus = this.event.participants[idx].statuses.findIndex(
              status => status.date == this.editDate
            );
            if (0 <= idxStatus) {
              // 編集日と同日の参加状態データがある場合ステータスを未定に変える
              this.event.participants[idx].statuses[idxStatus].status = 0;
            } else {
              // 編集日と同日の参加状態データがない場合ステータス未定のデータを追加する
              const status = DefaultCalendarParticipantStatus();
              status.status = 0;
              this.event.participants[idx].statuses.push(status);
            }
            return;
          }
          // これ以降の予定の場合開始日以降の予定を削除する
          const statuses: CalendarParticipantStatus[] = [];
          for (const status of this.event.participants[idx].statuses) {
            if (status.date < this.editDate) {
              statuses.push(status);
            }
          }
          this.event.participants[idx].statuses = statuses;
        }
      });
    }
  }

  // イベント 参加者削除
  private delParticipant(staffid: number) {
    this.event.participants = this.event.participants.filter(
      partcipant => partcipant.staff_id != staffid
    );
  }

  // 場所アイコン
  private clickLocate() {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = this.event.locate;
    a.click();
  }

  private validateEventDate(): boolean | string {
    if (this.event.repeat_div === RepeatDiv.None) {
      if (this.event.start_date > this.event.end_date) {
        return "終了日は開始日以降に設定してください";
      }
    }
    return true;
  }

  private validateEventTime(): boolean | string {
    if (
      this.event.repeat_div === RepeatDiv.None &&
      this.event.start_date == this.event.end_date
    ) {
      if (
        this.event.start_hh > this.event.end_hh ||
        (this.event.start_hh === this.event.end_hh &&
          this.event.start_mm > this.event.end_mm)
      ) {
        return "終了時間は開始時間以降に設定してください";
      }
    }
    return true;
  }

  // この予定に変更した場合編集日を予定開始日、終了日に入れる
  private changeEditRange(event: CalendarEvent) {
    if (this.selectedEditRange === EditRangeDiv.This) {
      event.start_date = this.editDate;
      event.end_date = this.editDate;
    }
  }
}
