




































































import { Component, Mixins, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { ProcessState, PreCheckResponse } from "@/views/reciept/types";

@Component
export default class PreCheckDialog extends Mixins(AxiosMixin, UtilMixin) {
  /** ダイアログ開閉状態 */
  private isOpenDialog = false;

  /** 逐次処理フラグ(1件ずつ処理し、途中で処理の中断が可能になる) */
  private isSequentialProcess = false;

  /** 処理合計 */
  private total = 0;

  /** 処理カウント */
  private count = 0;

  /** 処理状態 */
  private processState = ProcessState.UNPROCESS;

  /** タブ情報 */
  private tab = 0;

  /** 処理結果メッセージ */
  private message = "";

  /** 事前チェック結果 */
  private checkResponses: PreCheckResponse[] = [];

  /** getter */

  /** 表示用処理カウント */
  private get Count() {
    if (this.isSequentialProcess) {
      return this.count;
    } else {
      return this.total;
    }
  }

  /** 「処理開始」ボタン無効化 */
  private get StartButtonDisabled(): boolean {
    return [ProcessState.PROCESSING, ProcessState.INTERRUPTING].includes(
      this.processState
    );
  }

  /** 「処理中断」ボタン無効化 */
  private get InterruptButtonDisabled(): boolean {
    return [
      ProcessState.UNPROCESS,
      ProcessState.PROCESSED,
      ProcessState.INTERRUPTING,
      ProcessState.INTERRUPTED
    ].includes(this.processState);
  }

  /** 「結果ダウンロード」ボタン無効化 */
  private get DownloadButtonDisabled(): boolean {
    return [
      ProcessState.UNPROCESS,
      ProcessState.PROCESSING,
      ProcessState.INTERRUPTING
    ].includes(this.processState);
  }

  /** 「閉じる」ボタン無効化 */
  private get CloseButtonDisabled(): boolean {
    return [ProcessState.PROCESSING, ProcessState.INTERRUPTING].includes(
      this.processState
    );
  }

  /** ダイアログを開く */
  public open(total: number, isSequentialProcess = false) {
    this.isOpenDialog = true;
    this.isSequentialProcess = isSequentialProcess;
    this.total = total;
    this.reset();
  }

  /** データリセット */
  public reset() {
    this.count = 0;
    this.processState = ProcessState.UNPROCESS;
    this.checkResponses = [];
    this.message = "";
  }

  /** 処理を中断する */
  private interruption() {
    this.processState = ProcessState.INTERRUPTING;
  }

  /** ダウンロードする */
  private download() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/receipt/downloadPreCheckResult",
      {
        pre_check_responses: this.checkResponses
      },
      res => {
        if (!res.data.size) {
          return;
        }

        const nowDt = new Date();
        const nowDateTimeStr = this.dateToStr(nowDt, "yyyyMMdd_HHmmss");

        const blob = new Blob([res.data]);
        const a = document.createElement("a");
        a.download = `レセプト事前チェック_同一建物_${nowDateTimeStr}.xlsx`;
        a.href = URL.createObjectURL(blob);
        a.click();
      }
    );
  }

  /** 結果更新 */
  public updateResult(preCheckResponse: PreCheckResponse) {
    this.checkResponses.push(preCheckResponse);
    this.message += preCheckResponse.process_message;
    this.count++;
  }

  /** 「処理中断」中か取得 */
  public isInterrupting(): boolean {
    return this.processState == ProcessState.INTERRUPTING;
  }

  /** 「処理中」に設定 */
  public setProcessing() {
    this.reset();
    this.processState = ProcessState.PROCESSING;
  }

  /** 「処理完了」に設定 */
  public setProcessed() {
    this.processState = ProcessState.PROCESSED;
  }

  /** 「中断完了」に設定 */
  public setInterrupted() {
    this.processState = ProcessState.INTERRUPTED;
  }

  /** Emit */

  /** 処理開始 */
  @Emit("process:start")
  private startProcess() {
    return;
  }

  /** ダイアログを閉じる */
  @Emit() private close(): boolean {
    this.isOpenDialog = false;

    // 処理の結果、1件でも実績の再登録があるか親に通知
    // 親側で閉じるときに再登録がない（=確定状態に変更がない）のであれば再検索の必要なし
    return this.checkResponses.some((checkResponse: PreCheckResponse) => {
      return checkResponse.register_succeeded_count > 0;
    });
  }
}
