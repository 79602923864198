







































































































































































































import { Component, Watch, Mixins, Ref } from "vue-property-decorator";
import {
  CalendarEventMoving,
  DefaultCalendarEventMoving,
  VForm
} from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import CalendarMixin from "@/mixins/calendarMixin";
import PatientMixin from "@/mixins/patientMixin";
import DateMixin from "#/mixins/dateMixin";
import FocusingMixin from "@/mixins/focusingMixin";
import {
  ReceiptVisitRecord,
  HomeCancer,
  DefaultHomeCancer,
  RegistrationMessage,
  DefaultResultsConfirmedMessage,
  DefaultResetMessage,
  AmountInfo,
  DefaultAmountInfo,
  Receipt,
  DefaultReceipt,
  ReceiptSearchCondition,
  DefaultReceiptSearchCondition
} from "#/model/receipt";
import { IndicateInfoTexts } from "@/components/patient/calendar/types";
import { Calendar } from "#/components/calendar/common";
import ReceiptEditAddBase from "@/components/patient/receipt/ReceiptEditAddBase";
import ConfirmationMessage from "@/components/patient/receipt/ConfirmationMessage.vue";
import ResultsConfirmed from "@/components/patient/receipt/ResultsConfirmed.vue";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { COLLECTION_RECEIPT_LIST_ITEMS } from "@/const/envFireStore";
import ReceiptDetailTitle from "@/components/patient/receipt/ReceiptDetailTitle.vue";
import ReceiptDetailHonobonoInfo from "@/components/patient/receipt/ReceiptDetailHonobonoInfo.vue";
import ReceiptDetailFooter from "@/components/patient/receipt/ReceiptDetailFooter.vue";
import ReceiptDetailSlideMenu from "@/components/patient/receipt/ReceiptDetailSlideMenu.vue";
import {
  RequestPreviewServiceOffer,
  FetchParameter
} from "@/components/patient/receipt/types";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    ConfirmationMessage,
    ResultsConfirmed,
    ReceiptDetailTitle,
    ReceiptDetailHonobonoInfo,
    ReceiptDetailFooter,
    ReceiptDetailSlideMenu
  }
})
export default class RecieptDetail extends Mixins(
  AxiosMixin,
  RulesMixin,
  CalendarMixin,
  FireStoreMixin,
  PatientMixin,
  DateMixin,
  FocusingMixin
) {
  /** レセプト登録画面 */
  private DISPLAY_RECEIPT_REGIST = 0;

  /** サービス内容画面 */
  private DISPLAY_SERVICE_CONTENT = 1;

  /** 入力フォーム */
  @Ref("patient-header") private readonly patientHeader!: VForm;

  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: Calendar;

  /** 登録コンポーネント */
  @Ref("registPanelArea") private readonly registPanelArea!: ReceiptEditAddBase;

  /** スライドメニュー */
  @Ref("slide-menu") private readonly slideMenu!: ReceiptDetailSlideMenu;

  /** 利用者名 */
  private patient_name = "";
  /** 生年月日 */
  private birthday_wareki = "";
  /** 年齢 */
  private age = 0;

  /** 対象年月 */
  private yearmonth = "";

  /** 利用者事業所ID */
  private officeId = 0;

  /** 読み込み状態 */
  private isLoaded = false;

  /** 操作モード 0:レセプト登録 1:サービス内容 */
  private display_option = 0;

  /** ほのぼの使用フラグ */
  private isHonobono = 0;
  /** ほのぼの事業所名 */
  private honobonoJigyoKnj = "";

  /** 在宅がん医療総合診療料情報 */
  private homeCancer: HomeCancer = DefaultHomeCancer();

  /** カレンダーイベント */
  private events: CalendarEventMoving[] = [];
  /** カレンダー年月 */
  private focus = "";

  /** 指示書種類・期間情報（特別指示のみ） */
  private indicateInfoTexts: IndicateInfoTexts[] = [];

  /** レセプト確定フラグ  */
  private isDecisionMedical = false; //レセプト確定フラグ（医療）
  private isDecisionCare = false; //レセプト確定フラグ（介護）
  /** 実績確定表示 */
  private dispDecisionMedical = ""; //利用者情報（確定・未確定）（医療）
  private dispDecisionCare = ""; //利用者情報（確定・未確定）（介護）
  private dateOfResultsDeterminationMedical = ""; //実績確定日（医療）
  private dateOfResultsDeterminationCare = ""; //実績確定日（介護）
  /** 請求履歴確定済フラグ */
  private isBilledMedical = 0; //請求履歴確定済フラグ（医療）
  private isBilledCare = 0; //請求履歴確定済フラグ（介護）
  /** レセプトデータ */
  private receipt: Receipt = DefaultReceipt();

  /** 検索条件 */
  private searchCond = DefaultReceiptSearchCondition(); // 検索条件

  /** 医療訪問の利用者で、かつ医療保険の期間内に高額療養費を設定していない場合 */
  private medicalNotHighExpenseFlg = false;

  /** メッセージ項目 */
  private messageItem = {
    /** 登録時メッセージ */
    registrationMessages: [] as RegistrationMessage[],
    /** 確定時メッセージ */
    resultsConfirmedMessage: DefaultResultsConfirmedMessage(),
    /** リセット時メッセージ */
    resetMessage: DefaultResetMessage(),
    /** 実績確定解除時メッセージ */
    releaseConfirmedResultsMessage: "",
    /** 登録メッセージ */
    registrationMessage: ""
  };

  /** 実績確定時のチェック項目 */
  private confirmCheckItem = {
    /** 医療訪問の医療保険が公費単独で裏に公費の登録がない場合 */
    expiredPublicFlg: false,
    /** 介護訪問の裏の介護保険が申請中の場合 */
    applicationCareFlg: false,
    /** 月内に書面未到着の指示書がある場合 */
    notYetArrivedIndicateFlg: false,
    /** 在宅がん期間内に介護訪問がある（確定時用） */
    decisionHomeCancerCareFlg: false,
    /** DecisionHomeCancerCareFlgがtrueの場合の介護訪問がある日にち（確定時用） */
    decisionHomeCancerCareDay: "",
    /** 確定対象に医療訪問で在宅がんの訪問がある */
    decisionIncludeHomeCancer: false
  };

  /** 開いている訪問情報(詳細エリアを閉じた時は、最後に開いた訪問情報) */
  private event: CalendarEventMoving = DefaultCalendarEventMoving();
  /** 訪問情報（月内全て） */
  private receiptVisitRecords: ReceiptVisitRecord[] = [];
  /** 実績確定時金額表示用データ */
  private amountInfo: AmountInfo = DefaultAmountInfo();

  /** 利用者ID */
  private get PatientId() {
    return Number(this.$route.query.id);
  }

  /** パンくずリスト */
  public get BreadItems() {
    return [
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patient_name,
        disabled: false,
        to: `/patient/${this.PatientId}/calendar`
      },
      {
        text: "レセプト処理",
        disabled: true,
        to: ""
      }
    ];
  }

  /** カレンダー用のイベント */
  private get EventsForCalendar() {
    const calendarEvents = [];
    for (const event of this.events) {
      //医・介・定・定期巡回除外日・指定月以外の訪問の場合
      if (
        this.isVisitEvent(event) ||
        event.type == EventType.PassCrawlExceptDate ||
        event.type == EventType.DirectionSpecial ||
        event.type == EventType.OutMonthVisit
      ) {
        event.is_not_moving = true;
        calendarEvents.push(event);
      }
    }
    return calendarEvents;
  }

  /** APIパラメータ */
  private get FetchParameter(): FetchParameter {
    let startDate = "";
    let endDate = "";
    let calendarDiv = 0;
    startDate = this.MonthStartDate;
    endDate = this.MonthEndDate;
    calendarDiv = 1;
    const parameter: FetchParameter = {
      patient_id: this.PatientId,
      start_date: startDate,
      end_date: endDate,
      calendar_div: calendarDiv,
      is_reciept: true
    };
    return parameter;
  }

  //カレンダー対象年月制御
  @Watch("yearmonth") watchYearmonth() {
    this.$router.replace({
      name: this.$route.name ?? "PatientRecieptDetail",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        yearmonth: this.yearmonth
      }
    });
    this.reset();
  }

  //表示切り替え（レセプト登録・サービス内容）
  @Watch("display_option") watchDisplayOption() {
    this.reset();
  }

  private created(): void {
    this.getReceiptDocument();
    if (this.$route.query.yearmonth) {
      this.yearmonth = String(this.$route.query.yearmonth);
      this.focus = this.$route.query.yearmonth + "-01";
    } else {
      this.yearmonth = this.lastOrThisMonth();
      this.focus = this.yearmonth + "-01";
    }
    this.fetchEvents();
  }

  /** メッセージをリセットする */
  private resetMessage() {
    this.messageItem.registrationMessages = [];
    this.messageItem.resultsConfirmedMessage = DefaultResultsConfirmedMessage();
    this.messageItem.resetMessage = DefaultResetMessage();
    this.messageItem.releaseConfirmedResultsMessage = "";
    this.messageItem.registrationMessage = "";
  }

  /** カレンダー表示月の訪問イベントか */
  private isVisitEvent(event: CalendarEventMoving) {
    return (
      event.type == EventType.Record2NursingCare ||
      event.type == EventType.Record2Medical ||
      event.type == EventType.Record2PassCrawl
    );
  }

  /** イベント表示形式に整える */
  public dispEvent(event: CalendarEventMoving) {
    return event.name;
  }

  /** イベントツールチップ表示形式に整える */
  private dispTooltipEvent(event: CalendarEventMoving) {
    return event.name_toolchip || event.name;
  }

  /** イベントのクリックハンドラ */
  public handleEvent(event: CalendarEventMoving) {
    //レセプト登録の場合
    if (this.display_option == this.DISPLAY_RECEIPT_REGIST) {
      //医・介・定の場合
      if (this.isVisitEvent(event)) {
        //メッセージリセット
        this.resetMessage();

        if (
          event.visit_record_id === this.event.visit_record_id &&
          event.sub_no === this.event.sub_no
        ) {
          this.slideMenu.open();
        } else {
          //データ取得
          this.fetchEvent(event);
        }
      }
    }

    //サービス内容の場合
    if (this.display_option == this.DISPLAY_SERVICE_CONTENT) {
      //医・介・定の場合
      if (this.isVisitEvent(event)) {
        this.previewServiceOffer({
          patient_id: this.PatientId,
          office_id: this.officeId,
          start_date: event.start,
          end_date: event.end,
          insurance_type: event.visit_record.insurance_div
        });
      }
    }
  }

  /** イベントデータ取得 */
  public fetchEvent(event: CalendarEventMoving) {
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/receiptVisitRecord/get",
      {
        patient_id: this.PatientId,
        yearmonth: this.yearmonth,
        event: event,
        events: this.events
      },
      res => {
        this.event = DefaultCalendarEventMoving();
        this.$nextTick(() => {
          this.resetMessage();
          this.event = event;
          this.slideMenu.set(res.data);
        });
      }
    );
  }

  /** 前の実績イベント */
  private get PrevEvent(): CalendarEventMoving | undefined {
    let prevEvent: CalendarEventMoving | undefined = undefined;
    this.events
      .filter(event => this.isVisitEvent(event))
      .forEach((event, i, events) => {
        if (event.id === this.event.id && i >= 1) {
          prevEvent = events[i - 1];
        }
      });

    return prevEvent;
  }

  /** 次の実績イベント */
  private get NextEvent(): CalendarEventMoving | undefined {
    let nextEvent: CalendarEventMoving | undefined = undefined;
    this.EventsForCalendar.filter(event => this.isVisitEvent(event)).forEach(
      (event, i, events) => {
        if (event.id === this.event.id && i < events.length - 1) {
          nextEvent = events[i + 1];
        }
      }
    );

    return nextEvent;
  }

  /** 月検索の開始日 */
  private get MonthStartDate(): string {
    return this.addMonthDate(this.focus, 0);
  }

  /** 月検索の終了日 */
  private get MonthEndDate(): string {
    return this.addMonthDate(this.focus, 1);
  }

  /** イベントを取得する */
  private fetchEvents() {
    return new Promise((resolve, reject) => {
      // レセプト登録カレンダー取得
      if (this.display_option == 0) {
        this.postJsonCheck(
          window.base_url + "/api/patient/calendar/get",
          this.FetchParameter,
          res => {
            this.focus = res.data.date;
            this.indicateInfoTexts = res.data.indicate_info_texts;
            this.events = res.data.events;
            this.isBilledMedical = res.data.is_billed_medical;
            this.isBilledCare = res.data.is_billed_care;
            this.isDecisionMedical = res.data.is_decision_medical;
            this.isDecisionCare = res.data.is_decision_care;
            this.dispDecisionMedical = res.data.disp_decision_medical;
            this.dispDecisionCare = res.data.disp_decision_care;
            this.dateOfResultsDeterminationMedical =
              res.data.date_of_results_determination_medical;
            this.dateOfResultsDeterminationCare =
              res.data.date_of_results_determination_care;
            this.homeCancer = res.data.home_cancer;
            this.isHonobono = res.data.is_honobono;
            this.honobonoJigyoKnj = res.data.honobono_jigyo_knj;
            this.amountInfo = res.data.amount_info;
            this.officeId = res.data.office_id;
            this.patient_name = res.data.patient_name;
            this.birthday_wareki = res.data.birthday_wareki;
            this.age = res.data.age;
            this.medicalNotHighExpenseFlg =
              res.data.medical_not_high_expense_flg;
            //レセプト登録特有
            this.confirmCheckItem.expiredPublicFlg =
              res.data.expired_public_flg;
            this.confirmCheckItem.applicationCareFlg =
              res.data.application_care_flg;
            this.confirmCheckItem.notYetArrivedIndicateFlg =
              res.data.not_yet_arrived_indicate_flg;
            this.confirmCheckItem.decisionHomeCancerCareFlg =
              res.data.decision_home_cancer_care_flg;
            this.confirmCheckItem.decisionHomeCancerCareDay =
              res.data.decision_home_cancer_care_day;
            this.confirmCheckItem.decisionIncludeHomeCancer =
              res.data.decision_include_home_cancer;
            this.receiptVisitRecords = res.data.receipt_visit_records;

            this.isLoaded = true;
            resolve(true);
          },
          () => reject()
        );
      }

      // サービス内容カレンダー取得
      if (this.display_option == 1) {
        this.postJsonCheck(
          window.base_url + "/api/patient/servicesCalendar/get",
          this.FetchParameter,
          res => {
            this.focus = res.data.date;
            this.indicateInfoTexts = [];
            this.events = res.data.events;
            this.isBilledMedical = res.data.is_billed_medical;
            this.isBilledCare = res.data.is_billed_care;
            this.isDecisionMedical = res.data.is_decision_medical;
            this.isDecisionCare = res.data.is_decision_care;
            this.dispDecisionMedical = res.data.disp_decision_medical;
            this.dispDecisionCare = res.data.disp_decision_care;
            this.dateOfResultsDeterminationMedical =
              res.data.date_of_results_determination_medical;
            this.dateOfResultsDeterminationCare =
              res.data.date_of_results_determination_care;
            this.homeCancer = res.data.home_cancer;
            this.isHonobono = res.data.is_honobono;
            this.honobonoJigyoKnj = res.data.honobono_jigyo_knj;
            this.amountInfo = res.data.amount_info;
            this.officeId = res.data.office_id;
            this.patient_name = res.data.patient_name;
            this.birthday_wareki = res.data.birthday_wareki;
            this.age = res.data.age;
            //サービス内容特有
            this.receipt = res.data.receipt;

            this.isLoaded = true;
            resolve(true);
          },
          () => reject()
        );
      }
    });
  }

  /** 更新する */
  private update(noFetch: boolean) {
    if (noFetch) {
      return;
    }
    this.fetchEvents().then(() => {
      if (this.event.id > 0) {
        // 実績を選択中の場合は、それを再読み込みする
        // リセットで保険種類が変わることを考慮し、取得直後のイベント一覧のデータを使う
        const updatedEvent = this.EventsForCalendar.find(
          ev =>
            ev.visit_record_id === this.event.visit_record_id &&
            ev.sub_no === this.event.sub_no
        );
        if (updatedEvent) {
          this.event = updatedEvent;
          this.fetchEvent(this.event);
        } else {
          // 実績操作中に次の実績が差し戻されてしまったら、"選択して次へ"でも閉じる
          this.slideMenu.close();
        }
      }
    });
  }

  /** 表示する実績を次に切り替える準備 */
  private showNext() {
    this.event = this.NextEvent ?? DefaultCalendarEventMoving();
  }

  /** 確定状況一覧画面に移動する */
  private moveListOfConfirmedStatus() {
    this.$router.push({
      name: "Reciept",
      query: { yearmonth: this.yearmonth, officeid: String(this.officeId) }
    });
  }

  /** 単一日のサービス提供表を出力する */
  private previewServiceOffer(
    requestPreviewServiceOffer: RequestPreviewServiceOffer
  ): void {
    this.makePdf(
      window.base_url + "/api/patient/receipt/preview",
      requestPreviewServiceOffer
    );
  }

  /** Firestoreから情報を取得する */
  private async getReceiptDocument() {
    // fire store から利用者一覧のIdリスト取得
    this.collection = COLLECTION_RECEIPT_LIST_ITEMS;
    this.documentId = String(this.loginUser.id);
    const cond = await this.documentGet();
    if (cond === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
    // 検索結果にヒットした利用者がいれば、そのリストを保持
    const resultIds = (cond as ReceiptSearchCondition).ids;
    if (resultIds && resultIds.length > 0) {
      this.searchCond.ids = resultIds;
    }
  }

  /** リセット */
  private reset() {
    if (!this.isLoaded) {
      return;
    }
    this.focus = this.yearmonth + "-01";
    this.slideMenu.close();
    this.event = DefaultCalendarEventMoving();

    //メッセージリセット
    this.resetMessage();
    this.update(false);
  }
}
