import Base from "./base";

//服薬管理
export interface MedicineTaking extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  medicine_date: string; //'服薬管理日付',
  m_name: string; //'処方病院',
  d_name: string; //'医師名',
  depname: string; //'診療科',
  taking_timing: string; //'服薬タイミング',
  medicine_name: string; //'薬品名',
  medicine_capacity: string; //'容量',
  wake_up: string; //'起床',
  before_morning: string; //'朝食前',
  after_morning: string; //'朝食後',
  before_afternoon: string; //'昼食前',
  after_afternoon: string; //'昼食後',
  before_evening: string; //'夕食前',
  after_evening: string; //'夕食後',
  before_retiring: string; //'就寝前',
  stop_date: string; //'中止・終了日',
  important_point: string; //'注意点',
  medicine_date_wareki: string; //服薬管理日付(和暦)
  stop_date_wareki: string; //中止・終了日(和暦)
}
export const DefaultMedicineTaking = (): MedicineTaking => ({
  id: 0,
  patient_id: 0,
  medicine_date: "",
  m_name: "",
  d_name: "",
  depname: "",
  taking_timing: "",
  medicine_name: "",
  medicine_capacity: "",
  wake_up: "",
  before_morning: "",
  after_morning: "",
  before_afternoon: "",
  after_afternoon: "",
  before_evening: "",
  after_evening: "",
  before_retiring: "",
  stop_date: "",
  important_point: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  medicine_date_wareki: "",
  stop_date_wareki: ""
});
