




































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import CalendarMixin from "@/mixins/calendarMixin";
import PatientMixin from "@/mixins/patientMixin";
import { CalendarEvent, DefaultCalendarEvent, Choice } from "@/types";
import {
  IndicateInfo,
  FetchParameter,
  IndicateInfoTexts,
  CalendarType
} from "@/components/patient/calendar/types";
import { VisitRecord, getRemandName } from "#/model/visit";
import AbsenseDialog from "@/components/patient/calendar/AbsenseDialog.vue";
import VisitDialog from "@/components/patient/calendar/VisitDialog.vue";
import LayerDialog from "@/components/patient/calendar/LayerDialog.vue";
import SituationDialog from "@/components/patient/calendar/SituationDialog.vue";
import { OfficeStaffChoice } from "#/model/staff";
import * as appDate from "#/utility/appDate";
import { Route } from "vue-router";
import { OFFICE_STAFF } from "#/const";
import NowButton from "#/components/calendar/NowButton.vue";
import CalendarTitleBeforeAfter from "#/components/calendar/CalendarTitleBeforeAfter.vue";
import IbowCalendar from "@/components/common_ibow/calendar/IbowCalendar.vue";
import { EventType } from "#/model/schedule/calendarType";
import { PatientSituation } from "#/model/patient";

@Component({
  components: {
    AbsenseDialog,
    VisitDialog,
    LayerDialog,
    SituationDialog,
    NowButton,
    CalendarTitleBeforeAfter,
    IbowCalendar
  }
})
export default class PatientCalendar extends Mixins(
  AxiosMixin,
  CalendarMixin,
  PatientMixin
) {
  /** レセプトフラグ:バックエンド処理がカレンダーと類似の為 */
  private isReciept = false;
  /** 指示書情報 */
  private indicateInfos: IndicateInfo[] = [];
  /** 指示書テキスト */
  private indicateInfoTexts: IndicateInfoTexts[] = [];
  /** カレンダーイベント（フィルターなし） */
  private orgEvents: CalendarEvent[] = [];
  /** カレンダーイベント */
  private events: CalendarEvent[] = [];
  /** 請求履歴確定済フラグ（医療） */
  private isBilledMedical = 0;
  /** 請求履歴確定済フラグ（介護） */
  private isBilledCare = 0;
  /** レセプト確定フラグ（医療） */
  private isDecisionMedical = false;
  /** レセプト確定フラグ（介護） */
  private isDecisionCare = false;
  /** 誕生日 */
  private birthday = "";
  /** 権限ID */
  private authId = 0;
  /** 対象日 */
  private focus = this.dateToStr(new Date(), "yyyy-MM-dd");
  /** カレンダータイプ */
  private type = "month";
  /** 不在イベント */
  private adsenceEvent: CalendarEvent = DefaultCalendarEvent();
  /** 訪問イベント */
  private visitEvent: CalendarEvent = DefaultCalendarEvent();
  /** 利用者状況イベント */
  private situationEvent: CalendarEvent = DefaultCalendarEvent();
  /** 利用者リスト */
  private patients: Choice[] = [];
  /** 職員リスト */
  private staffs: OfficeStaffChoice[] = [];
  /** 表示設定対象のイベントタイプ */
  private changeEventType: number[] = [
    EventType.DirectionNormal,
    EventType.DirectionSpecial,
    EventType.Care,
    EventType.BurdenRatio,
    EventType.Medical,
    EventType.HighExpense,
    EventType.OldAge,
    EventType.PublicMoney
  ];
  private eventType = EventType;

  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: IbowCalendar;
  /** 不在ダイアログコンポーネント */
  @Ref("absenseDialog") private readonly absenseDialog!: AbsenseDialog;
  /** 訪問ダイアログコンポーネント */
  @Ref("visitDialog") private readonly visitDialog!: VisitDialog;
  /** 表示設定ダイアログコンポーネント */
  @Ref("layerDialog") private readonly layerDialog!: LayerDialog;
  /** 利用者状況ダイアログコンポーネント */
  @Ref("situationDialog") private readonly situationDialog!: SituationDialog;

  /** 日/週表示と月表示で表示情報を分ける */
  @Watch("type") private watchType() {
    this.fetchEvents();
    this.movePath();
  }

  /** 利用者移動時にデータを再取得する */
  @Watch("$route") watchRoute(to: Route, from: Route) {
    if (to.matched.length == 0 || from.matched.length == 0) {
      return;
    }

    // 利用者Aのカレンダー -> 利用者Bのカレンダーのような場合は再読み込みさせる
    if (
      to.matched[0].path == from.matched[0].path &&
      to.params.id != from.params.id
    ) {
      this.fetchEvents();
    }
  }

  /** 利用者ID */
  private get PatientId(): number {
    return Number(this.$route.params.id);
  }

  /** クエリパラメータに日付が設定されているかどうか */
  private get QueryHasDate(): boolean {
    const strDate = this.$route.query.focus;
    if (!strDate) {
      return false;
    }
    if (typeof strDate !== "string") {
      return false;
    }
    if (!strDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return false;
    }

    // 日付変換された日付が入力値と同じ事を確認
    // new Date()の引数に不正な日付が入力された場合、相当する日付に変換されてしまうため
    const date = new Date(strDate);
    if (
      date.getFullYear() != Number(strDate.split("-")[0]) ||
      date.getMonth() != Number(strDate.split("-")[1]) - 1 ||
      date.getDate() != Number(strDate.split("-")[2])
    ) {
      return false;
    }

    return true;
  }

  /** クエリパラメータにタイプが設定されているかどうか */
  private get QueryHasType(): boolean {
    const type = this.$route.query.type;
    // 空文字は無効
    if (type == "") {
      return false;
    }
    // 不正なtypeは無効
    if (type != "month" && type != "week" && type != "day") {
      return false;
    }
    return true;
  }

  /** データ取得パラメータ */
  private get FetchParameter(): FetchParameter {
    let startDate = "";
    let endDate = "";
    let calendarDiv = 0;
    switch (this.type) {
      case CalendarType.Month:
        startDate = this.MonthStartDate;
        endDate = this.MonthEndDate;
        calendarDiv = 1;
        break;
      case CalendarType.Week:
        startDate = this.WeekStartDate;
        endDate = this.WeekEndDate;
        calendarDiv = 2;
        break;
      case CalendarType.Day:
        startDate = this.DayStartEndDate;
        endDate = this.DayStartEndDate;
        calendarDiv = 2;
        break;
      default:
        break;
    }
    const parameter: FetchParameter = {
      patient_id: this.PatientId,
      start_date: startDate,
      end_date: endDate,
      calendar_div: calendarDiv,
      is_reciept: this.isReciept
    };
    return parameter;
  }

  /** 月検索の開始日 */
  private get MonthStartDate(): string {
    return this.addMonthDate(this.focus, 0);
  }

  /** 月検索の終了日 */
  private get MonthEndDate(): string {
    return this.addMonthDate(this.focus, 1);
  }

  /** 週検索の開始日 */
  private get WeekStartDate(): string {
    return this.addWeekDate(this.focus, 0);
  }

  /** 週検索の終了日 */
  private get WeekEndDate(): string {
    return this.addWeekDate(this.focus, 6);
  }

  //1日検索の開始終了日
  private get DayStartEndDate(): string {
    if (this.focus == "") {
      return "";
    }
    const date = this.strToDate(this.focus);
    return this.dateToStr(date, "yyyy-MM-dd");
  }

  created(): void {
    if (this.QueryHasDate) {
      this.focus = String(this.$route.query.focus);
    } else {
      this.focus = this.dateToStr(new Date(), "yyyy-MM-dd");
    }

    if (this.QueryHasType) {
      this.type = String(this.$route.query.type);
    } else {
      this.type = "month";
    }

    this.fetchEvents();
  }

  mounted() {
    this.setDefaultHeader();
  }

  /** データを取得する */
  private fetchEvents() {
    // カレンダー取得
    this.postJsonCheck(
      window.base_url + "/api/patient/calendar/get",
      this.FetchParameter,
      res => {
        this.indicateInfos = [];
        this.events = [];
        this.$nextTick(() => {
          //this.focus = res.data.date; //日付がずれる問題のため、コメントアウト
          this.indicateInfos = res.data.indicate_infos;
          this.isBilledMedical = res.data.is_billed_medical;
          this.isBilledCare = res.data.is_billed_care;
          this.isDecisionMedical = res.data.is_decision_medical;
          this.isDecisionCare = res.data.is_decision_care;
          this.orgEvents = res.data.events;
          if (res.data.schedule_events.length > 0) {
            this.orgEvents = this.orgEvents.concat(res.data.schedule_events);
          }
          this.birthday = res.data.birthday;
          this.authId = res.data.auth_id;
          this.staffs = res.data.staffs;
          this.patients = res.data.patients;
          this.events = this.filterEvent();
          this.indicateInfoTexts = res.data.indicate_info_texts;
        });
      }
    );
  }

  /** クエリパラメータを変更する */
  private movePath() {
    const movePath = `/patient/${this.FetchParameter.patient_id}/calendar?focus=${this.focus}&type=${this.type}`;
    if (this.$route.fullPath != movePath) {
      this.$router.push(movePath);
    }
  }

  /** データを取得し、パスを変更する */
  private fetchAndMovePath() {
    this.fetchEvents();
    this.movePath();
  }

  /** 表示設定ダイアログを開く */
  private openChengeLayerDialog() {
    this.layerDialog.open();
  }

  /** 表示設定によって、イベントを絞り込む */
  private filterEvent(): CalendarEvent[] {
    // 選択したレイヤー取得
    const select: number[] = this.layerDialog.selectLayers
      .filter(layer => {
        return layer.selected;
      })
      .map(layer => {
        return layer.value;
      });
    const filterEvents: CalendarEvent[] = [];
    this.orgEvents.forEach(event => {
      // 表示設定対象のイベントタイプかどうか
      if (this.changeEventType.includes(event.type)) {
        // チェックされていれば表示
        if (select.includes(event.type)) filterEvents.push(event);
      } else {
        // 表示設定対象以外のイベントはすべて表示
        filterEvents.push(event);
      }
    });
    // チェックボックスの色
    // イベントがない場合はグレー
    this.layerDialog.selectLayers.forEach(layer => {
      layer.color =
        this.orgEvents.find(event => {
          return event.type == layer.value;
        })?.color || "#c5ccd2";
    });

    return filterEvents;
  }

  /** 職員名を取得する */
  private getStaffName(staffId: number): string {
    const staff = this.staffs.find(staff => staff.value == staffId);
    if (staff) {
      return staff.text;
    }
    return "";
  }

  /** 職員ニックネームを取得する */
  private getStaffNickName(staffId: number): string {
    const staff = this.staffs.find(staff => staff.value == staffId);
    if (staff) {
      return staff.nick_name;
    }
    return "";
  }

  /** 利用者名を取得する */
  private getPatientName(patietId?: number): string {
    const patient = this.patients.find(
      patient => patient.value == patietId
    ) as Choice;
    return patient.text || "";
  }

  /** イベントを押下した時の処理 */
  public handleEvent(event: CalendarEvent) {
    //医・介・定の場合
    if (
      event.type == EventType.Record2NursingCare ||
      event.type == EventType.Record2Medical ||
      event.type == EventType.Record2PassCrawl
    ) {
      //PDF出力
      if (this.authId == OFFICE_STAFF.AUTH.COMMON) {
        this.pdf(event.visit_record);
      } else {
        this.visitEvent = event;
        this.visitDialog.open();
      }
    }
    //不在の場合
    if (event.type == EventType.Absence) {
      this.adsenceEvent = event;
      this.absenseDialog.open();
    }
    //ファイル登録の場合
    if (event.type == EventType.File) {
      this.previewFile(event.file_path);
    }
    //利用者状況の場合
    if (event.type == EventType.Situation) {
      this.situationEvent = event;
      this.situationDialog.open();
    }
  }

  /** 記録書2を差し戻す */
  private async remand(res: any) {
    //アラート文言編集
    const remandName = getRemandName(res.event.visit_record);
    let sendingBack = `看護記録書Ⅱを ${remandName}さん へ差戻しますか？`;
    if (
      (res.event.type == EventType.Record2Medical && this.isDecisionMedical) ||
      ((res.event.type == EventType.Record2NursingCare ||
        res.event.type == EventType.Record2PassCrawl) &&
        this.isDecisionCare)
    ) {
      sendingBack = `実績確定されています。差戻しを行うと実績確定が解除されますが看護記録書Ⅱを ${remandName}さん へ差戻しますか？`;
    }
    if (
      (res.event.type == EventType.Record2Medical &&
        this.isBilledMedical == 1) ||
      ((res.event.type == EventType.Record2NursingCare ||
        res.event.type == EventType.Record2PassCrawl) &&
        this.isBilledCare == 1)
    ) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください"
      );
      return;
    }
    if (await this.$openConfirm(sendingBack)) {
      this.postJsonCheck(
        window.base_url + "/api/patient/calendar/sendingBack",
        {
          patient_id: this.PatientId,
          event: res.event,
          month_start_date: "", // apiで使用していない？
          remand_comment: res.comment
        },
        () => {
          this.fetchEvents();
        }
      );
    }
  }

  /** PDFを出力する */
  private pdf(visitRecord: VisitRecord): void {
    this.makePdf(window.base_url + "/api/patient/visitrecord/preview/ids", {
      patient_id: this.PatientId,
      visit_records: [visitRecord],
      visit_record_ids: [visitRecord.id]
    });
    return;
  }

  /** 訪問予定作成 */
  private insertEventVisit(): void {
    if (this.patients.length === 0) {
      return;
    }
    const d = new Date();
    const date = appDate.dateToStr(d, "yyyy-MM-dd");
    this.calendar.visitPlanEditEventDialog.open(
      0,
      date,
      d.getHours(),
      d.getMinutes(),
      this.PatientId
    );
  }

  /** カレンダーをクリックする */
  private clickCalendar(event: CalendarEvent) {
    if (event.date) {
      this.calendar.visitPlanEditEventDialog.open(
        0,
        event.date,
        event.hour,
        event.minute,
        this.PatientId
      );
    } else {
      const d = new Date();
      const date = appDate.dateToStr(d, "yyyy-MM-dd");
      this.calendar.visitPlanEditEventDialog.open(
        0,
        date,
        d.getHours(),
        d.getMinutes(),
        this.PatientId
      );
    }
  }

  /** 利用者状況を編集する */
  private editPatientSituation(patientSituation: PatientSituation) {
    this.calendar.visitPlanEditEventDialog.open(
      0,
      patientSituation.target_date,
      0,
      0,
      0,
      0,
      0,
      false,
      patientSituation.id
    );
  }
}
