



















import { Component, Mixins } from "vue-property-decorator";
import OutputExcel from "@/components/dashboard/OutputExcel.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import StatisticsSearch from "@/components/dashboard/StatisticsSearch.vue";

@Component({
  components: {
    OutputExcel,
    StatisticsSearch
  }
})
export default class Dashboard extends Mixins(AxiosMixin) {}
