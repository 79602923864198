import { DataTableHeader } from "vuetify";
import { Choice } from "@/types";
import { Doctor } from "#/model/uniquemaster";

/** 出力用担当者model */
export interface OutputDoctor extends Doctor {
  /** 親ID */
  parent_unique_id: number;
  /** ID */
  unique_id: number;
}

/** Excel入力結果情報 */
export interface ExcelInputResult {
  /** 成否 */
  is_success: boolean;
  /** 結果情報 */
  result: ResultData;
  /** 取り込み対象Proto構造体 */
  proto_data: unknown;
  /** 取り込み用APIパス */
  api_path: string;
}

export interface ResultData {
  /** シートID(一意) */
  sheet_id: number;

  /** シート名 */
  sheet_name: string;

  /** ヘッダー情報(シート内のテーブル情報) ※ valueとレコード情報のプロパティ名を合わせる */
  headers: DataTableHeader[];

  /** 該当シート内の取り込み時エラーメッセージ */
  error_messages: string[];

  data: unknown;
}

export const DEFAULT_RESULT_DATA = {
  sheet_id: 0,
  sheet_name: "",
  headers: [],
  error_messages: [],
  data: {}
};

/** 各行の基本情報 */
export interface BaseRecord {
  /** 行番号 */
  row_id: number;

  /** 行状態 0:既存行（差分あり）, 1:既存行（差分なし）, 2:新規行, 3:エラー行, 4:スキップ行 */
  record_state: number;
}

/** 各項目の基本情報 */
export interface BaseColumn {
  /** 項目の表示名 */
  text: string;

  /** 項目状態 0:通常, 1:差分あり項目, 2:エラー項目 */
  column_state: number;

  /** 行内ID */
  id: number;
}

export interface Sheet {
  /** シートID */
  sheet_id: number;
  /** シート名 */
  sheet_name: string;
  /** 重複チェック用項目選択肢 */
  key_choices: Choice[];
  /** 事業所選択必要フラグ */
  need_office_select: boolean;
  /** 表示順 */
  sort_no: number;
  /** 医療保険オンライン請求フラグ */
  is_med_transmission: boolean;
}

export const SHEET_DATA: Sheet[] = [
  {
    sheet_id: 17,
    sheet_name: "職員",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 10,
    is_med_transmission: false
  },
  {
    sheet_id: 1,
    sheet_name: "＜マスタメンテ＞関連機関",
    key_choices: [
      { text: "ID", value: 1 },
      { text: "関連機関名", value: 2 }
    ],
    need_office_select: false,
    sort_no: 20,
    is_med_transmission: true
  },
  {
    sheet_id: 2,
    sheet_name: "＜マスタメンテ＞関連機関担当者",
    key_choices: [
      { text: "ID", value: 1 },
      { text: "関連機関名+担当者名", value: 2 }
    ],
    need_office_select: false,
    sort_no: 30,
    is_med_transmission: true
  },
  {
    sheet_id: 3,
    sheet_name: "＜マスタメンテ＞医療機関",
    key_choices: [
      { text: "ID", value: 1 },
      { text: "医療機関名", value: 2 }
    ],
    need_office_select: false,
    sort_no: 40,
    is_med_transmission: true
  },
  {
    sheet_id: 4,
    sheet_name: "＜マスタメンテ＞医師",
    key_choices: [
      { text: "ID", value: 1 },
      { text: "医療機関名+医師名", value: 2 }
    ],
    need_office_select: false,
    sort_no: 50,
    is_med_transmission: true
  },
  {
    sheet_id: 5,
    sheet_name: "利用者",
    key_choices: [
      { text: "ID", value: 1 },
      { text: "利用者ID", value: 2 },
      { text: "利用者氏名", value: 3 }
    ],
    need_office_select: true,
    sort_no: 60,
    is_med_transmission: false
  },
  {
    sheet_id: 8,
    sheet_name: "＜利用者＞医療保険",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 70,
    is_med_transmission: true
  },
  {
    sheet_id: 6,
    sheet_name: "＜利用者＞高齢受給者証",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 80,
    is_med_transmission: false
  },
  {
    sheet_id: 9,
    sheet_name: "＜利用者＞高額療養費",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 90,
    is_med_transmission: false
  },
  {
    sheet_id: 7,
    sheet_name: "＜利用者＞介護保険",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 100,
    is_med_transmission: false
  },
  {
    sheet_id: 11,
    sheet_name: "＜利用者＞負担割合証",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 110,
    is_med_transmission: false
  },
  {
    sheet_id: 12,
    sheet_name: "＜利用者＞公費",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 120,
    is_med_transmission: false
  },
  {
    sheet_id: 10,
    sheet_name: "＜利用者＞指示書",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 130,
    is_med_transmission: false
  },
  {
    sheet_id: 14,
    sheet_name: "＜利用者＞計画書(衛生材料等)",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 140,
    is_med_transmission: false
  },
  {
    sheet_id: 15,
    sheet_name: "＜利用者＞計画書(長期目標・短期目標)",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 150,
    is_med_transmission: false
  },
  {
    sheet_id: 16,
    sheet_name: "＜利用者＞計画書(療養上の課題・支援内容・評価)",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 160,
    is_med_transmission: false
  },
  {
    sheet_id: 13,
    sheet_name: "＜利用者＞報告書",
    key_choices: [{ text: "ID", value: 1 }],
    need_office_select: false,
    sort_no: 170,
    is_med_transmission: false
  }
];

/** ファイル情報 */
export interface FileIF {
  /** ID */
  id: number;
  /** ファイルパス */
  path: string;
}

/** 処理選択肢 */
export const PROCCESS_TYPES: Choice[] = [
  { text: "新規追加", value: 1 },
  { text: "更新", value: 2 }
];

/** 拡張Choice */
export interface ExChoice extends Choice {
  /** 表示順 */
  sort_no: number;
}
