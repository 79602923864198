






























import { Component, Emit, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import {
  CalendarDisplay,
  DefaultCalendarDisplay
} from "@/components/patient/calendar/types";
import { COLLECTION_PATIENT_CALENDAR_DISPLAY } from "@/const/envFireStore";
import { EventType } from "#/model/schedule/calendarType";

@Component
export default class LayerDialog extends Mixins(UtilMixin, FireStoreMixin) {
  /** コレクション名 */
  private collection_name = COLLECTION_PATIENT_CALENDAR_DISPLAY;

  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** 表示設定選択状態 */
  private documentSelectedLayers = DefaultCalendarDisplay();

  /** 選択肢 */
  public selectLayers = [
    {
      id: 1,
      label: "特別訪問看護指示書（精神科特別訪問看護指示書を含む）",
      value: EventType.DirectionSpecial,
      selected: false,
      color: ""
    },
    {
      id: 2,
      label: "訪問看護指示書（精神科訪問看護指示書を含む）",
      value: EventType.DirectionNormal,
      selected: false,
      color: ""
    },
    {
      id: 3,
      label: "介護保険",
      value: EventType.Care,
      selected: false,
      color: ""
    },
    {
      id: 4,
      label: "負担割合証",
      value: EventType.BurdenRatio,
      selected: false,
      color: ""
    },
    {
      id: 5,
      label: "医療保険",
      value: EventType.Medical,
      selected: false,
      color: ""
    },
    {
      id: 6,
      label: "高額療養費",
      value: EventType.HighExpense,
      selected: false,
      color: ""
    },
    {
      id: 7,
      label: "高齢受給者証",
      value: EventType.OldAge,
      selected: false,
      color: ""
    },
    {
      id: 8,
      label: "公費",
      value: EventType.PublicMoney,
      selected: false,
      color: ""
    }
  ];

  /** データ絞り込み */
  @Emit() private filter() {
    this.isOpen = false;
    if (this.collection_name !== "") {
      this.documentSelectedLayers = DefaultCalendarDisplay();
      this.documentSelectedLayers.selectLayers = this.selectLayers
        .filter(layer => {
          return layer.selected;
        })
        .map(layer => {
          return layer.value;
        });
      this.documentSave(this.documentSelectedLayers);
    }
    return this.selectLayers;
  }

  async created() {
    this.collection = this.collection_name;
    this.documentId = String(this.loginUser.id);
    const calendarDisp = (await this.documentGet()) as CalendarDisplay;
    if (calendarDisp) {
      this.documentSelectedLayers = this.deepCopy(calendarDisp);
      this.documentSelectedLayers.selectLayers.forEach(select => {
        this.selectLayers
          .filter(layer => {
            return layer.value === select;
          })
          .forEach(layer => {
            layer.selected = true;
          });
      });
    } else if (calendarDisp === undefined) {
      this.selectALl();
    } else if (calendarDisp === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
    this.filter();
  }

  /** ダイアログオープン */
  public open() {
    this.isOpen = true;
  }

  /** 全て選択 */
  private selectALl() {
    this.selectLayers.forEach(layer => {
      layer.selected = true;
    });
  }

  /** 全てクリア */
  private clearAll() {
    this.selectLayers.forEach(layer => {
      layer.selected = false;
    });
  }
}
