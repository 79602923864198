import Base from "../base";

// 共有ファイルタグ
export interface OfficeSharedFileTag extends Base {
  id: number;
  agreement_id: number; //'契約ID',
  office_id: number; //事業所ID
  office_shared_file_id: number; // 共有ファイルID
  tag: string; // タグ
}

export const DefaultOfficeShareFileTag = (): OfficeSharedFileTag => ({
  id: 0,
  agreement_id: 0,
  office_id: 0,
  office_shared_file_id: 0,
  tag: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
