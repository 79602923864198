import { ApplyStaff } from "#/model/common";

export interface InfoItems {
  id: number;
  date: string;
  updated_at: string;
  title: string;
  content: string;
  icon: string;
  staff_name: string;
  notice_count: number;
  auth_div: number;
  board_div: number;
  bulletin_board: {
    created_at_wareki: string;
    bulletin_board_comments: {
      id: number;
      bulletin_board_id: number;
      editor_staff_id: number;
      comment: string;
      created_at: string;
      updated_at: string;
      deleted_at: string;
      updated_at_wareki: string;
      editor_staff: ApplyStaff;
    }[];
    unread_comment_count: number;
    is_reading: number;
  };
  comment_items: {
    id: number;
    staff_name: string;
    is_own: number;
    edit: boolean;
    comment: string;
    updated_at_wareki: string;
    updated_at: string;
    created_at: string;
  }[];
}

export const defaultInfoItems = (): InfoItems => ({
  id: 0,
  date: "",
  updated_at: "",
  title: "",
  content: "",
  icon: "",
  staff_name: "",
  notice_count: 0,
  auth_div: 0,
  board_div: 0,
  bulletin_board: {
    created_at_wareki: "",
    bulletin_board_comments: [],
    unread_comment_count: 0,
    is_reading: 0
  },
  comment_items: []
});

// Firestore
export interface BbsSetting {
  opens_new_tab: boolean;
}
