























































import { Prop, Component, Emit } from "vue-property-decorator";
import {
  OfficeHistoryMail,
  DefaultOfficeHistoryMail
} from "#/model/agreement/officeHistoryMail";
import RulesMixin from "#/mixins/rulesMixin";

@Component
export default class NoticeTarget extends RulesMixin {
  @Prop({ default: DefaultOfficeHistoryMail }) historyMail!: OfficeHistoryMail;

  @Emit()
  private removeMail() {
    return;
  }
}
