
































































































































































































































































































































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import MasmenMixin from "@/mixins/masmenMixin";
import RulesMixin from "#/mixins/rulesMixin";
import InviteChip from "@/components/master/staff/InviteChip.vue";
import CompleteChip from "@/components/master/staff/CompleteChip.vue";
import ChangeLink from "@/components/master/staff/ChangeLink.vue";
import {
  Staff,
  DefaultStaff,
  EwellUserInfo,
  StaffCertification
} from "#/model/staff";
import { Choice, VForm } from "@/types";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import EditMixin from "@/mixins/editMixin";
import LinkStaffMixin from "@/mixins/linkStaffMixin";
import { AGREEMENT, OFFICE_STAFF } from "#/const";
import { BreadItem } from "@/store/modules/masmen/types";
import PractitionerTable from "@/components/master/staff/PractitionerTable.vue";
import SelectObject from "#/components/SelectObject.vue";
import { CERTIFICATION } from "#/const/certification";

@Component({
  components: {
    InviteChip,
    CompleteChip,
    OfficeSelect,
    ChangeLink,
    PractitionerTable,
    SelectObject
  }
})
export default class StaffEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  MasmenMixin,
  RulesMixin,
  EditMixin,
  LinkStaffMixin
) {
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;
  /** 事務所事務員権限 */
  private STAFF_CLERK = OFFICE_STAFF.AUTH.CLERK;
  /** 事務所所長権限 */
  private STAFF_CHIEF = OFFICE_STAFF.AUTH.CHIEF;
  /** 本社職員権限 */
  private STAFF_HOME = OFFICE_STAFF.AUTH.HOME;
  /** システム管理者権限 */
  private STAFF_ADMIN = OFFICE_STAFF.AUTH.ADMIN;

  @Ref("form") private readonly form!: VForm;

  /** 主たる業務 */
  private mainBusinesses = [
    { text: "保健師業務", value: 1 },
    { text: "助産師業務", value: 2 },
    { text: "看護師業務", value: 3 }
  ];

  /** 雇用形態 */
  private employmentStatuses = [
    { text: "正規雇用", value: 1 },
    { text: "非正規雇用", value: 2 },
    { text: "派遣", value: 3 }
  ];
  private statusValue = 0;

  /** 常勤換算 */
  private fulltimeEquivalents = [
    { text: "フルタイム労働者", value: 1 },
    { text: "短時間労働者", value: 2 }
  ];

  /** 短時間労働者 */
  private shortTimes = [
    { text: "0.1", value: 0.1 },
    { text: "0.2", value: 0.2 },
    { text: "0.3", value: 0.3 },
    { text: "0.4", value: 0.4 },
    { text: "0.5", value: 0.5 },
    { text: "0.6", value: 0.6 },
    { text: "0.7", value: 0.7 },
    { text: "0.8", value: 0.8 },
    { text: "0.9", value: 0.9 }
  ];

  private fulltimeValue = 0;

  /** 従事期間 */
  private engagementPeriod = [
    {
      text: "1年未満",
      value: 1
    },
    {
      text: "1年以上2年未満",
      value: 2
    },
    { text: "2年以上", value: 3 }
  ];
  private engagementPeriodValues = [
    { text: "新規", value: 1 },
    { text: "再就業", value: 2 },
    { text: "転職", value: 3 },
    { text: "その他", value: 4 }
  ];

  /** 職員ID */
  private staff_id = 0;

  /** 契約ID */
  private agreementId = 0;

  /** 企業コード */
  private co_code = "";

  /** 職員 */
  private staff: Staff = DefaultStaff();

  /** 職員 */
  private staffBeforeEdit: Staff = DefaultStaff();

  /** 招待内容 */
  private ibow_send_info = "";

  /** 職員権限リスト */
  private auths: Choice[] = [];

  /** 職種権限リスト */
  private staff_divs: Choice[] = [];

  /** 新しいパスワード（未使用） */
  private newPassword = "";

  /** 招待フラグ */
  private isInvite = false;

  /** 他システムのキーがあるかどうか */
  private isKey = false;

  /** パンくず用 職員名(全角)・ID(半角) */
  private keyword = "";

  /** ダイアログ展開状態 */
  private changeLinkDialogIsOpen = false;

  /** パンくずリスト */
  private breadItems: BreadItem[] = [
    {
      text: "職員",
      disabled: false,
      to: "/master/office/staff" + this.keyword,
      exact: true
    },
    {
      text: "職員情報登録",
      disabled: true,
      to: ""
    }
  ];

  private ewellUserInfo: EwellUserInfo[] = [];

  /** 連携ダイアログ openイベント */
  private clickOpenDialog() {
    this.changeLinkDialogIsOpen = true;
  }

  /** 連携ダイアログ closeイベント */
  private closeDialog() {
    this.changeLinkDialogIsOpen = false;
  }

  /**
   * getter
   */

  /** 他システム連携情報 */
  private get OtherSystemCoopInfo(): string {
    // ibow_user_id / coop_idの形で表示
    // どちらか片方ならスラッシュなし
    const infos: string[] = [];

    if (this.staff.key.ibow_user_id != "") {
      infos.push(this.staff.key.ibow_user_id);
    }
    if (this.staff.key.coop_id != "") {
      infos.push(this.staff.key.coop_id);
    }

    return infos.join("/");
  }

  /** ログインページURL（未使用） */
  private get LoginUrl() {
    return location.protocol + "//" + location.host + "/login/" + this.co_code;
  }

  // 削除ボタン表示フラグ
  private get showDelete(): boolean {
    return this.availableProcessing(
      AGREEMENT.SETTING_ID_STAFF,
      AGREEMENT.FUNCTION_DIV_DELETE
    );
  }

  created(): void {
    this.staff_id = Number(this.$route.params.id);
    if (typeof this.$route.query.keyword === "string") {
      this.keyword = this.$route.query.keyword;
    }
    if (this.staff_id !== 0) {
      this.breadItems = [
        {
          text: "職員",
          disabled: false,
          to: "/master/office/staff" + this.keyword,
          exact: true
        },
        {
          text: "職員情報編集",
          disabled: true,
          to: ""
        }
      ];
    }
    this.updateData();
  }

  //データ更新
  private updateData() {
    this.postJsonCheck(
      window.base_url + "/api/master/staff/get",
      { id: this.staff_id },
      res => {
        this.staff = res.data.staff;
        if (!this.staff_id) {
          this.staff.info.office_id = this.loginUser.office_id;
        }
        this.auths = res.data.auths;
        this.staff_divs = res.data.staff_divs;
        this.isKey = res.data.is_key;
        this.inviteType = res.data.invite_type;
        this.ibow_send_info = res.data.ibow_send_info;
        if (this.ibow_send_info) {
          this.isInvite = true;
        }
        this.co_code = res.data.co_code;
        this.form.resetValidation();
        this.setLoaded();

        this.staffBeforeEdit = this.deepCopy(this.staff);
        this.ewellUserInfo = res.data.ewell_user_info;
      }
    );
  }

  //招待情報チェック
  private checkStaff() {
    if (this.ibow_send_info && this.checkIbowSendInfo !== this.ibow_send_info) {
      if (this.ruleInvite(this.ibow_send_info) !== true) {
        return;
      }
      this.checkIbowSendInfo = this.ibow_send_info;
      this.postJsonCheck(
        window.base_url + "/api/master/staff/check",
        {
          staff: this.staff,
          ibow_send_info: this.ibow_send_info,
          ibow_send_type: this.inviteType,
          check_type: 0
        },
        async res => {
          if (
            res.data.message &&
            !(await this.$openConfirm(res.data.message))
          ) {
            this.$router.go(-1);
          }
        }
      );
    }
  }

  //招待
  private async clickCreate(isInvite: boolean, isUpdate: boolean) {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (isInvite) {
      switch (this.inviteType) {
        case this.INVITE_ID:
          if (
            !(await this.$openConfirm(
              "iBowアカウントに登録済みの情報で招待メールまたは招待SMSを送ります。よろしいですか？"
            ))
          ) {
            return;
          }
          break;
        case this.INVITE_MAIL:
          if (this.ibow_send_info.indexOf("@") < 0) {
            await this.$openAlert("招待用メールアドレスの形式が不正です。");
            return;
          }
          if (
            !(await this.$openConfirm(
              "招待メールを送信します。よろしいですか？"
            ))
          ) {
            return;
          }
          break;
        case this.INVITE_PHONE:
          if (
            !this.ibow_send_info.match(
              /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/
            )
          ) {
            await this.$openAlert("招待用携帯電話番号の形式が不正です。");
            return;
          }
          if (
            !(await this.$openConfirm(
              "招待SMS（ショートメッセージ）を送信します。よろしいですか？"
            ))
          ) {
            return;
          }
          break;
        case this.INVITE_NO:
          if (
            !(await this.$openConfirm(
              "未招待で職員を作成します。よろしいですか？"
            ))
          ) {
            return;
          }
          isInvite = false;
      }
    } else {
      if (!(await this.$openConfirm("保存します。よろしいですか？"))) {
        return;
      }
    }

    if (this.newPassword) {
      this.staff.key.ibow_password = this.newPassword;
      this.staff.is_exists_key = 1;
    } else {
      this.staff.is_exists_key = 0;
    }

    if (this.isKey) {
      this.isKey = true;
    } else {
      this.isKey = false;
    }

    // 権限依存の設定は、他の権限ならリセットする
    if (
      this.staff.info.auth_id != this.STAFF_HOME &&
      this.staff.info.auth_id != this.STAFF_ADMIN
    ) {
      this.staff.info.is_check_agreement = 0;
    }
    if (this.staff.info.auth_id === this.STAFF_COMMON) {
      // 一般職員はマスタメンテ全般を見られないが、念の為オペレーションログ閲覧可能フラグをOFFに
      this.staff.info.is_show_opelog = 0;
    } else if (this.staff.info.auth_id !== this.STAFF_CLERK) {
      // 事務所所長以上は、必ず見られるように
      this.staff.info.is_show_opelog = 1;
    }

    if (isInvite) {
      this.postJsonCheck(
        window.base_url + "/api/master/staff/check",
        {
          staff: this.staff,
          ibow_send_info: this.ibow_send_info,
          ibow_send_type: this.inviteType,
          check_type: 1
        },
        async res => {
          if (
            res.data.message &&
            !(await this.$openConfirm(res.data.message))
          ) {
            return;
          }
          this.saveStaff(isInvite, isUpdate);
        }
      );
    } else {
      this.saveStaff(isInvite, isUpdate);
    }
  }

  //保存
  private saveStaff(isInvite: boolean, isUpdate: boolean) {
    this.postJsonCheck(
      window.base_url + "/api/master/staff/create",
      {
        staff: this.staff,
        ibow_send_info: this.ibow_send_info,
        ibow_send_type: this.inviteType,
        is_key: this.isKey,
        is_invite: isInvite,
        is_update: isUpdate
      },
      () => {
        this.setNoEditMode();
        if (this.loginUser.id === this.staff.id) {
          // 自身の情報を変更した場合は、マスタを再取得
          this.GetMaster().then(() => {
            this.goBack();
          });
        } else {
          this.goBack();
        }
      }
    );
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.postJsonCheck(
        window.base_url + "/api/master/staff/delete",
        { staff: this.staff },
        () => {
          this.setNoEditMode();
          this.goBack();
        }
      );
    }
  }

  //連携解除
  private async clickRelease() {
    if (
      await this.$openConfirm(
        "iBowアカウントとの連携を解除します。よろしいですか？"
      )
    ) {
      this.postJsonCheck(
        window.base_url + "/api/master/staff/release",
        { staff: this.staff },
        () => {
          this.setNoEditMode();
          this.goBack();
        }
      );
    }
  }

  //ブラウザバック
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "StaffList" });
    }
  }

  //事業所選択ルール
  private ruleOfficeId(message: string): boolean | string {
    let ok = true;
    if (this.staff.info.auth_id < this.STAFF_HOME) {
      if (this.staff.info.office_id == 0) {
        ok = false;
      }
    }
    return ok || message;
  }

  //パスワード更新ルール
  private rulePassword(message: string): boolean | string {
    let ok = true;
    if (this.staff.key.id == 0 && this.newPassword == "") {
      ok = false;
    }
    return ok || message;
  }

  /** 権限変更時 */
  private onChangeAuthId(authId: number, before: number) {
    if (authId === this.STAFF_CLERK) {
      // 新規作成画面では内部的にOFF状態なので、事務所事務員を選んだ時デフォルトONにする
      this.staff.info.is_show_opelog = 1;
    }
  }

  //編集モード設定
  @Watch("staff", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  private checkMainBusiness() {
    // 主たる業務の対象の資格区分
    const c = this.staff.staff_certifications.filter(cert =>
      this.isMainBusiness(cert)
    );

    if (c.length == 1) {
      switch (c[0].certification_id) {
        case CERTIFICATION.REGISTERED_NURSE:
          this.staff.info.main_business = 3;
          break;
        case CERTIFICATION.PUBLIC_HEALTH_NURSE:
          this.staff.info.main_business = 1;
          break;
        case CERTIFICATION.MIDWIFE:
          this.staff.info.main_business = 2;
          break;
        default:
          this.staff.info.main_business = 0;
          break;
      }
    }

    // 主たる業務の対象の資格区分を２つ以上選択している場合 ture
    console.log(c.length);
    return c.length < 2;
  }

  private isMainBusiness(cert: StaffCertification): boolean {
    return (
      (cert.certification_id === CERTIFICATION.REGISTERED_NURSE ||
        cert.certification_id === CERTIFICATION.PRACTICAL_NURSE ||
        cert.certification_id === CERTIFICATION.MIDWIFE ||
        cert.certification_id === CERTIFICATION.PUBLIC_HEALTH_NURSE) &&
      cert.is_check === 1
    );
  }

  private changeEngagementPeriod() {
    if (this.staff.info.engagement_period === 3) {
      this.staff.info.engagement_period_value = 0;
    }
  }

  private changeFulltimeEquivalent() {
    if (this.staff.info.fulltime_equivalent === 1) {
      this.staff.info.fulltime_value = 0;
    }
  }
}
