



















import { Component, Mixins, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import AxiosMixin from "@/mixins/axiosMixin";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import MedicineTakingDialog from "@/components/master/medicinetaking/MedicineTakingDialog.vue";

interface Item {
  id: number;
  name: string;
}

@Component({
  components: {
    CommonMasterList,
    MedicineTakingDialog
  }
})
export default class MedicineTakingList extends Mixins(AxiosMixin) {
  @Ref("edit-dialog") private readonly editDialog!: MedicineTakingDialog;

  private headers: DataTableHeader[] = [
    {
      text: "表示名称",
      value: "name",
      align: "start",
      width: ""
    },
    { text: "", value: "actions", align: "end", width: "" }
  ];

  private items: Item[] = [];

  public created(): void {
    this.fetchMedicines();
  }

  private fetchMedicines(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/medicine/get",
      {},
      res => {
        this.items = res.data.medicines;
      }
    );
  }

  private newItem() {
    this.editDialog.openNew();
  }

  private editItem(item: Item) {
    this.editDialog.openEdit(item);
  }

  private rowDelete(item: Item) {
    this.postJsonCheck(
      window.base_url + "/api/master/medicine/delete",
      {
        medicine: item
      },
      () => {
        this.fetchMedicines();
      }
    );
  }
}
