import { render, staticRenderFns } from "./Report.vue?vue&type=template&id=6835aaf8&scoped=true&"
import script from "./Report.vue?vue&type=script&lang=ts&"
export * from "./Report.vue?vue&type=script&lang=ts&"
import style0 from "./Report.vue?vue&type=style&index=0&id=6835aaf8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6835aaf8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VIcon,VRadioGroup,VRow,VSpacer})
