































































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { SameBuilding } from "#/model/uniquemaster";

@Component
export default class FacilityList extends Mixins(AxiosMixin) {
  private headers = [
    {
      text: "建物名",
      value: "name",
      align: "start"
    },
    {
      text: "",
      value: "actions",
      align: "end",
      sortable: false
    }
  ];

  private search = "";

  private itemPerPage = 25;

  private pageNo = 1;

  private buildings: SameBuilding[] = [];

  public created() {
    this.fetchSameBuildings();
  }

  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  private edit(item: SameBuilding) {
    this.$router.push({
      name: "FacilityEdit",
      params: { id: item.id + "" }
    });
  }

  private fetchSameBuildings(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/same_buildings/get",
      {},
      res => {
        this.buildings = res.data.same_buildings;
      }
    );
  }
}
