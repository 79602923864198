import { Choice } from "@/types";

export const TimeCares = [
  { value: 0, text: "未選択" },
  { value: 1, text: "夜間訪問看護　（医療 / 介護）※18時～22時" },
  { value: 2, text: "早朝訪問看護　（医療 / 介護）※6時～8時" },
  { value: 3, text: "深夜訪問看護　（医療 / 介護）※22時～6時" }
] as Choice[];

// 訪問記録提出チェックレスポンスコード
export const CHECK_CODE: {
  [key in number]: {
    message: string; // メッセージ
    type: number; // 0:alert 1:confirm(キャンセルで処理中断) 2:confirm(キャンセルで処理中断しない)
  };
} = {
  1: {
    message:
      "24時間対応体制と過去1ヶ月以内の訪問がない状態、または別表7・別表8・特別指示がない状態で、同一緊急として加算が選択されています。",
    type: 0
  },
  2: {
    message:
      "主訪問者が同日、同時間帯で訪問看護記録IIが作成されています。このまま提出してもよろしいですか？",
    type: 1
  },
  3: {
    message: "既に外泊登録されていますが、登録を続行しますか？",
    type: 1
  },
  4: {
    message:
      "30分未満の訪問では複数名精神科訪問看護加算は取得できませんがこのまま提出しますか？",
    type: 1
  },
  5: {
    message:
      "精神訪問で主訪問者がOTの場合、複数名精神科訪問看護加算は取得できませんがこのまま提出しますか？",
    type: 1
  },
  6: {
    message: "夜間加算が選択されていませんが選択されますか？",
    type: 2
  },
  7: {
    message: "現在の条件では夜間加算を算定できません。選択を解除しますか？",
    type: 2
  },
  8: {
    message: "深夜加算が選択されていませんが選択されますか？",
    type: 2
  },
  9: {
    message: "現在の条件では深夜加算を算定できません。選択を解除しますか？",
    type: 2
  },
  10: {
    message: " 早朝加算が選択されていませんが選択されますか？",
    type: 2
  },
  11: {
    message: " 現在の条件では早朝加算を算定できません。選択を解除しますか？",
    type: 2
  },
  12: {
    message: " 既に夜間加算が同日に取得されていますが解除されますか？",
    type: 2
  },
  13: {
    message: " 既に深夜加算が同日に取得されていますが解除されますか？",
    type: 2
  },
  14: {
    message: "既に早朝加算が同日に取得されていますが解除されますか？",
    type: 2
  },
  15: {
    message: "既に夜間早朝加算が同日に選択されていますが解除しますか？",
    type: 2
  },
  16: {
    message: "同一日同一建物減算が選択されていません。このまま提出しますか？",
    type: 1
  },
  17: {
    message:
      "既に同月に退院時共同指導加算が選択されています。同時に算定はできませんがこのまま提出しますか？",
    type: 1
  },
  18: {
    message:
      "既に同月に初回訪問加算が選択されています。同時に算定はできませんがこのまま提出しますか？",
    type: 1
  }
};
