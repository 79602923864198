






























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AppIcon from "./AppIcon.vue";

@Component({
  components: {
    AppIcon
  }
})
export default class AppSectionTitle extends Vue {
  @Prop({ default: "" }) text!: string;
  @Prop({ default: -1 }) count!: number; // 通知件数(-1でバッジを非表示)
  @Prop() linkTo?: string; // リンク先のRouter設定名
  @Prop() linkText?: string; // リンクテキスト
}
