





































































































































































































































import { Component } from "vue-property-decorator";
import OneMonthAddV3 from "./OneMonthAddV3.vue";

@Component
export default class OneMonthAddV4 extends OneMonthAddV3 {
  /** 情報提供先の算定方法についての説明用ダイアログ */
  protected isOpenInfoExpenseGuide = false;

  created(): void {
    this.setHour24Div();
    this.setMedicalSpecialDivV3();
    this.setMedicalTerminalDiv();
    this.setAccompaniedStaffCnt();
  }

  //ターミナルケアと遠隔死亡診断補助
  protected disableCheckMedicalTerminalDivV4(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_terminal_care_add == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }

  //専門管理加算
  protected disableCheckSpecialManagementAddV4(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.staff_div != 1
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV4() {
    //ターミナルケアと遠隔死亡診断補助
    if (this.disableCheckMedicalTerminalDivV4()) {
      this.medical_terminal_div1 = false;
      this.medical_terminal_div2 = false;
      this.receiptVisitRecord.medical_terminal_div = 0;
      this.receiptVisitRecord.remote_death_assist = 0;
    }
    //特別管理指導
    if (this.disableCheckSpecialInstFlg()) {
      this.receiptVisitRecord.special_inst_flg = 0;
    }
    //24時間体制
    if (this.disableCheckMedicalHour24Add()) {
      this.hour24_div1 = false;
      this.hour24_div2 = false;
      this.receiptVisitRecord.hour24_div = 0;
    }
    //特別管理加算
    if (
      this.disableCheckMedicalSpecialDiv1() &&
      this.disableCheckMedicalSpecialDiv2()
    ) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = false;
      this.receiptVisitRecord.medical_special_div = 0;
    }
    //特別管理加算回数
    if (this.disableCheckMedicalSpecialDivCount()) {
      this.receiptVisitRecord.medical_special_div_count = 0;
    }
    //在宅患者連携指導
    if (this.disableCheckHomeInstFlg()) {
      this.receiptVisitRecord.home_inst_flg = 0;
    }
    //精神科重症患者支援管理連携加算
    if (this.disableCheckMentalSupportDivV2()) {
      this.mental_support_div1 = false;
      this.mental_support_div2 = false;
      this.receiptVisitRecord.mental_support_div = 0;
    }
    //情報提供療養費
    if (this.disableCheckInfoExpensesFlgV2()) {
      this.receiptVisitRecord.info_expenses_flg1 = 0;
      this.receiptVisitRecord.info_expenses_flg2 = 0;
      this.receiptVisitRecord.info_expenses_flg3 = 0;
    }
    //情報提供療養費（II）回数
    if (this.disableCheckInfoExpenses2Count()) {
      this.receiptVisitRecord.info_expenses2_count = 0;
    }
    //看護・介護職員連携
    if (this.disableCheckNursingCareFlg()) {
      this.receiptVisitRecord.nursing_care_flg = 0;
    }
    //看護・介護職員連携日
    if (this.disableCheckNursingCareDate()) {
      this.receiptVisitRecord.nursing_care_date = "";
    }
    //専門管理加算
    if (this.disableCheckSpecialManagementAddV4()) {
      this.receiptVisitRecord.special_management_add = 0;
    }
  }
}
