import Base from "../base";

/* 事業所履歴 訪問超過減算 対象加算 */
export interface OfficeHistoryOverTherapistTargetAddition extends Base {
  id: number; //ID
  office_history_id: number; //事業所履歴ID
  fiscal_year: number; //年度
  yearmonth: number; //年月
  is_uncalculated: number; //未計算フラグ
}

export const DefaultOfficeHistoryOverTherapistTargetAddition = (): OfficeHistoryOverTherapistTargetAddition => ({
  id: 0,
  office_history_id: 0,
  fiscal_year: 0,
  yearmonth: 0,
  is_uncalculated: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

// 翌月の減算設定
export interface NextMonthGensanSetting {
  yearmonth: number;             // 年月
  yearmonthText: string;         // 年月(表示用)
  estimatedValue: number         // 推定
  estimatedValueText: string     // 推定(表示用)
  conventionalValue: number      // 現在
  conventionalValueText: string  // 現在(表示用)
}

export const DefaultNextMonthGensanSetting = (): NextMonthGensanSetting => ({
  yearmonth: 0,
  yearmonthText: "",
  estimatedValue: 0,
  estimatedValueText: "",
  conventionalValue: 0,
  conventionalValueText: "",
});
