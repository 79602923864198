












import { Component, Prop, Vue } from "vue-property-decorator";
import { Adl } from "@/components/patient/provisionreport/types";
import { Choice } from "@/types";

interface AdlItem {
  id: number;
  title: string;
  items: Choice[];
}
@Component
export default class ProvisionCreate extends Vue {
  @Prop() adl_item!: AdlItem;
  @Prop() adls!: Adl[];

  private get adl(): Adl {
    const adlItem = this.adls.find(
      adl_item => adl_item.provision_report_adl_item_id == this.adl_item.id
    );
    if (adlItem) {
      return adlItem;
    }
    const newAdlItem: Adl = {
      id: 0,
      provision_report_id: 0,
      provision_report_adl_item_id: this.adl_item.id,
      provision_report_adl_select_item_id: 0
    };
    this.adls.push(newAdlItem);
    return newAdlItem;
  }
}
