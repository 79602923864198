








































































import { Component, Watch } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class OneMonthAdd extends CarePartBase {
  created(): void {
    this.setNursingSpecialDiv();
  }

  //特別管理
  protected setNursingSpecialDiv() {
    if (this.receiptVisitRecord.nursing_special_div == 1) {
      this.nursing_special_div1 = true;
      this.nursing_special_div2 = false;
    }
    if (this.receiptVisitRecord.nursing_special_div == 2) {
      this.nursing_special_div1 = false;
      this.nursing_special_div2 = true;
    }
  }

  /** 特別管理I */
  protected nursing_special_div1 = false;

  /** 特別管理II */
  protected nursing_special_div2 = false;

  //連携強化日:日付チェック（当月しか入力不可）
  protected chackCoopStrengtheningDate(): boolean | string {
    //当月でない場合
    if (
      this.receiptVisitRecord.coop_strengthening_date != "" &&
      this.yearmonth !=
        this.receiptVisitRecord.coop_strengthening_date.substr(0, 7) &&
      !this.disableCheckCoopStrengtheningDate()
    ) {
      return "対象年月のみ入力できます";
    }
    return true;
  }

  //特別管理I
  @Watch("nursing_special_div1")
  protected handleNursingSpecialDiv1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.nursing_special_div1 == true) {
      this.receiptVisitRecord.nursing_special_div = 1;
      this.nursing_special_div2 = false;
    }
    if (
      this.nursing_special_div1 == false &&
      this.nursing_special_div2 == false
    ) {
      this.receiptVisitRecord.nursing_special_div = 0;
    }
  }

  //特別管理II
  @Watch("nursing_special_div2")
  protected handleNursingSpecialDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.nursing_special_div2 == true) {
      this.receiptVisitRecord.nursing_special_div = 2;
      this.nursing_special_div1 = false;
    }
    if (
      this.nursing_special_div1 == false &&
      this.nursing_special_div2 == false
    ) {
      this.receiptVisitRecord.nursing_special_div = 0;
    }
  }

  //緊急時訪問看護
  protected disableCheckNursingUrgentFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.care_kinkyu_houmon_add == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //特別管理
  protected disableCheckNursingSpecialDiv(): boolean {
    let result = false;
    if (
      this.officeHistory.care_tokubetsu_kanri_add == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //ターミナルケア
  protected disableCheckNursingTerminalFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.care_terminal_care_add == 0 ||
      this.event.visit_record.care_div == 2 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //中山間部
  protected disableCheckMountainFlg(): boolean {
    let result = false;
    if (
      (this.officeHistory.care_small_office_area_add == 0 &&
        (this.officeHistory.care_small_office_scale_add == 0 ||
          (this.officeHistory.care_small_office_scale_add == 2 &&
            this.event.visit_record.care_div == 1) ||
          (this.officeHistory.care_small_office_scale_add == 3 &&
            this.event.visit_record.care_div == 2))) ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //連携強化
  protected disableCheckCoopStrengtheningFlg(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.event.visit_record.care_div == 2
    ) {
      result = true;
    }
    return result;
  }

  //連携強化日
  protected disableCheckCoopStrengtheningDate(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.coop_strengthening_flg == 0 ||
      this.event.visit_record.care_div == 2 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //看護体制強化
  protected disableCheckCareKyokaFlg(): boolean {
    let result = false;
    if (this.officeHistory.care_kyoka_add == 0) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //緊急時訪問看護
    if (this.disableCheckNursingUrgentFlg()) {
      this.receiptVisitRecord.nursing_urgent_flg = 0;
    }
    //特別管理
    if (this.disableCheckNursingSpecialDiv()) {
      this.nursing_special_div1 = false;
      this.nursing_special_div2 = false;
      this.receiptVisitRecord.nursing_special_div = 0;
    }
    //ターミナルケア
    if (this.disableCheckNursingTerminalFlg()) {
      this.receiptVisitRecord.nursing_terminal_flg = 0;
    }
    //中山間部
    if (this.disableCheckMountainFlg()) {
      this.receiptVisitRecord.mountain_flg = 0;
    }
    //連携強化
    if (this.disableCheckCoopStrengtheningFlg()) {
      this.receiptVisitRecord.coop_strengthening_flg = 0;
    }
    //連携強化日
    if (this.disableCheckCoopStrengtheningDate()) {
      this.receiptVisitRecord.coop_strengthening_date = "";
    }
    //看護体制強化
    if (this.disableCheckCareKyokaFlg()) {
      this.receiptVisitRecord.care_kyoka_flg = 0;
    }
  }
}
