

















































































import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { AmountInfo } from "#/model/receipt";

@Component
export default class SolutionDialogItem extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Prop({ default: () => ({}) }) amountInfo!: AmountInfo;

  private get highMedicalExpensesNote() {
    const note = this.amountInfo.high_medical_expenses_note;
    if (note != null && note != "") {
      return `（${note}）`;
    }
    return "";
  }
}
