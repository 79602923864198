

















import { Component } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class OneOrThreeWeekAdd extends MedPartBase {
  //週加算タイトル
  protected get titleWeekAddition(): string {
    let title = "週1回加算";
    if (
      this.event.indicate.longtime_beppyo_child_div == 1 ||
      this.event.indicate.longtime_severe_child_div == 1
    ) {
      title = "週3回加算";
    }
    return title;
  }

  //長時間
  protected disableCheckLongTimeFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //長時間
  protected chackLongTimeFlg(): boolean | string {
    //90分未満の訪問は長時間加算できない
    const start = new Date(
      this.event.visit_record.service_start_datetime.replace(/-/g, "/")
    );
    const end = new Date(
      this.event.visit_record.service_end_datetime.replace(/-/g, "/")
    );
    const diff = Math.abs((end.getTime() - start.getTime()) / (60 * 1000));
    if (
      this.receiptVisitRecord.long_time_flg == 1 &&
      diff < 91 &&
      !this.disableCheckLongTimeFlg()
    ) {
      return "訪問時間が長時間訪問看護に該当されません";
    }
    return true;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //長時間
    if (this.disableCheckLongTimeFlg()) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }
}
