import Base from "../base";

//指示書別表7病名
export interface IndicateBeppyo7Disease extends Base {
  id: number; //ID
  indicate_id: number; //'指示書ID';
  beppyo7_disease_id: number; //'別表7疾病ID';
  beppyo7_disease_code: string; //'別表7疾病名コード';
  beppyo7_disease_name: string; //'別表7疾病名',
}

export const DefaultIndicateBeppyo7Disease = (): IndicateBeppyo7Disease => ({
  id: 0,
  indicate_id: 0,
  beppyo7_disease_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  beppyo7_disease_code: "",
  beppyo7_disease_name: ""
});
