





































































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Fulltime, DefaultFulltime } from "#/model/fulltime";
import EditMixin from "@/mixins/editMixin";
import { DefaultPatientLinkCondition, VForm } from "@/types";
import PatientMixin from "@/mixins/patientMixin";

@Component({
  components: {
    PatientHeader
  }
})
export default class Hour24Regist extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private fulltimeId = 0;

  private patientId = 0;

  private fulltime: Fulltime = DefaultFulltime(); //24時間体制

  protected linkCondition = DefaultPatientLinkCondition(); //リンクエリア状態

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/hour24`,
        exact: true
      },
      {
        text: "24時間体制を登録する",
        disabled: true,
        to: ""
      }
    ]);
  }

  //リアルタイム今月（日にちまで）
  private today(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    const today = date.toISOString().substr(0, 10);
    return today;
  }

  //リアルタイム前月(日にちまで)
  private lastday(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setMonth(date.getMonth() - 1);
    const lastday = date.toISOString().substr(0, 10);
    return lastday;
  }

  // 利用者リンク状態取得
  protected fetchPatientLink() {
    this.postJsonCheck(
      window.base_url + "/api/patient/linkcondition",
      { patient_id: Number(this.patientId) },
      res => {
        if (res.data) {
          this.linkCondition = res.data;
        }
      }
    );
  }

  private created() {
    this.fulltimeId = Number(this.$route.params.fulltimeid);
    this.patientId = Number(this.$route.params.id);
    this.fetchPatientLink();
    this.fetchFulltime();
  }

  //キャンセル
  private cancel() {
    if (this.$route.name === "VisitHour24Regist") {
      window.close();
      return;
    }
    this.$router.go(-1);
  }

  //登録
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.fulltime.patient_id = this.patientId;

    //連絡元「その他」以外の場合連絡元名を空にする
    if (this.fulltime.messenger != 6) {
      this.fulltime.other_messenger = "";
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/fulltime/save",
      {
        fulltime: this.fulltime
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //変更
  private update() {
    this.fulltime.id = this.fulltimeId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.fulltime.id = this.fulltimeId;
      this.fulltime.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/fulltime/delete",
        {
          fulltime: this.fulltime
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  // 24時間体制情報取得
  fetchFulltime(): void {
    if (this.fulltimeId != 0) {
      this.postJsonCheck(
        window.base_url + "/api/patient/fulltime/get",
        {
          patient_ids: [this.patientId],
          fulltime_id: this.fulltimeId
        },
        res => {
          //24時間体制情報
          this.fulltime = res.data.fulltime;
          this.setLoaded();
        }
      );
    } else {
      this.setLoaded();
    }
  }
  //編集モード設定
  @Watch("fulltime", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  //-----------------------------------------------------
  // 連絡元名
  //-----------------------------------------------------
  //連絡元「その他」の場合連絡元名は必須
  private requiredOtherMessenger(): boolean | string {
    if (this.fulltime.messenger == 6 && this.fulltime.other_messenger == "") {
      return "必須です";
    }
    return true;
  }

  //-----------------------------------------------------
  // 画面上部ボタン群
  //-----------------------------------------------------
  //前3回の記録（看護）
  protected last3TimesRecordNursing() {
    this.makePdf(window.base_url + "/api/patient/visitrecord/preview/ids", {
      patient_id: this.patientId,
      visit_records: this.linkCondition.last3_nursing_records,
      visit_record_ids: this.linkCondition.last3_nursing_record_ids,
      order: "visit_start_datetime DESC"
    });
  }

  //全3回の記録（リハ）
  protected last3TimesRecordRehab() {
    this.makePdf(window.base_url + "/api/patient/visitrecord/preview/ids", {
      patient_id: this.patientId,
      visit_records: this.linkCondition.last3_rehab_records,
      visit_record_ids: this.linkCondition.last3_rehab_record_ids,
      order: "visit_start_datetime DESC"
    });
  }

  //対応履歴
  private responseHistory() {
    this.makePdf(window.base_url + "/api/fulltime/preview", {
      patient_ids: [this.patientId],
      start_date: this.lastday(),
      end_date: this.today()
    });
  }

  //経過観察
  private progress() {
    const name = "VisitProgress";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.patientId) }
    });
    window.open(resolvedRoute.href, "_blank");
  }

  //フェイスシート
  protected faceSheet() {
    this.makePdf(window.base_url + "/api/patient/facesheet/preview", {
      patient_ids: [this.patientId]
    });
  }

  //服薬管理
  private medicineTaking() {
    const name = "VisitMedicinetaking";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.patientId) }
    });
    window.open(resolvedRoute.href, "_blank");
  }
}
