var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppBreadcrumbs',{attrs:{"items":_vm.breadItems}}),_c('v-row',{staticClass:"pt-4 pb-6",attrs:{"no-gutters":""}},[_c('AppTitle',{attrs:{"label":_vm.staffName}})],1),_c('div',{staticClass:"ibow2-office-invoice"},[_c('AppDataTable',{staticClass:"elevation-0",attrs:{"headers":_vm.invoiceStaffHeaders,"items":_vm.reportDatas,"isNoTop":true,"isNoFooter":true,"disable-pagination":"","noDataText":"看護記録書Ⅱを提出していません。"},scopedSlots:_vm._u([{key:"item.patient_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient_name)+" さん ")]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.birthday(item.birthday, item.age))+" ")]}},{key:"item.visit_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateStr(item.visit_date))+" ")]}},{key:"item.submit_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateStr(item.submit_datetime))+" ")]}}],null,true)})],1),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }