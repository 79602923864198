


















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Title extends Vue {
  /** ログインユーザー名 */
  @Prop() userName!: string;

  /** 挨拶 */
  private get Greeting(): string {
    const hour = new Date().getHours();
    if (hour <= 12) {
      return "おはようございます";
    } else if (hour <= 18) {
      return "こんにちは";
    } else {
      return "こんばんは";
    }
  }
}
