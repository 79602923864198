var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"py-4",attrs:{"no-gutters":""}},[_c('AppTextField',{staticClass:"mb-1",staticStyle:{"max-width":"370px"},attrs:{"label":"検索","hide-details":"","width":"100%"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('AppIcon',{attrs:{"icon":"search"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('AppButton',{attrs:{"color":"primary","to":"/master/office/new?satelite=true"}},[_c('AppIcon',{attrs:{"icon":"add-2","fill":_vm.$vuetify.theme.themes.light.white}}),_vm._v(" サテライトを追加する ")],1)],1),_c('AppSheet',{staticClass:"py-4",attrs:{"HiddenActionArea":true}},[_c('AppDataTable',{staticClass:"elevation-0",attrs:{"hide-default-header":"","isNoTop":"true","isNoFooter":"true","item-class":_vm.itemClass,"headers":_vm.headers,"items":_vm.offices,"search":_vm.search,"sort-by":['satelite_info'],"no-data-text":"データがありませんでした。"},on:{"click:row":_vm.clickEdit},scopedSlots:_vm._u([{key:"item.office_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.office_name))])]}},{key:"item.satelite_info",fn:function(ref){
var item = ref.item;
return [(item.satelite_info)?_c('span',[_vm._v(_vm._s(item.office_info.name))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isDisplayCarelog(item))?_c('AppButton',{staticClass:"mr-2",attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.carelogoodConfig(item)}}},[_vm._v(" けあログっと設定 ")]):_vm._e(),(
            item.office_info.is_shift &&
              !item.satelite_info &&
              item.billing_info.is_attendance_use == 1
          )?_c('AppButton',{staticClass:"mr-2",attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.linkShiftKintai(item)}}},[_vm._v(" iBow KINTAIシフト一括取り込み ")]):_vm._e(),(item.office_info.is_shift)?_c('AppButton',{staticClass:"mr-2",attrs:{"color":"primary","outlined":"","height":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.editShiftTemplate(item)}}},[_vm._v(" シフト ")]):_vm._e(),_c('AppButton',{attrs:{"text":"","width":"40px"},on:{"click":function($event){$event.stopPropagation();return _vm.clickEdit(item)}}},[_c('AppIcon',{attrs:{"icon":"edit","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)}),_c('OfficeShiftTypeEntry',{attrs:{"officeId":_vm.selectedOfficeId,"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false}}}),_c('SelectYearMonthDialog',{attrs:{"officeId":_vm.selectedOfficeId,"dialog":_vm.linkShiftKintaiDialog},on:{"close":function($event){_vm.linkShiftKintaiDialog = false}}}),_c('CarelogoodConfigDialog',{attrs:{"officeId":_vm.selectedOfficeId,"officeName":_vm.selectedOfficeName,"isCarelog":_vm.isCarelog,"dialog":_vm.carelogoodDialog},on:{"close":function($event){_vm.carelogoodDialog = false}}})],1),_c('AppLoadingDialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }