


























import { Component } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class OneOrThreeWeek extends MedPartBase {
  //週加算タイトル
  protected get titleWeekAddition(): string {
    let title = "週1回加算";
    if (
      this.event.indicate.longtime_beppyo_child_div == 1 ||
      this.event.indicate.longtime_severe_child_div == 1
    ) {
      title = "週3回加算";
    }
    return title;
  }

  //緊急訪問看護
  protected disableCheckMedicalUrgentFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_kinkyu_houmon_kango_add == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //乳幼児
  protected disableCheckInfantsFlg(): boolean {
    let result = false;
    if (this.baseDisableCheck()) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItem() {
    //緊急訪問看護
    if (this.disableCheckMedicalUrgentFlg()) {
      this.receiptVisitRecord.medical_urgent_flg = 0;
    }
    //乳幼児
    if (this.disableCheckInfantsFlg()) {
      this.receiptVisitRecord.infants_flg = 0;
    }
  }
}
