import Base from "../base";

/** 定期巡回 */
export interface RegularVisit extends Base {
  /** ID */
  id: number;
  /** 利用者ID */
  patient_id: number;
  /** 定期巡回サービス内容 1:定期・随時 2:定期のみ 3:随時のみ */
  service_type: number;
  /** 定期巡回開始日 */
  start_date: string;
  /** 定期巡回計算内容(開始) 1:定期 2:日割り */
  calc_type_from: number;
  /** 定期巡回終了日 */
  end_date: string;
  /** 定期巡回計算内容(終了) 1:定期 2:日割り */
  calc_type_to: number;
  /** 定期巡回開始日(和暦) */
  start_date_wareki: string;
  /** 定期巡回終了日(和暦) */
  end_date_wareki: string;
}

/** 定期巡回デフォルト値 */
export const DefaultRegularVisit = (): RegularVisit => ({
  id: 0,
  patient_id: 0,
  service_type: 1,
  start_date: "",
  calc_type_from: 1,
  end_date: "",
  calc_type_to: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: ""
});
