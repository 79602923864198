













import { Component } from "vue-property-decorator";
import RegularPartBase from "./RegularPartBase";

@Component
export default class Other extends RegularPartBase {}
