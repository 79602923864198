










import { Component, Watch } from "vue-property-decorator";
import FirstFlg from "./FirstFlg.vue"

@Component
export default class FirstFlgV2 extends FirstFlg {
  created(): void {
    this.setFirstFlg();
  }

  /** 初回加算I */
  protected first_flg1 = false;

  /** 初回加算II */
  protected first_flg2 = false;

  //初回加算
  protected setFirstFlg() {
    if (this.receiptVisitRecord.first_flg == 1) {
      this.first_flg1 = false;
      this.first_flg2 = true;
    }
    if (this.receiptVisitRecord.first_flg == 2) {
      this.first_flg1 = true;
      this.first_flg2 = false;
    }
  }

  //初回加算Ⅰ
  @Watch("first_flg1")
  protected handleFirstFlg1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.first_flg1 == true) {
      this.receiptVisitRecord.first_flg = 2;
      this.first_flg2 = false;
    }
    if (
      this.first_flg1 == false && this.first_flg2 == false
    ) {
      this.receiptVisitRecord.first_flg = 0;
    }
  }

  //初回加算Ⅱ（従来の初回加算）
  @Watch("first_flg2")
  protected handleFirstFlg2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.first_flg2 == true) {
      this.receiptVisitRecord.first_flg = 1;
      this.first_flg1 = false;
    }
    if (
      this.first_flg1 == false && this.first_flg2 == false
    ) {
      this.receiptVisitRecord.first_flg = 0;
    }
  }

  //初回加算
  protected checkFirstFlgV2(): boolean | string {
    //初回加算と退院時共同指導は同時に加算できない
    if ((this.receiptVisitRecord.first_flg == 1 || this.receiptVisitRecord.first_flg == 2) && this.nursing_leave_inst_flg) {
      return "初回加算と退院時共同指導加算は併せて算定できません";
    }
    return true;
  }

}
