


































































































































































































































import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { PatientDispInfo } from "@/components/patient/types";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import BaseVisit from "@/views/BaseVisit";
import { COLLECTION_SEARCH_COND_PATIENT_LIST } from "@/const/envFireStore";
import { SearchCondition } from "@/components/patient/list/type";
import PatientMixin from "@/mixins/patientMixin";
import * as appDate from "#/utility/appDate";
import PatientInfoDialog from "@/components/patient/PatientInfoDialog.vue";
import PatientMemoDialog from "@/components/patient/PatientMemoDialog.vue";
import DateMixin from "#/mixins/dateMixin";
import VisitMemoDialog from "@/components/visit/VisitMemoDialog.vue";
import { VisitInfo, LinkShowReport } from "./types";

const CurrentDate = new Date();

@Component({
  components: {
    PatientInfoDialog,
    PatientMemoDialog,
    VisitMemoDialog
  }
})
export default class VisitHeader extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin,
  PatientMixin,
  BaseVisit,
  DateMixin
) {
  @Ref("header") readonly header!: HTMLElement;

  @Ref("patientInfoDialog")
  private readonly patientInfoDialog!: PatientInfoDialog;

  @Ref("patientMemoDialog")
  private readonly patientMemoDialog!: PatientMemoDialog;

  @Ref("visitMemoDialog")
  private readonly visitMemoDialog!: VisitMemoDialog;

  @Prop({ default: () => ({}) }) visitInfo!: VisitInfo; // 利用者情報

  // 長い名前が設定された場合の表示ずれを直す
  @Watch("info") private changeInfo() {
    setTimeout(this.observeExpandMenuButtons, 360);
  }

  @Watch("linkShowReports") private changeMenuButtons() {
    setTimeout(this.observeExpandMenuButtons, 120);
  }

  @Watch("$route.path") private changeRoute() {
    setTimeout(this.observeExpandMenuButtons, 120);
  }

  @Watch("isNeedTitleNextLine") private changeNextLineTitle() {
    setTimeout(this.observeExpandMenuButtons, 120);
  }

  /** アイコンエリアの幅 */
  private widthIconArea = 0;
  /** 訪問メモダイアログを訪問終了時にも表示するかどうか */
  public isDispMemoEnd = 1;
  /** 移動中:0 訪問中:1  */
  public mode = 0;

  /** 訪問開始前の書類確認リンク */
  protected linkShowReports: LinkShowReport[] = [];

  /** 訪問開始前の書類確認リンク：幅に収まらなかったメニュー入りリスト */
  protected linkSecondShowReports: LinkShowReport[] = [];

  /** 訪問メモをポップアップするかどうか */
  public get IsDispMemo(): boolean {
    if (this.visitInfo.display === 0 || this.visitInfo.memo === "") {
      return false;
    }
    return true;
  }

  /**
   * タイトルの最大幅は、アイコンエリアの幅とボタン最小幅を引いた分
   * @todo デザインの変更や仕様変更に応じて調整が必要
   */
  private get maxWidthTitle() {
    const buttonsWidth = 150;
    return `calc(100% - ${buttonsWidth + this.widthIconArea}px)`;
  }

  /** アイコンエリアを表示するか */
  private get isShowIconArea() {
    return (
      this.info.is_med ||
      this.info.is_emargency ||
      (this.isHonobonoAgreement && !this.info.is_honobono) ||
      this.info.group_icons.length > 0
    );
  }

  /**
   * 利用者名などタイトルを最低600px未満で折り返す
   * 状態バッジやグループアイコンのありかによっては少し広い幅でも折り返される
   */
  private get isNeedTitleNextLine() {
    const breakPoint = 600 + this.widthIconArea;
    return this.$vuetify.breakpoint.width < breakPoint;
  }

  /** パネル展開状態 */
  private openedPanels: number[] = [];

  /** 利用者情報 */
  private get info(): PatientDispInfo {
    return this.patientInfo;
  }

  // 生年月日
  private get birthDay(): string {
    return `${this.info.bday_wareki} ${this.info.age}`;
  }

  // 誕生日判定
  private get bDayIcon(): string {
    const b = appDate.strToDate(this.info.bday);
    if (CurrentDate.getMonth() != b.getMonth()) {
      return "";
    }
    const diffDate = b.getDate() - CurrentDate.getDate();
    if (diffDate < 0 || diffDate > 3) {
      return "";
    }
    switch (diffDate) {
      case 3:
        return "/images/birthday3.png";
      case 2:
        return "/images/birthday2.png";
      case 1:
        return "/images/birthday1.png";
      case 0:
        return "/images/birthday0.png";
      default:
        return "";
    }
  }

  /** ヘッダーのタイトル */
  private get Title(): string {
    switch (this.$route.name) {
      case "VisitMove":
        if (this.info.name) {
          return this.info.name + "さんに訪問する";
        } else {
          return "訪問する";
        }
      case "VisitStay":
        if (this.info.name) {
          return this.info.name + " さん";
        } else {
          return "";
        }
    }
    return this.info.name;
  }

  public async created() {
    this.patientId = Number(this.$route.params.id);
    this.addReadHistory();
    new Promise(this.fetchPatientLink).then(() => {
      this.linkSecondShowReports.splice(0);
      this.linkShowReports = [
        {
          label: "24時間体制登録",
          prependIcon: "24hours",
          isEnable: true,
          access: this.clickHour24Regist
        },
        {
          label: "24時間体制",
          isEnable: this.linkCondition.exist_hour24,
          access: this.clickHour24
        },
        {
          label: "共有ファイル",
          isEnable: true,
          access: this.clickShareFiles
        },
        {
          label: "利用者ファイル",
          isEnable: true,
          access: this.clickPatientFiles
        },
        {
          label: "最新計画書",
          isEnable: this.linkCondition.latest_vn_plant_id > 0,
          access: this.clickLatestVnPlan
        },
        {
          label: "フェイスシート",
          isEnable: true,
          access: this.clickFaceSheet
        },
        {
          label: "服薬管理表",
          isEnable: true,
          access: this.clickMedicinetaking
        },
        {
          label: "前月報告書",
          isEnable: this.linkCondition.before_month_report_id > 0,
          access: this.clickBeforeMonthReport
        },
        {
          label: "前3回の記録(リハ)",
          isEnable: this.linkCondition.last3_rehab_record_ids.length > 0,
          access: this.clickLast3TimesRecordRehab
        },
        {
          label: "前3回の記録(看護)",
          isEnable: this.linkCondition.last3_nursing_record_ids.length > 0,
          access: this.clickLast3TimesRecordNursing
        },
        {
          label: "前3回のADL",
          isEnable: this.linkCondition.exist_last3_adl,
          access: this.clickLast3TimesAdl
        },
        {
          label: "最新記録書Ⅰ",
          isEnable: this.linkCondition.latest_vnrecord_pdf_path !== "",
          access: this.clickLatestVnrecord
        },
        {
          label: "経過観察",
          isEnable: this.linkCondition.exsit_progress,
          access: this.clickProgress
        },
        {
          label: "訪問予定",
          isEnable: true,
          access: this.clickVisitSchedule
        }
      ];
    });
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private resizeWatcher: any = null;

  /** 訪問へ行くヘッダーのボタンを画面幅に収める */
  private observeExpandMenuButtons(): void {
    // ラッパーにはflex-wrap: nowrapが必要
    // 子孫中のテキストにはwhite-space: nowrapが要るかも
    // 子要素には水平marginを使わない、幅計算が狂ってしまう
    const wrapper = document.querySelector(".ibow2-visit-header-menu");
    // 溢れそうな時に隠す要素はラッパーの子
    const hidables = document.querySelectorAll(
      ".ibow2-visit-header-menu > .ibow2-visit-header-menu-hidable"
    );
    // 隠したのを収める要素はラッパーの子孫
    const expanders = document.querySelectorAll(
      ".ibow2-visit-header-menu .ibow2-visit-header-menu-expander"
    );
    // 隠すでも展開メニューでもない要素
    const others = document.querySelectorAll(
      ".ibow2-visit-header-menu > :not(.ibow2-visit-header-menu-hidable):not(.ibow2-visit-header-menu-expander)"
    );

    // 利用者情報の取得後なら、アイコンエリアの幅が分かる
    const iconAreaElem = document.querySelector(".ibow2-visit-header-icon");
    if (iconAreaElem?.clientWidth) {
      this.widthIconArea = iconAreaElem.clientWidth;
    }

    /**
     * まずは全子要素のdisplayを元通りに
     * hidablesでもexpandersでもない子要素の合計幅を求めて幅Aとする
     * expandersである要素の合計幅を求めて幅Bとする
     * 幅Aにhidablesな要素を一つずつ足していく
     *   この時幅Aがラッパーの幅 - 幅Bを超えたら、その要素を隠す要素リストCに入れる
     * 要素リストCに入れた要素を隠す
     * expandersは、要素リストCがあれば表示、無ければ隠す
     * primaryButtonsラベルリストの中で、後ろから[要素リストCの数]だけsecondaryLabelにも複製
     */
    const buttonPosSwitch = () => {
      if (wrapper == null) return;
      const wrapperWifdth = wrapper.clientWidth;

      // まずは全子要素のdisplayを元通りに
      [...wrapper.children].forEach(child => {
        (child as HTMLElement).style.display = "";
      });

      // hidablesでもexpandersでもない子要素の合計幅を求めて幅Aとする
      let childsBaseWidth = 0;
      others.forEach(other => {
        childsBaseWidth += other.clientWidth;
      });

      // expandersである要素の合計幅を求めて幅Bとする
      let expanderWidth = 0;
      expanders.forEach(expander => {
        expanderWidth += expander.clientWidth;
      });

      // 幅Aにhidablesな要素を一つずつ足していく
      //   この時幅Aがラッパーの幅 - 幅Bを超えたら、その要素を隠す要素リストCに入れる
      const hidableMaxSpace = wrapperWifdth - childsBaseWidth - expanderWidth;
      let hidableWidth = 0;
      const secondaryElems: Node[] = [];
      hidables.forEach(hider => {
        hidableWidth += hider.clientWidth;
        if (hidableWidth > hidableMaxSpace) {
          secondaryElems.push(hider);
        }
      });

      // 要素リストCに入れた要素を隠す
      secondaryElems.forEach(hider => {
        (hider as HTMLElement).style.display = "none";
      });

      // expandersは、要素リストCがあれば表示、無ければ隠す
      if (secondaryElems.length >= 1) {
        expanders.forEach(expander => {
          (expander as HTMLElement).style.display = "";
        });
      } else {
        expanders.forEach(expander => {
          (expander as HTMLElement).style.display = "none";
        });
      }

      // primaryButtonsラベルリストの中で、後ろから[要素リストCの数]だけsecondaryLabelにも複製
      this.linkSecondShowReports.splice(0);
      if (secondaryElems.length >= 1) {
        this.linkSecondShowReports.push(
          ...this.linkShowReports.slice(-secondaryElems.length)
        );
      }
    };

    // ラッパーの幅変化を検知する
    // 何も触らないとResizeObserverが働かないため、一度はすぐに幅変化を実行
    buttonPosSwitch();
    // 以前のResizeObserverは解除
    this.resizeWatcher?.disconnect?.();
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.resizeWatcher = new (window as any).ResizeObserver(buttonPosSwitch);
    // ラッパーの幅変化を検知する
    if (wrapper != null) this.resizeWatcher.observe(wrapper);
  }

  // 利用者参照履歴追加(最大10件保持)
  private async addReadHistory() {
    // fire store から参照履歴取得
    this.collection = COLLECTION_SEARCH_COND_PATIENT_LIST;
    this.documentId = String(this.loginUser.id);
    const cond = await this.documentGet();
    const ids = (cond as SearchCondition).read_history_ids;
    if (cond === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
    const index = ids.indexOf(this.patientId);
    if (index != -1) {
      // 参照履歴にある利用者の場合、配列の末尾に追加するため一度削除する
      ids.splice(index, 1);
    }
    // 参照履歴に利用者追加
    ids.push(this.patientId);
    // 10件以上になった場合は古い履歴（配列の先頭）を削除する
    if (ids.length > 10) {
      (cond as SearchCondition).read_history_ids = ids.slice(1, 11);
    }
    // fire storeに保存
    if (cond) {
      await this.documentSave(cond as SearchCondition);
    }
  }

  /** 訪問予定を表示 */
  clickVisitSchedule() {
    const name = "CalendarWebPrint";
    const WebPrintRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.patientId) },
      query: { target: this.dateToStr(new Date(), "yyyy-MM-dd") }
    });
    window.open(WebPrintRoute.href, "_blank");
  }

  /** 訪問メモダイアログ表示 */
  /** ダイアログopen時、Promiseのresolveを保持する */
  public async openMemoDialog(): Promise<boolean> {
    return await this.visitMemoDialog.openMemoDialog();
  }
}
