




























































import { Component, Mixins, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import {
  AiGenerateHistory,
  DefaultAiGenerateHistory
} from "@/views/patient/plan/types";
import {
  AI_GENERATE_STATE,
  AI_GENERATE_STATE_COLOR,
  AI_GENERATE_STATE_PROGRESS
} from "@/components/patient/common/aireport/const";
import UtilMixin from "@/mixins/utilMixin";
import * as appDate from "#/utility/appDate";

/** AI自動作成履歴を表示するコンポーネント */
@Component
export default class AiGenerateHistoryDialog extends Mixins(UtilMixin) {
  /** Prop */

  /** AI自動作成履歴情報 */
  @Prop({ default: () => [] }) aiGenerateHistories!: AiGenerateHistory[];

  /** 変数 */

  /** ダイアログ開閉状態 */
  private dialog = false;

  /** 履歴表示用ヘッダー情報 */
  private headers: DataTableHeader[] = [
    {
      text: "状態",
      value: "state",
      width: "100px",
      align: "center"
    },
    {
      text: "作成日時",
      value: "datetime",
      width: "160px",
      align: "center"
    },
    {
      text: "作成者",
      value: "staff_name",
      width: "160px",
      align: "center"
    }
  ];

  /** ステータス表示定数 */
  private AI_GENERATE_STATE = AI_GENERATE_STATE;

  /** ステータス色定数 */
  private AI_GENERATE_STATE_COLOR = AI_GENERATE_STATE_COLOR;

  /** ステータス(作成中) */
  private AI_GENERATE_STATE_PROGRESS = AI_GENERATE_STATE_PROGRESS;

  /** デバッグ表示対象の履歴 */
  private debugTargetHistory: AiGenerateHistory = DefaultAiGenerateHistory();

  /** デバッグ表示ダイアログ開閉 */
  private debugDialog = false;

  /** 関数 */

  /** ダイアログ開く */
  public open() {
    this.dialog = true;
  }

  /** ダイアログ閉じる */
  private close() {
    this.dialog = false;
  }

  /** 表示用日時を取得 */
  private getDispDatetime(datetime: string) {
    return appDate.dayjsDate(datetime).format("YYYY年MM月DD日<br/>HH時mm分");
  }

  /** デバッグ用ダイアログ表示 */
  private openDebugDialog(item: AiGenerateHistory) {
    // 検証環境以降では表示しない
    if (["stg", "prd", "pre"].includes(process.env.VUE_APP_MODE)) {
      return;
    }

    this.debugTargetHistory = item;
    this.debugDialog = true;
  }
}
