
















































































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { Choice, VForm } from "@/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import IndicateMixin from "@/mixins/indicateMixin";
import RulesMixin from "#/mixins/rulesMixin";
import EditMixin from "@/mixins/editMixin";
import { Indicate, DefaultSpecialIndicate } from "#/model/patient";
import PatientDoctorSelect, {
  SearchItem,
} from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import DateInput from "#/components/DateInput.vue";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";
import { TargetKind } from "@/const/ocr";

const Ocr = namespace(ocr.name);

@Component({
  components: {
    PatientHeader,
    PatientDoctorSelect,
    DateInput,
  },
})
export default class PatientSpecialIndicateEdit extends Mixins(
  UtilMixin,
  AxiosMixin,
  PatientMixin,
  IndicateMixin,
  RulesMixin,
  EditMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;
  @Ref("patient-doctor-select")
  private readonly patientDoctorSelect!: PatientDoctorSelect;

  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Getter("OcrState") ocrState!: ocr.OcrState;

  private specialIndicateId = 0;
  private patientId = 0;
  private mode = "";
  private isLoaded = false;

  private indicate: Indicate = DefaultSpecialIndicate(); //指示書
  private medicalItems: Choice[] = []; //関連機関（医療機関）items
  private range = 0;

  private ranges = [
    {
      value: 0,
      text: "未選択",
    },
    {
      value: 1,
      text: "2週間",
    },
  ];
  //開始日が今日よりも過去チェック
  private async pastCheck(): Promise<boolean> {
    let pastFlg = 0;
    if (this.indicate.start_date) {
      const date = new Date();
      date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
      date.setDate(date.getDate());
      const today = new Date(
        date
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "/")
      );
      const start = new Date(this.indicate.start_date.replace(/-/g, "/"));
      if (today > start) {
        pastFlg = 1;
      }
    }
    if (pastFlg) {
      return await this.$openConfirm(
        "開始日が過去の日付になっておりますが、よろしいですか？"
      );
    }
    return true;
  }

  created() {
    this.specialIndicateId = Number(this.$route.params.specialindicateid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchSpecialIndicate();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list",
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/indicate?key=Special`,
      },
      {
        text: "特別指示書",
        disabled: true,
        to: "",
      },
    ]);
  }

  destroy() {
    this.clearOcrState();
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //登録
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (!(await this.pastCheck())) {
      return;
    }

    if (this.mode) {
      this.indicate.id = 0;
    }
    this.indicate.patient_id = this.patientId;

    //点滴注射指示が有効でない場合は注射指示期間を空にする
    if (!this.indicate.is_injection) {
      this.indicate.injection_start_date = "";
      this.indicate.injection_end_date = "";
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/specialindicate/save",
      {
        indicate: this.indicate,
      },
      async (res) => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        // OCR 結果から作成成功した場合は OCR ステータスを「作成済み」にする
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.applyOcr();
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  private applyOcr() {
    if (this.ocrState.documentType === 0 || this.ocrState.documentId === 0) {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/ocrstore/mapping/apply",
      {
        document_type: this.ocrState.documentType,
        document_id: this.ocrState.documentId,
      },
      () => {
        // do nothing
      }
    );
  }

  //変更
  private update() {
    this.indicate.id = this.specialIndicateId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.indicate.id = this.specialIndicateId;
      this.indicate.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/specialindicate/delete",
        {
          indicate: this.indicate,
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  //特別指示書情報取得
  private fetchSpecialIndicate(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/specialindicate/get",
      {
        patient_id: this.patientId,
        special_indicate_id: this.specialIndicateId,
      },
      (res) => {
        this.medicalItems = res.data.medical_items;
        //特別指示書情報
        if (this.specialIndicateId != 0) {
          this.indicate = res.data.indicate;
          if (this.mode) {
            this.indicate.start_date = "";
            this.indicate.end_date = "";
            this.indicate.injection_start_date = "";
            this.indicate.injection_end_date = "";
          }
        }

        // OCR 結果から作成の場合、初期値設定
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.setOcrParams();
        }

        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  private setOcrParams() {
    // 指示書
    if (
      this.ocrState.targetKind === TargetKind.indicateSpecial ||
      this.ocrState.targetKind === TargetKind.indicateMentalSpecial
    ) {
      if (this.ocrState.targetKind === TargetKind.indicateSpecial) {
        this.indicate.indicate_div = 3; //特別普通指示書
      } else {
        this.indicate.indicate_div = 4; //精神特別普通指示書
      }
      this.indicate.start_date = this.ocrState.indicateSpecial.startDate;
      this.indicate.end_date = this.ocrState.indicateSpecial.endDate;
      this.indicate.injection_start_date = this.ocrState.indicateSpecial.injectionStartDate;
      this.indicate.injection_end_date = this.ocrState.indicateSpecial.injectionEndDate;
      this.indicate.injection_indicate = this.ocrState.indicateSpecial.injectionIndicate;
      this.indicate.injection_remarks = this.ocrState.indicateSpecial.injectionRemarks;
      if (
        this.indicate.injection_start_date !== "" ||
        this.indicate.injection_end_date !== "" ||
        this.indicate.injection_indicate !== "" ||
        this.indicate.injection_remarks !== ""
      ) {
        this.indicate.is_injection = 1;
      }
      this.indicate.doctor_indicate = this.ocrState.indicateSpecial.doctorIndicate;

      const hospitalName = this.ocrState.indicateSpecial.hospitalName;
      const doctorName = this.ocrState.indicateSpecial.doctorName;
      if (hospitalName !== "" || doctorName !== "") {
        const cond: SearchItem = {
          med_name: hospitalName,
          tel_area: "",
          name: doctorName,
        };
        this.patientDoctorSelect.openDialogWithCondition(cond);
      }
    }
  }

  // 終了期間
  private handleRange() {
    if (this.range == 0 || this.indicate.start_date == "") {
      return;
    }

    const date = new Date(this.indicate.start_date.replace(/-/g, "/"));
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);

    switch (this.range) {
      case 1:
        date.setDate(date.getDate() + 13);
        this.indicate.end_date = date.toISOString().substr(0, 10);
        break;
    }
  }
  //編集モード設定
  @Watch("indicate", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  //種類
  @Watch("indicate.indicate_div")
  private handleIndicateDiv() {
    if (!this.isLoaded) {
      return;
    }
    //精神科以外の場合は医療観察訪問看護フラグは0
    if (this.indicate.indicate_div != 4) {
      this.indicate.is_mtsa = 0;
    }
  }
}
