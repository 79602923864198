



































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { BreadItem } from "@/store/modules/masmen/types";
import {
  Office,
  OfficeHistory,
  SateliteHistory,
  setOffice
} from "#/model/agreement";
import { Location } from "vue-router";

@Component
export default class OfficeHistoryModel extends Mixins(AxiosMixin) {
  private isSatelite = false;

  // 事業所名
  public officeName = "";

  private itemPerPage = 25;
  private pageNo = 1;

  // 履歴ヘッダ
  public headers = [
    {
      text: "適用開始日",
      value: "start_date_wareki",
      align: "start",
      sortable: true
    },
    {
      text: "適用終了日",
      value: "end_date_wareki",
      align: "start",
      sortable: true
    },
    {
      text: "コメント",
      value: "comment",
      align: "start",
      sortable: true
    },
    {
      text: "",
      value: "actions",
      align: "end",
      sortable: false
    }
  ];

  // 事業所データ
  private office: Office = setOffice();

  // 履歴データ
  public histories: (OfficeHistory | SateliteHistory)[] = [];

  private breadItems: BreadItem[] = [];

  public created() {
    if (this.$route.query.satelite) {
      this.isSatelite = true;
    }

    this.getOffice();
    this.setBreadcrumbs();
  }

  private setBreadcrumbs() {
    let editPath = "/master/office/edit/" + this.$route.params.id;
    if (this.isSatelite) {
      editPath += "?satelite=" + this.isSatelite;
    }
    this.breadItems = [
      {
        text: "事業所・サテライト一覧",
        disabled: false,
        to: "/master/office/list",
        exact: true
      },
      {
        text: "事業所・サテライト情報変更",
        disabled: false,
        to: editPath,
        exact: true
      },
      {
        text: "履歴一覧",
        disabled: true,
        to: ""
      }
    ];
  }

  private getOffice() {
    if (this.$route.params.id) {
      this.postJsonCheck(
        window.base_url + "/api/master/station/get_by_id",
        {
          office_id: Number(this.$route.params.id)
        },
        res => {
          if (this.isSatelite) {
            this.histories = res.data.office.satelite_historys;
          } else {
            this.histories = res.data.office.office_historys;
          }
        }
      );
    }
  }

  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  private add() {
    // const name = "MastermaintenanceOfficeHistoryEdit";
    // const query = {
    //   id: this.office.id.toString(),
    //   history_id: "0",
    // };
    // this.$router.push({ name, query: query });
  }

  public edit(history: OfficeHistory | SateliteHistory) {
    const route: Location = {
      name: "MastermaintenanceOfficeHistoryEdit",
      params: { id: history.office_id + "" }
    };
    route.query = {};
    if (this.isSatelite) {
      route.query.satelite = "true";
    }
    if (history.id) {
      route.query.history_id = history.id + "";
    }
    this.$router.push(route);
  }

  private toNewHistory() {
    const url = `/master/office/history/edit/${this.$route.params.id}`;
    const query = {};
    if (this.isSatelite) {
      Object.assign(query, { satelite: true });
    }
    Object.assign(query, { history_id: 0 });
    this.$router.push({ path: url, query: query });
  }
}
