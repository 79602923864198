






























import { Component, Vue, Prop, Emit, Ref } from "vue-property-decorator";
import {
  StdEvalItemPrimaryContent,
  StdEvalItemSecondaryContent,
  DefaultStdEvalItemSecondaryContent
} from "@/views/master/stdevalitem/types";
import { VForm } from "@/types";

interface SelectItems {
  text: string | number | object;
  value: string | number | object;
}

@Component
export default class EditSecondaryContentDialog extends Vue {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return DefaultStdEvalItemSecondaryContent();
    }
  })
  content!: StdEvalItemSecondaryContent;

  @Prop({ default: () => [] }) primaryContents!: StdEvalItemPrimaryContent[];

  private cloneContent: StdEvalItemSecondaryContent = DefaultStdEvalItemSecondaryContent();

  private get StdEvalItem() {
    return this.primaryContents.find(
      item => item.id == this.cloneContent.std_eval_item_id
    )?.name;
  }

  created() {
    this.cloneContent = { ...this.content };
  }

  private save() {
    if (!this.form.validate()) {
      return;
    }
    this.saveSecondaryContent(this.cloneContent);
  }

  private cancel() {
    this.closeDialog();
  }

  @Emit("saveSecondaryContent")
  private saveSecondaryContent(content: unknown) {
    return content;
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
