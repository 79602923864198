var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppBreadcrumbs',{attrs:{"items":_vm.breadItems}}),_c('v-row',{staticClass:"pt-4 pb-6",attrs:{"no-gutters":""}},[_c('AppTitle',{attrs:{"label":"レセプト売上情報詳細"}})],1),_c('div',{staticClass:"ibow2-receipt-sales"},[_c('v-row',{staticClass:"align-center px-4 py-6",attrs:{"no-gutters":""}},[_c('div',{staticClass:"mr-4"},[_c('AppTitle',{staticClass:"ibow2-receipt-sales-table-title mb-2",attrs:{"label":_vm.officeName}}),_c('p',{staticClass:"mb-0 grey-light--text"},[_vm._v(_vm._s(_vm.receiptDate))])],1),_c('v-spacer'),_c('AppButton',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.receiptSalesPatient()}}},[_vm._v("利用者詳細"),_c('AppIcon',{attrs:{"icon":"arrow-right","fill":_vm.$vuetify.theme.themes.light.primary}})],1)],1),_c('div',{ref:"chartdiv",staticClass:"ibow2-receipt-sales-chart"}),_c('v-row',{staticClass:"align-center pa-4",attrs:{"no-gutters":""}},[_c('AppTitle',{staticClass:"ibow2-receipt-sales-table-title mr-4",attrs:{"label":"詳細"}}),_c('v-spacer'),_c('AppButton',{attrs:{"color":"grey","outlined":""},on:{"click":_vm.downloadExcel}},[_vm._v("Excel出力")])],1),_c('AppDataTable',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tableDatas,"isNoTop":true,"isNoFooter":true,"disable-pagination":"","noDataText":"データがありません。"},scopedSlots:_vm._u([{key:"item.total_sales",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_sales.toLocaleString())+" ")]}},{key:"item.insurance_claim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.kind == "自己負担" ? "-" : item.insurance_claim.toLocaleString())+" ")]}},{key:"item.public_funding_request",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.kind == "医療" || item.kind == "自己負担" ? "-" : item.public_funding_request.toLocaleString()))]}},{key:"item.user_request_subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_request_subtotal.toLocaleString())+" ")]}},{key:"item.user_request_insurance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.kind == "自己負担" ? "-" : item.user_request_insurance.toLocaleString())+" ")]}},{key:"item.user_request_overinsurance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.kind == "自己負担" ? "-" : item.user_request_overinsurance.toLocaleString())+" ")]}},{key:"item.adjust_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.kind == "予防(介護)" || item.kind == "自己負担" ? "-" : item.adjust_amount.toLocaleString())+" ")]}}],null,true)})],1),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }