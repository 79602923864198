

















import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import OneOrThreeWeekAdd from "./OneOrThreeWeekAdd.vue";

@Component
export default class OneOrThreeWeekAddV3 extends OneOrThreeWeekAdd {
  @Prop({ default: false }) protected readonly long_time_flg!: boolean;

  @Prop({ default: false })
  protected readonly long_time_flg_covid19_div!: boolean;

  @Emit("update:long_time_flg") protected updateLongTimeFlg(newValue: boolean) {
    return newValue;
  }

  @Emit("update:long_time_flg_covid19_div")
  protected updateLongTimeFlgCovid19Div(newValue: boolean) {
    return newValue;
  }

  created(): void {
    this.initLongTimeFlg();
  }

  protected get LongTimeFlg() {
    return this.long_time_flg;
  }

  protected set LongTimeFlg(newValue: boolean) {
    this.updateLongTimeFlg(newValue);
  }

  //長時間
  private initLongTimeFlg() {
    if (this.receiptVisitRecord.long_time_flg == 1) {
      this.updateLongTimeFlg(true);
    }
    if (this.receiptVisitRecord.long_time_flg == 2) {
      this.updateLongTimeFlg(false);
    }
  }

  //長時間
  @Watch("long_time_flg") protected handleLongTimeFlg() {
    if (!this.isLoaded) {
      return;
    }
    if (this.long_time_flg == true) {
      this.receiptVisitRecord.long_time_flg = 1;
      this.updateLongTimeFlgCovid19Div(false);
    }
    if (
      this.long_time_flg == false &&
      this.long_time_flg_covid19_div == false
    ) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }

  //長時間
  protected chackLongTimeFlgV3(): boolean | string {
    //90分未満の訪問は長時間加算できない
    const start = new Date(
      this.event.visit_record.service_start_datetime.replace(/-/g, "/")
    );
    const end = new Date(
      this.event.visit_record.service_end_datetime.replace(/-/g, "/")
    );
    const diff = Math.abs((end.getTime() - start.getTime()) / (60 * 1000));
    if (this.long_time_flg && diff < 91 && !this.disableCheckLongTimeFlg()) {
      return "訪問時間が長時間訪問看護に該当されません";
    }
    return true;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //長時間
    if (this.disableCheckLongTimeFlg() && !this.long_time_flg_covid19_div) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }
}
