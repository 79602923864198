



































































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import {
  PatientShareFileInfo,
  PatientShareFileSearchCondition,
  FileSortTypeSelect
} from "@/components/sharefile/type";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";

@Component
export default class FileHeader extends Mixins(ShareFileMixin) {
  @Prop() private searchObj!: PatientShareFileSearchCondition;
  @Prop() private checked!: PatientShareFileInfo[];
  @Prop() private fileSort!: FileSortTypeSelect;
  @Prop() private fileSortList!: FileSortTypeSelect[];
  @Prop({ default: false }) private isFiltered!: boolean;
  @Prop() items!: []; // データ一覧
  @Prop({ default: 1 }) pageNo!: number; //ページ番号
  @Prop({ default: 1 }) pageCount!: number; //ページ数
  @Prop({ default: false }) isHeadPagination!: boolean; //画面上部ページネイション
  @Prop({ default: -1 }) frontOnlyPageCount!: number; //フロントだけで制御する場合の1ページあたりの表示件数（これを有効にした場合、フロントのみのページングになる）

  @Emit("openTagDialog")
  private openTagDialog() {
    return;
  }
  @Emit("onChangeSortObj")
  private onChangeSortObj(rule: FileSortTypeSelect) {
    return rule;
  }
  @Emit("openSearchPanel")
  private openSearchPanel() {
    return;
  }

  @Emit("update:pageNo")
  private updatePageNo(newValue: number) {
    this.changePage(newValue);
    return newValue;
  }

  @Emit()
  private changePage(page: number) {
    return page;
  }

  private get PageNo() {
    return this.pageNo;
  }

  private set PageNo(newValue: number) {
    this.updatePageNo(newValue);
  }
}
