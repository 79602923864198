import Base from "./base";

/**
 * ダッシュボード統計お気に入り
 */
export interface StatisticFavorite extends Base {
  /** 'ID' */
  id: number;
  /** '契約ID' */
  agreement_id: number;
  /** '事業所ID' */
  office_id: number;
  /** 担当者ID */
  staff_id: number;
  /** お気に入り種類 */
  statistic_type: number;
  /** お気に入り名 */
  favorite_name: string;
  /** ダッシュボード統計お気に入りグループ */
  groups: StatisticFavoriteGroup[];
  /** ダッシュボード統計お気に入りカラム */
  columns: StatisticFavoriteColumn[];
  /** 開始日種類 */
  start_date_type: number;
  /** 終了日種類 */
  end_date_type: number;
  /** 1:「1利用者1行で表示」チェックボックスを検索条件に追加する */
  valid_single_row: number;
}

export const DefaultStatisticFavorite = (): StatisticFavorite => ({
  id: 0,
  agreement_id: 0,
  office_id: 0,
  staff_id: 0,
  statistic_type: 0,
  favorite_name: "",
  groups: [],
  columns: [],
  start_date_type: 0,
  end_date_type: 0,
  valid_single_row: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

/**
 * ダッシュボード統計お気に入りグループ
 */
export interface StatisticFavoriteGroup extends Base {
  /** ダッシュボードお気に入りID */
  statistic_favorite_id: number;
  /** 'ダッシュボードお気に入りグループ名', */
  group_id: string;
}

/**
 * ダッシュボード統計お気に入りカラム
 */
export interface StatisticFavoriteColumn extends Base {
  /** ダッシュボードお気に入りID */
  statistic_favorite_id: number;
  /** 'ダッシュボードお気に入りカラム名', */
  col_id: string;
  /** ソート番号 */
  sort: number;
  /** 絞り込み文字 */
  filter: string;
  /** 絞り込み条件 1:前方一致 2:部分一致 */
  filter_condition: number;
  /** 日付絞り込み条件1 0:対象年月日 1:今日 2:1ヶ月後 */
  date_filter_condition1: number;
  /** 日付絞り込み条件2 0:に等しい, 1:に等しくない, 2:より大きい, 3:以上, 4:より小さい, 5:以下 */
  date_filter_condition2: number;
}
