


























































































































import { Component, Mixins } from "vue-property-decorator";
import { Route, Dictionary } from "vue-router/types/router";
import FAQSearch from "@/components/contact/FAQSearch.vue";
import CategoryMenu from "@/components/contact/CategoryMenu.vue";
import FAQList from "@/components/contact/FAQList.vue";
import { BreadItem } from "@/store/modules/patient/types";
import {
  CategoryMenuList,
  CategoryChoice,
  DispTemplate,
  SearchCondFAQ,
  DEFAULT_SEARCH_COND_FAQ
} from "@/views/contact/types";
import ContactUtility from "@/views/contact/utility";

@Component({
  components: {
    FAQSearch,
    CategoryMenu,
    FAQList
  }
})
export default class FAQ extends Mixins(ContactUtility) {
  /** パンくずリスト */
  private breadItems: BreadItem[] = [
    {
      text: "サポート",
      disabled: false,
      to: "/contact",
      exact: true
    },
    {
      text: "iBowヘルプ",
      disabled: true,
      to: ""
    }
  ];

  /** FAQテンプレート情報 */
  private templates: DispTemplate[] = [];

  /** 検索結果に当てはまる全記事の件数 */
  private searchedAllCount = 0;

  /** カテゴリ選択用Choice */
  private categoryChoices: CategoryChoice[] = [];

  /** 現在のページ番号 */
  private pageNo = 1;

  /** 1ページあたりの表示件数 */
  private perPage = 10;

  /** 検索条件 */
  private searchObj: SearchCondFAQ = { ...DEFAULT_SEARCH_COND_FAQ };

  /** 検索した時の検索条件 */
  private savedSearchObj: SearchCondFAQ = { ...DEFAULT_SEARCH_COND_FAQ };

  /** 問い合わせ・回答内容ではなくおすすめキーワードで検索するか */
  private get IsKeywordMode() {
    return (
      this.$route.query.hashtag != null && this.$route.query.hashtag !== ""
    );
  }

  /** 現在検索しているカテゴリ */
  private get SearchCategory() {
    const catId = this.savedSearchObj.category_id;
    if (catId < 1) {
      return undefined;
    }
    return this.categoryChoices.find(cat => cat.value === catId);
  }

  /** 現在検索しているフリーワード */
  private get SearchKeywords() {
    // スペース区切りで1つ以上
    return this.savedSearchObj.keyword
      .trim()
      .split(/\s+/)
      .filter(p => p !== "");
  }

  /** 次の読み込みで取得する記事数 */
  private get NextItemLength() {
    // 10件以上あっても10件ずつ読み込む
    return Math.min(
      this.perPage,
      this.searchedAllCount - this.templates.length
    );
  }

  created() {
    // クエリパラメータから現在の検索条件を設定
    this.queryToSearchCond(this.$route.query);
    this.fetchCategory().then(() => {
      this.fetchFAQ();
    });
  }

  /** 検索実行やブラウザバックでURLパラメータが変わった時に実行 */
  beforeRouteUpdate(to: Route, from: Route, next: Function) {
    // 変更したクエリパラメータを検索条件に設定
    this.queryToSearchCond(to.query);
    this.resetSearchResults();
    this.scrollToTop();

    this.$nextTick(() => {
      this.fetchFAQ();
    });
    next();
  }

  /** クエリパラメータから現在の検索条件を設定 */
  private queryToSearchCond(query: Dictionary<string | (string | null)[]>) {
    if (query.keyword != null) {
      this.searchObj.keyword = query.keyword as string;
    }
    if (query.andor != null) {
      this.searchObj.keyword_operator = Number(query.andor) || 1;
    }
    if (query.category_id != null) {
      this.searchObj.category_id = Number(query.category_id);
    }
    if (query.hashtag != null) {
      this.searchObj.hashtag = query.hashtag as string;
    }

    this.savedSearchObj = { ...this.searchObj };
  }

  /** FAQテンプレート情報を取得 */
  private fetchFAQ() {
    const searchObj = {
      ...this.savedSearchObj,
      limit: this.perPage,
      offset: (this.pageNo - 1) * this.perPage
    };

    this.postJsonCheck(
      window.base_url + "/api/inquiry/templates/get",
      searchObj,
      res => {
        if (this.pageNo === 1) this.templates = [];

        if (res.data.templates) {
          this.templates.push(...res.data.templates);
          this.searchedAllCount = res.data.template_count;
          this.pageNo++;
        }
      }
    );
  }

  /** 検索結果一覧リセット処理 */
  private resetSearchResults() {
    this.pageNo = 1;
    this.templates = [];
    this.searchedAllCount = 0;
  }

  /** カテゴリマスタ情報取得 */
  private fetchCategory() {
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/inquiry/categorys/get",
        {},
        res => {
          this.categoryChoices = res.data.category_choice;
          resolve(0);
        }
      );
    });
  }

  /** 検索 */
  private search() {
    const query = this.getFAQUrlQuery(this.searchCondToQuery(this.searchObj));
    this.savedSearchObj = { ...this.searchObj };

    if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
      // 検索処理はURL変更サイクル内でする
      this.$router.push({
        name: "FAQ",
        query: query
      });
    } else {
      // 同じURLパラメータの場合ここで検索処理をする
      this.resetSearchResults();
      this.scrollToTop();
      this.fetchFAQ();
    }
  }

  /** FAQ項目のカテゴリクリック時 */
  private onClickCategoryLink(categoryId: number) {
    // カテゴリのみで検索し、他の検索条件はリセット
    this.searchObj = {
      ...DEFAULT_SEARCH_COND_FAQ,
      category_id: categoryId
    };
    this.search();
  }

  /** FAQ項目のキーワードクリック時 */
  private onClickKeywordLink(keyword: string) {
    this.searchObj = {
      ...DEFAULT_SEARCH_COND_FAQ,
      hashtag: keyword
    };
    this.search();
  }

  /** カテゴリリストクリック時 */
  private onClickCategoryList(category: CategoryMenuList) {
    // ページ数情報をリセット
    this.resetSearchResults();
    this.scrollToTop();

    if (this.searchObj.category_id !== category.category_id) {
      // 今検索中のカテゴリと違うカテゴリを選択した場合は、URL変更サイクル内で検索処理をする
      // カテゴリを変更し、他の検索条件はそのまま
      this.searchObj.category_id = category.category_id;
      this.savedSearchObj = { ...this.searchObj };
      this.$router.push({
        name: "FAQ",
        query: this.getFAQUrlQuery({
          ...this.searchCondToQuery(this.searchObj),
          category_id: String(category.category_id)
        })
      });
    } else {
      this.fetchFAQ();
    }
  }

  private scrollToTop() {
    this.$vuetify.goTo(0, { easing: "easeInOutCubic" });
  }

  private onScroll() {
    if (window.scrollY > window.innerHeight / 2) {
      document.getElementsByClassName("scroll-top")[0].classList.add("active");
    } else {
      document
        .getElementsByClassName("scroll-top")[0]
        .classList.remove("active");
    }
  }
}
