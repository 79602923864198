

















































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { Location } from "vue-router";
import ShiftTypeEntryDialog from "@/components/master/shift/StaffShiftTypeEntry.vue";
import { Facility } from "@/components/master/office/common";
import { BreadItem } from "@/store/modules/masmen/types";
import {
  Office,
  setOffice,
  SateliteHistory,
  setSateliteHistory,
  setOfficeHistory,
  OfficeHistory
} from "#/model/agreement";
import AppPlaces from "#/components/AppPlaces.vue";
import OfficeItem from "@/components/master/office/OfficeItem.vue";
import SateliteItem from "@/components/master/office/SateliteItem.vue";
import MasmenMixin from "@/mixins/masmenMixin";
import EditMixin from "@/mixins/editMixin";

@Component({
  components: {
    ShiftTypeEntryDialog,
    AppPlaces,
    OfficeItem,
    SateliteItem
  }
})
export default class OfficeHistoryEdit extends Mixins(MasmenMixin, EditMixin) {
  @Ref("sateliteItem") private readonly sateliteItem!: SateliteItem;

  @Ref("officeItem") private readonly officeItem!: OfficeItem;

  private isSatelite = false;

  private beforeHistory: OfficeHistory = setOfficeHistory();

  private history: OfficeHistory = setOfficeHistory();

  private afterHistory: OfficeHistory = setOfficeHistory();
  // 履歴データ
  public histories: (OfficeHistory | SateliteHistory)[] = [];

  private office: Office = setOffice();

  private facilities: Facility[] = [];

  private satelite: SateliteHistory = setSateliteHistory();

  private beforeSatelite: SateliteHistory = setSateliteHistory();

  private afterSatelite: SateliteHistory = setSateliteHistory();

  private offices: Office[] = [];

  private historyId = 0; // 事業所履歴ID
  private historyStart = ""; // 履歴の適用開始日

  private is_honobono = 0;

  private is_init_data = false; //初期データ 1970-01-01のデータ日付を変えられない

  private breadItems: BreadItem[] = [];

  private get monthToFirstDate() {
    return (monthOrDate: string) => {
      if (monthOrDate.length !== 7) {
        // yyyy-MM形式で無ければそのまま返す
        return monthOrDate;
      }
      return monthOrDate + "-01";
    };
  }

  private created() {
    if (this.$route.query.satelite) {
      this.isSatelite = true;
    }

    if (this.$route.query.history_id) {
      this.historyId = Number(this.$route.query.history_id);
    }
    this.getOffice();
  }

  private cloneOffice = {
    id: 0,
    office_info: {
      start_date: "string"
    }
  };

  /** 適用開始月用に、yyyy-MM-ddをyyyy-MMに変換する */
  private startDateToMonth(history: SateliteHistory | OfficeHistory) {
    history.start_date = history.start_date.slice(0, 7);
  }

  private getPrevOfficeHistory(historys: (SateliteHistory | OfficeHistory)[]) {
    const index = historys.findIndex(x => x.id == this.historyId);

    if (this.isSatelite) {
      if (index > 0) {
        this.satelite = { ...(historys[index] as SateliteHistory) };
        this.beforeSatelite = { ...(historys[index - 1] as SateliteHistory) };
        if (this.satelite.start_date == "1970-01-01") {
          this.is_init_data = true;
        }
      } else if (index == 0) {
        this.satelite = { ...(historys[index] as SateliteHistory) };
        if (this.satelite.start_date == "1970-01-01") {
          this.is_init_data = true;
        }
      }
      this.startDateToMonth(this.satelite);
    } else {
      if (index > 0) {
        this.beforeHistory = { ...(historys[index - 1] as OfficeHistory) };
        this.history = { ...(historys[index] as OfficeHistory) };
        if (this.history.start_date == "1970-01-01") {
          this.is_init_data = true;
        }
      } else if (index == 0) {
        this.history = { ...(historys[index] as OfficeHistory) };
        if (this.history.start_date == "1970-01-01") {
          this.is_init_data = true;
        }
      }
      this.startDateToMonth(this.history);
    }
    this.getNextOfficeHistory(historys, index);
  }

  private getNextOfficeHistory(
    historys: (SateliteHistory | OfficeHistory)[],
    index: number
  ) {
    if (this.isSatelite) {
      this.afterSatelite = { ...(historys[index + 1] as SateliteHistory) };
    } else {
      this.afterHistory = { ...(historys[index + 1] as OfficeHistory) };
    }
  }

  private getLastOfficeHistory(historys: (SateliteHistory | OfficeHistory)[]) {
    const length = historys.length;
    if (length > 0) {
      if (this.isSatelite) {
        this.beforeSatelite = { ...(historys[length - 1] as SateliteHistory) };
        this.satelite = { ...this.beforeSatelite };
        if (this.satelite.start_date == "1970-01-01") {
          this.is_init_data = true;
        }
        this.startDateToMonth(this.satelite);
      } else {
        this.beforeHistory = { ...(historys[length - 1] as OfficeHistory) };
        this.history = { ...this.beforeHistory };
        if (this.history.start_date == "1970-01-01") {
          this.is_init_data = true;
        }
        this.startDateToMonth(this.history);
      }
    }
  }

  private getOffice() {
    if (this.$route.params.id) {
      // 編集の場合
      this.postJsonCheck(
        window.base_url + "/api/master/station/get_by_id",
        {
          office_id: Number(this.$route.params.id)
        },
        res => {
          this.cloneOffice = { ...res.data.office };
          this.is_honobono = res.data.is_honobono;
          this.offices = res.data.parent_offices;
          this.facilities = res.data.same_buildings;

          if (this.isSatelite) {
            if (this.historyId > 0) {
              this.getPrevOfficeHistory(res.data.office.satelite_historys);
            } else {
              this.getLastOfficeHistory(res.data.office.satelite_historys);
            }
            this.historyStart = this.satelite.start_date;
          } else {
            if (this.historyId > 0) {
              this.getPrevOfficeHistory(res.data.office.office_historys);
            } else {
              this.getLastOfficeHistory(res.data.office.office_historys);
            }
            this.historyStart = this.history.start_date;
          }
          this.setLoaded();
        }
      );
    } else {
      // 作成の場合
      this.setLoaded();
    }
  }

  private setBreadcrumbs() {
    let editPath = "";
    let nowPagetitle = "履歴追加";
    if (this.isSatelite) {
      editPath =
        "/master/office/edit/" +
        this.$route.params.id +
        "?satelite=" +
        this.isSatelite;
    } else {
      editPath = "/master/office/edit/" + this.$route.params.id + "?satelite=";
    }
    if (this.historyId != 0) {
      nowPagetitle = "履歴編集";
    }
    this.breadItems = [
      {
        text: "事業所・サテライト一覧",
        disabled: false,
        to: "/master/office/list",
        exact: true
      },
      {
        text: "事業所・サテライト情報変更",
        disabled: false,
        to: editPath,
        exact: true
      },
      {
        text: "履歴一覧",
        disabled: false,
        to: "/master/office/history/" + this.$route.params.id,
        exact: true
      },
      {
        text: nowPagetitle,
        disabled: true,
        to: ""
      }
    ];
  }

  //事業所履歴保存
  private saveOfficeHistory() {
    this.history.id = this.historyId;
    this.history.office_id = Number(this.$route.params.id);
    if (this.history.use_edi_flg && this.history.edi_code.length < 10) {
      this.history.edi_code = this.history.edi_code.padEnd(10, "0");
    }
    this.postJsonCheck(
      window.base_url + "/api/master/station/save_info",
      {
        office_history: this.history
      },
      () => {
        this.callSaveApiAfter(false);
      },
      () => {
        this.startDateToMonth(this.history);
      }
    );
  }

  //事業所履歴削除
  private deleteOfficeHistory() {
    this.history.id = this.historyId;
    this.history.office_id = Number(this.$route.params.id);
    this.history.start_date = this.historyStart.slice(0, 7) + "-01";
    this.postJsonCheck(
      window.base_url + "/api/master/station/delete_info",
      {
        office_info: this.history
      },
      () => {
        this.callSaveApiAfter(false);
      }
    );
  }

  //サテライト履歴保存
  private saveSateliteHistory() {
    this.satelite.id = this.historyId;
    this.satelite.office_id = Number(this.$route.params.id);
    this.postJsonCheck(
      window.base_url + "/api/master/station/save_satelite_info",
      {
        satelite_history: this.satelite
      },
      () => {
        this.callSaveApiAfter(true);
      },
      () => {
        this.startDateToMonth(this.satelite);
      }
    );
  }

  //サテライト履歴削除
  private deleteSateliteHistory() {
    this.satelite.id = this.historyId;
    this.satelite.office_id = Number(this.$route.params.id);
    this.satelite.start_date = this.historyStart.slice(0, 7) + "-01";
    this.postJsonCheck(
      window.base_url + "/api/master/station/delete_satelite_info",
      {
        satelite_info: this.satelite
      },
      () => {
        this.callSaveApiAfter(true);
      }
    );
  }

  private async save() {
    if (this.isSatelite) {
      if (!this.sateliteItem.form.validate()) {
        await this.$openAlert("入力内容に不備があります");
        return;
      }
      if (this.satelite.start_date) {
        const [year, month] = this.satelite.start_date.split("-");
        this.satelite.start_date = year + "-" + month + "-01";
      }
      this.saveSateliteHistory();
    } else {
      if (!this.officeItem.form.validate()) {
        await this.$openAlert("入力内容に不備があります");
        return;
      }
      if (this.history.start_date) {
        const [year, month] = this.history.start_date.split("-");
        this.history.start_date = year + "-" + month + "-01";
      }
      // 24時間体制連絡加算ONが隠れたまま対応加算をONにした時、対応だけをONにして保存する
      if (
        this.history.medical_hour24_taiou_add &&
        this.history.medical_hour24_renraku_add
      ) {
        this.history.medical_hour24_renraku_add = 0;
      }
      this.saveOfficeHistory();
    }
  }

  private async deleteInfo() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }
    if (this.isSatelite) {
      this.deleteSateliteHistory();
    } else {
      this.deleteOfficeHistory();
    }
  }

  private callSaveApiAfter(isSatelite: boolean) {
    this.setNoEditMode();
    // マスタ情報を再取得
    this.GetMaster().then(() => {
      const toRoute: Location = {
        name: "MastermaintenanceOfficeHistory",
        params: { id: String(this.$route.params.id) }
      };
      if (isSatelite) {
        toRoute.query = { satelite: "true" };
      }
      this.$router.push(toRoute);
    });
  }

  public mounted() {
    this.setBreadcrumbs();
  }

  //編集モード設定
  @Watch("beforeHistory", { deep: true })
  private watchBeforeHistory() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
  @Watch("history", { deep: true })
  private watchHistory() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
  @Watch("afterHistory", { deep: true })
  private watchAfterHistory() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
  @Watch("beforeSatelite", { deep: true })
  private watchBeforeSatelite() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
  @Watch("satelite", { deep: true })
  private watchSatelite() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
  @Watch("afterSatelite", { deep: true })
  private watchAfterSatelite() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
