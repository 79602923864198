


















import { Component, Vue, Prop } from "vue-property-decorator";
import { Attend } from "@/components/home/types";
import Stamping from "@/components/home/stamping.vue";
import NoticeMessage from "@/components/home/noticeMessage.vue";

@Component({
  components: {
    Stamping,
    NoticeMessage
  }
})
export default class StampAndNotice extends Vue {
  /** 通常メッセージ */
  @Prop() private readonly infoList!: string[];

  /** 注意書きメッセージ */
  @Prop() private readonly cautionList!: string[];

  /** 件数メッセージがあるか */
  @Prop() private readonly hasNoticeMsg!: boolean;

  /** 勤怠情報 */
  @Prop() private readonly attend!: Attend;

  /** 事業所ID */
  @Prop() private readonly officeId!: number;
}
