


























































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class PatientSaveArea extends Vue {
  /** IDプレフィックス */
  @Prop({ default: "prefix" }) prefix_id!: string;

  /** 提出後にプレビューを表示する */
  @Prop({ default: false }) displayPreview!: boolean;

  /** 記事ID */
  @Prop({ default: 0 }) id!: number;

  /** 上書き保存表示フラグ */
  @Prop({ default: false }) isDispSaveOnly!: boolean;

  /** プレビューをみる */
  @Emit() preview() {
    return;
  }

  /** 保存して閉じない */
  @Emit() saveOnly() {
    return;
  }

  /** 保存して閉じる */
  @Emit() save() {
    return;
  }

  /** キャンセル */
  @Emit() cancel() {
    return;
  }

  /** 削除する */
  @Emit() delete() {
    return;
  }

  /** 提出してプレビュー */
  @Emit() submitAndPreview() {
    return;
  }

  /** 提出する */
  @Emit() submit() {
    return;
  }

  /** キャンセルボタンクリック */
  async clickCancel() {
    if (await this.$openConfirm("キャンセルします。よろしいですか。")) {
      this.cancel();
    }
  }

  /** 削除ボタンクリック */
  async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか。")) {
      this.delete();
    }
  }

  /** 提出ボタンクリック */
  async clickSubmit() {
    if (await this.$openConfirm("提出します。よろしいですか。")) {
      if (this.displayPreview) {
        this.submitAndPreview();
      } else {
        this.submit();
      }
    }
  }
}
