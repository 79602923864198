import { DiseaseWord } from "#/model/patient";
/** 傷病検索履歴 firestore保存用 */
export interface DiseaseHistories {
  histories: DiseaseHistory[];
}

export interface DiseaseHistory {
  name: string;
  code: string;
  kana: string;
  words: DiseaseWord[];
}

export const SELECT_DISEASE_TYPE = {
  /** 候補から選択する */
  SUGGEST: 1,
  /** 傷病名から組み合わせる */
  COMBO: 2
};

export const HEADER_TITLE = {
  SUGGEST: "組み合わせ候補から選ぶ",
  COMBO: "新しく組み合わせて選ぶ"
};
