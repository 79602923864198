


















import { Component, Vue } from "vue-property-decorator";

@Component
export default class SelectLinkAccount extends Vue {
  mounted(): void {
    console.log("mounted");
  }
}
