import { MutationTree } from "vuex";
import { MasmenState, SET_MASMEN, SetMasmen } from "./types";

export const mutations: MutationTree<MasmenState> = {
  [SET_MASMEN](state, input: SetMasmen) {
    state.menus = input.menus;
    state.is_agent = input.is_agent;
    state.is_nursing_category = input.is_nursing_category;
  }
};
