















import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class LoginTitle extends Mixins() {
  @Prop() title!: string;
  @Prop({ default: false }) miniTitle!: boolean;
  @Prop({ default: "" }) subMessage!: string;
}
