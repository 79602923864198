import { Choice } from "@/types";

export const BOARD_DIV: Choice[] = [
  { text: "iBowに関するお知らせ", value: 1 },
  { text: "eWeLLからのお知らせ", value: 2 },
  { text: "サポートからのお知らせ", value: 3 }
];

export const BOARD_DIV_COLOR: Choice[] = [
  { text: "teal", value: 1 },
  { text: "indigo darken-1", value: 2 },
  { text: "amber darken-3", value: 3 }
];
