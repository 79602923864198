// 訪問記録Ⅱ

/** 提供時間区分 1:訪看I1(20分未満) */
export const SERVICE_TIME_DIV_I1 = 1;
/** 提供時間区分 2:訪看I2(30分未満) */
export const SERVICE_TIME_DIV_I2 = 2;
/** 提供時間区分 3:訪看I3(30分以上60分未満) */
export const SERVICE_TIME_DIV_I3 = 3;
/** 提供時間区分 4:訪看I4(60分以上90分未満) */
export const SERVICE_TIME_DIV_I4 = 4;
/** 提供時間区分 5:訪看I4・長(90分以上) */
export const SERVICE_TIME_DIV_I4_LONG = 5;
/** 提供時間区分 6:訪看I5(20分) */
export const SERVICE_TIME_DIV_I5_20 = 6;
/** 提供時間区分 7:訪看I5(40分) */
export const SERVICE_TIME_DIV_I5_40 = 7;
/** 提供時間区分 8:訪看I5・2超(60分) */
export const SERVICE_TIME_DIV_I5_OVER_60 = 8;
/** 提供時間区分 9:訪看I5・2超(80分) */
export const SERVICE_TIME_DIV_I5_OVER_80 = 9;
/** 提供時間区分 10:訪看I5・2超(100分) */
export const SERVICE_TIME_DIV_I5_OVER_100 = 10;
/** 提供時間区分 11:訪看I5・2超(120分) */
export const SERVICE_TIME_DIV_I5_OVER_120 = 11;
/** 提供時間区分 12:訪看I5・2超(20分) */
export const SERVICE_TIME_DIV_I5_OVER_20 = 12;
/** 提供時間区分 13:訪看I5・2超(40分) */
export const SERVICE_TIME_DIV_I5_OVER_40 = 13;
/** 提供時間区分 14:医療保険訪問 */
export const SERVICE_TIME_DIV_MEDICAL = 14;

/** 専門看護師訪問区分 1:緩和ケア */
export const SENMON_CARE_DIV_MITIGATION = 1;
/** 専門看護師訪問区分 2:褥瘡ケア */
export const SENMON_CARE_DIV_BEDSORE = 2;
/** 専門看護師訪問区分 3:人工肛門／膀胱ケア */
export const SENMON_CARE_DIV_STOMA = 3;
/** 専門看護師訪問区分 4:特定行為 */
export const SENMON_NURSSE_PRACTITIONER = 4;

/** 訪問タイプ 1:サービス訪問（自費） */
export const NURSING_TYPE_OWNEXPENSE = 1;
/** 訪問タイプ 2:入院中の外泊訪問 */
export const NURSING_TYPE_STAYOUTSIDE = 2;
/** 訪問タイプ 3:加算のみ */
export const NURSING_TYPE_ADDONLY = 3;
/** 訪問タイプ 4:専門看護師として同行を行う */
export const NURSING_TYPE_ACCOMPANY_PROFESSIONAL = 4;
/** 訪問タイプ 5:専門看護師による計画的な管理（専門管理加算） */
export const NURSING_TYPE_SPECIAL_MANAGEMENT_ADD = 5;

/** 資格 1:看護師 */
export const CERTIFICATION_REGISTERED_NURSE = 1;
/** 資格 2:准看護師 */
export const CERTIFICATION_PRACTICAL_NURSE = 2;
/** 資格 3:専門看護師（緩和ケア） */
export const CERTIFICATION_SPECIALITY_NURSE_MITIGATION = 3;
/** 資格 4:専門看護師（褥瘡ケア） */
export const CERTIFICATION_SPECIALITY_NURSE_BEDSORE = 4;
/** 資格 5:専門看護師（人工肛門／膀胱ケア） */
export const CERTIFICATION_SPECIALITY_NURSE_URINARY_BLADDER = 5;
/** 資格 6:保健師 */
export const CERTIFICATION_PUBLIC_HEALTH_NURSE = 6;
/** 資格 7:助産師 */
export const CERTIFICATION_MIDWIFE = 7;
/** 資格 8:理学療法士(PT) */
export const CERTIFICATION_PT = 8;
/** 資格 9:作業療法士(OT) */
export const CERTIFICATION_OT = 9;
/** 資格 10:言語聴覚士(ST) */
export const CERTIFICATION_ST = 10;
/** 資格 11:ケアマネジャー */
export const CERTIFICATION_CARE_MANAGER = 11;
/** 資格 12:看護補助 */
export const CERTIFICATION_NURSE_ASSISTANT = 12;
/** 資格 13:看護学生 */
export const CERTIFICATION_NURSE_STUDENT = 13;
/** 資格 14:精神保健福祉士(PSW) */
export const CERTIFICATION_PSW = 14;
/** 資格 15:その他 */
export const CERTIFICATION_OTHER = 15;
/** 資格 16:専門看護師（看取り） */
export const CERTIFICATION_SPECIALITY_NURSE_END_OF_LIFE_CARE = 16;
/** 資格 17:専門看護師（特定） */
export const CERTIFICATION_NURSSE_PRACTITIONER = 17;

/** 保険区分 1:医療保険訪問 */
export const INSURANCE_DIV_MEDICAL = 1;
/** 保険区分 2:介護保険訪問 */
export const INSURANCE_DIV_CARE = 2;
/** 保険区分 3:自費訪問 */
export const INSURANCE_DIV_OWNEXPENSE = 3;

/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 0:定期巡回以外 */
export const REGULAR_VISIT_DIV_NOTREGULAR = 0;
/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 1:定期巡回 */
export const REGULAR_VISIT_DIV_REGULAR = 1;
/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 2:随時巡回 */
export const REGULAR_VISIT_DIV_ANYTIME = 2;
/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 3:定期か随時か選択 */
export const REGULAR_VISIT_DIV_REGULAR_OR_ANYTIME = 3;

/** 訪問時間区分 1:夜間訪問看護 */
export const TIME_TYPE_NIGHT = 1;
/** 訪問時間区分 2:早朝訪問看護 */
export const TIME_TYPE_MORNING = 2;
/** 訪問時間区分 3:深夜訪問看護 */
export const TIME_TYPE_MID_NIGHT = 3;

/** 訪問状態:移動中 */
export const MODE_MOVE = 1;
/** 訪問状態:訪問中 */
export const MODE_STAY = 2;
