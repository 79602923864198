















import { Component, Prop, Vue } from "vue-property-decorator";
import { getVisitMemo } from "@/components/schedule/common";
import sanitizeHTML from "sanitize-html";
import { VisitInfo } from "./types";

@Component
export default class VisitingMemo extends Vue {
  /** 訪問メモ情報 */
  @Prop({ default: {} }) visitInfo!: VisitInfo;

  /** 文字サイズ(firestore) */
  @Prop({ default: 0 }) selectFontSize!: number; // ホームで選択した文字サイズ

  /** サニタイズ */
  private sanitize = sanitizeHTML;

  /** 訪問メモをポップアップするかどうか */
  public get IsDispMemo(): boolean {
    if (this.visitInfo.display === 0 || this.visitInfo.memo === "") {
      return false;
    }
    return true;
  }

  /** 訪問メモURL対応後 */
  private get Memo(): string {
    return getVisitMemo(this.visitInfo.memo);
  }

  private get MemoStyle(): string {
    let style = "font-size: ";
    let fontRate = 1;
    if (this.selectFontSize === 1) {
      fontRate = 1.15;
    } else if (this.selectFontSize === 2) {
      fontRate = 1.3;
    }
    if (this.$vuetify.breakpoint.mdAndUp) {
      style += "1.5rem;";
    } else if (this.$vuetify.breakpoint.smOnly) {
      style += "calc(2.5vw*" + fontRate + ");";
    } else {
      style += "calc(4vw*" + fontRate + ");";
    }
    return style;
  }
}
