















import { Component, Mixins } from "vue-property-decorator";
import AddUpOfficeSelect from "@/components/common_ibow/AddUpOfficeSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: { AddUpOfficeSelect }
})
export default class ExcelOutput extends Mixins(AxiosMixin, UtilMixin) {
  /** 変数 */

  /** 出力対象事業所ID(利用者絞り込み用) */
  private officeIds = [];

  /** 関数 */

  /** Excel出力ボタン押下時 */
  private exportExcel() {
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/master/dataio/excel/output",
      {
        office_ids: this.officeIds
      },
      res => {
        if (res.data.size) {
          const nowDt = new Date();
          const nowDateTimeStr = this.dateToStr(nowDt, "yyyyMMdd_HHmmss");

          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = `Excel出力_${nowDateTimeStr}.xlsx`;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }
}
