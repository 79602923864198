











import { Component, Mixins } from "vue-property-decorator";
import PatientMixin from "@/mixins/patientMixin";
import { COLLECTION_SEARCH_COND_VISIT_LIST } from "@/const/envFireStore";
import PatientSearch from "@/components/patientsearch/PatientSearch.vue";
import { PatientDispInfo } from "@/components/patient/types";

@Component({
  components: {
    PatientSearch
  }
})
export default class PatientList extends Mixins(PatientMixin) {
  // fire store 保存先コレクション
  private collection_name = COLLECTION_SEARCH_COND_VISIT_LIST;

  // 行く
  private clickMove(patientId: number, patient: PatientDispInfo): void {
    // クリック不可
    if (patient.is_not_click_to_visit) {
      return;
    }
    this.$router.push({ path: `/visit/${patientId}/move` });
  }

  // ログイン職員がPTの場合、精神利用者は選択できないのでグレーアウト
  private itemClass(item: PatientDispInfo): string {
    return item.is_not_click_to_visit ? "grey lighten-5 pointer-none" : "";
  }
}
