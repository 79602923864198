






































































































































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import { Choice } from "@/types";
import {
  SearchCondition,
  ShareFireInfo,
  PatientShareFileInfo
} from "@/components/sharefile/type";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";

@Component
export default class FileListCard extends Mixins(ShareFileMixin) {
  @Prop() search!: SearchCondition; // 検索条件
  @Prop() shareFiles!: ShareFireInfo[] | PatientShareFileInfo[]; // ファイル取得
  @Prop() staffs!: Choice[]; // 職員一覧
  @Prop() fileEditFlag!: boolean; // ファイル編集フラグ
  @Prop({ default: false }) isPatient!: boolean; // 利用者ファイルかどうか
  @Prop({ default: "共有ファイルがありません。" }) noDataText!: string; // データがない場合の表記

  @Emit("click:edit")
  private editPatientShareFile(
    data: PatientShareFileInfo
  ): PatientShareFileInfo {
    return data;
  }
  @Emit("click:patient")
  private onClickPatient(patientId: number): number {
    return patientId;
  }
  // カードサイズ取得
  private get cardSize(): string {
    switch (this.search.cards_size) {
      case 0:
        return "ibow2-file-card-small";
      case 2:
        return "ibow2-file-card-large";
      default:
        return "ibow2-file-card-medium";
    }
  }

  @Emit("click:copy")
  private copyPatientShareFile(
    data: PatientShareFileInfo
  ): PatientShareFileInfo {
    return data;
  }
}
