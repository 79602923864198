




























import { Component, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { OFFICE_STAFF } from "#/const";

interface MenuButton {
  id: number;
  avater_color: string;
  icon: string;
  title: string;
  url: string;
  info_count: string;
}

@Component({})
export default class extends Mixins(UtilMixin) {
  private get Buttons(): MenuButton[] {
    const buttons = [
      {
        id: 1,
        avater_color: "red",
        icon: "mdi-file-document-multiple-outline",
        title: "統計",
        url: "/dashboard/statistics",
        info_count: ""
      },
      {
        id: 2,
        avater_color: "pink",
        icon: "mdi-file-document-multiple-outline",
        title: "帳票",
        url: "/dashboard/report",
        info_count: ""
      }
    ];
    if (this.loginUserAuthId != OFFICE_STAFF.AUTH.COMMON) {
      buttons.push({
        id: 3,
        avater_color: "light-green",
        icon: "mdi-file-document-multiple-outline",
        title: "表出力",
        url: "/dashboard/tables",
        info_count: ""
      });
    }
    return buttons;
  }

  private clickCard(item: MenuButton) {
    this.$router.push(item.url);
  }
}
