




















































































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import {
  OwnExpenseServiceItem,
  DefaultOwnExpenseServiceItem
} from "@/views/master/own_expense_service/OwnExpenseServiceList.vue";
import { VForm } from "@/types";
import { OwnExpenseServiceCategory } from "@/views/master/own_expense_service/OwnExpenseServiceList.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

interface SelectItems {
  text: string | number | object;
  value: string | number | object;
}

@Component
export default class EditServiceItemDialog extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean; //ダイアログ表示フラグ

  @Prop({
    type: Object,
    default: () => {
      return {
        id: null,
        own_expense_category_id: null,
        name: "",
        amount: 0,
        tax_div: 0,
        is_medical_deducation: 1,
        code: "",
        remarks: "",
        sort_no: null
      };
    }
  })
  item!: OwnExpenseServiceItem;

  @Prop({ default: () => [] }) categories!: OwnExpenseServiceCategory[];

  private cloneItem: OwnExpenseServiceItem = DefaultOwnExpenseServiceItem();

  private taxClassifications: SelectItems[] = [
    {
      text: "非課税",
      value: 3
    },
    {
      text: "内税（8％）",
      value: 4
    },
    {
      text: "内税（10％）",
      value: 1
    },
    {
      text: "外税（8％）",
      value: 5
    },
    {
      text: "外税（10％）",
      value: 2
    }
  ];

  private deductions: SelectItems[] = [
    {
      text: "なし",
      value: 0
    },
    {
      text: "あり",
      value: 1
    }
  ];

  get category() {
    return this.categories.find(category => {
      return category.id === this.cloneItem.own_expense_category_id;
    })?.name;
  }

  get IsDelete() {
    return this.cloneItem.id;
  }

  created() {
    this.cloneItem = { ...this.item };
  }

  //保存
  private async didClickSaveButton() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.saveServiceItem(this.cloneItem);
    this.closeDialog();
  }

  @Emit("saveServiceItem")
  private saveServiceItem(item: unknown) {
    return item;
  }

  //削除
  private async didClickRemoveButton() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.deleteServiceItem(this.cloneItem);
    this.closeDialog();
  }

  @Emit("deleteServiceItem")
  private deleteServiceItem(item: unknown) {
    return item;
  }

  //キャンセル
  private didClickCancelButton() {
    this.closeDialog();
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
