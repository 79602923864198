

















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class extends Vue {
  /** ローディング中かどうか */
  @Prop() private readonly loading!: boolean;
}
