import { render, staticRenderFns } from "./NoticePanel.vue?vue&type=template&id=69330b47&scoped=true&"
import script from "./NoticePanel.vue?vue&type=script&lang=ts&"
export * from "./NoticePanel.vue?vue&type=script&lang=ts&"
import style0 from "./NoticePanel.vue?vue&type=style&index=0&id=69330b47&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69330b47",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VDataTable,VRow,VSpacer})
