import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:高齢受給者証情報 */
export const headerOldAge: StatisticHeader = {
  id: STATISTIC_COL_ID.OLD_AGE,
  text: "高齢受給者証情報",
  is_checked: false,
  value: [
    {
      text: "開始日（高齢受給者証）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.OLD_AGE}.start_date`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（高齢受給者証）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.OLD_AGE}.end_date`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "負担割合（高齢受給者証）",
      select_name: "負担割合",
      value: `${STATISTIC_COL_ID.OLD_AGE}.str_payment_rate`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.NUMBER
    }
  ]
};
