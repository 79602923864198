import Base from "../base";

/** 検索文字 */
export interface DiseaseWord {
  code: string;
  name: string;
  name_kana: string;
  selected?: boolean;
}

export const DefaultDiseaseWord = (): DiseaseWord => ({
  code: "",
  name: "",
  name_kana: ""
});

/** 未コード化傷病名 */
export const UncodedDisease = (): DiseaseWord => ({
  code: "0000999",
  name: "＊＊　未コード化傷病名　＊＊",
  name_kana: "＊＊　ミコードカショウビョウメイ　＊＊"
});

/** 合成情報 */
export interface DiseaseMeta extends Base {
  id: number | string;
  name: string;
  name_kana: string;
  words: DiseaseWord[];
  selected?: boolean;
}

export const DefaultDiseaseMeta = (): DiseaseMeta => ({
  id: 0,
  name: "",
  name_kana: "",
  words: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export const DefaultArrayDiseaseMeta = (): DiseaseMeta[] => [];

/** 傷病名検索結果セット */
export interface SearchSet {
  prefixs: DiseaseWord[];
  diseases: DiseaseWord[];
  suffixs: DiseaseWord[];
}

export const DefaultSearchSet = (): SearchSet => ({
  prefixs: [],
  diseases: [],
  suffixs: []
});
