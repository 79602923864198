import AppChatRoom from "./AppChatRoom.vue";

export default {
  title: "Example/AppChatRoom",
  component: AppChatRoom,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatRoom },
  template: '<AppChatRoom v-bind="$props" />'
});

export const ChatRoom = Template.bind({});

ChatRoom.args = {
  selfEwellUserId: 1,
  chats: [
    {
      id: 1,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "麻生太郎",
      is_own: true,
      chat_type: 0,
      date: new Date(),
      read_count: 2,
      stamp_path: "",
      image_path: ""
    },
    {
      id: 2,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "河野",
      is_own: false,
      chat_type: 0,
      date: new Date(),
      read_count: 1,
      stamp_path:
        "https://1.bp.blogspot.com/-l4Xa9FbvkWE/XXXOfQfo-wI/AAAAAAABUuk/IphjktvChUAJTJnF8OiJLa8p9iX1KG5xQCLcBGAs/s1600/figure_tsumitate.png",
      image_path: ""
    },
    {
      id: 3,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "麻生太郎",
      is_own: true,
      chat_type: 1,
      date: new Date(),
      read_count: 1,
      stamp_path:
        "https://1.bp.blogspot.com/-l4Xa9FbvkWE/XXXOfQfo-wI/AAAAAAABUuk/IphjktvChUAJTJnF8OiJLa8p9iX1KG5xQCLcBGAs/s1600/figure_tsumitate.png",
      image_path: ""
    },
    {
      id: 4,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "河野",
      is_own: false,
      chat_type: 1,
      date: new Date(),
      read_count: 0,
      stamp_path:
        "https://1.bp.blogspot.com/-l4Xa9FbvkWE/XXXOfQfo-wI/AAAAAAABUuk/IphjktvChUAJTJnF8OiJLa8p9iX1KG5xQCLcBGAs/s1600/figure_tsumitate.png",
      image_path: ""
    },
    {
      id: 5,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "河野",
      is_own: false,
      chat_type: 1,
      date: new Date(),
      read_count: 0,
      stamp_path:
        "https://1.bp.blogspot.com/-l4Xa9FbvkWE/XXXOfQfo-wI/AAAAAAABUuk/IphjktvChUAJTJnF8OiJLa8p9iX1KG5xQCLcBGAs/s1600/figure_tsumitate.png",
      image_path: ""
    },
    {
      id: 6,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "河野",
      is_own: false,
      chat_type: 1,
      date: new Date(),
      read_count: 0,
      stamp_path:
        "https://1.bp.blogspot.com/-l4Xa9FbvkWE/XXXOfQfo-wI/AAAAAAABUuk/IphjktvChUAJTJnF8OiJLa8p9iX1KG5xQCLcBGAs/s1600/figure_tsumitate.png",
      image_path: ""
    },
    {
      id: 7,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "麻生太郎",
      is_own: true,
      chat_type: 0,
      date: new Date(),
      read_count: 2,
      stamp_path: "",
      image_path: ""
    },
    {
      id: 8,
      text:
        "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
      name: "麻生太郎",
      is_own: true,
      chat_type: 0,
      date: new Date(),
      read_count: 2,
      stamp_path: "",
      image_path: ""
    }
  ]
};
