























import { Component, Mixins, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import AxiosMixin from "@/mixins/axiosMixin";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import FiletagEditDialog from "@/components/master/filetag/FiletagEditDialog.vue";

interface Item {
  id: number;
  name: string;
  is_edit: number;
  tag_type: number;
}

@Component({
  components: {
    FiletagEditDialog,
    CommonMasterList
  }
})
export default class FileTagList extends Mixins(AxiosMixin) {
  //編集ダイアログ
  @Ref("edit-dialog") private readonly editDialog!: FiletagEditDialog;

  //ファイルタグヘッダー
  private headers: DataTableHeader[] = [
    {
      text: "表示名称",
      value: "name",
      align: "start",
      width: ""
    }
  ];

  private items: Item[] = []; //ファイルタグアイテム

  public created(): void {
    this.fetchFileTags();
  }

  //データを取得
  private fetchFileTags(): void {
    this.postJsonCheck(window.base_url + "/api/master/filetag/get", {}, res => {
      this.items = res.data.file_tags;
    });
  }

  private recordClass(item: Item) {
    // tag_type 0 はユーザーが作成したタグ
    if (item.tag_type === 0) {
      return "draggable";
    }

    // システム代行・ファイル登録通知は編集中ドラッグ不可
    return "undraggable";
  }

  //編集ダイアログ開く（新規作成）
  private newItem() {
    this.editDialog.openNew();
  }

  //編集ダイアログ開く（編集）
  private editItem(item: Item) {
    this.editDialog.openEdit(item);
  }

  //項目削除（編集）
  private deleteItem(item: Item) {
    this.postJsonCheck(
      window.base_url + "/api/master/filetag/delete",
      {
        file_tag: item
      },
      () => {
        this.fetchFileTags();
      }
    );
  }

  private saveOrder() {
    // ソート番号更新処理
    this.postJsonCheck(
      window.base_url + "/api/master/filetag/save_order",
      {
        file_tags: this.items
      },
      () => {
        this.fetchFileTags();
      }
    );
  }

  private editFunc(item: Item) {
    return item.is_edit === 1 || item.is_edit !== 0;
  }

  private getHelpMessage(item: Item): string {
    // ファイル登録通知のみヘルプメッセージを表示する
    if (item.tag_type === 2) {
      return "タグ選択時、利用者ファイルが登録されたことを通知します。\n通知先やタグの表示/非表示はマスタメンテ＞事業所マスタ＞通知で設定できます。";
    }

    return "";
  }
}
