




































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import * as appDate from "#/utility/appDate";

@Component({
  components: {}
})
export default class Record2BulkOutput extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** 現在日Dateオブジェクト */
  private now = new Date();

  /** 前日Dateオブジェクト */
  private beforeDay = new Date(
    `${this.now.getFullYear()}/${this.now.getMonth() + 1}/${this.now.getDate() -
      1}`
  );

  private targetDate = this.dateToStr(this.beforeDay, "yyyy-MM-dd");

  /** 出力処理でエラーが発生したかどうか */
  private hasError = false;

  /** PDF出力ボタン押下時 */
  private async outputPdf() {
    this.hasError = false;

    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/master/dataio/record2/output",
      { target_date: this.targetDate },
      res => {
        if (res.data.path == "") {
          this.hasError = true;
          return;
        }

        const a = document.createElement("a");

        // DLさせるzipファイル名
        a.download = `${this.dateToStr(
          appDate.strToDateObj(this.targetDate),
          "yyyyMMdd"
        )}.zip`;
        a.href = res.data.path;
        a.click();
      }
    );
  }
}
