




























import { Component, Vue } from "vue-property-decorator";

interface MenuButton {
  id: number;
  avater_color: string;
  icon: string;
  title: string;
  url: string;
  info_count: string;
}

@Component({})
export default class ShreFile extends Vue {
  private buttons: MenuButton[] = [
    {
      id: 1,
      avater_color: "red",
      icon: "mdi-file",
      title: "共有ファイル",
      url: "/sharefile/list",
      info_count: ""
    },
    {
      id: 2,
      avater_color: "pink",
      icon: "mdi-file-account",
      title: "利用者ファイル",
      url: "/sharefile/patient",
      info_count: ""
    }
  ];

  private clickCard(item: MenuButton) {
    this.$router.push(item.url);
  }
}
