
































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import { VisitSchdule, Rule } from "#/model/schedule";
import { OfficeStaffChoice, DefaultOfficeStaffChoice } from "#/model/staff";

@Component
export default class VisitPlanVisitor extends Mixins() {
  /** 訪問予定 */
  @Prop({ default: () => ({}) }) visitSchedule!: VisitSchdule;

  /** ルール */
  @Prop({ default: () => ({}) }) rule!: Rule;

  /** 訪問可能職種リスト */
  @Prop({ default: () => [] }) visitableDivs!: string[];

  /** 同行可能職種リスト */
  @Prop({ default: () => [] }) accompanableDivs!: string[];

  /** 訪問者一覧 */
  @Prop({ default: () => [] }) staffs!: OfficeStaffChoice[];

  /** 主訪問者 */
  @Prop({ default: () => 0 }) mainStaff!: number;

  /** 副訪問者１ */
  @Prop({ default: () => 0 }) subStaff1!: number;

  /** 副訪問者２ */
  @Prop({ default: () => 0 }) subStaff2!: number;

  /** 主訪問者変更時 */
  @Emit("update:mainStaff") updateMainStaff(value: number) {
    return value;
  }

  /** 副訪問者１変更時 */
  @Emit("update:subStaff1") updateSubStaff1(value: number) {
    return value;
  }

  /** 副訪問者２変更時 */
  @Emit("update:subStaff2") updateSubStaff2(value: number) {
    return value;
  }

  /** 主訪問者 */
  private get MainStaff(): number {
    return this.mainStaff;
  }
  private set MainStaff(value: number) {
    this.updateMainStaff(value);
  }

  /** 副訪問者１ */
  private get SubStaff1(): number {
    return this.subStaff1;
  }
  private set SubStaff1(value: number) {
    this.updateSubStaff1(value);
  }

  /** 副訪問者２ */
  private get SubStaff2(): number {
    return this.subStaff2;
  }
  private set SubStaff2(value: number) {
    this.updateSubStaff2(value);
  }

  /** 訪問可能職種 */
  private get VisitableText(): string {
    if (!this.visitableDivs) {
      return "";
    }
    return this.visitableDivs.join("/");
  }

  /** 同行可能職種 */
  private get AcconpanableText(): string {
    if (!this.accompanableDivs) {
      return "";
    }
    return this.accompanableDivs.join("/");
  }

  /** 主訪問者リスト */
  private get MainStaffs(): OfficeStaffChoice[] {
    const staffs = this.staffs.filter(
      staff => staff.visitable || staff.visitable_mental
    );
    return [DefaultOfficeStaffChoice(), ...staffs];
  }

  /** 副訪問者リスト */
  private get SubStaffs(): OfficeStaffChoice[] {
    const staffs = this.staffs.filter(
      staff => staff.accompanable || staff.accompanable_mental
    );
    return [DefaultOfficeStaffChoice(), ...staffs];
  }

  /** 主訪問者をチェックする */
  private get ValidateVisitorMain(): boolean | string {
    if (this.MainStaff == 0) {
      return true;
    }
    if (this.MainStaff == this.SubStaff1) {
      return "訪問者に同じ職員が選択されています";
    }
    if (this.MainStaff == this.SubStaff2) {
      return "訪問者に同じ職員が選択されています";
    }
    return true;
  }

  /** 副訪問者１をチェックする */
  private get ValidateVisitorSub1(): boolean | string {
    if (this.SubStaff1 == 0) {
      return true;
    }
    if (this.SubStaff1 == this.MainStaff) {
      return "訪問者に同じ職員が選択されています";
    }
    if (this.SubStaff1 == this.SubStaff2) {
      return "訪問者に同じ職員が選択されています";
    }
    return true;
  }

  /** 副訪問者２をチェックする */
  private get ValidateVisitorSub2(): boolean | string {
    if (this.SubStaff2 == 0) {
      return true;
    }
    if (this.SubStaff2 == this.MainStaff) {
      return "訪問者に同じ職員が選択されています";
    }
    if (this.SubStaff2 == this.SubStaff1) {
      return "訪問者に同じ職員が選択されています";
    }
    return true;
  }
}
