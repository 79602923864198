
























































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import RepeatNone from "@/components/schedule/RepeatNone.vue";
import RepeatEveryWeek from "#/components/calendar/RepeatEveryWeek.vue";
import RepeatMonthlyDateAssign from "#/components/calendar/RepeatMonthlyDateAssign.vue";
import RepeatMonthlyWeekAssign from "#/components/calendar/RepeatMonthlyWeekAssign.vue";
import RepeatMonthlyWeekDayAssign from "#/components/calendar/RepeatMonthlyWeekDayAssign.vue";
import RepeateLimitArea from "#/components/calendar/RepeateLimitArea.vue";
import { Rule, VisitSchdule } from "#/model/schedule";
import {
  RepeatDiv,
  RepeatPeriodDiv,
  RepeatItems,
  RepeatItemsNotNoRepeat,
  EditRangeDiv,
  EditRange,
  WeekDays
} from "#/components/calendar/common";

@Component({
  components: {
    RepeatNone,
    RepeatEveryWeek,
    RepeatMonthlyDateAssign,
    RepeatMonthlyWeekAssign,
    RepeatMonthlyWeekDayAssign,
    RepeateLimitArea
  }
})
export default class VisitPlanRepeat extends Vue {
  /** 訪問予定 */
  @Prop({ default: () => ({}) }) visitSchedule!: VisitSchdule;

  /** ルール */
  @Prop({ default: () => ({}) }) rule!: Rule;

  /** ルール（バックアップ） */
  @Prop({ default: () => ({}) }) bkRule!: Rule;

  /** 訪問日 */
  @Prop({ default: () => "" }) visitDate!: string;

  /** 編集範囲選択値 */
  @Prop({ default: EditRangeDiv.This }) selectedEditRange!: EditRangeDiv;

  /** 編集範囲選択値変更時 */
  @Emit("update:selectedEditRange") updateSelectedEditRange(
    selectedEditRange: EditRangeDiv
  ) {
    return selectedEditRange;
  }

  /** 繰り返し区分 */
  private RepeatDiv = RepeatDiv;

  /** 繰り返し期限区分 */
  private RepeatPeriodDiv = RepeatPeriodDiv;

  /** 繰り返し選択肢 */
  private get RepeatItems() {
    if (
      this.SelectedEditRange != EditRangeDiv.This &&
      this.SelectedEditRange != EditRangeDiv.New
    ) {
      return RepeatItemsNotNoRepeat;
    } else {
      return RepeatItems;
    }
  }

  /** 編集範囲区分 */
  private EditRangeDiv = EditRangeDiv;

  /** 編集範囲 */
  private EditRange = EditRange;

  /** 編集範囲選択値 */
  private get SelectedEditRange(): EditRangeDiv {
    return this.selectedEditRange;
  }
  private set SelectedEditRange(selectedEditRange: EditRangeDiv) {
    this.updateSelectedEditRange(selectedEditRange);
  }

  /** 繰り返し設定（表示用） */
  private get RepeatSetting(): string {
    // 繰り返し区分
    let repeatDiv = "";
    switch (this.rule.repeat_state) {
      case RepeatDiv.EveryDay:
        repeatDiv = "毎日";
        break;
      case RepeatDiv.EveryWeek:
        repeatDiv = `毎週（${this.strWeekdays()}）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedDate:
        repeatDiv = `毎月（${this.rule.visit_day}日）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedWeek:
        repeatDiv = `毎月（${this.strWeekNumbers()} ${this.strWeekdays()}）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedWeekDay:
        repeatDiv = `毎月（${this.strWeekCounts()}  ${this.strWeekdays()}）`;
        break;
    }
    // 訪問時間
    const visitTime = `
    ${this.zeroPadding(this.rule.shift_start_time_hour)}:
    ${this.zeroPadding(this.rule.shift_start_time_minute)}〜
    ${this.zeroPadding(this.rule.shift_end_time_hour)}:
    ${this.zeroPadding(this.rule.shift_end_time_minute)}
    `;
    // 期限
    let limit = this.rule.visit_limit_start_date.replaceAll("-", "/") + "〜";
    if (this.rule.visit_limit === RepeatPeriodDiv.Date) {
      limit += this.rule.visit_limit_end_date.replaceAll("-", "/");
    } else if (this.rule.visit_limit === RepeatPeriodDiv.Count) {
      limit += this.rule.visit_limit_count + "回";
    }
    return `${repeatDiv} ${visitTime} ${limit}`;
  }

  /** 日付ラベル */
  private get DateLabel(): string {
    if (this.visitSchedule.event_type == 1) {
      return "日時";
    }
    return "訪問時刻";
  }

  /** 繰り返しを変更する */
  private changeRepeat() {
    if (this.rule.repeat_state !== EditRangeDiv.This) {
      this.rule.shift_start_time_hour = this.visitSchedule.shift_start_time_hour;
      this.rule.shift_start_time_minute = this.visitSchedule.shift_start_time_minute;
      this.rule.shift_end_time_hour = this.visitSchedule.shift_end_time_hour;
      this.rule.shift_end_time_minute = this.visitSchedule.shift_end_time_minute;
    } else {
      this.visitSchedule.shift_start_time_hour = this.rule.shift_start_time_hour;
      this.visitSchedule.shift_start_time_minute = this.rule.shift_start_time_minute;
      this.visitSchedule.shift_end_time_hour = this.rule.shift_end_time_hour;
      this.visitSchedule.shift_end_time_minute = this.rule.shift_end_time_minute;
    }
  }

  /** 編集範囲を変更する */
  private changeEditRange() {
    this.rule.repeat_state = this.bkRule.repeat_state;
    switch (this.selectedEditRange) {
      case EditRangeDiv.This:
        // この予定を選択した場合は繰り返しをなしに変更
        this.rule.repeat_state = RepeatDiv.None;
        break;
      case EditRangeDiv.After:
        this.rule.visit_limit_start_date = this.visitDate;
        break;
      case EditRangeDiv.All:
        this.rule.visit_limit_start_date = this.bkRule.visit_limit_start_date;
        break;
    }
  }

  /** 日本語表記の曜日を取得する */
  private strWeekdays(): string[] {
    const result: string[] = [];
    const weekdays = this.rule.visit_week.split("");
    weekdays.forEach((weekday, i) => {
      if (weekday === "1") {
        result.push(WeekDays[i]);
      }
    });
    return result;
  }

  /** 日本語表記の週目を取得する */
  private strWeekNumbers(): string[] {
    const result: string[] = [];
    const weeks = this.rule.visit_week_weeks.split("");
    weeks.forEach((week, i) => {
      if (week === "1") {
        result.push(i + 1 + "週目");
      }
    });
    return result;
  }

  /** 日本語表記の週回を取得する */
  private strWeekCounts(): string[] {
    const result: string[] = [];
    const weeks = this.rule.visit_week_times.split("");
    weeks.forEach((week, i) => {
      if (week === "1") {
        result.push(i + 1 + "回目");
      }
    });
    return result;
  }

  /** 数値を0埋めする */
  private zeroPadding(val: number): string {
    return String(val).padStart(2, "0");
  }
}
