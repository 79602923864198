

























import { Component, Vue, Emit } from "vue-property-decorator";

@Component
export default class ScheduleManageTitle extends Vue {
  /** 新規予定追加 */
  @Emit("insertEvent") private insertEvent() {
    return;
  }
}
