



































































































import Component from "vue-class-component";
import { Emit, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { RecommendKeyword } from "#/model/inquiry";
import { SearchCondFAQ } from "@/views/contact/types";

@Component
export default class FAQSearch extends Mixins(AxiosMixin) {
  /** 検索条件 */
  @Prop() value!: SearchCondFAQ;
  /** キーワード検索欄のみを表示するか */
  @Prop({ default: false }) isOnlyKeyword!: boolean;

  /** おすすめキーワード情報 */
  private recommendKeywords: RecommendKeyword[] = [];

  private get innerValue(): SearchCondFAQ {
    return this.value;
  }

  private set innerValue(newValue: SearchCondFAQ) {
    this.input(newValue);
  }

  created() {
    if (!this.isOnlyKeyword) {
      this.fetchRecommendKeywords();
    }
  }

  /** キーワード検索解除ボタンをクリック時 */
  private noKeywordSearch() {
    this.innerValue = {
      ...this.innerValue,
      hashtag: ""
    };
    this.search();
  }

  private onClickKeyword(selectedKeyword: string) {
    this.innerValue = {
      ...this.innerValue,
      hashtag: selectedKeyword
    };
    this.search();
  }

  /** おすすめキーワード一覧を取得して表示する */
  private fetchRecommendKeywords() {
    this.postJsonCheck(
      window.base_url + "/api/inquiry/template/keywords/get",
      {},
      res => {
        if (res.data.recommends) {
          this.recommendKeywords = res.data.recommends;
        }
      }
    );
  }

  @Emit()
  private input(newValue: SearchCondFAQ): SearchCondFAQ {
    // キーワードがnullなら空文字にする
    newValue.keyword = newValue.keyword ?? "";
    return { ...newValue };
  }

  @Emit()
  private search() {
    return;
  }
}
