









































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import Hour24 from "@/components/hour24/hour24.vue";
import { Choice } from "@/types";

@Component({
  components: {
    Hour24
  }
})
export default class Hour24Board extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  private tabs: Choice[] = [
    { value: 0, text: "すべて", other_string: "1" },
    { value: 1, text: "未読", other_string: "2" },
    { value: 2, text: "既読", other_string: "3" },
    { value: 3, text: "お気に入り", other_string: "4" }
  ];

  private currentTab = this.tabs[0].value;
  private searchMode = this.tabs[0].other_string;
  private changeMode(newTab?: string) {
    this.searchMode = newTab ?? this.tabs[0].other_string;
  }

  /** 画面幅は狭いか */
  private get IsNarrow() {
    return this.$vuetify.breakpoint.width < 750;
  }
}
