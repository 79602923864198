

























import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

interface Item {
  name: string; // 利用者名
  sex: string; // 性別
  birth: string; // 誕生日（和暦）
  office: string; // 事業所名
}

@Component
export default class DuplicateCheckDialog extends Mixins(AxiosMixin) {
  @Prop({ default: "" }) firstName!: string;

  @Prop({ default: "" }) lastName!: string;

  private isOpen = false;

  private headers = [
    {
      text: "氏名",
      align: "start",
      sortable: false,
      value: "name"
    },
    {
      text: "性別",
      align: "start",
      sortable: false,
      value: "sex"
    },
    {
      text: "誕生日",
      align: "start",
      sortable: false,
      value: "birth"
    },
    {
      text: "事業所",
      align: "start",
      sortable: false,
      value: "office"
    }
  ];

  private items: Item[] = [];

  public open() {
    this.isOpen = true;
  }

  @Watch("isOpen")
  private fetchItems() {
    this.items = [];
    if (!this.isOpen) return;
    if (!this.firstName || !this.lastName) return;
    this.postJsonCheck(
      window.base_url + "/api/patients/accept/dupcheck",
      { first_name: this.firstName, last_name: this.lastName },
      res => {
        if (res.data.duplicate_patients) {
          this.items = res.data.duplicate_patients;
        }
      }
    );
  }

  private get message(): string {
    if (!this.firstName || !this.lastName) {
      return "氏名が入力されていません。";
    }
    if (this.items.length) {
      return `以下${this.items.length}名の利用者が重複しています。`;
    } else {
      return "重複する利用者はいませんでした。";
    }
  }
}
