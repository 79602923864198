














































































































































































































































































































import AppDisease from "@/components/disease/AppDisease.vue";
import { Component, Mixins, Ref, Watch, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  CareSummaryModel,
  DiseaseStatus,
  NurseProgress,
  DefaultCaresummaryRemand,
  DefaultCareSummaryModel,
  DefaultDiseaseStatus,
  DefaultNurseProgress,
  DefaultVital
} from "@/components/patient/caresummary/types";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import Vital from "@/components/patient/common/Vital.vue";
import ObservationItem from "@/components/patient/common/ObservationItem.vue";
import SymptomReport from "@/components/patient/common/SymptomReport.vue";
import CaresummaryNursingDetail from "@/components/patient/common/nursingdetail/CaresummaryNursingDetail.vue";
import Bedsore from "@/components/patient/common/Bedsore.vue";
import RiskFactor from "@/components/patient/common/RiskFactor.vue";
import {
  NursingDetailMaster,
  ObservationCategoryMaster,
  BedsoreMaster,
  RiskFactorMaster
} from "#/model/master";
import {
  VisitModel,
  DefaultVisitModel
} from "@/components/patient/record2/types";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import RemandExpand from "@/components/patient/common/remand/RemandExpand.vue";
import PatientDoctorSelect from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import PatientRelationInsSelect from "@/components/patient/common_ibow/PatientRelationInsSelect.vue";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import { Choice } from "@/types";
import { Link } from "#/types";
import JokusoMixin from "@/mixins/jokusoMixin";

@Component({
  components: {
    AppDisease,
    TextDayChangePicker,
    Vital,
    ObservationItem,
    SymptomReport,
    CaresummaryNursingDetail,
    Bedsore,
    RiskFactor,
    PatientSaveArea,
    RemandExpand,
    PatientDoctorSelect,
    PatientRelationInsSelect,
    PatientExpantionAreas
  }
})
export default class CareSummary extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin,
  RulesSaveMixin,
  EditMixin,
  PatientMixin,
  JokusoMixin
) {
  //フォーム
  @Ref("patient-header") private readonly patientHeader!: PatientExpantionAreas;

  @Prop() readonly pheader!: PatientHeader;

  //利用者ID
  private patientId = 0;

  //看護サマリID
  private id = 0;

  //プログレスバー項目
  private get Links() {
    const links: Link[] = [];
    if (this.caresummary.remand.id && this.caresummary.remand.editor_staff_id) {
      links.push({
        text: "差戻し情報",
        key: "remand",
        add_info: this.caresummary.remand.comment
      });
    }
    links.push(
      {
        text: "傷病・既往歴・病状",
        key: "medical_history"
      },
      {
        text: "訪問看護経過",
        key: "care_progress"
      },
      {
        text: "看護記録",
        key: "care_record"
      }
    );
    return links;
  }

  //看護サマリ
  private caresummary: CareSummaryModel = DefaultCareSummaryModel();

  //引き当て用傷病・既往歴・病状
  private diseaseStatus: DiseaseStatus = DefaultDiseaseStatus();

  //引き当て用訪問看護経過
  private nurseProgress: NurseProgress = DefaultNurseProgress();

  //引き当て用訪問記録
  private visitRecord: VisitModel = DefaultVisitModel();

  //観察項目カテゴリ
  private observations: ObservationCategoryMaster = {
    is_display_category: false,
    observation_categories: []
  };

  //看護内容
  private nursingDetails: NursingDetailMaster = {
    care_large_contents: []
  };

  //症状報告
  private conditions: Choice[] = [];

  //担当者
  private staffs: Choice[] = [];

  //宛先
  private send_types: Choice[] = [];

  //医師
  private doctors: Choice[] = [];

  //担当者(関連機関)
  private personnels: Choice[] = [];

  //褥瘡
  private bedsores: BedsoreMaster = {
    parts: [],
    depths: [],
    exudates: [],
    sizes: [],
    infections: [],
    granulations: [],
    necrotics: [],
    pockets: []
  };

  //観察項目設定区分 0:観察項目最大21項目とし、記録書Ⅱ（カルテ部分）をA4１枚に収める 1:観察項目の登録数制限を解除する（観察項目カテゴリ表示）
  private adl_setting_div = 0;

  //危険因子
  private risk_factors: RiskFactorMaster = {
    cans: [],
    existences: []
  };

  private staff_titles: Choice[] = [];

  private is_first = false;

  /** 危険因子の評価表示フラグ */
  private is_risk_factor = 0;

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  //可変褥瘡リンク
  private get linkJokuso() {
    return this.getLinkJokuso(this.bedsores, this.is_risk_factor);
  }

  private get StorageKey(): string {
    return `caresummary-${this.loginUser.id}-${this.patientId}-${this.id}`;
  }

  @Watch("caresummary", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      caresummary: this.caresummary,
      displayPreview: this.displayPreview,
      bedsores: this.bedsores,
      adl_setting_div: this.adl_setting_div,
      is_risk_factor: this.is_risk_factor
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  /** 褥瘡 */
  @Watch("bedsores", { deep: true }) private watchBedsores() {
    this.watchData();
  }

  /** 観察項目 */
  @Watch("adl_setting_div", { deep: true }) private watchAdlSettingDiv() {
    this.watchData();
  }

  /** 危険因子の評価 */
  @Watch("is_risk_factor", { deep: true }) private watchIsRiskFactor() {
    this.watchData();
  }

  @Watch("caresummary.visit_start_datetime")
  watchVisitDate() {
    if (!this.IsLoaded) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/caresummary/get",
      {
        patient_id: this.patientId,
        id: this.id,
        visit_start_date: this.caresummary.visit_start_datetime
      },
      res => {
        //褥瘡データと(親)事業所情報のみ取り直し
        this.bedsores = res.data.bedsores;
        this.adl_setting_div = res.data.adl_setting_div;
        // 以前の褥瘡データは削除する
        this.caresummary.bedsores = this.filterJokuso(
          this.bedsores,
          this.caresummary.bedsores
        );
        this.is_risk_factor = res.data.is_risk_factor;
      }
    );
  }

  created(): void {
    this.patientId = Number(this.$route.params.id);
    this.id = Number(this.$route.params.care_summary_id);
    this.updateData();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/caresummary`
      },
      {
        text: "看護サマリ",
        disabled: true,
        to: ""
      }
    ]);
  }

  //データ更新
  private updateData() {
    this.postJsonCheck(
      window.base_url + "/api/patient/caresummary/get",
      { patient_id: this.patientId, id: this.id },
      res => {
        this.caresummary = res.data.care_summary;
        this.diseaseStatus = res.data.disease_status;
        this.nurseProgress = res.data.nurse_progress;
        this.visitRecord = res.data.visit_record;
        this.observations = res.data.observations;
        this.nursingDetails = res.data.nursing_details;
        this.bedsores = res.data.bedsores;
        this.risk_factors = res.data.risk_factor;
        this.adl_setting_div = res.data.adl_setting_div;
        this.conditions = res.data.conditions;
        this.send_types = res.data.send_types;
        this.doctors = res.data.doctors;
        this.personnels = res.data.personnels;
        this.staffs = res.data.staffs;
        this.staff_titles = res.data.staff_titles;
        // 以前の褥瘡データは削除する
        this.caresummary.bedsores = this.filterJokuso(
          this.bedsores,
          this.caresummary.bedsores
        );
        this.is_risk_factor = res.data.is_risk_factor;
        if (this.id === 0) {
          this.caresummary.remand = DefaultCaresummaryRemand();
        }
        this.removeUnSelectableObservation();
        this.execAfterFetch(this.StorageKey, data => {
          this.caresummary = data.caresummary;
          this.setVital(this.caresummary.vital);
          this.displayPreview = data.displayPreview;
          this.bedsores = data.bedsores;
          this.adl_setting_div = data.adl_setting_div;
          this.is_risk_factor = data.is_risk_factor;
        });
      }
    );
  }

  //傷病・既往歴・病状最新情報反映
  private async setDiseaseStatus() {
    if (
      await this.$openConfirm(
        "傷病・既往歴・病状を最新情報に置き換えます。現在の内容が上書きされますが、よろしいですか？"
      )
    ) {
      const id = this.caresummary.id;
      const updatedAt = this.caresummary.updated_at;
      this.caresummary = { ...this.caresummary, ...this.diseaseStatus };
      this.caresummary.id = id;
      this.caresummary.updated_at = updatedAt;
    }
  }

  //訪問看護経過最新情報取得
  private async setNurseProgress() {
    if (
      await this.$openConfirm(
        "訪問看護経過を最新情報に置き換えます。現在の内容が上書きされますが、よろしいですか？"
      )
    ) {
      const id = this.caresummary.id;
      const updatedAt = this.caresummary.updated_at;
      this.caresummary = { ...this.caresummary, ...this.nurseProgress };
      this.caresummary.id = id;
      this.caresummary.updated_at = updatedAt;
    }
  }

  //看護記録最新情報取得
  private async setVisitRecord() {
    if (
      await this.$openConfirm(
        "看護記録を最新情報に置き換えます。現在の内容が上書きされますが、よろしいですか？"
      )
    ) {
      const id = this.caresummary.id;
      const updatedAt = this.caresummary.updated_at;
      this.caresummary = {
        ...this.caresummary,
        ...this.visitRecord,
        remand: this.caresummary.remand
      };
      this.caresummary.bedsores = this.visitRecord.jokusos ?? [];
      this.caresummary.care_contents = this.visitRecord.care_contents ?? [];
      this.caresummary.observations = this.visitRecord.observations ?? [];
      this.caresummary.pdf = this.visitRecord.pdf ?? [];
      this.caresummary.risk_factor = this.visitRecord.risk_factor ?? {};
      if (this.visitRecord.vitals && this.visitRecord.vitals.length > 0) {
        this.caresummary.vital = this.visitRecord.vitals[0] ?? {};
      } else {
        this.caresummary.vital = DefaultVital();
      }
      this.caresummary.id = id;
      this.caresummary.updated_at = updatedAt;
      this.removeUnSelectableObservation();
    }
  }

  //担当者追加
  private pushInCharge() {
    this.caresummary.staffs.push({
      id: 0,
      care_summary_id: 0,
      staff_id: 0
    });
  }

  //看護サマリ保存
  private saveCaresummary(isMove: boolean) {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/caresummary/save", false, isMove);
    });
  }

  //看護サマリ提出
  private async submitCaresummary(isPreview = false) {
    let ok = true;
    if (!this.patientHeader.validate()) {
      ok = false;
    }
    if (!ok) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.saveAction(2, "/api/patient/caresummary/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.caresummary.status = status;
    this.caresummary.patient_id = this.patientId;
    this.makePdf(
      window.base_url + url,
      { caresummary: this.caresummary, is_preview: isPreview },
      res => {
        this.setNoEditMode();
        if (isMove) {
          this.$router.go(-1);
        } else {
          this.id = res.data.id;
          this.caresummary.id = res.data.id;
          this.caresummary.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { care_summary_id: String(this.id) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //看護サマリ削除
  private deleteCaresummary() {
    this.caresummary.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/caresummary/delete",
      { caresummary: this.caresummary },
      () => {
        this.setNoEditMode();
        this.$router.go(-1);
      }
    );
  }

  //プレビュー
  private preview(): void {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.caresummary.patient_id = this.patientId;
      this.makePdf(window.base_url + "/api/patient/caresummary/preview", {
        caresummary: this.caresummary
      });
    });
  }

  /** 選択肢が無い観察項目は消す */
  private removeUnSelectableObservation() {
    /** マスタに存在する観察項目ID */
    const masterItemIds: number[] = [];
    /** マスタに存在する観察項目選択肢ID */
    const masterSelectItemIds: number[] = [];
    const masterCats = this.observations.observation_categories || [];
    for (const category of masterCats) {
      for (const item of category.ovservation_items || []) {
        masterItemIds.push(item.id);
        for (const selectItem of item.selet_items || []) {
          masterSelectItemIds.push(selectItem.value);
        }
      }
    }
    //選択していた観察項目選択肢がマスタから削除された場合、画面からも消す
    this.caresummary.observations = this.caresummary.observations.filter(
      obs => {
        if (
          !obs.observation_select_item_id &&
          !obs.observation_comment &&
          !masterItemIds.includes(obs.observation_item_id)
        ) {
          //看護サマリ観察項目は未入力の項目も保存されるので、未選択でコメント空でマスタから削除済みの観察項目は一覧から消す
          return false;
        }
        return masterSelectItemIds.includes(obs.observation_select_item_id);
      }
    );
  }

  //傷病チェック
  private checkMainDisease(disease: string): boolean | string {
    if (!disease) {
      return true;
    }
    let same_count = 0;
    if (disease === this.caresummary.disease1) {
      same_count++;
    }
    if (disease === this.caresummary.disease2) {
      same_count++;
    }
    if (disease === this.caresummary.disease3) {
      same_count++;
    }
    if (same_count > 1) {
      return "主傷病名に同じものが記入されています";
    }
    return true;
  }

  //担当者チェック
  private checkStaff(staffId: number) {
    if (staffId == 0) {
      return "必須です";
    }
    let count = 0;
    this.caresummary.staffs.forEach(staff => {
      if (staff.staff_id == staffId) {
        count++;
      }
    });
    if (count > 1) {
      return "担当者が重複しています。";
    }
    return true;
  }

  //担当者削除
  private async clickStaffDelete(index: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.caresummary.staffs.splice(index, 1);
    }
  }
}
