



















































































































































































import { Component, Ref } from "vue-property-decorator";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import SearchSelect from "#/components/SearchSelect.vue";
import OfficeSelects from "@/components/common_ibow/OfficeSelects.vue";
import StaffSelect from "@/components/common_ibow/StaffSelect.vue";
import { VisitRecord } from "#/model/visit";
import { Group } from "@/components/master/group/common";
import { VForm, Choice } from "@/types";
import * as appDate from "#/utility/appDate";
import BaseRecord2List, { ListItems } from "@/components/list/baseRecord2List";

interface SearchPatientPart {
  office_ids: number[];
  group_ids: number[];
  group_operator: number;
  keyword: string;
}

interface VisitRecordSubmit extends VisitRecord {
  edit: number; // 編集ボタンをソートできるように
  patient_name: string;
  patient_name_kana: string;
}

@Component({
  components: {
    BaseReportList,
    SearchSelect,
    OfficeSelects,
    StaffSelect
  }
})
export default class SubmitList extends BaseRecord2List {
  @Ref("form") private readonly form!: VForm;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  private headers = [
    {
      text: "利用者",
      value: "patient_name_sort",
      width: "170px"
    },
    {
      text: "訪問者",
      value: "visit_staff_name",
      width: "150px"
    },
    {
      text: "訪問日時",
      value: "visit_date_time",
      width: "230px"
    },
    {
      text: "記入者",
      value: "create_staff_name",
      width: "150px"
    }
  ];

  private commentHeader = {
    text: "コメント",
    value: "remand_comment"
  };

  private submitHeaders = [
    {
      text: "提出日",
      value: "submit_date_time",
      width: "160px"
    }
  ];

  /** 検索パネルを開いているか */
  private isOpenSearchPanel = false;

  /** グループ選択肢 */
  private groups: Group[] = [];

  /** 職員選択肢 */
  private staffs: Choice[] = [];

  /** 検索条件：訪問職員 */
  private searchStaffId = 0;

  /** 検索条件：編集職員 */
  private searchSubmitStaffId = 0;

  /** 最後に検索したタブ */
  private searchedTab = 0;

  /** 訪問日検索期間：開始月 */
  private searchStartMonth = "";

  /** 訪問日検索期間：終了月 */
  private searchEndMonth = "";

  /** グループ検索条件のAND/ORとチェックボックスを合わせる */
  private get searchGroupOperator(): boolean {
    return !this.searchCond.group_operator;
  }
  private set searchGroupOperator(newCond: boolean) {
    this.searchCond.group_operator = Number(!newCond);
  }

  /** デフォルト検索条件 */
  private DefaultSearchCond = (): SearchPatientPart => ({
    office_ids: [],
    group_ids: new Array(5).fill(0),
    group_operator: 1,
    keyword: ""
  });
  /** 検索条件 */
  private searchCond = this.DefaultSearchCond();
  /** 検索条件をリセット */
  private resetSearchCond() {
    const defaultCond = this.DefaultSearchCond();
    if (this.DefaultOffices) {
      defaultCond.office_ids = this.DefaultOffices;
    }
    this.searchCond = defaultCond;
    this.searchStartMonth = this.dateToStr(new Date(), "yyyy-MM");
    this.searchEndMonth = this.dateToStr(new Date(), "yyyy-MM");

    this.searchStaffId = 0;

    // 提出済みの場合
    if (this.searchedTab === 1) {
      this.searchSubmitStaffId = 0;
    }
  }
  /** デフォルト検索条件：事業所 */
  private get DefaultOffices(): number[] | undefined {
    if (this.loginUser.office_id !== 0) {
      // 所属している場合はログインユーザーの所属事業所
      return [this.loginUser.office_id];
    } else if (this.HeadOffice !== 0) {
      // 所属していない場合は先頭の事業所
      return [this.HeadOffice];
    }
    return undefined;
  }

  // 絞り込みしているかどうか
  private get IsFiltered(): boolean {
    // 未提出
    if (this.searchedTab === 0) {
      return !(
        JSON.stringify(this.searchCond.office_ids) ===
          JSON.stringify(this.DefaultOffices) &&
        !this.searchCond.group_ids.find(id => id !== 0 && id !== null) &&
        this.searchCond.group_operator === 1 &&
        !this.searchCond.keyword &&
        !this.searchStaffId
      );
    } else {
      // 提出済
      return !(
        JSON.stringify(this.searchCond.office_ids) ===
          JSON.stringify(this.DefaultOffices) &&
        !this.searchCond.group_ids.find(id => id !== 0 && id !== null) &&
        this.searchCond.group_operator === 1 &&
        !this.searchCond.keyword &&
        this.searchStartMonth === this.dateToStr(new Date(), "yyyy-MM") &&
        this.searchEndMonth === this.dateToStr(new Date(), "yyyy-MM") &&
        !this.searchStaffId &&
        !this.searchSubmitStaffId
      );
    }
  }

  created() {
    // デフォルトの検索事業所を設定
    if (this.searchCond.office_ids.length === 0 && this.DefaultOffices) {
      this.searchCond.office_ids = this.DefaultOffices;
    }
    this.searchStartMonth = this.dateToStr(new Date(), "yyyy-MM");
    this.searchEndMonth = this.dateToStr(new Date(), "yyyy-MM");

    this.fetchGroups();
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchRecord2({ tab: 0, page: 0 });
    }
  }

  /** グループリスト取得 */
  private fetchGroups() {
    this.postJsonCheck(window.base_url + "/api/master/groups/get", {}, res => {
      this.groups = res.data.groups;
    });
  }

  /** タブが切り替わった時 */
  private updateTab(obj: { tab: number; page: number }) {
    this.searchedTab = obj.tab;
    this.fetchRecord2(obj);
  }

  // 一覧取得
  private fetchRecord2(obj: { tab: number; page: number }) {
    // 提出済みタブのみ、月での絞り込みをする
    const searchStartMonth = obj.tab === 1 ? this.searchStartMonth : "";
    const searchEndMonth = obj.tab === 1 ? this.searchEndMonth : "";
    const searchStaffId = this.searchStaffId ? [this.searchStaffId] : [];
    const searchSubmitStaffId =
      this.searchSubmitStaffId && obj.tab === 1
        ? [this.searchSubmitStaffId]
        : [];
    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecords/search",
      {
        part: {
          ...this.searchCond,
          group_ids: this.searchCond.group_ids.filter(Boolean)
        },
        tab: obj.tab,
        staff_ids: searchStaffId,
        editor_staff_ids: searchSubmitStaffId,
        start_month: searchStartMonth,
        end_month: searchEndMonth,
        page: obj.page
      },
      res => {
        if (res.data.records != null) {
          this.reportList?.finishLoaded(res.data.records);
          if (obj.page != 0) {
            this.records = this.records.concat(res.data.records);
          } else {
            this.records = res.data.records;
          }
        } else {
          this.reportList?.finishLoaded([]);
        }
        if (res.data.staffs != null) {
          this.staffs = res.data.staffs;
        }
      }
    );
  }

  /** 検索条件バリデーション：事業所選択 */
  private checkOfficeSelect(): boolean | string {
    //1つも選択していない場合
    if (!this.searchCond.office_ids || this.searchCond.office_ids.length == 0) {
      return "1事業所は選択してください";
    }
    //5つ以上選択している場合
    if (!this.searchCond.office_ids || this.searchCond.office_ids.length > 5) {
      return "最大5事業所まで選択可能です";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の開始月 */
  private checkStartMonth(
    startMonthStr: string,
    endMonthStr: string
  ): boolean | string {
    const startMonth = appDate.strToDateObj(startMonthStr);
    const endMonth = appDate.strToDateObj(endMonthStr);

    // 開始月が入力されていない場合
    if (!startMonthStr) {
      return "開始月を入力すると検索できます";
    }
    // 有効な開始月として解釈できない場合
    if (Number.isNaN(startMonth.getDate())) {
      return "開始月の入力形式に誤りがあります";
    }
    // 開始月が終了月よりも後である場合
    if (startMonth > endMonth) {
      return "開始月は終了月よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了月 */
  private checkEndMonth(
    startMonthStr: string,
    endMonthStr: string
  ): boolean | string {
    const startMonth = appDate.strToDateObj(startMonthStr);
    const endMonth = appDate.strToDateObj(endMonthStr);
    // 終了月が入力されていない場合
    if (!endMonthStr) {
      return "終了月を入力すると検索できます";
    }
    // 有効な終了月として解釈できない場合
    if (Number.isNaN(endMonth.getDate())) {
      return "終了月の入力形式に誤りがあります";
    }
    // 終了月が開始月よりも前である場合
    if (startMonth > endMonth) {
      return "終了月は開始月よりも後に設定してください";
    }
    return true;
  }

  /**
   * 検索ボタン押下
   */
  private async clickSearch() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.reportList?.resetLoaded();
    this.fetchRecord2({ tab: this.searchedTab, page: 0 });
  }

  // 差し戻し
  private async clickRemand(item: ListItems) {
    if (await this.remand(item)) {
      this.reportList?.clickRemandBtn(item);
    }
  }
}
