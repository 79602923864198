














































































































































































































































































































import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import PatientInfo from "@/components/patient/PatientInfo.vue";
import BasicInfo from "@/components/patient/record2/BasicInfo.vue";
import FileUpload from "#/components/FileUpload.vue";
import AddItem from "@/components/patient/record2/AddItem.vue";
import Vital from "@/components/patient/common/Vital.vue";
import ObservationItem from "@/components/patient/common/ObservationItem.vue";
import SymptomReport from "@/components/patient/common/SymptomReport.vue";
import NursingDetail from "@/components/patient/common/nursingdetail/NursingDetail.vue";
import Bedsore from "@/components/patient/common/Bedsore.vue";
import RiskFactor from "@/components/patient/common/RiskFactor.vue";
import RemandExpand from "@/components/patient/common/remand/RemandExpand.vue";
import Infomation from "@/components/patient/record2/Infomation.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import GafEvaluate from "@/components/patient/record2/GafEvaluate.vue";
import BaseVisit from "@/views/BaseVisit";
import AxiosMixin from "@/mixins/axiosMixin";
import { VForm } from "@/types";
import EditMixin from "@/mixins/editMixin";
import { CHECK_CODE } from "@/components/patient/record2/const";
import * as constant from "#/const";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import { VnPlanProblem } from "#/model/vnplan";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import { Link } from "#/types";
import PatientMixin from "@/mixins/patientMixin";
import { getRemandName } from "#/model/visit";
import * as appDate from "#/utility/appDate";
import UtilMixin from "@/mixins/utilMixin";
import JokusoMixin from "@/mixins/jokusoMixin";
import FocusingMixin from "@/mixins/focusingMixin";

// 編集モード
export const EditMode = {
  Edit: "", // 編集
  Remand: "remand" // 差戻し
} as const;

export type EditMode = typeof EditMode[keyof typeof EditMode];

@Component({
  components: {
    PatientInfo,
    BasicInfo,
    FileUpload,
    AddItem,
    Vital,
    ObservationItem,
    SymptomReport,
    NursingDetail,
    Bedsore,
    RiskFactor,
    RemandExpand,
    Infomation,
    PatientHeader,
    PatientSaveArea,
    GafEvaluate,
    PatientExpantionAreas
  }
})
export default class Record2New extends Mixins(
  BaseVisit,
  AxiosMixin,
  EditMixin,
  RulesMixin,
  RulesSaveMixin,
  PatientMixin,
  UtilMixin,
  JokusoMixin,
  FocusingMixin
) {
  /* 変数 */
  @Prop() readonly pheader!: PatientHeader;

  private readonly constant = constant; // 定数

  private readonly EditMode = EditMode; // 編集モードの定数

  // 編集モード
  private mode: EditMode = EditMode.Edit;

  /** 日時を和暦に変換 */
  private toWarekiDatetime = appDate.toWarekiDatetime;

  // 記録書ⅡID
  private record2Id = 0;

  private get RemandLink(): Link[] {
    if (this.model.remand.id && this.model.remand.editor_staff_id) {
      return [
        {
          text: "差戻し情報",
          key: "remand",
          add_info: this.model.remand.comment
        }
      ];
    }
    return [];
  }
  private prefixLink = [
    {
      text: "基本情報",
      key: "basicInfo"
    },
    {
      text: "写真（A4 4分の1サイズ）",
      key: "printVisit",
      mini_text: "写真(A4 1/4枚)"
    },
    {
      text: "写真（A4 1枚サイズ）",
      key: "printScan",
      mini_text: "写真(A4 1枚)"
    }
  ];
  private middleLink = [
    {
      text: "本日の状態（バイタル）",
      key: "vital",
      mini_text: "本日の状態"
    },
    {
      text: "観察項目",
      key: "observationItem"
    },
    {
      text: "症状報告",
      key: "symptomReport"
    },
    {
      text: "看護内容",
      key: "nursingDetail"
    }
  ];
  private suffixLink = [
    {
      text: "連絡事項",
      key: "infomation"
    }
  ];

  /** アップロード可能ファイル */
  private acceptFilePaths = [".jpg", ".jpeg", ".png"];

  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";

  /** 訪問者エラーメッセージ（保存用） */
  private errorMessageVisitor = "";

  // 看護計画書
  private vnplan_problems: VnPlanProblem[] = [];

  /** 前3回の記録有無(通常) */
  private isNursingLast3 = false;
  /** 前3回の記録有無(リハビリ) */
  private isRehabLast3 = false;

  /** 訪問時間120分超えメッセージ表示フラグ */
  private isOverVisitMinutes = false;
  /** 訪問時間0分メッセージ表示フラグ */
  private isZeroDiff = false;

  /** 提出後にプレビューを表示する */
  private displayPreview = false;
  /** 差戻しコメント */
  private remandComment = "";
  /** 差戻して編集確認状態 */
  private remandConfirm = 0;

  /*
   * computesd
   */

  // form
  private get form(): VForm {
    return (this.$refs.form as unknown) as VForm;
  }

  // 訪問者の職種
  private get visitorJobType(): number {
    const staff = this.master.staffs.find(
      staff => staff.value === this.model.staff_id
    );
    if (staff) {
      return staff.job_type;
    }
    return 0;
  }

  /** アコーディオンメニュー */
  private localLinks: Link[] = [];

  /** アコーディオンメニュー getter */
  private get AccordionMenu(): Link[] {
    this.localLinks = [
      ...this.RemandLink,
      ...this.prefixLink,
      ...this.addLink,
      ...this.linkMental,
      ...this.middleLink,
      ...this.linkJokuso,
      ...this.suffixLink
    ];

    return this.localLinks;
  }

  /** アコーディオンメニュー setter */
  private set AccordionMenu(val: Link[]) {
    this.localLinks = val;
  }

  private get StorageKey(): string {
    return `visitrecord-${this.loginUser.id}-${this.patientId}-${this.record2Id}`;
  }

  /* 関数 */

  //編集モード設定
  @Watch("model", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      model: this.model,
      displayPreview: this.displayPreview,
      remandComment: this.remandComment,
      remandConfirm: this.remandConfirm
    });
  }

  @Watch("displayPreview")
  private watchDisplayPreview() {
    this.watchData();
  }

  @Watch("remandComment")
  private watchRemandComment() {
    this.watchData();
  }

  @Watch("remandConfirm")
  private watchRemandConfirm() {
    this.watchData();
  }

  //別記録の表示
  @Watch("$route.params.record2_id")
  private watchPathRecord2Id(_: string, oldId: string) {
    if (oldId === "0") {
      return;
    }
    this.fetchInit();
  }

  // 初期処理
  created() {
    this.fetchInit();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        exact: true,
        to: `/patient/${this.patientId}/record2`
      },
      {
        text: "看護記録",
        disabled: true,
        to: ""
      }
    ]);
  }

  // 訪問日時変更
  private updateVisitDate(changeDatetime: string) {
    // 訪問日が空で日時だけ選択した場合(visit_start_datetimeが空)はfetchしない
    if (!changeDatetime) {
      return;
    }

    this.fetch(changeDatetime);
  }

  // 訪問時間チェック
  private async checkVisitTime() {
    if (
      this.model.visit_start_datetime != "" &&
      this.model.visit_end_datetime != ""
    ) {
      const start = this.strToDate(this.model.visit_start_datetime);
      const end = this.strToDate(this.model.visit_end_datetime);
      const minute = appDate.diffMinute(start, end);
      if (minute > 120) {
        this.isOverVisitMinutes = true;
      } else {
        this.isOverVisitMinutes = false;
      }

      this.isZeroDiff = minute == 0;
    }
  }

  // 訪問記録取得
  private fetch(updateDatetime: string) {
    const visit_date =
      updateDatetime == "" ? this.model.visit_start_datetime : updateDatetime;

    // 訪問時間だけ選択した場合はfetchしない
    if (visit_date != "" && isNaN(this.newDate(visit_date).getDate())) {
      return;
    }

    this.postJsonBackground(
      window.base_url + "/api/patient/visitrecord/get",
      {
        patient_id: this.patientId,
        record2_id: this.record2Id,
        visit_date: visit_date,
        nursing_type: this.model.nursing_type
      },
      res => {
        if (res.data) {
          this.setData(res.data);
          this.model.insurance_div = res.data.visit_record.insurance_div;
          this.model.mental_div = res.data.visit_record.mental_div;
          this.model.care_div = res.data.visit_record.care_div;
          this.model.regular_visit_div =
            res.data.visit_record.regular_visit_div;
          // 初回加算が非表示の場合、初回加算0
          if (!this.master.show_first_visit) {
            this.model.first_visit_flg = 0;
          }
          // GAF値の初期設定（新規作成時のみGAF値を設定調整が必要）
          //  日付変更時はGAF値未設定の場合のみ再セットする。
          if (this.record2Id === 0) {
            if (
              this.model.gaf.gaf_score === -1 &&
              this.model.gaf.is_family_visit === 0
            ) {
              this.model.gaf.gaf_score = res.data.visit_record.gaf.gaf_score;
            }
          }
        }
        // モデル初期化
        this.initmodel();
      }
    );
  }

  private fetchInit() {
    this.patientId = Number(this.$route.params.id);
    this.record2Id = Number(this.$route.params.record2_id);
    const mode = this.$route.query.mode as EditMode;
    if (mode) {
      this.mode = mode;
    }

    const visit_date = this.model.visit_start_datetime;
    this.model.loaded = false;
    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecord/get",
      {
        patient_id: this.patientId,
        record2_id: this.record2Id,
        visit_date: visit_date,
        nursing_type: -1
      },
      res => {
        if (res.data) {
          this.model = res.data.visit_record;
          this.setData(res.data);
          // GAF値の初期設定（新規作成時のみGAF値を設定調整が必要）
          //  初回は日付も空のため、GAF値を初期状態にする。
          if (this.record2Id === 0) {
            this.model.gaf.gaf_score = -1;
          }
        }
        // モデル初期化
        this.initmodel();
        this.$nextTick(() => {
          this.model.loaded = true;
        });
        this.execAfterFetch(this.StorageKey, data => {
          this.model = data.model;
          this.setVital(this.model.vitals);
          this.displayPreview = data.displayPreview;
          this.remandComment = data.remandComment;
          this.remandConfirm = data.remandConfirm;
          this.updateVisitDate(this.model.visit_start_datetime);
        });
      }
    );

    // 前3回の記録があればボタンを表示
    this.fetchPatientLink(() => {
      if (this.linkCondition.last3_nursing_record_ids.length > 0) {
        this.isNursingLast3 = true;
      } else {
        this.isNursingLast3 = false;
      }
      if (this.linkCondition.last3_rehab_record_ids.length > 0) {
        this.isRehabLast3 = true;
      } else {
        this.isRehabLast3 = false;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setData(data: any) {
    // マスター
    this.master = data.master;
    // 利用者
    this.patient = data.patient;
    // 事業所
    this.office = data.office;
    // 看護計画書
    this.vnplan_problems = data.vnplan_problems;
    // 基礎情報の定期巡回のタイプが"定期・随時"、かつ、訪問記録の定期巡回タイプが設定前なら"定期"をセット
    if (
      this.patient.regular_visit_div ===
        constant.PATIENT.REGULAR_VISIT_DIV_REGULAR_OR_ANYTIME &&
      this.model.regular_visit_div === 0
    ) {
      this.model.regular_visit_div =
        constant.VISIT_RECORD.REGULAR_VISIT_DIV_REGULAR;
    }
    // 以前の褥瘡データは削除する
    this.model.jokusos = this.filterJokuso(
      this.master.bedsores,
      this.model.jokusos
    );
    this.model.is_risk_factor = data.is_risk_factor;
  }

  // ワーニング処理 return 処理継続:true 継続しない:false
  private async warning(
    warnings: number[],
    customWarnings: { [key: number]: string }
  ): Promise<boolean> {
    for (let i = 0; i < warnings.length; i++) {
      const warning = warnings[i];
      const check = CHECK_CODE[warning];
      if (!check) {
        continue;
      }
      let message = check.message;
      if (customWarnings[warning] !== undefined) {
        // 注意文は、指定がcustomWarningsにあればそちらを優先
        message = customWarnings[warning];
      }

      if (check.type == 0) {
        // アラート表示
        await this.$openAlert(message);
      } else if (check.type == 1) {
        // confirm表示（キャンセルで提出処理中断）
        if (!(await this.$openConfirm(message))) {
          return false;
        }
      } else if (check.type == 2) {
        // confirm表示（OKで個別の処理 CANCELは何もしない）
        if (await this.$openConfirm(message)) {
          switch (warning) {
            case 6: // 夜間加算選択
              this.model.time_type = constant.VISIT_RECORD.TIME_TYPE_NIGHT;
              break;
            case 8: // 深夜加算選択
              this.model.time_type = constant.VISIT_RECORD.TIME_TYPE_MID_NIGHT;
              break;
            case 10: // 早朝加算選択
              this.model.time_type = constant.VISIT_RECORD.TIME_TYPE_MORNING;
              break;
            case 7: // 夜間加算選択解除
            case 9: // 深夜加算選択解除
            case 11: // 早朝加算選択解除
            case 12: // 夜間加算選択解除
            case 13: // 深夜加算選択解除
            case 14: // 早朝加算選択解除
            case 15: // 夜間早朝加算選択解除
              // 訪問時間における訪問看護加算の選択解除
              this.model.time_type = 0;
              break;
          }
        }
      }
    }
    return true;
  }

  // 提出
  private submit(isPreview: boolean) {
    // バイタルは1回目を先頭にする
    this.onSaveVitals();
    // 緊急訪問のフラグがOFFの場合、理由欄をクリア
    this.clearEmergencyReason();
    // 訪問記録チェック（ワーニング）
    const staff = this.master.staffs.find(
      staff => staff.value === this.model.staff_id
    );
    this.resetAccompanyOther();
    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecord/check",
      {
        patient_id: this.patientId,
        visit_record: this.model,
        staff_job_type: staff ? staff.job_type : 0,
        is_same_building: this.master.is_sam_building //同一日同一建物の表示状態フラグ（画面状態を取得）
      },
      async res => {
        // ワーニング処理
        if (
          !(await this.warning(res.data.warnings, res.data.custom_warnings))
        ) {
          // 提出処理中断
          return;
        }
        // 提出
        this.is_finish = true;
        this.model.status = constant.COMMON.STATUS_SUBMIT;
        this.makePdf(
          window.base_url + "/api/patient/visitrecord/submission",
          {
            patient_id: this.patientId,
            visit_record: this.model,
            is_preview: isPreview,
            care_contents: this.createRegisteredCareContent(),
            staff_job_type: staff ? staff.job_type : 0
          },
          () => {
            this.is_finish = false;
            this.setNoEditMode();
            this.externalBack();
          },
          () => {
            this.is_finish = false;
          },
          isPreview
        );
      }
    );
  }

  // プレビュー
  private preview() {
    // バイタルは1回目を先頭にする
    this.onSaveVitals();
    this.resetAccompanyOther();
    this.makePdf(window.base_url + "/api/patient/visitrecord/preview", {
      patient_id: this.patientId,
      visit_record: this.model,
      care_contents: this.createRegisteredCareContent()
    });
  }

  // storage 保存パス
  private imagePath(): string {
    // [co_code]/[kst_no]/visit/[利用者ID]/[記録書ⅡID]/[ファイル名(uuid)]
    const kstNo = this.getKstnoByOfficeId(this.PatientOfficeId);
    return `${this.siteName}/${kstNo}/visit/${this.patientId}/${this.record2Id}/`;
  }

  /* イベント */

  // 提出処理
  private async clickSubmit() {
    if (!this.form.validate()) {
      await this.$openAlert(`入力内容に不備があります${this.checkInput()}`);
      return;
    }
    this.submit(false);
  }

  // 提出してプレビュー処理
  private async clickSubmitAndPreview() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.submit(true);
  }

  // 必須項目のうち未入力項目を取得
  private checkInput() {
    let dispInput = "";
    if (!this.model.visit_start_datetime) {
      dispInput += "\n訪問日時が選択されていません";
    }
    if (!this.model.staff_id) {
      dispInput += "\n訪問者が選択されていません";
    }
    if (
      !this.model.condition_div ||
      (this.model.condition_div == 2 && !this.model.condition)
    ) {
      dispInput += "\n症状報告が指定されていません";
    }
    if (!this.model.care_contents.length) {
      dispInput += "\n看護内容が選択されていません";
    }
    return dispInput;
  }

  // 差戻し処理
  private async clickRemand() {
    const remandName = getRemandName(this.model);
    if (
      !(await this.$openConfirm(
        `看護記録書Ⅱの編集を ${remandName}さん へ差戻しますか？`
      ))
    ) {
      return;
    }
    this.model.status = constant.COMMON.STATUS_REMAND;
    this.model.remand.editor_staff_id = this.loginUser.id;
    this.model.remand.comment = this.remandComment;
    this.model.remand.upd_flg = 1;
    this.save(true);
  }

  // 保存処理
  private async clickSave(isMove: boolean) {
    this.model.status = constant.COMMON.STATUS_SAVE;
    this.save(isMove);
  }

  private async save(isMove: boolean) {
    if (this.model.staff_id == 0) {
      await this.$openAlert("訪問者を選択してください。");
      this.errorMessageVisitor = "必須です";

      // アコーディオンメニュー:基本情報をエラーあり状態に更新
      this.prefixLink = this.prefixLink.map((link: Link) => {
        if (link.key == "basicInfo") {
          link.is_false = true;
        }
        return link;
      });
      return;
    }

    this.is_finish = true;
    this.setIsSave(true);
    this.$nextTick(async () => {
      // 緊急訪問のフラグがOFFの場合、理由欄をクリア
      this.clearEmergencyReason();

      if (!this.form.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.form.resetValidation();
      this.setIsSave(false);
      this.resetAccompanyOther();

      // 編集して差戻しの場合はそれ用のAPIを呼ぶ（オペログ表示用）
      // 処理は通常の保存処理と同じ
      let path = "/api/patient/visitrecord/save";
      if (this.mode === EditMode.Remand) {
        path = "/api/patient/visitrecord/editAndRemand";
      }

      // バイタルは1回目を先頭にする
      this.onSaveVitals();

      this.postJsonCheck(
        window.base_url + path,
        {
          patient_id: this.patientId,
          visit_record: this.model,
          care_contents: this.createRegisteredCareContent()
        },
        res => {
          this.setNoEditMode();
          if (isMove) {
            this.externalBack();
          } else {
            this.record2Id = res.data.id;
            this.model.id = res.data.id;
            this.model.updated_at = res.data.updated_at;
            this.$router.replace({
              params: { record2_id: String(this.record2Id) }
            });
            this.resetStorage(this.StorageKey);
            this.is_finish = false;
            this.$openAlert("編集中の内容を保存しました。");
          }
        },
        () => {
          this.is_finish = false;
        }
      );
    });
  }

  // 削除処理
  private clickDelete() {
    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecord/delete",
      {
        patient_id: this.patientId,
        visit_record: this.model
      },
      () => {
        this.setNoEditMode();
        this.externalBack();
      }
    );
  }

  // プレビュー表示処理
  private clickPreview() {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.form.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.form.resetValidation();
      this.setIsSave(false);
      this.preview();
    });
  }

  // 服薬管理
  private clickMedicineTaking() {
    const name = "VisitMedicinetaking";
    const resolvedRoute = this.$router.resolve({
      name: name,
      params: { id: String(this.patientId) }
    });
    window.open(resolvedRoute.href, "_blank");
  }

  /** キャンセル */
  private clickCancel() {
    this.postJsonBackground(
      window.base_url + "/api/patient/visitrecord/cancel",
      {
        patient_id: this.patientId,
        visit_record: this.model
      },
      () => {
        // Do Nothing
      }
    );
    this.externalBack();
  }

  // 訪問種別の変更による訪問記録の再取得
  private changeInsDiv() {
    this.fetch(this.model.visit_start_datetime);
  }

  // 同行者その他のテキストリセット
  private resetAccompanyOther() {
    if (this.model.accompany1_id != -1) {
      this.model.accompany1_other = "";
    }
    if (this.model.accompany2_id != -1) {
      this.model.accompany2_other = "";
    }
    if (this.model.accompany3_id != -1) {
      this.model.accompany3_other = "";
    }
  }

  // 緊急訪問のトグルがOFFの場合、理由欄をクリア（医療）
  private clearEmergencyReason() {
    if ( this.model.is_emergency_care == 0) this.model.emergency_reason = "";
  }
}
