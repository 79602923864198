import AppChatBubble from "./AppChatBubble.vue";

export default {
  title: "Example/AppChatBubble",
  component: AppChatBubble,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatBubble },
  template: '<AppChatBubble v-bind="$props" />'
});

export const ChatBubble = Template.bind({});

ChatBubble.args = {
  text:
    "A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.A U.S. expert on Korean Peninsula issues has stressed the urgent need for Japan-U.S.-South Korea cooperation on missile defense against North Korea, at a time when speculation is rife that Pyongyang may conduct a nuclear test in the near future.",
  name: "麻生太郎",
  isOwn: true,
  chatType: 0,
  date: new Date(),
  readCount: 2,
  stampPath:
    "https://1.bp.blogspot.com/-l4Xa9FbvkWE/XXXOfQfo-wI/AAAAAAABUuk/IphjktvChUAJTJnF8OiJLa8p9iX1KG5xQCLcBGAs/s1600/figure_tsumitate.png",
  imagePath:
    "https://blogger.googleusercontent.com/img/a/AVvXsEhElpwJN8Tv4BEh6m-qAviFMyGjAPZlxUbBg-y7lEiVwNp2xS585weXVZjdJCi3u_eOADZs8yqkCzmI7CwMW3r2xLPzY_bNUtHo6ODf6NjbZG6SXTCf5OGlQ5OGOMYO6r6q668jkWe1rFJQZjtCWGSNh1E_ceWupjLupsgfJ5jG6xiAesnDFh4NvcVOZA=s1105"
};
