


































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import InfiniteLoading from "vue-infinite-loading";
import AxiosMixin from "@/mixins/axiosMixin";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import UtilMixin from "@/mixins/utilMixin";
import { DispOperationLog } from "#/model/operationlog";
import { Choice } from "@/types";
import SearchPanel from "#/components/SearchPanel.vue";

@Component({
  components: {
    InfiniteLoading,
    OfficeSelect,
    SearchPanel
  }
})
export default class OperationLog extends Mixins(AxiosMixin, UtilMixin) {
  @Ref("infiniteLoading") private readonly infinite!: InfiniteLoading;

  /** ログ表示用のテーブルヘッダ情報 */
  private headers = [
    {
      text: "実行日時",
      value: "timestamp_disp",
      align: "start"
    },
    {
      text: "実行職員",
      value: "office_staff_id",
      align: "start"
    },
    {
      text: "画面・機能名",
      value: "function_name",
      align: "start"
    },
    {
      text: "操作名",
      value: "operation_name",
      align: "start"
    },
    {
      text: "状態",
      value: "status_name",
      align: "start"
    },
    {
      text: "内容",
      value: "short_content",
      align: "start"
    },
    {
      text: "IPアドレス",
      value: "ip",
      align: "start"
    },
    {
      text: "OS",
      value: "os",
      align: "start"
    },
    {
      text: "ブラウザ",
      value: "browser",
      align: "start"
    }
  ];

  /** オペログ情報 */
  private logs: DispOperationLog[] = [];

  /** 読み込み完了日時 */
  private next_timestamp = null;

  /** 開始日 */
  private start_date = this.dateToStr(new Date(), "yyyy-MM-dd");

  /** 開始時 */
  private start_hh = 0;

  /** 開始分 */
  private start_mm = 0;

  /** 終了日 */
  private end_date = this.dateToStr(new Date(), "yyyy-MM-dd");

  /** 終了時 */
  private end_hh = 23;

  /** 終了分 */
  private end_mm = 59;

  /** 選択肢用事業所情報 */
  private offices: Choice[] = [];

  /** 選択中事業所ID */
  private office_id = 0;

  /** 選択肢用職員情報 */
  private staffs: Choice[] = [];

  /** 選択中職員ID */
  private staff_id = 0;

  /** 選択肢用利用者情報 */
  private patients: Choice[] = [];

  /** 選択中利用者ID */
  private patient_id = 0;

  /** 選択肢用画面・機能マスタデータ */
  private functions: Choice[] = [];

  /** 選択中画面・機能ID */
  private function_id = 0;

  /** 取得系操作ログを除外するかどうかフラグ */
  private excludeGetLogs = true;

  /** 取得開始日時 */
  private get startDatetime(): string {
    const hour = ("0" + this.start_hh).slice(-2);
    const minute = ("0" + this.start_mm).slice(-2);

    return `${this.start_date} ${hour}:${minute}`;
  }

  /** 取得終了日時 */
  private get endDatetime(): string {
    const hour = ("0" + this.end_hh).slice(-2);
    const minute = ("0" + this.end_mm).slice(-2);

    return `${this.end_date} ${hour}:${minute}`;
  }

  // 絞り込みしているかどうか
  private get IsFiltered(): boolean {
    return !(
      this.office_id === 0 &&
      this.staff_id === 0 &&
      this.patient_id === 0 &&
      this.function_id === 0 &&
      this.excludeGetLogs
    );
  }

  /** 絞り込み */
  private searchPanel = false;

  /** 絞り込みデフォルト */
  private setDefaultSearch() {
    this.office_id = 0;
    this.staff_id = 0;
    this.patient_id = 0;
    this.function_id = 0;
    this.excludeGetLogs = true;
  }

  /** 絞り込み表示 */
  private openFilter() {
    this.searchPanel = true;
  }
  /** データ更新 */
  private updateData() {
    this.postJsonCheck(
      window.base_url + "/api/opelog/gets",
      {
        start_datetime: this.startDatetime,
        end_datetime: this.endDatetime,
        office_id: this.office_id,
        staff_id: this.staff_id,
        patient_id: this.patient_id,
        function_id: this.function_id,
        timestamp: this.next_timestamp,
        exlclude_get_logs: this.excludeGetLogs
      },
      res => {
        this.logs = this.logs.concat(res.data.operation_logs);
        this.next_timestamp = res.data.next_timestamp;
        this.staffs = res.data.staffs;
        this.patients = res.data.patients;
        this.functions = res.data.functions;
        if (this.infinite) {
          this.infinite.stateChanger.loaded();
          if (!res.data.next_timestamp) {
            this.infinite.stateChanger.complete();
          }
        }
      }
    );
  }

  /** CSV出力 */
  private csv() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/opelog/csv",
      {
        start_datetime: this.startDatetime,
        end_datetime: this.endDatetime,
        office_id: this.office_id,
        staff_id: this.staff_id,
        patient_id: this.patient_id,
        function_id: this.function_id,
        exlclude_get_logs: this.excludeGetLogs
      },
      res => {
        const blob = new Blob([res.data]);
        const a = document.createElement("a");
        a.download = "opelog.csv";
        a.href = URL.createObjectURL(blob);
        a.click();
      }
    );
  }

  /** 選択事業所に変更があった場合 */
  private onChangeOffice() {
    // 事業所に紐づく職員、利用者選択肢をリセットし再取得
    this.staff_id = 0;
    this.patient_id = 0;
    this.$nextTick(() => {
      this.search();
    });
  }

  /** 検索処理 */
  private search() {
    this.logs = [];
    this.infinite.stateChanger.reset();
    this.next_timestamp = null;
  }

  private infiniteHandler() {
    this.updateData();
  }
}
