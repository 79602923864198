


















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TableColumnGuide extends Vue {
  /** ツールチップとして表示する説明分 */
  @Prop() description!: string;
}
