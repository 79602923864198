var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('AppTextField',{staticClass:"mb-4",attrs:{"label":"*保険者名","required":"","rules":[_vm.requiredInsurerName()],"width":"100%","maxlength":"32","counterNum":32},model:{value:(_vm.insurer.insurer_name),callback:function ($$v) {_vm.$set(_vm.insurer, "insurer_name", $$v)},expression:"insurer.insurer_name"}}),_c('AppSubTitle',{staticClass:"mb-2"},[_vm._v(" 住所 ")]),_c('AppPlaces',{attrs:{"postal_code":_vm.insurer.post_code,"prefecture":_vm.insurer.pref,"cityAddress":_vm.insurer.city,"houseNumber":_vm.insurer.area,"building":_vm.insurer.building},on:{"update:postal_code":function($event){return _vm.$set(_vm.insurer, "post_code", $event)},"update:prefecture":function($event){return _vm.$set(_vm.insurer, "pref", $event)},"update:cityAddress":function($event){return _vm.$set(_vm.insurer, "city", $event)},"update:city-address":function($event){return _vm.$set(_vm.insurer, "city", $event)},"update:houseNumber":function($event){return _vm.$set(_vm.insurer, "area", $event)},"update:house-number":function($event){return _vm.$set(_vm.insurer, "area", $event)},"update:building":function($event){return _vm.$set(_vm.insurer, "building", $event)}}}),_c('v-divider',{staticClass:"mt-4 mb-2"}),_vm._l((_vm.insurer.insurer_divs),function(div,index){return _c('div',{key:index,staticClass:"mt-4"},[_c('AppSelect',{attrs:{"label":"*保険種類","width":"100%","items":[
            { text: '未選択', value: 0 },
            { text: '介護保険', value: 1 },
            { text: '国民健康保険', value: 2 },
            { text: '国民健康保険・退職', value: 3 },
            { text: '後期高齢者医療', value: 4 },
            { text: '健康保険', value: 5 },
            { text: '共済組合', value: 6 },
            { text: '船員保険', value: 7 },
            { text: 'その他', value: 8 }
          ],"required":"","rules":[_vm.requiredInsurerDiv(div)]},model:{value:(div.insurer_div),callback:function ($$v) {_vm.$set(div, "insurer_div", $$v)},expression:"div.insurer_div"}}),_c('AppTextField',{staticClass:"mt-4 mb-4",attrs:{"label":"*保険者番号","required":"","rules":[_vm.requiredInsurerDivNo(div)],"width":"100%","maxlength":"10","counterNum":10},model:{value:(div.insurer_no),callback:function ($$v) {_vm.$set(div, "insurer_no", $$v)},expression:"div.insurer_no"}}),_c('AppPhoneArea',{staticClass:"mb-4 input-label",attrs:{"label":"電話番号","area":div.tel_area,"city":div.tel_city,"num":div.tel_num},on:{"update:area":function($event){return _vm.$set(div, "tel_area", $event)},"update:city":function($event){return _vm.$set(div, "tel_city", $event)},"update:num":function($event){return _vm.$set(div, "tel_num", $event)}}}),_c('AppButton',{staticClass:"mb-2",attrs:{"color":"alert","dark":""},on:{"click":function($event){return _vm.removeInsurerDiv(div)}}},[_vm._v("保険者番号削除")]),_c('v-divider',{staticClass:"mb-2"})],1)}),_c('AppButton',{attrs:{"color":"primary mt-2"},on:{"click":_vm.addNewInsurerDiv}},[_vm._v(" 保険者番号追加 ")]),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('OfficeSelectInput',{attrs:{"selectedOffice":_vm.selectedOffice},on:{"update:selectedOffice":function($event){_vm.selectedOffice=$event},"update:selected-office":function($event){_vm.selectedOffice=$event}}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }