


































import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import OneDayAdd from "./OneDayAdd.vue";

@Component
export default class OneOrThreeWeek extends OneDayAdd {
  @Prop({ default: false }) protected readonly long_time_flg!: boolean;

  @Prop({ default: false })
  protected readonly long_time_flg_covid19_div!: boolean;

  @Emit("update:long_time_flg") protected updateLongTimeFlg(newValue: boolean) {
    return newValue;
  }

  @Emit("update:long_time_flg_covid19_div")
  protected updateLongTimeFlgCovid19Div(newValue: boolean) {
    return newValue;
  }

  created(): void {
    this.initLongTimeFlgCovid19Div();
  }

  protected get LongTimeFlgCovid19Div() {
    return this.long_time_flg_covid19_div;
  }

  protected set LongTimeFlgCovid19Div(newValue: boolean) {
    this.updateLongTimeFlgCovid19Div(newValue);
  }

  //長時間（新型コロナウイルス臨時加算）
  protected initLongTimeFlgCovid19Div() {
    if (this.receiptVisitRecord.long_time_flg == 1) {
      this.updateLongTimeFlgCovid19Div(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 2) {
      this.updateLongTimeFlgCovid19Div(true);
    }
  }

  //長時間
  @Watch("long_time_flg_covid19_div") protected handleLongTimeFlgCovid19Div() {
    if (!this.isLoaded) {
      return;
    }
    if (this.long_time_flg_covid19_div == true) {
      this.receiptVisitRecord.long_time_flg = 2;
      this.updateLongTimeFlg(false);
    }
    if (
      this.long_time_flg == false &&
      this.long_time_flg_covid19_div == false
    ) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV3() {
    //緊急訪問看護
    if (this.disableCheckMedicalUrgentFlg()) {
      this.receiptVisitRecord.medical_urgent_flg = 0;
    }
    //乳幼児
    if (this.disableCheckInfantsFlg()) {
      this.receiptVisitRecord.infants_flg = 0;
    }
  }
}
