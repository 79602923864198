

































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { OtherInfo } from "@/components/patient/info/types";

@Component({
  components: {}
})

/** 利用者基礎情報 状態フォーム>指示書情報 */
export default class OtherInfoViewer extends Vue {
  /** Prop */

  /** 基礎情報その他情報 */
  @Prop() otherInfo!: OtherInfo;
}
