




























































































































































































import { Mixins, Component, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import { app, install } from "#/mixins/firebase";
import ProgressItems from "@/components/patient/progress/ProgressItems.vue";

@Component({
  components: {
    ProgressItems
  }
})
export default class PrintProgress extends Mixins(
  UtilMixin,
  AxiosMixin,
  PatientMixin
) {
  @Ref("progress") private readonly progress!: ProgressItems;
  private patientId = 0;
  private patientName = "";
  private officeName = "";
  private showChart = true;
  private showObs = true;
  private showLabel = false;
  private showOutputDate = true;
  private showScroll = false;

  public created(): void {
    this.patientId = Number(this.$route.params.id);
    this.patientName = String(this.$route.query.name);
    this.officeName = String(this.$route.query.office);
  }

  public mounted(): void {
    this.setDefaultHeader();
  }

  private webPrint() {
    app
      .delete()
      .then(function() {
        window.print();
      })
      .catch(function(error) {
        console.log("Error deleting app:", error);
      })
      .finally(function() {
        install();
      });
  }
  private close() {
    window.close();
  }

  private get OutputDate(): string {
    return this.dateToStr(new Date(), "yyyy年MM月dd日");
  }
}
