








































import { Component, Prop, Vue } from "vue-property-decorator";
import { CareStation } from "@/views/master/carestation/types";
interface CareStationItem extends CareStation {
  office_names: string;
}
@Component
export default class CareStationList extends Vue {
  @Prop({ default: () => [] }) items!: CareStation[];

  private itemPerPage = 25;

  private pageNo = 1;

  private headers = [
    {
      text: "訪問看護ステーション",
      align: "start",
      value: "name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    { text: "", value: "actions", sortable: false, align: "end" }
  ];

  @Prop({ default: "" })
  search!: string;

  private edit(st: CareStation) {
    this.$router.push({
      name: "CareStationEdit",
      params: {
        id: String(st.id)
      }
    });
  }
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  //絞り込み
  private filterItems(value: unknown, search: string, item: CareStationItem) {
    if (search == null) {
      return false;
    }

    if (item == null) {
      return false;
    }
    if (
      item.name.match(search) ||
      item.furigana.match(search) ||
      item.office_names.match(search)
    ) {
      return true;
    }

    return false;
  }
}
