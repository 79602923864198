import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:情報提供療養費情報 */
export const headerVnRecord: StatisticHeader = {
  id: STATISTIC_COL_ID.VN_RECORD,
  text: "情報提供療養費情報",
  is_checked: false,
  value: [
    {
      text: "訪問看護情報提供療養費（Ⅰ）",
      value: `${STATISTIC_COL_ID.VN_RECORD}.str_is_government_submit`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST
    },
    {
      text: "訪問看護情報提供療養費（Ⅱ）",
      value: `${STATISTIC_COL_ID.VN_RECORD}.str_is_school_submit`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST
    },
    {
      text: "訪問看護情報提供療養費（Ⅲ）",
      value: `${STATISTIC_COL_ID.VN_RECORD}.str_is_medical_submit`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST
    }
  ]
};
