import Base from "../base";

//限度額適用認定証の適用区分
export interface LimitCertLimitDiv {
  id: number; // ID
  cert_div: number; // 認定証の種類 1:限度額適用認定証 2:限度額適用・標準負担額減額認定証
  high_expense_term_id: number; // 限度額適用認定証期間ID 3:70歳未満 4:70歳以上
  high_expense_div_id: number; // 限度額適用認定証設定区分 1:現役並み(2018/07/31以前) 2:一般 3:低所得I 4:低所得II 9:その他 (2018/08/01以降70歳以上) 5:現役並みIII 6:現役並みII 7:現役並みI (2018/08/01以降70歳未満) 11:適用区分ア 12:適用区分イ 13:適用区分ウ 14:適用区分エ 15:適用区分オ
  high_expense_div_name: string; // 高額療養費設定区分名
  special_note_code: string; // 高額療養費区分の特記事項コード
}
export const DefaultLimitCertLimitDiv = (): LimitCertLimitDiv => ({
  id: 0,
  cert_div: 0,
  high_expense_term_id: 0,
  high_expense_div_id: 0,
  high_expense_div_name: "",
  special_note_code: ""
});

//限度額適用認定証
export interface MedicalInsuranceLimitCert extends Base {
  id: number; // ID
  patient_id: number; // 利用者ID
  start_date: string; // 開始日
  end_date: string; // 終了日
  limit_div_id: number; // 適用区分ID (70歳未満) 1:ア 2:イ 3:ウ 4:エ 5:オ (70歳以上) 6:現役Ⅰ 7:現役Ⅱ 8:低所得Ⅰ 9:低所得Ⅱ
  cert_div_name: string; // 認定証の種類名
  is_make_high_expense: number; // 高額療養費作成フラグ
  limit_div: LimitCertLimitDiv; // 適用区分
}
export const DefaultMedicalInsuranceLimitCert = (): MedicalInsuranceLimitCert => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  limit_div_id: 0,
  cert_div_name: "",
  is_make_high_expense: 0,
  limit_div: DefaultLimitCertLimitDiv(),
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
