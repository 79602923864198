



























































































































































































































































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify";
import { Choice, SortTypeSelect } from "@/types";
import { SearchCondition } from "@/components/patient/list/type";
import { PatientDispInfo } from "@/components/patient/types";
import { StatusList } from "@/components/patientsearch/search";
import FireStoreMixin from "@/mixins/firestoreMixin";
import DocInfo from "@/components/patientsearch/DocInfo.vue";
import SearchPanel from "#/components/SearchPanel.vue";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import * as constant from "#/const";
import * as appDate from "#/utility/appDate";
import { covid19VaccineStatuses } from "#/model/patient";
import { OFFICE_STAFF } from "#/const";

@Component({
  components: {
    DocInfo,
    SearchPanel,
    OfficeSelect
  }
})
export default class PatientSearch extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin
) {
  @Prop({ default: "" }) aboutText!: string; // タイトルに付随するページ説明
  @Prop({ default: null }) callbackSelect!: Function; // 利用者選択クリック時のコールバック
  @Prop({ default: null }) callbackAccept!: Function; // 新規利用者クリック時のコールバック
  @Prop({ default: "" }) collection_name!: string; // fire store コレクション名
  @Prop({ default: 0 }) mode!: number; // 0:通常 1:24時間体制 2:記録を提出 3:訪問へ行く
  @Prop({ default: false }) showBackBtn!: boolean; // 戻るボタンの表示
  @Prop({ default: null }) itemClass!: Function; // DataTableのclass

  // タイトル
  private get title(): string {
    switch (this.mode) {
      case 1:
        return "24時間体制を登録する利用者の選択";
      case 2:
        return "看護記録";
      case 3:
        return "訪問する利用者を選択してください";
    }
    return "利用者一覧";
  }

  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;

  /** 合計件数 */
  private total = 0;

  private pageCount = 0; // ページ件数

  private groups: Choice[] = []; // グループ一覧

  /** コロナワクチン接種状況選択肢 */
  private covid19VaccineStatuses = covid19VaccineStatuses;

  // 検索サイドバーを開いているか
  private searchPanel = false;

  // 状況の選択肢
  private statusList = StatusList;

  private patients: PatientDispInfo[] = []; // 利用者一覧

  // あいうえおフィルター
  private alphabetFilter: Choice[] = [
    { text: "かな全て", value: 0 },
    { text: "あ行", value: 1 },
    { text: "か行", value: 2 },
    { text: "さ行", value: 3 },
    { text: "た行", value: 4 },
    { text: "な行", value: 5 },
    { text: "は行", value: 6 },
    { text: "ま行", value: 7 },
    { text: "や行", value: 8 },
    { text: "ら行", value: 9 },
    { text: "わ行", value: 10 },
    { text: "ん", value: 11 },
    { text: "その他", value: 99 }
  ];
  private alphabetFilterBy = this.alphabetFilter[0];

  // 利用者テーブルヘッダ
  private headers: DataTableHeader[] = [
    {
      text: "氏名",
      value: "name",
      align: "start",
      width: "190px",
      sortable: false
    },
    {
      text: "ふりがな",
      value: "name_kana",
      align: "start",
      width: "220px",
      sortable: false
    },
    {
      text: "生年月日/年齢",
      value: "bday_wareki",
      align: "start",
      width: "10.3rem",
      sortable: false
    },
    {
      text: "指示書",
      value: "indicate",
      width: "8.7rem",
      align: "start",
      sortable: false
    },
    {
      text: "保険",
      value: "insurance",
      width: "8.7rem",
      align: "start",
      sortable: false
    },
    {
      text: "証明書/\n認定証",
      value: "certificate",
      width: "6.8rem",
      class: "text-pre-wrap",
      align: "start",
      sortable: false
    },
    {
      text: "登録",
      value: "info_icons",
      align: "start",
      sortable: false
    },
    {
      text: "グループ",
      value: "group_icons",
      align: "start",
      sortable: false
    },
    {
      text: "ID",
      value: "display_id",
      width: "7.5rem",
      align: "start",
      sortable: false
    }
  ];

  /** 検索条件 */
  private DefaultSearchCond = (): SearchCondition => ({
    office_id: 0,
    status: 1,
    not_submitted: {
      report: 0,
      plan: 0,
      infomation: 0,
      accident: 0,
      record2: 0
    },
    fulltime: {
      medical_fultime: 0,
      care_kinkyu_houmon: 0
    },
    group_ids: Array.from({ length: 5 }, () => 0),
    covid19_vaccine_status: 0,
    group_operator: 1,
    keyword: "",
    past_one_month_visit: 0,
    page_no: 1, // ページ番号
    per_page: 25, // ページ表示件数
    sort: 0,
    sort_type: 0,
    result_patient_ids: [],
    read_history_ids: [],
    alphabet_filter_type: 0
  });

  /** デフォルトの検索条件 */
  private searchCond = this.DefaultSearchCond();

  /** デフォルトの検索条件を設定 */
  private setDefaultSearch() {
    const sort = this.searchCond.sort;
    const sortType = this.searchCond.sort_type;
    const alphabetFilterType = this.searchCond.alphabet_filter_type;
    this.searchCond = this.DefaultSearchCond();
    this.searchCond.sort = sort;
    this.searchCond.sort_type = sortType;
    this.searchCond.alphabet_filter_type = alphabetFilterType;
  }

  /** グループ検索条件のAND/ORとチェックボックスを合わせる */
  private get searchGroupOperator(): boolean {
    return !this.searchCond.group_operator;
  }
  private set searchGroupOperator(newCond: boolean) {
    this.searchCond.group_operator = Number(!newCond);
  }

  /** 新規利用者受付表示フラグ */
  private get showAccept(): boolean {
    // 新規利用者受付ボタン押下時の処理が設定されているか判定
    if (!this.callbackAccept) {
      return false;
    }
    // 利用可能な機能か判定
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_PATIENT,
      constant.AGREEMENT.FUNCTION_DIV_INSERT
    );
  }

  // 絞り込みしているかどうか
  private get IsFiltered(): boolean {
    return !(
      this.searchCond.office_id === 0 &&
      this.searchCond.status === 1 &&
      this.searchCond.not_submitted.report === 0 &&
      this.searchCond.not_submitted.plan === 0 &&
      this.searchCond.not_submitted.infomation === 0 &&
      this.searchCond.not_submitted.accident === 0 &&
      this.searchCond.not_submitted.record2 === 0 &&
      !this.searchCond.group_ids.find(id => id !== 0 && id !== null) &&
      this.searchCond.group_operator === 1 &&
      (this.searchCond.covid19_vaccine_status === 0 ||
        this.searchCond.covid19_vaccine_status === null) &&
      this.searchCond.past_one_month_visit === 0
    );
  }

  public async created() {
    this.collection = this.collection_name;
    this.documentId = String(this.loginUser.id);
    const searchCond = (await this.documentGet()) as SearchCondition;
    if (searchCond) {
      this.searchCond = this.deepCopy(searchCond);
      // 並び替えボタンのラベルを保存済み検索条件に合わせる
      const patientSortBy = this.patientSortByList.find(
        sortBy =>
          sortBy.sort === this.searchCond.sort &&
          sortBy.sortType === this.searchCond.sort_type
      );
      if (patientSortBy) this.patientSortBy = patientSortBy;
      // あいうえおフィルタの選択をリストア
      const alphabetFilterBy = this.alphabetFilter.find(
        item => item.value === this.searchCond.alphabet_filter_type
      );
      if (alphabetFilterBy) this.alphabetFilterBy = alphabetFilterBy;
    } else if (searchCond === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
    const idx = this.masters.group_offices.findIndex(
      office => office.value == this.searchCond.office_id
    );
    if (idx == -1) {
      this.searchCond.office_id = 0;
    }
    // 1ページの表示件数を強制的に25件にする
    this.searchCond.per_page = 25;

    this.fetch();
  }

  // 検索ボタン押下
  private clickSearch() {
    this.searchCond.page_no = 1;
    this.fetch();
  }

  // 検索
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/patients/get",
      { ...this.searchCond, is_visit: this.mode === 3 },
      res => {
        if (!res.data) return;
        this.patients = res.data.patients.map((patient: PatientDispInfo) => {
          patient.bday_wareki = `(${this.warekiToShort(patient.bday_wareki)}) ${
            patient.age
          }`;

          return patient;
        });
        this.groups = res.data.groups;
        this.pageCount = res.data.page_count;
        this.total = res.data.total;
        this.searchCond.result_patient_ids = res.data.patient_ids;
        if (this.collection_name !== "") {
          this.documentSave(this.searchCond);
        }
      }
    );
  }

  private diffBirthDay(bday: Date): number {
    let now = new Date();
    const bMonth = bday.getMonth();

    // bdayとnowを両方0時0分0秒に揃える
    // bdayの年だけは、誕生日を過ぎているかの比較をするため、nowの年と同じにする
    bday = new Date(now.getFullYear(), bday.getMonth(), bday.getDate());
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    // bdayの年を、now以降になるように変更
    // この時、元のbdayが閏日(2月29日)で、閏年でない年になるなら月を保つ
    if (bday < now) {
      bday.setFullYear(bday.getFullYear() + 1);
    }
    // 閏年でなくなったら、3月1日を2月28日に
    if (bMonth !== bday.getMonth()) {
      bday.setDate(0);
    }

    // getTimeして、日差を求める
    const diff = bday.getTime() - now.getTime();
    return Math.ceil(diff / (24 * 60 * 60 * 1000));
  }

  // 誕生日判定
  private getBdayIcon(bday: string): string {
    const birthday = appDate.strToDate(bday);
    if (Number.isNaN(birthday.getTime())) {
      return "";
    }
    const diffDate = this.diffBirthDay(birthday);
    switch (diffDate) {
      case 3:
        return "/images/birthday3.png";
      case 2:
        return "/images/birthday2.png";
      case 1:
        return "/images/birthday1.png";
      case 0:
        return "/images/birthday0.png";
      default:
        return "";
    }
  }

  // キーワード入力文字を検索条件に反映
  private updateKeyword(newKeyword: string) {
    this.searchCond.keyword = newKeyword;
  }

  // ページ変更
  private changePage(pageNo: number) {
    this.searchCond.page_no = pageNo;
    this.fetch();
  }

  // 利用者のソート方法
  private patientSortByList: SortTypeSelect[] = [
    { text: "登録順 - 降順", select: "登録順", sort: 0, sortType: 0 },
    { text: "登録順 - 昇順", select: "登録順", sort: 0, sortType: 1 },
    { text: "参照履歴 - 新しい順", select: "参照履歴順", sort: 1, sortType: 0 },
    { text: "参照履歴 - 古い順", select: "参照履歴順", sort: 1, sortType: 1 },
    {
      text: "利用者名順 - 降順（ふりがな優先）",
      select: "利用者名順",
      sort: 2,
      sortType: 0
    },
    {
      text: "利用者名順 - 昇順（ふりがな優先）",
      select: "利用者名順",
      sort: 2,
      sortType: 1
    },
    { text: "ID - 降順", select: "ID順", sort: 3, sortType: 0 },
    { text: "ID - 昇順", select: "ID順", sort: 3, sortType: 1 }
  ];
  private patientSortBy = this.patientSortByList[0];

  // ソート順変更
  private changeSortOrdar(rule: SortTypeSelect) {
    this.patientSortBy = rule;
    this.searchCond.sort = rule.sort;
    this.searchCond.sort_type = rule.sortType;
    this.searchCond.page_no = 1;
    this.fetch();
  }

  // あいうえおフィルター変更
  private changeAlphabetFilter(filter: Choice) {
    this.alphabetFilterBy = filter;
    this.searchCond.alphabet_filter_type = Number(filter.value);
    this.searchCond.page_no = 1;
    this.fetch();
  }

  // 利用者選択
  private clickSelect(item: PatientDispInfo): void {
    this.callbackSelect(item.id, item);
  }

  // 新規利用者受付
  private clickAccept(): void {
    this.callbackAccept();
  }

}
