













import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";
import { EldCertItems } from "./type";

@Component({
  components: {
    ItemTable
  }
})
export default class EldCert extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 医療保険 */
  @Prop({ default: () => [] }) private readonly items!: EldCertItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 介護保険負担割合証ヘッダー */
  private careRateCertHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    { text: "記号・番号", value: "insured_no" },
    { text: "負担割合", value: "payment_rate", width: "158px" },
    {
      text: "開始日",
      value: "start_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    }
  ];

  /** 新規作成 */
  private eldCertCreate() {
    this.$router.push({
      name: "InsuranceInfoEldCert",
      params: { id: String(this.patientId), inseldcertid: "0" }
    });
  }

  /** 修正 */
  private eldCertRepair(item: EldCertItems) {
    this.$router.push({
      name: "InsuranceInfoEldCert",
      params: { id: String(this.patientId), inseldcertid: String(item.id) }
    });
  }

  /** コピー */
  private eldCertCopy(item: EldCertItems) {
    this.$router.push({
      name: "InsuranceInfoEldCert",
      params: { id: String(this.patientId), inseldcertid: String(item.id) },
      query: { mode: "copy" }
    });
  }

  /** 現在有効な高齢受給者証に色を付ける */
  private eldCertItemsRowBackground(item: EldCertItems) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
