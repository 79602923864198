








































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";

@Component
export default class CarelogoodConfigDialog extends Mixins(AxiosMixin) {
  /** 事業所ID */
  @Prop({ default: 0 }) officeId!: number;

  /** 事業所名 */
  @Prop({ default: "" }) officeName!: string;

  /** けあログとして、所属しているかどうか */
  @Prop({ default: 0 }) isCarelog!: number;

  /** ダイアログの開閉状態 */
  @Prop({ default: false }) dialog!: number;

  /** ダイアログを閉じる */
  @Emit() private close() {
    return;
  }

  /** けあログっと設定画面へ遷移 */
  private linkShift() {
    //けあログっとを開く
    this.postJsonCheck(
      window.auth_backend_url + "/outer/auth/portal/token",
      { portal_office_staff_id: 0, portal_request_user_id: 0 },
      res => {
        this.setCookie(res);
        //けあログっとの登録画面を開く
        location.href =
          window.portal_frontend_url +
          "/recept?from_ibow=1&use_type=1&signup_office_id=" +
          this.officeId;
      }
    );
  }

  /** クッキーに設定 */
  private setCookie(res: AxiosResponse) {
    window.token = res.data.token;
    Cookies.set("token", res.data.token, {
      domain: window.cookie_domain
    });
    window.reftoken = res.data.ref_token;
    Cookies.set("reftoken", res.data.ref_token, {
      domain: window.cookie_domain
    });
  }
}
