





















import { Component, Prop, Emit } from "vue-property-decorator";
import RegularPartBase from "./RegularPartBase";

@Component
export default class VisitInfo extends RegularPartBase {
  @Prop({ default: false }) readonly service_visit!: boolean;

  @Emit("update:service_visit") private updateServiceVisit(newValue: boolean) {
    return newValue;
  }
  private get ServiceVisit() {
    return this.service_visit;
  }

  private set ServiceVisit(newValue: boolean) {
    this.updateServiceVisit(newValue);
  }
}
