


















































































import { Component, Mixins, Watch, Prop, Ref } from "vue-property-decorator";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import * as constant from "#/const";
import PatientMixin from "@/mixins/patientMixin";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { VisitModelExt, DefaultVisitModelExt } from "@/components/visit/types";
import BaseVisit from "@/views/BaseVisit";
import PatientInfoDialog from "@/components/patient/PatientInfoDialog.vue";
import VisitHeader from "@/components/visit/VisitHeader.vue";

@Component({
  components: {
    PatientHeader,
    PatientInfoDialog,
    VisitHeader
  }
})
export default class VisitMove extends Mixins(
  PatientMixin,
  BaseVisit,
  UtilMixin,
  AxiosMixin
) {
  @Ref("patientInfoDialog") readonly patientInfoDialog!: PatientInfoDialog;

  /** 変数 */
  @Prop() readonly pheader!: VisitHeader;

  /** 訪問開始ダイアログの開閉状態 */
  private actionDialog = false;

  async mounted() {
    this.patientId = Number(this.$route.params.id);
    //直打ち時はまだ利用者情報とログイン職員は読み込まれてないので、保存処理はWatcherに任せる
    this.saveModelMoving();

    // オペログ書き込み用
    this.postJsonCheck(
      window.base_url + "/api/visit/move",
      { patient_id: this.patientId },
      () => {
        // レスポンスはなし
      }
    );
  }

  //利用者情報とログイン職員が両方読み込まれたら、データ更新
  @Watch("patientInfo.name")
  private watchPatientInfo() {
    this.saveModelMoving();
  }
  @Watch("loginUser.id")
  private watchLoginUserId() {
    this.saveModelMoving();
  }

  /** 訪問一時保存情報の切り替え */
  private async saveModelMoving() {
    if (
      this.loginUser.id === 0 ||
      this.patientInfo.name === "" ||
      this.patientId === 0
    ) {
      return;
    }

    if (await this.getVisitTmpData(String(this.loginUser.id))) {
      return;
    }
    let model = this.visitTmpDataState.data as VisitModelExt;
    if (!model) {
      //モデルが存在しない場合は、ここでモデル作成する
      model = DefaultVisitModelExt();
      model.patient_id = Number(this.patientId);
      model.patient_name = this.patientInfo.name;
      model.mode = constant.VISIT_RECORD.MODE_MOVE;
    } else {
      //モデルが存在する場合は、チェック処理を実行する
      if (await this.validModel(model)) {
        return;
      }
    }

    await this.saveVisitTmpData(model);
  }

  /** 訪問開始ダイアログの開閉状態切り替え */
  private toggleActionDialog(): void {
    this.actionDialog = !this.actionDialog;
  }

  /** 訪問開始 */
  private async clickVisitStart() {
    const model = await this.getVisitFirestoreData();
    if (!model) {
      return;
    }

    // 移動中
    this.pheader.mode = 0;
    //訪問メモアラート
    if (this.pheader.IsDispMemo) {
      await this.pheader.openMemoDialog();
    }
    const date = new Date();
    model.visit_start_datetime = this.dateToStr(date, "yyyy-MM-dd HH:mm:ss");
    model.staff_id = this.loginUser.id;
    model.mode = constant.VISIT_RECORD.MODE_STAY;
    model.is_disp_memo_end = this.pheader.isDispMemoEnd;
    if (await this.saveVisitTmpData(model)) {
      return;
    }
    this.$router.replace({
      path: `/visit/${this.patientId}/stay`
    });
  }

  /** 不在 */
  private async clickAbsence() {
    const model = await this.getVisitFirestoreData();
    if (!model) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/visit/move/absence",
      { patient_id: Number(this.patientId) },
      async () => {
        if (await this.deleteVisitTmpData()) {
          return;
        }
        this.$router.replace({ name: "Home" });
      }
    );
  }

  /** キャンセル */
  private async clickCancel() {
    const model = await this.getVisitFirestoreData();
    if (!model) {
      return;
    }
    if (await this.deleteVisitTmpData()) {
      return;
    }
    // オペログ書き込み用
    this.postJsonCheck(
      window.base_url + "/api/visit/cancel",
      { patient_id: this.patientId },
      () => {
        // レスポンスはなし
      }
    );
    this.$router.replace({ name: "Home" });
  }

  /** 利用者情報画面に移動 */
  private async patientInfoMove() {
    if (
      !(await this.$openConfirm(
        "利用者情報画面に移動する場合、訪問をキャンセルします。\nよろしいですか？"
      ))
    ) {
      return;
    }
    const model = await this.getVisitFirestoreData();
    if (!model) {
      return;
    }
    if (await this.deleteVisitTmpData()) {
      return;
    }
    this.$router.push({ path: `/patient/${this.patientId}/calendar` });
  }
}
