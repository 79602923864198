var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppBreadcrumbs',{attrs:{"items":_vm.breadItems}}),_c('v-row',{staticClass:"pt-4 pb-6",attrs:{"no-gutters":""}},[_c('AppTitle',{attrs:{"label":"利用者詳細"}})],1),_c('div',{staticClass:"ibow2-receipt-sales"},[_c('v-row',{staticClass:"align-center px-4 py-6",attrs:{"no-gutters":""}},[_c('div',{staticClass:"mr-4"},[_c('AppTitle',{staticClass:"ibow2-receipt-sales-table-title mb-2",attrs:{"label":_vm.officeName}}),_c('p',{staticClass:"mb-0 grey-light--text"},[_vm._v(_vm._s(_vm.receiptDate))])],1),_c('v-spacer'),_c('AppButton',{attrs:{"color":"grey","outlined":""},on:{"click":_vm.downloadExcel}},[_vm._v("Excel出力")])],1),_c('AppDataTable',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tableDatas,"isNoTop":true,"isNoFooter":true,"disable-pagination":"","noDataText":"データがありません。"},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total.toLocaleString())+" ")]}},{key:"item.medical_insurance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.medical_insurance.toLocaleString())+" ")]}},{key:"item.medical_paymentfund",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.medical_paymentfund.toLocaleString())+" ")]}},{key:"item.medical_ownexpense",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.medical_ownexpense.toLocaleString())+" ")]}},{key:"item.adjust_amount1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.adjust_amount1.toLocaleString())+" ")]}},{key:"item.care_insurance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.care_insurance.toLocaleString())+" ")]}},{key:"item.care_publicexpenditure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.care_publicexpenditure.toLocaleString())+" ")]}},{key:"item.care_ownexpense",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.care_ownexpense.toLocaleString())+" ")]}},{key:"item.adjust_amount2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.adjust_amount2.toLocaleString())+" ")]}},{key:"item.ownexpense",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ownexpense.toLocaleString())+" ")]}}],null,true)})],1),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }