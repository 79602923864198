

























































































































import { Component, Watch } from "vue-property-decorator";
import OneMonthAdd from "./OneMonthAdd.vue";

@Component
export default class OneMonthAddV2 extends OneMonthAdd {
  created(): void {
    this.setHour24Div();
    this.setMedicalSpecialDiv();
    this.setMedicalTerminalDiv();
    this.setAccompaniedStaffCnt();
  }

  //ターミナルケア
  protected setMedicalTerminalDiv() {
    if (this.receiptVisitRecord.medical_terminal_div == 1) {
      this.medical_terminal_div1 = true;
      this.medical_terminal_div2 = false;
    }
    if (this.receiptVisitRecord.medical_terminal_div == 2) {
      this.medical_terminal_div1 = false;
      this.medical_terminal_div2 = true;
    }
  }

  //精神科重症患者支援管理連携加算
  protected setAccompaniedStaffCnt() {
    if (this.receiptVisitRecord.mental_support_div == 1) {
      this.mental_support_div1 = true;
      this.mental_support_div2 = false;
    }
    if (this.receiptVisitRecord.mental_support_div == 2) {
      this.mental_support_div1 = false;
      this.mental_support_div2 = true;
    }
  }

  /** ターミナルケア1 */
  protected medical_terminal_div1 = false;

  /** ターミナルケア2 */
  protected medical_terminal_div2 = false;

  /** 精神科重症患者支援管理連携加算（イ） */
  protected mental_support_div1 = false;

  /** 精神科重症患者支援管理連携加算（ロ） */
  protected mental_support_div2 = false;

  @Watch("medical_terminal_div1") protected handleMedicalTerminalDiv1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.medical_terminal_div1 == true) {
      this.receiptVisitRecord.medical_terminal_div = 1;
      this.medical_terminal_div2 = false;
    }
    if (
      this.medical_terminal_div1 == false &&
      this.medical_terminal_div2 == false
    ) {
      this.receiptVisitRecord.medical_terminal_div = 0;
    }
  }

  @Watch("medical_terminal_div2") protected handleMedicalTerminalDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.medical_terminal_div2 == true) {
      this.receiptVisitRecord.medical_terminal_div = 2;
      this.medical_terminal_div1 = false;
    }
    if (
      this.medical_terminal_div1 == false &&
      this.medical_terminal_div2 == false
    ) {
      this.receiptVisitRecord.medical_terminal_div = 0;
    }
  }

  //精神科重症患者支援管理連携加算（イ）
  @Watch("mental_support_div1") protected handleMentalSupportDiv1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.mental_support_div1 == true) {
      this.receiptVisitRecord.mental_support_div = 1;
      this.mental_support_div2 = false;
    }
    if (
      this.mental_support_div1 == false &&
      this.mental_support_div2 == false
    ) {
      this.receiptVisitRecord.mental_support_div = 0;
    }
  }
  //精神科重症患者支援管理連携加算（ロ）
  @Watch("mental_support_div2") protected handleMentalSupportDiv2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.mental_support_div2 == true) {
      this.receiptVisitRecord.mental_support_div = 2;
      this.mental_support_div1 = false;
    }
    if (
      this.mental_support_div1 == false &&
      this.mental_support_div2 == false
    ) {
      this.receiptVisitRecord.mental_support_div = 0;
    }
  }

  //ターミナルケア
  protected disableCheckMedicalTerminalDivV2(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_terminal_care_add == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //精神科重症患者支援管理連携加算
  protected disableCheckMentalSupportDivV2(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_seishin_renkei_add != 1 ||
      this.event.visit_record.mental_div == 1 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //情報提供療養費
  protected disableCheckInfoExpensesFlgV2(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.stay_outside_flg == 1 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }

  //看護・介護職員連携
  protected disableCheckNursingCareFlg(): boolean {
    let result = false;
    if (
      this.officeHistory.medical_hour24_taiou_add == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //看護・介護職員連携日
  protected disableCheckNursingCareDate(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.nursing_care_flg == 0 ||
      this.baseDisableCheck()
    ) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV2() {
    //ターミナルケア
    if (this.disableCheckMedicalTerminalDivV2()) {
      this.medical_terminal_div1 = false;
      this.medical_terminal_div2 = false;
      this.receiptVisitRecord.medical_terminal_div = 0;
    }
    //特別管理指導
    if (this.disableCheckSpecialInstFlg()) {
      this.receiptVisitRecord.special_inst_flg = 0;
    }
    //24時間体制
    if (this.disableCheckMedicalHour24Add()) {
      this.hour24_div1 = false;
      this.hour24_div2 = false;
      this.receiptVisitRecord.hour24_div = 0;
    }
    //特別管理加算
    if (
      this.disableCheckMedicalSpecialDiv1() &&
      this.disableCheckMedicalSpecialDiv2()
    ) {
      this.medical_special_div1 = false;
      this.medical_special_div2 = false;
      this.receiptVisitRecord.medical_special_div = 0;
    }
    //在宅患者連携指導
    if (this.disableCheckHomeInstFlg()) {
      this.receiptVisitRecord.home_inst_flg = 0;
    }
    //精神科重症患者支援管理連携加算
    if (this.disableCheckMentalSupportDivV2()) {
      this.mental_support_div1 = false;
      this.mental_support_div2 = false;
      this.receiptVisitRecord.mental_support_div = 0;
    }
    //情報提供療養費
    if (this.disableCheckInfoExpensesFlgV2()) {
      this.receiptVisitRecord.info_expenses_flg1 = 0;
      this.receiptVisitRecord.info_expenses_flg2 = 0;
      this.receiptVisitRecord.info_expenses_flg3 = 0;
    }
    //看護・介護職員連携
    if (this.disableCheckNursingCareFlg()) {
      this.receiptVisitRecord.nursing_care_flg = 0;
    }
    //看護・介護職員連携日
    if (this.disableCheckNursingCareDate()) {
      this.receiptVisitRecord.nursing_care_date = "";
    }
  }
}
