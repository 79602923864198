import { PatientDispInfo, Detail } from "@/components/patient/types";
import { ReportCount } from "@/types";

export const name = "patient";

export const SET_PATIENT = "SET_PATIENT";

export const SET_PATIENT_MASTER = "SET_PATIENT_MASTER";

export const SET_PATIENT_BASE = "SET_PATIENT_BASE";

export const SET_BREAD_ITEMS = "SET_BREAD_ITEMS";

export interface PatientState {
  info: PatientDispInfo; //職員基本情報
  detail: Detail; //職員詳細
  report_count: ReportCount; //未提出書類
  master: PatientMaster; //マスター
  bread_items: BreadItem[]; //パンくずリスト
  office_id: number; //事業所ID
  error: boolean;
}

export interface SetPatient {
  info: PatientDispInfo;
  detail: Detail;
  report_count: ReportCount;
}

export interface SetPatientMaster {
  master: PatientMaster;
}

export interface PatientList {
  id: number;
  title: string;
  link: string;
  count: number;
  report_group: number;
}

export interface BreadItem {
  text: string;
  disabled: boolean;
  to: string;
  exact?: boolean;
}

export interface PatientMaster {
  /** 関連機関リスト */
  relation_institutions: RelationInstitutionPatient[];
  /** 医療機関リスト */
  medical_institutions: MedicalInstitutionPatient[];
  /** 利用者メニュー */
  patient_menus: PatientList[];
  /** 所属事業所ID */
  office_id?: number;
  /** 看護計画書AI自動作成機能ON/OFF */
  is_ai_vnplan_enabled: number;
  /** 月次報告書AI自動作成機能ON/OFF */
  is_ai_month_report_enabled: number;
}

export interface RelationInstitutionPatient {
  id: number;
  name: string;
  furigana: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  support_office_div: number;
  document_form: number;
  personnels: PersonnelPatient[];
}

export interface PersonnelPatient {
  id: number;
  full_name: string;
  furigana: string;
  is_empty: number;
  status: number;
  section_name: string;
  relation_institution_id: number;
  relation_institution_name: string;
  personnel_contacts: Contact[];
}

export interface MedicalInstitutionPatient {
  id: number;
  name: string;
  furigana: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  doctors: DoctorPatient[];
}

export interface DoctorPatient {
  id: number;
  full_name: string;
  furigana: string;
  is_empty: number;
  status: number;
  section_name: string;
  medical_institution_id: number;
  medical_institution_name: string;
  doctor_contacts: Contact[];
}

export interface Contact {
  contact_type: number;
  contact_info: string;
}
