




















import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import InsurerForm from "@/components/master/insurer/InsurerForm.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import { Insurer, setInsurer } from "./types";
import EditMixin from "@/mixins/editMixin";

@Component({
  components: { InsurerForm }
})
export default class InsurerEdit extends Mixins(AxiosMixin, EditMixin) {
  @Ref("form") private readonly form!: InsurerForm;

  public insurer: Insurer = setInsurer();

  public created(): void {
    if (this.$route.params.id && Number(this.$route.params.id) > 0) {
      this.getInsurer(Number(this.$route.params.id));
    } else {
      this.setLoaded();
    }
  }

  //保険者取得
  private getInsurer(id: number): void {
    this.postJsonCheck(
      window.base_url + "/api/master/insurer/get",
      {
        insurer_id: id
      },
      res => {
        this.insurer = res.data.insurer;
        this.form.selectedOffice = [];
        this.insurer.insurer_offices.forEach(office => {
          if (office.office_id) {
            this.form.selectedOffice.push(office.office_id);
          }
        });
        this.setLoaded();
      }
    );
  }

  //保険者保存
  public async save(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.insurer.insurer_offices = this.form.selectedOffice.map(off => {
      return {
        id: 0,
        office_id: off
      };
    });
    this.postJsonCheck(
      window.base_url + "/api/master/insurer/save",
      {
        insurer: this.insurer
      },
      () => {
        this.setNoEditMode();
        this.$router.push({ name: "InsurerEntry" });
      }
    );
  }

  //保険者削除
  public async deleteInsurer(): Promise<void> {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/insurer/delete",
      {
        insurer: this.insurer
      },
      () => {
        this.setNoEditMode();
        this.$router.push({ name: "InsurerEntry" });
      }
    );
  }

  //編集モード設定
  @Watch("insurer", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
