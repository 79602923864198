
























import { Component, Mixins } from "vue-property-decorator";
import ItemTable from "#/components/ItemTable.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import Normal from "@/components/patient/indicate/Normal.vue";
import Special from "@/components/patient/indicate/Special.vue";
import Template from "@/components/patient/indicate/Template.vue";
import { Link } from "#/types";

@Component({
  components: {
    ItemTable,
    PatientHeader,
    Normal,
    Special,
    Template
  }
})
export default class PatientIndicate extends Mixins(
  AxiosMixin,
  UtilMixin,
  PatientMixin
) {
  /** 利用者ID */
  private patientId = 0;

  /** 普通指示書 */
  private indicates: unknown[] = [];

  /** 特別指示書 */
  private specialIndicates: unknown[] = [];

  /** 左サイドメニューアイテム */
  private links = [
    { text: "普通", key: "Normal" },
    { text: "特別", key: "Special" },
    { text: "ひな形", key: "Template" }
  ];

  /** 現在有効なメニュー */
  private link = this.links[0];

  /** コンポーネントに渡すテーブルアイテム */
  private get Items() {
    let items: unknown[] = [];
    switch (this.link.key) {
      case "Normal":
        items = this.indicates;
        break;
      case "Special":
        items = this.specialIndicates;
        break;
      case "Template":
        items = [];
        break;
    }
    return items;
  }

  created(): void {
    this.patientId = Number(this.$route.params.id);
    this.fetchIndicateList();
  }

  mounted() {
    // 有効なリンクの切り替え
    this.changeLinkQuery();
    this.setDefaultHeader();
  }

  //URLの変更で動作変更
  private changeLinkQuery() {
    if (typeof this.$route.query.key === "string") {
      const key = this.$route.query.key;
      for (const v of this.links) {
        if (v.key == key) {
          this.link = v;
        }
      }
    }
  }

  private setUrlParams({ link }: { link: Link }) {
    this.$router.push({
      query: {
        key: link.key
      }
    });
  }

  //保険情報一覧取得
  private fetchIndicateList(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/indicates/get",
      {
        patient_id: this.patientId
      },
      res => {
        if (res.data.indicates) {
          this.indicates = res.data.indicates;
        }
        if (res.data.special_indicates) {
          this.specialIndicates = res.data.special_indicates;
        }
      }
    );
  }
}
