




























































import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { VnPlanProblem, VnPlanProblemCareContent } from "#/model/vnplan";

interface Item {
  id: number;
  care_contents: VnPlanProblemCareContent[];
  problem: string;
}

@Component
export default class ReferenceVnplan extends Vue {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** 看護計画書 */
  @Prop({ default: () => [] })
  private readonly vnplan_problems!: VnPlanProblem[];

  /** 展開状態 */
  private dialog = false;

  /** 選択項目 */
  private selecteds: Item[] = [];

  /** 問題点・解決策 */
  private items: Item[] = [];

  /** テーブルヘッダ */
  private headers = [
    {
      text: "看護内容",
      value: "care_contents",
      sortable: false,
      width: "250px"
    },
    {
      text: "療養上の課題",
      value: "problem",
      sortable: false
    }
  ];

  /** ダイアログを開いた時に、計画書の項目からテーブルアイテムを作成し、選択済みにしておく */
  @Watch("dialog") private watchDialog() {
    if (
      !this.dialog ||
      !this.vnplan_problems ||
      this.vnplan_problems.length == 0
    ) {
      return;
    }

    this.items = [];
    this.selecteds = [];
    for (const problem of this.vnplan_problems) {
      if (!problem.care_contents) {
        problem.care_contents = [];
      }

      if (problem.care_contents.length == 0) {
        continue;
      }

      const planItem = {
        id: problem.id,
        care_contents: problem.care_contents,
        problem: problem.problem
      };
      this.items.push(planItem);
      this.selecteds.push(planItem);
    }
  }

  /** 計画書を記録書２に反映ボタンクリック */
  private clickVnPlan() {
    this.update();
    this.dialog = false;
  }

  /** 選択された看護計画書の看護内容を返却する */
  @Emit() private update(): VnPlanProblemCareContent[] {
    let result: VnPlanProblemCareContent[] = [];
    for (const select of this.selecteds) {
      result = result.concat(select.care_contents);
    }
    return result;
  }

  /** キャンセルボタンクリック */
  private clickCancel() {
    this.dialog = false;
  }
}
