





















































































































import {
  Component,
  Prop,
  Watch,
  Emit,
  Mixins,
  Ref
} from "vue-property-decorator";
import { OwnExpense, DefaultOwnExpense } from "#/model/patient";
import ZeroDispNumberInput from "#/components/ZeroDispNumberInput.vue";
import { Choice, VForm } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import OwnExpenseMixin from "@/mixins/ownExpenseMixin";
import { OwnExpenseItemChoice } from "#/model/patient/ownExpense";

@Component({ components: { ZeroDispNumberInput } })
export default class PrivateExpenseEdit extends Mixins(
  UtilMixin,
  RulesMixin,
  OwnExpenseMixin
) {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: () => DefaultOwnExpense() }) model!: OwnExpense; // 自費
  @Prop({ default: false }) isNew!: boolean; // 新規登録フラグ
  @Prop({ default: () => [] }) categories!: Choice[]; // 自費カテゴリリスト
  @Prop({ default: () => [] }) items!: OwnExpenseItemChoice[]; // 自費カテゴリアイテムリスト

  /*
   * emit
   */

  // キャンセルイベント通知
  @Emit()
  private cancel(): void {
    this.close();
  }

  // セーブイベント通知
  @Emit("update:model")
  private save() {
    // 金額算出
    this.calculation(this.localModel);
    this.close();
    return this.localModel;
  }

  // 削除イベント通知
  @Emit("delete")
  private clickDelete() {
    this.close();
  }

  /*
   * ref
   */

  // フォーム
  @Ref("form") private readonly form!: VForm;

  /*
   * watch
   */

  @Watch("model")
  private init() {
    if (!this.model) return;
    this.localModel = this.deepCopy(this.model);
    if (this.localModel.yearmonth == "") {
      const date = new Date();
      this.localModel.yearmonth = this.dateToStr(date, "yyyy-MM"); // 請求月に今月をセット
    }
    this.changeTaskDiv(this.localModel);
  }

  /*
   * computed
   */

  /** 単価と数量の初期値0を空欄として表示するかフラグ */
  private get IsNeedDispEmptyFirst() {
    return this.localModel.id == 0;
  }

  /** 自費アイテムマスタの絞り込み（カテゴリ選択時それに付随するアイテム表示）*/
  private get RefinementOwnExpenseItems() {
    return this.refinementOwnExpenseItems(this.localModel.category_name);
  }

  /*
   * data
   */

  private dialog = false;
  private localModel: OwnExpense = DefaultOwnExpense();

  /*
   * method
   */

  // ダイアログオープン
  public open() {
    this.$nextTick(() => {
      this.initItems();
      this.dialog = true;
      if (this.form) {
        this.form.resetValidation();
      }
    });
  }

  // ダイアログクローズ
  public close() {
    this.dialog = false;
  }

  // 保存処理
  @Emit("clickSave")
  private async clickSave(): Promise<void> {
    // バリデート
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.save();
  }

  /** カテゴリ・アイテム一覧を設定 */
  private initItems() {
    this.ownExpenseCategorys = this.categories;
    this.ownExpenseItems = this.items;

    this.isLoaded = true;
  }

  /** カテゴリ名・アイテム名変更時 */
  private changeItem() {
    const item = this.selectedItem(
      this.localModel.category_name,
      this.localModel.item_name
    );
    if (!item) {
      return;
    }
    // 項目セット
    this.setSelectedOwnExpense(this.localModel, item);
  }

  // バリデート 課税率
  private get RuleTaxRate() {
    return this.ruleTaxRate(this.localModel);
  }
}
