











import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import PatientMixin from "@/mixins/patientMixin";
import { RequesterChoice } from "@/types";

@Component
export default class PatientMedicalInsOnlySelect extends Mixins(PatientMixin) {
  @Prop({ default: "医療機関のみ" }) label!: string;
  @Prop() value!: number;
  @Prop({ default: false }) not_select!: boolean;

  private get Items() {
    const items: RequesterChoice[] = [];

    if (this.not_select) {
      items.push({
        id: 0,
        value: 0,
        text: "未選択",
        tel_area: "",
        tel_city: "",
        tel_no: ""
      });
    }

    for (const ins of this.patientMaster.medical_institutions) {
      for (const doctor of ins.doctors) {
        if (doctor.is_empty === 1) {
          continue;
        }

        let text = ins.name;
        if (doctor.full_name !== "") {
          text += "/" + doctor.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: doctor.id,
          value: doctor.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num
        });
      }
    }
    return items;
  }

  private get Value(): number {
    return this.value;
  }

  private set Value(newValue: number) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: number) {
    return newValue;
  }
}
