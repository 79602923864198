





























































import { Component, Mixins } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

/** 検索リクエスト */
interface SearchCond {
  /**  */
  kind: number;
  /** 事業所番号 */
  office_id: number;
  /** 年月 */
  year_month: string;
  /* 1:訪問時間に実時間を使用する 2:訪問時間に提供時間を使用する*/
  time_div: number;
}

@Component({
  components: {
    OfficeSelect
  }
})
export default class CsvOutputWiseman extends Mixins(AxiosMixin, UtilMixin) {
  private searchCond: SearchCond = {
    kind: 1,
    office_id: 0,
    year_month: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 7),
    time_div: 0
  };

  private LOCAL_STORAGE_WIZEMAN_OUTPUT_STATUS =
    "LOCAL_STORAGE_WIZEMAN_OUTPUT_STATUS";

  private kinds = [{ value: 1, text: "レセプト実績情報" }];

  created() {
    this.searchCond.time_div = Number(
      window.localStorage.getItem(this.LOCAL_STORAGE_WIZEMAN_OUTPUT_STATUS)
    );
    if (this.loginUser.office_id) {
      this.searchCond.office_id = this.loginUser.office_id;
    } else {
      this.searchCond.office_id = this.HeadOffice;
    }
  }

  /**
   * CSV出力ボタン押下時
   */
  private clickOutputCsv() {
    // const yearMonth = this.dateToStr(new Date(), "yyyyMM");
    // ST1234567890_202203241609_30102.WTF
    // const fileName = `ST1234567890${Date.now().toString()}.WTF`;

    const fileName = `ST${this.searchCond.office_id}_${this.dateToStr(
      new Date(),
      "yyyyMMddHHmmss"
    )}_30102.WTF`;

    window.localStorage.setItem(
      this.LOCAL_STORAGE_WIZEMAN_OUTPUT_STATUS,
      String(this.searchCond.time_div)
    );

    this.postJsonBlobResCheck(
      window.base_url + "/api/master/dataio/wiseman/output",
      this.searchCond,
      res => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }
}
