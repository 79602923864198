import Base from "../base";
import { FileIF } from "./file";

export interface Template extends Base {
  /** ID */
  id: number;
  /** テンプレート状態\n1:下書き\n2:完成(未公開)\n3:FAQ公開 */
  status: number;
  /** 問い合わせ内容 */
  inquiry_content: string;
  /** 回答内容 */
  answer_content: string;
  /** 回答内容(検索用) */
  search_content: string;
  /** 公開日時 */
  publish_datetime: string;
  /** 最終更新者(管理者)ID */
  admin_id: number;
  /** おすすめFAQか */
  is_recommend: number;
  /** カテゴリ情報 */
  template_categorys: TemplateCategory[];
  /** ハッシュタグ情報 */
  template_hashtags: TemplateHashtag[];
  /** 添付ファイル情報 */
  template_files: TemplateFile[];
}

export interface TemplateHashtag extends Base {
  /** ID */
  id: number;
  /** テンプレートID */
  template_id: number;
  /** ハッシュタグ */
  hashtag: string;
}

export interface TemplateFile extends Base, FileIF {
  /** ID */
  id: number;
  /** テンプレートID */
  template_id: number;
  /** 並び順 */
  sort_no: number;
  /** ファイルパス */
  file_path: string;
}

export interface TemplateCategory extends Base {
  /** ID */
  id: number;
  /** テンプレートID */
  template_id: number;
  /** カテゴリID */
  category_id: number;
  /** カテゴリ種類 */
  category_type: number;
  /** カテゴリ名 */
  category_name: string;
  /** 親カテゴリID */
  parent_id: number;
  /** 子カテゴリ情報 */
  child_categorys: TemplateCategory[];
}

export interface Category extends Base {
  /** ID */
  id: number;
  /** 親カテゴリID */
  parent_id: number;
  /** カテゴリID */
  category_id: number;
  /** カテゴリ種類 */
  category_type: number;
  /** カテゴリ名 */
  category_name: string;
  /** 子カテゴリ情報 */
  child_categorys: Category[];
}

export interface RecommendKeyword extends Base {
  /** ID */
  id: number;
  /** おすすめキーワード */
  keyword: string;
}

export const DEFAULT_TEMPLATE: Template = {
  id: 0,
  status: 1,
  inquiry_content: "",
  answer_content: "",
  search_content: "",
  publish_datetime: "",
  admin_id: 0,
  is_recommend: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  template_categorys: [],
  template_hashtags: [],
  template_files: []
};

export const DEFAULT_TEMPLATE_CATEGORY: TemplateCategory = {
  id: 0,
  template_id: 0,
  category_id: 0,
  category_type: 0,
  category_name: "",
  parent_id: 0,
  child_categorys: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
};

export const DEFAULT_TEMPLATE_FILE: TemplateFile = {
  id: 0,
  template_id: 0,
  sort_no: 0,
  file_path: "",
  path: "",
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
};
