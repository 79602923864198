import { Vue, Component } from "vue-property-decorator";
import { Indicate } from "#/model/patient";

@Component
export default class IndicateMixin extends Vue {
  // 医療機関情報：必須
  protected requiredMedicalItems(indicate: Indicate): boolean | string {
    if (indicate.doctor_id === 0) {
      return "医療機関情報を選択してください";
    }
    return true;
  }

  // 開始日：注射指示ありでない場合は必須
  // 注射指示ありでも、終了日が入力されていれば必須
  protected checkStartDate(indicate: Indicate): boolean | string {
    if (!indicate.is_injection && !indicate.start_date) {
      return "開始日を入力してください";
    }
    if (!indicate.start_date && indicate.end_date) {
      return "終了日が入力されている場合は開始日も入力してください";
    }
    if (
      indicate.start_date &&
      indicate.end_date &&
      indicate.start_date > indicate.end_date
    ) {
      return "開始日は終了日以前を入力してください";
    }
    return true;
  }

  // 終了日：注射指示ありでなく書面未到着でない場合は必須
  // 注射指示ありでも、開始日が入力されていれば必須
  protected checkEndDate(indicate: Indicate): boolean | string {
    if (
      !indicate.is_injection &&
      !indicate.is_not_document_arrive &&
      !indicate.end_date
    ) {
      return "終了日を入力してください";
    }
    if (
      !indicate.is_not_document_arrive &&
      indicate.start_date &&
      !indicate.end_date
    ) {
      return "開始日が入力されている場合は終了日も入力してください";
    }
    if (
      indicate.start_date &&
      indicate.end_date &&
      indicate.start_date > indicate.end_date
    ) {
      return "終了日は開始日以降を入力してください";
    }
    return true;
  }

  // 注射指示開始日：注射指示ありの場合は必須
  protected checkInjectionStartDate(indicate: Indicate): boolean | string {
    if (indicate.is_injection && !indicate.injection_start_date) {
      return "開始日を入力してください";
    }
    if (
      indicate.is_injection &&
      indicate.injection_start_date &&
      indicate.injection_end_date &&
      indicate.injection_start_date > indicate.injection_end_date
    ) {
      return "点滴注射指示開始日は終了日以前を入力してください";
    }
    return true;
  }

  // 点滴注射終了日：注射指示ありで書面未到着でない場合は必須
  protected checkInjectionEndDate(indicate: Indicate): boolean | string {
    if (
      indicate.is_injection &&
      !indicate.is_not_document_arrive &&
      !indicate.injection_end_date
    ) {
      return "終了日を入力してください";
    }
    if (
      indicate.is_injection &&
      indicate.injection_start_date &&
      indicate.injection_end_date &&
      indicate.injection_start_date > indicate.injection_end_date
    ) {
      return "点滴注射指示終了日は開始日以降を入力してください";
    }
    return true;
  }

  // 終了日：6ヶ月以上チェック
  protected check6MonthsOrMore(indicate: Indicate): boolean | string {
    if (indicate.start_date && indicate.end_date) {
      const start = new Date(indicate.start_date.replace(/-/g, "/"));
      const end = new Date(indicate.end_date.replace(/-/g, "/"));
      const monthAdd6 = new Date(indicate.start_date.replace(/-/g, "/"));
      monthAdd6.setMonth(start.getMonth() + 6);
      if (monthAdd6 <= end) {
        return "6ヶ月を超える期間は入力できません";
      }
    }
    return true;
  }

  // 終了日：15日以上チェック
  protected check15DaysOrMore(indicate: Indicate): boolean | string {
    if (indicate.end_date) {
      const start = new Date(indicate.start_date.replace(/-/g, "/"));
      const end = new Date(indicate.end_date.replace(/-/g, "/"));
      const dayAdd15 = new Date(indicate.start_date.replace(/-/g, "/"));
      dayAdd15.setDate(start.getDate() + 14);
      if (dayAdd15 <= end) {
        return "15日以上の期間は入力できません";
      }
    }
    return true;
  }
}
