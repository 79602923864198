

































































































































































































































































































































































































































import { Component, Ref } from "vue-property-decorator";
import { VForm } from "@/types";
import {
  StatisticHeader,
  StatisticHeaderColumn,
  DefaultStatisticsSearchCond,
  StatisticsItem
} from "@/components/dashboard/types";
import {
  StatisticFavorite,
  StatisticFavoriteGroup,
  StatisticFavoriteColumn,
  DefaultStatisticFavorite
} from "#/model/statistic";
import { SELECT_FILTER, OFFICE_STAFF } from "#/const";
import {
  ColumnKey,
  FilterClause,
  IsFilterMatch
} from "#/components/selectFilter/filter";
import { GetStatisticHeader } from "@/components/dashboard/StatisticSearchHeader";
import SelectFilter from "#/components/selectFilter/SelectFilter.vue";
import OfficeSelects from "@/components/common_ibow/OfficeSelects.vue";
import StatisticsSearchBase from "./StatisticsSearchBase";

@Component({
  components: {
    OfficeSelects,
    SelectFilter
  }
})
export default class StatisticsSearch extends StatisticsSearchBase {
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;
  /** 事務所事務員権限 */
  private STAFF_CLERK = OFFICE_STAFF.AUTH.CLERK;
  /** 本社職員権限 */
  private STAFF_HOME = OFFICE_STAFF.AUTH.HOME;
  /** システム管理者権限 */
  private STAFF_ADMIN = OFFICE_STAFF.AUTH.ADMIN;

  @Ref("selectFilterPatient")
  private readonly selectFilterP_!: SelectFilter;
  @Ref("selectFilterVisit") private readonly selectFilterV_!: SelectFilter;
  /** フィルター */
  private get selectFilter() {
    if (this.tab === 0) {
      return this.selectFilterP_;
    } else {
      return this.selectFilterV_;
    }
  }

  /** データテーブル */
  @Ref("tableData") private readonly tableData!: {
    $children: StatisticsItem[];
  };
  /** 入力チェック */
  @Ref("statistics") private readonly form!: VForm;
  /** テンプレート保存フォーム */
  @Ref("saveForm") private readonly saveForm!: VForm;
  /** テンプレート名変更フォーム */
  @Ref("editNameForm") private readonly editNameForm!: VForm;

  /** 表示列設定制御 */
  private statisticColumnDialog = false;
  /** お気に入り保存ダイアログ制御 */
  private statisticSaveDialog = false;
  /** お気に入り名編集ダイアログ制御 */
  private statisticNameEditDialog = false;
  /** 表の1ページあたり表示件数 */
  private itemsPerPage = 25;
  /** ソート後一覧 */
  private filteredItems: [] = [];
  private getFilteredItems(items: []) {
    this.filteredItems = items;
  }
  /** 検索パネルを開いているか */
  private searchPanelOpened = false;
  /** デフォルト検索条件：事業所 */
  private get DefaultOffices(): number[] {
    if (this.loginUser.office_id !== 0) {
      // 所属している場合はログインユーザーの所属事業所
      return [this.loginUser.office_id];
    }
    // 所属していない場合は先頭の事業所
    return [this.HeadOffice];
  }
  /** グループ検索条件のAND/ORとチェックボックスを合わせる */
  private get searchGroupOperator(): boolean {
    return !this.searchCond.group_operator;
  }
  private set searchGroupOperator(newCond: boolean) {
    this.searchCond.group_operator = Number(!newCond);
  }
  /**
   * 有効期限切れテンプレートと種類オブジェクト
   *
   * @var {[type]}
   */
  private expiredTemplate = [
    { key: 60, value: "指示書" },
    { key: 80, value: "医療保険" },
    { key: 110, value: "高額療養費" },
    { key: 130, value: "介護保険" },
    { key: 170, value: "公費" }
  ];

  private expiredTemplateTypeName = "";

  // 絞り込みしているかどうか
  private get IsFiltered(): boolean {
    const defaultCond = DefaultStatisticsSearchCond();
    return !(
      JSON.stringify(this.searchCond.office_ids.sort()) ==
        JSON.stringify(this.DefaultOffices.sort()) &&
      !this.searchCond.keyword &&
      this.searchCond.start_date === defaultCond.start_date &&
      this.searchCond.end_date === defaultCond.end_date &&
      !this.searchCond.group_ids.find(id => id !== 0 && id !== null) &&
      this.searchCond.group_operator === 1 &&
      (!this.ValidSingleRow ||
        (this.ValidSingleRow && this.searchCond.need_data_compression)) &&
      !this.searchCond.need_normal_indicates
      // !this.searchCond.exclude_now
    );
  }

  /** 「1利用者を1行で表示」チェックボックスを表示するか */
  private get ValidSingleRow(): boolean {
    const selectedStatisticFavorite = this.statisticFavorites.find(
      (statisticFavorite: StatisticFavorite) =>
        statisticFavorite.id == this.statisticFavoriteId
    );

    // テンプレートが選択されていて、かつチェックボックスを有効にするテンプレートなら表示
    return (
      selectedStatisticFavorite !== undefined &&
      selectedStatisticFavorite.valid_single_row == 1
    );
  }

  /**
   * 初期処理
   */
  public async created() {
    this.initmodel(1);
    await this.getStatisticFavorites();
    this.initmodel(0);
    await this.getStatisticFavorites();
  }

  public initmodel(tab: number) {
    this.tab = tab;
    const statisticType = tab ? 2 : 1;

    //事業所（所属していない場合は先頭の事業所）
    this.searchCond.office_ids = this.DefaultOffices;
    this.searchCond.statistic_type = statisticType;
    this.headerSettings = GetStatisticHeader(statisticType);

    // 検索条件のうち開始日と終了日は必ず先月初~先月末
    const now = new Date();
    const prevMonthFirst = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    this.searchCond.start_date = this.dateToStr(prevMonthFirst, "yyyy-MM-dd");
    const prevMonthLast = new Date(now.getFullYear(), now.getMonth(), 0);
    this.searchCond.end_date = this.dateToStr(prevMonthLast, "yyyy-MM-dd");

    //ヘッダー&検索設定
    if (tab == 0) {
      this.headerSettings.forEach(group => {
        if (group.id == "patient_info") {
          group.value.forEach(col => {
            col.selected = true;
          });
        } else {
          group.value.forEach(col => {
            col.selected = false;
          });
        }
      });
    } else {
      this.headerSettings.forEach(group => {
        if (group.id == "patient_info" || group.id == "visit_record") {
          group.value.forEach(col => {
            col.selected = true;
          });
        } else {
          group.value.forEach(col => {
            col.selected = false;
          });
        }
      });
      this.searchCond.selected_col["visit_record"] = true;
    }

    this.headerSettingDisps = this.deepCopy(this.headerSettings);
  }

  /**
   * ヘッダー
   */
  private get Headers() {
    this.headers = [];
    const selectedColumns = this.headerSettings
      .filter(hg => hg.is_checked)
      .flatMap(hg => {
        return [
          ...hg.value.filter(col => {
            if (col.selected) {
              col.filter = val => this.columnFilter(col.value, val);
              return true;
            }
          })
        ];
      });
    this.headers = [...selectedColumns];
    return this.headers;
  }

  /**
   * SelectFilterの選択肢
   * @return ColumnKey
   */
  private get columnKeys(): ColumnKey[] {
    return this.headers
      .filter(col => {
        return col.filter != null;
      })
      .map(col => {
        let type = SELECT_FILTER.FILTER_TYPE.EDIT;
        if (col.filterType != null) {
          type = col.filterType;
        }
        let selectable = null;
        if (
          col.filterType === SELECT_FILTER.FILTER_TYPE.LIST ||
          col.filterType === SELECT_FILTER.FILTER_TYPE.EDIT_LIST
        ) {
          //選択肢一覧作成
          const colvalues = col.value.split(".");

          const selectableMap: (string | undefined)[] = this.patients.map(
            itm => {
              // const txt = (itm as any)[col.value];
              let obj;
              colvalues.forEach(colValue => {
                obj = itm[colValue];
                itm = obj as { [key: string]: unknown };
              });
              const txt = obj;
              // const txt = (itm as any)[colvalues[0]][colvalues[1]];
              return txt === "" ? SELECT_FILTER.NO_DATA : txt;
            }
          );

          selectableMap.push(SELECT_FILTER.EXIST_DATA);

          selectable = selectableMap.filter((val, idx, arr) => {
            return arr.indexOf(val) === idx;
          }) as string[];
          //NO_DATAラベルを一覧の先頭に移動
          const idx = selectable.indexOf(SELECT_FILTER.NO_DATA);
          if (idx > 0) {
            this.moveAt(selectable, idx, 0);
          }
        }
        const fkey: ColumnKey = {
          type: type,
          key: col.value,
          text: col.text,
          selectable: selectable
        };

        return fkey;
      });
  }

  /** 検索パネルを開く */
  private openSearchPanel() {
    this.searchPanelOpened = true;
  }

  /** 検索条件をリセットする */
  private resetSearchCond() {
    const defaultSearchCond = DefaultStatisticsSearchCond();
    defaultSearchCond.office_ids = this.DefaultOffices;
    // 種別「保険・指示書・その他加算」「看護記録・訪問予定」はリセットしない
    defaultSearchCond.statistic_type = this.searchCond.statistic_type;
    const selectCol = this.searchCond.selected_col;
    this.searchCond = defaultSearchCond;
    // 表示列はリセットしない
    this.searchCond.selected_col = selectCol;

    // this.resetExcludeNow();
  }

  /**
   * カラムフィルター
   */
  private columnFilter(key: string, val: string | number | Date) {
    return IsFilterMatch(this.selectFilter.filterClauses, key, val);
  }

  /**
   * お気に入りカラムフィルター状態を反映
   */
  private setStatisticFavoriteFilter(
    currentStatisticFavorite: StatisticFavorite
  ) {
    const clauses = this.toFilterClauses(currentStatisticFavorite);
    this.selectFilter.filterClauses.splice(0);
    this.selectFilter.filterClauses.push(
      ...this.selectFilter.adjustClauses(clauses)
    );
  }

  /**
   * APIの結果をお気に入りカラムフィルター状態に整形
   * @param StatisticFavorite {currentStatisticFavorite}
   */

  private toFilterClauses(
    currentStatisticFavorite: StatisticFavorite
  ): FilterClause[] {
    const filterClauses: FilterClause[] = [];

    currentStatisticFavorite.columns.forEach((col, filterId) => {
      // 作成するフィルター条件
      // お気に入り登録されているカラムとヘッダーカラムが一致するもの
      // フィルター文字設定されている、または日付絞り込み条件が対象年月日以外の場合
      const findHeader = this.headers.find(
        headerCol =>
          headerCol.value == col.col_id &&
          (col.filter || col.date_filter_condition1 != 0)
      );
      if (!findHeader) return;

      let type = SELECT_FILTER.FILTER_TYPE.EDIT;
      if (findHeader.filterType != null) {
        type = findHeader.filterType;
      }
      let selectable = null;
      if (
        findHeader.filterType === SELECT_FILTER.FILTER_TYPE.LIST ||
        findHeader.filterType === SELECT_FILTER.FILTER_TYPE.EDIT_LIST
      ) {
        //選択肢一覧作成
        selectable = this.patients
          .map(itm => {
            // keyが階層になっているObjectの文字を取得
            const txt = this.getProperty(
              itm as { [key: string]: {} },
              findHeader.value
            );

            return txt === "" ? SELECT_FILTER.NO_DATA : txt;
          })
          .filter((val, idx, arr) => {
            return arr.indexOf(val) === idx;
          }) as string[];

        //NO_DATAラベルを一覧の先頭に移動
        const idx = selectable.indexOf(SELECT_FILTER.NO_DATA);
        if (idx > 0) {
          this.moveAt(selectable, idx, 0);
        }
      }

      const fkey: ColumnKey = {
        type: type,
        key: findHeader.value,
        text: findHeader.text,
        selectable: selectable
      };
      const clause: FilterClause = {
        id: filterId,
        colKey: fkey,
        word: col.filter,
        matchType: col.filter_condition ?? SELECT_FILTER.MATCH_TYPE_PERFECT,
        dateFilterCondition: col.date_filter_condition1 ?? 0,
        numIdx: col.date_filter_condition2 ?? 0,
        menu: false
      };
      filterClauses.push(clause);
    });

    return filterClauses;
  }

  /**
   * 現在のヘッダー設定をお気に入りにセット
   */
  private headerSettingsToStatisticFavorite(headerSettings: StatisticHeader[]) {
    this.statisticFavoriteColumns = [];
    this.statisticFavoriteGroups = [];

    headerSettings.forEach(hg => {
      const cols = hg.value
        .filter(col => col.selected)
        .flatMap(col => {
          // フィルター文字列取得
          const clauseses = this.selectFilter.filterClauses.filter(
            fc => col.value == fc.colKey.key
          );

          // ソート番号取得
          const sortNo =
            this.sortBy.findIndex(sortKey => sortKey == col.value) + 1;

          if (clauseses.length == 0) {
            return {
              statistic_favorite_id: this.statisticFavoriteId,
              col_id: col.value,
              sort: sortNo,
              filter: "",
              filter_condition: SELECT_FILTER.MATCH_TYPE_PERFECT,
              date_filter_condition1: 0,
              date_filter_condition2: 0
            } as StatisticFavoriteColumn;
          }

          const tmpCols = [];
          for (const clauses of clauseses) {
            tmpCols.push({
              statistic_favorite_id: this.statisticFavoriteId,
              col_id: col.value,
              sort: sortNo,
              filter: clauses?.word ?? "",
              filter_condition:
                clauses?.matchType ?? SELECT_FILTER.MATCH_TYPE_PERFECT,
              date_filter_condition1: clauses?.dateFilterCondition ?? 0,
              date_filter_condition2: clauses?.numIdx ?? 0
            } as StatisticFavoriteColumn);
          }
          return tmpCols;
        });

      // カラムが存在する場合該当するグループもセットする
      cols.length &&
        this.statisticFavoriteGroups.push({
          group_id: hg.id,
          statistic_favorite_id: this.statisticFavoriteId
        } as StatisticFavoriteGroup);

      this.statisticFavoriteColumns.push(...cols);
    });

    // ログインユーザーの権限によって削除できるユーザーを制限する
    let agreementId = this.loginUser.agreement_id;
    let officeId = this.loginUser.office_id;
    switch (this.loginUserAuthId) {
      // システム管理者
      case OFFICE_STAFF.AUTH.ADMIN:
        agreementId = 0;
        officeId = 0;
        break;
      // 本社職員
      case OFFICE_STAFF.AUTH.HOME:
        officeId = 0;
        break;
      default:
        break;
    }

    return {
      id: this.statisticFavoriteId,
      agreement_id: agreementId,
      office_id: officeId,
      staff_id: this.loginUser.id,
      statistic_type: this.tab + 1,
      favorite_name: this.statisticFavoriteName,
      groups: this.statisticFavoriteGroups,
      columns: this.statisticFavoriteColumns,
      created_at: "", // 作成日時
      updated_at: "", // 更新日時
      deleted_at: "" // 削除日時
    };
  }

  /**
   * お気に入りを現在のヘッダー設定に反映
   */
  private statisticFavoriteToColumnSetting(
    statisticFavorite: StatisticFavorite
  ) {
    this.sortBy = [];

    this.headerSettings.forEach(headerSetting => {
      // お気に入り登録されているグループにチェックする
      if (statisticFavorite.groups) {
        headerSetting.is_checked = statisticFavorite.groups.some(
          group => group.group_id == headerSetting.id
        );
      }

      // お気に入り登録されているカラムにチェックする
      headerSetting.value.some(headerSettingColumn => {
        if (statisticFavorite.columns) {
          headerSettingColumn.selected = statisticFavorite.columns.some(col => {
            if (col.sort) {
              this.sortBy[col.sort - 1] = col.col_id;
            }
            return col.col_id == headerSettingColumn.value;
          });
          if (headerSettingColumn.selected) {
            // グループのカラムが一つでも選択されていた場合検索対象
            this.searchCond.selected_col[headerSetting.id] = true;
          }
        }
      });
    });

    //検索条件をいじる
    for (const header of this.headerSettings) {
      this.searchCond.selected_col[header.id] = header.is_checked;
    }

    // 次にダイアログを開いた時、現在の表示列がデフォルトチェックになるように
    this.headerSettingDisps = this.deepCopy(this.headerSettings);

    // 非表示列のソートを解除
    this.unsortInvisible();
  }

  /**
   * 非表示列のソートを解除
   */
  private unsortInvisible() {
    // 非表示列のvalueを取得
    const selectedColsValue = this.headerSettings.flatMap(headerSetting => {
      return headerSetting.value
        .filter(settingValue => !settingValue.selected)
        .map(settingValue => settingValue.value);
    });
    // 削除したいsortBy設定のインデックス
    const deleteIndexes: number[] = [];
    this.sortBy.forEach((sortBy, i) => {
      if (selectedColsValue.includes(sortBy)) deleteIndexes.push(i);
    });
    // spliceで削除すると即要素が前にずれるため、後から消すようにする
    deleteIndexes.reverse();
    // sortBy設定を削除
    deleteIndexes.forEach(deleteIndex => {
      this.sortBy.splice(deleteIndex, 1);
    });
  }

  /**
   * ヘッダー設定カラム選択時処理
   */
  private clickColumnSetting(
    selectItem: StatisticHeaderColumn,
    headerSetting: StatisticHeader
  ) {
    // 検索条件として設定
    this.searchCond.selected_col[headerSetting.id] = headerSetting.value.some(
      value => value.selected
    );
  }

  /**
   * ヘッダー設定を表に適用しない
   */
  private cancelColumnSetting() {
    this.headerSettingDisps = this.deepCopy(this.headerSettings);
    this.statisticColumnDialog = false;
  }

  /**
   * ヘッダー設定を表に適用する
   */
  private applyColumnSetting() {
    this.headerSettings = this.deepCopy(this.headerSettingDisps);
    // 非表示列のソートを解除
    this.unsortInvisible();
    this.statisticColumnDialog = false;
    //利用者一覧再取得&サーバーにヘッダー設定を保存
    this.fetch();
  }

  /**
   * お気に入り取得処理
   */
  private getStatisticFavorites() {
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/dashboard/favorites/get",
        { statistic_favorite_id: 0 },
        res => {
          if (res.status !== 200 && res.data?.statistic_favorite == null) {
            return;
          }
          const statisticFavorites: StatisticFavorite[] =
            res.data.statistic_favorite;
          this.statisticFavorites = statisticFavorites.filter(sf => {
            return (
              sf.statistic_type === this.searchCond.statistic_type &&
              (sf.agreement_id == 0 ||
                sf.office_id == 0 ||
                this.loginUser.office_id == sf.office_id ||
                this.loginUserAuthId >= OFFICE_STAFF.AUTH.HOME)
            );
          });
          this.statisticFavoriteName = "";
          resolve(0);
        }
      );
    });
  }

  /**
   * お気に入り一覧選択時処理
   */
  private clickStatisticFavorite(id: number | null) {
    // お気に入りの選択をクリアした場合はidがnullになる
    if (id == null) {
      this.statisticFavorite = DefaultStatisticFavorite();
      this.statisticFavoriteId = this.statisticFavorite.id;
      return;
    }

    // 選択したお気に入りを現在のお気に入りに設定
    const currentStatisticFavorite = this.statisticFavorites.filter(
      statisticFavorite => statisticFavorite.id == id
    )[0];
    // 選択したお気に入りが取得できなければ何もしない
    if (!currentStatisticFavorite) return;

    this.statisticFavoriteId = id;
    this.statisticFavorite = currentStatisticFavorite;
    this.statisticFavoriteToColumnSetting(currentStatisticFavorite);

    // this.resetExcludeNow();

    //再検索
    this.fetch(currentStatisticFavorite).then(() => {
      // 検索後にフィルター設定
      this.setStatisticFavoriteFilter(currentStatisticFavorite);
    });
  }

  /** お気に入りテンプレート名の重複を検証 */
  private checkDuplicateStatisticFavorite(
    name: string,
    favorites: StatisticFavorite[],
    errorMsg: string
  ): boolean | string {
    if (favorites.find(fav => fav.favorite_name === name)) {
      return errorMsg;
    }
    return true;
  }

  /**
   * お気に入り保存処理
   */
  private async saveStatisticFavorite(isOverwrite: boolean) {
    if (this.statisticSaveDialog && !this.saveForm.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    const targetFavorite = this.headerSettingsToStatisticFavorite(
      this.headerSettingDisps
    );
    if (isOverwrite) {
      // 上書き保存は、現在読み込んでいるお気に入り名を入れる
      const currentFavorite = this.statisticFavorites.find(
        statisticFavorite => statisticFavorite.id == this.statisticFavoriteId
      );
      if (!this.statisticFavoriteId || currentFavorite == null) {
        await this.$openAlert("上書き対象のお気に入りが選択されていません。");
        return;
      }
      targetFavorite.favorite_name = currentFavorite.favorite_name;
    } else if (!this.statisticFavoriteName) {
      await this.$openAlert("お気に入り名が入力されていません");
      return;
    } else {
      // 上書きでない場合のIDは0とする
      targetFavorite.id = 0;
    }

    this.postJsonCheck(
      window.base_url + "/api/dashboard/favorite/save",
      { statistic_favorite: targetFavorite },
      res => {
        this.statisticSaveDialog = false;

        const savedFavorites: StatisticFavorite[] = res.data.statistic_favorite;
        // 利用者一覧再取得
        this.fetch();
        this.getStatisticFavorites().then(() => {
          if (!savedFavorites?.[0]?.id) return;
          // 保存したお気に入りを取得できIDも分かれば、それを選択状態にする
          const newFavorite = this.statisticFavorites.find(
            fav => fav.id === savedFavorites[0].id
          );
          if (newFavorite == undefined) return;
          this.statisticFavorite = newFavorite;
          this.statisticFavoriteId = this.statisticFavorite.id;
        });
      }
    );
  }

  /**
   * お気に入り名編集後保存処理
   */
  private async saveNameStatisticFavorite() {
    if (!this.editNameForm.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/dashboard/favorite/save",
      { statistic_favorite: this.statisticFavoriteEditing },
      () => {
        this.statisticNameEditDialog = false;
        //利用者一覧再取得
        this.fetch();
        this.getStatisticFavorites();
      }
    );
  }

  /**
   * 一覧取得
   */
  private fetch(
    statisticFavorite: StatisticFavorite = DefaultStatisticFavorite()
  ) {
    return new Promise(resolve => {
      // 非同期処理
      // カラムが選択状態になっている場合、そのグループも選択状態にする
      this.headerSettings.forEach(h => {
        h.is_checked = h.value.some(item => item.selected);
      });

      // 1利用者1行対応のテンプレートで条件がundefined(初回検索)ならtrueにして検索
      // firestoreに保存していないデフォルトの状態をtrueにしたいため
      if (
        this.ValidSingleRow &&
        this.searchCond.need_data_compression === undefined
      ) {
        this.searchCond.need_data_compression = true;
      }

      // 1利用者1行に対応していないテンプレートなら必ずfalseで渡す
      // 別個で渡すのは、searchCondを書き換えてしまうと、1利用者1行対応のテンプレートで保存した検索条件も上書きしてしまうため
      const needDataCompression = this.ValidSingleRow
        ? this.searchCond.need_data_compression
        : false;

      statisticFavorite.id = this.statisticFavoriteId;

      this.postJsonCheck(
        window.base_url + "/api/dashboard/patients/get",
        {
          ...this.searchCond,
          statistic_favorite: statisticFavorite,
          need_data_compression: needDataCompression,
          exclude_now: false
          // exclude_now: this.searchCond.exclude_now
        },
        res => {
          if (res.data) {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const patients: { [key: string]: any }[] = res.data.patients;
            // 年齢誕生日情報があれば
            if (typeof patients?.[0]?.patient_info?.age_bday === "string") {
              patients.forEach(patient => {
                // 令和4年5月6日をR4-5-6にする
                patient.patient_info.age_bday = this.warekiToShort(
                  patient.patient_info.age_bday
                );
                return patient;
              });
            }
            this.patients = patients;
            this.groups = res.data.groups;

            // 暗黙の検索期間を反映
            this.searchCond.start_date = res.data.search_cond.start_date;
            this.searchCond.end_date = res.data.search_cond.end_date;

            return resolve(true);
          }
        }
      );
    });
  }

  /**
   * 検索ボタン押下
   */
  private async clickSearch() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.fetch();
  }

  /**
   * Excelダウンロードボタン押下時
   */
  private clickDownloadExcel() {
    const reqItems = this.createExcelRequest();
    this.postJsonBlobResCheck(
      window.base_url + "/api/dashboard/excel/download",
      { items: reqItems },
      res => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = `統計.xlsx`;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }

  /**
   * タブ切り替え時処理
   */
  private onChangeTab() {
    const statisticType = this.tab + 1;
    this.searchCond.statistic_type = statisticType;
  }

  /** ページ番号切り替え */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /**
   * ヘッダー設定編集ボタン押下時処理
   */
  private clickStatisticFavoriteEdit(statisticFavorite: StatisticFavorite) {
    this.statisticFavoriteEditing = this.deepCopy(statisticFavorite);
    this.statisticNameEditDialog = true; // 編集ダイアログを開く
  }

  /**
   * ヘッダー設定削除ボタン押下時処理
   */
  private async clickStatisticFavoriteDelete(
    statisticFavorite: StatisticFavorite
  ) {
    if (
      !(await this.$openConfirm(
        `お気に入り「${statisticFavorite.favorite_name}」を削除します。よろしいですか？`
      ))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/dashboard/favorites/delete",
      { statistic_favorite: statisticFavorite },
      () => {
        this.getStatisticFavorites();
      }
    );
  }

  /**
   * エクセルダウンロードリクエスト作成
   */
  private createExcelRequest() {
    const tableChild = this.tableData.$children[0].$children[0];
    const filteredItems = tableChild.filteredItems;
    const sortItems = tableChild.sortItems(filteredItems.slice());
    const reqItems = sortItems.map((si: { [key: string]: {} }) => {
      return this.headers.map(header => this.getProperty(si, header.value));
    });
    reqItems.unshift(this.headers.map(header => header.text));
    return reqItems;
  }

  /**
   *
   */
  private getProperty(object: { [key: string]: {} }, propertyPath: string) {
    if (!object) {
      return "";
    }

    let result = object;
    const propertyArray = propertyPath.split(".");
    for (let i = 0; i <= propertyArray.length - 1; i += 1) {
      if (propertyArray[i] === "") {
        return "";
      }
      if (typeof result[propertyArray[i]] === "undefined") {
        return "";
      }
      result = result[propertyArray[i]];
    }
    return String(result);
  }

  /**
   * ダッシュボード統計お気に入り表示非表示
   * @return boolean
   */
  // private canDispStatisticFavorite(statisticFavorite: StatisticFavorite) {
  //   return (
  //     statisticFavorite.office_id == this.loginUser.office_id ||
  //     this.loginUser.info.auth_id >= OFFICE_STAFF.AUTH.HOME
  //   );
  // }

  /**
   * ダッシュボード統計お気に入り削除の可否
   * @return boolean
   */
  private canDeleteStatisticFavorite(statisticFavorite: StatisticFavorite) {
    // 一般職員は非表示
    // 一般より権限が上の場合、事業所IDが同じ場合削除可能
    // または、本社職員以上の権限がある場合も削除可能
    return (
      (this.loginUserAuthId != OFFICE_STAFF.AUTH.COMMON &&
        statisticFavorite.office_id == this.loginUser.office_id) ||
      this.loginUserAuthId >= OFFICE_STAFF.AUTH.HOME
    );

    // ログインユーザーと登録されているお気に入りのofficeIDが同じ場合削除可能
    // ただし、本社職員以上のお気に入りは本社職員以上のみ削除可能
    // return statisticFavorite.office_id == this.loginUser.office_id
    //   ? true
    //   : this.loginUser.info.auth_id >= OFFICE_STAFF.AUTH.HOME;
  }

  /**
   * 配列要素の移動
   */
  private moveAt(array: unknown[], idx: number, at: number) {
    if (idx === at || idx > array.length - 1 || at > array.length - 1) {
      return;
    }
    const value = array[idx];
    const tail = array.slice(idx + 1);
    array.splice(idx);
    Array.prototype.push.apply(array, tail);
    array.splice(at, 0, value);
  }

  /**
   * Chack
   */
  //開始日:日付チェック
  private chackStart(): boolean | string {
    //開始日が終了日より後
    if (
      this.searchCond.end_date != "" &&
      this.searchCond.start_date > this.searchCond.end_date
    ) {
      return "開始日が終了日より後になっています";
    }
    return true;
  }
  //終了日:日付チェック
  private chackEnd(): boolean | string {
    //終了日が開始日より前
    if (
      this.searchCond.start_date != "" &&
      this.searchCond.start_date > this.searchCond.end_date
    ) {
      return "終了日が開始日より前になっています";
    }
    return true;
  }

  //事業所選択
  private checkOfficeSelect(): boolean | string {
    //1つも選択していない場合
    if (!this.searchCond.office_ids || this.searchCond.office_ids.length == 0) {
      return "1事業所は選択してください";
    }
    //5つ以上選択している場合
    if (!this.searchCond.office_ids || this.searchCond.office_ids.length > 5) {
      return "最大5事業所まで選択可能です";
    }
    return true;
  }

  /** 利用者数を返す */
  private getPatientCount(obj: { [key: string]: { id: number } }): number {
    const ids = new Set();
    for (const key in obj) {
      ids.add(obj[key].id);
    }
    return [...ids].length;
  }

  private resetExcludeNow() {
    const et = this.expiredTemplate.find(
      self => self.key === this.statisticFavoriteId
    );
    if (et) {
      this.expiredTemplateTypeName = et.value;
      this.searchCond.exclude_now = true;
    } else {
      this.expiredTemplateTypeName = "";
      this.searchCond.exclude_now = false;
    }
  }
}
