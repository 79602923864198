



































































































































































































import { Component, Emit, Prop, Mixins, Ref } from "vue-property-decorator";
import PatientMixin from "@/mixins/patientMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { Doctor, DefaultDoctor } from "#/model/uniquemaster";
import { VForm, RequesterChoice, Choice } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import { namespace } from "vuex-class";
import * as patient from "@/store/modules/patient/types";
import MedicalInstitution from "@/components/master/medical/MedicalForm.vue";
import UtilMixin from "@/mixins/utilMixin";

export interface SearchItem {
  med_name: string;
  tel_area: string;
  name: string;
}
const DefaultSearchItem = (): SearchItem => ({
  med_name: "",
  tel_area: "",
  name: ""
});
interface SearchResList {
  id: number;
  med_name: string;
}
interface AddItem {
  medId: number;
  last_name: string;
  last_name_kana: string;
  first_name: string;
  first_name_kana: string;
}
const DefaultAddItem = (): AddItem => ({
  medId: 0,
  last_name: "",
  last_name_kana: "",
  first_name: "",
  first_name_kana: ""
});
const Patient = namespace(patient.name);
@Component({ components: { MedicalInstitution } })
export default class PatientDoctorSelect extends Mixins(
  PatientMixin,
  RulesMixin,
  AxiosMixin,
  UtilMixin
) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop({ default: "医師" }) label!: string;
  @Prop() value!: number;
  @Prop({ default: false }) not_select!: boolean;
  @Prop({ default: true }) not_empty!: boolean; // 医師が登録されていない機関は表示選択しない

  /** 無効化フラグ */
  @Prop({ default: false }) disabled!: boolean;

  @Ref("form") private readonly form!: VForm;
  @Ref("search") private readonly searchForm!: VForm;
  @Ref("medForm") private readonly medForm!: MedicalInstitution;
  @Patient.Mutation(patient.SET_PATIENT_MASTER) setPatientMaster!: Function;

  /** 医師検索 */
  // 入力値
  private searchItem: SearchItem = DefaultSearchItem();
  //formvaid
  private searchValid = true;
  // 検索結果ヘッダー

  private headers: DataTableHeader[] = [
    {
      text: "医療機関名",
      value: "med_name"
    },
    {
      text: "選択",
      value: "action"
    }
  ];
  //検索結果一覧
  private searchResList: SearchResList[] = [];
  /** 医師登録 */
  //ダイアログ
  private dialog = false;
  //formvaid
  private valid = true;
  // 入力値
  private addItem: AddItem = DefaultAddItem();

  private medFormDialog = false;

  private get Items() {
    const items: RequesterChoice[] = [];

    if (this.not_select) {
      items.push({
        id: 0,
        value: 0,
        text: "未選択",
        tel_area: "",
        tel_city: "",
        tel_no: ""
      });
    }

    for (const ins of this.patientMaster.medical_institutions) {
      for (const doctor of ins.doctors) {
        if (this.not_empty && doctor.is_empty === 0) {
          continue;
        }

        let text = ins.name;
        if (doctor.full_name !== "") {
          text += "/" + doctor.full_name;
        }
        if (ins.tel_area && ins.tel_city && ins.tel_num) {
          text += "/" + ins.tel_area + "-" + ins.tel_city + "-" + ins.tel_num;
        }
        items.push({
          id: doctor.id,
          value: doctor.id,
          text: text,
          tel_area: ins.tel_area,
          tel_city: ins.tel_city,
          tel_no: ins.tel_num
        });
      }
    }
    return items;
  }

  private get Value(): number {
    return this.value;
  }

  private set Value(newValue: number) {
    this.input(newValue);
  }

  // 関連機関選択肢
  private get MedItems() {
    const items: Choice[] = [];

    for (const ins of this.patientMaster.medical_institutions) {
      items.push({
        text: ins.name,
        value: ins.id
      });
    }
    return items;
  }
  @Emit()
  private input(newValue: number) {
    return newValue;
  }

  // ダイアログを開く
  private openDialog() {
    this.dialog = true;
  }
  public openDialogWithCondition(cond: SearchItem) {
    this.searchItem.med_name = cond.med_name;
    this.searchItem.tel_area = cond.tel_area;
    this.searchItem.name = cond.name;
    this.openDialog();
  }
  // ダイアログを閉じる
  private closeDialog() {
    this.searchItem = DefaultSearchItem();
    this.searchForm.resetValidation();
    this.searchResList = [];
    this.addItem = DefaultAddItem();
    this.form.resetValidation();
    this.dialog = false;
  }
  private select(id: number) {
    this.Value = id;
    this.closeDialog();
  }

  private async search() {
    if (!this.searchForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (
      this.searchItem.med_name === "" &&
      this.searchItem.tel_area === "" &&
      this.searchItem.name === ""
    ) {
      this.searchResList = [];
      return;
    }
    // 絞り込み
    this.searchResList = this.searchMeds();
  }

  // 担当者登録
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    const selectContent = this.patientMaster.medical_institutions.find(
      item => item.id === this.addItem.medId
    );
    if (!selectContent) {
      await this.$openAlert(
        "指定した医療機関情報が取得できませんでした。再度お試しください。"
      );
      return;
    }
    if (this.addItem) {
      const reqDoctor: Doctor = {
        ...DefaultDoctor(),
        medical_institution_id: selectContent.id,
        last_name: this.addItem.last_name,
        last_name_kana: this.addItem.last_name_kana,
        first_name: this.addItem.first_name,
        first_name_kana: this.addItem.first_name_kana,
        medical_institution_name: selectContent.name
      };
      this.postJsonCheck(
        window.base_url + "/api/master/doctor/save",
        { doctor: reqDoctor },
        () => {
          this.updateMaster();
          this.closeDialog();
        }
      );
    }
  }

  // 利用者マスタ情報取得
  private updateMaster() {
    this.postJsonCheck(
      window.base_url + "/api/patient/master/get",
      { patient_id: Number(this.$route.params.id) },
      res => {
        if (res.data) {
          this.setPatientMaster({ master: res.data });
        }
      }
    );
  }

  //検索
  private searchMeds(): SearchResList[] {
    const resItems: SearchResList[] = [];

    const searchInst = this.searchItem.med_name.replace(/\s/g, "");
    const searchName = this.searchItem.name.replace(/\s/g, "");

    const filteredItems = this.patientMaster.medical_institutions
      .filter(item => {
        // 関連機関名
        if (searchInst === "") {
          return true;
        }
        const name = item.name.replace(/\s/g, "");
        const nameKana = item.furigana.replace(/\s/g, "");
        if (name.match(searchInst) || nameKana.match(searchInst)) {
          return true;
        }
        return false;
      })
      .filter(item => {
        // 電話番号
        if (this.searchItem.tel_area === "") {
          return true;
        }
        const tel = "" + item.tel_area + item.tel_city + item.tel_num;
        if (tel.match(this.searchItem.tel_area)) {
          return true;
        }
        return false;
      });

    // 医師を取得
    filteredItems.forEach(item => {
      for (const doctor of item.doctors) {
        // 医師名絞り込み（ふりがなも含める）
        // 医師登録がないものは飛ばす
        const name = doctor.full_name.replace(/\s/g, "");
        const nameKana = doctor.furigana.replace(/\s/g, "");
        if (
          (this.not_empty && doctor.is_empty === 0) ||
          (searchName !== "" &&
            !name.match(searchName) &&
            !nameKana.match(searchName))
        ) {
          continue;
        }
        let text = item.name;
        if (doctor.full_name !== "") {
          text += "/" + doctor.full_name;
        }
        if (item.tel_area && item.tel_city && item.tel_num) {
          text +=
            "/" + item.tel_area + "-" + item.tel_city + "-" + item.tel_num;
        }
        resItems.push({
          id: doctor.id,
          med_name: text
        });
      }
    });

    return resItems;
  }

  //関連機関の登録
  private async saveMed() {
    if (await this.medForm.saveMedicalInstitution()) {
      this.updateMaster();
      this.clearMed();
    }
  }

  // 関連機関追加のキャンセル
  private clearMed() {
    this.medForm.clear();
    this.medFormDialog = false;
  }
}
