

















import { Component, Prop, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { TemplateFile } from "#/model/inquiry";

@Component
export default class FAQTemplateFileViewer extends Mixins(AxiosMixin) {
  @Prop({ default: () => [] as TemplateFile[] }) templateFiles!: TemplateFile[];
}
