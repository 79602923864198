

















































































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { VForm, Choice, OcrTemplateChoice } from "@/types";
import {
  PatientShareFileInfo,
  FileSortTypeSelect,
  OcrTemplate
} from "@/components/sharefile/type";
import OcrExecutionDialog from "@/components/ocr/OcrExecutionDialog.vue";
import OcrResultDialog from "@/components/ocr/OcrResultDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";
import FileUploadTag from "@/components/patient/file/FileUploadTag.vue";
import PatientFileListTable from "@/components/sharefile/PatientFileListTable.vue";
import PatientShareFileBulkUpdateDialog from "@/components/sharefile/PatientShareFileBulkUpdateDialog.vue";
import { OcrStatus, DocumentType } from "@/const/ocr";
import { AxiosResponse } from "axios";

@Component({
  components: {
    FileUploadTag,
    PatientFileListTable,
    PatientShareFileBulkUpdateDialog,
    OcrExecutionDialog,
    OcrResultDialog
  }
})
export default class PatientFile extends Mixins(
  AxiosMixin,
  UtilMixin,
  PatientMixin,
  ShareFileMixin
) {
  /** ファイルアップロードダイアログ */
  @Ref("fileUpload") private uploadDialog!: FileUploadTag;
  @Ref("ocrExecution") private ocrExecutionDialog!: OcrExecutionDialog;
  @Ref("ocrResult") private ocrResultDialog!: OcrResultDialog;

  /** 絞り込み検索フォーム */
  @Ref("searchForm") private readonly searchForm!: VForm;

  /** 利用者ID */
  private patientId = "0";

  /** 利用者共有ファイル一覧情報 */
  private patientShareFiles: PatientShareFileInfo[] = [];

  /** 検索サイドバーを開いているか */
  private searchPanel = false;

  /** チェック状態 */
  private checked: PatientShareFileInfo[] = [];

  /** タグ一括編集ダイアログ展開状態 */
  private tagDialog = false;

  /** タグマスタ */
  private tags: Choice[] = [];

  private ocrTemplates: OcrTemplateChoice[] = [];

  /** 検索用タグ情報 */
  private tag_ids: number[] = [];

  // 絞り込み：ファイル名
  private file_name = "";

  /** 検索開始日 */
  private start_date = "";

  /** 検索開始時間（時） */
  private start_hh = "";

  /** 検索開始時間（分） */
  private start_mm = "";

  /** 開始年月日時(yyyy-MM-dd hh:mm:ss) */
  private start_date_time = "";

  /** 検索終了日 */
  private end_date = "";

  /** 検索終了時間（時） */
  private end_hh = "";

  /** 検索終了時間（分） */
  private end_mm = "";

  /** 終了年月日時(yyyy-MM-dd hh:mm:ss) */
  private end_date_time = "";

  /** 検索ソート順 */
  private sort_column = "updated_at";

  /** 検索ソートカラム */
  private sort_type = "DESC";

  /** 開いているページ */
  private pageNo = 1;

  /** 1ページあたりの項目数 */
  private itemsPerPage = 25;

  private isOcrEnabled = false;

  private timerId = 0;

  /** 利用者ファイルアップロードディレクトリ */
  private get imageFilePath() {
    // [co_code]/[kst_no]/patient/[利用者ID]/[ファイル名(uuid)]
    const kstNo = this.getKstnoByOfficeId(this.PatientOfficeId);
    return `${this.siteName}/${kstNo}/patient/${this.patientId}/`;
  }

  /** 絞り込みしているかどうか */
  private get IsFiltered(): boolean {
    return !(
      (this.file_name === "" || this.file_name === null) &&
      !this.tag_ids.length &&
      this.end_date === "" &&
      this.start_date === ""
    );
  }

  private get DocumentType(): number {
    return DocumentType.patient;
  }

  created(): void {
    this.patientId = this.$route.params.id;
    // 並び順選択のラベルに反映
    this.sortSearchCondToSelect(this.sort_column, this.sort_type);
    this.fetch();
  }

  mounted() {
    this.setDefaultHeader();
  }

  destroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  /** バリデーションして検索 */
  private async validateFetch() {
    if (!this.searchForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      this.searchPanel = true;
      return;
    }
    this.fetch();
  }

  /** 利用者ファイル取得 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/patient/files/get",
      {
        patient_id: Number(this.patientId),
        tag_ids: this.tag_ids,
        file_name: this.file_name,
        start_date_time: this.start_date_time,
        end_date_time: this.end_date_time,
        sort_column: this.sort_column,
        sort_type: this.sort_type
      },
      res => {
        if (res.data) {
          this.tags = res.data.tags ?? [];
          const patientShareFiles: PatientShareFileInfo[] =
            res.data.patient_share_files;
          patientShareFiles.forEach(file => {
            // 行選択した時に全選択されてしまわないように
            file.id = file.patient_file.id;
          });
          this.patientShareFiles = patientShareFiles;

          this.isOcrEnabled = res.data.ocr_enabled;
          if (res.data.ocr_templates) {
            this.ocrTemplates = res.data.ocr_templates.map(
              (template: OcrTemplate) => {
                return {
                  text: template.name,
                  value: template.id,
                  target_kind: template.target_kind
                } as OcrTemplateChoice;
              }
            );
          }

          this.pollingOcrState();
        }
      }
    );
  }

  /** 検索条件を並び順選択に反映 */
  protected sortSearchCondToSelect(sortColumn: string, sortType: string) {
    const matcSortBy = this.fileSortByList.find(
      sortBy =>
        sortBy.file_column === sortColumn && sortBy.file_order === sortType
    );
    if (matcSortBy) {
      this.fileSortBy = matcSortBy;
    }
  }

  /** 検索条件のリセット */
  private setDefaultSearch() {
    this.tag_ids = [];
    this.file_name = "";
    this.start_date = "";
    this.end_date = "";
    this.start_hh = "";
    this.start_mm = "";
    this.end_hh = "";
    this.end_mm = "";
    this.start_date_time = "";
    this.end_date_time = "";
  }

  /** タグ一括編集保存時 */
  private saveTags(tagIds: number[]) {
    this.postJsonCheck(
      window.base_url + "/api/tags/patient/save",
      {
        patient_share_files: this.checked,
        tag_ids: tagIds
      },
      () => {
        // 更新後、チェック状態をリセットして再読み込み
        this.validateFetch();
      }
    );
    this.tagDialog = false;
  }

  /** ページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  /** 並び順変更 */
  private onChangeSortObj(rule: FileSortTypeSelect) {
    this.fileSortBy = rule;
    this.sort_column = rule.file_column;
    this.sort_type = rule.file_order;
    this.validateFetch();
  }

  private addFile() {
    this.postJsonBackground(
      window.base_url + "/api/patient/file/click",
      {
        patient_id: Number(this.patientId)
      },
      () => {
        // Do Nothing
      }
    );
    this.uploadDialog.open(0, this.isOcrEnabled, this.isOcrEnabled, 0, 0);
  }

  private editFile(fileInfo: PatientShareFileInfo) {
    const ocrStatus = fileInfo.ocr_meta.ocr_status.status;
    // 一度OCRを実行した場合はOCR対象外にできない
    const isOcrExecuted =
      ocrStatus === OcrStatus.pending ||
      ocrStatus === OcrStatus.completed ||
      ocrStatus === OcrStatus.created ||
      ocrStatus === OcrStatus.failed;

    this.uploadDialog.open(
      fileInfo.id,
      fileInfo.ocr_meta.ocr_enabled,
      !isOcrExecuted,
      Number(isOcrExecuted || ocrStatus === OcrStatus.standby),
      fileInfo.ocr_meta.ocr_status.reserved_ocr_template_id
    );
  }

  /** 閉じる */
  private clickClose() {
    window.close();
  }

  /** ファイル変更 */
  private changeFile() {
    this.postJsonBackground(
      window.base_url + "/api/patient/file/change",
      {
        patient_id: Number(this.patientId)
      },
      () => {
        // Do Nothing
      }
    );
  }

  private pollingOcrState() {
    this.timerId = setInterval(() => {
      // OCR 中のファイルのみ OCR 結果取得 API をポーリングする
      for (const file of this.patientShareFiles) {
        if (file.ocr_meta.ocr_status.status === OcrStatus.pending) {
          this.callCheckOcrStatus(file.id, true);
        }
      }
    }, 10000);
  }

  private checkOcrStatus(fileId: number) {
    this.callCheckOcrStatus(fileId, false);
  }

  private callCheckOcrStatus(fileId: number, isBackGround: boolean) {
    const requestURL = window.base_url + "/api/ocrstore/status/check";
    const requestParams = {
      document_type: DocumentType.patient,
      document_id: fileId
    };

    const update = (res: AxiosResponse) => {
      const index = this.patientShareFiles.findIndex(file => {
        return file.id === fileId;
      });

      if (index >= 0) {
        this.patientShareFiles[index].ocr_meta.ocr_status = res.data.ocr_status;
      }
    };

    if (isBackGround) {
      this.postJsonBackground(requestURL, requestParams, res => {
        update(res);
      });
    } else {
      this.postJsonCheck(requestURL, requestParams, res => {
        if (res.data.ocr_status.status === OcrStatus.pending) {
          this.$openAlert("OCRを実行中です\n終了までお待ちください");
          return;
        }

        update(res);
      });
    }
  }

  private openOcrExecutionDialog(file: PatientShareFileInfo) {
    this.ocrExecutionDialog.open(
      file.id,
      file.thumbnail_file_path,
      file.ocr_meta.ocr_status.reserved_ocr_template_id,
      file.ocr_meta.ocr_status.status
    );
  }

  private ocrExecutionSucceeded(fileId: number) {
    const index = this.patientShareFiles.findIndex(file => {
      return file.id === fileId;
    });

    if (index >= 0) {
      this.patientShareFiles[index].ocr_meta.ocr_status.status =
        OcrStatus.pending;
    }
  }

  private ocrCanceled(fileId: number) {
    const index = this.patientShareFiles.findIndex(file => {
      return file.id === fileId;
    });

    if (index >= 0) {
      this.patientShareFiles[index].ocr_meta.ocr_status = {
        id: 0,
        status: 0,
        smart_ocr_id: 0,
        reserved_ocr_template_id: 0
      };
    }
  }

  private openOcrResultDialog(file: PatientShareFileInfo) {
    this.ocrResultDialog.open(file.thumbnail_file_path, file.id);
  }

  /** URLコピー時 */
  private copyUrlLink(data: PatientShareFileInfo) {
    this.copyUrlClipboard(
      window.auth_middleend_url + "/appform/patient/file/view/",
      data.patient_file.id,
      "url-copy-area"
    );
  }

  /** 検索開始日時変更 */
  private changeStart() {
    this.start_date_time =
      this.start_date +
      this.inputTime(1, this.start_date, this.start_hh, this.start_mm);
  }
  /** 検索終了日時変更 */
  private changeEnd() {
    this.end_date_time =
      this.end_date +
      this.inputTime(2, this.end_date, this.end_hh, this.end_mm);
  }
}
