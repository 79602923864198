var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-2",attrs:{"no-gutters":"","align":"center"}},[(_vm.showMessage)?_c('p',[_vm._v(" 現在有効な保険情報の他に、前月まで有効だった保険情報と未来に有効な全ての保険情報を表示します。 ")]):_vm._e()]),_c('AppDataTable',{staticClass:"insurance-all-list",attrs:{"headers":_vm.headers,"items":_vm.items.med,"isNoFooter":true,"isNoTop":true,"item-class":_vm.itemClass,"hide-default-header":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" 医療保険 ")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date_wareki)+"/"),_c('br'),_vm._v(" "+_vm._s(item.end_date_wareki)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?[_c('table',{staticClass:"insurance-all-list-table"},_vm._l((_vm.medTable),function(tr,idx){return _c('tr',{key:idx},[_c('th',[_vm._v(_vm._s(tr.label))]),_c('th',[_vm._v("：")]),_c('td',[_vm._v(_vm._s(item[tr.name]))])])}),0)]:_vm._l((_vm.medTable),function(data,idx){return _c('p',{key:idx,staticClass:"ma-0"},[_vm._v(" "+_vm._s(data.label)+"："+_vm._s(item[data.name])+" ")])})]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-start column-type"},[_vm._v("医療保険")]),_c('td',{staticClass:"text-start column-type"}),_c('td',{staticClass:"text-start column-type"},[_vm._v("データがありません")])]},proxy:true}],null,true)}),_c('v-divider',{staticClass:"insurance-all-list-border"}),_c('AppDataTable',{staticClass:"insurance-all-list",attrs:{"headers":_vm.headers,"items":_vm.items.eldCert,"isNoFooter":true,"isNoTop":true,"item-class":_vm.itemClass,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" 高齢受給者証 ")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date_wareki)+"/"),_c('br'),_vm._v(" "+_vm._s(item.end_date_wareki)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?[_c('table',{staticClass:"insurance-all-list-table"},_vm._l((_vm.eldCertTable),function(tr,idx){return _c('tr',{key:idx},[_c('th',[_vm._v(_vm._s(tr.label))]),_c('th',[_vm._v("：")]),_c('td',[_vm._v(_vm._s(item[tr.name]))])])}),0)]:_vm._l((_vm.eldCertTable),function(data,idx){return _c('p',{key:idx,staticClass:"ma-0"},[_vm._v(" "+_vm._s(data.label)+"："+_vm._s(item[data.name])+" ")])})]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-start column-type"},[_vm._v("高齢受給者証")]),_c('td',{staticClass:"text-start column-type"}),_c('td',{staticClass:"text-start column-type"},[_vm._v("データがありません")])]},proxy:true}],null,true)}),_c('v-divider',{staticClass:"insurance-all-list-border"}),_c('AppDataTable',{staticClass:"insurance-all-list",attrs:{"headers":_vm.headers,"items":_vm.items.highCost,"isNoFooter":true,"isNoTop":true,"item-class":_vm.itemClass,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" 高額療養費設定（限度額登録） ")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date_wareki)+"/"),_c('br'),_vm._v(" "+_vm._s(item.end_date_wareki)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?[_c('table',{staticClass:"insurance-all-list-table"},_vm._l((_vm.highCostTable),function(tr,idx){return _c('tr',{key:idx},[_c('th',[_vm._v(_vm._s(tr.label))]),_c('th',[_vm._v("：")]),_c('td',[_vm._v(_vm._s(item[tr.name]))])])}),0)]:_vm._l((_vm.highCostTable),function(data,idx){return _c('p',{key:idx,staticClass:"ma-0"},[_vm._v(" "+_vm._s(data.label)+"："+_vm._s(item[data.name])+" ")])})]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-start column-type"},[_vm._v("高額療養費設定")]),_c('td',{staticClass:"text-start column-type"}),_c('td',{staticClass:"text-start column-type"},[_vm._v("データがありません")])]},proxy:true}],null,true)}),_c('v-divider',{staticClass:"insurance-all-list-border"}),_c('AppDataTable',{staticClass:"insurance-all-list",attrs:{"headers":_vm.headers,"items":_vm.items.limitCert,"isNoFooter":true,"isNoTop":true,"item-class":_vm.itemClass,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" 限度額適用認定証 ")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date_wareki)+"/"),_c('br'),_vm._v(" "+_vm._s(item.end_date_wareki)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?[_c('table',{staticClass:"insurance-all-list-table"},_vm._l((_vm.limitCertTable),function(tr,idx){return _c('tr',{key:idx},[_c('th',[_vm._v(_vm._s(tr.label))]),_c('th',[_vm._v("：")]),_c('td',[_vm._v(_vm._s(item[tr.name]))])])}),0)]:_vm._l((_vm.limitCertTable),function(data,idx){return _c('p',{key:idx,staticClass:"ma-0"},[_vm._v(" "+_vm._s(data.label)+"："+_vm._s(item[data.name])+" ")])})]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-start column-type"},[_vm._v("限度額適用認定証設定")]),_c('td',{staticClass:"text-start column-type"}),_c('td',{staticClass:"text-start column-type"},[_vm._v("データがありません")])]},proxy:true}],null,true)}),_c('v-divider',{staticClass:"insurance-all-list-border"}),_c('AppDataTable',{staticClass:"insurance-all-list",attrs:{"headers":_vm.headers,"items":_vm.items.care,"isNoFooter":true,"isNoTop":true,"item-class":_vm.itemClass,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" 介護保険 ")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date_wareki)+"/"),_c('br'),_vm._v(" "+_vm._s(item.end_date_wareki)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?[_c('table',{staticClass:"insurance-all-list-table"},_vm._l((_vm.careTable),function(tr,idx){return _c('tr',{key:idx},[_c('th',[_vm._v(_vm._s(tr.label))]),_c('th',[_vm._v("：")]),_c('td',[_vm._v(_vm._s(item[tr.name]))])])}),0)]:_vm._l((_vm.careTable),function(data,idx){return _c('p',{key:idx,staticClass:"ma-0"},[_vm._v(" "+_vm._s(data.label)+"："+_vm._s(item[data.name])+" ")])})]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-start column-type"},[_vm._v("介護保険")]),_c('td',{staticClass:"text-start column-type"}),_c('td',{staticClass:"text-start column-type"},[_vm._v("データがありません")])]},proxy:true}],null,true)}),_c('v-divider',{staticClass:"insurance-all-list-border"}),_c('AppDataTable',{staticClass:"insurance-all-list",attrs:{"headers":_vm.headers,"items":_vm.items.careRateCert,"isNoFooter":true,"isNoTop":true,"item-class":_vm.itemClass,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" 介護保険負担割合証 ")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date_wareki)+"/"),_c('br'),_vm._v(" "+_vm._s(item.end_date_wareki)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v("負担割合/給付率："+_vm._s(item.rate))])]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-start column-type"},[_vm._v("介護保険負担割合証")]),_c('td',{staticClass:"text-start column-type"}),_c('td',{staticClass:"text-start column-type"},[_vm._v("データがありません")])]},proxy:true}],null,true)}),_c('v-divider',{staticClass:"insurance-all-list-border"}),_c('AppDataTable',{staticClass:"insurance-all-list",attrs:{"headers":_vm.headers,"items":_vm.items.public,"isNoFooter":true,"isNoTop":true,"item-class":_vm.itemClass,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.type",fn:function(){return [_vm._v(" 公費 ")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date_wareki)+"/"),_c('br'),_vm._v(" "+_vm._s(item.end_date_wareki)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?[_c('table',{staticClass:"insurance-all-list-table"},_vm._l((_vm.publicTable),function(tr,idx){return _c('tr',{key:idx},[_c('th',[_vm._v(_vm._s(tr.label))]),_c('th',[_vm._v("：")]),_c('td',[_vm._v(_vm._s(item[tr.name]))])])}),0)]:_vm._l((_vm.publicTable),function(data,idx){return _c('p',{key:idx,staticClass:"ma-0"},[_vm._v(" "+_vm._s(data.label)+"："+_vm._s(item[data.name])+" ")])})]}},{key:"no-data",fn:function(){return [_c('td',{staticClass:"text-start column-type"},[_vm._v("公費")]),_c('td',{staticClass:"text-start column-type"}),_c('td',{staticClass:"text-start column-type"},[_vm._v("データがありません")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }