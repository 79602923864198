
































import { Component } from "vue-property-decorator";
import BaseNursingDetail from "./BaseNursingDetail";
import { CareContent } from "#/model/report";
import NursingDetailItem from "./NursingDetailItem.vue";

@Component({
  components: { NursingDetailItem }
})
export default class SolutionNursingDetail extends BaseNursingDetail {
  // 看護内容検索
  findCareContent(
    largeId: number,
    mediumId: number,
    mediumName: string,
    isAi: number
  ): CareContent | undefined {
    if (!this.care_contents) return undefined;
    if (isAi == 2) {
      return this.careContents.find(
        ob => ob.care_large_content_id == largeId && ob.care_text == mediumName
      );
    } else {
      return this.careContents.find(
        ob =>
          ob.care_large_content_id == largeId &&
          ob.care_medium_content_id == mediumId
      );
    }
  }
}
