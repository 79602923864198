












































































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Patient } from "#/model/patient";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {}
})

/** 利用者基礎情報 請求情報フォーム */
export default class FormBilling extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 利用者情報 */
  @Prop() patient!: Patient;

  /** 読み取り専用フラグ(履歴編集画面用) */
  @Prop({ default: false }) isReadOnly!: boolean;

  /** プレフィックスID(入力項目にIDを振る対応) */
  @Prop({ default: "patient-info" }) private prefixId!: string;

  /** get */

  /** 支払い方法：ゆうちょ選択時のルール適用条件 */
  private get YutyoRule(): boolean {
    if (
      this.patient.info.pay_kbn == 3 && // 支払い区分：引き落とし
      this.patient.info.withdrawal_st_kbn == 1 && // 引き落とし開始設定：ON
      this.patient.info.financial_institution_kbn == 1 // 銀行選択：ゆうちょ
    ) {
      return true;
    }
    return false;
  }

  /** 支払い方法：ゆうちょ以外選択時のルール適用条件 */
  private get OtherBankRule(): boolean {
    if (
      this.patient.info.pay_kbn == 3 && // 支払い区分：引き落とし
      this.patient.info.withdrawal_st_kbn == 1 && // 引き落とし開始設定：ON
      this.patient.info.financial_institution_kbn == 2 // 銀行選択：ゆうちょ以外
    ) {
      return true;
    }
    return false;
  }
}
