import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import {
  ChatState,
  FETCH_ACCOUNT_IMAGE_PATH,
  SET_ACCOUNT_IMAGE_PATH,
  SET_FETCHING_ACOUNT_ID,
  DELETE_FETCHING_ACCOUNT_ID
} from "#/store/types";
import { dayjsDate } from "#/utility/appDate";

export const actions: ActionTree<ChatState, RootState> = {
  [FETCH_ACCOUNT_IMAGE_PATH]: ({ state, commit }, accountId: number) => {
    // 画像パス取得中のアカウントは無視
    const isIncluded = state.fetchingAccountIds.includes(accountId);
    if (isIncluded) {
      return;
    }

    // 有効な画像パスがあれば取得処理を行わない
    const accountImage = state.accountImages.find(image => {
      return image.accountId === accountId;
    });
    const now = dayjsDate(new Date()).toDate();
    if (accountImage && now < accountImage.expired) {
      return;
    }

    if (!window) {
      return;
    }

    const option: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + window.token,
      }
    };

    commit(SET_FETCHING_ACOUNT_ID, accountId);
    window.axios
      .post(
        window.auth_backend_url + "/api/sign-user-icon-path/get",
        {
          ewell_user_id: accountId
        },
        option
      )
      .then((res: AxiosResponse) => {
        const expired = dayjsDate(new Date())
          .add(24, "h")
          .toDate();

        commit(SET_ACCOUNT_IMAGE_PATH, {
          accountId: accountId,
          path: res.data.image_path,
          expired
        });
      })
      .finally(() => {
        commit(DELETE_FETCHING_ACCOUNT_ID, accountId);
      });
  }
};
