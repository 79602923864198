// シフト種類色
export const SHIFT_COLORS = [
  "#EF9A9A", // Red
  "#F48FB1", // Pink
  "#CE93D8", // Purple
  "#B39DDB", // Deep-purple
  "#9FA8DA", // Indigo
  "#90CAF9", // Blue
  "#81D4FA", // Light-blue
  "#80DEEA", // Cyan
  "#80CBC4", // Teal
  "#A5D6A7", // Green
  "#C5E1A5", // Light-green
  "#E6EE9C", // Lime
  "#FFF59D", // Yellow
  "#FFE082", // Amber
  "#FFCC80", // Orange
  "#FFAB91", // Deep-orange
  "#BCAAA4", // Brown
  "#B0BEC5", // Blue-grey
  "#EEEEEE" // Grey
];

// シフトデータ
export interface ShiftItem {
  id: number;
  color: string;
  name: string;
  time: string;
  type: string;
}
