import { Component, Mixins, Prop } from "vue-property-decorator";
import { ReceiptVisitRecord } from "#/model/receipt";
import { CalendarEvent } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import { OfficeHistory } from "#/model/agreement";

@Component
export default class MedPartBase extends Mixins(UtilMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  @Prop({ default: () => ({}) }) event!: CalendarEvent;

  @Prop({ default: () => ({}) }) receiptVisitRecord!: ReceiptVisitRecord;

  @Prop({ default: () => ({}) }) officeHistory!: OfficeHistory;

  @Prop({ default: false }) isLoaded!: boolean;

  @Prop({ default: "" }) yearmonth!: string;

  protected baseDisableCheck(): boolean {
    let result = false;
    if (
      this.receiptVisitRecord.online_visit_flg == 1 ||
      this.receiptVisitRecord.stay_outside_flg == 1 ||
      this.receiptVisitRecord.remedy_div == 4 ||
      this.receiptVisitRecord.staff_div == 3
    ) {
      result = true;
    }
    return result;
  }
}
