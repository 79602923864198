














































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import { InvoiceItem, InvoiceOffice } from "@/components/officeinvoice/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {
    OfficeSelect
  }
})
export default class OfficeInvoice extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("office_invoice") private readonly form!: VForm;

  private chart!: am4charts.XYChart; //アムチャート
  private invoiceItems: InvoiceItem[] = []; //データテーブル用アイテム
  private invoiceOffices: InvoiceOffice[] = []; //アムチャート用事業所情報
  private officeId = 0; //検索条件：事業所ID
  private start = ""; //検索条件：請求開始月
  private end = ""; //検索条件：請求終了月

  //リアルタイム今月
  private todaymonth(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const todaymonth = date.toISOString().substr(0, 7);
    return todaymonth;
  }
  //リアルタイム去年
  private lastymonth(): string {
    const date = new Date();
    date.setDate(1); // 当月の1日にする
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setMonth(date.getMonth() - 11);
    date.setDate(date.getDate());
    const todaymonth = date.toISOString().substr(0, 7);
    return todaymonth;
  }

  //データテーブル
  private invoiceHeaders: DataTableHeader[] = [
    {
      text: "年月",
      value: "date",
      align: "start",
      width: ""
    },
    {
      text: "事業所",
      value: "office",
      align: "start",
      width: ""
    },
    {
      text: "看護記録書Ⅱ提出枚数",
      value: "submissions",
      align: "end",
      width: ""
    },
    {
      text: "提出人数",
      value: "submitters",
      align: "end",
      width: ""
    },
    {
      text: "",
      value: "actions",
      align: "center",
      width: "75px",
      sortable: false
    }
  ];

  public async created() {
    this.officeId = 0; //まず、未選択で検索する
    this.start = this.lastymonth();
    this.end = this.todaymonth();
    // クエリパラメータを優先
    if (typeof this.$route.query.officeid === "string") {
      this.officeId = Number(this.$route.query.officeid);
    }
    // startとendは、日付として有効な指定値であればフォーマットして適用
    if (typeof this.$route.query.start === "string") {
      const queryStart = this.strToDate(this.$route.query.start);
      if (!Number.isNaN(queryStart.getDate())) {
        this.start = this.dateToStr(queryStart, "yyyy-MM");
      }
    }
    if (typeof this.$route.query.end === "string") {
      const queryEnd = this.strToDate(this.$route.query.end);
      if (!Number.isNaN(queryEnd.getDate())) {
        this.end = this.dateToStr(queryEnd, "yyyy-MM");
      }
    }

    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  private invoiceDateFormatted(date: string): string {
    if (!date) return "";
    const [year, month] = date.split("-");
    return `${year}年${month}月`;
  }

  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    let chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    // ロケール設定
    chart = this.setChartLocale(chart);

    chart.paddingRight = 20;

    // グラフカーソル
    chart.cursor = new am4charts.XYCursor();

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "top";
    chart.legend.contentAlign = "right";
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;
    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;
    return chart;
  }

  private setChartLocale(chart: am4charts.XYChart) {
    chart.dateFormatter.language = new am4core.Language();
    chart.dateFormatter.language.locale = am4lang_ja_JP;
    chart.language.locale["_date_day"] = "MMMdd日";
    chart.language.locale["_date_year"] = "yyyy年";
    return chart;
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [
      {
        label: "...",
        menu: [
          {
            label: "Image",
            menu: [
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "svg", label: "SVG" },
              { type: "pdf", label: "PDF" }
            ]
          }
        ]
      }
    ];
    // 拡大スクロール
    const scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;
    // チャートデータセット
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 5; // y軸の幅
    valueAxis.title.text = "看護記録書Ⅱ枚数"; // y軸のタイトル
    valueAxis.fontSize = "0.785rem";
    valueAxis.min = 0;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.baseInterval = {
      timeUnit: "month",
      count: 1
    };
    dateAxis.renderer.minGridDistance = 20;

    let max = 0;
    this.invoiceItems.forEach((item: InvoiceItem) => {
      if (item.submissions > max) {
        max = item.submissions;
      }
    });

    if (max < 10) {
      valueAxis.max = 10;
    }

    dateAxis.events.on("startchanged", ev => {
      const start = new Date(ev.target.minZoomed);
      const end = new Date(ev.target.maxZoomed);

      let max = 0;
      this.invoiceItems.forEach((item: InvoiceItem) => {
        if (
          new Date(item.date.replace(/-/g, "/")) >= start &&
          new Date(item.date.replace(/-/g, "/")) <= end
        ) {
          if (item.submissions > max) {
            max = item.submissions;
          }
        }
      });

      if (max < 10) {
        valueAxis.max = 10;
      } else {
        valueAxis.max = undefined;
      }
    });

    dateAxis.events.on("endchanged", ev => {
      const start = new Date(ev.target.minZoomed);
      const end = new Date(ev.target.maxZoomed);

      let max = 0;
      this.invoiceItems.forEach((item: InvoiceItem) => {
        if (
          new Date(item.date.replace(/-/g, "/")) >= start &&
          new Date(item.date.replace(/-/g, "/")) <= end
        ) {
          if (item.submissions > max) {
            max = item.submissions;
          }
        }
      });

      if (max < 10) {
        valueAxis.max = 10;
      } else {
        valueAxis.max = undefined;
      }
    });

    this.invoiceOffices.forEach(office => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.data = office.submissions;
      series.dataFields.dateX = "date"; // 日付毎
      series.dataFields.valueY = "submission"; // 値
      series.name = office.office; // 凡例の名前
      series.tooltipText = "{valueY.value}" + "枚"; // ツールチップ
      series.strokeWidth = 3; // 折れ線の幅
      series.yAxis = valueAxis; // y軸とのマッピング
      // データポイントの設定
      const bullet = series.bullets.push(new am4charts.Bullet());
      const circle = bullet.createChild(am4core.Circle);
      circle.width = 10;
      circle.height = 10;
      circle.horizontalCenter = "middle";
      circle.verticalCenter = "middle";
    });
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    //1年を超える検索の場合
    const start = new Date(this.start.replace(/-/g, "/") + "/01");
    const end = new Date(this.end.replace(/-/g, "/") + "/01");
    const diffYear = end.getFullYear() - start.getFullYear();
    const diffMonth = end.getMonth() - start.getMonth();
    if ((diffYear >= 1 && diffMonth >= 0) || diffYear >= 2) {
      if (
        !(await this.$openConfirm(
          "検索範囲が1年を超える為、データ量によっては数十分かかる場合があります。よろしいですか？"
        ))
      ) {
        return;
      }
    }
    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  // Excelダウンロード
  private downloadExcel() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/officeinvoice/downloadexcel",
      {
        invoice_items: this.invoiceItems,
        start: this.start,
        end: this.end
      },
      res => {
        const fileName = "officeInvoice.xlsx";
        this.downloadOfficeInvoiceExcel(res.data, fileName);
      }
    );
  }
  //事業所請求情報Excelファイルのダウンロード
  private downloadOfficeInvoiceExcel(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  // 詳細ボタン
  private clickDetail(item: InvoiceItem) {
    const url = "/officeinvoice/detail";
    this.$router.push(
      url +
        `?id=${item.office_id}` +
        `&office=${item.office}` +
        `&yearmonth=${item.date}`
    );
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    //todo エラーの場合、resolveされない。。
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeinvoice/search",
        {
          office_id: this.officeId,
          start: this.start,
          end: this.end
        },
        res => {
          this.invoiceItems = res.data.invoice_items;
          this.invoiceOffices = res.data.invoice_offices;
          resolve(0);
        }
      );
    });
  }

  //-----------------------------------------------------
  // Chack
  //-----------------------------------------------------
  //開始日:日付チェック
  private chackStart(): boolean | string {
    //開始日が終了日より後
    if (this.end != "" && this.start > this.end) {
      return "開始日が終了日より後になっています";
    }
    return true;
  }
  //終了日:日付チェック
  private chackEnd(): boolean | string {
    //終了日が開始日より前
    if (this.start != "" && this.start > this.end) {
      return "終了日が開始日より前になっています";
    }
    return true;
  }
}
