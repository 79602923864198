


















































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import RulesMixin from "#/mixins/rulesMixin";
import LoginMixin from "#/mixins/loginMixin";
import Cookies from "js-cookie";

@Component
export default class LoginArea extends Mixins(RulesMixin, LoginMixin) {
  /** 契約ID */
  @Prop({ default: 0 }) agreement_id!: number;

  /** 企業コード */
  @Prop({ default: "" }) co_code!: string;

  /** パスワードリセット処理 */
  @Emit() private passwordReset() {
    return;
  }

  /** 2段階認証処理 */
  @Emit() private secondLogin(ewellUserId: number) {
    return ewellUserId;
  }

  /** ログイン完了処理 */
  @Emit() private finishLogin() {
    return;
  }

  /** パスワード表示フラグ */
  private isShow = false;

  /** ログインボタンクリック */
  private clickLogin() {
    this.login(
      window.auth_backend_url,
      window.axios,
      window.fingerprint,
      async res => {
        if (res.data.token && res.data.ref_token) {
          if (res.data.security_code) {
            if (
              await this.$openConfirm(
                "【2段階認証の有効期限が近づいています】\n\n" +
                  "現在有効な2段階認証の期限は" +
                  res.data.check_datetime +
                  "までです。\n\n" +
                  "再認証するまで登録したアドレス等にセキュリティコードを送信します。ご確認の上再認証を行ってください（期限が切れてからでも認証できます）。",
                "再認証する",
                "後で"
              )
            ) {
              this.continuedLogin(res);
            } else {
              this.setCookie(res);
            }
          } else {
            this.setCookie(res);
          }
        } else {
          this.continuedLogin(res);
        }
      },
      this.$openAlert
    );
  }

  /** クッキーに設定 */
  private setCookie(res: AxiosResponse) {
    window.token = res.data.token;
    Cookies.set("token", res.data.token, { domain: window.cookie_domain });
    window.reftoken = res.data.ref_token;
    Cookies.set("reftoken", res.data.ref_token, {
      domain: window.cookie_domain
    });
    this.finishLogin();
  }

  /** 2段階認証画面に遷移 */
  private continuedLogin(res: AxiosResponse) {
    this.$router.push({
      query: {
        ...this.$route.query,
        security_code: res.data.security_code
      }
    });
    this.secondLogin(res.data.ewell_user_id);
  }
}
