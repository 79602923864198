export interface StdEvalItemPrimaryContent {
  id: number;
  agreement_id: number;
  name: string;
  sort_no: number;
  select_items: StdEvalItemSecondaryContent[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export const DefaultStdEvalItemPrimaryContent = (): StdEvalItemPrimaryContent => ({
  id: 0,
  agreement_id: 0,
  name: "",
  sort_no: 0,
  select_items: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export interface StdEvalItemSecondaryContent {
  id: number;
  agreement_id: number;
  std_eval_item_id: number;
  name: string;
  sort_no: number;
  point: string;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export const DefaultStdEvalItemSecondaryContent = (): StdEvalItemSecondaryContent => ({
  id: 0,
  agreement_id: 0,
  std_eval_item_id: 0,
  name: "",
  sort_no: 0,
  point: "",
  description: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

export interface Office {
  office_id: number;
}
