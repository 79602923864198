





















































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import {
  OfficeHistorySameBuilding,
  SateliteHistorySameBuilding
} from "#/model/agreement";
import { Facility } from "@/components/master/office/common";

@Component({
  components: {
    AppPlaces
  }
})
export default class SameBuildings extends Mixins(UtilMixin, RulesMixin) {
  @Prop() same_buildings!: (
    | OfficeHistorySameBuilding
    | SateliteHistorySameBuilding
  )[];

  @Prop({ default: [] }) facilities!: Facility[]; //同一建物情報

  @Prop({ default: false }) isReadOnly!: boolean;

  @Emit()
  private addFacilityItem() {
    return;
  }

  private async deleteFacilityConfirm(item: unknown) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.deleteFacilityItem(item);
    }
  }

  @Emit()
  private deleteFacilityItem(item: unknown) {
    return item;
  }

  //施設ルール
  private requireSamebuilding(sameBuildingId: number): boolean | string {
    if (sameBuildingId == 0) {
      return "施設を選択してください。";
    }
    let count = 0;
    for (const building of this.same_buildings) {
      if (building.same_building_id == sameBuildingId) {
        count++;
      }
    }
    if (count > 1) {
      return "施設が重複しています。";
    }
    return true;
  }
}
