























































































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import PrivateExpenses from "@/components/patient/record2/PrivateExpenses.vue";
import { Choice } from "@/types";
import { VisitModel, Master } from "@/components/patient/record2/types";
import { VisitPatient, VisitOffice } from "@/components/visit/types";
import * as constant from "#/const";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

interface ExtVisitModel extends VisitModel {
  is_other_station_visit: number;
}

@Component({
  components: {
    PrivateExpenses
  }
})
export default class BasicInfo extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /*
   * prop
   */
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop() private model!: ExtVisitModel;
  @Prop() private readonly master!: Master;
  @Prop() private readonly patient!: VisitPatient;
  @Prop() private readonly office!: VisitOffice;
  @Prop({ default: "" }) private errorMessageVisitor!: string;
  @Prop({ default: false }) private isOverVisitMinutes!: boolean;
  @Prop({ default: false }) private isZeroDiff!: boolean;

  /*
   * Emit
   */

  /** 訪問日変更 */
  private changeVisitDay(changeDatetime: string) {
    if (this.model.visit_start_datetime) {
      const date = this.model.visit_start_datetime.split(" ")[0];
      const time = this.model.service_start_datetime.split(" ")[1];
      this.model.service_start_datetime = date + " " + time;
    }
    this.changeVisitDate(changeDatetime);
  }

  /** 訪問時間変更 */
  private changeVisitTime(changeDatetime: string) {
    if (this.model.visit_start_datetime) {
      const date = this.model.service_start_datetime.split(" ")[0];
      const time = this.model.visit_start_datetime.split(" ")[1];
      this.model.service_start_datetime = date + " " + time;
    }
    this.changeVisitDate(changeDatetime);
  }

  /** 訪問日時変更イベント通知 */
  @Emit() private changeVisitDate(changeDatetime: string) {
    this.changeServiceEndDatetime();
    return changeDatetime;
  }

  /** 訪問日変更 */
  private changeVisitEndDay() {
    if (
      this.model.visit_end_datetime &&
      (this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_MEDICAL ||
        this.patient.regular_visit_div !=
          constant.PATIENT.REGULAR_VISIT_DIV_NOTREGULAR)
    ) {
      const date = this.model.visit_end_datetime.split(" ")[0];
      const time = this.model.service_end_datetime.split(" ")[1];
      this.model.service_end_datetime = date + " " + time;
    }
    this.changeVisitEndDate();
  }

  /** 訪問時間変更 */
  private changeVisitEndTime() {
    if (
      this.model.visit_end_datetime &&
      (this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_MEDICAL ||
        this.patient.regular_visit_div !=
          constant.PATIENT.REGULAR_VISIT_DIV_NOTREGULAR)
    ) {
      const date = this.model.service_end_datetime.split(" ")[0];
      const time = this.model.visit_end_datetime.split(" ")[1];
      this.model.service_end_datetime = date + " " + time;
    }
    this.changeVisitEndDate();
  }

  /** 訪問終了日時変更イベント通知 */
  @Emit() private changeVisitEndDate() {
    this.changeServiceEndDatetime();
  }

  @Emit("update:errorMessageVisitor")
  private updateErrorMessageVisitor() {
    return "";
  }

  /*
   * Watch
   */

  // サービス提供開始日時監視
  private changeServiceEndDatetime(): void {
    if (!this.model.service_start_datetime) {
      return;
    }
    // 介護の場合、提供終了日自動算出
    if (this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_CARE) {
      const date = this.strToDate(this.model.service_start_datetime);
      switch (this.model.service_time_div) {
        case 1:
          date.setMinutes(date.getMinutes() + 19);
          break;
        case 2:
          date.setMinutes(date.getMinutes() + 29);
          break;
        case 3:
          date.setMinutes(date.getMinutes() + 59);
          break;
        case 4:
          date.setMinutes(date.getMinutes() + 89);
          break;
        case 5:
          date.setMinutes(date.getMinutes() + 90);
          break;
        case 6:
          date.setMinutes(date.getMinutes() + 20);
          break;
        case 7:
          date.setMinutes(date.getMinutes() + 40);
          break;
        case 8:
          date.setMinutes(date.getMinutes() + 60);
          break;
        case 9:
          date.setMinutes(date.getMinutes() + 80);
          break;
        case 10:
          date.setMinutes(date.getMinutes() + 100);
          break;
        case 11:
          date.setMinutes(date.getMinutes() + 120);
          break;
        case 12:
          date.setMinutes(date.getMinutes() + 20);
          break;
        case 13:
          date.setMinutes(date.getMinutes() + 40);
          break;
      }
      this.model.service_end_datetime = this.dateToStr(
        date,
        "yyyy-MM-dd HH:mm"
      );
    }
  }

  /*
   * data
   */

  // 定数読み込み
  private constant = constant;

  /*
   * computed
   */

  // 提供区分必須フラグ
  private get isNeedServiceTimeDiv(): boolean {
    // 定期巡回・医療の場合必須でない
    if (
      this.patient.regular_visit_div !==
        constant.VISIT_RECORD.REGULAR_VISIT_DIV_NOTREGULAR ||
      this.patient.ins_div === constant.PATIENT.INSURANCE_DIV_MEDICAL
    ) {
      return false;
    }

    //自費の場合必須でない
    if (
      this.model.nursing_type == constant.VISIT_RECORD.NURSING_TYPE_OWNEXPENSE
    ) {
      return false;
    }

    // 介護・提供時間必須の場合必須
    if (this.office.office_info.care_service_time_required === 1) {
      return true;
    }

    // 介護・提供時間でない場合、条件付きで必須（PT、OT、ST、の場合必須）
    const staff = this.master.staffs.find(
      staff => staff.value === this.model.staff_id
    );
    if (
      staff &&
      (staff.job_type === constant.OFFICE_STAFF.STAFF_DIV_PT ||
        staff.job_type === constant.OFFICE_STAFF.STAFF_DIV_OT ||
        staff.job_type === constant.OFFICE_STAFF.STAFF_DIV_ST)
    ) {
      return true;
    }
    return false;
  }

  //訪問者職種 1:看護師 2:准看護師 3:専門 4:PT 5:OT 6:ST 10:保健師
  private get staffJobType(): number {
    const tmp = this.master.staffs.find(
      staff => staff.value == this.model.staff_id
    );
    return tmp?.job_type || 0;
  }

  // 他ST訪問表示フラグ
  private get showOtherStationVisit(): boolean {
    if (this.patient.ins_div != constant.PATIENT.INSURANCE_DIV_CARE) {
      // 介護以外は表示しない
      return false;
    }
    // PT,OT,STの場合表示
    switch (this.staffJobType) {
      case constant.OFFICE_STAFF.STAFF_DIV_PT:
      case constant.OFFICE_STAFF.STAFF_DIV_OT:
      case constant.OFFICE_STAFF.STAFF_DIV_ST:
        return true;
    }
    return false;
  }

  //提供時間選択肢
  private get offerTime(): Choice[] {
    if (this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_MEDICAL) {
      // 医療
      return this.masters.medical_offer_time;
    } else if (this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_CARE) {
      // 介護
      switch (this.staffJobType) {
        case constant.OFFICE_STAFF.STAFF_DIV_PT:
        case constant.OFFICE_STAFF.STAFF_DIV_OT:
        case constant.OFFICE_STAFF.STAFF_DIV_ST:
          if (this.model.is_other_station_visit) {
            return this.masters.care_offer_time3;
          } else {
            return this.masters.care_offer_time2;
          }
      }
      return this.masters.care_offer_time;
    }
    return [];
  }

  // 訪問種類
  private get visitType() {
    if (!this.patient) {
      return "";
    }
    if (this.patient.ins_div === constant.PATIENT.INSURANCE_DIV_MEDICAL) {
      let text = "医療訪問";
      if (this.patient.mental_div == constant.PATIENT.MENTAL_DIV_MENTAL) {
        // 精神
        text += "（精神）";
      }
      return text;
    }
    if (this.patient.ins_div == constant.PATIENT.INSURANCE_DIV_CARE) {
      let text = "介護訪問";
      if (
        this.patient.regular_visit_div !==
        constant.VISIT_RECORD.REGULAR_VISIT_DIV_NOTREGULAR
      ) {
        text += "（定期巡回）";
      }
      return text;
    }
    return "";
  }

  /*
   * Method
   */

  // 訪問者変更
  private changeVisitor(): void {
    this.resetServiceTime();
    this.model.is_other_station_visit = 0;
    this.updateErrorMessageVisitor();
  }

  // 提供時間リセット
  private resetServiceTime(): void {
    this.model.service_time_div = 0;
  }

  // 提供区分バリデート
  private get CheckServiceTimeDiv(): (boolean | string)[] {
    if (!this.isNeedServiceTimeDiv) {
      return [];
    }

    return [
      this.minNumber(this.model.service_time_div, 1, "必須です"),
      this.maxNumber(this.model.service_time_div, 13, "必須です")
    ];
  }
}
