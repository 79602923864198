import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { SortWarekiDate, SortBirthMonth } from "#/utility/appSort";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:利用者基礎情報 */
export const headerPatient: StatisticHeader = {
  id: STATISTIC_COL_ID.PATIENT_INFO,
  text: "利用者基礎情報",
  is_checked: true,
  value: [
    {
      text: "受付日",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.regist_date`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "利用者名",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.name`,
      sortable: true,
      align: "start",
      width: "180px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "ふりがな",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.kana`,
      sortable: true,
      align: "start",
      width: "160px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "利用者ID",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.display_id`,
      sortable: true,
      align: "start",
      width: "100px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "生年月日/年齢",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.age_bday`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
      sort: SortWarekiDate
    },
    {
      text: "生年月日",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.birthday`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "年齢",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.age`,
      sortable: true,
      align: "start",
      width: "80px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.NUMBER
    },
    {
      text: "誕生月",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.birthmonth`,
      sortable: true,
      align: "start",
      width: "96px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
      sort: SortBirthMonth
    },
    {
      text: "郵便番号",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.post_code`,
      sortable: true,
      align: "start",
      width: "110px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "住所",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.address`,
      sortable: true,
      align: "start",
      width: "280px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: `電話番号`,
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.tel`,
      sortable: true,
      align: "start",
      width: "168px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "現在の状態",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.current_state`,
      sortable: true,
      align: "start",
      width: "124px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "キーパーソン",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.key_person`,
      sortable: true,
      align: "start",
      width: "150px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "電話番号（利用者基礎情報・キーパーソン）",
      select_name: "電話番号",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.key_person_tel`,
      sortable: true,
      align: "start",
      width: "320px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "訪問開始日",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.first_visit_date`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "訪問終了日",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.visit_end_date`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "死亡日",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.death_date`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "死亡時間",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.death_time`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "死亡場所",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.str_death_place`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "利用前経緯",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.requester_institution`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "利用前所在",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.str_before_use_type`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "利用後経緯",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.str_end_reason`,
      sortable: true,
      align: "start",
      width: "150px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "利用後行先",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.str_after_destination`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "性別",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.str_sex`,
      sortable: true,
      align: "start",
      width: "80px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "利用開始日",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.care_service_start_date`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "利用終了日",
      value: `${STATISTIC_COL_ID.PATIENT_INFO}.care_service_end_date`,
      sortable: true,
      align: "start",
      width: "134px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    }
  ]
};
