import Base from "../base";

export interface Question extends Base {
  /** ID */
  id: number;
  /** 掲載開始日 */
  start_date: string;
  /** 掲載終了日 */
  end_date: string;
  /** タイトル */
  title: string;
  /** 本文 */
  content: string;
  /** 掲載開始日（和暦） */
  start_date_wareki: string;
  /** 掲載終了日（和暦） */
  end_date_wareki: string;

  /** 掲載対象事業所情報 */
  question_offices: QuestionOffice[];
  /** 回答結果情報 */
  question_results: QuestionResult[];
}

export interface QuestionOffice extends Base {
  /** ID */
  id: number;
  /** アンケート情報ID */
  question_id: number;
  /** 契約ID */
  agreement_id: number;
  /** 事業所ID */
  office_id: number;
}

export interface QuestionResult extends Base {
  /** ID */
  id: number;
  /** アンケート情報ID */
  question_id: number;
  /** 職員ID */
  staff_id: number;
  /** 評価点(1~10) */
  result_point: number;
  /** 子カテゴリ情報 */
  free_text: string;
}

export const DEFAULT_QUESTION: Question = {
  id: 0,
  start_date: "",
  end_date: "",
  title: "",
  content: "",
  start_date_wareki: "",
  end_date_wareki: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  question_offices: [],
  question_results: []
};

export const DEFAULT_QUESTION_OFFICE: QuestionOffice = {
  id: 0,
  question_id: 0,
  agreement_id: 0,
  office_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
};

export const DEFAULT_QUESTION_RESULT: QuestionResult = {
  id: 0,
  question_id: 0,
  staff_id: 0,
  result_point: 1,
  free_text: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
};
