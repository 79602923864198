export interface FetchParameter {
  patient_id: number;
  start_date: string;
  end_date: string;
  calendar_div: number;
  is_reciept: boolean;
}

/** サービス提供表PDFプレビューリクエスト */
export interface RequestPreviewServiceOffer {
  /** 利用者ID */
  patient_id: number;
  /** 事業所ID */
  office_id: number;
  /** 開始日 */
  start_date: string;
  /** 終了日 */
  end_date: string;
  /** 保険種類 1:医療保険, 2:介護保険 */
  insurance_type?: number;
}

/** 月次表示 */
export const EveryMonthMode = {
  /** 別表 */
  MONTHLY_OTHER: 1,
  /** 給付費 */
  MONTHLY_BENEFIT: 2,
  /** 医療 */
  MONTHLY_MEDICAL: 3
};

/** レセプト登録画面 */
export const DISPLAY_RECEIPT_REGIST = 0;

/** サービス内容画面 */
export const DISPLAY_SERVICE_CONTENT = 1;
