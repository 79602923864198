







































































































import { Component, Mixins } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import CsvResult from "@/components/master/csv/CsvResult.vue";
import {
  InputRecords,
  ImportRequestWiseman,
  BaseRecordData
} from "@/views/master/csv/types";
import { isInputSelectable } from "@/components/master/csv/utility";
import { DataTableHeader } from "vuetify";
import { Office } from "#/model/agreement";

interface SelectItem {
  text: string;
  value: number;
}

interface OfficeSetting {
  office_id: number;
  office_name: string;
  office_code: string;
  before_zero: number;
}

@Component({
  components: { OfficeSelect, FileUpload, CsvResult }
})
export default class WisemanImport extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  private dialog = false;
  private csvFile = { id: 0, path: "" };

  // ファイルアップロードAPIパス
  private uploadApiUrl = window.base_url + "/api/upload";
  // ファイルパス作成
  private get imagePath(): string {
    return `wiseman/`;
  }

  private getRequest: ImportRequestWiseman = {
    kind: 1,
    office_id: 0,
    path: ""
  };

  private inputRecords: InputRecords = {
    header: [],
    records: [],
    patients: [],
    error: ""
  };

  private kinds = [
    {
      text: "利用者情報",
      value: 1
    },
    {
      text: "介護保険",
      value: 2
    }
  ];

  private err = "";

  private get Header() {
    return this.inputRecords.header;
  }
  private get Records() {
    return this.inputRecords.records;
  }

  private offices: Office[] = [];

  private get Offices() {
    return this.offices;
  }

  private selectRecords: BaseRecordData[] = [];

  private showResult = false;
  private isSuccess = false;

  private headers: DataTableHeader[] = [
    {
      text: "事業所",
      value: "name",
      align: "start",
      width: ""
    },
    { text: "自機関番号", value: "code", align: "start", width: "" },
    { text: "利用者ID前０", value: "before_zero", align: "start", width: "" }
  ];

  private selectItems: SelectItem[] = [
    {
      text: "無効",
      value: 0
    },
    {
      text: "有効",
      value: 1
    }
  ];

  private fetchOffices() {
    this.postJsonCheck(
      window.base_url + "/api/master/station/get",
      {
        office_id: Number(this.$route.params.id)
      },
      res => {
        this.offices = res.data.offices.filter(
          (office: { office_div: number }) => office.office_div == 1
        );
      }
    );
  }

  created() {
    this.fetchOffices();
    if (this.loginUser.parent_office_id) {
      // 主たる事業所のみ表示するので初期値は主たる事業所にする
      this.getRequest.office_id = this.loginUser.parent_office_id;
    } else {
      this.getRequest.office_id = this.HeadOnlyOffice;
    }
  }

  //Csv入力
  private async inputCsv() {
    if (this.csvFile.path == "") {
      await this.$openAlert("CSVファイルを指定してください。");
      return;
    }
    this.getRequest.path = this.csvFile.path;
    this.postJsonCheck(
      window.base_url + "/api/master/dataio/wiseman/input",
      this.getRequest,
      res => {
        this.inputRecords = res.data.CsvResult;
        this.selectRecords = (this.inputRecords
          .records as BaseRecordData[]).filter(isInputSelectable);
        this.showResult = true;
        this.err = this.inputRecords.error;
      }
    );
  }

  private back() {
    this.csvFile = { id: 0, path: "" };
    this.showResult = false;
    this.isSuccess = false;
  }

  private confirm() {
    let saveApiPath: string;
    // 選択かつ新規or更新行
    let requestData: unknown[] = [];
    const importData = this.inputRecords.patients;
    requestData = importData.filter(patient => {
      return this.selectRecords.some(
        record =>
          record.CoopId.text === patient.info.display_id &&
          isInputSelectable(record)
      );
    });

    switch (this.getRequest.kind) {
      case 1:
        saveApiPath = "/api/master/dataio/patients/save";
        break;
      case 2:
        saveApiPath = "/api/master/dataio/careins/save";
        break;

      default:
        requestData = [];
        saveApiPath = "";
    }
    this.save(saveApiPath, requestData);
  }

  private async save(saveApiPath: string, requestData: unknown[]) {
    if (!(await this.check(requestData))) return;

    this.postJsonCheck(
      window.base_url + saveApiPath,
      {
        patients: requestData
      },
      res => {
        // 選択していた行
        this.inputRecords.records = this.selectRecords
          .filter(isInputSelectable)
          .map(r => {
            r.is_success = {
              value: "is_success",
              text: "",
              label: "",
              state: 0
            };

            if (
              res.data.err_coop_ids.some(
                (coopId: number) => coopId === Number(r.CoopId.text)
              )
            ) {
              r.state = 3;
              r.is_success.text = "失敗";
            } else {
              r.state = 4;
              r.is_success.text = "成功";
            }
            return r;
          });
        // 成否カラム追加
        this.inputRecords.header.push({
          text: "成否",
          value: "is_success",
          align: "center",
          sortable: false,
          width: "128px"
        });
        this.isSuccess = true;
        this.selectRecords = [];
      }
    );
  }

  private async check(datas: unknown[]) {
    //チェックが1つもない場合
    if (datas.length == 0) {
      await this.$openAlert("1件以上にチェックを付けてください。");
      return false;
    }
    //確認
    if (!(await this.$openConfirm("登録してもよろしいですか？"))) {
      return false;
    }
    return true;
  }

  private saveOffice() {
    this.postJsonCheck(
      window.base_url + "/api/master/dataio/stations/save",
      {
        offices: this.offices as Office[]
      },
      res => {
        console.log(res);
        this.dialog = false;
        // 事業所再取得
        this.fetchOffices();
      }
    );
  }
}
