import { Component, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { Choice } from "@/types";
import {
  StatisticHeader,
  StatisticHeaderColumn,
  DefaultStatisticsSearchCond
} from "@/components/dashboard/types";
import {
  StatisticFavorite,
  StatisticFavoriteGroup,
  StatisticFavoriteColumn,
  DefaultStatisticFavorite
} from "#/model/statistic";
import SelectFilter from "#/components/selectFilter/SelectFilter.vue";
import OfficeSelects from "@/components/common_ibow/OfficeSelects.vue";

@Component({
  components: {
    OfficeSelects,
    SelectFilter
  }
})
export default class StatisticsSearchBase extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** 保険・指示書・その他加算 - 看護記録・訪問予定 切り替えタブ*/
  protected tab = 0;

  private statisticFavoriteName_ = "";
  private statisticFavoriteNameVisit_ = "";
  /** お気に入り名 */
  protected get statisticFavoriteName() {
    if (this.tab === 0) {
      return this.statisticFavoriteName_;
    } else {
      return this.statisticFavoriteNameVisit_;
    }
  }
  protected set statisticFavoriteName(value) {
    if (this.tab === 0) {
      this.statisticFavoriteName_ = value;
    } else {
      this.statisticFavoriteNameVisit_ = value;
    }
  }

  private groups_: Choice[] = [];
  private groupsVisit_: Choice[] = [];
  /** グループ一覧 */
  protected get groups() {
    if (this.tab === 0) {
      return this.groups_;
    } else {
      return this.groupsVisit_;
    }
  }
  protected set groups(value) {
    if (this.tab === 0) {
      this.groups_ = value;
    } else {
      this.groupsVisit_ = value;
    }
  }

  private patients_: { [key: string]: unknown }[] = [];
  private visits_: { [key: string]: unknown }[] = [];
  /** 一覧データ */
  protected get patients() {
    if (this.tab === 0) {
      return this.patients_;
    } else {
      return this.visits_;
    }
  }
  protected set patients(value) {
    if (this.tab === 0) {
      this.patients_ = value;
    } else {
      this.visits_ = value;
    }
  }

  private headers_: StatisticHeaderColumn[] = [];
  private headersVisit_: StatisticHeaderColumn[] = [];
  /** 利用者テーブルヘッダ */
  protected get headers() {
    if (this.tab === 0) {
      return this.headers_;
    } else {
      return this.headersVisit_;
    }
  }
  protected set headers(value) {
    if (this.tab === 0) {
      this.headers_ = value;
    } else {
      this.headersVisit_ = value;
    }
  }

  private headerSettings_: StatisticHeader[] = [];
  private headerSettingsVisit_: StatisticHeader[] = [];
  /** ヘッダー設定 */
  protected get headerSettings() {
    if (this.tab === 0) {
      return this.headerSettings_;
    } else {
      return this.headerSettingsVisit_;
    }
  }
  protected set headerSettings(value) {
    if (this.tab === 0) {
      this.headerSettings_ = value;
    } else {
      this.headerSettingsVisit_ = value;
    }
  }

  private headerSettingDisps_: StatisticHeader[] = [];
  private headerSettingDispsVisit_: StatisticHeader[] = [];
  /** ヘッダー設定(表示用・検索前用) */
  protected get headerSettingDisps() {
    if (this.tab === 0) {
      return this.headerSettingDisps_;
    } else {
      return this.headerSettingDispsVisit_;
    }
  }
  protected set headerSettingDisps(value) {
    if (this.tab === 0) {
      this.headerSettingDisps_ = value;
    } else {
      this.headerSettingDispsVisit_ = value;
    }
  }

  private pageNo_ = 1;
  private pageNoVisit_ = 1;
  /** 表の表示ページ */
  protected get pageNo() {
    if (this.tab === 0) {
      return this.pageNo_;
    } else {
      return this.pageNoVisit_;
    }
  }
  protected set pageNo(value) {
    if (this.tab === 0) {
      this.pageNo_ = value;
    } else {
      this.pageNoVisit_ = value;
    }
  }

  private sortBy_: string[] = [];
  private sortByVisit_: string[] = [];
  /** 利用者一覧ソート */
  protected get sortBy() {
    if (this.tab === 0) {
      return this.sortBy_;
    } else {
      return this.sortByVisit_;
    }
  }
  protected set sortBy(value) {
    if (this.tab === 0) {
      this.sortBy_ = value;
    } else {
      this.sortByVisit_ = value;
    }
  }

  private statisticFavoriteId_ = 0;
  private statisticFavoriteIdVisit_ = 0;
  /** ダッシュボード統計お気に入りID */
  protected get statisticFavoriteId() {
    if (this.tab === 0) {
      return this.statisticFavoriteId_;
    } else {
      return this.statisticFavoriteIdVisit_;
    }
  }
  protected set statisticFavoriteId(value) {
    if (this.tab === 0) {
      this.statisticFavoriteId_ = value;
    } else {
      this.statisticFavoriteIdVisit_ = value;
    }
  }

  private statisticFavoriteEditing_ = DefaultStatisticFavorite();
  private statisticFavoriteEditingVisit_ = DefaultStatisticFavorite();
  /** 編集中のダッシュボード統計お気に入り設定 */
  protected get statisticFavoriteEditing() {
    if (this.tab === 0) {
      return this.statisticFavoriteEditing_;
    } else {
      return this.statisticFavoriteEditingVisit_;
    }
  }
  protected set statisticFavoriteEditing(value) {
    if (this.tab === 0) {
      this.statisticFavoriteEditing_ = value;
    } else {
      this.statisticFavoriteEditingVisit_ = value;
    }
  }

  private statisticFavorites_: StatisticFavorite[] = [];
  private statisticFavoritesVisit_: StatisticFavorite[] = [];
  /** ダッシュボード統計お気に入り設定 */
  protected get statisticFavorites() {
    if (this.tab === 0) {
      return this.statisticFavorites_;
    } else {
      return this.statisticFavoritesVisit_;
    }
  }
  protected set statisticFavorites(value) {
    if (this.tab === 0) {
      this.statisticFavorites_ = value;
    } else {
      this.statisticFavoritesVisit_ = value;
    }
  }

  private statisticFavoriteGroups_: StatisticFavoriteGroup[] = [];
  private statisticFavoriteGroupsVisit_: StatisticFavoriteGroup[] = [];
  /** ダッシュボード統計お気に入り設定（グループ） */
  protected get statisticFavoriteGroups() {
    if (this.tab === 0) {
      return this.statisticFavoriteGroups_;
    } else {
      return this.statisticFavoriteGroupsVisit_;
    }
  }
  protected set statisticFavoriteGroups(value) {
    if (this.tab === 0) {
      this.statisticFavoriteGroups_ = value;
    } else {
      this.statisticFavoriteGroupsVisit_ = value;
    }
  }

  private statisticFavoriteColumns_: StatisticFavoriteColumn[] = [];
  private statisticFavoriteColumnsVisit_: StatisticFavoriteColumn[] = [];
  /** ダッシュボード統計お気に入り設定（カラム） */
  protected get statisticFavoriteColumns() {
    if (this.tab === 0) {
      return this.statisticFavoriteColumns_;
    } else {
      return this.statisticFavoriteColumnsVisit_;
    }
  }
  protected set statisticFavoriteColumns(value) {
    if (this.tab === 0) {
      this.statisticFavoriteColumns_ = value;
    } else {
      this.statisticFavoriteColumnsVisit_ = value;
    }
  }

  private statisticFavorite_: StatisticFavorite = DefaultStatisticFavorite();
  private statisticFavoriteVisit_: StatisticFavorite = DefaultStatisticFavorite();
  /** ダッシュボード統計お気に入り設定（単体） */
  protected get statisticFavorite() {
    if (this.tab === 0) {
      return this.statisticFavorite_;
    } else {
      return this.statisticFavoriteVisit_;
    }
  }
  protected set statisticFavorite(value) {
    if (this.tab === 0) {
      this.statisticFavorite_ = value;
    } else {
      this.statisticFavoriteVisit_ = value;
    }
  }

  private searchCond_ = DefaultStatisticsSearchCond();
  private searchCondVisit_ = DefaultStatisticsSearchCond();
  /** 検索条件 */
  protected get searchCond() {
    if (this.tab === 0) {
      return this.searchCond_;
    } else {
      return this.searchCondVisit_;
    }
  }
  protected set searchCond(value) {
    if (this.tab === 0) {
      this.searchCond_ = value;
    } else {
      this.searchCondVisit_ = value;
    }
  }
}
