/**
 * ソート処理共通モジュール
 */

/** 和暦のソートにおいて、両方の形式に対応できるように */
const eraList = [
  "M",
  "明治",
  "T",
  "大正",
  "S",
  "昭和",
  "H",
  "平成",
  "R",
  "令和"
];

/** 和暦日付をsortableに "R4/5/6" → "04-05-06" */
const extractWarekiYMD = (warekiDateStr: string): string => {
  // ["4","5","6"]
  const extracts = warekiDateStr.match(/[\d元]+/g);
  if (extracts == null) {
    // 必ず文字列を返す
    return "01-01-01";
  }
  // ["04", "05", "06"]
  return extracts
    .map(part => (part === "元" ? "01" : part.padStart(2, "0")))
    .join("-");
};

/**
 * 日付文字列を日付順にソート、以下の形式に対応
 * R4/5/6・令和4年5月6日のように、和暦の元号から始まる和暦日・日時文字列
 * yyyy-MM-dd
 * yyyy-MM-dd HH:mm:ss
 */
export const SortWarekiDate = (a: string, b: string): number => {
  // まずは年号だけ見て、違う年号なら順序が決まる
  let matchA = -1;
  let matchB = -1;
  for (const era of eraList) {
    matchA = a.indexOf(era);
    matchB = b.indexOf(era);
    if (matchA !== -1 && matchB === -1) {
      return -1; // aの方が古い
    } else if (matchA === -1 && matchB !== -1) {
      return 1;
    }
  }
  // 年号が同じなので、他の部分で判断する
  // "R4/5/6" → "04-05-06"
  const formatA = extractWarekiYMD(a);
  const formatB = extractWarekiYMD(b);
  if (formatA < formatB) {
    return -1; // aの方が古い
  } else if (formatA > formatB) {
    return 1;
  }
  return 0;
};

/**
 * 月を抜いてソート
 */
export const SortBirthMonth = (a: string, b: string): number => {
  const ra = a?.replace("月", "");
  const rb = b?.replace("月", "");
  return Number(ra) - Number(rb);
};
