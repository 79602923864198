


























































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { Choice, VForm } from "@/types";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import UtilMixin from "@/mixins/utilMixin";
import EditMixin from "@/mixins/editMixin";
import {
  DefaultServiceReportRemand,
  ServiceReport,
  DefaultServiceReport
} from "#/model/servicereport";
import PatientMixin from "@/mixins/patientMixin";
import ExpantionActions from "@/components/patient/ExpantionActions.vue";
import RemandInfo from "@/components/patient/common/remand/RemandInfo.vue";

@Component({
  components: {
    PatientSaveArea,
    ExpantionActions,
    RemandInfo
  }
})
export default class ServiceEdit extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private serviceReportId = 0;
  private patientId = 0;

  private service_report: ServiceReport = DefaultServiceReport(); //サービス内容確認書
  private explainStaffs: Choice[] = []; //説明者
  private vnRecordExistenceFlg = 0; //看護記録1存在フラグ
  private vnPlanExistenceFlg = 0; //看護計画書存在フラグ
  private hpPersonal = ""; //本人の要望と援助内容
  private hpFamily = ""; //家族の要望と援助内容
  private hpOrgan = ""; //依頼機関の要望と援助内容

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  private get StorageKey() {
    return `service_report-${this.loginUser.id}-${this.patientId}-${this.serviceReportId}`;
  }

  /** 差し戻し情報があるか */
  private get HasRemandInfo() {
    return (
      this.service_report.remand.id &&
      this.service_report.remand.editor_staff_id
    );
  }

  @Watch("service_report", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      service_report: this.service_report,
      displayPreview: this.displayPreview
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  created() {
    this.serviceReportId = Number(this.$route.params.servicereportid);
    this.patientId = Number(this.$route.params.id);
    this.fetchServiceReport();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/service`
      },
      {
        text: "サービス確認書",
        disabled: true,
        to: ""
      }
    ]);
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //保存
  private save(isMove: boolean) {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/servicereport/save", false, isMove);
    });
  }

  //提出
  private async submission(isPreview = false) {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    if (this.vnRecordExistenceFlg == 0) {
      await this.$openAlert("看護記録Ⅰが提出されていない為、提出できません");
      return;
    }
    if (this.vnPlanExistenceFlg == 0) {
      await this.$openAlert("看護計画書が提出されていない為、提出できません");
      return;
    }

    this.saveAction(2, "/api/patient/servicereport/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.service_report.patient_id = this.patientId;
    this.service_report.status = status;
    this.makePdf(
      window.base_url + url,
      {
        service_report: this.service_report,
        is_preview: isPreview
      },
      res => {
        this.setNoEditMode();
        if (isMove) {
          this.cancel();
        } else {
          this.serviceReportId = res.data.id;
          this.service_report.id = res.data.id;
          this.service_report.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { servicereportid: String(this.serviceReportId) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //削除
  private clickDelete() {
    this.service_report.id = this.serviceReportId;
    this.service_report.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/servicereport/delete",
      {
        service_report: this.service_report
      },
      () => {
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //プレビュー
  private preview() {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.service_report.patient_id = this.patientId;
      this.makePdf(window.base_url + "/api/patient/servicereport/preview", {
        service_report: this.service_report
      });
    });

    return;
  }

  //サービス内容確認書情報取得
  private fetchServiceReport(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/servicereport/get",
      {
        patient_id: this.patientId,
        service_report_id: this.serviceReportId
      },
      async res => {
        this.explainStaffs = res.data.explain_staffs;
        this.vnRecordExistenceFlg = res.data.vn_record_existence_flg;
        this.vnPlanExistenceFlg = res.data.vn_plan_existence_flg;
        this.hpPersonal = res.data.hp_personal;
        this.hpFamily = res.data.hp_family;
        this.hpOrgan = res.data.hp_organ;
        this.service_report = res.data.service_report;
        if (this.serviceReportId === 0) {
          this.service_report.remand = DefaultServiceReportRemand();
        }

        if (this.vnRecordExistenceFlg == 0 && this.vnPlanExistenceFlg == 0) {
          await this.$openAlert(
            "看護記録Ⅰと看護計画書が提出されていない為、提出はできません"
          );
        } else if (this.vnRecordExistenceFlg == 0) {
          await this.$openAlert(
            "看護記録Ⅰが提出されていない為、提出はできません"
          );
        } else if (this.vnPlanExistenceFlg == 0) {
          await this.$openAlert(
            "看護計画書が提出されていない為、提出はできません"
          );
        }
        this.execAfterFetch(this.StorageKey, data => {
          this.service_report = data.service_report;
          this.displayPreview = data.displayPreview;
        });
      }
    );
  }
}
