


































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { NursingDetailMaster } from "#/model/master";
import SolutionNursingDetail from "@/components/patient/common/nursingdetail/SolutionNursingDetail.vue";
import { VnPlanProblemCareContent } from "#/model/vnplan";

interface Payload {
  careContent: VnPlanProblemCareContent[];
  text: string;
  point: string;
}

@Component({
  components: {
    SolutionNursingDetail
  }
})
export default class SolutionDialogItem extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Prop() careContentMastr!: NursingDetailMaster;
  @Prop() solutionCareContents!: VnPlanProblemCareContent[];
  @Prop() text!: string;
  @Prop() pointsOfConcern!: string;

  private dialog = false;

  private nursingDetailMaster: NursingDetailMaster = {
    care_large_contents: []
  }; //看護内容マスタ
  private innerSolutionCareContents: VnPlanProblemCareContent[] = []; //看護内容
  private innerText = ""; //テキストエリア
  private innerPointsOfConcern = ""; //留意事項

  @Emit()
  private save(): Payload {
    return {
      careContent: this.innerSolutionCareContents,
      text: this.innerText,
      point: this.innerPointsOfConcern
    };
  }

  public open() {
    this.dialog = true;
    this.$nextTick(() => {
      this.nursingDetailMaster = this.careContentMastr;
      this.innerSolutionCareContents = this.deepCopy(this.solutionCareContents);
      this.innerText = this.text;
      this.innerPointsOfConcern = this.pointsOfConcern;
    });
  }

  public checkDiff() {
    // 解決策の選択肢に差分があるか確認する
    let isSameSolutionCareContents = true;
    if (
      this.innerSolutionCareContents.length === this.solutionCareContents.length
    ) {
      // 空で長さ同じなら確定で差分なし
      if (this.innerSolutionCareContents.length > 0) {
        for (const current of this.innerSolutionCareContents) {
          // 今時点の選択肢にあって、前の選択肢にない -> 差分あり
          const isIncluded = this.solutionCareContents.some(old => {
            return old.id === current.id;
          });

          if (!isIncluded) {
            isSameSolutionCareContents = false;
            break;
          }
        }
      }
    } else {
      isSameSolutionCareContents = false;
    }

    const hasDiff = !(
      isSameSolutionCareContents &&
      this.innerText === this.text &&
      this.innerPointsOfConcern === this.pointsOfConcern
    );

    if (hasDiff) {
      this.$openConfirm("変更した内容が破棄されます。よろしいですか？").then(
        ok => {
          if (ok) {
            this.close();
          }
        }
      );
    } else {
      this.close();
    }
  }

  public close() {
    this.dialog = false;
  }
}
