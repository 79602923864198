import AppChatStaffSelector from "./AppChatStaffSelector.vue";

export default {
  title: "Example/AppChatStaffSelector",
  component: AppChatStaffSelector,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatStaffSelector },
  template: '<AppChatStaffSelector v-bind="$props" />'
});

export const StaffSelector = Template.bind({});

StaffSelector.args = {
  viewType: 1,
  memberIds: [1, 3, 5, 6, 8, 10, 16, 20],
  staffs: [
    {
      id: 1,
      staffName: "1",
      iBowName: "いち",
      ewellUserId: 1
    },
    {
      id: 2,
      staffName: "2",
      iBowName: "に",
      ewellUserId: 2
    },
    {
      id: 3,
      staffName: "3",
      iBowName: "さん",
      ewellUserId: 3
    },
    {
      id: 4,
      staffName: "4",
      iBowName: "よん",
      ewellUserId: 4
    },
    {
      id: 5,
      staffName: "5",
      iBowName: "ご",
      ewellUserId: 5
    },
    {
      id: 6,
      staffName: "6",
      iBowName: "ろく",
      ewellUserId: 6
    },
    {
      id: 7,
      staffName: "7",
      iBowName: "なな",
      ewellUserId: 7
    },
    {
      id: 8,
      staffName: "8",
      iBowName: "はち",
      ewellUserId: 8
    },
    {
      id: 9,
      staffName: "9",
      iBowName: "きゅう",
      ewellUserId: 9
    },
    {
      id: 10,
      staffName: "10",
      iBowName: "じゅう",
      ewellUserId: 10
    },
    {
      id: 11,
      staffName: "11",
      iBowName: "じゅういち",
      ewellUserId: 1
    },
    {
      id: 12,
      staffName: "12",
      iBowName: "じゅうに",
      ewellUserId: 12
    },
    {
      id: 13,
      staffName: "13",
      iBowName: "じゅうさん",
      ewellUserId: 3
    },
    {
      id: 14,
      staffName: "14",
      iBowName: "じゅうし",
      ewellUserId: 14
    },
    {
      id: 15,
      staffName: "15",
      iBowName: "じゅうご",
      ewellUserId: 15
    },
    {
      id: 16,
      staffName: "16",
      iBowName: "じゅうろく",
      ewellUserId: 16
    },
    {
      id: 17,
      staffName: "17",
      iBowName: "じゅうなな",
      ewellUserId: 7
    },
    {
      id: 18,
      staffName: "18",
      iBowName: "じゅうはち",
      ewellUserId: 18
    },
    {
      id: 19,
      staffName: "19",
      iBowName: "じゅうきゅう",
      ewellUserId: 19
    },
    {
      id: 20,
      staffName: "20",
      iBowName: "にじゅう",
      ewellUserId: 20
    },
    {
      id: 21,
      staffName: "21",
      iBowName: "にじゅういち",
      ewellUserId: 1
    },
    {
      id: 22,
      staffName: "22",
      iBowName: "にじゅうに",
      ewellUserId: 22
    }
  ]
};
