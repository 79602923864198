

















































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import TopicTitle from "./topicArea/topicTitle.vue";
import TopicItems from "./topicArea/topicItems.vue";
import InfiniteLoading from "vue-infinite-loading";
import TopicSideBar from "./topicArea/topicSideBar.vue";
import TopicCreateDialog from "./topicArea/topicCreateDialog.vue";
import { InfoItems } from "@/components/bbs/types";
import { Choice } from "@/types";

@Component({
  components: {
    TopicTitle,
    TopicItems,
    InfiniteLoading,
    TopicSideBar,
    TopicCreateDialog
  }
})
export default class extends Mixins(AxiosMixin) {
  @Ref("infiniteLoading") private readonly infiniteLoading!: InfiniteLoading;

  @Ref("topicSideBar") private readonly topicSideBar!: TopicSideBar;

  @Ref("topicCreateDialog")
  private readonly topicCreateDialog!: TopicCreateDialog;

  /** 検索キーワード */
  private keyword = "";

  /** 一回で取得するデータ量 */
  private readonly limit = 20;

  /** 読み込みページ数 */
  private page = 0;

  /** 事業所掲示板アイテムリスト */
  private topicItems: InfoItems[] = [];

  /** ログイン中の職員ID */
  private staffId = 0;

  /** 選択できる事業所一覧 */
  private stations: Choice[] = [];

  /** ログイン職員の事業所ID */
  private officeId = 0;

  /** 0:一般職員 1:一般職員以外（一般職員以外は、新規トピックの作成・自分以外のトピックの削除・自分以外のコメントの削除が可能） */
  private authDiv = 0;

  mounted() {
    this.getStation();
    this.search();
  }

  //事業所取得
  private getStation(): void {
    this.postJsonCheck(window.base_url + "/api/bbsstation/get", {}, res => {
      this.stations = res.data.stations;
      this.officeId = res.data.office_id;
      this.authDiv = res.data.auth_div;
    });
  }

  /** 検索実行 */
  private search() {
    this.infiniteLoading?.stateChanger.reset();
    this.page = 0;
    this.topicItems = [];
    this.getBbsList();
  }

  /** 掲示板トピック一覧取得 */
  private getBbsList(): void {
    this.postJsonCheck(
      window.base_url + "/api/bbslist/get",
      {
        keyword: this.keyword,
        limit: this.limit,
        page: this.page
      },
      res => {
        if (this.page === 0) {
          this.topicItems = res.data.topic_items;
        } else {
          this.topicItems = this.topicItems.concat(res.data.topic_items);
        }
        this.staffId = res.data.staff_id;
        if (res.data.topic_items?.length < this.limit) {
          this.infiniteLoading?.stateChanger.complete();
        } else {
          this.infiniteLoading?.stateChanger.loaded();
        }
        this.page++;
      }
    );
  }

  /** トピックを選択状態にして、サイドバーを開く */
  private selectTopic(event: { item: InfoItems; idx: number }): void {
    this.topicSideBar.open(event.item, event.idx);
  }

  /** トピック新規作成ダイアログを開く */
  private openCreateTopicDialog(): void {
    this.topicCreateDialog.open();
  }
}
