import { Module } from "vuex";
import { RootState } from "@/store/types";
import { ChatState } from "#/store/types";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const state: ChatState = {
  enabledAgreements: [],
  accountImages: [],
  fetchingAccountIds: []
};

const namespaced = true;

export const chat: Module<ChatState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
