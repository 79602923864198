import { Vue, Component } from "vue-property-decorator";
import { Bedsore as BedsoreModel } from "#/model/report";
import { BedsoreMaster } from "#/model/master";

@Component
export default class JokusoMixin extends Vue {
  /** 褥瘡の絞り込み */
  protected filterJokuso(
    bedsoreMasters: BedsoreMaster,
    bedsores: BedsoreModel[]
  ): BedsoreModel[] {
    const jokusos: BedsoreModel[] = [];
    for (const jokuso of bedsores) {
      if (
        bedsoreMasters.parts.some(
          v =>
            v.bedsore_part_id == jokuso.jokuso_div &&
            v.name == jokuso.other_bedsore_part
        )
      ) {
        jokusos.push(jokuso);
      }
    }
    return jokusos;
  }

  /** 可変褥瘡リンクの取得 */
  protected getLinkJokuso(bedsores: BedsoreMaster, is_risk_factor: number) {
    if (!bedsores.parts) return [];
    const jokusoLink = bedsores.parts.map((value, index) => {
      const jokusouName = value.name;
      return {
        text: `褥瘡の状態（${jokusouName}）`,
        key: "bedsore" + index
      };
    });
    if (is_risk_factor == 1) {
      jokusoLink.push({
        text: "危険因子の評価",
        key: "riskFactor"
      });
    }
    return jokusoLink;
  }
}
