
























































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import {
  Sheet,
  SHEET_DATA,
  FileIF,
  ExChoice
} from "@/views/master/excel/types";

@Component({
  components: { FileUpload }
})
export default class ExcelInputStart extends Mixins(UtilMixin) {
  /** 選択中ファイル情報 */
  @Prop({ default: { id: 0, path: "" } }) inputFile!: FileIF;

  /** 医療保険オンライン請求フラグ */
  @Prop({ default: false }) isMedTransmission!: boolean;

  /** 選択中シートID */
  private sheetIds: number[] = [];

  /** ファイルアップロード用APIパス */
  private uploadApiUrl = `${window.base_url}/api/upload`;

  /** ファイルパス作成 */
  private get imagePath(): string {
    return "excel/input";
  }

  /** シート情報 */
  private sheetData = SHEET_DATA;

  /** シート選択肢情報 */
  private get sheetChoices(): ExChoice[] {
    // 医療保険オンライン請求の場合、特定シートのみ選択可能
    // (通常は全シート選択可能)
    return this.sheetData
      .filter((sheet: Sheet) => {
        if (!this.isMedTransmission) {
          return true;
        }
        return sheet.is_med_transmission === true;
      })
      .map((sheet: Sheet) => {
        return {
          text: sheet.sheet_name,
          value: sheet.sheet_id,
          sort_no: sheet.sort_no
        } as ExChoice;
      });
  }

  /** 開始日ボタンクリック時 */
  private async onClickStart() {
    if (this.inputFile.path == "") {
      await this.$openAlert("Excelファイルを指定してください。");
      return;
    }

    if (this.sheetIds.length == 0) {
      await this.$openAlert("シートを最低1つ選択してください。");
      return;
    }

    this.start();
  }

  /** 開始ボタンクリックイベント通知 */
  @Emit("click:start")
  start() {
    return this.sheetIds;
  }

  /** フォーマットExcelダウンロード */
  @Emit("download:format")
  download() {
    return;
  }
}
