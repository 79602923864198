




















































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Choice } from "@/types";
import { covid19VaccineStatuses, Patient } from "#/model/patient";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {}
})

/** 利用者基礎情報 グループ情報フォーム */
export default class FormGroupInfo extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 利用者情報 */
  @Prop() patient!: Patient;

  /** 読み取り専用フラグ(履歴編集画面用) */
  @Prop({ default: false }) isReadOnly!: boolean;

  /** グループマスタ情報 */
  @Prop() groupItems!: Choice[];

  /** プレフィックスID(入力項目にIDを振る対応) */
  @Prop({ default: "patient-info" }) private prefixId!: string;

  /** 変数 */

  /** コロナワクチン接種状況選択肢 */
  private covid19VaccineStatuses = covid19VaccineStatuses;
}
