import { Component, Emit, Mixins } from "vue-property-decorator";
import { FileInfo, FileSortTypeSelect } from "@/components/sharefile/type";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Choice } from "@/types";

// カードサイズ切り替えボタンの選択肢
export interface ThumbSelect extends Choice {
  font_size: string;
  font_weight: string;
}

@Component
export default class ShareFileMixin extends Mixins(AxiosMixin, UtilMixin) {
  @Emit()
  protected edit(file_id: number) {
    return file_id;
  }

  @Emit() protected copy(file_id: number) {
    return file_id;
  }

  // カード/リスト表示のトグルボタン
  protected viewTypeList: Choice[] = [
    { value: 0, text: "grid" },
    { value: 1, text: "list" }
  ];

  // カードサイズのトグルボタン
  protected cardSizesList: ThumbSelect[] = [
    {
      value: 0,
      text: "小",
      font_size: "0.75rem",
      font_weight: ""
    },
    {
      value: 1,
      text: "中",
      font_size: "1rem",
      font_weight: ""
    },
    {
      value: 2,
      text: "大",
      font_size: "1.25rem",
      font_weight: "bold"
    }
  ];

  // ファイル一覧のソート方法
  protected fileSortByList: FileSortTypeSelect[] = [
    {
      text: "ファイル名 - 降順",
      select: "ファイル名順",
      sort: 0,
      sortType: 0,
      file_column: "file_name",
      file_order: "DESC",
      patient_order: ""
    },
    {
      text: "ファイル名 - 昇順",
      select: "ファイル名順",
      sort: 0,
      sortType: 1,
      file_column: "file_name",
      file_order: "ASC",
      patient_order: ""
    },
    {
      text: "更新日 - 新しい順",
      select: "更新日順",
      sort: 1,
      sortType: 0,
      file_column: "updated_at",
      file_order: "DESC",
      patient_order: ""
    },
    {
      text: "更新日 - 古い順",
      select: "更新日順",
      sort: 1,
      sortType: 1,
      file_column: "updated_at",
      file_order: "ASC",
      patient_order: ""
    }
  ];
  protected fileSortBy = this.fileSortByList[0];

  // 複数利用者ファイル一覧のソート方法
  protected patientsFileSortByList: FileSortTypeSelect[] = [
    ...this.fileSortByList,
    {
      text: "利用者名順 - 降順（ふりがな優先）",
      select: "利用者名順",
      sort: 2,
      sortType: 0,
      file_column: "updated_at",
      file_order: "DESC",
      patient_order: "DESC"
    },
    {
      text: "利用者名順 - 昇順（ふりがな優先）",
      select: "利用者名順",
      sort: 2,
      sortType: 1,
      file_column: "updated_at",
      file_order: "DESC",
      patient_order: "ASC"
    }
  ];
  protected patientsFileSortBy = this.patientsFileSortByList[0];

  // ソート順
  protected sortOrdar(
    first: string | number,
    second: string | number,
    type: number
  ): number {
    if (type === 0) {
      // 降順
      if (first > second) {
        return -1;
      } else if (first < second) {
        return 1;
      } else {
        return 0;
      }
    } else {
      // 昇順
      if (first < second) {
        return -1;
      } else if (first > second) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  /** 検索条件バリデーション：開始月 */
  protected checkStartMonth(start: string, end: string): boolean | string {
    if (start != "" && end != "" && start > end) {
      return "開始月は終了月よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：終了月 */
  protected checkEndMonth(start: string, end: string): boolean | string {
    if (start != "" && end != "" && start > end) {
      return "終了月は開始月よりも後に設定してください";
    }
    return true;
  }

  // ファイル情報取得
  protected getFileInfo(path: string): FileInfo {
    const info: FileInfo = {
      icon: "",
      iconColor: ""
    };
    // 拡張子をもとにアイコン・サムネイルを取得
    const ext =
      path
        .split(".")
        .pop()
        ?.toLowerCase() ?? "";
    switch (ext) {
      case "jpg":
      case "jpeg":
        info.icon = "mdi-file-jpg-box";
        info.iconColor = "black";
        break;
      case "png":
        info.icon = "mdi-file-png-box";
        info.iconColor = "purple";
        break;
      case "gif":
        info.icon = "mdi-file-gif-box";
        info.iconColor = "brown";
        break;
      case "pdf":
        info.icon = "mdi-file-pdf-box";
        info.iconColor = "red darken-2";
        break;
      case "xls":
      case "xlsx":
        info.icon = "mdi-file-excel";
        info.iconColor = "green darken-4";
        break;
      case "doc":
      case "docx":
        info.icon = "mdi-file-word";
        info.iconColor = "blue darken-4";
        break;
      case "ppt":
      case "pptx":
        info.icon = "mdi-file-powerpoint";
        info.iconColor = "orange darken-1";
        break;
      default:
        info.icon = "mdi-file-outline";
    }

    return info;
  }

  // Choice型のリストからTextを取得する
  protected getText(id: number, choices: Choice[]): string {
    if (!choices) return "";
    const choice = choices.find(choice => choice.value == id);
    return choice ? choice.text : "";
  }

  private copyFileId = 0;

  private url = "";

  protected get CopyUrl(): string {
    return this.url + this.copyFileId;
  }

  /** クリップボードにコピー機能 */
  protected async copyUrlClipboard(
    url: string,
    fileId: number,
    elementId: string
  ) {
    this.url = url;
    this.copyFileId = fileId;
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(this.CopyUrl)
        .then(() => {
          this.$openAlert("クリップボードにコピーしました。");
        })
        .catch(() => {
          this.$openAlert(
            "クリップボードへのコピーに失敗しました。もう一度お試しください。"
          );
        });
    } else {
      if (await this.repeatCopy(elementId)) {
        this.$openAlert("クリップボードにコピーしました。");
      } else {
        this.$openAlert(
          "クリップボードへのコピーに失敗しました。もう一度お試しください。"
        );
      }
      const el = document.getElementById(elementId) as HTMLInputElement;
      el.style.visibility = "hidden";
    }
  }

  /** 3回繰り返す */
  private async repeatCopy(elementId: string): Promise<boolean> {
    await new Promise(resolve => setTimeout(resolve, 100));
    if (this.doCopy(elementId)) {
      return true;
    }
    await new Promise(resolve => setTimeout(resolve, 100));
    if (this.doCopy(elementId)) {
      return true;
    }
    await new Promise(resolve => setTimeout(resolve, 100));
    if (this.doCopy(elementId)) {
      return true;
    }
    return false;
  }

  /** Elementを探して、フォーカスして、コピーする */
  private doCopy(elementId: string): boolean {
    //navigator.clipboardはsafariだと13.1からのため、それ以前の対応
    const el = document.getElementById(elementId) as HTMLInputElement;
    el.style.visibility = "visible";
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
    el.setSelectionRange(0, 400);
    return document.execCommand("copy");
  }

  /** 時間入力 type\n1:開始\n2:終了 */
  protected inputTime(
    type: number,
    date: string,
    hh: string,
    mm: string
  ): string {
    let time = "";
    if (date !== "") {
      if (hh === "" || mm === "") {
        //開始
        if (type === 1) {
          time = " 00:00:00";
        }
        //終了
        if (type === 2) {
          time = " 23:59:59";
        }
      } else {
        time = " " + this.timeCorrection(hh) + ":" + this.timeCorrection(mm);
        //開始
        if (type === 1) {
          time += ":00";
        }
        //終了
        if (type === 2) {
          time += ":59";
        }
      }
    }

    return time;
  }

  /** 時間補正 */
  private timeCorrection(time: string): string {
    if (time.length === 1) {
      return "0" + time;
    }

    return time;
  }

  /** 検索日時チェック */
  protected checkDate(
    type: number,
    start_date_time: string,
    end_date_time: string
  ): boolean | string {
    if (start_date_time && end_date_time) {
      const start = new Date(start_date_time.replace(/-/g, "/"));
      const end = new Date(end_date_time.replace(/-/g, "/"));
      if (start > end) {
        if (type === 1) {
          return "開始日時が終了日時より後になっています";
        }
        if (type === 2) {
          return "終了日時が開始日時より前になっています";
        }
      }
    }
    return true;
  }

  /** 時間チェック */
  protected checkTime(
    type: number,
    date: string,
    hh: string,
    mm: string
  ): boolean | string {
    if (date === "" && (hh !== "" || mm !== "")) {
      if (type === 1) {
        return "開始日を入力して下さい";
      }
      if (type === 2) {
        return "終了日を入力して下さい";
      }
    }
    return true;
  }
}
