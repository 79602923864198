


























import { Component, Mixins } from "vue-property-decorator";
import PublicMoneyKindEditDialog from "@/components/master/public_money_kind/PublicMoneyKindEditDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import { PublicMoneyKind, DefaultPublicMoneyKind } from "#/model/patient";

@Component({
  components: {
    PublicMoneyKindEditDialog,
    CommonMasterList
  }
})
export default class PublicMoneyKindEntry extends Mixins(AxiosMixin) {
  private publicMoneyKinds: PublicMoneyKind[] = [];
  private selectedItem?: PublicMoneyKind = DefaultPublicMoneyKind();
  private isShownEditDialog = false;

  public headers = [
    {
      text: "公費名",
      align: "start",
      value: "name",
      class: "public-money-kind-name"
    },
    {
      text: "医/介",
      value: "insurance_div_name",
      width: "20px",
      class: "px-1",
      cellClass: "px-1"
    },
    {
      text: "法別番号",
      align: "end",
      value: "no",
      width: "20px",
      class: "px-1",
      cellClass: "px-1"
    },
    {
      text: "給付率(％)",
      align: "end",
      value: "payment_rate_string",
      width: "20px",
      class: "px-1",
      cellClass: "px-1"
    }
  ];

  public created(): void {
    this.fetchPublicMoneyKind();
  }

  private fetchPublicMoneyKind(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/public_money_kind/get",
      {},
      res => {
        this.publicMoneyKinds = res.data.public_money_kinds;
      }
    );
  }

  private clickCreateNew(): void {
    this.selectedItem = undefined;
    this.isShownEditDialog = true;
  }

  private clickEdit(item: PublicMoneyKind) {
    this.selectedItem = item;
    this.isShownEditDialog = true;
  }

  private deleteBtnClicked(item: PublicMoneyKind) {
    this.postJsonCheck(
      window.base_url + "/api/master/public_money_kind/delete",
      {
        public_money_kind: item
      },
      () => {
        this.fetchPublicMoneyKind();
        this.closeDialog();
      }
    );
  }

  private closeDialog() {
    this.isShownEditDialog = false;
  }

  private async save(item: unknown) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/public_money_kind/save",
      {
        public_money_kind: item
      },
      () => {
        this.fetchPublicMoneyKind();
        this.closeDialog();
      }
    );
  }

  private editFunc(item: PublicMoneyKind) {
    return item.is_add_user === 1;
  }
}
