import { DataTableHeader } from "vuetify/types/index";
import { SELECT_FILTER } from "#/const";
import { PdfLinkMail, DefaultPdfLinkMail } from "#/model/patient/pdfLink";

/** 機関種類定数 */
export const INSTITUTION_NUMBER = {
  RELATION: 1,
  MEDICAL: 2
};
/** 機関種類名 */
export const INSTITUTION_STRING = {
  RELATION: "関連機関",
  MEDICAL: "医療機関"
};

export interface FilterableTableHeader extends DataTableHeader {
  filterType?: number;
}

const generateHeaders = (
  withName: FilterableTableHeader[]
): FilterableTableHeader[] => {
  return [
    {
      text: "機関種類",
      value: "institution_div_name",
      filterType: SELECT_FILTER.FILTER_TYPE.LIST,
      class: "pr-0",
      cellClass: "pr-0",
      width: "5rem"
    },
    {
      text: "名前",
      value: "personnel_name",
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST,
      width: "8rem"
    },
    {
      text: "メールアドレス",
      value: "mail_address",
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST,
      cellClass: "word-break-all",
      width: "10rem"
    },
    ...withName,
    {
      text: "機関名",
      value: "institution_name",
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT_LIST,
      class: "w-auto",
      width: "10rem"
    }
  ];
};

export const Headers = generateHeaders([]);

export const HeadersSend: FilterableTableHeader[] = [
  {
    text: "",
    value: "is_read",
    sortable: false,
    class: "pr-0 pl-2",
    cellClass: "pr-0 pl-2",
    width: "3.5rem"
  },
  ...generateHeaders([
    {
      text: "既読日時",
      value: "read_datetime",
      filterType: SELECT_FILTER.FILTER_TYPE.DATE,
      width: "9rem"
    }
  ])
];

export interface Item extends PdfLinkMail {
  list_id: string; // 関係者IDと所属種類から成るリストID
  personnel_name: string; // 関係者名
  section_name: string; // 担当課/科
  institution_div_name: string; // 所属機関種類名
  institution_id: number; // 所属機関ID
  institution_name: string; // 所属機関名
  is_deleted: boolean; // 帳票が削除済みか
}
export const DefaultItem = (): Item => ({
  ...DefaultPdfLinkMail(),
  list_id: "",
  personnel_name: "",
  section_name: "",
  institution_div_name: "",
  institution_id: 0,
  institution_name: "",
  is_deleted: false
});
