import { Choice } from "@/types";
import { StaffItem } from "@/components/master/staff/types";
import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class ReportListMixin extends Mixins(AxiosMixin) {
  /** 職員リスト取得 */
  protected fetchStaffs(officeIds: number[]): Promise<Choice[]> {
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/reportlist/get/staffs",
        {
          office_ids: officeIds.filter(Boolean), // 事業所ID(未選択0は弾く)
          orderby: 1, // ふりがな順
          orderby_type: 1 // 昇順
        },
        res => {
          // 職員を一人も取得しなかった場合でも、"未選択"は表示する
          const staffs: Choice[] = [{ value: 0, text: "未選択" }];
          if (res.data.staffs != null) {
            const resStaffs: Choice[] = res.data.staffs.map(
              (staff: StaffItem) => ({
                value: staff.id,
                text: staff.ibow_nickname
              })
            );
            staffs.push(...resStaffs);
          }
          resolve(staffs);
        }
      );
    });
  }
}
