import { Vue, Component } from "vue-property-decorator";
import { State, Getter } from "vuex-class";
import { UAParser } from "ua-parser-js";
import * as master from "@/store/modules/master/types";
import { LoginUser, Master, Menu, Choice } from "@/types";
import * as constant from "#/const";
import { PREFECTURES } from "@/const";
import { AgreementUserSetting } from "#/model/agreement";
import {
  dateToStr,
  strToDate,
  convDispDateTime,
  convApiDateTime
} from "#/utility/appDate";

@Component
export default class UtilMixin extends Vue {
  @State(master.name) masterState!: master.MasterState;

  @Getter("contactTypes", { namespace: "master" }) contactTypes!: Choice[];

  public get loginUser(): LoginUser {
    return this.masterState.login_user as LoginUser;
  }

  public get ibowID(): string {
    const tmp = this.masterState.login_user?.ewell_user_ibow_id;
    return tmp ? tmp : "ID未設定";
  }

  public get CanAuthChange(): number {
    return this.masterState.can_auth_change as number;
  }

  public get masters(): Master {
    return this.masterState.master as Master;
  }

  public get menuItems(): Menu[] {
    return this.masterState.button_items as Menu[];
  }

  public get coCode(): string {
    return this.masterState.co_code as string;
  }

  public get kstNo(): string {
    const tmp = this.masterState.kst_no as string;
    return tmp ? tmp : "事業所未所属";
  }

  public get siteName(): string {
    return this.masterState.site_name as string;
  }

  public get isHonobonoAgreement(): boolean {
    return !!this.masterState.is_honobono_agreement as boolean;
  }

  public get isSukoyakaAgreement(): boolean {
    return !!this.masterState.is_sukoyaka_agreement as boolean;
  }

  public get agreementUserSetting(): AgreementUserSetting {
    return this.masterState.agreement_user_setting as AgreementUserSetting;
  }

  /** 選択肢の内1番目の事業所(サテライトを含む) */
  public get HeadOffice() {
    let officeId = 0;
    for (const office of this.masters.group_offices) {
      officeId = Number(office.value);
      if (officeId !== 0) {
        break;
      }
    }
    return officeId;
  }

  /** 選択肢の内1番目の事業所(事業所のみ) */
  public get HeadOnlyOffice() {
    let officeId = 0;
    for (const office of this.masters.group_only_offices) {
      officeId = Number(office.value);
      if (officeId !== 0) {
        break;
      }
    }
    return officeId;
  }

  /** ログイン中ユーザーの権限ID */
  public get loginUserAuthId(): number {
    if (!this.loginUser) {
      return 0;
    }
    return this.loginUser.auth_id;
  }

  /** 一般職員の権限 */
  public get isNormalStaff(): boolean {
    return this.loginUserAuthId === constant.OFFICE_STAFF.AUTH.COMMON;
  }

  /** iOSで画面を開いているか */
  public get IsiOS() {
    const uaDetail = UAParser(window.navigator.userAgent);
    if (uaDetail.os.name === "iOS") {
      return true;
    }
    const deviceModel = uaDetail.device.model?.toLowerCase() ?? "";
    const isMaciOS =
      deviceModel === "iphone" ||
      deviceModel === "ipad" ||
      deviceModel === "macintosh";
    const isTouchDevice = "ontouchend" in document;
    const isSingleTouch = navigator.maxTouchPoints === 1;
    // iPadかiPhoneであればtrueにする
    // そのため、Apple製デバイスで、かつタッチデバイスかどうかが判定材料
    // ただし、デスクトップMacでタッチ機能を有効にするケースを除く
    if (isMaciOS && isTouchDevice && !isSingleTouch) {
      return true;
    }
    return false;
  }

  /** KST番号の取得 */
  public getKstnoByOfficeId(officeId: number): string | undefined {
    return this.masterState.master?.offices.find(v => v.value == officeId)
      ?.other_string;
  }

  /**
   * レセプト連携設定に基づき利用可能な機能か判定する
   * @param settingId 外部システム連携設定
   * @param functionDiv 機能区分
   * @returns 利用可能:true 利用不可:false
   */
  public availableProcessing(settingId: number, functionDiv: number): boolean {
    const settings = this.masterState.receipt_link_settings;
    if (!settings || settings.length === 0) {
      return false;
    }
    let setting = null;
    let div = "";
    if (functionDiv === constant.AGREEMENT.FUNCTION_DIV_INSERT) {
      div = "insert_div";
    } else if (functionDiv === constant.AGREEMENT.FUNCTION_DIV_DELETE) {
      div = "delete_div";
    } else {
      return false;
    }
    setting = settings.find(
      setting =>
        setting.setting_id === settingId &&
        setting[div] === constant.AGREEMENT.DISP_SHOW
    );

    return !!setting;
  }

  /** Dateオブジェクトを指定のフォーマットに変換 */
  public dateToStr(date: Date, format: string): string {
    // 不正なdateオブジェクトの場合は空文字列を返す
    if (Number.isNaN(date.getDate())) {
      return "";
    }
    return dateToStr(date, format);
  }

  /** stringオブジェクトをDateオブジェクトに変換する */
  public strToDate(str: string): Date {
    return strToDate(str);
  }

  /** 2021-01-01 00:00:00 -> [2021-01-01, 0, 0] */
  public convDispDateTime(str: string): [string, number, number] {
    return convDispDateTime(str);
  }

  /** [2021-01-01, 0, 0] -> 2021-01-01 00:00:00 */
  public convApiDateTime(date: string, hh: number, mm: number): string {
    return convApiDateTime(date, hh, mm);
  }

  /** 令和4年5月6日 -> R4/5/6 */
  public warekiToShort(warekiDate: string) {
    return warekiDate
      .replace("明治", "M")
      .replace("大正", "T")
      .replace("昭和", "S")
      .replace("平成", "H")
      .replace("令和", "R")
      .replace("元", "1")
      .replace("年", "/")
      .replace("月", "/")
      .replace("日", "");
  }

  /** new Date()のラッパー safariへの対応 */
  public newDate(dateStr: string) {
    dateStr = dateStr.replace(/-/g, "/");
    return new Date(dateStr);
  }

  // ディープコピー Dateオブジェクト、undefined、関数は想定通りのコピーがされないので注意
  public deepCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
  }

  //全角カナを半角カナに変換
  public zenkana2Hankana(str: string): string {
    const zen = [
      "ア",
      "イ",
      "ウ",
      "エ",
      "オ",
      "カ",
      "キ",
      "ク",
      "ケ",
      "コ",
      "サ",
      "シ",
      "ス",
      "セ",
      "ソ",
      "タ",
      "チ",
      "ツ",
      "テ",
      "ト",
      "ナ",
      "ニ",
      "ヌ",
      "ネ",
      "ノ",
      "ハ",
      "ヒ",
      "フ",
      "ヘ",
      "ホ",
      "マ",
      "ミ",
      "ム",
      "メ",
      "モ",
      "ヤ",
      "ヰ",
      "ユ",
      "ヱ",
      "ヨ",
      "ラ",
      "リ",
      "ル",
      "レ",
      "ロ",
      "ワ",
      "ヲ",
      "ン",
      "ガ",
      "ギ",
      "グ",
      "ゲ",
      "ゴ",
      "ザ",
      "ジ",
      "ズ",
      "ゼ",
      "ゾ",
      "ダ",
      "ヂ",
      "ヅ",
      "デ",
      "ド",
      "バ",
      "ビ",
      "ブ",
      "ベ",
      "ボ",
      "パ",
      "ピ",
      "プ",
      "ペ",
      "ポ",
      "ァ",
      "ィ",
      "ゥ",
      "ェ",
      "ォ",
      "ャ",
      "ュ",
      "ョ",
      "ッ",
      "゛",
      "°",
      "、",
      "。",
      "「",
      "」",
      "ー",
      "・"
    ];

    const han = [
      "ｱ",
      "ｲ",
      "ｳ",
      "ｴ",
      "ｵ",
      "ｶ",
      "ｷ",
      "ｸ",
      "ｹ",
      "ｺ",
      "ｻ",
      "ｼ",
      "ｽ",
      "ｾ",
      "ｿ",
      "ﾀ",
      "ﾁ",
      "ﾂ",
      "ﾃ",
      "ﾄ",
      "ﾅ",
      "ﾆ",
      "ﾇ",
      "ﾈ",
      "ﾉ",
      "ﾊ",
      "ﾋ",
      "ﾌ",
      "ﾍ",
      "ﾎ",
      "ﾏ",
      "ﾐ",
      "ﾑ",
      "ﾒ",
      "ﾓ",
      "ﾔ",
      "ｲ",
      "ﾕ",
      "ｴ",
      "ﾖ",
      "ﾗ",
      "ﾘ",
      "ﾙ",
      "ﾚ",
      "ﾛ",
      "ﾜ",
      "ｦ",
      "ﾝ",
      "ｶﾞ",
      "ｷﾞ",
      "ｸﾞ",
      "ｹﾞ",
      "ｺﾞ",
      "ｻﾞ",
      "ｼﾞ",
      "ｽﾞ",
      "ｾﾞ",
      "ｿﾞ",
      "ﾀﾞ",
      "ﾁﾞ",
      "ﾂﾞ",
      "ﾃﾞ",
      "ﾄﾞ",
      "ﾊﾞ",
      "ﾋﾞ",
      "ﾌﾞ",
      "ﾍﾞ",
      "ﾎﾞ",
      "ﾊﾟ",
      "ﾋﾟ",
      "ﾌﾟ",
      "ﾍﾟ",
      "ﾎﾟ",
      "ｧ",
      "ｨ",
      "ｩ",
      "ｪ",
      "ｫ",
      "ｬ",
      "ｭ",
      "ｮ",
      "ｯ",
      "ﾞ",
      "ﾟ",
      "､",
      "｡",
      "｢",
      "｣",
      "ｰ",
      "･"
    ];

    let ato = "";

    for (let i = 0; i < str.length; i++) {
      let maechar = str.charAt(i);
      const zenindex = zen.indexOf(maechar);
      if (zenindex >= 0) {
        maechar = han[zenindex];
      }
      ato += maechar;
    }

    return ato;
  }

  //半角カナ小文字を大文字に変換
  public hankana2Upper(str: string): string {
    const low = ["ｧ", "ｨ", "ｩ", "ｪ", "ｫ", "ｬ", "ｭ", "ｮ", "ｯ"];

    const upper = ["ｱ", "ｲ", "ｳ", "ｴ", "ｵ", "ﾔ", "ﾕ", "ﾖ", "ﾂ"];

    let ato = "";

    for (let i = 0; i < str.length; i++) {
      let maechar = str.charAt(i);
      const lowindex = low.indexOf(maechar);
      if (lowindex >= 0) {
        maechar = upper[lowindex];
      }
      ato += maechar;
    }

    return ato;
  }

  //平仮名をカタカナに変換
  public hiraToKana(str: string): string {
    return str.replace(/[\u3041-\u3096]/g, match => {
      const chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }

  //ひらがな、かたかなを半角カナに変換
  public moji2Hankana(str: string): string {
    const zenkana = this.hiraToKana(str);
    return this.zenkana2Hankana(zenkana);
  }

  // Choice型のデータとidからテキストを取得
  public idToText(id: number, obj: Choice[]): string {
    if (id) {
      const item = obj.find(x => x.value == id);
      if (item) return item.text;
    }
    return "";
  }

  /** HTML文字列から要素部分を除去 <p id="test">te"st"</p> → te"st" */
  public removeHTMLTag(htmlStr: string) {
    return htmlStr.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "");
  }

  /** 名前一覧をフィルタリング */
  private nameFilter(cellValue?: string, search?: string) {
    if (typeof cellValue !== "string" || typeof search !== "string") {
      return false;
    }
    // 検索値と表内容の両方があれば、空白を取り除いて部分一致検索
    const fixValue = cellValue.toLocaleLowerCase().replace(/\s/g, "");
    const fixSearch = search.toLocaleLowerCase().replace(/\s/g, "");
    return fixValue.indexOf(fixSearch) !== -1;
  }

  /** 名前一覧オブジェクトをフィルタリング */
  public nameChoiceFilter(item: Choice, search?: string) {
    if (this.nameFilter(item.text, search)) {
      return true;
    }
    // ふりがなでも調べる
    return this.nameFilter(item.other_string, search);
  }

  /** Choice配列からtargetのvalueをもつChoiceのみをフィルタリング */
  public valuesChoiceFilter(
    choices: Choice[],
    targetValues: (number | string)[]
  ) {
    return choices.filter(choice => targetValues.includes(choice.value));
  }

  /** 都道府県コードから名称 */
  public prefName(prefCode: number | undefined) {
    const matchPref = PREFECTURES.find(pref => pref.value === prefCode);
    if (!matchPref || matchPref.value === 0) {
      return "";
    }
    return matchPref.text;
  }
}
