





















import { Component, Mixins, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import draggable from "vuedraggable";
import AxiosMixin from "@/mixins/axiosMixin";
import MasmenMixin from "@/mixins/masmenMixin";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import RelationEditDialog from "@/components/master/relation/RelationEditDialog.vue";

interface Item {
  id: number;
  name: string;
}

@Component({
  components: {
    draggable,
    CommonMasterList,
    RelationEditDialog
  }
})
export default class RelationList extends Mixins(AxiosMixin, MasmenMixin) {
  //編集ダイアログ
  @Ref("edit-dialog") private readonly editDialog!: RelationEditDialog;

  //続柄ヘッダー
  private headers: DataTableHeader[] = [
    {
      text: "続柄名",
      value: "name"
    }
  ];

  private items: Item[] = []; //続柄アイテム

  public created(): void {
    this.fetchFamilyRelationships();
  }

  //データ取得
  private fetchFamilyRelationships(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/family_relationship/get",
      {},
      res => {
        this.items = res.data.family_relationships;
      }
    );
  }

  //編集ダイアログ開く（新規作成）
  private newItem() {
    this.editDialog.openNew();
  }

  //編集ダイアログ開く（編集）
  private editItem(item: Item) {
    this.editDialog.openEdit(item);
  }

  //項目削除（編集）
  private deleteItem(item: Item) {
    this.postJsonCheck(
      window.base_url + "/api/master/family_relationship/delete",
      {
        family_relationship: item
      },
      () => {
        this.fetchFamilyRelationships();
        this.GetMaster();
      }
    );
  }

  //項目を追加/保存した
  private afterSaveItem() {
    this.fetchFamilyRelationships();
    this.GetMaster();
  }

  //ソート番号更新処理
  private saveOrder() {
    this.postJsonCheck(
      window.base_url + "/api/master/family_relationship/save_order",
      {
        family_relationships: this.items
      },
      () => {
        this.fetchFamilyRelationships();
        this.GetMaster();
      }
    );
  }
}
