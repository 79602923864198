















































import { Component, Vue, Prop, Emit, Ref } from "vue-property-decorator";
import {
  ObserveItemCategory,
  initObserveItemCategory,
  CareType
} from "./types";
import { VForm } from "@/types";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";

@Component({
  components: { OfficeSelectInput }
})
export default class EditObserveItemCategoryDialog extends Vue {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {
        id: 0,
        name: "",
        types: []
      };
    }
  })
  content!: ObserveItemCategory;

  @Prop({ default: [] }) care_types!: CareType[];

  // 選択済みの適応事業所
  private selectedOffice: number[] = [];

  private cloneContent: ObserveItemCategory = initObserveItemCategory();

  private get IsDelete() {
    return this.cloneContent.id !== 0;
  }

  private get IsEdit() {
    return this.cloneContent.is_editable === 1;
  }

  created() {
    this.cloneContent = { ...this.content };
  }

  mounted(): void {
    if (this.cloneContent.observation_category_item_offices.length > 0) {
      this.cloneContent.observation_category_item_offices.forEach(office => {
        if (office.office_id) {
          this.selectedOffice.push(office.office_id);
        }
      });
    } else {
      this.selectedOffice = [];
    }
  }

  //保存
  private async clickSave() {
    if (!this.form.validate()) {
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.cloneContent.observation_category_item_offices = [];
    if (this.selectedOffice.length > 0) {
      this.selectedOffice.forEach(officeId => {
        this.cloneContent.observation_category_item_offices.push({
          office_id: officeId
        });
      });
    }
    this.save(this.cloneContent);
  }

  //削除
  private async clickRemove() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.remove(this.cloneContent);
  }

  //キャンセル
  private cancel() {
    this.closeDialog();
  }

  @Emit("close")
  private closeDialog() {
    return;
  }

  @Emit()
  private save(content: unknown) {
    return content;
  }

  @Emit()
  private remove(content: unknown) {
    return content;
  }
}
