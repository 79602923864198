


































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import PatientInfo from "@/components/patient/PatientInfo.vue";
import PatientMixin from "@/mixins/patientMixin";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";

@Component({
  components: {
    PatientInfo
  }
})
export default class PatientMemoDialog extends Mixins(
  PatientMixin,
  UtilMixin,
  AxiosMixin
) {
  @Prop() private readonly patientId!: number;

  public isMemoDialogOpen = false;

  private patientMsg = {
    id: 0,
    content: "",
    lastModified: "",
    editor: ""
  };

  /** 編集したかどうか */
  private isEdit = 0;

  /** 読み込み完了したかどうか */
  private isLoaded = false;

  @Watch("patientMsg.content")
  private watchContent() {
    if (this.isLoaded) {
      this.isEdit = 1;
    }
  }

  // 利用者メッセージボードを開く
  public open() {
    this.isMemoDialogOpen = true;
    this.fetchMsgBoard();
  }

  // 利用者メッセージボード取得
  public fetchMsgBoard() {
    this.postJsonCheck(
      window.base_url + "/api/patient/msgboard/get",
      {
        patient_id: Number(this.patientId)
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        const resMsgBoard = res.data.msg_board;
        this.patientMsg.id = resMsgBoard.id;
        this.patientMsg.content = resMsgBoard.message;
        this.patientMsg.lastModified = resMsgBoard.updated_at;
        this.patientMsg.editor = resMsgBoard.editor_staff.ibow_nickname;
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      }
    );
  }

  // 利用者メッセージボード保存
  private saveMsgBoard() {
    //何も編集していない場合
    this.postJsonCheck(
      window.base_url + "/api/patient/msgboard/get",
      {
        patient_id: Number(this.patientId)
      },
      async res => {
        if (
          res.data.msg_board.updated_at !== this.patientMsg.lastModified &&
          !(await this.$openConfirm(
            "別の職員によって更新されています。強制的に上書き登録しますか？"
          ))
        ) {
          return;
        }

        this.postJsonCheck(
          window.base_url + "/api/patient/msgboard/save",
          {
            id: this.patientMsg.id,
            message: this.patientMsg.content,
            updated_at: this.patientMsg.lastModified,
            patient_id: Number(this.patientId),
            editor_staff_id: this.loginUser.id
          },
          async res => {
            if (res.data.code > 0) {
              await this.$openAlert(res.data.message);
              return;
            }
            this.isMemoDialogOpen = false;
          }
        );
      }
    );
  }

  private onClickClose() {
    // オペログ書き込み用 閉じる、キャンセル
    this.postJsonBackground(
      window.base_url + "/api/patient/msgboard/cancel",
      {
        patient_id: this.patientId
      },
      () => {
        // Do Nothing
      }
    );
    this.isMemoDialogOpen = false;
  }
}
