









































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { OfficeStaffChoice } from "#/model/staff";
import UtilMixin from "@/mixins/utilMixin";
import { OFFICE_STAFF } from "#/const";

@Component
export default class StaffShiftTitle extends Mixins(UtilMixin) {
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;
  /** 職員ID */
  @Prop({ default: 0 }) value!: number;
  /** 職員リスト */
  @Prop({ default: () => [] }) staffs!: OfficeStaffChoice[];
  /** 勤怠使用フラグ */
  @Prop({ default: () => [] }) isAttendUse!: boolean;

  /** データ更新 */
  @Emit() update() {
    return;
  }
  /** 職員ID更新時 */
  @Emit() input(value: number) {
    return value;
  }
  /** KINTAIシフト取り込み */
  @Emit() linkShift() {
    return;
  }

  /** 職員ID */
  private get Value() {
    return this.value;
  }
  /** 職員ID */
  private set Value(value: number) {
    this.input(value);
  }
}
