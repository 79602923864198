import Base from "./base";

//レセプト-訪問記録
export interface ReceiptVisitRecord extends Base {
  id: number; //ID
  receipt_id: number; //レセプトID
  visit_record_id: number; //訪問記録ID
  patient_id: number; //利用者ID
  editor_staff_id: number; //編集職員ID
  yearmonth: string; //年月
  sub_no: number; //連番
  service_time_div: number; //'提供時間区分\n看護師・准看護師の場合\n1:訪看I1(20分未満)\n2:訪看I2(30分未満)\n3:訪看I3(30分以上60分未満)\n4:訪看I4(60分以上90分未満)\n5:訪看I4・長(90分以上)\nOT・PT・STの場合\n6:訪看I5(20分)\n7:訪看I5(40分)\n8:訪看I5・2超(60分)\n9:訪看I5・2超(80分)\n10:訪看I5・2超(100分)\n11:訪看I5・2超(120分)',
  service_start_datetime: string; //サービス提供開始日時
  service_end_datetime: string; //サービス提供終了日時
  decision_flg: number; //'実績確定フラグ\n0:未確定\n1:確定',
  insurance_div: number; //'保険区分\n1:訪問看護(医療保険)\n2:訪問看護(介護保険)',
  regular_visit_div: number; //'定期巡回訪問タイプ\n1:定期巡回\n2:随時巡回',
  mental_div: number; //'一般・精神区分\n1:訪問看護\n2:精神訪問看護',
  care_div: number; //'介護・予防区分\n1:介護\n2:予防',
  stay_outside_flg: number; //'入院中の外泊訪問\n1:外泊訪問',
  online_visit_flg: number; //'オンライン訪問フラグ\n1:オンライン訪問（電話対応）',
  remedy_div: number; //'療養区分\n1:基本I\n2:基本II\n3:基本III\n4:基本IV',
  staff_div: number; //'職員区分\n1:正看等\n2:准看\n3:専門\n4:PT\n5:OT\n6:ST',
  medical_time_div: number; //'時間区分（医療）\n1:30分未満\n2:30分以上',
  extra_time_div: number; //'延長時間区分\n1:1時間\n2:2時間\n3:3時間\n4:4時間\n5:5時間',
  medical_time_zone_div: number; //'提供時間帯区分（医療）\n1:通常\n2:早朝\n3:夜間\n4:深夜',
  hour24_div: number; //'24時間体制区分\n1:24時間対応体制\n2:24時間連絡体制',
  medical_urgent_flg: number; //'緊急訪問看護フラグ（医療）\n0:緊急訪問看護なし\n1:緊急訪問看護あり',
  medical_special_div: number; //'特別管理区分（医療）\n1:特別管理加算\n2:重症度等の高い患者\n3:特別管理加算 + 重症度等の高い患者',
  medical_special_div_count: number; //'特別管理加算回数',
  times_div: number; //'複数回区分\n2:2回\n3:3回',
  times_threeperson_div: number; //'複数回訪問同一建物内人数\n1:1人\n2:2人\n3:3人',
  long_time_flg: number; //'長時間フラグ\n0:長時間なし\n1:長時間あり\n２:長時間（新型コロナウイルス臨時加算：緊急）\n3:1と2あり\n4:長時間（新型コロナウイルス臨時加算 : 計画）\n5:1と4あり',
  medical_leave_inst_flg: number; //'退院時共同指導フラグ（医療）\n0:指導なし\n1:指導あり',
  special_inst_flg: number; //'特別管理指導フラグ\n0:指導なし\n1:指導あり',
  leave_support_flg: number; //'退院支援フラグ\n0:支援なし\n1:退院支援指導\n2:退院支援指導（長時間）',
  leave_support_date: string; //'退院支援指導 退院日',
  home_inst_flg: number; //'在宅患者連携指導フラグ\n0:指導なし\n1:指導あり',
  info_expenses_flg1: number; //'情報提供療養費フラグ\n0:療養費なし\n1:療養費あり',
  info_expenses_flg2: number; //'情報提供療養費（Ⅱ）フラグ\n0:療養費なし\n1:療養費あり',
  info_expenses_flg3: number; //'情報提供療養費（Ⅲ）フラグ\n0:療養費なし\n1:療養費あり',
  info_expenses2_count: number; //'情報提供療養費（Ⅱ）回数',
  nursing_care_flg: number; //'看護介護連携フラグ\n0:看護・介護職員連携強化加算なし\n1:看護・介護職員連携強化加算あり',
  nursing_care_date: string; //'看護介護連携日',
  home_urgent_conf_flg: number; //'在宅患者緊急時カンファレンスフラグ\n0:なし\n1:あり',
  accompanied_staff_div: number; //'複数名職員区分\n1:看護師等\n2:准看護師\n3:専門\n4:PT\n5:OT\n6:ST\n7:看護補助者\n9:PSW',
  accompanied_hojo_staff_div: number; //'複数名看護補助区分\n1:（ニ）\n2:（ハ）',
  accompanied_staff_cnt: number; //'複数名訪問回数区分\n1:1回\n2:2回\n3:3回以上',
  accompanied_staff_threeperson_div: number; //'複数名訪問同一建物内人数\n1:1人\n2:2人\n3:3人',
  infants_flg: number; //'乳幼児フラグ\n0:乳幼児でない\n1:乳幼児である',
  medical_terminal_div: number; //'ターミナルケア区分（医療）\n0:なし\n2018年3月以前\n1:あり\n2018年4月以降\n1:ターミナルケア（療養費１）\n2:ターミナルケア（療養費２）',
  kinou_kyoka_div: number; //'機能強化区分\n1:1\n2:2\n3:3',
  three_person_flg: number; //'同一日3人以上フラグ\n0:3人未満\n1:3人以上',
  mental_support_div: number; //'精神科重症患者早期集中支援区分\n0:支援なし\n2018/03/31以前\n1:精神科重症患者早期集中支援管理連携加算\n2018/04/01以降\n1:精神科重症患者支援管理連携加算（イ）\n2:精神科重症患者支援管理連携加算（ロ）',
  nursing_time_div: number; //'時間区分（介護）\n1:20分未満\n2:30分未満\n3:30分～1時間\n4:1～1.5時間\n5:1.5時間以上',
  nursing_time_zone_div: number; //'提供時間帯区分（介護）\n1:通常\n2:早朝\n3:夜間\n4:深夜',
  nursing_urgent_flg: number; //'緊急時訪問看護フラグ（介護）\n0:なし\n1:あり',
  nursing_special_div: number; //'特別管理区分（介護保険）\n1:特別管理I\n2:特別管理II',
  nursing_terminal_flg: number; //'ターミナルフラグ（介護）\n0:なし\n1:あり',
  service_flg: number; //'サービス提供体制フラグ\n2021年4月以降\n0:加算なし\n1:サービス提供体制Ⅰ\n2:サービス提供体制Ⅱ\n2021年3月まで\n0:なし\n1:あり',
  mountain_flg: number; //'中山間部フラグ\n0:なし\n1:あり',
  two_person_flg: number; //'2人訪問フラグ\n2021年4月以降\n0:加算なし\n1:2人訪問Ⅰ\n2:2人訪問Ⅱ\n2021年3月まで\n0:なし\n1:あり',
  half_hour_flg: number; //'30分未満フラグ\n0:なし\n1:あり',
  first_flg: number; //'初回訪問フラグ\n0:なし\n1:あり',
  coop_strengthening_flg: number; //'連携強化フラグ\n0:なし\n1:あり',
  coop_strengthening_date: string; //'連携強化日'yyyy-mm-dd,
  same_building_flg: number; //'同一建物減算率区分\n2018年4月以降は減算率区分\n0:加算なし\n1:同一建物10%\n2:同一建物15%\n2018年3月までは減算率の有無',
  care_kyoka_flg: number; //'看護体制強化フラグ\n2018年4月以降\n0:加算なし\n1:加算体制強化加算Ⅰ\n2:加算体制強化加算Ⅱ\n2018年3月まで\n0:加算なし\n1:加算あり',
  nursing_leave_inst_date: string; //'退院時共同指導日'yyyy-mm-dd,
  nursing_leave_inst_date2: string; //'退院時共同指導日２'yyyy-mm-dd,
  covid19_div: number; //'コロナ感染症対策\n0:なし\n1:あり'
  prevent_gensan_div: number; //'予防訪問看護12月超減算区分\n0:なし\n1:あり'
  add_only_flg: number; //'加算のみフラグ',
  cancel_flg: number; //'実績解除フラグ\n1:解除\n0:有効\nこのフラグが立った場合は表面上はデータ削除されたような状態になるが、レセプト初期値としてデータ使用される',
  remote_death_assist: number; //遠隔死亡診断補助\n0:加算なし\n1:加算あり
  special_management_add: number; //専門管理加算\n0:加算なし\n1:加算あり
  senmon_care_div: number; //専門看護師訪問区分 1:緩和ケア 2:褥瘡ケア 3:人工肛門／膀胱ケア 4:特定行為
  mouth_renkei_kyoka_flg: number; //口腔連携強化加算\n0:加算なし\n1:加算あり
  dx_info_util_flg: number; //DX情報活用加算\n0:加算なし\n1:加算あり
  base_up1_flg: number; //ベースアップ評価料フラグ\n0:なし\n1:あり
  trach_tube_replace_flg: number; //特定行為 気管カニューレの交換の有無
  gast_jej_catheter_button_replace_flg: number; //特定行為 胃ろうカテーテル若しくは腸ろうカテーテル又は胃ろうボタンの交換の有無
  bladder_catheter_replace_flg : number; //特定行為 膀胱ろうカテーテルの交換の有無
  necrotic_tissue_remove_flg : number; //特定行為 褥瘡又は慢性創傷の治療における血流のない壊死組織の除去の有無
  negative_pressure_wound_therapy_flg : number; //特定行為 創傷に対する陰圧閉鎖療法の有無
  high_cal_infusion_adjust_flg : number; //特定行為 持続点滴中の高カロリー輸液の投与量の調整の有無
  fluid_dehydration_correction_flg : number; //特定行為 脱水症状に対する輸液による補正の有無
}
export const DefaultReceiptVisitRecord = (): ReceiptVisitRecord => ({
  id: 0,
  receipt_id: 0,
  visit_record_id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  yearmonth: "",
  sub_no: 0,
  service_time_div: 0,
  service_start_datetime: "",
  service_end_datetime: "",
  decision_flg: 0,
  insurance_div: 0,
  regular_visit_div: 0,
  mental_div: 0,
  care_div: 0,
  stay_outside_flg: 0,
  online_visit_flg: 0,
  remedy_div: 0,
  staff_div: 0,
  medical_time_div: 0,
  extra_time_div: 0,
  medical_time_zone_div: 0,
  hour24_div: 0,
  medical_urgent_flg: 0,
  medical_special_div: 0,
  medical_special_div_count: 0,
  times_div: 0,
  times_threeperson_div: 1,
  long_time_flg: 0,
  medical_leave_inst_flg: 0,
  special_inst_flg: 0,
  leave_support_flg: 0,
  leave_support_date: "",
  home_inst_flg: 0,
  info_expenses_flg1: 0,
  info_expenses_flg2: 0,
  info_expenses_flg3: 0,
  info_expenses2_count: 0,
  nursing_care_flg: 0,
  nursing_care_date: "",
  home_urgent_conf_flg: 0,
  accompanied_staff_div: 0,
  accompanied_hojo_staff_div: 0,
  accompanied_staff_cnt: 0,
  accompanied_staff_threeperson_div: 1,
  infants_flg: 0,
  medical_terminal_div: 0,
  kinou_kyoka_div: 0,
  three_person_flg: 0,
  mental_support_div: 0,
  nursing_time_div: 0,
  nursing_time_zone_div: 0,
  nursing_urgent_flg: 0,
  nursing_special_div: 0,
  nursing_terminal_flg: 0,
  service_flg: 0,
  mountain_flg: 0,
  two_person_flg: 0,
  half_hour_flg: 0,
  first_flg: 0,
  coop_strengthening_flg: 0,
  coop_strengthening_date: "",
  same_building_flg: 0,
  care_kyoka_flg: 0,
  nursing_leave_inst_date: "",
  nursing_leave_inst_date2: "",
  covid19_div: 0,
  prevent_gensan_div: 0,
  add_only_flg: 0,
  cancel_flg: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  remote_death_assist: 0,
  special_management_add: 0,
  senmon_care_div: 0,
  mouth_renkei_kyoka_flg: 0,
  dx_info_util_flg: 0,
  base_up1_flg: 0,
  trach_tube_replace_flg: 0,
  gast_jej_catheter_button_replace_flg: 0,
  bladder_catheter_replace_flg: 0,
  necrotic_tissue_remove_flg: 0,
  negative_pressure_wound_therapy_flg: 0,
  high_cal_infusion_adjust_flg: 0,
  fluid_dehydration_correction_flg: 0,
});

//レセプト-訪問回数
export interface ReceiptVisitCount extends Base {
  id: number; //ID
  receipt_id: number; //レセプトID
  visit_date: string; //訪問日
  insdiv: number; //訪問区分
  count: number; //訪問回数
}
export const DefaultReceiptVisitCount = (): ReceiptVisitCount => ({
  id: 0,
  receipt_id: 0,
  visit_date: "",
  insdiv: 0,
  count: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//レセプト-訪問日
export interface ReceiptVisit extends Base {
  id: number; //ID
  receipt_service_code_id: number; //レセプト_サービスコードID
  service_start_datetime: string; //サービス提供開始日時
  service_end_datetime: string; //サービス提供終了日時
  add_only: number; //加算のみフラグ
  nursing_leave_inst_date: string; //退院時共同指導日
  nursing_leave_inst_date2: string; //退院時共同指導日2
  coop_strengthening_date: string; //連携強化日
  nursing_care_date: string; //看護介護連携日
}
export const DefaultReceiptVisit = (): ReceiptVisit => ({
  id: 0,
  receipt_service_code_id: 0,
  service_start_datetime: "",
  service_end_datetime: "",
  add_only: 0,
  nursing_leave_inst_date: "",
  nursing_leave_inst_date2: "",
  coop_strengthening_date: "",
  nursing_care_date: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

//レセプト-サービスコード
export interface ReceiptServiceCode extends Base {
  id: number; //ID
  agreement_id: number; //契約ID
  visit_record_id: number; //訪問ID
  sub_no: number; //枝番
  kind_code: number; //サービス種類コード
  service_code: number; //サービスコード
  point: number; //ポイント
  point_old: number; //ポイント（割合を１００倍しない旧の計算式で算出した値）
  money: number; //金額
  visits: ReceiptVisit[]; //訪問日
}
export const DefaultReceiptServiceCode = (): ReceiptServiceCode => ({
  id: 0,
  agreement_id: 0,
  visit_record_id: 0,
  sub_no: 0,
  kind_code: 0,
  service_code: 0,
  point: 0,
  point_old: 0,
  money: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  visits: []
});

//レセプト
export interface Receipt extends Base {
  id: number; //ID
  patient_id: number; //利用者ID
  yearmonth: string; //年月
  is_history_yearmonth_medical: string; //請求履歴年月（医療）
  is_history_yearmonth_care: string; //請求履歴年月（介護）
  confirm_date_medical: string; //実績確定日（医療）
  confirm_date_care: string; //実績確定日（介護）
  confirm_datetime_medical: string; //実績確定日時（医療）
  confirm_datetime_care: string; //実績確定日時（介護）
  not_billing_medical: number; //請求しないフラグ（医療）\n0:請求する\n1:請求しない
  not_billing_care: number; //請求しないフラグ（介護）\n0:請求する\n1:請求しない
  plan_point: number; //計画単位数
  service_codes: ReceiptServiceCode[]; //サービスコード
  visit_counts: ReceiptVisitCount[]; //訪問回数
  receipt_visit_recors: ReceiptVisitRecord[]; //訪問記録
  month_service_report_path_care: string; //PDFパス給付表
  month_service_report_path_medical: string; //PDFパス医療
  month_service_report_path_beppyo: string; //PDFパス別表
  base_inner_point: string; //限度基準内単位数
  base_outer_point: string; //限度基準外単位数
}
export const DefaultReceipt = (): Receipt => ({
  id: 0,
  patient_id: 0,
  yearmonth: "",
  is_history_yearmonth_medical: "",
  is_history_yearmonth_care: "",
  confirm_date_medical: "",
  confirm_date_care: "",
  confirm_datetime_medical: "",
  confirm_datetime_care: "",
  not_billing_medical: 0,
  not_billing_care: 0,
  plan_point: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  service_codes: [],
  visit_counts: [],
  receipt_visit_recors: [],
  month_service_report_path_care: "",
  month_service_report_path_medical: "",
  month_service_report_path_beppyo: "",
  base_inner_point: "",
  base_outer_point: ""
});

//定期巡回除外日
export interface ExceptForRegularTourDay {
  id: number; //ID
  start: string; //開始日
  end: string; //終了日
  updated_at: string; //更新日時
}
export const DefaultExceptForRegularTourDays = (): ExceptForRegularTourDay => ({
  id: 0,
  start: "",
  end: "",
  updated_at: ""
});

//登録時メッセージ
export interface RegistrationMessage {
  success_flg: boolean; //登録成功フラグ
  message: string; //登録時メッセージ
}
export const DefaultRegistrationMessage = (): RegistrationMessage => ({
  success_flg: true,
  message: ""
});

//確定時メッセージ
export interface ResultsConfirmedMessage {
  success_flg: boolean; //確定成功フラグ
  message: string; //確定時メッセージ
}
export const DefaultResultsConfirmedMessage = (): ResultsConfirmedMessage => ({
  success_flg: true,
  message: ""
});

//リセット時メッセージ
export interface ResetMessage {
  success_flg: boolean; //確定成功フラグ
  message: string; //確定時メッセージ
}
export const DefaultResetMessage = (): ResetMessage => ({
  success_flg: true,
  message: ""
});

//在宅がん医療総合診療料（期間ごと）
export interface PeriodHomeCancer {
  active_flg: boolean; //対象週内に「末期の悪性腫瘍」の指示書があればtrue
  chack_flg: boolean; //対象期間を在宅がんとしている場合true
  start_date: string; //開始日
  end_date: string; //終了日
  disp_period: string; //表示用期間
}
export const DefaultPeriodHomeCancer = (): PeriodHomeCancer => ({
  active_flg: false,
  chack_flg: false,
  start_date: "",
  end_date: "",
  disp_period: ""
});

//在宅がん医療総合診療料
export interface HomeCancer {
  existence_home_cancer: boolean; //末期の悪性腫瘍存在フラグ
  check_home_cancer: boolean; //在宅がん医療総合診療料チェック
  period_home_cancer: PeriodHomeCancer[]; //在宅がん医療総合診療料（期間ごと）
}
export const DefaultHomeCancer = (): HomeCancer => ({
  existence_home_cancer: false,
  check_home_cancer: false,
  period_home_cancer: []
});

// 検索条件インターフェース
export interface SearchCondition {
  yearmonth: string; //対象年月
  office_id: number; //事業所ID
  patient_name: string; //利用者名(フロントで絞り込み)
  patient_name_by: number; //利用者名の検索方法(フロントで絞り込み)
  display_id: string; //利用者表示用ID(フロントで絞り込み)
  display_id_by: number; //利用者表示用IDの検索方法(フロントで絞り込み)
  is_medical: boolean; //医療が対象か(フロントで絞り込み)
  is_nursing: boolean; //介護が対象か(フロントで絞り込み)
  is_nursing_pre: boolean; //予防介護が対象か(フロントで絞り込み)
  is_regular: boolean; //定期巡回が対象か(フロントで絞り込み)
  plan_point: string; //計画単位数(フロントで絞り込み)
  plan_point_by: number; //計画単位数の検索方法(フロントで絞り込み)
  is_billing: boolean; //請求履歴確定済みが対象か(フロントで絞り込み)
  decision_date: string; //確定日(フロントで絞り込み)
  decision_date_by: number; //確定日の検索方法(フロントで絞り込み)
  status: boolean; //実績確定済みが対象か(フロントで絞り込み)
  is_plan_above: boolean; //計画単位数が上限を超えているか(フロントで絞り込み)
}
export const DefaultSearchCondition = (): SearchCondition => ({
  yearmonth: "",
  office_id: 0,
  patient_name: "",
  patient_name_by: 1,
  display_id: "",
  display_id_by: 0,
  is_medical: false,
  is_nursing: false,
  is_nursing_pre: false,
  is_regular: false,
  plan_point: "",
  plan_point_by: 0,
  is_billing: false,
  decision_date: "",
  decision_date_by: 0,
  status: false,
  is_plan_above: false
});

// 利用者情報
export interface PatientInfo {
  id: number; //ID
  patient_id: number; //利用者ID
  name: string; //利用者名
  furigana: string; //ふりがな
  birthday: string; //誕生日
  display_id: string; //ユーザーID
  processing_target: number; //処理対象/1:全て/2:医療/3:介護
  visit_type: string; //訪問種別
  medical: string; //医療
  prevention: string; //予防
  care: string; //介護
  regular: string; //定期巡回
  plan_point: string; //計画単位数
  org_plan_point: string; //計画単位数（元データ）
  limit_point: number; //上限単位数
  office: string; //事業所
  invoice_history: string; //請求履歴
  fixed_date: string; //確定日
  status: string; //確定済み
  visit_info: number; //訪問情報\n1:両方の訪問あり\n2:医療訪問のみあり\n3:介護訪問のみあり
  receipt: Receipt; //レセプト
  age: number; //年齢
}

// レセプトメッセージ
export interface ReceiptMessage {
  existence_flg: boolean; //存在フラグ\true:あり\false:なし
  message: string; //メッセージ
}
export const DefaultReceiptMessage = (): ReceiptMessage => ({
  existence_flg: false,
  message: ""
});

//実績確定時金額表示用データ
export interface AmountInfo {
  med_amount: string; //医療:総金額
  home_cancer_amount: string; //医療:在宅がん医療総合診療料
  insurance_amount: string; //医療:保険請求総金額
  benefit_ratio: string; //医療:給付割合
  own_expense: string; //医療:自己負担額
  high_medical_expenses: string; //医療:高額療養費
  high_medical_expenses_note: string; //医療:高額療養費補足
  home_cancer_terms: string[]; //医療:在宅がん医療総合診療料期間
  care_district_branch_giving_limit: string; //介護:区分支給限度額
  care_actual_number_of_units: string; //介護:実績単位数
  regular_district_branch_giving_limit: string; //定期:区分支給限度額
  regular_actual_number_of_units: string; //定期:実績単位数
  plan_point: string; //計画単位数
}
export const DefaultAmountInfo = (): AmountInfo => ({
  med_amount: "",
  home_cancer_amount: "",
  insurance_amount: "",
  benefit_ratio: "",
  own_expense: "",
  high_medical_expenses: "",
  high_medical_expenses_note: "",
  home_cancer_terms: [],
  care_district_branch_giving_limit: "",
  care_actual_number_of_units: "",
  regular_district_branch_giving_limit: "",
  regular_actual_number_of_units: "",
  plan_point: ""
});

//未処理
export interface UntreatedInfo {
  yearmonth: string; //対象年月
  count: number; //未確定件数
}
export const DefaultUntreatedInfo = (): UntreatedInfo => ({
  yearmonth: "",
  count: 0
});

// firestore用
export interface ReceiptSearchCondition {
  ids: number[];
  sort_by: string[];
  sort_desc: boolean[];
  search_cond: SearchCondition;
}

export const DefaultReceiptSearchCondition = (): ReceiptSearchCondition => ({
  ids: [],
  sort_by: ["status", "furigana"],
  sort_desc: [true, false],
  search_cond: DefaultSearchCondition()
});
