export interface ObservationItem {
  id: number;
  category_name: string;
  name: string;
  observation_item_offices: Office[];
  observation_select_items: ObservationSelectItem[];
  sort_no: number;
  observation_category_item_id: number;
}
export const DefaultObservationItem = (): ObservationItem => ({
  id: 0,
  category_name: "",
  name: "",
  observation_item_offices: [],
  observation_select_items: [],
  sort_no: 0,
  observation_category_item_id: 0
});

export interface ObservationSelectItem {
  id?: number;
  observation_item_id: number;
  name: string;
  sort_no: number;
}
export const DefaultObservationSelectItem = (): ObservationSelectItem => ({
  id: 0,
  observation_item_id: 0,
  name: "",
  sort_no: 0
});

export interface Office {
  office_id: number;
}
