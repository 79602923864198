



























import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import ReportsSearch from "@/components/report_list/ReportList.vue";
import { ReportCategory } from "@/components/report_list/types";

@Component({
  components: {
    ReportsSearch
  }
})
export default class Dashboard extends Mixins(AxiosMixin) {
  /** フィルター */
  @Ref("reportList") private readonly reportList!: ReportsSearch;
  private category = 0; // 帳票のカテゴリ 0:帳票 1:集計 2:ハイライト
  private categories: ReportCategory[] = [];

  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.categories = this.$refs?.reportList?.reportCategories ?? [];
  }
}
