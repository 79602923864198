import * as appDate from "#/utility/appDate";

export interface StatisticSearchCondition {
  office_ids: number[]; // 事業所ID
  start_date: string; // 開始日
  end_date: string; // 終了日
  group_ids: number[]; // グループ
  group_operator: number; // グループ検索演算子（1:AND 2:OR）
  keyword: string; // キーワード
  statistic_type: number; //統計種類
  selected_col: {
    [key: string]: boolean;
    medical_insurance: boolean;
    old_age: boolean;
    care_insurance: boolean;
    burden_ratio: boolean;
    high_expense: boolean;
    public_money: boolean;
    indicate: boolean;
    vn_record: boolean;
    regular_visit: boolean;
    visit_record: boolean;
  };
  /** 1利用者1行で表示するか */
  need_data_compression: boolean | undefined;
  /** 特別指示書・点滴指示書のみを除外するか */
  need_normal_indicates: boolean;
  /** 現在有効な情報(介護保険、医療保険、指示書、公費、高額療養費)がある利用者を除外 */
  exclude_now: boolean;
}

export const DefaultStatisticsSearchCond = (): StatisticSearchCondition => {
  // 検索条件のうち開始日と終了日は必ず先月初~先月末
  const now = new Date();
  const prevMonthFirst = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const prevMonthLast = new Date(now.getFullYear(), now.getMonth(), 0);
  return {
    office_ids: [],
    start_date: appDate.dateToStr(prevMonthFirst, "yyyy-MM-dd"),
    end_date: appDate.dateToStr(prevMonthLast, "yyyy-MM-dd"),
    group_ids: new Array(5).fill(0),
    group_operator: 1,
    keyword: "",
    statistic_type: 1,
    selected_col: {
      medical_insurance: false,
      old_age: false,
      care_insurance: false,
      burden_ratio: false,
      high_expense: false,
      public_money: false,
      indicate: false,
      regular_visit: false,
      vn_record: false,
      visit_record: false
    },
    need_data_compression: true,
    need_normal_indicates: false,
    exclude_now: false
  };
};

export interface StatisticHeader {
  id: string;
  text: string;
  is_checked: boolean;
  value: StatisticHeaderColumn[];
}

export interface StatisticHeaderColumn {
  text: string;
  select_name?: string;
  value: string;
  sortable: boolean;
  align: string;
  width: string;
  selected: boolean;
  is_branch?: boolean;
  filterCondition?: number;
  filterType?: number;
  filter?: (val: string | number | Date) => boolean;
  style?: string;
  sort?: (a: any, b: any) => number; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface StatisticsItem {
  $children: {
    filteredItems: object[];
    sortItems: (item: object[]) => [];
  }[];
}
