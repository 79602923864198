























































































































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { Choice, OcrTemplateChoice } from "@/types";
import { COLLECTION_PATIENT_SHARE_FILE_SETTING } from "@/const/envFireStore";
import {
  PatientShareFileInfo,
  PatientShareFileSearchCondition,
  DefaultPatientShareFileSearchCondition,
  FileSortTypeSelect,
  OcrTemplate
} from "@/components/sharefile/type";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";
import OcrExecutionDialog from "@/components/ocr/OcrExecutionDialog.vue";
import OcrResultDialog from "@/components/ocr/OcrResultDialog.vue";
import FireStoreMixin from "@/mixins/firestoreMixin";
import PatientFileListTable from "@/components/sharefile/PatientFileListTable.vue";
import PatientShareFileBulkUpdateDialog from "@/components/sharefile/PatientShareFileBulkUpdateDialog.vue";
import FileHeader from "@/components/sharefile/FlieHeader.vue";
import FileListCard from "@/components/sharefile/FileListCard.vue";
import FileUploadTag from "@/components/patient/file/FileUploadTag.vue";
import OfficeSelects from "@/components/common_ibow/OfficeSelects.vue";
import { VForm } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import { OcrStatus, DocumentType } from "@/const/ocr";
import { AxiosResponse } from "axios";

@Component({
  components: {
    PatientShareFileBulkUpdateDialog,
    PatientFileListTable,
    FileUploadTag,
    OfficeSelects,
    FileHeader,
    FileListCard,
    OcrExecutionDialog,
    OcrResultDialog
  }
})
export default class ShareFilePatient extends Mixins(
  ShareFileMixin,
  FireStoreMixin,
  UtilMixin
) {
  @Ref("fileUpload") private uploadDialog!: FileUploadTag;
  @Ref("listTable") private listTable!: PatientFileListTable;
  @Ref("searchForm") private readonly searchForm!: VForm;
  @Ref("ocrExecution") private ocrExecutionDialog!: OcrExecutionDialog;
  @Ref("ocrResult") private ocrResultDialog!: OcrResultDialog;

  @Watch("searchObj.toggle_list")
  private changeDisplay() {
    if (!this.searchObj.toggle_list) {
      this.checked = [];
    }
    this.documentSave(this.searchObj);
  }
  @Watch("searchObj.cards_size")
  private changeDisplaySize() {
    this.documentSave(this.searchObj);
  }

  /** タグ一括編集ダイアログ展開状態 */
  private tagDialog = false;

  /** 利用者共有ファイル一覧情報 */
  private patientShareFiles: PatientShareFileInfo[] = [];

  /** 編集対象の利用者ID */
  private targetPatientId = 0;

  /** 編集対象の利用者の所属事業所ID */
  private targetOfficeId = 0;

  /** 検索条件 */
  private searchObj = this.deepCopy(DefaultPatientShareFileSearchCondition());
  private setDefaultSearch() {
    // 表示切り替えボタン・並び順の選択状態は保持
    const defaultSearchCond = DefaultPatientShareFileSearchCondition();
    this.searchObj = {
      ...this.searchObj,
      office_ids: defaultSearchCond.office_ids,
      tag_ids: defaultSearchCond.tag_ids,
      file_name: defaultSearchCond.file_name,
      start_date: defaultSearchCond.start_date,
      start_hh: defaultSearchCond.start_hh,
      start_mm: defaultSearchCond.start_mm,
      start_date_time: defaultSearchCond.start_date_time,
      end_date: defaultSearchCond.end_date,
      end_hh: defaultSearchCond.end_hh,
      end_mm: defaultSearchCond.end_mm,
      end_date_time: defaultSearchCond.end_date_time
    };
  }

  /** 検索用タグ情報 */
  private tags: Choice[] = [];

  private ocrTemplates: OcrTemplateChoice[] = [];

  // 検索サイドバーを開いているか
  private searchPanel = false;

  // チェック状態
  private checked: PatientShareFileInfo[] = [];

  /** 合計ページ件数 */
  private pageCount = 0;
  /** ページング表示件数 */
  private dispPageLimit = 7;

  /** 現在のページ番号 */
  private pageNo = 1;

  /** 1ページあたりの表示件数 */
  private perPage = 25;

  /** 総件数 */
  private total = 0;

  /** 1ページあたりの表示件数一覧 */
  private pageLimits: Choice[] = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 }
  ];

  private timerId = 0;

  /** 利用者ファイル保存先 */
  private get imageFilePath() {
    // [co_code]/[kst_no]/patient/[利用者ID]/[ファイル名(uuid)]
    const kstNo = this.getKstnoByOfficeId(this.targetOfficeId);
    return `${this.siteName}/${kstNo}/patient/${this.targetPatientId}/`;
  }

  //** 絞り込みしているかどうか */
  private get IsFiltered(): boolean {
    return !(
      this.searchObj.tag_ids.length === 0 &&
      this.searchObj.office_ids.length === 0 &&
      this.searchObj.start_date === "" &&
      this.searchObj.end_date === ""
    );
  }

  /** カード表示時の現在ページ表記 */
  private get paginationText(): string {
    let text = "";
    if (!this.total) {
      return text;
    }

    text += "全";
    text += this.total + "件中 ";
    text += 1 + (this.perPage * this.pageNo - this.perPage) + "件 〜 ";
    const endCount = this.perPage * this.pageNo;
    if (endCount >= this.total) {
      text += this.total;
    } else {
      text += endCount;
    }
    text += "件";

    return text;
  }

  private get DocumentType(): number {
    return DocumentType.patient;
  }

  public async created() {
    this.collection = COLLECTION_PATIENT_SHARE_FILE_SETTING;
    this.documentId = String(this.loginUser.id);
    const data = await this.documentGet();
    if (data) {
      // 保存済み検索条件を反映、保存データにはない追加されたプロパティなら、デフォルト値が使われる
      this.searchObj = {
        ...DefaultPatientShareFileSearchCondition(),
        ...(data as PatientShareFileSearchCondition)
      };
      // 並び順選択のラベルに反映
      this.sortSearchCondToSelect(this.searchObj);
      // IV2-1994のリリース対応補正
      if (
        this.searchObj.start_hh === undefined ||
        this.searchObj.start_mm === undefined
      ) {
        this.searchObj.start_hh = "";
        this.searchObj.start_mm = "";
      }
      if (
        this.searchObj.end_hh === undefined ||
        this.searchObj.end_mm === undefined
      ) {
        this.searchObj.end_hh = "";
        this.searchObj.end_mm = "";
      }
    } else if (data === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }
    this.fetchPatientShareFiles();
  }

  destroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  /** 利用者共有ファイル一覧取得 */
  private fetchPatientShareFiles() {
    const offset = (this.pageNo - 1) * this.perPage;

    this.postJsonCheck(
      window.base_url + "/api/shares/patient/get",
      {
        ...this.searchObj,
        limit: this.perPage,
        offset: offset
      },
      res => {
        if (res.data) {
          const patientShareFiles: PatientShareFileInfo[] =
            res.data.patient_share_files;
          patientShareFiles.forEach(file => {
            // 行選択した時に全選択されてしまわないように
            file.id = file.patient_file.id;
          });
          this.patientShareFiles = patientShareFiles;
          this.tags = res.data.tags;

          // 合計ページ数の再計算
          this.pageCount = Math.ceil(res.data.count / this.perPage);
          this.total = res.data.count;

          if (res.data.ocr_templates) {
            this.ocrTemplates = res.data.ocr_templates.map(
              (template: OcrTemplate) => {
                return {
                  text: template.name,
                  value: template.id,
                  target_kind: template.target_kind
                } as OcrTemplateChoice;
              }
            );
          }

          this.pollingOcrState();
        }
      }
    );
  }

  /** 並び順選択を検索条件に反映 */
  private sortSelectToSearchCond(sortSelect: FileSortTypeSelect) {
    this.searchObj.sort_column = sortSelect.file_column;
    this.searchObj.sort_type = sortSelect.file_order;
    this.searchObj.sort_type_patient = sortSelect.patient_order;
  }

  /** 検索条件を並び順選択に反映 */
  private sortSearchCondToSelect(searchCond: PatientShareFileSearchCondition) {
    const matcSortBy = this.patientsFileSortByList.find(
      sortBy =>
        sortBy.file_column === searchCond.sort_column &&
        sortBy.file_order === searchCond.sort_type &&
        sortBy.patient_order === searchCond.sort_type_patient
    );
    if (matcSortBy) {
      this.patientsFileSortBy = matcSortBy;
    }
  }

  private onChangeSortObj(rule: FileSortTypeSelect) {
    this.patientsFileSortBy = rule;
    this.sortSelectToSearchCond(rule);
    this.documentSave(this.searchObj);
    this.update();
  }

  /** 検索 */
  private async search() {
    if (!this.searchForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      this.searchPanel = true;
      return;
    }
    this.pageNo = 1;

    this.documentSave(this.searchObj);
    this.update();
  }

  /** ページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
    this.update(false);
  }

  private closeTagDialog() {
    this.tagDialog = false;
  }

  //タグ一括編集イベント
  private openTagDialog() {
    this.tagDialog = true;
  }

  /** タグ一括編集保存時 */
  private saveTags(tagIds: number[]) {
    this.postJsonCheck(
      window.base_url + "/api/tags/patient/save",
      {
        patient_share_files: this.checked,
        tag_ids: tagIds
      },
      () => {
        // 更新後、チェック状態をリセットして再読み込み
        this.update();
      }
    );

    this.tagDialog = false;
  }

  /** ファイルアップロードダイアログ openイベント */
  private fileUploadOpen(data: PatientShareFileInfo) {
    this.targetPatientId = data.patient_id;
    this.targetOfficeId = data.office_id;
    this.$nextTick(() => {
      const ocrStatus = data.ocr_meta.ocr_status.status;
      // 一度OCRを実行した場合はOCR対象外にできない
      const isOcrExecuted =
        ocrStatus === OcrStatus.pending ||
        ocrStatus === OcrStatus.completed ||
        ocrStatus === OcrStatus.created ||
        ocrStatus === OcrStatus.failed;

      this.uploadDialog.open(
        data.patient_file.id,
        data.ocr_meta.ocr_enabled,
        !isOcrExecuted,
        Number(isOcrExecuted || ocrStatus === OcrStatus.standby),
        data.ocr_meta.ocr_status.reserved_ocr_template_id
      );
    });
  }

  /** 利用者ファイル編集時はデータ再取得で疑似リロード */
  private update(needReset = true) {
    if (needReset) {
      this.checked = [];
      // リスト表示の時
      if (this.searchObj.toggle_list) {
        this.listTable.reset();
      }
    }

    this.fetchPatientShareFiles();
  }

  /** 利用者クリック時 */
  private goPatientPage(patientId: number) {
    this.$router.push({ path: `/patient/${patientId}/file` });
  }

  private openSearchPanel() {
    this.searchPanel = true;
  }

  private pollingOcrState() {
    this.timerId = setInterval(() => {
      // OCR 中のファイルのみ OCR 結果取得 API をポーリングする
      for (const file of this.patientShareFiles) {
        if (file.ocr_meta.ocr_status.status === OcrStatus.pending) {
          this.callCheckOcrStatus(file.id, true);
        }
      }
    }, 10000);
  }

  private checkOcrStatus(fileId: number) {
    this.callCheckOcrStatus(fileId, false);
  }

  private callCheckOcrStatus(fileId: number, isBackGround: boolean) {
    const requestURL = window.base_url + "/api/ocrstore/status/check";
    const requestParams = {
      document_type: DocumentType.patient,
      document_id: fileId
    };

    const update = (res: AxiosResponse) => {
      const index = this.patientShareFiles.findIndex(file => {
        return file.id === fileId;
      });

      if (index >= 0) {
        this.patientShareFiles[index].ocr_meta.ocr_status = res.data.ocr_status;
      }
    };

    if (isBackGround) {
      this.postJsonBackground(requestURL, requestParams, res => {
        update(res);
      });
    } else {
      this.postJsonCheck(requestURL, requestParams, res => {
        if (res.data.ocr_status.status === OcrStatus.pending) {
          this.$openAlert("OCRを実行中です\n終了までお待ちください");
          return;
        }

        update(res);
      });
    }
  }

  private openOcrExecutionDialog(file: PatientShareFileInfo) {
    this.ocrExecutionDialog.open(
      file.id,
      file.thumbnail_file_path,
      file.ocr_meta.ocr_status.reserved_ocr_template_id,
      file.ocr_meta.ocr_status.status
    );
  }

  private ocrExecutionSucceeded(fileId: number) {
    const index = this.patientShareFiles.findIndex(file => {
      return file.id === fileId;
    });

    if (index >= 0) {
      this.patientShareFiles[index].ocr_meta.ocr_status.status =
        OcrStatus.pending;
    }
  }

  private ocrCanceled(fileId: number) {
    const index = this.patientShareFiles.findIndex(file => {
      return file.id === fileId;
    });

    if (index >= 0) {
      this.patientShareFiles[index].ocr_meta.ocr_status = {
        id: 0,
        status: 0,
        smart_ocr_id: 0,
        reserved_ocr_template_id: 0
      };
    }
  }

  private openOcrResultDialog(file: PatientShareFileInfo) {
    this.ocrResultDialog.open(file.thumbnail_file_path, file.id);
  }

  /** URLコピー時 */
  private copyUrlLink(data: PatientShareFileInfo) {
    this.copyUrlClipboard(
      window.auth_middleend_url + "/appform/patient/file/view/",
      data.patient_file.id,
      "url-copy-area"
    );
  }

  /** 検索開始日時変更 */
  private changeStart() {
    this.searchObj.start_date_time =
      this.searchObj.start_date +
      this.inputTime(
        1,
        this.searchObj.start_date,
        this.searchObj.start_hh,
        this.searchObj.start_mm
      );
  }
  /** 検索終了日時変更 */
  private changeEnd() {
    this.searchObj.end_date_time =
      this.searchObj.end_date +
      this.inputTime(
        2,
        this.searchObj.end_date,
        this.searchObj.end_hh,
        this.searchObj.end_mm
      );
  }
}
