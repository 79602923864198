import * as types from "./types";
import { Module } from "vuex";
import { RootState } from "@/store/types";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const state: types.OcrState = {
  documentType: 0,
  documentId: 0,
  targetKind: 0,
  mediIns: types.DefaultOcrMediInsCondition(),
  mediInsOldAge: types.DefaultOcrMediInsOldAgeCondition(),
  mediInsHighExpense: types.DefaultOcrMediInsHighExpenseCondition(),
  careIns: types.DefaultOcrCareInsCondition(),
  careInsPaymentRate: types.DefaultOcrCareInsPaymentRateCondition(),
  indicate: types.DefaultOcrIndicateCondition(),
  indicateSpecial: types.DefaultOcrSpecialIndicateCondition()
};

const namespaced = true;

export const ocr: Module<types.OcrState, RootState> = {
  namespaced,
  state,
  mutations,
  getters
};
