















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import AppCheckbox from "./AppCheckbox.vue";

@Component({
  components: { AppCheckbox }
})
export default class AppCheckboxGroup extends Vue {
  /** 選択肢 */
  @Prop() items!: {
    label: string;
    value: unknown;
    disabled: boolean;
    isNotShow: boolean;
  }[];

  /** 値 */
  @Prop() value!: unknown;

  /** 値変更時 */
  @Emit() private input(newValue: unknown): unknown {
    return newValue;
  }

  /** 全ての選択肢が非表示の場合、全体を非表示に */
  private get IsAllDisplay() {
    let result = false;
    for (const item of this.items) {
      if (!item.isNotShow) {
        result = true;
      }
    }
    return result;
  }

   /** 値 */
  private get Value() {
    if (this.value == 0) {
      return [];
    }
    return [this.value];
  }

  /** 値 */
  private set Value(newValue: unknown[]) {
    if (newValue.length == 0) {
      this.input(0);
    }
    this.input(newValue[newValue.length - 1]);
  }
}
