







































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { OcrTemplateChoice } from "@/types";
import { OcrStatus } from "@/const/ocr";

@Component
export default class OcrExecutionDialog extends Mixins(AxiosMixin) {
  @Prop({ default: 0 }) documentType!: number;
  @Prop({
    default: () => {
      return [];
    }
  })
  ocrTemplates!: OcrTemplateChoice[];

  private isOpen = false;
  private documentId = 0;
  private imagePath = "";
  private templateId = 0;
  private ocrStatus = 0;

  private get IsFailedData() {
    return this.ocrStatus === OcrStatus.failed;
  }

  public open(
    documentId: number,
    imagePath: string,
    templateId: number,
    ocrStatus: number
  ) {
    this.documentId = documentId;
    this.imagePath = imagePath;
    this.templateId = templateId;
    this.ocrStatus = ocrStatus;

    this.isOpen = true;
  }

  private executeOcr() {
    this.postJsonCheck(
      window.base_url + "/api/ocrstore/file/upload",
      {
        document_type: this.documentType,
        document_id: this.documentId,
        ocr_template_id: this.templateId
      },
      () => {
        this.success();
        this.close();
      }
    );
  }

  private cancelOcr() {
    this.postJsonCheck(
      window.base_url + "/api/ocrstore/status/clear",
      {
        document_type: this.documentType,
        document_id: this.documentId
      },
      () => {
        this.cancel();
        this.close();
      }
    );
  }

  private close() {
    this.isOpen = false;
  }

  @Emit()
  private success() {
    return this.documentId;
  }

  @Emit()
  private cancel() {
    return this.documentId;
  }
}
