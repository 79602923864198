











































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import { OFFICE_STAFF, COMMON } from "#/const";
import PatientMixin from "@/mixins/patientMixin";

COMMON.REPORT_STATUS.SUBMIT;

interface Items {
  id: number;
  status: number;
  evaluation_date: string;
  evaluation_staff: string;
  updated_at: string;
  vnrecord_flg: boolean;
}

@Component({
  components: {
    PatientHeader,
    BaseReportList
  }
})
export default class Adl extends Mixins(AxiosMixin, PatientMixin) {
  /** 保存 */
  private REPORT_SAVE = COMMON.REPORT_STATUS.SAVE;
  /** 提出 */
  private REPORT_SUBMIT = COMMON.REPORT_STATUS.SUBMIT;
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  private patientId = 0;
  private items: Items[] = []; //ADL
  private authId = 0; //権限
  private ids = []; //最新の3つのid

  private headers: DataTableHeader[] = [
    {
      text: "評価日",
      value: "evaluation_date",
      align: "start",
      sort: SortWarekiDate
    },
    {
      text: "評価者",
      value: "evaluation_staff",
      align: "start"
    }
  ];

  /** タブgetter */
  private get reportTab() {
    return this.$route.query.reporttab ?? "0";
  }

  /** ?マークの内容getter */
  private get hint() {
    //提出済みの場合のみ表示
    if (this.reportTab === "1") {
      return `看護記録Ⅰで提出した「ADL/日常生活自立度」も確認することができます。
看護記録Ⅰで作成されたADLはこのページでは編集できません。`;
    }
    return "";
  }

  created(): void {
    this.patientId = Number(this.$route.params.id);
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchADL({ tab: 0, page: 0 });
    }
  }

  mounted() {
    this.setDefaultHeader();
  }

  //新規作成
  private clickNew(): void {
    this.$router.push({
      name: "PatientAdlEdit",
      params: { id: String(this.patientId), adlid: "0" }
    });
  }
  //編集
  private clickEdit(item: Items): void {
    this.$router.push({
      name: "PatientAdlEdit",
      params: { id: String(this.patientId), adlid: String(item.id) }
    });
  }
  //確認
  private clickConfirmation(item: Items): void {
    if (item.vnrecord_flg) {
      //看護記録I
      this.$router.push({
        name: "PatientAdlConfirmationVnrecord",
        params: {
          id: String(this.patientId),
          vnrecordid: String(item.id)
        }
      });
    } else {
      //利用者ADL
      this.$router.push({
        name: "PatientAdlConfirmation",
        params: {
          id: String(this.patientId),
          adlid: String(item.id)
        }
      });
    }
  }

  //前3回評価
  private last3TimesAdl() {
    this.makePdf(window.base_url + "/api/patient/adl/preview", {
      patient_id: this.patientId
    });
    return;
  }

  //ADL情報一覧取得
  private fetchADL(obj: { tab: number; page: number }): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/adls/get",
      {
        patient_id: this.patientId,
        tab: obj.tab,
        page: obj.page
      },
      res => {
        this.authId = res.data.auth_id;
        this.ids = res.data.ids;
        if (res.data.items != null) {
          this.reportList?.finishLoaded(res.data.items);
          if (obj.page != 0) {
            this.items = this.items.concat(res.data.items);
          } else {
            this.items = res.data.items;
          }
          //評価日降順にソートし直す
          this.items.sort((a, b) =>
            SortWarekiDate(b.evaluation_date, a.evaluation_date)
          );
        } else {
          this.reportList?.finishLoaded([]);
        }
      }
    );
  }
}
