import Base from "../base";

//指示書別表8状態
export interface IndicateBeppyo8State extends Base {
  id: number; //ID
  indicate_id: number; //'指示書ID',
  beppyo8_state_id: number; //'別表8状態ID',
  beppyo8_state_code: string; //'別表8状態コード',
  beppyo8_state_code_after_r6: string; //'別表8状態コード令和6年6月以降',
  beppyo8_state_name: string; //'別表8状態名',
}

export const DefaultIndicateBeppyo8State = (): IndicateBeppyo8State => ({
  id: 0,
  indicate_id: 0,
  beppyo8_state_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  beppyo8_state_code: "",
  beppyo8_state_code_after_r6:"",
  beppyo8_state_name: ""
});
