


















import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import { CareSummary } from "../../../components/patient/caresummary/types";
import { COMMON } from "#/const";

@Component({
  components: {
    PatientHeader,
    BaseReportList
  }
})
export default class CareSummaryList extends Mixins(AxiosMixin) {
  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  //利用者ID
  private patientId = 0;

  //看護サマリヘッダ
  private headers: DataTableHeader[] = [
    {
      text: "訪問日",
      value: "visit_date_wareki",
      width: "152px",
      sort: SortWarekiDate
    }
  ];
  private commentHeader = {
    text: "コメント",
    value: "remand.comment"
  };

  //看護サマリデータ
  private items: CareSummary[] = [];

  public created(): void {
    this.patientId = Number(this.$route.params.id);
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchCareSummary({ tab: 0, page: 0 });
    }
  }

  //データ更新
  private fetchCareSummary(obj: { tab: number; page: number }) {
    this.postJsonCheck(
      window.base_url + "/api/patient/caresummaries/get",
      {
        patient_id: this.patientId,
        tab: obj.tab,
        page: obj.page
      },
      res => {
        if (res.data.care_summaries != null) {
          this.reportList?.finishLoaded(res.data.care_summaries);
          if (obj.page != 0) {
            this.items = this.items.concat(res.data.care_summaries);
          } else {
            this.items = res.data.care_summaries;
          }
        }
      }
    );
  }

  //新規作成ボタンクリック
  private clickNew(): void {
    this.$router.push({
      name: "PatientCareSummaryEdit",
      params: { id: String(this.patientId), care_summary_id: "0" }
    });
  }

  //編集ボタンクリック
  private clickEdit(item: CareSummary): void {
    this.$router.push({
      name: "PatientCareSummaryEdit",
      params: { id: String(this.patientId), care_summary_id: String(item.id) }
    });
  }

  //差し戻し
  private setRemand(recordItem: { item: CareSummary; comment: string }): void {
    recordItem.item.status = this.REPORT_REMAND;
    recordItem.item.remand.comment = recordItem.comment;
    this.postJsonCheck(
      window.base_url + "/api/patient/caresummary/saveRemand",
      {
        caresummary: recordItem.item
      },
      () => {
        this.items = this.items.filter(v => v.id !== recordItem.item.id);
      }
    );
  }

  //PDF
  private pdf(item: CareSummary): void {
    this.makePdf(window.base_url + "/api/patient/caresummary/preview/ids", {
      patient_id: this.patientId,
      care_summary_ids: [item.id],
      care_summarys: [item]
    });
  }
}
