







































import { Component, Prop, Vue } from "vue-property-decorator";
import { Insurer } from "@/views/master/insurer/types";

@Component
export default class InsurerList extends Vue {
  private itemPerPage = 25;

  private pageNo = 1;

  private headers = [
    {
      text: "保険者",
      align: "start",
      value: "insurer_name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    { text: "", value: "actions", sortable: false, align: "end" }
  ];

  @Prop({ default: "" }) search!: string;

  @Prop({ type: Array, default: () => [] }) insurers!: Insurer[];

  private edit(ins: Insurer) {
    this.$router.push({
      name: "InsurerEdit",
      params: {
        id: String(ins.id)
      }
    });
  }

  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }
}
