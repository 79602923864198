import Base from "../base";

//負担割合証
export interface CareInsuranceBurdenRatio extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  payment_rate: number; //'給付率（％）',
  use_payment_rate: number; //'給付率使用フラグ',
  burden_rate_select: number; //'負担割合\n0:未選択\n1:1割\n2:2割\n3:3割',
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
}

export const DefaultCareInsuranceBurdenRatio = (): CareInsuranceBurdenRatio => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  payment_rate: 0,
  use_payment_rate: 0,
  burden_rate_select: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: ""
});
