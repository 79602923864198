






















import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import CommonMasterList from "@/components/master/CommonMasterList.vue";
import NursingPlanEdit, {
  CareProblemSolution
} from "@/components/master/nursingplan/NursingPlanEdit.vue";

interface Building {
  id: number;
  name: string;
}

@Component({
  components: {
    CommonMasterList,
    NursingPlanEdit
  }
})
export default class NursingPlanList extends Mixins(AxiosMixin) {
  //編集ダイアログ
  @Ref("edit-dialog") private readonly editDialog!: NursingPlanEdit;

  private headers = [
    {
      text: "療養上の課題・支援内容",
      value: "keyword"
    }
  ];

  private buildings: Building[] = [];

  public created(): void {
    this.fetchCareproblemSolutions();
  }

  private fetchCareproblemSolutions(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/careproblem_solutions/get",
      {},
      res => {
        this.buildings = res.data.careproblem_solutions;
      }
    );
  }

  private deleteItem(item: CareProblemSolution) {
    this.postJsonCheck(
      window.base_url + "/api/master/careproblem_solution/delete",
      {
        careproblem_solution: item
      },
      () => {
        this.fetchCareproblemSolutions();
      }
    );
  }

  private editItem(item: CareProblemSolution) {
    this.editDialog.openEdit(item);
  }

  private newItem() {
    this.editDialog.openNew();
  }
}
