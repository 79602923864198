export interface Insurer {
  id: number;
  insurer_name: string;
  post_code: string;
  pref: number;
  city: string;
  area: string;
  building: string;
  insurer_divs: InsurerDiv[];
  insurer_offices: InsurerOffice[];
}

export function setInsurer(): Insurer {
  return {
    id: 0,
    insurer_name: "",
    post_code: "",
    pref: 0,
    city: "",
    area: "",
    building: "",
    insurer_divs: [],
    insurer_offices: []
  };
}

export interface InsurerDiv {
  id: number;
  insurer_id: number;
  insurer_div: number;
  insurer_no: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
}

export function setInsurerDiv(): InsurerDiv {
  return {
    id: 0,
    insurer_id: 0,
    insurer_div: 0,
    insurer_no: "",
    tel_area: "",
    tel_city: "",
    tel_num: ""
  };
}

export interface InsurerOffice {
  id: number;
  office_id: number;
}
