import Base from "../base";
import { ApplySameBuilding, DefaultApplySameBuilding } from "../common";

/* サテライト履歴-同一建物 */
export interface SateliteHistorySameBuilding extends Base {
  id: number; //ID
  satelite_history_id: number; //'事業所履歴ID',
  same_building_id: number; //'同一建物ID',
  subtraction_rate: number; //'同一建物減算率（%）',
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
  same_building: ApplySameBuilding; //同一建物
}

export const DefaultSateliteHistorySameBuilding = (): SateliteHistorySameBuilding => ({
  id: 0,
  satelite_history_id: 0,
  same_building_id: 0,
  subtraction_rate: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  same_building: DefaultApplySameBuilding()
});
