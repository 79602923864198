











import { Vue, Component, Prop } from "vue-property-decorator";
import { DispDocInfo } from "@/components/patient/types";

@Component
export default class DocInfo extends Vue {
  @Prop() docInfo!: DispDocInfo; // 利用者の指示書・保険等状況
}
