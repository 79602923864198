






import { Component, Vue } from "vue-property-decorator";
import CorpForm from "@/components/master/corp/CorpForm.vue";
@Component({
  components: { CorpForm }
})
export default class CorpEdit extends Vue {
  private corpId = -1;

  created() {
    if (this.$route.params.id) {
      this.corpId = Number(this.$route.params.id);
    }
  }
}
