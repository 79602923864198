


















import { Mixins, Component, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import { BedsorePlan } from "@/components/patient/bedsore/types";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import { COMMON } from "#/const";

@Component({
  components: {
    BaseReportList
  }
})
export default class PatientBedsoreList extends Mixins(AxiosMixin) {
  /** 差し戻し */
  private REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  @Ref("reportList") private readonly reportList?: BaseReportList;

  private patientId = 0;

  private headers: DataTableHeader[] = [
    {
      text: "計画作成日",
      value: "plan_date_wareki",
      align: "start",
      width: "152px",
      sort: SortWarekiDate
    }
  ];

  private listItems: BedsorePlan[] = [];

  private commentHeader = {
    text: "コメント",
    value: "remand.comment"
  };

  public created(): void {
    this.patientId = Number(this.$route.params.id);
    // 未提出タブ以外ではタブ変更後の取得処理に任せる
    if (!this.$route.query.reporttab || this.$route.query.reporttab === "0") {
      this.fetchBedsorePlans({ tab: 0, page: 0 });
    }
  }

  private fetchBedsorePlans(obj: { tab: number; page: number }): void {
    // 褥瘡対策計画一覧取得
    this.postJsonCheck(
      window.base_url + "/api/patient/bedsoreplans/get",
      {
        patient_id: this.patientId,
        tab: obj.tab,
        page: obj.page
      },
      res => {
        if (res.data.bedsore_plans != null) {
          this.reportList?.finishLoaded(res.data.bedsore_plans);
          if (obj.page != 0) {
            this.listItems = this.listItems.concat(res.data.bedsore_plans);
          } else {
            this.listItems = res.data.bedsore_plans;
          }
        }
      }
    );
  }

  private toEdit(bedsorePlanId: number) {
    this.$router.push({
      name: "PatientBedsoreEdit",
      params: {
        id: String(this.patientId),
        bedsore_plan_id: String(bedsorePlanId)
      }
    });
  }

  private getPdf(bedsorePlan: BedsorePlan): void {
    this.makePdf(window.base_url + "/api/patient/bedsoreplans/pdf/get", {
      patient_id: this.patientId,
      pdf_path: bedsorePlan.pdf.pdf_path,
      bedsore_plan: bedsorePlan
    });
  }

  //差し戻し
  private setRemand(recordItem: { item: BedsorePlan; comment: string }): void {
    recordItem.item.status = this.REPORT_REMAND;
    recordItem.item.remand.comment = recordItem.comment;
    this.postJsonCheck(
      window.base_url + "/api/patient/bedsoreplan/saveRemand",
      { bedsore_plan: recordItem.item },
      () => {
        this.listItems = this.listItems.filter(
          v => v.id !== recordItem.item.id
        );
      }
    );
  }
}
