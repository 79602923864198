









































































































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import { ExcelInputResult, BaseRecord } from "@/views/master/excel/types";

@Component({})
export default class ExcelInputDataResult extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** テーブルデータ */
  @Prop() result!: ExcelInputResult;

  /** 最終シートフラグ */
  @Prop() isLastSheet!: boolean;

  /** 医療保険オンライン請求フラグ */
  @Prop() isMedTransmission!: boolean;

  /** 各行の色定義 */
  private rowBackground(item: BaseRecord) {
    let color = "";

    switch (item.record_state) {
      case 5: // 成功行
        color = "white lighten-4";
        break;
      case 4: // スキップ行
        color = "blue-grey lighten-4";
        break;
      case 3: // エラー行
        color = "red lighten-4";
        break;
      case 2: // 新規行
        color = "yellow lighten-4";
        break;
      case 1: // 既存行(差分なし)
      case 0: // 既存行(差分あり)
      default:
        // 該当なし
        color = "";
        break;
    }
    return color;
  }

  /** 各項目の色定義 */
  private tableDataState(state: number, rowState: number) {
    let color = "";
    switch (state) {
      case 0: // 通常
        color = "default";
        break;
      case 1: // 差分あり
        color = "diff";
        break;
      case 2: // エラー
        color = "err";
        break;
      default:
        // 通常
        color = "default";
        break;
    }

    // 新規行のエラー行場合セルのカラーはエラー項目のみ有効
    switch (rowState) {
      case 5: // 成否：成功行
      case 3: // エラー行
      case 2: // 新規行
        if (state != 2) {
          color = "default";
        }
        break;
      case 4: // スキップ行の場合は必ずデフォルト
        color = "default";
        break;
      default:
        break;
    }

    return color;
  }

  /** エラーメッセージのテキスト形式出力 */
  private errMessageOutput() {
    let errorMessages = "";
    this.result.result.error_messages.forEach(message => {
      errorMessages += `${message}\n`;
    });

    this.postJsonBlobResCheck(
      window.base_url + "/api/master/patientinvoice/outputerrmessage",
      {
        err_message: errorMessages
      },
      res => {
        const fileName = "errorMessage.txt";
        this.downloadErrMessageTxt(res.data, fileName);
      }
    );
  }

  /** エラーメッセージテキストのダウンロード */
  private downloadErrMessageTxt(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  /** 保存ボタンクリック時 */
  @Emit("save")
  private save() {
    return;
  }

  /** スキップボタンクリック時 */
  @Emit("skip")
  private skip() {
    return;
  }

  /** リセットボタンクリック時 */
  @Emit("reset")
  private reset() {
    return;
  }
}
