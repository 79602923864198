import Base from "../base";

//医療保険
export interface MedicalInsurance extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  insurance_type: number; //'保険種類\n1:社保\n2:国保\n3:公費単独\n4:後期\n5:退職者',
  insured_div: number; //'被保険者区分\n1:本人\n2:家族\n3:6歳\n4:高齢一\n5:高齢7',
  is_old_age_certificate: number; //'兼高齢受給者証区分\n0:兼ねない\n1:兼ねる',
  is_pay_per20: number; //'後期高齢2割負担に対する配慮措置（令和7年9月30日まで）',
  is_exempt: number; //'減免（災害など）フラグ\n1:減免（災害など）である\n0:減免（災害など）でない',
  is_exception10: number; //'特例１割有効フラグ',
  is_make_high_expense: number; //'高額療養費作成フラグ',
  insurer_no: string; //'保険者番号',
  insurer_name: string; //'保険者名',
  insured_no: string; //'記号・番号（医療伝送に伴い非推奨 Deprecated）',
  insured_sign: string; //'記号（医療伝送対応）',
  insured_number: string; //'番号（医療伝送対応）',
  insured_branch: string; //'枝番',
  first_issue_date: string; //'資格取得年月日',
  payment_rate: number; //'給付割合（%）',
  occupational_reason: number; //'職務上の事由\n0:なし\n1:職上(職務上)\n2:下3(下船後3ヶ月以内)\n3:通災(通勤災害)',
  honobono_link_id: number; //ほのぼの連携ID
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
  first_issue_date_wareki: string; //資格取得年月日(和暦)
}

export const DefaultMedicalInsurance = (): MedicalInsurance => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  insurance_type: 0,
  insured_div: 0,
  is_old_age_certificate: 0,
  is_pay_per20: 0,
  is_exempt: 0,
  is_exception10: 0,
  is_make_high_expense: 0,
  insurer_no: "",
  insurer_name: "",
  insured_no: "",
  insured_sign: "",
  insured_number: "",
  insured_branch: "",
  first_issue_date: "",
  payment_rate: 0,
  occupational_reason: 0,
  honobono_link_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: "",
  first_issue_date_wareki: ""
});
