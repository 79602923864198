import { render, staticRenderFns } from "./topicTitle.vue?vue&type=template&id=550a184c&scoped=true&"
import script from "./topicTitle.vue?vue&type=script&lang=ts&"
export * from "./topicTitle.vue?vue&type=script&lang=ts&"
import style0 from "./topicTitle.vue?vue&type=style&index=0&id=550a184c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "550a184c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardTitle,VForm,VRow})
