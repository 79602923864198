





































import { Component, Vue } from "vue-property-decorator";

@Component
export default class MovieContents extends Vue {
  private dispMovieTitle = "";
  private dispMovieSrc = "";
  private dispMovie = false;
  private movies = [
    {
      title: "はじめてのiBow",
      src: "https://www.youtube.com/embed/ZQju3qSEuDA"
    },
    {
      title: "事業所情報登録",
      src: "https://www.youtube.com/embed/jikTYVDDLkQ"
    },
    {
      title: "利用者情報①（新規受付、基礎情報）",
      src: "https://www.youtube.com/embed/NEKbx23JW08"
    },
    {
      title: "利用者情報②（保険情報、指示書情報）",
      src: "https://www.youtube.com/embed/ZVL1Jlcdg1U"
    },
    {
      title: "訪問へ行く",
      src: "https://www.youtube.com/embed/vpRrUCLVrZU"
    },
    {
      title: "看護記録",
      src: "https://www.youtube.com/embed/I9wxM_dOMUs"
    },
    {
      title: "レセプト処理",
      src: "https://www.youtube.com/embed/S1FhWAG01hY"
    },
    {
      title: "ダッシュボード",
      src: "https://www.youtube.com/embed/hSTxPKsBUPg"
    },
    {
      title: "よくある質問",
      src: "https://www.youtube.com/embed/ItdIkhEBO5c"
    }
  ];

  private get Height(): string {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "300px";
    }
    return "500px";
  }

  private get Cols(): number {
    if (this.$vuetify.breakpoint.width > 2300) {
      return 2;
    } else if (this.$vuetify.breakpoint.width > 1500) {
      return 3;
    } else if (this.$vuetify.breakpoint.mdAndUp) {
      return 4;
    } else if (this.$vuetify.breakpoint.width < 500) {
      return 12;
    } else if (this.$vuetify.breakpoint.smAndDown) {
      return 6;
    }
    return 4;
  }

  private openMovie(type: number) {
    // 選択した動画のダイアログを表示
    this.dispMovieSrc = this.movies[type].src;
    this.dispMovieTitle = this.movies[type].title;
    this.dispMovie = true;
  }

  private closeMovie() {
    this.dispMovieSrc = "";
    this.dispMovieTitle = "";
    this.dispMovie = false;
  }
}
