












































































import { Component, Prop, Emit, Mixins, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import { RelationInstitution, CareplanOffice } from "../careplan";

const InputMode = {
  plan: 1,
  schedule: 2,
  delete: 3
} as const;

@Component
export default class CareplanInput extends Mixins(RulesMixin, UtilMixin) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: () => [] }) institutions!: RelationInstitution[];
  @Prop({ default: () => [] }) offices!: CareplanOffice[];

  private officeId = 0;
  private institutionId = 0;
  private deleteFile: File | null = null;
  private planFiles: File[] = [];
  private scheduleFiles: File[] = [];
  private isDragAndDropMode = false;
  private inputMode = 2;
  private inputModes = [
    {
      value: InputMode.schedule,
      text: "サービス利用票"
    },
    {
      value: InputMode.plan,
      text: "居宅サービス計画書"
    },
    {
      value: InputMode.delete,
      text: "削除ファイル"
    }
  ];

  private get officeChoices() {
    return this.offices
      .filter(office => {
        return !office.isSatelite;
      })
      .map(office => {
        return {
          text: office.name,
          value: office.id
        };
      });
  }

  private get isPlanInputMode() {
    return this.inputMode === InputMode.plan;
  }

  private get isScheduleInputMode() {
    return this.inputMode === InputMode.schedule;
  }

  private get isDeleteFileInputMode() {
    return this.inputMode === InputMode.delete;
  }

  private get fileTruncateLength() {
    if (this.$vuetify.breakpoint.xs) {
      return 22;
    }

    if (
      this.$vuetify.breakpoint.sm ||
      this.$vuetify.breakpoint.md ||
      this.$vuetify.breakpoint.lg ||
      this.$vuetify.breakpoint.xl
    ) {
      return 66;
    }

    return 22;
  }

  private get institutionChoices() {
    return this.institutions.map(institution => {
      return {
        value: institution.relation_institution_id,
        text: institution.name + " / " + institution.office_code
      };
    });
  }

  private created() {
    if (this.loginUser.parent_office_id > 0) {
      // 主たる事業所のみ表示するので初期値は主たる事業所にする
      this.officeId = this.loginUser.parent_office_id;
    } else {
      // 未所属は未選択
    }
  }

  private dragEnter() {
    this.isDragAndDropMode = true;
  }

  private dropFile(event: DragEvent) {
    this.isDragAndDropMode = false;
    if (event.dataTransfer) {
      if (this.isPlanInputMode) {
        this.planFiles = [...event.dataTransfer.files];
      } else if (this.isScheduleInputMode) {
        this.scheduleFiles = [...event.dataTransfer.files];
      } else if (this.isDeleteFileInputMode) {
        if (event.dataTransfer.files.item(0)) {
          this.deleteFile = event.dataTransfer.files.item(0);
        }
      }
    }
  }

  private closeDragAndDropArea() {
    this.isDragAndDropMode = false;
  }

  private inputCSV() {
    if (!this.form.validate()) {
      return;
    }

    let errMsg = "";
    switch (this.inputMode) {
      case InputMode.plan:
        errMsg = this.validatePlanFiles();
        if (errMsg === "") {
          this.inputPlan();
        } else {
          this.$openAlert(errMsg);
        }

        break;

      case InputMode.schedule:
        errMsg = this.validateScheduleFiles();
        if (errMsg === "") {
          this.inputSchedule();
        } else {
          this.$openAlert(errMsg);
        }
        break;

      case InputMode.delete: {
        errMsg = this.validateDeleteFile();
        if (errMsg === "") {
          const fileName = this.deleteFile?.name ?? "";
          if (fileName.startsWith("DLT1KYO")) {
            this.inputDeletePlan();
          } else if (fileName.startsWith("DLTPLAN")) {
            this.inputDeleteSchedule();
          }
        } else {
          this.$openAlert(errMsg);
        }

        break;
      }

      default:
        // validate がOKの場合ここを通ることはない
        break;
    }
  }

  @Emit("input:plan")
  private inputPlan() {
    const hosoku = this.planFiles.find(file => {
      return file.name.startsWith("UPHOSOKU");
    });
    const up1kyo = this.planFiles.find(file => {
      return file.name.startsWith("UP1KYO");
    });
    const up2kyo = this.planFiles.find(file => {
      return file.name.startsWith("UP2KYO");
    });
    return {
      officeId: this.officeId,
      institutionId: this.institutionId,
      hosokuCSV: hosoku,
      up1kyoCSV: up1kyo,
      up2kyoCSV: up2kyo
    };
  }

  @Emit("input:schedule")
  private inputSchedule() {
    const hosoku = this.scheduleFiles.find(file => {
      return file.name.startsWith("UPHOSOKU");
    });
    const upPlan = this.scheduleFiles.find(file => {
      return file.name.startsWith("UPPLAN");
    });
    const upSikyu = this.scheduleFiles.find(file => {
      return file.name.startsWith("UPSIKYU");
    });
    return {
      officeId: this.officeId,
      institutionId: this.institutionId,
      hosokuCSV: hosoku,
      upplanCSV: upPlan,
      upsikyuCSV: upSikyu
    };
  }

  @Emit("input:delete-plan")
  private inputDeletePlan() {
    return {
      officeId: this.officeId,
      institutionId: this.institutionId,
      deleteCSV: this.deleteFile
    };
  }

  @Emit("input:delete-schedule")
  private inputDeleteSchedule() {
    return {
      officeId: this.officeId,
      institutionId: this.institutionId,
      deleteCSV: this.deleteFile
    };
  }

  private validatePlanFiles() {
    let message = "";

    const hosoku = this.planFiles.find(file => {
      return file.name.startsWith("UPHOSOKU");
    });
    if (hosoku) {
      if (!this.validatePlanHosoku(hosoku.name)) {
        message += "利用者補足情報のファイル名が正しくありません\n";
      }
    } else {
      message += "利用者補足情報のファイルが選択されていません\n";
    }

    const up1kyo = this.planFiles.find(file => {
      return file.name.startsWith("UP1KYO");
    });
    if (up1kyo) {
      if (!this.validateUp1kyo(up1kyo.name)) {
        message += "居宅サービス計画書1表のファイル名が正しくありません\n";
      }
    } else {
      message += "居宅サービス計画書1表のファイルが選択されていません\n";
    }

    const up2kyo = this.planFiles.find(file => {
      return file.name.startsWith("UP2KYO");
    });
    if (up2kyo) {
      if (!this.validateUp2kyo(up2kyo.name)) {
        message += "居宅サービス計画書2表のファイル名が正しくありません\n";
      }
    } else {
      message += "居宅サービス計画書2表のファイルが選択されていません\n";
    }

    return message;
  }

  private validateScheduleFiles() {
    let message = "";

    const hosoku = this.scheduleFiles.find(file => {
      return file.name.startsWith("UPHOSOKU");
    });
    if (hosoku) {
      if (!this.validateScheduleHosoku(hosoku.name)) {
        message += "利用者補足情報のファイル名が正しくありません\n";
      }
    } else {
      message += "利用者補足情報のファイルが選択されていません\n";
    }

    const upPlan = this.scheduleFiles.find(file => {
      return file.name.startsWith("UPPLAN");
    });
    if (upPlan) {
      if (!this.validateUpplan(upPlan.name)) {
        message += "サービス利用票のファイル名が正しくありません\n";
      }
    } else {
      message += "サービス利用票のファイルが選択されていません\n";
    }

    const upSikyu = this.scheduleFiles.find(file => {
      return file.name.startsWith("UPSIKYU");
    });
    if (upSikyu) {
      if (!this.validateUpsikyu(upSikyu.name)) {
        message +=
          "サービス利用票別表のファイル名が正しくありません\n";
      }
    } else {
      message += "サービス利用票別表のファイルが選択されていません\n";
    }

    return message;
  }

  private validatePlanHosoku(fileName: string) {
    const regex = /^UPHOSOKU_[0]{6}_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    return regex.test(fileName);
  }

  private validateUp1kyo(fileName: string) {
    const regex = /^UP1KYO_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    return regex.test(fileName);
  }
  private validateUp2kyo(fileName: string) {
    const regex = /^UP2KYO_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    return regex.test(fileName);
  }

  private validateScheduleHosoku(fileName: string) {
    const regex = /^UPHOSOKU_[\d]{6}_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    return regex.test(fileName);
  }

  private validateUpplan(fileName: string) {
    const regex = /^UPPLAN_[\d]{6}_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    return regex.test(fileName);
  }

  private validateUpsikyu(fileName: string) {
    const regex = /^UPSIKYU_[\d]{6}_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    return regex.test(fileName);
  }

  private validateDeleteFile() {
    if (!this.deleteFile) {
      return "削除用ファイルが見つかりません";
    }

    const regex1KYO = /^DLT1KYO_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    const regexPLAN = /^DLTPLAN_[\d]{6}_[\d]{10}_[\d]{10}_[\d]{14}.csv$/i;
    const isMatchedPLAN = regexPLAN.test(this.deleteFile.name);
    const isMatched1KYO = regex1KYO.test(this.deleteFile.name);

    if (isMatchedPLAN || isMatched1KYO) {
      return "";
    }

    return "削除用ファイルのファイル名が正しくありません";
  }
}
