








































import { Component, Prop, Vue } from "vue-property-decorator";
import { RelationInstitution } from "#/model/uniquemaster";
interface RelationInstitutionItem extends RelationInstitution {
  office_names: string;
}
@Component
export default class CorpList extends Vue {
  private headers = [
    {
      text: "関連機関",
      align: "start",
      value: "name"
    },
    {
      text: "利用事業所",
      value: "office_names"
    },
    { text: "", value: "actions", sortable: false, align: "end" }
  ];

  @Prop({ default: "" }) search!: string;

  @Prop({ type: Array, default: () => [] }) companies!: unknown[];

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;
  /** 表の現在のページ数 */
  private pageNo = 1;

  // 編集
  private clickEdit(id: number) {
    this.$router.push({
      name: "CorpEdit",
      params: {
        id: String(id)
      }
    });
  }

  /** 表のページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  //絞り込み
  private filterItems(
    value: unknown,
    search: string,
    item: RelationInstitutionItem
  ) {
    if (search == null) {
      return false;
    }

    if (item == null) {
      return false;
    }
    if (
      item.name.match(search) ||
      item.furigana.match(search) ||
      item.office_names.match(search)
    ) {
      return true;
    }

    return false;
  }
}
