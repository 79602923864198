// 利用者定数

/** 保険区分：医療 */
export const INSURANCE_DIV_MEDICAL = 1;
/** 保険区分：介護 */
export const INSURANCE_DIV_CARE = 2;

/** 一般・精神区分 1:訪問看護 */
export const MENTAL_DIV_NORMAL = 1;
/** 一般・精神区分 2:精神訪問看護（医療保険のみ） */
export const MENTAL_DIV_MENTAL = 2;

/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 0:定期巡回以外 */
export const REGULAR_VISIT_DIV_NOTREGULAR = 0;
/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 1:定期・随時 */
export const REGULAR_VISIT_DIV_REGULAR_OR_ANYTIME = 1;
/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 2:定期 */
export const REGULAR_VISIT_DIV_REGULAR = 2;
/** 定期巡回訪問タイプ（定期巡回は介護保険のみ） 3:随時 */
export const REGULAR_VISIT_DIV_ANYTIME = 3;

/** 訪問場所区分（訪問した場所）自宅 */
export const VISIT_PLACE_DIV_HOME = 1;
/** 訪問場所区分（訪問した場所）施設 */
export const VISIT_PLACE_DIV_FACILITY = 2;
/** 訪問場所区分（訪問した場所）その他 */
export const VISIT_PLACE_DIV_OTHER = 3;

/** 保険種類：1:社保 */
export const INSURANCE_TYPE_SOCIAL = 1;
/** 保険種類：2:国保 */
export const INSURANCE_TYPE_NATIONAL = 2;
/** 保険種類：3:公費単独 */
export const INSURANCE_TYPE_PUBLIC = 3;
/** 保険種類：4:後期 */
export const INSURANCE_TYPE_LATE = 4;
/** 保険種類：5:退職者 */
export const INSURANCE_TYPE_RETIRE = 5;

/** 被保険者区分：1:本人 */
export const INSURED_DIV_SELF = 1;
/** 被保険者区分：2:家族 */
export const INSURED_DIV_FAMILY = 2;
/** 被保険者区分：3:6歳 */
export const INSURED_DIV_SIX = 3;
/** 被保険者区分：4:高齢一 */
export const INSURED_DIV_OLD = 4;
/** 被保険者区分：5:高齢7 */
export const INSURED_DIV_OLDSEVEN = 5;

/** 高額療養費の追加元区分 0:新規作成 */
export const HIGH_EXPENSE_ADD_DIV_SELF = 0;
/** 高額療養費の追加元区分 1:限度額適用認定証 */
export const HIGH_EXPENSE_ADD_DIV_LIMIT_CERT = 1;
/** 高額療養費の追加元区分 2:医療保険(兼高齢) */
export const HIGH_EXPENSE_ADD_DIV_MED_INSURANCE = 2;
/** 高額療養費の追加元区分 3:高齢受給者証 */
export const HIGH_EXPENSE_ADD_DIV_OLD_AGE = 3;
