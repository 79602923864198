import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:介護保険情報 */
export const headerCareInsurance: StatisticHeader = {
  id: STATISTIC_COL_ID.CARE_INSURANCE,
  text: "介護保険情報",
  is_checked: false,
  value: [
    {
      text: "保険者番号（介護保険）",
      select_name: "保険者番号",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.insurer_no`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "保険者名（介護保険）",
      select_name: "保険者名",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.insurer_name`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "被保険者番号（介護保険）",
      select_name: "被保険者番号",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.insured_no`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "給付率（介護保険）",
      select_name: "給付率",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.payment_rate`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.NUMBER
    },
    {
      text: "申請状態（介護保険）",
      select_name: "申請状態",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.str_application_state`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "開始日（介護保険）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.start_date`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（介護保険）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.end_date`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "居宅支援事業所（介護保険）",
      select_name: "居宅支援事業所",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.relation_institution_name`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "ふりがな（介護保険）",
      select_name: "居宅支援事業所ふりがな",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.relation_institution_name_kana`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "郵便番号（介護保険・居宅支援事業所）",
      select_name: "郵便番号",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.post_code`,
      sortable: true,
      align: "start",
      width: "320px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "住所（介護保険・居宅支援事業所）",
      select_name: "住所",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.care_insurance_address`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "電話番号（介護保険・居宅支援事業所）",
      select_name: "電話番号",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.relation_institution_tel_no`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "FAX（介護保険・居宅支援事業所）",
      select_name: "FAX",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.relation_institution_fax_no`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "ケアマネ（介護保険）",
      select_name: "ケアマネ",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.name`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "ふりがな（介護保険）",
      select_name: "ケアマネふりがな",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.furigana`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "連絡先1（介護保険・ケアマネ）",
      select_name: "連絡先1",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.contact1`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "連絡先2（介護保険・ケアマネ）",
      select_name: "連絡先2",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.contact2`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "連絡先3（介護保険・ケアマネ）",
      select_name: "連絡先3",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.plan_writer_personnel.contact3`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "要介護度（介護保険）",
      select_name: "要介護度",
      value: `${STATISTIC_COL_ID.CARE_INSURANCE}.str_need_care_level`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    }
  ]
};
