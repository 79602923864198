var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"AppChatBubble",staticClass:"d-flex flex-column"},[(_vm.sectionHeader)?_c('div',{staticClass:"mx-auto mb-3 px-3 py-1 text-caption grey lighten-1 white--text rounded-pill font-weight-bold"},[_vm._v(" "+_vm._s(_vm.sectionHeader)+" ")]):_vm._e(),(_vm.deleteFlg)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"rounded-xl",attrs:{"outlined":true,"color":"#E0E0E0"}},[_c('p',{staticClass:"app-chat-bubble-text-chat-text my-2 mx-3"},[_vm._v(" "+_vm._s(_vm.isOwn ? "メッセージの送信を取り消しました" : (_vm.name + "さんがメッセージの送信を取り消しました"))+" ")])])],1):_c('div',{staticClass:"overflow-x-hidden",class:[_vm.isOwn ? 'flex-row-reverse' : 'flex-row', 'd-flex']},[(_vm.isSystemChat)?_c('AppChatSystemIcon',{staticClass:"mr-1",attrs:{"fileType":_vm.fileType}}):(!_vm.isOwn)?_c('AppIconOrInitials',{staticClass:"mr-1",attrs:{"iconPath":_vm.accountImagePath(_vm.accountId),"name":_vm.name,"size":"32","color":"grey lighten-5"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[(!_vm.isOwn)?_c('p',{staticClass:"app-chat-bubble-name mt-0 mb-1 mx-1 font-weight-bold text-left"},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex",class:[_vm.isOwn ? 'flex-row-reverse' : 'flex-row']},[_c('div',[(_vm.isTextChat)?_c('div',{staticClass:"d-flex",class:[_vm.isOwn ? 'flex-row' : 'flex-row-reverse']},[_c('div',{staticClass:"flex-grow-1"}),_c('v-card',{staticClass:"rounded-xl",class:[
                _vm.isOwn
                  ? 'app-chat-bubble-text-chat-own green lighten-4'
                  : 'app-chat-bubble-text-chat teal darken-4 white--text'
              ],attrs:{"outlined":true}},[_c('p',{staticClass:"app-chat-bubble-text-chat-text my-2 mx-3",domProps:{"innerHTML":_vm._s(_vm.sanitize(_vm.chatText))}})])],1):(_vm.isStampChat)?_c('v-img',{attrs:{"width":"120px","height":"120px","aspect-ratio":"1","contain":"","src":_vm.stampPath}}):(_vm.isImageChat)?_c('div',{staticClass:"app-chat-bubble-image-chat rounded-xl d-flex",on:{"click":function($event){return _vm.openImageTab(_vm.signImagePath)}}},[_c('v-img',{class:[
                _vm.isOwn
                  ? 'app-chat-bubble-image-chat-image-own'
                  : 'app-chat-bubble-image-chat-image'
              ],attrs:{"src":_vm.signImagePath,"contain":""}})],1):_vm._e()],1),_c('div',{staticClass:"app-chat-bubble-chat-info d-flex flex-column-reverse mx-1",class:[_vm.isOwn ? 'text-right' : 'text-left']},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(_vm.time))]),(_vm.isOwn && _vm.readCount > 0)?_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s("既読 " + _vm.readCount)+" ")]):_vm._e()]),_c('div',{staticClass:"app-chat-bubble-chat-info d-flex flex-column-reverse mx-1"},[(_vm.isOwn && _vm.dropChatModeFlg && !_vm.deleteFlg)?_c('v-btn',{staticClass:"my-0",attrs:{"icon":"","height":"32px","width":"32px"},on:{"click":function($event){return _vm.dropChat()}}},[_c('AppIcon',{attrs:{"icon":"chat-remove"}})],1):_vm._e()],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }