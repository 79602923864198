












import { Component, Prop, Emit } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class VisitContentDiv extends CarePartBase {
  @Prop({ default: 1 }) protected readonly visit_content_div!: number;

  @Emit("update:visit_content_div") protected updateVisitContentDiv(
    newValue: number
  ) {
    return newValue;
  }

  protected get VisitContentDiv() {
    return this.visit_content_div;
  }

  protected set VisitContentDiv(newValue: number) {
    this.updateVisitContentDiv(newValue);
  }
}
