





























import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

import CareStationList from "@/components/master/carestation/CareStationList.vue";
import { CareStation } from "./types";

@Component({
  components: {
    CareStationList
  }
})
export default class CorpEntry extends Mixins(AxiosMixin) {
  private search = "";

  public carestations: CareStation[] = [];

  public created() {
    this.fetchCareStations();
  }

  private fetchCareStations(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/carestation/get",
      {},
      res => {
        this.carestations = res.data.carestations;
      }
    );
  }
}
