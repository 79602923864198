






import { Component, Vue } from "vue-property-decorator";
import CareStationForm from "@/components/master/carestation/CareStationForm.vue";
@Component({
  components: { CareStationForm }
})
export default class CareStationEdit extends Vue {}
