














































































































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  AdlItem,
  AdlMasterItem,
  Vnrecord,
  VnrecordDefaultValues
} from "./types";

@Component
export default class Adl extends Vue {
  @Prop({ default: () => VnrecordDefaultValues() })
  private vnrecord!: Vnrecord;
  @Prop({ default: () => [] }) private adl_master_items!: AdlMasterItem[];
  @Prop() private readonly previewLimitPerLine!: number; // 心身の状態プレビューの文字数制約

  private changeNoSupport(item: AdlItem) {
    if (item.support == 2) {
      item.assistance = 3;
    }
  }

  private get adl_items() {
    return this.vnrecord.adls;
  }

  private get AdlItems() {
    return this.adl_master_items.filter(
      item => item.id !== 11 && item.id !== 12
    );
  }

  private get Bedridden() {
    return (
      this.adl_master_items.find(item => item.id == 11) ?? {
        id: 0,
        name: "寝たきり度",
        supports: [],
        assistances: []
      }
    );
  }

  private get Dementia() {
    return (
      this.adl_master_items.find(item => item.id == 12) ?? {
        id: 0,
        name: "認知症の状況",
        supports: [],
        assistances: []
      }
    );
  }

  private get BedriddenItems() {
    return [
      { value: 0, text: "未選択" },
      { value: 1, text: "J1" },
      { value: 2, text: "J2" },
      { value: 3, text: "A1" },
      { value: 4, text: "A2" },
      { value: 5, text: "B1" },
      { value: 6, text: "B2" },
      { value: 7, text: "C1" },
      { value: 8, text: "C2" }
    ];
  }

  private get DementiaItems() {
    return [
      { value: 0, text: "未選択" },
      { value: 1, text: "Ⅰ" },
      { value: 2, text: "Ⅱa" },
      { value: 3, text: "Ⅱb" },
      { value: 4, text: "Ⅲa" },
      { value: 5, text: "Ⅲb" },
      { value: 6, text: "Ⅳ" },
      { value: 7, text: "M" }
    ];
  }

  //-----------------------------------------------------
  // 心身の状態プレビューへ反映
  //-----------------------------------------------------
  private get mindAndBodyState(): string {
    let mindAndBodyState = "";
    //ADL、日常生活自立度：不問にチェックがあるまたは評価が未選択の場合は反映しない
    for (const adlItem of this.adl_items) {
      if (adlItem.check != 1) {
        if (adlItem.vn_record_adl_item_id == 11 && adlItem.support != 0) {
          //寝たきり度
          mindAndBodyState +=
            this.Bedridden.name +
            ":" +
            this.BedriddenItems.find(item => item.value == adlItem.support)
              ?.text +
            "。";
        } else if (
          adlItem.vn_record_adl_item_id == 12 &&
          adlItem.support != 0
        ) {
          //認知症の状況
          mindAndBodyState +=
            this.Dementia.name +
            ":" +
            this.DementiaItems.find(item => item.value == adlItem.support)
              ?.text +
            "。";
        } else if (adlItem.assistance != 0) {
          //ADL
          const adlMasterItem = this.AdlItems.find(
            item => item.id == adlItem.vn_record_adl_item_id
          );
          mindAndBodyState +=
            adlMasterItem?.name +
            ":" +
            adlMasterItem?.assistances.find(
              item => item.value == adlItem.assistance
            )?.text +
            "。";
        }
      }
    }
    //コメント：チェック付きを心身の状態プレビューに反映する
    if (mindAndBodyState != "") {
      mindAndBodyState += "\n";
    }
    if (this.vnrecord.is_comment_to_state == 1) {
      mindAndBodyState += this.vnrecord.comment;
    }

    this.vnrecord.mind_and_body_state = mindAndBodyState;
    return mindAndBodyState;
  }

  private get IsReadOnly(): boolean {
    return this.mindAndBodyState.length > this.previewLimitPerLine;
  }

  private get PreviewErrorMessage(): string {
    if (this.IsReadOnly) {
      return this.Message;
    }
    return "";
  }

  private get Message(): string {
    return this.previewLimitPerLine + "文字までが療養費明細書に反映されます";
  }
}
