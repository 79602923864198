<template>
  <div>
    <!-- v-date-pickerはyearだけ取得することはできないためv-selectで対応-->
    <v-select
      v-model="date"
      style="max-width: 140px"
      v-bind="$attrs"
      v-on="$listeners"
      :label="label"
      :items="years"
      @input="handleSelectedYearChange"
    ></v-select>
  </div>
</template>

<script>
import * as appDate from "#/utility/appDate";
export default {
  props: {
    value: Number,
    label: {
      type: String,
      default: "Select Year" // デフォルトのラベル
    }
  },
  computed: {
    date: {
      get() {
        return Number(this.value);
      },
      set(newValue) {
        this.selectedYear = newValue;
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {
      selectedYear: null,
      years: [],
    };
  },
  mounted() {
    //2024から100年後まで表示する
    const targetYear = new Date("2024-01-01").getFullYear();
    const nextYear = new Date().getFullYear() + 100;
    const yearDifference = nextYear - targetYear;
    this.years = Array.from({ length: yearDifference + 1 }, (_, i) => ({
      text: (appDate.toWarekiDatetime(targetYear + i + "-01", false, false)).slice(0, -3)+"度"+"("+(targetYear + i).toString()+")",
      value: targetYear + i,
    }));
  },
  methods: {
    handleSelectedYearChange() {
      // 'selectedYear' イベントを発行して、親コンポーネントに値を通知
      this.$emit("selectedYearChange", this.selectedYear);
    },
  },
};
</script>
