import AppChatSelectedStaff from "./AppChatSelectedStaff.vue";

export default {
  title: "Example/AppChatSelectedStaff",
  component: AppChatSelectedStaff,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatSelectedStaff },
  template: '<AppChatSelectedStaff v-bind="$props" />'
});

export const SelectedStaff = Template.bind({});

SelectedStaff.args = {
  staffName: "てすとたろう",
  iconPath:
    "https://4.bp.blogspot.com/-7DLdBODmEqc/VCIitQRzAWI/AAAAAAAAmeY/g1fjm8NqyaI/s800/animal_arupaka.png"
};
