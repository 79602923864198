





























































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { Choice, VForm } from "@/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import AdlSelectItem from "@/components/patient/adl/AdlSelectItem.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import UtilMixin from "@/mixins/utilMixin";
import { Adl, DefaultAdl, DefaultAdlItem } from "#/model/adl";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";

interface AdlEvaluationItem {
  text: string;
  value: number;
  adl_evaluation_select_items: Choice[];
}

@Component({
  components: {
    PatientHeader,
    PatientSaveArea,
    AdlSelectItem
  }
})
export default class AdlCreate extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private adlId = 0;
  private patientId = 0;
  private isLoaded = false;
  private previewLimitPerLine = 250;
  private adl: Adl = DefaultAdl(); //ADL
  private lastTimeAdlFlg = 0; //前回提出のADLがあったかどうか(前3回評価：表示用)
  private evaluationStaffs: Choice[] = []; //評価者
  private adlEvaluationItems: AdlEvaluationItem[] = []; //ADL評価項目

  //評価日をリアルタイムの今日にする
  private today() {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const today = date.toISOString().substr(0, 10);
    this.adl.evaluation_date = today;
  }

  created() {
    this.adlId = Number(this.$route.params.adlid);
    this.patientId = Number(this.$route.params.id);
    this.fetchAdl();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/adl`,
        exact: true
      },
      {
        text: "ADL評価を作成する",
        disabled: true,
        to: ""
      }
    ]);
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //保存
  private save() {
    this.setIsSave(true);
    this.validateAfterExec(() => {
      this.saveAction(1, "/api/patient/adl/save");
    });
  }

  //提出
  private async submission(isPreview = false) {
    this.validateAfterExec(() => {
      this.saveAction(2, "/api/patient/adl/submission", isPreview);
    });
  }

  private saveAction(status: number, url: string, isPreview = false) {
    this.adl.patient_id = this.patientId;
    this.adl.status = status;
    this.adl.mind_and_body_state = this.mindAndBodyState.slice(0, 250);
    this.postJsonCheck(
      window.base_url + url,
      {
        adl: this.adl,
        patient_id: this.patientId
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }
        if (isPreview) {
          this.preview(true).then(() => {
            this.setNoEditMode();
            this.cancel();
          });
        } else {
          this.setNoEditMode();
          this.cancel();
        }
      }
    );
  }

  //削除
  private clickDelete() {
    this.adl.id = this.adlId;
    this.adl.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/adl/delete",
      {
        adl: this.adl
      },
      () => {
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //プレビュー
  private preview(isEdit = true) {
    return new Promise(resolve => {
      this.adl.id = this.adlId;
      this.adl.patient_id = this.patientId;
      const reqObj = isEdit
        ? { adl: this.adl }
        : { patient_id: this.adl.patient_id };
      this.setIsSave(true);
      this.validateAfterExec(() => {
        this.makePdf(
          window.base_url + "/api/patient/adl/preview",
          reqObj,
          () => {
            return resolve(true);
          }
        );
      });
    });
  }

  /** Validation */
  private validateAfterExec(exec: Function) {
    this.previewLimitPerLine = 1000000000;
    this.$nextTick(async () => {
      const ok = this.patientHeader.validate();
      this.setIsSave(false);
      this.previewLimitPerLine = 250;
      if (!ok) {
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      exec();
    });
  }

  //Adl取得
  private fetchAdl(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/adl/get",
      {
        patient_id: this.patientId,
        adl_id: this.adlId
      },
      res => {
        this.lastTimeAdlFlg = res.data.last_time_adl_flg;
        this.evaluationStaffs = res.data.evaluation_staffs;
        this.adlEvaluationItems = res.data.adl_evaluation_items;
        this.adl = res.data.adl;
        this.setLoaded();
        if (this.adlId == 0) {
          this.today();
        }
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      }
    );
  }

  //-----------------------------------------------------
  // ADL関連
  //-----------------------------------------------------
  //ADL追加
  private addAdl() {
    this.adl.items.push(DefaultAdlItem());
  }
  //ADL削除
  private async deleteAdl(idx: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.adl.items.splice(idx, 1);
    }
  }

  //-----------------------------------------------------
  // 心身の状態プレビューへ反映
  //-----------------------------------------------------
  private get mindAndBodyState(): string {
    let mindAndBodyState = "";
    //ADL：チェック付きを心身の状態プレビューに反映する
    for (const item of this.adl.items) {
      if (item.is_refer == 1) {
        for (const evaluationItem of this.adlEvaluationItems) {
          if (
            evaluationItem.value == item.adl_evaluation_item_id &&
            evaluationItem.value != 0
          ) {
            mindAndBodyState += evaluationItem.text + ":";
          }
          for (const selectItem of evaluationItem.adl_evaluation_select_items) {
            if (
              selectItem.value == item.adl_evaluation_select_item_id &&
              selectItem.value != 0
            ) {
              mindAndBodyState += selectItem.text + "。";
            }
          }
        }
      }
    }
    //コメント：チェック付きを心身の状態プレビューに反映する
    if (mindAndBodyState != "") {
      mindAndBodyState += "\n";
    }
    if (this.adl.is_comment_to_state == 1) {
      mindAndBodyState += this.adl.comment;
    }
    return mindAndBodyState;
  }

  private get IsReadOnly(): boolean {
    return this.mindAndBodyState.length > 250;
  }

  private get PreviewErrorMessage(): string {
    if (this.IsReadOnly) {
      return "250文字までが療養費明細書に反映されます";
    }
    return "";
  }

  //-----------------------------------------------------
  // ADL項目重複時エラー
  //-----------------------------------------------------
  private checkAdl(adl_evaluation_item_id: number): boolean | string {
    let counter = 0;
    for (const item of this.adl.items) {
      if (adl_evaluation_item_id == item.adl_evaluation_item_id) {
        counter += 1;
      }
      if (counter == 2) {
        return "ADL項目が重複しています";
      }
    }
    return true;
  }

  //編集モード設定
  @Watch("adl", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
