// ファイル
export interface FileIF {
  id: number; //ID
  path: string; //'パス',
}
export const DefaultFileIF = (): FileIF => ({
  id: 0,
  path: ""
});

//項目状態
export interface State {
  value: string; //項目
  state: number; //項目状態\n1:既存\n2:差分あり\n3:エラー
}
export const DefaultState = (): State => ({
  value: "",
  state: 0
});

//利用者請求情報
export interface PatientInvoiceData {
  id: number; //行番号をそのまま使用
  patient_state: number; //利用者請求情報状態\n1:支払方法設定済（差分項目ある場合あり）\n2:支払方法未設定\n3:エラー\n4:差分項目あり
  row_no: string; //行番号
  patient_id: string; //利用者システムID,
  display_id: State; //ユーザーID,
  last_name: State; //氏名(姓),
  first_name: State; //氏名(名),
  last_name_kana: State; //ふりがな(姓),
  first_name_kana: State; //ふりがな(名),
  sex: State; //性別,
  pay_kbn: State; //支払方法,
  withdrawal_st_kbn: State; //引落開始設定,
  financial_institution_kbn: State; //銀行選択,
  yucho_branch_no: State; //支店コード(通帳記号)(ゆうちょ),
  yucho_account_no: State; //口座番号(通帳番号)(ゆうちょ),
  yucho_depositor: State; //預金者名(ゆうちょ),
  institution_no: State; //振替金融機関コード(ゆうちょ銀行以外の金融機関),
  institution_name: State; //振替金融機関名(ゆうちょ銀行以外の金融機関),
  branch_no: State; //支店コード(通帳記号)(ゆうちょ銀行以外の金融機関),
  branch_name: State; //支店名(ゆうちょ銀行以外の金融機関),
  deposit_kbn: State; //預金種目(ゆうちょ銀行以外の金融機関),
  account_no: State; //口座番号(通帳番号)(ゆうちょ銀行以外の金融機関),
  depositor: State; //預金者名(ゆうちょ銀行以外の金融機関),
  success_or_failure: boolean; //成否\true:成功\false:失敗
}
export const DefaultPatientInvoiceData = (): PatientInvoiceData => ({
  id: 0,
  patient_state: 0,
  row_no: "",
  patient_id: "",
  display_id: DefaultState(),
  last_name: DefaultState(),
  first_name: DefaultState(),
  last_name_kana: DefaultState(),
  first_name_kana: DefaultState(),
  sex: DefaultState(),
  pay_kbn: DefaultState(),
  withdrawal_st_kbn: DefaultState(),
  financial_institution_kbn: DefaultState(),
  yucho_branch_no: DefaultState(),
  yucho_account_no: DefaultState(),
  yucho_depositor: DefaultState(),
  institution_no: DefaultState(),
  institution_name: DefaultState(),
  branch_no: DefaultState(),
  branch_name: DefaultState(),
  deposit_kbn: DefaultState(),
  account_no: DefaultState(),
  depositor: DefaultState(),
  success_or_failure: true
});
