







import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
@Component
export default class AppIconOrInitials extends Vue {
  @Prop() iconPath?: string;
  @Prop() name!: string;
  @Prop({ default: "40" }) size?: string;
  @Prop({ default: "grey lighten-5" }) color?: string;
  private get Initials() {
    return this.name.slice(0, 1);
  }
}
