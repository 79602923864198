












import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";

interface Selfburden {
  id: number;
  regist: number;
  category_name: string;
  item_name: string;
  unit_price: number;
  quantity: number;
  amount: number;
  tax_div: string;
  medical_deducation: string;
  invoice_date: string;
  visit_date: string;
  remarks: string;
}

@Component({
  components: {
    ItemTable
  }
})
export default class SelfBurden extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 自費 */
  @Prop({ default: () => [] }) private readonly items!: Selfburden[];

  /** 自費ヘッダー */
  private selfburdenHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    {
      text: "カテゴリ名",
      value: "category_name",
      width: "150px"
    },
    {
      text: "アイテム名",
      value: "item_name",
      width: "150px"
    },
    {
      text: "単価",
      value: "unit_price",
      width: "70px"
    },
    {
      text: "数量",
      value: "quantity",
      width: "70px"
    },
    {
      text: "金額",
      value: "amount",
      width: "70px"
    },
    {
      text: "課税区分",
      value: "tax_div",
      width: "90px"
    },
    {
      text: "医療費控除",
      value: "medical_deducation",
      width: "90px"
    },

    {
      text: "請求月",
      value: "invoice_date",
      width: "120px",
      sort: SortWarekiDate
    },
    {
      text: "訪問日(登録日)",
      value: "visit_date",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "備考",
      value: "remarks",
      width: "200px"
    }
  ];

  /** 新規作成 */
  private selfburdenCreate() {
    this.$router.push({
      name: "PatientSelfburdenEdit",
      params: { id: String(this.patientId), selfburdenid: "0" }
    });
  }

  /** 修正 */
  private selfburdenEdit(item: Selfburden) {
    this.$router.push({
      name: "PatientSelfburdenEdit",
      params: { id: String(this.patientId), selfburdenid: String(item.id) }
    });
  }

  /** コピー */
  private selfburdenCopy(item: Selfburden) {
    this.$router.push({
      name: "PatientSelfburdenEdit",
      params: { id: String(this.patientId), selfburdenid: String(item.id) },
      query: { mode: "copy" }
    });
  }
}
