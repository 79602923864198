/** CSV出力検索条件 */
export interface OutputCsvSearchObj {
  /** 指定年月日(yyyy-MM-dd) */
  target_date: string;
  /** 指定年月(yyyy-MM) */
  target_yearmonth: string;
}

/** CSV出力項目情報型定義 */
export interface OutputCsvItem {
  /** 表示名 */
  text: string;
  /** 値 */
  value: number;
  /** テンプレート出力フラグ */
  has_template: boolean;
  /** 指定年月日フラグ */
  has_date: boolean;
  /** 指定年月フラグ */
  has_yearmonth: boolean;
  /** ファイル名 */
  file_name: string;
}

/** CSV出力項目情報 */
export const OUTPUT_CSV_ITEMS: OutputCsvItem[] = [
  {
    text: "利用者情報(統一)",
    value: 1,
    has_template: false,
    has_date: true,
    has_yearmonth: false,
    file_name: "patientUnity"
  },
  {
    text: "レセプト実績情報",
    value: 2,
    has_template: false,
    has_date: false,
    has_yearmonth: true,
    file_name: "receipt"
  },
  {
    text: "利用者情報",
    value: 3,
    has_template: false,
    has_date: false,
    has_yearmonth: false,
    file_name: "patient"
  },
  {
    text: "利用者基礎情報",
    value: 4,
    has_template: false,
    has_date: false,
    has_yearmonth: false,
    file_name: "patientBase"
  },
  {
    text: "関連機関",
    value: 5,
    has_template: false,
    has_date: false,
    has_yearmonth: false,
    file_name: "adminCorp"
  },
  {
    text: "関連機関担当者",
    value: 6,
    has_template: false,
    has_date: false,
    has_yearmonth: false,
    file_name: "person"
  },
  {
    text: "医療機関",
    value: 7,
    has_template: false,
    has_date: false,
    has_yearmonth: false,
    file_name: "medical"
  },
  {
    text: "医療機関医師",
    value: 8,
    has_template: false,
    has_date: false,
    has_yearmonth: false,
    file_name: "doctor"
  },
  {
    text: "医療保険",
    value: 9,
    has_template: true,
    has_date: true,
    has_yearmonth: false,
    file_name: "medicalInsurance"
  },
  {
    text: "介護保険",
    value: 10,
    has_template: true,
    has_date: true,
    has_yearmonth: false,
    file_name: "careInsurance"
  },
  {
    text: "指示書",
    value: 11,
    has_template: true,
    has_date: true,
    has_yearmonth: false,
    file_name: "indicate"
  },
  {
    text: "公費",
    value: 12,
    has_template: true,
    has_date: true,
    has_yearmonth: false,
    file_name: "publicMoney"
  }
];
