var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppSelect',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-pay-kbn"),"label":"支払い方法","items":_vm.masters.pay_types,"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.pay_kbn),callback:function ($$v) {_vm.$set(_vm.patient.info, "pay_kbn", $$v)},expression:"patient.info.pay_kbn"}}),_c('AppSwitch',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-withdrawal-st-kbn"),"label":"引落開始設定","true-value":1,"false-value":2,"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.withdrawal_st_kbn),callback:function ($$v) {_vm.$set(_vm.patient.info, "withdrawal_st_kbn", $$v)},expression:"patient.info.withdrawal_st_kbn"}}),_c('AppSelect',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-financial-institution-kbn"),"label":"銀行選択","items":_vm.masters.bank_types,"isNeed":_vm.patient.info.withdrawal_st_kbn == 1,"rules":[
      _vm.patient.info.withdrawal_st_kbn != 1 ||
        _vm.minNumber(_vm.patient.info.financial_institution_kbn, 1, '必須です')
    ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.financial_institution_kbn),callback:function ($$v) {_vm.$set(_vm.patient.info, "financial_institution_kbn", $$v)},expression:"patient.info.financial_institution_kbn"}}),_c('v-expand-transition',[(_vm.patient.info.financial_institution_kbn == 1)?_c('div',[_c('NumberInputString',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-branch-no"),"label":"支店コード(通帳記号)","maxlength":"3","isNeed":_vm.YutyoRule,"prefix":_vm.patient.info.financial_institution_kbn == 1 ? '1' : '',"suffix":_vm.patient.info.financial_institution_kbn == 1 ? '0' : '',"rules":[
          !_vm.YutyoRule || _vm.required(_vm.patient.info.branch_no, '必須です'),
          !_vm.YutyoRule || _vm.patient.info.branch_no.length == 3
            ? true
            : '支店コード(通帳記号)は半角数字5桁で入力してください'
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.branch_no),callback:function ($$v) {_vm.$set(_vm.patient.info, "branch_no", $$v)},expression:"patient.info.branch_no"}}),_c('NumberInputString',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-account-no"),"label":"口座番号(通帳番号)","maxlength":"8","isNeed":_vm.YutyoRule,"rules":[
          !_vm.YutyoRule || _vm.required(_vm.patient.info.account_no, '必須です'),
          !_vm.YutyoRule || _vm.patient.info.account_no.length == 8
            ? true
            : '口座番号(通帳番号)は8桁で入力してください'
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.account_no),callback:function ($$v) {_vm.$set(_vm.patient.info, "account_no", $$v)},expression:"patient.info.account_no"}}),_c('AppHankanaField',{attrs:{"id":(_vm.prefixId + "-depositor"),"label":"預金者名","maxlength":"30","isNeed":_vm.YutyoRule,"rules":[
          !_vm.YutyoRule || _vm.required(_vm.patient.info.depositor, '必須です'),
          _vm.hankanaBank(_vm.patient.info.depositor, false)
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.depositor),callback:function ($$v) {_vm.$set(_vm.patient.info, "depositor", $$v)},expression:"patient.info.depositor"}}),_c('div',{staticClass:"text-caption red--text"},[_vm._v(" ※カナ大文字、英大文字(A～Z)、数字(0～9)、スペース、記号4種類（ - ( ) . ）のみ入力できます。 "),_c('br'),_vm._v("※口座振替サービスにより『ヲ』など一部対応されていない文字がございますのでご注意ください。 ")])],1):_vm._e()]),_c('v-expand-transition',[(_vm.patient.info.financial_institution_kbn == 2)?_c('div',[_c('NumberInputString',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-institution-no"),"label":"振替金融機関コード","maxlength":"4","isNeed":_vm.OtherBankRule,"rules":[
          !_vm.OtherBankRule || _vm.required(_vm.patient.info.institution_no, '必須です'),
          !_vm.OtherBankRule ||
            _vm.maxLength(
              _vm.patient.info.institution_no,
              4,
              '振替金融機関コードは4桁で入力してください'
            )
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.institution_no),callback:function ($$v) {_vm.$set(_vm.patient.info, "institution_no", $$v)},expression:"patient.info.institution_no"}}),_c('AppHankanaField',{attrs:{"id":(_vm.prefixId + "-institution-name"),"label":"振替金融機関名","maxlength":"15","rules":[_vm.hankanaBank(_vm.patient.info.institution_name, false)],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.institution_name),callback:function ($$v) {_vm.$set(_vm.patient.info, "institution_name", $$v)},expression:"patient.info.institution_name"}}),_c('div',{staticClass:"text-caption red--text mb-6"},[_vm._v(" ※カナ大文字(ヲを除く) 、英大文字(A～Z)、数字(0～9)、スペース、記号4種類（ - ( ) . ）のみ入力できます。 ")]),_c('NumberInputString',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-branch-no-2"),"label":"支店コード(通帳記号)","maxlength":"3","isNeed":_vm.OtherBankRule,"rules":[
          !_vm.OtherBankRule || _vm.required(_vm.patient.info.branch_no, '必須です'),
          !_vm.OtherBankRule ||
            _vm.maxLength(
              _vm.patient.info.branch_no,
              3,
              '支店コード(通帳記号)は3桁で入力してください'
            )
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.branch_no),callback:function ($$v) {_vm.$set(_vm.patient.info, "branch_no", $$v)},expression:"patient.info.branch_no"}}),_c('AppHankanaField',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-branch-name"),"label":"支店名","maxlength":"15","rules":[_vm.hankanaBankBranchName(_vm.patient.info.branch_name, false)],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.branch_name),callback:function ($$v) {_vm.$set(_vm.patient.info, "branch_name", $$v)},expression:"patient.info.branch_name"}}),_c('div',{staticClass:"text-caption red--text mb-6"},[_vm._v(" ※カナ大文字(ヲを除く) 、英大文字(A～Z)、数字(0～9)、記号はﾊｲﾌﾝ（ - ）のみ入力できます。 ")]),_c('AppSelect',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-deposit-kbn"),"label":"預金種目","items":_vm.masters.deposit_type,"isNeed":_vm.OtherBankRule,"rules":[
          !_vm.OtherBankRule || _vm.minNumber(_vm.patient.info.deposit_kbn, 1, '必須です')
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.deposit_kbn),callback:function ($$v) {_vm.$set(_vm.patient.info, "deposit_kbn", $$v)},expression:"patient.info.deposit_kbn"}}),_c('NumberInputString',{staticClass:"mb-6",attrs:{"id":(_vm.prefixId + "-account-no-2"),"label":"口座番号(通帳番号)","maxlength":"7","isNeed":_vm.OtherBankRule,"rules":[
          !_vm.OtherBankRule || _vm.required(_vm.patient.info.account_no, '必須です'),
          !_vm.OtherBankRule ||
            _vm.maxLength(
              _vm.patient.info.account_no,
              7,
              '口座番号(通帳番号)は7桁で入力してください'
            )
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.account_no),callback:function ($$v) {_vm.$set(_vm.patient.info, "account_no", $$v)},expression:"patient.info.account_no"}}),_c('AppHankanaField',{attrs:{"id":(_vm.prefixId + "-depositor-2"),"label":"預金者名","maxlength":"30","isNeed":_vm.OtherBankRule,"rules":[
          !_vm.OtherBankRule || _vm.required(_vm.patient.info.depositor, '必須です'),
          _vm.hankanaBank(_vm.patient.info.depositor, false)
        ],"disabled":_vm.isReadOnly},model:{value:(_vm.patient.info.depositor),callback:function ($$v) {_vm.$set(_vm.patient.info, "depositor", $$v)},expression:"patient.info.depositor"}}),_c('div',{staticClass:"text-caption red--text"},[_vm._v(" ※カナ大文字、英大文字(A～Z)、数字(0～9)、スペース、記号4種類（ - ( ) . ）のみ入力できます。 "),_c('br'),_vm._v("※口座振替サービスにより『ヲ』など一部対応されていない文字がございますのでご注意ください。 ")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }