




















import { Component, Vue, Prop } from "vue-property-decorator";
import { RegistrationMessage, ResetMessage } from "#/model/receipt";

@Component
export default class VisitMessage extends Vue {
  @Prop() private readonly registrationMessage!: RegistrationMessage; //登録時メッセージ
  @Prop() private readonly resetMessage!: ResetMessage; //リセット時メッセージ
}
