import Base from "./base";
import { ApplyStaff, DefaultApplyStaff } from "./common";
import { covid19VaccineStatuses } from "./patient";

//24時間体制 - コメント
export interface FulltimeComment extends Base {
  id: number; //ID
  fulltime_id: number; //'24時間体制ID',
  staff_id: number; //'職員ID',
  comment: string; //'コメント',
  before_comment?: string; //'編集前のコメント',
  is_reading: number; //既読フラグ
  staff: ApplyStaff; //職員
  edit: boolean; //編集・登録切り替え
  editable_flg: boolean; //編集可能フラグ
  editor_staff_name: string; //編集者名
  replace_comment: string; //コメント（リンク表示用）
}
export const DefaultFulltimeComment = (): FulltimeComment => ({
  id: 0,
  fulltime_id: 0,
  staff_id: 0,
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  is_reading: 0,
  staff: DefaultApplyStaff(),
  edit: false,
  editable_flg: false,
  editor_staff_name: "",
  replace_comment: ""
});

//24時間体制
export interface Fulltime extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  support_datetime: string; //'対応日時',
  support_method: number; //'対応方法\n1:TEL\n2:FAX\n3:訪問\n4:mail\n5:来所\n6:その他',
  messenger: number; //'連絡元\n1:本人\n2:家族\n3:ケアマネ\n4:主治医\n5:キーパーソン\n6:その他',
  other_messenger: string; //'その他連絡元',
  support_div: number; //'対応区分\n1:緊急対応\n2:クレーム\n3:訪問キャンセル連絡\n4:訪問予定変更連絡\n5:その他訪問に関する連絡事項\n6:情報連携あり\n7:情報連携なし',
  memo: string; //'メモ',
  support_datetime_wareki: string; //対応日時（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  is_reading: number; //既読フラグ
  is_favorite: number; //お気に入りフラグ
  fulltime_comments: FulltimeComment[]; //コメント
  editor_staff: ApplyStaff; //職員
}
export const DefaultFulltime = (): Fulltime => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  support_datetime: "",
  support_method: 1,
  messenger: 1,
  other_messenger: "",
  support_div: 6,
  memo: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  support_datetime_wareki: "",
  updated_at_wareki: "",
  is_reading: 0,
  is_favorite: 0,
  fulltime_comments: [],
  editor_staff: DefaultApplyStaff()
});

/** 24時間体制の利用者欄に表示するグループ情報 */
export interface GroupIcon {
  /** グループ名 */
  name: string;
  /** 画像パス */
  path: string;
}

export const DefaultGroupIcon = (): GroupIcon => ({
  name: "",
  path: ""
});

//未読・既読・詳細用
export interface BoardItem extends Fulltime {
  color: string; //ボード背景色
  favorite: boolean; //お気に入り表示用
  favorite_icon: string; //お気に入りアイコン
  patient_name: string; //利用者名
  editor_staff_name: string; //編集者名
  status: string; //未読・既読
  category: string; //カテゴリ表示用
  comment_order: number; // コメントの表示順 1:古い順 2:新しい順
  editable_flg: boolean; //編集可能フラグ
  tags: Tag[]; //詳細表示用タグ
  group_icons: GroupIcon[]; // 利用者グループ情報
  covid19_vaccine_status_icon: GroupIcon; // コロナワクチン接種状況
}
export const DefaultBoardItem = (): BoardItem => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  support_datetime: "",
  support_method: 0,
  messenger: 0,
  other_messenger: "",
  support_div: 0,
  memo: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  support_datetime_wareki: "",
  updated_at_wareki: "",
  is_reading: 0,
  is_favorite: 0,
  fulltime_comments: [],
  editor_staff: DefaultApplyStaff(),
  color: "",
  favorite: false,
  favorite_icon: "",
  patient_name: "",
  editor_staff_name: "",
  status: "",
  category: "",
  comment_order: 0,
  editable_flg: false,
  tags: [],
  group_icons: [],
  covid19_vaccine_status_icon: DefaultGroupIcon()
});

//詳細表示用タグ
export interface Tag {
  id: number;
  name: string;
  color: string;
  icon: string;
}

// 検索条件インターフェース
export interface SearchCondition {
  office_ids: number[]; // 事業所ID
  keyword: string; // キーワード
  start_yearmonth: string; // 開始月
  end_yearmonth: string; // 終了月
  start_date: string; // 開始日
  end_date: string; // 終了日
  only_favorite_flg: boolean; // お気に入りのみフラグ
  selected_category_items: number[]; // カテゴリ
  patient_id: number; // 利用者ID
  status: number; // 利用者の状況
  only_care_emergency: boolean; // 介護の緊急時訪問
  only_medical_emergency: boolean; // 医療の24時間体制
  group_ids: number[]; // グループ
  group_operator: number; // グループ検索演算子（1:AND 2:OR）
  sort: number; // 並び順
  sort_type: number; // ならび順 昇順・降順
}
export const DefaultSearchCondition = (): SearchCondition => ({
  office_ids: [],
  keyword: "",
  start_yearmonth: "",
  end_yearmonth: "",
  start_date: "",
  end_date: "",
  only_favorite_flg: false,
  selected_category_items: [1, 2, 3, 4, 5, 6, 7],
  patient_id: 0,
  status: 1,
  only_care_emergency: false,
  only_medical_emergency: false,
  group_ids: new Array(5).fill(0),
  group_operator: 1,
  sort: 0,
  sort_type: 0
});
