



























































































































































































































import { Component, Ref, Mixins } from "vue-property-decorator";
import { Calendar, CalendarDispEvent } from "#/components/calendar/common";
import { Patient, DefaultPatient } from "#/model/patient";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { app, install } from "#/mixins/firebase";
import { ApplyOffice } from "#/model/apply";
import CalendarTitleBeforeAfter from "#/components/calendar/CalendarTitleBeforeAfter.vue";
import IbowCalendar from "@/components/common_ibow/calendar/IbowCalendar.vue";
import { EventType } from "#/model/schedule/calendarType";

interface PatientAddOffice extends Patient {
  office?: ApplyOffice;
}

@Component({
  components: { CalendarTitleBeforeAfter, IbowCalendar }
})
export default class CalendarWebPrint extends Mixins(AxiosMixin, UtilMixin) {
  // カレンダーコンポーネント
  @Ref("calendar") private readonly calendar!: Calendar;
  /** イベント */
  private events: CalendarDispEvent[] = [];
  /** 利用者 */
  private patient: PatientAddOffice = DefaultPatient();
  /** 対象月 */
  private targetMonth = this.dateToStr(new Date(), "yyyy-mm-dd");
  /** 祝日表示フラグ */
  private dispHoliday = true;
  /** 訪問者を表示しないフラグ */
  private dispStaff = 1;
  /** 電話番号を表示するフラグ */
  private dispTelNo = 1;
  /** 印刷日を表示するフラグ */
  private dispPrintDate = true;
  /** 個別予定表示フラグ */
  private dispIndividual = true;

  /** タイトル */
  private get Title(): string {
    return (
      this.patient.info.last_name +
      this.patient.info.first_name +
      "さんのカレンダー"
    );
  }

  /** 表示用年月日 */
  private get YearMonth(): string {
    const [year, month] = this.targetMonth.split("-");
    return `${year}年${month}月`;
  }

  /** 事業所名 */
  private get OfficeName(): string {
    if (this.patient.office) {
      return this.patient.office.name;
    }
    return "";
  }

  /** 電話番号 */
  private get TelNo(): string {
    if (this.dispTelNo && this.patient.office) {
      return "TEL:" + this.patient.office.tel_no;
    }
    return "";
  }

  /** 印刷日 */
  private get PrintDate(): string {
    if (this.dispPrintDate) {
      return "印刷日:" + this.dateToStr(new Date(), "yyyy-MM-dd");
    }
    return "";
  }

  private get Events(): CalendarDispEvent[] {
    return this.events.filter(event => {
      if (!this.dispHoliday && event.type === EventType.Holiday) {
        return false;
      }
      if (event.type === EventType.VisitSchedule) {
        if (this.dispStaff) {
          event.name = event.name_toolchip;
        } else {
          event.name = event.visit_plan.start_and_end;
        }
      }
      if (!this.dispIndividual && event.type === EventType.IndivisualSchedule) {
        return false;
      }
      return true;
    });
  }

  private created() {
    if (this.$route.query.target) {
      this.targetMonth = String(this.$route.query.target);
    }
    this.fetch();
  }

  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/schedule/patientschedule/get",
      {
        patient_id: Number(this.$route.params.id),
        target_date: this.targetMonth
      },
      res => {
        this.patient = res.data.patient;
        this.events = res.data.patient_events;
      }
    );
  }

  private close() {
    window.close();
  }

  /** Web印刷ボタンクリック */
  private webPrint() {
    app
      .delete()
      .then(function() {
        window.print();
      })
      .catch(function(error) {
        console.log("Error deleting app:", error);
      })
      .finally(function() {
        install();
      });
  }

  /** PDF印刷ボタンクリック */
  private pdfPrint() {
    this.makePdf(window.base_url + "/api/schedule/patientschedule/pdf", {
      target_date: this.targetMonth,
      patient: this.patient,
      patient_events: this.Events,
      disp_staff: this.dispStaff,
      disp_tel_no: this.dispTelNo
    });
  }
}
