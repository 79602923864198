
















































import { Component, Emit, Mixins, Prop, Ref } from "vue-property-decorator";
import { InfoItems } from "../types";
import AxiosMixin from "@/mixins/axiosMixin";
import sanitizeHTML from "sanitize-html";
import InfiniteLoading from "vue-infinite-loading";

@Component({
  components: {
    InfiniteLoading
  }
})
export default class extends Mixins(AxiosMixin) {
  /** 無限ローディングコンポーネント */
  @Ref("infiniteLoading") private readonly infiniteLoading!: InfiniteLoading;

  /** お知らせテーブルデータ */
  @Prop() private readonly topicItems!: InfoItems[];

  /** トピック選択時 */
  @Emit("selectTopic") private selectTopic(
    item: InfoItems,
    idx: number
  ): { item: InfoItems; idx: number } {
    return { item, idx };
  }

  /** サニタイズ */
  private readonly sanitize = sanitizeHTML;

  /** トピッククリック時に選択状態にし、かつ、既読にする */
  private selectTopicAndReadComplete(item: InfoItems, idx: number): void {
    this.selectTopic(item, idx);
    this.readComplete(item, idx);
  }

  /** トピックを既読にする */
  private readComplete(item: InfoItems, idx: number): void {
    // 既読の場合、既読処理を行わない
    if (
      item.bulletin_board.is_reading == 1 &&
      item.bulletin_board.unread_comment_count == 0
    ) {
      return;
    }

    this.postJsonBackground(
      window.base_url + "/api/bbs/mark",
      {
        bbs_id: item.id
      },
      () => {
        item.bulletin_board.unread_comment_count = 0;
        item.bulletin_board.is_reading = 1;
        this.topicItems.splice(idx, 1, item);
        return;
      }
    );
  }
}
