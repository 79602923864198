

















import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import OneOrThreeWeekAddV3 from "./OneOrThreeWeekAddV3.vue";

@Component
export default class OneOrThreeWeekAddV4 extends OneOrThreeWeekAddV3 {
  @Prop({ default: false })
  protected readonly long_time_flg_covid19_urgent_div!: boolean;

  @Prop({ default: false })
  protected readonly long_time_flg_covid19_plan_div!: boolean;

  @Emit("update:long_time_flg") protected updateLongTimeFlg(newValue: boolean) {
    return newValue;
  }

  @Emit("update:long_time_flg_covid19_div")
  protected updateLongTimeFlgCovid19Div(newValue: boolean) {
    return newValue;
  }

  created(): void {
    this.initLongTimeFlgV4();
  }

  protected get LongTimeFlg() {
    return this.long_time_flg;
  }

  protected set LongTimeFlg(newValue: boolean) {
    this.updateLongTimeFlg(newValue);
  }

  //長時間
  private initLongTimeFlgV4() {
    if (this.receiptVisitRecord.long_time_flg == 1) {
      this.updateLongTimeFlg(true);
    }
    if (this.receiptVisitRecord.long_time_flg == 2) {
      this.updateLongTimeFlg(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 3) {
      this.updateLongTimeFlg(true);
    }
    if (this.receiptVisitRecord.long_time_flg == 4) {
      this.updateLongTimeFlg(false);
    }
    if (this.receiptVisitRecord.long_time_flg == 5) {
      this.updateLongTimeFlg(true);
    }
  }

  //長時間（オーバーライド）
  @Watch("long_time_flg") protected handleLongTimeFlg() {
    if (!this.isLoaded) {
      return;
    }
    if (this.long_time_flg) {
      if (this.long_time_flg_covid19_urgent_div) {
        this.receiptVisitRecord.long_time_flg = 3;
      }
      if (this.long_time_flg_covid19_plan_div) {
        this.receiptVisitRecord.long_time_flg = 5;
      }
      if (
        !this.long_time_flg_covid19_urgent_div &&
        !this.long_time_flg_covid19_plan_div
      ) {
        this.receiptVisitRecord.long_time_flg = 1;
      }
    } else {
      if (this.long_time_flg_covid19_urgent_div) {
        this.receiptVisitRecord.long_time_flg = 2;
      }
      if (this.long_time_flg_covid19_plan_div) {
        this.receiptVisitRecord.long_time_flg = 4;
      }
    }
    if (
      this.long_time_flg == false &&
      this.long_time_flg_covid19_urgent_div == false &&
      this.long_time_flg_covid19_plan_div == false
    ) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV4() {
    // コロナ加算が無くなったタイミングよりあとは何もしない
    if (this.yearmonth >= "2024-04") {
      return;
    }
    //長時間（新型コロナウイルス臨時加算：緊急）
    if (
      this.disableCheckLongTimeFlg() &&
      this.long_time_flg_covid19_urgent_div &&
      !this.long_time_flg_covid19_plan_div
    ) {
      this.receiptVisitRecord.long_time_flg = 2;
    }
    //長時間（新型コロナウイルス臨時加算：計画）
    if (
      this.disableCheckLongTimeFlg() &&
      !this.long_time_flg_covid19_urgent_div &&
      this.long_time_flg_covid19_plan_div
    ) {
      this.receiptVisitRecord.long_time_flg = 4;
    }
    //長時間（全てチェック無し）
    if (
      this.disableCheckLongTimeFlg() &&
      !this.long_time_flg_covid19_urgent_div &&
      !this.long_time_flg_covid19_plan_div
    ) {
      this.receiptVisitRecord.long_time_flg = 0;
    }
  }
}
