import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";

/** 画面表示設定 */
export interface ShowSetting {
  column: ShowColumn;
  is_need_child_open: boolean;
}

/** 非表示のヘッダー { 帳票種類value: [ヘッダーvalue] } */
export interface ShowColumn {
  [key: string]: boolean;
}

/** 帳票一覧表のヘッダー */
export interface ReportListHeader extends DataTableHeader {
  selectName?: string; // 選択肢名
  /** 表示列の設定可能フラグ */
  hidable?: boolean;
  /** 表示列の設定デフォルト値 */
  is_shown_default?: boolean;
}

const headerPatient: ReportListHeader[] = [
  {
    text: "ID",
    value: "patient_display_id",
    hidable: true
  },
  {
    text: "利用者名",
    value: "patient_name"
  },
  {
    text: "ふりがな",
    value: "patient_name_kana",
    hidable: true,
    is_shown_default: true
  }
];

export const vnrecord: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "担当者",
    value: "staff_name"
  },
  {
    text: "記載日",
    value: "write_date",
    sort: SortWarekiDate
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const vnplan: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "宛名",
    value: "address_name",
    width: "200px"
  },
  {
    text: "ふりがな",
    selectName: "宛名ふりがな",
    value: "address_name_kana",
    width: "250px"
  },
  {
    text: "作成者",
    value: "writer_name"
  },
  {
    text: "計画月",
    value: "target_month",
    sort: SortWarekiDate
  },
  {
    text: "記載日",
    value: "write_date",
    sort: SortWarekiDate
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const vnplanMonth: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "計画書宛名",
    value: "address_name_vnplan",
    width: "170px"
  },
  {
    text: "ふりがな",
    selectName: "計画書宛名ふりがな",
    value: "address_name_kana_vnplan",
    width: "200px"
  },
  {
    text: "報告書宛名",
    value: "address_name_monthreport",
    width: "170px"
  },
  {
    text: "ふりがな",
    selectName: "報告書宛名ふりがな",
    value: "address_name_kana_monthreport",
    width: "200px"
  },
  {
    text: "計画月",
    value: "target_month_vnplan",
    sort: SortWarekiDate
  },
  {
    text: "報告月",
    value: "target_month_monthreport",
    sort: SortWarekiDate
  },
  {
    text: "サービス提供年月",
    value: "target_month_offer",
    sort: SortWarekiDate
  },
  {
    text: "計画書記載日",
    value: "write_date_vnplan",
    sort: SortWarekiDate
  },
  {
    text: "報告書記載日",
    value: "write_date_monthreport",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    cellClass: "text-no-wrap",
    sortable: false
  }
];

export const serviceReport: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const visitRecord: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "訪問者名",
    value: "staff_name"
  },
  {
    text: "作成者名",
    value: "editor_name"
  },
  {
    text: "訪問日時",
    value: "visit_datetime",
    cellClass: "text-pre-wrap",
    width: "11rem",
    sort: SortWarekiDate
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    cellClass: "text-no-wrap",
    sortable: false
  }
];

export const visitRecordHonobono: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "訪問者名",
    value: "staff_name"
  },
  {
    text: "作成者名",
    value: "editor_name"
  },
  {
    text: "訪問日時",
    value: "visit_datetime",
    cellClass: "text-pre-wrap",
    width: "11rem",
    sort: SortWarekiDate
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "実績登録完了一覧取得連携状態",
    value: "honobono_link_status"
  },
  {
    text: "",
    value: "actions",
    cellClass: "text-no-wrap",
    sortable: false
  }
];

export const visitRecordUnsubmit: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "種別",
    value: "docInfos.div",
    align: "start"
  },
  {
    text: "指示書情報",
    value: "docInfos.indicate",
    align: "center"
  },
  {
    text: "保険情報",
    value: "docInfos.insurances",
    align: "center"
  },
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "訪問者名",
    value: "staff_name"
  },
  {
    text: "訪問日時",
    value: "visit_datetime",
    cellClass: "text-pre-wrap",
    width: "11rem",
    sort: SortWarekiDate
  },
  {
    text: "最終更新日時",
    value: "update_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const provisionReport: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "種類",
    value: "pro_report_type_name",
  },
  {
    text: "作成者",
    value: "editor_name"
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const provisionEachReport: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "種類",
    value: "pro_report_type_name",
    width: "150px"
  },
  {
    text: "提供書様式",
    value: "per_pdf_format",
    width: "128px"
  },
  {
    text: "情報提供先機関名・氏名",
    value: "per_provide_name",
    width: "200px"
  },
  {
    text: "ふりがな",
    value: "per_provide_name_kana",
    width: "250px"
  },
  {
    text: "作成者",
    value: "per_editor_name",
    width: "128px"
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const monthReport: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "宛名",
    value: "address_name",
    width: "200px"
  },
  {
    text: "ふりがな",
    selectName: "宛名ふりがな",
    value: "address_name_kana",
    width: "250px"
  },
  {
    text: "担当者",
    value: "staff_name"
  },
  {
    text: "報告月",
    value: "target_month",
    sort: SortWarekiDate
  },
  {
    text: "記載日",
    value: "write_date",
    sort: SortWarekiDate
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const indicateExpired: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "生年月日",
    value: "patient_birthday",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "指示書種類",
    value: "indicate_type",
    width: "8.75rem",
  },
  {
    text: "指示書開始日",
    value: "indicate_start_wareki",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "指示書有効期限",
    value: "indicate_expired_wareki",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "病院名",
    value: "doctor_inst",
    width: "260px"
  },
  {
    text: "ふりがな",
    selectName: "病院名ふりがな",
    value: "doctor_inst_kana",
    width: "260px"
  },
  {
    text: "主治医名",
    value: "doctor_name",
    width: "160px"
  },
  {
    text: "ふりがな",
    selectName: "主治医名ふりがな",
    value: "doctor_name_kana",
    width: "160px"
  },
  {
    text: "受付日",
    value: "reception_date",
    width: "10rem",
    sort: SortWarekiDate
  }
];

export const publicMoneyExpired: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "生年月日",
    value: "patient_birthday",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "年齢",
    value: "patient_age",
    width: "80px"
  },
  {
    text: "区分",
    value: "pm_insurance_div",
    width: "80px"
  },
  {
    text: "公費種類",
    value: "pm_name",
    width: "154px"
  },
  {
    text: "開始日",
    value: "pm_start_wareki",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "終了日",
    value: "pm_end_wareki",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "負担者番号",
    value: "pm_insurer_no",
    width: "120px"
  },
  {
    text: "受給者番号",
    value: "pm_insured_no",
    width: "120px"
  },
  {
    text: "受付日",
    value: "reception_date",
    width: "10rem",
    sort: SortWarekiDate
  }
];

export const bedsorePlan: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const occurReport: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "発生日時",
    value: "occur_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const patient: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const careSummary: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "作成日時",
    value: "create_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const conferenceReport: ReportListHeader[] = [
  {
    text: "タイトル",
    value: "title"
  },
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "会議日",
    value: "conference_date",
    sort: SortWarekiDate
  },
  {
    text: "提出日時",
    value: "submit_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const serviceOffer: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "サービス提供年月",
    value: "service_month",
    sort: SortWarekiDate
  },
  {
    text: "居宅名/担当者名",
    value: "care_personnel",
    width: "300px"
  },
  {
    text: "ふりがな",
    selectName: "居宅名/担当者名ふりがな",
    value: "care_personnel_kana",
    width: "300px"
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const monthlyExist: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "サービス提供票",
    value: "me_receipt_decision",
    width: "285px",
    sort: SortWarekiDate
  },
  {
    text: "看護計画書",
    value: "me_vn_plan",
    width: "285px",
    sort: SortWarekiDate
  },
  {
    text: "月次報告書",
    value: "me_month_report",
    width: "285px",
    sort: SortWarekiDate
  },
  {
    text: "情報提供書",
    value: "me_provision_report",
    width: "285px",
    sort: SortWarekiDate
  }
];

export const monthlyBatch: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "年月",
    value: "yearmonth"
  },
  {
    text: "所属ステーション",
    value: "office_name"
  }
];

/** 緊急連絡先リスト */
export const emergencyContactList: ReportListHeader[] = [
  ...headerPatient,
  {
    text: "性別",
    value: "ec_sex",
    hidable: true
  },
  {
    text: "生年月日",
    value: "ec_birth_day",
    hidable: true
  },
  {
    text: "年齢",
    value: "ec_age",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "郵便番号",
    value: "ec_post_code",
    width: "116px",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "住所",
    value: "ec_address",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "ハザードマップ",
    value: "ec_hazard_map",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "電話番号",
    value: "ec_tel_no",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "携帯番号",
    value: "ec_mobile_no",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "要介護認定",
    value: "ec_need_care_level",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "初回訪問年月日",
    value: "ec_first_visit_date",
    hidable: true
  },
  {
    text: "キーパーソン\n【氏名】",
    value: "ec_key_person_name",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "キーパーソン\n【続柄】",
    value: "ec_key_person_family_relationship",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "キーパーソン\n【電話番号】",
    value: "ec_key_person_tel_no",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "キーパーソン\n【特記すべき事項】",
    value: "ec_key_person_comment",
    hidable: true
  },
  {
    text: "主傷病名1",
    value: "ec_main_disease1",
    hidable: true
  },
  {
    text: "主傷病名2",
    value: "ec_main_disease2",
    hidable: true
  },
  {
    text: "主傷病名3",
    value: "ec_main_disease3",
    hidable: true
  },

  {
    text: "指示書\n開始日",
    value: "ec_indicate_start_date",
    hidable: true
  },
  {
    text: "指示書\n終了日",
    value: "ec_indicate_end_date",
    hidable: true
  },
  {
    text: "指示書\n医療機関",
    value: "ec_indicate_ins_name",
    hidable: true
  },
  {
    text: "指示書\n医師",
    value: "ec_indicate_doctor_name",
    hidable: true
  },
  {
    text: "指示書\n指示内容",
    value: "ec_indicate_content",
    hidable: true
  },
  {
    text: "特別指示書\n開始日",
    value: "ec_sp_indicate_start_date",
    hidable: true
  },
  {
    text: "特別指示書\n終了日",
    value: "ec_sp_indicate_end_date",
    hidable: true
  },
  {
    text: "特別指示書\n医療機関",
    value: "ec_sp_indicate_ins_name",
    hidable: true
  },
  {
    text: "特別指示書\n医師",
    value: "ec_sp_indicate_doctor_name",
    hidable: true
  },
  {
    text: "特別指示書\n指示内容",
    value: "ec_sp_indicate_content",
    hidable: true
  },
  {
    text: "主治医連絡先\n医療機関名",
    value: "ec_doctor_ins_name",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "主治医連絡先\n電話番号",
    value: "ec_doctor_ins_tel",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "主治医連絡先\nFAX",
    value: "ec_doctor_ins_fax",
    hidable: true
  },
  {
    text: "主治医\n医師名",
    value: "ec_doctor_name",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "主治医\n連絡先1",
    value: "ec_doctor_contact1",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "主治医\n連絡先2",
    value: "ec_doctor_contact2",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "主治医\n連絡先3",
    value: "ec_doctor_contact3",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "主治医\n連絡先留意事項",
    value: "ec_doctor_remarks",
    hidable: true
  },
  {
    text: "ケアマネ連絡先\n関連機関名",
    value: "ec_care_manager_ins_name",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "ケアマネ連絡先\n電話番号",
    value: "ec_care_manager_ins_tel",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "ケアマネ連絡先\nFAX",
    value: "ec_care_manager_ins_fax",
    hidable: true
  },
  {
    text: "ケアマネ\n担当者名",
    value: "ec_care_manager_name",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "ケアマネ\n連絡先1",
    value: "ec_care_manager_contact1",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "ケアマネ\n連絡先2",
    value: "ec_care_manager_contact2",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "ケアマネ\n連絡先3",
    value: "ec_care_manager_contact3",
    hidable: true,
    is_shown_default: true
  },
  {
    text: "ケアマネ\n連絡先留意事項",
    value: "ec_care_manager_remarks",
    hidable: true
  },
  {
    text: "利用者状況の備考",
    value: "ec_remarks",
    hidable: true
  },
  {
    text: "グループ1",
    value: "ec_group1",
    hidable: true
  },
  {
    text: "グループ2",
    value: "ec_group2",
    hidable: true
  },
  {
    text: "グループ3",
    value: "ec_group3",
    hidable: true
  },
  {
    text: "グループ4",
    value: "ec_group4",
    hidable: true
  },
  {
    text: "グループ5",
    value: "ec_group5",
    hidable: true
  },
  {
    text: "グループ6",
    value: "ec_group6",
    hidable: true
  },
  {
    text: "グループ7",
    value: "ec_group7",
    hidable: true
  },
  {
    text: "グループ8",
    value: "ec_group8",
    hidable: true
  },
  {
    text: "グループ9",
    value: "ec_group9",
    hidable: true
  },
  {
    text: "グループ10",
    value: "ec_group10",
    hidable: true
  },
  {
    text: "コロナワクチン\n接種状況",
    value: "ec_covid19_vaccine_status",
    hidable: true
  },
  {
    text: "申し送り事項",
    value: "ec_message",
    hidable: true
  }
];

export const newReception: ReportListHeader[] = [
  {
    text: "受付日",
    value: "reception_date",
    width: "10rem",
    sort: SortWarekiDate
  },
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "依頼元",
    value: "requester_type",
    width: "7rem"
  },
  {
    text: "依頼元：\n機関名/氏名",
    value: "requester_name"
  },
  {
    text: "初回実績",
    value: "first_visit_date",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "初回訪問日",
    value: "first_visit_info_date",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "介護サービス\n開始日",
    value: "care_start_date",
    width: "10rem",
    sort: SortWarekiDate
  },
  {
    text: "保険",
    value: "nr_insurance",
    width: "10rem"
  },
  {
    text: "指示書",
    value: "nr_indicate"
  },
  {
    text: "看護記録I",
    value: "nr_vn_record"
  },
  {
    text: "看護計画",
    value: "nr_vn_plan"
  },
  {
    text: "サービス確認",
    value: "nr_service_report"
  },
  {
    text: "褥瘡対策",
    value: "nr_bedsore_plan"
  }
];

export const highlightRecord: ReportListHeader[] = [
  {
    text: "訪問日時",
    value: "visit_datetime",
    cellClass: "text-pre-wrap",
    width: "11rem",
    sort: SortWarekiDate
  },
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name"
  },
  {
    text: "主訪問者",
    value: "staff_name"
  },
  {
    text: "症状報告内容",
    value: "condition_memo"
  },
  {
    text: "特記事項内容",
    value: "other_memo"
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];

export const highlightUrgency: ReportListHeader[] = [
  {
    text: "対応日時",
    value: "support_datetime",
    cellClass: "text-pre-wrap",
    width: "10rem",
    sort: SortWarekiDate
  },
  ...headerPatient,
  {
    text: "所属ステーション",
    value: "office_name",
    width: "300px"
  },
  {
    text: "編集者名",
    value: "staff_name"
  },
  {
    text: "対応方法",
    value: "support_method"
  },
  {
    text: "連絡元",
    value: "messenger"
  },
  {
    text: "メモ",
    value: "memo",
    width: "200px"
  },
  {
    text: "コメント",
    value: "comment",
    cellClass: "text-super-long",
    width: "600px"
  },
  {
    text: "",
    value: "actions",
    sortable: false
  }
];
