













































































































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { Choice, VForm } from "@/types";
import { IsurerMaster } from "@/components/patient/types";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import DateInput from "#/components/DateInput.vue";
import { CareInsurance, DefaultCareInsurance } from "#/model/patient";
import PatientRelationInsSelect, {
  SearchItem
} from "@/components/patient/common_ibow/PatientRelationInsSelect.vue";
import PatientRelationInsOnlySelect from "@/components/patient/common_ibow/PatientRelationInsOnlySelect.vue";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";
import { TargetKind } from "@/const/ocr";

const Ocr = namespace(ocr.name);

@Component({
  components: {
    PatientHeader,
    PatientRelationInsSelect,
    PatientRelationInsOnlySelect,
    DateInput
  }
})
export default class InsuranceInfoCare extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;
  @Ref("patient-relation-ins-select1")
  private readonly patientRelationInsSelect1!: PatientRelationInsSelect;
  @Ref("patient-relation-ins-select2")
  private readonly patientRelationInsSelect2!: PatientRelationInsSelect;

  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Getter("OcrState") ocrState!: ocr.OcrState;

  private insCareId = 0;
  private patientId = 0;
  private mode = "";
  private isLoaded = false;

  private care_insurance: CareInsurance = DefaultCareInsurance(); //介護保険
  private searchInsurer = 0; // 保険者検索v-model
  private isurerItems: Choice[] = []; // 保険者検索Items
  private isurerMaster: IsurerMaster[] = []; // 保険者マスター

  /** 申請状態(保存前の状態) */
  private orgApplicationState = 0;

  //開始日が平成30年8月1日以前フラグ（確認用）
  private get BeforeStartFlg(): number {
    let flg = 0;
    const start = new Date(this.care_insurance.start_date.replace(/-/g, "/"));
    const target = new Date("2018-08-01".replace(/-/g, "/"));
    if (start < target) {
      flg = 1;
    }
    return flg;
  }

  created() {
    this.insCareId = Number(this.$route.params.inscareid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchInsurerMster();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=Care`
      },
      {
        text: "介護保険",
        disabled: true,
        to: ""
      }
    ]);
  }

  destroy() {
    this.clearOcrState();
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //登録
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    //要介護度が非該当の場合チェック
    if (this.care_insurance.need_care_level == 1) {
      if (
        !(await this.$openConfirm(
          "要介護度が非該当のため介護保険が無効になりますが宜しいですか？"
        ))
      ) {
        return;
      }
    }
    //給付率が70%でBeforeStartFlgが1の時チェック
    if (this.care_insurance.payment_rate == "70" && this.BeforeStartFlg == 1) {
      if (
        !(await this.$openConfirm(
          "給付率70％は、平成30年8月1日以降に有効な給付率ですが登録を行いますか？"
        ))
      ) {
        return;
      }
    }

    if (this.mode) {
      this.care_insurance.id = 0;
    }
    this.care_insurance.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/care/save",
      {
        care_insurance: this.care_insurance
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        // OCR 結果から作成成功した場合は OCR ステータスを「作成済み」にする
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.applyOcr();
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  private applyOcr() {
    if (this.ocrState.documentType === 0 || this.ocrState.documentId === 0) {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/ocrstore/mapping/apply",
      {
        document_type: this.ocrState.documentType,
        document_id: this.ocrState.documentId
      },
      () => {
        // do nothing
      }
    );
  }

  //変更
  private update() {
    this.care_insurance.id = this.insCareId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.care_insurance.id = this.insCareId;
      this.care_insurance.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/care/delete",
        {
          care_insurance: this.care_insurance
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  // 保険者一覧作成
  private createIsurerItems() {
    this.isurerItems = this.isurerMaster.map(item => ({
      value: item.id,
      text: `${item.number}/${item.name} (${item.type})`
    }));
  }

  // 保険者検索イベント
  public async changeSearchInsurer(id: number) {
    const info = this.isurerItems.filter(item => item.value == id)[0];
    if (
      this.care_insurance.insurer_no == "" &&
      this.care_insurance.insurer_name == ""
    ) {
      this.inputSearchInsurer(id);
    } else if (
      await this.$openConfirm(`保険者情報を"${info.text}"で置き換えますか？`)
    ) {
      this.inputSearchInsurer(id);
    }
  }

  //保険者検索入れ込み
  private inputSearchInsurer(id: number) {
    const master = this.isurerMaster.filter(item => item.id == id)[0];
    // 自動フィルイン
    this.care_insurance.insurer_no = master.number; // 保険者番号
    this.care_insurance.insurer_name = master.name; // 保険者名
  }

  // 介護保険情報取得
  fetchInsurerMster(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/care/get",
      {
        patient_id: this.patientId,
        ins_care_id: this.insCareId
      },
      res => {
        this.isurerMaster = res.data.insurer;
        this.createIsurerItems();
        //医療保険情報
        if (this.insCareId != 0) {
          const insurance: CareInsurance = res.data.care_insurance;
          if (this.mode) {
            insurance.start_date = "";
            insurance.end_date = "";
          }
          this.care_insurance = insurance;
        }

        this.orgApplicationState = this.care_insurance.application_state;

        // OCR 結果から作成の場合、初期値設定
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.setOcrParams();
        }

        this.$nextTick(() => {
          this.isLoaded = true;
        });
        this.setLoaded();
      }
    );
  }

  private setOcrParams() {
    // 一般
    if (this.ocrState.targetKind === TargetKind.careInsGeneral) {
      this.care_insurance.insured_no = this.ocrState.careIns.insuredNo;
      this.care_insurance.insurer_no = this.ocrState.careIns.insurerNo;
      this.care_insurance.start_date = this.ocrState.careIns.startDate;
      this.care_insurance.end_date = this.ocrState.careIns.endDate;
      this.care_insurance.need_care_level = this.ocrState.careIns.needCareLevel;
      const stationName = this.ocrState.careIns.stationName;
      if (stationName !== "") {
        const cond: SearchItem = {
          corp_name: stationName,
          tel_area: "",
          name: ""
        };
        this.$nextTick(() => {
          if (this.patientRelationInsSelect1) {
            this.patientRelationInsSelect1.openDialogWithCondition(cond);
          }
          if (this.patientRelationInsSelect2) {
            this.patientRelationInsSelect2.openDialogWithCondition(cond);
          }
        });
      }
    }
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //保険者番号：必須切り替え
  private requiredInsurerNo(): boolean | string {
    if (this.care_insurance.application_state == 1) {
      return true;
    }
    return this.required(this.care_insurance.insurer_no, "必須です");
  }

  //保険者名：必須切り替え
  private requiredInsurerName(): boolean | string {
    if (this.care_insurance.application_state == 1) {
      return true;
    }
    return this.required(this.care_insurance.insurer_name, "必須です");
  }

  //被保険者番号：必須切り替え
  private requiredInsuredNo(): boolean | string {
    if (this.care_insurance.application_state == 1) {
      return true;
    }
    if (!this.care_insurance.insured_no.match(/^[A-Za-z0-9]*$/)) {
      return "半角英数字のみ入力可能です";
    }
    return this.required(this.care_insurance.insured_no, "必須です");
  }

  //給付率：桁管理
  private checkPaymentRate(): boolean | string {
    if (this.care_insurance.payment_rate) {
      return this.rangeNumber(
        Number(this.care_insurance.payment_rate),
        0,
        100,
        "給付率は0～100で入力してください"
      );
    }
    return true;
  }

  //要介護度：必須切り替え
  private requiredNeedCareLevel(): boolean | string {
    if (this.care_insurance.application_state == 1) {
      return true;
    }
    return this.minNumber(this.care_insurance.need_care_level, 1, "必須です");
  }

  //居宅サービス計画作成者
  private requiredPlanWriterPersonnel(): boolean | string {
    if (
      this.care_insurance.request_relation_institution_id != 0 &&
      (this.care_insurance.plan_writer_personnel_id == 0 ||
        this.care_insurance.plan_writer_personnel_id == undefined) &&
      (this.care_insurance.need_care_level == 2 ||
        this.care_insurance.need_care_level == 3)
    ) {
      return "必須です";
    }
    return true;
  }
  //居宅サービス計画作成者に地域包括が選択されている場合、地域包括のセレクトボックスを非活性
  private checkPlanWriterPersonnel(): boolean {
    for (const ins of this.patientMaster.relation_institutions) {
      if (ins.support_office_div == 3) {
        for (const personnel of ins.personnels) {
          if (this.care_insurance.plan_writer_personnel_id == personnel.id) {
            this.care_insurance.request_relation_institution_id = 0;
            return true;
          }
        }
      }
    }
    return false;
  }

  /** 申請状態変更時のイベント */
  private async onChangeApplicationState() {
    // 元々ONで保存されており、ON → OFFにした場合にメッセージを出す
    if (
      this.orgApplicationState == 1 &&
      this.care_insurance.application_state == 0
    ) {
      await this.$openAlert(
        "「認定の有効期間/終了日」の登録忘れにご注意ください"
      );
    }
  }

  //-----------------------------------------------------
  // Watch
  //-----------------------------------------------------
  //被保険者番号の先頭がHの場合給付率0
  @Watch("care_insurance.insured_no")
  private handleInsuredNo() {
    if (!this.isLoaded) {
      return;
    }
    if (this.care_insurance.insured_no.slice(0, 1) == "H") {
      this.care_insurance.payment_rate = "0";
    }
  }

  @Watch("care_insurance", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
