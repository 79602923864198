






































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  /** 検索キーワード */
  @Prop() private readonly keyword!: string;

  /** 0:一般職員 1:一般職員以外（一般職員以外は、新規トピックの作成・自分以外のトピックの削除・自分以外のコメントの削除が可能） */
  @Prop() private readonly authDiv!: number;

  /** 検索時 */
  @Emit("search") private search(): void {
    return;
  }

  /** 新規トピック作成時 */
  @Emit("createTopic") private createTopic(): void {
    return;
  }

  /** 検索キーワード */
  @Emit("update:keyword") private updateKeyword(keyword: string): string {
    return keyword;
  }

  /** 検索キーワード */
  private get Keyword(): string {
    return this.keyword;
  }
  private set Keyword(keyword: string) {
    this.updateKeyword(keyword);
  }
}
