




















































import { Component, Emit, Mixins } from "vue-property-decorator";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";
import RulesMixin from "#/mixins/rulesMixin";
import LoginMixin from "#/mixins/loginMixin";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class DoubleLoginArea extends Mixins(
  RulesMixin,
  AxiosMixin,
  LoginMixin
) {
  /** ログイン完了処理 */
  @Emit() private finishLogin() {
    return;
  }

  /** セキュリティコード確認 */
  private doubleLogin() {
    this.loginKeyCode(
      window.auth_backend_url,
      window.axios,
      this.$route.query.security_code as string,
      this.setCookie,
      this.$openAlert
    );
  }

  /** 生体認証 */
  private webauthnLogin() {
    this.loginWebauthnBegin(
      window.auth_backend_url,
      window.axios,
      this.$route.query.security_code as string,
      this.setCookie,
      this.$openAlert
    );
  }

  /** クッキーに設定 */
  private async setCookie(res: AxiosResponse) {
    window.token = res.data.token;
    Cookies.set("token", res.data.token, { domain: window.cookie_domain });
    window.reftoken = res.data.ref_token;
    Cookies.set("reftoken", res.data.ref_token, {
      domain: window.cookie_domain
    });
    if (res.data.not_webauthn_device_registerd) {
      this.finishLoginSetBiometricRegistFlg(true);
    } else {
      this.finishLoginSetBiometricRegistFlg(false);
    }
  }

  /** ログイン終了処理と、生体認証登録フラグの設定 */
  private finishLoginSetBiometricRegistFlg(
    isOpenBiometricRegistDialog: boolean
  ) {
    const value = isOpenBiometricRegistDialog ? "1" : "0";
    Cookies.set("not_webauthn_device_registerd", value, {
      domain: window.cookie_domain
    });
    this.finishLogin();
  }
}
