import Base from "../base";

// 事業書履歴 管理者用メール
export interface OfficeHistoryMail extends Base {
  id: number; //ID
  office_history_id: number; //'事業所履歴ID',
  mail_address: string; //'管理者用メールアドレス',
  notices_occur_report_submitted: boolean; // ヒヤリハット提出時に通知するかどうか
  notices_patient_file_registered: boolean; // ファイル登録時に通知するかどうか
}

export const DefaultOfficeHistoryMail = (): OfficeHistoryMail => ({
  id: 0,
  office_history_id: 0,
  mail_address: "",
  notices_occur_report_submitted: false,
  notices_patient_file_registered: false,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
