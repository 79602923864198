














































































import { Component, Prop } from "vue-property-decorator";
import { Bedsore as BedsoreModel, DefaultBedsore } from "#/model/report";
import { BeforeBedsore } from "@/components/patient/record2/types";
import UtilMixin from "@/mixins/utilMixin";
import * as appDate from "#/utility/appDate";

@Component
export default class Bedsore extends UtilMixin {
  /*
   * prop
   */
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** 褥瘡の配列 */
  @Prop() private bedsores!: BedsoreModel[];

  /** 褥瘡ID */
  @Prop() private readonly part_id!: number;

  /** 褥瘡名 */
  @Prop() private readonly part_name!: string;

  /** 前回評価 */
  @Prop({ default: () => [] }) private readonly before!: BeforeBedsore[];

  /** 評価しないフラグ */
  @Prop({ default: false }) private readonly not_evaluate!: boolean;

  /** 褥瘡部位 */
  private get bedsore(): BedsoreModel {
    const jokuso = this.bedsores.find(
      jokuso =>
        jokuso.jokuso_div == this.part_id &&
        jokuso.other_bedsore_part == this.part_name
    );
    if (jokuso) {
      return jokuso;
    }
    const newJokuso = DefaultBedsore(this.part_id, this.part_name);
    this.bedsores.push(newJokuso);
    return newJokuso;
  }

  /** 前回評価の褥瘡情報 */
  private get beforeBedsore(): BeforeBedsore | undefined {
    if (!this.before) {
      return undefined;
    }
    const beforeJokuso = this.before.find(
      jokuso =>
        jokuso.jokuso_div == this.part_id &&
        jokuso.other_bedsore_part == this.part_name
    );
    return beforeJokuso;
  }

  /** 前回評価日 */
  private get beforeDate(): string {
    if (!this.before) {
      return "";
    }

    const beforeJokuso = this.before.find(
      jokuso =>
        jokuso.jokuso_div == this.part_id &&
        jokuso.other_bedsore_part == this.part_name
    );

    if (!beforeJokuso || !beforeJokuso.date) {
      return "";
    }

    const d = appDate.strToDate(beforeJokuso.date);
    return appDate.dateToStr(d, "前回評価：yyyy年MM月dd日");
  }

  /** 前回評価を参照クリック */
  private clickBeforeEvaluate(value: boolean) {
    if (!value) {
      return;
    }
    if (!this.beforeBedsore) {
      return;
    }
    //computedであるため、deepcopyできない
    this.bedsore.depth = this.beforeBedsore.depth;
    this.bedsore.exudate = this.beforeBedsore.exudate;
    this.bedsore.size = this.beforeBedsore.size;
    this.bedsore.infection = this.beforeBedsore.infection;
    this.bedsore.granulation_tissue = this.beforeBedsore.granulation_tissue;
    this.bedsore.necrotic_tissue = this.beforeBedsore.necrotic_tissue;
    this.bedsore.pocket = this.beforeBedsore.pocket;
  }
}
