

















import { Component, Mixins } from "vue-property-decorator";
import ItemTable from "#/components/ItemTable.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import Normal from "@/components/patient/indicate/Normal.vue";
import Special from "@/components/patient/indicate/Special.vue";
import Template from "@/components/patient/indicate/Template.vue";
import { Link as LinkType } from "#/types";

@Component({
  components: {
    ItemTable,
    PatientHeader,
    Normal,
    Special,
    Template
  }
})
export default class ReportGroup extends Mixins(
  AxiosMixin,
  UtilMixin,
  PatientMixin
) {
  /** 利用者ID */
  private patientId = 0;

  /** 現在有効なメニュー */
  private get Link() {
    for (const item of this.ReportGroupItems) {
      if (this.$route.name == item.key) {
        return item;
      }
    }
    return {
      id: 0,
      title: "",
      link: ""
    };
  }

  created(): void {
    this.patientId = Number(this.$route.params.id);
  }

  mounted() {
    // 有効なリンクの切り替え
    this.setDefaultHeader();
  }

  private setUrlParams({ link }: { link: LinkType }) {
    this.$router.push({
      name: link.key
    });
  }
}
