var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-disease-table"},[_c('AppDataTable',{attrs:{"headers":_vm.medicalDiseaseHeaders,"items":_vm.value,"isNoFooter":true,"isNoTop":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"c-disease-table__top"},[(!_vm.isSort)?_c('div',[_c('AppButton',{staticClass:"mr-2 mb-2",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addDisease.apply(null, arguments)}}},[_vm._v(" ＋ 傷病名を追加する ")]),_c('AppButton',{staticClass:"mb-2",attrs:{"color":"primary","disabled":_vm.Value.length === 0},on:{"click":function($event){return _vm.sortStart()}}},[_vm._v(" 並び替え ")])],1):_vm._e(),(_vm.isSort)?_c('div',{staticClass:"d-flex align-center ml-auto"},[_c('AppButton',{on:{"click":function($event){return _vm.sortCancel()}}},[_vm._v(" キャンセル ")]),_c('AppButton',{staticClass:"ml-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.sortComplete()}}},[_vm._v(" 完了 ")])],1):_vm._e()])]},proxy:true},{key:"item.name",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[_c('div',{staticClass:"c-disease-table__number"},[_vm._v(_vm._s(index + 1)+".")]),_c('div',[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(item.name))]),_vm._l((item.words),function(word,i){return _c('AppDiseaseChip',{key:("suggest-list-" + i),staticClass:"mr-1 mb-1 text-body-2 rounded",staticStyle:{"height":"22px"},attrs:{"word":word,"supCode":true}})})],2)])]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center my-2"},[_c('AppToolTip',{attrs:{"left":"","top":false,"label":("“" + (item.name) + "”を編集する")},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"40px","width":"40px","disabled":_vm.isSort},on:{"click":function($event){$event.stopPropagation();return _vm.editDisease(item)}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"edit","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)}),_c('AppButton',{staticClass:"mx-2",attrs:{"color":"alert","outlined":"","disabled":_vm.isSort},on:{"click":function($event){return _vm.deleteDisease(index)}}},[_vm._v("削除")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }