










































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import { MaintenanceInfo } from "#/model/admin";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class MaintenanceInfoDialog extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  @Prop({ default: false }) isShown!: boolean; //表示フラグ

  @Prop() mainte!: MaintenanceInfo; //掲示板

  //OKボタンクリック
  private clickSave() {
    this.postJsonCheck(
      window.base_url + "/api/master/mainte/save",
      {
        maintenance_info: this.mainte
      },
      () => {
        return;
      }
    );
    this.closeDialog();
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
