






























import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import BatchStaffLinkStart from "@/components/master/batch_staff_link/BatchStaffLinkStart.vue";
import BatchStaffLinkCheckResult from "@/components/master/batch_staff_link/BatchStaffLinkCheckResult.vue";
import BatchStaffLinkSaveResult from "@/components/master/batch_staff_link/BatchStaffLinkSaveResult.vue";
import {
  FileIF,
  DEFAULT_FILEIF,
  BatchStaffLinkCheckResponse,
  DEFAULT_BATCH_STAFF_LINK_CHECK_RESPONSE
} from "@/views/master/batch_staff_link/types";
import * as appDate from "#/utility/appDate";

@Component({
  components: {
    FileUpload,
    BatchStaffLinkStart,
    BatchStaffLinkCheckResult,
    BatchStaffLinkSaveResult
  }
})
/**
 * 職員一括連携画面
 */
export default class BatchStaffLink extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  /** 設定中のファイル情報 */
  private inputFile: FileIF = { ...DEFAULT_FILEIF };

  /** 状態(1:初期 2:入力チェック結果表示 3:連携結果) */
  private state = 1;

  /** 職員一括連携 入力チェック結果情報 */
  private batchStaffLinkCheckRes: BatchStaffLinkCheckResponse = {
    ...DEFAULT_BATCH_STAFF_LINK_CHECK_RESPONSE
  };

  /** 職員一括連携 入力チェック */
  private input() {
    this.postJsonCheck(
      window.base_heavy_url + "/api/master/dataio/stafflink/input",
      {
        file_path: this.inputFile.path,
        file_name: this.inputFile.name
      },
      res => {
        if (!res.data.batch_staff_link_check_res) {
          return;
        }
        // 入力チェック完了後、結果表示へ遷移
        this.batchStaffLinkCheckRes = res.data.batch_staff_link_check_res;
        this.state = 2;
      }
    );
  }

  /** 一括連携 */
  private save() {
    if (!confirm("連携します。よろしいですか？")) {
      return;
    }
    this.postJsonCheck(
      window.base_heavy_url + "/api/master/dataio/stafflink/save",
      {
        data: this.batchStaffLinkCheckRes.data
      },
      () => {
        // 保存完了後
        this.state = 3;
        this.batchStaffLinkCheckRes = {
          ...DEFAULT_BATCH_STAFF_LINK_CHECK_RESPONSE
        };
      }
    );
  }

  /** 処理リセット時 */
  private reset() {
    this.state = 1;
    this.batchStaffLinkCheckRes = {
      ...DEFAULT_BATCH_STAFF_LINK_CHECK_RESPONSE
    };
  }

  /** 職員情報出力 */
  private output() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/master/dataio/stafflink/output",
      {},
      res => {
        if (res.data.size) {
          const fileName = this.getOutputFileName();

          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }

  /** 出力ファイル名取得 */
  private getOutputFileName(): string {
    // 形式:職員情報_yyyyMMddHHmmss.xlsx
    const nowDateObj = appDate.strToDateObj();
    const dateStr = appDate.dateToStr(nowDateObj, "yyyyMMddHHmmss");
    return `職員情報_${dateStr}.xlsx`;
  }
}
