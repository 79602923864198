import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/store/types";
import { mutations } from "@/store/mutations";
import { master } from "@/store/modules/master";
import { patient } from "@/store/modules/patient";
import { masmen } from "@/store/modules/masmen";
import { visitTmpData } from "@/store/modules/visitTmpData";
import { chat } from "@/store/modules/chat";
import { ocr } from "@/store/modules/ocr";
import { bbs } from "@/store/modules/bbs";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: "0.0.1",
    is_edit: false,
    is_registerd_auth_navigation: false,
    is_visit_navigation: false,
    is_save: false,
    is_btn_click: false,
    is_processing: false
  },
  mutations,
  modules: {
    master,
    patient,
    masmen,
    visitTmpData,
    chat,
    ocr,
    bbs
  }
};

export default new Vuex.Store<RootState>(store);
