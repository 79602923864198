export const OcrStatus = {
  standby: 1,
  pending: 2,
  completed: 3,
  created: 4,
  failed: 99
} as const;

export const DocumentType = {
  patient: 1,
  visitRecord: 2,
  vnRecord: 3
} as const;

export const TargetKind = {
  careInsGeneral: 101, //介護保険証
  careInsPaymentRate: 201, //介護保険負担割合証
  mediInsGeneral: 301, //医療保険
  mediInsNational: 302, //医療保険（国保/兼高齢）
  mediInsOldOld: 303, //医療保険（後期高齢）
  indicateNormal: 401, //一般指示書
  indicateMental: 402, //精神指示書
  indicateSpecial: 501, //特別指示書
  indicateMentalSpecial: 502, //精神特別指示書
  mediInsOldAge: 601, //高齢受給者証
  mediInsHighExpense: 701 //高額療養費（70歳未満/70歳以上）
} as const;

export interface OcrAttr {
  key: string; //キー文字列
  type: number; //1:input, 2:textarea, 3:date
}

/** 医療保険証 */
export const MediInsGeneralAttrs: OcrAttr[] = [
  { key: "記号", type: 1 },
  { key: "番号", type: 1 },
  { key: "氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "性別", type: 1 },
  { key: "取得年月日", type: 3 },
  { key: "保険者番号", type: 1 },
  { key: "保険者名称", type: 1 }
];

/** 医療保険証（国保） */
export const MediInsNationalAttrs: OcrAttr[] = [
  { key: "記号", type: 1 },
  { key: "番号", type: 1 },
  { key: "枝番", type: 1 },
  { key: "氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "性別", type: 1 },
  { key: "資格取得年月日", type: 3 },
  { key: "保険者番号", type: 1 },
  { key: "保険者名称", type: 1 },
  { key: "負担割合", type: 1 },
  { key: "有効期限（開始日）", type: 3 },
  { key: "有効期限（終了日）", type: 3 }
];

/** 医療保険証（後期高齢） */
export const MediInsOldOldAttrs: OcrAttr[] = [
  { key: "氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "保険者番号", type: 1 },
  { key: "保険者名称", type: 1 },
  { key: "被保険者番号", type: 1 },
  { key: "取得年月日", type: 3 },
  { key: "発行期日(開始日)", type: 3 },
  { key: "有効期限(終了日)", type: 3 },
  { key: "負担割合", type: 1 }
];

/** 高齢受給者証 */
export const MediInsOldAgeAttrs: OcrAttr[] = [
  { key: "記号", type: 1 },
  { key: "番号", type: 1 },
  { key: "氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "性別", type: 1 },
  { key: "資格取得年月日", type: 3 },
  { key: "保険者番号", type: 1 },
  { key: "保険者名称", type: 1 },
  { key: "負担割合", type: 1 },
  { key: "有効期限（開始日）", type: 3 },
  { key: "有効期限（終了日）", type: 3 },
  { key: "対象非保護者", type: 1 }
];

/** 高額療養費 */
export const MediInsHighExpenseAttrs: OcrAttr[] = [
  { key: "氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "交付年月日", type: 3 },
  { key: "保険者番号", type: 1 },
  { key: "保険者名称", type: 1 },
  { key: "適用区分", type: 1 },
  { key: "開始日", type: 3 },
  { key: "終了日", type: 3 }
];

/** 介護保険証 */
export const CareInsGeneralAttrs: OcrAttr[] = [
  { key: "氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "被保険者番号", type: 1 },
  { key: "保険者番号", type: 1 },
  { key: "要介護度", type: 1 },
  { key: "有効期間開始", type: 3 },
  { key: "有効期間終了", type: 3 },
  { key: "居宅介護支援事業者", type: 1 },
  { key: "介護予防支援事業者", type: 1 }
];

/** 介護保険負担割合証 */
export const CareInsPaymentRateAttrs: OcrAttr[] = [
  { key: "氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "被保険者番号", type: 1 },
  { key: "保険者番号", type: 1 },
  { key: "負担1割開始日", type: 3 },
  { key: "負担1割終了日", type: 3 },
  { key: "負担2割開始日", type: 3 },
  { key: "負担2割終了日", type: 3 },
  { key: "負担3割開始日", type: 3 },
  { key: "負担3割終了日", type: 3 }
];

/** 指示書 */
export const IndicateNormalAttrs: OcrAttr[] = [
  { key: "患者氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "訪問看護指示期間開始", type: 3 },
  { key: "訪問看護指示期間終了", type: 3 },
  { key: "点滴注射指示期間開始", type: 3 },
  { key: "点滴注射指示期間終了", type: 3 },
  { key: "主傷病名１", type: 1 },
  { key: "主傷病名２", type: 1 },
  { key: "主傷病名３", type: 1 },
  { key: "主傷病名その他", type: 1 },
  { key: "病状・治療状態", type: 2 },
  { key: "療養生活指導上の留意事項", type: 2 },
  { key: "在宅患者訪問点滴注射に関する指示", type: 2 },
  { key: "緊急時の連絡先", type: 2 },
  { key: "特記すべき留意事項", type: 2 },
  { key: "医療機関名", type: 1 },
  { key: "医師氏名", type: 1 }
];

/** 精神指示書 */
export const IndicateMentalAttrs: OcrAttr[] = [
  { key: "患者氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "精神指示期間開始", type: 3 },
  { key: "精神指示期間終了", type: 3 },
  { key: "主傷病名１", type: 1 },
  { key: "主傷病名２", type: 1 },
  { key: "主傷病名３", type: 1 },
  { key: "主傷病名その他", type: 1 },
  { key: "病状・治療状況", type: 2 },
  { key: "投与中の薬剤の用量・用法", type: 2 },
  { key: "緊急時の連絡先", type: 2 },
  { key: "特記すべき留意事項", type: 2 },
  { key: "留意事項及び指示事項", type: 2 },
  { key: "医療機関名", type: 1 },
  { key: "医師氏名", type: 1 }
];

/** 特別指示書 */
export const IndicateSpecialAttrs: OcrAttr[] = [
  { key: "患者氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "特別看護指示期間開始", type: 3 },
  { key: "特別看護指示期間終了", type: 3 },
  { key: "点滴注射指示期間開始", type: 3 },
  { key: "点滴注射指示期間終了", type: 3 },
  { key: "病状・主訴", type: 2 },
  { key: "一時的に訪問看護が頻回に必要な理由", type: 2 },
  { key: "留意事項及び指示事項", type: 2 },
  { key: "点滴注射指示内容", type: 2 },
  { key: "緊急時の連絡先等", type: 2 },
  { key: "医療機関名", type: 1 },
  { key: "医師氏名", type: 1 }
];

/** 精神特別指示書 */
export const IndicateMentalSpecialAttrs: OcrAttr[] = [
  { key: "患者氏名", type: 1 },
  { key: "生年月日", type: 3 },
  { key: "特別看護指示期間開始", type: 3 },
  { key: "特別看護指示期間終了", type: 3 },
  { key: "点滴注射指示期間開始", type: 3 },
  { key: "点滴注射指示期間終了", type: 3 },
  { key: "病状・主訴", type: 2 },
  { key: "一時的に訪問看護が頻回に必要な理由", type: 2 },
  { key: "点滴注射指示内容", type: 2 },
  { key: "緊急時の連絡先等", type: 2 },
  { key: "留意事項及び指示事項", type: 2 },
  { key: "特に観察を要する項目", type: 2 },
  { key: "医療機関名", type: 1 },
  { key: "医師氏名", type: 1 }
];
