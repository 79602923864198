
















































































import { Component, Mixins, Ref, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";
import AppSelectTable from "#/components/AppSelectTable.vue";
import { Careplan, CareplanOffice } from "@/components/careplan/careplan";
import { thisMonth } from "#/utility/appDate";
import { SortWarekiDate } from "#/utility/appSort";
import { DataTableHeader } from "vuetify";
import { AxiosResponse } from "axios";

const ServiceType = {
  plan: 1,
  schedule: 2
} as const;

@Component({
  components: {
    AppSelectTable
  }
})
export default class PatientCareplan extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: () => [] }) offices!: CareplanOffice[];

  private patientId = 0;
  private serviceType = 2;
  private targetYearMonth = "";
  private isFetching = false;
  private viewType = 0;
  private careplans: Careplan[] = [];
  private selectedCareplans: Careplan[] = [];

  private get tableHeaders() {
    const headers: DataTableHeader[] = [
      {
        text: "居宅介護支援事業所名",
        value: "care_provider_name",
        filterable: false
      },
      {
        text: "担当者名",
        value: "care_manager_name",
        filterable: false
      }
    ];

    if (this.viewType === ServiceType.plan) {
      headers.push({
        text: "居宅サービス計画作成(変更)日",
        value: "created_date_str",
        filterable: false,
        sort: SortWarekiDate
      });
    } else if (this.viewType === ServiceType.schedule) {
      headers.push({
        text: "作成年月日",
        value: "created_date_str",
        filterable: false,
        sort: SortWarekiDate
      });
    }

    headers.push(
      {
        text: "ケアマネ出力日時",
        value: "timestamp",
        filterable: false,
        sort: SortWarekiDate
      },
      {
        text: "取込日時",
        value: "link_date",
        filterable: false,
        sort: SortWarekiDate
      }
    );

    return headers;
  }

  private created() {
    if (this.$route.query.serviceType) {
      this.serviceType = Number(this.$route.query.serviceType);
    }

    if (this.$route.query.targetYearmonth) {
      this.targetYearMonth = this.$route.query.targetYearmonth as string;
    } else {
      this.targetYearMonth = thisMonth();
    }

    this.patientId = Number(this.$route.params.id);
  }

  private mounted() {
    this.search();
  }

  private search() {
    if (!this.form.validate()) {
      return;
    }

    this.fetchCareplans(res => {
      this.isFetching = false;
      this.careplans = res.data.care_plan_list;
      // チェック状態初期化
      this.selectedCareplans = [];
      // 画面タイプ切り替え
      this.viewType = this.serviceType;
    });
  }

  private fetchCareplans(
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: () => void = () => {
      return;
    }
  ) {
    this.isFetching = true;

    this.postJsonBackground(
      window.base_url + "/api/patient/careplan/careplans/get",
      {
        patient_id: this.patientId,
        target_yearmonth: this.targetYearMonth,
        service_type: this.serviceType,
        // 利用者の画面は過去の取り込み分の計画書も表示する。そのためのフラグ
        is_need_withdrawn: 1,
        is_need_all_service_plan: 1
      },
      res => {
        this.isFetching = false;
        sucessFunc(res);
      },
      () => {
        this.isFetching = false;
        catchFunc();
      }
    );
  }

  private recordClass(item: Careplan) {
    if (item.is_deleted) {
      return "patient-careplan-list-table-record-delete";
    }

    return "";
  }

  private showWithdrawAlert() {
    this.$openConfirm(
      "選択したケアプランデータを削除します。\nこの操作は取り消せません。\nよろしいですか？"
    ).then(ok => {
      if (ok) {
        this.withdrawCareplans();
      }
    });
  }

  // 物理削除は初弾リリースでは実装しない 将来実装予定があるのでコメントアウトして残しておく
  // private showDeleteAlert() {
  //   this.$openConfirm(
  //     "選択したケアプランデータを削除します。取り下げ済みのケアプランデータのみが削除対象となります。\nこの操作は取り消せません。\nよろしいですか？"
  //   ).then(ok => {
  //     if (ok) {
  //       this.deleteCareplans();
  //     }
  //   });
  // }

  private withdrawCareplans() {
    const carePlanIds = this.selectedCareplans.map(careplan => {
      return careplan.care_plan_id;
    });

    if (carePlanIds.length === 0) {
      this.$openAlert("取り下げ可能なデータがありません");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/careplan/withdraw",
      { care_plan_ids: carePlanIds },
      () => {
        this.fetchCareplans(res => {
          this.careplans = res.data.care_plan_list;
        });
      }
    );
  }

  // 物理削除は初弾リリースでは実装しない 将来実装予定があるのでコメントアウトして残しておく
  // private deleteCareplans() {
  //   const carePlanIds = this.selectedCareplans.map(careplan => {
  //     return careplan.care_plan_id;
  //   });

  //   if (carePlanIds.length === 0) {
  //     this.$openAlert("削除可能なデータがありません");
  //     return;
  //   }

  //   this.postJsonCheck(
  //     window.base_url + "/api/careplan/delete",
  //     { care_plan_ids: carePlanIds },
  //     () => {
  //       this.fetchCareplans(
  //         (res) => {
  //           this.careplans = res.data.care_plan_list;
  //         },
  //       );
  //     },
  //   );
  // }

  private previewPlans() {
    const carePlanIds = this.selectedCareplans.map(careplan => {
      return careplan.care_plan_id;
    });

    if (carePlanIds.length === 0) {
      this.$openAlert("表示可能なデータがありません");
      return;
    }

    let path = "";
    let param;
    if (this.viewType === ServiceType.plan) {
      path = "plan/preview";
      param = {
        service_plan_ids: carePlanIds
      };
    } else if (this.viewType === ServiceType.schedule) {
      path = "schedule/preview";
      param = {
        service_schedule_ids: carePlanIds,
        target_yearmonth: this.targetYearMonth
      };
    } else {
      // failsafe
      return;
    }

    this.makePdf(window.base_heavy_url + "/api/careplan/" + path, param);
  }
}
