











import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class StaffSelect extends Vue {
  @Prop({ default: "事業所職員" }) label!: string;

  @Prop() value!: number;

  @Prop() staffs!: unknown;

  private get Value(): number {
    return this.value;
  }

  private set Value(newValue: number) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: number) {
    return newValue;
  }
}
