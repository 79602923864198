






















import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { CalendarEvent, DefaultCalendarEvent } from "@/types";

@Component
export default class SituationDialog extends Mixins() {
  /** 表示中のイベント */
  @Prop({ default: () => DefaultCalendarEvent() })
  private readonly event!: CalendarEvent;

  /** 表示用日時 */
  private dateTime = "";

  /** ダイアログ開閉状態 */
  private isOpen = false;

  /** 編集 */
  @Emit() private edit() {
    this.close();
    return this.event.patient_situation;
  }

  /** ダイアログを閉じる */
  private close() {
    this.isOpen = false;
  }

  /** ダイアログを開く */
  public open() {
    this.isOpen = true;
  }
}
