var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mr-n4",attrs:{"no-gutters":""}},[_vm._l((_vm.shareFiles),function(sharefile){return _c('v-card',{key:sharefile.id,staticClass:"ibow2-file-card d-flex flex-column rounded-lg mr-4 mb-4",class:_vm.cardSize,attrs:{"tile":"","justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isPatient)?_c('v-img',_vm._g(_vm._b({staticClass:"ibow2-file-card-thumb ibow2-file-card-clickable flex-grow-0",attrs:{"src":sharefile.thumbnail_file_path || '/images/share/no-image.png'},on:{"click":function($event){$event.stopPropagation();return _vm.previewFile(sharefile.patient_file.file_path)}}},'v-img',attrs,false),on),[_c('v-icon',{staticClass:"ibow2-file-card-type d-flex justify-end align-end",attrs:{"size":"24","color":sharefile.patient_file.file_name
                  ? _vm.getFileInfo(sharefile.patient_file.file_name).iconColor
                  : ''}},[_vm._v(" "+_vm._s(sharefile.patient_file.file_name ? _vm.getFileInfo(sharefile.patient_file.file_name).icon : "")+" ")])],1):_c('v-img',_vm._g(_vm._b({staticClass:"ibow2-file-card-thumb ibow2-file-card-clickable flex-grow-0",attrs:{"src":sharefile.thumbnail_file_path || '/images/share/no-image.png'},on:{"click":function($event){$event.stopPropagation();return _vm.previewFile(sharefile.path)}}},'v-img',attrs,false),on),[_c('v-icon',{staticClass:"ibow2-file-card-type d-flex justify-end align-end",attrs:{"size":"24","color":_vm.getFileInfo(sharefile.file_name).iconColor}},[_vm._v(" "+_vm._s(_vm.getFileInfo(sharefile.file_name).icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("ファイルを開く")])]),(_vm.isPatient)?_c('v-card-title',{staticClass:"ibow2-file-card-title pt-4 px-2 pb-1"},[_c('div',[_c('a',{staticClass:"primary--text",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.onClickPatient(sharefile.patient_id)}}},[_vm._v(_vm._s(sharefile.patient_name))])])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(_vm.isPatient)?_c('div',_vm._g(_vm._b({staticClass:"ibow2-file-card-clickable flex-grow-1",on:{"click":function($event){return _vm.editPatientShareFile(sharefile)}}},'div',attrs,false),on),[_c('v-card-title',{staticClass:"ibow2-file-card-title pa-2 pt-1"},[_vm._v(" "+_vm._s(sharefile.patient_file.file_name)+" ")]),(_vm.search.cards_size !== 0)?_c('v-row',{staticClass:"pl-2",attrs:{"no-gutters":""}},_vm._l((sharefile.tags),function(tag){return _c('v-chip',{key:tag.value,staticClass:"mr-2 mb-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(tag.text)+" ")])}),1):_vm._e()],1):_c('div',_vm._g(_vm._b({staticClass:"ibow2-file-card-clickable flex-grow-1",on:{"click":function($event){return _vm.edit(sharefile.id)}}},'div',attrs,false),on),[_c('v-card-title',{staticClass:"ibow2-file-card-title pa-2"},[_vm._v(" "+_vm._s(sharefile.file_name)+" ")]),(_vm.search.cards_size !== 0)?_c('v-row',{staticClass:"pl-2",attrs:{"no-gutters":""}},_vm._l((sharefile.hash_tags),function(tag){return _c('v-chip',{key:tag.value,staticClass:"mr-2 mb-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(tag.text)+" ")])}),1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("ファイルを編集する")])]),_c('div',{staticClass:"ibow2-file-card-copy-button"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('AppButton',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"40px","width":"40px"},on:{"click":function($event){$event.stopPropagation();{
                  if (_vm.isPatient) {
                    _vm.copyPatientShareFile(sharefile);
                  } else {
                    _vm.copy(sharefile.id);
                  }
                }}}},'AppButton',attrs,false),on),[_c('AppIcon',{attrs:{"icon":"duplicate","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)]}}],null,true)},[_c('span',[_vm._v("有効期限のないリンクをクリップボードにコピーする")])])],1)],1)}),(!_vm.shareFiles.length)?_c('p',{staticClass:"grey-light--text mx-auto my-0"},[_vm._v(" "+_vm._s(_vm.noDataText)+" ")]):_vm._e()],2),_vm._t("bottom"),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }