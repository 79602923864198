















import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class StateButton extends Mixins(UtilMixin) {
  @Prop({ default: "絞り込み" }) private label!: string;

  @Prop() value!: boolean;

  @Prop({ default: false }) private disabled!: boolean;

  private get BgColor() {
    if (this.disabled) {
      // コンポーネントのデフォルト指定に任せる
      return null;
    }
    if (this.value) {
      return "rgb(251,245,229) !important";
    } else {
      return this.$vuetify.theme.themes.light.white + " !important";
    }
  }

  private get Color() {
    if (this.disabled) {
      // コンポーネントのデフォルト指定に任せる
      return null;
    }
    if (this.value) {
      return "warning";
    } else {
      return "grey";
    }
  }
}
