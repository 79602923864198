import Base from "../base";
import { ApplyPersonnel, DefaultApplyPersonnel } from "../common";

//介護保険
export interface CareInsurance extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  start_date: string; //'開始日（認定の有効期間）',
  end_date: string; //'終了日（認定の有効期間）',
  insurer_no: string; //'保険者番号',
  insurer_name: string; //'保険者名',
  insured_no: string; //'被保険者番号',
  payment_rate: string; //'給付率（%）',
  need_care_level: number; //'要介護度\n0:未選択\n1:非該当\n2:要支援1\n3:要支援2\n4:経過的要介護（未使用）\n5:要介護1\n6:要介護2\n7:要介護3\n8:要介護4\n9:要介護5',
  application_state: number; //'申請状態\n0:申請中以外\n1:申請中',
  plan_writer_personnel_id: number; //'居宅サービス計画作成担当者ID',
  request_relation_institution_id: number; //'依頼元関連機関ID',
  certification_date: string; //'認定年月日',
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
  certification_date_wareki: string; //認定年月日(和暦)
  plan_writer_personnel: ApplyPersonnel; //居宅サービス計画作成担当者
  request_relation_institution: ApplyPersonnel; //依頼元関連機関
}

export const DefaultCareInsurance = (): CareInsurance => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  insurer_no: "",
  insurer_name: "",
  insured_no: "",
  payment_rate: "",
  need_care_level: 0,
  application_state: 0,
  plan_writer_personnel_id: 0,
  request_relation_institution_id: 0,
  certification_date: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: "",
  certification_date_wareki: "",
  plan_writer_personnel: DefaultApplyPersonnel(),
  request_relation_institution: DefaultApplyPersonnel()
});
