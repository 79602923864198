





























import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
@Component
export default class AiRecommendDisease extends Mixins(AxiosMixin, UtilMixin) {
  /** 変数 */

  /** 病名一覧 */
  private diseases: string[] = [];

  private created() {
    this.fetch();
  }

  /** 病名一覧を取得 */
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/ai/diseases/get", {}, res => {
      this.diseases = res.data.diseases;
    });
  }
}
