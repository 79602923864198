












































import { Component, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class NoticeMessage extends Mixins(UtilMixin, AxiosMixin) {
  @Prop() infoList!: string[]; // お知らせメッセージ
  @Prop() cautionList!: string[]; // 要対応メッセージ
}
