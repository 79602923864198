import AppChatRoomListItem from "./AppChatRoomListItem.vue";

export default {
  title: "Example/AppChatRoomListItem",
  component: AppChatRoomListItem,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatRoomListItem },
  template: '<AppChatRoomListItem v-bind="$props" />'
});

export const HasIcon = Template.bind({});
export const NoIcon = Template.bind({});

HasIcon.args = {
  roomName: "なかよしぐるーぷ",
  unreadCount: 2,
  roomIconPath:
    "https://4.bp.blogspot.com/-7DLdBODmEqc/VCIitQRzAWI/AAAAAAAAmeY/g1fjm8NqyaI/s800/animal_arupaka.png"
};

NoIcon.args = {
  roomName: "なかよしぐるーぷ",
  unreadCount: 2
};
