import Base from "./base";
import { ApplyCareContent, ApplyStaff, DefaultApplyStaff } from "./common";

//看護内容
export interface CareContent {
  id: number; //ID
  care_large_content_id: number; //'看護内容大項目ID',
  care_medium_content_id: number; //'看護内容中項目ID',
  care_text: string; //'看護内容テキスト'
  care_content: ApplyCareContent;
}

//観察項目
export interface Observation {
  id: number; //ID
  observation_category_item_id: number; //'観察項目カテゴリID',
  observation_item_id: number; //'観察項目ID',
  observation_select_item_id: number; //'観察項目選択肢ID',
  observation_comment: string; //'観察項目コメント',
}

//観察項目初期化
export const DefaultObservation = (
  categoyId: number,
  itemId: number
): Observation => ({
  id: 0, //ID
  observation_category_item_id: categoyId, //'観察項目カテゴリID',
  observation_item_id: itemId, //'観察項目ID',
  observation_select_item_id: 0, //'観察項目選択肢ID',
  observation_comment: "" //'観察項目コメント',
});

//褥瘡
export interface Bedsore {
  /** ID */
  id: number;

  /** 褥瘡区分 1:仙骨部 2:坐骨部 3:尾骨部 4:腸骨部 5:大転子部 6:踵部 7:その他 */
  jokuso_div: number;

  /** 褥瘡名前（その他） */
  jokuso_name: string;

  /** 評価しないフラグ */
  is_not_evaluate: number;

  /** 前回評価を参照チェック */
  copy_recent_evaluation_part: number;

  /** 深さ 0:なし 1:持続する発赤 2:真皮までの損傷 3:皮下組織までの損傷 4:皮下組織をこえる損傷 5:関節腔、体腔にいたる損傷 99:深さ判定が不能な場合(U) */
  depth: number;

  /** 滲出液 0:なし 1:少量：毎日の交換を要しない 3:中等量：1日1回の交換 6:多量：1日2回以上の交換 */
  exudate: number;

  /** 大きさ 0:皮膚損傷なし 3:4未満 6:4以上16未満 8:16以上36未満 9:36以上64未満 12:64以上100未満 15:100以上 */
  size: number;

  /** 炎症・感染 0:局所の炎症徴候なし 1:局所の炎症徴候あり 3:局所の明らかな感染徴候あり 9:全身的な影響あり */
  infection: number;

  /** 肉芽形成 0:創閉鎖又は創が浅い為評価不能 1:創面の90％以上を占める 3:創面の50％以上90％未満を占める 4:創面の10%以上50％未満を占める 5:創面の10％未満を占める 6:全く形成されていない */
  granulation_tissue: number;

  /** 壊死組織 0:なし 3:柔らかい壊死組織あり 6:硬く厚い密着した壊死組織あり */
  necrotic_tissue: number;

  /** ポケット 0:なし 6:4未満 9:4以上16未満 12:16以上36未満 24:36以上 */
  pocket: number;

  /** その他部位名 */
  other_bedsore_part: string;
}

export const DefaultBedsore = (
  part_id: number,
  part_name: string
): Bedsore => ({
  id: 0,
  jokuso_div: part_id,
  jokuso_name: part_name,
  is_not_evaluate: 0,
  copy_recent_evaluation_part: 0,
  depth: 0,
  exudate: 0,
  size: 0,
  infection: 0,
  granulation_tissue: 0,
  necrotic_tissue: 0,
  pocket: 0,
  other_bedsore_part: part_name
});

//PDF
export interface Pdf {
  id: number; //ID
  pdf_path: string; //'PDFファイルパス',
}
export const DefaultPdf = (): Pdf => ({
  id: 0,
  pdf_path: ""
});

//差し戻し
export interface Remand extends Base {
  id: number; //ID
  editor_staff_id: number; //'編集者ID',
  comment: string; //'差し戻しコメント',
  editor_staff: ApplyStaff; //編集者
}
export const DefaultRemand = (): Remand => ({
  id: 0,
  editor_staff_id: 0,
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  editor_staff: DefaultApplyStaff()
});

//危険因子
export interface RiskFactor {
  id: number; //ID
  basic_action1: number; //'基本動作能力（ベッド上 自立体位変換)\n0:未選択\n1:できる\n2:できない',
  basic_action2: number; //'基本動作能力（イス上 座位姿勢の保持、除圧)\n0:未選択\n1:できる\n2:できない',
  bone: number; //'病的骨突出\n0:未選択\n1:なし\n2:あり',
  joint: number; //'関節拘縮\n0:未選択\n1:なし\n2:あり',
  nutrition: number; //'栄養状態低下\n0:未選択\n1:なし\n2:あり',
  skin: number; //'皮膚湿潤(多汗、尿失禁、便失禁)\n0:未選択\n1:なし\n2:あり',
  edema: number; //'浮腫(局所以外の部位)\n0:未選択\n1:なし\n2:あり',
  skin_tear: number; //'皮膚の脆弱性(スキン―テアの保有、既往)\n0:未選択\n1:なし\n2:あり',
  jokuso_valuation: string; //'褥瘡の状態：危険因子の評価',
  copy_recent_evaluation_risk_factor: number; // 前回評価を参照チェック 危険因子
}
export const DefaultRiskFacotr = (): RiskFactor => ({
  id: 0,
  basic_action1: 0,
  basic_action2: 0,
  bone: 0,
  joint: 0,
  nutrition: 0,
  skin: 0,
  edema: 0,
  skin_tear: 0,
  jokuso_valuation: "",
  copy_recent_evaluation_risk_factor: 0
});

//バイタル
export interface Vital {
  id: number; //ID
  vital_count: number; //'測定回数',
  body_temprature: number; //'体温',
  body_temprature_int: number; //'体温（整数部）',
  body_temprature_dec: number; //'体温（小数部）',
  breath: string; //'呼吸回数/分',
  breath_div: number; //'呼吸区分\n0:平静\n1:努力様',
  pulse: string; //'脈拍回数/分',
  pulse_div: number; //'脈拍区分\n0:平静\n1:努力様',
  bp_high_right: string; //'最高血圧（右腕）',
  bp_low_right: string; //'最低血圧（右腕）',
  bp_high_left: string; //'最高血圧（左腕）',
  bp_low_left: string; //'最低血圧（左腕）',
  spo2: string; //'SpO2（%）',
  spo2_amount: string; //'呼吸器酸素流量',
  loc_div: number; //'意識レベル\n1:良好\n2:Ⅰ－１\n3:Ⅰ－２\n4:Ⅰ－３\n5:Ⅱ－１０\n6:Ⅱ－２０\n7:Ⅱ－３０\n8:Ⅲ－１００\n9:Ⅲ－２００\n10:Ⅲ－１００',
  blood_sugar: string; //'血糖値',
  blood_sugar_timing: number; //'血糖値測定時間\n1:食前\n2:食後1時間未満\n3:食後2時間未満\n4:食後3時間未満\n5:食後4時間未満\n6:食後5時間未満\n7:食後6時間未満\n8:食後7時間未満\n9:食後8時間未満\n99:不明',
  weight: string; //'体重',
  abd_girth_navel: string; //'臍上腹囲',
  abd_girth: string; //'最大腹囲',
  tan_div: number; //'痰\n0:なし\n1:あり',
  lung_noise_div: number; //'肺副雑音\n0:なし\n1:あり',
  daily_life_rate_div: number; //'日常生活自立度\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2',
}

export const DefaultVital = (): Vital => ({
  id: 0, //ID
  vital_count: 1, //'測定回数',
  body_temprature: 0, //'体温',
  body_temprature_int: 99, //'体温（整数部）',
  body_temprature_dec: 99, //'体温（小数部）',
  breath: "", //'呼吸回数/分',
  breath_div: 0, //'呼吸区分\n0:平静\n1:努力様',
  pulse: "", //'脈拍回数/分',
  pulse_div: 0, //'脈拍区分\n0:平静\n1:努力様',
  bp_high_right: "", //'最高血圧（右腕）',
  bp_low_right: "", //'最低血圧（右腕）',
  bp_high_left: "", //'最高血圧（左腕）',
  bp_low_left: "", //'最低血圧（左腕）',
  spo2: "", //'SpO2（%）',
  spo2_amount: "", //'呼吸器酸素流量',
  loc_div: 0, //'意識レベル\n1:良好\n2:Ⅰ－１\n3:Ⅰ－２\n4:Ⅰ－３\n5:Ⅱ－１０\n6:Ⅱ－２０\n7:Ⅱ－３０\n8:Ⅲ－１００\n9:Ⅲ－２００\n10:Ⅲ－１００',
  blood_sugar: "", //'血糖値',
  blood_sugar_timing: 0, //'血糖値測定時間\n1:食前\n2:食後1時間未満\n3:食後2時間未満\n4:食後3時間未満\n5:食後4時間未満\n6:食後5時間未満\n7:食後6時間未満\n8:食後7時間未満\n9:食後8時間未満\n99:不明',
  weight: "", //'体重',
  abd_girth_navel: "", //'臍上腹囲',
  abd_girth: "", //'最大腹囲',
  tan_div: 0, //'痰\n0:なし\n1:あり',
  lung_noise_div: 0, //'肺副雑音\n0:なし\n1:あり',
  daily_life_rate_div: 0 //'日常生活自立度\n1:J1\n2:J2\n3:A1\n4:A2\n5:B1\n6:B2\n7:C1\n8:C2',
});

//ファイル
export interface File {
  id: number; //ID
  file_type: number; //'写真種別\n1:訪問へ行く\n2:記録書Ⅱをスキャンする',
  path: string; //'画像パス',
  comment: string; //'コメント',
}
export const DefaultFile = (fileType: number): File => ({
  id: 0,
  file_type: fileType,
  path: "",
  comment: ""
});
