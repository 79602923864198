import { Vue, Component } from "vue-property-decorator";
import {
  ColumnKey,
  FilterClause,
  IsFilterMatch
} from "../components/selectFilter/filter";
import { FilterableTableHeader, TableItemNest } from "../types/table";
import * as SELECT_FILTER from "../const/selectFilter";

/**
 * v-data-tableのフィルタMixin
 */
@Component
export default class TableMixin extends Vue {
  /** 絞り込み条件も含めた表ヘッダー */
  protected filterableHeaders(headers: FilterableTableHeader[]) {
    return headers.map(column => {
      column.filter = val =>
        IsFilterMatch(this.filterClauses, column.value, val);
      return column;
    });
  }

  /** 選択肢一覧情報（呼び出し元から受け取る必要がある情報） */
  protected choiceColumnKey(
    headers: FilterableTableHeader[],
    items: unknown[]
  ): ColumnKey[] {
    return headers
      .filter(column => column.filter != null)
      .map(column => {
        const type = column.filterType ?? SELECT_FILTER.FILTER_TYPE.EDIT;
        let selectable: Array<string> | null = null;
        if (
          column.filterType === SELECT_FILTER.FILTER_TYPE.LIST ||
          column.filterType === SELECT_FILTER.FILTER_TYPE.EDIT_LIST
        ) {
          // 選択肢一覧、重複なし
          const selectUnique = new Set<string>();
          items.forEach(item => {
            const cellVal = this.getNestValue(
              item as TableItemNest,
              column.value
            );
            if (
              cellVal != null &&
              cellVal !== "" &&
              cellVal !== SELECT_FILTER.NO_DATA
            ) {
              // 値があれば選択肢に追加
              selectUnique.add(String(cellVal));
            }
          });
          selectable = [];
          // データ中全ての値が空なら→「データなし」「データあり」
          // データ中どれか一つでも値が空なら→「データなし」「...」「...」「データあり」
          // 逆に空の値がなければ→「データなし」「...」「...」「データあり」
          selectable.push(SELECT_FILTER.NO_DATA);
          // データの途中に「データあり」がある場合、最後に移動する
          selectUnique.delete(SELECT_FILTER.EXIST_DATA);
          selectable.push(...selectUnique);
          selectable.push(SELECT_FILTER.EXIST_DATA);
        }
        const columnKey: ColumnKey = {
          type: type,
          key: column.value,
          text: column.text,
          selectable: selectable
        };
        return columnKey;
      });
  }

  /** 設定中のフィルター */
  protected filterClauses: FilterClause[] = [];

  /** ネストも考慮し、オブジェクトの値を取得 */
  protected getNestValue(
    object: TableItemNest,
    propertyPath: string
  ): unknown | undefined {
    if (!object) {
      return undefined;
    }
    // { a: {b: 1}, c: 2 }を対象に、"a.b"なら1が、"c"なら2が得られる
    const propertyArray = propertyPath.split(".");
    for (const prop of propertyArray) {
      if (typeof object !== "object" || object === null) {
        // 途中でネストの最後に到達した場合
        // つまり{ a: {b: 1}, c: 2 }を対象に、"a.b.what"や、"c.what"を指定した場合
        return undefined;
      }
      if (!prop || object[prop] == undefined) {
        // プロパティ名が空文字・プロパティが存在しない・プロパティ値がnullかundefined場合
        return object[prop];
      }
      object = object[prop] as TableItemNest;
    }
    return object;
  }
}
