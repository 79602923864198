import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Rule, VisitSchdule } from "#/model/schedule";
import { CalendarEvent, DefaultCalendarEvent } from "@/types";
import { ApplyStaff, DefaultApplyStaff } from "#/model/common";
import { OfficeStaffChoice } from "#/model/staff";
import { RepeatDiv, RepeatPeriodDiv } from "#/components/calendar/common";
import * as appDate from "#/utility/appDate";
import { Choice } from "@/types";

@Component
export default class ScheduleAssistantVisitDates extends Mixins(AxiosMixin) {
  /** 訪問予定 */
  @Prop({ default: () => ({}) }) protected visitSchedule!: VisitSchdule;
  /** ルール */
  @Prop({ default: () => ({}) }) private rule!: Rule;
  /** 主訪問者 */
  @Prop({ default: () => 0 }) private mainStaff!: number;
  /** 副訪問者１ */
  @Prop({ default: () => 0 }) private subStaff1!: number;
  /** 副訪問者２ */
  @Prop({ default: () => 0 }) private subStaff2!: number;
  /** 職員一覧 */
  @Prop({ default: () => [] }) protected staffs!: OfficeStaffChoice[];
  /** 利用者一覧 */
  @Prop({ default: () => [] }) protected patients!: Choice[];

  /** 繰り返し区分 */
  private RepeatDiv = RepeatDiv;

  /** 訪問者一覧 */
  protected get Visitors(): number[] {
    let visitors: number[] = [];
    if (this.mainStaff > 0) {
      visitors = visitors.concat(this.mainStaff);
    }
    if (this.subStaff1 > 0) {
      visitors = visitors.concat(this.subStaff1);
    }
    if (this.subStaff2 > 0) {
      visitors = visitors.concat(this.subStaff2);
    }
    return visitors;
  }

  /** 訪問開始時間 */
  protected get StartTime(): string {
    if (this.rule.repeat_state === RepeatDiv.None) {
      return (
        ("00" + this.visitSchedule.shift_start_time_hour).slice(-2) +
        ":" +
        ("00" + this.visitSchedule.shift_start_time_minute).slice(-2)
      );
    } else {
      return (
        ("00" + this.rule.shift_start_time_hour).slice(-2) +
        ":" +
        ("00" + this.rule.shift_start_time_minute).slice(-2)
      );
    }
  }

  /** 訪問開始スクロール時間 */
  protected get ScrollTime(): string {
    if (this.rule.repeat_state === RepeatDiv.None) {
      const hour = this.visitSchedule.shift_start_time_hour - 1;
      return (
        ("00" + hour).slice(-2) +
        ":" +
        ("00" + this.visitSchedule.shift_start_time_minute).slice(-2)
      );
    } else {
      const hour = this.rule.shift_start_time_hour - 1;
      return (
        ("00" + hour).slice(-2) +
        ":" +
        ("00" + this.rule.shift_start_time_minute).slice(-2)
      );
    }
  }

  /** 訪問終了時間 */
  protected get EndTime(): string {
    if (this.rule.repeat_state === RepeatDiv.None) {
      return (
        ("00" + this.visitSchedule.shift_end_time_hour).slice(-2) +
        ":" +
        ("00" + this.visitSchedule.shift_end_time_minute).slice(-2)
      );
    } else {
      return (
        ("00" + this.rule.shift_end_time_hour).slice(-2) +
        ":" +
        ("00" + this.rule.shift_end_time_minute).slice(-2)
      );
    }
  }

  /** 終日フラグ */
  protected get IsAll(): boolean {
    if (this.rule.repeat_state === RepeatDiv.None) {
      return this.visitSchedule.all_day_flg == 1;
    } else {
      return this.rule.all_day_flg == 1;
    }
  }

  /** 訪問イベント一覧 */
  public get VisitDates(): CalendarEvent[] {
    //
    // 繰り返し なし
    //
    const visitStaffs: ApplyStaff[] = [];
    for (const id of this.Visitors) {
      const staffName = this.getStaffName(id);
      visitStaffs.push({
        ...DefaultApplyStaff(),
        id: id,
        ibow_nickname: staffName
      });
    }

    if (this.rule.repeat_state === RepeatDiv.None) {
      // 訪問日をそのまま返す
      if (
        this.visitSchedule.visit_start_date &&
        this.visitSchedule.visit_end_date
      ) {
        let start = this.visitSchedule.visit_start_date + " " + this.StartTime;
        let end = this.visitSchedule.visit_end_date + " " + this.EndTime;
        if (this.visitSchedule.all_day_flg) {
          start = this.visitSchedule.visit_start_date;
          end = this.visitSchedule.visit_end_date;
        }
        return [
          {
            ...DefaultCalendarEvent(),
            start_day: this.visitSchedule.visit_start_date,
            end_day: this.visitSchedule.visit_end_date,
            start: start,
            end: end,
            visitor_ids: this.Visitors,
            visitors: visitStaffs,
            timed: !this.visitSchedule.all_day_flg,
            schedule_id: this.visitSchedule.id,
            rule_id: -1
            // event_type: this.visitSchedule.event_type,
            // event_title: this.visitSchedule.event_title || ""
          }
        ];
      }
      return [];
    }

    //
    // 繰り返し
    //

    if (!this.rule.visit_limit_start_date) {
      return [];
    }
    // 開始日
    const startDate = appDate.strToDate(this.rule.visit_limit_start_date);
    // 終了日（Max一年後まで）
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 365);

    // 期限日付
    if (this.rule.visit_limit === RepeatPeriodDiv.Date) {
      if (!this.rule.visit_limit_end_date) {
        return [];
      }
      // 期限:日付 終了日の入力なし
      endDate = appDate.strToDate(this.rule.visit_limit_end_date);
    }
    if (
      this.rule.visit_limit === RepeatPeriodDiv.Count &&
      !this.rule.visit_limit_count
    ) {
      // 期限:回数 回数の入力なし
      return [];
    }

    if (
      this.rule.repeat_state === RepeatDiv.EveryMonthSpecifiedDate &&
      !this.rule.visit_day
    ) {
      // 繰り返し:毎月（日付指定） 日付の入力なし
      return [];
    }

    // 訪問日一覧
    const visitDates: CalendarEvent[] = [];

    // 対象日
    const targetDate = new Date(startDate);

    // 訪問日展開数
    let expenseCnt = 0;

    // 終了判定までループして訪問日を展開する
    while (!this.isExpenseEventLimit(targetDate, endDate, expenseCnt)) {
      // 訪問日展開判定
      if (this.isExpenseEvent(targetDate, startDate)) {
        // 訪問日を展開
        const tmpDate = new Date(targetDate);
        if (
          this.rule.shift_start_time_hour > this.rule.shift_end_time_hour ||
          (this.rule.shift_start_time_hour == this.rule.shift_end_time_hour &&
            this.rule.shift_start_time_minute > this.rule.shift_end_time_minute)
        ) {
          tmpDate.setDate(tmpDate.getDate() + 1);
        }
        let start =
          appDate.dateToStr(targetDate, "yyyy-MM-dd") + " " + this.StartTime;
        let end = appDate.dateToStr(tmpDate, "yyyy-MM-dd") + " " + this.EndTime;
        if (this.rule.all_day_flg) {
          start = appDate.dateToStr(targetDate, "yyyy-MM-dd");
          end = appDate.dateToStr(tmpDate, "yyyy-MM-dd");
        }
        visitDates.push({
          ...DefaultCalendarEvent(),
          start_day: appDate.dateToStr(targetDate, "yyyy-MM-dd"),
          end_day: appDate.dateToStr(tmpDate, "yyyy-MM-dd"),
          start: start,
          end: end,
          visitor_ids: this.Visitors,
          visitors: visitStaffs,
          timed: !this.rule.all_day_flg,
          schedule_id: this.visitSchedule.id,
          rule_id: this.rule.id
          // event_type: this.visitSchedule.event_type,
          // event_title: this.visitSchedule.event_title || ""
        });
        // 展開数をカウントアップ
        expenseCnt++;
      }
      // 対象日を進める
      targetDate.setDate(targetDate.getDate() + 1);
    }
    return visitDates;
  }

  /** 職員名を取得する */
  private getStaffName(staffId: number): string {
    const staff = this.staffs.find(staff => staff.value == staffId);
    if (staff) {
      return staff.nick_name;
    }
    return "";
  }

  // 訪問日作成終了判定
  private isExpenseEventLimit(
    targetDate: Date,
    endDate: Date,
    cnt: number
  ): boolean {
    if (targetDate.getTime() > endDate.getTime()) {
      return true;
    }
    if (this.rule.visit_limit === RepeatPeriodDiv.Count) {
      // 期限:回数
      return cnt >= this.rule.visit_limit_count;
    }
    return false;
  }

  // 訪問日作成日判定
  private isExpenseEvent(targetDate: Date, startDate: Date): boolean {
    switch (this.rule.repeat_state) {
      case RepeatDiv.EveryDay: // 毎日
        return true;
      case RepeatDiv.EveryWeek: // 毎週
        // 対象の週、かつ、曜日か判定
        return (
          this.isTargetWeek(
            targetDate,
            startDate,
            this.rule.visit_interval_week
          ) && this.isTargeWeekDay(targetDate, this.rule.visit_week)
        );
      case RepeatDiv.EveryMonthSpecifiedDate: // 毎月（日付指定）
        // 対象の月、かつ、日付か判定
        return (
          this.isTargetMonth(
            targetDate,
            startDate,
            this.rule.visit_interval_month
          ) && this.isTargetDay(targetDate, this.rule.visit_day)
        );
      case RepeatDiv.EveryMonthSpecifiedWeek: // 毎月（週指定）
        // 対象の月、かつ、週、かつ、曜日か判定
        return (
          this.isTargetMonth(
            targetDate,
            startDate,
            this.rule.visit_interval_month
          ) &&
          this.isTargetWeekRound(targetDate, this.rule.visit_week_weeks) &&
          this.isTargeWeekDay(targetDate, this.rule.visit_week)
        );
      case RepeatDiv.EveryMonthSpecifiedWeekDay: // 毎月（曜日指定）
        // 対象の月、かつ、曜日、かつ、n回目の曜日か判定
        return (
          this.isTargetMonth(
            targetDate,
            startDate,
            this.rule.visit_interval_month
          ) &&
          this.isTargeWeekDay(targetDate, this.rule.visit_week) &&
          this.isTargetWeekDayLap(targetDate, this.rule.visit_week_times)
        );
      default:
        return false;
    }
  }

  // 日付がイベント展開対象の曜日かを判定する
  private isTargeWeekDay(targetDate: Date, weekdays: string): boolean {
    return weekdays[targetDate.getDay()] === "1";
  }

  // 日付がイベント展開対象の曜日（月初からn回目）か判定する
  private isTargetWeekDayLap(targetDate: Date, weekdayTimes: string): boolean {
    let weekDayCount = 0;
    const targetWeekDay = targetDate.getDay();
    const date = new Date(targetDate);
    date.setDate(1);
    while (targetDate.getTime() > date.getTime()) {
      // 対象曜日の場合カウントアップ
      if (targetWeekDay === date.getDay()) {
        weekDayCount++;
      }
      date.setDate(date.getDate() + 1);
    }
    return weekdayTimes[weekDayCount] === "1";
  }

  // 日付がイベント展開対象の週（隔週）か判定する
  private isTargetWeek(
    targetDate: Date,
    startDate: Date,
    interval: number
  ): boolean {
    // 毎週の場合は常にtrue
    if (interval === 0) {
      return true;
    }
    // 期限開始日から対象年月まで何週間あるか算出
    const diff = appDate.diffDate(targetDate, startDate);
    const weekCount = Math.floor((diff + 1) / 7);
    // 算出した週数が対象の間隔か判定
    return weekCount % (interval + 1) === 0;
  }

  // 日付がイベント展開対象の週（月初からn周目）か判定する
  private isTargetWeekRound(targetDate: Date, weeks: string): boolean {
    const weekNumber = appDate.calcWeekNumber(targetDate) - 1;
    return weeks[weekNumber] === "1";
  }

  // 日付がイベント展開対象の月かを判定する
  private isTargetMonth(
    targetDate: Date,
    startDate: Date,
    interval: number
  ): boolean {
    // 毎月の場合は常にtrue
    if (interval === 0) {
      return true;
    }
    const diff = appDate.diffMonth(targetDate, startDate);
    return diff % (interval + 1) === 0;
  }

  // 日付がイベント展開対象の日付かを判定する
  private isTargetDay(targetDate: Date, day: number): boolean {
    return targetDate.getDate() === day;
  }
}
