import { Master, LoginUser, Menu } from "@/types";
import { ReceiptLinkSetting } from "#/model/agreement/receiptLinkSetting";
import { AgreementUserSetting } from "#/model/agreement/agreementUserSetting";

export const name = "master";

export const SET_MASTER = "SET_MASTER";

export const SET_MASTER_BY_TOKEN = "SET_MASTER_BY_TOKEN";
export interface MasterState {
  master?: Master;
  login_user?: LoginUser;
  can_auth_change?: number;
  button_items?: Menu[];
  co_code?: string;
  kst_no?: string;
  site_name?: string;
  is_honobono_agreement?: number;
  is_sukoyaka_agreement?: number;
  receipt_link_settings?: ReceiptLinkSetting[];
  agreement_user_setting?: AgreementUserSetting;
  error: boolean;
}

export interface SetMaster {
  master: Master;
  login_user: LoginUser;
  can_auth_change?: number;
  button_items: Menu[];
  co_code?: string;
  kst_no?: string;
  site_name?: string;
  is_honobono_agreement?: number;
  is_sukoyaka_agreement?: number;
  receipt_link_settings?: ReceiptLinkSetting[];
  agreement_user_setting?: AgreementUserSetting;
}

export interface SetMasterByToken {
  admin_id: number; //管理者ID（管理画面からのログインの場合）
  agreement_id: number; //契約ID
  auth_id: number; //職員権限
  co_code: string; //企業コード
  kst_no: string; //KST番号
  site_name: string; //サイト名
  ewell_id: number; //iBowID
  group_office_ids: number[]; //使用可能事業所
  is_honobono: number; //ほのぼの使用フラグ
  office_id: number; //事業所ID
  office_staff_id: number; //職員ID
  proxy_flg: number; //代理ログインフラグ（管理画面からのログインの場合）
}
