







import { Vue, Component, Prop } from "vue-property-decorator";
import { toWarekiDatetime } from "#/utility/appDate";
import { Remand } from "#/model/report";

/** 差し戻し情報のアコーディオン表示 */
@Component
export default class RemandExpand extends Vue {
  /** 差戻し情報 */
  @Prop() private remand!: Remand;

  /** 日時を和暦に変換 */
  private toWarekiDatetime = toWarekiDatetime;
}
