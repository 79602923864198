









































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import FileUpload from "#/components/FileUpload.vue";
import ExcelInputSelect from "@/components/master/excel/ExcelInputSelect.vue";
import ExcelInputDataResult from "@/components/master/excel/ExcelInputDataResult.vue";
import ExcelInputSaveResult from "@/components/master/excel/ExcelInputSaveResult.vue";
import { Sheet, ExcelInputResult } from "@/views/master/excel/types";

@Component({
  components: {
    FileUpload,
    ExcelInputSelect,
    ExcelInputDataResult,
    ExcelInputSaveResult
  }
})
export default class ExcelInputContainer extends Mixins(UtilMixin) {
  /** 処理中のシート情報 */
  @Prop() sheet!: Sheet;

  /** 処理済みシート数 */
  @Prop() completedSheetCount!: number;

  /** シート数 */
  @Prop() totalSheetCount!: number;

  /** 状態(1:初期 2:処理選択 3:取り込み結果 4:保存結果) */
  @Prop() state!: number;

  /** 事業所選択の必要フラグ */
  @Prop() needOfficeSelect!: number;

  /** 結果 */
  @Prop() result!: ExcelInputResult;

  /** 医療保険オンライン請求フラグ */
  @Prop() isMedTransmission!: boolean;

  /** 最終シートフラグ */
  private get isLastSheet(): boolean {
    return this.completedSheetCount == this.totalSheetCount - 1;
  }

  @Emit("reset")
  reset() {
    return;
  }

  @Emit("save")
  save() {
    return;
  }

  @Emit("skip")
  skip() {
    return;
  }

  @Emit("input:data")
  inputData(obj: { processType: number; keyType: number; officeId: number }) {
    return obj;
  }
}
