

















































import { Component, Mixins } from "vue-property-decorator";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import BaseReportList from "@/components/patient/BaseReportList.vue";
import Hour24, { DefaultSearchMode } from "@/components/hour24/hour24.vue";
import PatientMixin from "@/mixins/patientMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Link } from "#/types";

@Component({
  components: {
    PatientHeader,
    BaseReportList,
    Hour24
  }
})
export default class ConferenceEntry extends Mixins(PatientMixin, AxiosMixin) {
  /** 利用者ID */
  private patientId = 0;

  /** 左サイドメニューアイテム */
  private links: Link[] = [
    { text: "すべて", key: DefaultSearchMode },
    { text: "未読", key: "2" },
    { text: "既読", key: "3" },
    { text: "お気に入り", key: "4" }
  ];

  /** 開始月 */
  private start = "";

  /** 終了月 */
  private end = "";

  /** 現在有効なメニュー */
  private link = this.links[0];

  created(): void {
    this.patientId = Number(this.$route.params.id);
  }

  mounted() {
    this.changeLinkQuery();
    this.setDefaultHeader();
  }

  /** URLを見て、どのメニューを有効にするか決める */
  private changeLinkQuery() {
    if (typeof this.$route.query.key === "string") {
      const key = this.$route.query.key;
      for (const v of this.links) {
        if (v.key == key) {
          this.link = v;
        }
      }
    }
  }

  /** 横サイドメニュー切り替え時にパスに残す */
  private setUrlParams({ link }: { link: Link }) {
    this.$router.push({
      query: {
        key: link.key
      }
    });
  }

  /** 新規作成ボタンクリック */
  private clickNew(): void {
    this.$router.push({
      name: "PatientHour24Regist",
      params: { id: String(this.patientId), fulltimeid: "0" }
    });
  }

  /** PDF出力 */
  private pdf() {
    this.makePdf(window.base_url + "/api/fulltime/preview", {
      patient_ids: [this.patientId],
      start_date: this.start,
      end_date: this.end
    });
  }
}
