




















import { Component, Prop, Vue } from "vue-property-decorator";
import { PATIENT } from "#/const";
import { HighCostItems } from "./type";
import HighCostSeparated from "./HighCostSeparated.vue";

@Component({
  components: {
    HighCostSeparated
  }
})
export default class HighCost extends Vue {
  /** 高額療養費 */
  @Prop({ default: () => [] }) private readonly items!: HighCostItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 上の表に表示する一覧 (下の一覧のいずれかと期間が重複しても優先して使われる) */
  private get PrimaryItems() {
    return this.items.filter(item =>
      [
        PATIENT.HIGH_EXPENSE_ADD_DIV_SELF,
        PATIENT.HIGH_EXPENSE_ADD_DIV_LIMIT_CERT
      ].includes(item.add_div)
    );
  }

  /** 下の表に表示する一覧 */
  private get SecondaryItems() {
    return this.items.filter(item =>
      [
        PATIENT.HIGH_EXPENSE_ADD_DIV_MED_INSURANCE,
        PATIENT.HIGH_EXPENSE_ADD_DIV_OLD_AGE
      ].includes(item.add_div)
    );
  }
}
