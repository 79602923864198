
















import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import TablesSearch from "@/components/dashboard/TablesSearch.vue";

@Component({
  components: {
    TablesSearch
  }
})
export default class Dashboard extends Mixins(AxiosMixin) {}
