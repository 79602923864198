

















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit, Ref, Watch } from "vue-property-decorator";
import AppChatList, { Chat, Account } from "./AppChatList.vue";
import AppChatInput from "./AppChatInput.vue";
import { Stamp } from "./AppChatStampList.vue";
import InfiniteLoading from "vue-infinite-loading";

@Component({
  components: { AppChatList, AppChatInput, InfiniteLoading }
})
export default class AppChatRoom extends Vue {
  @Prop() selfEwellUserId!: number;
  @Prop() inifiniteState!: string;
  @Prop({
    default: () => {
      return [];
    }
  })
  chats!: Chat[];
  @Prop({
    default: () => {
      return [];
    }
  })
  accounts!: Account[];
  @Prop() dropChatModeFlg!: boolean;

  @Ref("AppChatRoomChatList")
  private readonly appChatRoomChatList!: HTMLDivElement;
  @Ref("InfiniteLoading")
  private readonly infiniteLoading!: InfiniteLoading;

  @Emit()
  private sendMessage(message: string) {
    return message;
  }

  @Emit()
  private sendStamp(stamp: Stamp) {
    return stamp;
  }

  @Emit()
  private sendImage(file: File) {
    return file;
  }

  @Emit()
  private reachedTop(id?: string) {
    return id;
  }

  @Emit("drop:chat")
  private dropChat(id: string) {
    return id;
  }

  @Emit("dropChatMode:off")
  private dropChatModeOff() {
    return false;
  }

  @Watch("chats")
  private chatsDidChange() {
    // チャットが更新された時、画面最下部にいた場合、受け取ったチャットを表示するために最下部へスクロール
    if (this.isReachedBottom()) {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  }

  @Watch("inifiniteState")
  private changeInifiniteState() {
    switch (this.inifiniteState) {
      case "loaded":
        this.infiniteLoading.stateChanger.loaded();
        break;
      case "complete":
        this.infiniteLoading.stateChanger.complete();
        break;
      case "reset":
        this.infiniteLoading.stateChanger.reset();
        break;
      case "error":
        this.infiniteLoading.stateChanger.error();
        break;
      default:
        // do nothing
        break;
    }
  }

  mounted() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.appChatRoomChatList.scrollTo(0, this.appChatRoomChatList.scrollHeight);
  }

  isReachedBottom() {
    return (
      this.appChatRoomChatList.scrollHeight -
        (this.appChatRoomChatList.scrollTop +
          this.appChatRoomChatList.clientHeight) <=
      0
    );
  }

  private infiniteHandler() {
    if (!this.chats.length) {
      this.reachedTop();
      return;
    }

    this.reachedTop(this.chats[0].id);
  }
}
