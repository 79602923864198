






























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import sanitizeHTML from "sanitize-html";
import { TimeSchedule } from "@/components/home/types";
import { getVisitMemo } from "@/components/schedule/common";
import LoadingArea from "@/components/home/loadingArea.vue";

@Component({
  components: {
    LoadingArea
  }
})
export default class ScheduleToday extends Vue {
  /** 本日の予定 */
  @Prop() private readonly schedules!: TimeSchedule[];

  /** 本日の予定の件数 */
  @Prop() private readonly count!: number;

  /** ローディング */
  @Prop() private readonly loading!: boolean;

  /** サニタイズ */
  private sanitize = sanitizeHTML;

  /** 訪問メモURL対応後 */
  private memo(str: string) {
    return getVisitMemo(str);
  }

  /** 訪問するボタンを表示するかどうか */
  private isVisitBtn(event: TimeSchedule): boolean {
    // 過ぎていない訪問予定 かつ PT、STの場合は、精神利用者以外で表示
    if (
      event.is_visit &&
      event.patientId &&
      !event.is_over &&
      !event.is_cancel
    ) {
      return true;
    }
    return false;
  }
}
