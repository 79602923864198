

































































































































































































































import { Component, Watch } from "vue-property-decorator";
import OneMonthAddV5 from "./OneMonthAddV5.vue";

@Component
export default class OneMonthAddV5Covit extends OneMonthAddV5 {
  created(): void {
    this.setHour24Div();
    this.setMedicalSpecialDivV5();
    this.setMedicalTerminalDiv();
    this.setAccompaniedStaffCnt();
  }

  //特別管理加算（コロナ臨時）
  protected disableCheckMedicalSpecialDiv6(): boolean {
    // 2024年4月以降は無効
    return true;
  }
}
