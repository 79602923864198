































































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { UnsubmitReport } from "@/components/home/types";
import { SidebarList, ReportTypeToRoute } from "@/components/unsubmit/unsubmit";
import { Choice } from "#/types";
import { ChoiceCompareDate } from "#/components/selectFilter/SelectFilter.vue";

interface SearchCond {
  keyword: string;
  updatedDate: string;
  updatedDateBy: number;
}

@Component
export default class Unsubmit extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin
) {
  @Prop({ default: [] }) reports!: UnsubmitReport[]; // 未提出書類
  @Prop({ default: null }) reportType!: string; // 絞り込み条件：書類の種類

  /** サイドメニュー項目 */
  private sidebarList = SidebarList;

  /** 検索パネルを開いているか */
  private searchPanel = false;
  // 日付検索方法の選択肢
  private choiceDateCompareBy: Choice[] = ChoiceCompareDate();

  private get ReportType() {
    return this.reportType;
  }
  private set ReportType(newType: string) {
    this.reportType = newType;
  }
  /** 開いているメニューの項目 */
  private get matchViewingMenu() {
    return SidebarList.find(report => report.key === this.ReportType);
  }

  /** デフォルト検索条件 */
  private DefaultSearchCond = (): SearchCond => ({
    keyword: "",
    updatedDate: "",
    updatedDateBy: +this.choiceDateCompareBy[0].value
  });
  /** 検索条件 */
  private searchCond = this.DefaultSearchCond();
  /** 検索条件をリセット */
  private resetSearchCond() {
    this.searchCond = this.DefaultSearchCond();
  }

  // 日付を比較する
  private compareDate(
    target: string,
    base: string,
    compareBy: number
  ): boolean {
    const tTime = new Date(target.replaceAll("-", "/")).getTime();
    const bTime = new Date(base.replaceAll("-", "/")).getTime();
    if (Number.isNaN(tTime) || Number.isNaN(bTime)) {
      return false;
    }
    switch (compareBy) {
      case 0: // に等しい
        return tTime === bTime;
      case 1: // に等しくない
        return tTime !== bTime;
      case 2: // より後
        return tTime > bTime;
      case 3: // 以後
        return tTime >= bTime;
      case 4: // より前
        return tTime < bTime;
      case 5: // 以前
        return tTime <= bTime;
      default:
        return false;
    }
  }

  private get reportHeaders(): DataTableHeader[] {
    const header = [] as DataTableHeader[];
    header.push(
      {
        text: "書類種別",
        value: "report_type_name",
        align: "start"
      },
      {
        text: "利用者",
        value: "patient_name",
        align: "start",
        cellClass: "ibow2-home-unsubmit-table-underline"
      }
    );
    if (!this.matchViewingMenu?.isHideUpdStaff) {
      header.push(
        {
          text: "更新者",
          value: "update_staff.ibow_nickname",
          align: "start"
        },
        {
          text: "更新日時",
          value: "update_datetime",
          align: "start"
        },
        {
          text: this.matchViewingMenu?.labelStaff ?? "担当者",
          value: "target_staff.ibow_nickname",
          align: "start"
        }
      );
    } else {
      header.push(
        {
          text: this.matchViewingMenu?.labelStaff ?? "担当者",
          value: "target_staff.ibow_nickname",
          align: "start"
        },
        {
          text: "更新日時",
          value: "update_datetime",
          align: "start"
        }
      );
    }

    header.push(
      {
        text: this.matchViewingMenu?.labelDate ?? "記載日",
        value: "target_date",
        align: "start"
      },
      {
        text: "",
        value: "actions",
        align: "start",
        width: "48px",
        sortable: false
      }
    );

    return header;
  }

  // 絞り込んだ一覧を表示
  private get showReports(): UnsubmitReport[] {
    if (this.reports == null) return [];

    const reports = this.reports
      .filter(report => {
        if (!this.reportType == null) return true;
        return String(report.report_type) === this.reportType;
      })
      .filter(report => {
        // 実績確定日絞り込み
        if (!this.searchCond.updatedDate) return true;
        return this.compareDate(
          report.target_date,
          this.searchCond.updatedDate,
          this.searchCond.updatedDateBy
        );
      })
      .map(report => {
        report.patient_name =
          report.patient.last_name + " " + report.patient.first_name;
        return report;
      });

    return reports;
  }

  private clickRow(report: UnsubmitReport) {
    const routeName = ReportTypeToRoute[report.report_type];
    this.$router.push({
      path: `/patient/${report.patient_id}/${routeName}/${report.report_id}`
    });
  }

  @Emit()
  changeReportType(newType: string) {
    return newType;
  }
}
