
































































































































































import { Component, Ref, Mixins } from "vue-property-decorator";
import { Calendar, CalendarDispEvent } from "#/components/calendar/common";
import { Staff, DefaultStaff } from "#/model/staff";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { app, install } from "#/mixins/firebase";
import CalendarTitleBeforeAfter from "#/components/calendar/CalendarTitleBeforeAfter.vue";
import IbowCalendar from "@/components/common_ibow/calendar/IbowCalendar.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    CalendarTitleBeforeAfter,
    IbowCalendar
  }
})
export default class StaffCalendarPrint extends Mixins(AxiosMixin, UtilMixin) {
  // カレンダーコンポーネント
  @Ref("calendar") private readonly calendar!: Calendar;
  /** イベント */
  private events: CalendarDispEvent[] = [];
  /** 職員 */
  private staff: Staff = DefaultStaff();
  /** 対象月 */
  private targetMonth = this.dateToStr(new Date(), "yyyy-mm-dd");
  /** 祝日表示フラグ */
  private dispHoliday = true;

  /** タイトル */
  private get Title(): string {
    return this.staff.info.ibow_nickname + " スケジュール";
  }

  /** 表示用年月日 */
  private get YearMonth(): string {
    const [year, month] = this.targetMonth.split("-");
    return `${year}年${month}月`;
  }

  /** 事業所名 */
  private get OfficeName(): string {
    if (this.staff.office) {
      return this.staff.office.name;
    }
    return "";
  }

  /** カレンダー表示イベント */
  private get Events(): CalendarDispEvent[] {
    return (
      this.events.filter(event => {
        if (!this.dispHoliday && event.type === EventType.Holiday) {
          return false;
        }
        return true;
      }) ?? []
    );
  }

  private created() {
    if (this.$route.query.target) {
      this.targetMonth = String(this.$route.query.target);
    }
    this.fetch();
  }

  /** データを取得する */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/schedule/staffschedule/get",
      {
        office_staff_id: Number(this.$route.params.id),
        target_date: this.targetMonth
      },
      res => {
        this.staff = res.data.staff;
        this.events = res.data.staff_events;
      }
    );
  }

  /** PDFを出力する */
  private print() {
    this.makePdf(window.base_url + "/api/schedule/staffschedule/pdf", {
      target_date: this.targetMonth,
      staff: this.staff,
      staff_events: this.Events
    });
  }

  /** タブを閉じる */
  private close() {
    window.close();
  }

  /** ブラウザ印刷をする */
  private webPrint() {
    app
      .delete()
      .then(function() {
        window.print();
      })
      .catch(function(error) {
        console.log("Error deleting app:", error);
      })
      .finally(function() {
        install();
      });
  }
}
