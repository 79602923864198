
















































































































































































































































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Vital as VitalModel, DefaultVital } from "#/model/report";
import { Choice } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VitalText, DefaultVitalText } from "@/components/visit/types";

@Component
export default class Vital extends Mixins(UtilMixin, RulesMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  /** バイタル */
  @Prop({ default: () => DefaultVital() }) vital!: VitalModel;
  /** 前回のバイタル */
  @Prop({ default: () => DefaultVitalText() }) beforeVital!: VitalText;
  /** 血糖値選択肢非表示フラグ */
  @Prop({ default: false }) isBloodSugarTimingNoDisplay!: boolean;

  /** 体温 整数部 */
  private tempIntegerItems: Choice[] = [
    { text: "-", value: 99 },
    { text: "31", value: 31 },
    { text: "32", value: 32 },
    { text: "33", value: 33 },
    { text: "34", value: 34 },
    { text: "35", value: 35 },
    { text: "36", value: 36 },
    { text: "37", value: 37 },
    { text: "38", value: 38 },
    { text: "39", value: 39 },
    { text: "40", value: 40 },
    { text: "41", value: 41 },
    { text: "42", value: 42 },
    { text: "43", value: 43 }
  ];

  /** 体温 小数部 */
  private tempDecimalItems: Choice[] = [
    { text: "-", value: 99 },
    { text: "0", value: 0 },
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 }
  ];

  /** 体温を設定する */
  private setBodyTemp() {
    if (
      this.vital.body_temprature_int != 99 &&
      this.vital.body_temprature_dec != 99
    ) {
      // 整数部・小数部が共にハイフン以外
      this.vital.body_temprature = parseFloat(
        this.vital.body_temprature_int + "." + this.vital.body_temprature_dec
      );
      return;
    }
    if (
      this.vital.body_temprature_int != 99 &&
      this.vital.body_temprature_dec == 99
    ) {
      // 小数部のみハイフン
      this.vital.body_temprature = this.vital.body_temprature_int;
      return;
    }
    this.vital.body_temprature = 0;
  }

  /** 小数部のルール */
  private getBodyTempRule(): boolean | string {
    if (
      this.vital.body_temprature_int === 99 &&
      this.vital.body_temprature_dec !== 99
    ) {
      return "整数を入力してください";
    }
    return true;
  }
}
