export interface Smid {
  id: number;
  no: string;
  item: string;
  score: number;
}

export const Smids = (): Smid[] => [
  {
    id: 1,
    no: "①",
    item: "レスピレーター管理",
    score: 10
  },
  {
    id: 2,
    no: "②",
    item: "気管内挿管・気管切開",
    score: 8
  },
  {
    id: 3,
    no: "③",
    item: "鼻咽頭エアウェイ",
    score: 5
  },
  {
    id: 4,
    no: "④",
    item: "O2注入またはSaO2 90%以下の状態が10%以上",
    score: 5
  },
  {
    id: 5,
    no: "⑤",
    item: "1回/時間以上の頻回の吸引",
    score: 8
  },
  {
    id: 6,
    no: "",
    item: "6回/日以上の頻回の吸引",
    score: 3
  },
  {
    id: 7,
    no: "⑥",
    item: "ネブライザ6回以上/日または継続使用",
    score: 3
  },
  {
    id: 8,
    no: "⑦",
    item: "IVH",
    score: 10
  },
  {
    id: 9,
    no: "⑧",
    item: "経口摂取（全介助）",
    score: 3
  },
  {
    id: 10,
    no: "",
    item: "経管（経鼻・胃ろう含む）",
    score: 5
  },
  {
    id: 11,
    no: "⑨",
    item: "腸ろう・腸管栄養",
    score: 8
  },
  {
    id: 12,
    no: "",
    item: "持続注入ポンプ使用（腸ろう・腸管栄養時）",
    score: 3
  },
  {
    id: 13,
    no: "⑩",
    item:
      "手術・服薬にても改善しない過緊張で、発汗による更衣と姿勢修正を3回以上/日",
    score: 3
  },
  {
    id: 14,
    no: "⑪",
    item: "継続する透析（腹膜灌流を含む）",
    score: 10
  },
  {
    id: 15,
    no: "⑫",
    item: "定期導尿（3回/日以上）＊人工膀胱を含む",
    score: 5
  },
  {
    id: 16,
    no: "⑬",
    item: "人工肛門",
    score: 5
  },
  {
    id: 17,
    no: "⑭",
    item: "体位交換6回/日以上",
    score: 3
  }
];
