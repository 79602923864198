import { Choice } from "@/types";

/** AI自動作成ステータス表示定数 */
export const AI_GENERATE_STATE: Choice[] = [
  { text: "作成中", value: 1 },
  { text: "エラー", value: 2 },
  { text: "完了", value: 3 }
];

/** AI自動作成ステータス色定数 */
export const AI_GENERATE_STATE_COLOR: Choice[] = [
  { text: "success", value: 1 },
  { text: "error", value: 2 },
  { text: "success", value: 3 }
];

/** AI自動作成ステータス定数:作成中 */
export const AI_GENERATE_STATE_PROGRESS = 1;

/** AI自動作成ステータス定数:失敗 */
export const AI_GENERATE_STATE_FAIL = 2;

/** AI自動作成ステータス定数:成功 */
export const AI_GENERATE_STATE_SUCCESS = 3;

/** AI自動作成書類タイプ:看護計画書 */
export const AI_GENERATE_REPORT_TYPE_VNPLAN = 1;

/** AI自動作成書類タイプ:月次報告書 */
export const AI_GENERATE_REPORT_TYPE_MONTHREPORT = 2;
