


























































































































































































































































































































import {
  Component,
  Mixins,
  Ref,
  Prop,
  Emit,
  Watch
} from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { VForm } from "@/types";
import { BoardItem, FulltimeComment } from "#/model/fulltime";
import UtilMixin from "@/mixins/utilMixin";
import { DefaultApplyStaff } from "#/model/common";
import { ApplyPatient } from "#/model/common";
import sanitizeHTML from "sanitize-html";

// 利用者を追加
interface BoardItemAddPatient extends BoardItem {
  patient: Patient;
}
interface Patient extends ApplyPatient {
  age: number;
  birthday: string;
}
@Component
export default class Hour24Item extends Mixins(AxiosMixin, UtilMixin) {
  @Ref("hour24_sidearea") private readonly hour24DetailArea!: Vue; //24時間体制詳細エリア

  @Ref("hour24_comment_create") private readonly hour24CommentCreate!: VForm; // 24時間体制のコメント登録エリア

  @Prop() private readonly hour24!: BoardItemAddPatient; // 24時間体制の項目

  @Prop({ default: 0 }) private readonly patientId!: number; //利用者24時間体制から来る利用者ID

  @Prop() private readonly staffId!: number; //職員ID

  @Prop({ default: "500px" }) private detailWidth!: string; //詳細エリアの表示幅

  @Prop({ default: true }) private isOverlayDetail!: boolean; //詳細エリアをドロワーとして表示するか

  @Prop({ default: false }) private isLastOpen!: boolean; //詳細エリアを開いているか/最後に選択された項目か

  @Emit("update:hour24") private updateHour24(newValue: unknown) {
    return newValue;
  }

  /** 詳細エリアを開くよう要求 */
  @Emit() private openDetail() {
    return this.hour24.id;
  }

  /** 詳細エリアを閉じるよう要求 */
  @Emit() private closeDetail() {
    return this.hour24.id;
  }

  @Emit() private reset() {
    return;
  }

  @Watch("hour24", { immediate: true }) watchHour24() {
    this.setStarColor();
  }

  private headerElem: HTMLElement | null = null;
  public DetailElem: HTMLElement | null = null;
  private detailHeaderElem: HTMLElement | null = null;
  /** 詳細エリアのスクロール要素 */
  private detailScrollArea: HTMLElement | null = null;

  /** メモを省略せず全表示するフラグ */
  private isShowFullMemo = false;

  /** 通知対象を関係者だけにするフラグ */
  private is_not_all_notice_flag = 0;

  /** パネルの開閉状態 */
  private isPanelOpen = false;
  private get IsPanelOpen() {
    return this.isPanelOpen;
  }
  private set IsPanelOpen(newState: boolean) {
    this.isPanelOpen = newState;
    if (newState) {
      this.openDetail();
    } else {
      this.closeDetail();
    }
  }

  /** 確認ダイアログを開いているか */
  private isOpenAlertConfirm = false;

  /** 書き込んだコメント */
  private comment = "";

  /** サニタイズ */
  private sanitize = sanitizeHTML;

  /** 連絡元 */
  private get MessengerText() {
    for (const messenger of this.masters.messenger) {
      if (messenger.value == this.hour24.messenger) {
        if (messenger.value == 6) {
          return `${messenger.text}（${this.hour24.other_messenger}）`;
        }
        return messenger.text;
      }
    }
    return "";
  }

  /** 対応方法 */
  private get SupportMethod() {
    for (const support_method of this.masters.support_method) {
      if (support_method.value == this.hour24.support_method) {
        return support_method.text;
      }
    }
    return "";
  }

  /** カテゴリ */
  private get SupportDiv() {
    for (const support_div of this.masters.support_div) {
      if (support_div.value == this.hour24.support_div) {
        return support_div.text;
      }
    }
    return "";
  }

  mounted() {
    this.headerElem = document.querySelector(".ibow2-header");
    this.DetailElem = this.hour24DetailArea.$el as HTMLElement;
    this.detailHeaderElem = this.DetailElem.querySelector(
      ".ibow2-search-panel-header"
    );
    this.detailScrollArea = this.DetailElem.querySelector(".v-card__text");
    if (this.DetailElem && navigator.maxTouchPoints === 0) {
      // タッチデバイスではなくマウス操作の場合に、詳細エリアホバー時のスクロールチェーンを防ぐイベントを設定
      this.DetailElem.addEventListener("mouseenter", this.mainScrollLock);
      this.DetailElem.addEventListener("mouseleave", this.mainScrollUnlock);
    }
  }

  beforeDestroy() {
    this.mainScrollUnlock();
    if (this.DetailElem) {
      this.DetailElem.removeEventListener("mouseenter", this.mainScrollLock);
      this.DetailElem.removeEventListener("mouseleave", this.mainScrollUnlock);
    }
  }

  /** メインエリアをスクロールできないようにする */
  private mainScrollLock() {
    const scrollBarWidth = window.innerWidth - document.body.clientWidth;
    [document.body, this.headerElem, this.DetailElem].forEach(shiftElem => {
      (shiftElem as HTMLElement).style.marginRight = `${scrollBarWidth}px`;
    });
    document.documentElement.style.overflowY = "hidden";
  }

  /** メインエリアをスクロールできるようにする */
  private mainScrollUnlock() {
    // タッチデバイスで、スワイプするとイベント発生後も数ミリ~十数ミリ秒ほどスクロールしてしまうので、setTimeoutで待つ
    setTimeout(() => {
      document.documentElement.style.overflowY = "";
      [document.body, this.headerElem, this.DetailElem].forEach(shiftElem => {
        (shiftElem as HTMLElement).style.marginRight = "";
      });
    }, 50);
  }

  /** 24時間体制詳細エリアを閉じた */
  public CloseDetail() {
    this.isPanelOpen = false;
    this.mainScrollUnlock();
  }

  /** 未読コメントカウント */
  private commentCount(): number {
    let count = 0;
    for (const comment of this.hour24.fulltime_comments) {
      if (comment.is_reading === 0) {
        count++;
      }
    }
    return count;
  }

  /** スター押下時 */
  private swithFavorite() {
    //押下でfalseになるので未読・既読を逆にする
    this.hour24.favorite = !this.hour24.favorite;
    this.setStarColor();
    this.postJsonCheck(
      window.base_url + "/api/fulltime/notice/save",
      {
        fulltime: this.hour24
      },
      () => {
        this.updateHour24(this.hour24);
      }
    );
  }

  private setStarColor() {
    if (this.hour24.favorite == true) {
      this.hour24.favorite_icon = "star";
      this.hour24.is_favorite = 1;
      this.hour24.color = "#F9CC30";
    } else {
      this.hour24.favorite_icon = "star-empty";
      this.hour24.is_favorite = 0;
      this.hour24.color = "#5F667A";
    }
  }

  /** アイテムクリック時 */
  private clickBordItem() {
    if (!this.IsPanelOpen) {
      this.IsPanelOpen = true;
    }

    //未読のコメントがあれば、既読状態にする
    const hasUnread = this.hour24.fulltime_comments.some(comment => {
      return comment.is_reading === 0;
    });
    if (hasUnread) {
      this.saveCommentNotice();
    }
  }

  /** コメントを既読状態にする */
  private saveCommentNotice() {
    this.postJsonBackground(
      window.base_url + "/api/fulltime/commentnotice/save",
      {
        fulltime: this.hour24,
        comments: this.hour24.fulltime_comments
      },
      () => {
        for (const comment of this.hour24.fulltime_comments) {
          comment.is_reading = 1;
        }
        this.updateHour24(this.hour24);
      }
    );
  }

  /** 編集アイコンクリック時 */
  private edit() {
    this.$router.push(
      `/patient/${this.hour24.patient_id}/hour24/regist/${this.hour24.id}`
    );
  }

  /** 利用者画面へ遷移する */
  private redirectPatientPage() {
    this.$router.push({
      name: "PatientCalendar",
      params: { id: String(this.hour24.patient_id) }
    });
  }

  /** 既読にする */
  private toggleAlreadyRead() {
    this.hour24.is_reading = Number(!this.hour24.is_reading);
    this.postJsonCheck(
      window.base_url + "/api/fulltime/notice/save",
      {
        fulltime: this.hour24
      },
      () => {
        this.reset();
      }
    );
  }

  /** 新規コメント入力を始めた時 */
  private onFocusCommentRegist() {
    const scrollYBefore = scrollY;
    // キーボードが開き終わるのを待つ
    setTimeout(() => {
      // 入力欄がキーボードで隠れてしまいスクロールしなかった場合、隠れない位置にスクロールする
      if (scrollYBefore === scrollY && !this.isOverlayDetail) {
        this.scrollToInputComment();
      }
    }, 500);
  }

  /** コメント登録する */
  private registrationComment() {
    if (!this.hour24CommentCreate.validate()) {
      return;
    }

    const commentItem = {
      id: 0,
      fulltime_id: this.hour24.id,
      staff_id: this.staffId,
      comment: this.comment,
      created_at: "",
      updated_at: "",
      deleted_at: "",
      is_reading: 0,
      staff: DefaultApplyStaff(),
      edit: false,
      editable_flg: false,
      editor_staff_name: ""
    };
    this.postJsonCheck(
      window.base_url + "/api/fulltime/comment/edit",
      {
        fulltime: this.hour24,
        comment: commentItem,
        is_not_all_notice_flag: this.is_not_all_notice_flag
      },
      res => {
        res.data.comment.is_reading = 1;
        // コメント並び順設定が古い順なら上に、新しい順なら下に追加
        if (this.hour24.comment_order === 1) {
          this.hour24.fulltime_comments.push(res.data.comment);
        } else {
          this.hour24.fulltime_comments.unshift(res.data.comment);
        }
        this.comment = "";
        this.hour24CommentCreate.resetValidation();
        this.$nextTick(() => {
          this.scrollToLatestComment();
        });
      }
    );
  }

  /** 指定したIDの24時間体制コメント */
  private hour24CommentElem(commentId: number): VForm | undefined {
    return ((this.$refs[
      `hour24Comment_${commentId}`
    ] as unknown) as VForm[])?.[0];
  }

  /** 最新のコメントにスクロールする */
  private scrollToLatestComment() {
    if (this.detailScrollArea) {
      if (this.hour24.comment_order === 1) {
        // 古い順の場合、最新の(投稿直後の)コメントの先頭部分まで
        const commentElem = this.hour24CommentElem(
          this.hour24.fulltime_comments[
            this.hour24.fulltime_comments.length - 1
          ].id
        );
        if (commentElem && this.detailHeaderElem) {
          //  詳細エリアのヘッダーと最新のコメントが被らないように調整
          this.detailScrollArea.scrollTo({
            top: commentElem.$el.offsetTop - this.detailHeaderElem.clientHeight,
            behavior: "smooth"
          });
        }
      } else {
        // 新しい順の場合、一番上までスクロール(最新のコメントは見える)
        this.detailScrollArea.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }

  /** コメント入力を始めた時、フローティングキーボードで入力欄が隠れてしまわないように */
  private scrollToInputComment() {
    // iOSフローティングキーボードの高さ分スクロールする
    if (
      "visualViewport" in window &&
      window.visualViewport != null &&
      innerHeight !== window.visualViewport.height
    ) {
      const scrollYTo = scrollY + innerHeight - window.visualViewport.height;
      scrollTo(0, scrollYTo);
    }
  }

  /** コメントの入力チェック */
  private requiredComment(comment: string): boolean | string {
    if (!comment.match(/.*\S+.*/)) {
      return "必須です";
    }
    return true;
  }

  /** コメントの編集をキャンセルする */
  private cancelEditComment(comment: FulltimeComment) {
    comment.edit = false;
    comment.comment = comment.before_comment ?? "";
  }

  /** コメント編集する */
  private editComment(comment: FulltimeComment) {
    // 自身以外のコメントは編集をキャンセル
    this.hour24.fulltime_comments.forEach(item => {
      if (item.edit) {
        this.cancelEditComment(item);
      }
    });
    comment.edit = true;
    comment.before_comment = comment.comment;
  }

  /** コメントの編集を保存する */
  private saveEditComment(comment: FulltimeComment) {
    const targetForm = this.hour24CommentElem(comment.id);
    if (!targetForm || !targetForm.validate()) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/fulltime/comment/edit",
      {
        fulltime: this.hour24,
        comment: comment,
        is_not_all_notice_flag: this.is_not_all_notice_flag
      },
      res => {
        comment.edit = false;
        //詳細のコメントを置き換える
        const indexReadingItem = this.hour24.fulltime_comments.findIndex(
          item => item.id == comment.id
        );
        this.hour24.fulltime_comments.splice(
          indexReadingItem,
          1,
          res.data.comment
        );
      }
    );
  }

  /** コメント削除する */
  private async deleteComment(comment: FulltimeComment) {
    this.isOpenAlertConfirm = true;
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      this.isOpenAlertConfirm = false;
      return;
    }
    this.isOpenAlertConfirm = false;

    this.postJsonCheck(
      window.base_url + "/api/fulltime/comment/delete",
      {
        fulltime: this.hour24,
        comment: comment
      },
      () => {
        const idx = this.hour24.fulltime_comments.findIndex(
          item => item.id == comment.id
        );
        if (idx >= 0) {
          this.hour24.fulltime_comments.splice(idx, 1);
        }
      }
    );
  }

  /** タグの色 */
  private tagColor(category: string): string {
    let color = this.$vuetify.theme.themes.light.greyLight + "";

    if (category == "緊急対応") {
      color = "error";
    } else if (category == "クレーム") {
      color = "orange";
    }

    return color;
  }
}
