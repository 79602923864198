

























































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { CalendarEvent } from "@/types";
import PatientMixin from "@/mixins/patientMixin";
import FocusingMixin from "@/mixins/focusingMixin";
import StateButton from "@/components/common_ibow/StateButton.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    StateButton
  }
})
export default class ReceiptDetailTitle extends Mixins(
  PatientMixin,
  FocusingMixin
) {
  /** イベント */
  @Prop({ default: () => [] }) events!: CalendarEvent[];

  /** 利用者名 */
  @Prop({ default: "" }) patient_name!: string;

  /** 生年月日（和暦） */
  @Prop({ default: "" }) birthday_wareki!: string;

  /** 年齢 */
  @Prop({ default: 0 }) age!: number;

  /** 実績確定日 */
  @Prop({ default: "" }) dateOfResultsDeterminationMedical!: string;
  @Prop({ default: "" }) dateOfResultsDeterminationCare!: string;

  /** 実績確定フラグ */
  @Prop({ default: false }) isDecisionMedical!: boolean;
  @Prop({ default: false }) isDecisionCare!: boolean;

  /** 利用者情報（確定・未確定） */
  @Prop({ default: "" }) dispDecisionMedical!: string;
  @Prop({ default: "" }) dispDecisionCare!: string;

  /** IDリスト */
  @Prop({ default: () => [] }) ids!: number[];

  /** 利用者ID */
  @Prop({ default: "" }) patientId!: string;

  /** 年月 */
  @Prop({ default: "" }) yearmonth!: string;

  /** 高額療養費が医療保険適用期間内に設定されていないか */
  @Prop({ default: false }) notHighExpense!: boolean;

  /** 利用者変更時 */
  @Emit() changePatient() {
    return;
  }

  /** 利用者移動ボタンを表示するかどうか */
  private get IsShowLinkNextDetail() {
    if (this.ids.length === 0) {
      return false;
    } else if (
      this.ids.length === 1 &&
      this.ids[0] === Number(this.patientId)
    ) {
      return false;
    }
    return true;
  }

  /** 前の利用者 */
  private clickLeft() {
    this.changePatientId(false);
  }

  /** 次の利用者 */
  private clickRight() {
    this.changePatientId(true);
  }

  /** 利用者を変更する */
  private changePatientId(isNext: boolean) {
    const id = Number(this.$route.query.id);
    const ids = this.ids;
    const nextId = this.getChangePatientId(isNext, id, ids);

    let movePath = "";
    if (this.$route.query.frompatient) {
      movePath = `/reciept-patient?id=${nextId}&yearmonth=${this.yearmonth}&frompatient=true`;
    } else {
      movePath = `/reciept-patient?id=${nextId}&yearmonth=${this.yearmonth}`;
    }

    this.$router.push({ path: movePath }).then(() => {
      this.changePatient();
    });
  }

  /** 利用者確定状態（確定・未確定） */
  private get dispDecision() {
    let disp = "";
    if (this.HasEventType.isMed) {
      disp += this.dispDecisionMedical;
    }
    if (this.HasEventType.isCare) {
      disp += this.dispDecisionCare;
    }
    return disp;
  }

  private get HasEventType(): { isMed: boolean; isCare: boolean } {
    let isMed = false;
    let isCare = false;
    for (const event of this.events) {
      if (event.type == EventType.Record2Medical) {
        isMed = true;
      }
      if (
        event.type == EventType.Record2NursingCare ||
        event.type == EventType.Record2PassCrawl
      ) {
        isCare = true;
      }
    }
    return { isMed, isCare };
  }
}
