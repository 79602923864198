import { Component, Vue } from "vue-property-decorator";
import { ShiftTemplate } from "#/model/schedule";
import { SHIFT_COLORS } from "./types";

@Component
export default class ShiftMixin extends Vue {
  /** 文字列末尾から指定文字数をパディングする */
  protected padStr(padding: string, target: number): string {
    return (padding + target).slice(-padding.length);
  }

  /** シフトの時分を取得する */
  protected getShiftTime(shift: ShiftTemplate): string {
    const padding = "00";
    const time = `${this.padStr(padding, shift.shift_start_time_hour)}:
    ${this.padStr(padding, shift.shift_start_time_minute)}~
    ${this.padStr(padding, shift.shift_end_time_hour)}:
    ${this.padStr(padding, shift.shift_end_time_minute)}`;
    return time;
  }

  /** シフトの種類を取得する */
  protected getShiftType(shift: ShiftTemplate): string {
    return shift.staff_id == 0 ? "事業所シフト" : "Myシフト";
  }

  /** シフトの色を取得する */
  protected getShiftColor(shift: ShiftTemplate): string {
    return SHIFT_COLORS[shift.color_id];
  }
}
