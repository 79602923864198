






















import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import Unsubmit from "@/components/unsubmit/unsubmit.vue";
import { UnsubmitReport } from "@/components/home/types";
import { ReportList, SidebarList } from "@/components/unsubmit/unsubmit";
import { BreadItem } from "@/store/modules/patient/types";

@Component({
  components: { Unsubmit }
})
export default class UnsubmitList extends Mixins(AxiosMixin) {
  private unsubmits: UnsubmitReport[] = []; // 未提出書類

  /** パンくずリスト */
  private breadItems: BreadItem[] = [
    {
      text: "ホーム",
      disabled: false,
      to: "/",
      exact: true
    },
    {
      text: "未提出書類",
      disabled: true,
      to: ""
    }
  ];

  /** サイドメニュー項目 */
  private sidebarList = SidebarList;

  /** 現在の表示項目 */
  private link: ReportList = this.sidebarList[0];

  /** 未提出リスト */
  private not_submit_counts: { report_type: number; count: number }[] = [];

  /** 帳票種類 */
  private get reportType() {
    return this.link.key;
  }

  /** 未提出件数付きリスト */
  private get SidebarListAddCount(): ReportList[] {
    const list: ReportList[] = [];
    for (const item of this.sidebarList) {
      for (const count of this.not_submit_counts) {
        if (Number(item.key) == count.report_type) {
          item.count = count.count;
          list.push(item);
        }
      }
    }
    return list;
  }

  /** サイドバーから表示メニューを切り替える */
  private changeList(item: { link: ReportList; index: number }) {
    this.link = item.link;
  }

  /** 選択欄から表示メニューを切り替える */
  private changeReportType(reportType: string) {
    const foundItem = this.sidebarList.find(item => item.key === reportType);
    if (foundItem) {
      this.link = foundItem;
    }
  }

  created() {
    /** 全カテゴリ未提出書類一覧取得 */
    this.postJsonCheck(window.base_url + "/api/notsubmit/get", {}, res => {
      if (!res.data) return;
      this.unsubmits = res.data.not_submits;
      this.not_submit_counts = res.data.not_submit_counts;
    });
  }
}
