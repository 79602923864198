import { Choice, SortTypeSelect } from "@/types";
import { PatientFile } from "#/model/patient";

// 検索条件インターフェース
export interface SearchCondition {
  office_id: number; // 事業所ID
  text_field: string; // 検索フィールド(ファイル名・ハッシュタグ・備考)
  update_user_id: number; // 更新者ID
  sort: number; // ソート順
  sort_type: number; // ソート：昇順・降順
  cards_size: number; // カード表示サイズ(0:小 1:中 2:大)
  toggle_list: number; // カード/リスト切り替え(0:カード表示 1:リスト表示)
}

export const DefaultSearchCondition = (): SearchCondition => ({
  office_id: 0,
  text_field: "",
  update_user_id: 0,
  sort: 0,
  sort_type: 0,
  cards_size: 1,
  toggle_list: 0
});

// ファイルインターフェース
export interface ShareFireInfo {
  id: number; //ファイルID
  file_name: string; // ファイル名
  path: string; // ファイルパス
  remarks: string; // 備考
  office_id: number; // 事業所ID
  hash_tags: Choice[]; // ハッシュタグ
  thumbnail_file_path: string; // サムネイルファイルパス
  editor_staff_id: number; // 更新者名
  updated_at: string; // 更新日時
  size: string; // サイズ
}

export const DefaultOfficeSharedFilePara = (): ShareFireInfo => ({
  id: 0,
  file_name: "",
  path: "",
  remarks: "",
  office_id: 0,
  hash_tags: [],
  thumbnail_file_path: "",
  editor_staff_id: 0,
  updated_at: "",
  size: ""
});

export interface PatientShareFileInfo {
  /** ファイルID */
  id: number;
  /** 利用者ID */
  patient_id: number;
  /** 利用者名 */
  patient_name: string;
  /** 利用者名ふりがな */
  patient_name_kana: string;
  /** 事業所ID */
  office_id: number;
  /** 事業所名 */
  office_name: string;
  /** ファイル情報 */
  patient_file: PatientFile;
  /** タグ情報 */
  tags: Choice[];
  /** サムネイルファイルパス */
  thumbnail_file_path: string;
  /** サイズ */
  size: string;
  ocr_meta: OcrMeta;
}

export interface PatientShareFile {
  /** ファイルID */
  id: number;
  /** ファイル名 */
  file_name: string;
  /** ファイルパス */
  file_path: string;
  /** タグ情報 */
  tags: Choice[];
  /** 更新日時(西暦) */
  updated_at: string;
}

export interface OcrMeta {
  ocr_enabled: boolean;
  ocr_status: OcrStatus;
  ocr_map: OcrMap;
}

export interface OcrStatus {
  id: number;
  status: number;
  smart_ocr_id: number;
  reserved_ocr_template_id: number;
}

export interface OcrMap {
  id: number;
  ocr_template_id: number;
  map: string;
}

export interface OcrTemplate {
  id: number;
  name: string;
  template_id: string;
  target_kind: number;
  sort_no: number;
}

export interface PatientShareFileSearchCondition {
  /** 事業所ID(複数) */
  office_ids: number[];
  /** タグID(複数) */
  tag_ids: number[];
  /** ファイル名 */
  file_name: string;
  /** 検索開始日 */
  start_date: string;
  /** 検索開始時間（時） */
  start_hh: string;
  /** 検索開始時間（分） */
  start_mm: string;
  /** 開始年月日時(yyyy-MM-dd hh:mm:ss) */
  start_date_time: string;
  /** 検索終了日 */
  end_date: string;
  /** 検索終了時間（時） */
  end_hh: string;
  /** 検索終了時間（分） */
  end_mm: string;
  /** 終了年月日時(yyyy-MM-dd hh:mm:ss) */
  end_date_time: string;
  /** ソート対象カラム名 */
  sort_column: string;
  /** ソート順 */
  sort_type: string;
  /** 利用者名ソート順 */
  sort_type_patient: string;
  /** カード表示サイズ(0:小 1:中 2:大) */
  cards_size?: number;
  /** カード/リスト切り替え(0:カード表示 1:リスト表示) */
  toggle_list?: number;
}

export const DefaultPatientShareFileSearchCondition = (): PatientShareFileSearchCondition => ({
  office_ids: [],
  tag_ids: [],
  file_name: "",
  start_date: "",
  start_hh: "",
  start_mm: "",
  start_date_time: "",
  end_date: "",
  end_hh: "",
  end_mm: "",
  end_date_time: "",
  sort_column: "updated_at",
  sort_type: "DESC",
  sort_type_patient: "",
  cards_size: 1,
  toggle_list: 1
});

// ファイルの並び順選択肢
export interface FileSortTypeSelect extends SortTypeSelect {
  file_column: string;
  file_order: string;
  patient_order: string;
}

// ファイルアイコン、サムネイル表示インターフェース
export interface FileInfo {
  icon: string; // アイコン種類
  iconColor: string; // アイコンカラー
}

// タグ一括編集インターフェース
export interface EditTagsList {
  ids: number[]; // 共有ファイルID
  add_tags: string[]; // 追加タグ名
  delete_tags: string[]; // 削除タグ名
}

// タグタイプ
export const TagType = {
  Non: 0, // 編集なし
  Add: 1, // 追加
  Del: 2 // 削除
} as const;
export type TagType = typeof TagType[keyof typeof TagType];

// 表示タグ
export interface DispTag {
  tag: string; // タグ
  type: TagType; // 0:編集なし 1:追加 2:削除
}
