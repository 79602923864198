
















import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { OutputRequest } from "@/views/master/choju/types";

@Component({
  components: {
    OfficeSelect
  }
})
export default class Action extends Vue {
  @Prop({}) public OutputRequest!: OutputRequest;
  @Emit()
  public input(_: OutputRequest) {
    return _;
  }
  private set Target(value: OutputRequest) {
    this.input(value);
  }
  private get Target() {
    return this.OutputRequest;
  }
}
