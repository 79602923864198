









































































































import { Component, Ref } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ja_JP from "@amcharts/amcharts4/lang/ja_JP";
import BiOfficeSelect from "@/components/officeAggregate/BiOfficeSelect.vue";
import {
  ChartData,
  TableData,
  SearchCondition
} from "@/components/officeAggregate/patientNumber/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {
    BiOfficeSelect
  }
})
export default class PatientNumber extends Base {
  @Ref("patient_number") private readonly form!: VForm;

  private chart!: am4charts.XYChart; //アムチャート
  private chartDatas: ChartData[] = []; //アムチャートデータ
  private tableDatas: TableData[] = []; //テーブルデータ
  private officeIds: number[] = []; //検索条件：事業所IDs
  private start = ""; //検索条件：請求開始月
  private end = ""; //検索条件：請求終了月

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      text: "年月",
      value: "date",
      align: "start",
      width: ""
    },
    {
      text: "事業所",
      value: "office",
      align: "start",
      width: ""
    },
    {
      text: "利用者総数",
      value: "count",
      align: "start",
      width: ""
    },
    {
      text: "13ヶ月以上（人）",
      value: "thirteen",
      align: "start",
      width: ""
    },
    {
      text: "12ヶ月（人）",
      value: "twelve",
      align: "start",
      width: ""
    },
    {
      text: "6ヶ月（人）",
      value: "six",
      align: "start",
      width: ""
    },
    {
      text: "3ヶ月（人）",
      value: "three",
      align: "start",
      width: ""
    },
    {
      text: "1ヶ月（人）",
      value: "one",
      align: "start",
      width: ""
    }
  ];

  public async mounted() {
    this.collection = "office-aggregate-patient-number";
    this.documentId = String(this.loginUser.id);
    const fbDoc = await this.documentGet();

    //親画面で保存した検索条件
    const parentFbDoc = await this.getParentSearchCon();

    //デフォルトの検索条件
    this.start = this.lastmonth();
    this.end = this.todaymonth();

    if (parentFbDoc !== undefined && typeof parentFbDoc !== "boolean") {
      const searchCondition = parentFbDoc;
      this.start = searchCondition?.start;
      this.end = searchCondition?.end;
    }
    if (fbDoc !== undefined && typeof fbDoc !== "boolean") {
      const searchCondition = fbDoc.searchCondition as SearchCondition;
      this.officeIds = searchCondition?.office_ids;
    }

    this.disposeChart();
    // 初期メッセージ
    this.chart = this.xyChartMessage();
  }

  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.XYChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.XYChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.XYChart) {
    // 拡大スクロール
    const scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX = scrollbarX;

    // Create axes
    //日付ラベル
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = false;
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.dateFormatter.language = new am4core.Language();
    dateAxis.dateFormatter.language.locale = am4lang_ja_JP;
    dateAxis.language.locale["_date_day"] = "MMM";
    dateAxis.language.locale["_date_year"] = "yyyy年";
    dateAxis.baseInterval = {
      timeUnit: "month",
      count: 1
    };

    //利用者数ラベル
    const countAxis = chart.yAxes.push(new am4charts.ValueAxis());
    countAxis.title.text = "利用者数(人)";
    let max = 0;
    this.tableDatas.forEach((item: TableData) => {
      if (item.count > max) {
        max = item.count;
      }
    });
    if (max < 10) {
      countAxis.min = 0;
      countAxis.max = 10;
    }

    //継続率ラベル
    const conAxis = chart.yAxes.push(new am4charts.ValueAxis());
    conAxis.title.text = "継続率(%)";
    conAxis.calculateTotals = true;
    conAxis.min = 0;
    conAxis.max = 100;
    conAxis.strictMinMax = true;
    conAxis.renderer.opposite = true;
    conAxis.renderer.labels.template.adapter.add("text", function(text) {
      return text + "%";
    });

    // Create series
    for (const chartData of this.chartDatas) {
      //利用者数グラフ
      const distanceSeries = chart.series.push(new am4charts.ColumnSeries());
      this.countSeries(
        distanceSeries,
        chartData,
        "利用者数" + "（" + chartData.office + "）",
        "count",
        countAxis
      );

      //継続率グラフ
      const con1Series = chart.series.push(new am4charts.LineSeries());
      this.continueSeries(con1Series, chartData, "1ヶ月", "con1", conAxis);

      const con3Series = chart.series.push(new am4charts.LineSeries());
      this.continueSeries(con3Series, chartData, "3ヶ月", "con3", conAxis);

      const con6Series = chart.series.push(new am4charts.LineSeries());
      this.continueSeries(con6Series, chartData, "6ヶ月", "con6", conAxis);

      const con12Series = chart.series.push(new am4charts.LineSeries());
      this.continueSeries(con12Series, chartData, "12ヶ月", "con12", conAxis);

      const con13Series = chart.series.push(new am4charts.LineSeries());
      this.continueSeries(
        con13Series,
        chartData,
        "13ヶ月以上",
        "con13",
        conAxis
      );
    }

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.fullWidthLineX = true;
    chart.cursor.xAxis = dateAxis;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.fill = am4core.color("#000");
    chart.cursor.lineX.fillOpacity = 0.1;

    return chart;
  }

  //利用者数グラフ
  private countSeries(
    series: am4charts.ColumnSeries,
    chartData: ChartData,
    name: string,
    valueY: string,
    countAxis: am4charts.ValueAxis<am4charts.AxisRenderer>
  ) {
    series.data = chartData.patient_datas;
    series.dataFields.valueY = valueY;
    series.dataFields.dateX = "date";
    series.yAxis = countAxis;
    series.tooltipText = "{valueY} 人";
    series.name = name;
    series.columns.template.fillOpacity = 0.7;
    series.columns.template.propertyFields.strokeDasharray = "dash_length";
    series.columns.template.propertyFields.fillOpacity = "alpha";
    series.showOnInit = true;

    const distanceState = series.columns.template.states.create("hover");
    distanceState.properties.fillOpacity = 0.9;
  }

  //継続率グラフ
  private continueSeries(
    series: am4charts.LineSeries,
    chartData: ChartData,
    name: string,
    valueY: string,
    conAxis: am4charts.ValueAxis<am4charts.AxisRenderer>
  ) {
    series.data = chartData.patient_datas;
    series.dataFields.valueY = valueY;
    series.dataFields.dateX = "date";
    series.yAxis = conAxis;
    series.name = name;
    series.strokeWidth = 2;
    series.propertyFields.strokeDasharray = "dash_length";
    series.tooltipText = "{valueY} %";
    series.showOnInit = true;

    const bullet = series.bullets.push(new am4charts.Bullet());
    const rectangle = bullet.createChild(am4core.Rectangle);
    bullet.horizontalCenter = "middle";
    bullet.verticalCenter = "middle";
    bullet.width = 7;
    bullet.height = 7;
    rectangle.width = 7;
    rectangle.height = 7;

    const state = bullet.states.create("hover");
    state.properties.scale = 1.2;
  }

  // チャートデータセット
  private setChartData(chart: am4charts.XYChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      alert("入力内容に不備があります");
      return;
    }
    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  // Excelダウンロード
  private downloadExcel() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/officeAggregate/patientNumber/downloadexcel",
      {
        table_datas: this.tableDatas,
        start: this.start,
        end: this.end
      },
      res => {
        const fileName = "patientNumber.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    const searchCondition = {
      office_ids: this.officeIds,
      start: this.start,
      end: this.end
    };

    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/patientNumber/search",
        searchCondition,
        res => {
          this.chartDatas = res.data.chart_datas;
          for (const chartData of this.chartDatas) {
            chartData.patient_datas.reverse();
          }
          this.tableDatas = res.data.table_datas;
          this.documentSave({
            searchCondition: searchCondition
          });
          resolve(0);
        }
      );
    });
  }
}
