/**
 * ダッシュボード統計カラムID
 */
export const STATISTIC_COL_ID = {
  /** 利用者情報 */
  PATIENT_INFO: "patient_info",
  /** 医療保険 */
  MEDICAL_INSURANCE: "medical_insurance",
  /** 高齢受給者証 */
  OLD_AGE: "old_age",
  /** 介護保険 */
  CARE_INSURANCE: "care_insurance",
  /** 負担割合証 */
  BURDEN_RATIO: "burden_ratio",
  /** 高額療養 */
  HIGH_EXPENSE: "high_expense",
  /** 公費1 */
  PUBLIC_MONEY: "public_money",
  /** 在宅がん療養費診療期間 */
  HOME_CANCER_TERM: "home_cancer_term",
  /** 指示書 */
  INDICATE: "indicate",
  /** 定期巡回 */
  REGULAR_VISIT: "regular_visit",
  /** 記録書Ⅰ */
  VN_RECORD: "vn_record",
  /** 記録書Ⅱ */
  VISIT_RECORD: "visit_record"
};
/**
 * ダッシュボード統計コレクションID Firebase用
 */
export const COLLECTION_ID = {
  /** 指示書など */
  INSURANCE: "statistic_insurance",
  /** 訪問予定 */
  VISIT: "statistic_visit"
};
