







































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Choice } from "@/types";
import {
  BedsorePlanPart,
  TIME_DIV_NOW
} from "@/components/patient/bedsore/types";
import RulesMixin from "#/mixins/rulesMixin";

@Component
export default class BedsoreExist extends Mixins(RulesMixin) {
  @Prop() parts!: BedsorePlanPart[];
  @Prop() existence_parts!: Choice[];
  private time_div_now = TIME_DIV_NOW;

  isBedsorePartsStr(str: string): boolean | string {
    // 半角カナと一部記号を弾く
    return (
      !/(?:[｡-ﾟ])|(?:[!-/])|(?:[:-@])|(?:[[-`])|(?:[{-~])/.test(str) ||
      "正しく入力してください"
    );
  }
}
