










































































































import { Component, Prop, Emit, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";
import { Choice, OcrTemplateChoice } from "@/types";
import { DefaultPatientFileIF } from "@/components/patient/file/type";
import { uuid } from "vue-uuid";
import { VForm } from "@/types";
import { PatientFile } from "#/model/patient";

@Component
export default class FileUploadTags extends Mixins(
  AxiosMixin,
  RulesMixin,
  ShareFileMixin
) {
  /**
   * prop
   */
  @Prop({ default: 0 }) patient_id!: number; // 利用者ID
  @Prop({ default: 0 }) officeId!: number;
  @Prop({ default: "" }) upload_dir_path!: string; // アップロードディレクトリパス
  @Prop({ default: 0 }) documentType!: number;
  @Prop({ default: [] }) tag_items!: Choice[]; // タグリスト
  @Prop({
    default: () => {
      return [];
    }
  })
  ocrTemplates!: OcrTemplateChoice[];

  /*
   * emit
   */

  @Emit()
  update() {
    return;
  }

  @Emit()
  change() {
    return;
  }

  /**
   * ref
   */

  @Ref("form") private readonly form!: VForm;

  /*
   * data
   */

  // ファイルID
  private fileId = 0;
  // ダイアログ
  private dialog = false;
  // ファイル編集フラグ
  private isModify = false;
  // 変更前ファイルパス
  private beforeFilePath = "";
  // ファイル情報
  private fileInfo = DefaultPatientFileIF();
  // イメージ
  private image: File | [] = [];
  // OCR制御用
  private isOcrShown = false;
  private isOcrEnabled = false;
  private isOcrTarget = 0;
  private ocrTemplateId = 0;
  // 直で書くと改行されない（長すぎ？）
  private helpMessage =
    "タグを複数設定した時の並び順は、マスタメンテで設定したファイルタグの並び順と同じになります。\nファイルタグはマスタメンテ＞選択肢マスタ＞ファイルタグ＞[並び替え]で並び替えができます。\n\n「ファイル登録通知」タグ選択時、利用者ファイルが登録されたことを通知します。\n通知先やタグの表示/非表示はマスタメンテ＞事業所マスタ＞通知で設定できます。";

  /*
   * method
   */

  public created(): void {
    this.init();
  }

  public open(
    fileId: number,
    isOcrShown: boolean,
    isOcrEnabled: boolean,
    isOcrTarget: number,
    ocrTemplateId: number
  ): void {
    // 初期化
    this.fileId = fileId;
    this.isOcrShown = isOcrShown;
    this.isOcrEnabled = isOcrEnabled;
    this.isOcrTarget = isOcrTarget;
    this.ocrTemplateId = ocrTemplateId;

    if (this.fileId > 0) {
      // ファイル情報取得
      this.postJsonCheck(
        window.base_url + "/api/patient/file/get",
        {
          patient_id: Number(this.patient_id),
          patient_file_id: Number(this.fileId)
        },
        res => {
          if (!res.data) return;
          this.$nextTick(() => {
            this.fileInfo = res.data.file_info;
            this.beforeFilePath = this.fileInfo.file_path;
          });
        }
      );
    }

    if (this.form) {
      this.form.reset();
    }
    this.dialog = true;
  }

  public close(): void {
    this.dialog = false;
    this.init();
  }

  // 初期化
  private init(): void {
    this.image = [];
    this.isModify = false;
    this.beforeFilePath = "";
    // 以前のblobデータを解放
    this.fileInfo = DefaultPatientFileIF();
  }

  // ファイル変更
  private changeFile(): void {
    this.isModify = true;
    // 以前のblobデータを解放
    if (this.image instanceof File) {
      let name = this.image.name;
      if (name.length > 100) {
        const split = name.split(".");
        name = split[0].slice(0, 90) + "." + split[1];
      }
      this.fileInfo.file_name = name;
      this.change();
    }
  }

  // 削除
  private async clickDelete(): Promise<void> {
    if (
      !(await this.$openConfirm(
        "削除します。一度削除するとデータは復旧できません。よろしいですか？"
      ))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/patient/file/delete",
      {
        file_info: this.fileInfo
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  // 保存
  private async clickSave() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (this.isOcrTarget && this.ocrTemplateId === 0) {
      await this.$openAlert("OCRテンプレートを選択してください");
      return;
    }

    if (this.isModify && this.image instanceof File) {
      // ファイルアップロード
      const formData = new FormData();
      formData.append("upload", this.image);
      let path = this.fileInfo.file_path;
      if (!path) {
        // パスが存在しない（新規に登録）場合はパスを作成
        path = this.upload_dir_path;
        if (path.slice(-1) !== "/") {
          path += "/";
        }
        path += uuid.v4();
      }
      formData.append("path", path);
      formData.append("is_temp", "0");
      const url = window.base_url + "/api/upload";
      this.postJsonCheck(url, formData, res => {
        this.fileInfo.file_path = res.data.path;
        this.saveFileInfo();
      });
    } else {
      this.saveFileInfo();
    }
  }

  // ファイル情報保存
  private saveFileInfo(): void {
    this.fileInfo.patient_id = Number(this.patient_id);
    this.postJsonCheck(
      window.base_url + "/api/patient/file/save",
      {
        file_info: this.fileInfo,
        before_file_path: this.beforeFilePath
      },
      async res => {
        // メール送信に失敗した場合は、メッセージだけ表示し以降の処理は成功と同じ（保存自体は成功している）

        // レセプト代行通知失敗
        if (res.data.send_agency_email_failed) {
          await this.$openAlert(
            "レセプト代行サービスへファイル登録通知メールが送信できませんでした。\n連絡が必要な場合は、別の方法でご連絡をお願いします。"
          );
        }

        // ファイル登録通知失敗
        if (res.data.send_notice_email_failed) {
          await this.$openAlert(
            "ファイル登録通知メールが送信できませんでした。\n\n--------エラー内容詳細--------\n\n登録された「通知メールアドレス」が間違っているか、何らかの理由により一時的に送信できなかった可能性があります。\n別の方法でご連絡いただくか、事業所マスタ＞通知＞「通知メールアドレス」が正しいかご確認ください。"
          );
        }

        // OCR周りの処理
        if (this.isOcrShown && this.isOcrEnabled) {
          this.ocr(res.data.saved_patient_file);
        } else {
          this.update();
          this.close();
        }
      }
    );
  }

  private ocr(file: PatientFile) {
    this.postJsonCheck(
      window.base_url + "/api/ocrstore/target/save",
      {
        document_type: this.documentType,
        document_id: file.id,
        patient_id: file.patient_id,
        office_id: this.officeId,
        is_ocr_target: this.isOcrTarget,
        ocr_target_template_id: this.ocrTemplateId
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  // ファイルチェック
  private validFileInput(): boolean | string {
    let result;
    if (!this.fileInfo.file_path) {
      result = this.requiredFile(this.image, "ファイルが選択されていません");
      if (result !== true) {
        return result;
      }
    }
    result = this.maxFileSize(this.image, 1e7, "ファイルが10Mを超えています");
    if (result !== true) {
      return result;
    }
    return true;
  }
}
