/** 処理状態 */
export const ProcessState = {
  /** 未処理 */
  UNPROCESS: 1,
  /** 処理中 */
  PROCESSING: 2,
  /** 処理完了 */
  PROCESSED: 3,
  /** 中断中 */
  INTERRUPTING: 4,
  /** 中断完了 */
  INTERRUPTED: 5
};

/* 同一建物内人数集計結果 */
export interface SameBuildingAggregation {
  /** 訪問日 */
  visit_date: string;
  /** 同一建物ID */
  same_building_id: number;
  /** 利用者数(基本) */
  patient_count: number;
  /** 利用者数(複数名（イ）・1回) */
  patient_count_accompanied_i_one: number;
  /** 利用者数(複数名（イ）・2回) */
  patient_count_accompanied_i_two: number;
  /** 利用者数(複数名（イ）・3回以上) */
  patient_count_accompanied_i_over_three: number;
  /** 利用者数(複数名（ロ）・1回) */
  patient_count_accompanied_ro_one: number;
  /** 利用者数(複数名（ロ）・2回) */
  patient_count_accompanied_ro_two: number;
  /** 利用者数(複数名（ロ）・3回以上) */
  patient_count_accompanied_ro_over_three: number;
  /** 利用者数(複数名（ハ）・1回) */
  patient_count_accompanied_ha_one: number;
  /** 利用者数(複数名（ニ）1回) */
  patient_count_accompanied_ni_one: number;
  /** 利用者数(複数名（ニ）2回) */
  patient_count_accompanied_ni_two: number;
  /** 利用者数(複数名（ニ）3回以上) */
  patient_count_accompanied_ni_over_three: number;
  /** 利用者数(難病複数回・2回) */
  patient_count_times_div_two: number;
  /** 利用者数(難病複数回・3回以上) */
  patient_count_times_div_over_three: number;
}

/** レセプト事前チェック結果 */
export interface PreCheckResponse {
  /** 処理結果メッセージ */
  process_message: string;
  /** レセプト事前チェック結果 */
  check_responses: PreCheckResponseRecord[];
  /** 再登録成功実績カウント */
  register_succeeded_count: number;
  /** 再登録失敗実績カウント */
  register_failed_count: number;
}

/** レセプト事前チェック結果(各実績の情報) */
export interface PreCheckResponseRecord {
  /** 実績ID */
  id: number;
  /** 1:医療 2:精神 */
  mental_div: number;
  /** サービス提供日 */
  service_date: string;
  /** サービス提供開始時刻 */
  service_start_time: string;
  /** サービス提供終了時刻  */
  service_end_time: string;
  /** サービス提供時刻 */
  service_time: string;
  /** 同一建物名 */
  same_building_name: string;
  /** 利用者名 */
  patient_name: string;
  /** 利用者名ふりがな */
  patient_name_kana: string;
  /** 基本療養費区分（前） */
  remedy_div_before: number;
  /** 基本療養費区分（後）  */
  remedy_div_after: number;
  /** 同一日3人以上（前） */
  three_person_flg_before: number;
  /** 同一日3人以上（後）  */
  three_person_flg_after: number;
  /** 複数名訪問看護加算に関わる同一建物内人数(前) */
  accompanied_staff_threeperson_div_before: number;
  /** 複数名訪問看護加算に関わる同一建物内人数(前)  */
  accompanied_staff_threeperson_div_after: number;
  /** 難病等複数回訪問看護加算に関わる同一建物内人数(前) */
  times_threeperson_div_before: number;
  /** 難病等複数回訪問看護加算に関わる同一建物内人数(前)  */
  times_threeperson_div_after: number;
  /** 状態 0:変更なし 1:再登録成功 2:再登録失敗 */
  status: number;
  /** エラーメッセージ */
  error_message: string;
}
