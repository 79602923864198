


























































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import DateInput from "#/components/DateInput.vue";
import {
  CareInsuranceBurdenRatio,
  DefaultCareInsuranceBurdenRatio
} from "#/model/patient";
import { VForm } from "@/types";
import { namespace } from "vuex-class";
import * as ocr from "@/store/modules/ocr/types";
import { TargetKind } from "@/const/ocr";

const Ocr = namespace(ocr.name);

@Component({
  components: {
    PatientHeader,
    DateInput
  }
})
export default class InsuranceInfoCareRateCert extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  @Ocr.Mutation(ocr.CLEAR_STATE) clearOcrState!: Function;
  @Ocr.Getter("OcrState") ocrState!: ocr.OcrState;

  private insCareRateCertId = 0;
  private patientId = 0;
  private mode = "";

  private care_burden: CareInsuranceBurdenRatio = DefaultCareInsuranceBurdenRatio(); //介護保険負担割合証

  created() {
    this.insCareRateCertId = Number(this.$route.params.inscareratecertid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchInsurerRateCert();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=CareRateCert`
      },
      {
        text: "介護保険負担割合証",
        disabled: true,
        to: ""
      }
    ]);
  }

  destroy() {
    this.clearOcrState();
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //登録
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    if (this.mode) {
      this.care_burden.id = 0;
    }
    this.care_burden.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/ratecert/save",
      {
        care_burden: this.care_burden
      },
      async res => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        // OCR 結果から作成成功した場合は OCR ステータスを「作成済み」にする
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.applyOcr();
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  private applyOcr() {
    if (this.ocrState.documentType === 0 || this.ocrState.documentId === 0) {
      return;
    }
    this.postJsonBackground(
      window.base_url + "/api/ocrstore/mapping/apply",
      {
        document_type: this.ocrState.documentType,
        document_id: this.ocrState.documentId
      },
      () => {
        // do nothing
      }
    );
  }

  //変更
  private update() {
    this.care_burden.id = this.insCareRateCertId;
    this.save();
  }

  //削除
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.care_burden.id = this.insCareRateCertId;
      this.care_burden.patient_id = this.patientId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/ratecert/delete",
        {
          care_burden: this.care_burden
        },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  //介護保険負担割合証情報取得
  private fetchInsurerRateCert(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/ratecert/get",
      {
        patient_id: this.patientId,
        ins_care_rate_cert_id: this.insCareRateCertId
      },
      res => {
        //介護保険負担割合証情報
        if (this.insCareRateCertId != 0) {
          const careBurden: CareInsuranceBurdenRatio = res.data.care_burden;
          if (this.mode) {
            careBurden.start_date = "";
            careBurden.end_date = "";
          }
          this.care_burden = careBurden;
        }

        // OCR 結果から作成の場合、初期値設定
        const from = this.$route.query.from;
        if (from === "ocr") {
          this.setOcrParams();
        }

        this.setLoaded();
      }
    );
  }

  private setOcrParams() {
    // 一般
    if (this.ocrState.targetKind === TargetKind.careInsPaymentRate) {
      this.care_burden.burden_rate_select = this.ocrState.careInsPaymentRate.rate;
      this.care_burden.start_date = this.ocrState.careInsPaymentRate.startDate;
      this.care_burden.end_date = this.ocrState.careInsPaymentRate.endDate;
    }
  }

  //-----------------------------------------------------
  // 必須入力切り替え
  //-----------------------------------------------------
  //負担割合：負担割合未選択 && 給付率スイッチオフで必須チェック
  private requiredBurdenRateSelect(): boolean | string {
    if (
      this.care_burden.burden_rate_select == 0 &&
      this.care_burden.use_payment_rate == 0
    ) {
      return this.minNumber(this.care_burden.burden_rate_select, 1, "必須です");
    }
    return true;
  }

  //給付率：給付率未入力 && 給付率スイッチオンで必須チェック・入力値チェック
  private requiredPaymentRate(): boolean | string {
    if (
      this.care_burden.payment_rate == 0 &&
      this.care_burden.use_payment_rate == 1
    ) {
      return this.minNumber(this.care_burden.payment_rate, 0, "必須です");
    }
    return this.rangeNumber(
      this.care_burden.payment_rate,
      0,
      100,
      "給付率は0～100で入力してください"
    );
  }

  //開始日有効判定
  private checkStartDate(): boolean | string {
    if (this.care_burden.start_date) {
      const start = new Date(this.care_burden.start_date.replace(/-/g, "/"));
      const target = new Date("2015-08-01".replace(/-/g, "/"));
      if (target > start) {
        return "開始日は2015年8月1日以降を入力してください";
      }
    }
    return true;
  }

  //7月末ボタンn表示
  private dispJulyEnd(): boolean {
    if (this.care_burden.start_date) {
      return true;
    }
    return false;
  }

  //編集モード設定
  @Watch("care_burden", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
