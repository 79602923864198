





















import { Component, Prop, Emit, Mixins, Ref } from "vue-property-decorator";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

@Component
export default class PdfLinkShowConfirm extends Mixins(RulesMixin) {
  @Ref("confirmForm") private readonly confirmForm!: VForm;

  /** ダイアログ表示フラグ */
  @Prop() dialog!: boolean;
  /** アラートメッセージ */
  @Prop() alertMessage!: string;

  /** 入力メールアドレス */
  private mailAddress = "";

  private close() {
    // 空白ページabout:blankに遷移
    location.href = "about:blank";
  }

  private async onSubmit() {
    if (!this.confirmForm.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.confirm();
  }

  @Emit()
  confirm() {
    return this.mailAddress;
  }
}
