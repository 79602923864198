













import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { SortWarekiDate } from "#/utility/appSort";
import ItemTable from "#/components/ItemTable.vue";
import { PublicItems } from "./type";

@Component({
  components: {
    ItemTable
  }
})
export default class Public extends Vue {
  /** 利用者ID */
  @Prop({ default: 0 }) private patientId!: number;

  /** 高額療養費 */
  @Prop({ default: () => [] }) private readonly items!: PublicItems[];

  /** 職員権限区分 0:一般職員 1:一般職員以外（一般職員は新規作成、修正、コピー等の編集ができない）*/
  @Prop({ default: 0 }) private readonly authDiv!: number;

  /** 公費ヘッダー */
  private publicHeaders: DataTableHeader[] = [
    {
      text: "編集",
      value: "regist",
      sortable: false,
      width: "7.5rem",
      class: "px-1",
      cellClass: "px-1",
      align: "center"
    },
    { text: "区分", value: "div", width: "60px" },
    { text: "公費種類", value: "public_money_type", width: "260px" },
    {
      text: "開始日",
      value: "start_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "終了日",
      value: "end_date_wareki",
      width: "158px",
      sort: SortWarekiDate
    },
    {
      text: "負担者番号",
      value: "bearer_number",
      width: "80px"
    },
    {
      text: "受給者番号",
      value: "recipient_number",
      width: "80px"
    },
    { text: "備考", value: "remarks", width: "200px" }
  ];

  /** 新規作成 */
  private publicCreate() {
    this.$router.push({
      name: "InsuranceInfoPublic",
      params: { id: String(this.patientId), inspublicid: "0" }
    });
  }

  /** 修正 */
  private publicRepair(item: PublicItems) {
    this.$router.push({
      name: "InsuranceInfoPublic",
      params: { id: String(this.patientId), inspublicid: String(item.id) }
    });
  }

  /** コピー */
  private publicCopy(item: PublicItems) {
    this.$router.push({
      name: "InsuranceInfoPublic",
      params: { id: String(this.patientId), inspublicid: String(item.id) },
      query: { mode: "copy" }
    });
  }

  /** 現在有効な公費に色を付ける */
  private publicItemsRowBackground(item: PublicItems) {
    return item.effective == true ? "green lighten-4" : "";
  }
}
