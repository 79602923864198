



























import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import {
  NursingPrimaryContent,
  DefaultNursingPrimaryContent
} from "@/views/master/nursing_content/types";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";
import { VForm } from "@/types";

@Component({
  components: {
    OfficeSelectInput
  }
})
export default class EditNursingPrimaryContentDialog extends Mixins(
  AxiosMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {
        id: null,
        content_name: "",
        care_large_content_offices: [],
        care_medium_contents: [],
        is_ai: 1
      };
    }
  })
  content!: NursingPrimaryContent;

  private cloneContent: NursingPrimaryContent = DefaultNursingPrimaryContent();

  //選択済みの事業所
  public selectedOffice: number[] = [];

  created() {
    this.cloneContent = { ...this.content };
  }

  // 選択済みの適応事業所
  private mounted(): void {
    if (this.cloneContent.care_large_content_offices.length > 0) {
      this.cloneContent.care_large_content_offices.forEach(office => {
        if (office.office_id) {
          this.selectedOffice.push(office.office_id);
        }
      });
    } else {
      this.selectedOffice = [];
    }
  }

  private get IsDelete() {
    return this.cloneContent.id && this.cloneContent.id !== 0;
  }

  //保存ボタン
  private didClickSaveButton() {
    if (this.form.validate()) {
      this.cloneContent.care_large_content_offices = [];
      if (this.selectedOffice.length > 0) {
        this.selectedOffice.forEach(officeId => {
          this.cloneContent.care_large_content_offices.push({
            office_id: officeId
          });
        });
      }
      this.save(this.cloneContent);
    }
  }

  //キャンセルボタン
  private didClickCancelButton() {
    this.closeDialog();
  }

  //削除ボタン
  private async didClickRemoveButton() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.remove(this.cloneContent);
  }

  @Emit("save")
  private save(cloneContent: unknown) {
    return cloneContent;
  }

  @Emit("remove")
  private remove(cloneContent: unknown) {
    return cloneContent;
  }

  @Emit("close")
  private closeDialog() {
    return;
  }
}
