import { Link } from "#/types";

export interface ReportList extends Link {
  labelStaff?: string; // デフォルト：担当者
  labelDate?: string; // デフォルト：記載日
  isHideUpdStaff?: boolean; // 更新者を隠すかどうか
}

/** 帳票種別 */
export const REPORTTYPE = {
  VISITRECORD: 1,
  BEDSOREPLAN: 2,
  CARESUMMARY: 3,
  CONFERENCEREPORT: 4,
  MONTHREPORT: 5,
  OCCURREPORT: 6,
  PROVISIONREPORT: 7,
  SERVICEREPORT: 8,
  VNPLAN: 9,
  VNRECORD: 10
};

/** 書類種類別の編集画面パス */
export const ReportTypeToRoute = {
  [REPORTTYPE.VISITRECORD]: "record2/edit",
  [REPORTTYPE.BEDSOREPLAN]: "bedsore/edit",
  [REPORTTYPE.CARESUMMARY]: "caresummary/edit",
  [REPORTTYPE.CONFERENCEREPORT]: "conference/edit",
  [REPORTTYPE.MONTHREPORT]: "report/edit",
  [REPORTTYPE.OCCURREPORT]: "occur/edit",
  [REPORTTYPE.PROVISIONREPORT]: "provision/edit",
  [REPORTTYPE.SERVICEREPORT]: "service/edit",
  [REPORTTYPE.VNPLAN]: "plan/edit",
  [REPORTTYPE.VNRECORD]: "vnrecord/edit"
};

/** サイドメニュー項目 */
export const SidebarList: ReportList[] = [
  {
    key: REPORTTYPE.VISITRECORD + "",
    text: "看護記録Ⅱ",
    labelStaff: "訪問者",
    labelDate: "訪問日"
  },
  {
    key: REPORTTYPE.BEDSOREPLAN + "",
    text: "褥瘡対策看護計画",
    labelStaff: "計画作成者",
    labelDate: "計画作成日"
  },
  {
    key: REPORTTYPE.CARESUMMARY + "",
    text: "看護サマリ",
    labelDate: "訪問日"
  },
  {
    key: REPORTTYPE.CONFERENCEREPORT + "",
    text: "会議録",
    labelDate: "会議日"
  },
  {
    key: REPORTTYPE.MONTHREPORT + "",
    text: "月次報告書",
    labelDate: "報告月"
  },
  {
    key: REPORTTYPE.OCCURREPORT + "",
    text: "事故/ヒヤリハット報告書",
    labelStaff: "報告者",
    labelDate: "報告日"
  },
  {
    key: REPORTTYPE.PROVISIONREPORT + "",
    text: "情報提供書",
    labelStaff: "更新者",
    isHideUpdStaff: true,
    labelDate: "報告月"
  },
  {
    key: REPORTTYPE.SERVICEREPORT + "",
    text: "サービス確認書",
    labelStaff: "説明者",
    labelDate: "説明確認日"
  },
  {
    key: REPORTTYPE.VNPLAN + "",
    text: "看護計画書",
    labelDate: "計画月"
  },
  {
    key: REPORTTYPE.VNRECORD + "",
    text: "看護記録Ⅰ",
    labelStaff: "更新者",
    isHideUpdStaff: true
  }
];
