import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:記録書Ⅱ */
export const headerVisitRecord: StatisticHeader = {
  id: STATISTIC_COL_ID.VISIT_RECORD,
  text: "記録書Ⅱ",
  is_checked: true,
  value: [
    {
      text: "訪問日",
      value: `${STATISTIC_COL_ID.VISIT_RECORD}.visit_date`,
      sortable: true,
      align: "start",
      width: "100px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "訪問時間",
      value: `${STATISTIC_COL_ID.VISIT_RECORD}.visit_start_end_time`,
      sortable: true,
      align: "start",
      width: "100px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "次回訪問日",
      value: `${STATISTIC_COL_ID.VISIT_RECORD}.next_visit_date`,
      sortable: true,
      align: "start",
      width: "100px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "次回訪問時間",
      value: `${STATISTIC_COL_ID.VISIT_RECORD}.next_visit_time`,
      sortable: true,
      align: "start",
      width: "100px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "訪問者",
      value: `${STATISTIC_COL_ID.VISIT_RECORD}.staff.ibow_nickname`,
      sortable: true,
      align: "start",
      width: "100px",
      selected: true,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    }
  ]
};
