import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import UtilMixin from "@/mixins/utilMixin";
import { OFFICE_STAFF, COMMON } from "#/const";

export interface ListItems {
  id: number; // ID
  patient_id: number; // 利用者ID
  is_show_edit: boolean; // 編集ボタン表示するかどうか
  is_show_remand: boolean; // 差戻しボタン表示するかどうか
  status: number; // 書類状態
  visit_staff_name: string; // 訪問者
  visit_date_time: string; // 訪問日時
  visit_date_time_wareki: string; // 訪問日時_表示用和暦
  create_staff_name: string; // 記入者
  remand_comment: string; // 差戻しコメント
  submit_date_time: string; // 提出日時
  submit_date_wareki: string; // 提出日_表示用和暦
  receipt_yearmonth: string; // レセプト実績確定月
  receipt_status: number; // 0:未確定 1:実績確定済 2:請求履歴済
  remand_staff_name: string; // 差し戻し先職員名
}

export interface PatientListItems extends ListItems {
  patient_info: string; //表示用利用者情報
  patient_name_sort: string; //ソート用利用者情報
  edit: number; //編集ソート
}

@Component
export default class BaseRecord2List extends Mixins(
  AxiosMixin,
  UtilMixin,
  PatientMixin
) {
  /** 一般職員権限 */
  protected STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;
  /** 保存 */
  protected REPORT_SAVE = COMMON.REPORT_STATUS.SAVE;
  /** 提出 */
  protected REPORT_SUBMIT = COMMON.REPORT_STATUS.SUBMIT;
  /** 差し戻し */
  protected REPORT_REMAND = COMMON.REPORT_STATUS.REMAND;

  protected records: (ListItems | PatientListItems)[] = [];

  /** 編集ボタン列の幅 */
  protected get submitEditAreaWidth() {
    if (this.loginUserAuthId > this.STAFF_COMMON) {
      return "192px";
    }
    return "60px";
  }

  /** 行をクリックした時の処理 */
  protected clickEdit(item: ListItems) {
    if (item.is_show_edit) {
      //編集できる場合は編集画面に遷移
      this.$router.push({
        name: "PatientRecord2Edit",
        params: { id: String(item.patient_id), record2_id: String(item.id) }
      });
    } else {
      //編集できない場合はPDFを出力
      this.pdf(item);
    }
  }

  // 編集して差戻し
  protected async clickEditRemand(item: ListItems) {
    if (await this.remand(item)) {
      this.$router.push({
        name: "PatientRecord2Edit",
        params: { id: String(item.patient_id), record2_id: String(item.id) },
        query: { mode: "remand" }
      });
    }
  }

  // 差戻し共通処理
  protected async remand(item: ListItems): Promise<boolean> {
    if (item.receipt_status == 2) {
      await this.$openAlert(
        "請求履歴が確定されています。管理者へ請求履歴を未確定にするように連絡してください。"
      );
      return false;
    }
    if (item.receipt_status == 1) {
      if (
        !(await this.$openConfirm(
          "実績確定されています。差戻しを行うと実績確定が解除されますが記録書を差戻しますか？"
        ))
      ) {
        return false;
      }
    }
    return true;
  }

  /** 書類を差し戻す */
  protected async remandPdf(recordItem: {
    item: ListItems;
    comment: string;
  }): Promise<void> {
    if (
      !(await this.$openConfirm(
        `看護記録書Ⅱを ${recordItem.item.remand_staff_name}さん へ差戻しますか？`
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/patient/visitrecord/remandByList",
      {
        patient_id: recordItem.item.patient_id,
        visit_record_id: recordItem.item.id,
        comment: recordItem.comment
      },
      () => {
        this.records = this.records.filter(v => v.id !== recordItem.item.id);
      }
    );
  }

  /** PDFを表示する */
  protected pdf(item: ListItems): void {
    // 提出済みの場合
    if (item.status === this.REPORT_SUBMIT) {
      this.makePdf(window.base_url + "/api/patient/visitrecord/preview/ids", {
        patient_id: item.patient_id,
        visit_records: [item],
        visit_record_ids: [item.id]
      });
      return;
    }
    // 提出済み以外
    this.makePdf(window.base_url + "/api/patient/visitrecord/previewByList", {
      patient_id: item.patient_id,
      visit_record_id: item.id
    });
    return;
  }
}
