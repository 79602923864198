




































import { Component, Vue, Prop } from "vue-property-decorator";
import { CalendarEvent } from "@/types";

@Component
export default class VisitTitle extends Vue {
  @Prop({ default: () => ({}) }) isHonobono!: number;
  @Prop({ default: () => ({}) }) honobonoJigyoKnj!: string;
  @Prop({ default: () => ({}) }) event!: CalendarEvent;
  @Prop({ default: () => ({}) }) title!: string;
}
