export interface CareType {
  id: number;
  icon: string;
  color: string;
  text: string;
  name: string;
}

export interface ObserveItemCategory {
  [key: string]: number | string | Office[];
  id: number;
  name: string;
  use_care: number;
  use_care_rehabilitation: number;
  use_medical: number;
  use_medical_rehabilitation: number;
  use_medical_mental: number;
  use_medical_mental_rehabilitation: number;
  observation_category_item_offices: Office[];
  is_editable: number;
}

export function initObserveItemCategory(): ObserveItemCategory {
  return {
    id: 0,
    name: "",
    use_care: 0,
    use_care_rehabilitation: 0,
    use_medical: 0,
    use_medical_rehabilitation: 0,
    use_medical_mental: 0,
    use_medical_mental_rehabilitation: 0,
    observation_category_item_offices: [],
    is_editable: 1
  };
}

export interface Office {
  office_id: number;
}

export const CARE_TYPES: CareType[] = [
  {
    id: 1,
    icon: "mdi-human-wheelchair",
    color: "primary",
    text: "介護看護",
    name: "use_care"
  },
  {
    id: 2,
    icon: "mdi-account-heart",
    color: "cyan",
    text: "介護リハ",
    name: "use_care_rehabilitation"
  },
  {
    id: 3,
    icon: "mdi-heart-pulse",
    color: "primary",
    text: "医療看護",
    name: "use_medical"
  },
  {
    id: 4,
    icon: "mdi-heart-plus",
    color: "cyan",
    text: "医療リハ",
    name: "use_medical_rehabilitation"
  },
  {
    id: 5,
    icon: "mdi-head-heart-outline",
    color: "primary",
    text: "精神看護",
    name: "use_medical_mental"
  },
  {
    id: 6,
    icon: "mdi-head-lightbulb-outline",
    color: "cyan",
    text: "精神リハ",
    name: "use_medical_mental_rehabilitation"
  }
];
