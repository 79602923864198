









































































import { Component, Vue } from "vue-property-decorator";
import { SHIFT_COLORS, ShiftItem } from "@/components/master/shift/common";

@Component
export default class StaffShiftTypeEntry extends Vue {
  public dialog = false;

  // シフト開始時刻
  public startHour = 0;
  public startMinute = 0;

  // シフト終了時刻
  public endHour = 0;
  public endMinute = 0;

  // シフト種類色
  public colors: string[] = SHIFT_COLORS;

  // シフトデータ
  public shiftItems: ShiftItem[] = [
    {
      id: 1,
      color: "primary",
      name: "テスト",
      time: "10:00~11:00",
      type: "Myシフト"
    },
    {
      id: 2,
      color: "green",
      name: "テス2",
      time: "10:00~11:00",
      type: "Myシフト"
    },
    {
      id: 3,
      color: "red",
      name: "テス3",
      time: "10:00~11:00",
      type: "Myシフト"
    }
  ];

  // シフト登録
  public saveShift() {
    this.dialog = false;
  }

  // シフト削除
  public async deleteShift(id: number) {
    if (!(await this.$openConfirm("シフト種類を削除してよろしいですか？"))) {
      return;
    }
    this.shiftItems = this.shiftItems.filter(item => item.id != id);
  }

  public close() {
    this.dialog = false;
  }
}
