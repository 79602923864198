import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { ChatState } from "#/store/types";

export const getters: GetterTree<ChatState, RootState> = {
  AccountImagePath: (state: ChatState) => (accountId: number) => {
    const accountImage = state.accountImages.find(image => {
      return image.accountId === accountId;
    });

    if (accountImage) {
      return accountImage.path;
    }

    return "";
  },
  CanNoticeChatAgreement: (state: ChatState) => (agreementId: number) => {
    return (
      state.enabledAgreements.findIndex(agreement => {
        return agreement.id === agreementId;
      }) !== -1
    );
  },
  HasChatEnabledAgreement: (state: ChatState) => {
    return state.enabledAgreements.length > 0;
  }
};
