












































































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { DataTableHeader } from "vuetify/types/index";

interface Disease {
  agreement_id: number;
  name: string;
}

@Component
export default class AppDisease extends Mixins(AxiosMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  @Prop() value!: string; //値

  @Prop() label!: string; //ラベル

  @Prop() rules!: (boolean | string)[]; //条件

  @Prop({ default: false }) showConvertButton!: boolean; //変換ボタン表示

  private isOpen = false; //ダイアログ

  private keyword = ""; //検索キーワード

  //傷病ヘッダー
  private diseaseHeaders: DataTableHeader[] = [
    {
      text: "傷病名",
      value: "name",
      width: "300px",
      align: "center",
      class: "pa-1"
    }
  ];

  //傷病アイテムリスト
  private diseases: Disease[] = [];

  private get Diseases() {
    if (this.loading) {
      return [];
    }
    return this.diseases;
  }

  //傷病ダイアログで行選択
  private diseaseClickRow(row: Disease) {
    this.Value = row.name;
    this.isOpen = false;
  }

  //検索（病名）
  private async search() {
    //検索キーワードが空の場合、検索不可能（件数が多すぎる為）
    if (this.keyword == "") {
      await this.$openAlert("傷病名を入力してください");
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/patient/indicate/search",
      {
        disease_input: this.keyword
      },
      res => {
        if (!res.data.diseases) {
          this.diseases = [];
        } else {
          this.diseases = res.data.diseases;
        }
      }
    );
  }

  //ダイアログ開く
  private openDialog() {
    this.isOpen = true;
  }

  private get Value(): string {
    return this.value;
  }

  private set Value(newValue: string) {
    this.input(newValue);
  }

  @Emit()
  private input(newValue: string): string {
    return newValue;
  }

  @Emit()
  private convert(): string {
    return this.prefix_id;
  }
}
