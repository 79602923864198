


























































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import ShiftTypeEntryDialog from "@/components/shift/StaffShiftTypeEntry.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { ShiftTemplate } from "#/model/schedule";
import { VForm } from "@/types";
import { CalendarEvent } from "vuetify";
import { Calendar } from "#/components/calendar/common";
import { OfficeStaffChoice } from "#/model/staff";
import { OFFICE_STAFF } from "#/const";
import { SHIFT_COLORS } from "@/components/shift/types";
import StaffShiftTitle from "@/components/shift/StaffShiftTitle.vue";
import StaffShiftList from "@/components/shift/StaffShiftList.vue";
import StaffShiftEditDialog from "@/components/shift/StaffShiftEditDialog.vue";

@Component({
  components: {
    ShiftTypeEntryDialog,
    StaffShiftTitle,
    StaffShiftList,
    StaffShiftEditDialog
  }
})
export default class StaffShift extends Mixins(AxiosMixin, UtilMixin) {
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;

  /** フォームrefオブジェクト */
  @Ref("dayShiftForm") private readonly dayShiftForm!: VForm;

  /** カレンダーrefオブジェクト */
  @Ref("calendar") private readonly calendar!: Calendar;

  /** シフト編集refオブジェクト */
  @Ref("staff-shift-edit-dialog")
  private readonly staffShiftEditDialog!: StaffShiftEditDialog;

  /** 対象年月変更時 */
  @Watch("yearmonth") private changeYearmonth() {
    this.fetch();
  }

  /** 事業所ID */
  private officeId = 0;

  /** 職員ID */
  private staffId = 0;

  /** 職員リスト */
  private staffs: OfficeStaffChoice[] = [];

  /** シフトテンプレート */
  protected shift_templates: ShiftTemplate[] = [];

  /** 対象年月 */
  private yearmonth = new Date().toISOString().substr(0, 7);

  /** iBowKINTAIで取り込んだシフトの色ID */
  private kintaiLinkShiftColorId = -1;

  /** iBowKINTAI使用フラグ */
  private isAttendUse = false;

  /** シフトイベント */
  private events: CalendarEvent[] = [];

  /** 休日イベント */
  private holiday_events: CalendarEvent[] = [];

  /** カレンダー表示用イベント */
  private calendarEvents: CalendarEvent[] = [];

  /** 対象年月の初日 */
  private get CalendarDate(): string {
    return this.yearmonth + "-01";
  }

  created(): void {
    const office_id = this.$route.params.office_id;
    if (office_id) {
      this.officeId = Number(office_id);
    } else {
      this.officeId = this.loginUser.office_id;
    }
    const staff_id = this.$route.params.staff_id;
    if (staff_id) {
      this.staffId = Number(staff_id);
    } else {
      this.staffId = this.loginUser.id;
    }
    this.fetch();
  }

  /** データを取得する */
  private fetch(): void {
    this.postJsonCheck(
      window.base_url + "/api/shift/staff/get",
      {
        staff_id: this.staffId,
        office_id: this.officeId,
        target_year_month: this.yearmonth
      },
      res => {
        if (!res.data) return;
        if (res.data.shift_templates != null) {
          this.shift_templates = res.data.shift_templates;
        }
        if (res.data.events != null) {
          this.events = res.data.events;
        }
        this.isAttendUse = res.data.is_attend_use;
        if (res.data.holiday_events != null) {
          this.holiday_events = res.data.holiday_events;
        }
        this.staffs = res.data.staffs;
        this.setCalendarEvents();
      }
    );
  }

  // カレンダー用のイベントを作成する
  private setCalendarEvents() {
    const events: CalendarEvent[] = [];
    for (const event of this.events) {
      event.color = this.getEventColor(event);
      events.push(event);
    }
    for (const event of this.holiday_events) {
      events.push(event);
    }
    this.calendarEvents = events;
  }

  /** 勤怠の色を取得する */
  private getEventColor(event: CalendarEvent): string {
    return event.color_id != this.kintaiLinkShiftColorId
      ? SHIFT_COLORS[event.color_id]
      : "#42BA96";
  }

  /** 翌月へコピーする */
  private async clickCopy() {
    if (!(await this.$openConfirm("翌月へコピーします。よろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/shift/staff/copy",
      {
        staff_id: this.staffId,
        target_year_month: this.yearmonth
      },
      async () => {
        this.fetch();
        await this.$openAlert("コピーしました。");
      }
    );
  }

  /** イベント編集ダイアログを開く */
  private open(dateObj: CalendarEvent) {
    if (dateObj.invalid_edit) {
      return;
    }
    this.staffShiftEditDialog.open(dateObj.date);
  }

  /** iBow勤怠のシフトを登録 */
  private async linkShift() {
    if (
      !(await this.$openConfirm(
        "iBow KINTAIで登録したシフト情報を登録します。\nよろしいですか？"
      ))
    ) {
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/staffshift/kintai/update",
      {
        yearmonth: this.yearmonth,
        office_id: this.officeId,
        staff_id: this.staffId
      },
      () => {
        this.fetch();
      }
    );
  }
}
