






import Component from "vue-class-component";
import { Vue, Prop } from "vue-property-decorator";
import { FileType } from "./const";

@Component
export default class AppChatSystemIcon extends Vue {
  @Prop({ default: 0 }) fileType!: number;
  @Prop({ default: "32" }) size?: string;

  private get iconPath(): string {
    switch (this.fileType) {
      case FileType.occurReport:
        return "./icon-occur.svg";
      case FileType.patientFile:
        return "./icon-file.svg";
      case FileType.aiReport:
        return "./icon-ai-report.svg";
    }

    return "";
  }
}
