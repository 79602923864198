



















































































































































import { Mixins, Component, Prop, Emit } from "vue-property-decorator";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import { VForm, Choice } from "@/types";
import { OtherInfo } from "@/components/patient/info/types";
import FormBaseInfo from "@/components/patient/info/FormBaseInfo.vue";
import FormBilling from "@/components/patient/info/FormBilling.vue";
import FormSituation from "@/components/patient/info/FormSituation.vue";
import FormCondition from "@/components/patient/info/FormCondition.vue";
import FormAcceptInfo from "@/components/patient/info/FormAcceptInfo.vue";
import FormGroupInfo from "@/components/patient/info/FormGroupInfo.vue";
import FormRegularVisit from "@/components/patient/info/FormRegularVisit.vue";
import { Link } from "#/types";
import { Patient } from "#/model/patient";
import { Office } from "#/model/agreement";
import EditMixin from "@/mixins/editMixin";
import PatientMixin from "@/mixins/patientMixin";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import * as appDate from "#/utility/appDate";

export interface PreNursingMonth {
  id: number;
  exec_yearmonth: number; //リハ予防訪問看護実施年月
  is_auto: number; //自動入力フラグ
  is_checked: number; //チェックフラグ
  wareki: string; //和暦
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

@Component({
  components: {
    PatientExpantionAreas,
    FormBaseInfo,
    FormBilling,
    FormSituation,
    FormCondition,
    FormAcceptInfo,
    FormGroupInfo,
    FormRegularVisit
  }
})
export default class PatientInfoItem extends Mixins(
  PatientMixin,
  UtilMixin,
  EditMixin,
  AxiosMixin,
  RulesMixin
) {
  /** Prop */

  /** 編集対象の利用者情報 */
  @Prop() patient!: Patient;

  /** 事業所情報 */
  @Prop() office!: Office;

  /** リハ予防訪問看護実施月 */
  @Prop({ default: () => [] }) preNursingMonths!: PreNursingMonth[];

  /** 主治医ID */
  @Prop() private indicateDoctorId!: number;

  /** 主治医強調表示フラグ */
  @Prop() private hightlightDoctor!: boolean;

  /** その他情報(傷病) */
  @Prop() otherInfo!: OtherInfo;

  /** グループマスタ */
  @Prop() groupItems!: Choice[];

  /** 読み取り専用モード */
  @Prop() isReadOnly!: boolean;

  /** ロード完了フラグ */
  @Prop() isLoaded!: boolean;

  /** 基礎情報履歴編集モード */
  @Prop({ default: false }) isHistoryEditMode!: boolean;

  /** 日付コンポーネント表示 */
  @Prop({ default: false }) showDatePicker!: boolean;

  /** 予約された履歴かどうか */
  @Prop() isReservedHistory!: boolean;

  /** 「履歴を残して基礎情報を保存」できるかフラグ */
  @Prop() canLeaveHistory!: boolean;

  /** 変更予約された利用者情報があるかフラグ */
  @Prop() hasReservedHistory!: boolean;

  /** サイドバー非表示フラグ */
  @Prop() isHideSideBar!: boolean;

  /** 変数 */

  /** 保存時に履歴を残すかどうか */
  private leaveHistory = false;

  /** アコーディオンメニュー */
  private localAccordionMenu: Link[] = [];

  /** アコーディオンメニュー:基本情報 */
  private prefixLink = [
    {
      text: "基本情報",
      key: "base_info"
    }
  ];

  /** アコーディオンメニュー:請求情報 */
  private billingLink = [
    {
      text: "請求情報",
      key: "billing"
    }
  ];

  /** アコーディオンメニュー:状況、状態、受付情報 */
  private midLink = [
    {
      text: "状況",
      key: "situation"
    },
    {
      text: "状態",
      key: "condition"
    },
    {
      text: "受付情報",
      key: "accept_info"
    }
  ];

  /** アコーディオンメニュー:グループ情報 */
  private groupLink = [
    {
      text: "グループ情報",
      key: "group_info"
    }
  ];

  /** アコーディオンメニュー:定期巡回 */
  private regularVisitLink = [
    {
      text: "定期巡回",
      key: "regular_visit"
    }
  ];

  /** get */

  /** form操作用 */
  private get Form(): VForm {
    return (this.$refs.form as unknown) as VForm;
  }

  /** アコーディオンメニュー情報 */
  private get AccordionMenuLinks(): Link[] {
    // 基本情報
    this.localAccordionMenu = [...this.prefixLink];

    // 請求情報は事務員以上のみ
    if (this.loginUser.auth_id >= 10) {
      this.localAccordionMenu.push(...this.billingLink);
    }

    // 状況、状態、受付情報
    this.localAccordionMenu.push(...this.midLink);

    // 履歴編集画面や基礎情報>変更予約された基礎情報はグループ情報と定期巡回なし
    if (this.isHistoryEditMode || this.isReservedHistory) {
      return this.localAccordionMenu;
    }

    // グループ情報
    this.localAccordionMenu.push(...this.groupLink);

    // 定期巡回は事業所設定を参照
    if (this.office.office_info.care_regular_visit_service_exist === 1) {
      this.localAccordionMenu.push(...this.regularVisitLink);
    }

    return this.localAccordionMenu;
  }

  /** set */
  private set AccordionMenuLinks(v: Link[]) {
    this.localAccordionMenu = v;
  }

  /** 関数 */

  /** バリデーション実行 */
  public validate(): boolean {
    return this.Form.validate();
  }

  /** 適用開始日として選択可能な日付かチェック */
  private checkSelectableDate(dateStr: string): boolean {
    const nowDateStr = new Date();
    const targetDateStr = appDate.strToDate(dateStr);

    return targetDateStr > nowDateStr;
  }

  /** 適用開始日の制約チェック */
  private checkHistoryStartDate(): boolean | string {
    // 開始日の最低は1970年
    if (this.patient.info.start_date.slice(0, 7) < "1970-01") {
      return "1970年以降を指定してください";
    }

    if (
      this.patient.info.start_date == "" &&
      this.patient.info.history_type != 0
    ) {
      return "必須です";
    }

    return true;
  }

  /** ADLから情報反映 */
  @Emit("fetch:adl")
  private clickFetchAdl() {
    return;
  }

  /** 利用者削除 */
  @Emit("delete:patient")
  private deletePatient() {
    return;
  }

  /** 保存イベント発行 */
  @Emit("save")
  private save() {
    return this.leaveHistory;
  }

  /** 未来の履歴確認ダイアログオープン */
  @Emit("open:futureHistory")
  private openFutureHistoryDialog() {
    return;
  }

  /** 適用開始日変更時 */
  @Emit("change:startDate")
  private changeHistoryStartDate() {
    return;
  }
}
