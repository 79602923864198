import { Component, Mixins, Prop } from "vue-property-decorator";
import { ReceiptVisitRecord } from "#/model/receipt";
import { CalendarEvent } from "@/types";
import UtilMixin from "@/mixins/utilMixin";
import { OfficeHistory } from "#/model/agreement";
import { ExceptForRegularTourDay } from "#/model/receipt";

@Component
export default class RegularPartBase extends Mixins(UtilMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  @Prop({ default: () => ({}) }) event!: CalendarEvent;

  @Prop({ default: () => ({}) }) receiptVisitRecord!: ReceiptVisitRecord;

  @Prop({ default: () => ({}) }) officeHistory!: OfficeHistory;

  @Prop({ default: false }) isLoaded!: boolean;

  @Prop({ default: "" }) yearmonth!: string;

  @Prop({ default: "" }) endDay!: string;

  @Prop({ default: () => [] })
  exceptForRegularTourDays!: ExceptForRegularTourDay[];

  /** 退院時共同指導日フラグ */
  protected nursing_leave_inst_flg = false;
}
