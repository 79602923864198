














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ReceiptDetailHonobonoInfo extends Vue {
  /** ほのぼの向け情報表示フラグ */
  @Prop({ default: false }) isHonobono!: boolean;

  /** ほのぼの実績連携状態 */
  @Prop({ default: "" }) honobonoJigyoKnj!: string;
}
