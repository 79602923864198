import Base from "../base";
import { OfficeSharedFileTag } from "./officeSharedFileTag";

//共有ファイル
export interface OfficeSharedFile extends Base {
  id: number; //ID
  agreement_id: number; //'契約ID',
  office_id: number; //事業所ID
  editor_staff_id: number; //更新者ID
  file_name: string; //ファイル名
  file_path: string; //ファイルパス
  remarks: string; //備考
  tags: OfficeSharedFileTag[]; //ファイルタグ
  thumbnail_file_path: string; //サムネイルファイルパス
}

export const DefaultOfficeSharedFile = (): OfficeSharedFile => ({
  id: 0,
  agreement_id: 0,
  office_id: 0,
  editor_staff_id: 0,
  file_name: "",
  file_path: "",
  remarks: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  tags: [],
  thumbnail_file_path: ""
});
