





























import { Component, Mixins, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import PatientMixin from "@/mixins/patientMixin";
import { Link } from "#/types";
import SelfBurden from "@/components/patient/selfburden/SelfBurden.vue";
import SelfBurdenTemplate from "@/components/patient/selfburden/SelfBurdenTemplate.vue";

@Component({
  components: {
    SelfBurden,
    SelfBurdenTemplate
  }
})
export default class PatientSelfburden extends Mixins(
  AxiosMixin,
  PatientMixin
) {
  /** 利用者ID */
  private patientId = 0;

  /** 対象年月 */
  private yearmonth = this.today(); //年月

  /** 自費 */
  private selfburdens = []; //自費

  /** 自費テンプレート */
  private selfburdenTemplates = []; //自費テンプレート

  /** 左サイドメニューアイテム */
  private links = [
    { text: "自費", key: "SelfBurden" },
    { text: "自費テンプレート", key: "SelfBurdenTemplate" }
  ];

  /** 現在有効なメニュー */
  private link = this.links[0];

  /** コンポーネントに渡すテーブルアイテム */
  private get Items() {
    let items: never[] = [];
    switch (this.link.key) {
      case "SelfBurden":
        items = this.selfburdens;
        break;
      case "SelfBurdenTemplate":
        items = this.selfburdenTemplates;
        break;
    }
    return items;
  }

  /** 対象年月変更時 */
  @Watch("yearmonth")
  private watchYearmonth() {
    this.getSelfburden();
  }

  created(): void {
    this.patientId = Number(this.$route.params.id);
    this.getSelfburden();
  }

  mounted() {
    // 有効なリンクの切り替え
    this.changeLinkQuery();
    this.setDefaultHeader();
  }

  //URLの変更で動作変更
  private changeLinkQuery() {
    if (typeof this.$route.query.key === "string") {
      const key = this.$route.query.key;
      for (const v of this.links) {
        if (v.key == key) {
          this.link = v;
        }
      }
    }
  }

  //自費一覧取得（年月変更用）
  private getSelfburden(): void {
    this.selfburdens = [];
    this.selfburdenTemplates = [];
    this.postJsonCheck(
      window.base_url + "/api/patient/selfburdens/get",
      {
        patient_id: this.patientId,
        yearmonth: this.yearmonth
      },
      res => {
        if (res.data.selfburdens) {
          this.selfburdens = res.data.selfburdens;
        }
        if (res.data.selfburden_templates) {
          this.selfburdenTemplates = res.data.selfburden_templates;
        }
      }
    );
  }

  /** 今日の年月を返す */
  private today(): string {
    const date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
    date.setDate(date.getDate());
    const today = date.toISOString().substr(0, 7);
    return today;
  }

  /** URLパラメータ変更 */
  private setUrlParams({ link }: { link: Link }) {
    this.$router.push({
      query: {
        key: link.key
      }
    });
  }
}
