




















































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import FileUploadTag from "@/components/patient/file/FileUploadTag.vue";
import InfoTextArea from "@/components/patient/patientinfo/InfoTextArea.vue";
import UtilMixin from "@/mixins/utilMixin";
import { Detail, DetailIndicate, Contact, PatientDispInfo } from "./types";

@Component({
  components: {
    FileUploadTag,
    InfoTextArea
  }
})
export default class PatientInfo extends Mixins(UtilMixin) {
  @Prop() detail!: Detail;

  @Prop() info!: PatientDispInfo;

  private get disease(): string {
    let str = "";
    str += this.detail.main_disease1;
    str += str ? "、" : "";
    str += this.detail.main_disease2;
    str += str ? "、" : "";
    str += this.detail.main_disease3;

    return str;
  }

  private indicateText(detail: DetailIndicate): string {
    let str =
      detail.indicate_period +
      "<br />" +
      detail.ins_name +
      " " +
      detail.indicate_doctor_name;

    str += detail.indicate_content
      ? "<br />指示内容：" + this.convertNewLineToBrTag(detail.indicate_content)
      : "";

    return str;
  }

  /** 主治医連絡先 */
  private get doctorContact(): string {
    // 医療機関
    let str = this.detail.primary_doctor_ins_name;
    str += this.detail.primary_doctor_tel
      ? " Tel：" + this.getTelTag(this.detail.primary_doctor_tel)
      : "";
    str += this.detail.primary_doctor_fax
      ? " Fax：" + this.detail.primary_doctor_fax
      : "";

    // 医師
    str += this.detail.primary_doctor_name
      ? "<br /><br /><p>" + this.detail.primary_doctor_name + "</p>"
      : "";

    if (
      this.detail.primary_doctor_contacts &&
      this.detail.primary_doctor_contacts.length > 0
    ) {
      this.detail.primary_doctor_contacts.forEach((contact: Contact) => {
        // 携帯か自宅電話なら電話番号用タグ付きに変換
        const contactInfo =
          contact.contact_type == 1 || contact.contact_type == 2
            ? this.getTelTag(contact.contact_info)
            : contact.contact_info;

        str += `${contact.contact_type_name} ${contactInfo}<br />`;
      });
      str += "<br />";
    }

    str += "留意事項：" + this.detail.primary_doctor_remarks;

    return str;
  }

  /** ケアマネ連絡先 */
  private get careManagerContact(): string {
    // 関連機関
    let str = this.detail.care_mana_ins_name;
    str += this.detail.care_mana_tel
      ? " Tel:" + this.getTelTag(this.detail.care_mana_tel)
      : "";
    str += this.detail.care_mana_fax
      ? " Fax：" + this.detail.care_mana_fax
      : "";

    // ケアマネ
    str += this.detail.care_mana_staff
      ? "<br /><br /><p>" + this.detail.care_mana_staff + "</p>"
      : "";

    if (
      this.detail.care_mana_contacts &&
      this.detail.care_mana_contacts.length > 0
    ) {
      this.detail.care_mana_contacts.forEach((contact: Contact) => {
        // 携帯か自宅電話なら電話番号用タグ付きに変換
        const contactInfo =
          contact.contact_type == 1 || contact.contact_type == 2
            ? this.getTelTag(contact.contact_info)
            : contact.contact_info;

        str += `${contact.contact_type_name} ${contactInfo}<br />`;
      });
      str += "<br />";
    }

    str += "留意事項：" + this.detail.care_mana_remarks;

    return str;
  }

  private get address(): string {
    if (this.detail.address == "未選択" || this.detail.address == "") {
      return this.detail.address;
    }

    return `<a href="https://www.google.com/maps/search/${this.detail.address_google_map_url}" target="_blank">${this.detail.address}</a>`;
  }

  private get hazardMap(): string {
    if (
      this.detail.address_hazard_map == "未選択" ||
      this.detail.address_hazard_map == ""
    ) {
      return "";
    }

    return (
      `<a href="http://disaportal.gsi.go.jp/maps/index.html?query=${this.detail.address_hazard_map}" target="_blank">${this.detail.address_hazard_map}</a>` +
      "<br /><div class='text-caption'>出典：ハザードマップポータルサイト</div>"
    );
  }

  private getTelTag(tel: string) {
    return '<a href="tel:' + tel + '">' + tel + "</a>";
  }

  /** 表示用に、改行コードを<br />タグに変換 */
  private convertNewLineToBrTag(text: string): string {
    return text.replace(/\r?\n/g, "<br />");
  }
}
