import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:医療保険情報 */
export const headerMedicalInsurance: StatisticHeader = {
  id: STATISTIC_COL_ID.MEDICAL_INSURANCE,
  text: "医療保険情報",
  is_checked: false,
  value: [
    {
      text: "保険種類（医療保険）",
      select_name: "保険種類",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.str_insurance_type`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "本人種別（医療保険）",
      select_name: "本人種別",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.str_insured_div`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.LIST
    },
    {
      text: "保険者番号（医療保険）",
      select_name: "保険者番号",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.insurer_no`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "保険者名（医療保険）",
      select_name: "保険者名",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.insurer_name`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "記号番号（医療保険）",
      select_name: "記号番号",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.insured_sign_number`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "枝番（医療保険）",
      select_name: "枝番",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.insured_branch`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.EDIT
    },
    {
      text: "給付割合（医療保険）",
      select_name: "給付割合",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.str_payment_rate`,
      sortable: true,
      align: "start",
      width: "192px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.NUMBER
    },
    {
      text: "資格取得年月日（医療保険）",
      select_name: "資格取得年月日",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.first_issue_date`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "開始日（医療保険）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.start_date`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（医療保険）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.MEDICAL_INSURANCE}.end_date`,
      sortable: true,
      align: "start",
      width: "186px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    }
  ]
};
