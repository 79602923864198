



































import {
  Component,
  Mixins,
  Prop,
  Emit,
  Ref,
  Watch
} from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { VForm, Choice } from "@/types";
import RulesMixin from "#/mixins/rulesMixin";

@Component({
  components: {}
})
export default class InfoCoordOutput extends Mixins(UtilMixin, RulesMixin) {
  @Ref("form") private readonly form!: VForm;

  /** 事業所選択肢情報 */
  @Prop({ default: () => [] }) officeSelects!: Choice[];

  /** 対象年月 */
  private targetYearmonth = this.getCurrentYearmonth();

  /** 選択事業所 */
  private selectedOffice: Choice = { text: "", value: 0 };

  /** CSV出力ボタンを押した時 */
  private async onSubmit() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.outputCsv();
  }

  /** 現在の年月取得 */
  private getCurrentYearmonth(): string {
    const now = new Date();
    return this.dateToStr(now, "yyyy-MM");
  }

  @Emit("output:csv")
  private outputCsv(): object {
    return {
      selectedOffice: this.selectedOffice,
      targetYearmonth: this.targetYearmonth
    };
  }

  @Watch("officeSelects", { immediate: true })
  private getHeadOffice() {
    if (this.loginUser.office_id > 0) {
      this.selectedOffice = this.officeSelects.filter(
        (officeSelect: Choice) => officeSelect.value == this.loginUser.office_id
      )[0];
      return;
    }

    if (this.officeSelects.length > 0) {
      this.selectedOffice = this.officeSelects[0];
      return;
    }

    this.selectedOffice = { text: "", value: 0, other_string: "" };
  }
}
