import { CalendarEventParsed } from "vuetify";
import { Choice } from "@/types";
import { OfficeStaffChoice } from "#/model/staff";

// イベントインターフェース
export interface Event {
  is_repeat: boolean; // 繰り返しイベントフラグ
  schedule_id: number; // 訪問予定ID（シフトの場合は0）
  event_type: EventType; // イベントタイプ（シフト 訪問予定）
  start: string; // 開始時間
  end: string; // 終了時間
  timed: boolean; // 終日フラグ false: 終日
  user_id: number; // スタッフID | 利用者ID
  visitor_ids: number[]; // 訪問者（要素の一番最初が主訪問）
  shift_name: string; // シフト名
  is_extend_time?: boolean; //時間増減可能なイベントかどうか
  is_move?: boolean; //移動可能なイベントかどうか
  is_not_click?: boolean; //移動可能なイベントかどうか
  name?: string; //表示名
  color?: string; //色
  category?: number | string;
}
// イベントインターフェース
export interface DispEvent {
  is_repeat: boolean; // 繰り返しイベントフラグ
  schedule_id: number; // 訪問予定ID（シフトの場合は0）
  title: string;
  title_tool: string;
  name: string;
  event_type: EventType; // イベントタイプ（シフト 訪問予定）
  start: string; // 開始時間
  end: string; // 終了時間
  timed: boolean; // 終日フラグ false: 終日
  user_id: number; // スタッフID | 利用者ID
  visitor_names: string; // 訪問者（要素の一番最初が主訪問）
  event_class: string; // 予,訪、訪(未),シ別のclass
  is_extend_time?: boolean; //時間増減可能なイベントかどうか
  visit_memo: string; //訪問メモ
  is_move?: boolean; //移動可能なイベントかどうか
  is_not_click?: boolean; //移動可能なイベントかどうか
  color?: string; //色
  category?: number | string;
  patient_id?: number; //利用者ID
  is_cancel?: number;
}

// イベントタイプ
export const EventType = {
  Temp: 1, // 一時データ
  Shift: 2, // シフトイベント
  Visit: 3, // 訪問イベント
  Calendar: 4, // カレンダー
  Holiday: 5 // 祝日
} as const;
export type EventType = typeof EventType[keyof typeof EventType];

// スケジュールアシスタント用訪問日リスト
export interface VisitDate {
  start_day: string;
  end_day: string;
}

// firestore 画面状態保存用
export interface ScheduleSearchCondition {
  office_id: number; // 事業所ID
  patient_ids: number[]; //利用者IDリスト
  group_ids: number[]; // グループリスト
  group_operator: number; // グループ検索演算子（1:AND 2:OR）
  staff_ids: number[]; // 職員IDリスト
  shifts: string[]; // シフトリスト
  layer_display: {
    calendar: number; //職員カレンダー
    visit: number; // 訪問予定
    visit_no_staff: number; // 訪問予定職員未割り当て
    shift: number; //シフト
  };
  type: string;
  data: ScheduleSearchConditionData[];
}
// firestore 絞り込み条件保存用
export interface ScheduleSearchConditionData {
  office_id: number; // 事業所ID
  patient_ids: number[]; //利用者IDリスト
  group_ids: number[]; // グループリスト
  group_operator: number; // グループ検索演算子（1:AND 2:OR）
  staff_ids: number[]; // 職員IDリスト
  shifts: string[]; // シフトリスト
}

/** 利用者名を取得する */
export function getPatientName(patietId: number, patients: Choice[]): string {
  const patient = patients.find(patient => patient.value == patietId) as Choice;
  if (patient?.text) {
    return patient.text;
  }
  return "";
}

/** 職員名を取得する */
export function getStaffName(
  staffId: number,
  staffs: OfficeStaffChoice[]
): string {
  const staff = staffs.find(
    staff => staff.value == staffId
  ) as OfficeStaffChoice;
  if (staff?.nick_name) {
    return staff.nick_name;
  }
  return "";
}

/** 訪問予定イベントの表示用文言を取得する */
export function getVisitPlanEvent(
  event: Event,
  eventParsed: CalendarEventParsed,
  patients: Choice[],
  staffs: OfficeStaffChoice[]
): string {
  let text = "";
  if (event.event_type == EventType.Visit) {
    text += `${getPatientName(event.user_id, patients)} さん`;
    if (event.visitor_ids.length > 0) {
      let first = true;
      text += "(";
      for (const id of event.visitor_ids) {
        if (!first) {
          text += ",";
        }
        text += getStaffName(id, staffs);
        first = false;
      }
      text += ")";
    }
  }
  return text;
}

/** 訪問予定イベントの表示用文言を取得する（ツールチップ用） */
export function getVistPlanEventTooltip(
  event: Event,
  eventParsed: CalendarEventParsed,
  patients: Choice[],
  staffs: OfficeStaffChoice[]
): string {
  let text = "";
  const staffNames: string[] = [];
  event.visitor_ids.forEach(id => {
    if (id === 0) return;
    staffNames.push(getStaffName(id, staffs));
  });
  text += `<br>${getPatientName(
    event.user_id,
    patients
  )} さん<br>訪問職員：${staffNames}`;
  return text;
}

/** 訪問メモを取得する */
export function getVisitMemo(str: string) {
  const regexp = /(https?:\/\/[^\s]*)/g;
  const memo = str.replaceAll(regexp, "<a href='$1' target='_blank'>$1</a>");
  return memo;
}
