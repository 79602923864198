



























































































































































































import { Component, Watch, Mixins, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import ReportListMixin from "@/mixins/reportListMixin";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import StaffSelect from "@/components/common_ibow/StaffSelect.vue";
import { ReportType, ValidCheckOption } from "@/components/report_list/types";
import { Choice, ChoiceAddOther, VForm } from "@/types";
import * as Table from "@/components/dashboard/tableTypes";
import { OFFICE_STAFF } from "#/const";

interface SearchCondition {
  startDate: string;
  endDate: string;
  startDateImplReportInfo: string;
  endDateImplReportInfo: string;
  officeId: number;
  staffId: number;
  include_satelite: boolean;
}

@Component({
  components: {
    OfficeSelect,
    StaffSelect
  }
})
export default class Dashboard extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin,
  ReportListMixin
) {
  @Ref("reportSearchForm") private readonly form!: VForm;

  /** 変数 */

  private StatisticsType = {
    VISITCOUNT: 101,
    VISITDETAIL: 102,
    IMPLREPORTINFO: 103
  };

  private selectedRadioItemValue = 0;
  private get selectedRadioItem(): ReportType {
    const selected = this.statisticsItems.find(
      reportType => reportType.value === this.selectedRadioItemValue
    );
    return selected ?? { text: "", value: 0 };
  }

  private searchPanelOpened = false;

  // 絞り込みしているかどうか
  private get IsFiltered(): boolean {
    return !(
      this.searchCond.officeId === this.DefaultOffice &&
      this.searchCond.staffId === 0 &&
      this.searchCond.startDate ===
        this.dateToStr(this.monthFirstDate, "yyyy-MM-dd") &&
      this.searchCond.endDate ===
        this.dateToStr(this.monthLastDate, "yyyy-MM-dd") &&
      this.searchCond.include_satelite === this.DefaultImpCheck
    );
  }

  /** 訪問内容詳細のダウンロードボタンと処理用 */
  private visitDetailDownloads: Choice[] = [
    { value: 0, text: "全体リスト" },
    { value: 1, text: "予防" },
    { value: 2, text: "介護" },
    { value: 3, text: "医療" },
    { value: 4, text: "精神" },
    { value: 5, text: "定期" },
    { value: 6, text: "自費" }
  ];

  // 職員一覧
  private staffs: Choice[] = [];

  private tables: Table.Info[] = [];
  private tableVisitCountStaff: Table.Info | null = null;
  private tablesVisitCountDetail: Table.VisitCountDetail[] = [];
  private visitDetailCategories: Table.VisitDetail | null = null;
  private implReportInfoCategories?: Table.ImplReportInfo | null = null;

  private currentDate = new Date();
  private monthFirstDate = new Date(new Date(this.currentDate).setDate(1));
  private monthLastDate = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth() + 1,
    0
  );

  /** 令和6年度までを表示する @todo 実施状況報告書の法改正対応後に年を変える */
  private targetYearImplReportInfo = 2024;

  // 前年の8月1日から7月31日まで、年は後から選べる
  private startDateImplReportInfo: Date = new Date(
    +this.targetYearImplReportInfo - 1,
    7,
    1
  );
  private endDateImplReportInfo = new Date(
    this.startDateImplReportInfo.getFullYear() + 1,
    7,
    0
  );

  /** 検索条件 */
  private DefaultSearchCond = (): SearchCondition => ({
    startDate: this.dateToStr(this.monthFirstDate, "yyyy-MM-dd"),
    endDate: this.dateToStr(this.monthLastDate, "yyyy-MM-dd"),
    startDateImplReportInfo: this.dateToStr(
      this.startDateImplReportInfo,
      "yyyy-MM-dd"
    ),
    endDateImplReportInfo: this.dateToStr(
      this.endDateImplReportInfo,
      "yyyy-MM-dd"
    ),
    officeId: 0,
    staffId: 0,
    include_satelite: false
  });
  private searchCond: SearchCondition = this.DefaultSearchCond();
  /** 検索後帳票出力用に期間を保持 */
  private searchCondSave: SearchCondition = this.DefaultSearchCond();
  /** デフォルト検索条件：事業所 */
  private get DefaultOffice(): number {
    // 帳票種類が未選択の場合は先頭の事業所
    if (!this.selectedRadioItemValue) {
      return this.HeadOffice;
    }

    // 実施状況報告書の場合
    if (
      this.selectedRadioItemValue == this.StatisticsType.IMPLREPORTINFO &&
      this.loginUser.auth_id < OFFICE_STAFF.AUTH.HOME
    ) {
      const parentOffice = this.masters.group_offices.find(
        (officeChoice: ChoiceAddOther) =>
          officeChoice.value == this.loginUser.parent_office_id
      );
      return parentOffice ? Number(parentOffice.value) : this.HeadOffice;
    }

    // 所属している場合はログインユーザーの所属事業所
    if (this.loginUser.office_id !== 0) {
      return this.loginUser.office_id;
    }
    // 所属していない場合は先頭の事業所
    return this.HeadOffice;
  }

  /** デフォルト検索条件:サテライト合算チェック */
  private get DefaultImpCheck(): boolean {
    // 事務員・所長の場合は、true
    // 本社・管理者の場合は、false
    if (this.loginUser.auth_id >= OFFICE_STAFF.AUTH.HOME) {
      return false;
    }

    return true;
  }

  /** 検索条件をデフォルトに戻す */
  private resetSearchCond() {
    const defaultCond = this.DefaultSearchCond();
    defaultCond.officeId = this.DefaultOffice;
    defaultCond.include_satelite = this.DefaultImpCheck;
    this.searchCond = defaultCond;
  }

  /** 表出力ページのボタン属性共通部分 */
  private reportButtonAttrs = {
    color: "grey",
    outlined: true,
    class: "mr-4 mb-4"
  };

  private searchPeriodImplReportInfo = "";
  /** 実施状況報告書情報の集計年を変える度に検索範囲条件を変更 */
  @Watch("targetYearImplReportInfo")
  private onChangeTargetYearImplReportInfo(newValue: string) {
    const startDateStr = +newValue - 1 + "-08-01";
    const endDateStr = newValue + "-07-31";
    this.searchCond.startDateImplReportInfo = startDateStr;
    this.searchCond.endDateImplReportInfo = endDateStr;

    /** Dateオブジェクト→元号を含む和暦年月日 */
    const dayToWareki = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
      era: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    });
    const startDateWareki = dayToWareki.format(
      new Date(startDateStr.replace(/-/g, "/"))
    );
    const endDateWareki = dayToWareki.format(
      new Date(endDateStr.replace(/-/g, "/"))
    );
    this.searchPeriodImplReportInfo = `集計期間：${startDateWareki} 〜 ${endDateWareki}`;
  }

  /** 検索対象事業所に所属する職員一覧に差し替え */
  @Watch("searchCond.officeId")
  private onChangeCondOfficeId(officeId: number) {
    this.fetchStaffs([officeId]).then(staffs => {
      this.staffs = staffs;
      // 職員一覧が更新されたので、選択職員をリセットする
      this.searchCond.staffId = 0;
    });
  }

  created(): void {
    this.searchCond.officeId = this.DefaultOffice;
    this.searchCond.include_satelite = this.DefaultImpCheck;

    this.onChangeTargetYearImplReportInfo(
      this.startDateImplReportInfo.getFullYear() + 1 + ""
    );
    this.fetchStaffs([this.searchCond.officeId]).then(staffs => {
      this.staffs = staffs;
    });
  }

  /** 帳票種類変更時 */
  private onChangeItem() {
    // 条件リセット（リセットボタンを押した時と同じ）しつつ絞り込みパネルを開く
    this.resetSearchCond();
    this.openSearchPanel();
  }

  /** 検索パネルを開く */
  private openSearchPanel() {
    this.searchPanelOpened = true;
  }

  /** 検索条件バリデーション：事業所選択 */
  private checkOfficeSelect(): boolean | string {
    // 選択していない場合
    if (this.searchCond.officeId === 0) {
      return "事業所を選択してください。";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の開始日 */
  private checkStartDate(
    startDateStr: string,
    endDateStr: string
  ): boolean | string {
    const startDate = new Date(startDateStr.replace(/-/g, "/"));
    const endDate = new Date(endDateStr.replace(/-/g, "/"));
    // なぜか「月」ではなく「日」を加算した年月日で判定するという仕様のため、最長を92日間としている
    // startDate の当日を含むので +91日
    const start3Month = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + 91
    );

    // 開始日が入力されていない場合
    if (!startDateStr) {
      return "開始日を入力すると検索できます";
    }
    // 有効な開始日として解釈できない場合
    if (Number.isNaN(startDate.getDate())) {
      return "開始日の入力形式に誤りがあります";
    }
    // 開始日が終了日よりも後である場合
    if (startDate > endDate) {
      return "開始日は終了日よりも前に設定してください";
    }
    // 検索期間が3ヶ月を超えている場合
    if (start3Month < endDate) {
      return "期間が3ヶ月を超えています";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了日 */
  private checkEndDate(
    startDateStr: string,
    endDateStr: string
  ): boolean | string {
    const startDate = new Date(startDateStr.replace(/-/g, "/"));
    const endDate = new Date(endDateStr.replace(/-/g, "/"));
    // なぜか「月」ではなく「日」を加算した年月日で判定するという仕様のため、最長を92日間としている
    // startDate の当日を含むので +91日
    const start3Month = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + 91
    );

    // 終了日が入力されていない場合
    if (!endDateStr) {
      return "終了日を入力すると検索できます";
    }
    // 有効な終了日として解釈できない場合
    if (Number.isNaN(endDate.getDate())) {
      return "終了日の入力形式に誤りがあります";
    }
    // 終了日が開始日よりも前である場合
    if (startDate > endDate) {
      return "終了日は開始日よりも後に設定してください";
    }
    // 検索期間が3ヶ月を超えている場合
    if (start3Month < endDate) {
      return "期間が3ヶ月を超えています";
    }
    return true;
  }

  /** 検索条件の検証後に取得処理を実行し、検索できない時は知らせる */
  private checkSearchValid(option?: ValidCheckOption) {
    this.$nextTick(async () => {
      if (!this.form.validate()) {
        if (option?.isSkipOffice && this.searchCond.officeId == 0) {
          this.form.resetValidation();
        } else if (option?.alert) {
          await this.$openAlert("入力内容に不備があります");
        }
        // 検索条件に不備があれば、パネルを開く
        this.openSearchPanel();
        return;
      }
      this.fetchStatisticsList();
    });
  }

  /** 表出力データの取得処理振り分け */
  private fetchStatisticsList(): void {
    switch (this.selectedRadioItem.value) {
      case this.StatisticsType.VISITCOUNT:
        this.fetchVisitCount();
        break;
      case this.StatisticsType.VISITDETAIL:
        this.fetchVisitDetail();
        break;
      case this.StatisticsType.IMPLREPORTINFO:
        this.fetchImplReportInfo();
        break;
    }
  }

  /** 訪問件数詳細表の取得と出力 */
  private fetchVisitCount(): void {
    this.tables = [];
    this.tableVisitCountStaff = null;
    this.tablesVisitCountDetail = [];
    this.postJsonCheck(
      window.base_heavy_url + "/api/dashboard/visitcount/get",
      {
        office_id: this.searchCond.officeId,
        staff_id: this.searchCond.staffId,
        start_date: this.searchCond.startDate,
        end_date: this.searchCond.endDate
      },
      res => {
        console.log(res.data);
        if (
          res.data.tables == null ||
          res.data.table_staff == null ||
          res.data.table_details == null
        )
          return;

        if (res.data.tables == null) return;
        this.tables = res.data.tables.map(this.formatTable);
        this.tableVisitCountStaff = this.formatTable(res.data.table_staff);
        this.tablesVisitCountDetail = res.data.table_details;
      }
    );
  }

  /** 訪問内容詳細の取得と出力 */
  private fetchVisitDetail(): void {
    this.tables = [];
    this.visitDetailCategories = null;
    this.searchCondSave.startDate = this.searchCond.startDate;
    this.searchCondSave.endDate = this.searchCond.endDate;
    this.postJsonCheck(
      window.base_heavy_url + "/api/dashboard/visitdetail/get",
      {
        office_id: this.searchCond.officeId,
        start_date: this.searchCondSave.startDate,
        end_date: this.searchCondSave.endDate
      },
      res => {
        console.log(res.data);
        if (res.data.tables == null || res.data.table_categories == null)
          return;

        // フロント出力用
        this.tables = res.data.tables.map(this.formatTable);
        // Excel出力用
        for (const tableName in res.data.table_categories) {
          this.formatTable(res.data.table_categories[tableName]);
        }
        this.visitDetailCategories = res.data.table_categories;
      }
    );
  }

  /** 実施状況報告書情報の取得と出力 */
  private fetchImplReportInfo(): void {
    this.tables = [];
    this.implReportInfoCategories = null;

    const officeIds = this.TargetOffices.map(
      (officeChoice: ChoiceAddOther) => officeChoice.value
    );

    this.postJsonCheck(
      window.base_heavy_url + "/api/dashboard/implreportinfo/get",
      {
        start_date: this.searchCond.startDateImplReportInfo,
        end_date: this.searchCond.endDateImplReportInfo,
        office_ids: officeIds
      },
      res => {
        console.log(res.data);
        if (res.data.tables == null || res.data.table_categories == null)
          return;

        // フロント出力用
        this.tables = res.data.tables.map(this.formatTable);
        // Excel出力用
        for (const tableName in res.data.table_categories) {
          this.formatTable(res.data.table_categories[tableName]);
        }
        this.implReportInfoCategories = res.data.table_categories;
      }
    );
  }

  /** 取得した表の値を調整 */
  private formatTable(table: Table.Info): Table.Info | null {
    table.data?.forEach((tr: Table.Cell[]) => {
      tr.forEach(td => {
        if (td.head) td.className = "table-dashboard-count-head";
        if (td.vert) {
          table.className = "table-dashboard-count-narrow";
          td.subClassName = "table-dashboard-count-vertical";
        }
        // colspan属性のため、ゼロ値は1にする
        if (td.col < 2) td.col = 1;
        // 数値としてのデータを優先して使う
        if (td.value_num != null) td.value = td.value_num + "";
        // 得られた値がゼロ値なら表示しない
        if (td.is_zero) td.value = "";
      });
    });
    return table;
  }

  /** 訪問件数詳細表Excel生成ダウンロード */
  private downloadVisitCountExcel(): void {
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/dashboard/visitcount/excel/download",
      { table: this.tableVisitCountStaff },
      res => {
        console.log(res.data);
        const fileName = "VisitCnt.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  /** 訪問件数詳細表(詳細一括)Excel生成ダウンロード */
  private downloadVisitCountDetailExcel(): void {
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/dashboard/visitcountdetail/excel/download",
      { tables: this.tablesVisitCountDetail },
      res => {
        console.log(res.data);
        const fileName = "visitCntDetail.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  /** 訪問内容詳細Excel生成ダウンロード */
  private async downloadVisitDetailExcel(
    downloadType: number | string
  ): Promise<void> {
    let fileName = "";
    switch (downloadType) {
      case 0:
        fileName = "ServiceListAll.xlsx";
        break;
      case 1:
        fileName = "PreventCare.xlsx";
        break;
      case 2:
        fileName = "Care.xlsx";
        break;
      case 3:
        fileName = "Medical.xlsx";
        break;
      case 4:
        fileName = "Seisin.xlsx";
        break;
      case 5:
        fileName = "Regular.xlsx";
        break;
      case 6:
        fileName = "OwnExpense.xlsx";
        break;
      default:
        await this.$openAlert("不明な種類です。");
        return;
    }
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/dashboard/visitdetail/excel/download",
      {
        table_categories: this.visitDetailCategories,
        category: downloadType,
        start_date: this.searchCondSave.startDate,
        end_date: this.searchCondSave.endDate
      },
      res => {
        console.log(res.data);
        this.downloadFile(res.data, fileName);
      }
    );
  }

  /** 実施状況報告書情報Excel生成ダウンロード */
  private downloadImplReportInfoExcel(): void {
    this.postJsonBlobResCheck(
      window.base_heavy_url + "/api/dashboard/implreportinfo/excel/download",
      { table_categories: this.implReportInfoCategories },
      res => {
        console.log(res.data);
        const fileName = "impl_report_info.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  /** ファイルのダウンロード */
  private downloadFile(blobable: Blob, fileName: string): void {
    if (!blobable.size) return;
    const blob = new Blob([blobable]);
    const a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  }

  /** ファイルモード */
  private get statisticsItems() {
    return [
      {
        text: "訪問件数詳細表",
        value: this.StatisticsType.VISITCOUNT,
        fetchFunc: this.fetchVisitCount
      },
      {
        text: "訪問内容詳細",
        value: this.StatisticsType.VISITDETAIL,
        fetchFunc: this.fetchVisitDetail
      },
      /** 令和6年度までを表示する @todo 実施状況報告書の法改正対応後に年を変える */
      {
        text: "実施状況報告書情報（令和６年度）",
        value: this.StatisticsType.IMPLREPORTINFO,
        fetchFunc: this.fetchImplReportInfo
      }
    ];
  }

  /** (実施状況報告書情報)事業所変更時、チェック状態リセット */
  private resetIncludeSateliteCheck() {
    if (this.selectedRadioItemValue !== this.StatisticsType.IMPLREPORTINFO) {
      return;
    }

    // チェックボックスが無効化されるような事業所に変更した場合は、OFF
    // そうじゃない場合は、デフォルト状態にする
    this.searchCond.include_satelite = this.CheckBoxDisabled
      ? false
      : this.DefaultImpCheck;
  }

  /** (実施状況報告書情報)合算用チェックボックス無効化 */
  private get CheckBoxDisabled(): boolean {
    // 未選択、サテライトを選択している場合は無効化
    if (this.searchCond.officeId === 0) {
      return true;
    }

    const selectedOffice = this.masters.group_offices.find(
      (officeChoice: ChoiceAddOther) =>
        officeChoice.value === this.searchCond.officeId
    );

    if (!selectedOffice) {
      return true;
    }

    return selectedOffice.value !== selectedOffice.other;
  }

  /** (実施状況報告書情報)集計対象の事業所 */
  private get TargetOffices(): ChoiceAddOther[] {
    const selectedOffice = this.masters.group_offices.find(
      (officeChoice: ChoiceAddOther) =>
        officeChoice.value === this.searchCond.officeId
    );

    if (!selectedOffice) {
      return [];
    }

    if (!this.searchCond.include_satelite) {
      return [selectedOffice];
    }

    // 合算にチェックが入っているなら、選択した事業所のサテライトも含める
    // 先頭が選んだ事業所、続けてID順に並んだサテライト
    return [
      selectedOffice,
      ...this.masters.group_offices
        .filter((officeChoice: ChoiceAddOther) => {
          return (
            officeChoice.other === selectedOffice.value &&
            officeChoice.value !== selectedOffice.value
          );
        })
        .sort((a, b) => {
          return a.value < b.value ? -1 : 1;
        })
    ];
  }
}
