




































import { Component, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { COMMON } from "#/const";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import PdfLinkMixin from "@/mixins/pdfLinkMixin";
import AppSelectTable from "#/components/AppSelectTable.vue";
import { ItemPdfShow } from "@/components/patient/pdf/list";
import PdfLinkShowConfirm from "@/components/app/PdfLinkShowConfirm.vue";

@Component({
  components: {
    AppSelectTable,
    PdfLinkShowConfirm
  }
})
export default class PdfUrlShow extends Mixins(
  UtilMixin,
  AxiosMixin,
  PdfLinkMixin
) {
  /** 帳票の提出状況 */
  private REPORT_STATUS = COMMON.REPORT_STATUS;

  /** PDFリンクメールの送信先を特定するための暗号化文字列 */
  private pdfLinkMailKey = "";

  /** PDF一覧表のヘッダー */
  private headers: DataTableHeader[] = [
    {
      text: "帳票種類",
      value: "report_type_name",
      width: "8rem"
    },
    {
      text: "詳細",
      value: "description",
      class: "w-auto",
      cellClass: "text-pre-wrap",
      width: "20rem"
    }
  ];
  /** 帳票PDF一覧 */
  private reports: ItemPdfShow[] = [];
  /** 選択済み帳票PDF一覧 */
  private selectedReports: ItemPdfShow[] = [];
  private get SelectedReports() {
    return this.selectedReports;
  }
  private set SelectedReports(selects: ItemPdfShow[]) {
    this.selectedReports = selects;
  }

  /** メールアドレス確認ダイアログ */
  private dialog = false;
  /** 確認ダイアログのアラートメッセージ */
  private alertMessage = "";

  mounted() {
    this.$nextTick(() => {
      if (this.$route.params.linkKey) {
        this.pdfLinkMailKey = String(this.$route.params.linkKey);
        this.fetchPdfLinkByKey("");
      }
    });
  }

  /** 行を選択できる条件 */
  private isRowSelectable(item: ItemPdfShow) {
    return !item.is_deleted && item.report_status === this.REPORT_STATUS.SUBMIT;
  }

  /** リンクURL取得 */
  private fetchPdfLinkByKey(mailAddress: string) {
    this.dialog = false;
    this.postJsonCheck(
      window.base_url + "/api/pdflink/report/get",
      {
        mail_key: this.pdfLinkMailKey,
        mail_address: mailAddress
      },
      async res => {
        if (!res.data) {
          await this.$openAlert("PDFリンク情報を取得できませんでした。");
          return;
        }
        this.alertMessage = res.data.message;
        if (res.data.need_confirm) {
          // 本人確認が必要な場合
          this.dialog = true;
          return;
        }
        this.reports = res.data.reports;
        this.saveAccess();
      }
    );
  }

  /** リンクURL取得 */
  private previewPdf() {
    const pdfPaths = this.SelectedReports.map(report => report.pdf_path);
    this.makePdf(window.base_url + "/api/pdflink/pdf/join", {
      str_pdfs: pdfPaths
    });
  }

  /** アクセス情報保存 */
  private saveAccess() {
    this.postJsonBackground(
      window.base_url + "/api/pdflink/access/save",
      { mail_key: this.pdfLinkMailKey },
      () => null
    );
  }
}
