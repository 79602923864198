


























import { Component, Vue, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { UnsubmitReport } from "@/components/home/types";
import { ReportTypeToRoute } from "@/components/unsubmit/unsubmit";
import LoadingArea from "@/components/home/loadingArea.vue";

@Component({
  components: {
    LoadingArea
  }
})
export default class Unsubmit extends Vue {
  @Prop({ default: [] }) reports!: UnsubmitReport[]; // 未提出書類

  @Prop() loading!: boolean; // ローディング

  @Prop() countNotSubmit!: number; // 未提出書類件数

  private reportHeaders: DataTableHeader[] = [
    {
      text: "書類種別",
      value: "report_type_name",
      align: "start"
    },
    {
      text: "利用者",
      value: "patient_name",
      align: "start",
      width: "128px",
      cellClass: "ibow2-home-unsubmit-table-underline"
    },
    {
      text: "更新者",
      value: "update_staff_name",
      align: "start",
      width: "128px"
    },
    {
      text: "更新日",
      value: "update_datetime",
      align: "start",
      width: "160px"
    },
    {
      text: "",
      value: "actions",
      align: "start",
      width: "48px",
      sortable: false
    }
  ];

  // 書類がまだ取得できていない場合を考慮
  private get Reports(): UnsubmitReport[] {
    return this?.reports || [];
  }

  private clickRow(report: UnsubmitReport) {
    const routeName = ReportTypeToRoute[report.report_type];
    this.$router.push({
      path: `/patient/${report.patient_id}/${routeName}/${report.report_id}`
    });
  }
}
