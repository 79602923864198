









































import { Component, Vue, Prop } from "vue-property-decorator";
import { ManualItem } from "@/components/manual/types";

@Component
export default class ManualList extends Vue {
  @Prop() manuals!: ManualItem[]; // マニュアルリスト

  /** マニュアルのリンクを開く */
  private open(manual: ManualItem) {
    if (manual.is_outer) {
      // 外部リンクは別タブ
      window.open(manual.url, "_blank");
    } else {
      this.$router.push(manual.url);
    }
  }
}
