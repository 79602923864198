



























































import { Component, Mixins, Ref, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

export interface CareProblemSolution {
  id: number;
  keyword: string;
  problem: string;
  solution: string;
  care_problem_solution_diseases: Disease[];
}

interface Disease {
  id: number;
  care_problem_solution_id: number;
  disease_name: string;
}

@Component
export default class NursingPlanEdit extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("form") private readonly form!: VForm;

  // 選択済みの適応事業所
  public selectedOffice: number[] = [];

  private dialog = false; //表示フラグ

  public careProblemSolution: CareProblemSolution = {
    id: 0,
    keyword: "",
    problem: "",
    solution: "",
    care_problem_solution_diseases: [
      {
        id: 0,
        care_problem_solution_id: 0,
        disease_name: ""
      },
      {
        id: 0,
        care_problem_solution_id: 0,
        disease_name: ""
      },
      {
        id: 0,
        care_problem_solution_id: 0,
        disease_name: ""
      },
      {
        id: 0,
        care_problem_solution_id: 0,
        disease_name: ""
      },
      {
        id: 0,
        care_problem_solution_id: 0,
        disease_name: ""
      }
    ]
  };

  private get IsDelete(): boolean {
    return this.careProblemSolution.id !== 0;
  }

  //ダイアログ開く（新規作成）
  public openNew() {
    this.dialog = true;
    this.careProblemSolution = {
      id: 0,
      keyword: "",
      problem: "",
      solution: "",
      care_problem_solution_diseases: [
        {
          id: 0,
          care_problem_solution_id: 0,
          disease_name: ""
        },
        {
          id: 0,
          care_problem_solution_id: 0,
          disease_name: ""
        },
        {
          id: 0,
          care_problem_solution_id: 0,
          disease_name: ""
        },
        {
          id: 0,
          care_problem_solution_id: 0,
          disease_name: ""
        },
        {
          id: 0,
          care_problem_solution_id: 0,
          disease_name: ""
        }
      ]
    };
    this.$nextTick(() => {
      this.form.resetValidation();
    });
  }

  //ダイアログ開く（編集）
  public openEdit(item: CareProblemSolution) {
    this.dialog = true;
    this.getCareProblemSolution(item.id);
    this.$nextTick(() => {
      this.form.resetValidation();
    });
  }

  //データ取得
  private getCareProblemSolution(id: number): void {
    this.postJsonCheck(
      window.base_url + "/api/master/careproblem_solution/get",
      {
        careproblem_solution_id: id
      },
      res => {
        console.log(res.data.careproblem_solution);
        this.careProblemSolution = res.data.careproblem_solution;
      }
    );
  }

  //保存
  private async save() {
    if (!this.form.validate()) {
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/careproblem_solution/save",
      {
        careproblem_solution: this.careProblemSolution
      },
      () => {
        this.dialog = false;
        this.finishSave();
      }
    );
  }

  //削除
  private async remove() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/master/careproblem_solution/delete",
      {
        careproblem_solution: this.careProblemSolution
      },
      () => {
        this.dialog = false;
        this.finishSave();
      }
    );
  }

  @Emit()
  private finishSave() {
    return;
  }

  /**************************************************
   * ルール
   **************************************************/
  private requiredKeyword(): boolean | string {
    if (this.careProblemSolution.keyword.length > 0) return true;

    return this.required(this.careProblemSolution.keyword, "必須です");
  }

  private requiredProblem(): boolean | string {
    if (this.careProblemSolution.problem.length > 0) return true;

    return this.required(this.careProblemSolution.problem, "必須です");
  }

  private requiredSolution(): boolean | string {
    if (this.careProblemSolution.solution.length > 0) return true;
    return this.required(this.careProblemSolution.solution, "必須です");
  }
}
