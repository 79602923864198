









































import { Component, Vue, Prop, Ref, Mixins } from "vue-property-decorator";
import { ScheduleSearchCondition } from "@/components/schedule/common";
import { Choice } from "@/types";
import SearchSelect from "#/components/SearchSelect.vue";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    SearchSelect
  }
})
export default class ScheduleManageSearchPanel extends Mixins(UtilMixin) {
  /** 利用者絞り込み条件 */
  @Ref("patientSelect") private readonly patientSelect!: SearchSelect;

  /** グループ絞り込み条件 */
  @Ref("groupSelect") private readonly groupSelect!: SearchSelect;

  /** 職員絞り込み条件 */
  @Ref("staffSelect") private readonly staffSelect!: SearchSelect;

  /** シフト絞り込み条件 */
  @Ref("shiftSelect") private readonly shiftSelect!: SearchSelect;

  /** 検索パネル開閉状態 */
  @Prop({ default: false }) value!: boolean;
  /** 利用者リスト */
  @Prop({ default: () => [] }) patients!: Choice[];
  /** グループリスト */
  @Prop({ default: () => [] }) groups!: Choice[];
  /** 職員リスト */
  @Prop({ default: () => [] }) staffs!: Choice[];
  /** シフトリスト */
  @Prop({ default: () => [] }) shifts!: Choice[];
  /** 検索条件 */
  @Prop({ required: true }) searchCondition!: ScheduleSearchCondition;

  /** 検索パネル開閉状態 */
  private get Value(): boolean {
    return this.value;
  }

  /** 検索パネル開閉状態 */
  private set Value(newVal: boolean) {
    this.$emit("input", newVal);
  }

  /** グループ検索条件のAND/OR */
  private get SearchGroupOperator(): number {
    if (this.searchCondition.group_operator) {
      return 0;
    } else {
      return 1;
    }
  }
  /** グループ検索条件のAND/OR */
  private set SearchGroupOperator(newCond: number) {
    if (newCond) {
      this.searchCondition.group_operator = 0;
    } else {
      this.searchCondition.group_operator = 1;
    }
  }

  /** 検索条件リセット */
  private reset() {
    this.searchCondition.patient_ids = [];
    this.searchCondition.group_ids = [];
    this.SearchGroupOperator = 0;
    this.searchCondition.staff_ids = [];
    this.searchCondition.shifts = [];
  }

  /** SearchInputクリア */
  public resetSearchInput() {
    this.patientSelect.resetSearchInput();
    this.groupSelect.resetSearchInput();
    this.staffSelect.resetSearchInput();
    this.shiftSelect.resetSearchInput();
  }

  /** キャッシュクリア */
  public resetCachedItems() {
    this.patientSelect.resetCachedItems();
    this.groupSelect.resetCachedItems();
    this.staffSelect.resetCachedItems();
    this.shiftSelect.resetCachedItems();
  }
}
