import { MutationTree } from "vuex";
import * as types from "./types";

export const mutations: MutationTree<types.BbsState> = {
  [types.SET_IBOW_HEADLINES](state, headlines) {
    state.ibowHeadlines = headlines;
  },
  [types.SET_EWELL_HEADLINES](state, headlines) {
    state.ewellHeadlines = headlines;
  },
  [types.SET_SUPPORT_HEADLINES](state, headlines) {
    state.supportHeadlines = headlines;
  }
};
