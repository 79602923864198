


















import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import { namespace } from "vuex-class";
import * as patient from "@/store/modules/patient/types";
import VisitHeader from "@/components/visit/VisitHeader.vue";
import { COLLECTION_HOME_ITEM_LIST } from "@/const/envFireStore";
import { ButtonItems } from "@/components/home/types";
import VisitTmpDataMixin from "@/mixins/firestore/visitTmpDataMixin";
import { VisitModel } from "@/components/main/types";
import VisitingMemo from "@/components/visit/VisitingMemo.vue";
import { VisitInfo } from "./types";

const Patient = namespace(patient.name);

@Component({
  components: {
    VisitHeader,
    VisitingMemo
  }
})
export default class VisitBase extends Mixins(
  FireStoreMixin,
  UtilMixin,
  PatientMixin,
  AxiosMixin,
  VisitTmpDataMixin
) {
  @Patient.Mutation(patient.SET_PATIENT_MASTER) setPatientMaster!: Function;

  @Patient.Mutation(patient.SET_PATIENT_BASE) setPatientBase!: Function;

  @Patient.Mutation(patient.SET_PATIENT) setPatient!: Function;

  /** 利用者ID */
  private patientId = "0";

  /** マスタデータ読み取り完了フラグ */
  private isLoadedMaster = true;

  /** 利用者ヘッダー */
  private pheader: Vue | Element | (Vue | Element)[] | undefined = [];

  /** 文字サイズ */
  private selectFontSize = 0;

  /** 訪問メモ */
  private visitInfo: VisitInfo = {
    memo: "",
    display: 1
  };

  /** 共通エリアの表示フラグ */
  private get IsNoCommonArea() {
    return this.$route.meta?.isNoCommonArea;
  }

  /** 訪問データモデル */
  private get VisitModel(): VisitModel {
    return this.visitTmpDataState.data as VisitModel;
  }

  /** 訪問中かどうか */
  private get IsVisit() {
    if (this.VisitModel && this.VisitModel.mode) {
      return true;
    }
    return false;
  }

  async created() {
    this.patientId = this.$route.params.id;

    //利用者情報取得
    this.postJsonCheck(
      window.base_url + "/api/patient/base/get",
      { patient_id: Number(this.patientId) },
      res => {
        if (res.data) {
          this.setPatientBase({ master: res.data });
          this.isLoadedMaster = true;
        }
      }
    );

    //服薬管理表の編集画面のみ
    if (
      this.$route.name == "VisitMedicinetaking" ||
      this.$route.name == "VisitMedicinetakingEdit"
    ) {
      //利用者共通マスタ情報
      this.postJsonBackground(
        window.base_url + "/api/patient/master/get",
        { patient_id: Number(this.patientId) },
        res => {
          if (res.data) {
            this.setPatientMaster({ master: res.data });
          }
        }
      );
    }

    // 利用者情報取得
    this.postJsonCheck(
      window.base_url + "/api/patient/header/get",
      { patient_id: Number(this.patientId), is_visit: true },
      res => {
        if (!res.data) return;
        this.setPatient(res.data);
        // 訪問メモ
        this.visitInfo.memo = res.data.visit_info.memo;
        this.visitInfo.display = res.data.visit_info.display;
      }
    );

    if (this.loginUser.id) {
      this.collection = COLLECTION_HOME_ITEM_LIST;
      this.documentId = String(this.loginUser.id);
      const documentButtonItem = (await this.documentGet()) as ButtonItems;
      if (documentButtonItem) {
        this.selectFontSize = documentButtonItem.selectFontSize;
      } else if (documentButtonItem === false) {
        // firestore取得失敗
        this.$openAlert(
          "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
        );
        return;
      }
    }
  }

  mounted() {
    this.pheader = this.$refs.pheader;
  }
}
