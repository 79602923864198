





























import { Component, Watch } from "vue-property-decorator";
import MedPartBase from "./MedPartBase";

@Component
export default class SenmonCareDiv extends MedPartBase {
  //職員区分変更時、専門区分の中身未選択にする
  @Watch("receiptVisitRecord.staff_div") protected handleSenmonCareDiv() {
    if (!this.isLoaded) {
      return;
    }
    this.receiptVisitRecord.senmon_care_div = 0;
  }

  /** 専門看護師の専門区分に関するバリデーションチェック */
  protected checkExpertSenmonCareDiv(): boolean | string {
    // 職員区分が専門の場合は、専門区分未選択NG
    if (
      this.receiptVisitRecord.staff_div == 3 &&
      this.receiptVisitRecord.senmon_care_div == 0
    ) {
      return "専門区分を選択してください";
    }

    return true;
  }

  /** 看護師等の専門区分に関するバリデーションチェック */
  protected checkNurseSenmonCareDiv(): boolean | string {
    // 職員区分が看護師等で専門管理加算が有効になっている場合は、専門区分未選択NG
    if (
      this.receiptVisitRecord.staff_div == 1 &&
      this.receiptVisitRecord.senmon_care_div == 0 &&
      this.receiptVisitRecord.special_management_add == 1
    ) {
      return "専門区分を選択してください";
    }

    return true;
  }
}
