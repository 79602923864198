


































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { DefaultOwnExpense, OwnExpense } from "#/model/patient";
import PrivateExpenseEdit from "@/components/patient/record2/PrivateExpenseEdit.vue";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Master } from "@/components/patient/record2/types";
import { Patient } from "#/model/patient";

@Component({
  components: {
    PrivateExpenseEdit
  }
})
export default class PrivateExpenses extends Mixins(UtilMixin, AxiosMixin) {
  @Prop({ default: "prefix" }) private prefix_id!: string;
  @Prop() private items!: OwnExpense[]; // テーブルデータ
  @Prop() private readonly master!: Master; // マスター
  @Prop() private readonly patient!: Patient;

  private refEditDialog!: PrivateExpenseEdit;

  // 新規登録フラグ
  private isNew = false;

  // 編集集中の自費
  private index = -1;
  private get model(): OwnExpense {
    return this.items[this.index];
  }
  private set model(val: OwnExpense) {
    this.items.splice(this.index, 1, val);
  }

  // テーブルヘッダ
  private headers = [
    { text: "編集", value: "action", width: "190px" },
    { text: "カテゴリ名", value: "category_name", width: "112px" },
    { text: "アイテム名", value: "item_name", width: "110px" },
    { text: "単価", value: "unit_price", width: "76px" },
    { text: "数量", value: "quantity", width: "0px" },
    { text: "金額", value: "amount", width: "76px" },
    { text: "課税区分", value: "tax_div", width: "90px" },
    { text: "医療費控除", value: "medical_deducation", width: "76px" },
    { text: "請求月", value: "yearmonth", width: "88px" },
    { text: "備考", value: "remarks" }
  ];

  public mounted(): void {
    this.refEditDialog = this.$refs.refEditDialog as PrivateExpenseEdit;
  }

  // 課税区分
  private getTaxDivText(item: OwnExpense) {
    let text = "";
    text += this.masters.tax_divs.find(choice => choice.value == item.tax_div)
      ?.text;
    if (item.tax_div != 0) {
      text += `(${item.tax_rate}%)`;
    }
    return text;
  }

  // 医療費控除
  private getMedicalDeducation(item: OwnExpense) {
    return this.masters.yes_or_no.find(
      choice => choice.value == item.medical_deducation
    )?.text;
  }

  // 新規登録
  private clickInsert(): void {
    this.isNew = true;
    this.items.push(DefaultOwnExpense());
    this.index = this.items.length - 1;
    this.items[this.index].patient_id = this.patient.id;
    this.refEditDialog.open();
  }

  // 編集
  private clickEdit(index: number) {
    this.isNew = false;
    this.index = index;
    this.items[index].is_edited = 1;
    this.refEditDialog.open();
  }

  // コピー
  private clickCopy(index: number) {
    this.isNew = true;
    const copyItem = this.deepCopy(this.items[index]);
    copyItem.is_edited = 0;
    this.items.push(copyItem);
    this.index = this.items.length - 1;
    this.refEditDialog.open();
  }

  // 削除
  private clickDelete(): void {
    this.items.splice(this.index, 1);
  }

  // キャンセル
  private clickCancel(): void {
    if (this.isNew) {
      this.items.pop();
      this.isNew = false;
    }
  }

  /**
   * オペログ書き込み用
   * 自費保存
   */
  private onClickSave() {
    this.postJsonBackground(
      window.base_url + "/api/patient/visitrecord/ownexpense/save",
      {
        own_expense: this.model
      },
      () => {
        // Do Nothing
      }
    );
  }
}
