










































































































































import { Component, Mixins, Ref, Watch, Prop } from "vue-property-decorator";
import { Choice } from "@/types";
import { Link } from "#/types";
import AppSelect from "#/components/AppSelect.vue";
import AppTextField from "#/components/AppTextField.vue";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import RulesSaveMixin from "#/mixins/rulesSaveMixin";
import UtilMixin from "@/mixins/utilMixin";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import RemandExpand from "@/components/patient/common/remand/RemandExpand.vue";
import {
  DefaultOccurReportRemand,
  OccurReport,
  DefaultOccurReport
} from "#/model/occurreport";
import PatientMedicalInsOnlySelect from "@/components/patient/common_ibow/PatientMedicalInsOnlySelect.vue";
import EditMixin from "@/mixins/editMixin";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import { OFFICE_STAFF } from "#/const";
import PatientMixin from "@/mixins/patientMixin";

@Component({
  components: {
    AppSelect,
    AppTextField,
    TextDayChangePicker,
    PatientExpantionAreas,
    RemandExpand,
    PatientSaveArea,
    PatientMedicalInsOnlySelect
  }
})
export default class OccurCreate extends Mixins(
  AxiosMixin,
  RulesMixin,
  RulesSaveMixin,
  UtilMixin,
  EditMixin,
  PatientMixin
) {
  /** 一般職員権限 */
  private STAFF_COMMON = OFFICE_STAFF.AUTH.COMMON;

  @Ref("patient-header") private readonly patientHeader!: PatientExpantionAreas;

  @Prop() readonly pheader!: PatientHeader;

  private occurId = 0;
  private patientId = 0;
  private staffId = 0;
  private authId = 0; //権限ID\n99:システム管理者\n20:本社職員\n10:事務所所長\n11:事務所事務員\n1:一般職員

  private occur_report: OccurReport = DefaultOccurReport(); //事故/ヒヤリハット報告書
  private reporterNames: Choice[] = []; //報告者名
  private careStations: Choice[] = []; //搬送先医療機関

  /** 提出時に保存履歴を残す */
  private displayPreview = false;

  private get Links() {
    const links: Link[] = [];
    if (
      this.occur_report.remand.id &&
      this.occur_report.remand.editor_staff_id
    ) {
      links.push({
        text: "差戻し情報",
        key: "remand",
        add_info: this.occur_report.remand.comment
      });
    }
    links.push(
      {
        text: "報告日",
        key: "report_date"
      },
      {
        text: "報告者名",
        key: "report_staff_name"
      },
      {
        text: "発生状況",
        key: "occur_situation"
      },
      {
        text: "対応状況",
        key: "correspond_situation"
      },
      {
        text: "事故状況",
        key: "accident_situation"
      }
    );
    return links;
  }

  private get StorageKey(): string {
    return `occurreport-${this.loginUser.id}-${this.patientId}-${this.occurId}`;
  }

  //編集モード設定
  @Watch("occur_report", { deep: true })
  private watchData() {
    this.saveLocalStorage({
      occur_report: this.occur_report,
      displayPreview: this.displayPreview
    });
  }

  /** プレビューボタン */
  @Watch("displayPreview") private watchDisplayPreview() {
    this.watchData();
  }

  created() {
    this.occurId = Number(this.$route.params.occurid);
    this.patientId = Number(this.$route.params.id);
    this.fetchOccurReport();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list"
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/report-group/occur`
      },
      {
        text: "事故/ヒヤリハット報告書",
        disabled: true,
        to: ""
      }
    ]);
  }

  //キャンセル
  private cancel() {
    this.$router.go(-1);
  }

  //保存
  private save(isMove: boolean) {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);
      this.saveAction(1, "/api/patient/occurreport/save", false, isMove);
    });
  }

  //提出
  private async submission(isPreview = false) {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.saveAction(2, "/api/patient/occurreport/submission", isPreview);
  }

  private saveAction(
    status: number,
    url: string,
    isPreview = false,
    isMove = true
  ) {
    this.is_finish = true;
    this.occur_report.patient_id = this.patientId;
    this.occur_report.status = status;
    this.makePdf(
      window.base_url + url,
      {
        occur_report: this.occur_report,
        is_preview: isPreview
      },
      async res => {
        // メール送信失敗時エラー
        if (res.data.has_send_mail_error) {
          await this.$openAlert(
            "事故/ヒヤリハット報告書提出通知メールが送信できませんでした。\n\n--------エラー内容詳細--------\n\n登録された「通知メールアドレス」が間違っているか、何らかの理由により一時的に送信できなかった可能性があります。\n別の方法でご連絡いただくか、事業所マスタ＞通知＞「通知メールアドレス」が正しいかご確認ください。"
          );
        }
        this.setNoEditMode();
        if (isMove) {
          this.cancel();
        } else {
          this.occurId = res.data.id;
          this.occur_report.id = res.data.id;
          this.occur_report.updated_at = res.data.updated_at;
          this.$router.replace({
            params: { occurid: String(this.occurId) }
          });
          this.resetStorage(this.StorageKey);
          this.is_finish = false;
          this.$openAlert("編集中の内容を保存しました。");
        }
      },
      () => {
        this.is_finish = false;
      },
      isPreview
    );
  }

  //削除
  private clickDelete() {
    this.occur_report.id = this.occurId;
    this.occur_report.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/occurreport/delete",
      {
        occur_report: this.occur_report
      },
      () => {
        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  //プレビュー
  private preview(): void {
    this.setIsSave(true);
    this.$nextTick(async () => {
      if (!this.patientHeader.validate()) {
        this.setIsSave(false);
        await this.$openAlert(`入力内容に不備があります`);
        return;
      }
      this.patientHeader.resetValidation();
      this.setIsSave(false);

      this.occur_report.patient_id = this.patientId;
      // this.conference_report.status = status;
      this.makePdf(window.base_url + "/api/patient/occurreport/preview", {
        occur_report: this.occur_report
      });
    });
  }

  //事故ヒヤリハット情報取得
  private fetchOccurReport(): void {
    this.postJsonCheck(
      window.base_url + "/api/patient/occurreport/get",
      {
        patient_id: this.patientId,
        occur_id: this.occurId
      },
      res => {
        this.staffId = res.data.staff_id;
        this.authId = res.data.auth_id;
        //authIdが1の場合（一般職員）報告者名固定
        if (this.authId == this.STAFF_COMMON) {
          this.occur_report.report_staff_id = this.staffId;
        }
        this.reporterNames = res.data.reporter_names;
        this.careStations = res.data.care_stations;
        if (this.occurId != 0) {
          this.occur_report = res.data.occur_report;
        } else {
          this.occur_report.remand = DefaultOccurReportRemand();
        }
        this.execAfterFetch(this.StorageKey, data => {
          this.occur_report = data.occur_report;
          this.displayPreview = data.displayPreview;
        });
      }
    );
  }
}
