


































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import MedicalList from "@/components/master/medical/MedicalList.vue";
import { MedicalInstitution } from "#/model/uniquemaster";
import UtilMixin from "@/mixins/utilMixin";
import * as constant from "#/const";

@Component({
  components: {
    MedicalList
  }
})
export default class MedicalEntry extends Mixins(AxiosMixin, UtilMixin) {
  private search = "";
  private medicalList: MedicalInstitution[] = [];

  // 新規登録ボタン表示フラグ
  private get showNew(): boolean {
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_MEDICAL_INSTITUTION,
      constant.AGREEMENT.FUNCTION_DIV_INSERT
    );
  }

  public mounted(): void {
    this.fetchMedicalList();
  }

  private fetchMedicalList(): void {
    this.postJsonCheck(
      window.base_url + "/api/master/medicalinstitutions/get",
      {},
      res => {
        this.medicalList = res.data.medical_institutions;
      }
    );
  }
}
