






















































































































import { Component, Watch } from "vue-property-decorator";
import EveryTime from "./EveryTime.vue";

@Component
export default class EveryTimeV2 extends EveryTime {
  created(): void {
    this.setSameBuildingFlg();
    this.setTwoPersonFlg();
    this.setNursingLeaveInstFlg();
  }

  //同一建物
  protected setSameBuildingFlg() {
    if (this.receiptVisitRecord.same_building_flg == 1) {
      this.same_building_flg1 = true;
      this.same_building_flg2 = false;
    }
    if (this.receiptVisitRecord.same_building_flg == 2) {
      this.same_building_flg1 = false;
      this.same_building_flg2 = true;
    }
  }

  //2人訪問
  protected setTwoPersonFlg() {
    if (this.receiptVisitRecord.two_person_flg == 1) {
      this.two_person_flg1 = true;
      this.two_person_flg2 = false;
    }
    if (this.receiptVisitRecord.two_person_flg == 2) {
      this.two_person_flg1 = false;
      this.two_person_flg2 = true;
    }
  }

  /** 同一建物10% */
  protected same_building_flg1 = false;

  /** 同一建物15% */
  protected same_building_flg2 = false;

  /** 2人訪問Ⅰ */
  protected two_person_flg1 = false;

  /** 2人訪問Ⅱ */
  protected two_person_flg2 = false;

  //同一建物10%
  @Watch("same_building_flg1")
  protected handleSameBuildingFlg1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.same_building_flg1 == true) {
      this.receiptVisitRecord.same_building_flg = 1;
      this.same_building_flg2 = false;
    }
    if (this.same_building_flg1 == false && this.same_building_flg2 == false) {
      this.receiptVisitRecord.same_building_flg = 0;
    }
  }

  //同一建物15%
  @Watch("same_building_flg2")
  protected handleSameBuildingFlg2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.same_building_flg2 == true) {
      this.receiptVisitRecord.same_building_flg = 2;
      this.same_building_flg1 = false;
    }
    if (this.same_building_flg1 == false && this.same_building_flg2 == false) {
      this.receiptVisitRecord.same_building_flg = 0;
    }
  }

  //2人訪問Ⅰ
  @Watch("two_person_flg1")
  protected handleTwoPersonFlg1() {
    if (!this.isLoaded) {
      return;
    }
    if (this.two_person_flg1 == true) {
      this.receiptVisitRecord.two_person_flg = 1;
      this.two_person_flg2 = false;
    }
    if (this.two_person_flg1 == false && this.two_person_flg2 == false) {
      this.receiptVisitRecord.two_person_flg = 0;
    }
    this.handleHalfHourFlg();
  }

  //2人訪問Ⅱ
  @Watch("two_person_flg2")
  protected handleTwoPersonFlg2() {
    if (!this.isLoaded) {
      return;
    }
    if (this.two_person_flg2 == true) {
      this.receiptVisitRecord.two_person_flg = 2;
      this.two_person_flg1 = false;
    }
    if (this.two_person_flg1 == false && this.two_person_flg2 == false) {
      this.receiptVisitRecord.two_person_flg = 0;
    }
    this.handleHalfHourFlg();
  }

  //同一建物
  protected disableCheckSameBuildingFlgV2(): boolean {
    let result = false;
    if (
      this.officeHistory.care_riyousya_gensan == 0 ||
      this.receiptVisitRecord.online_visit_flg == 1
    ) {
      result = true;
    }
    return result;
  }

  //2人訪問
  protected disableCheckTwoPersonV2(): boolean {
    let result = false;
    if (this.receiptVisitRecord.online_visit_flg == 1) {
      result = true;
    }
    return result;
  }

  //登録時に、disabledの項目は0にする
  public clearDisableItemV2() {
    //サービス提供体制
    if (this.disableCheckServiceFlg()) {
      this.receiptVisitRecord.service_flg = 0;
    }
    //同一建物
    if (this.disableCheckSameBuildingFlgV2()) {
      this.same_building_flg1 = false;
      this.same_building_flg2 = false;
      this.receiptVisitRecord.same_building_flg = 0;
    }
    //提供時間
    if (this.disableCheckNursingTimeZoneDiv()) {
      this.receiptVisitRecord.nursing_time_zone_div = 0;
    }
    //2人訪問
    if (this.disableCheckTwoPersonV2()) {
      this.two_person_flg1 = false;
      this.two_person_flg2 = false;
      this.receiptVisitRecord.two_person_flg = 0;
      this.receiptVisitRecord.half_hour_flg = 0;
    }
    //退院時共同指導日
    if (this.disableCheckNursingLeaveInstDate()) {
      this.receiptVisitRecord.nursing_leave_inst_date = "";
      this.receiptVisitRecord.nursing_leave_inst_date2 = "";
    }
  }
}
