











































import { Component, Mixins, Prop, Emit, Ref } from "vue-property-decorator";
import {
  OwnExpenseServiceCategory,
  DefaultOwnExpenseServiceCategory,
  Office
} from "@/views/master/own_expense_service/OwnExpenseServiceList.vue";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";
import { VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    OfficeSelectInput
  }
})
export default class EditServiceCategoryDialog extends Mixins(
  AxiosMixin,
  RulesMixin,
  UtilMixin
) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: false }) isShown!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {
        id: null,
        name: "",
        code: "",
        order: null,
        items: [],
        offices: []
      };
    }
  })
  category!: OwnExpenseServiceCategory;

  private cloneCategory: OwnExpenseServiceCategory = DefaultOwnExpenseServiceCategory();

  private get IsDelete() {
    return this.cloneCategory.id;
  }

  created() {
    this.cloneCategory = { ...this.category };
  }

  private mounted(): void {
    if (this.cloneCategory.offices) {
      this.cloneCategory.offices.forEach(office => {
        const officeObj = office as Office;
        if (officeObj.office_id == 0) {
          this.selectedOffice = [];
          return;
        }
        if (officeObj.office_id) {
          this.selectedOffice.push(officeObj.office_id);
        }
      });
    } else {
      this.selectedOffice = [];
    }
  }

  // 選択済みの適応事業所
  public selectedOffice: number[] = [];

  //保存
  private async didClickSaveButton() {
    if (!this.form.validate()) {
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.cloneCategory.offices = [];
    if (this.selectedOffice.length > 0) {
      this.selectedOffice.forEach(officeId => {
        this.cloneCategory.offices.push({
          office_id: officeId
        });
      });
    }
    this.save(this.cloneCategory);
    this.closeDialog();
  }

  //保存
  @Emit()
  private save(category: unknown) {
    return category;
  }

  //削除
  private async didClickRemoveButton() {
    if (!(await this.$openConfirm("削除しますか？"))) {
      return;
    }
    this.remove(this.cloneCategory);
    this.closeDialog();
  }

  //削除
  @Emit()
  private remove(category: unknown) {
    return category;
  }

  //キャンセル
  private didClickCancelButton() {
    this.closeDialog();
  }

  //ダイアログ閉じる
  @Emit("close")
  private closeDialog() {
    return;
  }
}
