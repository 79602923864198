import {
  VisitModel,
  DefaultVisitModel
} from "@/components/patient/record2/types";
import { strToDate } from "#/utility/appDate";
import { VISIT_RECORD } from "#/const";

export interface VisitInfo {
  memo: string;
  display: number;
}

export interface LinkShowReport {
  label: string;
  prependIcon?: string; // ラベルの左に表示するアイコン種類
  isEnable: boolean; // リンク先遷移を有効にするか
  access: Function;
}

export interface VisitModelExt extends VisitModel {
  patient_name: string;
  mode: number; // 1:移動中 2:訪問中
  loaded: boolean; //データ読み込み済みフラグ
  is_risk_factor: number; //危険因子の評価表示フラグ
  is_disp_memo_end: number; //訪問終了時にメモを表示するかどうか
}

export const DefaultVisitModelExt = (): VisitModelExt => {
  const values = DefaultVisitModel() as VisitModelExt;
  values.patient_name = "";
  values.mode = 1;
  values.loaded = false;
  values.is_risk_factor = 0;
  values.is_disp_memo_end = 0;
  return values;
};

/** バイタルの測定値テキスト */
export interface VitalText {
  body_temprature: string; // 体温
  breath: string; // 呼吸回数
  pulse: string; // 脈拍回数
  bp_right: string; // 血圧(右腕)
  bp_left: string; // 血圧(左腕)
  spo2: string; // SpO2・呼吸器酸素流量
  loc: string; // 意識レベル
  blood_sugar: string; // 血糖値・測定時間
  weight: string; // 体重
  abd_girth: string; // 臍上腹囲・最大腹囲
  tan: string; // 痰
  lung_noise: string; // 肺副雑音
  daily_life_rate: string; // 日常生活自立度
}

export interface VisitOffice {
  office_info: {
    care_is_senmon_kangoshi: number;
    care_service_time_required: number;
    adl_setting_div: number;
    medical_is_senmon_kangoshi: number;
    medical_kinkyu_houmon_kango_add: number;
  };
}

export interface VisitPatient {
  id: number;
  agreement_id: number;
  ins_div: number;
  mental_div: number;
  regular_visit_div: number;
  info: {
    office_id: number;
    last_name: string;
    first_name: string;
    birthday_wareki: string;
    age: number;
  };
}

export const DefaultVitalText = () => ({
  body_temprature: "",
  breath: "",
  pulse: "",
  bp_right: "",
  bp_left: "",
  spo2: "",
  loc: "",
  blood_sugar: "",
  weight: "",
  abd_girth: "",
  tan: "",
  lung_noise: "",
  daily_life_rate: ""
});

/** 訪問開始日時に応じた時間帯加算の選択値 */
export const ResetVisitTimeType = (visitDatetime: string): number => {
  if (visitDatetime) {
    const date = strToDate(visitDatetime);
    const hour = date.getHours();
    if (18 <= hour && hour < 22) {
      // 夜間訪問看護
      return VISIT_RECORD.TIME_TYPE_NIGHT;
    } else if (6 <= hour && hour < 8) {
      // 早朝看護
      return VISIT_RECORD.TIME_TYPE_MORNING;
    } else if (22 <= hour || 6 > hour) {
      // 深夜訪問看護
      return VISIT_RECORD.TIME_TYPE_MID_NIGHT;
    }
  }
  return 0;
};
