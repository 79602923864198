









































import { Component, Mixins } from "vue-property-decorator";
import PatientInfo from "@/components/master/chouju_csv/PatientInfo.vue";
import Rezept from "@/components/master/chouju_csv/Rezept.vue";
import { OutputRequest } from "@/views/master/choju/types";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    PatientInfo, // 利用者情報（統一）
    Rezept // （レセプト）
  }
})
export default class ChoujuCsvOutput extends Mixins(AxiosMixin, UtilMixin) {
  private searchRequest: OutputRequest = {
    kind: 1,
    office_id: 0,
    target_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    year_month: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 7),
    time_div: 0
  };

  private LOCAL_STORAGE_CHOUJU_OUTPUT_STATUS =
    "LOCAL_STORAGE_CHOUJU_OUTPUT_STATUS";

  private kinds = [
    {
      name: "patientUnity",
      text: "利用者情報",
      value: 1
    },
    {
      name: "receipt",
      text: "レセプト実績情報",
      value: 2
    }
  ];

  created() {
    this.searchRequest.time_div = Number(
      window.localStorage.getItem(this.LOCAL_STORAGE_CHOUJU_OUTPUT_STATUS)
    );
  }

  /**
   * CSV出力ボタン押下時
   */
  private clickOutputCsv() {
    const selectKind = this.kinds.find(
      kind => kind.value == this.searchRequest.kind
    );
    const fileName = `${selectKind?.name}_${this.dateToStr(
      new Date(),
      "yyyyMMddHHmmss"
    )}.csv`;

    window.localStorage.setItem(
      this.LOCAL_STORAGE_CHOUJU_OUTPUT_STATUS,
      String(this.searchRequest.time_div)
    );

    this.postJsonBlobResCheck(
      window.base_url + "/api/master/dataio/choju/output",
      this.searchRequest,
      res => {
        if (res.data.size) {
          const blob = new Blob([res.data]);
          const a = document.createElement("a");
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.click();
        }
      }
    );
  }
}
