import { SELECT_FILTER, COMMON } from "#/const";
import { PdfLinkReport, DefaultPdfLinkReport } from "#/model/patient/pdfLink";
import { lastMonth, today } from "#/utility/appDate";
import { FilterableTableHeader } from "@/components/master/doctorpersonnel/list";

export const Headers: FilterableTableHeader[] = [
  {
    text: "帳票種類",
    value: "report_type_name",
    filterType: SELECT_FILTER.FILTER_TYPE.LIST,
    width: "8rem"
  },
  {
    text: "担当者",
    value: "report_staff_name",
    filterType: SELECT_FILTER.FILTER_TYPE.LIST,
    width: "8rem"
  },
  {
    text: "記載日",
    value: "write_date",
    filterType: SELECT_FILTER.FILTER_TYPE.DATE,
    width: "10rem"
  },
  {
    text: "提出者",
    value: "submit_staff_name",
    filterType: SELECT_FILTER.FILTER_TYPE.LIST,
    width: "8rem"
  },
  {
    text: "提出日",
    value: "submit_datetime",
    filterType: SELECT_FILTER.FILTER_TYPE.DATE,
    width: "10rem"
  },
  {
    text: "詳細",
    value: "description",
    filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
    sortable: false,
    class: "w-auto",
    cellClass: "text-pre-wrap",
    width: "20rem"
  }
];

export const HeadersSend: FilterableTableHeader[] = [
  {
    text: "",
    value: "report_status",
    sortable: false,
    class: "pr-0 pl-2",
    cellClass: "pr-0 pl-2",
    width: "3rem"
  },
  ...Headers
];

export interface Item extends PdfLinkReport {
  list_id: string; // 帳票種類と帳票IDから成るリストID
  report_status: number; // 提出状況
  is_deleted: boolean; // 帳票が削除済みか
}
export const DefaultItem = (): Item => ({
  ...DefaultPdfLinkReport(),
  list_id: "",
  report_status: 0,
  is_deleted: false
});

export interface ItemPdfShow extends PdfLinkReport {
  report_status: number; // 提出状況
  is_deleted: number; // 帳票が削除済みか
}

export interface SearchCond {
  writeStart: string; // 提出日検索開始
  writeEnd: string; // 提出日検索終了
  statuses: number[]; // 帳票提出状況
}
export const DefaultSearchCond = () => {
  const cond: SearchCond = {
    writeStart: lastMonth() + "-01", // 先月初
    writeEnd: today(), // 今日
    statuses: [COMMON.STATUS_SUBMIT]
  };
  return cond;
};
