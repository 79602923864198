




































import { Component, Emit, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class PasswordRemindCheckCode extends Mixins(AxiosMixin) {
  keyCode = ""; //セキュリティコード

  //セキュリティコード確認
  private checkPassword() {
    this.postJsonCheck(
      window.auth_backend_url + "/password-reset/check",
      {
        token: this.$route.query.check_token,
        key_code: this.keyCode
      },
      res => {
        window.tmp_token = res.data.token;
        this.inputPasswordReset();
      }
    );
  }

  @Emit()
  private inputPasswordReset() {
    return;
  }
}
