var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web-print-calendar"},[_c('div',{staticClass:"web-print-calendar-header py-4"},[_c('div',{staticClass:"web-print-calendar-header-item left"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('AppSwitch',{staticClass:"no-print",attrs:{"label":"祝日を表示"},model:{value:(_vm.dispHoliday),callback:function ($$v) {_vm.dispHoliday=$$v},expression:"dispHoliday"}}):_vm._e()],1),_c('div',{staticClass:"web-print-calendar-header-item center"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('AppTitle',{attrs:{"label":_vm.Title}})],1),_c('v-row',{staticClass:"my-2 no-wrap-item",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('CalendarTitleBeforeAfter',{attrs:{"type":"month","calendar":_vm.calendar},on:{"change":function($event){return _vm.fetch()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('AppTitle',{attrs:{"label":_vm.YearMonth}})]},proxy:true}]),model:{value:(_vm.targetMonth),callback:function ($$v) {_vm.targetMonth=$$v},expression:"targetMonth"}})],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"web-print-calendar-header-item right"},[_c('div',{staticClass:"mb-2",staticStyle:{"display":"inline-block"}},[_c('AppButton',{staticClass:"ml-2 no-print",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.print()}}},[_vm._v("PDFで印刷する")])],1),_c('div',{staticClass:"mb-2",staticStyle:{"display":"inline-block"}},[_c('AppButton',{staticClass:"ml-2 no-print",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.webPrint()}}},[_vm._v("Webで印刷する")])],1),_c('AppToolTip',{attrs:{"bottom":"","addContentClass":"no-print","maxWidth":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppIcon',_vm._g(_vm._b({staticClass:"ml-2 no-print",staticStyle:{"vertical-align":"middle"},attrs:{"icon":"help","fill":_vm.$vuetify.theme.themes.light.greyLight}},'AppIcon',attrs,false),on))]}}],null,false,2926848123)},[_c('span',[_vm._v("「Webで印刷する」ボタンについて"),_c('br'),_vm._v(" 印刷画面を1ページに収めたい場合は、印刷の設定で倍率を変更してサイズを調整してください。"),_c('br'),_vm._v(" iPadやiPhoneのOSのバージョンが15以下の場合は、印刷の設定に倍率変更機能がありません。倍率を変更できるようにするには、OSのバージョンを16以上にアップデートする必要があります。16以上の場合は、プリントオプションに「サイズ調整」が表示されますので、そちらで倍率が変更できます。"),_c('br'),_vm._v(" ただし、Macのsafariをお使いの場合、印刷設定を変更しても1ページに収められないことがあります。その場合は、ChromeやFirefoxなど別のブラウザでお試しください。")])])],1):_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","max-width":"100%","width":"5rem","content-class":"no-print text-right"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"ml-auto",staticStyle:{"height":"30px","width":"30px"}},[_c('AppButton',_vm._g({staticClass:"no-print",attrs:{"color":"stroke","outlined":"","height":"30px","width":"30px"}},on),[_c('AppIcon',{attrs:{"icon":"more-horiz","fill":_vm.$vuetify.theme.themes.light.greyLight}})],1)],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.dispHoliday = !_vm.dispHoliday}}},[_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dispHoliday),expression:"!dispHoliday"}],staticClass:"ma-0"},[_vm._v("祝日を表示")]),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(_vm.dispHoliday),expression:"dispHoliday"}],staticClass:"ma-0"},[_vm._v("祝日を非表示")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.print()}}},[_vm._v(" PDFで印刷する ")]),_c('v-list-item',{on:{"click":function($event){return _vm.webPrint()}}},[_vm._v(" Webで印刷する ")])],1)],1),_c('AppToolTip',{attrs:{"bottom":"","addContentClass":"no-print","maxWidth":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AppIcon',_vm._g(_vm._b({staticClass:"ml-2 no-print",attrs:{"icon":"help","fill":_vm.$vuetify.theme.themes.light.greyLight}},'AppIcon',attrs,false),on))]}}])},[_c('span',[_vm._v("「Webで印刷する」ボタンについて"),_c('br'),_vm._v(" 印刷画面を1ページに収めたい場合は、印刷の設定で倍率を変更してサイズを調整してください。"),_c('br'),_vm._v(" iPadやiPhoneのOSのバージョンが15以下の場合は、印刷の設定に倍率変更機能がありません。倍率を変更できるようにするには、OSのバージョンを16以上にアップデートする必要があります。16以上の場合は、プリントオプションに「サイズ調整」が表示されますので、そちらで倍率が変更できます。"),_c('br'),_vm._v(" ただし、Macのsafariをお使いの場合、印刷設定を変更しても1ページに収められないことがあります。その場合は、ChromeやFirefoxなど別のブラウザでお試しください。")])])],1)],1),_c('IbowCalendar',{ref:"calendar",attrs:{"events":_vm.Events,"type":"month"},model:{value:(_vm.targetMonth),callback:function ($$v) {_vm.targetMonth=$$v},expression:"targetMonth"}}),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"end"}},[_c('span',[_vm._v(_vm._s(_vm.OfficeName))])]),_c('v-row',{staticClass:"py-4 no-print",attrs:{"no-gutters":"","justify":"start"}},[_c('AppButton',{staticClass:"mr-2",attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("タブを閉じる")])],1),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }