
























































import { Component } from "vue-property-decorator";
import FAQSearch from "@/components/contact/FAQSearch.vue";
import ContactNoticeList from "@/components/contact/ContactNoticeList.vue";
import ContactList from "@/components/contact/ContactList.vue";
import FAQList from "@/components/contact/FAQList.vue";
import CategoryMenu from "@/components/contact/CategoryMenu.vue";
import MovieContents from "@/components/contact/MovieContents.vue";
import ManualList from "@/components/manual/manual.vue";
import {
  CategoryMenuList,
  SearchCondFAQ,
  DEFAULT_SEARCH_COND_FAQ
} from "@/views/contact/types";
import { ListInquiry } from "@/views/contact/types";
import { ManualItem } from "@/components/manual/types";
import ContactUtility from "@/views/contact/utility";

@Component({
  components: {
    FAQSearch,
    CategoryMenu,
    ContactNoticeList,
    ContactList,
    FAQList,
    ManualList,
    MovieContents
  }
})
export default class Contact extends ContactUtility {
  /** FAQ検索キーワード */
  private searchFAQ: SearchCondFAQ = { ...DEFAULT_SEARCH_COND_FAQ };

  /** マニュアルリスト */
  private manuals: ManualItem[] = [];

  /** カテゴリリスト情報 */
  private categoryList: CategoryMenuList[] = [];

  /** 問合せ情報 */
  private inquiries: ListInquiry[] = [];

  created() {
    this.fetchInquiries();
    this.fetchManuals();
  }

  /** 問合せ取得 */
  private fetchInquiries() {
    this.postJsonCheck(window.base_url + "/api/inquiries/get", {}, res => {
      const resData: ListInquiry[] = res.data.inquiries;

      // メタデータ削除
      this.inquiries = resData.map(inquiry => {
        inquiry.normalized_inquiry_content = this.trimMetaData(
          inquiry.normalized_inquiry_content
        );
        return inquiry;
      });
    });
  }

  /** マニュアルを取得 */
  private fetchManuals() {
    this.postJsonCheck(window.base_url + "/api/manuals/get", {}, res => {
      this.manuals = res.data.manuals;
    });
  }

  /** 取得したカテゴリ一覧を保持 */
  private fetchedCategoryList(categoryList: CategoryMenuList[]) {
    this.categoryList = categoryList;
  }

  /** カテゴリリンククリック時 */
  private onClickCategoryLink(category: CategoryMenuList) {
    this.$router.push({
      name: "FAQ",
      query: this.getFAQUrlQuery({
        category_id: String(category.category_id)
      })
    });
  }

  /** 検索 */
  private search() {
    this.$router.push({
      name: "FAQ",
      query: this.getFAQUrlQuery(this.searchCondToQuery(this.searchFAQ))
    });
  }
}
