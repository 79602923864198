import Base from "./base";
import { Pdf, DefaultPdf, Remand, DefaultRemand } from "./report";
import { ApplyStaff, DefaultApplyStaff } from "./common";

/** サービス確認書差戻し */
export interface ServiceReportRemand extends Remand {
  service_report_id: number; //サービス確認書ID
}
export const DefaultServiceReportRemand = (): ServiceReportRemand => ({
  ...DefaultRemand(),
  service_report_id: 0
});

//サービス確認書
export interface ServiceReport extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  editor_staff_id: number; //'編集者ID',
  status: number; //'記事ステータス\n1:保存\n2:提出\n3:差し戻し',
  submit_datetime: string; //'提出日時',
  week_visit_cnt: number; //'訪問回数',
  explain_staff_id: number; //'説明者ID',
  check_date: string; //'説明確認日',
  request: string; //'利用者へのお願い',
  main_care_content: string; //'主な看護内容（看護/リハビリ目標）',
  main_doctor_indication: string; //'主な医師からの指示内容',
  submit_datetime_wareki: string; //提出日時（和暦）
  check_date_wareki: string; //説明確認日（和暦）
  updated_at_wareki: string; //更新日時（和暦）
  pdf: Pdf; //PDF
  remand: Remand; //差し戻し
  editor_staff: ApplyStaff; //編集者
  explain_staff: ApplyStaff; //説明者
}
export const DefaultServiceReport = (): ServiceReport => ({
  id: 0,
  patient_id: 0,
  editor_staff_id: 0,
  status: 0,
  submit_datetime: "",
  week_visit_cnt: 0,
  explain_staff_id: 0,
  check_date: "",
  request: "",
  main_care_content: "",
  main_doctor_indication: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  submit_datetime_wareki: "",
  check_date_wareki: "",
  updated_at_wareki: "",
  pdf: DefaultPdf(),
  remand: DefaultRemand(),
  editor_staff: DefaultApplyStaff(),
  explain_staff: DefaultApplyStaff()
});
