


























import { Component, Vue, Prop } from "vue-property-decorator";
import Hour24Item from "@/components/hour24/hour24Item.vue";
import LoadingArea from "@/components/home/loadingArea.vue";
import { BoardItem } from "#/model/fulltime";

@Component({
  components: {
    Hour24Item,
    LoadingArea
  }
})
export default class Hour24 extends Vue {
  /** 24時間体制未読一覧 */
  @Prop() unreads!: BoardItem[]; // 24時間体制未読一覧

  /** 24時間体制未読件数 */
  @Prop({ default: 0 }) count!: number;

  /** ローディング中かどうか */
  @Prop() private readonly loading!: boolean;
}
