



























































































import { Component, Ref } from "vue-property-decorator";
import Base from "@/components/officeAggregate/Base";
import { VForm } from "@/types";
import { DataTableHeader } from "vuetify/types/index";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import BiOfficeSelect from "@/components/officeAggregate/BiOfficeSelect.vue";
import {
  ChartData,
  TableData,
  SearchCondition
} from "@/components/officeAggregate/patientContinuityRate/types";

am4core.useTheme(am4themes_animated);
am4core.addLicense(process.env.VUE_APP_AMCHART_LICENSE_KEY);

@Component({
  components: {
    BiOfficeSelect
  }
})
export default class PatientContinuityRate extends Base {
  @Ref("patient_continuity_rate") private readonly form!: VForm;

  private chart!: am4charts.PieChart; //アムチャート
  private chartDatas: ChartData[] = []; //アムチャートデータ
  private tableDatas: TableData[] = []; //テーブルデータ
  private officeId = 0; //検索条件：事業所ID
  private targetMonth = ""; //検索条件：対象年月

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      text: "氏名",
      value: "name",
      align: "start",
      width: ""
    },
    {
      text: "生年月日",
      value: "birthday",
      align: "start",
      width: ""
    },
    {
      text: "継続期間",
      value: "continue_num",
      align: "start",
      width: ""
    }
  ];

  public async mounted() {
    this.collection = "office-patient-continuity-rate";
    this.documentId = String(this.loginUser.id);
    const fbDoc = await this.documentGet();

    //親画面で保存した検索条件
    const parentFbDoc = await this.getParentSearchCon();

    //デフォルトの検索条件
    this.officeId = this.offices(); //まず、選択肢の1番目で検索する
    this.targetMonth = this.todaymonth();

    if (parentFbDoc !== undefined && typeof parentFbDoc !== "boolean") {
      const searchCondition = parentFbDoc;
      this.targetMonth = searchCondition?.end; //親画面で選択した月
    }
    if (fbDoc !== undefined && typeof fbDoc !== "boolean") {
      const searchCondition = fbDoc.searchCondition as SearchCondition;
      this.officeId = searchCondition?.office_id;
    }

    this.disposeChart();
    // 初期メッセージ
    this.chart = this.pieChartMessage();
  }

  // 利用者継続期間は初期メッセージを変更します
  protected pieChartMessage(): am4charts.PieChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.PieChart
    );

    const message = chart.createChild(am4core.Label);
    this.customMessagePatientContinuityRate(message);

    return chart;
  }

  // 利用者継続期間初期メッセージ
  private customMessagePatientContinuityRate(message: am4core.Label) {
    message.text =
      "[bold #808080 font-size:19px]事業所を選択し対象年月を選んで「検索」を押すとグラフを表示します。\n\n最新OSにバージョンアップデートできる端末でご利用ください。\n\n端末が古い場合、画面が正しく表示できない場合があります。[/]";
    message.marginBottom = 250;
    message.align = "center";
  }
  //-----------------------------------------------------
  // アムチャート
  //-----------------------------------------------------
  public beforeDestroy(): void {
    this.disposeChart();
  }

  private disposeChart() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // チャート初期設定
  private initChart(): am4charts.PieChart {
    const chart = am4core.create(
      this.$refs.chartdiv as string | HTMLElement,
      am4charts.PieChart
    );

    this.customizeChart(chart);

    return chart;
  }

  // チャートカスタマイズ
  private customizeChart(chart: am4charts.PieChart) {
    // Add and configure Series
    //グラフデータ
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";

    // 真ん中のテキスト
    const label = chart.seriesContainer.createChild(am4core.Label);
    label.text = `${this.tableDatas.length}`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 50;

    // Let's cut a hole in our Pie chart the size of 30% the radius
    //グラフ内円の大きさ
    chart.innerRadius = am4core.percent(30);

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer"
      }
    ];

    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    // Create a base filter effect (as if it's not there) for the hover to return to
    const shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;

    // Create hover state
    const hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    if (hoverState) {
      const hoverShadow = hoverState.filters.push(
        new am4core.DropShadowFilter()
      );
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 5;
    }

    // 凡例
    chart.legend = new am4charts.Legend();
    chart.legend.maxHeight = 150;
    chart.legend.scrollable = true;

    return chart;
  }

  // チャートデータセット
  private setChartData(chart: am4charts.PieChart) {
    // ダウンロードメニュー
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = this.menu();

    // Add data
    chart.data = this.chartDatas;
  }

  //-----------------------------------------------------
  // ボタン
  //-----------------------------------------------------
  // 検索ボタン
  private async pushSearch() {
    if (!this.form.validate()) {
      alert("入力内容に不備があります");
      return;
    }
    await this.search();
    this.disposeChart();
    // チャート初期設定
    this.chart = this.initChart();
    // チャートデータセット
    this.setChartData(this.chart);
  }

  // Excelダウンロード
  private downloadExcel() {
    const officeName = this.getOfficeName(this.officeId);
    this.postJsonBlobResCheck(
      window.base_url +
        "/api/officeAggregate/patientContinuityRate/downloadexcel",
      {
        table_datas: this.tableDatas,
        target_month: this.targetMonth,
        office_name: officeName
      },
      res => {
        const fileName = "patientContinuityRate.xlsx";
        this.downloadFile(res.data, fileName);
      }
    );
  }

  //-----------------------------------------------------
  // 検索
  //-----------------------------------------------------
  //検索
  private async search() {
    const searchCondition = {
      office_id: this.officeId,
      target_month: this.targetMonth
    };
    return new Promise(resolve => {
      this.postJsonCheck(
        window.base_url + "/api/officeAggregate/patientContinuityRate/search",
        searchCondition,
        res => {
          this.chartDatas = res.data.chart_datas;
          this.tableDatas = res.data.table_datas;
          this.documentSave({
            searchCondition: searchCondition
          });
          resolve(0);
        }
      );
    });
  }
}
