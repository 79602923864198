import Base from "../base";
import { ApplySameBuilding, DefaultApplySameBuilding } from "../common";

/* 事業所履歴-同一建物 */
export interface OfficeHistorySameBuilding extends Base {
  id: number; //ID
  office_history_id: number; //'事業所履歴ID',
  same_building_id: number; //'同一建物ID',
  subtraction_rate: number; //'同一建物減算率（%）',
  same_building: ApplySameBuilding; //同一建物
}

export const DefaultOfficeHistorySameBuilding = (): OfficeHistorySameBuilding => ({
  id: 0,
  office_history_id: 0,
  same_building_id: 0,
  subtraction_rate: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  same_building: DefaultApplySameBuilding()
});
