import { Component, Mixins } from "vue-property-decorator";
import { State } from "vuex-class";
import AxiosMixin from "@/mixins/axiosMixin";
import { namespace } from "vuex-class";
import * as master from "@/store/modules/master/types";
import * as masmen from "@/store/modules/masmen/types";

const Master = namespace(master.name);
const Masmen = namespace(masmen.name);

@Component
export default class MasmenMixin extends Mixins(AxiosMixin) {
  /** 共通マスタのセッター */
  @Master.Mutation(master.SET_MASTER) setMaster!: Function;

  /** マスタメンテ用マスタのセッター */
  @Masmen.Mutation(masmen.SET_MASMEN) setMasmen!: Function;

  /** マスタメンテ用のStore */
  @State(masmen.name) masmenState!: masmen.MasmenState;

  /** マスタメンテメニュー項目 */
  public get MasmenMenus(): masmen.MasterMenu[] {
    return this.masmenState.menus;
  }

  /** 観察項目カテゴリが有効か */
  public get IsNursingCategory(): number {
    return this.masmenState.is_nursing_category;
  }

  /** レセプト代行設定が有効か */
  public get IsAgent(): number {
    return this.masmenState.is_agent;
  }

  /** マスタメンテ用マスタの取得 */
  public GetMaster() {
    // 共通マスタを再取得
    const getMaster = new Promise((resolveGet, rejectGet) => {
      this.postJsonCheck(
        window.base_url + "/api/master/get",
        {},
        res => {
          if (!res.data) {
            rejectGet(res);
            return;
          }
          this.setMaster(res.data);
          resolveGet(res.data);
        },
        errorRes => {
          rejectGet(errorRes);
        }
      );
    });
    // マスタメンテ用マスタを再取得
    const getMasmen = new Promise((resolveGet, rejectGet) => {
      this.postJsonCheck(
        window.base_url + "/api/master/base/get",
        {},
        res => {
          if (!res.data) {
            rejectGet(res);
            return;
          }
          this.setMasmen(res.data);
          resolveGet(res.data);
        },
        errorRes => {
          rejectGet(errorRes);
        }
      );
    });
    // どちらのマスタ取得処理も終えれば、解決する
    return Promise.allSettled([getMaster, getMasmen]);
  }
}
