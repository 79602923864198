


























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import UtilMixin from "@/mixins/utilMixin";
import { ChoiceAddOther } from "@/types";

@Component({
  components: { OfficeSelect }
})
export default class AddUpOfficeSelect extends Mixins(UtilMixin) {
  /** Prop */

  /** 最終的に選択された事業所ID */
  @Prop() value!: number[];

  /** チェックボックスのラベル */
  @Prop({ default: "紐づくサテライトを合算して出力" }) checkBoxLabel!: string;

  /** 対象事業所のラベル */
  @Prop({ default: "出力対象の事業所" }) officeListLabel!: string;

  /** 変数 */

  /** 選択中の事業所ID */
  private officeId = 0;

  /** 紐づくサテライトを含めるフラグ */
  private includeSatelite = false;

  /** getter */

  /** デフォルトで選択される事業所ID */
  private get DefaultOfficeId(): number {
    if (this.loginUser.office_id !== 0) {
      // 所属している場合はログインユーザーの所属事業所
      return this.loginUser.office_id;
    }
    // 所属していない場合は先頭の事業所
    return this.HeadOffice;
  }

  created(): void {
    this.officeId = this.DefaultOfficeId;
  }

  /** 関数 */

  /** 事業所変更時、チェック状態リセット */
  private resetIncludeSateliteCheck() {
    // チェックボックスが無効化されるような事業所に変更した場合は、
    // OFFに強制変更
    if (this.CheckBoxDisabled) {
      this.includeSatelite = false;
    }
  }

  /** 合算用チェックボックス無効化 */
  private get CheckBoxDisabled(): boolean {
    // 未選択、サテライトを選択している場合は無効化
    if (this.officeId === 0) {
      return true;
    }

    const selectedOffice = this.masters.group_offices.find(
      (officeChoice: ChoiceAddOther) => officeChoice.value === this.officeId
    );

    if (!selectedOffice) {
      return true;
    }

    return selectedOffice.value !== selectedOffice.other;
  }

  /** 集計対象の事業所 */
  private get TargetOffices(): ChoiceAddOther[] {
    const selectedOffice = this.masters.group_offices.find(
      (officeChoice: ChoiceAddOther) => officeChoice.value === this.officeId
    );

    if (!selectedOffice) {
      this.input([]);
      return [];
    }

    if (!this.includeSatelite) {
      this.input([Number(selectedOffice.value)]);
      return [selectedOffice];
    }

    // 合算にチェックが入っているなら、選択した事業所のサテライトも含める
    // 先頭が選んだ事業所、続けてID順に並んだサテライト
    const a = [
      selectedOffice,
      ...this.masters.group_offices
        .filter((officeChoice: ChoiceAddOther) => {
          return (
            officeChoice.other === selectedOffice.value &&
            officeChoice.value !== selectedOffice.value
          );
        })
        .sort((a, b) => {
          return a.value < b.value ? -1 : 1;
        })
    ];

    const officeIds = a.map((officeChoice: ChoiceAddOther) =>
      Number(officeChoice.value)
    );
    this.input(officeIds);

    return a;
  }

  /** Emit */

  @Emit("input")
  input(officeIds: number[]) {
    return officeIds;
  }
}
