import { Vue, Component } from "vue-property-decorator";
import { cantMoving, moveByPostMessage } from "@/main";

@Component
export default class FocusingMixin extends Vue {
  /** 共通エリア・他画面への動線を非表示にするフラグ */
  protected get IsFocusingMode() {
    return this.$route.meta?.isNoCommonArea;
  }

  mounted() {
    if (this.IsFocusingMode) {
      // 別タブで見ている時、開いた元のタブが遷移制御できるようにする
      window.addEventListener("message", moveByPostMessage);
    }
  }

  beforeDestroy() {
    window.removeEventListener("message", moveByPostMessage);
  }

  /** 前の画面に戻る、別タブで開いている時はタブを閉じる */
  protected async externalBack() {
    if (this.IsFocusingMode) {
      if (!(await cantMoving())) {
        window.close();
      }
    } else {
      this.$router.go(-1);
    }
  }
}
