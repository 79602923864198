
































































import {
  Component,
  Prop,
  Watch,
  Mixins,
  Emit,
  Ref
} from "vue-property-decorator";
import { VForm } from "@/types";
import ShareFileMixin from "./sharefileMixins";
import { EditTagsList, TagType, DispTag } from "@/components/sharefile/type";

@Component
export default class TagEdit extends Mixins(ShareFileMixin) {
  @Ref("form") private readonly form!: VForm;

  @Prop() dialog!: boolean; // ダイアログ表示状態
  @Prop() shareFileIds!: number[]; // 共有ファイルIDリスト

  @Emit()
  public close(isUpdate: boolean) {
    return isUpdate;
  }

  private TagType = TagType;

  // タグテキスト
  private tagText = "";
  // タグ一覧 オリジナル
  private orgTags: string[] = [];
  // タグ一覧 追加
  private addTags: string[] = [];
  // タグ一覧 削除
  private delTags: string[] = [];
  // 新規タグ一覧 表示
  private get AddTags(): DispTag[] {
    const ret: DispTag[] = [];
    this.addTags.forEach(tag => {
      const dispTag: DispTag = { tag: tag, type: TagType.Add };
      ret.push(dispTag);
    });
    return ret;
  }
  // 既存タグ一覧 表示
  private get OrgDelTags(): DispTag[] {
    const ret: DispTag[] = [];
    this.orgTags.forEach(tag => {
      const dispTag: DispTag = { tag: tag, type: TagType.Non };
      if (this.delTags.find(dTag => dTag == tag)) {
        dispTag.type = TagType.Del;
      }
      ret.push(dispTag);
    });
    return ret;
  }

  // 初期化処理
  @Watch("dialog")
  private init() {
    this.tagText = "";
    this.orgTags = [];
    this.addTags = [];
    this.delTags = [];
    if (this.dialog) {
      this.fetch();
    }
  }

  // データ取得
  private fetch() {
    console.log(this.shareFileIds);
    // 選択されたファイルのタグ取得
    if (this.shareFileIds.length > 0) {
      console.log(this.shareFileIds);

      this.postJsonCheck(
        window.base_url + "/api/tags/get",
        { shareFileIds: this.shareFileIds },
        res => {
          this.orgTags = res.data.tags;
        }
      );
    }
  }

  // バリデーション タグテキスト
  private validateTagText(): boolean | string {
    if (this.orgTags.concat(this.addTags).find(tag => tag == this.tagText)) {
      return "タグが重複しています";
    }
    return true;
  }

  // タグ追加
  private addTag() {
    if (!this.tagText) return;
    if (!this.form.validate()) {
      return;
    }
    this.addTags.push(this.tagText);
    this.tagText = "";
  }

  // タグ削除
  private delTag(tag: string) {
    if (this.delTags.find(dTag => dTag == tag)) {
      this.delTags = this.delTags.filter(dTag => dTag != tag);
      return;
    }
    if (this.addTags.find(aTag => aTag == tag)) {
      this.addTags = this.addTags.filter(aTag => aTag != tag);
      return;
    }
    this.delTags.push(tag);
  }

  // 保存リクエストパラメータ作成
  private generageRequestParam(): EditTagsList {
    return {
      ids: this.shareFileIds,
      add_tags: this.addTags,
      delete_tags: this.delTags
    };
  }

  // 保存イベント
  private clickSave() {
    const tagsinfo: EditTagsList = this.generageRequestParam();
    console.log(tagsinfo);
    // TODO 未実装
    this.postJsonCheck(
      window.base_url + "/api/tags/save",
      {
        Ids: tagsinfo.ids,
        add_tags: tagsinfo.add_tags,
        delete_tags: tagsinfo.delete_tags
      },
      () => {
        this.close(true);
      }
    );
  }

  // キャンセルイベント
  private clickCancel() {
    this.close(false);
  }
}
