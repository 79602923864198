





















































































































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import { OcrMeta, PatientShareFileInfo } from "@/components/sharefile/type";
import { DataTableHeader } from "vuetify";
import ShareFileMixin from "@/components/sharefile/sharefileMixins";
import { OcrStatus } from "@/const/ocr";
import { FONT_SIZE } from "@/types";

@Component
export default class PatientFileListTable extends Mixins(ShareFileMixin) {
  /** 利用者ファイル情報 */
  @Prop() patientShareFiles!: PatientShareFileInfo[];

  /** データテーブルのページネーション機能を無効化するかどうか */
  @Prop({ default: false }) disablePagination!: boolean;

  /** 利用者名を表示するかどうか */
  @Prop({ default: true }) isDisplayPatientName!: boolean;

  /** 合計件数（フロントだけで制御する場合以外の時） */
  @Prop({ default: 0 }) backTotal!: number;

  /** １ページあたりの件数（フロントだけで制御する場合以外の時） */
  @Prop({ default: 0 }) backPageItems!: number;

  /** チェックが入った利用者ファイル情報 */
  private selected: PatientShareFileInfo[] = [];

  private get headers() {
    const headerParts: DataTableHeader[] = [];
    const isOcrShow = this.patientShareFiles.some(item => {
      return this.isOcrShown(item.ocr_meta);
    });

    // バックエンド側でページングする場合、フロント側では全件データを持っていないのでソートしない
    const isTableSort = !this.disablePagination;

    let updateDateWidth = "112px";
    switch (window.font_size) {
      case FONT_SIZE.DEFAULT:
        updateDateWidth = "112px";
        break;
      case FONT_SIZE.SIZE_L:
        updateDateWidth = "124px";
        break;
      case FONT_SIZE.SIZE_LL:
        updateDateWidth = "140px";
        break;
    }

    if (this.isDisplayPatientName) {
      headerParts.push(
        {
          text: "利用者名",
          value: "patient_name",
          sortable: isTableSort,
          align: "start",
          width: "124px",
          class: "pl-0",
          cellClass: "pl-0"
        },
        {
          text: "ふりがな",
          value: "patient_name_kana",
          sortable: isTableSort,
          align: "start",
          width: "158px",
          class: "pl-0",
          cellClass: "pl-0"
        }
      );
    }
    headerParts.push(
      {
        text: "",
        value: "preview",
        sortable: false,
        align: "start",
        width: "64px",
        class: "px-0",
        cellClass: "px-0"
      },
      {
        text: "ファイル名",
        value: "patient_file.file_name",
        sortable: isTableSort,
        align: "start",
        width: "220px",
        class: "pl-0",
        cellClass: "pl-0 word-break-all"
      },
      {
        text: "タグ",
        value: "tags",
        sortable: false,
        align: "start",
        width: "200px",
        class: "pl-0",
        cellClass: "pl-0"
      }
    );
    if (isOcrShow) {
      headerParts.push({
        text: "",
        value: "ocr",
        sortable: false,
        align: "start",
        width: "88px",
        class: "px-0",
        cellClass: "px-0"
      });
    }
    headerParts.push(
      {
        text: "",
        value: "actions",
        sortable: false,
        align: "start",
        width: "84px",
        class: "px-0",
        cellClass: "px-0"
      },
      {
        text: "更新日時",
        value: "patient_file.updated_at",
        sortable: isTableSort,
        align: "start",
        width: updateDateWidth
      },
      {
        text: "形式",
        value: "icon",
        sortable: false,
        align: "center",
        width: "72px"
      },
      {
        text: "サイズ",
        value: "size",
        sortable: isTableSort,
        align: "start",
        width: this.isDisplayPatientName ? "128px" : ""
      }
    );
    if (this.isDisplayPatientName) {
      headerParts.push({
        text: "事業所",
        value: "office_name",
        sortable: isTableSort,
        align: "start"
      });
    }

    return headerParts;
  }

  @Emit("update:checked")
  private checked(): PatientShareFileInfo[] {
    return this.selected;
  }

  @Emit("click:edit")
  private editPatientShareFile(
    data: PatientShareFileInfo
  ): PatientShareFileInfo {
    return data;
  }

  @Emit("click:copy") private copyPatientShareFile(
    data: PatientShareFileInfo
  ): PatientShareFileInfo {
    return data;
  }

  @Emit("click:patient")
  private onClickPatient(patientId: number): number {
    return patientId;
  }

  @Emit("click:checkOcrStatus")
  private checkOcrStatus(fileId: number) {
    return fileId;
  }

  @Emit("click:execOcr")
  private execOcr(file: PatientShareFileInfo) {
    return file;
  }

  @Emit("click:checkOcrResult")
  private checkOcrResult(file: PatientShareFileInfo) {
    return file;
  }

  private isOcrShown(ocrMeta: OcrMeta) {
    // ocr_status.id が 0 -> ファイルに対応した OCR レコードが存在しない
    return ocrMeta.ocr_enabled && ocrMeta.ocr_status.id > 0;
  }
  private ocrStatusText(ocrStatus: number) {
    switch (ocrStatus) {
      case OcrStatus.standby:
        return "OCR対象";
      case OcrStatus.pending:
        return "OCR中";
      case OcrStatus.completed:
        return "OCR済み";
      case OcrStatus.created:
        return "作成済み";
      case OcrStatus.failed:
        return "OCR失敗";
      default:
        return "";
    }
  }

  private ocrStatusColor(ocrStatus: number) {
    switch (ocrStatus) {
      case OcrStatus.standby:
        return "yellow--text text--darken-2";
      case OcrStatus.pending:
        return "light-blue--text text--lighten-2";
      case OcrStatus.completed:
        return "primary--text";
      case OcrStatus.created:
        return "grey--text text--lighten-4";
      case OcrStatus.failed:
      default:
        return "";
    }
  }

  private ocrAction(file: PatientShareFileInfo) {
    switch (file.ocr_meta.ocr_status.status) {
      case OcrStatus.standby:
        this.execOcr(file);
        return;

      case OcrStatus.pending:
        this.checkOcrStatus(file.id);
        return;

      case OcrStatus.completed:
        this.checkOcrResult(file);
        return;

      case OcrStatus.created:
        this.checkOcrResult(file);
        return;

      case OcrStatus.failed:
        this.execOcr(file);
        return;

      default:
        // do nothing
        break;
    }
  }

  public reset() {
    this.selected = [];
  }
}
