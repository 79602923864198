


























































































import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import ShiftTypeEntryBase from "@/components/shift/ShiftTypeEntryBase";

@Component
export default class OfficeShiftTypeEntry extends ShiftTypeEntryBase {
  /** ダイアログ開閉状態 */
  @Prop({ default: false }) dialog!: boolean;

  /** 閉じた時 */
  @Emit() private close() {
    return;
  }

  /** ダイアログが開いたときに、データを取得する */
  @Watch("dialog") private changeDialog() {
    if (!this.dialog) {
      return;
    }
    this.fetch();
  }
}
