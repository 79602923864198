

















































import { Component, Mixins, Emit } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class Reciept extends Mixins(AxiosMixin) {
  /** ダイアログ開閉状態 */
  private isOpenDialog = false;

  /** メッセージ */
  private message = "";

  /** タイトル */
  private title = "";

  /** サブタイトル */
  private subTitle = "";

  /** 逐次処理かどうか（処理中断ボタン表示フラグ） */
  private isInteractive = false;

  /** 処理合計 */
  private total = 0;

  /** 処理カウント */
  private count = 0;

  /** 逐次処理状態 0:正常 1:中断中 2:中断完了 */
  private interruptionStatus = 0;

  /** 処理終了フラグ */
  private get ProcessingEnd() {
    return (
      this.total === this.count ||
      this.interruptionStatus == 2 ||
      !this.isInteractive
    );
  }

  /** 表示用処理カウント */
  private get Count() {
    if (this.isInteractive) {
      return this.count;
    } else {
      return this.total;
    }
  }

  /** ダイアログを開く */
  public open(
    title: string,
    subTitle: string,
    total: number,
    isInteractive = false
  ) {
    this.isOpenDialog = true;
    this.message = "";
    this.title = title;
    this.subTitle = subTitle;
    this.isInteractive = isInteractive;
    this.total = total;
    this.count = 0;
    this.interruptionStatus = 0;
  }

  /** ダイアログを閉じる */
  @Emit() private close() {
    this.isOpenDialog = false;
  }

  /** 処理を中断する */
  private interruption() {
    this.interruptionStatus = 1;
  }

  /** ダウンロードする */
  private download() {
    this.postJsonBlobResCheck(
      window.base_url + "/api/receipt/downloadResult",
      {
        message: this.message
      },
      res => {
        const fileName = "downloadResult.txt";
        if (!res.data.size) {
          return;
        }
        const blob = new Blob([res.data]);
        const a = document.createElement("a");
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.click();
      }
    );
  }

  /** 結果を更新する（実績確定） */
  public updateResult(
    successFlag: boolean,
    successMessage: string,
    errorFlag: boolean,
    errorMessage: string
  ) {
    //成功メッセージ
    if (successFlag) {
      this.message += successMessage;
    }
    //失敗メッセージ
    if (errorFlag) {
      this.message += errorMessage;
    }
    //処理完了
    this.count++;
  }

  /** 結果メッセージ（実績確定じゃない場合） */
  public messageResult(message: string) {
    this.message = message;
  }

  /** タイトルを更新する */
  public setTitle(title: string) {
    this.title = title;
  }

  /** 中断フラグ取得 */
  public getInterruptionFlg() {
    return this.interruptionStatus;
  }

  /** 中断完了設定 */
  public finishInterruption() {
    this.interruptionStatus = 2;
  }
}
