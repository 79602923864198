import Base from "../base";
//同一建物
export interface SameBuilding extends Base {
  id: number; //ID
  agreement_id: number; //'契約ID',
  building_kind: number; //'施設種類\n0:未選択\n1:サービス付き高齢者向け住宅\n2:グループホーム\n3:軽費老人ホーム（ケアハウス）\n4:養護老人ホーム\n5:介護療養型医療施設\n6:介護老人保健施設\n7:特別養護老人ホーム\n8:有料老人ホーム\n9:小規模多機能型居宅介護施設\n10:短期入所生活介護\n11:認知症対応型共同生活介護\n12:同居者のいる一戸建て',
  name: string; //'施設名',
  display_name: string; //'表示名称',
  post_code: string; //'郵便番号',
  pref: number; //'都道府県',
  city: string; //'市区町村',
  area: string; //'番地',
  building: string; //'建物名',
  tel_area: string; //'電話番号1',
  tel_city: string; //'電話番号2',
  tel_num: string; //'電話番号3',
  created_at: string; //作成日時
  updated_at: string; //更新日時
  deleted_at: string; //削除日時
}

export const DefaultSameBuilding = (): SameBuilding => ({
  id: 0,
  agreement_id: 0,
  building_kind: 0,
  name: "",
  display_name: "",
  post_code: "",
  pref: 0,
  city: "",
  area: "",
  building: "",
  tel_area: "",
  tel_city: "",
  tel_num: "",
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
