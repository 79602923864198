












import { Component, Watch } from "vue-property-decorator";
import CarePartBase from "./CarePartBase";

@Component
export default class StaffDiv extends CarePartBase {
  //職員区分 PT,OT,STにした場合提供時間区分を空にする
  @Watch("receiptVisitRecord.staff_div")
  protected handleStaffDiv() {
    if (!this.isLoaded) {
      return;
    }
    if (
      this.receiptVisitRecord.staff_div == 4 ||
      this.receiptVisitRecord.staff_div == 5 ||
      this.receiptVisitRecord.staff_div == 6
    ) {
      this.receiptVisitRecord.nursing_time_div = 0;
    }
  }
}
