export interface IsurerMaster {
  id: number; // ID
  name: string; // 保険者名
  number: string; // 保険者番号
  type: string; // 保険種類
}

// ファイル
export interface FileIF {
  id: number; //ID
  path: string; //'画像パス',
  comment: string; //'コメント',
}

// 利用者の指示書・保険等状況表示用
export interface DispDocInfo {
  text: string; // テキスト
  alert: number; // アラート表示(0:標準 1:赤 2:青)
}

const DefaultDispDocInfo = () => ({
  text: "",
  alert: 0
});

// 利用者情報表示用
export interface PatientDispInfo {
  id: string; // 利用者ID
  display_id: string; // 表示用利用者ID
  name: string; // 利用者名
  name_kana: string; // 利用者名（ふりがな）
  bday_wareki: string; // 誕生日（和暦）
  bday: string; // 誕生日
  age: string; // 年齢
  indicate: DispDocInfo; // 指示書の状況
  insurance: DispDocInfo; // 保険の状況
  high_expense: DispDocInfo; // 高額療養費・負担割合証の状況
  is_med: string; // アイコン（医）表示フラグ
  is_emargency: string; // アイコン（医）表示フラグ
  group_icons: {
    path: string; // imgパス
    name: string; // グループ名
  }[]; // グループアイコン
  covid19_vaccine_status_icon: {
    path: string; // imgパス
    name: string; // グループ名
  };
  is_honobono: string; // ほのぼの利用フラグ
  office_name: string;
  is_not_click_to_visit?: boolean; // クリック不可
}

export const PatientDispInfoDetailValues = () => ({
  id: "",
  display_id: "",
  name: "",
  name_kana: "",
  bday_wareki: "",
  bday: "",
  is_bday: "",
  age: "",
  indicate: DefaultDispDocInfo(),
  insurance: DefaultDispDocInfo(),
  high_expense: DefaultDispDocInfo(),
  is_med: "",
  is_emargency: "",
  group_icons: [],
  covid19_vaccine_status_icon: { path: "", name: "" },
  is_honobono: "",
  office_name: ""
});

export interface DetailIndicate {
  /** 指示内容 */
  indicate_content: string;
  indicate_div_label: string; // 指示書区分ラベル
  indicate_period: string; // 指示書 期間
  ins_name: string; // 指示書 機関
  indicate_doctor_name: string; // 指示書 医師
}

// 利用者詳細
export interface Detail {
  name: string; // 氏名
  name_kana: string; // ふりがな
  sex: string; // 性別
  birthday: string; // 生年月日
  first_visit_date_wareki: string; // 初回訪問年月日
  post_code: string; // 郵便番号
  address: string; // 住所
  address_google_map_url: string; // google map url
  address_hazard_map: string; // ハザードマップ用住所
  tel: string; // 電話番号
  tel_contact: string; // 電話番号連絡先
  mobile: string; // 携帯番号
  mobile_contact: string; // 携帯番号連絡先
  remarks: string; // 備考
  need_care_level: string; // 要介護認定
  care_mana_ins_name: string; // ケアマネ 機関
  care_mana_tel: string; // ケアマネ 電話番号
  care_mana_fax: string; // ケアマネ FAX
  care_mana_staff: string; // ケアマネ 担当者

  /** ケアマネ連絡先 */
  care_mana_contacts: Contact[];
  /** ケアマネ留意事項 */
  care_mana_remarks: string;

  main_disease1: string; // 主傷病名１
  main_disease2: string; // 主傷病名２
  main_disease3: string; // 主傷病名３
  indicate_content: string; // 指示書 指示内容（未使用）
  primary_doctor_ins_name: string; // 主治医 機関
  primary_doctor_name: string; // 主治医 医師
  primary_doctor_tel: string; // 主治医 電話番号
  primary_doctor_fax: string; // 主治医 FAX

  /** 主治医連絡先 */
  primary_doctor_contacts: Contact[];
  /** 主治医留意事項 */
  primary_doctor_remarks: string;

  indicates: DetailIndicate[]; // 指示書リスト
  person_name: string; // キーパーソン（氏名）
  person_tel: string; // キーパーソン（電話番号）
  person_sp_comment: string; // キーパーソン（特記すべき事項）
  person_family_relationship: string; // キーパーソン（続柄）
  message: string; // 申し送り事項
}

export interface Contact {
  /** 連絡先種別 0:未選択 1:携帯電話 2:自宅電話 3:自宅FAX 4:email 5:携帯メール 6:その他 */
  contact_type: number;
  /** 連絡先種別名 */
  contact_type_name: string;
  /** 連絡先情報 */
  contact_info: string;
}

export const DetailDefaultValues = (): Detail => ({
  name: "",
  name_kana: "",
  sex: "",
  birthday: "",
  first_visit_date_wareki: "",
  post_code: "",
  address: "",
  address_google_map_url: "",
  address_hazard_map: "",
  tel: "",
  tel_contact: "",
  mobile: "",
  mobile_contact: "",
  remarks: "",
  need_care_level: "",
  care_mana_ins_name: "",
  care_mana_tel: "",
  care_mana_fax: "",
  care_mana_staff: "",
  care_mana_contacts: [],
  care_mana_remarks: "",
  main_disease1: "",
  main_disease2: "",
  main_disease3: "",
  indicates: [],
  indicate_content: "",
  primary_doctor_ins_name: "",
  primary_doctor_name: "",
  primary_doctor_tel: "",
  primary_doctor_fax: "",
  primary_doctor_contacts: [],
  primary_doctor_remarks: "",
  person_name: "",
  person_tel: "",
  person_sp_comment: "",
  person_family_relationship: "",
  message: ""
});
