























































import { Component, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { RelationInstitution } from "#/model/uniquemaster";

@Component({
  components: {}
})
export default class InfoCoord extends Mixins(AxiosMixin, UtilMixin) {
  /** 絞込み用文字列 */
  private search = "";

  /** 関連機関情報 */
  private rInsts: RelationInstitution[] = [];

  /** リストヘッダー情報 */
  private headers: DataTableHeader[] = [
    {
      text: "関連機関名",
      align: "start",
      sortable: false,
      value: "name",
      width: "288px"
    },
    {
      text: "事業所区分",
      align: "start",
      sortable: false,
      value: "office_div",
      width: "154px"
    },
    {
      text: "主たる事業所",
      align: "start",
      sortable: false,
      value: "parent_office",
      width: "202px"
    },
    {
      text: "事業所番号",
      align: "start",
      sortable: false,
      value: "office_code",
      width: "148px"
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "start"
    }
  ];

  /** 1ページあたりの表示件数 */
  private itemPerPage = 25;

  /** 表の現在のページ数 */
  private pageNo = 1;

  created() {
    this.fetchRelationInstitutions();
  }

  /** 関連機関情報取得 */
  private fetchRelationInstitutions() {
    this.postJsonCheck(
      window.base_url +
        "/api/master/dataio/info_coord/relationinstitutions/get",
      {},
      res => {
        if (res.data.relation_institutions) {
          this.rInsts = res.data.relation_institutions;
        }
      }
    );
  }

  /** 選択ボタンクリック時 */
  private clickEdit(id: number) {
    this.$router.push({
      name: "InfoCoordIO",
      params: {
        id: String(id)
      }
    });
  }

  /** 表のページ変更 */
  private changePage(pageNo: number) {
    this.pageNo = pageNo;
  }

  //絞り込み
  private filterItems(
    value: unknown,
    search: string,
    item: RelationInstitution
  ) {
    if (search == null) {
      return false;
    }

    if (item == null) {
      return false;
    }
    if (
      item.name.match(search) ||
      item.furigana.match(search) ||
      item.office_code.match(search)
    ) {
      return true;
    }

    return false;
  }
}
