// 職員

/** 職種:PT */
export const STAFF_DIV_PT = 4;
/** 職種:OT */
export const STAFF_DIV_OT = 5;
/** 職種:ST */
export const STAFF_DIV_ST = 6;
/** 職種:看護学生 */
export const STAFF_DIV_STUDENT = 8;

/** 職員権限:システム管理者 */
export const STAFF_AUTH_ADMIN = 99;
/** 職員権限:本社職員 */
export const STAFF_AUTH_HOME = 20;
/** 職員権限:事務所所長 */
export const STAFF_AUTH_CHIEF = 10;
/** 職員権限:事務所事務員 */
export const STAFF_AUTH_CLERK = 11;
/** 職員権限:一般職員 */
export const STAFF_AUTH_COMMON = 1;

export const AUTH = {
  /** 職員権限:システム管理者 */
  ADMIN: 99,
  /** 職員権限:本社職員 */
  HOME: 20,
  /** 職員権限:事務所所長 */
  CHIEF: 10,
  /** 職員権限:事務所事務員 */
  CLERK: 11,
  /** 職員権限:一般職員 */
  COMMON: 1
};
