



















import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component
export default class RightSideMenu extends Vue {
  private isOpen = false;

  //サイドメニューオープンで大画面（サイドメニュー外）のスクロール制限
  @Watch("isOpen")
  onIsOpen(newValue: boolean, oldValue: boolean) {
    if (newValue) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }
}
