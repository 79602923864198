























































































import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import AppPlaces from "#/components/AppPlaces.vue";
import RulesMixin from "#/mixins/rulesMixin";
import {
  Insurer,
  InsurerDiv,
  setInsurer,
  setInsurerDiv
} from "@/views/master/insurer/types";
import { VForm } from "@/types";
import OfficeSelectInput from "@/components/master/OfficeSelectInput.vue";

@Component({
  components: {
    AppPlaces,
    OfficeSelectInput
  }
})
export default class InsurerForm extends Mixins(RulesMixin) {
  @Ref("form") private readonly form!: VForm;

  @Prop({ default: () => setInsurer() }) insurer!: Insurer;

  //選択済みの適応事業所
  public selectedOffice: number[] = [];

  public validate(): boolean {
    return this.form.validate();
  }

  private addNewInsurerDiv(): void {
    this.insurer.insurer_divs.push(setInsurerDiv());
  }

  private async removeInsurerDiv(div: InsurerDiv): Promise<void> {
    if (!(await this.$openConfirm("保険者番号を削除しますか？"))) {
      return;
    }
    this.insurer.insurer_divs = this.insurer.insurer_divs.filter(d => d != div);
  }

  private requiredInsurerName(): boolean | string {
    return this.required(this.insurer.insurer_name, "必須です");
  }

  private requiredInsurerDiv(div: InsurerDiv): boolean | string {
    if (div.insurer_div > 0) {
      return true;
    }
    return "必須です";
  }

  private requiredInsurerDivNo(div: InsurerDiv): boolean | string {
    if (div.insurer_no.length == 0) {
      return "必須です";
    }

    let count = 0;
    for (const insDiv of this.insurer.insurer_divs) {
      if (
        div.insurer_div == insDiv.insurer_div &&
        div.insurer_no == insDiv.insurer_no
      ) {
        count++;
      }
    }

    if (count > 1) {
      return "保険者番号が重複しています。";
    }

    return true;
  }
}
