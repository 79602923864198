import Base from "../base";
import { PatientHistory, DefaultPatientHistory } from "./patientHistory";
import { PatientFile } from "./patientFile";
import { CareInsurance, DefaultCareInsurance } from "./careInsurance";
import {
  CareInsuranceBurdenRatio,
  DefaultCareInsuranceBurdenRatio
} from "./careInsuranceBurdenRatio";
import { MedicalInsurance, DefaultMedicalInsurance } from "./medicalInsurance";
import {
  MedicalInsuranceHighExpense,
  DefaultMedicalInsuranceHighExpense
} from "./medicalInsuranceHighExpense";
import { RegularVisit, DefaultRegularVisit } from "./regularVisit";
import {
  MedicalInsuranceOldAge,
  DefaultMedicalInsuranceOldAge
} from "./medicalInsuranceOldAge";
import { PublicMoney } from "./publicMoney";
import { OwnExpenseTemplate } from "./ownExpenseTemplate";
import { Choice } from "@/types";
import { PatientPreNursingMonth } from "./patientPreNursingMonth";

interface PatientGroup {
  id: number;
  patient_id: number;
  group_id: number;
  sort_no: number;
  is_icon_display: number;
}

interface PatientGroupCovid19VaccineStatus extends Base {
  id: number;
  patient_id: number;
  status: number;
  icon_display: number;
}

export const DefaultPatientGroupCovid19VaccineStatus = (): PatientGroupCovid19VaccineStatus => ({
  id: 0,
  patient_id: 0,
  status: 0,
  icon_display: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

/** コロナワクチン接種状況選択肢 */
export const covid19VaccineStatuses: Choice[] = [
  { text: "未選択", value: 0 },
  { text: "ワクチン1回目接種済み", value: 1 },
  { text: "ワクチン2回目接種済み", value: 2 },
  { text: "ワクチン3回目接種済み", value: 3 },
  { text: "ワクチン4回目接種済み", value: 4 },
  { text: "ワクチン5回目接種済み", value: 5 },
  { text: "ワクチン6回目接種済み", value: 6 },
  { text: "ワクチン7回目接種済み", value: 7 },
  { text: "ワクチン8回目接種済み", value: 8 },
  { text: "接種を望まない", value: 99 }
];

export interface Patient extends Base {
  id: number; //'ID',
  agreement_id: number; //'契約ID',
  requester_type: number; //'依頼元種類',
  personnel_id: number; //'担当者ID',
  doctor_id: number; //'医師ID',
  tel_area: string; //'電話番号市外局番',
  tel_city: string; //'電話番号市内局番',
  tel_num: string; //'電話番号加入者番号',
  requester_fullname: string; //'依頼元:氏名',
  family_relationship_id: number; //'依頼元:続柄',
  contact_info: string; //'連絡事項',
  coop_id: number; //利用者連携用ID\n他システムから入力連携される場合、他システム側で発行されたIDを設定する\niBowで入力する場合、同一契約内で一意のIDを設定する\n他システムに出力連携する場合、必要
  honobono_sv_jigyo_id: number; //'ほのぼのサービス事業者ID\nほのぼのから連携される',
  honobono_user_id: number; //'ほのぼのシステムID\nほのぼのから連携される',
  ins_div: number; //保険区分 1:医療保険 2:介護保険
  regular_visit_div: number; //'定期巡回訪問タイプ（定期巡回は介護保険のみ）\n0:定期巡回以外\n1:定期巡回\n2:随時巡回
  mental_div: number; //'一般・精神区分\n1:訪問看護\n2:精神訪問看護（医療保険のみ）',
  care_div: number; //'介護・予防区分\n0:介護以外\n1:介護\n2:予防',
  info: PatientHistory; //基礎情報
  patient_histories: PatientHistory[]; //利用者履歴
  files: PatientFile[]; //ファイル
  groups: PatientGroup[]; //グループ
  covid19_vaccine_status: PatientGroupCovid19VaccineStatus; //コロナワクチン接種状況
  care_insurances: CareInsurance[]; //介護保険
  burden_ratios: CareInsuranceBurdenRatio[]; //負担割合証
  // indicates * Indicate                     //指示書
  medical_insurances: MedicalInsurance[]; //医療保険
  high_expenses: MedicalInsuranceHighExpense[]; //高額療養費
  old_ages: MedicalInsuranceOldAge[]; //高齢受給者証
  public_moneys: PublicMoney[]; //公費
  own_expense_templates: OwnExpenseTemplate[]; //自費テンプレート
  // msg_board: MsgBoard;                       //メッセージボード
  care_insurance_now: CareInsurance; //有効な介護保険
  burden_ratio_now: CareInsuranceBurdenRatio; //有効な負担割合
  // range_indicates: Indicate[];                     //指定範囲で有効な指示書
  // indicate_now: Indicate;                       //有効な指示書
  // injection_indicate_now: Indicate;                       //有効な注射指示書
  // special_indicate_now: Indicate;                       //有効な特別指示書
  medical_insurance_now: MedicalInsurance; //有効な医療保険
  high_expense_now: MedicalInsuranceHighExpense; //有効な高額療養費
  old_age_now: MedicalInsuranceOldAge; //有効な高齢受給者証
  public_money_nows: PublicMoney[]; //有効な公費（複数有効あり）
  regular_visit_now: RegularVisit; // 有効な定期巡回
  regular_visits: RegularVisit[]; // 定期巡回
  // latest_adl: Adl;                            //最新のADL
  // personnel: ApplyPersonnel;           //担当者
  // doctor: ApplyDoctor;              //医師
  // family_relationship: ApplyFamilyRelationship;  //依頼元:続柄
  patient_pre_nursing_months?: PatientPreNursingMonth[];
}

export const DefaultPatient = (): Patient => ({
  id: 0, //'ID',
  agreement_id: 0, //'契約ID',
  requester_type: 0, //'依頼元種類',
  personnel_id: 0, //'担当者ID',
  doctor_id: 0, //'医師ID',
  tel_area: "", //'電話番号市外局番',
  tel_city: "", //'電話番号市内局番',
  tel_num: "", //'電話番号加入者番号',
  requester_fullname: "", //'依頼元:氏名',
  family_relationship_id: 0, //'依頼元:続柄',
  contact_info: "", //'連絡事項',
  coop_id: 0, //利用者連携用ID\n他システムから入力連携される場合、他システム側で発行されたIDを設定する\niBowで入力する場合、同一契約内で一意のIDを設定する\n他システムに出力連携する場合、必要
  honobono_sv_jigyo_id: 0, //'ほのぼのサービス事業者ID\nほのぼのから連携される',
  honobono_user_id: 0, //'ほのぼのシステムID\nほのぼのから連携される',
  ins_div: 0, //保険区分 1:医療保険 2:介護保険
  regular_visit_div: 0, //'定期巡回訪問タイプ（定期巡回は介護保険のみ）\n0:定期巡回以外\n1:定期巡回\n2:随時巡回\n3:定期か随時か選択',
  mental_div: 0, //'一般・精神区分\n1:訪問看護\n2:精神訪問看護（医療保険のみ）',
  care_div: 0, //'介護・予防区分\n0:介護以外\n1:介護\n2:予防',
  info: DefaultPatientHistory(), //基礎情報
  patient_histories: [],
  files: [], //ファイル
  groups: [], //グループ
  covid19_vaccine_status: DefaultPatientGroupCovid19VaccineStatus(), // コロナワクチン接種状況
  care_insurances: [], //介護保険
  burden_ratios: [], //負担割合証
  // indicates * Indicate                     //指示書
  medical_insurances: [], //医療保険
  high_expenses: [], //高額療養費
  old_ages: [], //高齢受給者証
  public_moneys: [], //公費
  own_expense_templates: [], //自費テンプレート
  // msg_board: MsgBoard;                       //メッセージボード
  care_insurance_now: DefaultCareInsurance(), //有効な介護保険
  burden_ratio_now: DefaultCareInsuranceBurdenRatio(), //有効な負担割合
  // range_indicates: Indicate[];                     //指定範囲で有効な指示書
  // indicate_now: Indicate;                       //有効な指示書
  // injection_indicate_now: Indicate;                       //有効な注射指示書
  // special_indicate_now: Indicate;                       //有効な特別指示書
  medical_insurance_now: DefaultMedicalInsurance(), //有効な医療保険
  high_expense_now: DefaultMedicalInsuranceHighExpense(), //有効な高額療養費
  old_age_now: DefaultMedicalInsuranceOldAge(), //有効な高齢受給者証
  public_money_nows: [], //有効な公費（複数有効あり）
  regular_visit_now: DefaultRegularVisit(),
  regular_visits: [],
  // latest_adl: Adl;                            //最新のADL
  // personnel: ApplyPersonnel;           //担当者
  // doctor: ApplyDoctor;              //医師
  // family_relationship: ApplyFamilyRelationship;  //依頼元:続柄
  created_at: "",
  updated_at: "",
  deleted_at: ""
});
