











































































































































































































































































































































































import { Component, Prop, Emit, Mixins, Watch } from "vue-property-decorator";
import { R3_CHANGE_LAW_BASE_DATE } from "@/const/common";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import PatientDoctorSelect from "@/components/patient/common_ibow/PatientDoctorSelect.vue";
import * as constant from "#/const";
import UtilMixin from "@/mixins/utilMixin";
import RulesMixin from "#/mixins/rulesMixin";
import PatientMixin from "@/mixins/patientMixin";
import { Patient } from "#/model/patient";
import { Office } from "#/model/agreement";

@Component({
  components: {
    OfficeSelect,
    PatientDoctorSelect
  }
})

/** 利用者基礎情報 状況フォーム */
export default class FormSituation extends Mixins(
  UtilMixin,
  RulesMixin,
  PatientMixin
) {
  /** Prop */

  /** 利用者情報 */
  @Prop() patient!: Patient;

  /** 主治医ID */
  @Prop() private indicateDoctorId!: number;

  /** 主治医強調表示フラグ */
  @Prop() private hightlightDoctor!: boolean;

  /** 読み取り専用フラグ(履歴編集画面用) */
  @Prop({ default: false }) isReadOnly!: boolean;

  /** 履歴編集画面フラグ */
  @Prop({ default: false }) isHistoryEditMode!: boolean;

  /** 事業所情報 */
  @Prop() office!: Office;

  /** 予防訪問看護減算起算開始月 */
  @Prop({ default: () => [] }) preNursingMonths!: {
    id: number;
    exec_yearmonth: number; //リハ予防訪問看護実施年月
    is_auto: number; //自動入力フラグ
    is_checked: number; //チェックフラグ
    wareki: string; //和暦
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
  }[];

  /** プレフィックスID(入力項目にIDを振る対応) */
  @Prop({ default: "patient-info" }) private prefixId!: string;

  /** ロード完了フラグ */
  @Prop() isLoaded!: boolean;

  /** get */

  /** ほのぼの事業所名 */
  private get HonobonoOfficeName(): string {
    const honobono = this.office.office_honobono_offices.find(
      hoffice =>
        hoffice.honobono_sv_jigyo_id == this.patient.honobono_sv_jigyo_id
    );
    return honobono ? "事業所名：" + honobono.honobono_jigyo_knj : "";
  }

  /** 訪問看護終了時間（時） */
  private get VisitEndTimeHour(): string {
    return this.patient.info.visit_end_time_hour;
  }

  /** 訪問看護終了時間（時） */
  private set VisitEndTimeHour(val: string) {
    this.patient.info.visit_end_time_hour = val;
  }

  /** 訪問看護終了時間（分） */
  private get VisitEndTimeMinute(): string {
    return this.patient.info.visit_end_time_minute;
  }

  /** 訪問看護終了時間（分） */
  private set VisitEndTimeMinute(val: string) {
    this.patient.info.visit_end_time_minute = val;
  }

  /** 死亡時間（時） */
  private get DeathTimeHour(): string {
    return this.patient.info.death_time_hour;
  }

  /** 死亡時間（時） */
  private set DeathTimeHour(val: string) {
    this.patient.info.death_time_hour = val;
  }

  /** 死亡時間（分） */
  private get DeathTimeMinute(): string {
    return this.patient.info.death_time_minute;
  }

  /** 死亡時間（分） */
  private set DeathTimeMinute(val: string) {
    this.patient.info.death_time_minute = val;
  }

  /** 予防訪問看護減算起算開始月の集計 */
  private get PreNursingCount() {
    let count = 12;
    for (const pre of this.preNursingMonths) {
      count -= pre.is_checked;
    }
    if (count < 0) {
      count = 0;
    }

    return count;
  }

  /** 「この利用者を削除」表示フラグ */
  private get ShowDelete() {
    // 権限チェック
    switch (this.loginUserAuthId) {
      case constant.OFFICE_STAFF.STAFF_AUTH_CLERK:
      case constant.OFFICE_STAFF.STAFF_AUTH_CHIEF:
      case constant.OFFICE_STAFF.STAFF_AUTH_ADMIN:
        break;
      default:
        return false;
    }

    // 機能使用可能チェック
    return this.availableProcessing(
      constant.AGREEMENT.SETTING_ID_PATIENT,
      constant.AGREEMENT.FUNCTION_DIV_DELETE
    );
  }

  /** 主治医情報 */
  private get FamilyDoctor(): { text: string; style: { color: string } } {
    let text = "";
    const style: { color: string } = { color: "" };
    if (this.patientMaster.medical_institutions) {
      for (const ins of this.patientMaster.medical_institutions) {
        for (const doctor of ins.doctors) {
          if (doctor.id == this.indicateDoctorId) {
            text = doctor.full_name;
          }
        }
        if (text) {
          break;
        }
      }
    }
    if (!text) {
      text = "未選択";
    }
    if (this.hightlightDoctor) {
      style.color = "#ff0000";
    }
    return { text: text, style: style };
  }

  /** 利用者削除ボタンクリック */
  @Emit("delete:patient")
  private clickPatientDelete() {
    return;
  }

  /** 関数 */

  /** 初回訪問日ボタンクリック */
  private clickFirstDay() {
    // 初回訪問日を介護サービス開始日にコピー
    this.$set(
      this.patient.info,
      "care_service_start_date",
      this.patient.info.first_visit_date
    );
  }

  /** 訪問看護終了日ボタンクリック */
  private clickVisitEndDay() {
    // 訪問終了日を介護サービス終了日にコピー
    this.$set(
      this.patient.info,
      "care_service_end_date",
      this.patient.info.visit_end_date
    );
  }

  /** 介護サービス期間チェック */
  private validateCareServiceDate(): boolean | string {
    if (
      this.patient.info.care_service_start_date &&
      this.patient.info.care_service_end_date
    ) {
      if (
        this.patient.info.care_service_start_date >
        this.patient.info.care_service_end_date
      ) {
        return "期間が不正です";
      }
    }
    return true;
  }

  /** 訪問看護感染症実施加算回数チェック */
  private validCovid19AddCount(): boolean | string {
    if (
      this.patient.info.medical_covid19_add_flg &&
      !this.patient.info.medical_covid19_add_cnt
    ) {
      return "必須です";
    }
    return true;
  }

  /** 予防訪問看護減算起算開始月チェック */
  private validPreventGensanDate(): boolean | string {
    if (this.patient.info.prevent_gensan_month) {
      const baseDate = new Date(R3_CHANGE_LAW_BASE_DATE.replace(/-/g, "/"));
      const gensanStartDate = new Date(
        (this.patient.info.prevent_gensan_month + "-01").replace(/-/g, "/")
      );
      if (baseDate > gensanStartDate) {
        return "予防訪問看護減算起算開始月は令和3年4月以降を指定してください。";
      }
    }

    return true;
  }

  /** かかりつけ医チェック */
  private validatePrimaryDoctor(): boolean | string {
    if (
      this.patient.info.primary_doctor_id != 0 &&
      this.patient.info.primary_doctor_id == this.indicateDoctorId
    ) {
      return "既に主治医として登録されています";
    }
    return true;
  }

  // 介護サービスの中止日
  @Watch("patient.info.care_service_end_date") protected handleCareServiceEndDate() {
    if (!this.isLoaded) {
      return;
    }
    // R6年4月以降、介護療養型医療施設入所は選択できなくなったため、該当する場合はリセット
    if ((!this.patient.info.care_service_end_date || this.patient.info.care_service_end_date >= "2024-04-01") && this.patient.info.care_service_end_reason == 7)  {
      this.patient.info.care_service_end_reason = 0;
    }
  }

  // 介護サービスの中止理由の選択肢
  private filteredCareServiceEndReasons() {
    if (this.patient.info.care_service_end_date && this.patient.info.care_service_end_date < "2024-04-01") {
      return this.masters.care_service_end_reasons;
    } else {
      // 7:介護療養型医療施設入所を除く（R6年4月以降、介護療養型医療施設入所は選択できなくなりました）
      return this.masters.care_service_end_reasons.filter((reason: any) => reason.value != 7) 
    }
  }

 }
