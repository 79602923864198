import Base from "../base";

//高齢受給者証
export interface MedicalInsuranceOldAge extends Base {
  id: number; //ID
  patient_id: number; //'利用者ID',
  start_date: string; //'開始日',
  end_date: string; //'終了日',
  insured_div: number; //'被保険者区分\n0:未選択\n1:高齢1\n2:高齢7',
  payment_rate: number; //'給付割合（％）',
  is_exception10: number; //'特例1割有効フラグ',
  is_make_high_expense: number; //'高額療養費作成フラグ',
  insured_no: string; //'記号・番号',
  start_date_wareki: string; //開始日(和暦)
  end_date_wareki: string; //終了日(和暦)
}
export const DefaultMedicalInsuranceOldAge = (): MedicalInsuranceOldAge => ({
  id: 0,
  patient_id: 0,
  start_date: "",
  end_date: "",
  insured_div: 0,
  payment_rate: 0,
  is_exception10: 0,
  is_make_high_expense: 0,
  insured_no: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  start_date_wareki: "",
  end_date_wareki: ""
});
