






import { Component, Prop, Vue } from "vue-property-decorator";
import CorpForm from "@/components/master/corp/CorpForm.vue";
@Component({
  components: { CorpForm }
})
export default class CorpNew extends Vue {
  @Prop({ default: null })
  corpId!: number;
}
