import { StatisticHeader } from "@/components/dashboard/types";
import { SELECT_FILTER } from "#/const";
import { STATISTIC_COL_ID } from "@/components/dashboard/const";

/** 統計ヘッダー:介護保険負担割合証情報 */
export const headerBurdenRatio: StatisticHeader = {
  id: STATISTIC_COL_ID.BURDEN_RATIO,
  text: "介護保険負担割合証情報",
  is_checked: false,
  value: [
    {
      text: "開始日（介護保険負担割合証）",
      select_name: "開始日",
      value: `${STATISTIC_COL_ID.BURDEN_RATIO}.start_date`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "終了日（介護保険負担割合証）",
      select_name: "終了日",
      value: `${STATISTIC_COL_ID.BURDEN_RATIO}.end_date`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.DATE
    },
    {
      text: "給付率（介護保険負担割合証）",
      select_name: "給付率",
      value: `${STATISTIC_COL_ID.BURDEN_RATIO}.str_payment_rate`,
      sortable: true,
      align: "start",
      width: "256px",
      selected: false,
      filterType: SELECT_FILTER.FILTER_TYPE.NUMBER
    }
  ]
};
