









































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { InfoItems } from "../types";

@Component
export default class extends Vue {
  /** ヘッダーラベル */
  @Prop() private readonly label!: string;

  /** 未読件数 */
  @Prop() private readonly unreadCount!: number;

  /** お知らせテーブルデータ */
  @Prop() private readonly tableItems!: InfoItems[];

  /** お知らせテーブルデータのクリック時 */
  @Emit("clickTableItem") private clickTableItem(item: InfoItems): InfoItems {
    return item;
  }

  /** お知らせテーブルヘッダー */
  private tableHeaders: DataTableHeader[] = [
    {
      text: "概要",
      value: "title",
      align: "start"
    },
    { text: "アイコン", value: "icon", align: "end" }
  ];
}
