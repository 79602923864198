import { render, staticRenderFns } from "./RecieptDetail.vue?vue&type=template&id=49843eeb&"
import script from "./RecieptDetail.vue?vue&type=script&lang=ts&"
export * from "./RecieptDetail.vue?vue&type=script&lang=ts&"
import style0 from "./RecieptDetail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VForm,VRow,VSpacer})
