import AppChatInput from "./AppChatInput.vue";

export default {
  title: "Example/AppChatInput",
  component: AppChatInput,
  argTypes: {}
};

const Template = (args, { argTypes }) => ({
  props: Object.keys(argTypes),
  components: { AppChatInput },
  template: '<AppChatInput v-bind="$props" />'
});

export const ChatInput = Template.bind({});

ChatInput.args = {};
