































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { MasterMenu } from "@/store/modules/masmen/types";

@Component
export default class MasterHeader extends Mixins(AxiosMixin) {
  @Prop() menu?: MasterMenu; // ヘッダー内容及びタブ項目

  /** タブ */
  private tab: number | string = 0;

  /** マスタメンテの共通ヘッダーを非表示にするか */
  private get IsNoHeader() {
    return !!this.$route.meta?.isNotMasterHeader;
  }

  @Watch("$route.path")
  tabBind() {
    /** ページパスに応じてアクティブタブを変える */
    const matchMenu =
      this.menu?.lists.findIndex(item => {
        return item.link === this.$route.name;
      }) ?? -1;

    this.tab = matchMenu !== -1 ? matchMenu : 1;

    /** 子画面かどうか判定 */
    if (matchMenu === -1) {
      // タブ表示数取得
      const listsCount = this.menu?.lists.length;

      // タブ表示数分ループ
      if (listsCount) {
        for (let i = 0; listsCount > i; i++) {
          // 同一タブ内の子画面かどうかroute.nameで判定
          const menuItem = this.menu?.lists[i];
          if (menuItem == null) continue;
          const isSameTree = menuItem.children?.some(childItem => {
            return childItem == this.$route.name;
          });
          if (isSameTree) {
            this.tab = menuItem.path;
            break;
          }
        }
      }
    } else {
      this.tab = this.$route.path;
    }
  }

  mounted() {
    this.tabBind();
  }
}
