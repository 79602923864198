












































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import EditMixin from "@/mixins/editMixin";
import MasmenMixin from "@/mixins/masmenMixin";
import { cantMoving } from "@/main";
import NoticeTarget from "@/components/master/notice/NoticeTarget.vue";
import NoticeChatTarget from "@/components/master/notice/NoticeChatTarget.vue";
import NoticeHeader from "@/components/master/notice/NoticeHeader.vue";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import { VForm } from "@/types";
import {
  DefaultOfficeHistory,
  DefaultOfficeHistoryMail
} from "#/model/agreement";
import AppButton from "#/components/AppButton.vue";
import { Getter } from "vuex-class";
import ExpantionActions from "@/components/patient/ExpantionActions.vue";

@Component({
  components: {
    NoticeTarget,
    NoticeChatTarget,
    NoticeHeader,
    OfficeSelect,
    AppButton,
    ExpantionActions
  }
})
export default class NoticeSetting extends Mixins(
  UtilMixin,
  EditMixin,
  AxiosMixin,
  MasmenMixin
) {
  @Ref("MasterNoticeSettingAddButton")
  private readonly addButton!: AppButton;

  @Ref("MastgerNoticeSettingForm")
  private readonly form!: VForm;

  @Getter("CanNoticeChatAgreement", { namespace: "chat" })
  canNoticeChatAgreement!: (agreementId: number) => boolean;

  private officeId = 0;
  private currentOfficeId = 0;
  private copiedOffice = DefaultOfficeHistory();
  private originalOffice = DefaultOfficeHistory();

  @Watch("copiedOffice", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }

  private created() {
    if (this.loginUser.parent_office_id > 0) {
      // 主たる事業所のみ表示するので初期値は主たる事業所にする
      this.officeId = this.loginUser.parent_office_id;
      this.getOffice();
    } else {
      // 未所属は未選択
    }
  }

  private async onChangeOffice() {
    // 変更事項がある場合、事業所変更の前に確認する
    if (await cantMoving()) {
      this.officeId = this.currentOfficeId;
      return;
    }

    // 編集状態のリセット
    this.setNotLoaded();
    this.setNoEditMode();
    this.getOffice();
  }

  private getOffice() {
    this.postJsonCheck(
      window.base_url + "/api/master/station/get_by_id",
      {
        office_id: this.officeId
      },
      res => {
        this.currentOfficeId = this.officeId;
        this.copiedOffice = this.deepCopy(res.data.office.office_info);
        this.originalOffice = this.deepCopy(res.data.office.office_info);
        this.setLoaded();
      }
    );
  }

  private addMail() {
    this.copiedOffice.office_history_mails.push(DefaultOfficeHistoryMail());
    this.$nextTick(() => {
      this.addButton.$el.scrollIntoView({ behavior: "smooth" });
    });
  }

  private async removeMail(index: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.copiedOffice.office_history_mails.splice(index, 1);
    }
  }

  private save() {
    if (!this.form.validate()) {
      this.$openAlert("入力内容に不備があります");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/master/station/notice/save",
      {
        office_history: this.copiedOffice
      },
      () => {
        this.setNoEditMode();
        this.setNotLoaded();

        // 続けて編集・保存するときに updated_at で弾かれないようにするため
        this.getOffice();

        // マスタ情報を更新しておく
        this.GetMaster().then(() => {
          this.$openAlert("保存しました");
        });
      }
    );
  }

  private async cancel() {
    if (await cantMoving()) {
      return;
    }

    this.setNotLoaded();
    this.setNoEditMode();

    this.copiedOffice = this.deepCopy(this.originalOffice);
    this.setLoaded();
  }
}
