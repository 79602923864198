
















import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Choice } from "@/types";

@Component
export default class ChikenLauncher extends Mixins(UtilMixin, AxiosMixin) {
  private dialog = false;
  private office: Choice = { value: 0, text: "" };
  private officeList: Choice[] = [{ value: 0, text: "" }];

  @Watch("dialog")
  private changeDialog() {
    if (this.dialog == false) {
      return;
    }
    this.office = this.officeList[0];
  }

  public launch(chikenDiv: string) {
    const officeId = this.loginUser.office_id;
    if (chikenDiv == "1") {
      if (officeId == 0) {
        // 所属事業所がない場合は連携済み事業所リスト表示
        this.postJsonCheck(
          window.base_url + "/api/home/chiken/officelist/get",
          {},
          res => {
            this.officeList = res.data.chiken_link_office_list;
            if (this.officeList.length > 0) {
              this.office = this.officeList[0];
            }
            this.dialog = true;
          }
        );
      } else {
        this.toChiken(officeId);
      }
      return;
    }

    if (chikenDiv == "2") {
      this.toChikenInformation();
      return;
    }
  }

  private toChikenInformation() {
    this.postJsonCheck(
      window.base_url + "/api/home/chiken/frontend",
      {},
      res => {
        window.open(
          `${res.data.chiken_front_url}/?chiken_div=2`,
          "_brank",
          "noreferrer"
        );
      }
    );
    this.close();
  }

  private toChiken(officeId: number) {
    this.postJsonCheck(
      window.base_url + "/api/home/chiken/onetime",
      { office_id: officeId },
      res => {
        window.open(
          `${res.data.chiken_front_url}/?onetime=${res.data.one_time_token}&chiken_div=1`,
          "_brank",
          "noreferrer"
        );
      }
    );
    this.close();
  }

  private selected() {
    const officeId = Number(this.office.value);
    if (isNaN(officeId)) {
      console.log(this.office.value, "is not number.");
    } else {
      this.toChiken(officeId);
    }
  }

  private close() {
    this.dialog = false;
  }
}
